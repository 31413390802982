import { motion } from 'framer-motion'
import { useState } from 'react'
import { IoIosMenu } from 'react-icons/io'
import { IoClose } from 'react-icons/io5'
import { MenuVariantProps, NewMenuLinkType } from '../menu'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../utils'

export default function MenuVariantSeventeen({ menu, website }: MenuVariantProps) {
  return (
    <div>
      <DesktopNavbar menu={menu} website={website} />
    </div>
  )
}

const DesktopNavbar = ({ menu, website }: MenuVariantProps) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '#35ac35' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className="flex justify-between items-center px-4 md:px-12 md:py-6  py-4 text-white ">
        <a href="/">
          {website?.config?.logo ? (
            <img
              src={website?.config?.logo}
              alt={website?.name}
              className="cursor-pointer w-[40px] md:w-[60px] h-[40px] md:h-[60px] rounded-full object-cover"
            />
          ) : (
            <p
              className="md:text-xl"
              style={{
                ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }),
                ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
              }}
            >
              {website?.name}
            </p>
          )}
        </a>
        <div>
          <button onClick={() => setIsOpen(!isOpen)} className="flex items-center gap-x-1">
            <span
              className="md:text-xl"
              style={{
                ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }),
                ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
              }}
            >
              Menu
            </span>
            <span>
              <IoIosMenu
                size={46}
                className="hidden md:block"
                color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }).color}
              />
              <IoIosMenu
                size={26}
                className="block md:hidden"
                color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }).color}
              />
            </span>
          </button>
        </div>
      </div>
      {isOpen && (
        <motion.div
          initial={{ y: -150 }}
          animate={{ y: -95 }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
          exit={{ y: -150 }}
          style={{
            ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'white' }),
            ...getSpacing(menu?.settings?.spacing),
          }}
          className="bg-white md:mt-0 mt-10 py-2"
        >
          <div className="flex justify-between items-center px-4 md:px-12 md:py-6  py-4 text-white ">
            <a href="/">
              {website?.config?.logo ? (
                <img
                  src={website?.config?.logo}
                  alt={website?.name}
                  className="cursor-pointer w-[40px] md:w-[60px] h-[40px] md:h-[60px] rounded-full object-cover"
                />
              ) : (
                <p
                  className="md:text-xl"
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  {website?.name}
                </p>
              )}
            </a>
            <div>
              <button onClick={() => setIsOpen(!isOpen)} className="flex items-center gap-x-1">
                <span
                  className="md:text-xl"
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  Close
                </span>
                <span>
                  <IoClose
                    size={46}
                    className="hidden md:block"
                    color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }).color}
                  />
                  <IoClose
                    size={28}
                    className="block md:hidden"
                    color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }).color}
                  />
                </span>
              </button>
            </div>
          </div>
          <div
            style={{
              ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '#ffffff' }),
              ...getSpacing(menu?.settings?.spacing),
            }}
            className="flex flex-col justify-center items-center pb-4  list-none  text-gray-500 hover:text-black gap-y-6 md:gap-y-10"
          >
            {menu.links.map(item => {
              return (
                <p
                  key={item.id}
                  className="hover:scale-125 md:hover:scale-150 transition-all duration-200  md:text-2xl hover:border-b border-black"
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#7b7b7b' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                    borderColor: getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }).color,
                  }}
                >
                  <a href={item.url} target={item.type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}>
                    {item.name}
                  </a>
                </p>
              )
            })}
          </div>
        </motion.div>
      )}
    </div>
  )
}
