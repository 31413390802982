import { BsArrowUpRightCircle } from 'react-icons/bs'
import { FooterVariantProps, getSocialLabel, NewFooter, Social } from '../footer'
import FontLoader from '../../menu/FontLoader'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../../menu/utils'

const SocialItem = ({ type, link, footer }: { type: Social; link: string; footer: NewFooter }) => {
  const label = getSocialLabel(type)

  return (
    <div>
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className="cursor-pointer flex gap-x-2 items-center hover:text-gray-600 md:ml-4  transition-all duration-300 pr-2"
        style={{
          ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
          ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'black' }),
        }}
      >
        <span>{label}</span>
        <BsArrowUpRightCircle
          color={getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'black' }).color}
        />
      </a>
    </div>
  )
}
export default function VariantFour({ footer, website }: FooterVariantProps) {
  return (
    <div>
      <FontLoader settings={footer?.settings?.fontFamily} />
      <Footer footer={footer} website={website} />
    </div>
  )
}

const Footer = ({ website, footer }: FooterVariantProps) => {
  return (
    <div
      style={{
        ...getBackgroundColor({ settings: footer?.settings?.backgroundColor, defaultValue: '#B7B4A2' }),
        ...getSpacing(footer?.settings?.spacing),
      }}
      className="py-12 md:text-3xl"
    >
      <div className="flex justify-between items-start md:pt-6 md:px-12 px-4">
        <div
          className="flex flex-col gap-y-1 md:gap-y-3"
          style={{
            ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
            ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'black' }),
          }}
        >
          {website?.config?.email ? <a href={website?.config?.email}> {website?.config?.email}</a> : footer?.email}
          <p>{website?.config?.phone ? website?.config?.phone : footer?.phone}</p>
          <p className="w-[90%] md:w-full">
            {website?.config?.address ? <p>{website?.config?.address} </p> : footer?.address}
          </p>
        </div>
        <div className="flex flex-col gap-y-1 md:gap-y-3 md:flex-row md:items-end justify-end text-black">
          {website?.config?.social
            ? Object.keys(website?.config?.social).map(item => (
                <SocialItem footer={footer} type={item as Social} link={website?.config?.social[item]} />
              ))
            : footer?.social?.map(socialType => <SocialItem type={socialType} link="#" footer={footer} />)}
        </div>
      </div>

      <div className="grid items-end justify-end mt-12 pr-4 md:px-12 md:pb-6">
        <div className="flex items-end justify-end text-2xl md:text-5xl uppercase font-bold">
          <a href="/">
            {website?.config?.logo ? (
              <span
                className="font-serif "
                style={{
                  ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
                  ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'black' }),
                }}
              >
                {website.name.slice(0, 3)}
              </span>
            ) : (
              <span
                className="font-serif "
                style={{
                  ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
                  ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'black' }),
                }}
              >
                {website.name}
              </span>
            )}
          </a>
        </div>
      </div>
    </div>
  )
}
