import Header from '../posts-page/components/Header'
import Preview from './components/preview'

function PreviewPage() {
  return (
    <div className="bg-[#D9D9D9] dark:bg-[#353535] min-h-100vh">
      <Header variant="minimal" />
      <div className="dark:bg-black md:bg-black md:rounded-t-[40px] p-3 md:mx-2 md:mt-8">
        <Preview />
      </div>
    </div>
  )
}

export default PreviewPage
