import { motion } from 'framer-motion'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
// import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { BlogItemsGroup, BlogSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

// const viewVariants = {
//   offscreen: {
//     y: 100,
//   },
//   onscreen: {
//     y: 0,
//     transition: {
//       duration: 0.5,
//       delay: 0.2,
//       ease: [0, 0.71, 0.2, 1.01],
//     },
//   },
// }
const viewImgVariants = {
  offscreen: {
    opacity: 0.1,
  },
  onscreen: {
    opacity: 1,
    transition: {
      duration: 1,
      delay: 0.8,
      ease: [0, 0.71, 0.2, 1.01],
    },
  },
}
const viewport = { once: true, amount: 0.5 }

function VariantFive({ section }: { section: BlogSection }) {
  const items = section.variables.find(variable => variable.name === 'ITEMS')?.data as BlogItemsGroup[]
  // const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  // const text: Descendant[] = [
  //   {
  //     type: 'paragraph',
  //     children: [
  //       {
  //         text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique',
  //       },
  //     ],
  //   },
  // ]
  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
      className="py-8 "
    >
      <div className="px-4 lg:px-12">
        {items ? (
          <div className="space-y-6 lg:space-y-12">
            {items?.map(item => (
              <div key={item?.id} className="lg:grid lg:grid-cols-[52%_40%] py-4 md:py-12 space-y-8 lg:space-y-0">
                <motion.div initial="offscreen" whileInView="onscreen" viewport={viewport} className="lg:order-first">
                  <motion.div>
                    <div className="md:pt-12 space-y-4 lg:max-w-[600px] m-auto">
                      <button className={`md:text-sm text-lg border border-black px-[5px] py-px rounded-lg`}>
                        <SectionSlatePreview initialValue={item?.title} previewMode />
                      </button>
                      <p className="md:text-2xl">
                        <SectionSlatePreview initialValue={item?.texts} previewMode />
                      </p>
                    </div>
                  </motion.div>
                </motion.div>
                <motion.div initial="offscreen" whileInView="onscreen" viewport={viewport}>
                  <motion.div variants={viewImgVariants}>
                    <div className="h-[300px] sm:h-[400px] md:h-[500px] lg:h-[600px]">
                      <img className="w-full h-full rounded-xl" src={item?.image} alt="" />
                    </div>
                  </motion.div>
                </motion.div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default VariantFive
