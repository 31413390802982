import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { TeamMembersSection, TeamMembersSectionMember } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import Team51 from './VariantDesign/Team51'
import Team52 from './VariantDesign/Team52'

export default function VariantFive({ section }: { section: TeamMembersSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const members = section.variables.find(variable => variable.name === 'TEAM_MEMBERS')
    ?.data as TeamMembersSectionMember[]

  return (
    <div
      className="h-full py-[50px]"
      style={{
        ...generateSectionBackground({
          defaultColor: 'rgb(214 211 209)',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="container mx-auto ">
        <div className="md:w-[750px] max-w-full mx-auto px-4 md:px-0 space-y-1">
          <div className="text-2xl md:text-[40px]">
            <p className="text-black text-center">
              <SectionSlatePreview initialValue={title} previewMode />
            </p>
          </div>
          {subTitle && (
            <div>
              <p className="text-black md:text-[18px] text-center">
                <SectionSlatePreview initialValue={subTitle} previewMode />
              </p>
            </div>
          )}
          {paragraph && (
            <div>
              <p className="md:text-[16px] text-[14px] text-center">
                <SectionSlatePreview initialValue={paragraph} previewMode />
              </p>
            </div>
          )}
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-2 text-white text-center gap-y-8 mt-6 md:mt-14">
          {members.map((member, index) => {
            switch (index % 2) {
              case 0:
                return <Team51 key={member.id} member={member} />
              case 1:
                return <Team52 key={member.id} member={member} />
            }
          })}
        </div>
      </div>
    </div>
  )
}
