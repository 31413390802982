import { motion } from 'framer-motion'
import { useState } from 'react'
import FontLoader from '../FontLoader'
import { MenuVariantProps, NewMenuLinkType } from '../menu'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../utils'
export default function MenuVariantEight({ menu, website }: MenuVariantProps) {
  return (
    <div>
      <FontLoader settings={menu?.settings?.fontFamily} />
      <DesktopNavbar menu={menu} website={website} />
      <MobileNavbar menu={menu} website={website} />
    </div>
  )
}

const DesktopNavbar = ({ menu, website }: MenuVariantProps) => {
  const [hover, setHover] = useState(false)

  return (
    <div
      className="hidden lg:block "
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'white' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className="px-12 mx-auto flex items-center py-6 justify-between ">
        <div>
          <a href="/">
            {website?.config.logo ? (
              <img
                src={website?.config?.logo}
                alt={website?.name}
                className="cursor-pointer w-[80px] h-[80px] rounded-full"
              />
            ) : (
              <p
                className="text-xl font-bold"
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                }}
              >
                {website?.name}
              </p>
            )}
          </a>
        </div>

        <div
          className={`py-2 bg-white px-8 transition-all duration-300 ${hover ? 'shadow-xl' : 'shadow-lg'}`}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <ul className="list-none font-semibold flex text-xl space-x-6 gap-x-6 py-2 px-4 rounded">
            {menu.links.map(({ id, name, type, url }) => {
              return (
                <li key={id} className="transition-all duration-300 hover:text-gray-500">
                  <a
                    href={url}
                    target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                    style={{
                      ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                      ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                    }}
                  >
                    {name}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

const MobileNavbar = ({ menu, website }: MenuVariantProps) => {
  const [show, setShow] = useState(false)
  return (
    <div
      className="block lg:hidden "
      style={{
        ...getBackgroundColor({
          settings: menu?.settings?.backgroundColor,
          defaultValue: `${show ? 'black' : 'white'}`,
        }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <motion.div
        className={`flex justify-between transition-all duration-500 ease-in-out items-center px-4 py-4 ${
          show ? ' text-white' : ' text-black'
        }`}
        style={{
          ...getBackgroundColor({
            settings: menu?.settings?.backgroundColor,
            defaultValue: `${show ? 'black' : 'white'}`,
          }),
          ...getSpacing(menu?.settings?.spacing),
        }}
      >
        <div className="px-4 py-4">
          <a href="/">
            {website?.config?.logo ? (
              <img
                className="cursor-pointer h-[38px] w-[38px] rounded-full"
                src={website?.config?.logo}
                alt={website?.name}
              />
            ) : (
              <p
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: `${show ? 'white' : 'black'}` }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                }}
              >
                {website?.name}
              </p>
            )}
          </a>
        </div>

        {show ? (
          <button
            className="outline-none border-none bg-white  rounded-lg p-2 py-1  transition-all duration-300 hover:bg-[#ffffffad]"
            onClick={() => setShow(false)}
            style={{
              ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
              ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
              ...getBackgroundColor({
                settings: menu?.settings?.backgroundColor,
                defaultValue: 'white',
              }),
              ...getSpacing(menu?.settings?.spacing),
            }}
          >
            cancel
          </button>
        ) : (
          <button
            className="outline-none border-none transition-all duration-300 hover:bg-[#000000ad] bg-black text-white rounded-lg p-2 py-1"
            onClick={() => setShow(true)}
            style={{
              ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }),
              ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
              ...getBackgroundColor({
                settings: menu?.settings?.backgroundColor,
                defaultValue: 'black',
              }),
              ...getSpacing(menu?.settings?.spacing),
            }}
          >
            menu
          </button>
        )}
      </motion.div>
      {show && (
        <div
          className="list-none bg-black  pb-8 gap-y-4 text-white flex flex-col"
          style={{
            ...getBackgroundColor({
              settings: menu?.settings?.backgroundColor,
              defaultValue: `${show ? 'black' : 'white'}`,
            }),
            ...getSpacing(menu?.settings?.spacing),
          }}
        >
          <motion.ul
            animate={{
              x: show ? [80, 0] : 0,
            }}
            transition={{
              duration: 0.5,
              type: 'spring',
            }}
            className="list-none"
          >
            {menu.links.map(({ id, name, url, type }) => {
              return (
                <li
                  key={id}
                  className="py-1 w-[350px] hover:bg-[gray] rounded-lg"
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  <a href={url} target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}>
                    {name}
                  </a>
                </li>
              )
            })}
          </motion.ul>
        </div>
      )}
    </div>
  )
}
