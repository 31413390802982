import clsx from 'clsx'
import { useContext, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Descendant } from 'slate'
import { ReactComponent as ImageIcon } from 'src/assets/icons/new/image.svg'
import { upsertBlockByPageID } from 'src/services/website.service'
import {
  BlogItemsGroup,
  BlogSection,
  BlogVariables,
  SectionVariableCategories,
} from 'src/_polly/components/src/sections'
import { v4 as uuid } from 'uuid'
import { ReactComponent as DeleteIcon } from '../../assets/icons/menuBar/delete.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/menuBar/edit.svg'
import { ReactComponent as Blog } from '../../icons/ComponentsIcons/Blog.svg'
import { WebsiteContext } from '../context/WebsiteContext'
import { AdvancedFeature } from '../modal/advanced'
import { EditSection } from './EditSectionModal'

import BottomFloatingButton from '../BottomFloatingButton'
// import { SectionSlate } from '../editor'
import { MenuSaveButton } from '../modal/section/NewMenu/NewMenu'
import { ButtonForSection } from './ButtonEditor'
import ImageUploader from './ImageUploader'
import ModalDialogV2 from './ModalDialogV2'
import SectionBaseField from './SectionField/BaseField'
import SectionTextField from './SectionField/SectionTextField'
import ItemField from './SectionField/SectionSingleItemField'

interface EditSectionModelModalProps extends EditSection {
  section: BlogSection
}

type EditItemProps = {
  data?: BlogItemsGroup
  onSave: (data: Partial<BlogItemsGroup>) => void
  variantName: string
}

function Item({
  data,
  onDeleteClick,
  onEditClick,
}: {
  data: BlogItemsGroup
  onDeleteClick: () => void
  onEditClick: () => void
}) {
  return (
    <div className=" border border-black dark:border-white h-[65px] flex justify-between">
      <div className=" flex items-center">
        <div className=" grid grid-cols-2 gap-1 ml-2 mr-4">
          <div className=" w-2 h-2 bg-gray-300 rounded-full" />
          <div className=" w-2 h-2 bg-gray-300 rounded-full" />
          <div className=" w-2 h-2 bg-gray-300 rounded-full" />
          <div className=" w-2 h-2 bg-gray-300 rounded-full" />
        </div>
        <div className=" flex items-center gap-4">
          <div className=" aspect-1 h-10">
            <img
              className=" object-cover object-center  bg-black rounded-full  w-full h-full"
              src={data.image}
              alt=""
            />
          </div>
          {/* <SectionSlate initialValue={data?.name} previewMode /> */}
          <p>
            <ItemField initialValue={data.name} />
          </p>
        </div>
      </div>
      <div className=" flex ">
        <button
          type="button"
          className="flex justify-center items-center w-16 border-l dark:border-white border-black"
          onClick={onEditClick}
        >
          <EditIcon />
        </button>
        <button
          type="button"
          className="flex justify-center items-center w-16 border-l dark:border-white border-black"
          onClick={onDeleteClick}
        >
          <DeleteIcon />
        </button>
      </div>
    </div>
  )
}
type FormInput = {
  name: any
  title: any
  description: any
  text: any
}
// function EditItem({ data: blogData, variantName, onSave }: EditItemProps) {
function EditItem({ data, onSave, variantName }: EditItemProps) {
  const initialDataName: Descendant[] = [
    {
      type: 'paragraph',
      children: [
        {
          text: 'item name',
        },
      ],
    },
  ]
  const initialDataDescription: Descendant[] = [
    {
      type: 'paragraph',
      children: [
        {
          text: 'item description',
        },
      ],
    },
  ]
  const initialDate = '1 MAY 2016'

  const [showImageModal, setShowImageModal] = useState(false)
  const [url, setUrl] = useState(data?.image || '')
  const [titles, setTitle] = useState(data?.title || initialDataName)
  const [names, setName] = useState(data?.name || initialDataName)
  const [descriptions, setDescription] = useState(data?.description || initialDataDescription)
  const [date, setDate] = useState(data?.date || initialDate)
  const [textsData, setTexts] = useState(data?.texts || initialDataDescription)

  const fullDate = `${new Date(date).toLocaleString('default', { day: 'numeric' })} ${new Date(date).toLocaleString(
    'default',
    { month: 'long' },
  )} 
  ${new Date(date).toLocaleString('default', { year: 'numeric' })}`
  const { handleSubmit } = useForm<FormInput>()
  const onSubmit: SubmitHandler<FormInput> = value => {
    setName(value?.name)
    setDescription(value?.description)
    setTitle(value?.title)
    setTexts(value?.text)
    setUrl(url)
    setDate(date)
    onSave({
      ...value,
      id: data?.id,
      name: names,
      description: descriptions,
      title: titles,
      texts: textsData,
      image: url,
      date,
    })
  }

  return (
    <div className="px-4 dark:text-white">
      <form className="pb-24" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-8">
          {(variantName === 'VARIANT_4' || variantName === 'VARIANT_5' || variantName === 'VARIANT_7') && (
            <div>
              <SectionTextField
                initialValue={titles ?? initialDataName}
                title="Title"
                onChange={value => setTitle(value)}
                onUpdate={() => handleSubmit(onSubmit)}
              />
            </div>
          )}
          {variantName !== 'VARIANT_5' && (
            <div>
              <SectionTextField
                initialValue={names ?? initialDataName}
                title="Name"
                onChange={value => setName(value)}
                onUpdate={() => handleSubmit(onSubmit)}
              />
            </div>
          )}
          {variantName === 'VARIANT_5' ? (
            <div>
              <SectionTextField
                title="Description"
                initialValue={textsData}
                onChange={value => setTexts(value)}
                onUpdate={() => handleSubmit(onSubmit)}
              />
            </div>
          ) : (
            <div>
              {variantName !== 'VARIANT_2' && variantName !== 'VARIANT_1' && (
                <div>
                  <SectionTextField
                    initialValue={descriptions ?? initialDataDescription}
                    title="Description"
                    onChange={value => setDescription(value)}
                    onUpdate={() => handleSubmit(onSubmit)}
                  />
                </div>
              )}
            </div>
          )}
          {variantName === 'VARIANT_7' && (
            <SectionBaseField title="Date" preview={fullDate} onUpdate={() => handleSubmit(onSubmit)}>
              <div>
                <div className="mx-4 border-b border-black dark:border-white">
                  <input
                    type="date"
                    defaultValue={date}
                    className="text-xl focus:outline-none outline-0 mt-2 w-full bg-slate-100 dark:bg-slate-400 text-black rounded p-3 mb-2"
                    onChange={(value: any) => setDate(value?.target?.value)}
                  />
                </div>
              </div>
            </SectionBaseField>
          )}
          <div className="space-y-4">
            <h2 className="text-[19px] font-semibold">Image</h2>
            <button type="button" className="w-full" onClick={() => setShowImageModal(true)}>
              <div
                className={clsx(
                  'w-full bg-white dark:bg-black',
                  !url && 'aspect-1 flex justify-center items-center border border-black dark:border-white',
                  url && 'aspect-1',
                )}
              >
                {url && <img src={url} className="object-cover object-center bg-black w-full h-full" alt="" />}
                {!url && <ImageIcon />}
              </div>
            </button>
          </div>
        </div>
      </form>
      <BottomFloatingButton label={data?.id ? 'Update' : 'Add'} onClick={handleSubmit(onSubmit)} disabled={!url} />
      {/* <BottomFloatingButton
        label={blogData?.id ? 'Update' : 'Add'}
        onClick={handleSubmit(onSubmit)}
        // disabled={!url || !names}
      /> */}
      <ModalDialogV2 open={showImageModal} onBack={() => setShowImageModal(false)}>
        <ImageUploader
          onSubmit={_url => {
            setUrl(_url)
            setShowImageModal(false)
          }}
          buttonLabel="Select Image"
          onClose={() => setShowImageModal(false)}
        />
      </ModalDialogV2>
    </div>
  )
}

export default function EditSectionBlogModal({
  open,
  section: defaultValue,
  onClose: onBack,
  onUpdate,
}: EditSectionModelModalProps) {
  const websiteContext = useContext(WebsiteContext)
  if (!open || !defaultValue) return null
  const [section, setSection] = useState(defaultValue)
  const [updating, setUpdating] = useState(false)
  const [editItem, setEditItem] = useState<BlogItemsGroup>()
  const [showAddItemModel, setShowAddItemModel] = useState(false)
  const [fireUpdate, setFireUpdate] = useState({
    fire: false,
    goBack: false,
  })

  const title = section.variables.find(variable => variable.name === 'TITLE')
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')
  // const media = section.variables.find(variable => variable.name === 'MEDIA')
  const items = section.variables.find(variable => variable.name === 'ITEMS')
  const button = section.variables.find(variable => variable.name === 'BUTTON_1')

  const updateVariableData = (name: BlogVariables, category: SectionVariableCategories, data: any) => {
    const current = section.variables.find(variable => variable.name === name)
    if (!current) {
      setSection({
        ...section,
        variables: [...section.variables, { name, data, category, id: uuid() }],
      })
      return
    }
    const otherVariables = section.variables.filter(variable => variable.name !== name)
    const newVariables = [...otherVariables, { ...current, data }]
    setSection({ ...section, variables: newVariables })
  }

  const handleDelete = (id: string) => {
    const newItems = items?.data.filter((item: BlogItemsGroup) => item.id !== id)
    setSection({
      ...section,
      variables: section.variables.map(variable => {
        if (variable.name === 'ITEMS') {
          return { ...variable, data: newItems }
        }
        return variable
      }),
    })
    handleSave(false)
  }

  const handleEditAdd = (data: Partial<BlogItemsGroup>) => {
    if (data.id) {
      const newItems = items?.data.map((item: BlogItemsGroup) => {
        if (item.id === data.id) {
          return { ...item, ...data }
        }
        return item
      })
      setSection({
        ...section,
        variables: section.variables.map(variable => {
          if (variable.name === 'ITEMS') {
            return { ...variable, data: newItems }
          }
          return variable
        }),
      })
    } else {
      setSection({
        ...section,
        variables: section.variables.map(variable => {
          if (variable.name === 'ITEMS') {
            return { ...variable, data: [...variable.data, { ...data, id: uuid() }] }
          }
          return variable
        }),
      })
    }

    setEditItem(undefined)
    setShowAddItemModel(false)
    handleSave(false)
  }

  const handleSave = async (goBack = true) => {
    setFireUpdate({ fire: true, goBack })
  }

  useEffect(() => {
    const update = async (goBack = true) => {
      setUpdating(true)
      await upsertBlockByPageID(websiteContext.pageID, section as any)
      setUpdating(false)
      setFireUpdate({ fire: false, goBack: false })
      if (goBack) onBack()
    }
    if (fireUpdate.fire) update(fireUpdate.goBack)
  }, [fireUpdate, section])

  useEffect(() => {
    onUpdate?.(section)
  }, [section])

  return (
    <>
      <ModalDialogV2 {...{ open, onBack }}>
        <div className="px-5 dark:text-white mt-[25px]">
          <div className="border-t-[1px] border-black dark:border-white">
            {section.variant !== 'VARIANT_3' &&
              section.variant !== 'VARIANT_4' &&
              section.variant !== 'VARIANT_5' &&
              section.variant !== 'VARIANT_6' &&
              section.variant !== 'VARIANT_8' && (
                <>
                  <SectionTextField
                    initialValue={title?.data ?? []}
                    onChange={value => updateVariableData('TITLE', 'TEXT', value)}
                    onUpdate={() => handleSave(false)}
                  />
                  {section.variant !== 'VARIANT_2' && section.variant !== 'VARIANT_1' && (
                    <SectionTextField
                      title="Subtitle"
                      initialValue={subTitle?.data ?? []}
                      onChange={value => updateVariableData('SUBTITLE', 'TEXT', value)}
                      onUpdate={() => handleSave(false)}
                    />
                  )}
                  {section.variant !== 'VARIANT_7' && (
                    <SectionTextField
                      title="Paragraph"
                      initialValue={paragraph?.data ?? []}
                      isParagraph
                      onChange={value => updateVariableData('PARAGRAPH', 'TEXT', value)}
                      onUpdate={() => handleSave(false)}
                    />
                  )}
                  {/* {section.variant !== 'VARIANT_7' && (
                  <SectionTextField
                    title="Paragraph"
                    initialValue={paragraph?.data ?? []}
                    isParagraph
                    onChange={value => updateVariableData('PARAGRAPH', 'TEXT', value)}
                    onUpdate={() => handleSave(false)}
                  />
                )} */}
                </>
              )}

            {section.variant === 'VARIANT_3' && (
              <SectionTextField
                initialValue={title?.data ?? []}
                onChange={value => updateVariableData('TITLE', 'TEXT', value)}
                onUpdate={() => handleSave(false)}
              />
            )}
            {section.variant === 'VARIANT_7' && (
              <ButtonForSection
                defaultValue={button?.data ?? null}
                onButtonChange={update => updateVariableData('BUTTON_1', 'BUTTON', update)}
                onChange={update => updateVariableData('BUTTON_1', 'BUTTON', update)}
              />
            )}
            <div className="mt-12 space-y-4 pb-24">
              <h2 className="capitalize text-[19px] font-semibold ">Blogs</h2>
              <div className=" space-y-5 ">
                {items?.data.map((item: BlogItemsGroup) => (
                  <Item
                    key={item.id}
                    data={item}
                    onDeleteClick={() => handleDelete(item.id)}
                    onEditClick={() => setEditItem(item)}
                  />
                ))}
                <button
                  className="border border-black bg-black dark:border-white h-[65px] flex justify-between items-center text-lg px-6 w-full text-white"
                  type="button"
                  onClick={() => setShowAddItemModel(true)}
                >
                  <span>Add Blog</span> <span className="text-4xl">+</span>
                </button>
              </div>
            </div>
          </div>
          <div className="absolute top-[12px] left-[50%] translate-x-[-50%]">
            <Blog />
          </div>
          <div className="absolute top-[21px] right-[50px]">
            <button type="button" onClick={() => handleSave()} disabled={updating}>
              <MenuSaveButton save="save" />
            </button>
          </div>
          {updating && (
            <div className="fixed z-30 inset-0 lg:max-w-[500px] dark:bg-black/80 bg-white/80">
              <p className="flex items-center h-full justify-center w-full text-[19px] dark:text-white">Updating...</p>
            </div>
          )}

          <AdvancedFeature section={section} onChange={settings => setSection(s => ({ ...s, settings }))} />
        </div>

        <AdvancedFeature
          onBack={() => handleSave(false)}
          section={section}
          onChange={settings => setSection(s => ({ ...s, settings }))}
        />
      </ModalDialogV2>
      <ModalDialogV2
        open={editItem !== undefined || showAddItemModel}
        onBack={() => (editItem ? setEditItem(undefined) : setShowAddItemModel(false))}
      >
        <EditItem data={editItem} variantName={section?.variant} onSave={handleEditAdd} />
      </ModalDialogV2>
    </>
  )
}
