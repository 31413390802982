import Icon from './icons'
import { IconPropsT } from './types'

function SendMessage(props: IconPropsT) {
  const { title = 'Send', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 20 20" size={size} title={title} {...otherProps}>
      <g clipPath="url(#clip0_1994_4167)">
        <path
          d="M18.6733 9.08871C18.5377 8.80867 18.3115 8.58254 18.0315 8.44693L7.60355 3.40122C7.35264 3.27968 7.07093 3.23693 6.79525 3.27848C6.51957 3.32004 6.26292 3.44402 6.05898 3.63412C5.85504 3.82422 5.71329 4.0715 5.6525 4.34359C5.59171 4.61567 5.61468 4.89979 5.71832 5.15861L7.53146 9.69097L5.71804 14.2239C5.61429 14.4828 5.59128 14.7671 5.65205 15.0393C5.71283 15.3115 5.85466 15.5591 6.05867 15.7494C6.26269 15.9396 6.51939 16.0636 6.79522 16.1052C7.07104 16.1468 7.353 16.104 7.60405 15.9824L18.0319 10.9349C18.1953 10.8557 18.3414 10.7452 18.462 10.6096C18.5827 10.474 18.6755 10.316 18.735 10.1445C18.7945 9.97306 18.8196 9.79146 18.809 9.61026C18.7984 9.42907 18.7522 9.25182 18.673 9.08849L18.6733 9.08871ZM7.20267 15.152C7.11901 15.1925 7.02502 15.2067 6.9331 15.1929C6.84118 15.179 6.75561 15.1376 6.68762 15.0742C6.61963 15.0108 6.57248 14.9284 6.55222 14.8377C6.53197 14.747 6.53955 14.6522 6.57411 14.5659L8.33862 10.1513L17.5346 10.1511L7.20267 15.152ZM8.33948 9.23011L6.57506 4.81547C6.54085 4.72918 6.5335 4.63455 6.55401 4.54402C6.57451 4.45349 6.62188 4.37126 6.68994 4.30814C6.758 4.24502 6.84357 4.20395 6.93539 4.19031C7.0272 4.17667 7.12105 4.19114 7.20452 4.23175L17.5375 9.23008L8.34152 9.2303L8.33948 9.23011Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1994_4167">
          <rect width="13.7065" height="13.7072" fill="white" transform="translate(9.69238) rotate(45)" />
        </clipPath>
      </defs>
    </Icon>
  )
}

export default SendMessage
