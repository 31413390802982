type ChatInfo = {
  name: string
  message: string
  date: string
  image: string
  isUserMessage?: boolean
}

export default function BubbleChat({ name, date, image, message, isUserMessage }: ChatInfo) {
  let backgroundColor = '#E90970'
  let textColor = 'white'
  let nameColor = 'white'
  let direction = 'mr-auto'
  let border = 'rounded-3xl rounded-bl-none border-none'

  if (isUserMessage) {
    backgroundColor = '#FFFFFF'
    textColor = '#7E7E7E'
    nameColor = 'black'
    direction = 'ml-auto'
    border = 'rounded-3xl rounded-br-none'
  }

  return (
    <div className={`flex lg:mt-[95px] mt-[90px] xl:mt-[117px] pb-12 gap-x-2 ${direction}`}>
      <div className={`flex flex-col gap-y-1 ${!isUserMessage && 'order-2'}`}>
        <div
          style={{ backgroundColor }}
          className={`border border-black h-[120px] flex flex-col gap-y-1 -mt-20  ${border} text-[${textColor}] text-sm w-[269px] lg:w-[269px] xl:w-[289px] lg:px-4 xl:px-6 px-4 py-4 text-[#313131]`}
        >
          <h1 className={`font-bold text-[${nameColor}]`}>{name}</h1>
          <p className="font-[500px]">{message}</p>
        </div>
        <p className="text-[11px] hidden xl:black lg:black md:block font-semibold ml-auto mr-8 capitalize text-[#7E7E7E]">
          {date}
        </p>
      </div>
      <img
        src={image}
        alt=""
        className="w-[69.3px] h-[67.76px] object-cover rounded-full xl:lg:md:border-4 dark:border-2 dark:border-[#D9D9D9] xl:lg:md:border-[#FFFFFF] border-[#FFFFFF]"
      />
    </div>
  )
}
