import { motion } from 'framer-motion'
import SectionSlate from '../../../common/SectionSlatePreview'
import { TextSection as TextSectionType } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

function VariantEleven({ section }: { section: TextSectionType }) {
  const viewVariants = {
    offscreen: {
      y: 300,
    },
    onscreen: {
      y: 0,
      transition: {
        duration: 0.5,
        delay: 0.2,
        ease: [0, 0.71, 0.2, 1.01],
      },
    },
  }

  const viewport = { once: true, amount: 0.5 }
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data

  return (
    <>
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={viewport}
        style={{
          ...generateSectionBackground({
            defaultColor: '#E4E1DF',
            backgroundSettings: section?.settings?.color?.background,
            image: null,
          }),
          ...generateSectionSpacing(section?.settings?.spacing),
        }}
      >
        <motion.div variants={viewVariants} className="py-4 md:py-12 px-4 md:px-12 ">
          <div className=" space-y-2 md:space-y-4">
            <button className={`border border-black px-[10px] py-px rounded-lg`}>
              <SectionSlate initialValue={title} previewMode />
            </button>
            <p className="md:text-xl lg:pr-4">
              <SectionSlate initialValue={paragraph} previewMode />
            </p>
          </div>
        </motion.div>
      </motion.div>
    </>
  )
}
export default VariantEleven
