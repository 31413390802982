import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { ContactUsSection, ContractSectionContract } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

function VariantThirteen({ section }: { section: ContactUsSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const contracts = section.variables.find(variable => variable.name === 'CONTRACT')?.data
  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: '#262525',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
      className="py-12 md:py-24"
    >
      <div className="grid md:grid-cols-2 text-[#bebebe] px-6 md:px-12 space-y-4">
        <div>
          <h2 className="text-3xl mt-8 md:text-[32px] uppercase font-neuton">
            <SectionSlatePreview initialValue={title} previewMode />
          </h2>
        </div>
        <div>
          <p className="md:text-2xl font-neuton leading-none md:max-w-[485px]">
            <SectionSlatePreview initialValue={paragraph} previewMode />
          </p>
          <div className="pt-12 md:pt-20 font-neuton space-y-4 md:space-y-8">
            {contracts?.map((item: ContractSectionContract) => (
              <div key={item?.id}>
                {/* <h5 className="text-[16px] uppercase">{item?.name}</h5>
                <a className="text-[24px] md:text-[36px]">{item?.description} </a> */}
                <h5 className="md:text-[16px] uppercase">
                  <SectionSlatePreview initialValue={item.name} previewMode />
                </h5>
                <p className="text-[14px] md:text-[30px]">
                  <SectionSlatePreview initialValue={item.description} previewMode />
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default VariantThirteen
