import { useState } from 'react'
import { useForm } from 'react-hook-form'
import InputText from '../../../common/InputText'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import TextArea from '../../../common/TextArea'
import { ContactUsSection, ContractSectionContract, InputsContactUSSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantTen({ section }: { section: ContactUsSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subtitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const contact = section.variables.find(variable => variable.name === 'CONTRACT')?.data as ContractSectionContract[]
  const inputs = section.variables.find(variant => variant.name === 'INPUTS')?.data as InputsContactUSSection[]

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const [error, setError] = useState('')

  const onSubmit = async () => {
    try {
    } catch (error) {
      setError('Something went wrong!')
    }
  }

  const inputClasses = {
    classInput:
      ' placeholder:text-[#8080804c] placeholder:tracking-wide resize-none w-full md:w-[100%] border-b-2 border-gray-600 placeholder:capitalize md:py-3 bg-transparent border-b-3 outline-none placeholder:text-[12px]',
    classLabel: 'text-black inline-block my-3 md:my-0 ',
  }

  let indexIdentifier = 4

  return (
    <div
      className="bg-[#eee] lg:py-20 py-8 grid lg:grid-cols-2 lg:px-12 "
      style={{
        ...generateSectionBackground({
          defaultColor: '#eee',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className=" flex flex-col lg:w-[80%] px-4 md:px-12 justify-center">
        <div>
          <h1 className="lg:text-[2.8rem] text-3xl font-bold ">
            <SectionSlatePreview initialValue={title} previewMode />
            <div className=" h-1 bg-black lg:w-7 w-4 " />
          </h1>
          <p className="lg:text-2xl text-sm mt-4 lg:mt-6 lg:tracking-wide">
            <SectionSlatePreview initialValue={paragraph} previewMode />
          </p>
        </div>
        {contact && (
          <div className="lg:mt-8 lg:text-xl flex  lg:h-[110px] h-[80px] justify-center pt-6 flex-col gap-y-3">
            {contact.map(({ id, name, description }) => {
              return (
                <div key={id} className="flex gap-x-1 text-sm lg:text-2xl  items-center">
                  {/* <span className="lg:text-[24px] text-sm text-gray-900 font-bold">{name} </span>
                  <span className="text-[18px] font-light w-6/12">{description}</span> */}
                  <span className="lg:text-[24px] text-sm text-gray-900 font-bold">
                    <SectionSlatePreview initialValue={name} previewMode />
                  </span>
                  <span className="text-[18px] font-light w-6/12">
                    <SectionSlatePreview initialValue={description} previewMode />
                  </span>
                </div>
              )
            })}
          </div>
        )}
        <div className="flex text-gray-900 font-semibold items-center  gap-x-2 lg:h-[80px] h-[60px] lg:text-xl capitalize">
          <div className="bg-black lg:w-[10px] w-[10px] h-[1px]" />
          <p className="lg:text-2xl text-sm">
            <SectionSlatePreview initialValue={subtitle} previewMode />
          </p>
        </div>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="xl:px-8 md:px-2 py-4 xl:py-12 xl:w-[90%] md:mt-0 mt-2  justify-center items-center px-6"
      >
        {inputs && (
          <div className={`text-sm  lg:grid gap-12 `}>
            {inputs.map(({ type, name, error, id, placeholder }, idx) => {
              if (idx === indexIdentifier) {
                if (indexIdentifier % 9 == 0) {
                  indexIdentifier += 2
                } else {
                  indexIdentifier += 1
                }

                return (
                  <div key={id} className="col-span-2 ">
                    {type !== 'button' && type !== 'textarea' && (
                      <InputText
                        {...inputClasses}
                        label={name}
                        name={name}
                        register={register}
                        type={type}
                        errors={errors}
                        placeholder={placeholder}
                        required={{ value: true, message: `${error}` }}
                      />
                    )}

                    {type === 'button' && (
                      <div className="w-full col-span-2 ">
                        <input
                          type={type}
                          value={name}
                          placeholder={placeholder}
                          className="px-5 py-3 font-medium bg-black text-white cursor-pointer"
                        />
                      </div>
                    )}
                    {type === 'textarea' && (
                      <div>
                        <TextArea
                          {...inputClasses}
                          label={name}
                          name={name}
                          placeholder={placeholder}
                          register={register}
                          type={type}
                          required={{ value: true, message: `${error}` }}
                          errors={errors}
                        />
                      </div>
                    )}
                  </div>
                )
              } else {
                return (
                  <div key={id}>
                    {type !== 'button' && type !== 'textarea' && (
                      <div className="w-full">
                        <InputText
                          errors={errors}
                          {...inputClasses}
                          label={name}
                          name={name}
                          register={register}
                          type={type}
                          placeholder={placeholder}
                          required={{ value: true, message: `${error}` }}
                        />
                      </div>
                    )}

                    {type === 'button' && (
                      <div className="w-full">
                        <input
                          placeholder={placeholder}
                          type={type}
                          value={name}
                          // placeholder={placeholder}
                          className="px-5 py-3 font-medium bg-black text-white cursor-pointer"
                        />
                      </div>
                    )}
                    {type === 'textarea' && (
                      <div className="col-span-2">
                        <TextArea
                          {...inputClasses}
                          placeholder={placeholder}
                          label={name}
                          name={name}
                          register={register}
                          type={type}
                          errors={errors}
                          required={{ value: true, message: `${error}` }}
                        />
                      </div>
                    )}
                  </div>
                )
              }
            })}
          </div>
        )}

        {error && <span className="text-secondary.main text-center mb-4">{error}</span>}
      </form>
    </div>
  )
}
