import { Template } from '.'
import { blog, contactUs, faq, gallery, love, quote, team, text } from './variants'

export const Template04: Template = {
  id: '04',
  pages: [
    {
      slug: '/',
      name: 'Home',
      blocks: [
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_8')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#f5f5f5', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  children: [
                    {
                      fontFamily: 'Playfair Display',
                      color: '#000000',
                      className: 'uppercase',
                      text: 'Hello IAm, ',
                    },
                    {
                      fontFamily: 'Playfair Display',
                      color: '#8f7f4f',
                      className: 'uppercase',
                      text: 'HomeDot.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '1e2b1704-3098-4b35-822b-d128e31d1a34',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#635c5c',
                      text: 'Captivating User Experience, B2B Focused',
                      bold: true,
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '962649b1-4bbf-42a2-bc52-b979e21ed8cc',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_8',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_1')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  children: [
                    {
                      color: '#000000',
                      text: 'Snatched Crowdfunding UX',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: 'd8bec99d-167a-4b79-9751-30d5b58f3861',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#000000',
                      text: 'Bootstrap Your UX Design',
                      bold: true,
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '69437424-2f7c-4323-9151-8efdcc664cae',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: '',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '4467188a-e74e-4b26-a43f-355353674b28',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          type: 'TEXT',
        },
        {
          settings: {
            ...gallery.find(i => i.variant === 'VARIANT_4')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1501183638710-841dd1904471?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxob21lfGVufDB8fHx8MTY3NzY2MTU4Nw&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [
                    {
                      children: [
                        {
                          text: 'Dope Burn Rate',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1556020685-ae41abfc9365?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxob21lfGVufDB8fHx8MTY3NzY2MTU4Nw&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [
                    {
                      children: [
                        {
                          text: 'Dope Burn Rate',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1567016376408-0226e4d0c1ea?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxMHx8aG9tZXxlbnwwfHx8fDE2Nzc2NjE1ODc&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [
                    {
                      children: [
                        {
                          text: 'Dope Burn Rate',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1484154218962-a197022b5858?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxob21lfGVufDB8fHx8MTY3NzY2MTU4Nw&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [
                    {
                      children: [
                        {
                          text: 'Dope Burn Rate',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1507494924047-60b8ee826ca9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxsaWdodHN8ZW58MHx8fHwxNjc3NjYxNzEy&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [
                    {
                      children: [
                        {
                          text: 'Dope Burn Rate',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1529704193007-e8c78f0f46f9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw2fHxsaWdodHxlbnwwfHx8fDE2Nzc2NjE3MzE&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [
                    {
                      children: [
                        {
                          text: 'Dope Burn Rate',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
              ],
              name: 'MEDIA',
              id: '21a4bcd3-0f39-42f2-95e0-10deddf30197',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_4',
          type: 'GALLERY',
        },
        {
          settings: {
            ...faq.find(i => i.variant === 'VARIANT_1')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 60, left: 0, bottom: 60, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#f3f3f3', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1542728928-1413d1894ed1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxsYW1wfGVufDB8fHx8MTY3NzY3ODkzMQ&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  description: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [
                    {
                      children: [
                        {
                          fontFamily: 'Playfair Display',
                          color: '#000000',
                          text: 'Dope Burn ',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  items: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1505693416388-ac5ce068fe85?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwyfHxiZWR8ZW58MHx8fHwxNjc3Njc4OTQ4&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  description: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [
                    {
                      children: [
                        {
                          fontFamily: 'Playfair Display',
                          color: '#000000',
                          text: 'Dope Burn',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  items: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1486946255434-2466348c2166?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxMHx8cm9vbXxlbnwwfHx8fDE2Nzc2Nzg5NjQ&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  description: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [
                    {
                      children: [
                        {
                          fontFamily: 'Playfair Display',
                          color: '#000000',
                          text: 'Dope Burn',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  items: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1677339513775-733c9a8e9d1d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2Nzc2Nzg5Nzg&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  description: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [
                    {
                      children: [
                        {
                          fontFamily: 'Playfair Display',
                          color: '#000000',
                          text: 'Dope Burn',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  items: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
              ],
              name: 'ITEMS',
              id: '9dbca5e4-827a-4b46-8bff-64f57297d62d',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#707070',
                      text: 'UX Design for Crowdfunding Success',
                      bold: true,
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'db3c19d9-f10f-440c-81c2-11fc5b7541a1',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#000000',
                      text: '',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'ac6a2e00-c91d-4ed6-a456-69fc4dc4d273',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      fontFamily: 'Merriweather',
                      color: '#000000',
                      text: 'Bootstrap Your UX Design',
                      bold: true,
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '85d2d44c-4be6-4f6d-aa3c-c3db61baf222',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          type: 'FAQ',
        },
        {
          settings: {
            ...quote.find(i => i.variant === 'VARIANT_4')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  children: [
                    {
                      text: 'Paragraph',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'DESCRIPTION',
              id: 'ba253f34-8366-4ea1-a1ab-688ec7253f21',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1501183638710-841dd1904471?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxob21lfGVufDB8fHx8MTY3NzY2MTU4Nw&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: 'a80b2392-f864-4fdf-b65f-f60fa8bffcba',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      fontFamily: 'Playfair Display',
                      text: 'About Us ..',
                      bold: true,
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: 'ffa30d2d-ee4d-4dc0-a246-bd4ea65260cb',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'UX Design for Crowdfunding Success',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '27bc1699-003c-471d-bc98-a4576cfc3ba9',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_4',
          type: 'QUOTE_SECTION',
        },
        {
          settings: {
            ...faq.find(i => i.variant === 'VARIANT_8')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ededed', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1481567758055-3e8a6e89ca58?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxsb2dvJTIwZGFya3xlbnwwfHx8fDE2Nzc2OTE0MjY&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  description: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [
                    {
                      children: [
                        {
                          text: 'item name',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  items: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1557053964-937650b63311?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw4fHxsb2dvfGVufDB8fHx8MTY3NzY5MTMzMA&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  description: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [
                    {
                      children: [
                        {
                          text: 'item name',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  items: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1549924231-f129b911e442?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxsb2dvfGVufDB8fHx8MTY3NzY5MTMzMA&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  description: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [
                    {
                      children: [
                        {
                          text: 'item name',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  items: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1496200186974-4293800e2c20?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw3fHxsb2dvfGVufDB8fHx8MTY3NzY5MTMzMA&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  description: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [
                    {
                      children: [
                        {
                          text: 'item name',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  items: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1504805572947-34fad45aed93?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwyfHxsb2dvJTIwYmxhY2t8ZW58MHx8fHwxNjc3NjkxNDAz&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  description: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [
                    {
                      children: [
                        {
                          text: 'item name',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  items: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1503437313881-503a91226402?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw1fHxsb2dvJTIwZGFya3xlbnwwfHx8fDE2Nzc2OTE0MjY&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  description: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [
                    {
                      children: [
                        {
                          text: 'item name',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  items: [
                    {
                      children: [
                        {
                          text: 'item description',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
              ],
              name: 'ITEMS',
              id: '40dfd2b8-0591-4510-8d64-ebd3d903da27',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      fontFamily: 'Playfair Display',
                      color: '#000000',
                      text: 'UX Design for Crowdfunding Success',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '0d008656-cfb1-43ac-ae5e-00e2bdd3def7',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_8',
          type: 'FAQ',
        },
        {
          settings: {
            ...quote.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  children: [
                    {
                      text: 'Paragraph',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'DESCRIPTION',
              id: 'ca0f281f-eb19-49b1-a9a1-e5f8d97d4125',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
              name: 'MEDIA',
              id: '92463250-5df5-42fb-bce9-612f7af02abf',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      fontFamily: 'Playfair Display',
                      text: 'Testimonials',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '1974a1d0-ebe1-4b90-87c4-6ad45ba7fc3a',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#7e7e7e',
                      text: 'UX Design for Crowdfunding Success',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '7e361240-0946-43a8-b4fd-8b12409412e8',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'QUOTE_SECTION',
        },
        {
          settings: {
            ...quote.find(i => i.variant === 'VARIANT_8')?.settings,

            spacing: {
              padding: { top: 30, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  children: [
                    {
                      text: 'Title',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: 'a867247f-382a-455f-b5f7-c8d784694cc3',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
              name: 'MEDIA',
              id: '69d8198b-3232-4f2c-a66f-7c5419e617dd',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Akira Hathaway',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '8e003dc5-5024-4b58-8c00-2b41ddec1df7',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Great Trying and Result',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'DESCRIPTION',
              id: 'f11120a4-175c-4101-9973-b8cd068416a6',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_8',
          type: 'QUOTE_SECTION',
        },
      ],
    },
    {
      slug: '/services',
      name: 'Services',
      blocks: [
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_14')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 60, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#d6d6d6', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  children: [
                    {
                      text: 'Paragraph',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'fb522443-983a-4a29-9ad1-e74dc6e11f66',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1483728642387-6c3bdd6c93e5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxtb3VudGFpbnxlbnwwfHx8fDE2NzIzMjQ3NDc&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '5dd4a313-880d-4c8b-995f-e6b3dfd4fda7',
              category: 'IMAGE',
            },
            {
              data: 0,
              name: 'TRANSITION',
              id: '67c43030-b078-4bde-aa2e-6e96ff554172',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#1d0e0e',
                      text: '',
                      bold: true,
                    },
                  ],
                  type: 'paragraph',
                },
                {
                  children: [
                    {
                      fontFamily: 'Noto Serif',
                      color: '#010101',
                      bold: true,
                      text: 'What ',
                    },
                    {
                      fontFamily: 'Noto Serif',
                      color: '#8c764b',
                      bold: true,
                      text: 'We',
                    },
                    {
                      fontFamily: 'Noto Serif',
                      color: '#1d0e0e',
                      bold: true,
                      text: ' ',
                    },
                    {
                      fontFamily: 'Noto Serif',
                      color: '#000000',
                      bold: true,
                      text: 'Do ',
                    },
                    {
                      fontFamily: 'Noto Serif',
                      color: '#1d0e0e',
                      bold: true,
                      text: '...',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '67f1e3c7-226c-46cb-9845-64e79dc194af',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      highlightColor: '#d0d0d0',
                      color: '#000000',
                      text: 'Disruptive Design for Acquisition',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'af74ec2d-eacf-4520-aa74-64783e987723',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_14',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...quote.find(i => i.variant === 'VARIANT_6')?.settings,
          },
          variables: [
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'DESCRIPTION',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
              name: 'MEDIA',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'Captivating UX for Startups' }], type: 'paragraph' }],
              name: 'TITLE',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ text: '' }], type: 'paragraph' },
                {
                  children: [
                    {
                      text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                    },
                  ],
                  type: 'paragraph',
                },
                { children: [{ text: '' }], type: 'paragraph' },
              ],
              name: 'SUBTITLE',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          id: 'ab7c069c-f884-40c8-8742-d27f1ac0d048',
          type: 'QUOTE_SECTION',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_4')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 30, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  children: [
                    {
                      text: 'Paragraph',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'de4db827-917e-48c1-8384-dd808a41e1f3',
              category: 'TEXT',
            },
            {
              data: {
                top: 20,
                left: 15,
                bottom: 10,
                right: 25,
              },
              name: 'MARGIN',
              id: 'f3589c55-029f-4b7a-88ba-3a875a705772',
              category: 'TEXT',
            },
            {
              data: {
                top: 20,
                left: 15,
                bottom: 10,
                right: 25,
              },
              name: 'PADDING',
              id: '29ca98c0-576d-465f-8304-3889b0701abc',
              category: 'TEXT',
            },
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [
                    {
                      children: [
                        {
                          text: 'loream iteam',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [
                    {
                      children: [
                        {
                          text: 'loream iteam',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [
                    {
                      children: [
                        {
                          text: 'loream iteam',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
              ],
              name: 'MEDIAGROUP',
              id: '002736c8-22a3-472b-b120-84371a90bcdb',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: '',
                      bold: true,
                    },
                  ],
                  type: 'paragraph',
                },
                {
                  children: [
                    {
                      bold: true,
                      text: 'Snatched Crowdfunding UX',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '676a5bc1-2636-4f20-b826-a371a654982f',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Captivating UX for Startups',
                    },
                  ],
                  type: 'paragraph',
                },
                {
                  children: [
                    {
                      text: '',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '7d144800-7a69-4307-b15c-8426b403da4b',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1505691723518-36a5ac3be353?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw1fHxob21lfGVufDB8fHx8MTY3NzY2MTU4Nw&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '0ea2ac27-c58f-440c-9ced-aefeca50c4e5',
              category: 'IMAGE',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#333333',
                  backgroundColor: '#d9d9d9',
                  color: '#000000',
                  textAlign: 'center',
                  display: 'block',
                  bold: false,
                  borderRadius: '25px',
                  borderWidth: '1px',
                  width: '249px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'more',
              },
              name: 'BUTTON_1',
              id: '750141cc-e1bf-4681-b3ee-8267e2a8acec',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_4',
          type: 'TEXT',
        },
        {
          settings: {
            ...team.find(i => i.variant === 'VARIANT_4')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#f8f8f8', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw4fHxtYW58ZW58MHx8fHwxNjc3Njk4Mjkw&ixlib=rb-4.0.3&q=80&w=1080',
                  subtitle1: [{ children: [{ text: 'Position/Role' }], type: 'paragraph' }],
                  subtitle: [
                    {
                      children: [
                        {
                          fontFamily: 'Playfair Display',
                          color: '#c88f47',
                          text: 'UX Design for Crowdfunding Success',
                          bold: true,
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  name: [
                    {
                      children: [{ fontFamily: 'Playfair Display', text: 'Amara Henson', bold: true }],
                      type: 'paragraph',
                    },
                  ],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  name1: [{ children: [{ text: 'Taylor Quill' }], type: 'paragraph' }],
                  description1: [{ children: [{ text: '#developer #expert #designer' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1557862921-37829c790f19?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxtYW58ZW58MHx8fHwxNjc3Njk4Mjkw&ixlib=rb-4.0.3&q=80&w=1080',
                  subtitle1: [{ children: [{ text: 'Position/Role' }], type: 'paragraph' }],
                  subtitle: [
                    {
                      children: [{ color: '#ae8967', text: 'UX Design for Crowdfunding Success', bold: true }],
                      type: 'paragraph',
                    },
                  ],
                  name: [
                    {
                      children: [{ fontFamily: 'Playfair Display', text: 'Anthony Wahlberg', bold: true }],
                      type: 'paragraph',
                    },
                  ],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  name1: [{ children: [{ text: 'Taylor Quill' }], type: 'paragraph' }],
                  description1: [{ children: [{ text: '#developer #expert #designer' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1581382575275-97901c2635b7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxtYW58ZW58MHx8fHwxNjc3Njk4Mjkw&ixlib=rb-4.0.3&q=80&w=1080',
                  subtitle1: [{ children: [{ text: 'Position/Role' }], type: 'paragraph' }],
                  subtitle: [
                    {
                      children: [
                        {
                          fontFamily: 'Playfair Display',
                          color: '#a88255',
                          text: 'UX Design for Crowdfunding Success',
                          bold: true,
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  name: [
                    {
                      children: [{ fontFamily: 'Playfair Display', text: 'Charles McConaughey', bold: true }],
                      type: 'paragraph',
                    },
                  ],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  name1: [{ children: [{ text: 'Taylor Quill' }], type: 'paragraph' }],
                  description1: [{ children: [{ text: '#developer #expert #designer' }], type: 'paragraph' }],
                },
              ],
              name: 'TEAM_MEMBERS',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Playfair Display', text: 'Meet Our Team', bold: true }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: '' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_4',
          type: 'TEAM_MEMBERS',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1501183638710-841dd1904471?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxob21lfGVufDB8fHx8MTY3NzY2MTU4Nw&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ text: 'UX Design for Crowdfunding Success' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1586023492125-27b2c045efd7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxob21lfGVufDB8fHx8MTY3NzY2MTU4Nw&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ text: 'UX Design for Crowdfunding Success' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1505691723518-36a5ac3be353?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw1fHxob21lfGVufDB8fHx8MTY3NzY2MTU4Nw&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ text: 'UX Design for Crowdfunding Success' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                },
              ],
              name: 'MEDIAGROUP',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'UX Design for Crowdfunding Success', bold: true }], type: 'paragraph' }],
              name: 'TITLE',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'TEXT',
        },
      ],
    },
    {
      slug: '/blog',
      name: 'Blog',
      blocks: [
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#E7E7E7', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  description: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'Investor-Focused UX Design',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: 'c8377ead-aefd-4847-8835-be7e27643a78',
                  title: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'YOLO Startup Design',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  description: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'B2B UX Design for Investors',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: 'c4d92d65-e0ab-4d12-bfea-6d0f882055a9',
                  title: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'OMG User Experience',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  description: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'Bootstrap Your UX Design',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '734bf623-209f-4666-98ba-9a6db01057b3',
                  title: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'Thirsty Cash Flow',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  description: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'Accelerator-Approved UX Design',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: 'c7342104-4438-434f-b4fd-63e6d824d54f',
                  title: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'GOAT Acquisition UX',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  description: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'B2B UX Design for Investors',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: 'c7326704-414d-43f6-ab1e-5b77a1b92d17',
                  title: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'OMG User Experience',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  description: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'Bootstrap Your UX Design',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: 'e84b20f9-db64-44ea-bd31-fd75d9dfd3ff',
                  title: [
                    {
                      children: [
                        {
                          color: '#1f0f0f',
                          text: 'Thirsty Cash Flow',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  description: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'Accelerator-Approved UX Design',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2d8d1ad3-5190-4a21-bff0-5b4dfd02b358',
                  title: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'GOAT Acquisition UX',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
              ],
              name: 'ITEMS',
              id: '58ccd2f9-22df-46e8-90ae-a9520e612b43',
              category: 'IMAGE',
            },
          ],
          variant: 'VARIANT_6',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...blog.find(i => i.variant === 'VARIANT_5')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  date: '1 MAY 2016',
                  image:
                    'https://images.unsplash.com/photo-1675453988015-304184957af2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2Nzc3MDA1NzM&ixlib=rb-4.0.3&q=80&w=1080',
                  texts: [
                    {
                      children: [
                        {
                          text: "Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience? Look no further than our custom site and portfolio design services. Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. Whether you're a digital nomad or a disruptive technology startup, we have the expertise to optimize your website for exceptional user experience on any device.",
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  name: [{ children: [{ text: 'Lorem ipsum' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Apple pie gummies dessert muffin soufflé. Halvah dessert soufflé cheesecake halvah carrot cake tiramisu topping marshmallow. Powder biscuit liquorice croissant tootsie roll tiramisu jujubes. Toffee sweet roll carrot cake dessert cheesecake marzipan gummies. Donut apple pie biscuit tart chocolate bar tiramisu candy canes halvah bonbon Apple pie tart toffee cookie topping cake. Toffee wafer danish chocolate cake caramels jelly dragée.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [{ children: [{ text: 'Burn Rate Buster UX Design' }], type: 'paragraph' }],
                },
              ],
              name: 'ITEMS',
              category: 'IMAGE',
            },
          ],
          variant: 'VARIANT_5',
          type: 'BLOG',
        },
        {
          settings: {
            ...blog.find(i => i.variant === 'VARIANT_5')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  date: '1 MAY 2016',
                  image:
                    'https://images.unsplash.com/photo-1603673329776-28248af53fc0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDl8fG9mZmljZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
                  texts: [
                    {
                      children: [
                        {
                          text: "Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience? Look no further than our custom site and portfolio design services. Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. Whether you're a digital nomad or a disruptive technology startup, we have the expertise to optimize your website for exceptional user experience on any device.",
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  name: [{ children: [{ text: 'Lorem ipsum' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Apple pie gummies dessert muffin soufflé. Halvah dessert soufflé cheesecake halvah carrot cake tiramisu topping marshmallow. Powder biscuit liquorice croissant tootsie roll tiramisu jujubes. Toffee sweet roll carrot cake dessert cheesecake marzipan gummies. Donut apple pie biscuit tart chocolate bar tiramisu candy canes halvah bonbon Apple pie tart toffee cookie topping cake. Toffee wafer danish chocolate cake caramels jelly dragée.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [{ children: [{ text: 'UX Design for Crowdfunding Success' }], type: 'paragraph' }],
                },
              ],
              name: 'ITEMS',
              category: 'IMAGE',
            },
          ],
          variant: 'VARIANT_5',
          type: 'BLOG',
        },
      ],
    },
    {
      slug: '/contact-us',
      name: 'Contact Us',
      blocks: [
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_4')?.settings,
          },
          variables: [
            {
              data: [{ children: [{ text: 'Get In Touch' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '135e0367-4e12-4dde-9bc3-fe20415e9a9c',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Burn Rate Buster UX Design' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '7d98a66f-4353-4318-921e-be108fcef5ed',
              category: 'TEXT',
            },
            {
              data: [
                {
                  name: 'First Name',
                  id: '631ae0e6-219d-4c4c-816a-8edb0c685699',
                  placeholder: 'enter your first name',
                  type: 'text',
                  error: 'First Name is required',
                },
                {
                  name: 'last Name',
                  id: 'cbbd1379-95eb-4bb8-9a96-ffd108c0cad8',
                  placeholder: 'enter your last name',
                  type: 'text',
                  error: 'last Name is required',
                },
                {
                  name: 'Email',
                  id: 'e12b8fb4-e748-473d-b388-1cf4de456692',
                  placeholder: 'enter your email',
                  type: 'email',
                  error: 'email is required',
                },
                {
                  name: 'Message',
                  id: 'f0522872-f985-4ffa-b4e1-e49b9bb87f46',
                  placeholder: 'enter your message',
                  type: 'textarea',
                  error: 'message is required',
                },
              ],
              name: 'INPUTS',
              id: '51d36cd9-b54d-45eb-b815-705a6fdb05d3',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  backgroundColor: '#978246',
                  color: '#ffffff',
                  borderRadius: '25px',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'send',
              },
              name: 'BUTTON_1',
              id: '7a81b60b-1946-4b79-ad66-ad3894a82105',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_4',
          type: 'CONTACT_US',
        },
      ],
    },
  ],

  newMenu: [
    { name: 'Home', type: 'internal', url: '/' },
    { name: 'Services', type: 'internal', url: '/services' },
    { name: 'Blog', type: 'internal', url: '/blog' },
    { name: 'Contact us', type: 'internal', url: '/contact-us' },
  ],
  newMenuConfig: {
    links: [
      {
        name: 'Home',
        type: 'internal',
        url: '/',
      },
      {
        name: 'Contact us',
        type: 'email',
        url: 'Email',
      },
    ],
    id: 'VARIANT_6',
    settings: { backgroundColor: { color: '#B7B4A2', enabled: true }, textColor: { color: '#000000', enabled: true } },

    tags: [],
  },
  newFooter: {
    designedBy: 'Plly Staff',
    id: 'VARIANT_4',
    categories: [
      {
        name: 'Explore',
        links: [{ name: 'Home', type: 'internal', url: '/' }],
      },
    ],
    tags: [],
  },
  links: [],
}
