import { NewMenu } from './menu'

export function getBackgroundColor({
  settings,
  defaultValue,
}: {
  // @ts-ignore
  settings?: NewMenu['settings']['backgroundColor']
  defaultValue: string
}) {
  let color = defaultValue
  if (settings?.enabled) color = settings.color
  return {
    backgroundColor: color,
  }
}

export function getTextColor({
  settings,
  defaultValue,
}: {
  // @ts-ignore
  settings?: NewMenu['settings']['textColor']
  defaultValue: string
}) {
  let color = defaultValue
  if (settings?.enabled) color = settings.color
  return {
    color,
  }
}

// @ts-ignore
export function getSpacing(spacing: NewMenu['settings']['spacing']) {
  if (!spacing?.enabled) return {}

  const { padding, margin } = spacing
  return {
    padding: `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`,
    margin: `${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px`,
  }
}

export function getFontFamily({
  settings,
  defaultValue,
}: {
  // @ts-ignore
  settings?: NewMenu['settings']['fontFamily']
  defaultValue: string
}) {
  let family = defaultValue
  if (settings?.enabled) family = settings.font
  return {
    fontFamily: family,
  }
}
