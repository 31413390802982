import Icon from './icons'
import { IconPropsT } from './types'

function SearchMenu(props: IconPropsT) {
  const { title = 'Search', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 30 30" size={size} title={title} {...otherProps}>
      <path
        d="M22.3536 21.3542C24.4678 18.978 25.5729 15.871 25.4342 12.693C25.2956 9.51505 23.9241 6.51624 21.6109 4.33327C19.2978 2.15029 16.2251 0.955048 13.0452 1.00129C9.86539 1.04754 6.82875 2.33163 4.58002 4.58095C2.33128 6.83027 1.04753 9.8677 1.00129 13.0484C0.95506 16.2291 2.14999 19.3025 4.3324 21.6163C6.5148 23.9301 9.51283 25.3019 12.69 25.4406C15.8672 25.5793 18.9733 24.4739 21.3489 22.3591L27.7812 28.7931C27.8473 28.8597 27.9261 28.9123 28.0129 28.9479C28.0998 28.9834 28.1928 29.0011 28.2866 28.9999C28.4755 28.9989 28.6566 28.9248 28.792 28.7931C28.9252 28.6598 29 28.4791 29 28.2907C29 28.1022 28.9252 27.9215 28.792 27.7882L22.3536 21.3542ZM2.45475 13.2443C2.45475 11.1116 3.087 9.02683 4.27155 7.25357C5.4561 5.4803 7.13974 4.09821 9.10958 3.28206C11.0794 2.46592 13.247 2.25238 15.3381 2.66845C17.4293 3.08451 19.3501 4.1115 20.8578 5.61954C22.3654 7.12758 23.3922 9.04894 23.8081 11.1406C24.2241 13.2324 24.0106 15.4005 23.1947 17.3708C22.3787 19.3412 20.997 21.0253 19.2242 22.2101C17.4514 23.395 15.3671 24.0274 13.235 24.0274C10.377 24.0237 7.63714 22.8865 5.61624 20.8651C3.59534 18.8436 2.45839 16.103 2.45475 13.2443Z"
        // fill="black"
        // strokeWidth="0.6"
      />
    </Icon>
  )
}

export default SearchMenu
