import Icon from './icons'
import { IconPropsT } from './types'

function Notification(props: IconPropsT) {
  const { title = 'Notification', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 33 41" size={size} title={title} {...otherProps}>
      <path
        d="M25.4036 32.7184V32.7183H25.3943L7.25977 32.7183L7.25083 32.7184C3.13669 32.7674 1.58057 31.446 1.0384 30.2257C0.750313 29.5773 0.698815 28.8627 0.792847 28.1985C0.887803 27.5278 1.12381 26.9616 1.34711 26.6327L1.3622 26.6105L1.37566 26.5872C2.6621 24.365 3.25606 21.8097 3.082 19.2484C3.16868 16.1308 4.1905 13.1108 6.01524 10.5808C7.84683 8.04133 10.4021 6.11363 13.347 5.04982L13.8422 4.87094V4.34444C13.8422 2.33014 14.456 1.4985 14.9659 1.13353C15.5171 0.738974 16.1607 0.731773 16.4705 0.759657L16.5392 0.765833L16.6078 0.759402C16.8975 0.732241 17.4929 0.73907 18.0043 1.1233C18.4831 1.48302 19.0734 2.31681 19.0734 4.34444V4.85736L19.5514 5.04338C22.4209 6.16008 24.8887 8.1131 26.6348 10.6492C28.3757 13.1778 29.3192 16.1697 29.344 19.2391C29.1737 21.7869 29.7341 24.3307 30.9597 26.5714L30.9729 26.5955L30.9879 26.6187C31.4405 27.3188 31.8392 28.8755 31.294 30.2027C30.795 31.4175 29.373 32.7198 25.6021 32.7208L25.4036 32.7184Z"
        stroke="#E90570"
        strokeWidth="1.5"
      />
      <path
        d="M15.954 39.2643H15.3964C14.3782 39.2174 13.391 38.8857 12.5489 38.3035C11.9241 37.8716 11.4 37.3164 11.0063 36.6763H20.665C20.3009 37.2979 19.8104 37.8411 19.2209 38.2684C18.3369 38.9092 17.2746 39.2574 16.1828 39.2643H15.954Z"
        stroke="#E90570"
        strokeWidth="1.5"
      />
    </Icon>
  )
}

export default Notification
