import Icon from './icons'
import { IconPropsT } from './types'

export default function NewMessageMenu(props: IconPropsT) {
  const { title = 'message', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 32 27" size={size} title={title} {...otherProps}>
      <path
        d="M22.1922 18.353H15.1132C14.988 18.353 14.8679 18.3033 14.7794 18.2148C14.6909 18.1263 14.6411 18.0062 14.6411 17.881C14.6411 17.7558 14.6909 17.6358 14.7794 17.5472C14.8679 17.4587 14.988 17.409 15.1132 17.409H22.1922C22.3174 17.409 22.4374 17.4587 22.5259 17.5472C22.6144 17.6358 22.6642 17.7558 22.6642 17.881C22.6642 18.0062 22.6144 18.1263 22.5259 18.2148C22.4374 18.3033 22.3174 18.353 22.1922 18.353ZM22.0372 8.79401L12.6161 18.215C12.5278 18.3033 12.4081 18.3529 12.2832 18.353H9.92316C9.79824 18.353 9.67845 18.3034 9.59012 18.215C9.50179 18.1267 9.45215 18.0069 9.45215 17.882V15.522C9.45228 15.3971 9.50191 15.2774 9.59015 15.189L19.0112 5.76801C19.4126 5.3666 19.957 5.14111 20.5247 5.14111C21.0923 5.14111 21.6367 5.3666 22.0381 5.76801C22.4396 6.16941 22.6651 6.71382 22.6651 7.28149C22.6651 7.84917 22.4396 8.39358 22.0381 8.79498L22.0372 8.79401Z"
        fill="#D9D9D9"
      />
      <path
        d="M28.5312 1.49985H3.58426C3.07181 1.49021 2.5765 1.68433 2.20712 2.03965C1.83774 2.39497 1.6245 2.88238 1.61426 3.39481V19.8158C1.6245 20.3283 1.83774 20.8157 2.20712 21.171C2.5765 21.5263 3.07181 21.7205 3.58426 21.7109H17.3703V24.8688C17.3737 25.0394 17.4447 25.2018 17.5677 25.3201C17.6907 25.4384 17.8556 25.5031 18.0262 25.4999C18.1552 25.5001 18.2816 25.4633 18.3903 25.3938L24.1313 21.7109H28.5312C29.0436 21.7205 29.5388 21.5263 29.9081 21.1709C30.2773 20.8156 30.4903 20.3282 30.5002 19.8158V3.39481C30.4903 2.88247 30.2773 2.39506 29.9081 2.03971C29.5388 1.68437 29.0436 1.49021 28.5312 1.49985V1.49985Z"
        strokeWidth="1.1"
      />
    </Icon>
  )
}
