import { Descendant } from 'slate'
import {
  Section,
  SectionTags,
  TESTIMONIALSectionItem,
  TESTIMONIALVariables,
  TESTIMONIALVariants,
} from 'src/_polly/components/src/sections'
import { v4 as uuid } from 'uuid'
import { DEFAULT_VARIANT_SETTINGS, getSectionVariants } from './get-variants'
const tags: Record<TESTIMONIALVariants, SectionTags[]> = {
  VARIANT_1: ['IMAGE', 'SLIDESHOW', 'ANIMATION'],
  VARIANT_2: ['IMAGE', 'SLIDESHOW', 'ANIMATION'],
}
export function getTESTIMONIALVariants() {
  const variants = getSectionVariants('TESTIMONIAL')
  const sections: Section<TESTIMONIALVariants, TESTIMONIALVariables>[] = []

  variants.forEach(variant => {
    const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Accelerate Appeal' }] }]
    const subtitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Tech wid, Bd' }] }]
    const subtitle2: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Apple pie gummi' }] }]
    const subtitle3: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Pudding biscuit pastry' }] }]
    const description: Descendant[] = [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Pastry shortbread muffin sesame snaps icing marzipan. Brownie sesame snaps candy canes chocolate cake donut bonbon powder cotton candy. Jelly sesame snaps gummies pudding cotton candy. Sesame snaps danish muffin sweet roll cheesecake cake wafer pastry. Icing fruitcake cookie cupcake cake muffin cheesecake bonbon pie. Wafer marshmallow gummi bears wafer halvah. Croissant jelly-o croissant gummi bears cake. Gingerbread icing dessert toffee lemon drops sweet roll jelly beans.',
          },
        ],
      },
    ]
    const description2: Descendant[] = [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Apple pie apple pie gummi bears shortbread liquorice shortbread gummi bears jujubes sugar plum. Sweet roll biscuit tiramisu muffin sesame snaps lollipop. Sweet lemon drops fruitcake apple pie bonbon topping tiramisu cupcake chocolate. Cake caramels bonbon sugar plum candy canes macaroon. Jelly marzipan cupcake pudding candy. Candy cotton candy gingerbread chocolate cookie gingerbread bear claw.',
          },
        ],
      },
    ]
    const description3: Descendant[] = [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Pastry shortbread muffin sesame snaps icing marzipan. Brownie sesame snaps candy canes chocolate cake donut bonbon powder cotton candy. Jelly sesame snaps gummies pudding cotton candy. Sesame snaps danish muffin sweet roll cheesecake cake wafer pastry. Icing fruitcake cookie cupcake cake muffin cheesecake bonbon pie. Wafer marshmallow gummi bears wafer halvah. Croissant jelly-o croissant gummi bears cake. Gingerbread icing dessert toffee lemon drops sweet roll jelly beans.',
          },
        ],
      },
    ]
    if (variant.id === 'VARIANT_1') {
      const data: Section<TESTIMONIALVariants, TESTIMONIALVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TESTIMONIAL',
        updatedAt: '',
        tags: tags[variant.id as TESTIMONIALVariants],
        variables: [
          {
            id: uuid(),
            name: 'ITEMS',
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                title1: title,
                subtitle1: subtitle,
                description1: description,
                image:
                  'https://images.unsplash.com/photo-1615921511258-0aa98c84d400?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTY1fHxsb2dvfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
              },
              {
                id: uuid(),
                title1: title,
                subtitle1: subtitle2,
                description1: description2,
                image:
                  'https://images.unsplash.com/photo-1554730501-8dd4db2b18cc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nzd8fGxvZ298ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
              },
              {
                id: uuid(),
                title1: title,
                subtitle1: subtitle3,
                description1: description3,
                image:
                  'https://images.unsplash.com/photo-1579509330413-8a7e4addc442?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OTZ8fGxvZ298ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
              },
            ] as TESTIMONIALSectionItem[],
          },
        ],
        variant: variant.id as TESTIMONIALVariants,
        styleName: variant.name,
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else {
      const data: Section<TESTIMONIALVariants, TESTIMONIALVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TESTIMONIAL',
        updatedAt: '',
        tags: tags[variant.id as TESTIMONIALVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'VIDEO', data: 'w7ejDZ8SWv8', name: 'VIDEO' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
            name: 'MEDIA',
          },
          {
            id: uuid(),
            name: 'ITEMS',
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                title1: title,
                subtitle1: subtitle,
                description1: description,
                image:
                  'https://images.unsplash.com/photo-1615921511258-0aa98c84d400?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTY1fHxsb2dvfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
              },
              {
                id: uuid(),
                title1: title,
                subtitle1: subtitle,
                description1: description,
                image:
                  'https://images.unsplash.com/photo-1554730501-8dd4db2b18cc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nzd8fGxvZ298ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
              },
              {
                id: uuid(),
                title1: title,
                subtitle1: subtitle,
                description1: description,
                image:
                  'https://images.unsplash.com/photo-1579509330413-8a7e4addc442?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OTZ8fGxvZ298ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
              },
            ] as TESTIMONIALSectionItem[],
          },
        ],
        variant: variant.id as TESTIMONIALVariants,
        styleName: variant.name,
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    }
  })

  return sections
}
