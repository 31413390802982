import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { AttentionGrabberItemsGroup, AttentionGrabberSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantSix({ section }: { section: AttentionGrabberSection }) {
  const items = section.variables.find(variable => variable?.name === 'ITEMS')?.data as AttentionGrabberItemsGroup[]

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: '#111',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
        // backgroundImage: 'radial-gradient(#333, #111)',
        // backgroundSize: 'cover',
        fontWeight: 700,
        overflow: 'hidden',
      }}
      className="relative md:h-[600px] h-[300px] w-full overflow-hidden"
    >
      <div
        className="text-center text-white md:text-[4rem]  md:my-[40vh] mx-auto my-[150px] flex flex-col items-center justify-center uppercase"
        id="fly-in"
      >
        {items?.map(item => (
          <div id="fly-in-div" key={item?.id}>
            <span
              style={{
                display: 'block',
                fontSize: '.4em',
                opacity: '.8',
              }}
            >
              <SectionSlatePreview initialValue={item?.title} previewMode />
            </span>
            <SectionSlatePreview initialValue={item?.description} previewMode />
          </div>
        ))}
      </div>
    </div>
  )
}
