import FontLoader from '../../menu/FontLoader'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../../menu/utils'
import { FooterVariantProps, getSocialLabel, NewFooter, Social } from '../footer'

export default function VariantTwo({ website, footer }: FooterVariantProps) {
  return (
    <>
      <FontLoader settings={footer?.settings?.fontFamily} />
      <DesktopFooter website={website} footer={footer} />
      <MobileFooter website={website} footer={footer} />
    </>
  )
}

const SocialItem = ({ type, link, footer }: { type: Social; link: string; footer: NewFooter }) => {
  const label = getSocialLabel(type)
  return (
    <a
      style={{
        ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
        ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: '#ed4545' }),
        borderColor: getTextColor({ settings: footer?.settings?.textColor, defaultValue: '#ed4545' }).color,
      }}
      href={link}
      target="_blank"
      className="md:h-[80px] flex items-center justify-center text-center md:text-left text-2xl md:px-4 py-6 md:flex md:items-center md:w-[600px] border md:text-xl font-bold md:border-2 hover:text-white transition-all duration-300"
    >
      {label}
    </a>
  )
}

const DesktopFooter = ({ website, footer }: FooterVariantProps) => {
  console.log('phone is ', website?.config?.phone)

  return (
    <div
      className="flex-col justify-start border-t text-red-500  hidden md:flex"
      style={{
        ...getBackgroundColor({ settings: footer?.settings?.backgroundColor, defaultValue: 'black' }),
        borderColor: getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'red' }).color,
        ...getSpacing(footer?.settings?.spacing),
      }}
    >
      <div className="py-12 px-4 flex flex-col gap-y-2 ">
        <div className="px-2 ">
          {website?.config?.logo ? (
            <p
              className="font-serif capitalize text-4xl tracking-wider font-bold"
              style={{
                ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
                ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: '#ed4545' }),
              }}
            >
              {website?.name}
            </p>
          ) : (
            <p
              className="font-serif capitalize text-4xl tracking-wider font-bold"
              style={{
                ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
                ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: '#ed4545' }),
              }}
            >
              {website?.name}
            </p>
          )}
        </div>
        <div className="py-6">
          <div className="flex w-full">
            {website?.config?.social
              ? Object.keys(website?.config.social).map(item => (
                  <SocialItem type={item as Social} link={website.config.social[item]} footer={footer} />
                ))
              : footer?.social?.map(socialType => <SocialItem type={socialType} link="#" footer={footer} />)}
          </div>
        </div>
        <div
          className="flex justify-between gap-5 items-center px-2 text-xl font-semibold"
          style={{
            ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
            ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: '#ed4545' }),
          }}
        >
          <p>{website?.config?.copyright ? <p>{website?.config?.copyright}</p> : footer?.copyright}</p>
          <p>{website?.config?.phone ? <p>{website?.config?.phone} </p> : footer?.phone}</p>
        </div>
      </div>
    </div>
  )
}
const MobileFooter = ({ website, footer }: FooterVariantProps) => {
  return (
    <div
      style={{
        ...getBackgroundColor({ settings: footer?.settings?.backgroundColor, defaultValue: 'black' }),
        ...getSpacing(footer?.settings?.spacing),
        borderColor: getTextColor({ settings: footer?.settings?.textColor, defaultValue: '#ed4545' }).color,
      }}
      className="bg-black items-center justify-center flex-col text-red-500 flex md:hidden border-t-2 border-red-500 "
    >
      <div className="text-center pb-3 ">
        {website?.config?.logo ? (
          <p
            className="font-serif capitalize text-2xl tracking-wider font-bold"
            style={{
              ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
              ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: '#ed4545' }),
            }}
          >
            {website?.name}
          </p>
        ) : (
          <p
            className="font-serif capitalize text-2xl tracking-wider font-bold"
            style={{
              ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
              ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: '#ed4545' }),
            }}
          >
            {website?.name}
          </p>
        )}
      </div>
      <div className="flex  flex-col w-full px-4 ">
        {website?.config?.social
          ? Object.keys(website?.config.social).map(item => (
              <SocialItem type={item as Social} link={website.config.social[item]} footer={footer} />
            ))
          : ''}
      </div>
      <div
        className="flex flex-col w-full font-semibold px-4"
        style={{
          ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
          ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: '#ed4545' }),
        }}
      >
        {website?.config?.copyright ? website?.config?.copyright : footer?.copyright}
        <p
          className="border-b border-t-none py-6 w-full text-center flex items-center justify-center"
          style={{
            borderColor: getTextColor({ settings: footer?.settings?.textColor, defaultValue: '#ed4545' }).color,
          }}
        >
          {website?.config?.phone ? website?.config?.phone : footer?.phone}
        </p>
      </div>
    </div>
  )
}
