import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { TABLESection, TABLESectionItem } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantThree({ section }: { section: TABLESection }) {
  const tables = section.variables.find(variable => variable.name === 'ITEMS')?.data as TABLESectionItem[]

  return (
    <div
      style={{
        ...generateSectionSpacing(section.settings.spacing),
        ...generateSectionBackground({
          defaultColor: '#f8f2ea',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
      }}
      className=" lg:px-12 py-8 md:py-12"
    >
      <div className="grid md:grid-cols-3 gap-y-6 md:gap-x-12 px-6 md:px-8">
        {tables?.map(item => (
          <div key={item?.id} className="border-b pb-4 hover:border-[gray] cursor-pointer">
            <p className="text-[18px]">
              <SectionSlatePreview initialValue={item?.title} previewMode />
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}
