import clsx from 'clsx'
import isKhali from 'khali'
import { useContext, useEffect, useState } from 'react'
import { WebsiteContext } from 'src/components/context/WebsiteContext'
import { Loader } from 'src/components/loader'
import ModalDialogV2 from 'src/components/new/ModalDialogV2'
import Chat from 'src/icons/Chat'
import EditPlus from 'src/icons/EditPlus'
import ExpandIcon from 'src/icons/Hamburger'
import LinkIcon from 'src/icons/Link'
import Love from 'src/icons/Love'
import Network from 'src/icons/network'
import Save from 'src/icons/Save'
import Share from 'src/icons/Share'
import { getWebsiteByID, saveWebsite } from 'src/lib/services/website.service'
import useWebsite from 'src/store/website'
import { formatMenuLinks } from 'src/util/menu'
import { getMenuVariants, NewMenu as NewMenuType, NewMenuLink } from 'src/_polly/components/src/menu/menu'
import MenuPreviewRenderer from 'src/_polly/components/src/menu/MenuPreviewRenderer'
import { SectionPreviewRenderer } from 'src/_polly/components/src/sections'
import { EffectCoverflow } from 'swiper'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ReactComponent as PencilIcon } from '../../../icons/Pencil.svg'
import AdvancedMenuFooter from '../../advanced/AdvancedMenuFooter'
import { VariantIframe } from '../../NewSectionVariantSelection'
import MenuIframe from './MenuIframe'
import MenuLinks from './MenuLinks'

type NewMenuProps = {
  website: any
  firstSection?: any
  invalidate?: any
}

export default function NewMenu({ website, firstSection, invalidate }: NewMenuProps) {
  const websiteContext = useContext(WebsiteContext)
  const store = useWebsite(state => state)

  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [menuLinks, setMenuLinks] = useState<NewMenuLink[]>([])
  const [newMenuConfig, setNewMenuConfig] = useState<Omit<NewMenuType, 'links'> | null>(null)
  const [mode, setMode] = useState<'desktop' | 'mobile'>('desktop')
  const [loading, setLoading] = useState(false)
  const [previewItem, setPreviewItem] = useState<NewMenuType | null>(null)
  const [menuExpanded, setMenuExpanded] = useState(false)
  const [mainNavigation, setMainNavigation] = useState(false)
  const variants = getMenuVariants()

  const handleSelect = async (menu: NewMenuType) => {
    if (website?.newMenuConfig !== menu.id) {
      setLoading(true)
      await saveWebsite({ id: website.id, newMenuConfig: JSON.stringify(menu) })
    }

    setLoading(false)
    setNewMenuConfig(menu)
    setShowPreviewModal(false)
    setMainNavigation(false)
    invalidate?.()
    fetchWebsite()
  }

  // query received by website setting modal
  const url: any = document?.location
  const query: any = new URL(url)?.searchParams
  const webSetting = query?.get('webSetting')

  useEffect(() => {
    setMainNavigation(webSetting)
  }, [])

  const fetchWebsite = () => {
    getWebsiteByID(websiteContext.websiteID).then(_website => {
      setMenuLinks(JSON.parse(_website?.newMenu ?? '[]'))
      setNewMenuConfig(JSON.parse(_website?.newMenuConfig ?? '{}'))
    })
  }

  useEffect(() => {
    fetchWebsite()
  }, [website])

  return (
    <div>
      <div>
        <div className="flex items-center h-[46px] px-[5px] mt-[2px] border-t border-b border-black dark:border-[#D6D6D6]">
          <h2 className="w-full font-bold text-[13px] md:text-[15px] dark:text-white">Main Navigation</h2>
          <div className="w-full flex items-center gap-5 md:gap-[28px]">
            <button type="button" onClick={() => setShowPreviewModal(true)}>
              <PencilIcon className="hidden md:block" />
              <PencilIcon width={22} className="md:hidden block" />
            </button>
            <button type="button" onClick={() => setMainNavigation(true)}>
              <LinkIcon
                size={24}
                className="hidden md:block stroke-black fill-black dark:fill-white dark:stroke-white"
              />
              <LinkIcon
                size={20}
                className="block md:hidden stroke-black fill-black dark:fill-white dark:stroke-white"
              />
            </button>
            {store.website && (
              <AdvancedMenuFooter
                mode="icon"
                menu={{
                  ...store.website.newMenuConfig,
                  links: store.website.newMenu,
                }}
                component="menu"
                onBack={invalidate}
              />
            )}
          </div>
          <button type="button" onClick={() => setMenuExpanded(!menuExpanded)}>
            <ExpandIcon
              size={24}
              className="stroke-black hidden md:block fill-black dark:fill-white dark:stroke-white"
            />
            <ExpandIcon
              size={18}
              className="stroke-black block md:hidden fill-black dark:fill-white dark:stroke-white"
            />
          </button>
        </div>
        {menuExpanded && (
          <div className="pt-[20px] border-b border-black dark:border-[#D6D6D6]">
            <ul className="list-none p-0">
              {formatMenuLinks({ links: menuLinks, website }).map(link => (
                <li key={link.id}>
                  <a href={link.url} className="w-full font-bold pb-[20px] text-[15px] block dark:text-white">
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="mt-6">
          {newMenuConfig && !isKhali(newMenuConfig) && (
            <MenuPreviewRenderer
              menu={{
                ...newMenuConfig,
                links: formatMenuLinks({ links: menuLinks, website }),
              }}
              noShadow
              rounded
              stroke
              disableHover
              website={{
                ...website,
                config: JSON.parse(website?.config ?? '{}'),
              }}
            />
          )}
        </div>
      </div>

      <ModalDialogV2
        open={showPreviewModal}
        onBack={() => {
          setShowPreviewModal(false)
          setPreviewItem(null)
        }}
      >
        <div className="px-4">
          <div className="flex w-full justify-between items-center">
            <div className="absolute top-[21px] right-[30px]">
              <button
                type="button"
                className="border-[0.6px] border-[#0C0C0C] dark:border-white rounded-[5px] w-[24px] h-[32px]"
                onClick={() => setMode('mobile')}
              >
                <div
                  className={clsx(
                    'border-[0.6px] border-black dark:border-[#D9D9D9] rounded-[5px] w-[18px] h-[25px] mx-auto',
                    mode === 'mobile' && 'bg-[#D9D9D9]',
                  )}
                />
              </button>

              <button
                type="button"
                className="border-[0.6px] border-[#0C0C0C] dark:border-white rounded-[5px] w-[40px] h-[32px] ml-[10px]"
                onClick={() => setMode('desktop')}
              >
                <div
                  className={clsx(
                    'border-black dark:border-[#D9D9D9] border-[0.6px] rounded-[5px] w-[32px] h-[25px] mx-auto',
                    mode === 'desktop' && 'bg-[#D9D9D9]',
                  )}
                />
              </button>
            </div>
          </div>

          <Loader show={loading} />

          {previewItem ? (
            <>
              <div className="border border-black mt-[100px]">
                {mode === 'desktop' && (
                  <>
                    <MenuPreviewRenderer
                      menu={{
                        ...previewItem,
                        links: formatMenuLinks({ links: menuLinks, website }),
                      }}
                      noShadow
                      disableHover
                      website={{
                        ...website,
                        config: JSON.parse(website?.config ?? '{}'),
                      }}
                    />
                    {firstSection && <SectionPreviewRenderer section={firstSection} noShadow disableHover />}
                  </>
                )}

                {mode === 'mobile' && (
                  <>
                    <MenuIframe
                      menu={{
                        ...previewItem,
                        links: formatMenuLinks({ links: menuLinks, website }),
                      }}
                      website={{
                        ...website,
                        config: JSON.parse(website?.config ?? '{}'),
                      }}
                    />
                    {firstSection && <VariantIframe section={firstSection} />}
                  </>
                )}
              </div>
              <div className="flex justify-center">
                <button
                  type="button"
                  onClick={() => {
                    handleSelect(previewItem)
                    setPreviewItem(null)
                  }}
                  className="mt-20 h-[4.5rem] w-96  bg-gray-100 text-4xl font-extralight border border-black"
                >
                  select
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="mt-[40px] mb-12">
                <h1 className="capitalize text-center font-extralight text-4xl dark:text-white">Main Menu</h1>
                <p className="text-center font-extralight text-xl mt-2 dark:text-white">
                  Click on your desired section
                </p>
              </div>

              <div className="space-y-8">
                <Swiper
                  effect="coverflow"
                  modules={[EffectCoverflow]}
                  loop
                  slidesPerView={1}
                  slidesPerGroup={1}
                  shortSwipes={false}
                  className="mySwiper"
                >
                  {variants.map(menu => (
                    <SwiperSlide key={menu.id}>
                      <div className="w-full h-full px-2 bg-white dark:bg-black">
                        <div>
                          {mode === 'desktop' && (
                            <>
                              <MenuPreviewRenderer
                                menu={{
                                  ...menu,
                                  links: menuLinks,
                                }}
                                noShadow
                                stroke
                                rounded
                                disableHover
                                website={{
                                  ...website,
                                  config: JSON.parse(website?.config ?? '{}'),
                                }}
                              />
                              {firstSection && <SectionPreviewRenderer section={firstSection} noShadow disableHover />}
                            </>
                          )}

                          {mode === 'mobile' && (
                            <>
                              <MenuIframe
                                menu={{
                                  ...menu,
                                  links: menuLinks,
                                }}
                                rounded
                                stroke
                                website={{
                                  ...website,
                                  config: JSON.parse(website?.config ?? '{}'),
                                }}
                              />
                              {firstSection && <VariantIframe section={firstSection} />}
                            </>
                          )}
                        </div>

                        <div className="w-full h-[50px] px-4 mt-[2px] flex justify-between border-b border-t border-black dark:border-[#D6D6D6]">
                          <div className="flex items-center gap-x-[12px]">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="8.36636" cy="8.41629" r="7.53152" className="fill-black dark:fill-white" />
                            </svg>
                            <p className="text-[15px] font-bold dark:text-white">{menu.styleName}</p>
                          </div>
                          <div className="flex gap-x-[18px] items-center">
                            <button type="button">
                              <Love size={20} className="text-black dark:text-white" />
                            </button>
                            <button type="button">
                              <Chat size={20} className="text-black dark:text-white" />
                            </button>
                            <button type="button">
                              <Network size={20} fill="none" className="stroke-black dark:stroke-white" />
                            </button>
                            <button type="button">
                              <Share size={20} className="stroke-black fill-black dark:fill-white dark:stroke-white" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-[44px] flex bg-black h-[53px] w-[216px] justify-center text-white mx-auto border-[4px] border-white dark:border-[#E90570] rounded-[9px]">
                          <button
                            type="button"
                            className="text-[20px] h-full w-full text-center"
                            onClick={() => handleSelect(menu)}
                          >
                            select
                          </button>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </>
          )}

          <div className="pb-10" />
        </div>
      </ModalDialogV2>

      <ModalDialogV2
        open={mainNavigation}
        onBack={() => {
          setShowPreviewModal(false)
          setMainNavigation(false)
          invalidate?.()
          fetchWebsite()
        }}
      >
        <MenuLinks
          menuExpanded={menuExpanded}
          onExpand={() => setMenuExpanded(!menuExpanded)}
          onSave={() => setMainNavigation(true)}
          website={website}
          showPreview={() => setShowPreviewModal(true)}
          onBack={() => {
            setShowPreviewModal(false)
            setMainNavigation(false)
            invalidate?.()
            fetchWebsite()
          }}
        />
      </ModalDialogV2>
    </div>
  )
}

export function MenuSaveButton({
  label = 'Save',
  save,
  onClick,
  type = 'button',
  inverse,
}: {
  label?: string
  onClick?: () => void
  type?: 'button' | 'submit'
  inverse?: boolean
  save?: string
}) {
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={clsx(
        save
          ? 'w-full bg-transparent'
          : 'w-full h-[82px] border border-black flex items-center px-12 text-[28px] font-light mt-10 justify-center',
        inverse ? 'bg-white text-black' : 'bg-black text-white',
      )}
      onClick={onClick}
    >
      {save ? <Save height={23} fill="none" className="mx-auto" /> : label}
    </button>
  )
}

export function MenuIconButton({
  label,
  onClick,
  icon = 'pencil',
}: {
  label: string
  onClick?: () => void
  icon?: 'plus' | 'minus' | 'pencil'
}) {
  let Icon

  if (icon === 'plus') {
    Icon = (
      <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.6 9.44V11.96H10.8V20.42H8.04V11.96H0.18V9.44H8.04V0.979999H10.8V9.44H18.6Z" fill="#525252" />
      </svg>
    )
  } else if (icon === 'minus') {
    Icon = (
      <svg width="25" height="3" viewBox="0 0 25 3" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.96 2.76V0H24.12V2.76H0.96Z" fill="#525252" />
      </svg>
    )
  } else {
    Icon = <EditPlus size={50} fill="none" className="stroke-[#8D8D8D] fill-white dark:fill-black dark:stroke-white" />
  }

  return (
    <button
      type="button"
      className="flex justify-between items-center px-[22px] h-[76px] border-[#D9D9D9] dark:border-white border-b w-full"
      onClick={onClick}
    >
      <p className="text-[19px] dark:text-white">{label}</p>
      <div className="">{Icon}</div>
    </button>
  )
}
