import { useState } from 'react'
import { IoPencil } from 'react-icons/io5'
import EditImageOverlay from '../EditImageOverlay'
import ImageUploader from '../ImageUploader'
import ModalDialogV2 from '../ModalDialogV2'
import VideosUploader from '../VideosUploder'
import SectionBaseField from './BaseField'

interface SectionMediaFieldProps {
  defaultValue: string
  onChange: (url: string) => void
  isVideo?: boolean
  title?: string
}

export default function SectionMediaField({ defaultValue, onChange, isVideo, title }: SectionMediaFieldProps) {
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [mediaModal, setMediaModal] = useState(false)

  return (
    <>
      <SectionBaseField
        title={title || 'Media'}
        mediaModal={mediaModal}
        closeMediaModal={(modal: boolean) => setMediaModal(modal)}
        hideUpdateButton
      >
        <button type="button" onClick={() => setShowUploadModal(true)} className="w-full relative mt-3">
          {isVideo ? (
            <div>
              <iframe
                width="100%"
                height="350px"
                title="youtube video"
                src={`https://www.youtube.com/embed/${defaultValue}`}
              />
              <div className="absolute top-0 left-0 bg-black/30 w-full h-full grid place-items-center">
                <IoPencil className="text-white" color="white" size={32} />
              </div>
            </div>
          ) : (
            <EditImageOverlay src={defaultValue} />
          )}
        </button>
      </SectionBaseField>

      <ModalDialogV2 open={showUploadModal} onBack={() => setShowUploadModal(false)}>
        {isVideo ? (
          <VideosUploader
            onSubmit={url => {
              onChange(url)
              setShowUploadModal(false)
              setMediaModal(false)
            }}
          />
        ) : (
          <ImageUploader
            onSubmit={url => {
              onChange(url)
              setShowUploadModal(false)
              setMediaModal(false)
            }}
            state="media"
          />
        )}
      </ModalDialogV2>
    </>
  )
}
