import { Auth } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import AuthLayout from 'src/components/layout/AuthLayout'
import { Loader } from 'src/components/loader'
import UnauthenticatedPage from 'src/components/UnauthenticatedPage'
import AuthLogo from 'src/icons/AuthLogo'
import GoogleLogin from './GoogleLogin'
import Register from './register'
// import logo from '../assets/images/logo-techboom.png'

interface ISignIpUser {
  email: string
  password: string
}

export default function Login() {
  const { register, handleSubmit, watch } = useForm<ISignIpUser>()

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [showVerify, setShowVerify] = useState(false)

  const watchEmail = watch('email')
  const watchPassword = watch('password')

  const onSubmit: SubmitHandler<ISignIpUser> = async (user: ISignIpUser) => {
    const { email, password } = user
    try {
      setLoading(true)
      await Auth.signIn(email, password)
    } catch (err: any) {
      if (err?.code === 'UserNotConfirmedException') {
        Auth.resendSignUp(email)
        setShowVerify(true)
      } else {
        setError(err?.message ?? 'Something went wrong')
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const subscription = watch(() => setError(''))
    return () => subscription.unsubscribe()
  }, [watch])

  if (showVerify) {
    return <Register confirm email={watchEmail} password={watchPassword} />
  }

  return (
    <UnauthenticatedPage>
      <AuthLayout>
        <Loader show={loading} />
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          {error && <p className="text-secondary.main mb-4 text-center">{error}</p>}
          <div className="space-y-4">
            <div className="w-[265px]">
              <input
                className="w-full px-5 py-2.5 bg-white text-[15px] rounded focus:outline-none outline-0"
                type="email"
                placeholder="Username or Email"
                {...register('email', {
                  required: { value: true, message: 'Email is required' },
                  pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Invalid email address' },
                })}
              />
            </div>
            <div className="w-[265px]">
              <input
                className="w-full px-5 py-2.5 bg-white text-[15px] rounded focus:outline-none outline-0"
                type="password"
                placeholder="Password"
                {...register('password', { required: { value: true, message: 'Password is required' } })}
              />
              <p className="text-[10px] text-white opacity-70 pt-2">
                Passwords should atleast 1 character and 1 capital letter
              </p>
            </div>
          </div>
          <div className="w-[265px]">
            <button className="w-full py-2 bg-brand-accent text-white text-[20px] rounded" type="submit">
              Login
            </button>
            <p className="text-[10px] flex items-center justify-center text-white opacity-70 pt-2">
              <Link to="/forgot-password">Forgot your password?</Link>
            </p>
          </div>
          <div className="space-y-4">
            <div>
              <GoogleLogin isSignUp={false} />
            </div>
            <div>
              <Link
                to="/register"
                className="flex cursor-pointer justify-center items-center gap-[12px] w-full h-[45px] mt-4 bg-white text-black text-[15px] border border-white rounded text-center"
              >
                <AuthLogo fill="white" size={22} />
                <span>Create new account</span>
              </Link>
            </div>
          </div>
        </form>
      </AuthLayout>
    </UnauthenticatedPage>
  )
}
