import AllUsers from 'src/icons/AllUsers'
import Close from 'src/icons/Close'
import Filter from 'src/icons/Filter'
import Search from 'src/icons/Search'
import Header from '../common/posts/Header'
import BottomNavigation from '../feed/components/BottomNavigation'

export default function AllUSers() {
  return (
    <>
      <Header />
      <div className=" absolute top-[32px] right-[24px] block lg:hidden xl:hidden md:hidden">
        <button
          type="button"
          className=" bg-[#D9D9D9] w-[28px] rounded h-[28px] flex justify-center items-center text-center"
        >
          <Close fill="black" size={12} />
        </button>
      </div>
      <div className="flex flex-col bg-white dark:bg-black h-screen">
        <div className="flex justify-between items-center lg:px-[25px] xl:px-[25px] px-[22px]  ">
          <div className="lg:mt-[104px] xl:mt-[104px] mt-[34px] w-[85%] xl:w-[794px] relative lg:w-[694px] z-10 flex items-center justify-between border-b-8 border-black dark:border-white lg:pb-3 xl:pb-3 md:pb-2 lg:ml-[137px] xl:ml-[137px]">
            <input
              type="text"
              placeholder="search"
              className="placeholder:tracking-wide lg:px-4 bg-transparent border-none outline-none text-3xl placeholder:capitalize xl:placeholder:text-[60px] lg:placeholder:text-[60px] placeholder:text-[45px] pt-12 w-full placeholder:italic placeholder:text-black dark:placeholder:text-[#D9D9D9]"
            />
            <div className="text-[#E90970] lg:mr-5 xl:mr-5 md:mr-5 -mr-2 xl:mt-4 lg:mt-4 mt-10 ">
              <Search size={46} className="stroke-[#E90970] mt-6" />
            </div>
          </div>
          <div className="lg:hidden xl:hidden md:hidden block mt-[108px]">
            <div className="w-[60px] h-[30px]">
              <Filter className="mt-5 h-full w-full stroke-black dark:stroke-white" />
            </div>
          </div>
        </div>
        <div className="items-center gap-x-5 mt-[139px] px-16 hidden lg:flex xl:flex md:flex">
          <button type="button">
            <AllUsers size={40} fill="none" />
          </button>
          <p className="capitalize text-4xl text-black dark:text-white"> All Users</p>
          <button type="button" className="w-[50px] mt-2 -ml-2 h-[30px]">
            <Filter className="h-full w-full stroke-black dark:stroke-white" />
          </button>
        </div>
      </div>
      <div className="block xl:hidden lg:hidden md:hidden">
        <BottomNavigation variant="three" />
      </div>
    </>
  )
}
