import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import BackIcon from 'src/icons/BackIcon'
import Logo from 'src/icons/Logo'
import Modal from '../common/Modal'
import { ReactComponent as XIcon } from '../icons/x-icon.svg'

export interface ModalProps {
  open: boolean
  title?: React.ReactNode
  customHeader?: boolean
  websiteId?: any
  componenstLength?: any
  onClose: () => void
  onBack?: () => void
  onNext?: () => void
}

interface ModalDialogProps extends ModalProps {
  children: any
  title?: React.ReactNode
  hideLogo?: boolean
  tutorial?: any
  tutorialCount?: any
}

const ModalDialog = ({
  title,
  open,
  customHeader,
  websiteId,
  componenstLength,
  onClose,
  onBack,
  onNext,
  hideLogo,
  children,
  tutorial,
  tutorialCount,
}: ModalDialogProps) => {
  const currentLocation = useLocation()
  const [location] = useState(currentLocation)

  useEffect(() => {
    if (currentLocation !== location) onClose()
  }, [onClose, currentLocation, location])

  return (
    <Modal open={open}>
      <div className="fixed lg:max-w-[500px] inset-0 bg-white overflow-auto dark:bg-black">
        {!customHeader && (
          <div className="flex items-center px-5 pb-5 pt-[21px] items-">
            {onBack ? (
              <button type="button" className="flex-shrink-0" onClick={onBack}>
                <BackIcon
                  size={28}
                  fill="none"
                  className="fill-white dark:fill-black stroke-black dark:stroke-white cursor-pointer mr-[16px]"
                />
              </button>
            ) : (
              <button type="button" className="flex-shrink-0" onClick={onClose}>
                <BackIcon
                  size={28}
                  fill="none"
                  className="fill-white dark:fill-black stroke-black dark:stroke-white cursor-pointer mr-[16px]"
                />
              </button>
            )}

            {!hideLogo && (
              <Link to="/" className="">
                <Logo
                  size={28}
                  fill="none"
                  className="fill-white dark:fill-black stroke-black dark:stroke-white cursor-pointer"
                />
              </Link>
            )}

            {onNext ? (
              <button
                type="button"
                className="flex-shrink-0 px-4 py-2 text-base border-2 border-current"
                onClick={onNext}
              >
                Next
              </button>
            ) : (
              <button type="button" className="flex-shrink-0 px-2 text-xl hidden" onClick={onClose}>
                <XIcon />
              </button>
            )}
          </div>
        )}
        {tutorial && websiteId && componenstLength >= 0 && tutorialCount <= 2 ? (
          <div className="px-5">
            <h1 className="flex-grow text-[19px] font-extralight dark:text-white pb-[12px] border-b border-black dark:border-white">
              {tutorial?.title}
            </h1>
            <p className="flex-grow text-[19px] dark:text-white pt-[12px] pb-[26px]">{tutorial?.description}</p>
          </div>
        ) : (
          <h1 className="flex-grow text-[34px] dark:text-white px-5 pb-[17px]">{title}</h1>
        )}
        {children}
      </div>
    </Modal>
  )
}

export default ModalDialog
