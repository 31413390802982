import clsx from 'clsx'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import InputText from '../../../common/InputText'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import TextArea from '../../../common/TextArea'
import { ContactUsSection, InputsContactUSSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import ButtonRenderer from '../ButtonRenderer'

export default function VariantThree({ section }: { section: ContactUsSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data
  const inputs = section.variables.find(variant => variant.name === 'INPUTS')?.data as InputsContactUSSection[]
  const button = section.variables.find(variant => variant.name === 'BUTTON_1')?.data
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    setLoading(true)
    try {
    } catch (error) {
      setError('Something went wrong!')
    }
    setLoading(false)
  }

  const inputClasses = {
    classInput: 'w-full border border-[gray] md:px-3 py-2 resize-none px-1',
    classLabel: ' text-sm inline-block pb-0 mb-1 ',
  }
  let indexIdentifier = 2
  return (
    <div
      className="items-center flex-col-reverse lg:flex-row flex"
      style={{
        ...generateSectionBackground({
          defaultColor: '#f3f3f3',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="flex-1 flex md:justify-center pb-4 md:pb-14 md:px-12 px-4">
        <div className="md:space-y-5">
          <p className="md:text-[60px] text-3xl text-center md:text-left uppercase font-bold hidden md:block text-[#ff4d82]">
            <SectionSlatePreview initialValue={title} previewMode />
          </p>
          <div className="flex flex-col items-start justify-start">
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col items-start">
              {inputs && (
                <div
                  className={`md:mb-8 gap-x-2 items-center gap-y-3 mx-auto text-sm grid-cols-3 space-y-2 lg:space-y-0 lg:grid`}
                >
                  {inputs.map(({ type, name, error, id, placeholder }, idx) => {
                    if (idx === indexIdentifier) {
                      if (indexIdentifier % 9 == 0) {
                        indexIdentifier += 2
                      } else {
                        indexIdentifier += 1
                      }

                      return (
                        <div key={id} className="col-span-2 ">
                          {type !== 'button' && type !== 'textarea' && (
                            <InputText
                              {...inputClasses}
                              label={name}
                              name={name}
                              register={register}
                              type={type}
                              errors={errors}
                              required={{ value: true, message: `${error}` }}
                              placeholder={placeholder}
                            />
                          )}

                          {type === 'button' && (
                            <div className="w-full col-span-2 ">
                              <input
                                type={type}
                                value={name}
                                // placeholder={placeholder}
                                className="px-5 py-3 font-medium bg-black text-white cursor-pointer"
                              />
                            </div>
                          )}
                          {type === 'textarea' && (
                            <div className="w-full col-span-2 ">
                              <TextArea
                                {...inputClasses}
                                label={name}
                                name={name}
                                register={register}
                                type={type}
                                errors={errors}
                                placeholder={placeholder}
                                required={{ value: true, message: `${error}` }}
                              />
                            </div>
                          )}
                        </div>
                      )
                    } else {
                      return (
                        <div key={id}>
                          {type !== 'button' && type !== 'textarea' && (
                            <div className="w-full">
                              <InputText
                                {...inputClasses}
                                label={name}
                                name={name}
                                register={register}
                                type={type}
                                errors={errors}
                                required={{ value: true, message: `${error}` }}
                                placeholder={placeholder}
                              />
                            </div>
                          )}

                          {type === 'button' && (
                            <div className="w-full">
                              <input
                                type={type}
                                value={name}
                                className="px-5 py-3 font-medium bg-black text-white cursor-pointer"
                              />
                            </div>
                          )}
                          {type === 'textarea' && (
                            <div className="w-full">
                              <TextArea
                                {...inputClasses}
                                label={name}
                                name={name}
                                register={register}
                                type={type}
                                errors={errors}
                                required={{ value: true, message: `${error}` }}
                                placeholder={placeholder}
                              />
                            </div>
                          )}
                        </div>
                      )
                    }
                  })}
                </div>
              )}
              {error && <span className="text-secondary.main text-center mb-4">{error}</span>}
              <div className="btn-submit">
                {button ? (
                  <ButtonRenderer button={button} typeOfButton="submit" withOutHref />
                ) : (
                  <button
                    disabled={loading}
                    type="submit"
                    className={clsx(
                      loading && 'cursor-not-allowed disabled:opacity-50',
                      'px-5 py-1 md:py-3 font-medium bg-[#ff4d82]  text-white',
                    )}
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="flex-1 relative">
        <p className="text-3xl font-bold uppercase text-center py-4 md:hidden">
          <SectionSlatePreview initialValue={title} previewMode />
        </p>
        <div className="w-10/12 mx-auto md:w-full h-[250px] md:h-full top-0 left-0 hidden md:block">
          <img className="h-[200px] md:h-[900px] w-full object-cover object-center bg-black" src={media} alt="" />
        </div>
      </div>
    </div>
  )
}
