import Alarm from 'src/icons/AlarmLight'
import Profile from 'src/icons/Profile'
import Send from 'src/icons/Send'
import darkLogo from '../../../../assets/images/black_logo.png'
import lightLogo from '../../../../assets/images/logo-techboom.png'

type HeaderFunction = {
  onSendClick?: () => void
  onNotificationClick?: () => void
  onProfileClick?: () => void
}
function Headers({ onSendClick, onNotificationClick, onProfileClick }: HeaderFunction) {
  return (
    <section className="flex justify-between items-center bg-[#ffff] dark:bg-[#000000] dark:border-black px-6 md:px-12 py-6 border-b-[3px] xl:lg:md:border-[#000000] border-[#C4C4C4]">
      <div className="w-[45px] h-[45px] ">
        <img className="w-full h-full hidden dark:block" src={darkLogo} alt="" />
        <img className="w-full h-full dark:hidden" src={lightLogo} alt="" />
      </div>

      <div className=" space-x-10 hidden xl:flex lg:flex md:flex items-center">
        <button className="w-[200px] bg-[#E90570] text-[20px] text-white text-center py-2 rounded " type="submit">
          Create
        </button>
        <button type="button" onClick={onSendClick}>
          <Send size={35} />
        </button>
        <button type="button">
          <Alarm size={26} onClick={onNotificationClick} />
        </button>
        <button type="button" onClick={onProfileClick}>
          <Profile size={40} fill="white" className=" stroke-black" strokeWidth="2" />
        </button>
      </div>
    </section>
  )
}

export default Headers
