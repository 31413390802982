import { API, Auth } from 'aws-amplify'
import {
  CommentsByPostIdQuery,
  CommentsByPostIdQueryVariables,
  CreateCommentLikeMutation,
  CreateCommentLikeMutationVariables,
  CreateCommentReplyInput,
  CreatePostCommentInput,
  LikesByPostCommentsIdQuery,
  LikesByPostCommentsIdQueryVariables,
  LikeStatus,
  NotificationStatus,
  RepliesByCommentsIdQuery,
  RepliesByCommentsIdQueryVariables,
  Topics,
  UpdateCommentLikeMutation,
  UpdateCommentLikeMutationVariables,
} from 'src/API'
import { createCommentLike, createCommentReply, createPostComment, updateCommentLike } from 'src/graphql/mutations'
import { commentsByPostId, likesByPostCommentsId, repliesByCommentsId } from 'src/graphql/queries'
import { graphqlQuery } from 'src/lib/queries'
import { createNewNotification } from './notification.service'
import { serviceGetPost } from './post.service'
import { getUserById } from './user.service'

export const getCommentLikes = async (commentID: string, filters?: LikesByPostCommentsIdQueryVariables) => {
  try {
    const { data } = await graphqlQuery<LikesByPostCommentsIdQuery>({
      query: likesByPostCommentsId,
      variables: {
        commentID,
        ...filters,
      } as LikesByPostCommentsIdQueryVariables,
    })

    return data?.likesByPostCommentsId
  } catch (err) {
    console.error('@comment.service::getCommentLikes::error', err)
    throw err
  }
}

export const getUserCommentLike = async (commentID: string) => {
  try {
    const CognitoUser = await Auth?.currentAuthenticatedUser()
    const attr = CognitoUser?.signInUserSession?.idToken?.payload

    const { items } =
      (await getCommentLikes(commentID, {
        filter: { userID: { eq: CognitoUser?.username || attr?.sub } },
      })) || {}

    const commentLike = (items || [])[0]

    return commentLike
  } catch (err) {
    console.error('@comment.service::getUserCommentLike::error', err)
    throw err
  }
}

export const likeComment = async (commentID: string) => {
  try {
    const CognitoUser = await Auth?.currentAuthenticatedUser()
    const attr = CognitoUser?.signInUserSession?.idToken?.payload
    const commentLike = await getUserCommentLike(commentID)

    if (commentLike) {
      const { id } = commentLike

      const { data } = await graphqlQuery<UpdateCommentLikeMutation>({
        query: updateCommentLike,
        variables: {
          input: {
            id,
            commentID,
            userID: CognitoUser?.username || attr?.sub,
            like: LikeStatus.LIKE,
          },
        } as UpdateCommentLikeMutationVariables,
      })

      return data?.updateCommentLike
    }

    const { data } = await graphqlQuery<CreateCommentLikeMutation>({
      query: createCommentLike,
      variables: {
        input: {
          commentID,
          userID: CognitoUser?.username || attr?.sub,
          like: LikeStatus.LIKE,
        },
      } as CreateCommentLikeMutationVariables,
    })

    return data?.createCommentLike
  } catch (err) {
    console.error('@comment.service::likeComment::error', err)
    throw err
  }
}

export const unlikeComment = async (commentID: string) => {
  try {
    const CognitoUser = await Auth?.currentAuthenticatedUser()
    const attr = CognitoUser?.signInUserSession?.idToken?.payload
    const commentLike = await getUserCommentLike(commentID)

    if (!commentLike) throw new Error("You haven't like this post yet")

    const { id } = commentLike

    const { data } = await graphqlQuery<UpdateCommentLikeMutation>({
      query: updateCommentLike,
      variables: {
        input: {
          id,
          commentID,
          userID: CognitoUser?.username || attr?.sub,
          like: LikeStatus.UNLIKE,
        },
      } as UpdateCommentLikeMutationVariables,
    })

    return data?.updateCommentLike
  } catch (err) {
    console.error('@comment.service::unlikeComment::error', err)
    throw err
  }
}

export const createReply = async (reply: CreateCommentReplyInput) => {
  try {
    const createReply = await graphqlQuery({
      query: createCommentReply,
      variables: { input: reply },
    })
    if (!createReply) return {}

    return createReply
  } catch (error) {
    console.error('@replyComment.service::createReply::error', error)
    throw error
  }
}

export const allRepliesByCommentID = async (commentID: string, filters?: RepliesByCommentsIdQueryVariables) => {
  try {
    const { data } = await graphqlQuery<RepliesByCommentsIdQuery>({
      query: repliesByCommentsId,
      variables: { commentID: commentID, ...filters },
    })

    if (!data?.repliesByCommentsId?.items) return undefined

    const replies = await Promise.all(
      data?.repliesByCommentsId.items.map(async (reply: any) => ({
        ...reply,
        user: await getUserById(reply.userID),
      })),
    )

    data.repliesByCommentsId.items = replies

    return data?.repliesByCommentsId
  } catch (error) {
    console.error('@replies.service::repliesByCommentsId::error', error)
  }
}

export const allCommentByPostID = async (postID: string, filters?: CommentsByPostIdQueryVariables) => {
  try {
    const { data } = (await API.graphql({
      query: commentsByPostId,
      variables: {
        postID,
        ...filters,
      },
    })) as { data: CommentsByPostIdQuery }

    if (!data?.commentsByPostId?.items) return undefined

    return data.commentsByPostId
  } catch (error) {
    console.error('@post.service::allCommentByPostID::error', error)
    throw error
  }
}

export const createCommentPost = async (comment: CreatePostCommentInput) => {
  try {
    const createComment = await graphqlQuery({
      query: createPostComment,
      variables: { input: comment },
    })

    if (!createComment) return {}

    // Notify post owner
    const post: any = await serviceGetPost(comment.postID)
    const CognitoUser = await Auth?.currentAuthenticatedUser()
    const attr = CognitoUser?.signInUserSession?.idToken?.payload

    // Only notify another user
    if (post.userID !== CognitoUser?.username || attr?.sub) {
      await createNewNotification({
        owner: CognitoUser?.username || attr?.sub,
        toNotifyID: post.userID,
        topic: Topics.NEW_POST_COMMENT,
        topicID: post.id,
        topicDescription: 'commented on your ' + (post.type || 'post').toLowerCase(),
        topicUrl: '/posts/' + post.id,
        status: NotificationStatus.NOTIFIED,
      })
    }

    return createComment
  } catch (error) {
    console.error('@post.service::createPostComment::error', error)
    throw error
  }
}
