import Icon from './icons'
import { IconPropsT } from './types'

function HomeIcon(props: IconPropsT) {
  const { title = 'HomeIcon', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 24 27" size={size} title={title} {...otherProps}>
      <path
        d="M18.8442 22.7183H4.7904C4.6951 22.7176 4.60086 22.6977 4.51307 22.6598C4.42528 22.6218 4.34566 22.5666 4.27875 22.4972C4.21184 22.4277 4.15895 22.3455 4.12311 22.2552C4.08726 22.1649 4.06916 22.0682 4.06984 21.9707V10.5803C4.07028 10.467 4.09531 10.3552 4.14312 10.253C4.19093 10.1508 4.26032 10.0607 4.3463 9.98921L11.3688 4.29098C11.4957 4.18975 11.652 4.13477 11.8129 4.13477C11.9738 4.13477 12.1301 4.18975 12.257 4.29098L19.2795 9.98996C19.3668 10.0602 19.4372 10.15 19.4851 10.2525C19.5331 10.355 19.5573 10.4674 19.5559 10.5811V21.9798C19.5581 22.0765 19.5412 22.1726 19.5064 22.2625C19.4715 22.3525 19.4193 22.4343 19.3529 22.5032C19.2866 22.5721 19.2073 22.6266 19.1199 22.6635C19.0325 22.7005 18.9388 22.7191 18.8442 22.7183ZM5.51096 21.2232H18.1236V10.9368L11.8129 5.82289L5.50287 10.9368V21.2232H5.51096Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M21.6522 26.0328H1.91853C1.78472 26.0319 1.6524 26.0046 1.52913 25.9525C1.40586 25.9004 1.29406 25.8245 1.2001 25.7293C1.10615 25.634 1.03189 25.5211 0.981558 25.3971C0.931227 25.2731 0.905812 25.1404 0.906764 25.0066V9.36957C0.907382 9.21401 0.942524 9.06053 1.00965 8.9202C1.07678 8.77987 1.17423 8.65619 1.29495 8.55809L11.1556 0.735451C11.3337 0.596476 11.5532 0.520996 11.7792 0.520996C12.0051 0.520996 12.2246 0.596476 12.4027 0.735451L22.2634 8.55912C22.386 8.6555 22.4849 8.77883 22.5522 8.91955C22.6195 9.06028 22.6535 9.21462 22.6516 9.3706V25.019C22.6546 25.1517 22.631 25.2837 22.582 25.4072C22.533 25.5306 22.4598 25.6429 22.3666 25.7375C22.2733 25.8321 22.1621 25.9069 22.0393 25.9577C21.9166 26.0084 21.785 26.034 21.6522 26.0328ZM2.93031 23.9804H20.6404V9.85894L11.7792 2.83849L2.91895 9.85894V23.9804H2.93031Z"
        fill="white"
        stroke="#202020"
      />
      <path
        d="M20.7196 24.783H2.9181C2.79738 24.7822 2.67802 24.7579 2.56682 24.7115C2.45562 24.6651 2.35477 24.5976 2.27001 24.5127C2.18526 24.4279 2.11827 24.3274 2.07287 24.217C2.02746 24.1066 2.00454 23.9885 2.00539 23.8694V9.94771C2.00595 9.80922 2.03765 9.67258 2.09821 9.54764C2.15877 9.4227 2.24667 9.31259 2.35557 9.22525L11.2507 2.26075C11.4114 2.13702 11.6094 2.06982 11.8132 2.06982C12.0171 2.06982 12.2151 2.13702 12.3758 2.26075L21.2709 9.22617C21.3816 9.31198 21.4707 9.42178 21.5314 9.54707C21.5922 9.67235 21.6228 9.80976 21.6211 9.94863V23.8804C21.6238 23.9986 21.6025 24.1161 21.5583 24.226C21.5142 24.3359 21.4481 24.4359 21.364 24.5201C21.2799 24.6043 21.1795 24.6709 21.0688 24.7161C20.9581 24.7613 20.8393 24.784 20.7196 24.783ZM3.8308 22.9557H19.8069V10.3834L11.8132 4.13309L3.82056 10.3834V22.9557H3.8308Z"
        fill="white"
        stroke="white"
      />
      <rect x="6.13477" y="6.19971" width="11.3566" height="16.5187" fill="white" />
      <rect x="5.10254" y="10.3291" width="14.4539" height="11.3566" fill="white" />
    </Icon>
  )
}

export default HomeIcon
