import SectionSlate from '../../../common/SectionSlatePreview'
import { ImageOmgSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantFifteen({ section }: { section: ImageOmgSection }) {
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const tittle = section.variables.find(variable => variable.name === 'TITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data
  // const circleText = section.variables.find(variable => variable.name === 'CIRCLE_TEXT')?.data
  // const text = new String(circleText?.[0]?.children[0]?.text ?? '').split('')
  // const color = circleText?.[0]?.children[0]?.color
  // const radius = text.length * 10
  // const circumference = 2 * Math.PI * radius
  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: '#ededed',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
      className="py-4 md:py-6"
    >
      <div className="md:flex justify-between items-center space-y-12 md:space-y-0 py-4 px-4 md:px-12 relative">
        <div className="md:w-[30%] md:pb-0 space-y-3 md:space-5">
          <h2 className="text-[30px] md:text-[60px] uppercase font-bold">
            <span className="text-[gray] block">
              <SectionSlate initialValue={tittle} previewMode />
            </span>
            <span className="text-[#ff4d82]">
              <SectionSlate initialValue={subTitle} previewMode />
            </span>
          </h2>
          <p className="md:text-[20px] text-[14px]">
            <SectionSlate initialValue={paragraph} previewMode />
          </p>
        </div>
        <div className="md:w-[50%] relative md:h-[880px]">
          <img className="w-full h-full" src={media} alt="" />
        </div>
      </div>
    </div>
  )
}
