import { GoPrimitiveDot } from 'react-icons/go'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { MENUSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantThree({ section }: { section: MENUSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
      }}
    >
      <div
        className=" lg:py-20 p-6"
        style={{
          ...generateSectionSpacing(section?.settings?.spacing),
        }}
      >
        <div className="flex h-full items-center  justify-center container mx-auto">
          <div
            className="md:max-w-[750px] lg:py-24 bg-no-repeat bg-cover py-12 flex items-center hero border-[16px] border-[#131822] shadow-lg shadow-black"
            style={{
              backgroundImage: media ? `url(${media})` : '',
            }}
          >
            <div className="text-center m-10 lg:m-0 lg:mx-14">
              {subTitle && (
                <div>
                  <h1 className="text-3xl font-head text-[#B48B7E]">
                    <SectionSlatePreview initialValue={subTitle} previewMode />
                  </h1>
                </div>
              )}
              {title && (
                <div>
                  <h1 className="text-5xl mt-2 font-head text-[#B48B7E]">
                    <SectionSlatePreview initialValue={title} previewMode />
                  </h1>
                </div>
              )}
              <div className="text-[#B48B7E] my-2 w-28 mx-auto flex items-center">
                <div className="w-full">
                  <div className="bg-[#B48B7E] h-[1px]" />
                </div>
                <span className="mx-2">
                  <GoPrimitiveDot></GoPrimitiveDot>
                </span>
                <div className="w-full">
                  <div className="bg-[#B48B7E] h-[1px]" />
                </div>
              </div>
              <p className="mt-6">
                {paragraph && (
                  <div>
                    <h1 className="text-[14px] md:text-xl font-head text-black">
                      <SectionSlatePreview initialValue={paragraph} previewMode />
                    </h1>
                  </div>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
