import { useContext, useState } from 'react'
import { WebsiteContext } from 'src/components/context/WebsiteContext'
import { Loader } from 'src/components/loader'
import Save from 'src/icons/Save'
import { saveWebsite } from 'src/lib/services/website.service'
import { NewFooter } from 'src/_polly/components/src/footer/footer'
import { v4 as uuid } from 'uuid'
import { getWebsiteFooter } from './footer'

type NewSubtitleProps = {
  onSave?: () => void
}

export default function NewSubtitle({ onSave }: NewSubtitleProps) {
  const websiteContext = useContext(WebsiteContext)

  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSave = async () => {
    if (!name) return
    setLoading(true)
    const footer = await getWebsiteFooter(websiteContext.websiteID)
    if (!footer) return

    const newFooter: NewFooter = {
      ...footer,
      categories: [
        ...footer.categories,
        {
          id: uuid(),
          name,
          links: [],
        },
      ],
    }

    await saveWebsite({ id: websiteContext.websiteID, newFooter: JSON.stringify(newFooter) })
    setLoading(false)
    onSave?.()
  }

  return (
    <div className="mt-[142px] px-[18px]">
      <Loader show={loading} />
      <h3 className="text-[19px] dark:text-white border-black px-[2px] border-b-[1px] dark:border-white pb-[25px] mb-[46px]">
        New Subtitle
      </h3>
      <div className="flex gap-6 items-end px-4 border-b border-black dark:border-white pb-[45px]">
        <input
          className="text-[19px] w-full border-b-[1px] dark:text-white dark:bg-black dark:border-white border-black pb-[7px] outline-none text-left"
          type="text"
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder="Type Subtitle Name"
        />
        <div className="flex items-end gap-3">
          <button type="button" onClick={handleSave}>
            <Save height={23} fill="none" className="mx-auto" />
          </button>
        </div>
      </div>
    </div>
  )
}
