import { useEffect, useState } from 'react'
import GoogleFontLoader from 'react-google-font-loader'
import { Link, useNavigate } from 'react-router-dom'
import Site from 'src/icons/Site'
import { getWebsitesByUserID } from 'src/lib/services/website.service'

function SiteItem() {
  const [websites, setWebsites] = useState<any[]>([])
  const navigate = useNavigate()

  const fetchWebsites = () => {
    getWebsitesByUserID().then(res => {
      setWebsites(res?.items || [])
    })
  }
  useEffect(() => {
    fetchWebsites()
  }, [])

  return (
    <>
      <GoogleFontLoader
        fonts={[
          {
            font: 'Monoton',
            weights: [400],
          },
        ]}
        subsets={['cyrillic-ext', 'greek']}
      />
      <div className="flex flex-col md:items-start">
        <div className="flex items-center  space-x-[18px] md:space-x-1">
          <button type="button">
            <Site size={20} className="hidden md:block" />
            <Site size={30} className="md:hidden" />
          </button>
          <h1 className=" text-[40px] md:text-[20px]">Sites </h1>
        </div>
        <div className="md:grid flex flex-wrap gap-3 md:grid-cols-3 md:gap-x-4 pt-3 pb-4 ml-3 ">
          {websites.map((website, idx) => (
            <Link
              onClick={() =>
                localStorage.setItem(
                  `tutorial_editpage_${website?.id}`,
                  JSON.stringify(JSON.parse(localStorage.getItem(`tutorial_editpage_${website?.id}`) || '0') + 1),
                )
              }
              key={idx}
              to={`/websites/${website.id}`}
            >
              <div
                key={idx}
                className="relative w-[68px] md:w-[42px] overflow-hidden h-[101px] md:h-[65px] border border-black dark:border-white rounded-[3px] flex items-center justify-center text-center bg-[#D9D9D9] hover:shadow-md transition-all duration-500"
              >
                {JSON.parse(website?.config)?.logo ? (
                  <img
                    src={JSON.parse(website?.config)?.logo}
                    alt=""
                    className="w-full h-full rounded-[3px] z-10 object-cover object-center absolute"
                  />
                ) : (
                  <div className="font-monoton w-[90%] text-2xl md:text-xl  dark:text-black  flex items-center justify-center  text-center">
                    {website.name.split('')[0]}
                  </div>
                )}
              </div>
            </Link>
          ))}
          <button
            onClick={() => navigate('/builder?newSite=true')}
            type="button"
            className="md:hover:shadow-md transition-all duration-500"
          >
            <svg
              width="42"
              height="65"
              className="hidden md:block"
              viewBox="0 0 42 65"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 0.5H39C40.3807 0.5 41.5 1.61929 41.5 3V62C41.5 63.3807 40.3807 64.5 39 64.5H3C1.61929 64.5 0.5 63.3807 0.5 62V3C0.5 1.61929 1.61929 0.5 3 0.5Z"
                fill="#D9D9D9"
              />
              <path
                d="M3 0.5H39C40.3807 0.5 41.5 1.61929 41.5 3V62C41.5 63.3807 40.3807 64.5 39 64.5H3C1.61929 64.5 0.5 63.3807 0.5 62V3C0.5 1.61929 1.61929 0.5 3 0.5Z"
                stroke="black"
              />

              <mask
                id="mask0_16_3"
                maskUnits="userSpaceOnUse"
                x="1"
                y="2"
                width="40"
                height="61"
                style={{ maskType: 'alpha' }}
              >
                <path
                  d="M4.42365 2.90747H37.5762C38.9569 2.90747 40.0762 4.02676 40.0762 5.40747V59.5927C40.0762 60.9734 38.9569 62.0927 37.5762 62.0927H4.42364C3.04293 62.0927 1.92365 60.9734 1.92365 59.5927V5.40747C1.92365 4.02676 3.04293 2.90747 4.42365 2.90747Z"
                  fill="#D9D9D9"
                  stroke="black"
                />
              </mask>
              <g mask="url(#mask0_16_3)">
                <rect x="1.44827" y="2.4126" width="39.5862" height="60.3149" fill="#D9D9D9" />
                <path
                  d="M26.216 30.544V32.336H22.248V36.656H20.232V32.336H16.264V30.544H20.232V26.224H22.248V30.544H26.216Z"
                  fill="#121212"
                />
                <path
                  d="M25.616 30.937V32.729H21.648V37.049H19.632V32.729H15.664V30.937H19.632V26.617H21.648V30.937H25.616Z"
                  fill="white"
                />
              </g>
            </svg>

            <svg
              className="block md:hidden"
              width="68"
              height="101"
              viewBox="0 0 68 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H64.7439C66.1246 0.5 67.2439 1.61929 67.2439 3V98C67.2439 99.3807 66.1246 100.5 64.7439 100.5H3C1.61929 100.5 0.5 99.3807 0.5 98V3Z"
                fill="#D9D9D9"
              />
              <path
                d="M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H64.7439C66.1246 0.5 67.2439 1.61929 67.2439 3V98C67.2439 99.3807 66.1246 100.5 64.7439 100.5H3C1.61929 100.5 0.5 99.3807 0.5 98V3Z"
                stroke="#D9D9D9"
              />
              <path
                d="M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H64.7439C66.1246 0.5 67.2439 1.61929 67.2439 3V98C67.2439 99.3807 66.1246 100.5 64.7439 100.5H3C1.61929 100.5 0.5 99.3807 0.5 98V3Z"
                stroke="#D9D9D9"
              />
              <path
                d="M3.41357 5.13354C3.41357 3.75283 4.53286 2.63354 5.91357 2.63354H63.2869C64.6676 2.63354 65.7869 3.75283 65.7869 5.13354V94.9482C65.7869 96.3289 64.6676 97.4482 63.2869 97.4482H5.91358C4.53286 97.4482 3.41357 96.3289 3.41357 94.9482V5.13354Z"
                fill="#D9D9D9"
              />
              <path
                d="M3.41357 5.13354C3.41357 3.75283 4.53286 2.63354 5.91357 2.63354H63.2869C64.6676 2.63354 65.7869 3.75283 65.7869 5.13354V94.9482C65.7869 96.3289 64.6676 97.4482 63.2869 97.4482H5.91358C4.53286 97.4482 3.41357 96.3289 3.41357 94.9482V5.13354Z"
                stroke="black"
              />
              <path
                d="M3.41357 5.13354C3.41357 3.75283 4.53286 2.63354 5.91357 2.63354H63.2869C64.6676 2.63354 65.7869 3.75283 65.7869 5.13354V94.9482C65.7869 96.3289 64.6676 97.4482 63.2869 97.4482H5.91358C4.53286 97.4482 3.41357 96.3289 3.41357 94.9482V5.13354Z"
                stroke="black"
              />
              <mask
                id="mask0_1276_12624"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="5"
                y="5"
                width="60"
                height="90"
              >
                <path
                  d="M5.56201 8.68261C5.56201 7.3019 6.6813 6.18262 8.06201 6.18262H61.1388C62.5196 6.18262 63.6388 7.30191 63.6388 8.68262V91.3999C63.6388 92.7806 62.5196 93.8999 61.1388 93.8999H8.06201C6.6813 93.8999 5.56201 92.7806 5.56201 91.3999V8.68261Z"
                  fill="#D9D9D9"
                  stroke="black"
                />
              </mask>
              <g mask="url(#mask0_1276_12624)">
                <rect x="5.09912" y="5.69019" width="59.7312" height="88.9084" fill="#D9D9D9" />
                <path
                  d="M43.1818 47.105V50.745H35.0568V59.52H31.0268V50.745H22.9018V47.105H31.0268V38.33H35.0568V47.105H43.1818Z"
                  fill="#121212"
                />
                <path
                  d="M41.725 47.8162V51.4562H33.6V60.2312H29.57V51.4562H21.445V47.8162H29.57V39.0412H33.6V47.8162H41.725Z"
                  fill="white"
                />
              </g>
            </svg>
          </button>
        </div>
      </div>
    </>
  )
}

export default SiteItem
