import Icon from './icons'
import { IconPropsT } from './types'

function Chat(props: IconPropsT) {
  const { title = 'Chat', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 20 17" size={size} title={title} {...otherProps}>
      <path
        d="M4.36344 0.816406H15.8794C16.941 0.817487 17.9588 1.23969 18.7095 1.99036C19.4602 2.74104 19.8824 3.75886 19.8835 4.82047V11.0045C19.8848 11.8573 19.6093 12.6875 19.0985 13.3703C18.5877 14.0532 17.869 14.5519 17.0506 14.7914L15.1171 16.7249C15.064 16.7781 15.001 16.8203 14.9316 16.8491C14.8622 16.8779 14.7878 16.8927 14.7127 16.8927C14.6376 16.8927 14.5632 16.8779 14.4938 16.8491C14.4244 16.8203 14.3614 16.7781 14.3083 16.7249L12.5348 14.9506H4.36344C3.30197 14.9495 2.28427 14.5274 1.53362 13.7769C0.782969 13.0264 0.360671 12.0088 0.359375 10.9474L0.359375 4.82047C0.360455 3.75886 0.782658 2.74104 1.53333 1.99036C2.284 1.23969 3.30183 0.817487 4.36344 0.816406M1.50199 10.9474C1.50372 11.7044 1.80523 12.43 2.34056 12.9653C2.87589 13.5007 3.60147 13.8022 4.35854 13.8039H12.765C12.8407 13.8034 12.9157 13.8179 12.9858 13.8466C13.0558 13.8753 13.1195 13.9177 13.173 13.9712L14.7099 15.508L16.3495 13.8749C16.4228 13.8013 16.5148 13.7491 16.6156 13.7239C17.2225 13.573 17.7613 13.223 18.146 12.7299C18.5306 12.2368 18.7389 11.6291 18.7376 11.0037V4.82047C18.7359 4.0634 18.4343 3.33782 17.899 2.80249C17.3637 2.26716 16.6381 1.96565 15.881 1.96392H4.36344C3.60636 1.96565 2.88079 2.26716 2.34546 2.80249C1.81013 3.33782 1.50862 4.0634 1.50689 4.82047L1.50199 10.9474Z"
        // fill="black"
        strokeWidth={0.5}
      />
    </Icon>
  )
}

export default Chat
