import GoogleFontLoader from 'react-google-font-loader'
import { NewMenu } from './menu'

type FonatLoaderProps = {
  // @ts-ignore
  settings: NewMenu['settings']['fontFamily'] | undefined
}

export default function FontLoader({ settings }: FonatLoaderProps) {
  if (!settings?.enabled) return null
  return (
    <GoogleFontLoader
      fonts={[
        {
          font: settings?.font,
          weights: [400, 500, 600, 700],
        },
      ]}
    />
  )
}
