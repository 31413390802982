import React, { useCallback, useContext, useState } from 'react'
import { v4 as uuid } from 'uuid'
import produce from 'immer'
import { WebsiteContext } from 'src/components/context/WebsiteContext'
import ModalDialogV2 from 'src/components/new/ModalDialogV2'
import { ReactComponent as MoreIcon } from '../../../assets/icons/menuBar/more.svg'
import { ReactComponent as AddIcon } from '../../../assets/icons/menuBar/add.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/menuBar/delete.svg'
import { ReactComponent as DownIcon } from '../../../assets/icons/menuBar/down.svg'
import { ReactComponent as DuplicateIcon } from '../../../assets/icons/menuBar/duplicate.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/menuBar/edit.svg'
import { ReactComponent as UpIcon } from '../../../assets/icons/menuBar/up.svg'
import ModalDialog, { ModalProps } from '../../new/ModalDialog'
import SectionMenuPreview from './SectionMenuPreview'
import EditMenubarItem from './EditMenubarItem'

interface IProps extends ModalProps {
  defaultValue: any
  websiteID?: string
  pageID?: string
}

const MenuBar = (props: IProps) => {
  const websiteContext = useContext(WebsiteContext)
  const { defaultValue, ...rest } = props

  const [activeKey, setActiveKey] = useState('')
  const [pages, setPages] = useState((defaultValue?.pages || []) as any[])
  const [editItem, setEditItem] = useState<any>()
  const [showMenuPreview, setShowMenuPreview] = useState(false)
  const showModalAddPage = !!editItem

  const set = useCallback((func: (state: any[]) => any) => {
    setPages(
      produce(state => {
        func(state)
      }),
    )
  }, [])

  return (
    <ModalDialogV2 onBack={rest.onClose} open={rest.open}>
      <div className="p-5">
        <h1 className="text-4xl">Menu</h1>
        <div className="h-[2px] mt-2 w-[60%] bg-[#C4C4C4]" />
        <div>
          {pages.map((item: any, index) => (
            <div key={item.id}>
              <div className="flex items-center justify-between my-4">
                <div className="flex items-center gap-x-3">
                  <div className="title text-2xl">{item.title}</div>
                  {!item?.visible && <div className="px-2 py-1 bg-gray-200 text-sm">Hidden</div>}
                </div>
                <MoreIcon onClick={() => setActiveKey(activeKey === item.id ? '' : item.id)} />
              </div>
              {item.id === activeKey && (
                <div className="flex justify-between items-center">
                  <div className="grow h-14 w-20 border-[1px] border-black flex justify-center items-center">
                    <UpIcon
                      onClick={() => {
                        if (index === 0) return
                        set(x => {
                          const items = x.splice(index, 1)
                          x.splice(index - 1, 0, ...items)
                        })
                      }}
                    />
                  </div>
                  <div className="grow h-14 w-20 border-t-[1px] border-b-[1px] border-r-[1px] border-black flex justify-center items-center">
                    <DuplicateIcon
                      onClick={() => {
                        const id = uuid()
                        set(x => x.splice(index + 1, 0, { ...item, id }))
                        setActiveKey(id)
                      }}
                    />
                  </div>
                  <div className="grow h-14 w-20 border-t-[1px] border-b-[1px] border-r-[1px] border-black flex justify-center items-center">
                    <EditIcon onClick={() => setEditItem(item)} />
                  </div>
                  <div className="grow h-14 w-20 border-t-[1px] border-b-[1px] border-black flex justify-center items-center">
                    <DeleteIcon onClick={() => set(x => x.splice(index, 1))} />
                  </div>
                  <div className="grow h-14 w-20 border-[1px] border-black flex justify-center items-center">
                    <DownIcon
                      onClick={() => {
                        if (index + 1 === pages.length) return
                        set(x => {
                          const items = x.splice(index, 1)
                          x.splice(index + 1, 0, ...items)
                        })
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <button type="button" className="pt-10 flex items-center" onClick={() => setEditItem({})}>
          <div className="h-14 w-16 border-[1px] border-black flex justify-center items-center">
            <AddIcon />
          </div>
          <span className="text-2xl pl-10">Add Link</span>
        </button>
        <div className="mt-4">
          <button
            className="px-4 py-3 border border-black w-full"
            type="submit"
            onClick={() => setShowMenuPreview(true)}
          >
            Preview
          </button>
        </div>

        <ModalDialogV2 open={showModalAddPage} onBack={() => setEditItem(null)}>
          <EditMenubarItem
            defaultValue={editItem}
            onUpdate={item => {
              setEditItem(null)
              set(_pages => {
                if (!item.id) {
                  _pages.push({ ...item, id: uuid() })
                } else {
                  const index = _pages.findIndex(x => x.id === item.id)
                  if (index !== -1) _pages.splice(index, 1, item)
                }
              })
            }}
          />
        </ModalDialogV2>

        <ModalDialog
          title="Menu Preview"
          open={showMenuPreview}
          onBack={() => setShowMenuPreview(false)}
          onClose={() => setShowMenuPreview(false)}
        >
          <SectionMenuPreview
            menu={{ pages, style: defaultValue?.style }}
            website={defaultValue?.website}
            websiteId={websiteContext.websiteID}
            onClose={rest.onClose}
          />
        </ModalDialog>
      </div>
    </ModalDialogV2>
  )
}

export default MenuBar
