import SectionSlate from '../../../common/SectionSlatePreview'
import { ImageOmgSection as omgSectionType } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

interface VariantOneProps {
  section: omgSectionType
  hideImageBg?: boolean
}

export default function VariantNine({ section, hideImageBg }: VariantOneProps) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data

  return (
    <div
      className="md:py-[100px] bg-cover bg-no-repeat bg-center"
      style={{
        ...generateSectionBackground({
          defaultColor: '#000',
          backgroundSettings: section?.settings?.color?.background,
          image: media && !hideImageBg ? media : null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="container mx-auto px-6 md:px-0">
        <div className="w-[750px] max-w-full mx-auto">
          <p className="text-[32px] md:text-4xl font-bold  text-white text-center lg:leading-[90px]">
            {<SectionSlate initialValue={title} previewMode />}
          </p>
        </div>

        {subTitle && (
          <div className="w-[750px] max-w-full mx-auto ">
            <p className="text-[20px] text-white text-center">{<SectionSlate initialValue={subTitle} previewMode />}</p>
          </div>
        )}

        {paragraph && (
          <div className="w-[750px] max-w-full mx-auto ">
            <p className="text-white text-center lg:leading-7">
              {<SectionSlate initialValue={paragraph} previewMode />}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
