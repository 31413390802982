import { Auth } from 'aws-amplify'
import {
  CreateUserProfileMutation,
  CreateUserProfileMutationVariables,
  GetUserProfileQuery,
  ListUserProfilesQuery,
  UpdateUserProfileInput,
  UpdateUserProfileMutation,
  UserByUsernameQuery,
} from 'src/API'
import { createUserProfile, updateUserProfile } from 'src/graphql/mutations'
import { getUserProfile, listUserProfiles, userByUsername } from 'src/graphql/queries'
import { graphqlQuery } from 'src/lib/queries'
import { createUser } from 'src/lib/services/user.service'

export const createUserRecord = async () => {
  try {
    const userAuth = await Auth.currentAuthenticatedUser()

    const str = userAuth?.attributes?.name || userAuth?.signInUserSession?.idToken?.payload?.name
    const res = str.split(' ')
    const newUser = {
      id: userAuth?.username || userAuth?.signInUserSession?.idToken?.payload?.sub,
      firstName: res[0],
      lastName: res[1],
      email: userAuth?.attributes?.email || userAuth?.signInUserSession?.idToken?.payload?.email,
    }

    const { data } = await graphqlQuery<CreateUserProfileMutation>({
      query: createUserProfile,
      variables: { input: newUser } as CreateUserProfileMutationVariables,
    })

    if (!data) return
    return data.createUserProfile
  } catch (error) {
    console.error('@user.service::createUserRecord::error', error)
    throw error
  }
}

export const getCurrentUser = async () => {
  try {
    const userAuth = await Auth.currentAuthenticatedUser()
    const { data } = await graphqlQuery<GetUserProfileQuery>({
      query: getUserProfile,
      variables: { id: userAuth.username },
    })
    const str = userAuth?.attributes?.name || userAuth?.signInUserSession?.idToken?.payload?.name || ''
    const res = str.split(' ')
    console.log('kix current user', data)
    if (!data?.getUserProfile) {
      return createUser({
        id: userAuth?.username,
        firstName: res[0] ?? '',
        lastName: res[1] ?? '',
        email: userAuth?.signInUserSession?.idToken?.payload?.email,
      })
    }

    const user = data.getUserProfile

    return user
  } catch (err) {
    console.error('@user.service::getCurrentUser::error', err)
    throw err
  }
}

export const getUserById = async (id: string) => {
  try {
    const userRes = await graphqlQuery<GetUserProfileQuery>({
      query: getUserProfile,
      variables: { id: id },
    })

    const user = userRes.data?.getUserProfile

    return {
      ...user,
      config: JSON.parse(user?.config || '{}'),
    }
  } catch (err) {
    console.error('@user.service::getUserById::error', err)
    throw err
  }
}

export const getUserByUsername = async (username: string) => {
  try {
    const userRes = await graphqlQuery<UserByUsernameQuery>({
      query: userByUsername,
      variables: { username: username },
    })

    const user = userRes.data?.userByUsername?.items[0]

    if (!user) return null

    return {
      ...user,
      config: JSON.parse(user?.config || '{}'),
    }
  } catch (err) {
    console.error('@user.service::getUserById::error', err)
    throw err
  }
}

export const saveUser = async (user: UpdateUserProfileInput) => {
  try {
    const { data } = await graphqlQuery<UpdateUserProfileMutation>({
      query: updateUserProfile,
      variables: { input: user },
    })

    return data
  } catch (error) {
    console.error('@user.service::saveUser::error', error)
    throw error
  }
}

export const getAllUsers = async () => {
  const { data } = await graphqlQuery<ListUserProfilesQuery>({
    query: listUserProfiles,
  })
  return data?.listUserProfiles?.items ?? []
}
