import { Template } from '.'
import { aboutUs, contactUs, gallery, love, quote } from './variants'

export const Template14: Template = {
  id: '14',
  pages: [
    {
      slug: '/',
      name: 'Home',
      blocks: [
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_9')?.settings,
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ className: '', text: 'Welcome In Our Gallery' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'b1fda8bc-f42a-447c-a6f6-8067e93df3b9',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Amara Henson' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '3aa62abd-febe-402a-9be7-19f10339207e',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_9',
          id: '633a977d-1691-43c7-b325-8bfece388125',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...gallery.find(i => i.variant === 'VARIANT_1')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1574068468668-a05a11f871da?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw3fHxhbmltYWxzfGVufDB8fHx8MTY3OTQ3MDQ5MQ&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [{ text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia' }],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                  title: [{ children: [{ text: 'YOLO Startup Design' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1659539909696-2de5f1f75b10?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxhbmltYWwlMjBvZiUyMHJpdmVyfGVufDB8fHx8MTY3OTQ3MDM2Mg&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [{ text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia' }],
                      type: 'paragraph',
                    },
                  ],
                  id: '4',
                  title: [{ children: [{ text: 'YOLO Startup Design' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1534447677768-be436bb09401?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxzdGFyJTIwb2YlMjB0aGUlMjBzZWF8ZW58MHx8fHwxNjc5NDcwMzE2&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [{ text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia' }],
                      type: 'paragraph',
                    },
                  ],
                  id: '5',
                  title: [{ children: [{ text: 'YOLO Startup Design' }], type: 'paragraph' }],
                },
              ],
              name: 'MEDIA',
              id: '678cb285-5528-40bb-91ed-8f396c4ef9ac',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          id: '805c507f-002c-481d-852b-906d695239cf',
          type: 'GALLERY',
        },
      ],
    },
    {
      slug: '/photos',
      name: 'Photos',
      blocks: [
        {
          settings: {
            ...gallery.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1529778873920-4da4926a72c2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxhbmltYWxzfGVufDB8fHx8MTY3OTQ3MDQ5MQ&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                  title: [{ children: [{ text: 'YOLO Startup Design' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1574870111867-089730e5a72b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw1fHxhbmltYWxzfGVufDB8fHx8MTY3OTQ3MDQ5MQ&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                  title: [{ children: [{ text: 'YOLO Startup Design' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1676950740454-db2c24ebe17c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2Nzk0NzA5NTE&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                  title: [{ children: [{ text: 'YOLO Startup Design' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1602491453631-e2a5ad90a131?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxhbmltYWx8ZW58MHx8fHwxNjc5NDcwMzkw&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '4',
                  title: [{ children: [{ text: 'YOLO Startup Design' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1577023311546-cdc07a8454d9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw1fHxjYXRzfGVufDB8fHx8MTY3OTQ3MTAwOQ&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '5',
                  title: [{ children: [{ text: 'YOLO Startup Design' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1614027164847-1b28cfe1df60?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxsaW9ufGVufDB8fHx8MTY3OTQ3NDgyMg&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '6',
                  title: [{ children: [{ text: 'YOLO Startup Design' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1605092676920-8ac5ae40c7c8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHx0aWdlcnxlbnwwfHx8fDE2Nzk0NzQ4NDM&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '7',
                  title: [{ children: [{ text: 'YOLO Startup Design' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1590420485404-f86d22b8abf8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHx3b2xmfGVufDB8fHx8MTY3OTQ3NDk0Ng&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '8',
                  title: [{ children: [{ text: 'YOLO Startup Design' }], type: 'paragraph' }],
                },
              ],
              name: 'MEDIA',
              id: '123',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'GALLERY',
        },
        {
          settings: {
            ...gallery.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1444464666168-49d633b86797?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxiaXJkfGVufDB8fHx8MTY3OTQ3NDkyMA&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1480044965905-02098d419e96?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw2fHxiaXJkfGVufDB8fHx8MTY3OTQ3NDkyMA&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1522926193341-e9ffd686c60f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxiaXJkfGVufDB8fHx8MTY3OTQ3NDkyMA&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1606567595334-d39972c85dbe?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxiaXJkfGVufDB8fHx8MTY3OTQ3NDkyMA&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '4',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1605720766560-0e9026556297?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw4fHxzbWFsbCUyMGJpcmR8ZW58MHx8fHwxNjc5NDc3ODAw&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '5',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1551085254-e96b210db58a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw1fHxiaXJkfGVufDB8fHx8MTY3OTQ3NDkyMA&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '6',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1539664030485-a936c7d29c6e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw3fHxiaXJkfGVufDB8fHx8MTY3OTQ3NDkyMA&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '7',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1518428618744-ecd97fbd445b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxiaXJkJTIwc21hbGx8ZW58MHx8fHwxNjc5NDc3Nzg3&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '8',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
              ],
              name: 'MEDIA',
              id: '123',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'GALLERY',
        },
      ],
    },
    {
      slug: '/about',
      name: 'About',
      blocks: [
        {
          settings: {
            ...aboutUs.find(i => i.variant === 'VARIANT_4')?.settings,
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
              name: 'MEDIA',
              id: 'f488ad0b-59aa-4e2e-a30a-cb9763d9782f',
              category: 'IMAGE',
            },
            {
              data: [
                { children: [{ color: '#ffffff', text: 'Cottage Industry, Captivating Design' }], type: 'paragraph' },
              ],
              name: 'SUBTITLE',
              id: '9bfea875-db7d-4fcc-972f-8ef374d87a9b',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#ffffff', text: 'Ava Johansson' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '70d14519-3034-4f04-9edb-df8067649a15',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#ffffff',
                      text: 'Startup Appeal: At Dead Startup Appeal, we specialize in reviving companies that have hit a roadblock. Our team of experts assesses the issues and implements tailored solutions to bring Startup Appeal: At Dead Startup Appeal, we specialize in reviving companies that have hit a roadblock. Our team of experts assesses the issues and implements tailored solutions to bring businesses back to life. With our unique approach, we turn dead startups into thriving enterprises with renewed appeal .Startup Appeal: At Dead Startup Appeal, we specialize in reviving companies that have hit a roadblock. Our team of experts assesses the issues and implements tailored solutions to bring businesses back to life. With our unique approach, we turn dead startups into thriving enterprises with renewed appeal back to life. With our unique approach, we turn startups into thriving enterprises with renewed appeal.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '9e4367ff-e995-4f4d-a283-5d5bdf6f7cb0',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_4',
          type: 'ABOUT_US',
        },
        {
          settings: {
            ...quote.find(i => i.variant === 'VARIANT_5')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 60, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'DESCRIPTION',
              id: 'e32536a3-bcf2-415b-80f2-24ef55cfb15e',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      fontFamily: 'Dancing Script',
                      color: '#ececec',
                      text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '148cdc7c-717d-4da9-9bd5-73c32d794621',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw4fHxwaG90b2dyYXBoZXJ8ZW58MHx8fHwxNjc5NDgwNTEx&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '034b0c89-4d10-4bba-a437-c6854a201cd3',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ color: '#ffffff', text: 'Ava Johansson' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '803a4c1e-f066-4c82-8a11-d31e4ef9d363',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_5',
          type: 'QUOTE_SECTION',
        },
      ],
    },
    {
      slug: '/contact',
      name: 'Contact',
      blocks: [
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_6')?.settings,
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  name: 'Email',
                  id: '69aac4f2-99d5-4792-bfe5-c4227ae5a5ed',
                  placeholder: 'Enter Your Email',
                  type: 'email',
                  error: 'email is required',
                },
              ],
              name: 'INPUTS',
              id: 'b0672a4e-a8a9-45d6-846e-4b37c15a1af2',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#ffffff', text: 'contact with me', bold: true }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '5ff419bf-962f-4fac-848c-3ebbfa6f6c84',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#ffffff',
                      text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '57ce964e-2d5c-433b-9cf0-c9111aafb1aa',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#ffffff',
                  backgroundColor: '#000000',
                  borderRadius: '0px',
                  color: '#ffffff',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'send',
              },
              name: 'BUTTON_1',
              id: '60716c54-2f3d-4957-8cd0-5469ef5d1472',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_6',
          type: 'CONTACT_US',
        },
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: {
                address: '1600 Amphitheatre Parkway, Mountain View, CA',
                position: { lng: -122.0842499, lat: 37.4224764 },
              },
              name: 'MAP',
              id: '485c3ae4-15fa-4891-a6a2-da315a41051c',
              category: 'TEXT',
            },
            {
              data: [
                {
                  name: [{ children: [{ color: '#ffffff', text: 'Hours' }], type: 'paragraph' }],
                  description: [{ children: [{ color: '#ffffff', text: 'monday-friday' }], type: 'paragraph' }],
                  id: 'c0650e6c-515e-4d38-9a9b-b3d3478f71ca',
                },
                {
                  name: [{ children: [{ color: '#ffffff', text: 'Phone' }], type: 'paragraph' }],
                  description: [{ children: [{ color: '#ffffff', text: '(555) 555-5555' }], type: 'paragraph' }],
                  id: '3ab52f33-048c-42a1-8edc-2c7f0504748e',
                },
              ],
              name: 'CONTRACT',
              id: 'bdf9f63e-84b4-46cd-8456-9d53a0b7624d',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#ffffff',
                      text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'a4778904-4799-4dc8-934c-b28e5c813204',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#ffffff', text: '' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '86009719-90fb-4d53-868e-4e2e27fe3490',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'CONTACT_US',
        },
      ],
    },
  ],
  newMenu: [
    { name: 'Home', type: 'internal', url: '/' },
    { name: 'Photos', type: 'internal', url: '/photos' },
    { name: 'About', type: 'internal', url: '/about' },
    { name: 'Contact', type: 'internal', url: '/contact' },
  ],
  newMenuConfig: {
    settings: { backgroundColor: { color: '#000000', enabled: true }, textColor: { color: '#ffffff', enabled: true } },
    links: [
      { name: 'Home', id: '2825da4c-4d6b-42da-96b6-e7ec24427bcb', type: 'internal', url: '/' },
      { name: 'Contact Us', id: '2692b6f0-6f11-45af-830c-32c01af250d1', type: 'email', url: 'hello@example.com' },
    ],
    styleName: 'VARIANT 17',
    id: 'VARIANT_17',
    tags: [],
  },
  newFooter: {
    designedBy: 'Plly Staff',
    id: 'VARIANT_9',
    categories: [
      {
        name: 'Explore',
        links: [{ name: 'Home', id: '01355cf0-e87b-473a-b3c5-f6a78159d903', type: 'internal', url: '/' }],
        id: 'd0d58b24-4903-4e55-88d2-9d6a74709f55',
      },
    ],
    tags: [],
  },
  links: [],
}
