import { Descendant } from 'slate'
import {
  PortfolioItemsGroup,
  PortfolioVariables,
  PortfolioVariants,
  Section,
  SectionTags,
} from 'src/_polly/components/src/sections'
import { v4 as uuid } from 'uuid'
import { DEFAULT_VARIANT_SETTINGS, getSectionVariants } from './get-variants'

const tags: Record<PortfolioVariants, SectionTags[]> = {
  VARIANT_1: ['IMAGE', 'ANIMATION'],
  VARIANT_2: ['IMAGE', 'ANIMATION'],
  VARIANT_3: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_4: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_5: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_6: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
}

export function getPortfolioVariants() {
  const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Title' }] }]
  const SubTitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Sub Title' }] }]
  const paragraph: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Paragraph' }] }]
  const media =
    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'
  const transition = 0
  const variants = getSectionVariants('PORTFOLIO')
  const sections: Section<PortfolioVariants, PortfolioVariables>[] = []
  const item: Descendant[] = [{ type: 'paragraph', children: [{ text: 'item 1' }] }]
  const item2: Descendant[] = [{ type: 'paragraph', children: [{ text: 'item 2' }] }]
  const item3: Descendant[] = [{ type: 'paragraph', children: [{ text: 'item 3' }] }]
  const item4: Descendant[] = [{ type: 'paragraph', children: [{ text: 'item 4' }] }]
  const item5: Descendant[] = [{ type: 'paragraph', children: [{ text: 'item 5' }] }]
  const item6: Descendant[] = [{ type: 'paragraph', children: [{ text: 'item 6' }] }]
  variants.forEach(variant => {
    if (variant.id === 'VARIANT_1') {
      const item: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Mindful Customer Acquisition' }] }]
      const item2: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Mindful Customer Acquisition' }] }]

      const data: Section<PortfolioVariants, PortfolioVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'PORTFOLIO',
        updatedAt: '',
        variables: [
          {
            id: uuid(),
            category: 'TEXT',
            name: 'BACKGROUND',
            data: ' transparent',
          },
          { id: uuid(), category: 'TEXT', data: { top: 0, bottom: 0, left: 0, right: 0 }, name: 'MARGIN' },
          { id: uuid(), category: 'TEXT', data: { top: 0, bottom: 0, left: 1, right: 0 }, name: 'PADDING' },
          { id: uuid(), category: 'IMAGE', data: transition, name: 'TRANSITION' },

          {
            id: uuid(),
            category: 'TEXT',
            name: 'ITEMS',
            data: [
              {
                id: uuid(),
                image:
                  'https://images.unsplash.com/photo-1633410189542-36d96e3762b8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHx0ZWFtc3xlbnwwfHx8fDE2Nzc3NTk4MDQ&ixlib=rb-4.0.3&q=80&w=1080',

                // name: 'item 1',
                name: item,
              },
              {
                id: uuid(),
                image:
                  'https://images.unsplash.com/photo-1543857778-c4a1a3e0b2eb?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwyfHxjb2xvciUyMHJvc2V8ZW58MHx8fHwxNjc3NzYyNzk2&ixlib=rb-4.0.3&q=80&w=1080',

                name: item2,
              },
            ] as PortfolioItemsGroup[],
          },
        ],
        variant: variant.id as PortfolioVariants,
        tags: tags[variant.id as PortfolioVariants],
        styleName: variant.name,
        designedBy: 'Abrar Muthana',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_2') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Title' }] }]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia' }],
        },
      ]
      const data: Section<PortfolioVariants, PortfolioVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'PORTFOLIO',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          { id: uuid(), category: 'TEXT', data: { top: 0, bottom: 0, left: 0, right: 0 }, name: 'MARGIN' },
          { id: uuid(), category: 'TEXT', data: { top: 0, bottom: 0, left: 0, right: 0 }, name: 'PADDING' },
          { id: uuid(), category: 'IMAGE', data: transition, name: 'TRANSITION' },
          {
            id: uuid(),
            category: 'TEXT',
            name: 'BACKGROUND',
            data: ' transparent',
          },
          {
            id: uuid(),
            category: 'IMAGE',
            name: 'ITEMS',
            data: [
              {
                id: uuid(),
                image: media,
                name: item,
              },
              {
                id: uuid(),
                image: media,
                name: item2,
              },
              {
                id: uuid(),
                image: media,
                name: item3,
              },
              {
                id: uuid(),
                image: media,
                name: item4,
              },
              {
                id: uuid(),
                image: media,
                name: item5,
              },
              {
                id: uuid(),
                image: media,
                name: item6,
              },
            ] as PortfolioItemsGroup[],
          },
        ],
        variant: variant.id as PortfolioVariants,
        tags: tags[variant.id as PortfolioVariants],
        styleName: variant.name,
        designedBy: 'Abrar Muthana',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_3') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Title' }] }]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique.Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitLorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similiqueLorem ipsum .',
            },
          ],
        },
      ]
      const data: Section<PortfolioVariants, PortfolioVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'PORTFOLIO',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          { id: uuid(), category: 'TEXT', data: { top: 0, bottom: 0, left: 0, right: 0 }, name: 'MARGIN' },
          { id: uuid(), category: 'TEXT', data: { top: 0, bottom: 0, left: 0, right: 0 }, name: 'PADDING' },
          { id: uuid(), category: 'IMAGE', data: transition, name: 'TRANSITION' },
          {
            id: uuid(),
            category: 'TEXT',
            name: 'BACKGROUND',
            data: ' transparent',
          },
          {
            id: uuid(),
            category: 'IMAGE',
            name: 'ITEMS',
            data: [
              {
                id: uuid(),
                image: media,
                name: item,
                // descriptions: ' Lorem ipsum dolor sit amet consectetur  ',
                descriptions: paragraph,
              },
              {
                id: uuid(),
                image: media,
                name: item2,
                // descriptions: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia',
                descriptions: paragraph,
              },
            ] as PortfolioItemsGroup[],
          },
        ],
        variant: variant.id as PortfolioVariants,
        tags: tags[variant.id as PortfolioVariants],
        styleName: variant.name,
        designedBy: 'Abrar Muthana',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_4') {
      const description: Descendant[] = [{ type: 'paragraph', children: [{ text: '2022' }] }]
      const description2: Descendant[] = [{ type: 'paragraph', children: [{ text: '2011' }] }]
      const description3: Descendant[] = [{ type: 'paragraph', children: [{ text: '1997' }] }]
      const description4: Descendant[] = [{ type: 'paragraph', children: [{ text: '1980' }] }]

      const data: Section<PortfolioVariants, PortfolioVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'PORTFOLIO',
        updatedAt: '',
        variables: [
          {
            id: uuid(),
            category: 'TEXT',
            name: 'BACKGROUND',
            data: ' black',
          },
          { id: uuid(), category: 'TEXT', data: { top: 0, bottom: 0, left: 0, right: 0 }, name: 'MARGIN' },
          { id: uuid(), category: 'TEXT', data: { top: 0, bottom: 0, left: 0, right: 0 }, name: 'PADDING' },
          { id: uuid(), category: 'IMAGE', data: transition, name: 'TRANSITION' },

          {
            id: uuid(),
            category: 'IMAGE',
            name: 'ITEMS',
            data: [
              {
                id: uuid(),
                image: media,
                name: item,
                title: item,
                descriptions: description,
              },
              {
                id: uuid(),
                image: media,
                name: item2,
                title: item2,
                descriptions: description2,
              },
              {
                id: uuid(),
                image: media,
                name: item3,
                title: item3,
                descriptions: description3,
              },
              {
                id: uuid(),
                image: media,
                name: item4,
                title: item4,
                descriptions: description4,
              },
            ] as PortfolioItemsGroup[],
          },
        ],
        variant: variant.id as PortfolioVariants,
        tags: tags[variant.id as PortfolioVariants],
        styleName: variant.name,
        designedBy: 'Abrar Muthana ',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_6') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'YOLO Design' }] }]
      const item: Descendant[] = [{ type: 'paragraph', children: [{ text: 'YOLO Startup Design' }] }]
      const description: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'The 13 Best Drip Coffee Makers For Home In 2022 Ranked & Reviewed' }],
        },
      ]
      const data: Section<PortfolioVariants, PortfolioVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'PORTFOLIO',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          {
            id: uuid(),
            category: 'TEXT',
            name: 'BACKGROUND',
            data: ' black',
          },
          { id: uuid(), category: 'TEXT', data: { top: 0, bottom: 0, left: 0, right: 0 }, name: 'MARGIN' },
          { id: uuid(), category: 'TEXT', data: { top: 0, bottom: 0, left: 0, right: 0 }, name: 'PADDING' },
          { id: uuid(), category: 'IMAGE', data: transition, name: 'TRANSITION' },

          {
            id: uuid(),
            category: 'IMAGE',
            name: 'ITEMS',
            data: [
              {
                id: uuid(),
                image:
                  'https://images.unsplash.com/photo-1580086319619-3ed498161c77?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw3fHxneW1zfGVufDB8fHx8MTY3ODAxNzk1OQ&ixlib=rb-4.0.3&q=80&w=1080',

                title: item,
                descriptions: description,
              },
              {
                id: uuid(),
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-4b76341e-3c1f-4137-9369-075855a9b5c4',

                title: item,
                descriptions: description,
              },
              {
                id: uuid(),
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-023af45c-9d02-4ed6-905c-14169a68b88b',
                title: item,
                descriptions: description,
              },
              {
                id: uuid(),
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-66d6162e-1520-4f99-b7c8-2880c3bc3077',
                title: item,
                descriptions: description,
              },
              {
                id: uuid(),
                image:
                  'https://images.unsplash.com/photo-1576678927484-cc907957088c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxneW18ZW58MHx8fHwxNjc4MDE3OTQ1&ixlib=rb-4.0.3&q=80&w=1080',
                title: item,
                descriptions: description,
              },
            ] as PortfolioItemsGroup[],
          },
        ],
        variant: variant.id as PortfolioVariants,
        tags: tags[variant.id as PortfolioVariants],
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else {
      const data: Section<PortfolioVariants, PortfolioVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'PORTFOLIO',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: SubTitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          { id: uuid(), category: 'IMAGE', data: media, name: 'MEDIA' },
          {
            id: uuid(),
            category: 'TEXT',
            name: 'HRTITLEBACKGROUND',
            data: ' black',
          },
          {
            id: uuid(),
            category: 'TEXT',
            name: 'BACKGROUND',
            data: ' transparent',
          },
          { id: uuid(), category: 'TEXT', data: { top: 0, bottom: 0, left: 0, right: 0 }, name: 'MARGIN' },
          { id: uuid(), category: 'TEXT', data: { top: 0, bottom: 0, left: 0, right: 0 }, name: 'PADDING' },
          { id: uuid(), category: 'IMAGE', data: transition, name: 'TRANSITION' },
          {
            id: uuid(),
            category: 'IMAGE',
            name: 'ITEMS',
            data: [
              {
                id: uuid(),
                image: media,
                // name: 'item 1',
                // descriptions: ' Lorem ipsum dolor sit amet consectetur  ',
                title: item,
                descriptions: paragraph,
              },
              {
                id: uuid(),
                image: media,
                // name: 'item 2',
                // descriptions: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia',
                title: item2,
                descriptions: paragraph,
              },
              {
                id: uuid(),
                image: media,
                // name: ' item 3',
                // descriptions: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia',
                title: item3,
                descriptions: paragraph,
              },
              {
                id: uuid(),
                image: media,
                // name: 'item 4',
                // descriptions: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia',
                title: item4,
                descriptions: paragraph,
              },
            ] as PortfolioItemsGroup[],
          },
        ],
        variant: variant.id as PortfolioVariants,
        tags: tags[variant.id as PortfolioVariants],
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    }
  })
  return sections
}
