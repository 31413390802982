import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { FAQSection, FAQSectionItem } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantEight({ section }: { section: FAQSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const faqs = section.variables.find(variable => variable.name === 'ITEMS')?.data as FAQSectionItem[]
  return (
    <section
      className="md:py-12 py-4"
      style={{
        ...generateSectionBackground({
          defaultColor: '#b654c5',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="text-center text-[24px] md:text-[40px] font-semibold md:font-bold px-4 md:px-0 text-white">
        <h6>
          <SectionSlatePreview initialValue={title} previewMode />
        </h6>
      </div>
      <div className="grid grid-cols-3 gap-y-6 md:gap-y-0 md:flex max-w-[700px] justify-between items-center mx-auto py-12">
        {faqs?.map(faq => (
          <div className="mx-auto">
            <img className="w-[78px] h-auto" src={faq?.image} alt="" />
          </div>
        ))}
      </div>
    </section>
  )
}
