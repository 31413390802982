import {
  CreateNotificationInput,
  CreateNotificationMutation,
  GetNotificationQuery,
  ListByToNotifyIDQuery,
  NotificationStatus,
  OnCreateNotificationSubscription,
  OnDeleteNotificationSubscription,
  OnUpdateNotificationSubscription,
  Topics,
  UpdateNotificationInput,
  UpdateNotificationMutation,
} from 'src/API'
import { IUser } from 'src/components/context/UserContext'
import { createNotification, updateNotification } from 'src/graphql/mutations'
import { getNotification, listByToNotifyID } from 'src/graphql/queries'
import { onCreateNotification, onDeleteNotification, onUpdateNotification } from 'src/graphql/subscriptions'
import { graphqlQuery, graphqlSubscibe } from 'src/util/graphql'

export interface INotification {
  id: string
  owner: string
  creator: {
    firstName: string
    lastName: string
    profilePicture?: string
    profilePics?: {
      key: string
      src: string | null
    } | null
  }
  toNotifyID: string
  toNotify: IUser
  topic: Topics
  topicDescription?: string
  topicID: string
  topicUrl?: string
  status: NotificationStatus
  createdAt?: string
}

export const notificationSubscriptions = () => {
  const onCreate = graphqlSubscibe<OnCreateNotificationSubscription>({
    query: onCreateNotification,
  })

  const onUpdate = graphqlSubscibe<OnUpdateNotificationSubscription>({
    query: onUpdateNotification,
  })

  const onDelete = graphqlSubscibe<OnDeleteNotificationSubscription>({
    query: onDeleteNotification,
  })

  return { onCreate, onUpdate, onDelete }
}

export const createNewNotification = async (notifyData: CreateNotificationInput) => {
  try {
    const { data } = await graphqlQuery<CreateNotificationMutation>({
      query: createNotification,
      variables: { input: notifyData },
    })

    return data?.createNotification || {}
  } catch (error) {
    console.error('@notification.service::createNewNotification::error', error)
    throw error
  }
}

const determineTopicDescription = (notification: any) => {
  if (notification.topicDescription && notification.topicDescription.length > 0) return notification.topicDescription

  // Backward compatibility:
  // Handle circumstance when there are still unconsumed notifications
  // of the old model structure
  const topic = notification.topic
  const str = topic.split('_').join(' ')
  const desc = str.toLowerCase()
  return desc
}

const determineTopicPath = (notification: any) => {
  if (notification.topicUrl && notification.topicUrl.length > 0) {
    return notification.topicUrl
  }

  // Backward compatibility:
  // Handle circumstance when there are still unconsumed notifications
  // of the old model structure
  const topic = notification.topic
  switch (topic) {
    case 'NEW_POST_LIKE':
      return '/posts/' + notification.topicID

    case 'NEW_POST_COMMENT':
      return '/posts/' + notification.topicID

    default:
      break
  }
}

export const getNotificationById = async (id: string) => {
  const { data } = await graphqlQuery<GetNotificationQuery>({
    query: {
      key: 'service:getNotificationById',
      graphql: getNotification,
      maxDepth: 4,
    },
    variables: { id },
  })

  const notification = data?.getNotification
  return {
    ...notification,
    topicDescription: determineTopicDescription(notification),
    topicUrl: determineTopicPath(notification),
  }
}

export const fetchNotification = async (id: string) => {
  try {
    const { data } = await graphqlQuery<ListByToNotifyIDQuery>({
      query: {
        key: 'service:fetchNotification',
        graphql: listByToNotifyID,
        maxDepth: 4,
        omit: ['listByToNotifyID.items.toNotify'],
      },
      variables: {
        toNotifyID: id,
        filter: { owner: { ne: null } },
        sortDirection: 'DESC',
        limit: 100,
      },
    })

    if (!data?.listByToNotifyID?.items || data.listByToNotifyID.items.length <= 0) return []

    const notifications = await Promise.all(
      data.listByToNotifyID.items
        .filter(item => !!item?.creator)
        .map(async (item: any) => ({
          ...item,
          topicDescription: determineTopicDescription(item),
          topicUrl: determineTopicPath(item),
        })),
    )

    return notifications
  } catch (error) {
    console.error('@notification.service::fetchNotification::error', error)
    throw error
  }
}

export const markNotificationAsRead = async (toUpdate: UpdateNotificationInput) => {
  const updateData = {
    owner: toUpdate.owner,
    id: toUpdate.id,
    status: NotificationStatus.READ,
    toNotifyID: toUpdate.toNotifyID,
    topic: toUpdate.topic,
    topicID: toUpdate.topicID,
    createdAt: toUpdate.createdAt,
  }

  try {
    const { data } = await graphqlQuery<UpdateNotificationMutation>({
      query: updateNotification,
      variables: { input: updateData },
    })
    if (data) return 'Update Successful'
  } catch (error) {
    console.error('@notification.service::updateNotification::error', error)
    throw error
  }
}
