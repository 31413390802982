import { motion } from 'framer-motion'
import { useState } from 'react'
import FontLoader from '../FontLoader'
import { MenuVariantProps, NewMenuLinkType } from '../menu'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../utils'

export default function MenuVariantSix({ menu, website }: MenuVariantProps) {
  return (
    <>
      <FontLoader settings={menu?.settings?.fontFamily} />
      <DesktopNavbar menu={menu} website={website} />
      <MobileNavbar menu={menu} website={website} />
    </>
  )
}

const DesktopNavbar = ({ menu, website }: MenuVariantProps) => {
  return (
    <motion.div
      initial={{ y: -70, opacity: 0 }}
      animate={{
        y: 0,
        opacity: 1,
        transition: {
          duration: 1,
        },
      }}
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'white' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className="hidden lg:block">
        <div className="px-12 mx-auto">
          <div className="flex items-center justify-between py-6">
            <div className="w-[25%]">
              <a href="/">
                {website?.config?.logo ? (
                  <img
                    className="cursor-pointer w-[80px] h-[80px] rounded-full"
                    src={website?.config?.logo}
                    alt={website?.name}
                  />
                ) : (
                  <p
                    className="text-xl tracking-wide font-bold "
                    style={{
                      ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                      ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                    }}
                  >
                    {website?.name}
                  </p>
                )}
              </a>
            </div>

            <div className="flex-1" />

            <ul className="flex justify-end gap-x-16 list-none">
              {menu.links.map(({ id, name, type, url }) => (
                <li
                  key={id}
                  className="relative  hover:scale-110 transition-all duration-500"
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  <a
                    href={url}
                    target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                    className="text-xl font-semibold uppercase ease-in-out transition-all duration-500 hover:font-bold"
                  >
                    {name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

const MobileNavbar = ({ menu, website }: MenuVariantProps) => {
  const [show, setShow] = useState(false)
  return (
    <div className="block lg:hidden">
      <div className="flex justify-between px-4 py-4">
        <a href="/">
          {website?.config?.logo ? (
            <img
              className="cursor-pointer w-[30px] h-[30px] rounded-full "
              src={website?.config?.logo}
              alt={website?.name}
            />
          ) : (
            <p
              className="capitalize font-bold p-2 rounded"
              style={{
                ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '#eee' }),
                ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
              }}
            >
              {website?.name}
            </p>
          )}
        </a>
        {show ? (
          <button
            className="cursor-pointer uppercase outline-none text-[12px] border-none rounded p-2"
            onClick={() => setShow(false)}
            style={{
              ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '#eee' }),
              ...getSpacing(menu?.settings?.spacing),
              ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
              ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
            }}
          >
            close
          </button>
        ) : (
          <button
            className="cursor-pointer uppercase outline-none text-[12px] border-none rounded p-2"
            onClick={() => setShow(true)}
            style={{
              ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '#eee' }),
              ...getSpacing(menu?.settings?.spacing),
              ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
              ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
            }}
          >
            Menu
          </button>
        )}
      </div>

      {show && (
        <div
          style={{
            ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'white' }),
            ...getSpacing(menu?.settings?.spacing),
          }}
        >
          <motion.ul
            animate={{
              y: show ? [-280, 0] : -280,
            }}
            transition={{
              duration: 0.7,
              type: 'spring',
            }}
            className="py-4 overflow-hidden list-none flex flex-col gap-y-2  items-center justify-center"
          >
            {menu.links.map(({ id, name, url, type }) => {
              return (
                <motion.li
                  initial={{ opacity: 0, y: 170 }}
                  animate={{
                    opacity: 1,
                    y: show ? 0 : -170,
                    transition: {
                      duration: 0.8,
                      delay: 0.1,
                    },
                  }}
                  key={id}
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  <a
                    href={url}
                    target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                    className="flex items-center gap-y-1"
                  >
                    <span className="hover:scale-110 uppercase transition-all duration-300"> {name}</span>
                  </a>
                </motion.li>
              )
            })}
          </motion.ul>
        </div>
      )}
    </div>
  )
}
