import { useContext, useEffect, useState } from 'react'
import clsx from 'clsx'
import { WebsiteContext } from 'src/components/context/WebsiteContext'
import { Loader } from 'src/components/loader'
import { getPagesByWebsite } from 'src/lib/services/website.service'

type InternalPageSelectorProps = {
  defaultValue?: string
  onSelect?: (path: string | undefined) => void
}

export default function InternalPageSelector({ defaultValue, onSelect }: InternalPageSelectorProps) {
  const { websiteID } = useContext(WebsiteContext)

  const [pages, setPages] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [currentPage] = useState(defaultValue)

  const fetchPages = async () => {
    setLoading(true)
    await getPagesByWebsite(websiteID).then(_pages => {
      setPages(_pages)
      setLoading(false)
    })
  }

  const handleSelect = (page: any) => {
    if (currentPage === page?.path) {
      onSelect?.(undefined)
    } else {
      onSelect?.(page?.path)
    }
  }

  useEffect(() => {
    fetchPages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="px-4">
      <Loader show={loading} />
      <div className="px-4 pt-5 pb-16 border border-black">
        <p className="text-[22px] font-light">Select Internal Page</p>
        <p className="test-[13px] font-light">
          an Internal Page is a page that is already part of the website, a page created previously.
        </p>

        <div className="mt-6 space-y-4">
          {pages.map(page => (
            <button
              key={page.id}
              type="button"
              className={clsx(
                'border border-black flex h-[60px] w-full',
                currentPage === page.path ? 'bg-gray-200' : 'bg-white',
              )}
              onClick={() => handleSelect(page)}
            >
              <div className="flex-1 flex items-center h-full px-5">
                <div className="w-14">
                  {page.path === '/' ? (
                    <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 0L29.7224 25.5H0.277568L15 0Z" fill="#D9D9D9" />
                    </svg>
                  ) : (
                    <svg width="31" height="35" viewBox="0 0 31 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="path-1-inside-1_2493_6909" fill="white">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.8603 10.6074L10.6449 19.8967L18.9857 17.9388V34.3396H0V10.6074H11.8603ZM12.8299 10.6074L18.9857 16.1739V10.6074H12.8299Z"
                        />
                      </mask>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.8603 10.6074L10.6449 19.8967L18.9857 17.9388V34.3396H0V10.6074H11.8603ZM12.8299 10.6074L18.9857 16.1739V10.6074H12.8299Z"
                        fill="#C4C4C4"
                      />
                      <path
                        d="M10.6449 19.8967L10.1492 19.8318L10.0554 20.5486L10.7592 20.3834L10.6449 19.8967ZM11.8603 10.6074L12.3561 10.6723L12.43 10.1074H11.8603V10.6074ZM18.9857 17.9388H19.4857V17.3078L18.8715 17.452L18.9857 17.9388ZM18.9857 34.3396V34.8396H19.4857V34.3396H18.9857ZM0 34.3396H-0.5V34.8396H0V34.3396ZM0 10.6074V10.1074H-0.5V10.6074H0ZM12.8299 10.6074V10.1074H11.5315L12.4945 10.9783L12.8299 10.6074ZM18.9857 16.1739L18.6504 16.5448L19.4857 17.3002V16.1739H18.9857ZM18.9857 10.6074H19.4857V10.1074H18.9857V10.6074ZM11.1407 19.9615L12.3561 10.6723L11.3645 10.5426L10.1492 19.8318L11.1407 19.9615ZM18.8715 17.452L10.5307 19.4099L10.7592 20.3834L19.1 18.4256L18.8715 17.452ZM18.4857 17.9388V34.3396H19.4857V17.9388H18.4857ZM18.9857 33.8396H0V34.8396H18.9857V33.8396ZM0.5 34.3396V10.6074H-0.5V34.3396H0.5ZM0 11.1074H11.8603V10.1074H0V11.1074ZM12.4945 10.9783L18.6504 16.5448L19.3211 15.8031L13.1652 10.2366L12.4945 10.9783ZM18.4857 10.6074V16.1739H19.4857V10.6074H18.4857ZM12.8299 11.1074H18.9857V10.1074H12.8299V11.1074Z"
                        fill="black"
                        mask="url(#path-1-inside-1_2493_6909)"
                      />
                      <path
                        d="M9.46614 20.9757C9.32568 21.0057 9.20037 20.8822 9.22822 20.7413L11.2793 10.367C11.3102 10.2107 11.5024 10.1515 11.6159 10.2633L19.9074 18.4317C20.0208 18.5435 19.9645 18.7366 19.8087 18.7698L9.46614 20.9757Z"
                        fill="white"
                        stroke="#383838"
                        strokeWidth="0.2"
                      />
                      <rect
                        x="22.5107"
                        width="11.759"
                        height="14.2393"
                        transform="rotate(47.6632 22.5107 0)"
                        fill="white"
                      />
                    </svg>
                  )}
                </div>
                <p className="text-[25px]">{page.name}</p>
              </div>

              <div className="h-full w-[85px] flex justify-center items-center border-l border-black">
                {currentPage === page.path ? (
                  <svg width="25" height="3" viewBox="0 0 25 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.96 2.76V0H24.12V2.76H0.96Z" fill="#525252" />
                  </svg>
                ) : (
                  <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M18.6 9.44V11.96H10.8V20.42H8.04V11.96H0.18V9.44H8.04V0.979999H10.8V9.44H18.6Z"
                      fill="#525252"
                    />
                  </svg>
                )}
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}
