import Emoji from 'src/icons/Emoji'
import AddImage from 'src/icons/AddImage'
import SendMessage from 'src/icons/SendMessage'

type ChatFooterProps = {
  onImageAddClick?: () => void
  onEmojiAddClick?: () => void
  search: any
}

export default function ChatFooter({ onImageAddClick, onEmojiAddClick, search }: ChatFooterProps) {
  return (
    <div>
      {search ? (
        <div className=" md:bg-white bg-[#D9D9D9] xl:h-[100px] md:h-[100px] pt-2 dark:bg-white md:pt-4 md:pb-8 absolute bottom-12 md:bottom-0 left-0 right-0 pb-8  px-3 border-t md:border border-black  ">
          <div className=" flex md:bg-[#EFEFEF] rounded-lg	 2xl:w-[68%] xl:w-[64%] lg:w-[60%] md:w-[56%] gap-x-3 md:gap-x-4 md:px-6 pt-2 items-center ">
            <button type="button" onClick={onImageAddClick}>
              <AddImage className="dark:text-black hidden xl:block" size={48} />
              <AddImage className="dark:text-black block xl:hidden " size={36} />
            </button>
            <div className="md:hidden h-[46px] xl:w-[1.2px] lg:w-[1.2px] md:w-[1.2px] w-[1.2px] bg-black dark:hidden" />
            <button type="button" onClick={onEmojiAddClick}>
              <Emoji className="dark:text-black hidden xl:block " size={38} />
              <Emoji className="dark:text-black block xl:hidden " size={32} />
            </button>
            <div className="h-[46px] w-[1px] bg-black block xl:hidden lg:hidden md:hidden dark:hidden" />
            <div className="w-full">
              <input
                className="outline-none w-full dark:bg-[#EFEFEF] rounded-lg bg-transparent h-[58px] placeholder:text-black placeholder:font-semibold md:placeholder:font-normal md:placeholder:text-[18px] px-4"
                type="text"
                placeholder="Type a message"
              />
            </div>
            <div className="dark:block hidden mt-2 -ml-2">
              <button type="submit" className="outline-none">
                <SendMessage />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className=" md:bg-white bg-[#D9D9D9] xl:h-[100px] md:h-[100px] pt-2 dark:bg-white md:pt-4 md:pb-8 absolute bottom-12 md:bottom-0 left-0 right-0 pb-8  px-3 border-t md:border border-black  ">
          <div className=" flex  rounded-lg	 2xl:w-[70%] lg:w-[60%] xl:w-[64%] md:w-[56%] gap-x-3 md:gap-x-4 md:px-6 pt-2 items-center ">
            <button type="button" onClick={onImageAddClick}>
              <AddImage className="dark:text-black hidden xl:block" size={48} />
              <AddImage className="dark:text-black block xl:hidden " size={36} />
            </button>
            <div className="h-[46px] xl:w-[1.2px] lg:w-[1.2px] md:w-[1.2px] w-[1.2px] bg-black dark:hidden" />
            <button type="button" onClick={onEmojiAddClick}>
              <Emoji className="dark:text-black hidden xl:block " size={38} />
              <Emoji className="dark:text-black block xl:hidden " size={32} />
            </button>
            <div className="h-[46px] w-[1px] bg-black block md:hidden dark:hidden" />
            <div className="w-full">
              <input
                className="outline-none w-full md:bg-[#EFEFEF] dark:bg-[#D9D9D9]  rounded-lg bg-transparent md:h-[58px] h-[50px] placeholder:text-black placeholder:font-semibold md:placeholder:font-normal md:placeholder:text-[18px] px-4"
                type="text"
                placeholder="Type a message"
              />
            </div>
            <div className="dark:block hidden mt-2 -ml-2">
              <button type="submit" className="outline-none">
                <SendMessage />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
