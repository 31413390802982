import Icon from './icons'
import { IconPropsT } from './types'

function Emoji(props: IconPropsT) {
  const { title = 'Emoji', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 24 24" size={size} title={title} {...otherProps}>
      <g clipPath="url(#clip0_815_2602)">
        <path d="M11.9893 2C10.0247 2 8.10415 2.57819 6.47058 3.6614C4.837 4.7446 3.56378 6.28421 2.8119 8.08553C2.06002 9.88685 1.86326 11.869 2.24648 13.7813C2.62971 15.6936 3.57571 17.4501 4.96488 18.8289C6.35406 20.2076 8.12398 21.1466 10.0509 21.5271C11.9778 21.9075 13.975 21.7124 15.7902 20.9664C17.6054 20.2204 19.1568 18.957 20.2485 17.3359C21.3401 15.7148 21.9228 13.8089 21.923 11.8591C21.92 9.2454 20.8724 6.73957 19.0102 4.89131C17.148 3.04304 14.623 2.00322 11.9893 2ZM11.9893 20.8958C10.1881 20.896 8.42738 20.366 6.92968 19.3731C5.43197 18.3801 4.26462 16.9686 3.57526 15.3172C2.8859 13.6657 2.70549 11.8486 3.05685 10.0954C3.4082 8.34217 4.27554 6.73179 5.54917 5.46784C6.82279 4.20387 8.44551 3.3431 10.2121 2.99441C11.9787 2.64572 13.8098 2.82475 15.4739 3.50888C17.1379 4.19301 18.5601 5.35152 19.5607 6.83787C20.5613 8.32417 21.0953 10.0716 21.0951 11.8591C21.0923 14.255 20.1321 16.5519 18.425 18.246C16.7179 19.9401 14.4035 20.8931 11.9893 20.8958Z" />
        <path d="M8.67748 10.2159C9.13467 10.2159 9.50535 9.84812 9.50535 9.39437C9.50535 8.94063 9.13467 8.57275 8.67748 8.57275C8.22025 8.57275 7.84961 8.94063 7.84961 9.39437C7.84961 9.84812 8.22025 10.2159 8.67748 10.2159Z" />
        <path d="M15.3005 10.2159C15.7577 10.2159 16.1284 9.84812 16.1284 9.39437C16.1284 8.94063 15.7577 8.57275 15.3005 8.57275C14.8433 8.57275 14.4727 8.94063 14.4727 9.39437C14.4727 9.84812 14.8433 10.2159 15.3005 10.2159Z" />
        <path d="M11.9894 16.7877C15.4666 16.7877 16.8162 15.0538 16.8725 14.98C16.9361 14.893 16.9628 14.7846 16.9468 14.6782C16.9308 14.5719 16.8733 14.476 16.7869 14.4111C16.7003 14.3463 16.5917 14.3177 16.4842 14.3315C16.3767 14.3453 16.279 14.4004 16.212 14.485C16.1655 14.5457 15.0241 15.9661 11.9894 15.9661C8.95383 15.9661 7.81243 14.5449 7.76754 14.4874C7.7349 14.4442 7.694 14.408 7.6472 14.3805C7.60039 14.353 7.5486 14.335 7.49477 14.3274C7.44094 14.3197 7.38613 14.3227 7.33349 14.3362C7.28081 14.3496 7.23137 14.3732 7.18788 14.4056C7.14439 14.438 7.10779 14.4786 7.08009 14.525C7.05243 14.5715 7.03422 14.6228 7.02656 14.6762C7.01894 14.7297 7.02196 14.7841 7.03549 14.8363C7.04898 14.8886 7.07277 14.9377 7.10541 14.9808C7.16094 15.0562 8.51132 16.7877 11.9894 16.7877Z" />
      </g>
      <defs>
        <clipPath id="clip0_815_2602">
          <rect width="20" height="20" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </Icon>
  )
}

export default Emoji
