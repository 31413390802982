import { useEffect, useState } from 'react'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { PortfolioItemsGroup, PortfolioSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
export default function VariantSix({ section }: { section: PortfolioSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const data = section.variables.find(variable => variable.name === 'ITEMS')?.data as PortfolioItemsGroup[]
  const [firstItem, setFirstItem] = useState<any>(null)
  const [oddItems, setOddItems] = useState<any>([])
  const [evenItems, setEvenItems] = useState<any>([])

  useEffect(() => {
    if (data?.length > 0) {
      setFirstItem(data[0])
    }
  }, [data])

  useEffect(() => {
    const filterIndex = data?.filter((_item, index) => index !== 0)
    const odd = filterIndex.filter((_item, index) => index % 2 === 1)
    const even = filterIndex.filter((_item, index) => index % 2 === 0)
    setOddItems(odd)
    setEvenItems(even)
  }, [data])

  return (
    <div
      className="px-6 py-6"
      style={{
        ...generateSectionBackground({
          defaultColor: '#eee',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="md:px-12 px-4">
        <div className="text-center md:flex md:items-center md:justify-between">
          <hr className="hidden md:block h-[2px] bg-[#ff692d] w-[25%]" />
          <h2 className="text-[32px] md:text-7xl text-[#ff692d] font-semibold md:font-bold">
            <SectionSlatePreview initialValue={title} previewMode />
          </h2>
          <hr className="hidden md:block h-[2px] bg-[#ff692d] w-[25%]" />
        </div>
        <div className="grid md:flex justify-between items-center gap-y-3 md:gap-6">
          <div className="w-full grid grid-cols-2 gap-4 md:grid-cols-1 md:gap-0 md:w-[25%] md:space-y-10">
            {oddItems?.map((item: any) => (
              <div key={item?.id} className="text-center md:space-y-6">
                <img className="w-full h-[150px] md:h-[300px]" src={item?.image} alt="" />
                <h4 className="text-[#ff692d] text-[14px] uppercase">
                  <SectionSlatePreview initialValue={item?.title} previewMode />
                </h4>
                <p className="text-[18px]">
                  <SectionSlatePreview initialValue={item?.description} previewMode />
                </p>
              </div>
            ))}
          </div>
          <div className="order-first md:order-none w-full md:w-[50%]">
            <img className="w-full z-10" src={firstItem?.image} alt="" />
            <div className="text-center bg-white space-y-6 mx-4 z-50">
              <h2 className="text-[#ff692d] text-[14px] uppercase">
                <SectionSlatePreview initialValue={firstItem?.title} previewMode />
              </h2>
              <p className="text-[26px]">
                <SectionSlatePreview initialValue={firstItem?.description} previewMode />
              </p>
            </div>
          </div>
          <div className="md:order-last w-full grid grid-cols-2 gap-4 md:grid-cols-1 md:gap-0 md:w-[25%] md:space-y-10">
            {evenItems?.map((item: any) => (
              <div key={item?.id} className="text-center  md:space-y-6">
                <img className="w-full h-[150px] md:h-[300px]" src={item?.image} alt="" />
                <h4 className="text-[#ff692d] text-[14px] uppercase">
                  <SectionSlatePreview initialValue={item?.title} previewMode />
                </h4>
                <p className="text-[18px]">
                  <SectionSlatePreview initialValue={item?.description} previewMode />
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
