import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import BackIcon from 'src/icons/BackIcon'
import Close from 'src/icons/Close'
import Logo from 'src/icons/Logo'
import { getWebsiteByID } from '../../lib/services/website.service'
import Modal from '../common/Modal'
import { WebsiteContext } from '../context/WebsiteContext'
import Menu from '../layout/NavBar2'
import ModalDialog from './ModalDialog'

export interface ModalProps {
  open: any
  bgFill?: any
  onBack?: () => void
  onNext?: () => void
  onClose?: () => void
  hideNext?: boolean
  hideLogo?: boolean
  showModal?: string
  setShowModal?: any
}

interface ModalDialogProps extends ModalProps {
  children: any
  title?: React.ReactNode
  tutorial?: any
  tutorialCount?: any
  parentId?: string
}

const ModalDialogV2 = ({
  title,
  open,
  onBack,
  children,
  bgFill,
  hideLogo,
  onNext,
  hideNext,
  onClose,
  tutorial,
  tutorialCount,
  parentId,
  setShowModal,
  showModal = '',
}: ModalDialogProps) => {
  const currentLocation = useLocation()
  const { websiteID } = useContext(WebsiteContext)

  const [location] = useState(currentLocation)
  const [logo, setLogo] = useState()
  const [showMenu, setShowMenu] = useState(false)

  const handleBackModal = () => {
    if (showModal === 'preview' || showModal === 'edit') {
      setShowModal('post')
    }
  }

  useEffect(() => {
    if (currentLocation !== location) onBack?.()
  }, [onBack, currentLocation, location])

  useEffect(() => {
    if (websiteID) {
      getWebsiteByID(websiteID).then(data => {
        const config = JSON.parse(data?.config || '{}')
        setLogo(config?.logo || undefined)
      })
    }
  }, [websiteID])

  return (
    <>
      <Modal open={open}>
        <div
          id={parentId}
          className={`fixed lg:max-w-[500px] inset-0 overflow-auto ${
            bgFill ? 'bg-white/90 dark:bg-black/90' : 'bg-white dark:bg-black'
          }`}
        >
          <div className="flex items-center justify-between px-5 pb-5 pt-[21px]">
            <div className="flex items-center">
              {onBack && (
                <button type="button" className="flex-shrink-0" onClick={showModal ? handleBackModal : onBack}>
                  <BackIcon
                    size={28}
                    fill="none"
                    className="fill-white dark:fill-black stroke-black dark:stroke-white cursor-pointer mr-[16px]"
                  />
                </button>
              )}
              {!hideLogo && (
                <Link to="/" className="">
                  <Logo
                    size={28}
                    fill="none"
                    className="fill-white dark:fill-black stroke-black dark:stroke-white cursor-pointer"
                  />
                </Link>
              )}
            </div>
            <div>
              {logo ? (
                // eslint-disable-next-line
                <button
                  type="button"
                  className="border-2 border-black rounded-md h-[50px] p-[4px] ml-4 mt-1 hidden"
                  onClick={() => setShowMenu(true)}
                >
                  <div className="w-[40px] h-full flex items-center justify-center ">
                    <img src={logo} alt="" className="border-2 border-black rounded-md w-full h-[40px] object-cover" />
                  </div>
                </button>
              ) : (
                // <MenuIcon className="cursor-pointer" onClick={() => setShowMenu(true)} />
                <button
                  type="button"
                  className="border-2 border-black rounded-md h-[50px] p-[4px] ml-4 mt-1 hidden"
                  onClick={() => setShowMenu(true)}
                >
                  <div className="w-[40px] h-full flex items-center justify-center ">
                    <img src={logo} alt="" className="border-2 border-black rounded-md w-full h-[40px] object-cover" />
                  </div>
                </button>
              )}
              {onNext && !hideNext && (
                <button type="button" className="rotate-180" onClick={onNext}>
                  <BackIcon
                    size={28}
                    fill="none"
                    className="fill-white dark:fill-black stroke-black dark:stroke-white cursor-pointer mr-[16px]"
                  />
                </button>
              )}
            </div>
            <div>
              {onClose && (
                <button type="button" onClick={onClose}>
                  <Close
                    size={24}
                    fill="white"
                    className="fill-white dark:fill-black stroke-black ml-auto dark:stroke-white cursor-pointer"
                  />
                </button>
              )}
            </div>
          </div>
          {tutorial && tutorialCount <= 2 ? (
            <div className="px-5">
              <h1 className="flex-grow text-[19px] font-extralight dark:text-white pb-[12px] border-b border-black dark:border-white">
                {tutorial?.title}
              </h1>
              <p className="flex-grow text-[19px] dark:text-white pt-[12px] pb-[26px]">{tutorial?.description}</p>
            </div>
          ) : (
            <h1 className="flex-grow text-[34px] dark:text-white px-5 pb-[17px]">{title}</h1>
          )}
          {children}
        </div>
      </Modal>

      {/* menu modal */}
      <ModalDialog title="Menu" open={showMenu} onClose={() => setShowMenu(false)}>
        <Menu />
      </ModalDialog>
    </>
  )
}

export default ModalDialogV2
