import { AboutUsSection as AboutUsSectionType } from '../../types'
import SectionSlate from '../../../common/SectionSlatePreview'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

interface VariantFourProps {
  section: AboutUsSectionType
}

export default function VariantFour({ section }: VariantFourProps) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const buttonOne = section.variables.find(variable => variable.name === 'BUTTON_1')?.data
  const buttonTwo = section.variables.find(variable => variable.name === 'BUTTON_2')?.data

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: '#EFEFEF',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="container mx-auto py-12 px-6 md:px-20 md:py-[100px] font-light lg:space-y-5">
        {title && (
          <div>
            <p className="text-[26px] lg:text-4xl font-bold ">{<SectionSlate initialValue={title} previewMode />}</p>
          </div>
        )}

        {subTitle && (
          <div>
            <p className="md:text-[24px] font-semibold">{<SectionSlate initialValue={subTitle} previewMode />}</p>
          </div>
        )}

        {paragraph && (
          <div>
            <p className="text-[14px] md:tex-[18px] md:leading-7">
              {<SectionSlate initialValue={paragraph} previewMode />}
            </p>
          </div>
        )}

        {buttonOne && (
          <div className="flex justify-start gap-5 flex-wrap pt-3">
            <a className="h-11 px-8 bg-[#4A4C51] text-white flex justify-center items-center" href={buttonOne.to}>
              {buttonOne.label}
            </a>
            {buttonTwo && (
              <a className="h-11 px-8 border border-[#4A4C51] flex justify-center items-center " href={buttonTwo.to}>
                {buttonTwo.label}
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
