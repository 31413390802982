import Icon from './icons'
import { IconPropsT } from './types'

function ProfileBox(props: IconPropsT) {
  const { title = 'ProfileBox', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 120 120" size={size} title={title} {...otherProps}>
      <path
        d="M111 0H9C4.02944 0 0 4.02944 0 9V111C0 115.971 4.02944 120 9 120H111C115.971 120 120 115.971 120 111V9C120 4.02944 115.971 0 111 0Z"
        fill="#D9D9D9"
      />
      <mask id="path-2-inside-1_194_5835" fill="white">
        <path d="M63.0832 35C65.3342 35 67.5346 35.6675 69.4062 36.918C71.2778 38.1686 72.7365 39.9461 73.5979 42.0257C74.4593 44.1053 74.6847 46.3937 74.2455 48.6014C73.8064 50.8091 72.7225 52.837 71.1308 54.4286C69.5391 56.0203 67.5112 57.1043 65.3035 57.5434C63.0958 57.9825 60.8075 57.7571 58.7278 56.8957C56.6482 56.0343 54.8708 54.5756 53.6202 52.704C52.3696 50.8324 51.7021 48.632 51.7021 46.381C51.7021 43.3626 52.9012 40.4678 55.0356 38.3334C57.1699 36.1991 60.0648 35 63.0832 35Z" />
      </mask>
      <path
        d="M63.0832 35C65.3342 35 67.5346 35.6675 69.4062 36.918C71.2778 38.1686 72.7365 39.9461 73.5979 42.0257C74.4593 44.1053 74.6847 46.3937 74.2455 48.6014C73.8064 50.8091 72.7225 52.837 71.1308 54.4286C69.5391 56.0203 67.5112 57.1043 65.3035 57.5434C63.0958 57.9825 60.8075 57.7571 58.7278 56.8957C56.6482 56.0343 54.8708 54.5756 53.6202 52.704C52.3696 50.8324 51.7021 48.632 51.7021 46.381C51.7021 43.3626 52.9012 40.4678 55.0356 38.3334C57.1699 36.1991 60.0648 35 63.0832 35Z"
        fill="black"
      />
      <path
        d="M63.0832 36C65.1364 36 67.1434 36.6088 68.8506 37.7495L69.9617 36.0866C67.9257 34.7261 65.5319 34 63.0832 34V36ZM68.8506 37.7495C70.5577 38.8902 71.8883 40.5115 72.674 42.4084L74.5218 41.643C73.5847 39.3807 71.9978 37.447 69.9617 36.0866L68.8506 37.7495ZM72.674 42.4084C73.4597 44.3053 73.6653 46.3926 73.2648 48.4063L75.2263 48.7965C75.7041 46.3948 75.4589 43.9054 74.5218 41.643L72.674 42.4084ZM73.2648 48.4063C72.8642 50.42 71.8755 52.2697 70.4237 53.7215L71.8379 55.1358C73.5694 53.4042 74.7486 51.1981 75.2263 48.7965L73.2648 48.4063ZM70.4237 53.7215C68.9719 55.1734 67.1222 56.1621 65.1084 56.5626L65.4986 58.5242C67.9003 58.0465 70.1064 56.8673 71.8379 55.1358L70.4237 53.7215ZM65.1084 56.5626C63.0947 56.9632 61.0074 56.7576 59.1105 55.9719L58.3452 57.8196C60.6075 58.7567 63.0969 59.0019 65.4986 58.5242L65.1084 56.5626ZM59.1105 55.9719C57.2136 55.1861 55.5924 53.8556 54.4517 52.1484L52.7887 53.2596C54.1492 55.2956 56.0828 56.8825 58.3452 57.8196L59.1105 55.9719ZM54.4517 52.1484C53.311 50.4413 52.7021 48.4342 52.7021 46.381H50.7021C50.7021 48.8298 51.4283 51.2235 52.7887 53.2596L54.4517 52.1484ZM52.7021 46.381C52.7021 43.6278 53.7959 40.9874 55.7427 39.0405L54.3285 37.6263C52.0066 39.9482 50.7021 43.0974 50.7021 46.381H52.7021ZM55.7427 39.0405C57.6895 37.0937 60.33 36 63.0832 36V34C59.7995 34 56.6504 35.3044 54.3285 37.6263L55.7427 39.0405Z"
        fill="black"
        mask="url(#path-2-inside-1_194_5835)"
      />
      <mask id="path-4-inside-2_194_5835" fill="white">
        <path d="M63.0829 60.4482C57.7582 60.4482 52.6515 62.5629 48.8855 66.3271C45.1195 70.0914 43.0025 75.1971 43 80.5218C43 81.9435 43.5648 83.307 44.5701 84.3123C45.5754 85.3177 46.9389 85.8824 48.3606 85.8824H77.8147C79.2364 85.8824 80.5999 85.3177 81.6052 84.3123C82.6105 83.307 83.1753 81.9435 83.1753 80.5218C83.1741 77.8845 82.6534 75.2732 81.643 72.8371C80.6326 70.401 79.1522 68.1877 77.2865 66.3237C75.4207 64.4597 73.2061 62.9815 70.7691 61.9734C68.332 60.9652 65.7203 60.447 63.0829 60.4482Z" />
      </mask>
      <path
        d="M63.0829 60.4482C57.7582 60.4482 52.6515 62.5629 48.8855 66.3271C45.1195 70.0914 43.0025 75.1971 43 80.5218C43 81.9435 43.5648 83.307 44.5701 84.3123C45.5754 85.3177 46.9389 85.8824 48.3606 85.8824H77.8147C79.2364 85.8824 80.5999 85.3177 81.6052 84.3123C82.6105 83.307 83.1753 81.9435 83.1753 80.5218C83.1741 77.8845 82.6534 75.2732 81.643 72.8371C80.6326 70.401 79.1522 68.1877 77.2865 66.3237C75.4207 64.4597 73.2061 62.9815 70.7691 61.9734C68.332 60.9652 65.7203 60.447 63.0829 60.4482Z"
        fill="black"
      />
      <path
        d="M43 80.5218L42 80.5213V80.5218H43ZM83.1753 80.5218H84.1753V80.5213L83.1753 80.5218ZM63.0829 59.4482C57.4931 59.4482 52.1321 61.6682 48.1785 65.6198L49.5924 67.0344C53.1709 63.4576 58.0234 61.4482 63.0829 61.4482V59.4482ZM48.1785 65.6198C44.225 69.5715 42.0026 74.9315 42 80.5213L44 80.5223C44.0024 75.4627 46.0139 70.6112 49.5924 67.0344L48.1785 65.6198ZM42 80.5218C42 82.2087 42.6701 83.8266 43.863 85.0194L45.2772 83.6052C44.4594 82.7875 44 81.6783 44 80.5218H42ZM43.863 85.0194C45.0558 86.2123 46.6737 86.8824 48.3606 86.8824V84.8824C47.2041 84.8824 46.095 84.423 45.2772 83.6052L43.863 85.0194ZM48.3606 86.8824H77.8147V84.8824H48.3606V86.8824ZM77.8147 86.8824C79.5016 86.8824 81.1195 86.2123 82.3123 85.0194L80.8981 83.6052C80.0803 84.423 78.9712 84.8824 77.8147 84.8824V86.8824ZM82.3123 85.0194C83.5052 83.8266 84.1753 82.2087 84.1753 80.5218H82.1753C82.1753 81.6783 81.7159 82.7875 80.8981 83.6052L82.3123 85.0194ZM84.1753 80.5213C84.174 77.7527 83.6274 75.0113 82.5667 72.4539L80.7193 73.2202C81.6794 75.535 82.1741 78.0162 82.1753 80.5223L84.1753 80.5213ZM82.5667 72.4539C81.506 69.8965 79.9519 67.5731 77.9933 65.6163L76.5797 67.0312C78.3526 68.8024 79.7592 70.9054 80.7193 73.2202L82.5667 72.4539ZM77.9933 65.6163C76.0346 63.6595 73.7097 62.1076 71.1513 61.0493L70.3868 62.8974C72.7025 63.8553 74.8069 65.26 76.5797 67.0312L77.9933 65.6163ZM71.1513 61.0493C68.5929 59.991 65.8511 59.4469 63.0825 59.4482L63.0834 61.4482C65.5894 61.4471 68.0711 61.9395 70.3868 62.8974L71.1513 61.0493Z"
        fill="black"
        mask="url(#path-4-inside-2_194_5835)"
      />
      <mask id="path-6-inside-3_194_5835" fill="white">
        <path d="M61.0832 35C63.3342 35 65.5346 35.6675 67.4062 36.918C69.2778 38.1686 70.7365 39.9461 71.5979 42.0257C72.4593 44.1053 72.6847 46.3937 72.2455 48.6014C71.8064 50.8091 70.7225 52.837 69.1308 54.4286C67.5391 56.0203 65.5112 57.1043 63.3035 57.5434C61.0958 57.9825 58.8075 57.7571 56.7278 56.8957C54.6482 56.0343 52.8708 54.5756 51.6202 52.704C50.3696 50.8324 49.7021 48.632 49.7021 46.381C49.7021 43.3626 50.9012 40.4678 53.0356 38.3334C55.1699 36.1991 58.0648 35 61.0832 35Z" />
      </mask>
      <path
        d="M61.0832 35C63.3342 35 65.5346 35.6675 67.4062 36.918C69.2778 38.1686 70.7365 39.9461 71.5979 42.0257C72.4593 44.1053 72.6847 46.3937 72.2455 48.6014C71.8064 50.8091 70.7225 52.837 69.1308 54.4286C67.5391 56.0203 65.5112 57.1043 63.3035 57.5434C61.0958 57.9825 58.8075 57.7571 56.7278 56.8957C54.6482 56.0343 52.8708 54.5756 51.6202 52.704C50.3696 50.8324 49.7021 48.632 49.7021 46.381C49.7021 43.3626 50.9012 40.4678 53.0356 38.3334C55.1699 36.1991 58.0648 35 61.0832 35Z"
        fill="white"
      />
      <path
        d="M61.0832 36C63.1364 36 65.1434 36.6088 66.8506 37.7495L67.9617 36.0866C65.9257 34.7261 63.5319 34 61.0832 34V36ZM66.8506 37.7495C68.5577 38.8902 69.8883 40.5115 70.674 42.4084L72.5218 41.643C71.5847 39.3807 69.9978 37.447 67.9617 36.0866L66.8506 37.7495ZM70.674 42.4084C71.4597 44.3053 71.6653 46.3926 71.2648 48.4063L73.2263 48.7965C73.7041 46.3948 73.4589 43.9054 72.5218 41.643L70.674 42.4084ZM71.2648 48.4063C70.8642 50.42 69.8755 52.2697 68.4237 53.7215L69.8379 55.1358C71.5694 53.4042 72.7486 51.1981 73.2263 48.7965L71.2648 48.4063ZM68.4237 53.7215C66.9719 55.1734 65.1222 56.1621 63.1084 56.5626L63.4986 58.5242C65.9003 58.0465 68.1064 56.8673 69.8379 55.1358L68.4237 53.7215ZM63.1084 56.5626C61.0947 56.9632 59.0074 56.7576 57.1105 55.9719L56.3452 57.8196C58.6075 58.7567 61.0969 59.0019 63.4986 58.5242L63.1084 56.5626ZM57.1105 55.9719C55.2136 55.1861 53.5924 53.8556 52.4517 52.1484L50.7887 53.2596C52.1492 55.2956 54.0828 56.8825 56.3452 57.8196L57.1105 55.9719ZM52.4517 52.1484C51.311 50.4413 50.7021 48.4342 50.7021 46.381H48.7021C48.7021 48.8298 49.4283 51.2235 50.7887 53.2596L52.4517 52.1484ZM50.7021 46.381C50.7021 43.6278 51.7959 40.9874 53.7427 39.0405L52.3285 37.6263C50.0066 39.9482 48.7021 43.0974 48.7021 46.381H50.7021ZM53.7427 39.0405C55.6895 37.0937 58.33 36 61.0832 36V34C57.7995 34 54.6504 35.3044 52.3285 37.6263L53.7427 39.0405Z"
        fill="black"
        mask="url(#path-6-inside-3_194_5835)"
      />
      <mask id="path-8-inside-4_194_5835" fill="white">
        <path d="M61.0829 60.4482C55.7582 60.4482 50.6515 62.5629 46.8855 66.3271C43.1195 70.0914 41.0025 75.1971 41 80.5218C41 81.9435 41.5648 83.307 42.5701 84.3123C43.5754 85.3177 44.9389 85.8824 46.3606 85.8824H75.8147C77.2364 85.8824 78.5999 85.3177 79.6052 84.3123C80.6105 83.307 81.1753 81.9435 81.1753 80.5218C81.1741 77.8845 80.6534 75.2732 79.643 72.8371C78.6326 70.401 77.1522 68.1877 75.2865 66.3237C73.4207 64.4597 71.2061 62.9815 68.7691 61.9734C66.332 60.9652 63.7203 60.447 61.0829 60.4482Z" />
      </mask>
      <path
        d="M61.0829 60.4482C55.7582 60.4482 50.6515 62.5629 46.8855 66.3271C43.1195 70.0914 41.0025 75.1971 41 80.5218C41 81.9435 41.5648 83.307 42.5701 84.3123C43.5754 85.3177 44.9389 85.8824 46.3606 85.8824H75.8147C77.2364 85.8824 78.5999 85.3177 79.6052 84.3123C80.6105 83.307 81.1753 81.9435 81.1753 80.5218C81.1741 77.8845 80.6534 75.2732 79.643 72.8371C78.6326 70.401 77.1522 68.1877 75.2865 66.3237C73.4207 64.4597 71.2061 62.9815 68.7691 61.9734C66.332 60.9652 63.7203 60.447 61.0829 60.4482Z"
        fill="white"
      />
      <path
        d="M41 80.5218L40 80.5213V80.5218H41ZM81.1753 80.5218H82.1753V80.5213L81.1753 80.5218ZM61.0829 59.4482C55.4931 59.4482 50.1321 61.6682 46.1785 65.6198L47.5924 67.0344C51.1709 63.4576 56.0234 61.4482 61.0829 61.4482V59.4482ZM46.1785 65.6198C42.225 69.5715 40.0026 74.9315 40 80.5213L42 80.5223C42.0024 75.4627 44.0139 70.6112 47.5924 67.0344L46.1785 65.6198ZM40 80.5218C40 82.2087 40.6701 83.8266 41.863 85.0194L43.2772 83.6052C42.4594 82.7875 42 81.6783 42 80.5218H40ZM41.863 85.0194C43.0558 86.2123 44.6737 86.8824 46.3606 86.8824V84.8824C45.2041 84.8824 44.095 84.423 43.2772 83.6052L41.863 85.0194ZM46.3606 86.8824H75.8147V84.8824H46.3606V86.8824ZM75.8147 86.8824C77.5016 86.8824 79.1195 86.2123 80.3123 85.0194L78.8981 83.6052C78.0803 84.423 76.9712 84.8824 75.8147 84.8824V86.8824ZM80.3123 85.0194C81.5052 83.8266 82.1753 82.2087 82.1753 80.5218H80.1753C80.1753 81.6783 79.7159 82.7875 78.8981 83.6052L80.3123 85.0194ZM82.1753 80.5213C82.174 77.7527 81.6274 75.0113 80.5667 72.4539L78.7193 73.2202C79.6794 75.535 80.1741 78.0162 80.1753 80.5223L82.1753 80.5213ZM80.5667 72.4539C79.506 69.8965 77.9519 67.5731 75.9933 65.6163L74.5797 67.0312C76.3526 68.8024 77.7592 70.9054 78.7193 73.2202L80.5667 72.4539ZM75.9933 65.6163C74.0346 63.6595 71.7097 62.1076 69.1513 61.0493L68.3868 62.8974C70.7025 63.8553 72.8069 65.26 74.5797 67.0312L75.9933 65.6163ZM69.1513 61.0493C66.5929 59.991 63.8511 59.4469 61.0825 59.4482L61.0834 61.4482C63.5894 61.4471 66.0711 61.9395 68.3868 62.8974L69.1513 61.0493Z"
        fill="black"
        mask="url(#path-8-inside-4_194_5835)"
      />
    </Icon>
  )
}

export default ProfileBox
