import { Template } from '.'
import { contactUs, text, aboutUs, gallery, quote } from './variants'

export const Template15: Template = {
  id: '15',
  pages: [
    {
      slug: '/',
      name: 'Home',
      blocks: [
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_15')?.settings,
            spacing: {
              marginMobile: { top: 30, left: 0, bottom: 0, right: 0 },
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 60, left: 0, bottom: 30, right: 0 },
              enabled: true,
              paddingMobile: { top: 0, left: 0, bottom: 0, right: 0 },
            },
          },
          variables: [
            {
              data: [
                {
                  children: [{ fontFamily: 'Montserrat', text: 'Hello! welcome to Natural photos Gallery' }],
                  type: 'paragraph',
                  align: 'center',
                },
                {
                  children: [{ fontFamily: 'Montserrat', text: ' with creative & unique style' }],
                  type: 'paragraph',
                  align: 'center',
                },
              ],
              name: 'TITLE',
              id: '79e1ec8b-3c78-45f2-807a-4e7e537639d7',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#727272', text: '' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: '9e2aca41-7abd-40cb-a710-a48f99125892',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_15',
          type: 'TEXT',
        },
        {
          settings: {
            ...gallery.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              marginMobile: { top: 0, left: 0, bottom: 0, right: 0 },
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
              paddingMobile: { top: 0, left: 0, bottom: 0, right: 0 },
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1682498900183-c95ba7e34b27?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2ODMxNzg0ODg&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1682533945500-9e7012432f75?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2ODMxNzg1MzA&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1585016495481-91613a3ab1bc?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxuYXR1cmFsfGVufDB8fHx8MTY4MzE3ODU1OQ&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '4',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1680562725589-eb6b43ff0145?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2ODMxNzg1Nzg&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '5',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1520962922320-2038eebab146?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw2fHxuYXR1cmFsfGVufDB8fHx8MTY4MzE3ODU1OQ&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '6',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1681927167131-8654c51fb5e7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2ODMxNzg3NjI&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '7',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1680951103925-d4ee1a91ea6a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2ODMxNzg3OTI&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '8',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1680670017023-34dc69f1a311?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2ODMwMzI2NjM&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3a38217f-643a-478f-bb26-eb0391d5580a',
                  title: [
                    { children: [{ text: '' }], type: 'paragraph' },
                    { children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1680644946605-69f656db956c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2ODMxNzg5ODI&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '52098425-f780-4601-a728-c4be23af6170',
                  title: [
                    { children: [{ text: '' }], type: 'paragraph' },
                    { children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1681377736790-7d5d6840659c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2ODMwMzI2OTE&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1d456cc8-d718-4a21-8784-82e7b3758125',
                  title: [
                    { children: [{ text: '' }], type: 'paragraph' },
                    { children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1681121311532-e4882571a74e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2ODMxNzkwMDI&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '89ed53a8-e7bf-4978-9e5a-1b1aa80fc8f4',
                  title: [
                    { children: [{ text: '' }], type: 'paragraph' },
                    { children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' },
                  ],
                },
                {
                  image:
                    'https://plus.unsplash.com/premium_photo-1668799886418-2335be7716e9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2ODQxNDk1Njh8&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '51cdfa47-8a88-4e01-a622-3a500ee564cb',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
              ],
              name: 'MEDIA',
              id: 'fd8a2024-4ecb-4bf5-b131-e0549ee177f3',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'GALLERY',
        },
      ],
    },
    {
      slug: '/photos',
      name: 'Photos',
      blocks: [
        {
          settings: {
            ...gallery.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1520962922320-2038eebab146?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw2fHxuYXR1cmFsfGVufDB8fHx8MTY4MzE3ODU1OQ&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1549486909-73fa91fe2ca6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxyaXZlcnxlbnwwfHx8fDE2ODMxNzk3MTk&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1473448912268-2022ce9509d8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxmb3Jlc3R8ZW58MHx8fHwxNjgzMTc5NzQx&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1462143338528-eca9936a4d09?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHx0cmVlc3xlbnwwfHx8fDE2ODMxNzk3Nzg&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '4',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1463947628408-f8581a2f4aca?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw3fHxza3l8ZW58MHx8fHwxNjgzMTc5OTM4&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '5',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1570483358100-6d222cdea6ff?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxza3l8ZW58MHx8fHwxNjgzMTc5OTM4&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '6',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1513002749550-c59d786b8e6c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwyfHxza3l8ZW58MHx8fHwxNjgzMTc5OTM4&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '7',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1471922694854-ff1b63b20054?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw1fHxza3klMjB3aXRoJTIwbmF0dXJhbHxlbnwwfHx8fDE2ODMxODAwMTI&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '8',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1681392282273-91f7b6e3d272?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2ODMxODAxMjI&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '370abd52-2394-4186-be97-74deb8d0f06a',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1440288736878-766bd5839edb?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxjaGlsZHxlbnwwfHx8fDE2ODMxODAxNzA&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '4e97b752-5552-4c01-995c-7a7f6f8a2383',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1503454537195-1dcabb73ffb9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxjaGlsZHxlbnwwfHx8fDE2ODMxODAxNzA&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '9520a90b-f5ca-460d-bae0-8d78c85e0352',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1468235786671-7d0c7b22c605?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxjaGlsZHN8ZW58MHx8fHwxNjgzMTgwMjI5&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: 'e4506764-c8e3-4b1f-ae22-9ca3b9bef1db',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
              ],
              name: 'MEDIA',
              id: '123',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'GALLERY',
        },
      ],
    },
    {
      slug: '/bio',
      name: 'Bio',
      blocks: [
        {
          settings: {
            ...aboutUs.find(i => i.variant === 'VARIANT_15')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 30, left: 0, bottom: 30, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1518109331836-a2a7e93f89bb?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwyfHx0ZWFtJTIwd2l0aCUyMG5hdHVyYWx8ZW58MHx8fHwxNjgzMTgwMzY1&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ text: 'who we are ?', bold: true }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'We are a team of experienced entrepreneurs and investors dedicated to acquiring and growing innovative businesses. Our approach is rooted in the latest industry trends and our commitment to long-term success. ',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2f900906-4961-4a0d-84b0-b625ee6f5e9f',
                  title: [{ children: [{ text: 'Looking for an electrifyingly' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1582213782179-e0d53f98f2ca?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwyfHxoYXBweSUyMHRlYW18ZW58MHx8fHwxNjgzMTgwNTA4&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ text: 'where we are ?', bold: true }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'We are a team of experienced entrepreneurs and investors dedicated to acquiring and growing innovative businesses. Our approach is rooted in the latest industry trends and our commitment to long-term success. ',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '68b1d540-922f-4b2d-bc54-4f24d10b5ad6',
                  title: [{ children: [{ text: 'Looking for an electrifyingly' }], type: 'paragraph' }],
                },
              ],
              name: 'SERVICE',
              id: '5d5aba98-f8c6-44dc-9777-d0be76b52ab5',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1523240795612-9a054b0db644?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHx0ZWFtJTIwaGFwcHl8ZW58MHx8fHwxNjgzMTgwNjE4&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '93c42092-b41a-40f1-bd88-a6deb1a492fa',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'About us' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '9e2c18c0-7408-4a6a-b8a0-808832cc7ea3',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_15',
          type: 'ABOUT_US',
        },
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              marginMobile: { top: 0, left: 0, bottom: 0, right: 0 },
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: false,
              paddingMobile: { top: 0, left: 0, bottom: 0, right: 0 },
            },
            color: { background: { value: '#F8F8F8', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Send us a message' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '010db3b5-93c8-4839-ac95-eb7844c2cbb8',
              category: 'TEXT',
            },
            {
              data: [
                {
                  name: 'Email',
                  id: 'bc2a1723-c90c-4014-bf78-17d75cfbcccd',
                  placeholder: 'example@gmail.com',
                  type: 'email',
                  error: 'email is required',
                },
              ],
              name: 'INPUTS',
              id: '25b54024-2079-4816-a423-6e3491410fd6',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: ' Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'c0526477-7b38-4e8d-a805-1bc4dc997f17',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#000000',
                  backgroundColor: '#2f7f39',
                  borderRadius: '0px',
                  color: '#fff',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  bold: true,
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'Send',
              },
              name: 'BUTTON_1',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_6',
          type: 'CONTACT_US',
        },
      ],
    },
    {
      slug: '/blog',
      name: 'Blog',
      blocks: [
        {
          settings: {
            ...quote.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              marginMobile: { top: 0, left: 0, bottom: 0, right: 0 },
              padding: { top: 30, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#F8F8F8', enabled: true } },
          },
          variables: [
            {
              data: [
                { children: [{ text: '' }], type: 'paragraph' },
                { children: [{ text: 'My Blog' }], type: 'paragraph' },
              ],
              name: 'TITLE',
              id: '50daf3e7-6a5f-412f-8019-026f4926f3c8',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: '' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '35214b4b-1b32-4b5b-9231-7457bef325ef',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'QUOTE_SECTION',
        },
        {
          settings: {
            ...aboutUs.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              marginMobile: { top: 0, left: 0, bottom: 0, right: 0 },
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: false,
              paddingMobile: { top: 0, left: 0, bottom: 0, right: 0 },
            },
            color: { background: { value: '#F8F8F8', enabled: true } },
          },
          variables: [
            { data: [], name: 'SUBTITLE', id: '0aed1cd4-cccc-4d4f-9897-f308e5c947e0', category: 'TEXT' },
            {
              data: 'https://images.unsplash.com/photo-1525741802326-999b36aeae7d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw4fHxuYXR1cmFsJTIwbGlmZXxlbnwwfHx8fDE2ODMxODA3NDk&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '572784d0-9ed6-4ec8-87af-eefb027dc208',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ color: '#000000', text: 'GOAT Acquisition UX' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '042a3663-9619-44aa-bfb4-9cde23882900',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Our streamlined design process ensures that your site is not only visually appealing but also easy to navigate, with modern layouts that include an interactive user interface and engaging typography. Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '20feadd2-adf5-4084-b951-804d80a1a62c',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'ABOUT_US',
        },
        {
          settings: {
            ...aboutUs.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#F8F8F8', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'GOAT Acquisition UX' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'cdbac119-c31b-46f2-9e11-911b3e4ace9a',
              category: 'TEXT',
            },
            { data: [], name: 'SUBTITLE', id: '6444c2e4-7aca-46d1-84a1-1dcadb0ea67a', category: 'TEXT' },
            {
              data: 'https://images.unsplash.com/photo-1500558994976-136e954ad203?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxuYXR1cmFsJTIwbGlmZXxlbnwwfHx8fDE2ODMxODA3NDk&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: 'e6e9e9ef-a7bb-46e3-8242-7dec8c399006',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Our streamlined design process ensures that your site is not only visually appealing but also easy to navigate, with modern layouts that include an interactive user interface and engaging typography. Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '5761ccf9-23c9-4190-b656-033e1c5bdb86',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'ABOUT_US',
        },
        {
          settings: {
            ...aboutUs.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              marginMobile: { top: 0, left: 0, bottom: 0, right: 0 },
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: false,
              paddingMobile: { top: 0, left: 0, bottom: 0, right: 0 },
            },
            color: { background: { value: '#F8F8F8', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'GOAT Acquisition UX' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '353a8a68-7f95-4242-9a0a-6ac2177efc39',
              category: 'TEXT',
            },
            { data: [], name: 'SUBTITLE', id: 'eee49363-07fe-4ae4-96e5-0509a3049a9d', category: 'TEXT' },
            {
              data: 'https://images.unsplash.com/photo-1518419530812-e4cc91c95162?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxuYXR1cmFsJTIwZGF5fGVufDB8fHx8MTY4MzE4MDgyMA&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '120c67f2-e964-4e6f-a16f-61ed6b496103',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Our streamlined design process ensures that your site is not only visually appealing but also easy to navigate, with modern layouts that include an interactive user interface and engaging typography. Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'ca1a2dff-a839-4bdc-8b23-885d0cedaaca',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'ABOUT_US',
        },
      ],
    },
    {
      slug: '/contact',
      name: 'Contact',
      blocks: [
        {
          settings: {
            ...aboutUs.find(i => i.variant === 'VARIANT_3')?.settings,
            spacing: {
              marginMobile: { top: 0, left: 0, bottom: 0, right: 0 },
              padding: { top: 30, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
              paddingMobile: { top: 0, left: 0, bottom: 0, right: 0 },
            },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Thrilling Design for Investors' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '23e16945-be22-4a7a-92a1-fdec83fddab7',
              category: 'TEXT',
            },
            { data: [], name: 'SUBTITLE', id: '06ac7eb1-e403-4e39-8345-ae1e6ffaf60c', category: 'TEXT' },
            {
              data: 'https://images.unsplash.com/photo-1533038590840-1cde6e668a91?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzNjM2MzV8MHwxfHNlYXJjaHwzfHxmbG93ZXJ8ZW58MHx8fHwxNjg0MDcwOTY2fDA&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '8c04c098-20ef-4319-8793-866d909592ab',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Our streamlined design process ensures that your site is not only visually appealing but also easy to navigate, with modern layouts that include an interactive user interface and engaging typography. Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '1bd5ecb5-f1f2-4f46-867c-794a37ff041a',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_3',
          type: 'ABOUT_US',
        },
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_4')?.settings,
            spacing: {
              marginMobile: { top: 0, left: 0, bottom: 0, right: 0 },
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: false,
              paddingMobile: { top: 0, left: 0, bottom: 0, right: 0 },
            },
            color: { background: { value: '#F8F8F8', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  name: 'First Name',
                  id: 'c0d3d11c-1973-4255-ba90-b3f204c2b314',
                  placeholder: 'enter your first name',
                  type: 'text',
                  error: 'First Name is required',
                },
                {
                  name: 'last Name',
                  id: '470235f3-0a00-4488-9034-30c58fff815f',
                  placeholder: 'enter your last name',
                  type: 'text',
                  error: 'last Name is required',
                },
                {
                  name: 'Email',
                  id: 'b5c36fa3-3a37-4523-9477-6bd9eb7eb241',
                  placeholder: 'enter your email',
                  type: 'email',
                  error: 'email is required',
                },
                {
                  name: 'Message',
                  id: '106e2f20-0ed2-440e-9159-f3081834ddbd',
                  placeholder: 'enter your message',
                  type: 'textarea',
                  error: 'message is required',
                },
              ],
              name: 'INPUTS',
              id: '1349997d-a371-4d79-aca0-42f3e41c18a6',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#b6b6b6', text: '' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '59845a40-241d-4a31-9e44-25bfac45e613',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: "Let's chat now" }], type: 'paragraph' }],
              name: 'TITLE',
              id: '2bfbc7b0-f417-4473-9be1-f972bd431afb',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#000',
                  backgroundColor: '#2f7f39',
                  borderRadius: '0px',
                  color: '#fff',
                  borderWidth: '1px',
                  display: 'block',
                  width: '249px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'message',
              },
              name: 'BUTTON_1',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_4',
          type: 'CONTACT_US',
        },
      ],
    },
  ],

  newFooter: {
    settings: { backgroundColor: { color: '#2f2f2f', enabled: true } },
    designedBy: 'Plly Staff',
    id: 'VARIANT_7',
    categories: [
      {
        name: 'Explore',
        links: [{ name: 'Home', id: '921067dd-0f8a-4cec-add7-6b6080b7f6fd', type: 'internal', url: '/' }],
        id: '84aec155-d841-47a9-a86a-75e7b1a24cde',
      },
    ],
    tags: [],
  },
  newMenu: [
    { name: 'Home', id: '094c1cb8-243c-4b73-ab4f-8e520455411d', type: 'internal', url: '/' },
    { name: 'photos', id: '5e46e35e-c236-429e-b4d6-c72dd1c6ce16', type: 'internal', url: '/photos' },
    { name: 'Bio', id: '9917b031-fe86-4827-a88b-f3c0805b4193', type: 'internal', url: '/bio' },
    { name: 'Blog', id: 'c20365da-6643-493d-8f4e-1f88fccfc718', type: 'internal', url: '/blog' },
    { name: 'Contact ', id: 'ffb852d1-fd93-4ddc-96ce-bd09419875bc', type: 'internal', url: '/contact' },
  ],
  newMenuConfig: {
    settings: { backgroundColor: { color: '#2f7f39', enabled: true }, textColor: { color: '#ffffff', enabled: true } },
    links: [
      { name: 'Home', id: '5a616ea9-529b-4c8b-a26c-3c4ea7488fe2', type: 'internal', url: '/' },
      { name: 'Contact Us', id: 'eab8ef76-fd37-4e0d-a51b-a09af15fcea5', type: 'email', url: 'hello@example.com' },
    ],
    styleName: 'VARIANT 5',
    id: 'VARIANT_5',
    tags: [],
  },
  links: [],
}
