import { API } from 'aws-amplify'
import { GraphQLResult, graphqlOperation } from '@aws-amplify/api-graphql'
import { parse, print, visit } from 'graphql/language'
import { memoize } from 'lodash'
import { Observable } from 'zen-observable-ts'

interface Options {
  query: string | GraphQLQuerySpec
  variables?: {}
}

type Subscription<T> = Observable<{
  provider: any
  value: {
    data: T
  }
}>

const DEFAULT_MAX_DEPTH = 3

export interface GraphQLQuerySpec {
  key: string
  graphql: string
  maxDepth?: number
  /** paths in graphql to omit */
  omit?: string[]
}

function transformQuery(query: string | GraphQLQuerySpec) {
  let spec =
    typeof query !== 'string'
      ? query
      : {
          key: '',
          graphql: query,
        }

  const ast = parse(spec.graphql)
  const maxDepth = spec.maxDepth || DEFAULT_MAX_DEPTH
  const omit = (spec.omit || []).map(x => '.' + x)

  let depth = 0
  let path = ''

  const newAST = visit(ast, {
    Field: {
      enter(node) {
        const isNestObject = !!node.selectionSet
        if (depth + Number(isNestObject) > maxDepth) {
          return null
        }

        const newPath = path + '.' + node.name.value
        if (omit.some(x => x === newPath)) {
          return null
        }

        depth++
        path = newPath
      },
      leave() {
        path = path.slice(0, path.lastIndexOf('.'))
        depth--
      },
    },
  })

  return print(newAST)
}

export const resolveQuery = memoize(transformQuery, spec => (typeof spec === 'string' ? spec : spec.key))

export function graphqlSubscibe<T = object>(options: Options) {
  const observable = API.graphql(graphqlOperation(resolveQuery(options.query), options.variables)) as Subscription<T>

  return observable
}

export function graphqlQuery<T = object>(options: Options) {
  const promise = API.graphql(graphqlOperation(resolveQuery(options.query), options.variables)) as unknown as Promise<
    GraphQLResult<T>
  >

  ;(promise as any).cancel = () => API.cancel(promise)

  return promise
}
