import { useSearchParams } from 'react-router-dom'
import FooterRenderer from 'src/_polly/components/src/footer/Renderer'

export default function FooterPreview() {
  const [searchParams] = useSearchParams()
  const footer = searchParams.get('footer')
  const website = searchParams.get('website')

  if (!footer || !website) return <p>none</p>

  return <FooterRenderer footer={JSON.parse(window.atob(footer))} website={JSON.parse(window.atob(website))} />
}
