import Icon from './icons'
import { IconPropsT } from './types'

function SideBuilder(props: IconPropsT) {
  const { title = 'Side Builder', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 120 120" size={size} title={title} {...otherProps}>
      <path
        d="M111 0H9C4.02944 0 0 4.02944 0 9V111C0 115.971 4.02944 120 9 120H111C115.971 120 120 115.971 120 111V9C120 4.02944 115.971 0 111 0Z"
        fill="#D9D9D9"
      />
      <path
        d="M108.5 2.5H11.5C6.52944 2.5 2.5 6.52944 2.5 11.5V108.5C2.5 113.471 6.52944 117.5 11.5 117.5H108.5C113.471 117.5 117.5 113.471 117.5 108.5V11.5C117.5 6.52944 113.471 2.5 108.5 2.5Z"
        fill="#D9D9D9"
        stroke="none"
      />
      <rect x="16.5" y="29.5" width="89" height="53" rx="5.5" fill="black" stroke="black" />
      <rect x="17.5" y="30.5" width="87" height="47" rx="5.5" fill="white" stroke="black" />
      <circle cx="61" cy="80" r="1" fill="#D9D9D9" />
      <rect x="52" y="82" width="19" height="9" fill="black" />
      <rect x="47" y="91" width="29" height="2" rx="1" fill="#050505" />
      <rect x="82.5" y="68" width="16" height="24.5" rx="3.5" fill="white" stroke="black" />
      <path d="M83 90H98C98 91.6569 96.6569 93 95 93H86C84.3431 93 83 91.6569 83 90Z" fill="black" />
    </Icon>
  )
}

export default SideBuilder
