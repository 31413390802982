import { sortBy } from 'lodash'
import { useEffect, useState } from 'react'
import ChatMateListItem from './ChatMateListItem'

export default function ChatMateList(props: any) {
  const { chatThreads, chatmateId, hasMore, nextBatch } = props

  const [items, setItems] = useState<any>([])

  const onScroll = (e: any) => {
    const { target } = e
    if (target.scrollHeight < target.scrollTop + target.clientHeight + 100) {
      nextBatch()
    }
  }

  useEffect(() => {
    const list = sortBy(chatThreads, item => item?.lastMessage?.createdAt || item?.lastActiveTime).reverse()
    setItems(list)
  }, [chatThreads])

  return (
    <div className="h-full overflow-auto" onScroll={onScroll}>
      {items.map((item: any) => (
        <ChatMateListItem
          key={item.id ?? item?.chatmateID}
          paramEntry={item}
          // deleteMsg={handleDeleteBtn}
          // msgOptions={handleOptBtn}
          active={item.chatmateID === chatmateId}
        />
      ))}
      {hasMore && <p>Loading...</p>}
    </div>
  )
}
