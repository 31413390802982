import { Template } from '.'
import { contactUs, gallery, image, love, team, text } from './variants'

export const Template02: Template = {
  id: '02',
  pages: [
    {
      slug: '/',
      name: 'Home',
      blocks: [
        {
          settings: { ...love.find(i => i.variant === 'VARIANT_8')?.settings },
          variables: [
            {
              data: [{ children: [{ className: 'uppercase', text: 'TBH Brand Reputation' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '0a2200c2-f91d-448f-b6a5-9c5210b4ce88',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ text: 'Decore Company' }], type: 'paragraph' },
                {
                  children: [{ highlightColor: '#a5a48b', text: '                               ' }],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'b9502299-aa71-4a5f-8f21-8409bd5148b2',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_8',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...image.find(i => i.variant === 'VARIANT_4')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [
                { subtitle: 'Static Site Generator', description: '', title: 'React' },
                { subtitle: 'CSS Framework', description: '', title: 'Tailwind' },
                { subtitle: 'Animation', description: '', title: 'Framer Motion' },
                { subtitle: 'Headless CMS', description: '', title: 'Sanity' },
                { subtitle: 'Hosting', description: '', title: 'Vercel' },
              ],
              name: 'ITEMS',
              id: 'bf62c9bf-f4e7-4188-852d-8366a6aebb6c',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Dope Investor Design: Dope Investor Design is a full-service investment firm with a focus on innovative design. We invest in companies that prioritize exceptional user experience and visual appeal. Our team of experienced designers and investors work hand-in-hand to support the growth and success of our portfolio companies.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '9ffd6199-f32c-4129-b1f5-4380425f228c',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1448697138198-9aa6d0d84bf4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxkZWNvcmV8ZW58MHx8fHwxNjc3NTk2NjQ0&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '4674fc98-317c-4fa3-8728-f75b57149277',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'About US ....' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '4c77d5d9-c90c-42fd-ad67-8b0ff198362a',
              category: 'TEXT',
            },
            { data: [], name: 'PARAGRAPH', id: '7ac7a1bc-6c78-49d3-a532-28b3dabdb758', category: 'TEXT' },
          ],
          variant: 'VARIANT_4',
          type: 'IMAGE_OMG',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 60, left: 0, bottom: 60, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1674707619293-d71bd8d7afa3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2Nzc1OTY4MDg&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ color: '#c8c6b8', text: 'Burn Rate Buster UX Design' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#b6b6b6',
                          text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1675248954741-6d26c8fd7fd6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2Nzc1OTY4NzI&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ color: '#a9a494', text: 'UX Design for Digital Nomads' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#a39e9e',
                          text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1606246481699-f16245882373?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxkZWNvcmV8ZW58MHx8fHwxNjc3NTk2NjQ0&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ color: '#aba1a1', text: 'Hits Different Investors' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#cdc4c4',
                          text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: 'ef280bfe-3b0d-4daf-9637-3af6551b3a2f',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ color: '#ffffff', text: 'What We Do ?' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '448251fa-e625-4b08-8de6-ef8c95f164ba',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'TEXT',
        },
        {
          settings: {
            ...image.find(i => i.variant === 'VARIANT_7')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                { subtitle: 'Static Site Generator', description: '', title: 'React' },
                { subtitle: 'CSS Framework', description: '', title: 'Tailwind' },
                { subtitle: 'Animation', description: '', title: 'Framer Motion' },
                { subtitle: 'Headless CMS', description: '', title: 'Sanity' },
                { subtitle: 'Hosting', description: '', id: 'c3147634-5dc1-4390-9fe8-0a9b774ffbcb', title: 'Vercel' },
              ],
              name: 'ITEMS',
              id: '4baeff75-55e9-403d-bdd3-92e5cdb4d94f',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1448697138198-9aa6d0d84bf4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxkZWNvcmV8ZW58MHx8fHwxNjc3NTk2NjQ0&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: 'f0c22368-7f90-4553-a939-4986c30ee28d',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'Dope Burn Rate' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'd687b169-d84d-4895-8c52-b4785d0138a1',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Dope Investor Design: Dope Investor Design is a full-service investment firm with a focus on innovative design. We invest in companies that prioritize exceptional user experience and visual appeal. Our team of experienced designers and investors work hand-in-hand to support the growth and success of our portfolio companies.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'b46de902-fd06-4784-b2a4-227d563bce6d',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_7',
          type: 'IMAGE_OMG',
        },
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_7')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  children: [
                    {
                      text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '650429ca-01bf-4635-8f4e-5f78ebc82e3b',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Our Customer Say :-' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'e3849a49-ecb7-46f8-9617-fc58c163c401',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ text: 'Carmen Lopez', italic: true }], type: 'paragraph' },
                { children: [{ color: '#606060', text: '---------', italic: true }], type: 'paragraph' },
              ],
              name: 'SUBTITLE',
              id: 'd2990cb0-726b-4390-9df4-c61fd999bb7f',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_7',
          type: 'ATTENTION_GRABBER',
        },
      ],
    },
    {
      slug: '/works',
      name: 'Works',
      blocks: [
        {
          settings: { ...love.find(i => i.variant === 'VARIANT_8')?.settings },
          variables: [
            {
              data: [{ children: [{ className: 'uppercase', text: 'Our Work' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '704bea2c-ea09-46c2-b6f3-5d4a0890da42',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Looking for an creative ideas' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'f43650ee-9ef7-4305-8bb1-57dd7b233c04',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_8',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...gallery.find(i => i.variant === 'VARIANT_4')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1606246481699-f16245882373?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxkZWNvcmV8ZW58MHx8fHwxNjc3NTk2NjQ0&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Our experience showcasing unicorn investors and influencers means that we know exactly what it takes to optimize your website for maximum ROI.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                  title: [{ children: [{ text: 'TitleYeet Your Design' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1513161455079-7dc1de15ef3e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwyfHxkZWNvcmV8ZW58MHx8fHwxNjc3NTk2NjQ0&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Our experience showcasing unicorn investors and influencers means that we know exactly what it takes to optimize your website for maximum ROI.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                  title: [{ children: [{ text: 'TitleYeet Your Design' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1532372320572-cda25653a26d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw1fHxkZWNvcmV8ZW58MHx8fHwxNjc3NTk2NjQ0&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Our experience showcasing unicorn investors and influencers means that we know exactly what it takes to optimize your website for maximum ROI.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                  title: [{ children: [{ text: 'TitleYeet Your Design' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1504541989296-167df755af3f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxkYXJrJTIwZGVjb3JlfGVufDB8fHx8MTY3NzU5ODMyOQ&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Our experience showcasing unicorn investors and influencers means that we know exactly what it takes to optimize your website for maximum ROI.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '4',
                  title: [{ children: [{ text: 'TitleYeet Your Design' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1496180727794-817822f65950?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxkZWNvcmV8ZW58MHx8fHwxNjc3NTk2NjQ0&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Our experience showcasing unicorn investors and influencers means that we know exactly what it takes to optimize your website for maximum ROI.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '5',
                  title: [{ children: [{ text: 'TitleYeet Your Design' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1606246481694-b8d4ce67b287?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxMHx8ZGVjb3JlfGVufDB8fHx8MTY3NzU5NjY0NA&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Our experience showcasing unicorn investors and influencers means that we know exactly what it takes to optimize your website for maximum ROI.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '6',
                  title: [{ children: [{ text: 'TitleYeet Your Design' }], type: 'paragraph' }],
                },
              ],
              name: 'MEDIA',
              id: '7edb293b-4722-49b1-b23a-01fd79809a2d',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_4',
          type: 'GALLERY',
        },
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_7')?.settings,
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Benjamin Cruise', italic: true }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '6c7b337c-6362-4b2c-92a8-2cd856d8ebc7',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Our Customer say:' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '5d07046a-05db-4bfb-960c-4df92ea44ef4',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: "Our experience showcasing unicorn investors and influencers means that we know exactly what it takes to optimize your website for maximum ROI. Whether you're a cottage industry or a disruptive technology startup, ",
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'efea31e4-51d7-477d-a022-40a29ebcf9b7',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_7',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...team.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxtYW58ZW58MHx8fHwxNjc3NTgwODQ5&ixlib=rb-4.0.3&q=80&w=1080',
                  subtitle1: [{ children: [{ text: 'Position/Role' }], type: 'paragraph' }],
                  subtitle: [{ children: [{ text: '' }], type: 'paragraph' }],
                  name: [{ children: [{ color: '#ffffff', text: 'Cassidy Cruise' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '1',
                  name1: [{ children: [{ text: 'Taylor Quill' }], type: 'paragraph' }],
                  description1: [{ children: [{ text: '#developer #expert #designer' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwyfHxtZW58ZW58MHx8fHwxNjc3NTgxMTQ0&ixlib=rb-4.0.3&q=80&w=1080',
                  subtitle1: [{ children: [{ text: 'Position/Role' }], type: 'paragraph' }],
                  subtitle: [{ children: [{ text: '' }], type: 'paragraph' }],
                  name: [{ children: [{ color: '#ffffff', text: 'Chris Rock' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '2',
                  name1: [{ children: [{ text: 'Taylor Quill' }], type: 'paragraph' }],
                  description1: [{ children: [{ text: '#developer #expert #designer' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw2fHxtZW58ZW58MHx8fHwxNjc3NTgxMTQ0&ixlib=rb-4.0.3&q=80&w=1080',
                  subtitle1: [{ children: [{ text: 'Position/Role' }], type: 'paragraph' }],
                  subtitle: [{ children: [{ text: '' }], type: 'paragraph' }],
                  name: [{ children: [{ color: '#ffffff', text: 'Arjun McCartney' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '3',
                  name1: [{ children: [{ text: 'Taylor Quill' }], type: 'paragraph' }],
                  description1: [{ children: [{ text: '#developer #expert #designer' }], type: 'paragraph' }],
                },
              ],
              name: 'TEAM_MEMBERS',
              id: '03bdbe1e-25cd-4ceb-b5e6-2918907b4f1e',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#ffffff', text: 'Meet Our Team', bold: true }], type: 'paragraph' }],
              name: 'TITLE',
              id: '0e4385dd-3a67-43a7-a392-d5d3a964a12f',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#ffffff', text: 'Fire Cottage Industry' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'fbea9265-bdd5-42e8-8c21-4c4189b7496f',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      highlightColor: '#000000',
                      color: '#6e6868',
                      text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                      bold: true,
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '9ac8d88f-b8f5-4c88-884b-47fbed578c96',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'TEAM_MEMBERS',
        },
      ],
    },
    {
      slug: '/contact-us',
      name: 'Contact us',
      blocks: [
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_13')?.settings,
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Sub Title' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '2441b5a3-27c4-4f20-a2ad-41691c9aead4',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ color: '#ffffff', className: 'shadow-lightness', text: 'Contact us', bold: true }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '3e59cb36-0e5a-4cf7-bd4f-6b886ad54829',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_13',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_8')?.settings,
            spacing: {
              marginMobile: { top: 0, left: 0, bottom: 0, right: 0 },
              padding: { top: 0, left: 0, bottom: 90, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
              paddingMobile: { top: 0, left: 0, bottom: 0, right: 0 },
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  name: [{ children: [{ text: 'P:' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '(555) 555-5555' }], type: 'paragraph' }],
                  id: 'a2f28197-e248-4d14-b7d3-35f4c9cf6c31',
                },
                {
                  name: [{ children: [{ text: 'E:' }], type: 'paragraph' }],
                  description: [{ children: [{ text: 'example2gmail.com' }], type: 'paragraph' }],
                  id: '7419b2b2-faf6-48eb-b9d2-984d96a4c4d3',
                },
              ],
              name: 'CONTRACT',
              id: 'f91ac18d-afdc-4c67-bc96-3395141ed3eb',
              category: 'TEXT',
            },
            {
              data: [
                {
                  name: 'First Name',
                  id: 'e34e5884-05d1-4e7c-b877-29b02bc7c6d3',
                  placeholder: 'First Name',
                  type: 'text',
                  error: 'First Name is required',
                },
                {
                  name: 'Last Name',
                  id: '7e012052-3cbf-4f2e-afc6-4a72da11753a',
                  placeholder: 'Last Name',
                  type: 'text',
                  error: 'Last Name is required',
                },
                {
                  name: 'Email',
                  id: 'f4fd560c-828a-4f27-ad32-25423be54bb4',
                  placeholder: 'example@gmail.com',
                  type: 'email',
                  error: 'email is required',
                },
                {
                  name: 'company',
                  id: '60caeeae-dba0-41f0-9881-e3ea198f0895',
                  placeholder: 'company',
                  type: 'text',
                  error: 'company is required',
                },
                {
                  name: 'Message',
                  id: '3396b808-8dfd-4f20-90af-98a88765e82d',
                  placeholder: '',
                  type: 'textarea',
                  error: 'message is required',
                },
              ],
              name: 'INPUTS',
              id: '45052c0a-1930-4add-896b-3dad8f75c7d8',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'What We Should Work Together ?' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: 'e21ac59d-9fe0-42e2-b699-d1ce13ebb95e',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Low-Key Accelerator Ready', bold: true }], type: 'paragraph' }],
              name: 'TITLE',
              id: '260229b0-0804-468d-8eec-157198c0299f',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#a6a6a6',
                      text: 'We are a team of experienced entrepreneurs and investors dedicated to acquiring and growing innovative businesses. Our approach is rooted in the latest industry trends and our commitment to long-term success .',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '83e35388-fb1f-41aa-8157-bacf9ae94d8b',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_8',
          type: 'CONTACT_US',
        },
      ],
    },
  ],

  newMenu: [
    { name: 'Home', type: 'internal', url: '/' },
    { name: 'Works', type: 'internal', url: '/works' },
    { name: 'Contact us', type: 'internal', url: '/contact-us' },
  ],
  newMenuConfig: {
    links: [
      {
        name: 'Home',
        type: 'internal',
        url: '/',
      },
      {
        name: 'Contact us',
        type: 'email',
        url: 'example@gmail.com',
      },
    ],
    id: 'VARIANT_10',
    tags: [],
  },
  newFooter: {
    designedBy: 'Plly Staff',
    id: 'VARIANT_4',
    categories: [
      {
        name: 'Explore',
        links: [{ name: 'Home', type: 'internal', url: '/' }],
      },
    ],
    tags: [],
  },
  links: [],
}
