import { Auth } from 'aws-amplify'
import { useEffect, useRef } from 'react'
import { createConversation, createMessage } from 'src/services/messenger.service'
import { TECHBOOM_SUPPORT_ID } from 'src/util/constants'
import { IMessageItem } from './ConvoSection'
import MessageItem from './MessageItem'

type Props = {
  messages: IMessageItem[]
  loading: boolean
  chatmate: any
  currentUser: any
}

export default function ConvoMessages(props: Props) {
  const { messages, loading: loadingMessages, chatmate, currentUser } = props

  const bottomRef = useRef<HTMLDivElement>(null)

  const toSender = (user: any) => ({
    name: `${user?.firstName} ${user?.lastName}`,
    avatar: user?.profilePicture,
  })

  const sendSupportMessage = async () => {
    const CognitoUser = await Auth?.currentAuthenticatedUser()
    const newConvo = await createConversation(TECHBOOM_SUPPORT_ID)
    await createMessage({
      threadID: newConvo?.convID ?? '',
      senderID: TECHBOOM_SUPPORT_ID,
      receiverID: CognitoUser?.username ?? '',
      message:
        'Hello. Welcome to Techboom Support.  Please type your question or challenge and we will try help answer',
      messageType: 'TEXT',
    })
  }

  useEffect(() => {
    const bottomTimeout = setTimeout(() => {
      if (bottomRef.current) {
        bottomRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }, 0)

    return () => {
      clearTimeout(bottomTimeout)
    }
  }, [messages, chatmate.id])

  useEffect(() => {
    if (!loadingMessages && messages.length === 0 && chatmate.id === TECHBOOM_SUPPORT_ID) {
      sendSupportMessage()
    }
  }, [loadingMessages, messages, chatmate])

  return (
    <div className="p-5">
      {loadingMessages && <div>Loading...</div>}
      {!loadingMessages && messages.length === 0 && chatmate.id !== TECHBOOM_SUPPORT_ID && (
        <p>Send a message below to start this conversation</p>
      )}
      <div className="space-y-6">
        {messages.reverse().map((item, index: any) => {
          const self = currentUser?.id === item?.senderID
          return (
            <MessageItem
              key={`convo-item-${index}`}
              sender={toSender(self ? currentUser : chatmate)}
              message={item}
              self={self}
            />
          )
        })}
      </div>
      <div ref={bottomRef} />
    </div>
  )
}
