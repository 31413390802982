import { motion } from 'framer-motion'
import SectionSlate from '../../../common/SectionSlatePreview'
import { TextSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantSeven({ section }: { section: TextSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const viewVariants = {
    offscreen: {
      y: 300,
    },
    onscreen: {
      y: 0,
      transition: {
        duration: 0.5,
        delay: 0.2,
        ease: [0, 0.71, 0.2, 1.01],
      },
    },
  }
  const viewport = { once: true, amount: 0.5 }

  return (
    <>
      <div
        className="space-y-2 md:space-y-3 py-6 md:py-12 px-6 md:px-12"
        style={{
          ...generateSectionBackground({
            defaultColor: 'white',
            backgroundSettings: section?.settings?.color?.background,
            image: null,
          }),
          ...generateSectionSpacing(section?.settings?.spacing),
        }}
      >
        <motion.div initial="offscreen" whileInView="onscreen" viewport={viewport}>
          <motion.div variants={viewVariants}>
            <p className="text-xl md:text-4xl font-bold ">
              <SectionSlate initialValue={title} previewMode />
            </p>
          </motion.div>
        </motion.div>
        <motion.div initial="offscreen" whileInView="onscreen" viewport={viewport}>
          <motion.div variants={viewVariants}>
            <p className="text-[16px] md:text-2xl ">
              <SectionSlate initialValue={paragraph} previewMode />
            </p>
          </motion.div>
        </motion.div>
      </div>
    </>
  )
}
