import { motion } from 'framer-motion'
import GoogleFontLoader from 'react-google-font-loader'
import SectionSlate from '../../../common/SectionSlatePreview'
import { BlogSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import ButtonRenderer from '../ButtonRenderer'

const VariantSeven = ({ section }: { section: BlogSection }) => {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const button = section.variables.find(variable => variable.name === 'BUTTON_1')?.data
  const items = section.variables.find(variable => variable.name === 'ITEMS')?.data
  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: '#3F51B5',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="text-center lg:py-56 py-36">
        <h1 className="text-white font-medium lg:text-7xl text-3xl">
          <SectionSlate initialValue={title} previewMode />
        </h1>
        <h2 className="text-[#FFFFFF]/20 lg:text-7xl text-3xl -mt-4">
          <GoogleFontLoader
            fonts={[
              {
                font: 'Allura',
                weights: [400],
              },
            ]}
          />
          <SectionSlate initialValue={subTitle} previewMode />
        </h2>
      </div>
      <div className="bg-[#d9dbeb]">
        <div className="container mx-auto lg:py-20 py-10 px-4">
          {items?.map((item: any, index: number) => (
            <div key={item?.id} className={` flex justify-start ${index % 2 == 0 ? 'flex-row' : 'flex-row-reverse'}`}>
              <div
                className={`flex lg:w-[calc(100%_+_.75rem)] w-full gap-4 lg:gap-[2rem] ${
                  index % 2 == 0 ? 'lg:flex-row flex-row-reverse' : 'flex-row-reverse'
                }`}
              >
                <motion.div
                  initial={{ x: index % 2 == 0 ? -200 : 200 }}
                  whileInView={{ x: 0 }}
                  transition={{ duration: 0.6 }}
                  //   viewport={{ once: true }}
                  className="bg-white w-full lg:mb-16 mb-8 rounded-md shadow-2xl"
                >
                  <div className="w-full lg:h-60 h-44 relative">
                    <img src={item?.image} className="w-full h-full object-cover object-center" alt="" />
                    <div className="absolute w-full h-full top-0 left-0 bg-[#3F51B5]/10" />
                    <p
                      style={{
                        ...generateSectionBackground({
                          defaultColor: '#FF4081',
                          backgroundSettings: section?.settings?.color?.background,
                          image: null,
                        }),
                      }}
                      className={`absolute uppercase font-roboto font-medium z-10 top-0 p-2 text-white w-fit ${
                        index % 2 == 0 ? 'lg:right-0' : 'left-0'
                      }`}
                    >
                      {`${new Date(item?.date).toLocaleString('default', { day: 'numeric' })} ${new Date(
                        item?.date,
                      ).toLocaleString('default', { month: 'long' })} 
  ${new Date(item?.date).toLocaleString('default', { year: 'numeric' })}`}
                    </p>
                    <h1 className="absolute z-10 bottom-2 left-2 text-white font-medium text-2xl">
                      <SectionSlate initialValue={item?.name} previewMode />
                    </h1>
                  </div>
                  <div className="p-4">
                    <h1>
                      <SectionSlate initialValue={item?.description} previewMode />
                    </h1>
                    {button ? (
                      <div className="flex justify-start">
                        <ButtonRenderer button={button} />
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="px-3 py-1 text-white bg-[#3F51B5] mt-4"
                        style={{
                          ...generateSectionBackground({
                            defaultColor: '#FF4081',
                            backgroundSettings: section?.settings?.color?.background,
                            image: null,
                          }),
                        }}
                      >
                        More
                      </button>
                    )}
                  </div>
                </motion.div>
                <div
                  className="lg:w-[.4rem] w-[5px] h-full bg-[#3F51B5]/30 relative"
                  style={{
                    ...generateSectionBackground({
                      defaultColor: '#FF4081',
                      backgroundSettings: section?.settings?.color?.background,
                      image: null,
                    }),
                  }}
                >
                  <div
                    className="absolute top-6 left-1/2 -translate-x-1/2 lg:w-8 lg:h-8 w-6 h-6 rounded-full bg-[#3F51B5] border"
                    style={{
                      ...generateSectionBackground({
                        defaultColor: '#FF4081',
                        backgroundSettings: section?.settings?.color?.background,
                        image: null,
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="w-full lg:block hidden" />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default VariantSeven
