import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Descendant } from 'slate'

import { PostStatus, PostType } from 'src/API'
import Chat from 'src/icons/Chat'
import Heart from 'src/icons/Heart'
import Share from 'src/icons/Share'
import Users from 'src/icons/Users'
import UsersLight from 'src/icons/UsersLight'
import { getPageByID, getWebsiteByID, saveWebsite } from 'src/lib/services/website.service'
import { postOnSocial } from 'src/services/feed.service'
import { getUserById } from 'src/services/user.service'
import { SectionPreviewRenderer } from 'src/_polly/components/src/sections'
import { Loader } from '../loader'
import ModalDialogV2 from '../new/ModalDialogV2'
import SectionTextField from '../new/SectionField/SectionTextField'
import { SectionSlate } from '../editor'

type PostDemoProps = {
  showModal?: string
  component: any
  website: any
  inputTitle: Descendant[]
  inputDescription: Descendant[]
}
function PostDemo({ showModal, component, website, inputDescription, inputTitle }: PostDemoProps) {
  const [user, setUser] = useState<any>(null)
  const getUserData = async () => {
    const userProfile = await getUserById(website?.userID ?? '')
    setUser(userProfile)
  }

  useEffect(() => {
    if (website) {
      getUserData()
    }
  }, [website])
  return (
    <>
      <div id="post-preview">
        {component ? (
          <SectionPreviewRenderer section={component} noShadow disableHover />
        ) : (
          <div className="px-10 py-20 border rounded">
            <p className="text-black dark:text-white">
              This post was shared when we didn&apos;t have the component render feature. Posts from now will have
              preview.
            </p>
          </div>
        )}
      </div>
      <div className="border-t border-b py-3">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-3">
            <div className="w-[20px] h-[20px]">
              <img
                className="w-full h-full object-cover object-center bg-gray-200 rounded-full"
                src={user?.profilePicture ?? ''}
                alt=""
              />
            </div>
            <h4 className="text-[15px] dark:text-white md:text-[17px]">
              <SectionSlate initialValue={inputTitle} readOnly previewMode />
            </h4>
          </div>
          <div className="flex items-center space-x-6 ">
            <div className="relative flex items-end pt-1">
              <button type="submit">
                <Heart size={20} className="text-black dark:text-white" />
              </button>
            </div>
            <div className="relative flex items-end">
              <button type="submit">
                <Chat size={20} />
              </button>
            </div>
            <button type="submit">
              <div className="hidden dark:block">
                <Users size={20} className="dark:text-[#ddd]" />
              </div>
              <div className=" dark:hidden">
                <UsersLight size={20} className="text-black" />
              </div>
            </button>
            <div className="relative flex items-end">
              <button type="submit">
                <Share size={20} />
              </button>
            </div>
          </div>
        </div>
        {showModal === 'preview' && inputDescription && (
          <div className="pt-2">
            <p className="text-black dark:text-white text-[15px]">
              <SectionSlate initialValue={inputDescription} previewMode readOnly />
            </p>
          </div>
        )}
      </div>
    </>
  )
}

type PostWebsiteProps = {
  open: boolean
  onBack: () => void
  website: Awaited<ReturnType<typeof getWebsiteByID>>
  pageID: string
  componentID: string
}

export default function PostWebsite({ onBack, open, website, componentID, pageID }: PostWebsiteProps) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState('post')
  const [component, setComponent] = useState<any>(null)
  const [inputTitle, setInputTitle] = useState<Descendant[]>([
    {
      type: 'paragraph',
      children: [
        {
          text: website?.name ?? '',
        },
      ],
    },
  ])
  const [inputDescription, setInputDescription] = useState<Descendant[]>([
    {
      type: 'paragraph',
      children: [
        {
          text: 'post description',
        },
      ],
    },
  ])

  const handleSubmit = () => {
    setLoading(true)
    Promise.all([
      postOnSocial({
        title: JSON.stringify(inputTitle),
        description: JSON.stringify(inputDescription),
        userID: website?.userID as string,
        websiteID: website?.id as string,
        type: PostType.COMPONENT,
        status: PostStatus.ACTIVE,
        componentID,
        pageID,
      }),
      saveWebsite({ id: website?.id as string, isPostedOnSocial: true }),
    ])
    navigate('/social/posts')
  }

  useEffect(() => {
    if (pageID) {
      getPageByID(pageID).then(data => {
        if (!data) return
        setComponent((JSON.parse(data.blocks) as any[]).find(block => block.id === componentID))
      })
    }
  }, [website])

  return (
    <div className="px-6 dark:bg-black">
      <Loader show={loading} />

      <ModalDialogV2 open={open} onBack={onBack}>
        <div className="px-4 ">
          <PostDemo
            component={component}
            website={website}
            inputDescription={inputDescription}
            inputTitle={inputTitle}
          />
          <div className="dark:text-white py-2 ">
            <div className="w-full">
              <SectionTextField
                initialValue={inputDescription}
                onChange={e => setInputDescription(e)}
                title="Description"
              />
            </div>
          </div>
          <div className="flex justify-center py-12 gap-4">
            <button
              onClick={() => setShowModal('edit')}
              type="button"
              className="w-[150px] h-[52px] flex justify-center items-center text-[20px] text-white bg-[#E90570] rounded-lg"
            >
              <span className="text-[20px] px-2">Edit</span>
            </button>
            <button
              onClick={() => setShowModal('preview')}
              className="w-[150px] h-[52px] flex justify-center items-center text-[20px] text-black dark:text-white border-4 border-[#E90570] rounded-lg"
              type="button"
            >
              Preview
            </button>
          </div>
        </div>
      </ModalDialogV2>
      {showModal === 'preview' && (
        <ModalDialogV2 open={open} onBack={onBack} setShowModal={setShowModal} showModal={showModal}>
          <div className="px-4 ">
            <PostDemo
              showModal={showModal}
              component={component}
              website={website}
              inputDescription={inputDescription}
              inputTitle={inputTitle}
            />
            <div className="flex justify-center py-12">
              <button
                onClick={handleSubmit}
                className="w-[215px] h-[52px] flex justify-center items-center text-[20px] text-black dark:text-white border-4 border-[#E90570] rounded-lg"
                type="button"
              >
                Post
              </button>
            </div>
          </div>
        </ModalDialogV2>
      )}
      {showModal === 'edit' && (
        <ModalDialogV2 open={open} onBack={onBack} setShowModal={setShowModal} showModal={showModal}>
          <div className="dark:text-white px-4 space-y-6">
            <div>
              <SectionTextField initialValue={inputTitle} onChange={e => setInputTitle(e)} title="Title" />
            </div>
            <div>
              <div className=" pt-2">
                <SectionTextField
                  initialValue={inputDescription}
                  onChange={e => setInputDescription(e)}
                  title="Description"
                />
              </div>
            </div>
            <div className="flex justify-center py-12">
              <button
                onClick={() => setShowModal('post')}
                className="w-[215px] h-[52px] flex justify-center items-center text-[20px] text-black dark:text-white border-4 border-[#E90570] rounded-lg"
                type="button"
              >
                Save
              </button>
            </div>
          </div>
        </ModalDialogV2>
      )}
    </div>
  )
}
