import SectionSlate from '../../../common/SectionSlatePreview'
import { AttentionGrabberSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantSeven({ section }: { section: AttentionGrabberSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: '#b33597',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
      className="relative md:h-[100vh] min-h-[300px] flex justify-center items-center md:py-0 py-4 w-full"
    >
      <div className="py-4 md:py-0 relative">
        <div className="text-center text-white px-6 py-4">
          <h2 className="text-[26px] md:text-[40px] lg:text-[60px] uppercase font-bold">
            <SectionSlate initialValue={title} previewMode />
          </h2>
          <h4 className="text-[18px] md:text-[30px]">
            <SectionSlate initialValue={subTitle} previewMode />
          </h4>
          <p className="md:text-[20px] text-[14px] w-[90%] m-auto  ">
            <SectionSlate initialValue={paragraph} previewMode />
          </p>
        </div>
        <div className="md:w-[60%] absolute ">
          <div className="bubble bubble--1 " />
          <div className="bubble bubble--2" />
          <div className="bubble bubble--3" />
          <div className="bubble bubble--4" />
          <div className="bubble bubble--5" />
          <div className="bubble bubble--6" />
          <div className="bubble bubble--7" />
          <div className="bubble bubble--8" />
          <div className="bubble bubble--9 " />
          <div className="bubble bubble--10 " />
          <div className="bubble bubble--11 " />
          <div className="bubble bubble--12 " />
          <div id="octocat"></div>
        </div>
      </div>
    </div>
  )
}
