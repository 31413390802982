import { useState } from 'react'
import usePostLike from 'src/hooks/usePostLike'
import Chat from 'src/icons/Chat'
import Heart from 'src/icons/Heart'
import Share from 'src/icons/Share'
import Users from 'src/icons/Users'
import UsersLight from 'src/icons/UsersLight'

type SocialSiteCardProps = {
  post: any
}

export default function SocialSiteCard({ post }: SocialSiteCardProps) {
  const [showDescription, setShowDescription] = useState(false)
  const { liked, toggleLike } = usePostLike(post.id)

  return (
    <div>
      <div className="h-[400px] flex justify-center items-center bg-slate-200 rounded-t-md">
        <p>website&apos;s beautiful first section</p>
      </div>
      <div className=" px-4 py-3 border-b border-slate-100 border-l border-r">
        <div className="flex justify-between">
          <div className="flex items-center gap-5">
            <div className="h-[30px] w-[30px] bg-gray-300 rounded-full" />
            <p onClick={() => setShowDescription(!showDescription)} className="cursor-pointer text-xl">
              {post.title}
            </p>
          </div>

          <div className="flex items-center gap-x-4 md:gap-x-10">
            <button type="button" onClick={toggleLike}>
              {liked ? (
                <Heart size={24} className="text-red-500 dark:text-red-500" />
              ) : (
                <Heart size={24} className="text-black dark:text-black" />
              )}
            </button>
            <button type="button" className="outline-none">
              <Chat size={24} className="text-black dark:text-black" />
            </button>
            <button type="button" className="outline-none">
              <UsersLight size={24} className="block dark:hidden" />
              <Users size={24} className="hidden dark:block" />
            </button>
            <button type="button" className="outline-none">
              <Share size={24} className="text-black dark:text-black" />
            </button>
          </div>
        </div>

        {showDescription && post?.description && (
          <div className="mt-3">
            <p className="text-xl">{post.description}</p>
          </div>
        )}
      </div>
    </div>
  )
}
