import { motion } from 'framer-motion'
import { ContactUsSection, InputsContactUSSection } from '../../types'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import TextArea from '../../../common/TextArea'
import InputText from '../../../common/InputText'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import ButtonRenderer from '../ButtonRenderer'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

const viewVariants = {
  offscreen: {
    y: 100,
  },
  onscreen: {
    y: 0,
    transition: {
      duration: 0.5,
      delay: 0.2,
      ease: [0, 0.71, 0.2, 1.01],
    },
  },
}
const viewImgVariants = {
  offscreen: {
    opacity: 0.1,
  },
  onscreen: {
    opacity: 1,
    transition: {
      duration: 1,
      delay: 0.8,
      ease: [0, 0.71, 0.2, 1.01],
    },
  },
}
const viewport = { once: true, amount: 0.5 }

function VariantNine({ section }: { section: ContactUsSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data
  const inputs = section.variables.find(variant => variant.name === 'INPUTS')?.data as InputsContactUSSection[]
  const button = section.variables.find(variable => variable.name === 'BUTTON_1')?.data

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    setLoading(true)
    try {
    } catch (error) {
      setError('Something went wrong!')
    }
    setLoading(false)
  }

  const inputClasses = {
    classInput:
      'block py-1.5 lg:py-2.5 px-0 w-full lg:text-xl text-gray-900 bg-transparent border-0 border-b resize-none border-gray-800 appearance-none  focus:outline-none focus:ring-0  peer',
    classLabel: 'font-medium lg:text-[22px]',
  }

  return (
    <>
      <div
        className="lg:py-12 py-4"
        style={{
          ...generateSectionBackground({
            defaultColor: '#f9f9f9',
            backgroundSettings: section?.settings?.color?.background,
            image: null,
          }),
          ...generateSectionSpacing(section?.settings?.spacing),
        }}
      >
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 py-8 space-y-8 lg:space-y-0 px-4 lg:px-12">
          <div>
            <motion.div initial="offscreen" whileInView="onscreen" viewport={viewport}>
              <motion.div variants={viewVariants}>
                <h2 className="text-3xl lg:text-5xl text-[#a73faf] font-medium pb-3 sm:pb-8 lg:pb-12">
                  <SectionSlatePreview initialValue={title} previewMode />
                </h2>
                <p className="lg:text-[16px]">
                  <SectionSlatePreview initialValue={paragraph} previewMode />
                </p>
              </motion.div>
            </motion.div>
            <motion.div initial="offscreen" whileInView="onscreen" viewport={viewport}>
              <motion.div variants={viewVariants}>
                <div className="pt-4 lg:pt-8">
                  <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col items-start">
                    {inputs.map(item => {
                      return (
                        <div key={item.id} className="relative z-0 mb-3 lg:mb-6 w-full">
                          {item.type !== 'button' && item.type !== 'textarea' && (
                            <InputText
                              {...inputClasses}
                              label={item.name}
                              name={item.name}
                              register={register}
                              type={item.type}
                              errors={errors}
                              placeholder={item.placeholder}
                              required={{ value: true, message: `${item.error}` }}
                            />
                          )}

                          {item.type === 'button' && (
                            <div key={item.id} className="relative z-0 mb-3 lg:mb-6 w-full">
                              <input
                                type={item.type}
                                value={item.name}
                                placeholder={item.placeholder}
                                className="px-5 py-3 font-medium bg-black text-white cursor-pointer"
                              />
                            </div>
                          )}
                          {item.type === 'textarea' && (
                            <div className="relative z-0 mb-3 lg:mb-6 w-full" key={item.id}>
                              <TextArea
                                {...inputClasses}
                                label={item.name}
                                name={item.name}
                                register={register}
                                type={item.type}
                                errors={errors}
                                placeholder={item.placeholder}
                                required={{ value: true, message: `${item.error}` }}
                              />
                            </div>
                          )}
                        </div>
                      )
                    })}
                    {error && <span className="text-secondary.main text-center mb-4">{error}</span>}
                    {button ? (
                      <ButtonRenderer button={button} typeOfButton="submit" withOutHref />
                    ) : (
                      <button
                        type="submit"
                        disabled={loading}
                        className={clsx(
                          loading && 'cursor-not-allowed disabled:opacity-50',
                          'bg-[#a73faf] text-white  lg:px-6 px-3 py-1 rounded-3xl',
                        )}
                      >
                        Send a message
                      </button>
                    )}
                  </form>
                </div>
              </motion.div>
            </motion.div>
          </div>
          <div>
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              viewport={viewport}
              className="h-[650px] lg:w-[90%] hidden lg:block"
            >
              <motion.img variants={viewImgVariants} className="w-full h-full rounded-xl" src={media} alt="" />
            </motion.div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VariantNine
