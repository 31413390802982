import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import SectionSlate from '../../../common/SectionSlatePreview'
import { ImageOmgSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantThree({ section }: { section: ImageOmgSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data

  const text = ['brand vision', 'design culture ', 'teaching browss']
  const [activeIndex, setActiveIndex] = useState(0)
  useEffect(() => {
    const interval = setInterval(
      () => setActiveIndex(prevState => (prevState + 1 === text.length ? 0 : prevState + 1)),
      4000,
    )
    return () => {
      clearInterval(interval)
    }
  }, [])
  return (
    <div
      className="lg:mx-auto lg:py-[50px]"
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <motion.section className="bg-main px-4 md:p-[2rem] py-4 md:mt-10 ">
        <motion.div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 },
            }}
            className="uppercase text-center flex flex-col justify-center 
            items-center"
          >
            <motion.p className="tracking-widest font-semibold md:text-[18px] text-[12px] md:w-[60%] m-auto">
              <SectionSlate initialValue={subTitle} previewMode />
            </motion.p>
            <AnimatePresence exitBeforeEnter>
              {
                text.map(index => (
                  <motion.div
                    className="text-[20px] mt-1 md:text-4xl font-bold uppercase md:mt-4 md:w-[80%] m-auto  font-head drop-shadow-xl shadow-[#eeee] px-2"
                    key={index}
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -40, opacity: 0 }}
                    transition={{ duration: 0.2, type: 'tween' }}
                  >
                    <SectionSlate initialValue={title} previewMode />
                  </motion.div>
                ))[activeIndex]
              }
            </AnimatePresence>
          </motion.div>
        </motion.div>

        <div className="flex items-center justify-center lg:mt-4">
          <div>
            <img src={media} alt="" className="lg:h-[900px] lg:w-[1500px] object-cover object-center" />
          </div>
        </div>
      </motion.section>
    </div>
  )
}
