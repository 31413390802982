/* eslint-disable jsx-a11y/no-static-element-interactions */
import moment from 'moment'
import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { UserContext } from 'src/components/context/UserContext'
import Close from 'src/icons/Close'
import { renderClickableLinks } from 'src/util'
import { IMessageItem } from './ConvoSection'

type Props = {
  message: IMessageItem
  sender: {
    name: string
    avatar?: string
  }
  /** Whether current user is sender? */
  self?: boolean
}

export default function MessageItem(props: Props) {
  const { message, sender, self } = props
  const { user } = useContext(UserContext)
  const [showImgPreview, setShowImgPreview] = useState(false)

  const sending = !!message.sendStatus
  const profileLink = self ? `/profile/${user.id}` : `/profile/${message.senderID}`

  const formatDate = (date: string) => {
    const format = moment().isSame(date, 'day') ? 'hh:mm A' : 'MMM DD hh:mm A'
    return moment(date).format(format)
  }

  function getMessageStatus() {
    if (message.readAt) return `Read at ${formatDate(message.readAt)}`
    if (message.sendStatus === 'sending') return 'Sending...'
    if (message.sendStatus === 'error') return 'Message could not be sent, try again?'
    if (message.createdAt) return `Message sent ${formatDate(message.createdAt)}`
    return ''
  }

  return (
    <div className={`flex ${sending ? 'opacity-60' : 'opacity-100'} ${self ? 'justify-end' : 'justify-start'}`}>
      <div className="flex items-end gap-2">
        <div className={`h-[50px] w-[50px] lg:h-[60px] lg:w-[60px] bg-gray-300 rounded-full mt-2 ${self && 'order-2'}`}>
          <img src={sender?.avatar} className="h-full w-full rounded-full" alt="" />
        </div>
        <div>
          <div
            className={`rounded-3xl p-4 max-w-[270px] ${
              self
                ? 'bg-white text-black rounded-br-none border border-black'
                : 'bg-brand-accent text-white rounded-bl-none'
            }
          `}
          >
            <Link to={profileLink} className="text-sm font-bold">
              {sender.name}
            </Link>
            {message?.type === 'TEXT' && (
              <p className="font-medium break-words">
                {renderClickableLinks(message?.content)?.map((x: any, idx: number) => (
                  <span key={idx} className={`${x?.type === 'a' && 'text-[#1437d3] underline'}`}>
                    {x}
                  </span>
                ))}
              </p>
            )}
            {message?.type === 'IMAGE' && (
              <>
                <img onClick={() => setShowImgPreview(true)} src={message.content} alt="" className="cursor-pointer" />
                <div className={`${!showImgPreview && 'hidden'} fixed z-[9999] top-0 left-0 w-full h-full`}>
                  <div onClick={() => setShowImgPreview(false)} className="w-full h-full absolute z-40 bg-black/70" />
                  <button
                    onClick={() => setShowImgPreview(false)}
                    type="button"
                    className="absolute top-8 right-4 z-50"
                  >
                    <Close size={30} className="text-[#fff]" />
                  </button>
                  <img
                    src={message.content}
                    alt=""
                    className="lg:h-[80%] lg:w-fit h-fit w-11/12 absolute z-50 -translate-x-1/2 left-1/2 top-1/2 -translate-y-1/2"
                  />
                </div>
              </>
            )}
            {!message?.type && <p className="font-medium break-words">{message.content}</p>}
          </div>
          <div>
            <p className="text-xs font-medium text-gray-500 mt-2 mx-2">{getMessageStatus()}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
