import { useState } from 'react'
import { useForm } from 'react-hook-form'
import InputText from '../../../common/InputText'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import TextArea from '../../../common/TextArea'
import { ContactUsSection, ContractSectionContract, InputsContactUSSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantEight({ section }: { section: ContactUsSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  // ?? 'contact us'
  const subtitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data

  //  ??'Be the first to find out about sales, styles and everything between'
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const contact = section.variables.find(variable => variable.name === 'CONTRACT')?.data as ContractSectionContract[]
  const inputs = section.variables.find(variant => variant.name === 'INPUTS')?.data as InputsContactUSSection[]
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const [error, setError] = useState('')

  const onSubmit = async () => {
    try {
    } catch (error) {
      setError('Something went wrong!')
    }
  }

  const inputClasses = {
    classInput:
      'font-extraligh placeholder:text-[12px] w-full placeholder:capitalize placeholder:opacity-80 placeholder:text-[#eeeeee26]  md:py-3 border-b-[1px]  border-[#eee] outline-none resize-none bg-transparent  placeholder:text-gray-300 border-b-3 my-2 md:my-0',
    classLabel: 'font-extralight inline-block ',
  }

  let indexIdentifier = 4

  return (
    <div
      className="lg:py-12 py-4 text-[silver]"
      style={{
        ...generateSectionBackground({
          defaultColor: '#000000dc',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="py-5 grid xl:grid-cols-2 lg:px-12 px-4 text-[#eee]">
        <div className="flex flex-col lg:w-[80%] justify-center">
          <div>
            <h1 className="md:text-[2rem] text-3xl text-white">
              <SectionSlatePreview initialValue={title} previewMode />
            </h1>
            <p className="md:text-2xl md:mt-4">
              <SectionSlatePreview initialValue={subtitle} previewMode />
            </p>
          </div>
          {contact && (
            <div className="xl:mt-12 xl:text-xl flex flex-col gap-y-5 ">
              {contact.map(({ id, name, description }) => {
                return (
                  <div key={id} className="flex gap-x-2 ">
                    {/* <p className=" font-semibold">{name} </p> */}
                    <p className="font-semibold">
                      <SectionSlatePreview initialValue={name} previewMode />
                    </p>
                    {/* <p className="w-[80%]">{description}</p> */}
                    <p className="w-[80%]">
                      <SectionSlatePreview initialValue={description} previewMode />
                    </p>
                  </div>
                )
              })}
            </div>
          )}
          <div className="flex items-center xl:mt-12 mt-5 gap-x-2 md:text-xl capitalize text-gray-300">
            <div className="bg-gray-300 md:w-[40px] w-[10px] h-[1px]" />
            <p>
              <SectionSlatePreview initialValue={paragraph} previewMode />
            </p>
          </div>
        </div>

        <>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ boxShadow: '1px 3px 7px 2px gray' }}
            className="lg:px-8 px-2 py-4 lg:py-12 lg:w-[90%] lg:mt-0 mt-12 justify-center items-center"
          >
            {inputs && (
              <div className={`text-sm lg:grid space-y-4 md:space-y-0 md:gap-12 `}>
                {inputs.map(({ type, name, error, id, placeholder }, idx) => {
                  if (idx === indexIdentifier) {
                    if (indexIdentifier % 9 == 0) {
                      indexIdentifier += 2
                    } else {
                      indexIdentifier += 1
                    }

                    return (
                      <div key={id} className="col-span-2 ">
                        {type !== 'button' && type !== 'textarea' && (
                          <InputText
                            {...inputClasses}
                            label={name}
                            name={name}
                            register={register}
                            type={type}
                            errors={errors}
                            placeholder={placeholder}
                            required={{ value: true, message: `${error}` }}
                          />
                        )}

                        {type === 'button' && (
                          <div className="w-full col-span-2 ">
                            <input
                              type={type}
                              value={name}
                              placeholder={placeholder}
                              className="px-5 py-3 font-medium bg-black text-white cursor-pointer"
                            />
                          </div>
                        )}
                        {type === 'textarea' && (
                          <div>
                            <TextArea
                              {...inputClasses}
                              label={name}
                              name={name}
                              placeholder={placeholder}
                              register={register}
                              type={type}
                              required={{ value: true, message: `${error}` }}
                              errors={errors}
                            />
                          </div>
                        )}
                      </div>
                    )
                  } else {
                    return (
                      <div key={id}>
                        {type !== 'button' && type !== 'textarea' && (
                          <div className="w-full">
                            <InputText
                              errors={errors}
                              {...inputClasses}
                              label={name}
                              name={name}
                              register={register}
                              type={type}
                              placeholder={placeholder}
                              required={{ value: true, message: `${error}` }}
                            />
                          </div>
                        )}

                        {type === 'button' && (
                          <div className="w-full">
                            <input
                              placeholder={placeholder}
                              type={type}
                              value={name}
                              // placeholder={placeholder}
                              className="px-5 py-3 font-medium bg-black text-white cursor-pointer"
                            />
                          </div>
                        )}
                        {type === 'textarea' && (
                          <div className="w-full">
                            <TextArea
                              {...inputClasses}
                              placeholder={placeholder}
                              label={name}
                              name={name}
                              register={register}
                              type={type}
                              errors={errors}
                              required={{ value: true, message: `${error}` }}
                            />
                          </div>
                        )}
                      </div>
                    )
                  }
                })}
              </div>
            )}
            {error && <span className="text-secondary.main text-center mb-4">{error}</span>}
          </form>
        </>
      </div>
    </div>
  )
}
