import SectionSlate from '../../../common/SectionSlatePreview'
import { ImageOmgSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantFive({ section }: { section: ImageOmgSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data

  return (
    <div>
      <div
        className="py-[50px] lg:py-[100px] bg-cover bg-no-repeat bg-center"
        style={{
          ...generateSectionBackground({
            defaultColor: '#000',
            backgroundSettings: section?.settings?.color?.background,
            image: null,
          }),
          ...generateSectionSpacing(section?.settings?.spacing),
        }}
      >
        {media && (
          <div className="bg-black pb-4 md:py-12 md:pb-10 pt-4">
            <div className="h-[445px] w-[940px] max-w-full mx-auto">
              <img className="h-full w-full object-cover object-center bg-black" src={media} alt="" />
            </div>
          </div>
        )}
        <div></div>

        <div className="container mx-auto px-6 md:px-0">
          <div className="w-[940px] max-w-full mx-auto pb-4">
            <div className="text-[26px] md:text-4xl md:font-bold  text-white ">
              <SectionSlate initialValue={title} previewMode />
            </div>
            {subTitle && (
              <div className="md:text-[20px] text-white text-[14px]">
                <SectionSlate initialValue={subTitle} previewMode />
              </div>
            )}
            {paragraph && (
              <div className="text-white md:text-[20px]">
                <SectionSlate initialValue={paragraph} previewMode />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
