import { Route, Routes } from 'react-router-dom'
import LandingPage from '../landing-page'
import About from './about-page/about'
import AllUSers from './allusers/allusers'
import Feed from './feed/feed'
import Messengers from './messenger/messengers'
import MobileChat from './messenger/mobilechat'
import Posts from './posts-page/posts'
import PreviewPage from './preview-page/preview-page'
import SignIn from './sign-in/sign-in'
import ProfilePage from './profile/profile'
import SearchPage from './search-page/searchpage'

export default function Social() {
  return (
    <div>
      <Routes>
        <Route path="signin" element={<SignIn />} />
        <Route path="landing-page" element={<LandingPage />} />
        <Route path="about" element={<About />} />
        <Route path="posts" element={<Posts />} />
        <Route path="feed" element={<Feed />} />
        <Route path="messages" element={<Messengers />} />
        <Route path="messages/:chatmateId" element={<Messengers />} />
        <Route path="preview" element={<PreviewPage />} />
        <Route path="messenger/message" element={<MobileChat />} />
        <Route path="all-users" element={<AllUSers />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="search" element={<SearchPage />} />
      </Routes>
    </div>
  )
}
