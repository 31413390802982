import { TextSection as textsectionTypes } from '../../types'
import SectionSlate from '../../../common/SectionSlatePreview'
import { motion } from 'framer-motion'
import ButtonRenderer from '../ButtonRenderer'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantFour({ section }: { section: textsectionTypes }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data
  const button = section.variables.find(variable => variable.name === 'BUTTON_1')?.data

  return (
    <div
      className="mt-5 flex flex-col items-center justify-center"
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="grid grid-cols-1  md:grid-cols-2 border md:border-none md:gap-x-6 gap-x-2 shadow-xl md:border-2 items-center md:justify-between justify-center p-4 md:p-4 md:w-[1400px] ">
        <div className="bg-white rounded-[30px] flex flex-col items-center justify-center pb-6 md:pb-0 md:min-h-[400px]">
          {title && (
            <div>
              <p className="text-3xl md:text-4xl font-bold text-center md:mt-8 flex flex-col items-center text-[#D37113]">
                {<SectionSlate initialValue={title} previewMode />}
              </p>
            </div>
          )}
          {subTitle && (
            <div>
              <p className="rounded-[30px]  px-6 text-sm md:py-2 xl:text-2xl font-semibold md:mt-5 capitalize  text-center outline-none">
                {<SectionSlate initialValue={subTitle} previewMode />}
              </p>
            </div>
          )}

          {button ? (
            <ButtonRenderer button={button} />
          ) : (
            <button className="bg-[#D37113]  text-white rounded-[30px] xl:px-12 px-6 xl:py-2 py-1 xl:text-xl text-sm font-semibold xl:mt-12 mt-2 capitalize outline-none">
              for more
            </button>
          )}
        </div>

        <div>
          {media && (
            <div className="rounded-[30px] flex items-center justify-center ">
              <motion.img
                whileHover={{
                  scale: 1.1,
                  transition: {
                    duration: 1,
                  },
                }}
                src={media}
                className="flex-1 xl:mt-0 h-[200px] md:p-4  object-cover md:h-[400px] w-[350px] md:mt-10 md:w-[800px] transition duration-500 sm:order-1
                hover:brightness-90 object-center"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
