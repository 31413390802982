interface ConfirmDeleteModalProps {
  open: boolean
  onConfirm: (status: boolean) => void
  title?: string
}

const ConfirmModal = ({
  open,
  onConfirm,
  title = 'Are you sure you would like to delete this component?',
}: ConfirmDeleteModalProps) => {
  if (!open) return null
  return (
    <div className="z-50 fixed top-0 right-0 bottom-0 left-0 justify-center items-center">
      <div className="relative w-full h-full bg-white/90 dark:bg-black/90 flex justify-center items-center">
        {/* <!-- Modal content --> */}
        <div className="w-[362px] bg-white dark:bg-black border-[1px] rounded-[6px] border-[#E90570] border-solid  relative">
          <div className="pt-[13px] pb-[20px]">
            <p className="text-[19px] text-black dark:text-white text-center">{title}</p>
          </div>
          <div className="grid border-t dark:border-white mx-[10px] border-black grid-cols-2">
            <button
              onClick={() => onConfirm(true)}
              type="button"
              className="border-r text-[19px] dark:border-white dark:text-white border-black text-center text-black my-[16px] "
            >
              YES
            </button>
            <button
              onClick={() => onConfirm(false)}
              type="button"
              className="dark:text-white text-[19px] text-black my-[16px]"
            >
              NO
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmModal
