import { ReactNode, useState } from 'react'
import ModalDialog, { ModalProps } from 'src/components/new/ModalDialog'
import { SectionTypes } from 'src/_polly/components/src/sections'
import { ReactComponent as Aboutus } from '../icons/Categories/Aboutus.svg'
import { ReactComponent as Blog } from '../icons/Categories/Blog.svg'
import { ReactComponent as Dmus } from '../icons/Categories/DmUs.svg'
import { ReactComponent as Faq } from '../icons/Categories/Faq.svg'
import { ReactComponent as GalleryLit } from '../icons/Categories/GalleryLit.svg'
import { ReactComponent as Love1stSite } from '../icons/Categories/Love@1stSite.svg'
import { ReactComponent as Menu } from '../icons/Categories/Menu.svg'
import { ReactComponent as Money } from '../icons/Categories/Money.svg'
import { ReactComponent as Omg } from '../icons/Categories/Omg.svg'
import { ReactComponent as Portfolio } from '../icons/Categories/Portfolio.svg'
import { ReactComponent as Quote } from '../icons/Categories/Quoteme.svg'
import { ReactComponent as Table } from '../icons/Categories/Table.svg'
import { ReactComponent as Team } from '../icons/Categories/Team.svg'
import { ReactComponent as Viving } from '../icons/Categories/Vibing.svg'
import { ReactComponent as Yolo } from '../icons/Categories/Yolo.svg'
import ModalDialogV2 from '../new/ModalDialogV2'
import NewSectionVariantSelection from './NewSectionVariantSelection'
import NewTextSection from './NewText'

export function Item({ image, label, onClick }: { image?: any; label: string; onClick?: () => void }) {
  return (
    <button
      type="button"
      className={`w-full text-left h-[108px] rounded-[8px] flex items-center justify-center px-[16px] text-[28px] font-light bg-[#D9D9D9] ${
        !onClick && 'cursor-not-allowed'
      }`}
      {...{ onClick }}
    >
      {image || <p>{label.slice(0, 6)}</p>}
    </button>
  )
}

export function SectionCategory({ children }: { children: ReactNode }) {
  return <div className="space-y-5">{children}</div>
}

export default function NewSection(props: ModalProps) {
  const { onClose } = props
  const { websiteId } = props
  const { componenstLength } = props

  const [showTextSection, setShowTextSection] = useState(false)
  const [selectedSection, setSelectedSection] = useState<SectionTypes | undefined>()

  const handleSetTutorial = () => {
    localStorage.setItem(
      `tutorial_selection_${websiteId}`,
      JSON.stringify(JSON.parse(localStorage.getItem(`tutorial_selection_${websiteId}`) || '0') + 1),
    )
  }

  const tutorialSelectionCount = JSON.parse(localStorage.getItem(`tutorial_selection_${websiteId}`) || '1')

  const tutorialCount = JSON.parse(localStorage.getItem(`tutorial_component_${websiteId}`) || '1')

  const tutorial = {
    title: 'Component Categories',
    description:
      componenstLength === 0
        ? 'Select a component to start building your site.'
        : 'Our pages are made of components. Each component adds a section to your web page.  Just select your favorite component... and build your site.',
  }
  const tutorialSelection = {
    title: 'Select a Component',
    description:
      'Swipe left or right to view all of the components in this category.  Then press select and its yours!',
  }
  return (
    <ModalDialog title="Component Categories" tutorialCount={tutorialCount} tutorial={tutorial} {...props}>
      <NewTextSection
        open={showTextSection}
        onBack={() => setShowTextSection(false)}
        onClose={() => {
          setShowTextSection(false)
          onClose()
        }}
      />
      <div className="w-full px-5 grid grid-cols-3 gap-5">
        <SectionCategory>
          <Item
            image={<Love1stSite />}
            label="Love @ First Site"
            onClick={() => {
              setSelectedSection('ATTENTION_GRABBER')
              handleSetTutorial()
            }}
          />
        </SectionCategory>
        <SectionCategory>
          <Item
            image={<Omg />}
            label="Image OMG"
            onClick={() => {
              setSelectedSection('IMAGE_OMG')
              handleSetTutorial()
            }}
          />
        </SectionCategory>
        <SectionCategory>
          <Item
            // image={<Gallery />}
            image={<GalleryLit />}
            label="Gallery Be Lit"
            onClick={() => {
              setSelectedSection('GALLERY')
              handleSetTutorial()
            }}
          />
        </SectionCategory>
        <SectionCategory>
          <Item
            image={<Yolo />}
            label="YOLO Text"
            onClick={() => {
              setSelectedSection('TEXT')
              handleSetTutorial()
            }}
          />
        </SectionCategory>
        <SectionCategory>
          <Item
            image={<Blog />}
            label="Goat Blog"
            onClick={() => {
              setSelectedSection('BLOG')
              handleSetTutorial()
            }}
          />
        </SectionCategory>
        <SectionCategory>
          <Item
            image={<Viving />}
            label="Testimonial"
            onClick={() => {
              setSelectedSection('TESTIMONIAL')
              handleSetTutorial()
            }}
          />
        </SectionCategory>
        <SectionCategory>
          <Item
            image={<Portfolio />}
            label="Portfolio"
            onClick={() => {
              setSelectedSection('PORTFOLIO')
              handleSetTutorial()
            }}
          />
        </SectionCategory>
        <SectionCategory>
          <Item
            image={<Quote />}
            label="Quote Me"
            onClick={() => {
              setSelectedSection('QUOTE_SECTION')
              handleSetTutorial()
            }}
          />
        </SectionCategory>
        <SectionCategory>
          <Item
            image={<Aboutus />}
            label="Get to Know Us"
            onClick={() => {
              setSelectedSection('ABOUT_US')
              handleSetTutorial()
            }}
          />
        </SectionCategory>
        <SectionCategory>
          <Item
            image={<Dmus />}
            label="DM Us"
            onClick={() => {
              setSelectedSection('CONTACT_US')
              handleSetTutorial()
            }}
          />
        </SectionCategory>
        <SectionCategory>
          <Item
            image={<Menu />}
            label="On the MENU"
            onClick={() => {
              setSelectedSection('MENU')
              handleSetTutorial()
            }}
          />
        </SectionCategory>
        <SectionCategory>
          <Item image={<Money />} label="Money" />
        </SectionCategory>
        <SectionCategory>
          <Item
            image={<Table />}
            label="Table"
            onClick={() => {
              setSelectedSection('TABLE')
              handleSetTutorial()
            }}
          />
        </SectionCategory>
        <SectionCategory>
          <Item
            image={<Team />}
            label="No I in Team"
            onClick={() => {
              setSelectedSection('TEAM_MEMBERS')
              handleSetTutorial()
            }}
          />
        </SectionCategory>
        <SectionCategory>
          <Item
            image={<Faq />}
            label="All the FAQs"
            onClick={() => {
              setSelectedSection('FAQ')
              handleSetTutorial()
            }}
          />
        </SectionCategory>
        {/* create Setcion button  */}
        <button
          type="button"
          className="w-full hidden text-center h-[108px] rounded-[8px] px-[16px] font-light bg-[#e9056fd5]"
          onClick={() => setShowTextSection(true)}
        >
          <h1 className="text-7xl font-normal text-white">+</h1>
        </button>
      </div>

      <ModalDialogV2
        tutorial={tutorialSelection}
        tutorialCount={tutorialSelectionCount}
        open={selectedSection !== undefined}
        onBack={() => setSelectedSection(undefined)}
      >
        <NewSectionVariantSelection
          onClose={() => {
            setSelectedSection(undefined)
            onClose()
          }}
          sectionType={selectedSection}
        />
      </ModalDialogV2>
    </ModalDialog>
  )
}
