import { Dialog, Popover, Transition } from '@headlessui/react'
import { useContext, useEffect, useState } from 'react'
import GoogleFontLoader from 'react-google-font-loader'
import { Link, useNavigate } from 'react-router-dom'
import { NotificationsContext } from 'src/components/context/NotificationContext'
import { UserContext } from 'src/components/context/UserContext'
import Close from 'src/icons/Close'
import NotificationTWo from 'src/icons/NotificationTwo'
import Profile from 'src/icons/Profile'
import RightArrow from 'src/icons/RightArrow'
import Send from 'src/icons/Send'
import TechboomLogo from 'src/icons/TechboomLogo'
import { getWebsitesByUserID } from 'src/lib/services/website.service'
import CreatePostModal from './CreatePostModal'
import NotificationModal from './NotificationModal'
import ProfileModal from './ProfileModal'
import SettingsModal from './SettingsModal'

type HeaderProps = {
  variant?: 'minimal' | 'profile' | 'default'
}

function Header({ variant = 'default' }: HeaderProps) {
  const navigate = useNavigate()
  const { notifyCount, msgNotifyCount } = useContext(NotificationsContext)
  const { user } = useContext(UserContext)

  const [websites, setWebsites] = useState<any[]>([])
  const [settingModalOpen, setSettingModalOpen] = useState(false)
  const [showPostModal, setShowPostModal] = useState(false)
  const [showCreateImage, setShowCreateImage] = useState(false)
  const [showCreatePost, setShowCreatePost] = useState(false)
  // comments modal open based on desktop and mobile
  // const [width, setWidth] = useState<number>(window.innerWidth)
  // useEffect(() => {
  //   const handleResize = () => setWidth(window.innerWidth)
  //   window.addEventListener('resize', handleResize)
  //   return () => {
  //     window.removeEventListener('resize', handleResize)
  //   }
  // }, [])

  const fetchWebsites = () => {
    getWebsitesByUserID().then(res => {
      setWebsites(res?.items || [])
    })
  }

  useEffect(() => {
    fetchWebsites()
  }, [])

  return (
    <>
      <GoogleFontLoader
        fonts={[
          {
            font: 'Monoton',
            weights: [400],
          },
        ]}
        subsets={['cyrillic-ext', 'greek']}
      />
      <section className="md:flex justify-between items-center bg-[#ffff] dark:bg-[#000000] px-6 md:px-12 h-[86px] border-b-[3px] border-[#000000] dark:border-[#FFFFFF] py-4 md:py-0">
        <div className="flex justify-between md:gap-x-8  lg:gap-x-10">
          <div className="w-[45px] h-[45px]">
            <Link to="/home">
              <TechboomLogo size={50} fill="none" className="stroke-black dark:stroke-white stroke-2" />
            </Link>
          </div>

          {variant === 'default' && (
            <div className="flex gap-x-4">
              {websites.slice(0, 3).map((website, idx) => (
                <Link
                  onClick={() =>
                    localStorage.setItem(
                      `tutorial_editpage_${website?.id}`,
                      JSON.stringify(JSON.parse(localStorage.getItem(`tutorial_editpage_${website?.id}`) || '0') + 1),
                    )
                  }
                  to={`/websites/${website.id}`}
                  key={idx}
                >
                  {JSON.parse(website?.config)?.logo ? (
                    <img
                      src={JSON.parse(website?.config)?.logo}
                      alt=""
                      className="h-[50px] w-[30px] object-cover  border rounded-[3px] border-black dark:border-white"
                    />
                  ) : (
                    <div
                      // className="w-[30px] h-[50px] font-semibold rounded bg-[#ddd] text-xl dark:bg-[#000] dark:text-white border-y-4 border-x-2 border-black dark:border-white flex items-center justify-center  text-center"
                      className="font-monoton w-[30px] text-[23px] h-[50px] text-xl  dark:text-white border rounded-[3px] border-black dark:border-white flex items-center justify-center  text-center"
                    >
                      {website.name.split('')[0]}
                    </div>
                  )}
                </Link>
              ))}
              <div>
                {/* <CreateStory height="50" /> */}
                <button
                  onClick={() => navigate('/builder?newSite=true')}
                  // className="w-[30px] h-[50px] rounded bg-[#ddd] dark:bg-[#000] dark:text-white border-y-4 border-x-2 border-black dark:border-white flex items-center justify-center text-[30px]"
                  className="w-[30px] h-[50px] rounded-[3px] dark:text-white border-y-[3px] border-x border-black dark:border-white flex items-center justify-center text-[30px]"
                  type="button"
                >
                  +
                </button>
              </div>
            </div>
          )}
          {variant === 'profile' && (
            <div className="flex md:hidden gap-x-4">
              <button type="button" onClick={() => navigate(-1)}>
                <Close size={24} className="text-[#fff]" />
              </button>
              <button type="button">
                <RightArrow size={24} className="" />
              </button>
            </div>
          )}
        </div>
        <div className="hidden md:flex md:space-x-8 lg:space-x-10">
          <div>
            <button
              className="w-[200px] bg-[#E90570] text-[20px] text-white text-center py-2 rounded"
              type="button"
              onClick={() => setShowPostModal(true)}
            >
              Create
            </button>
            <CreatePostModal isOpen={showPostModal} setIsOpen={setShowPostModal} />
          </div>
          <div className="relative pt-1">
            <button type="submit" onClick={() => navigate('/messages')}>
              <Send size={35} className="text-black dark:text-white relative z-10" />
            </button>
            {msgNotifyCount > 0 && (
              <div className="w-[32px] -right-4 -top-2 z-0 absolute h-[32px] rounded-full  flex justify-center items-center bg-brand-accent text-white text-[18px] ">
                {msgNotifyCount}
              </div>
            )}
          </div>
          <Popover className="relative z-30">
            <Popover.Button className="focus:outline-0">
              <div className="relative">
                <NotificationTWo size={40} className="text-black dark:text-white relative z-10" />

                {notifyCount > 0 && (
                  <div className="w-[32px] -top-2 -right-2 absolute h-[32px] rounded-full flex z-0 justify-center items-center bg-brand-accent text-white text-[18px]">
                    {notifyCount}
                  </div>
                )}
              </div>
            </Popover.Button>
            <Transition
              enter="transition ease-out duration-500"
              enterFrom="opacity-0 -translate-y-5"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-300"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 -translate-y-5"
            >
              <Popover.Panel className="absolute right-0 z-10 mt-7">
                <NotificationModal />
              </Popover.Panel>
            </Transition>
          </Popover>
          <Popover className="relative z-50">
            <Popover.Button className="focus:outline-0">
              {user?.profilePicture ? (
                <img
                  src={user?.profilePicture}
                  className="w-[40px] h-[40px] rounded-full border-2 border-black dark:border-white"
                  alt=""
                />
              ) : (
                <>
                  <Profile size={40} fill="white" stroke="black" strokeWidth={1.5} className="dark:hidden" />
                  <Profile size={40} fill="#D9D9D9" className="hidden dark:block" />
                </>
              )}
            </Popover.Button>
            <Transition
              enter="transition ease-out duration-500"
              enterFrom="opacity-0 -translate-y-9"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-300"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 -translate-y-5"
            >
              <Popover.Panel className="absolute right-0 z-10">
                <ProfileModal
                  setSettingModalOpen={setSettingModalOpen}
                  setCreateImageModalOpen={setShowCreateImage}
                  setCreatePostModalOpen={setShowCreatePost}
                />
              </Popover.Panel>
            </Transition>
          </Popover>
        </div>
      </section>
      {/* <SettingsModal /> */}
      {/* {width > 768 && settingModalOpen && ( */}
      <Transition appear show={settingModalOpen}>
        <Dialog as="div" className="relative z-50" onClose={() => setSettingModalOpen(false)}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-white/90 dark:bg-black/90" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full transform overflow-hidden rounded-2xl bg-white dark:bg-black border-4 border-[#4E4E4E] px-8 py-6 text-left align-middle shadow-xl transition-all">
                  <SettingsModal />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={showCreateImage}>
        <Dialog as="div" className="relative z-50" onClose={() => setShowCreateImage(false)}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel>
              <CreatePostModal isOpen={showCreateImage} setIsOpen={() => setShowCreateImage(false)} modal="image" />
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition>
      <Transition appear show={showCreatePost}>
        <Dialog as="div" className="relative z-50" onClose={() => setShowCreatePost(false)}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel>
              <CreatePostModal isOpen={showCreatePost} setIsOpen={() => setShowCreatePost(false)} modal="post" />
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  )
}

export default Header
