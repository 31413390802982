import { FAQSection, FAQSectionItem } from '../../types'
import { motion } from 'framer-motion'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
export default function VariantFive({ section }: { section: FAQSection }) {
  const faqs = section.variables.find(variable => variable.name === 'ITEMS')?.data as FAQSectionItem[]
  return (
    <div
      className="py-6"
      style={{
        ...generateSectionBackground({
          defaultColor: '#ffffff',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="container mx-auto md:items-start md:px-12 px-4">
        <div className="grid grid-cols-1 gap-16  md:grid-cols-4 ">
          {faqs.map(item => {
            return (
              <motion.div
                initial={{ opacity: 0, y: 40 }}
                whileInView={{ y: 0, opacity: 1, transition: { duration: 1 } }}
                key={item.id}
                className="flex flex-col gap-y-4 md:items-start items-center"
              >
                <div className="overflow-hidden ease-linear">
                  <p className="space-y-3">
                    <p className="md:text-xl">
                      <SectionSlatePreview initialValue={item.title} previewMode />
                    </p>
                    <hr className="bg-[silver] h-[1px] w-full md:w-full" />
                  </p>
                </div>
                <div className="overflow-hidden flex text-center md:text-left text-sm md:text-lg">
                  <p>
                    <SectionSlatePreview initialValue={item.description} previewMode />
                  </p>
                </div>
              </motion.div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
