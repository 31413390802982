import { NewMenu } from './menu'
import MenuVariantEight from './variants/VariantEight'
import MenuVariantEighteen from './variants/VariantEighteen'
import MenuVariantEleven from './variants/VariantEleven'
import MenuVariantFifteen from './variants/VariantFifteen'
import MenuVariantFive from './variants/VariantFive'
import MenuVariantFour from './variants/VariantFour'
import MenuVariantFourteen from './variants/VariantFourteen'
import MenuVariantNine from './variants/VariantNine'
import MenuVariantNineteen from './variants/VariantNineteen'
import MenuVariantOne from './variants/VariantOne'
import MenuVariantSeven from './variants/VariantSeven'
import MenuVariantSeventeen from './variants/VariantSeventeen'
import MenuVariantSix from './variants/VariantSix'
import MenuVariantSixteen from './variants/VariantSixteen'
import MenuVariantTen from './variants/VariantTen'
import MenuVariantThirteen from './variants/VariantThirteen'
import MenuVariantThree from './variants/VariantThree'
import MenuVariantTwelve from './variants/VariantTwelve'
import MenuVariantTwenty from './variants/VariantTwenty'
import MenuVariantTwentyFive from './variants/VariantTwentyFive'
import MenuVariantTwentyFour from './variants/VariantTwentyFour'
import MenuVariantTwentyOne from './variants/VariantTwentyOne'
import MenuVariantTwentyThree from './variants/VariantTwentyThree'
import MenuVariantTwo from './variants/VariantTwo'

const variants: any = {
  VARIANT_1: MenuVariantOne,
  VARIANT_2: MenuVariantTwo,
  VARIANT_3: MenuVariantThree,
  VARIANT_4: MenuVariantFour,
  VARIANT_5: MenuVariantFive,
  VARIANT_6: MenuVariantSix,
  VARIANT_7: MenuVariantSeven,
  VARIANT_8: MenuVariantEight,
  VARIANT_9: MenuVariantNine,
  VARIANT_10: MenuVariantTen,
  VARIANT_11: MenuVariantEleven,
  VARIANT_12: MenuVariantTwelve,
  VARIANT_13: MenuVariantThirteen,
  VARIANT_14: MenuVariantFourteen,
  VARIANT_15: MenuVariantFifteen,
  VARIANT_16: MenuVariantSixteen,
  VARIANT_17: MenuVariantSeventeen,
  VARIANT_18: MenuVariantEighteen,
  VARIANT_19: MenuVariantNineteen,
  VARIANT_20: MenuVariantTwenty,
  VARIANT_21: MenuVariantTwentyOne,
  VARIANT_22: MenuVariantTwentyThree,
  VARIANT_23: MenuVariantTwentyFour,
  VARIANT_24: MenuVariantTwentyFive,
}

export default function MenuRenderer({ menu, website }: { menu: NewMenu; website: any }) {
  const Menu = variants[menu.id]
  return Menu ? <Menu menu={menu} website={website} /> : null
}
