import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { FAQSection, FAQSectionItem } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

function VariantSix({ section }: { section: FAQSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const faqs = section.variables.find(variable => variable.name === 'ITEMS')?.data as FAQSectionItem[]
  return (
    <>
      <div
        className="md:flex md:justify-between xl:justify-start px-[20px] md:px-[32px] py-12"
        style={{
          ...generateSectionBackground({
            defaultColor: '#262525',
            backgroundSettings: section?.settings?.color?.background,
            image: null,
          }),
          ...generateSectionSpacing(section?.settings?.spacing),
        }}
      >
        <div className="xl:w-[490px] lg:w-[400px] md:w-[200px]">
          <p className="text-[18px] sm:text-[20px] text-[#bebebe] uppercase font-neuton">
            <SectionSlatePreview initialValue={title} previewMode />
          </p>
          <div className="xl:w-[330px] text-[#bebebe] py-6">
            <div>
              <h4 className="text-[16px] lg:ext-[20px] font-neuton uppercase pb-2 opacity-60">
                <SectionSlatePreview initialValue={subTitle} previewMode />
              </h4>
              <hr className="opacity-20" />
            </div>
            {faqs?.map(faq => (
              <div key={faq?.id}>
                <div className="lg:flex justify-between items-center py-2">
                  <h4 className="text-[18px] md:text-[20px] xl:text-[34px] uppercase leading-none font-neue pb-2 lg:pb-0">
                    <SectionSlatePreview initialValue={faq?.title} previewMode />
                  </h4>
                  <h4 className="text-[16px] md:text-[18px] lg:text-[20px] leading-none font-neuton ">
                    <SectionSlatePreview initialValue={faq?.description} previewMode />
                  </h4>
                </div>
                <hr className="opacity-20" />
              </div>
            ))}
          </div>
        </div>
        <div className="md:w-[490px] text-[#bebebe] pt-4 md:pt-0">
          <p className="text-[24px] sm:text-[33px] md:text-[40px] lg:text-[50px] font-neuton leading-none ">
            &nbsp;&nbsp;&nbsp;&nbsp;
            <SectionSlatePreview initialValue={paragraph} previewMode />
          </p>
        </div>
      </div>
    </>
  )
}

export default VariantSix
