import EditPlus from 'src/icons/EditPlus'
import LinkIcon from 'src/icons/Link'
import Save from 'src/icons/Save'
import UpIcon from 'src/icons/UpIcon'
import { NewMenuLink } from 'src/_polly/components/src/menu/menu'
import NewMenuAddNewLink from './AddNewLink'

type LinkItemProps = {
  item: NewMenuLink
  isNameClickable?: boolean
  index?: number
  onEditClick?: () => void
  onEditBack?: () => void
  onNameClick?: () => void
  onDuplicateClick?: () => void
  onDeleteClick?: () => void
  onUpClick?: () => void
  onDownClick?: () => void
  onAdd?: any
  myLinks?: any
  defaultValue?: any
  handleLinkAdd?: (link: NewMenuLink) => void
  allLink?: any
}

export default function LinkItem({
  item,
  index,
  isNameClickable,
  onNameClick,
  onDeleteClick,
  onDownClick,
  onDuplicateClick,
  onUpClick,
  onAdd,
  myLinks,
  handleLinkAdd,
  onEditClick,
  onEditBack,
  defaultValue,
  allLink,
}: LinkItemProps) {
  return (
    <div className="">
      <div className="h-[76px] border-b-[1px] border-[#D9D9D9] flex justify-between px-[22px] items-center">
        <div className="flex items-center gap-4 md:gap-[18px]">
          <LinkIcon height={10} className="stroke-[#8D8D8D] fill-[#8D8D8D] dark:fill-[#D9D9D9] dark:stroke-[#D9D9D9]" />
          <p className="md:text-[19px] text-left dark:text-white">{item.name}</p>
        </div>
        {myLinks && !allLink ? (
          <button type="button" disabled={!isNameClickable} onClick={onNameClick}>
            <Save height={23} fill="none" />
          </button>
        ) : (
          <div className="flex items-center">
            {!allLink && (
              <div>
                {index !== 0 && (
                  <button type="button" className="md:mr-[32px]" onClick={onUpClick}>
                    <UpIcon height={15} className="text-[#8D8D8D] dark:text-[#D9D9D9] hidden md:block" />
                    <UpIcon height={13} className="text-[#8D8D8D] dark:text-[#D9D9D9] md:hidden" />
                  </button>
                )}
              </div>
            )}
            {!allLink && (
              <button type="button" className="md:mr-[27px]" onClick={onDownClick}>
                <UpIcon height={15} className="text-[#8D8D8D] dark:text-[#D9D9D9] rotate-180 hidden md:block" />
                <UpIcon height={13} className="text-[#8D8D8D] dark:text-[#D9D9D9] rotate-180 md:hidden" />
              </button>
            )}
            <button type="button" onClick={onEditClick}>
              <EditPlus
                size={50}
                fill="none"
                className="stroke-[#8D8D8D] fill-white  ml-5 md:ml-0 dark:fill-black dark:stroke-white"
              />
            </button>
          </div>
        )}
      </div>
      {defaultValue === item && (
        <div>
          <div className="fixed z-30 inset-0 lg:max-w-[500px] dark:bg-black bg-white" />
          <div className="absolute top-[20vh] px-[22px] z-40 inset-0 w-full">
            <NewMenuAddNewLink
              handleLinkAdd={handleLinkAdd}
              editBack={onEditBack}
              defaultValue={item}
              index={index}
              onDeleteClick={onDeleteClick}
              onDuplicateClick={onDuplicateClick}
              onAdd={onAdd}
            />
          </div>
        </div>
      )}
    </div>
  )
}
