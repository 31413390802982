import { motion } from 'framer-motion'
import { useState } from 'react'
import { GiCrossMark, GiHeartKey } from 'react-icons/gi'
import { IoMenuOutline } from 'react-icons/io5'
import FontLoader from '../FontLoader'
import { MenuVariantProps, NewMenuLinkType } from '../menu'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../utils'
export default function MenuVariantFive({ menu, website }: MenuVariantProps) {
  return (
    <div>
      <FontLoader settings={menu?.settings?.fontFamily} />
      <Navbar menu={menu} website={website} />
    </div>
  )
}

const Navbar = ({ menu, website }: MenuVariantProps) => {
  const [show, setShow] = useState(false)
  return (
    <div>
      {!show && (
        <div
          style={{
            ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'white' }),
            ...getSpacing(menu?.settings?.spacing),
          }}
        >
          <div className="flex items-center lg:px-12 justify-between px-4  py-4 md:py-6">
            <a href="/">
              {website?.config.logo ? (
                <a href="/">
                  <img
                    src={website?.config?.logo}
                    alt={website?.name}
                    className="cursor-pointer md:w-[80px] md:h-[80px] w-[30px] h-[30px] rounded-full "
                  />
                </a>
              ) : (
                <p
                  className="font-bold lg:text-xl"
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  {website?.name}
                </p>
              )}
            </a>
            <div>
              {!show && (
                <IoMenuOutline
                  color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color}
                  onClick={() => setShow(true)}
                  className="cursor-pointer lg:text-3xl text-xl"
                />
              )}
            </div>
          </div>
          <hr
            className="h-[2px] m-auto"
            style={{ background: getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }).color }}
          />
        </div>
      )}
      {show && (
        <motion.div
          animate={{
            y: show ? [-180, 0] : -180,
          }}
          transition={{
            duration: 0.7,
            type: 'spring',
          }}
          className={`font-bold bg-[#eee] w-[100%] py-6 lg:text-xl`}
          style={{
            ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '#eee' }),
            ...getSpacing(menu?.settings?.spacing),
          }}
        >
          <>
            <div className="flex px-4 items-center pb-2 lg:px-12 justify-between md:py-2">
              <a href="/">
                {website?.config.logo ? (
                  <a href="/">
                    <img
                      className="cursor-pointer w-[30px] h-[30px] rounded-full "
                      src={website?.config?.logo}
                      alt={website?.name}
                    />
                  </a>
                ) : (
                  <p
                    className="font-bold lg:text-xl"
                    style={{
                      ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                      ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                    }}
                  >
                    {website?.name}
                  </p>
                )}
              </a>
              <div>
                {show && (
                  <GiCrossMark
                    color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color}
                    onClick={() => setShow(false)}
                    className="cursor-pointer lg:text-2xl text-lg"
                  />
                )}
              </div>
            </div>
            <hr
              className="h-[1px] bg-black m-auto lg:mx-4 "
              style={{ background: getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }).color }}
            />
          </>
          <div className="py-12 flex flex-col items-center justify-center lg:gap-y-8 gap-y-3">
            {menu.links.map(({ id, name, url, type }) => {
              return (
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    transition: {
                      duration: 1,
                      delay: 0.3,
                    },
                  }}
                  key={id}
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                  className="hover:scale-125 transition-all duration-300"
                >
                  <a
                    href={url}
                    target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                    className="hover:font-serif flex gap-x-1 lg:text-2xl transition-all duration-500 text-center"
                  >
                    <GiHeartKey
                      className="rotate-10"
                      color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color}
                    />
                    {name}
                    <GiHeartKey
                      className="rotate-90"
                      color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color}
                    />
                  </a>
                </motion.p>
              )
            })}
          </div>
        </motion.div>
      )}
    </div>
  )
}
