import { Template } from '.'
import { contactUs, image, text, aboutUs } from './variants'

export const Template12: Template = {
  id: '12',
  pages: [
    {
      slug: '/',
      name: 'WORK',
      blocks: [
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_15')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 60, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Sub Title' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'cf69cceb-25a3-477a-8856-6d8016235dab',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'MARGIN',
              id: '1a550e95-4b1e-4cf7-b0fd-8edd9d79dae9',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'PADDING',
              id: 'fcc2e2a7-c89b-47fd-a61e-61b4ae5e7a22',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
              name: 'MEDIA',
              id: 'f758074f-d381-4344-8131-277569c4b3b6',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: '4e879f95-308e-4568-8c46-145d2b86be3d',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [{ color: '#000000', text: 'Fire Cottage Industry', bold: true }],
                  type: 'paragraph',
                  align: 'center',
                },
              ],
              name: 'TITLE',
              id: 'ac2e53d0-86b9-442f-ac4c-cb3f160e9cc3',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Accelerate Your User Engagement' }], type: 'paragraph', align: 'center' }],
              name: 'PARAGRAPH',
              id: 'e60c5659-e6d6-4189-8700-e88a3295c2bd',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_15',
          type: 'TEXT',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_14')?.settings,
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1582201957428-5ff47ff7605c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw2fHxEcmF3aW5nJTIwYm9hcmRzfGVufDB8fHx8MTY3ODk0NjExMA&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ color: '#acacac', text: 'TBH Digital Nomads' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '1',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1582201942988-13e60e4556ee?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxEcmF3aW5nJTIwYm9hcmRzfGVufDB8fHx8MTY3ODk0NjExMA&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ color: '#bebebe', text: 'TBH Digital Nomads' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '2',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1582201957424-621320ad670d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw3fHxEcmF3aW5nJTIwYm9hcmR8ZW58MHx8fHwxNjc4OTQ2MTQz&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ color: '#aeaeae', text: 'TBH Digital Nomads' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: 'd8666f70-3f85-4a53-bc03-19428462a302',
              category: 'IMAGE',
            },
          ],
          variant: 'VARIANT_14',
          type: 'TEXT',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_14')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1602868973132-205800d4d3df?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxkcmF3aW5nc3xlbnwwfHx8fDE2Nzg5NDYzNjU&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ color: '#b1b1b1', text: 'Tea for Investors' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '1',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1595752024492-e8ceaea3efc7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw3fHxkcmF3aW5nc3xlbnwwfHx8fDE2Nzg5NDYzNjU&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ color: '#aeaeae', text: 'Tea for Investors' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '2',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1558522195-e1201b090344?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxkcmF3aW5nc3xlbnwwfHx8fDE2Nzg5NDYzNjU&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ color: '#989898', text: 'Tea for Investors' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: 'eb771891-6746-42fd-9fc9-a48afa31da52',
              category: 'IMAGE',
            },
          ],
          variant: 'VARIANT_14',
          type: 'TEXT',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_14')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 30, left: 0, bottom: 30, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1585644198527-05519fdeaf98?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw3fHxhcnRzfGVufDB8fHx8MTY3ODk0NTkwMw&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ color: '#aeaeae', text: "Cheugy Site's Appeal" }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '1',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1569091791842-7cfb64e04797?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxkcmF3aW5nfGVufDB8fHx8MTY3ODk0NjIwNA&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ color: '#a5a5a5', text: "Cheugy Site's Appeal" }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '2',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1580136579312-94651dfd596d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxvbGQlMjBwYWludGluZ3xlbnwwfHx8fDE2Nzg5NDYwMTY&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ color: '#a9a9a9', text: "Cheugy Site's Appeal" }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: 'ade82d1d-15c8-444b-a99d-c4b9b7637da0',
              category: 'IMAGE',
            },
          ],
          variant: 'VARIANT_14',
          type: 'TEXT',
        },
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 60, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  name: 'Email',
                  id: 'feb5b860-bcea-40bb-ad53-6e33c3ca2d8b',
                  placeholder: 'example@gmail.com',
                  type: 'email',
                  error: 'email is required',
                },
              ],
              name: 'INPUTS',
              id: '46ca09ff-eeb4-433b-8681-1a94c04e35ca',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Subscribe' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'a1712772-b468-430c-bfa8-eff5001400f8',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless .',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'a001a1e8-2745-4b7a-bdb5-b79566387d4e',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#000',
                  backgroundColor: '#000000',
                  borderRadius: '0px',
                  color: '#ffffff',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'Sign Up',
              },
              name: 'BUTTON_1',
              id: '3ccf4fc2-bdf9-456d-826c-446a251905f3',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_6',
          type: 'CONTACT_US',
        },
      ],
    },
    {
      slug: '/experiments',
      name: 'EXPERIMENTS',
      blocks: [
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_14')?.settings,
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1527576539890-dfa815648363?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHx3aGl0ZSUyMGFuZCUyMGJsYWNrJTIwZHJhd3xlbnwwfHx8fDE2Nzg5NDczNjc&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ color: '#b6b6b6', text: 'Snatched Crowdfunding UX' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '1',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1527567018838-584d3468eb85?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHx3aGl0ZSUyMGFuZCUyMGJsYWNrfGVufDB8fHx8MTY3ODk0NzQxNA&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ color: '#929292', text: ' Snatched Crowdfunding UX' }], type: 'paragraph' }],
                  description: [{ children: [{ text: ' ' }], type: 'paragraph' }],
                  id: '2',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1520262494112-9fe481d36ec3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxkcmF3aW5nJTIwYmxhY2t8ZW58MHx8fHwxNjc4OTQ3NTMz&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ color: '#a2a2a2', text: 'Snatched Crowdfunding UX' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: '13a4c6a3-782c-42ab-81ac-4aae67c858f3',
              category: 'IMAGE',
            },
          ],
          variant: 'VARIANT_14',
          type: 'TEXT',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_14')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-e6fbe9aa-14d0-4fa7-bacb-dd8c1e79e38e',
                  name: [{ children: [{ color: '#a5a5a5', text: 'Snatched Crowdfunding UX' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '1',
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-7f8a7a7a-34a7-41c3-8a7e-08d1bfe170a5',
                  name: [{ children: [{ color: '#8f8f8f', text: 'Snatched Crowdfunding UX' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '2',
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-b550d7e7-0ee6-457b-b815-ca1febdd5e26',
                  name: [{ children: [{ color: '#989898', text: 'Snatched Crowdfunding UX' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: '59c7b4cd-370a-49b2-b70e-8d36ba43a3d4',
              category: 'IMAGE',
            },
          ],
          variant: 'VARIANT_14',
          type: 'TEXT',
        },
      ],
    },
    {
      slug: '/art',
      name: 'ART',
      blocks: [
        {
          settings: {
            ...image.find(i => i.variant === 'VARIANT_3')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: 'https://images.unsplash.com/photo-1602868973132-205800d4d3df?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxkcmF3aW5nc3xlbnwwfHx8fDE2Nzg5NDYzNjU&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: 'cd80d119-98b5-4dc2-8619-12efbb2a1adf',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'Snatched Crowdfunding UX' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '6ecd74d6-8ade-4106-bd54-db9b090f6d8f',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Accelerate Your User Engagement' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'd1989fd5-2b2d-449b-b145-0ffd7b4057a1',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_3',
          id: 'e650cdc4-1707-4898-9c96-ba51485c40d2',
          type: 'IMAGE_OMG',
        },
        {
          settings: {
            ...image.find(i => i.variant === 'VARIANT_3')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: 'https://images.unsplash.com/photo-1595752024492-e8ceaea3efc7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw3fHxkcmF3aW5nc3xlbnwwfHx8fDE2Nzg5NDYzNjU&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '55cdeb89-b4ca-4b9f-9a48-52625980933d',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: '' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '63195a78-8c1a-4464-aee7-1dfbd251d9ae',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: '' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '44e40726-a174-4dc8-b14e-bdb3fa82ea92',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_3',
          id: '9cbd5aac-db0c-4581-883b-3ad52026a9fe',
          type: 'IMAGE_OMG',
        },
        {
          settings: {
            ...image.find(i => i.variant === 'VARIANT_3')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ text: '' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '78e16088-54d0-4847-86a8-37d464a4d715',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: '' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'c017d251-742a-4848-b2c4-efad8f5407b2',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-d9f21142-9bae-4577-b640-9e1f9ac3e0b6',
              name: 'MEDIA',
              id: '9e74e0a0-d022-49ae-850b-cbe7c3fafce5',
              category: 'IMAGE',
            },
          ],
          variant: 'VARIANT_3',
          id: '7de2efff-0d57-4ac6-8c3b-30a2c98ef03f',
          type: 'IMAGE_OMG',
        },
        {
          settings: {
            ...image.find(i => i.variant === 'VARIANT_3')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ text: '' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'df71644c-92f0-4bf0-84cd-19697108bcfa',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: '' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '70e02343-5a0f-43cd-9a1c-6792274f0356',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-e1038663-ba46-4c13-b58d-a117344f478a',
              name: 'MEDIA',
              id: '74b015a3-c2a9-4304-9a5e-2cdb20162709',
              category: 'IMAGE',
            },
          ],
          variant: 'VARIANT_3',
          id: '746da9e4-b6a3-40ba-a9f1-6d77d5d9858f',
          type: 'IMAGE_OMG',
        },
      ],
    },
    {
      slug: '/about-us',
      name: 'ABOUT',
      blocks: [
        {
          settings: {
            ...aboutUs.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 30, left: 0, bottom: 30, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            { data: [], name: 'SERVICE', id: '8f44be67-7a1a-4ccb-92d4-bad3dbe28e51', category: 'TEXT' },
            {
              data: [{ children: [{ color: '#000000', text: 'Ariel Bullock' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '66edc5fb-a525-4cb2-ba1d-753cdd91d4b4',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1568602471122-7832951cc4c5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxtYW58ZW58MHx8fHwxNjgxNjI1MTg3&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '903ecb78-63d2-4b1e-b736-d87acb6f7cb9',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'Accelerate Your User Engagement' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '81f4e556-27b4-4385-b2a4-aec1c86798dd',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#000000',
                      text: 'We are a team of experienced entrepreneurs and investors dedicated to acquiring and growing innovative businesses. Our approach is rooted in the latest industry trends and our commitment to long-term success.',
                    },
                  ],
                  type: 'paragraph',
                },
                {
                  children: [
                    {
                      color: '#000000',
                      text: ' With our expertise and network, we aim to bring Cheugy-approved businesses to the forefront of the market.Cheugy-Approved Acquisition: We are a team of experienced entrepreneurs and investors dedicated to acquiring and growing innovative businesses. Our approach is rooted in the latest industry trends and our commitment to long-term success.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'a3650e89-40c4-40a5-90ce-f73f8ef52779',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#828282', text: 'Accelerate Your User Engagement' }], type: 'paragraph' }],
              name: 'SECTION_1',
              id: '5df21d63-b242-49bd-b6b6-fc2a9a5be7e5',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'ABOUT_US',
        },
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  name: 'Email',
                  placeholder: 'example@gmail.com',
                  id: 'f9f76371-76a8-490e-a1fc-b1938801c788',
                  type: 'email',
                  error: 'email is required',
                },
              ],
              name: 'INPUTS',
              id: '01fa9b6b-e073-42a3-b71a-24c42b9410c9',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Subscribe' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '40cbd22a-8ad9-4faa-af0a-0743ca1185d0',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: '. Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. ',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '5ca58711-c406-4e0e-b2c7-195640288cda',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#000',
                  backgroundColor: '#000000',
                  borderRadius: '0px',
                  color: '#ffffff',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'send',
              },
              name: 'BUTTON_1',
              id: '8e4ac6c7-941f-4e69-bc6f-5afe272f8725',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_6',
          type: 'CONTACT_US',
        },
      ],
    },
    {
      slug: '/cv',
      name: 'CV',
      blocks: [
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_11')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            { data: [], name: 'SUBTITLE', id: '4a2d8e19-bd18-4529-adf8-a417aa9962fe', category: 'TEXT' },
            {
              data: [
                {
                  children: [{ highlightColor: '#ffffff', color: '#000000', text: '     About us      ', bold: true }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: 'd8e16152-861b-4eaa-a1bc-d4d0c87f5870',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#686868',
                      text: "Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience? Look no further than our custom site and portfolio design services. Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. Whether you're a digital nomad or a disruptive technology startup, we have the expertise to optimize your website for exceptional user experience on any device.",
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'cd14e04e-3440-4a84-aa9b-99bfe9fe1f62',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_11',
          type: 'TEXT',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_11')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            { data: [], name: 'SUBTITLE', id: '29405418-f25a-443c-a26f-42a97a7c457f', category: 'TEXT' },
            {
              data: [{ children: [{ text: '   skills and Experience    ', bold: true }], type: 'paragraph' }],
              name: 'TITLE',
              id: '3028e1ea-4e1b-4e25-9662-e40d8fefa99e',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#686868',
                      text: "Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience? Look no further than our custom site and portfolio design services. Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. Whether you're a digital nomad or a disruptive technology startup, we have the expertise to optimize your website for exceptional user experience on any device.",
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '587c773e-7f52-4180-8fe7-e283d2c45f2b',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_11',
          type: 'TEXT',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_11')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            { data: [], name: 'SUBTITLE', id: '0099f4c5-83f4-469c-8f36-78b6310686f5', category: 'TEXT' },
            {
              data: [{ children: [{ text: '  Snatched Crowdfunding UX  ', bold: true }], type: 'paragraph' }],
              name: 'TITLE',
              id: '70ea4492-78e2-49da-8667-4ad178e50163',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#605c5c',
                      text: 'At our agency, we prioritize user satisfaction, user retention, and user conversion. We offer website performance, website speed, website accessibility, website security, website maintenance, website optimization, website analytics, website testing, website updates, website improvements.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'fb4cece6-8c23-45f6-a7d2-e1ee8f028742',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_11',
          type: 'TEXT',
        },
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 30, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  name: 'Email',
                  id: '86c3117e-e778-483b-9a9c-9c4e8883dd0d',
                  placeholder: 'example@gmail.com',
                  type: 'email',
                  error: 'email is required',
                },
              ],
              name: 'INPUTS',
              id: '91d8641c-c0b7-4892-825e-a30bc904ed04',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ fontFamily: 'Satisfy', text: 'Dope Burn Rate', bold: true }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '1c6febeb-bb24-404a-bc4d-58cb57288bbc',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'At our agency, we prioritize user satisfaction, user retention, and user conversion. We offer website performance, website speed, website accessibility, website security, website maintenance, website optimization, website analytics.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '9979bb38-07e7-4563-8d8e-87f6b052cee4',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#9d9d9d',
                  backgroundColor: '#000000',
                  borderRadius: '0px',
                  color: '#ffffff',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'send',
              },
              name: 'BUTTON_1',
              id: '427e34a7-b85d-44a9-9c4a-40ea40f1e798',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_6',
          type: 'CONTACT_US',
        },
      ],
    },
  ],

  newMenu: [
    { name: 'WORK', type: 'internal', url: '/' },
    { name: 'EXPERIMENTS', type: 'internal', url: '/experiments' },
    { name: 'ART', type: 'internal', url: '/art' },
    { name: 'ABOUT', type: 'internal', url: '/about-us' },
    { name: 'CV', type: 'internal', url: '/cv' },
  ],
  newMenuConfig: {
    settings: {
      backgroundColor: { color: '#e5e5e5', enabled: true },
      spacing: {
        padding: { top: 0, left: 0, bottom: 0, right: 0 },
        margin: { top: 0, left: 0, bottom: 0, right: 0 },
        enabled: true,
      },
      textColor: { color: '#000000', enabled: true },
    },
    links: [
      { name: 'Home', id: '5f5d10e1-04c3-4bff-878c-394d3782fb06', type: 'internal', url: '/' },
      { name: 'Contact Us', id: 'd7f94b61-aa31-4ba3-a002-94dc6adcf246', type: 'email', url: 'hello@example.com' },
    ],
    styleName: 'VARIANT 16',
    id: 'VARIANT_16',
    tags: [],
  },
  newFooter: {
    settings: {
      backgroundColor: { color: '#ffffff', enabled: true },
      spacing: {
        padding: { top: 0, left: 0, bottom: 0, right: 0 },
        margin: { top: 0, left: 0, bottom: 0, right: 0 },
        enabled: true,
      },
      textColor: { color: '#000000', enabled: true },
    },
    designedBy: 'Plly Staff',
    id: 'VARIANT_6',
    categories: [
      {
        name: 'Explore',
        links: [{ name: 'Home', id: 'c61deb1c-1838-4197-9e77-fb54d1545107', type: 'internal', url: '/' }],
        id: '228e7dce-628e-4bb8-b8c0-b65908dfc18a',
      },
    ],
    tags: [],
  },
  links: [],
}
