import { Popover, Transition } from '@headlessui/react'
import { useRef, useState } from 'react'
import { SearchablePostSortableFields, SearchableSortDirection } from 'src/API'
import { Loader } from 'src/components/loader'
import AllUsers from 'src/icons/AllUsers'
import Filter from 'src/icons/Filter'
import { serviceSearchPost } from 'src/services/post.service'
import BottomNavigation from '../feed/components/BottomNavigation'
import Header from '../posts-page/components/Header'
import Post from '../posts-page/components/Post'
import SearchModal from './components/SearchModal'

export default function SearchPage() {
  const ref = useRef<HTMLInputElement>(null)

  const [posts, setPosts] = useState<any[]>([])
  const [filterGrid] = useState<string>('three')
  const [loading, setLoading] = useState(false)
  const [searched, setSearched] = useState(false)

  const handleSearch = async (e: any) => {
    e.preventDefault()
    const query = ref.current?.value
    if (!query) return
    setLoading(true)
    const search = await serviceSearchPost({
      filter: {
        or: [
          { title: { matchPhrase: query } },
          { title: { eq: query } },
          { description: { matchPhrase: query } },
          { description: { eq: query } },
        ],
      },
      sort: {
        field: SearchablePostSortableFields.title,
        direction: SearchableSortDirection.asc,
      },
    })
    setPosts(search)
    setSearched(true)
    setLoading(false)
  }

  return (
    <div className="dark:bg-black min-h-screen relative">
      <Loader show={loading} />
      <div>
        <Header />
      </div>
      <div className="flex flex-col">
        <div className="flex justify-between gap-x-2 items-center md:px-[25px] px-[22px]">
          <form
            onSubmit={handleSearch}
            className="md:mt-10 mt-[34px] lg:w-[694px] md:w-[60%] w-[90%] relative z-10 flex items-center justify-between border-b-4 border-black dark:border-white lg:pb-3 xl:pb-3 md:pb-2 md:ml-[137px]"
          >
            <input
              ref={ref}
              type="text"
              placeholder="search"
              className="placeholder:tracking-wide  md:px-4 bg-transparent border-none outline-none dark:text-white placeholder:capitalize md:placeholder:text-[40px] placeholder:text-[35px] pt-12 w-full placeholder:italic placeholder:text-black dark:placeholder:text-[#D9D9D9]"
            />
            <button className="text-brand-accent mr-2 md:mr-5 lg:mt-10 mt-5" type="button" onClick={handleSearch}>
              <svg width="30" height="29" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.7146 38.961C31.2393 38.961 38.9605 31.2398 38.9605 21.7151C38.9605 12.1905 31.2393 4.46924 21.7146 4.46924C12.19 4.46924 4.46875 12.1905 4.46875 21.7151C4.46875 31.2398 12.19 38.961 21.7146 38.961Z"
                  stroke="#E90970"
                  strokeWidth="5"
                  strokeLinecap="round"
                />
                <path d="M36.5781 35.897L47.6604 46.7803" stroke="#E90970" strokeWidth="5" strokeLinecap="round" />
              </svg>
            </button>
          </form>
          <div className="md:hidden block md:mt-[108px] mt-[82px]">
            <Popover className="relative z-50">
              <Popover.Button type="button" className="mt-2 -ml-2 outline-none">
                <Filter size={35} className="md:mt-5 mt-3" />
              </Popover.Button>
              <Transition
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-300"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-0"
              >
                <button type="button" className="absolute -left-96 top-0 mt-4">
                  <SearchModal />
                </button>
              </Transition>
            </Popover>
          </div>
        </div>
        <div className="items-center gap-x-6 mt-20 px-16 hidden md:flex">
          <div className="flex gap-x-2 items-center">
            <span>
              <AllUsers size={33} fill="none" />
            </span>
            <p className="capitalize text-4xl text-black dark:text-white"> All Users</p>
          </div>
          <Popover className="relative z-50">
            <Popover.Button type="button" className="mt-2 -ml-2 outline-none">
              <Filter size={33} className="dark:text-[#D9D9D9]" />
            </Popover.Button>
            <Transition
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-0"
            >
              <button type="button" className="absolute left-0 top-0 mt-4">
                <SearchModal />
              </button>
            </Transition>
          </Popover>
        </div>

        <div
          className={`container mx-auto mt-20 px-5.5  lg:px-8 grid ${
            filterGrid === 'three' && 'lg:grid-cols-2 xl:grid-cols-3 gap-x-8'
          } ${filterGrid === 'two' && 'lg:grid-cols-2 gap-x-8'}`}
        >
          {posts.length === 0 && searched && <p className="dark:text-white">Nothing found.</p>}
          {posts?.map((post, idx) => (
            <div key={idx}>
              <Post post={post} filterGrid="three" />
            </div>
          ))}
        </div>
      </div>
      <div>
        <BottomNavigation variant="three" />
      </div>
    </div>
  )
}
