import clsx from 'clsx'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { WebsiteContext } from 'src/components/context/WebsiteContext'
import { Loader } from 'src/components/loader'
import { createPageRecord, getPagesByWebsite, getWebsiteByID, saveWebsite } from 'src/lib/services/website.service'
import { NewMenuLink, NewMenuLinkType } from 'src/_polly/components/src/menu/menu'
import { v4 as uuid } from 'uuid'
import LinkItem from './LinkItem'

type NewMenuAllLinksProps = {
  onSelect?: (link: NewMenuLink) => void
  myAllLinks?: any
  myLink?: any

  fetchWebsiteLinks?: any
}

export default function NewMenuAllLinks({ onSelect, myAllLinks, myLink, fetchWebsiteLinks }: NewMenuAllLinksProps) {
  const websiteContext = useContext(WebsiteContext)
  const params = useParams()

  const [menuLinks, setMenuLinks] = useState<NewMenuLink[]>([])
  const [hiddenLinks, setHiddenLinks] = useState<NewMenuLink[]>([])
  const [editLink, setEditLink] = useState<NewMenuLink | null>(null)
  const [loading, setLoading] = useState(false)
  const [deleteItem, setDeleteItem] = useState<NewMenuLink | null>(null)
  const [deleteItemHidden, setDeleteItemHidden] = useState<NewMenuLink | null>(null)
  const [linksUpdated, setLinksUpdated] = useState<'update' | 'add' | 'sort' | 'duplicate' | 'delete' | 'none'>('none')
  const [focusSearch, setFocusSearch] = useState(false)
  const [pages, setPages] = useState<any>([])

  const fetchWebsite = () => {
    setLoading(true)
    getWebsiteByID(websiteContext.websiteID).then(_website => {
      setMenuLinks(JSON.parse(_website?.newMenu ?? '[]'))
      setHiddenLinks(JSON.parse(_website?.links ?? '[]'))
      setLoading(false)
    })
  }

  const handleUpdateLink = async (link: NewMenuLink) => {
    setHiddenLinks(prev => [
      ...prev,
      {
        ...link,
        id: uuid(),
      },
    ])
    setLinksUpdated('add')
    setEditLink(null)
  }

  const handleSave = async () => {
    setLoading(true)
    await saveWebsite({ id: websiteContext.websiteID, newMenu: JSON.stringify(menuLinks) })
    if (linksUpdated === 'add' && onSelect) {
      onSelect(menuLinks[menuLinks.length - 1])
    }
    fetchWebsiteLinks()
    setLoading(false)
  }
  const handleSaveHidden = async () => {
    setLoading(true)
    await saveWebsite({ id: websiteContext.websiteID, links: JSON.stringify(hiddenLinks) })
    if (linksUpdated === 'add' && onSelect) {
      onSelect(hiddenLinks[hiddenLinks.length - 1])
    }
    fetchWebsiteLinks()
    setLoading(false)
  }

  const handleDuplicate = (index: number) => {
    const newLinks = [...menuLinks]
    if (newLinks[index]?.type === NewMenuLinkType.INTERNAL) {
      const random = Math.random().toString(36).substring(2, 5)
      const path = `/${random}-${newLinks[index]?.name.toLowerCase().replace(/ /g, '-')}`
      const blocks = pages?.find((data: any) => data?.path === newLinks[index]?.url)
      newLinks.splice(index + 1, 0, { ...newLinks[index], id: uuid(), url: path })
      const options = {
        websiteID: params.id as string,
        name: newLinks[index]?.name,
        path,
        blocks: blocks?.blocks,
      }
      createPageRecord(options)
    } else {
      newLinks.splice(index + 1, 0, { ...newLinks[index], id: uuid() })
    }
    setMenuLinks(newLinks)
    setLinksUpdated('duplicate')
    setEditLink(null)
  }
  const handleDuplicateHidden = (index: number) => {
    const newLinks = [...hiddenLinks]
    if (newLinks[index]?.type === NewMenuLinkType.INTERNAL) {
      const random = Math.random().toString(36).substring(2, 5)
      const path = `/${random}-${newLinks[index]?.name.toLowerCase().replace(/ /g, '-')}`
      const blocks = pages?.find((data: any) => data?.path === newLinks[index]?.url)
      newLinks.splice(index + 1, 0, { ...newLinks[index], id: uuid(), url: path })
      const options = {
        websiteID: params.id as string,
        name: newLinks[index]?.name,
        path,
        blocks: blocks?.blocks,
      }
      createPageRecord(options)
    } else {
      newLinks.splice(index + 1, 0, { ...newLinks[index], id: uuid() })
    }
    setHiddenLinks(newLinks)
    setLinksUpdated('duplicate')
    setEditLink(null)
  }

  const handleDelete = async () => {
    setMenuLinks(prev => prev.filter(link => link.id !== deleteItem?.id))
    setDeleteItem(null)
    setLinksUpdated('delete')
    setEditLink(null)
  }

  const handleDeleteHidden = async () => {
    setHiddenLinks(prev => prev.filter(link => link.id !== deleteItem?.id))
    setDeleteItemHidden(null)
    setLinksUpdated('delete')
    setEditLink(null)
  }

  const handleEdit = async (link: Partial<NewMenuLink>) => {
    setMenuLinks(prev => prev.map(item => (item.id === editLink?.id ? { ...item, ...link } : item)))
    setLinksUpdated('update')
    setEditLink(null)
  }

  const handleEditHidden = async (link: Partial<NewMenuLink>) => {
    setHiddenLinks(prev => prev.map(item => (item.id === editLink?.id ? { ...item, ...link } : item)))
    setLinksUpdated('update')
    setEditLink(null)
  }

  useEffect(() => {
    if (linksUpdated !== 'none' && menuLinks.length) {
      handleSave()
    }
    if (linksUpdated !== 'none' && hiddenLinks.length) {
      handleSaveHidden()
    }
  }, [menuLinks, hiddenLinks, linksUpdated])

  useEffect(() => {
    fetchWebsite()
    getPagesByWebsite(websiteContext.websiteID).then(data => setPages(data))
  }, [])

  return (
    <div className="px-[18px] pt-[36px]">
      <Loader show={loading} />

      <div
        className={clsx(
          'z-50 fixed top-0 right-0 bottom-0 left-0 justify-center items-center',
          !deleteItem && 'hidden',
        )}
      >
        <div className="relative w-full h-full bg-white/90 dark:bg-black/90 flex justify-center items-center">
          <div className="w-[362px] bg-white dark:bg-black border-[1px] rounded-[6px] border-[#E90570] border-solid">
            <div className="pt-[13px] pb-[20px]">
              <p className="text-[25px] text-black dark:text-white text-center">Remove link</p>
              <p className="text-[25px] text-black dark:text-white text-center">{deleteItem?.name}</p>
            </div>
            <div className="grid border-t dark:border-white mx-[10px] border-black grid-cols-2">
              <button
                onClick={() => handleDelete()}
                type="button"
                className="border-r text-[19px] dark:border-white dark:text-white border-black text-center text-black my-[16px] "
              >
                Delete
              </button>
              <button
                onClick={() => setDeleteItem(null)}
                type="button"
                className="dark:text-white text-[19px] text-black my-[16px]"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={clsx(
          'z-50 fixed top-0 right-0 bottom-0 left-0 justify-center items-center',
          !deleteItemHidden && 'hidden',
        )}
      >
        <div className="relative w-full h-full bg-white/90 dark:bg-black/90 flex justify-center items-center">
          <div className="w-[362px] bg-white dark:bg-black border-[1px] rounded-[6px] border-[#E90570] border-solid">
            <div className="pt-[13px] pb-[20px]">
              <p className="text-[25px] text-black dark:text-white text-center">Remove link</p>
              <p className="text-[25px] text-black dark:text-white text-center">{deleteItemHidden?.name}</p>
            </div>
            <div className="grid border-t dark:border-white mx-[10px] border-black grid-cols-2">
              <button
                onClick={() => handleDeleteHidden()}
                type="button"
                className="border-r text-[19px] dark:border-white dark:text-white border-black text-center text-black my-[16px] "
              >
                Delete
              </button>
              <button
                onClick={() => setDeleteItem(null)}
                type="button"
                className="dark:text-white text-[19px] text-black my-[16px]"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      {focusSearch ? (
        <div className="border-b-[8px] dark:border-[#D9D9D9] border-black mr-[58px]">
          <div className="flex justify-between px-[6px] pb-[8px]">
            <input
              placeholder="Search"
              type="text"
              className="w-full italic dark:text-white dark:bg-black placeholder:text-[45px] focus:outline-none text-[45px]"
            />
            <button type="button" onClick={() => setFocusSearch(false)}>
              <svg width="28" height="28" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.28376 16.6564C13.3558 16.6564 16.6569 13.3553 16.6569 9.28327C16.6569 5.21121 13.3558 1.91016 9.28376 1.91016C5.2117 1.91016 1.91064 5.21121 1.91064 9.28327C1.91064 13.3553 5.2117 16.6564 9.28376 16.6564Z"
                  stroke="#E90970"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
                <path d="M15.6382 15.3477L20.3762 20.0006" stroke="#E90970" strokeWidth="3" strokeLinecap="round" />
              </svg>
            </button>
          </div>
        </div>
      ) : (
        <div className="border-b-[1px] dark:border-[#D9D9D9] border-black">
          <div className="flex justify-between pl-[6px] pr-[36px] pb-[22px]">
            <p onClick={() => setFocusSearch(true)} className="text-[19px] dark:text-white">
              {myAllLinks ? 'All Links' : 'My Links'}
            </p>
            <button type="button" onClick={() => setFocusSearch(true)}>
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.28376 16.6564C13.3558 16.6564 16.6569 13.3553 16.6569 9.28327C16.6569 5.21121 13.3558 1.91016 9.28376 1.91016C5.2117 1.91016 1.91064 5.21121 1.91064 9.28327C1.91064 13.3553 5.2117 16.6564 9.28376 16.6564Z"
                  stroke="#E90970"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
                <path d="M15.6382 15.3477L20.3762 20.0006" stroke="#E90970" strokeWidth="3" strokeLinecap="round" />
              </svg>
            </button>
          </div>
        </div>
      )}
      <div className="">
        {menuLinks.map((link: NewMenuLink, idx) => (
          <LinkItem
            myLinks={myLink}
            key={idx}
            index={idx}
            item={link}
            allLink={myAllLinks}
            defaultValue={editLink}
            onEditClick={() => setEditLink(link)}
            onEditBack={() => setEditLink(null)}
            onNameClick={() => {
              onSelect?.(link)
              handleSave()
            }}
            isNameClickable={onSelect !== undefined}
            onDuplicateClick={() => handleDuplicate(idx)}
            onDeleteClick={() => setDeleteItem(link)}
            handleLinkAdd={handleUpdateLink}
            onAdd={handleEdit}
          />
        ))}
        {hiddenLinks.map((link: NewMenuLink, idx) => (
          <LinkItem
            myLinks={myLink}
            key={idx}
            index={idx}
            item={link}
            allLink={myAllLinks}
            defaultValue={editLink}
            onEditClick={() => setEditLink(link)}
            onEditBack={() => setEditLink(null)}
            onNameClick={() => {
              onSelect?.(link)
              handleSaveHidden()
            }}
            isNameClickable={onSelect !== undefined}
            onDuplicateClick={() => handleDuplicateHidden(idx)}
            onDeleteClick={() => setDeleteItemHidden(link)}
            handleLinkAdd={handleUpdateLink}
            onAdd={handleEditHidden}
          />
        ))}
      </div>

      {/* <MenuSaveButton onClick={handleSave} /> */}
    </div>
  )
}
