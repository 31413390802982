import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { AboutUsSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantThirteen({ section }: { section: AboutUsSection }) {
  const media = section.variables.find(i => i.name === 'MEDIA')?.data
  const title = section.variables.find(i => i.name === 'TITLE')?.data
  const subtitle = section.variables.find(i => i.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(i => i.name === 'PARAGRAPH')?.data

  return (
    <div
      className="flex flex-col items-center"
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <img
        src={media}
        alt=""
        className="md:w-[350px] md:h-[350px] rounded-full object-cover object-top w-[100px] h-[100px]"
      />
      <div className="w-[80%] flex  mt-4 gap-y-1 flex-col items-center text-center">
        <h1 className="md:text-3xl">
          <SectionSlatePreview initialValue={title} previewMode />
        </h1>
        <h6 className="md:text-xl text-[12px]">
          <SectionSlatePreview initialValue={subtitle} previewMode />
        </h6>
        <h1 className="text-[12px] md:text-[16px]">
          <SectionSlatePreview initialValue={paragraph} previewMode />
        </h1>
      </div>
    </div>
  )
}
