import { Descendant } from 'slate'

import { useContext, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import BottomFloatingButton from 'src/components/BottomFloatingButton'
import { WebsiteContext } from 'src/components/context/WebsiteContext'
import ModalDialogV2 from 'src/components/new/ModalDialogV2'
import FlexTable from 'src/icons/FlexTable'
import { upsertBlockByPageID } from 'src/services/website.service'
import {
  SectionVariableCategories,
  TABLESection,
  TABLESectionItem,
  TABLEVariables,
} from 'src/_polly/components/src/sections'
import { v4 as uuid } from 'uuid'
import { ReactComponent as DeleteIcon } from '../../assets/icons/menuBar/delete.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/menuBar/edit.svg'
import { AdvancedFeature } from '../modal/advanced'
import { MenuSaveButton } from '../modal/section/NewMenu/NewMenu'
import { EditSection } from './EditSectionModal'
import SectionTextField from './SectionField/SectionTextField'
import ItemField from './SectionField/SectionSingleItemField'

interface EditSectionTABLEModalProps extends EditSection {
  section: TABLESection
}

type EditTABLEProps = {
  data?: TABLESectionItem
  onSave: (data: Partial<TABLESectionItem>) => void
}

function Item({
  data,
  onDeleteClick,
  onEditClick,
}: {
  data: TABLESectionItem
  onEditClick: () => void
  onDeleteClick: () => void
}) {
  return (
    <div className="border border-black dark:border-white h-[65px] flex justify-between">
      <div className="flex items-center">
        <div className="grid grid-cols-2 gap-1 ml-2 mr-4">
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
        </div>
        <div className="flex items-center gap-4">
          <p>
            <ItemField initialValue={data.title} />
          </p>
        </div>
      </div>

      <div className="flex">
        <button
          type="button"
          className="flex justify-center items-center w-16 border-l border-black dark:border-white"
          onClick={onEditClick}
        >
          <EditIcon />
        </button>
        <button
          type="button"
          className="flex justify-center items-center w-16 border-l border-black dark:border-white"
          onClick={onDeleteClick}
        >
          <DeleteIcon />
        </button>
      </div>
    </div>
  )
}

type FormInput = {
  title: any
  subtitle?: any
  description?: any
}

function EditTABLE({ data, onSave }: EditTABLEProps) {
  const initialDataTitle: Descendant[] = [
    {
      type: 'paragraph',
      children: [
        {
          text: 'item title',
        },
      ],
    },
  ]
  const initialDataSubtitle: Descendant[] = [
    {
      type: 'paragraph',
      children: [
        {
          text: 'item subtitle',
        },
      ],
    },
  ]
  const initialDataDescription: Descendant[] = [
    {
      type: 'paragraph',
      children: [
        {
          text: 'item description',
        },
      ],
    },
  ]
  const [titles, setTitle] = useState(data?.title || initialDataTitle)
  const [subtitles, setSubtitle] = useState(data?.subtitle || initialDataSubtitle)
  const [descriptions, setDescription] = useState(data?.description || initialDataDescription)
  const { handleSubmit } = useForm<FormInput>()

  const onSubmit: SubmitHandler<FormInput> = value => {
    onSave({
      ...value,
      id: data?.id,
      title: titles,
      subtitle: subtitles,
      description: descriptions,
    })
    setTitle(value?.title)
    setSubtitle(value?.subtitle)
    setDescription(value?.description)
  }
  return (
    <div className="px-4 dark:text-white">
      <form className="pb-024" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-8">
          <div>
            <SectionTextField
              title="Title"
              initialValue={titles}
              onChange={value => setTitle(value)}
              onUpdate={() => handleSubmit(onSubmit)}
            />
          </div>
          {/* {variantName === 'VARIANT_1' && ( */}
          <div>
            <SectionTextField
              title="subtitle"
              initialValue={subtitles}
              onChange={value => setSubtitle(value)}
              onUpdate={() => handleSubmit(onSubmit)}
            />
          </div>
          {/* )} */}
          {/* {variantName === 'VARIANT_2' && ( */}
          <div>
            <SectionTextField
              title="Description"
              initialValue={descriptions}
              onChange={value => setDescription(value)}
              onUpdate={() => handleSubmit(onSubmit)}
            />
          </div>
          {/* )} */}
        </div>
      </form>
      <BottomFloatingButton
        label={data?.id ? 'Save' : 'Add'}
        onClick={handleSubmit(onSubmit)}
        // disabled={!title || !description}
      />
    </div>
  )
}

export default function EditSectionTABLEModal({
  section: defaultValue,
  open,
  onClose: onBack,
  onUpdate,
}: EditSectionTABLEModalProps) {
  const websiteContext = useContext(WebsiteContext)

  const [section, setSection] = useState(defaultValue)
  // const variantName = section?.variant

  const [showAddTABLEModal, setShowAddTABLEModal] = useState(false)
  const [editTABLE, setEditTABLE] = useState<TABLESectionItem>()
  const [updating, setUpdating] = useState(false)
  const [fireUpdate, setFireUpdate] = useState({
    fire: false,
    goBack: false,
  })

  const title = section.variables.find(variable => variable.name === 'TITLE')
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')

  const table = section.variables.find(variable => variable.name === 'ITEMS')

  const updateVariableData = (name: TABLEVariables, category: SectionVariableCategories, data: any) => {
    const current = section.variables.find(variable => variable.name === name)
    if (!current) {
      setSection({
        ...section,
        variables: [...section.variables, { name, data, category, id: uuid() }],
      })
      return
    }
    const otherVariables = section.variables.filter(variable => variable.name !== name)
    const newVariables = [...otherVariables, { ...current, data }]
    setSection({ ...section, variables: newVariables })
  }

  const handleDelete = (id: string) => {
    const newItems = table?.data.filter((item: TABLESectionItem) => item.id !== id)
    setSection({
      ...section,
      variables: section.variables.map(variable => {
        if (variable.name === 'ITEMS') {
          return { ...variable, data: newItems }
        }
        return variable
      }),
    })
    handleSave(false)
  }

  const handleEditAdd = (data: Partial<TABLESectionItem>) => {
    if (data.id) {
      const newItems = table?.data.map((item: TABLESectionItem) => {
        if (item.id === data.id) {
          return { ...item, ...data }
        }
        return item
      })
      setSection({
        ...section,
        variables: section.variables.map(variable => {
          if (variable.name === 'ITEMS') {
            return { ...variable, data: newItems }
          }
          return variable
        }),
      })
    } else {
      setSection({
        ...section,
        variables: section.variables.map(variable => {
          if (variable.name === 'ITEMS') {
            return { ...variable, data: [...variable.data, { ...data, id: uuid() }] }
          }
          return variable
        }),
      })
    }
    setEditTABLE(undefined)
    setShowAddTABLEModal(false)
    handleSave(false)
  }

  const handleSave = async (goBack = true) => {
    setFireUpdate({ fire: true, goBack })
  }

  useEffect(() => {
    const update = async (goBack = true) => {
      setUpdating(true)
      await upsertBlockByPageID(websiteContext.pageID, section as any)
      setUpdating(false)
      setFireUpdate({ fire: false, goBack: false })
      if (goBack) onBack()
    }
    if (fireUpdate.fire) update(fireUpdate.goBack)
  }, [fireUpdate, section])

  useEffect(() => {
    onUpdate?.(section)
  }, [section])

  return (
    <div>
      <ModalDialogV2 {...{ open, onBack }}>
        <div className="px-5 dark:text-white  mt-[25px]">
          <div className="border-t-[1px] border-black dark:border-white">
            {section.variant !== 'VARIANT_3' && (
              <div>
                <SectionTextField
                  title="Title"
                  initialValue={title?.data ?? []}
                  onChange={value => updateVariableData('TITLE', 'TEXT', value)}
                  onUpdate={() => handleSave(false)}
                />
              </div>
            )}

            <div>
              {section.variant !== 'VARIANT_3' && (
                <SectionTextField
                  title="SubTitle"
                  initialValue={subTitle?.data ?? []}
                  onChange={value => updateVariableData('SUBTITLE', 'TEXT', value)}
                  onUpdate={() => handleSave(false)}
                />
              )}
              {section.variant === 'VARIANT_2' ? (
                <div>
                  <SectionTextField
                    title="Paragraph"
                    initialValue={paragraph?.data ?? []}
                    onChange={value => updateVariableData('PARAGRAPH', 'TEXT', value)}
                    onUpdate={() => handleSave(false)}
                  />
                </div>
              ) : null}
            </div>
            <div className="mt-12 space-y-4 pb-24 dark:text-white">
              <h2 className="capitalize text-[19px] font-semibold ">Tables</h2>
              <div className="space-y-5">
                {table?.data.map((item: TABLESectionItem) => (
                  <Item
                    key={item.id}
                    data={item}
                    onDeleteClick={() => handleDelete(item.id)}
                    onEditClick={() => setEditTABLE(item)}
                  />
                ))}
              </div>
              <button
                className="border border-black bg-black dark:border-white h-[65px] flex justify-between items-center text-lg px-6 w-full text-white"
                type="button"
                onClick={() => setShowAddTABLEModal(true)}
              >
                <span>Add Table</span> <span className="text-4xl">+</span>
              </button>
            </div>
          </div>
        </div>
        <div className="absolute top-[0px] left-[50%] translate-x-[-50%]">
          {/* <Table width="auto" height={60} /> */}
          <FlexTable width="auto" height={60} />
        </div>
        <div className="absolute top-[21px] right-[50px]">
          <button type="button" onClick={() => handleSave()} disabled={updating}>
            <MenuSaveButton save="save" />
          </button>
        </div>
        {updating && (
          <div className="fixed z-30 inset-0 lg:max-w-[500px] dark:bg-black/80 bg-white/80">
            <p className="flex items-center h-full justify-center w-full text-[19px] dark:text-white">Updating...</p>
          </div>
        )}
        <AdvancedFeature
          onBack={() => handleSave(false)}
          section={section}
          onChange={settings => setSection(s => ({ ...s, settings }))}
        />
      </ModalDialogV2>

      <ModalDialogV2
        open={editTABLE !== undefined || showAddTABLEModal}
        onBack={() => (editTABLE ? setEditTABLE(undefined) : setShowAddTABLEModal(false))}
      >
        <EditTABLE data={editTABLE} onSave={handleEditAdd} />
      </ModalDialogV2>
    </div>
  )
}
