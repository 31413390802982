import React, { useContext, useEffect, useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import GoogleFontLoader from 'react-google-font-loader'

import EmojiPicker, { IEmojiData } from 'emoji-picker-react'
import { ReactComponent as EmojiIcon } from '../../../assets/icons/toolbar/emoji.svg'
import { ReactComponent as LinkIcon } from '../../../assets/icons/toolbar/link-icon.svg'
import { ReactComponent as TextCenterIcon } from '../../../assets/icons/toolbar/text-center.svg'
import { ReactComponent as TextLeftIcon } from '../../../assets/icons/toolbar/text-left.svg'
import { ReactComponent as TextRightIcon } from '../../../assets/icons/toolbar/text-right.svg'

import { WebsiteContext } from '../../context/WebsiteContext'

import { TextProps, TEXT_ALIGN } from '../../modal/section/SectionTitle'
import ColorPalette from '../ColorPalette'
import ModalDialog from '../ModalDialog'

interface TextToolbarProps {
  settings: TextProps['settings']
  onLinkClick?: () => void
  onChange: (setting: TextProps['settings']) => void
  onEmojiClick?: (emoji: IEmojiData) => void
}

interface FontButtonProps {
  label: string
  font: string
}

function getHeadingLabel(heading?: string) {
  if (!heading) return 'Heading 1'
  if (heading.startsWith('heading')) {
    const lastChar = heading.charAt(heading.length - 1)
    return `Heading ${lastChar}`
  }
  return heading
}

export default function TextToolbar(props: TextToolbarProps) {
  const { onChange, settings, onLinkClick, onEmojiClick } = props

  const { addGoogleFont } = useContext(WebsiteContext)
  const [showHeadingModal, setShowHeadingModal] = useState(false)
  const [showFontModal, setShowFontModal] = useState(false)
  const [showColorModal, setShowColorModal] = useState(false)
  const [showColorPicker, setShowColorPicker] = useState(false)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)

  const updateSetting = <Type, Key extends keyof TextProps['settings']>(key: Key, value: Type) => {
    onChange({ [key]: value })
    setShowHeadingModal(false)
    setShowFontModal(false)
    setShowColorModal(false)
    if (key === 'font') {
      addGoogleFont(value as unknown as string)
    }
  }

  const handleEmojiClick = (_event: React.MouseEvent<Element, MouseEvent>, data: IEmojiData) => {
    if (onEmojiClick) onEmojiClick(data)
    setShowEmojiPicker(false)
  }

  const FontButton = ({ label, font }: FontButtonProps) => (
    <button
      type="button"
      className="w-full mb-4 px-3 py-4 border border-black text-3xl dark:border-white dark:text-white"
      style={{
        fontFamily: font,
        textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      }}
      onClick={() => updateSetting('font', font)}
    >
      {label}
    </button>
  )

  const [result, setResult] = useState<{ items: any; family: any }>()
  useEffect(() => {
    fetch('https://webfonts.googleapis.com/v1/webfonts?sort=POPULARITY&key=AIzaSyAxTv4tboo3bw5YB9TD5k0zTuNKvOIPMvg')
      .then(response => response.json())
      .then(data => setResult(data))
  }, [])
  if (!result) {
    return <p className="pl-3 text-lg">Loading...</p>
  }

  return (
    <>
      <div className="px-6 border border-black font-thin dark:border-white dark:text-white">
        <div className="py-3 flex divide-x divide-black border-b border-black dark:border-white">
          <button
            type="button"
            className="px-2 grow capitalize dark:border-white dark:border-r"
            onClick={() => setShowHeadingModal(true)}
          >
            {getHeadingLabel(settings?.size)}
          </button>
          <button type="button" className="px-2 grow dark:text-white" onClick={() => setShowFontModal(true)}>
            Font
          </button>
        </div>
        <div className="py-3 flex items-center justify-between">
          <button type="button" onClick={() => setShowColorPicker(!showColorPicker)}>
            <span
              className="h-6 w-6 block border border-black dark:border-white"
              style={{
                backgroundColor: settings.color || 'black',
              }}
            />
          </button>
          <button
            type="button"
            onClick={() => updateSetting('bold', !settings.bold)}
            className="font-bold dark:text-white"
          >
            B
          </button>
          <button
            type="button"
            onClick={() => updateSetting('italic', !settings.italic)}
            className="italic dark:text-white"
          >
            I
          </button>
          <button
            type="button"
            onClick={() => updateSetting('underline', !settings.underline)}
            className="underline dark:text-white"
          >
            U
          </button>
          <button type="button" onClick={() => updateSetting('textAlign', TEXT_ALIGN.LEFT)}>
            <TextLeftIcon className="dark:stroke-white" />
          </button>
          <button type="button" onClick={() => updateSetting('textAlign', TEXT_ALIGN.CENTER)}>
            <TextCenterIcon className="dark:stroke-white" />
          </button>
          <button type="button" onClick={() => updateSetting('textAlign', TEXT_ALIGN.RIGHT)}>
            <TextRightIcon className="dark:stroke-white" />
          </button>
          <button type="button" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
            <EmojiIcon className="dark:stroke-white" />
          </button>
          <button type="button" onClick={onLinkClick}>
            {/* <LinkIcon
              style={{
                height: '18px',
                width: 'auto',
              }}
            /> */}
            <LinkIcon height={18} className="dark:stroke-white" />
          </button>
        </div>

        {showColorPicker ? (
          <div className="mb-6 mt-3">
            <HexColorPicker
              color={settings.color ?? '#000'}
              onChange={(color: any) => updateSetting('color', color)}
              style={{ width: '100%' }}
            />
          </div>
        ) : null}

        {showEmojiPicker ? (
          <div className="mb-6 mt-3">
            <EmojiPicker onEmojiClick={handleEmojiClick} pickerStyle={{ width: '100%' }} />
          </div>
        ) : null}
      </div>
      {/* modals */}
      <div id="modals">
        <ModalDialog title="Heading Selector" open={showHeadingModal} onClose={() => setShowHeadingModal(false)}>
          <div className="p-4 ">
            <button
              type="button"
              className="w-full mb-4 font-bold px-3 py-4 border border-black  dark:border-white dark:text-white text-4xl"
              onClick={() => updateSetting('size', 'heading1')}
            >
              Heading 1
            </button>
            <button
              type="button"
              className="w-full mb-4 font-bold px-3 py-4 border border-black text-3xl dark:border-white dark:text-white"
              onClick={() => updateSetting('size', 'heading2')}
            >
              Heading 2
            </button>
            <button
              type="button"
              className="w-full mb-4 font-bold px-3 py-4 border border-black text-2xl dark:border-white dark:text-white"
              onClick={() => updateSetting('size', 'heading3')}
            >
              Heading 3
            </button>
            <button
              type="button"
              className="w-full mb-4 font-bold px-3 py-4 border border-black text-xl dark:border-white dark:text-white"
              onClick={() => updateSetting('size', 'heading4')}
            >
              Heading 4
            </button>
            <button
              type="button"
              className="w-full mb-4 font-bold px-3 py-4 border border-black text-lg dark:border-white dark:text-white"
              onClick={() => updateSetting('size', 'heading5')}
            >
              Heading 5
            </button>
            <button
              type="button"
              className="w-full mb-4 font-bold px-3 py-4 border border-black text-base dark:border-white dark:text-white"
              onClick={() => updateSetting('size', 'heading6')}
            >
              Heading 6
            </button>
            <button
              type="button"
              className="w-full mb-4 px-3 py-4 border border-black text-base dark:border-white dark:text-white"
              onClick={() => updateSetting('size', 'paragraph')}
            >
              Paragraph
            </button>
          </div>
        </ModalDialog>
        <ModalDialog title="Font Palette" open={showFontModal} onClose={() => setShowFontModal(false)}>
          <div className="p-4">
            {result?.items.map((fonts: any, index: number) => (
              <>
                <GoogleFontLoader
                  fonts={[
                    {
                      font: fonts.family,
                      weights: fonts.variants,
                    },
                  ]}
                  subsets={fonts.subsets}
                />
                <FontButton key={index} label={fonts.family} font={fonts.family} />
              </>
            ))}
          </div>
        </ModalDialog>
        <ModalDialog title="Color Palette" open={showColorModal} onClose={() => setShowColorModal(false)}>
          <ColorPalette onChange={(color: string) => updateSetting('color', color)} />
        </ModalDialog>
      </div>
    </>
  )
}
