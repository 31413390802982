import FontLoader from '../FontLoader'
import { MenuVariantProps, NewMenuLinkType } from '../menu'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../utils'

export default function MenuVariantFifteen({ menu, website }: MenuVariantProps) {
  return (
    <>
      <FontLoader settings={menu?.settings?.fontFamily} />
      <DesktopNavbar menu={menu} website={website} />
    </>
  )
}

const DesktopNavbar = ({ menu }: MenuVariantProps) => {
  return (
    <div
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'white' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className="py-6 z-50 px-6 md:px-8">
        <details>
          <summary
            style={{
              ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
              ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
            }}
          ></summary>
          <nav className="menu-15">
            {menu.links.map(({ id, name, type, url }) => (
              <a
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                }}
                key={id}
                href={url}
                target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
              >
                {name}
              </a>
            ))}
          </nav>
        </details>
      </div>
    </div>
  )
}
