import { Autolinker } from 'autolinker'
import React from 'react'
import clsx from 'clsx'

const instance = new Autolinker({
  urls: {
    schemeMatches: true,
    tldMatches: true,
  },
  email: false,
  phone: false,
  mention: false,
  hashtag: false,

  stripPrefix: true,
  stripTrailingSlash: true,
  newWindow: true,

  truncate: { length: 0 },

  className: '',
})

export default function matchUrls(str: string) {
  return instance.parse(str)
}

/**
 * swap two object in array with position<ahead | behind> next to each other
 * @param arr
 * @param peroperty
 * @param value
 * @param position
 * @returns array object is swapped
 */
export const swapObject = (
  arr: Array<any>,
  peroperty: string,
  value: string,
  position: 'ahead' | 'behind',
  deleteCount = 0 as number,
): Array<any> => {
  const indexSection = arr.findIndex(item => item[peroperty] === value)
  const start: number = position === 'ahead' ? indexSection - 1 : indexSection + 1
  const currentSection = arr.find(item => item[peroperty] === value)
  const swapSections = arr.filter(item => item[peroperty] !== value)
  swapSections.splice(start, deleteCount, {
    ...currentSection,
  })
  return swapSections
}

export const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation)

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg(
  imageSrc: string,
  pixelCrop: any,
  type = 'image/jpeg',
  rotation = 0,
  flip = { horizontal: false, vertical: false },
) {
  const image: any = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  const rotRad = getRadianAngle(rotation)

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation)

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth
  canvas.height = bBoxHeight

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(rotRad)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)

  // draw rotated image
  ctx.drawImage(image, 0, 0)

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height)

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0)

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');
  return canvas.toDataURL(type)
}

function renderUrls(text: string, matches: any[], className?: string) {
  if (!text) return null

  const items = []
  let lastIndex = text.length

  // parse from right to left to not break the indices in `matches`
  for (let x of matches.reverse()) {
    if (lastIndex !== x.start + x.key.length) {
      items.unshift(text.slice(x.start + x.key.length, lastIndex))
    }

    items.unshift(
      React.createElement(
        'a',
        {
          key: x.key,
          href: x.href,
          className: clsx('no-transform', className),
          target: '_blank',
          title: x.title,
        },
        x.title.slice(0, 15),
      ),
    )

    lastIndex = x.start
  }

  if (lastIndex !== 0) items.unshift(text.slice(0, lastIndex))

  return items
}

export function renderClickableLinks(text?: string) {
  if (!text) return null
  const matches = matchUrls(text).map(x => ({
    start: x.getOffset(),
    key: x.getMatchedText(),
    href: x.getAnchorHref(),
    title: x.getAnchorText(),
  }))

  return renderUrls(text, matches)
}

export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);