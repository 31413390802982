import { useForm } from 'react-hook-form'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { ContactUsSection, InputsContactUSSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import { useState } from 'react'
import ButtonRenderer from '../ButtonRenderer'
import TextArea from '../../../common/TextArea'
import InputText from '../../../common/InputText'

export default function VariantSixteen({ section }: { section: ContactUsSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subtitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const inputs = section.variables.find(variant => variant.name === 'INPUTS')?.data as InputsContactUSSection[]
  const button = section.variables.find(variant => variant.name === 'BUTTON_1')?.data
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const [error, setError] = useState('')

  const onSubmit = async () => {
    try {
    } catch (error) {
      setError('Something went wrong!')
    }
  }

  const inputClasses = {
    classInput: 'border-b border-black bg-transparent w-full md:w-[500px] focus:outline-0 placeholder:text-[12px] py-2',
    classLabel: 'text-sm inline-block mb-1 ',
  }

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: '#fff',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="px-6 md:px-8 py-6 md:py-8 w-full md:w-[600px] space-y-4 md:space-y-8">
        <h2 className="text-[24px] md:text-[40px] uppercase font-bold leading-none">
          <SectionSlatePreview initialValue={title} previewMode />
        </h2>
        <p className="md:text-[20px]">
          <SectionSlatePreview initialValue={subtitle} previewMode />
        </p>
        <div className="md:flex md:gap-x-4 space-y-4 md:space-y-0">
          {inputs && (
            <form className="flex flex-col gap-5" onSubmit={handleSubmit(onSubmit)}>
              {inputs.map(item => {
                return (
                  <div key={item.id}>
                    {item.type !== 'button' && item.type !== 'textarea' && (
                      <InputText
                        {...inputClasses}
                        label={item.name}
                        name={item.name}
                        register={register}
                        type={item.type}
                        errors={errors}
                        required={{ value: true, message: `${item.error}` }}
                        placeholder={item.placeholder}
                      />
                    )}

                    {item.type === 'button' && (
                      <div>
                        <input
                          type={item.type}
                          value={item.name}
                          // placeholder={item.placeholder}
                          className="w-full px-5 py-[13.5px] text-sm bg-black text-white cursor-pointer"
                        />
                      </div>
                    )}
                    {item.type === 'textarea' && (
                      <TextArea
                        {...inputClasses}
                        label={item.name}
                        name={item.name}
                        register={register}
                        type={item.type}
                        errors={errors}
                        placeholder={item.placeholder}
                        required={{ value: true, message: `${item.error}` }}
                      />
                    )}
                  </div>
                )
              })}
              {error && <span className="text-center font-semibold">{error}</span>}
              <div>
                {button ? (
                  <ButtonRenderer button={button} typeOfButton="submit" withOutHref />
                ) : (
                  <button
                    className="py-2 px-4 w-[175px] rounded-full border border-black font-bold bg-[#feff05]"
                    type="submit"
                  >
                    Get Started
                  </button>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}
