import { motion } from 'framer-motion'
import SectionSlate from '../../../common/SectionSlatePreview'
import { AboutUsSection, AboutUsSectionServices } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
const viewVariants = {
  offscreen: {
    y: 100,
  },
  onscreen: {
    y: 0,
    transition: {
      duration: 0.5,
      delay: 0.2,
      ease: [0, 0.71, 0.2, 1.01],
    },
  },
}
const viewport = { once: true, amount: 0.5 }

function VariantTen({ section }: { section: AboutUsSection }) {
  const subtitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const services = section.variables.find(variable => variable.name === 'SERVICE')?.data as AboutUsSectionServices[]
  return (
    <>
      <div
        className="px-6 md:px-12 py-[30px] md:py-[50px]"
        style={{
          ...generateSectionBackground({
            defaultColor: 'white',
            backgroundSettings: section?.settings?.color?.background,
            image: null,
          }),
          ...generateSectionSpacing(section?.settings?.spacing),
        }}
      >
        <motion.div variants={viewVariants}>
          <section className="flex justify-between py-6">
            <p className="max-w-[725px] text-[22px] md:text-2xl">
              <SectionSlate initialValue={subtitle} previewMode />
            </p>
          </section>
        </motion.div>
        {services?.map(data => (
          <div className="lg:flex lg:space-x-12 py-3">
            <motion.div initial="offscreen" whileInView="onscreen" viewport={viewport}>
              <motion.div variants={viewVariants}>
                <div className="pb-4 lg:pb-0 min-w-[100px]">
                  <button className="text-sm border border-black px-[5px] py-px rounded-lg">
                    <SectionSlatePreview initialValue={data?.name} previewMode />
                  </button>
                </div>
              </motion.div>
            </motion.div>
            <motion.div initial="offscreen" whileInView="onscreen" viewport={viewport}>
              <motion.div variants={viewVariants}>
                <p className="lg:max-w-[680px] text-[20px] md:text-2xl">
                  <SectionSlatePreview initialValue={data?.description} previewMode />
                </p>
              </motion.div>
            </motion.div>
          </div>
        ))}
      </div>
    </>
  )
}

export default VariantTen
