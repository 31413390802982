import { useState } from 'react'
import { BsInstagram, BsTwitter } from 'react-icons/bs'
import { GoPrimitiveDot } from 'react-icons/go'
import { IoClose, IoMenu } from 'react-icons/io5'
import FontLoader from '../FontLoader'
import { getSocialIcon, MenuVariantProps, NewMenuLinkType, Social, NewMenu } from '../menu'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../utils'

export default function MenuVariantThree({ menu, website }: MenuVariantProps) {
  return (
    <>
      <FontLoader settings={menu?.settings?.fontFamily} />
      <DesktopNavbar menu={menu} website={website} />
      <MobileNavbar menu={menu} website={website} />
    </>
  )
}

const SocialItems = ({ type, link, menu }: { type: Social; link: string; menu: NewMenu }) => {
  let Icon = getSocialIcon(type)
  return (
    <a href={link} className="text-black transition-all duration-500 shadow-2xl ">
      <Icon
        className="lg:text-xl"
        color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color}
      />
    </a>
  )
}

const DesktopNavbar = ({ menu, website }: MenuVariantProps) => {
  return (
    <div
      className="hidden lg:block "
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'white' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className="flex justify-between items-center py-6 px-12">
        <div className="text-xl ">
          <a href="/">
            {website?.config?.logo ? (
              <img
                src={website?.config?.logo}
                alt={website?.name}
                className="cursor-pointer rounded-full w-[80px] h-[80px]"
              />
            ) : (
              <p
                className="capitalize font-bold"
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                }}
              >
                {website?.name}
              </p>
            )}
          </a>
        </div>

        <div className="flex items-center gap-x-3 ">
          <ul className="list-none flex items-center font-semibold tracking-wide gap-x-12">
            {menu.links.map(({ id, name, type, url }) => {
              return (
                <li key={id}>
                  <a
                    href={url}
                    target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                    className="hover:border-b-2 pb-2 border-black transition-all duration-300"
                    style={{
                      ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                      ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                    }}
                  >
                    {name}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>

        <div className=" ">
          <ul className="list-none flex gap-x-6 text-xl">
            {website?.config?.social ? (
              <div className="flex gap-x-3">
                {website?.config?.social && (
                  <SocialItems menu={menu} type={'facebook'} link={website?.config?.social['facebook']} />
                )}
                {website?.config?.social && (
                  <SocialItems menu={menu} type={'twitter'} link={website?.config?.social['twitter']} />
                )}
              </div>
            ) : (
              <div className="flex gap-x-6">
                <li>
                  <BsInstagram
                    className="cursor-pointer"
                    color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color}
                  />
                </li>
                <li>
                  <BsTwitter
                    className="cursor-pointer"
                    color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color}
                  />
                </li>
              </div>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

const MobileNavbar = ({ menu, website }: MenuVariantProps) => {
  const [show, setShow] = useState(false)
  return (
    <div
      className="block lg:hidden "
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'white' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className="flex justify-between items-center py-4 px-4">
        <a href="/">
          {website?.config?.logo ? (
            <img
              className="cursor-pointer rounded-full w-[30px] h-[30px]"
              src={website?.config?.logo}
              alt={website?.name}
            />
          ) : (
            <p
              className="capitalize font-bold text-xl"
              style={{
                ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
              }}
            >
              {website?.name}
            </p>
          )}
        </a>
        {show ? (
          <IoClose
            className="cursor-pointer"
            onClick={() => setShow(false)}
            size={25}
            color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color}
          />
        ) : (
          <IoMenu
            className="cursor-pointer"
            onClick={() => setShow(true)}
            size={25}
            color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color}
          />
        )}
      </div>

      {show && (
        <ul
          style={{
            ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'white' }),
            ...getSpacing(menu?.settings?.spacing),
          }}
          className="shadow-lg py-4 list-none flex flex-col justify-start px-4 gap-y-2"
        >
          {menu.links.map(({ id, name, url, type }) => {
            return (
              <li key={id}>
                <a
                  href={url}
                  target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                  className="flex items-center gap-x-1 text-gray-800 font-semibold"
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  <GoPrimitiveDot
                    className="text-gray-600 "
                    color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color}
                  />
                  <span className="hover:text-gray-500 transition-all duration-300"> {name}</span>
                </a>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}
