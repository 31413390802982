import Icon from './icons'
import { IconPropsT } from './types'

function HelpBox(props: IconPropsT) {
  const { title = 'HelpBox', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 120 120" size={size} title={title} {...otherProps}>
      <path
        d="M111 0H9C4.02944 0 0 4.02944 0 9V111C0 115.971 4.02944 120 9 120H111C115.971 120 120 115.971 120 111V9C120 4.02944 115.971 0 111 0Z"
        fill="#D9D9D9"
      />

      <path
        d="M60.5 82C70.165 82 78 74.165 78 64.5C78 54.835 70.165 47 60.5 47C50.835 47 43 54.835 43 64.5C43 74.165 50.835 82 60.5 82Z"
        fill="white"
      />
      <path
        d="M58.9863 36.3566C53.368 36.3565 47.8759 38.0229 43.2045 41.1443C38.5331 44.2656 34.8923 48.7017 32.7424 53.8923C30.5927 59.0829 30.0304 64.7945 31.1265 70.3047C32.2226 75.8149 34.9277 80.8762 38.9002 84.8491C42.4246 88.3828 46.818 90.926 51.6378 92.2218C56.4575 93.5177 61.5338 93.521 66.3552 92.2311C71.1764 90.9418 75.5727 88.405 79.1016 84.876C82.6306 81.3471 85.1674 76.9508 86.4567 72.1296C87.7461 67.3082 87.7425 62.2322 86.4467 57.4125C85.1508 52.5929 82.6084 48.1993 79.075 44.6746C76.4429 42.0287 73.3122 39.9315 69.8639 38.5037C66.4156 37.0759 62.7184 36.3458 58.9863 36.3566ZM76.6133 45.5273L69.2444 52.8438C66.3925 50.385 62.7525 49.0329 58.987 49.0329C55.2215 49.0329 51.5812 50.385 48.7293 52.8438L41.3619 45.5273C46.1728 41.1178 52.4618 38.6718 58.9878 38.6718C65.5138 38.6718 71.8025 41.1178 76.6133 45.5273ZM58.9863 78.1817C55.4268 78.1817 52.0129 76.7675 49.496 74.2505C46.9791 71.7336 45.5652 68.3201 45.5652 64.7607C45.5652 61.2012 46.9791 57.7869 49.496 55.27C52.0129 52.7531 55.4268 51.3396 58.9863 51.3396C62.5457 51.3396 65.9592 52.7531 68.4761 55.27C70.9931 57.7869 72.4073 61.2012 72.4073 64.7607C72.4052 68.3195 70.9903 71.7317 68.4738 74.2482C65.9573 76.7647 62.5451 78.1796 58.9863 78.1817ZM39.7228 47.1633L47.0902 54.4782C44.6175 57.3314 43.2553 60.9797 43.2527 64.7553C43.2501 68.5308 44.6072 72.1811 47.0759 75.0377L39.7355 82.3766C35.3366 77.5686 32.896 71.2889 32.8939 64.7722C32.8919 58.2555 35.3284 51.974 39.7243 47.1633H39.7228ZM41.3731 84.0141L48.7135 76.6752C51.5698 79.1438 55.2198 80.5011 58.9951 80.4985C62.7704 80.4959 66.4184 79.1331 69.2714 76.6606L76.5867 84.0249C71.7765 88.4206 65.4955 90.857 58.9793 90.8549C52.4632 90.8529 46.1836 88.4128 41.3761 84.0141H41.3731ZM78.2239 82.3927L70.9055 75.0223C73.3694 72.1663 74.723 68.5187 74.7187 64.7468C74.7143 60.9748 73.3525 57.3301 70.8819 54.4798L78.2478 47.1633C82.6462 51.9767 85.0829 58.2626 85.0782 64.783C85.0735 71.3034 82.6277 77.5856 78.2224 82.3927H78.2239Z"
        fill="black"
        stroke="black"
        strokeWidth="0.5"
      />
    </Icon>
  )
}

export default HelpBox
