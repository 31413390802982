import { useContext, useEffect, useState } from 'react'
// import BottomFloatingButton from 'src/components/BottomFloatingButton'
import { WebsiteContext } from 'src/components/context/WebsiteContext'
import ModalDialogV2 from 'src/components/new/ModalDialogV2'
import { upsertBlockByPageID } from 'src/services/website.service'
import { v4 as uuid } from 'uuid'
import { QuoteSection, QuoteVariables, SectionVariableCategories } from 'src/_polly/components/src/sections'
import QuoteMe from 'src/icons/ComponentsIcons/QuoteMe'
import { AdvancedFeature } from '../modal/advanced'
import { MenuSaveButton } from '../modal/section/NewMenu/NewMenu'
import { EditSection } from './EditSectionModal'
import SectionTextField from './SectionField/SectionTextField'
import SectionMediaField from './SectionField/SectionMediaField'

interface EditSectionQuoteModalProps extends EditSection {
  section: QuoteSection
}

export default function EditQuoteModal({
  section: defaultValue,
  open,
  onClose: onBack,
  onUpdate,
}: EditSectionQuoteModalProps) {
  const websiteContext = useContext(WebsiteContext)

  const [section, setSection] = useState(defaultValue)
  const [updating, setUpdating] = useState(false)
  const [fireUpdate, setFireUpdate] = useState({
    fire: false,
    goBack: false,
  })

  const title = section.variables.find(variable => variable.name === 'TITLE')
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')
  const paragraph = section.variables.find(variable => variable.name === 'DESCRIPTION')
  const media = section.variables.find(variable => variable.name === 'MEDIA')

  const updateVariableData = (name: QuoteVariables, category: SectionVariableCategories, data: any) => {
    const current = section.variables.find(variable => variable.name === name)
    if (!current) {
      setSection({
        ...section,
        variables: [...section.variables, { name, data, category, id: uuid() }],
      })
      return
    }
    const otherVariables = section.variables.filter(variable => variable.name !== name)
    const newVariables = [...otherVariables, { ...current, data }]
    setSection({ ...section, variables: newVariables })
  }

  const handleSave = async (goBack = true) => {
    setFireUpdate({ fire: true, goBack })
  }

  useEffect(() => {
    const update = async (goBack = true) => {
      setUpdating(true)
      await upsertBlockByPageID(websiteContext.pageID, section as any)
      setUpdating(false)
      setFireUpdate({ fire: false, goBack: false })
      if (goBack) onBack()
    }
    if (fireUpdate.fire) update(fireUpdate.goBack)
  }, [fireUpdate, section])

  useEffect(() => {
    onUpdate?.(section)
  }, [section])

  return (
    <div>
      <ModalDialogV2 {...{ open, onBack }}>
        <div className="px-5 dark:text-white mt-[25px]">
          <div className="border-t-[1px] border-black dark:border-white">
            {/* {section.variant !== 'VARIANT_5' && ( */}
            {section.variant !== 'VARIANT_8' && (
              <div>
                <SectionTextField
                  title="Title"
                  initialValue={title?.data ?? []}
                  onChange={value => updateVariableData('TITLE', 'TEXT', value)}
                  onUpdate={() => handleSave(false)}
                />
              </div>
            )}
            {section.variant !== 'VARIANT_1' && (
              <div>
                <SectionTextField
                  title="Subtitle"
                  initialValue={subTitle?.data ?? []}
                  onChange={value => updateVariableData('SUBTITLE', 'TEXT', value)}
                  onUpdate={() => handleSave(false)}
                />
              </div>
            )}
            {section.variant === 'VARIANT_8' || section.variant === 'VARIANT_9' || section.variant === 'VARIANT_1' ? (
              <div>
                <SectionTextField
                  title="Paragraph"
                  initialValue={paragraph?.data ?? []}
                  onChange={value => updateVariableData('DESCRIPTION', 'TEXT', value)}
                  onUpdate={() => handleSave(false)}
                />
              </div>
            ) : null}
            {section.variant !== 'VARIANT_6' && section.variant !== 'VARIANT_7' && section.variant !== 'VARIANT_8' ? (
              <SectionMediaField
                defaultValue={media?.data}
                onChange={url => {
                  if (!url) {
                    setSection({
                      ...section,
                      variables: section.variables.filter(variable => variable.name !== 'MEDIA'),
                    })
                    return
                  }
                  updateVariableData('MEDIA', 'IMAGE', url)
                  handleSave(false)
                }}
              />
            ) : null}
          </div>
        </div>
        <div className="absolute top-4 left-[50%] translate-x-[-50%]">
          <QuoteMe width="auto" height={36} />
        </div>
        <div className="absolute top-[21px] right-[50px]">
          <button type="button" onClick={() => handleSave()} disabled={updating}>
            <MenuSaveButton save="save" />
          </button>
        </div>
        {updating && (
          <div className="fixed z-30 inset-0 lg:max-w-[500px] dark:bg-black/80 bg-white/80">
            <p className="flex items-center h-full justify-center w-full text-[19px] dark:text-white">Updating...</p>
          </div>
        )}

        <AdvancedFeature
          onBack={() => handleSave(false)}
          section={section}
          onChange={settings => setSection(s => ({ ...s, settings }))}
        />
      </ModalDialogV2>
    </div>
  )
}
