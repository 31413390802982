import { motion } from 'framer-motion'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { BlogItemsGroup, BlogSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
const viewVariants = {
  offscreen: {
    y: 100,
  },
  onscreen: {
    y: 0,
    transition: {
      duration: 0.5,
      delay: 0.2,
      ease: [0, 0.71, 0.2, 1.01],
    },
  },
}
const viewImgVariants = {
  offscreen: {
    opacity: 0.1,
  },
  onscreen: {
    opacity: 1,
    transition: {
      duration: 1,
      delay: 0.8,
      ease: [0, 0.71, 0.2, 1.01],
    },
  },
}
const viewport = { once: true, amount: 0.5 }
function VariantFour({ section }: { section: BlogSection }) {
  const items = section.variables.find(variable => variable.name === 'ITEMS')?.data as BlogItemsGroup[]
  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
      className="py-8 md:py-12"
    >
      <div className="px-6 md:px-12">
        {items?.map(item => (
          <div className="block space-y-12 lg:space-y-0 lg:flex lg:space-x-28 py-6">
            <motion.div initial="offscreen" whileInView="onscreen" viewport={viewport}>
              <motion.div variants={viewVariants}>
                <div className="block xl:flex xl:space-x-12">
                  <div className="py-2 xl:min-w-[150px]">
                    <button className={`text-sm block border border-black px-[5px] py-px rounded-md`}>
                      <SectionSlatePreview initialValue={item?.name} previewMode />
                    </button>
                  </div>
                  <div>
                    <div className="lg:w-[620px]">
                      <h4 className="text-[20px] md:text-3xl font-medium pb-5">
                        <SectionSlatePreview initialValue={item?.title} previewMode />
                      </h4>
                      <p className="text-[16px]">
                        <SectionSlatePreview initialValue={item?.description} previewMode />
                      </p>
                    </div>
                  </div>
                </div>
              </motion.div>
            </motion.div>
            <motion.div initial="offscreen" whileInView="onscreen" viewport={viewport}>
              <motion.div variants={viewImgVariants}>
                <div className="h-[300px] sm:h-[360px] max-w-[400px] sm:w-[475px]">
                  <img className="w-full h-full rounded object-cover object-center" src={item?.image} alt="" />
                </div>
              </motion.div>
            </motion.div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default VariantFour
