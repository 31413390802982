import { Auth } from 'aws-amplify'
import {
  Conversation,
  ConvoStatus,
  CreateConversationInput,
  CreateConversationMutation,
  CreateConversationMutationVariables,
  CreateMessageMutation,
  CreateMessageMutationVariables,
  DeleteConversationMutation,
  DeleteConversationMutationVariables,
  GetDirectConversationQuery,
  GetDirectConversationQueryVariables,
  ListConversationByStatusQuery,
  ListConversationByStatusQueryVariables,
  ListMessagesByThreadQuery,
  ListMessagesByThreadQueryVariables,
  ModelSortDirection,
  NotificationStatus,
  OnCreateConversationSubscription,
  OnCreateMessageSubscription,
  OnUpdateConversationSubscription,
  OnUpdateMessageSubscription,
  Topics,
  UpdateMessageMutation,
  UpdateMessageMutationVariables,
} from 'src/API'
import {
  createConversation as createConversationGraphql,
  createMessage as createMessageGql,
  deleteConversation,
  updateMessage,
} from 'src/graphql/mutations'
import { getDirectConversation, listConversationByStatus, listMessagesByThread } from 'src/graphql/queries'
import { onCreateConversation, onCreateMessage, onUpdateConversation, onUpdateMessage } from 'src/graphql/subscriptions'
import { graphqlQuery } from 'src/lib/queries'
import { graphqlSubscibe } from 'src/util/graphql'
import { v4 as uuidv4 } from 'uuid'
import { createNewNotification } from './notification.service'

export const listConversationByUser = async (nextToken?: string | null) => {
  try {
    const CognitoUser = await Auth?.currentAuthenticatedUser()
    const attr = CognitoUser?.signInUserSession?.idToken?.payload
    const { data } = await graphqlQuery<ListConversationByStatusQuery>({
      query: listConversationByStatus,
      variables: {
        status: ConvoStatus.ACTIVE,
        userIDLastActiveTime: {
          beginsWith: { userID: CognitoUser?.username || attr?.sub },
        },
        sortDirection: ModelSortDirection.DESC,
        nextToken: nextToken,
        limit: 10,
      } as ListConversationByStatusQueryVariables,
    })

    return data?.listConversationByStatus || { items: [], nextToken: null }
  } catch (err) {
    console.error('@messenger.service::getThreadsAsAuthor::error', err)
    throw err
  }
}

export const getConversation = async (options: { from?: string; to: string }) => {
  const CognitoUser = await Auth?.currentAuthenticatedUser()
  const userId = options.from || CognitoUser?.username
  const chatmateId = options.to

  const { data } = await graphqlQuery<GetDirectConversationQuery>({
    query: getDirectConversation,
    variables: {
      chatmateID: chatmateId,
      userID: { eq: userId },
      filter: {
        status: { eq: ConvoStatus.ACTIVE },
      },
      sortDirection: ModelSortDirection.DESC,
    } as GetDirectConversationQueryVariables,
  })

  return data?.getDirectConversation?.items?.[0] || null
}

export const createMessage = async (props: {
  threadID: string
  message: string
  messageType: 'TEXT' | 'IMAGE'
  receiverID: string
  senderID: string | null
  skipNotification?: boolean
}) => {
  try {
    let { threadID, message, messageType, receiverID, senderID, skipNotification } = props
    const CognitoUser = await Auth?.currentAuthenticatedUser()
    const attr = CognitoUser?.signInUserSession?.idToken?.payload
    if (senderID === null) senderID = CognitoUser?.username || attr?.sub
    const { data } = await graphqlQuery<CreateMessageMutation>({
      query: createMessageGql,
      variables: {
        input: {
          senderID: senderID,
          conversationID: threadID,
          content: message,
          type: messageType,
        },
      } as CreateMessageMutationVariables,
    })

    if (!skipNotification) {
      const newNotification = {
        owner: senderID,
        toNotifyID: receiverID,
        topic: Topics.NEW_MESSAGE,
        topicDescription: 'has sent a new message',
        topicUrl: '/messages/' + senderID,
        status: NotificationStatus.NOTIFIED,
      }
      createNewNotification(newNotification)
    }

    const msg = data?.createMessage
    return msg
  } catch (err) {
    console.error('@messenger.service::createMessage:error', err)
    throw err
  }
}

export const createConversation = async (receiverID: string, authorID?: string) => {
  // TODO: transaction
  try {
    const CognitoUser = await Auth?.currentAuthenticatedUser()
    const attr = CognitoUser?.signInUserSession?.idToken?.payload
    authorID = authorID || (CognitoUser?.username as string) || (attr?.sub as string)

    const common = {
      convID: uuidv4(),
      status: ConvoStatus.ACTIVE,
    }

    const conv = await getConversation({ from: authorID, to: receiverID })
    if (conv) return conv

    const items: CreateConversationInput[] = [
      { ...common, userID: authorID, chatmateID: receiverID },
      { ...common, userID: receiverID, chatmateID: authorID },
    ]

    const res = await Promise.all(
      items.map(x =>
        graphqlQuery<CreateConversationMutation>({
          query: createConversationGraphql,
          variables: {
            input: x,
          } as CreateConversationMutationVariables,
        }),
      ),
    )
    return res[0]?.data?.createConversation
  } catch (err) {
    console.error('@messenger.service::createThread::error', err)
    throw err
  }
}

export const deleteThread = async (options: { from?: string; to: string }) => {
  try {
    const CognitoUser = await Auth?.currentAuthenticatedUser()
    const conversation = await getConversation({ from: options.from || CognitoUser?.username, to: options.to })
    const { data } = await graphqlQuery<DeleteConversationMutation>({
      query: deleteConversation,
      variables: {
        input: {
          id: conversation?.id,
        },
      } as DeleteConversationMutationVariables,
    })
    return data?.deleteConversation
  } catch (err) {
    console.error('@messenger.service::deleteThread::error', err)
    throw err
  }
}

export const markMessageAsRead = async (messageID: string) => {
  const { data } = await graphqlQuery<UpdateMessageMutation>({
    query: updateMessage,
    variables: {
      input: {
        id: messageID,
        read: true,
      },
    } as UpdateMessageMutationVariables,
  })

  return data?.updateMessage
}

export const getMessagesByConversation = async (conversationID: string, nextToken?: string | null) => {
  try {
    const { data } = await graphqlQuery<ListMessagesByThreadQuery>({
      query: listMessagesByThread,
      variables: {
        conversationID,
        sortDirection: ModelSortDirection.DESC,
        nextToken,
      } as ListMessagesByThreadQueryVariables,
    })

    return data?.listMessagesByThread
  } catch (err) {}
}

/**
 * Thread subscriptions
 */
export const threadSubscriptions = () => {
  const createConvoObservable = graphqlSubscibe<OnCreateConversationSubscription>({
    query: onCreateConversation,
  })

  const updateConvoObservable = graphqlSubscibe<OnUpdateConversationSubscription>({
    query: onUpdateConversation,
  })

  return {
    onThreadCreate: createConvoObservable
      .map(x => x?.value?.data?.onCreateConversation as Conversation)
      .filter(x => !!x),
    onThreadUpdate: updateConvoObservable
      .map(x => x?.value?.data?.onUpdateConversation as Conversation)
      .filter(x => !!x),
  }
}

/**
 * Message subscription
 */
export const messageSubscriptions = () => {
  const onCreate = graphqlSubscibe<OnCreateMessageSubscription>({
    query: onCreateMessage,
  })

  const onUpdate = graphqlSubscibe<OnUpdateMessageSubscription>({
    query: onUpdateMessage,
  })

  return {
    onMessageCreate: onCreate.map(x => x?.value?.data?.onCreateMessage).filter(x => !!x),
    onMessageUpdate: onUpdate.map(x => x?.value?.data?.onUpdateMessage).filter(x => !!x),
  }
}
