import { useNavigate } from 'react-router-dom'
import Search from 'src/icons/Search'

function SearchAndFilter() {
  const navigate = useNavigate()
  return (
    <div className="flex items-center space-x-[30px] py-12">
      <button type="button" className="-mt-1" onClick={() => navigate('/search')}>
        <Search size={46} fill="white" className="dark:hidden" />
        <Search size={46} fill="#D9D9D9" className="hidden dark:block" />
      </button>
    </div>
  )
}

export default SearchAndFilter
