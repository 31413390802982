import clsx from 'clsx'
import SectionSlate from '../../../common/SectionSlatePreview'
import { AttentionGrabberSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import ButtonRenderer from '../ButtonRenderer'

export default function VariantTen({ section }: { section: AttentionGrabberSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data
  const button = section.variables.find(variant => variant.name === 'BUTTON_1')?.data

  return (
    <>
      <div
        className="md:min-h-[90vh] min-h-[50vh] bg-center bg-no-repeat md:bg-cover bg-gradient-to-tr relative from-black flex flex-col items-center justify-center  to-slate-800"
        style={{
          ...generateSectionBackground({
            defaultColor: 'white',
            backgroundSettings: section?.settings?.color?.background,
            image: media ?? null,
          }),
          ...generateSectionSpacing(section?.settings?.spacing),
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundOrigin: 'border-box',
        }}
      >
        <div className="h-full w-full bg-[#00000056] z-0 absolute" />
        <div className="gap-10 text-center text-neutral-content flex flex-col items-center justify-center  absolute z-10">
          <div className="md:min-w-[900px] md:max-w-[90%] py-6 flex flex-col">
            <div className="font-semibold text-[white] text-[12px] md:text-[18px] mt-2 lg:mt-6 mb-4">
              <SectionSlate initialValue={subTitle} previewMode />
            </div>
            <div className="text-white text-[26px] md:text-4xl font-bold">
              <SectionSlate initialValue={title} previewMode />
            </div>
            <div className="mt-4 md:text-xl text-white md:w-[80%] px-4 md:mx-auto">
              <div className="btn-submit">
                {button ? (
                  <ButtonRenderer button={button} typeOfButton="submit" withOutHref />
                ) : (
                  <button type="submit" className={clsx('px-5 py-3 font-medium text-white border border-white')}>
                    for more
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
