import Icon from './icons'
import { IconPropsT } from './types'

function SmileEmoji(props: IconPropsT) {
  const { title = 'SmileEmoji', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 18 17" size={size} title={title} {...otherProps}>
      <path d="M8.97273 0C7.33332 5.53708e-09 5.73073 0.486156 4.3676 1.39695C3.00448 2.30774 1.94204 3.60227 1.31463 5.11687C0.687228 6.63147 0.523039 8.29813 0.842822 9.90604C1.1626 11.514 1.952 12.9909 3.11118 14.1502C4.27037 15.3095 5.74729 16.099 7.35518 16.4189C8.96307 16.7388 10.6297 16.5748 12.1444 15.9475C13.659 15.3202 14.9536 14.2579 15.8645 12.8948C16.7755 11.5318 17.2617 9.92924 17.2619 8.28983C17.2593 6.09213 16.3852 3.98517 14.8313 2.43109C13.2773 0.877017 11.1704 0.00270464 8.97273 0ZM8.97273 15.8882C7.46973 15.8883 6.00045 15.4427 4.7507 14.6078C3.50095 13.7728 2.52685 12.586 1.95162 11.1975C1.37638 9.80889 1.22584 8.28093 1.51903 6.80681C1.81222 5.33268 2.53596 3.97863 3.59874 2.91586C4.66152 1.85308 6.0156 1.12932 7.48973 0.836131C8.96385 0.542944 10.4918 0.693474 11.8804 1.26871C13.2689 1.84394 14.4557 2.81805 15.2907 4.0678C16.1256 5.31755 16.5712 6.78683 16.571 8.28983C16.5687 10.3043 15.7674 12.2356 14.3429 13.6601C12.9185 15.0845 10.9872 15.8858 8.97273 15.8882Z" />
      <path d="M6.2084 6.90776C6.58992 6.90776 6.89921 6.59847 6.89921 6.21694C6.89921 5.83541 6.58992 5.52612 6.2084 5.52612C5.82687 5.52612 5.51758 5.83541 5.51758 6.21694C5.51758 6.59847 5.82687 6.90776 6.2084 6.90776Z" />
      <path d="M11.7357 6.90776C12.1173 6.90776 12.4266 6.59847 12.4266 6.21694C12.4266 5.83541 12.1173 5.52612 11.7357 5.52612C11.3542 5.52612 11.0449 5.83541 11.0449 6.21694C11.0449 6.59847 11.3542 6.90776 11.7357 6.90776Z" />
      <path d="M8.97276 12.4338C11.8743 12.4338 13.0005 10.9758 13.0475 10.9138C13.1006 10.8406 13.1228 10.7495 13.1095 10.66C13.0961 10.5706 13.0482 10.49 12.976 10.4355C12.9038 10.381 12.8132 10.3569 12.7235 10.3685C12.6338 10.3801 12.5522 10.4265 12.4963 10.4975C12.4575 10.5486 11.5051 11.7429 8.97276 11.7429C6.43976 11.7429 5.48733 10.548 5.44986 10.4996C5.42262 10.4633 5.3885 10.4328 5.34945 10.4097C5.3104 10.3866 5.26718 10.3714 5.22226 10.365C5.17734 10.3586 5.1316 10.3611 5.08766 10.3724C5.04372 10.3837 5.00244 10.4036 4.96616 10.4308C4.92988 10.458 4.89932 10.4922 4.87623 10.5312C4.85313 10.5703 4.83796 10.6135 4.83157 10.6584C4.82518 10.7033 4.8277 10.749 4.83899 10.793C4.85027 10.8369 4.87011 10.8782 4.89735 10.9145C4.94368 10.9778 6.07051 12.4338 8.97276 12.4338Z" />
    </Icon>
  )
}

export default SmileEmoji
