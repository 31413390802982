import { motion } from 'framer-motion'
import { FreeMode, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ReactComponent as LeftArrow } from '../../../assets/icons/left-arrow.svg'
import { ReactComponent as RightArrow } from '../../../assets/icons/right-arrow.svg'
import SectionSlate from '../../../common/SectionSlatePreview'
import { PortfolioItemsGroup, PortfolioSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantTwo({ section }: { section: PortfolioSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const items = section.variables.find(variable => variable.name === 'ITEMS')?.data as PortfolioItemsGroup[]

  return (
    <motion.div
      transition={{ x: -50 }}
      animate={{
        x: 0,
        transition: {
          duration: 1,
        },
      }}
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="md:px-12 px-2 relative flex-flex-col py-4">
        <div className="flex flex-col md:px-10">
          <h1 className="md:text-3xl font-bold uppercase w-[95%]">
            <SectionSlate initialValue={title} previewMode />
          </h1>
          <p className="md:text-xl text-sm font-semibold w-[90%]">
            <SectionSlate initialValue={paragraph} previewMode />
          </p>
        </div>
        {/* desktop */}
        <div className="my-6 lg:my-6 hidden lg:inline-block relative w-[95%]">
          <Swiper
            slidesPerView={4}
            spaceBetween={10}
            freeMode={true}
            navigation={{
              nextEl: '.custom-swiper-button-next',
              prevEl: '.custom-swiper-button-prev',
            }}
            modules={[FreeMode, Navigation]}
            className="mySwiper w-[95%] mr-12"
          >
            {items.map(item => {
              return (
                <SwiperSlide key={item.id}>
                  <img
                    src={item.image}
                    alt=""
                    className="cursor-pointer transition-all duration-500 grayscale-[100%] hover:grayscale-0 object-cover object-center w-[450px] lg:h-[300px]"
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
        <div className="justify-between hidden lg:flex">
          <button
            className="stroke-black dark:stroke-white absolute top-[310px] right-[100px] z-50 custom-swiper-button-next"
            // style={{ stroke: backgroundHr }}
          >
            <RightArrow />
          </button>
          <button
            className="absolute top-[310px] stroke-black dark:stroke-white left-0 z-10 custom-swiper-button-prev"
            // style={{ stroke: backgroundHr }}
          >
            <LeftArrow />
          </button>
        </div>
        <hr
          className="h-1 bg-black rounded-full  mx-6 w-[93%] hidden lg:flex"
          //  style={{ background: backgroundHr }}
        />
      </div>
      {/* mobile */}
      <div className="flex lg:hidden overflow-y-hidden overflow-scroll gap-x-3  ml-2 w-[367px]">
        {items.map(item => {
          return (
            <img
              key={item.id}
              src={item.image}
              alt=""
              className="cursor-pointer h-[100px] w-[9rem] mb-2 grayscale-[100%] hover:grayscale-0 transition-all duration-500  object-cover object-center "
            />
          )
        })}
      </div>
    </motion.div>
  )
}
