import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { GallerySection, GallerySectionItem } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantFour({ section }: { section: GallerySection }) {
  const gallery = section.variables.find(variable => variable.name === 'MEDIA')?.data as GallerySectionItem[]

  let indexIdentifier = 1
  return (
    <div
      className="py-[50px]"
      style={{
        ...generateSectionBackground({
          defaultColor: '#000',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      {Array.isArray(gallery) ? (
        <div className=" container w-[1300px] mx-auto max-w-full grid-cols-3 space-y-2 lg:space-y-0 lg:grid lg:gap-3 lg:grid-rows-3">
          {gallery.map((data, idx) => {
            if (idx === indexIdentifier) {
              if (indexIdentifier % 3 == 0) {
                indexIdentifier += 4
              } else {
                indexIdentifier += 2
              }
              return (
                <div
                  key={idx}
                  className="w-full col-span-2 row-span-2 bg-center bg-cover bg-white relative tmp-inner md:min-h-[400px]"
                >
                  <img className="object-cover object-center bg-black w-full h-full" src={data.image} alt="" />
                  <div className="hover-box flex justify-center items-center mx-auto py-[20px] px-6">
                    <span>
                      <h1 className="lg:text-[3.5em] text-center py-1 lg:py-6 mx-auto text-white">
                        <SectionSlatePreview initialValue={data.title} previewMode />
                      </h1>
                      <p className="text-[10px] lg:text-[1.5em] font-semibold text-white text-center mx-auto">
                        <SectionSlatePreview initialValue={data.description} previewMode />
                      </p>
                    </span>
                  </div>
                </div>
              )
            } else {
              return (
                <div key={idx} className="w-full bg-center bg-cover bg-white relative tmp-inner">
                  <img className="object-cover object-center bg-black w-full h-full" src={data.image} alt="" />
                  <div className="hover-box flex justify-center items-center mx-auto py-[20px] px-6">
                    <span>
                      <h1 className="lg:text-[3.5em] text-center py-1 lg:py-6 mx-auto text-white">
                        <SectionSlatePreview initialValue={data.title} previewMode />
                      </h1>
                      <p className="text-[10px] lg:text-[1.5em] font-semibold text-white text-center mx-auto">
                        <SectionSlatePreview initialValue={data.description} previewMode />
                      </p>
                    </span>
                  </div>
                </div>
              )
            }
          })}
        </div>
      ) : null}
    </div>
  )
}
