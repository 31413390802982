import { Descendant } from 'slate'
import { BlogItemsGroup, BlogVariables, BlogVariants, Section, SectionTags } from 'src/_polly/components/src/sections'
import { v4 as uuid } from 'uuid'
import { DEFAULT_VARIANT_SETTINGS, getSectionVariants } from './get-variants'

const tags: Record<BlogVariants, SectionTags[]> = {
  VARIANT_1: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_2: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_3: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_4: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_5: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_6: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_7: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_8: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
}

export function getBlogVariants() {
  const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Title' }] }]
  const SubTitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Sub Title' }] }]
  const paragraph: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Paragraph' }] }]
  const nameItem: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Lorem ipsum' }] }]
  const media =
    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'

  const variants = getSectionVariants('BLOG')
  const sections: Section<BlogVariants, BlogVariables>[] = []
  variants.forEach(variant => {
    if (variant.id === 'VARIANT_1') {
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique.',
            },
          ],
        },
      ]

      const data: Section<BlogVariants, BlogVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'BLOG',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: SubTitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          { id: uuid(), category: 'IMAGE', data: media, name: 'MEDIA' },
          {
            id: uuid(),
            category: 'IMAGE',
            name: 'ITEMS',
            data: [
              {
                id: uuid(),
                image:
                  'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                name: nameItem,
              },
              {
                id: uuid(),
                image:
                  'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                name: nameItem,
              },
              {
                id: uuid(),
                image:
                  'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                name: nameItem,
              },
            ] as BlogItemsGroup[],
          },
        ],
        variant: variant.id as BlogVariants,
        tags: tags[variant.id as BlogVariants],
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_2') {
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique.',
            },
          ],
        },
      ]
      const media =
        'https://images.unsplash.com/photo-1572044162444-ad60f128bdea?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'

      const data: Section<BlogVariants, BlogVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'BLOG',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          { id: uuid(), category: 'IMAGE', data: media, name: 'MEDIA' },
          {
            id: uuid(),
            category: 'IMAGE',
            name: 'ITEMS',
            data: [
              {
                id: uuid(),
                image: media,
                name: nameItem,
              },
              {
                id: uuid(),
                image: media,
                name: nameItem,
              },
              {
                id: uuid(),
                image: media,
                name: nameItem,
              },
              {
                id: uuid(),
                image: media,
                name: nameItem,
              },
              {
                id: uuid(),
                image: media,
                name: nameItem,
              },
            ] as BlogItemsGroup[],
          },
        ],
        variant: variant.id as BlogVariants,
        tags: tags[variant.id as BlogVariants],
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_3') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Hits Different Investors' }] }]
      const nameItem: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Accelerate Appeal' }] }]
      const description: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'For B2B startups and those building their first site, our alluring web and UX design services provide the invigorating expertise you need to create an intuitive, user-centered experience.',
            },
          ],
        },
      ]

      const data: Section<BlogVariants, BlogVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'BLOG',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },

          {
            id: uuid(),
            category: 'IMAGE',
            name: 'ITEMS',
            data: [
              {
                id: uuid(),
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-cb603365-7b8e-4e1a-b1a9-97e7550436a8',
                name: nameItem,
                description: description,
              },
              {
                id: uuid(),
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-50594cad-3a77-468d-92cd-fff32c729bcc',
                name: nameItem,
                description: description,
              },
              {
                id: uuid(),
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-d42eddb5-11d6-4344-9b0f-90121ecb9bf5',
                name: nameItem,
                description: description,
              },
            ] as BlogItemsGroup[],
          },
        ],
        variant: variant.id as BlogVariants,
        tags: tags[variant.id as BlogVariants],
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_4') {
      const name: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Why korr',
            },
          ],
        },
      ]
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Marzipan donut dessert',
            },
          ],
        },
      ]
      const descriptionItem: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Apple pie gummies dessert muffin soufflé. Halvah dessert soufflé cheesecake halvah carrot cake tiramisu topping marshmallow. Powder biscuit liquorice croissant tootsie roll tiramisu jujubes. Toffee sweet roll carrot cake dessert cheesecake marzipan gummies. Donut apple pie biscuit tart chocolate bar tiramisu candy canes halvah bonbon Apple pie tart toffee cookie topping cake. Toffee wafer danish chocolate cake caramels jelly dragée. Cotton candy cupcake dragée danish caramels sweet. Jujubes cake biscuit fruitcake topping tootsie roll danish marshmallow',
            },
          ],
        },
      ]
      const data: Section<BlogVariants, BlogVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'BLOG',
        updatedAt: '',
        variables: [
          {
            id: uuid(),
            category: 'IMAGE',
            name: 'ITEMS',
            data: [
              {
                id: uuid(),
                image:
                  'https://images.unsplash.com/photo-1618685959528-2700ba389504?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTh8fGRhcmt8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
                description: descriptionItem,
                title: title,
                name: name,
              },
              {
                id: uuid(),
                image:
                  'https://images.unsplash.com/photo-1617396900799-f4ec2b43c7ae?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NzV8fGRhcmt8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
                description: descriptionItem,
                title: title,
                name: name,
              },
            ] as BlogItemsGroup[],
          },
        ],
        variant: variant.id as BlogVariants,
        tags: tags[variant.id as BlogVariants],
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_5') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Burn Rate Buster UX Design',
            },
          ],
        },
      ]
      const descriptionItem: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience? Look no further than our custom site and portfolio design services. Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. Whether you are a digital nomad or a disruptive technology startup, we have the expertise to optimize your website for exceptional user experience on any device..',
            },
          ],
        },
      ]
      const data: Section<BlogVariants, BlogVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'BLOG',
        updatedAt: '',
        variables: [
          {
            id: uuid(),
            category: 'IMAGE',
            name: 'ITEMS',
            data: [
              {
                id: uuid(),
                image:
                  'https://images.unsplash.com/photo-1675453988015-304184957af2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2Nzc3MDA1NzM&ixlib=rb-4.0.3&q=80&w=1080',
                title: title,
                name: nameItem,
                texts: descriptionItem,
              },
            ] as BlogItemsGroup[],
          },
        ],
        variant: variant.id as BlogVariants,
        tags: tags[variant.id as BlogVariants],
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_6') {
      const nameItem: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Burn Rate Buster UX Design',
            },
          ],
        },
      ]
      const descriptionItem: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience? Look no further than our custom site and portfolio design services. Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. Whether you are a digital nomad or a disruptive technology startup, we have the expertise to optimize your website for exceptional user experience on any device..',
            },
          ],
        },
      ]
      const data: Section<BlogVariants, BlogVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'BLOG',
        updatedAt: '',
        variables: [
          {
            id: uuid(),
            category: 'IMAGE',
            name: 'ITEMS',
            data: [
              {
                id: uuid(),
                image:
                  'https://images.unsplash.com/photo-1603673329776-28248af53fc0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDl8fG9mZmljZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
                // name: 'Mission',
                name: nameItem,
                description: descriptionItem,
              },
            ] as BlogItemsGroup[],
          },
        ],
        variant: variant.id as BlogVariants,
        tags: tags[variant.id as BlogVariants],
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_7') {
      const title: Descendant[] = [
        { type: 'paragraph', children: [{ text: 'VERTICAL TIMELINE', className: 'uppercase' }] },
      ]
      const subTitle: Descendant[] = [
        { type: 'paragraph', children: [{ text: 'Sava Lazic', className: 'allura-subtitle' }] },
      ]
      const description: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repudiandae obcaecati expedita nobis nesciunt voluptatibus quasi fuga quod vitae nulla esse..',
            },
          ],
        },
      ]

      const data: Section<BlogVariants, BlogVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'BLOG',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subTitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: uuid(),
            category: 'IMAGE',
            name: 'ITEMS',
            data: [
              {
                id: uuid(),
                image:
                  'https://images.unsplash.com/photo-1469474968028-56623f02e42e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bmF0dXJlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60',
                name: nameItem,
                description: description,
                date: '1 MAY 2016',
              },
              {
                id: uuid(),
                image:
                  'https://images.unsplash.com/photo-1441974231531-c6227db76b6e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fG5hdHVyZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=400&q=60',
                name: nameItem,
                description: description,
                date: '25 MAY 2016',
              },
              {
                id: uuid(),
                image:
                  'https://images.unsplash.com/photo-1510784722466-f2aa9c52fff6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fG5hdHVyZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=400&q=60',
                name: nameItem,
                description: description,
                date: '3 JUN 2016',
              },
            ] as BlogItemsGroup[],
          },
        ],
        variant: variant.id as BlogVariants,
        tags: tags[variant.id as BlogVariants],
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_8') {
      const nameItem: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Fire Cottage Industry',
            },
          ],
        },
      ]
      const description: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
            },
          ],
        },
      ]

      const data: Section<BlogVariants, BlogVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'BLOG',
        updatedAt: '',
        variables: [
          {
            id: uuid(),
            category: 'IMAGE',
            name: 'ITEMS',
            data: [
              {
                id: uuid(),
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-87cb29d6-4356-4d2f-b110-551a00758608',
                name: nameItem,
                description: description,
              },
              {
                id: uuid(),
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-516fa206-2533-44ba-b795-7b2f88f07a55',
                name: nameItem,
                description: description,
              },
            ] as BlogItemsGroup[],
          },
        ],
        variant: variant.id as BlogVariants,
        tags: tags[variant.id as BlogVariants],
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else {
      const name: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Lorem ipsum',
            },
          ],
        },
      ]
      const description: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique',
            },
          ],
        },
      ]
      const data: Section<BlogVariants, BlogVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'BLOG',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: SubTitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: uuid(),
            category: 'IMAGE',
            name: 'ITEMS',
            data: [
              {
                id: uuid(),
                image: media,
                description: description,
                name: name,
                title: title,
                texts: description,
              },
              {
                id: uuid(),
                image: media,
                description: description,
                name: name,
                title: title,
                texts: description,
              },
            ] as BlogItemsGroup[],
          },

          {
            id: uuid(),
            category: 'IMAGE',
            name: 'ITEMS',
            data: [] as BlogItemsGroup[],
          },
        ],
        variant: variant.id as BlogVariants,
        styleName: variant.name,
        tags: tags[variant.id as BlogVariants],
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    }
  })
  return sections
}
