import { motion } from 'framer-motion'
import { useState } from 'react'
import { IoClose, IoMenuOutline } from 'react-icons/io5'
import FontLoader from '../FontLoader'
import { MenuVariantProps } from '../menu'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../utils'

export default function MenuVariantTwenty({ menu, website }: MenuVariantProps) {
  return (
    <div>
      <FontLoader settings={menu?.settings?.fontFamily} />
      <DesktopNavbar menu={menu} website={website} />
    </div>
  )
}
const underLine = {
  default: {
    x: -400,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    x: 400,
    transition: {
      duration: 0.5,
    },
  },

  hover: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
}
const DesktopNavbar = ({ menu }: MenuVariantProps) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div
      style={{
        ...getBackgroundColor({
          settings: menu?.settings?.backgroundColor,
          defaultValue: `${!isOpen ? 'white' : 'black'}`,
        }),
        ...getSpacing(menu?.settings?.spacing),
      }}
      className={`transition-all duration-300`}
    >
      <div className="md:px-12 px-4 md:py-6 py-3">
        {isOpen ? (
          <button
            className="flex flex-col cursor-pointer top-0 right-0 z-20 relative w-10 h-10 focus:outline-none -ml-2"
            onClick={() => setIsOpen(false)}
          >
            <div className="md:text-4xl text-2xl">
              <IoClose color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#8383e8' }).color} />
            </div>
          </button>
        ) : (
          <button onClick={() => setIsOpen(true)} className="focus:outline-none ">
            <IoMenuOutline
              className="md:text-4xl text-2xl"
              color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#8383e8' }).color}
            />
          </button>
        )}
      </div>
      <div>
        {isOpen && (
          <motion.ul
            initial={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 0, transition: { delay: 0.3, ease: 'easeInOut' } }}
            exit={{ opacity: 0, y: 40 }}
            className="flex flex-col md:flex-row text-white items-center justify-center md:gap-x-8 pb-3 overflow-y-hidden "
            style={{
              ...getBackgroundColor({
                settings: menu?.settings?.backgroundColor,
                defaultValue: `${!isOpen ? 'white' : 'black'}`,
              }),
              ...getSpacing(menu?.settings?.spacing),
            }}
          >
            {menu.links.map(items => {
              return (
                <motion.li
                  initial="default"
                  exit="exit"
                  whileHover="hover"
                  animate="default"
                  key={items.id}
                  className="md:text-xl relative grid  items-center overflow-hidden md:pb-10"
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  <a href={items.url}>{items.name}</a>
                  <motion.div
                    variants={underLine}
                    className=" top-[17px] h-[3px] relative left-0 w-[100%] z-1 mt-[-23px] rounded-full "
                    style={{
                      background: getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }).color,
                    }}
                  />
                </motion.li>
              )
            })}
          </motion.ul>
        )}
      </div>
    </div>
  )
}
