import Icon from './icons'
import { IconPropsT } from './types'

function EditPlus(props: IconPropsT) {
  const { title = 'EditPlus', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 50 50" size={size} title={title} {...otherProps}>
      <rect x="13" y="13" width="24" height="24" rx="3.5" />
      <g clipPath="url(#clip0_534_11015)">
        <path d="M19.6702 25H31.4336" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M25.5519 19.1183V30.8817" strokeWidth="1.5" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_534_11015">
          <rect width="10.1562" height="10.1562" transform="translate(18.3706 25) rotate(-45)" />
        </clipPath>
      </defs>
    </Icon>
  )
}

export default EditPlus
