import { motion } from 'framer-motion'
import { useState } from 'react'
import { BsFacebook, BsInstagram, BsTwitter } from 'react-icons/bs'
import { IoMdClose } from 'react-icons/io'
import { RiArrowDownSLine, RiArrowUpSLine, RiMenu5Fill } from 'react-icons/ri'
import FontLoader from '../FontLoader'
import { getSocialIcon, getSocialLabel, MenuVariantProps, NewMenu, NewMenuLinkType, Social } from '../menu'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../utils'

const SocialItem = ({ link, type, menu }: { type: Social; link: string; menu: NewMenu }) => {
  const Icon = getSocialIcon(type)
  const label = getSocialLabel(type)
  return (
    <a
      className="flex gap-x-2 items-center"
      href={link}
      target="_blank"
      style={{
        ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
        ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
      }}
    >
      <span
        className="text-black transition-all duration-300 lg:hover:text-gray-500 rounded-full lg:p-4 p-1  lg:border border-black text-sm lg:text-xl"
        // style={{ boxShadow: 'inset 22.82px 22.82px 16px #CBCDD2, inset -22.82px -22.82px 16px #FFFFFF' }}
        style={{
          borderColor: getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color,
        }}
      >
        <Icon color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color} />
      </span>
      <span className="font-semibold text-sm block lg:hidden  hover:text-gray-200 transition-all duration-300">
        {label}
      </span>
    </a>
  )
}

export default function MenuVariantEleven({ menu, website }: MenuVariantProps) {
  return (
    <>
      <FontLoader settings={menu?.settings?.fontFamily} />
      <DesktopNavbar menu={menu} website={website} />
      <MobileNavbar menu={menu} website={website} />
    </>
  )
}

const DesktopNavbar = ({ menu, website }: MenuVariantProps) => {
  return (
    <div
      className="hidden lg:block"
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'white' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className="flex pr-12 items-center justify-between py-6">
        <div>
          <ul className="flex items-center list-none gap-x-5">
            {menu.links.map(({ id, name, type, url }) => {
              return (
                <li key={id}>
                  <a
                    href={url}
                    target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                    className="text-black text-xl font-bold hover:text-gray-500 transition-all duration-300 rounded-lg"
                    style={{
                      ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                      ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                    }}
                  >
                    {name}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
        <div>
          <a href="/">
            {website?.config?.logo ? (
              <img
                src={website?.config?.logo}
                alt={website?.name}
                className="cursor-pointer border w-[80px] h-[80px] rounded-full"
              />
            ) : (
              <p
                className="uppercase font-bold text-xl"
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                }}
              >
                {website?.name}
              </p>
            )}
          </a>
        </div>

        <div className="flex items-center gap-x-6 ">
          {website?.config.social ? (
            Object.keys(website.config.social).map(item => (
              <SocialItem type={item as Social} link={website.config.social[item]} menu={menu} />
            ))
          ) : (
            <ul className="flex items-center gap-x-5 list-none">
              <li className="flex gap-x-2 items-center">
                <span
                  className="text-black transition-all duration-300 lg:hover:text-gray-500 rounded-full md:p-2 p-2  lg:border border-black text-sm lg:text-xl"
                  // style={{ boxShadow: 'inset 22.82px 22.82px 16px #CBCDD2, inset -22.82px -22.82px 16px #FFFFFF' }}
                  style={{
                    borderColor: getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color,
                  }}
                >
                  <BsInstagram
                    color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color}
                  />
                </span>
              </li>
              <li className="flex gap-x-2 items-center">
                <span
                  style={{
                    borderColor: getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color,
                  }}
                  className="text-black transition-all duration-300 lg:hover:text-gray-500 rounded-full md:p-2 p-2  lg:border border-black text-sm lg:text-xl"
                >
                  <BsTwitter
                    color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color}
                  />
                </span>
              </li>
              <li className="flex gap-x-2 items-center">
                <span className="text-black transition-all duration-300 lg:hover:text-gray-500  rounded-full md:p-2 p-2  lg:border border-black text-sm lg:text-xl">
                  <BsFacebook
                    color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color}
                    style={{
                      borderColor: getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color,
                    }}
                  />
                </span>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

const MobileNavbar = ({ menu, website }: MenuVariantProps) => {
  const [show, setShow] = useState(false)
  const [contact, setContact] = useState(false)
  return (
    <div
      className="block lg:hidden"
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'white' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className="flex justify-between px-4 py-4 overflow-hidden ">
        <div>
          <a href="/">
            {website?.config.logo ? (
              <img
                className="cursor-pointer rounded-full w-[30px] h-[30px]"
                src={website?.config?.logo}
                alt={website?.name}
              />
            ) : (
              <p
                className="font-bold tracking-wide"
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  textShadow: ' 3px 3px 3px gray',
                }}
              >
                {website?.name}
              </p>
            )}
          </a>
        </div>
        {show ? (
          <button type="button" className="outline-none">
            <IoMdClose
              onClick={() => setShow(false)}
              fontSize={24}
              className="hover:rotate-180 transition-all ease-in-out duration-300"
              color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color}
            />
          </button>
        ) : (
          <button type="button" className="outline-none">
            <RiMenu5Fill
              onClick={() => setShow(true)}
              fontSize={24}
              color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color}
            />
          </button>
        )}
      </div>
      <motion.div
        className="overflow-hidden bg-white"
        animate={{ x: show ? [-120, 0] : -120 }}
        transition={{ duration: 0.6, type: 'spring' }}
      >
        {show && (
          <motion.ul
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 1,
              },
            }}
            style={{
              ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'white' }),
              ...getSpacing(menu?.settings?.spacing),
            }}
            className="list-none font-semibold flex flex-col gap-y-3 py-3 pb-8 bg-black text-gray-300 hover:text-gray-400"
          >
            {menu.links.map(({ id, name, type, url }) => {
              return (
                <li
                  key={id}
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                  className="px-4"
                >
                  <a
                    href={url}
                    target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                    className="font-semibold hover:text-gray-200 transition-all duration-300  "
                  >
                    {name}
                  </a>
                </li>
              )
            })}
            <li className="px-4 pb-2">
              <button
                className="font-semibold flex items-center gap-x-1 "
                onClick={() => setContact(!contact)}
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                }}
              >
                <span>Contact us</span>
                {contact ? (
                  <RiArrowUpSLine
                    color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color}
                  />
                ) : (
                  <RiArrowDownSLine
                    className="mt-1"
                    color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color}
                  />
                )}
              </button>
            </li>
            {contact && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                className="flex flex-col overflow-hidden gap-y-2 px-4 pb-4"
              >
                {website?.config.social &&
                  Object.keys(website.config.social).map(item => (
                    <SocialItem type={item as Social} link={website.config.social[item]} menu={menu} />
                  ))}
              </motion.div>
            )}
          </motion.ul>
        )}
      </motion.div>
    </div>
  )
}
