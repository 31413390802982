import { Auth } from 'aws-amplify'
import { useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5'
import { Link, useNavigate } from 'react-router-dom'
import InputText from 'src/_polly/components/src/common/InputText'
import AuthLayout from 'src/components/layout/AuthLayout'
import { Loader } from '../components/loader'

interface Input {
  code: string
  password: string
}

const ForgotPassword = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<Input>()
  const navigate = useNavigate()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const passwordRef = useRef({})
  passwordRef.current = watch('password', '')
  const code = watch('code', '')

  useEffect(() => {
    const email = localStorage.getItem('email')
    if (!email) navigate('/login')
  }, [navigate])

  const onSubmit: SubmitHandler<Input> = async data => {
    const email = localStorage.getItem('email') as string
    try {
      setLoading(true)
      await Auth.forgotPasswordSubmit(email, data.code, data.password)
      await Auth.signIn(email, data.password)
      navigate('/home')
    } catch (err: any) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <AuthLayout>
      <div className="">
        <Loader show={loading} />
        <form autoComplete="off" className="space-y-8 flex flex-col items-center" onSubmit={handleSubmit(onSubmit)}>
          <div className="text-[20px] text-white text-center opacity-80">
            <p>Reset Account Password</p>
          </div>
          <div className="w-[265px] flex justify-center">
            <InputText
              authInput
              placeholder="Verification Code"
              name="code"
              type="text"
              register={register}
              errors={errors}
              required={{ value: true, message: 'Verification code is required' }}
            />
          </div>
          <div className="w-[265px] relative">
            <InputText
              authInput
              placeholder="New Password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              register={register}
              errors={errors}
              required={{ value: true, message: 'Password is required' }}
              minLength={{ value: 8, message: 'Password must be 8-32 characters long' }}
              maxLength={{ value: 32, message: 'Password must be 8-32 characters long' }}
            />
            <div className="absolute right-4 top-2.5">
              <button type="button" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <IoEyeOutline size={20} /> : <IoEyeOffOutline size={20} />}
              </button>
            </div>
            <p className="text-[10px] text-[#FFFFFF] opacity-70 pt-2">
              Passwords should atleast 1 character and 1 capital letter
            </p>
          </div>
          {error && <span className="text-secondary.main text-center mb-4">{error}</span>}
          <div className="w-[265px]">
            <button
              className={`w-full py-2 bg-[#E90570] text-white text-[20px] rounded ${
                (loading || code.length !== 6) && 'cursor-not-allowed'
              }`}
              disabled={loading || code.length !== 6}
              type="submit"
            >
              Reset
            </button>
          </div>
          <div className="w-[265px]">
            <Link
              to="/forgot-password"
              className="w-full py-2 bg-[#D9D9D9] text-[#000000] text-[20px] border-3 border-white rounded block text-center"
            >
              Look up Your Account
            </Link>
          </div>
        </form>
      </div>
    </AuthLayout>
  )
}

export default ForgotPassword
