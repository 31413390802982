import { NavLink } from 'react-router-dom'
import FontLoader from '../FontLoader'
import { MenuVariantProps, NewMenuLinkType } from '../menu'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../utils'

export default function MenuVariantTwentyFive({ menu, website }: MenuVariantProps) {
  return (
    <>
      <FontLoader settings={menu?.settings?.fontFamily} />
      <DesktopNavbar menu={menu} website={website} />
    </>
  )
}

const DesktopNavbar = ({ menu }: MenuVariantProps) => {
  return (
    <div
      style={{
        ...getBackgroundColor({
          settings: menu?.settings?.backgroundColor,
          defaultValue: 'white',
        }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className={`w-auto mx-auto py-6 px-6 md:px-12`}>
        <div className="flex items-center justify-center">
          <div className="bg-white flex flex-wrap md:flex-nowrap gap-x-4 items-center justify-center rounded-full py-1 px-3 md:px-12 shadow-lg space-x-4">
            {menu.links.map(({ id, name, type, url }) => (
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? 'text-[#fff] uppercase block bg-gray-100 px-3 py-2 rounded-full md:text-xl text-sm '
                    : 'md:text-xl text-sm py-2 hover:bg-gray-100 rounded-full px-3'
                }
                key={id}
                to={url}
                target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'gray' }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                }}
              >
                {name}
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
