import { useEffect, useState } from 'react'

interface TitleBarProps {
  title: string
  showSwitch?: boolean
  checked?: boolean
  onChange?: (value: boolean) => void
  description?: string
}

export default function TitleBar({ title, showSwitch, checked = false, onChange, description }: TitleBarProps) {
  return (
    <div className={`${showSwitch ? 'pb-4' : 'pb-5'} border-b border-black dark:border-white`}>
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <div>
            <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.59016 6.85509L7.66471 6.73434V6.59243V0.5H13.8294V6.98935V7.13125L13.904 7.25201L20.0073 17.1381L20.0477 17.2036L20.106 17.2539C20.4994 17.5934 20.7471 18.094 20.7471 18.6529C20.7471 19.673 19.9201 20.5 18.9 20.5H2.34706C1.32696 20.5 0.5 19.673 0.5 18.6529C0.5 18.0924 0.749116 17.5905 1.14442 17.251L1.20332 17.2004L1.24411 17.1343L7.59016 6.85509Z"
                stroke="#E90570"
              />
              <circle cx="6.96471" cy="13.9647" r="2.96471" fill="#E90570" />
              <circle cx="11.3661" cy="8.39931" r="2.22353" fill="#E90570" />
              <circle cx="9.63666" cy="3.45795" r="1.23529" fill="#E90570" />
              <circle cx="14.5" cy="16.5" r="2.5" fill="#E90570" />
            </svg>
          </div>
          <p className="text-xl dark:text-white">{title}</p>
        </div>

        {showSwitch && <Switch {...{ checked, onChange }} />}
      </div>
      {description && <p className="mt-5">{description}</p>}
    </div>
  )
}

function Switch({ checked, onChange }: { checked: boolean; onChange?: (value: boolean) => void }) {
  const [isChecked, setIsChecked] = useState(checked)

  useEffect(() => {
    onChange?.(isChecked)
  }, [isChecked])

  const BlackBox = () => <div className="h-8 w-8 bg-black dark:bg-white rounded" />

  return (
    <button
      onClick={() => setIsChecked(!isChecked)}
      type="button"
      className="border border-black dark:border-white rounded flex w-[110px] justify-between py-2 px-3 items-center dark:text-white"
    >
      <div>{isChecked ? <BlackBox /> : <p>ON</p>}</div>
      <div>{!isChecked ? <BlackBox /> : <p>OFF</p>}</div>
    </button>
  )
}
