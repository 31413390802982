import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import { Descendant } from 'slate'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { FAQSection, FAQSectionItem } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

interface FaqItemProps {
  faq: {
    title: Descendant[]
    description: Descendant[]
  }
}

function FAQItem({ faq }: FaqItemProps) {
  const [active, setActive] = useState(false)
  const toggle = () => {
    setActive(!active)
  }
  return (
    <div className="flex flex-col m-auto shadow-xl mt-3 p-4">
      <h2 className="flex items-center  justify-between">
        <h1 className=" text-center md:text-3xl capitalize py-2 font-semibold">
          <SectionSlatePreview initialValue={faq?.title} previewMode />
        </h1>
        <button
          className="py-6 md:text-2xl text-gray-600 box-border appearance-none cursor-pointer focus:outline-none flex items-center justify-between"
          onClick={toggle}
        >
          {active ? <AiOutlineMinus /> : <AiOutlinePlus />}
        </button>
      </h2>
      <AnimatePresence>
        {active && (
          <motion.div
            initial="collapsed"
            animate="open"
            whileTap="open"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            className="md:text-2xl text-sm px-2 overflow-auto bg-gray-100 border-2 border-gray-50 py-12 transition-max-height duration-700 ease-in-out"
          >
            <div className="px-2">
              <SectionSlatePreview initialValue={faq?.description} previewMode />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default function VariantFour({ section }: { section: FAQSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const faqs = section.variables.find(variable => variable.name === 'ITEMS')?.data as FAQSectionItem[]

  return (
    <>
      <div
        className="py-6 gap-y-12 p-5 md:w-[60%] m-auto"
        style={{
          ...generateSectionBackground({
            defaultColor: 'white',
            backgroundSettings: section?.settings?.color?.background,
            image: null,
          }),
          ...generateSectionSpacing(section?.settings?.spacing),
        }}
      >
        <h1 className="text-center md:text-4xl text-2xl text-black font-semibold">
          <SectionSlatePreview initialValue={title} previewMode />{' '}
        </h1>
        {faqs?.map((faq, idx) => (
          <FAQItem faq={faq} key={idx} />
        ))}
      </div>
    </>
  )
}
