import BottomFloatingButton from './BottomFloatingButton'

type PreviewSaveButtonProps = {
  onPreviewClick?: () => void
  onSaveClick?: () => void
}

export default function PreviewSaveButton({ onPreviewClick, onSaveClick }: PreviewSaveButtonProps) {
  return (
    <>
      <div className="lg:hidden">
        <BottomFloatingButton
          label="Preview"
          onClick={onPreviewClick}
          secondaryLabel="Save"
          onSecondaryClick={onSaveClick}
        />
      </div>

      <div className="hidden lg:block">
        <BottomFloatingButton label="Save" onClick={onSaveClick} />
      </div>
    </>
  )
}
