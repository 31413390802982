import { useContext, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import ImageCreator from 'src/icons/ImageCreator'
import { upsertBlockByPageID } from 'src/services/website.service'
import {
  ImageOmgItemsGroup,
  ImageOmgSection,
  ImageOmgVariables,
  SectionVariableCategories,
} from 'src/_polly/components/src/sections'
import { v4 as uuid } from 'uuid'
import BottomFloatingButton from '../BottomFloatingButton'
import { WebsiteContext } from '../context/WebsiteContext'
import { AdvancedFeature } from '../modal/advanced'
import { MenuSaveButton } from '../modal/section/NewMenu/NewMenu'
import { EditSection } from './EditSectionModal'
import ModalDialogV2 from './ModalDialogV2'
import SectionMediaField from './SectionField/SectionMediaField'
import SectionTextField from './SectionField/SectionTextField'
// import TransitionSpeed from './SectionField/TransitionSpeed'

interface EditSectionModelModalProps extends EditSection {
  section: ImageOmgSection
}

type EditItemProps = {
  data?: ImageOmgItemsGroup
  onSave: (data: Partial<ImageOmgItemsGroup>) => void
}

type FormInput = {
  title: string
  subtitle: string
}
function EditItem({ data, onSave }: EditItemProps) {
  const { register, handleSubmit, watch } = useForm<FormInput>()
  const onSubmit: SubmitHandler<FormInput> = value => {
    onSave({
      ...value,
      id: data?.id,
    })
  }

  const title = watch('title')
  const subtitle = watch('subtitle')
  // const description = watch('description')

  return (
    <div className="px-4 dark:text-white">
      <form className="pb-24" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-8">
          <div>
            <h2 className="uppercase font-medium text-sm">Title</h2>
            <input
              className="border-b border-black dark:border-white dark:bg-black w-full py-2 outline-none mt-1"
              type="text"
              defaultValue={data?.title}
              {...register('title')}
            />
          </div>
          <div>
            <h2 className="uppercase font-medium text-sm">Subtitle</h2>
            <input
              className="border-b border-black dark:border-white dark:bg-black w-full py-2 outline-none mt-1"
              type="text"
              defaultValue={data?.subtitle}
              {...register('subtitle')}
            />
          </div>
          {/* <div>
            <h2 className="uppercase font-medium text-sm">Description</h2>
            <textarea
              className="border-b border-black w-full py-2 outline-none mt-1"
              value={data?.description}
              rows={4}
              {...register('description')}
            />
          </div> */}
        </div>
      </form>

      <BottomFloatingButton
        label={data?.id ? 'Update' : 'Add'}
        onClick={handleSubmit(onSubmit)}
        disabled={!title || !subtitle}
      />
    </div>
  )
}

export default function EditSectionImageOmg({
  open,
  section: defaultValue,
  onClose: onBack,
  onUpdate,
}: EditSectionModelModalProps) {
  const websiteContext = useContext(WebsiteContext)
  if (!open || !defaultValue) return null
  const [section, setSection] = useState(defaultValue)
  const [updating, setUpdating] = useState(false)
  const [editItem, setEditItem] = useState<ImageOmgItemsGroup>()
  const [showAddItemModel, setShowAddItemModel] = useState(false)
  const [fireUpdate, setFireUpdate] = useState({
    fire: false,
    goBack: false,
  })

  const title = section.variables.find(variable => variable.name === 'TITLE')
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')
  const media = section.variables.find(variable => variable.name === 'MEDIA')
  const items = section.variables.find(variable => variable.name === 'ITEMS')

  const updateVariableData = (name: ImageOmgVariables, category: SectionVariableCategories, data: any) => {
    const current = section.variables.find(variable => variable.name === name)
    if (!current) {
      setSection({
        ...section,
        variables: [...section.variables, { name, data, category, id: uuid() }],
      })
      return
    }
    const otherVariables = section.variables.filter(variable => variable.name !== name)
    const newVariables = [...otherVariables, { ...current, data }]
    setSection({ ...section, variables: newVariables })
  }

  const handleEditAdd = (data: Partial<ImageOmgItemsGroup>) => {
    if (data.id) {
      const newItems = items?.data.map((item: ImageOmgItemsGroup) => {
        if (item.id === data.id) {
          return { ...item, ...data }
        }
        return item
      })
      setSection({
        ...section,
        variables: section.variables.map(variable => {
          if (variable.name === 'ITEMS') {
            return { ...variable, data: newItems }
          }
          return variable
        }),
      })
    } else {
      setSection({
        ...section,
        variables: section.variables.map(variable => {
          if (variable.name === 'ITEMS') {
            return { ...variable, data: [...variable.data, data] }
          }
          return variable
        }),
      })
    }

    setEditItem(undefined)
    setShowAddItemModel(false)
    handleSave(false)
  }

  const handleSave = async (goBack = true) => {
    setFireUpdate({ fire: true, goBack })
  }

  useEffect(() => {
    const update = async (goBack = true) => {
      setUpdating(true)
      await upsertBlockByPageID(websiteContext.pageID, section as any)
      setUpdating(false)
      setFireUpdate({ fire: false, goBack: false })
      if (goBack) onBack()
    }
    if (fireUpdate.fire) update(fireUpdate.goBack)
  }, [fireUpdate, section])

  useEffect(() => {
    onUpdate?.(section)
  }, [section])

  return (
    <>
      <ModalDialogV2 {...{ open, onBack }}>
        <div className="px-5  dark:text-white mt-[25px]">
          <div className="border-t-[1px] border-black dark:border-white ">
            <SectionTextField
              initialValue={title?.data ?? []}
              onChange={value => updateVariableData('TITLE', 'TEXT', value)}
              onUpdate={() => handleSave(false)}
            />
            {section.variant !== 'VARIANT_4' && (
              <SectionTextField
                title="Subtitle"
                initialValue={subTitle?.data ?? []}
                onChange={value => updateVariableData('SUBTITLE', 'TEXT', value)}
                onUpdate={() => handleSave(false)}
              />
            )}
            {(section.variant === 'VARIANT_15' ||
              section.variant === 'VARIANT_4' ||
              section.variant === 'VARIANT_7' ||
              section.variant === 'VARIANT_9') && (
              <SectionTextField
                title="Paragraph"
                initialValue={paragraph?.data ?? []}
                isParagraph
                onChange={value => updateVariableData('PARAGRAPH', 'TEXT', value)}
                onUpdate={() => handleSave(false)}
              />
            )}

            <SectionMediaField
              defaultValue={media?.data}
              onChange={url => {
                if (!url) {
                  setSection({
                    ...section,
                    variables: section.variables.filter(variable => variable.name !== 'MEDIA'),
                  })
                  return
                }
                updateVariableData('MEDIA', 'IMAGE', url)
                handleSave(false)
              }}
            />
          </div>
          <div className="absolute top-[21px] left-[50%] translate-x-[-50%]">
            {/* <Omg width="auto" height={28} /> */}
            <div className="flex justify-center items-center space-x-2">
              <ImageCreator height={28} width="auto" />
              <span className="dark:text-white text-[24px]">OMG</span>
            </div>
          </div>
          <div className="absolute top-[21px] right-[50px]">
            <button type="button" onClick={() => handleSave()} disabled={updating}>
              <MenuSaveButton save="save" />
            </button>
          </div>
          {updating && (
            <div className="fixed z-30 inset-0 lg:max-w-[500px] dark:bg-black/80 bg-white/80">
              <p className="flex items-center h-full justify-center w-full text-[19px] dark:text-white">Updating...</p>
            </div>
          )}

          <AdvancedFeature
            onBack={() => handleSave(false)}
            section={section}
            onChange={settings => setSection(s => ({ ...s, settings }))}
          />
        </div>
      </ModalDialogV2>

      <ModalDialogV2
        open={editItem !== undefined || showAddItemModel}
        onBack={() => (editItem ? setEditItem(undefined) : setShowAddItemModel(false))}
      >
        <EditItem data={editItem} onSave={handleEditAdd} />
      </ModalDialogV2>
    </>
  )
}
