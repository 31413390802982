import SectionSlate from '../../../common/SectionSlatePreview'
import { QuoteSection as QuoteSectionType } from '../../types'
import clsx from 'clsx'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

interface VariantOneProps {
  section: QuoteSectionType
}

export default function VariantFour({ section }: VariantOneProps) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="md:container md:mx-auto px-4 md:px-12">
        <div className="flex flex-col sm:flex-row md:items-center md:justify-center gap-2 overflow-hidden">
          <div className="lg:w-1/2">
            <img className={clsx('object-cover lg:w-full mb-5 bg-center overflow-hidden')} src={media} alt="" />
          </div>
          <div className="bg-[#EEEEEE] lg:p-8 p-2 -mt-16 md:mt-0 rounded text-start lg:w-2/5  lg:-ml-28">
            <p className="text-gray-700 pb-4 md:text-3xl text-xl font-bold">
              <SectionSlate initialValue={title} previewMode />
            </p>
            <p className="text-gray-700 md:text-xl text-[14px] font-semibold">
              - <SectionSlate initialValue={subTitle} previewMode />
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
