import { Template } from '.'
import { contactUs, faq, gallery, image, love, text, video } from './variants'

export const Template05: Template = {
  id: '05',
  pages: [
    {
      slug: '/',
      name: 'Home',
      blocks: [
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_8')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Exploring real healthy life' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'a8fd70f9-92e7-4b5b-8b2b-9c3c4a364cdd',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    { color: '#58d53f', className: 'uppercase', text: 'Green ' },
                    { color: '#ffffff', className: 'uppercase', text: 'Life' },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '59456b9c-1a44-48e6-a126-915f49c11f2a',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_8',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_1')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ color: '#ffffff', text: 'About Us' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'd87a9563-fa11-4ee8-8ece-4a6e723c5c74',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ color: '#e3e3e3', text: 'Cash Flow Positive UX Design for Investors', bold: true }],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '77394393-0164-40fe-80aa-7426aad656cb',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#cccccc',
                      text: 'We are a team of experienced entrepreneurs and investors dedicated to acquiring and growing innovative businesses. Our approach is rooted in the latest industry trends and our commitment to long-term success. With our expertise and network, we aim to bring Cheugy-approved businesses to the forefront of the market.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '2dd1fbf8-2ab5-4596-bd63-d28425a78a48',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          type: 'TEXT',
        },
        {
          settings: {
            ...gallery.find(i => i.variant === 'VARIANT_4')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1601985705806-5b9a71f6004f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxwbGFudHN8ZW58MHx8fHwxNjc3OTk0MjQx&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [{ children: [{ text: 'Slay Your Startup' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1597305877032-0668b3c6413a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxwbGFudHxlbnwwfHx8fDE2Nzc5OTQyODI&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [{ children: [{ text: 'Slay Your Startup' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1501004318641-b39e6451bec6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxwbGFudHxlbnwwfHx8fDE2Nzc5OTQyODI&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [{ children: [{ text: 'Slay Your Startup' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1520412099551-62b6bafeb5bb?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw2fHxwbGFudHxlbnwwfHx8fDE2Nzc5OTQyODI&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [{ children: [{ text: 'Slay Your Startup' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1536882240095-0379873feb4e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw1fHxwbGFudHxlbnwwfHx8fDE2Nzc5OTQyODI&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [{ children: [{ text: 'Slay Your Startup' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1459156212016-c812468e2115?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxMHx8cGxhbnR8ZW58MHx8fHwxNjc3OTk0Mjgy&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [{ children: [{ text: 'Slay Your Startup' }], type: 'paragraph' }],
                },
              ],
              name: 'MEDIA',
              id: '2ae8d01c-5ddc-4cf6-9506-2fe452563d99',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_4',
          type: 'GALLERY',
        },
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_7')?.settings,
            color: { background: { value: '#43ad44', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  children: [{ fontFamily: 'Abril Fatface', text: 'Captivating UX for Investors', bold: true }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '232c97fb-ddc1-436b-97f9-ca39b9989e08',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience? Look no further than our custom site and portfolio design services ',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '79ede000-a628-4165-88dc-d9f2f92f5c21',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Captivating UX for Startups' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '620893ab-aa85-4bf3-99b3-62a8ac5aceaa',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_7',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...faq.find(i => i.variant === 'VARIANT_1')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1601985705806-5b9a71f6004f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxwbGFudHN8ZW58MHx8fHwxNjgxMzAwMTA3&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [
                    { children: [{ color: '#ffffff', text: 'UX Design for Crowdfunding Success' }], type: 'paragraph' },
                  ],
                  id: '1',
                  title: [{ children: [{ color: '#ffffff', text: 'Dope Burn' }], type: 'paragraph' }],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1613858749733-3a3e456e3d9e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw1fHxwbGFudHMlMjBjYXJpbmd8ZW58MHx8fHwxNjgxMzAwMTM0&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [
                    { children: [{ color: '#ffffff', text: 'UX Design for Crowdfunding Success' }], type: 'paragraph' },
                  ],
                  id: '2',
                  title: [{ children: [{ color: '#fffefe', text: 'Dope Burn' }], type: 'paragraph' }],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1596364725424-7673f05f64b1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxjYXJpbmclMjBwbGFudHN8ZW58MHx8fHwxNjgxMzAwMTYz&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [
                    { children: [{ color: '#ffffff', text: 'UX Design for Crowdfunding Success' }], type: 'paragraph' },
                  ],
                  id: '3',
                  title: [{ children: [{ color: '#ffffff', text: 'Dope Burn' }], type: 'paragraph' }],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1459156212016-c812468e2115?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxMHx8cGxhbnR8ZW58MHx8fHwxNjgxMzAwMTk2&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [
                    { children: [{ color: '#ffffff', text: 'UX Design for Crowdfunding Success' }], type: 'paragraph' },
                  ],
                  id: '4',
                  title: [{ children: [{ color: '#ffffff', text: 'Dope Burn' }], type: 'paragraph' }],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
              ],
              name: 'ITEMS',
              id: '123',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ color: '#ffffff', text: 'UX Design for Crowdfunding Success' }], type: 'paragraph' },
              ],
              name: 'SUBTITLE',
              id: '737c06e7-55c1-46d0-8c88-ae79c8521410',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#ffffff', text: 'What We Do ?' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'dfb13c27-37d1-41cb-b40e-3a821700ceeb',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: '' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: '3de3c324-5da4-428a-9536-116afc4b4c9e',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          type: 'FAQ',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1531875456634-3f5418280d20?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw3fHxwbGFudHN8ZW58MHx8fHwxNjc3OTk0MjQx&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ color: '#ffffff', text: 'Green Energy Acceleration' }], type: 'paragraph' }],
                  description: [{ children: [{ color: '#ffffff', text: '15$' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1501004318641-b39e6451bec6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxwbGFudHxlbnwwfHx8fDE2Nzc5OTQyODI&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ color: '#ffffff', text: 'Green Energy Acceleration' }], type: 'paragraph' }],
                  description: [{ children: [{ color: '#ffffff', text: ' 20$-25$' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1536882240095-0379873feb4e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw1fHxwbGFudHxlbnwwfHx8fDE2Nzc5OTQyODI&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ color: '#ffffff', text: 'Green Energy Acceleration' }], type: 'paragraph' }],
                  description: [{ children: [{ color: '#ffffff', text: '30$' }], type: 'paragraph' }],
                },
              ],
              name: 'MEDIAGROUP',
              id: 'fcc14f65-936e-466f-9771-2e54c12c7841',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Abril Fatface', color: '#ffffff', text: 'Trending Plants' }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '95dc1825-0331-4280-b567-b4b214b48f33',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'TEXT',
        },
        {
          settings: {
            ...image.find(i => i.variant === 'VARIANT_3')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [
                { children: [{ color: '#efeaea', text: 'Customer with our Plant' }], type: 'paragraph' },
                { children: [{ color: '#efeaea', text: '' }], type: 'paragraph' },
              ],
              name: 'TITLE',
              id: '1d2e209b-c361-46b5-94f8-a397244ee583',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1498758536662-35b82cd15e29?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxMHx8Y3VzdG9tZXIlMjB3aXRoJTIwcGxhbnR8ZW58MHx8fHwxNjc4MDA2NDI5&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '466e101d-1c00-458b-96a7-95af757f26ac',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ color: '#fdfdfd', text: 'Gucci Acquisition Success' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '375af7af-daa7-468e-9275-a688b8753668',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_3',
          type: 'IMAGE_OMG',
        },
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_7')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#39a72d', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  children: [{ fontFamily: 'Libre Baskerville', text: 'Gucci Acquisition Success' }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: 'b76d9835-a0f1-43b2-bf3f-c0b7b341d270',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: '' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '3768004f-8e07-4947-b875-f10737b7415b',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'For startups and those building their first site, our web design and UX design services provide the expertise you need to create an intuitive, user-centered experience.We pride ourselves on streamlined user experience, clean design, and modern layouts that include an interactive user interface and easy-to-use interface.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'fb98774d-4a7d-4237-9a2c-f0777212e7c7',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_7',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...video.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
              name: 'MEDIA',
              id: '655c18cc-bffa-467f-ba93-a547060c4013',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1615921511258-0aa98c84d400?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTY1fHxsb2dvfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
                  subtitle1: [{ children: [{ text: 'Tech wid, Bd' }], type: 'paragraph' }],
                  title1: [{ children: [{ text: 'Testimonial' }], type: 'paragraph' }],
                  description1: [
                    {
                      children: [
                        {
                          text: 'Pastry shortbread muffin sesame snaps icing marzipan. Brownie sesame snaps candy canes chocolate cake donut bonbon powder cotton candy. Jelly sesame snaps gummies pudding cotton candy. Sesame snaps danish muffin sweet roll cheesecake cake wafer pastry. Icing fruitcake cookie cupcake cake muffin cheesecake bonbon pie. Wafer marshmallow gummi bears wafer halvah. Croissant jelly-o croissant gummi bears cake. Gingerbread icing dessert toffee lemon drops sweet roll jelly beans.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1554730501-8dd4db2b18cc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nzd8fGxvZ298ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
                  subtitle1: [{ children: [{ text: 'Tech wid, Bd' }], type: 'paragraph' }],
                  title1: [{ children: [{ text: 'Testimonial' }], type: 'paragraph' }],
                  description1: [
                    {
                      children: [
                        {
                          text: 'Pastry shortbread muffin sesame snaps icing marzipan. Brownie sesame snaps candy canes chocolate cake donut bonbon powder cotton candy. Jelly sesame snaps gummies pudding cotton candy. Sesame snaps danish muffin sweet roll cheesecake cake wafer pastry. Icing fruitcake cookie cupcake cake muffin cheesecake bonbon pie. Wafer marshmallow gummi bears wafer halvah. Croissant jelly-o croissant gummi bears cake. Gingerbread icing dessert toffee lemon drops sweet roll jelly beans.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1579509330413-8a7e4addc442?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OTZ8fGxvZ298ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
                  subtitle1: [{ children: [{ text: 'Tech wid, Bd' }], type: 'paragraph' }],
                  title1: [{ children: [{ text: 'Testimonial' }], type: 'paragraph' }],
                  description1: [
                    {
                      children: [
                        {
                          text: 'Pastry shortbread muffin sesame snaps icing marzipan. Brownie sesame snaps candy canes chocolate cake donut bonbon powder cotton candy. Jelly sesame snaps gummies pudding cotton candy. Sesame snaps danish muffin sweet roll cheesecake cake wafer pastry. Icing fruitcake cookie cupcake cake muffin cheesecake bonbon pie. Wafer marshmallow gummi bears wafer halvah. Croissant jelly-o croissant gummi bears cake. Gingerbread icing dessert toffee lemon drops sweet roll jelly beans.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
              ],
              name: 'ITEMS',
              id: 'ce87f46c-c600-4e1d-8461-5d43689ac247',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ text: 'We believe that health and wellness are essential components of a happy life' }],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'f09a1e21-2510-4aba-9fc8-0161de577f64',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Some of our unique Plants' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'd60ac966-1a8d-4dbb-a923-ff3337236fcd',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'TESTIMONIAL',
        },
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_6')?.settings,
            color: { background: { value: '#37b027', enabled: true } },
          },
          variables: [
            {
              data: [{ name: 'Email', type: 'email', error: 'email is required', placeholder: 'example@gmail.com' }],
              name: 'INPUTS',
              id: '2c984adc-618c-4f42-9062-680114757aab',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Lobster', color: '#ffffff', text: 'Enter Your Email', bold: true }],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '453d682c-05b6-4b38-8f32-7ec98cf0ca4e',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#ffffff',
                      text: 'We aim to bring approved businesses to the forefront of the market',
                      bold: true,
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '6c107fde-d241-4b14-8c0f-784377566190',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#1f974d',
                  backgroundColor: '#ffffff',
                  borderRadius: '0px',
                  color: '#166f12',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  bold: true,
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'send',
              },
              name: 'BUTTON_1',
              id: '353f72ff-9f3a-4496-99d9-bbd196133f38',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_6',
          type: 'CONTACT_US',
        },
      ],
    },
    {
      slug: '/gallery',
      name: 'Gallery',
      blocks: [
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_5')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Discover More' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'febf72ec-6536-48f2-b7e1-7aba875f0519',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: 'cae117d8-0742-492b-baed-556bee2938f0',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1521052313888-1181c2f5f406?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw1fHxwbGFudHMlMjBibGFja3xlbnwwfHx8fDE2NzgwMTM5NTY&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: 'fe60de9e-8b52-458d-8abc-f4eb068b8ac4',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      fontFamily: 'Anton',
                      color: '#ffffff',
                      className: 'uppercase',
                      text: 'Enjoy your Eyes  with Our Gallery ',
                      bold: true,
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: 'caee271c-80ce-4637-83ac-3bae6660c2b6',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#000',
                  backgroundColor: '#3fd84f',
                  borderRadius: '0px',
                  color: '#ffffff',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  bold: true,
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'more',
              },
              name: 'BUTTON_1',
              id: 'aef8da3e-c9da-498e-9ece-4f0ebbaa6d23',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_5',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1509937528035-ad76254b0356?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxVbmlxdWUlMjBwbGFudHN8ZW58MHx8fHwxNjc4MDE0MzIz&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ color: '#ffffff', text: 'Dope Burn Rate' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1459433312032-29eb4bea7d3b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxwYWxudHN8ZW58MHx8fHwxNjc4MDA2ODUw&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ color: '#ffffff', text: 'Dope Burn Rate' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1590733122896-8fafc885d3b3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxVbmlxdWV8ZW58MHx8fHwxNjc4MDE0NDA3&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ color: '#ffffff', text: 'Dope Burn Rate' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                },
              ],
              name: 'MEDIAGROUP',
              id: '62923eba-53af-45ae-826e-6b7eae4cbc65',
              category: 'IMAGE',
            },
            {
              data: [
                { children: [{ fontFamily: 'Anton', color: '#ffffff', text: '', bold: true }], type: 'paragraph' },
                {
                  children: [{ fontFamily: 'Anton', color: '#ffffff', bold: true, text: 'Our Unique Gallery' }],
                  type: 'paragraph',
                },
                { children: [{ fontFamily: 'Anton', color: '#ffffff', bold: true, text: '' }], type: 'paragraph' },
              ],
              name: 'TITLE',
              id: 'f209aee5-9f0a-4c6e-972e-271209905db0',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'TEXT',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_1')?.settings,
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: '' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: 'a005a31b-3fd0-40a9-a2f7-80847348a1da',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#ffffff', text: 'Accelerate Appeal' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'eb1d1322-7747-499f-9a89-f697ad3b5606',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#ffffff',
                      text: 'we can help you raise the funds you need to take your business to the next level',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'f1db18e0-a2d0-47ce-a280-e11aec452413',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          type: 'TEXT',
        },
        {
          settings: {
            ...gallery.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 0, left: 30, bottom: 30, right: 30 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1606041008023-472dfb5e530f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=388&q=80',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1589458456444-f7158a7e8a4f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw4fHxyb3Nlc3xlbnwwfHx8fDE2Nzg3MTAxNDc&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1589035088760-fcba25b41cbd?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHx1bmlxdWUlMjBmbG93ZXJ8ZW58MHx8fHwxNjc4MDE1MTA1&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1582794543139-8ac9cb0f7b11?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxyb3Nlc3xlbnwwfHx8fDE2Nzg3MTAxNDc&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '4',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1562690868-60bbe7293e94?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxMHx8cm9zZXxlbnwwfHx8fDE2Nzg3MTAwOTQ&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '5',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1496062031456-07b8f162a322?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxyb3NlfGVufDB8fHx8MTY3ODcxMDA5NA&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '6',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1495231916356-a86217efff12?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=436&q=80',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '7',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1439122955805-cbac26b73f3b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '8',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
              ],
              name: 'MEDIA',
              id: '123',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'GALLERY',
        },
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#1d9010', enabled: true } },
          },
          variables: [
            {
              data: [{ name: 'Email', type: 'email', error: 'email is required', placeholder: 'example@gmail.com' }],
              name: 'INPUTS',
              id: 'e217fd64-2e92-4752-9586-2796de39f8d9',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Anton', color: '#ffffff', bold: true, text: 'Contact with us' }],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'f18c2af0-0be9-4cdd-b122-a4451cb0af3c',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#ffffff',
                      text: 'we can help you raise the funds you need to take your business to the next level',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '7a6a9cbe-e853-40cf-89c5-aeabe64ba8aa',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#ffffff',
                  backgroundColor: '#ffffff',
                  borderRadius: '0px',
                  color: '#1ca224',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'send',
              },
              name: 'BUTTON_1',
              id: '2122611f-1bc2-44f1-9c42-bab8a0335a34',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_6',
          type: 'CONTACT_US',
        },
      ],
    },
    {
      slug: '/contact-us',
      name: 'Contact US',
      blocks: [
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_6')?.settings,

            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#010000', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  name: 'Email',
                  type: 'email',
                  placeholder: 'example@gmail.com',
                  error: 'email is required',
                },
              ],
              name: 'INPUTS',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#ffffff',
                      text: 'Our aesthetic design includes minimalist design, compelling visuals, visually stunning custom graphics, and attractive typography, all of which are strategically placed using visual hierarchy for an attention-grabbing design that keeps users engaged and coming back for more.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#ffffff', text: 'Send a message', bold: true }], type: 'paragraph' }],
              name: 'SUBTITLE',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#000',
                  backgroundColor: '#49c43a',
                  borderRadius: '0px',
                  color: '#000',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'send',
              },
              name: 'BUTTON_1',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_6',
          type: 'CONTACT_US',
        },
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: {
                address: '1600 Amphitheatre Parkway, Mountain View, CA',
                position: { lng: -122.0842499, lat: 37.4224764 },
              },
              name: 'MAP',
              category: 'TEXT',
            },
            {
              data: [
                {
                  name: [{ children: [{ color: '#ffffff', text: 'Hours' }], type: 'paragraph' }],
                  description: [{ children: [{ color: '#ffffff', text: 'monday-friday' }], type: 'paragraph' }],
                },
                {
                  name: [{ children: [{ color: '#ffffff', text: 'Phone' }], type: 'paragraph' }],
                  description: [{ children: [{ color: '#ffffff', text: '(555) 555-5555' }], type: 'paragraph' }],
                },
              ],
              name: 'CONTRACT',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#ffffff', text: 'contact us' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    { color: '#ffffff', text: 'Our aesthetic design includes minimalist design, compelling visuals.' },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'CONTACT_US',
        },
      ],
    },
  ],
  newMenu: [
    { name: 'Home', type: 'internal', url: '/' },
    { name: 'Gallery', type: 'internal', url: '/gallery' },
    { name: 'Contact us', type: 'internal', url: '/contact-us' },
  ],
  newMenuConfig: {
    links: [
      {
        name: 'Home',
        type: 'internal',
        url: '/',
      },
      {
        name: 'Contact us',
        type: 'email',
        url: 'hello@example.com',
      },
    ],
    id: 'VARIANT_17',
    tags: [],
  },
  newFooter: {
    id: 'VARIANT_2',
    designedBy: 'Plly Staff',
    settings: {
      backgroundColor: { color: '#000000', enabled: true },
      spacing: {
        padding: { top: 30, left: 0, bottom: 30, right: 0 },
        margin: { top: 0, left: 0, bottom: 0, right: 0 },
        enabled: true,
      },
      textColor: { color: '#ffffff', enabled: true },
    },
    categories: [
      {
        name: 'Explore',
        links: [{ name: 'Home', type: 'internal', url: '/' }],
      },
    ],
    tags: [],
  },
  links: [],
}
