import { useState } from 'react'
import { Triangle } from 'react-loader-spinner'
import { NewFooter } from 'src/_polly/components/src/footer/footer'

export default function FooterIframe({ footer, website }: { footer: NewFooter; website: any }) {
  const [loaded, setLoaded] = useState(false)
  return (
    <div className="w-full max-h-[300px] h-[300px] relative">
      {!loaded && (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
          <Triangle height="80" width="80" color="#000" ariaLabel="triangle-loading" wrapperStyle={{}} visible />
        </div>
      )}
      <iframe
        key={footer.id}
        src={`/footer-preview/?footer=${window.btoa(JSON.stringify(footer))}&website=${window.btoa(
          JSON.stringify(website),
        )}`}
        frameBorder="0"
        title="Plly Preview"
        className="w-full max-h-[500px] h-[300px]"
        onLoad={() => setLoaded(true)}
      />
    </div>
  )
}
