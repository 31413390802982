import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { FAQSection, FAQSectionItem } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

function VariantSeven({ section }: { section: FAQSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const faqs = section.variables.find(variable => variable.name === 'ITEMS')?.data as FAQSectionItem[]
  return (
    <>
      <div
        className="py-4 md:py-12"
        style={{
          ...generateSectionBackground({
            defaultColor: 'white',
            backgroundSettings: section?.settings?.color?.background,
            image: null,
          }),
          ...generateSectionSpacing(section?.settings?.spacing),
        }}
      >
        <div className="px-4 md:px-12 lg:px-20">
          <div className="border-b border-[#262525]">
            <h4 className="text-[#262525] text-[16px] font-neuton mb-2 uppercase">
              <SectionSlatePreview initialValue={title} previewMode />
            </h4>
          </div>
          <div>
            {faqs?.map(feature => (
              <div
                key={feature?.id}
                className="flex justify-between md:grid md:grid-cols-[20%_40%_20%_20%] border-b border-[#262525] items-center py-2"
              >
                <h4 className="hidden md:block text-[#262525] text-[30px] font-neue opacity-80 ">
                  <SectionSlatePreview initialValue={feature?.title} previewMode />
                </h4>
                <p className="hidden md:block text-[#262525] text-[24px] font-neuton opacity-70">
                  <SectionSlatePreview initialValue={feature?.description} previewMode />
                </p>
                <div className="md:hidden">
                  <h4 className="text-[#262525] text-[20px] leading-none font-neue opacity-80 ">
                    <SectionSlatePreview initialValue={feature?.title} previewMode />
                  </h4>
                  <p className="text-[#262525] text-[13px] font-neuton opacity-70">
                    <SectionSlatePreview initialValue={feature?.description} previewMode />
                  </p>
                </div>
                <p className="flex items-center">
                  <p className="text-[#262525] text-[18px] md:text-[24px]  font-neuton opacity-70">
                    <SectionSlatePreview initialValue={feature?.items} previewMode />
                  </p>
                </p>
                <p className="hidden md:block text-[#262525] text-[24px] font-neuton opacity-70 text-end">
                  <SectionSlatePreview initialValue={feature?.year} previewMode />
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
export default VariantSeven
