/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserProfile = /* GraphQL */ `
  query GetUserProfile($id: ID!) {
    getUserProfile(id: $id) {
      id
      firstName
      lastName
      email
      profilePics {
        bucket
        region
        key
      }
      username
      profilePicture
      country
      state
      city
      bio
      config
      createdAt
      updatedAt
    }
  }
`;
export const listUserProfiles = /* GraphQL */ `
  query ListUserProfiles(
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByUsername = /* GraphQL */ `
  query UserByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWebsite = /* GraphQL */ `
  query GetWebsite($id: ID!) {
    getWebsite(id: $id) {
      id
      name
      config
      userID
      createdAt
      menu
      footer
      pages
      links
      newMenu
      newMenuConfig
      newFooter
      isPostedOnSocial
      cloudfrontDistributionID
      cloudfrontDomainName
      cloudfrontAlias
      gaTrackingID
      updatedAt
    }
  }
`;
export const listWebsites = /* GraphQL */ `
  query ListWebsites(
    $filter: ModelWebsiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWebsites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        config
        userID
        createdAt
        menu
        footer
        pages
        links
        newMenu
        newMenuConfig
        newFooter
        isPostedOnSocial
        cloudfrontDistributionID
        cloudfrontDomainName
        cloudfrontAlias
        gaTrackingID
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWebsiteByUserID = /* GraphQL */ `
  query GetWebsiteByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWebsiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getWebsiteByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        config
        userID
        createdAt
        menu
        footer
        pages
        links
        newMenu
        newMenuConfig
        newFooter
        isPostedOnSocial
        cloudfrontDistributionID
        cloudfrontDomainName
        cloudfrontAlias
        gaTrackingID
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWebsiteByCloudfrontAlias = /* GraphQL */ `
  query GetWebsiteByCloudfrontAlias(
    $cloudfrontAlias: String
    $sortDirection: ModelSortDirection
    $filter: ModelWebsiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getWebsiteByCloudfrontAlias(
      cloudfrontAlias: $cloudfrontAlias
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        config
        userID
        createdAt
        menu
        footer
        pages
        links
        newMenu
        newMenuConfig
        newFooter
        isPostedOnSocial
        cloudfrontDistributionID
        cloudfrontDomainName
        cloudfrontAlias
        gaTrackingID
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBuildRequest = /* GraphQL */ `
  query GetBuildRequest($id: ID!) {
    getBuildRequest(id: $id) {
      id
      status
      websiteID
      createdAt
      userID
      cloudfrontInvalidationID
      updatedAt
    }
  }
`;
export const listBuildRequests = /* GraphQL */ `
  query ListBuildRequests(
    $filter: ModelBuildRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBuildRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        websiteID
        createdAt
        userID
        cloudfrontInvalidationID
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBuildRequestByWebsiteID = /* GraphQL */ `
  query GetBuildRequestByWebsiteID(
    $websiteID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBuildRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getBuildRequestByWebsiteID(
      websiteID: $websiteID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        websiteID
        createdAt
        userID
        cloudfrontInvalidationID
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPage = /* GraphQL */ `
  query GetPage($id: ID!) {
    getPage(id: $id) {
      id
      name
      path
      websiteID
      blocks
      createdAt
      userID
      updatedAt
    }
  }
`;
export const listPages = /* GraphQL */ `
  query ListPages(
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        path
        websiteID
        blocks
        createdAt
        userID
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPageByWebsiteID = /* GraphQL */ `
  query GetPageByWebsiteID(
    $websiteID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPageByWebsiteID(
      websiteID: $websiteID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        path
        websiteID
        blocks
        createdAt
        userID
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBlock = /* GraphQL */ `
  query GetBlock($id: ID!) {
    getBlock(id: $id) {
      id
      type
      props
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listBlocks = /* GraphQL */ `
  query ListBlocks(
    $filter: ModelBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        props
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getMintTransaction = /* GraphQL */ `
  query GetMintTransaction($id: ID!) {
    getMintTransaction(id: $id) {
      id
      transactionHash
      from
      userID
      blockID
      createdAt
      updatedAt
      block {
        id
        type
        props
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const listMintTransactions = /* GraphQL */ `
  query ListMintTransactions(
    $filter: ModelMintTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMintTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        transactionHash
        from
        userID
        blockID
        createdAt
        updatedAt
        block {
          id
          type
          props
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const getMintTransactionByUser = /* GraphQL */ `
  query GetMintTransactionByUser(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMintTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMintTransactionByUser(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        transactionHash
        from
        userID
        blockID
        createdAt
        updatedAt
        block {
          id
          type
          props
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      title
      description
      websiteID
      userID
      type
      images
      createdAt
      prompt
      status
      pageID
      componentID
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      page {
        id
        name
        path
        websiteID
        blocks
        createdAt
        userID
        updatedAt
      }
      owner
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        websiteID
        userID
        type
        images
        createdAt
        prompt
        status
        pageID
        componentID
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        page {
          id
          name
          path
          websiteID
          blocks
          createdAt
          userID
          updatedAt
        }
        owner
      }
      nextToken
    }
  }
`;
export const postsByUserId = /* GraphQL */ `
  query PostsByUserId(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByUserId(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        websiteID
        userID
        type
        images
        createdAt
        prompt
        status
        pageID
        componentID
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        page {
          id
          name
          path
          websiteID
          blocks
          createdAt
          userID
          updatedAt
        }
        owner
      }
      nextToken
    }
  }
`;
export const listByPostType = /* GraphQL */ `
  query ListByPostType(
    $type: PostType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listByPostType(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        websiteID
        userID
        type
        images
        createdAt
        prompt
        status
        pageID
        componentID
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        page {
          id
          name
          path
          websiteID
          blocks
          createdAt
          userID
          updatedAt
        }
        owner
      }
      nextToken
    }
  }
`;
export const listByPostStatus = /* GraphQL */ `
  query ListByPostStatus(
    $status: PostStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listByPostStatus(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        websiteID
        userID
        type
        images
        createdAt
        prompt
        status
        pageID
        componentID
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        page {
          id
          name
          path
          websiteID
          blocks
          createdAt
          userID
          updatedAt
        }
        owner
      }
      nextToken
    }
  }
`;
export const searchPosts = /* GraphQL */ `
  query SearchPosts(
    $filter: SearchablePostFilterInput
    $sort: SearchablePostSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPosts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        title
        description
        websiteID
        userID
        type
        images
        createdAt
        prompt
        status
        pageID
        componentID
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        page {
          id
          name
          path
          websiteID
          blocks
          createdAt
          userID
          updatedAt
        }
        owner
      }
      nextToken
      total
    }
  }
`;
export const getPostLike = /* GraphQL */ `
  query GetPostLike($id: ID!) {
    getPostLike(id: $id) {
      id
      userID
      postID
      like
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      post {
        id
        title
        description
        websiteID
        userID
        type
        images
        createdAt
        prompt
        status
        pageID
        componentID
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        page {
          id
          name
          path
          websiteID
          blocks
          createdAt
          userID
          updatedAt
        }
        owner
      }
      owner
    }
  }
`;
export const listPostLikes = /* GraphQL */ `
  query ListPostLikes(
    $filter: ModelPostLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        postID
        like
        createdAt
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        post {
          id
          title
          description
          websiteID
          userID
          type
          images
          createdAt
          prompt
          status
          pageID
          componentID
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const likesByUserId = /* GraphQL */ `
  query LikesByUserId(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPostLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likesByUserId(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        postID
        like
        createdAt
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        post {
          id
          title
          description
          websiteID
          userID
          type
          images
          createdAt
          prompt
          status
          pageID
          componentID
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const likesByPostId = /* GraphQL */ `
  query LikesByPostId(
    $postID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likesByPostId(
      postID: $postID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        postID
        like
        createdAt
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        post {
          id
          title
          description
          websiteID
          userID
          type
          images
          createdAt
          prompt
          status
          pageID
          componentID
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const getPostComment = /* GraphQL */ `
  query GetPostComment($id: ID!) {
    getPostComment(id: $id) {
      id
      userID
      postID
      content
      createdAt
      status
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      post {
        id
        title
        description
        websiteID
        userID
        type
        images
        createdAt
        prompt
        status
        pageID
        componentID
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        page {
          id
          name
          path
          websiteID
          blocks
          createdAt
          userID
          updatedAt
        }
        owner
      }
      owner
    }
  }
`;
export const listPostComments = /* GraphQL */ `
  query ListPostComments(
    $filter: ModelPostCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        postID
        content
        createdAt
        status
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        post {
          id
          title
          description
          websiteID
          userID
          type
          images
          createdAt
          prompt
          status
          pageID
          componentID
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const commentsByUserId = /* GraphQL */ `
  query CommentsByUserId(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPostCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByUserId(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        postID
        content
        createdAt
        status
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        post {
          id
          title
          description
          websiteID
          userID
          type
          images
          createdAt
          prompt
          status
          pageID
          componentID
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const commentsByPostId = /* GraphQL */ `
  query CommentsByPostId(
    $postID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByPostId(
      postID: $postID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        postID
        content
        createdAt
        status
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        post {
          id
          title
          description
          websiteID
          userID
          type
          images
          createdAt
          prompt
          status
          pageID
          componentID
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const getCommentLike = /* GraphQL */ `
  query GetCommentLike($id: ID!) {
    getCommentLike(id: $id) {
      id
      userID
      commentID
      like
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      comment {
        id
        userID
        postID
        content
        createdAt
        status
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        post {
          id
          title
          description
          websiteID
          userID
          type
          images
          createdAt
          prompt
          status
          pageID
          componentID
          updatedAt
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const listCommentLikes = /* GraphQL */ `
  query ListCommentLikes(
    $filter: ModelCommentLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommentLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        commentID
        like
        createdAt
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        comment {
          id
          userID
          postID
          content
          createdAt
          status
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const likedCommentsByUserId = /* GraphQL */ `
  query LikedCommentsByUserId(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCommentLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likedCommentsByUserId(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        commentID
        like
        createdAt
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        comment {
          id
          userID
          postID
          content
          createdAt
          status
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const likesByPostCommentsId = /* GraphQL */ `
  query LikesByPostCommentsId(
    $commentID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likesByPostCommentsId(
      commentID: $commentID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        commentID
        like
        createdAt
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        comment {
          id
          userID
          postID
          content
          createdAt
          status
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const getCommentReply = /* GraphQL */ `
  query GetCommentReply($id: ID!) {
    getCommentReply(id: $id) {
      id
      userID
      commentID
      content
      createdAt
      status
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      comment {
        id
        userID
        postID
        content
        createdAt
        status
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        post {
          id
          title
          description
          websiteID
          userID
          type
          images
          createdAt
          prompt
          status
          pageID
          componentID
          updatedAt
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const listCommentReplies = /* GraphQL */ `
  query ListCommentReplies(
    $filter: ModelCommentReplyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommentReplies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        commentID
        content
        createdAt
        status
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        comment {
          id
          userID
          postID
          content
          createdAt
          status
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const replyByuserId = /* GraphQL */ `
  query ReplyByuserId(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCommentReplyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    replyByuserId(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        commentID
        content
        createdAt
        status
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        comment {
          id
          userID
          postID
          content
          createdAt
          status
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const repliesByCommentsId = /* GraphQL */ `
  query RepliesByCommentsId(
    $commentID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentReplyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    repliesByCommentsId(
      commentID: $commentID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        commentID
        content
        createdAt
        status
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        comment {
          id
          userID
          postID
          content
          createdAt
          status
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!) {
    getConversation(id: $id) {
      id
      convID
      userID
      status
      lastActiveTime
      lastMessageID
      createdAt
      chatmateID
      chatmate {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      lastMessage {
        id
        senderID
        conversationID
        content
        type
        createdAt
        read
        deleted
        updatedAt
      }
    }
  }
`;
export const listConversations = /* GraphQL */ `
  query ListConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        convID
        userID
        status
        lastActiveTime
        lastMessageID
        createdAt
        chatmateID
        chatmate {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        lastMessage {
          id
          senderID
          conversationID
          content
          type
          createdAt
          read
          deleted
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listConversationByStatus = /* GraphQL */ `
  query ListConversationByStatus(
    $status: ConvoStatus
    $userIDLastActiveTime: ModelConversationByStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversationByStatus(
      status: $status
      userIDLastActiveTime: $userIDLastActiveTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        convID
        userID
        status
        lastActiveTime
        lastMessageID
        createdAt
        chatmateID
        chatmate {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        lastMessage {
          id
          senderID
          conversationID
          content
          type
          createdAt
          read
          deleted
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listConversationParticipants = /* GraphQL */ `
  query ListConversationParticipants(
    $convID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversationParticipants(
      convID: $convID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        convID
        userID
        status
        lastActiveTime
        lastMessageID
        createdAt
        chatmateID
        chatmate {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        lastMessage {
          id
          senderID
          conversationID
          content
          type
          createdAt
          read
          deleted
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getDirectConversation = /* GraphQL */ `
  query GetDirectConversation(
    $chatmateID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDirectConversation(
      chatmateID: $chatmateID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        convID
        userID
        status
        lastActiveTime
        lastMessageID
        createdAt
        chatmateID
        chatmate {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        lastMessage {
          id
          senderID
          conversationID
          content
          type
          createdAt
          read
          deleted
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      senderID
      conversationID
      content
      type
      createdAt
      read
      deleted
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        senderID
        conversationID
        content
        type
        createdAt
        read
        deleted
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMessagesByThread = /* GraphQL */ `
  query ListMessagesByThread(
    $conversationID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessagesByThread(
      conversationID: $conversationID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        senderID
        conversationID
        content
        type
        createdAt
        read
        deleted
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      owner
      toNotifyID
      topic
      topicUrl
      topicDescription
      topicID
      status
      createdAt
      updatedAt
      creator {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      toNotify {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        toNotifyID
        topic
        topicUrl
        topicDescription
        topicID
        status
        createdAt
        updatedAt
        creator {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        toNotify {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listByToNotifyID = /* GraphQL */ `
  query ListByToNotifyID(
    $toNotifyID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listByToNotifyID(
      toNotifyID: $toNotifyID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        toNotifyID
        topic
        topicUrl
        topicDescription
        topicID
        status
        createdAt
        updatedAt
        creator {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        toNotify {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getImage = /* GraphQL */ `
  query GetImage($id: ID!) {
    getImage(id: $id) {
      id
      userID
      title
      prompt
      type
      url
      status
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
export const listImages = /* GraphQL */ `
  query ListImages(
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        title
        prompt
        type
        url
        status
        createdAt
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        owner
      }
      nextToken
    }
  }
`;
export const imagesByUserID = /* GraphQL */ `
  query ImagesByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    imagesByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        title
        prompt
        type
        url
        status
        createdAt
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        owner
      }
      nextToken
    }
  }
`;
export const listByImageType = /* GraphQL */ `
  query ListByImageType(
    $type: ImageType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listByImageType(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        title
        prompt
        type
        url
        status
        createdAt
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        owner
      }
      nextToken
    }
  }
`;
export const listByImageStatus = /* GraphQL */ `
  query ListByImageStatus(
    $status: ImageStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listByImageStatus(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        title
        prompt
        type
        url
        status
        createdAt
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        owner
      }
      nextToken
    }
  }
`;
export const searchImages = /* GraphQL */ `
  query SearchImages(
    $filter: SearchableImageFilterInput
    $sort: SearchableImageSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchImages(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        userID
        title
        prompt
        type
        url
        status
        createdAt
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        owner
      }
      nextToken
      total
    }
  }
`;
