import { useState } from 'react'
import Delete from 'src/icons/Delete'
import Save from 'src/icons/Save'

export type FooterSimpleFieldProps = {
  type: 'phone' | 'email' | 'address' | 'copyright'
  defaultValue: string
  onSave: (type: string, value: string) => void
  onDelete?: () => void
}

export default function FooterSimpleField({ defaultValue, type, onSave, onDelete }: FooterSimpleFieldProps) {
  const [value, setValue] = useState(defaultValue)

  return (
    <div className="mt-[142px] px-[18px]">
      <h3 className="text-[19px] dark:text-white border-black px-[2px] border-b-[1px] dark:border-white pb-[25px] mb-[46px] capitalize">
        {type}
      </h3>
      <div className="flex gap-6 items-end px-4 border-b border-black dark:border-white pb-[45px]">
        <input
          className="text-[19px] w-full border-b-[1px] dark:text-white dark:bg-black dark:border-white border-black pb-[7px] outline-none text-left"
          type="text"
          value={value}
          onChange={e => setValue(e.target.value)}
          placeholder={`Type ${type}`}
        />
        <div className="flex items-end justify-end gap-6">
          <button type="button" onClick={() => onSave(type, value)}>
            <Save height={23} fill="none" className="mx-auto" />
          </button>
          <button
            type="button"
            onClick={() => {
              setValue('')
              if (onDelete) onDelete()
            }}
          >
            <Delete height={23} fill="none" className="stroke-[#E90570]" />
          </button>
        </div>
      </div>
    </div>
  )
}
