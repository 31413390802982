import photography from '../assets/templates-media/photography.png'
import Decore from '../assets/templates-media/decore.png'
import Business from '../assets/templates-media/business.png'
import Home from '../assets/templates-media/home.png'
import Plants from '../assets/templates-media/plants.png'
import Sport from '../assets/templates-media/sport.png'
import Animal from '../assets/templates-media/pet.png'
import Travel from '../assets/templates-media/travel.png'
import Restaurant from '../assets/templates-media/restaurant.png'
import Biance_Blog from '../assets/templates-media/Biance_Blog.png'
import Gallery_Calligraphy from '../assets/templates-media/Gallery-calligraphy.png'
import Art from '../assets/templates-media/Art.png'
import Isaiah_Blog from '../assets/templates-media/Isaiah_blog.png'
import Gallery_Animal from '../assets/templates-media/Animal_Gallery.png'
import Gallery_NaturaL from '../assets/templates-media/ga.png'

export async function getSectionTemplates() {
  return [
    {
      id: '01',
      name: 'Photography',
      image: photography,
    },
    {
      id: '02',
      name: 'Decore Company',
      image: Decore,
    },
    {
      id: '03',
      name: 'Business',
      image: Business,
    },
    {
      id: '04',
      name: 'HomeDot Company',
      image: Home,
    },
    {
      id: '05',
      name: 'Plants',
      image: Plants,
    },
    {
      id: '06',
      name: 'Sporting',
      image: Sport,
    },
    {
      id: '07',
      name: 'Animals',
      image: Animal,
    },
    {
      id: '08',
      name: 'Traveling',
      image: Travel,
    },
    {
      id: '09',
      name: 'Restaurant',
      image: Restaurant,
    },
    {
      id: '10',
      name: 'Bianca Witherspoon Blog',
      image: Biance_Blog,
    },
    {
      id: '11',
      name: 'Gallery for calligraphy',
      image: Gallery_Calligraphy,
    },
    {
      id: '12',
      name: 'Art',
      image: Art,
    },
    {
      id: '13',
      name: 'Isaiah Smith Blog',
      image: Isaiah_Blog,
    },
    {
      id: '14',
      name: 'Gallery for Animals',
      image: Gallery_Animal,
    },
    {
      id: '15',
      name: 'Gallery for Natural',
      image: Gallery_NaturaL,
    },
  ] as const
}
