import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { TeamMembersSection, TeamMembersSectionMember } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import CardDesign from './VariantDesign/CardDesign'

export default function VariantSix({ section }: { section: TeamMembersSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const members = section.variables.find(variable => variable.name === 'TEAM_MEMBERS')
    ?.data as TeamMembersSectionMember[]
  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: '#fff',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
      className="md:py-[100px] py-4"
    >
      <div className="container mx-auto">
        <div className="md:w-[1250px] max-w-full mx-auto space-y-1">
          <div className="text-2xl md:text-[40px]">
            <p className="text-black text-center">
              {title && <SectionSlatePreview initialValue={title} previewMode />}
            </p>
          </div>

          <div>
            <p className="text-black md:text-[18px] text-center">
              {subTitle && <SectionSlatePreview initialValue={subTitle} previewMode />}
            </p>
          </div>

          <div>
            <p className="text-black text-[14px] md:text-[16px] text-center">
              {paragraph && <SectionSlatePreview initialValue={paragraph} previewMode />}
            </p>
          </div>
        </div>

        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-center md:justify-between lg:justify-between gap-y-14 mt-6 md:mt-14">
          {members.map(member => (
            <CardDesign key={member.id} member={member} />
          ))}
        </div>
      </div>
    </div>
  )
}
