import { Descendant } from 'slate'
import { FAQSectionItem, FAQVariables, FAQVariants, Section, SectionTags } from 'src/_polly/components/src/sections'
import { v4 as uuid } from 'uuid'
import { DEFAULT_VARIANT_SETTINGS, getSectionVariants } from './get-variants'
const tags: Record<FAQVariants, SectionTags[]> = {
  VARIANT_1: [],
  VARIANT_2: [],
  VARIANT_3: ['IMAGE', 'ANIMATION'],
  VARIANT_4: ['ANIMATION'],
  VARIANT_5: ['ANIMATION'],
  VARIANT_6: [],
  VARIANT_7: [],
  VARIANT_8: [],
}
export function getFAQVariants() {
  const variants = getSectionVariants('FAQ')
  const sections: Section<FAQVariants, FAQVariables>[] = []
  const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'List Title' }] }]
  const SubTitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Sub Title' }] }]
  const paragraph: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Paragraph' }] }]
  const item1: Descendant[] = [{ type: 'paragraph', children: [{ text: '83' }] }]
  const item2: Descendant[] = [{ type: 'paragraph', children: [{ text: '240' }] }]
  const item3: Descendant[] = [{ type: 'paragraph', children: [{ text: '15' }] }]
  const item4: Descendant[] = [{ type: 'paragraph', children: [{ text: '7' }] }]
  const description: Descendant[] = [
    {
      type: 'paragraph',
      children: [{ text: 'Describe the item and include any relevant details. Click to edit the text.' }],
    },
  ]
  const media =
    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'
  variants.forEach(variant => {
    if (variant.id === 'VARIANT_1') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Bootstrap Your UX Design' }] }]
      const subtitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'UX Design for Crowdfunding Success' }] }]
      const description: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
            },
          ],
        },
      ]
      const paragraph: Descendant[] = [{ type: 'paragraph', children: [{ text: '' }] }]
      const item1: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Dope Burn' }] }]
      const data: Section<FAQVariants, FAQVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'FAQ',
        updatedAt: '',
        tags: tags[variant.id as FAQVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: '123',
            name: 'ITEMS',
            category: 'TEXT',
            data: [
              {
                id: '1',
                title: item1,
                description: description,
                image:
                  'https://images.unsplash.com/photo-1542728928-1413d1894ed1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxsYW1wfGVufDB8fHx8MTY3NzY3ODkzMQ&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '2',
                title: item1,
                description: description,
                image:
                  'https://images.unsplash.com/photo-1505693416388-ac5ce068fe85?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwyfHxiZWR8ZW58MHx8fHwxNjc3Njc4OTQ4&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '3',
                title: item1,
                description: description,
                image:
                  'https://images.unsplash.com/photo-1486946255434-2466348c2166?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxMHx8cm9vbXxlbnwwfHx8fDE2Nzc2Nzg5NjQ&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '4',
                title: item1,
                description: description,
                image:
                  'https://images.unsplash.com/photo-1677339513775-733c9a8e9d1d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2Nzc2Nzg5Nzg&ixlib=rb-4.0.3&q=80&w=1080',
              },
            ] as FAQSectionItem[],
          },
        ],
        variant: variant.id as FAQVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_2') {
      const data: Section<FAQVariants, FAQVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'FAQ',
        updatedAt: '',
        tags: tags[variant.id as FAQVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: SubTitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: '123',
            name: 'ITEMS',
            category: 'TEXT',
            data: [
              {
                id: '1',
                title: item1,
                description: description,
                image:
                  'https://images.unsplash.com/photo-1611262588024-d12430b98920?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80',
              },
              {
                id: '2',
                title: item2,
                description: description,
                image:
                  'https://images.unsplash.com/photo-1614680376408-81e91ffe3db7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80',
              },
              {
                id: '3',
                title: item3,
                description: description,
                image:
                  'https://images.unsplash.com/photo-1611605698335-8b1569810432?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80',
              },
              {
                id: '4',
                title: item4,
                description: description,
                image:
                  'https://images.unsplash.com/photo-1611162616475-46b635cb6868?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80',
              },
            ] as FAQSectionItem[],
          },
        ],
        variant: variant.id as FAQVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_3') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Skills and Experience' }] }]
      const item: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Hits Different Investors' }] }]
      const description: Descendant[] = [{ type: 'paragraph', children: [{ text: 'UX Design for Digital' }] }]
      const data: Section<FAQVariants, FAQVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'FAQ',
        updatedAt: '',
        tags: tags[variant.id as FAQVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          {
            id: '123',
            name: 'ITEMS',
            category: 'TEXT',
            data: [
              {
                id: '1',
                title: item,
                description: description,
                image:
                  'https://images.unsplash.com/photo-1523726491678-bf852e717f6a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxkZXNpZ258ZW58MHx8fHwxNjc3NTc3OTE0&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '2',
                title: item,
                description: description,
                image:
                  'https://images.unsplash.com/photo-1558655146-d09347e92766?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw1fHxkZXNpZ258ZW58MHx8fHwxNjc3NTc3OTE0&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '3',
                title: item,
                description: description,
                image:
                  'https://images.unsplash.com/photo-1482053450283-3e0b78b09a70?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxMHx8ZGVzaWdufGVufDB8fHx8MTY3NzU3NzkxNA&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '4',
                title: item,
                description: description,
                image:
                  'https://images.unsplash.com/photo-1510127034890-ba27508e9f1c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwyfHxjYW1lcmF8ZW58MHx8fHwxNjc3NTc4NDA2&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '5',
                title: item,
                description: description,
                image:
                  'https://images.unsplash.com/photo-1516961642265-531546e84af2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw2fHxjYW1lcmF8ZW58MHx8fHwxNjc3NTc4NDA2&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '6',
                title: item,
                description: description,
                image:
                  'https://images.unsplash.com/photo-1607462109225-6b64ae2dd3cb?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw3fHxjYW1lcmF8ZW58MHx8fHwxNjc3NTc4NDA2&ixlib=rb-4.0.3&q=80&w=1080',
              },
            ] as FAQSectionItem[],
          },
        ],
        variant: variant.id as FAQVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_4') {
      const data: Section<FAQVariants, FAQVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'FAQ',
        updatedAt: '',
        tags: tags[variant.id as FAQVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          {
            id: '123',
            name: 'ITEMS',
            category: 'TEXT',
            data: [
              {
                id: '1',
                // title: 'Question Here',
                title: item1,
                description: description,
              },
              {
                id: '2',
                title: item1,
                // description:
                //   'Ure rem, for pariatur and excepteur velit. Deserunt reprehenderit. Non sunt. Ut natus so veritatis so magnam yet ea. Ut aut for aliquip..',
                description: description,
              },
              {
                id: '3',
                title: item1,
                description: description,
              },
              {
                id: '4',
                title: item1,
                description: description,
              },
            ] as FAQSectionItem[],
          },
        ],
        variant: variant.id as FAQVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_5') {
      const item: Descendant[] = [{ type: 'paragraph', children: [{ text: 'GOAT Acquisition UX' }] }]
      const description: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'We ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
            },
          ],
        },
      ]
      const data: Section<FAQVariants, FAQVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'FAQ',
        updatedAt: '',
        tags: tags[variant.id as FAQVariants],
        variables: [
          {
            id: '123',
            name: 'ITEMS',
            category: 'TEXT',
            data: [
              {
                id: '1',
                title: item,
                description: description,
                image:
                  'https://images.unsplash.com/photo-1523726491678-bf852e717f6a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxkZXNpZ258ZW58MHx8fHwxNjc3NTc3OTE0&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '2',
                title: item,
                description: description,
                image:
                  'https://images.unsplash.com/photo-1558655146-d09347e92766?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw1fHxkZXNpZ258ZW58MHx8fHwxNjc3NTc3OTE0&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '3',
                title: item,
                description: description,
                image:
                  'https://images.unsplash.com/photo-1482053450283-3e0b78b09a70?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxMHx8ZGVzaWdufGVufDB8fHx8MTY3NzU3NzkxNA&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '4',
                title: item,
                description: description,
                image:
                  'https://images.unsplash.com/photo-1510127034890-ba27508e9f1c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwyfHxjYW1lcmF8ZW58MHx8fHwxNjc3NTc4NDA2&ixlib=rb-4.0.3&q=80&w=1080',
              },
            ] as FAQSectionItem[],
          },
        ],
        variant: variant.id as FAQVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_6') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'ABOUT THE PROJECT' }] }]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Tattooed pickled pug iceland. Pork belly austin taiyaki hexagon occupy gatekeep jianbing tattooed sriracha, ugh pop-up. Meditation gluten-free copper mug lyft food truck mustache yes plz blog tattooed affogato tofu prism la croix fam green juice. Knausgaard pickled celiac vibecession, slow-carb listicle hashtag ',
            },
          ],
        },
      ]
      const subtitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'AWARDS' }] }]
      const description: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Site Of The Day' }] }]

      const data: Section<FAQVariants, FAQVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'FAQ',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          {
            id: uuid(),
            category: 'TEXT',
            data: paragraph,
            name: 'PARAGRAPH',
          },
          {
            id: uuid(),
            name: 'ITEMS',
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                // title: 'CSSDA',
                title: item1,
                // description: 'Site Of The Day',
                description: description,
              },
            ] as FAQSectionItem[],
          },
        ],
        variant: variant.id as FAQVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_7') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'FEATURES & AWARDS' }] }]
      const item1: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Cssda' }] }]
      const item2: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Awards' }] }]
      const description1: Descendant[] = [
        { type: 'paragraph', children: [{ text: 'Developer Of The Year (Special Kudos)' }] },
      ]
      const description2: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Site Of The Day' }] }]
      const description3: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Public UI Award' }] }]
      const description4: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Public Innovation Award' }] }]
      const description5: Descendant[] = [
        { type: 'paragraph', children: [{ text: 'Ecommerce Of The Year (Nominee)' }] },
      ]
      const itemNum0: Descendant[] = [{ type: 'paragraph', children: [{ text: '' }] }]
      const itemNum1: Descendant[] = [{ type: 'paragraph', children: [{ text: '(3times)' }] }]
      const itemNum2: Descendant[] = [{ type: 'paragraph', children: [{ text: '(5times)' }] }]
      const itemNum3: Descendant[] = [{ type: 'paragraph', children: [{ text: '(9times)' }] }]
      const year1: Descendant[] = [{ type: 'paragraph', children: [{ text: '2021' }] }]
      const year2: Descendant[] = [{ type: 'paragraph', children: [{ text: '2019 â€” 21' }] }]
      const year3: Descendant[] = [{ type: 'paragraph', children: [{ text: '2023' }] }]

      const data: Section<FAQVariants, FAQVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'FAQ',
        updatedAt: '',
        tags: tags[variant.id as FAQVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          {
            id: uuid(),
            name: 'ITEMS',
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                title: item1,
                description: description1,
                items: itemNum1,
                year: year1,
              },
              {
                id: uuid(),
                title: item1,
                description: description2,
                items: itemNum2,
                year: year1,
              },
              {
                id: uuid(),
                title: item1,
                description: description3,
                items: itemNum0,
                year: year1,
              },
              {
                id: uuid(),
                title: item1,
                description: description4,
                items: itemNum0,
                year: year3,
              },
              {
                id: uuid(),
                title: item2,
                description: description3,
                items: itemNum2,
                year: year2,
              },
              {
                id: uuid(),
                title: item2,
                description: description5,
                items: itemNum0,
                year: year3,
              },
              {
                id: uuid(),
                title: item2,
                description: description2,
                items: itemNum2,
                year: year1,
              },
              {
                id: uuid(),
                title: item2,
                description: description3,
                items: itemNum1,
                year: year2,
              },
              {
                id: uuid(),
                title: item1,
                description: description1,
                items: itemNum2,
                year: year3,
              },
              {
                id: uuid(),
                title: item2,
                description: description4,
                items: itemNum3,
                year: year1,
              },
              {
                id: uuid(),
                title: item1,
                description: description5,
                items: itemNum2,
                year: year1,
              },
            ] as FAQSectionItem[],
          },
        ],
        variant: variant.id as FAQVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_8') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Proudly Featured' }] }]
      const data: Section<FAQVariants, FAQVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'FAQ',
        updatedAt: '',
        tags: tags[variant.id as FAQVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          {
            id: uuid(),
            name: 'ITEMS',
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                image: 'https://www.roastycoffee.com/wp-content/uploads/business-insider-logo.svg',
              },
              {
                id: uuid(),
                image: 'https://www.roastycoffee.com/wp-content/uploads/buzzfeed-logo.svg',
              },
              {
                id: uuid(),
                image: 'https://www.roastycoffee.com/wp-content/uploads/fastcompany-logo.svg',
              },
              {
                id: uuid(),
                image: 'https://www.roastycoffee.com/wp-content/uploads/today-logo.svg',
              },
              {
                id: uuid(),
                image: 'https://www.roastycoffee.com/wp-content/uploads/wired-logo.svg',
              },
              {
                id: uuid(),
                image: 'https://www.roastycoffee.com/wp-content/uploads/yahoo-logo.svg',
              },
            ] as FAQSectionItem[],
          },
        ],
        variant: variant.id as FAQVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else {
      const description: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'Describe the item and include any relevant details. Click to edit the text.' }],
        },
      ]
      const data: Section<FAQVariants, FAQVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'FAQ',
        updatedAt: '',
        tags: tags[variant.id as FAQVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          {
            id: '123',
            name: 'ITEMS',
            category: 'TEXT',
            data: [
              {
                id: '1',
                title: item1,
                description: description,
                image: media,
              },
              {
                id: '2',
                title: item2,
                description: description,
                image: media,
              },
              {
                id: '3',
                title: item3,
                description: description,
                image: media,
              },
              {
                id: '4',
                title: item4,
                description: description,
                image: media,
              },
            ] as FAQSectionItem[],
          },
        ],
        variant: variant.id as FAQVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    }
  })

  return sections
}
