import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { TeamMembersSection, TeamMembersSectionMember } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import Team1 from './VariantDesign/Team1'

export default function VariantOne({ section }: { section: TeamMembersSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const members = section.variables.find(variable => variable.name === 'TEAM_MEMBERS')
    ?.data as TeamMembersSectionMember[]

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: '#fff',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
      className="md:py-[50px]"
    >
      <div className="md:container md:mx-auto md:px-12 px-4">
        <div className="w-[750px] max-w-full mx-auto space-y-1">
          <div className="text-2xl lg:text-[40px]">
            <p className="text-center">
              <SectionSlatePreview initialValue={title} previewMode />
            </p>
          </div>
          {subTitle && (
            <div>
              <p className="md:text-[18px] text-center">
                <SectionSlatePreview initialValue={subTitle} previewMode />
              </p>
            </div>
          )}
          {paragraph && (
            <div>
              <p className="text-center md:text-[16px] text-[14px]">
                <SectionSlatePreview initialValue={paragraph} previewMode />
              </p>
            </div>
          )}
        </div>

        <div className="grid sm:grid-col-1 md:grid-col-1 lg:grid-cols-1 md:justify-between lg:justify-between gap-y-4 lg:md:gap-y-14 md:gap-y-14  mt-4">
          {members.map(member => (
            <Team1 key={member.id} member={member} isRound />
          ))}
        </div>
      </div>
    </div>
  )
}
