import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyAwHvz99D0t8FUmCExfaH4O1c0CzJETFCI',
  authDomain: 'techboom-ai.firebaseapp.com',
  projectId: 'techboom-ai',
  storageBucket: 'techboom-ai.appspot.com',
  messagingSenderId: '652704697246',
  appId: '1:652704697246:web:4e4a7acc3d71423400648f',
  measurementId: 'G-M4ZNWMVSDX',
}

export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
