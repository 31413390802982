import SectionSlatePreview from '../../../common/SectionSlatePreview'

export default function MENUTextItem({
  image,
  title,
  subtitle,
  description,
}: {
  image: string
  title: any
  subtitle: any
  description: any
}) {
  return (
    <div className="lg:flex md:flex gap-4 items-center justify-between">
      {image && (
        <img
          src={image}
          alt=""
          className="w-24 h-24  rounded-full mx-auto md:mx-0 lg:mb-0 lg:mx-0 mb-4"
          style={{ borderRadius: '100%' }}
        />
      )}

      <div className="flex items-center md:gap-x-0 gap-x-3 justify-between w-full">
        <div className="w-full">
          {title && (
            <h1 className="md:text-lg uppercase">
              <SectionSlatePreview initialValue={title} previewMode />
            </h1>
          )}
          {description && (
            <p className="text-sm">
              <SectionSlatePreview initialValue={description} previewMode />
            </p>
          )}
        </div>
        <div className="border-b md:py-6 ">
          {subtitle && (
            <h1 className="md:text-2xl font-sub text-[#B48B7E] flex">
              <SectionSlatePreview initialValue={subtitle} previewMode />
            </h1>
          )}
        </div>
      </div>
    </div>
  )
}
