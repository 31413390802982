import { useContext, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Descendant } from 'slate'
import Love1stSite from 'src/icons/ComponentsIcons/Love@1stSite'
import {
  AttentionGrabberItemsGroup,
  AttentionGrabberSection,
  AttentionGrabberVariables,
  SectionVariableCategories,
} from 'src/_polly/components/src/sections'
import { v4 as uuid } from 'uuid'
import { ReactComponent as DeleteIcon } from '../../assets/icons/menuBar/delete.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/menuBar/edit.svg'
import { upsertBlockByPageID } from '../../services/website.service'
import BottomFloatingButton from '../BottomFloatingButton'
import { WebsiteContext } from '../context/WebsiteContext'
import { AdvancedFeature } from '../modal/advanced'
import { MenuSaveButton } from '../modal/section/NewMenu/NewMenu'
import { ButtonForSection } from './ButtonEditor'
import ModalDialogV2 from './ModalDialogV2'
import SectionMediaField from './SectionField/SectionMediaField'
import SectionTextField from './SectionField/SectionTextField'
import ItemField from './SectionField/SectionSingleItemField'

type EditSection = {
  open: boolean
  section: AttentionGrabberSection | null
  onClose: () => void
  onUpdate?: (section: AttentionGrabberSection) => void
}

type EditItemProps = {
  data?: AttentionGrabberItemsGroup
  onSave: (data: Partial<AttentionGrabberItemsGroup>) => void
}

function Item({
  data,
  onDeleteClick,
  onEditClick,
}: {
  data: AttentionGrabberItemsGroup
  onDeleteClick: () => void
  onEditClick: () => void
}) {
  return (
    <div className="border border-black dark:border-white h-[65px] flex justify-between">
      <div className="flex items-center">
        <div className="grid grid-cols-2 gap-1 ml-2 mr-4">
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
        </div>
        <div className="flex items-center gap-4">
          <p>
            <ItemField initialValue={data?.title} />
          </p>
        </div>
      </div>

      <div className="flex">
        <button
          type="button"
          className="flex justify-center items-center w-16 border-l border-black dark:border-white"
          onClick={onEditClick}
        >
          <EditIcon />
        </button>
        <button
          type="button"
          className="flex justify-center items-center w-16 border-l border-black dark:border-white"
          onClick={onDeleteClick}
        >
          <DeleteIcon />
        </button>
      </div>
    </div>
  )
}

type FormInput = {
  name?: string
  title?: string
  description: string
}

function EditItem({ data, onSave }: EditItemProps) {
  const initialDataTitle: Descendant[] = [
    {
      type: 'paragraph',
      children: [
        {
          text: 'item title',
        },
      ],
    },
  ]
  const initialDataDescription: Descendant[] = [
    {
      type: 'paragraph',
      children: [
        {
          text: 'item description',
        },
      ],
    },
  ]
  const [titles, setTitle] = useState(data?.title || initialDataTitle)
  const [descriptions, setDescription] = useState(data?.description || initialDataDescription)
  const { handleSubmit } = useForm<FormInput>()

  const onSubmit: SubmitHandler<FormInput> = value => {
    onSave({
      ...value,
      id: data?.id,
      title: titles,
      description: descriptions,
    })
  }

  return (
    <div className="px-4 dark:text-white">
      <form className="pb-024" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-8">
          <div>
            <SectionTextField
              title="Title"
              initialValue={titles}
              onChange={value => setTitle(value)}
              onUpdate={() => handleSubmit(onSubmit)}
            />
          </div>
          <div>
            <SectionTextField
              title="Description"
              initialValue={descriptions}
              onChange={value => setDescription(value)}
              onUpdate={() => handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </form>
      <BottomFloatingButton label={data?.id ? 'Save' : 'Add'} onClick={handleSubmit(onSubmit)} />
    </div>
  )
}

export default function EditSectionTextModal({ open, section: defaultValue, onClose: onBack, onUpdate }: EditSection) {
  const websiteContext = useContext(WebsiteContext)

  if (!open || !defaultValue) return null

  const [section, setSection] = useState(defaultValue)
  const [updating, setUpdating] = useState(false)
  const [editItem, setEditItem] = useState<AttentionGrabberItemsGroup>()
  const [showAddItemModel, setShowAddItemModel] = useState(false)
  const [fireUpdate, setFireUpdate] = useState({
    fire: false,
    goBack: false,
  })

  const title = section.variables.find(variable => variable.name === 'TITLE')
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')
  const media = section.variables.find(variable => variable.name === 'MEDIA')
  const media2 = section.variables.find(variable => variable.name === 'MEDIA2')
  const button = section.variables.find(variable => variable.name === 'BUTTON_1')
  const items = section.variables.find(variable => variable.name === 'ITEMS')

  const updateVariableData = (name: AttentionGrabberVariables, category: SectionVariableCategories, data: any) => {
    const current = section.variables.find(variable => variable.name === name)
    if (!current) {
      setSection({
        ...section,
        variables: [...section.variables, { name, data, category, id: uuid() }],
      })
      return
    }
    const otherVariables = section.variables.filter(variable => variable.name !== name)
    const newVariables = [...otherVariables, { ...current, data }]
    setSection({ ...section, variables: newVariables })
  }

  const handleSave = async (goBack = true) => {
    setFireUpdate({ fire: true, goBack })
  }

  const handleDelete = (id: string) => {
    const newItems = items?.data.filter((item: AttentionGrabberItemsGroup) => item.id !== id)
    setSection({
      ...section,
      variables: section.variables.map(variable => {
        if (variable.name === 'ITEMS') {
          return { ...variable, data: newItems }
        }
        return variable
      }),
    })
    handleSave(false)
  }

  const handleEditAdd = (data: Partial<AttentionGrabberItemsGroup>) => {
    if (data.id) {
      const newItems = items?.data.map((item: AttentionGrabberItemsGroup) => {
        if (item.id === data.id) {
          return { ...item, ...data }
        }
        return item
      })
      setSection({
        ...section,
        variables: section.variables.map(variable => {
          if (variable.name === 'ITEMS') {
            return { ...variable, data: newItems }
          }
          return variable
        }),
      })
    } else {
      setSection({
        ...section,
        variables: section.variables.map(variable => {
          if (variable.name === 'ITEMS') {
            return {
              ...variable,
              data: [
                ...variable.data,
                {
                  ...data,
                  id: uuid(),
                },
              ],
            }
          }
          return variable
        }),
      })
    }

    setEditItem(undefined)
    setShowAddItemModel(false)
    handleSave(false)
  }

  useEffect(() => {
    const update = async (goBack = true) => {
      setUpdating(true)
      await upsertBlockByPageID(websiteContext.pageID, section as any)
      setUpdating(false)
      setFireUpdate({ fire: false, goBack: false })
      if (goBack) onBack()
    }
    if (fireUpdate.fire) update(fireUpdate.goBack)
  }, [fireUpdate, section])

  useEffect(() => {
    onUpdate?.(section)
  }, [section])

  return (
    <>
      <ModalDialogV2 open={open} onBack={onBack}>
        <div className="px-5 dark:text-white mt-[25px]">
          <div className="border-t-[1px] border-black dark:border-white">
            {section.variant !== 'VARIANT_6' && (
              <div>
                <SectionTextField
                  initialValue={title?.data ?? []}
                  onChange={value => updateVariableData('TITLE', 'TEXT', value)}
                  onUpdate={() => handleSave(false)}
                />

                {section.variant !== 'VARIANT_5' &&
                  section.variant !== 'VARIANT_11' &&
                  section.variant !== 'VARIANT_12' &&
                  section.variant !== 'VARIANT_13' && (
                    <SectionTextField
                      title="Subtitle"
                      initialValue={subTitle?.data ?? []}
                      onChange={value => updateVariableData('SUBTITLE', 'TEXT', value)}
                      onUpdate={() => handleSave(false)}
                    />
                  )}

                {section.variant !== 'VARIANT_2' &&
                  section.variant !== 'VARIANT_3' &&
                  section.variant !== 'VARIANT_5' &&
                  section.variant !== 'VARIANT_8' &&
                  section.variant !== 'VARIANT_9' &&
                  section.variant !== 'VARIANT_10' &&
                  section.variant !== 'VARIANT_11' &&
                  section.variant !== 'VARIANT_12' &&
                  section.variant !== 'VARIANT_13' &&
                  section.variant !== 'VARIANT_14' && (
                    <SectionTextField
                      title="Paragraph"
                      initialValue={paragraph?.data ?? []}
                      isParagraph
                      onChange={value => updateVariableData('PARAGRAPH', 'TEXT', value)}
                      onUpdate={() => handleSave(false)}
                    />
                  )}

                {section.variant !== 'VARIANT_3' &&
                  section.variant !== 'VARIANT_7' &&
                  section.variant !== 'VARIANT_8' &&
                  section.variant !== 'VARIANT_9' &&
                  section.variant !== 'VARIANT_11' &&
                  section.variant !== 'VARIANT_13' &&
                  section.variant !== 'VARIANT_14' && (
                    <SectionMediaField
                      defaultValue={media?.data}
                      onChange={url => {
                        if (!url) {
                          setSection({
                            ...section,
                            variables: section.variables.filter(variable => variable.name !== 'MEDIA'),
                          })
                          return
                        }
                        updateVariableData('MEDIA', 'IMAGE', url)
                        handleSave(false)
                      }}
                    />
                  )}
                {section.variant === 'VARIANT_2' && (
                  <SectionMediaField
                    defaultValue={media2?.data}
                    onChange={url => {
                      if (!url) {
                        setSection({
                          ...section,
                          variables: section.variables.filter(variable => variable.name !== 'MEDIA2'),
                        })
                        return
                      }
                      updateVariableData('MEDIA2', 'IMAGE', url)
                      handleSave(false)
                    }}
                    title="Accent Image"
                  />
                )}

                {section.variant === 'VARIANT_3' ||
                section.variant === 'VARIANT_5' ||
                section.variant === 'VARIANT_10' ? (
                  <ButtonForSection
                    defaultValue={button?.data ?? null}
                    onButtonChange={update => updateVariableData('BUTTON_1', 'BUTTON', update)}
                    onChange={update => updateVariableData('BUTTON_1', 'BUTTON', update)}
                  />
                ) : null}
              </div>
            )}
          </div>

          {section.variant === 'VARIANT_6' && (
            <div className="mt-4 space-y-4 pb-24">
              <h2 className="capitalize text-[19px] font-semibold ">Items</h2>
              <div className="space-y-5 ">
                {items?.data.map((item: AttentionGrabberItemsGroup) => (
                  <Item
                    key={item.id}
                    data={item}
                    onDeleteClick={() => handleDelete(item.id)}
                    onEditClick={() => setEditItem(item)}
                  />
                ))}
                <button
                  className=" border border-black bg-black dark:border-white h-[65px] flex justify-between items-center text-lg px-6 w-full text-white"
                  type="button"
                  onClick={() => setShowAddItemModel(true)}
                >
                  Add Item
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="absolute top-[12px] left-[50%] translate-x-[-50%]">
          <Love1stSite width="auto" height={36} />
        </div>

        <div className="absolute top-[21px] right-[50px]">
          <button type="button" onClick={() => handleSave()} disabled={updating}>
            <MenuSaveButton save="save" />
          </button>
        </div>
        {updating && (
          <div className="fixed z-30 inset-0 lg:max-w-[500px] dark:bg-black/80 bg-white/80">
            <p className="flex items-center h-full justify-center w-full text-[19px] dark:text-white">Updating...</p>
          </div>
        )}

        <AdvancedFeature
          section={section}
          onChange={settings => setSection(s => ({ ...s, settings }))}
          onBack={() => handleSave(false)}
        />
      </ModalDialogV2>

      <ModalDialogV2
        open={editItem !== undefined || showAddItemModel}
        onBack={() => (editItem ? setEditItem(undefined) : setShowAddItemModel(false))}
      >
        <EditItem data={editItem} onSave={handleEditAdd} />
      </ModalDialogV2>
    </>
  )
}
