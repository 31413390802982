// import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { useContext, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ReactComponent as ImageIcon } from 'src/assets/icons/new/image.svg'
import { Descendant } from 'slate'

import { useParams } from 'react-router-dom'
import Portfolio from 'src/icons/ComponentsIcons/Portfolio'
import { getComponents, upsertBlockByPageID } from 'src/services/website.service'
import {
  PortfolioItemsGroup,
  PortfolioSection,
  PortfolioVariables,
  RenderSection,
  SectionVariableCategories,
} from 'src/_polly/components/src/sections'
import { v4 as uuid } from 'uuid'
import { ReactComponent as DeleteIcon } from '../../assets/icons/menuBar/delete.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/menuBar/edit.svg'
import { WebsiteContext } from '../context/WebsiteContext'
import { EditSection } from './EditSectionModal'

import BottomFloatingButton from '../BottomFloatingButton'
import ImageUploader from './ImageUploader'
import ModalDialogV2 from './ModalDialogV2'
// import SectionMediaField from './SectionField/SectionMediaField'
import { MenuSaveButton } from '../modal/section/NewMenu/NewMenu'
import SectionMediaField from './SectionField/SectionMediaField'
import SectionTextField from './SectionField/SectionTextField'

import { AdvancedFeature } from '../modal/advanced'
import Component from './Component'
import ItemField from './SectionField/SectionSingleItemField'

interface EditSectionModelModalProps extends EditSection {
  section: PortfolioSection
}

type EditItemProps = {
  data?: PortfolioItemsGroup
  onSave: (data: Partial<PortfolioItemsGroup>) => void
  variantName: string
}

function Item({
  data,
  onDeleteClick,
  onEditClick,
}: {
  data: PortfolioItemsGroup
  onDeleteClick: () => void
  onEditClick: () => void
}) {
  return (
    <div className=" border border-black dark:border-white h-[65px] flex justify-between">
      <div className=" flex items-center">
        <div className=" grid grid-cols-2 gap-1 ml-2 mr-4">
          <div className=" w-2 h-2 bg-gray-300 rounded-full" />
          <div className=" w-2 h-2 bg-gray-300 rounded-full" />
          <div className=" w-2 h-2 bg-gray-300 rounded-full" />
          <div className=" w-2 h-2 bg-gray-300 rounded-full" />
        </div>
        <div className="text-black dark:text-white flex items-center gap-4">
          <div className=" aspect-1 h-10">
            <img
              className=" object-cover object-center  bg-black rounded-full  w-full h-full"
              src={data.image}
              alt=""
            />
          </div>
          <p className="text-black dark:text-white">
            {data?.name ? <ItemField initialValue={data?.name} /> : <ItemField initialValue={data?.title} />}
          </p>
        </div>
      </div>
      <div className=" flex ">
        <button
          type="button"
          className="flex justify-center items-center w-16 border-l dark:border-white border-black"
          onClick={onEditClick}
        >
          <EditIcon />
        </button>
        <button
          type="button"
          className="flex justify-center items-center w-16 border-l dark:border-white border-black"
          onClick={onDeleteClick}
        >
          <DeleteIcon />
        </button>
      </div>
    </div>
  )
}

type FormInput = {
  name?: any
  title?: any
  description: any
}
function EditItem({ data, variantName, onSave }: EditItemProps) {
  const [showImageModal, setShowImageModal] = useState(false)
  const [url, setUrl] = useState(data?.image || '')

  const initialDataName: Descendant[] = [
    {
      type: 'paragraph',
      children: [
        {
          text: 'item name',
        },
      ],
    },
  ]
  const initialDataDescription: Descendant[] = [
    {
      type: 'paragraph',
      children: [
        {
          text: 'item description',
        },
      ],
    },
  ]
  const [titles, setTitle] = useState(data?.title || initialDataName)
  const [names, setName] = useState(data?.name || initialDataName)
  const [description, setDescription] = useState(data?.descriptions || initialDataDescription)
  const { handleSubmit } = useForm<FormInput>()

  const onSubmit: SubmitHandler<FormInput> = value => {
    onSave({
      ...value,
      id: data?.id,
      title: titles,
      descriptions: description,
      image: url,
      name: names,
    })
    setName(value?.name)
    setTitle(value?.title)
    setDescription(value?.description)
    setUrl(url)
  }

  return (
    <div className="px-4 dark:text-white">
      <form className=" pb-24" onSubmit={handleSubmit(onSubmit)}>
        <div className=" space-y-8">
          {variantName !== 'VARIANT_6' && (
            <div>
              <SectionTextField
                title="Name"
                initialValue={names}
                onChange={value => setName(value)}
                onUpdate={() => handleSubmit(onSubmit)}
              />
            </div>
          )}
          {variantName === 'VARIANT_6' && (
            <div>
              <SectionTextField
                title="Title"
                initialValue={titles}
                onChange={value => setTitle(value)}
                onUpdate={() => handleSubmit(onSubmit)}
              />
            </div>
          )}

          <div>
            <SectionTextField
              title="Description"
              initialValue={description}
              onChange={value => setDescription(value)}
              onUpdate={() => handleSubmit(onSubmit)}
            />
          </div>
          <div className="space-y-4">
            <h2 className="text-[19px] font-semibold">Image</h2>
            <button type="button" className="w-full" onClick={() => setShowImageModal(true)}>
              <div
                className={clsx(
                  'w-full bg-white dark:bg-black',
                  !url && 'aspect-1 flex justify-center items-center border border-black dark:border-white',
                  url && 'aspect-1',
                )}
              >
                {url && <img src={url} className="object-cover object-center bg-black w-full h-full" alt="" />}
                {!url && <ImageIcon />}
              </div>
            </button>
          </div>
        </div>
      </form>
      <ModalDialogV2 open={showImageModal} onBack={() => setShowImageModal(false)}>
        <ImageUploader
          onSubmit={_url => {
            setUrl(_url)
            setShowImageModal(false)
          }}
          buttonLabel="Select Image"
          onClose={() => setShowImageModal(false)}
        />
      </ModalDialogV2>
      <BottomFloatingButton
        label={data?.id ? 'Update' : 'Add'}
        onClick={handleSubmit(onSubmit)}
        // disabled={!url || !name}
      />
    </div>
  )
}

export default function EditSectionPortfolioModal({
  open,
  section: defaultValue,
  onClose: onBack,
  onUpdate,
}: EditSectionModelModalProps) {
  const websiteContext = useContext(WebsiteContext)
  if (!open || !defaultValue) return null
  const [section, setSection] = useState(defaultValue)
  const [updating, setUpdating] = useState(false)
  const [editItem, setEditItem] = useState<PortfolioItemsGroup>()
  const [showAddItemModel, setShowAddItemModel] = useState(false)
  const [preview, setPreview] = useState(false)
  const [fireUpdate, setFireUpdate] = useState({
    fire: false,
    goBack: false,
  })

  const title = section.variables.find(variable => variable.name === 'TITLE')
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')
  const media = section.variables.find(variable => variable.name === 'MEDIA')
  const items = section.variables.find(variable => variable.name === 'ITEMS')

  const updateVariableData = (name: PortfolioVariables, category: SectionVariableCategories, data: any) => {
    const current = section.variables.find(variable => variable.name === name)
    if (!current) {
      setSection({
        ...section,
        variables: [...section.variables, { name, data, category, id: uuid() }],
      })
      return
    }
    const otherVariables = section.variables.filter(variable => variable.name !== name)
    const newVariables = [...otherVariables, { ...current, data }]
    setSection({ ...section, variables: newVariables })
  }

  const handleDelete = (id: string) => {
    const newItems = items?.data.filter((item: PortfolioItemsGroup) => item.id !== id)
    setSection({
      ...section,
      variables: section.variables.map(variable => {
        if (variable.name === 'ITEMS') {
          return { ...variable, data: newItems }
        }
        return variable
      }),
    })
    handleSave(false)
  }

  const handleEditAdd = (data: Partial<PortfolioItemsGroup>) => {
    if (data.id) {
      const newItems = items?.data.map((item: PortfolioItemsGroup) => {
        if (item.id === data.id) {
          return { ...item, ...data }
        }
        return item
      })
      setSection({
        ...section,
        variables: section.variables.map(variable => {
          if (variable.name === 'ITEMS') {
            return { ...variable, data: newItems }
          }
          return variable
        }),
      })
    } else {
      setSection({
        ...section,
        variables: section.variables.map(variable => {
          if (variable.name === 'ITEMS') {
            return { ...variable, data: [...variable.data, { ...data, id: uuid() }] }
          }
          return variable
        }),
      })
    }

    setEditItem(undefined)
    setShowAddItemModel(false)
    handleSave(false)
  }

  const handleSave = async (goBack = true) => {
    setFireUpdate({ fire: true, goBack })
  }

  useEffect(() => {
    const update = async (goBack = true) => {
      setUpdating(true)
      await upsertBlockByPageID(websiteContext.pageID, section as any)
      setUpdating(false)
      setFireUpdate({ fire: false, goBack: false })
      if (goBack) onBack()
    }
    if (fireUpdate.fire) update(fireUpdate.goBack)
  }, [fireUpdate, section])

  useEffect(() => {
    onUpdate?.(section)
  }, [section])

  const params = useParams()
  const id = params.id as string
  const [components, setComponents] = useState<any[]>(['none'])

  const { websiteID } = useContext(WebsiteContext)

  useEffect(() => {
    getComponents(websiteID).then(_components => setComponents(_components))
  }, [id])

  return (
    <>
      <ModalDialogV2 {...{ open, onBack }}>
        <div className="px-5  dark:text-white mt-[25px]">
          <div className="border-t-[1px] border-black dark:border-white">
            {section.variant !== 'VARIANT_4' && section.variant !== 'VARIANT_1' && (
              <SectionTextField
                initialValue={title?.data ?? []}
                onChange={value => updateVariableData('TITLE', 'TEXT', value)}
                onUpdate={() => handleSave(false)}
              />
            )}
            {section.variant !== 'VARIANT_1' &&
              section.variant !== 'VARIANT_2' &&
              section.variant !== 'VARIANT_3' &&
              section.variant !== 'VARIANT_4' &&
              section.variant !== 'VARIANT_5' &&
              section.variant !== 'VARIANT_6' && (
                <SectionTextField
                  title="Subtitle"
                  initialValue={subTitle?.data ?? []}
                  onChange={value => updateVariableData('SUBTITLE', 'TEXT', value)}
                  onUpdate={() => handleSave(false)}
                />
              )}
            {section.variant !== 'VARIANT_6' && section.variant !== 'VARIANT_4' && section.variant !== 'VARIANT_1' && (
              <SectionTextField
                title="Paragraph"
                initialValue={paragraph?.data ?? []}
                isParagraph
                onChange={value => updateVariableData('PARAGRAPH', 'TEXT', value)}
                onUpdate={() => handleSave(false)}
              />
            )}
            {section.variant !== 'VARIANT_6' &&
              section.variant !== 'VARIANT_5' &&
              section.variant !== 'VARIANT_4' &&
              section.variant !== 'VARIANT_3' &&
              section.variant !== 'VARIANT_2' &&
              section.variant !== 'VARIANT_1' && (
                <SectionMediaField
                  defaultValue={media?.data}
                  onChange={url => {
                    if (!url) {
                      setSection({
                        ...section,
                        variables: section.variables.filter(variable => variable.name !== 'MEDIA'),
                      })
                      return
                    }
                    updateVariableData('MEDIA', 'IMAGE', url)
                    handleSave(false)
                  }}
                />
              )}

            <div>
              <div className="mt-12 space-y-4 pb-24">
                <h2 className="capitalize text-[19px] font-semibold ">Items</h2>
                <div className="space-y-5 ">
                  {items?.data.map((item: PortfolioItemsGroup) => (
                    <Item
                      key={item.id}
                      data={item}
                      onDeleteClick={() => handleDelete(item.id)}
                      onEditClick={() => setEditItem(item)}
                    />
                  ))}
                  <button
                    className=" border border-black bg-black dark:border-white h-[65px] flex justify-between items-center text-lg px-6 w-full text-white"
                    type="button"
                    onClick={() => setShowAddItemModel(true)}
                  >
                    Add Item
                  </button>
                </div>
              </div>
            </div>
            {updating && (
              <div className="fixed z-30 inset-0 lg:max-w-[500px] dark:bg-black/80 bg-white/80">
                <p className="flex items-center h-full justify-center w-full text-[19px] dark:text-white">
                  Updating...
                </p>
              </div>
            )}

            <AdvancedFeature
              onBack={() => handleSave(false)}
              section={section}
              onChange={settings => setSection(s => ({ ...s, settings }))}
            />
          </div>
        </div>

        <div className="absolute top-[12px] left-[50%] translate-x-[-50%]">
          <Portfolio width="auto" height={36} />
        </div>
        <div className="absolute top-[21px] right-[50px]">
          <button type="button" onClick={() => handleSave()} disabled={updating}>
            <MenuSaveButton save="save" />
          </button>
        </div>
      </ModalDialogV2>

      <ModalDialogV2
        open={editItem !== undefined || showAddItemModel}
        onBack={() => (editItem ? setEditItem(undefined) : setShowAddItemModel(false))}
      >
        <EditItem data={editItem} variantName={section?.variant} onSave={handleEditAdd} />
      </ModalDialogV2>
      <div className="md:hidden block">
        {preview ? (
          <ModalDialogV2 open={preview} onClose={() => setPreview(false)}>
            {components.map(comp => (
              <div key={comp.id}>
                {comp.isSection ? (
                  <RenderSection section={comp} />
                ) : (
                  <div className="block md:hidden px-4">
                    <Component data={comp} />
                  </div>
                )}
              </div>
            ))}
          </ModalDialogV2>
        ) : null}
      </div>
    </>
  )
}
