import GoogleFontLoader from 'react-google-font-loader'
import { AttentionGrabberSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantNine({ section }: { section: AttentionGrabberSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const dreamTitle = new String(title[0]?.children[0]?.text)
  const dreamSubTitle = new String(subTitle[0]?.children[0]?.text)
  const colorTitle = title[0]?.children[0]?.color
  const colorSubTitle = subTitle[0]?.children[0]?.color
  return (
    <div
      className="mx-auto overflow-hidden"
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          defaultBackground: 'linear-gradient(#df725d, #cc4b51, #7f4855, #463e50, #233e51)',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="md:min-h-[500px] min-h-[250px] flex justify-center items-center text-center ">
        <div className="wrapper md:space-y-4">
          <p className="dr-para dream md:text-9xl text-2xl -ml-2 md:-ml-6">
            <GoogleFontLoader
              fonts={[
                {
                  font: 'Playfair Display',
                  weights: [400],
                },
              ]}
            />
            {[...dreamTitle]?.map(a => (
              <span style={{ color: colorTitle ?? '#DEFAULT_COLOR' }}>{a === ' ' ? '\u00A0' : a}</span>
            ))}
          </p>
          <p className="dr-para dangerously md:text-5xl ">
            <GoogleFontLoader
              fonts={[
                {
                  font: 'Roboto',
                  weights: [100],
                },
              ]}
            />
            {[...dreamSubTitle]?.map(a => (
              <span style={{ color: colorSubTitle ?? '#DEFAULT_COLOR' }}>{a === ' ' ? '\u00A0' : a}</span>
            ))}
          </p>
        </div>
      </div>
    </div>
  )
}
