import Icon from './icons'
import { IconPropsT } from './types'

function Account(props: IconPropsT) {
  const { title = 'Account', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 39 39" size={size} title={title} {...otherProps}>
      <path
        d="M8.19286 2.46606H10.6658C11.4047 2.46712 12.113 2.76109 12.6354 3.28353C13.1578 3.80596 13.4518 4.51423 13.4529 5.25306V6.49206C13.4518 7.2309 13.1578 7.93917 12.6354 8.4616C12.113 8.98404 11.4047 9.27801 10.6658 9.27906H8.18886C7.45002 9.27801 6.74175 8.98404 6.21931 8.4616C5.69688 7.93917 5.40291 7.2309 5.40186 6.49206V5.25306C5.40292 4.51354 5.69743 3.80466 6.22073 3.28211C6.74403 2.75957 7.45333 2.46606 8.19286 2.46606V2.46606Z"
        stroke="#E90570"
        strokeWidth="3"
      />
      <path
        d="M5.7162 12.374H13.1472C13.886 12.3751 14.5943 12.6691 15.1167 13.1915C15.6392 13.7139 15.9331 14.4222 15.9342 15.161V19.496C15.9324 19.7418 15.8339 19.9771 15.6601 20.1509C15.4863 20.3247 15.251 20.4232 15.0052 20.425H3.8582C3.61238 20.4232 3.37715 20.3247 3.20332 20.1509C3.0295 19.9771 2.93103 19.7418 2.9292 19.496V15.161C2.93026 14.4222 3.22422 13.7139 3.74666 13.1915C4.26909 12.6691 4.97737 12.3751 5.7162 12.374V12.374Z"
        stroke="#E90570"
        strokeWidth="3"
      />
      <path
        d="M14.5117 36.5H4.38084C4.05768 36.4887 3.74132 36.4138 3.44725 36.2793C3.15318 36.1448 2.88789 35.9538 2.66795 35.7168C2.44801 35.4798 2.27618 35.2017 2.16405 34.8984C2.05191 34.5951 2.00081 34.2723 2.01366 33.9492V27.3984C2.00121 27.0753 2.05374 26.7525 2.166 26.4492C2.27826 26.146 2.44811 25.868 2.66795 25.6309C2.88779 25.3937 3.15336 25.2032 3.44725 25.0684C3.74114 24.9335 4.05772 24.8577 4.38084 24.8457H14.5117C15.1635 24.8717 15.7793 25.1543 16.2226 25.6328C16.6659 26.1113 16.9007 26.7466 16.8769 27.3984V33.9492C16.8999 34.6007 16.6639 35.2349 16.2207 35.7129C15.7775 36.1909 15.163 36.4737 14.5117 36.5V36.5Z"
        stroke="#E90570"
        strokeWidth="3"
      />
      <path
        d="M34.6738 13.1934H23.75C23.4144 13.1939 23.0816 13.1282 22.7715 13C22.4614 12.8718 22.1806 12.6827 21.9434 12.4453C21.7062 12.2079 21.5167 11.9274 21.3887 11.6172C21.2607 11.307 21.1965 10.9743 21.1973 10.6387V4.08789C21.1986 3.41139 21.4668 2.76138 21.9453 2.2832C22.4239 1.80503 23.0735 1.53595 23.75 1.53516H34.6738C35.0089 1.53476 35.3407 1.60046 35.6504 1.72852C35.9601 1.85658 36.2416 2.04624 36.4785 2.2832C36.7155 2.52017 36.9032 2.80165 37.0313 3.11133C37.1593 3.42101 37.225 3.75278 37.2246 4.08789V10.6387C37.2254 10.9741 37.1611 11.3052 37.0332 11.6152C36.9053 11.9253 36.7175 12.208 36.4805 12.4453C36.2435 12.6827 35.9603 12.8697 35.6504 12.998C35.3405 13.1264 35.0092 13.1936 34.6738 13.1934V13.1934Z"
        stroke="#E90570"
        strokeWidth="3"
      />
      <path
        d="M34.6738 36.5115H23.75C23.1004 36.5336 22.468 36.2978 21.9902 35.8572C21.5124 35.4167 21.2279 34.8072 21.1973 34.158V20.0565C21.2253 19.4063 21.5099 18.7944 21.9883 18.3533C22.4667 17.9122 23.0997 17.6783 23.75 17.7029H34.6738C35.3232 17.6807 35.956 17.9146 36.4336 18.3553C36.9111 18.796 37.1947 19.4073 37.2246 20.0565V34.158C37.1955 34.8074 36.9113 35.4184 36.4336 35.8592C35.9559 36.3 35.3235 36.5346 34.6738 36.5115Z"
        stroke="#E90570"
        strokeWidth="3"
      />
    </Icon>
  )
}

export default Account
