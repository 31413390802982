import SectionSlate from '../../../common/SectionSlatePreview'
import { QuoteSection as QuoteSectionType } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

interface VariantOneProps {
  section: QuoteSectionType
}

export default function VariantSix({ section }: VariantOneProps) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="md:container md:mx-auto px-4 md:px-0">
        <div className="bg-white mx-auto items-center lg:w-3/4 justify-center lg:mx-auto space-y-2">
          <p className="text-zinc-800 text-xl md:text-4xl lg:w-3/5 w-full font-bold mx-auto antialiased leading-relaxed text-center ">
            <SectionSlate initialValue={title} previewMode />
          </p>
          <p className="text-gray-700 font-semibold  text-center ">
            <SectionSlate initialValue={subTitle} previewMode />
          </p>
        </div>
      </div>
    </div>
  )
}
