import SectionSlate from '../../../common/SectionSlatePreview'
import { QuoteSection as QuoteSectionType } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import clsx from 'clsx'

interface VariantOneProps {
  section: QuoteSectionType
}

export default function VariantOne({ section }: VariantOneProps) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'DESCRIPTION')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data

  return (
    <div
      className="w-full"
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="md:container md:mx-auto px-4 md:px-0">
        <div className="grid md:grid-cols-2 items-center">
          {/* <div className="flex flex-col sm:flex-row items-center justify-around gap-x-20"> */}
          <div className="max-w-[600px] max-h-[700px] px-4 md:px-0">
            <img className={clsx('object-cover w-full h-full mb-5 bg-center overflow-hidden')} src={media} alt="" />
          </div>
          <div className="text-start md:w-6/12 md:px-10 px-4">
            <p className="text-[#ff4d82] pb-4 md:text-4xl font-bold leading-relaxed  tracking-tight">
              <SectionSlate initialValue={title} previewMode />
            </p>
            <p className="text-gray-700 md:text-2xl text-[14px] font-semibold pb-4">
              <SectionSlate initialValue={paragraph} previewMode />
            </p>
          </div>
        </div>
      </div>
    </div>
    // </div>
  )
}
