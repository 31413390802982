import { template01 } from './01-photography'
import { Template02 } from './02-DecoreCompany'
import { Template03 } from './03-Business'
import { Template04 } from './04-HomeCompany'
import { Template05 } from './05-plants'
import { Template06 } from './06-GYM'
import { Template07 } from './07-Pet'
import { Template08 } from './08-Traveling'
import { Template09 } from './09-Restaurant'
import { Template10 } from './10-Blog'
import { Template11 } from './11-Gallery'
import { Template12 } from './12-Art'
import { Template13 } from './13-Blog'
import { Template14 } from './14-Gallery'
import { Template15 } from './15-Gallery'

export type Template = {
  id: string
  pages: {
    name: string
    slug: string
    blocks: any[]
  }[]
  newFooter: any
  newMenuConfig: any
  newMenu: any[]
  links: any[]
}

export const templates = {
  '01': template01,
  '02': Template02,
  '03': Template03,
  '04': Template04,
  '05': Template05,
  '06': Template06,
  '07': Template07,
  '08': Template08,
  '09': Template09,
  '10': Template10,
  '11': Template11,
  '12': Template12,
  '13': Template13,
  '14': Template14,
  '15': Template15,
}
