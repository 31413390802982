/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserProfileInput = {
  id?: string | null
  firstName: string
  lastName: string
  email: string
  profilePics?: S3ObjectInput | null
  username?: string | null
  profilePicture?: string | null
  country?: string | null
  state?: string | null
  city?: string | null
  bio?: string | null
  config?: string | null
}

export type S3ObjectInput = {
  bucket: string
  region: string
  key: string
}

export type ModelUserProfileConditionInput = {
  firstName?: ModelStringInput | null
  lastName?: ModelStringInput | null
  email?: ModelStringInput | null
  username?: ModelStringInput | null
  profilePicture?: ModelStringInput | null
  country?: ModelStringInput | null
  state?: ModelStringInput | null
  city?: ModelStringInput | null
  bio?: ModelStringInput | null
  config?: ModelStringInput | null
  and?: Array<ModelUserProfileConditionInput | null> | null
  or?: Array<ModelUserProfileConditionInput | null> | null
  not?: ModelUserProfileConditionInput | null
}

export type ModelStringInput = {
  ne?: string | null
  eq?: string | null
  le?: string | null
  lt?: string | null
  ge?: string | null
  gt?: string | null
  contains?: string | null
  notContains?: string | null
  between?: Array<string | null> | null
  beginsWith?: string | null
  attributeExists?: boolean | null
  attributeType?: ModelAttributeTypes | null
  size?: ModelSizeInput | null
}

export enum ModelAttributeTypes {
  binary = 'binary',
  binarySet = 'binarySet',
  bool = 'bool',
  list = 'list',
  map = 'map',
  number = 'number',
  numberSet = 'numberSet',
  string = 'string',
  stringSet = 'stringSet',
  _null = '_null',
}

export type ModelSizeInput = {
  ne?: number | null
  eq?: number | null
  le?: number | null
  lt?: number | null
  ge?: number | null
  gt?: number | null
  between?: Array<number | null> | null
}

export type UserProfile = {
  __typename: 'UserProfile'
  id: string
  firstName: string
  lastName: string
  email: string
  profilePics?: S3Object | null
  username?: string | null
  profilePicture?: string | null
  country?: string | null
  state?: string | null
  city?: string | null
  bio?: string | null
  config?: string | null
  createdAt: string
  updatedAt: string
}

export type S3Object = {
  __typename: 'S3Object'
  bucket: string
  region: string
  key: string
}

export type UpdateUserProfileInput = {
  id: string
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  profilePics?: S3ObjectInput | null
  username?: string | null
  profilePicture?: string | null
  country?: string | null
  state?: string | null
  city?: string | null
  bio?: string | null
  config?: string | null
}

export type DeleteUserProfileInput = {
  id: string
}

export type CreateWebsiteInput = {
  id?: string | null
  name: string
  config: string
  userID: string
  createdAt?: string | null
  menu?: string | null
  footer?: string | null
  pages?: string | null
  links?: string | null
  newMenu?: string | null
  newMenuConfig?: string | null
  newFooter?: string | null
  isPostedOnSocial?: boolean | null
  cloudfrontDistributionID?: string | null
  cloudfrontDomainName?: string | null
  cloudfrontAlias?: string | null
  gaTrackingID?: string | null
}

export type ModelWebsiteConditionInput = {
  name?: ModelStringInput | null
  config?: ModelStringInput | null
  createdAt?: ModelStringInput | null
  menu?: ModelStringInput | null
  footer?: ModelStringInput | null
  pages?: ModelStringInput | null
  links?: ModelStringInput | null
  newMenu?: ModelStringInput | null
  newMenuConfig?: ModelStringInput | null
  newFooter?: ModelStringInput | null
  isPostedOnSocial?: ModelBooleanInput | null
  cloudfrontDistributionID?: ModelStringInput | null
  cloudfrontDomainName?: ModelStringInput | null
  cloudfrontAlias?: ModelStringInput | null
  gaTrackingID?: ModelStringInput | null
  and?: Array<ModelWebsiteConditionInput | null> | null
  or?: Array<ModelWebsiteConditionInput | null> | null
  not?: ModelWebsiteConditionInput | null
}

export type ModelBooleanInput = {
  ne?: boolean | null
  eq?: boolean | null
  attributeExists?: boolean | null
  attributeType?: ModelAttributeTypes | null
}

export type Website = {
  __typename: 'Website'
  id: string
  name: string
  config: string
  userID: string
  createdAt: string
  menu?: string | null
  footer?: string | null
  pages?: string | null
  links?: string | null
  newMenu?: string | null
  newMenuConfig?: string | null
  newFooter?: string | null
  isPostedOnSocial?: boolean | null
  cloudfrontDistributionID?: string | null
  cloudfrontDomainName?: string | null
  cloudfrontAlias?: string | null
  gaTrackingID?: string | null
  updatedAt: string
}

export type UpdateWebsiteInput = {
  id: string
  name?: string | null
  config?: string | null
  userID?: string | null
  createdAt?: string | null
  menu?: string | null
  footer?: string | null
  pages?: string | null
  links?: string | null
  newMenu?: string | null
  newMenuConfig?: string | null
  newFooter?: string | null
  isPostedOnSocial?: boolean | null
  cloudfrontDistributionID?: string | null
  cloudfrontDomainName?: string | null
  cloudfrontAlias?: string | null
  gaTrackingID?: string | null
}

export type DeleteWebsiteInput = {
  id: string
}

export type CreateBuildRequestInput = {
  id?: string | null
  status: BuildStatus
  websiteID: string
  createdAt?: string | null
  userID: string
  cloudfrontInvalidationID?: string | null
}

export enum BuildStatus {
  INPROGRESS = 'INPROGRESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export type ModelBuildRequestConditionInput = {
  status?: ModelBuildStatusInput | null
  websiteID?: ModelIDInput | null
  createdAt?: ModelStringInput | null
  cloudfrontInvalidationID?: ModelStringInput | null
  and?: Array<ModelBuildRequestConditionInput | null> | null
  or?: Array<ModelBuildRequestConditionInput | null> | null
  not?: ModelBuildRequestConditionInput | null
}

export type ModelBuildStatusInput = {
  eq?: BuildStatus | null
  ne?: BuildStatus | null
}

export type ModelIDInput = {
  ne?: string | null
  eq?: string | null
  le?: string | null
  lt?: string | null
  ge?: string | null
  gt?: string | null
  contains?: string | null
  notContains?: string | null
  between?: Array<string | null> | null
  beginsWith?: string | null
  attributeExists?: boolean | null
  attributeType?: ModelAttributeTypes | null
  size?: ModelSizeInput | null
}

export type BuildRequest = {
  __typename: 'BuildRequest'
  id: string
  status: BuildStatus
  websiteID: string
  createdAt: string
  userID: string
  cloudfrontInvalidationID?: string | null
  updatedAt: string
}

export type UpdateBuildRequestInput = {
  id: string
  status?: BuildStatus | null
  websiteID?: string | null
  createdAt?: string | null
  userID?: string | null
  cloudfrontInvalidationID?: string | null
}

export type DeleteBuildRequestInput = {
  id: string
}

export type CreatePageInput = {
  id?: string | null
  name: string
  path: string
  websiteID: string
  blocks: string
  createdAt?: string | null
  userID: string
}

export type ModelPageConditionInput = {
  name?: ModelStringInput | null
  path?: ModelStringInput | null
  websiteID?: ModelIDInput | null
  blocks?: ModelStringInput | null
  createdAt?: ModelStringInput | null
  and?: Array<ModelPageConditionInput | null> | null
  or?: Array<ModelPageConditionInput | null> | null
  not?: ModelPageConditionInput | null
}

export type Page = {
  __typename: 'Page'
  id: string
  name: string
  path: string
  websiteID: string
  blocks: string
  createdAt: string
  userID: string
  updatedAt: string
}

export type UpdatePageInput = {
  id: string
  name?: string | null
  path?: string | null
  websiteID?: string | null
  blocks?: string | null
  createdAt?: string | null
  userID?: string | null
}

export type DeletePageInput = {
  id: string
}

export type CreateBlockInput = {
  id?: string | null
  type: string
  props: string
}

export type ModelBlockConditionInput = {
  type?: ModelStringInput | null
  props?: ModelStringInput | null
  and?: Array<ModelBlockConditionInput | null> | null
  or?: Array<ModelBlockConditionInput | null> | null
  not?: ModelBlockConditionInput | null
}

export type Block = {
  __typename: 'Block'
  id: string
  type: string
  props: string
  createdAt: string
  updatedAt: string
  owner?: string | null
}

export type UpdateBlockInput = {
  id: string
  type?: string | null
  props?: string | null
}

export type DeleteBlockInput = {
  id: string
}

export type CreateMintTransactionInput = {
  id?: string | null
  transactionHash: string
  from: string
  userID: string
  blockID: string
  createdAt?: string | null
}

export type ModelMintTransactionConditionInput = {
  transactionHash?: ModelStringInput | null
  from?: ModelStringInput | null
  blockID?: ModelIDInput | null
  createdAt?: ModelStringInput | null
  and?: Array<ModelMintTransactionConditionInput | null> | null
  or?: Array<ModelMintTransactionConditionInput | null> | null
  not?: ModelMintTransactionConditionInput | null
}

export type MintTransaction = {
  __typename: 'MintTransaction'
  id: string
  transactionHash: string
  from: string
  userID: string
  blockID: string
  createdAt: string
  updatedAt: string
  block?: Block | null
}

export type UpdateMintTransactionInput = {
  id: string
  transactionHash?: string | null
  from?: string | null
  userID?: string | null
  blockID?: string | null
  createdAt?: string | null
}

export type DeleteMintTransactionInput = {
  id: string
}

export type CreatePostInput = {
  id?: string | null
  title?: string | null
  description?: string | null
  websiteID?: string | null
  userID: string
  type?: PostType | null
  images?: string | null
  createdAt?: string | null
  prompt?: string | null
  status?: PostStatus | null
  pageID?: string | null
  componentID?: string | null
}

export enum PostType {
  COMPONENT = 'COMPONENT',
  TTM = 'TTM',
  POST = 'POST',
}

export enum PostStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type ModelPostConditionInput = {
  title?: ModelStringInput | null
  description?: ModelStringInput | null
  websiteID?: ModelIDInput | null
  userID?: ModelIDInput | null
  type?: ModelPostTypeInput | null
  images?: ModelStringInput | null
  createdAt?: ModelStringInput | null
  prompt?: ModelStringInput | null
  status?: ModelPostStatusInput | null
  pageID?: ModelIDInput | null
  componentID?: ModelIDInput | null
  and?: Array<ModelPostConditionInput | null> | null
  or?: Array<ModelPostConditionInput | null> | null
  not?: ModelPostConditionInput | null
}

export type ModelPostTypeInput = {
  eq?: PostType | null
  ne?: PostType | null
}

export type ModelPostStatusInput = {
  eq?: PostStatus | null
  ne?: PostStatus | null
}

export type Post = {
  __typename: 'Post'
  id: string
  title?: string | null
  description?: string | null
  websiteID?: string | null
  userID: string
  type?: PostType | null
  images?: string | null
  createdAt?: string | null
  prompt?: string | null
  status?: PostStatus | null
  pageID?: string | null
  componentID?: string | null
  updatedAt: string
  user?: UserProfile | null
  page?: Page | null
  owner?: string | null
}

export type UpdatePostInput = {
  id: string
  title?: string | null
  description?: string | null
  websiteID?: string | null
  userID?: string | null
  type?: PostType | null
  images?: string | null
  createdAt?: string | null
  prompt?: string | null
  status?: PostStatus | null
  pageID?: string | null
  componentID?: string | null
}

export type DeletePostInput = {
  id: string
}

export type CreatePostLikeInput = {
  id?: string | null
  userID: string
  postID: string
  like?: LikeStatus | null
  createdAt?: string | null
}

export enum LikeStatus {
  LIKE = 'LIKE',
  UNLIKE = 'UNLIKE',
}

export type ModelPostLikeConditionInput = {
  userID?: ModelIDInput | null
  postID?: ModelIDInput | null
  like?: ModelLikeStatusInput | null
  createdAt?: ModelStringInput | null
  and?: Array<ModelPostLikeConditionInput | null> | null
  or?: Array<ModelPostLikeConditionInput | null> | null
  not?: ModelPostLikeConditionInput | null
}

export type ModelLikeStatusInput = {
  eq?: LikeStatus | null
  ne?: LikeStatus | null
}

export type PostLike = {
  __typename: 'PostLike'
  id: string
  userID: string
  postID: string
  like?: LikeStatus | null
  createdAt?: string | null
  updatedAt: string
  user?: UserProfile | null
  post?: Post | null
  owner?: string | null
}

export type UpdatePostLikeInput = {
  id: string
  userID?: string | null
  postID?: string | null
  like?: LikeStatus | null
  createdAt?: string | null
}

export type DeletePostLikeInput = {
  id: string
}

export type CreatePostCommentInput = {
  id?: string | null
  userID: string
  postID: string
  content: string
  createdAt?: string | null
  status?: CommentStatus | null
}

export enum CommentStatus {
  ACTIVE = 'ACTIVE',
  EDITED = 'EDITED',
  DELETED = 'DELETED',
}

export type ModelPostCommentConditionInput = {
  userID?: ModelIDInput | null
  postID?: ModelIDInput | null
  content?: ModelStringInput | null
  createdAt?: ModelStringInput | null
  status?: ModelCommentStatusInput | null
  and?: Array<ModelPostCommentConditionInput | null> | null
  or?: Array<ModelPostCommentConditionInput | null> | null
  not?: ModelPostCommentConditionInput | null
}

export type ModelCommentStatusInput = {
  eq?: CommentStatus | null
  ne?: CommentStatus | null
}

export type PostComment = {
  __typename: 'PostComment'
  id: string
  userID: string
  postID: string
  content: string
  createdAt?: string | null
  status?: CommentStatus | null
  updatedAt: string
  user?: UserProfile | null
  post?: Post | null
  owner?: string | null
}

export type UpdatePostCommentInput = {
  id: string
  userID?: string | null
  postID?: string | null
  content?: string | null
  createdAt?: string | null
  status?: CommentStatus | null
}

export type DeletePostCommentInput = {
  id: string
}

export type CreateCommentLikeInput = {
  id?: string | null
  userID: string
  commentID: string
  like?: LikeStatus | null
  createdAt?: string | null
}

export type ModelCommentLikeConditionInput = {
  userID?: ModelIDInput | null
  commentID?: ModelIDInput | null
  like?: ModelLikeStatusInput | null
  createdAt?: ModelStringInput | null
  and?: Array<ModelCommentLikeConditionInput | null> | null
  or?: Array<ModelCommentLikeConditionInput | null> | null
  not?: ModelCommentLikeConditionInput | null
}

export type CommentLike = {
  __typename: 'CommentLike'
  id: string
  userID: string
  commentID: string
  like?: LikeStatus | null
  createdAt?: string | null
  updatedAt: string
  user?: UserProfile | null
  comment?: PostComment | null
  owner?: string | null
}

export type UpdateCommentLikeInput = {
  id: string
  userID?: string | null
  commentID?: string | null
  like?: LikeStatus | null
  createdAt?: string | null
}

export type DeleteCommentLikeInput = {
  id: string
}

export type CreateCommentReplyInput = {
  id?: string | null
  userID: string
  commentID: string
  content: string
  createdAt?: string | null
  status?: CommentStatus | null
}

export type ModelCommentReplyConditionInput = {
  userID?: ModelIDInput | null
  commentID?: ModelIDInput | null
  content?: ModelStringInput | null
  createdAt?: ModelStringInput | null
  status?: ModelCommentStatusInput | null
  and?: Array<ModelCommentReplyConditionInput | null> | null
  or?: Array<ModelCommentReplyConditionInput | null> | null
  not?: ModelCommentReplyConditionInput | null
}

export type CommentReply = {
  __typename: 'CommentReply'
  id: string
  userID: string
  commentID: string
  content: string
  createdAt?: string | null
  status?: CommentStatus | null
  updatedAt: string
  user?: UserProfile | null
  comment?: PostComment | null
  owner?: string | null
}

export type UpdateCommentReplyInput = {
  id: string
  userID?: string | null
  commentID?: string | null
  content?: string | null
  createdAt?: string | null
  status?: CommentStatus | null
}

export type DeleteCommentReplyInput = {
  id: string
}

export type CreateConversationInput = {
  id?: string | null
  convID: string
  userID: string
  status?: ConvoStatus | null
  lastActiveTime?: string | null
  lastMessageID?: string | null
  createdAt?: string | null
  chatmateID?: string | null
}

export enum ConvoStatus {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
  DELETED = 'DELETED',
  ARCHIVED = 'ARCHIVED',
}

export type ModelConversationConditionInput = {
  convID?: ModelIDInput | null
  status?: ModelConvoStatusInput | null
  lastActiveTime?: ModelStringInput | null
  lastMessageID?: ModelIDInput | null
  createdAt?: ModelStringInput | null
  chatmateID?: ModelIDInput | null
  and?: Array<ModelConversationConditionInput | null> | null
  or?: Array<ModelConversationConditionInput | null> | null
  not?: ModelConversationConditionInput | null
}

export type ModelConvoStatusInput = {
  eq?: ConvoStatus | null
  ne?: ConvoStatus | null
}

export type Conversation = {
  __typename: 'Conversation'
  id: string
  convID: string
  userID: string
  status?: ConvoStatus | null
  lastActiveTime: string
  lastMessageID?: string | null
  createdAt: string
  chatmateID?: string | null
  chatmate?: UserProfile | null
  lastMessage?: Message | null
}

export type Message = {
  __typename: 'Message'
  id: string
  senderID: string
  conversationID: string
  content: string
  type?: MessageType | null
  createdAt: string
  read?: boolean | null
  deleted?: boolean | null
  updatedAt: string
}

export enum MessageType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
}

export type UpdateConversationInput = {
  id: string
  convID?: string | null
  userID?: string | null
  status?: ConvoStatus | null
  lastActiveTime?: string | null
  lastMessageID?: string | null
  createdAt?: string | null
  chatmateID?: string | null
}

export type DeleteConversationInput = {
  id: string
}

export type CreateMessageInput = {
  id?: string | null
  senderID: string
  conversationID: string
  content: string
  type?: MessageType | null
  createdAt?: string | null
  read?: boolean | null
  deleted?: boolean | null
}

export type ModelMessageConditionInput = {
  conversationID?: ModelIDInput | null
  content?: ModelStringInput | null
  type?: ModelMessageTypeInput | null
  createdAt?: ModelStringInput | null
  read?: ModelBooleanInput | null
  deleted?: ModelBooleanInput | null
  and?: Array<ModelMessageConditionInput | null> | null
  or?: Array<ModelMessageConditionInput | null> | null
  not?: ModelMessageConditionInput | null
}

export type ModelMessageTypeInput = {
  eq?: MessageType | null
  ne?: MessageType | null
}

export type UpdateMessageInput = {
  id: string
  senderID?: string | null
  conversationID?: string | null
  content?: string | null
  type?: MessageType | null
  createdAt?: string | null
  read?: boolean | null
  deleted?: boolean | null
}

export type DeleteMessageInput = {
  id: string
}

export type CreateNotificationInput = {
  id?: string | null
  owner?: string | null
  toNotifyID?: string | null
  topic: Topics
  topicUrl?: string | null
  topicDescription?: string | null
  topicID?: string | null
  status?: NotificationStatus | null
  createdAt?: string | null
}

export enum Topics {
  NEW_POST_LIKE = 'NEW_POST_LIKE',
  NEW_POST_COMMENT = 'NEW_POST_COMMENT',
  NEW_MESSAGE = 'NEW_MESSAGE',
  NEW_COMMENT_REPLY = 'NEW_COMMENT_REPLY',
}

export enum NotificationStatus {
  READ = 'READ',
  UNREAD = 'UNREAD',
  DELETED = 'DELETED',
  NOTIFIED = 'NOTIFIED',
}

export type ModelNotificationConditionInput = {
  toNotifyID?: ModelIDInput | null
  topic?: ModelTopicsInput | null
  topicUrl?: ModelStringInput | null
  topicDescription?: ModelStringInput | null
  topicID?: ModelIDInput | null
  status?: ModelNotificationStatusInput | null
  createdAt?: ModelStringInput | null
  and?: Array<ModelNotificationConditionInput | null> | null
  or?: Array<ModelNotificationConditionInput | null> | null
  not?: ModelNotificationConditionInput | null
}

export type ModelTopicsInput = {
  eq?: Topics | null
  ne?: Topics | null
}

export type ModelNotificationStatusInput = {
  eq?: NotificationStatus | null
  ne?: NotificationStatus | null
}

export type Notification = {
  __typename: 'Notification'
  id: string
  owner?: string | null
  toNotifyID?: string | null
  topic: Topics
  topicUrl?: string | null
  topicDescription?: string | null
  topicID?: string | null
  status?: NotificationStatus | null
  createdAt?: string | null
  updatedAt: string
  creator?: UserProfile | null
  toNotify?: UserProfile | null
}

export type UpdateNotificationInput = {
  id: string
  owner?: string | null
  toNotifyID?: string | null
  topic?: Topics | null
  topicUrl?: string | null
  topicDescription?: string | null
  topicID?: string | null
  status?: NotificationStatus | null
  createdAt?: string | null
}

export type DeleteNotificationInput = {
  id: string
}

export type CreateImageInput = {
  id?: string | null
  userID: string
  title?: string | null
  prompt?: string | null
  type: ImageType
  url: string
  status?: ImageStatus | null
  createdAt?: string | null
}

export enum ImageType {
  UPLOAD = 'UPLOAD',
  TEXT_TO_IMAGE = 'TEXT_TO_IMAGE',
  IMAGE_TO_IMAGE = 'IMAGE_TO_IMAGE',
}

export enum ImageStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type ModelImageConditionInput = {
  userID?: ModelIDInput | null
  title?: ModelStringInput | null
  prompt?: ModelStringInput | null
  type?: ModelImageTypeInput | null
  url?: ModelStringInput | null
  status?: ModelImageStatusInput | null
  createdAt?: ModelStringInput | null
  and?: Array<ModelImageConditionInput | null> | null
  or?: Array<ModelImageConditionInput | null> | null
  not?: ModelImageConditionInput | null
}

export type ModelImageTypeInput = {
  eq?: ImageType | null
  ne?: ImageType | null
}

export type ModelImageStatusInput = {
  eq?: ImageStatus | null
  ne?: ImageStatus | null
}

export type Image = {
  __typename: 'Image'
  id: string
  userID: string
  title?: string | null
  prompt?: string | null
  type: ImageType
  url: string
  status?: ImageStatus | null
  createdAt?: string | null
  updatedAt: string
  user?: UserProfile | null
  owner?: string | null
}

export type UpdateImageInput = {
  id: string
  userID?: string | null
  title?: string | null
  prompt?: string | null
  type?: ImageType | null
  url?: string | null
  status?: ImageStatus | null
  createdAt?: string | null
}

export type DeleteImageInput = {
  id: string
}

export type ModelUserProfileFilterInput = {
  id?: ModelIDInput | null
  firstName?: ModelStringInput | null
  lastName?: ModelStringInput | null
  email?: ModelStringInput | null
  username?: ModelStringInput | null
  profilePicture?: ModelStringInput | null
  country?: ModelStringInput | null
  state?: ModelStringInput | null
  city?: ModelStringInput | null
  bio?: ModelStringInput | null
  config?: ModelStringInput | null
  and?: Array<ModelUserProfileFilterInput | null> | null
  or?: Array<ModelUserProfileFilterInput | null> | null
  not?: ModelUserProfileFilterInput | null
}

export type ModelUserProfileConnection = {
  __typename: 'ModelUserProfileConnection'
  items: Array<UserProfile | null>
  nextToken?: string | null
}

export enum ModelSortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type ModelWebsiteFilterInput = {
  id?: ModelIDInput | null
  name?: ModelStringInput | null
  config?: ModelStringInput | null
  userID?: ModelIDInput | null
  createdAt?: ModelStringInput | null
  menu?: ModelStringInput | null
  footer?: ModelStringInput | null
  pages?: ModelStringInput | null
  links?: ModelStringInput | null
  newMenu?: ModelStringInput | null
  newMenuConfig?: ModelStringInput | null
  newFooter?: ModelStringInput | null
  isPostedOnSocial?: ModelBooleanInput | null
  cloudfrontDistributionID?: ModelStringInput | null
  cloudfrontDomainName?: ModelStringInput | null
  cloudfrontAlias?: ModelStringInput | null
  gaTrackingID?: ModelStringInput | null
  and?: Array<ModelWebsiteFilterInput | null> | null
  or?: Array<ModelWebsiteFilterInput | null> | null
  not?: ModelWebsiteFilterInput | null
}

export type ModelWebsiteConnection = {
  __typename: 'ModelWebsiteConnection'
  items: Array<Website | null>
  nextToken?: string | null
}

export type ModelStringKeyConditionInput = {
  eq?: string | null
  le?: string | null
  lt?: string | null
  ge?: string | null
  gt?: string | null
  between?: Array<string | null> | null
  beginsWith?: string | null
}

export type ModelBuildRequestFilterInput = {
  id?: ModelIDInput | null
  status?: ModelBuildStatusInput | null
  websiteID?: ModelIDInput | null
  createdAt?: ModelStringInput | null
  userID?: ModelIDInput | null
  cloudfrontInvalidationID?: ModelStringInput | null
  and?: Array<ModelBuildRequestFilterInput | null> | null
  or?: Array<ModelBuildRequestFilterInput | null> | null
  not?: ModelBuildRequestFilterInput | null
}

export type ModelBuildRequestConnection = {
  __typename: 'ModelBuildRequestConnection'
  items: Array<BuildRequest | null>
  nextToken?: string | null
}

export type ModelPageFilterInput = {
  id?: ModelIDInput | null
  name?: ModelStringInput | null
  path?: ModelStringInput | null
  websiteID?: ModelIDInput | null
  blocks?: ModelStringInput | null
  createdAt?: ModelStringInput | null
  userID?: ModelIDInput | null
  and?: Array<ModelPageFilterInput | null> | null
  or?: Array<ModelPageFilterInput | null> | null
  not?: ModelPageFilterInput | null
}

export type ModelPageConnection = {
  __typename: 'ModelPageConnection'
  items: Array<Page | null>
  nextToken?: string | null
}

export type ModelBlockFilterInput = {
  id?: ModelIDInput | null
  type?: ModelStringInput | null
  props?: ModelStringInput | null
  and?: Array<ModelBlockFilterInput | null> | null
  or?: Array<ModelBlockFilterInput | null> | null
  not?: ModelBlockFilterInput | null
}

export type ModelBlockConnection = {
  __typename: 'ModelBlockConnection'
  items: Array<Block | null>
  nextToken?: string | null
}

export type ModelMintTransactionFilterInput = {
  id?: ModelIDInput | null
  transactionHash?: ModelStringInput | null
  from?: ModelStringInput | null
  userID?: ModelIDInput | null
  blockID?: ModelIDInput | null
  createdAt?: ModelStringInput | null
  and?: Array<ModelMintTransactionFilterInput | null> | null
  or?: Array<ModelMintTransactionFilterInput | null> | null
  not?: ModelMintTransactionFilterInput | null
}

export type ModelMintTransactionConnection = {
  __typename: 'ModelMintTransactionConnection'
  items: Array<MintTransaction | null>
  nextToken?: string | null
}

export type ModelPostFilterInput = {
  id?: ModelIDInput | null
  title?: ModelStringInput | null
  description?: ModelStringInput | null
  websiteID?: ModelIDInput | null
  userID?: ModelIDInput | null
  type?: ModelPostTypeInput | null
  images?: ModelStringInput | null
  createdAt?: ModelStringInput | null
  prompt?: ModelStringInput | null
  status?: ModelPostStatusInput | null
  pageID?: ModelIDInput | null
  componentID?: ModelIDInput | null
  and?: Array<ModelPostFilterInput | null> | null
  or?: Array<ModelPostFilterInput | null> | null
  not?: ModelPostFilterInput | null
}

export type ModelPostConnection = {
  __typename: 'ModelPostConnection'
  items: Array<Post | null>
  nextToken?: string | null
}

export type SearchablePostFilterInput = {
  id?: SearchableIDFilterInput | null
  title?: SearchableStringFilterInput | null
  description?: SearchableStringFilterInput | null
  websiteID?: SearchableIDFilterInput | null
  userID?: SearchableIDFilterInput | null
  images?: SearchableStringFilterInput | null
  createdAt?: SearchableStringFilterInput | null
  prompt?: SearchableStringFilterInput | null
  pageID?: SearchableIDFilterInput | null
  componentID?: SearchableIDFilterInput | null
  and?: Array<SearchablePostFilterInput | null> | null
  or?: Array<SearchablePostFilterInput | null> | null
  not?: SearchablePostFilterInput | null
}

export type SearchableIDFilterInput = {
  ne?: string | null
  gt?: string | null
  lt?: string | null
  gte?: string | null
  lte?: string | null
  eq?: string | null
  match?: string | null
  matchPhrase?: string | null
  matchPhrasePrefix?: string | null
  multiMatch?: string | null
  exists?: boolean | null
  wildcard?: string | null
  regexp?: string | null
  range?: Array<string | null> | null
}

export type SearchableStringFilterInput = {
  ne?: string | null
  gt?: string | null
  lt?: string | null
  gte?: string | null
  lte?: string | null
  eq?: string | null
  match?: string | null
  matchPhrase?: string | null
  matchPhrasePrefix?: string | null
  multiMatch?: string | null
  exists?: boolean | null
  wildcard?: string | null
  regexp?: string | null
  range?: Array<string | null> | null
}

export type SearchablePostSortInput = {
  field?: SearchablePostSortableFields | null
  direction?: SearchableSortDirection | null
}

export enum SearchablePostSortableFields {
  id = 'id',
  title = 'title',
  description = 'description',
  websiteID = 'websiteID',
  userID = 'userID',
  images = 'images',
  createdAt = 'createdAt',
  prompt = 'prompt',
  pageID = 'pageID',
  componentID = 'componentID',
}

export enum SearchableSortDirection {
  asc = 'asc',
  desc = 'desc',
}

export type SearchablePostConnection = {
  __typename: 'SearchablePostConnection'
  items: Array<Post | null>
  nextToken?: string | null
  total?: number | null
}

export type ModelPostLikeFilterInput = {
  id?: ModelIDInput | null
  userID?: ModelIDInput | null
  postID?: ModelIDInput | null
  like?: ModelLikeStatusInput | null
  createdAt?: ModelStringInput | null
  and?: Array<ModelPostLikeFilterInput | null> | null
  or?: Array<ModelPostLikeFilterInput | null> | null
  not?: ModelPostLikeFilterInput | null
}

export type ModelPostLikeConnection = {
  __typename: 'ModelPostLikeConnection'
  items: Array<PostLike | null>
  nextToken?: string | null
}

export type ModelPostCommentFilterInput = {
  id?: ModelIDInput | null
  userID?: ModelIDInput | null
  postID?: ModelIDInput | null
  content?: ModelStringInput | null
  createdAt?: ModelStringInput | null
  status?: ModelCommentStatusInput | null
  and?: Array<ModelPostCommentFilterInput | null> | null
  or?: Array<ModelPostCommentFilterInput | null> | null
  not?: ModelPostCommentFilterInput | null
}

export type ModelPostCommentConnection = {
  __typename: 'ModelPostCommentConnection'
  items: Array<PostComment | null>
  nextToken?: string | null
}

export type ModelCommentLikeFilterInput = {
  id?: ModelIDInput | null
  userID?: ModelIDInput | null
  commentID?: ModelIDInput | null
  like?: ModelLikeStatusInput | null
  createdAt?: ModelStringInput | null
  and?: Array<ModelCommentLikeFilterInput | null> | null
  or?: Array<ModelCommentLikeFilterInput | null> | null
  not?: ModelCommentLikeFilterInput | null
}

export type ModelCommentLikeConnection = {
  __typename: 'ModelCommentLikeConnection'
  items: Array<CommentLike | null>
  nextToken?: string | null
}

export type ModelCommentReplyFilterInput = {
  id?: ModelIDInput | null
  userID?: ModelIDInput | null
  commentID?: ModelIDInput | null
  content?: ModelStringInput | null
  createdAt?: ModelStringInput | null
  status?: ModelCommentStatusInput | null
  and?: Array<ModelCommentReplyFilterInput | null> | null
  or?: Array<ModelCommentReplyFilterInput | null> | null
  not?: ModelCommentReplyFilterInput | null
}

export type ModelCommentReplyConnection = {
  __typename: 'ModelCommentReplyConnection'
  items: Array<CommentReply | null>
  nextToken?: string | null
}

export type ModelConversationFilterInput = {
  id?: ModelIDInput | null
  convID?: ModelIDInput | null
  userID?: ModelIDInput | null
  status?: ModelConvoStatusInput | null
  lastActiveTime?: ModelStringInput | null
  lastMessageID?: ModelIDInput | null
  createdAt?: ModelStringInput | null
  chatmateID?: ModelIDInput | null
  and?: Array<ModelConversationFilterInput | null> | null
  or?: Array<ModelConversationFilterInput | null> | null
  not?: ModelConversationFilterInput | null
}

export type ModelConversationConnection = {
  __typename: 'ModelConversationConnection'
  items: Array<Conversation | null>
  nextToken?: string | null
}

export type ModelConversationByStatusCompositeKeyConditionInput = {
  eq?: ModelConversationByStatusCompositeKeyInput | null
  le?: ModelConversationByStatusCompositeKeyInput | null
  lt?: ModelConversationByStatusCompositeKeyInput | null
  ge?: ModelConversationByStatusCompositeKeyInput | null
  gt?: ModelConversationByStatusCompositeKeyInput | null
  between?: Array<ModelConversationByStatusCompositeKeyInput | null> | null
  beginsWith?: ModelConversationByStatusCompositeKeyInput | null
}

export type ModelConversationByStatusCompositeKeyInput = {
  userID?: string | null
  lastActiveTime?: string | null
}

export type ModelIDKeyConditionInput = {
  eq?: string | null
  le?: string | null
  lt?: string | null
  ge?: string | null
  gt?: string | null
  between?: Array<string | null> | null
  beginsWith?: string | null
}

export type ModelMessageFilterInput = {
  id?: ModelIDInput | null
  senderID?: ModelIDInput | null
  conversationID?: ModelIDInput | null
  content?: ModelStringInput | null
  type?: ModelMessageTypeInput | null
  createdAt?: ModelStringInput | null
  read?: ModelBooleanInput | null
  deleted?: ModelBooleanInput | null
  and?: Array<ModelMessageFilterInput | null> | null
  or?: Array<ModelMessageFilterInput | null> | null
  not?: ModelMessageFilterInput | null
}

export type ModelMessageConnection = {
  __typename: 'ModelMessageConnection'
  items: Array<Message | null>
  nextToken?: string | null
}

export type ModelNotificationFilterInput = {
  id?: ModelIDInput | null
  owner?: ModelIDInput | null
  toNotifyID?: ModelIDInput | null
  topic?: ModelTopicsInput | null
  topicUrl?: ModelStringInput | null
  topicDescription?: ModelStringInput | null
  topicID?: ModelIDInput | null
  status?: ModelNotificationStatusInput | null
  createdAt?: ModelStringInput | null
  and?: Array<ModelNotificationFilterInput | null> | null
  or?: Array<ModelNotificationFilterInput | null> | null
  not?: ModelNotificationFilterInput | null
}

export type ModelNotificationConnection = {
  __typename: 'ModelNotificationConnection'
  items: Array<Notification | null>
  nextToken?: string | null
}

export type ModelImageFilterInput = {
  id?: ModelIDInput | null
  userID?: ModelIDInput | null
  title?: ModelStringInput | null
  prompt?: ModelStringInput | null
  type?: ModelImageTypeInput | null
  url?: ModelStringInput | null
  status?: ModelImageStatusInput | null
  createdAt?: ModelStringInput | null
  and?: Array<ModelImageFilterInput | null> | null
  or?: Array<ModelImageFilterInput | null> | null
  not?: ModelImageFilterInput | null
}

export type ModelImageConnection = {
  __typename: 'ModelImageConnection'
  items: Array<Image | null>
  nextToken?: string | null
}

export type SearchableImageFilterInput = {
  id?: SearchableIDFilterInput | null
  userID?: SearchableIDFilterInput | null
  title?: SearchableStringFilterInput | null
  prompt?: SearchableStringFilterInput | null
  url?: SearchableStringFilterInput | null
  createdAt?: SearchableStringFilterInput | null
  and?: Array<SearchableImageFilterInput | null> | null
  or?: Array<SearchableImageFilterInput | null> | null
  not?: SearchableImageFilterInput | null
}

export type SearchableImageSortInput = {
  field?: SearchableImageSortableFields | null
  direction?: SearchableSortDirection | null
}

export enum SearchableImageSortableFields {
  id = 'id',
  userID = 'userID',
  title = 'title',
  prompt = 'prompt',
  url = 'url',
  createdAt = 'createdAt',
}

export type SearchableImageConnection = {
  __typename: 'SearchableImageConnection'
  items: Array<Image | null>
  nextToken?: string | null
  total?: number | null
}

export type CreateUserProfileMutationVariables = {
  input: CreateUserProfileInput
  condition?: ModelUserProfileConditionInput | null
}

export type CreateUserProfileMutation = {
  createUserProfile?: {
    __typename: 'UserProfile'
    id: string
    firstName: string
    lastName: string
    email: string
    profilePics?: {
      __typename: 'S3Object'
      bucket: string
      region: string
      key: string
    } | null
    username?: string | null
    profilePicture?: string | null
    country?: string | null
    state?: string | null
    city?: string | null
    bio?: string | null
    config?: string | null
    createdAt: string
    updatedAt: string
  } | null
}

export type UpdateUserProfileMutationVariables = {
  input: UpdateUserProfileInput
  condition?: ModelUserProfileConditionInput | null
}

export type UpdateUserProfileMutation = {
  updateUserProfile?: {
    __typename: 'UserProfile'
    id: string
    firstName: string
    lastName: string
    email: string
    profilePics?: {
      __typename: 'S3Object'
      bucket: string
      region: string
      key: string
    } | null
    username?: string | null
    profilePicture?: string | null
    country?: string | null
    state?: string | null
    city?: string | null
    bio?: string | null
    config?: string | null
    createdAt: string
    updatedAt: string
  } | null
}

export type DeleteUserProfileMutationVariables = {
  input: DeleteUserProfileInput
  condition?: ModelUserProfileConditionInput | null
}

export type DeleteUserProfileMutation = {
  deleteUserProfile?: {
    __typename: 'UserProfile'
    id: string
    firstName: string
    lastName: string
    email: string
    profilePics?: {
      __typename: 'S3Object'
      bucket: string
      region: string
      key: string
    } | null
    username?: string | null
    profilePicture?: string | null
    country?: string | null
    state?: string | null
    city?: string | null
    bio?: string | null
    config?: string | null
    createdAt: string
    updatedAt: string
  } | null
}

export type CreateWebsiteMutationVariables = {
  input: CreateWebsiteInput
  condition?: ModelWebsiteConditionInput | null
}

export type CreateWebsiteMutation = {
  createWebsite?: {
    __typename: 'Website'
    id: string
    name: string
    config: string
    userID: string
    createdAt: string
    menu?: string | null
    footer?: string | null
    pages?: string | null
    links?: string | null
    newMenu?: string | null
    newMenuConfig?: string | null
    newFooter?: string | null
    isPostedOnSocial?: boolean | null
    cloudfrontDistributionID?: string | null
    cloudfrontDomainName?: string | null
    cloudfrontAlias?: string | null
    gaTrackingID?: string | null
    updatedAt: string
  } | null
}

export type UpdateWebsiteMutationVariables = {
  input: UpdateWebsiteInput
  condition?: ModelWebsiteConditionInput | null
}

export type UpdateWebsiteMutation = {
  updateWebsite?: {
    __typename: 'Website'
    id: string
    name: string
    config: string
    userID: string
    createdAt: string
    menu?: string | null
    footer?: string | null
    pages?: string | null
    links?: string | null
    newMenu?: string | null
    newMenuConfig?: string | null
    newFooter?: string | null
    isPostedOnSocial?: boolean | null
    cloudfrontDistributionID?: string | null
    cloudfrontDomainName?: string | null
    cloudfrontAlias?: string | null
    gaTrackingID?: string | null
    updatedAt: string
  } | null
}

export type DeleteWebsiteMutationVariables = {
  input: DeleteWebsiteInput
  condition?: ModelWebsiteConditionInput | null
}

export type DeleteWebsiteMutation = {
  deleteWebsite?: {
    __typename: 'Website'
    id: string
    name: string
    config: string
    userID: string
    createdAt: string
    menu?: string | null
    footer?: string | null
    pages?: string | null
    links?: string | null
    newMenu?: string | null
    newMenuConfig?: string | null
    newFooter?: string | null
    isPostedOnSocial?: boolean | null
    cloudfrontDistributionID?: string | null
    cloudfrontDomainName?: string | null
    cloudfrontAlias?: string | null
    gaTrackingID?: string | null
    updatedAt: string
  } | null
}

export type CreateBuildRequestMutationVariables = {
  input: CreateBuildRequestInput
  condition?: ModelBuildRequestConditionInput | null
}

export type CreateBuildRequestMutation = {
  createBuildRequest?: {
    __typename: 'BuildRequest'
    id: string
    status: BuildStatus
    websiteID: string
    createdAt: string
    userID: string
    cloudfrontInvalidationID?: string | null
    updatedAt: string
  } | null
}

export type UpdateBuildRequestMutationVariables = {
  input: UpdateBuildRequestInput
  condition?: ModelBuildRequestConditionInput | null
}

export type UpdateBuildRequestMutation = {
  updateBuildRequest?: {
    __typename: 'BuildRequest'
    id: string
    status: BuildStatus
    websiteID: string
    createdAt: string
    userID: string
    cloudfrontInvalidationID?: string | null
    updatedAt: string
  } | null
}

export type DeleteBuildRequestMutationVariables = {
  input: DeleteBuildRequestInput
  condition?: ModelBuildRequestConditionInput | null
}

export type DeleteBuildRequestMutation = {
  deleteBuildRequest?: {
    __typename: 'BuildRequest'
    id: string
    status: BuildStatus
    websiteID: string
    createdAt: string
    userID: string
    cloudfrontInvalidationID?: string | null
    updatedAt: string
  } | null
}

export type CreatePageMutationVariables = {
  input: CreatePageInput
  condition?: ModelPageConditionInput | null
}

export type CreatePageMutation = {
  createPage?: {
    __typename: 'Page'
    id: string
    name: string
    path: string
    websiteID: string
    blocks: string
    createdAt: string
    userID: string
    updatedAt: string
  } | null
}

export type UpdatePageMutationVariables = {
  input: UpdatePageInput
  condition?: ModelPageConditionInput | null
}

export type UpdatePageMutation = {
  updatePage?: {
    __typename: 'Page'
    id: string
    name: string
    path: string
    websiteID: string
    blocks: string
    createdAt: string
    userID: string
    updatedAt: string
  } | null
}

export type DeletePageMutationVariables = {
  input: DeletePageInput
  condition?: ModelPageConditionInput | null
}

export type DeletePageMutation = {
  deletePage?: {
    __typename: 'Page'
    id: string
    name: string
    path: string
    websiteID: string
    blocks: string
    createdAt: string
    userID: string
    updatedAt: string
  } | null
}

export type CreateBlockMutationVariables = {
  input: CreateBlockInput
  condition?: ModelBlockConditionInput | null
}

export type CreateBlockMutation = {
  createBlock?: {
    __typename: 'Block'
    id: string
    type: string
    props: string
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type UpdateBlockMutationVariables = {
  input: UpdateBlockInput
  condition?: ModelBlockConditionInput | null
}

export type UpdateBlockMutation = {
  updateBlock?: {
    __typename: 'Block'
    id: string
    type: string
    props: string
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type DeleteBlockMutationVariables = {
  input: DeleteBlockInput
  condition?: ModelBlockConditionInput | null
}

export type DeleteBlockMutation = {
  deleteBlock?: {
    __typename: 'Block'
    id: string
    type: string
    props: string
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type CreateMintTransactionMutationVariables = {
  input: CreateMintTransactionInput
  condition?: ModelMintTransactionConditionInput | null
}

export type CreateMintTransactionMutation = {
  createMintTransaction?: {
    __typename: 'MintTransaction'
    id: string
    transactionHash: string
    from: string
    userID: string
    blockID: string
    createdAt: string
    updatedAt: string
    block?: {
      __typename: 'Block'
      id: string
      type: string
      props: string
      createdAt: string
      updatedAt: string
      owner?: string | null
    } | null
  } | null
}

export type UpdateMintTransactionMutationVariables = {
  input: UpdateMintTransactionInput
  condition?: ModelMintTransactionConditionInput | null
}

export type UpdateMintTransactionMutation = {
  updateMintTransaction?: {
    __typename: 'MintTransaction'
    id: string
    transactionHash: string
    from: string
    userID: string
    blockID: string
    createdAt: string
    updatedAt: string
    block?: {
      __typename: 'Block'
      id: string
      type: string
      props: string
      createdAt: string
      updatedAt: string
      owner?: string | null
    } | null
  } | null
}

export type DeleteMintTransactionMutationVariables = {
  input: DeleteMintTransactionInput
  condition?: ModelMintTransactionConditionInput | null
}

export type DeleteMintTransactionMutation = {
  deleteMintTransaction?: {
    __typename: 'MintTransaction'
    id: string
    transactionHash: string
    from: string
    userID: string
    blockID: string
    createdAt: string
    updatedAt: string
    block?: {
      __typename: 'Block'
      id: string
      type: string
      props: string
      createdAt: string
      updatedAt: string
      owner?: string | null
    } | null
  } | null
}

export type CreatePostMutationVariables = {
  input: CreatePostInput
  condition?: ModelPostConditionInput | null
}

export type CreatePostMutation = {
  createPost?: {
    __typename: 'Post'
    id: string
    title?: string | null
    description?: string | null
    websiteID?: string | null
    userID: string
    type?: PostType | null
    images?: string | null
    createdAt?: string | null
    prompt?: string | null
    status?: PostStatus | null
    pageID?: string | null
    componentID?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    page?: {
      __typename: 'Page'
      id: string
      name: string
      path: string
      websiteID: string
      blocks: string
      createdAt: string
      userID: string
      updatedAt: string
    } | null
    owner?: string | null
  } | null
}

export type UpdatePostMutationVariables = {
  input: UpdatePostInput
  condition?: ModelPostConditionInput | null
}

export type UpdatePostMutation = {
  updatePost?: {
    __typename: 'Post'
    id: string
    title?: string | null
    description?: string | null
    websiteID?: string | null
    userID: string
    type?: PostType | null
    images?: string | null
    createdAt?: string | null
    prompt?: string | null
    status?: PostStatus | null
    pageID?: string | null
    componentID?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    page?: {
      __typename: 'Page'
      id: string
      name: string
      path: string
      websiteID: string
      blocks: string
      createdAt: string
      userID: string
      updatedAt: string
    } | null
    owner?: string | null
  } | null
}

export type DeletePostMutationVariables = {
  input: DeletePostInput
  condition?: ModelPostConditionInput | null
}

export type DeletePostMutation = {
  deletePost?: {
    __typename: 'Post'
    id: string
    title?: string | null
    description?: string | null
    websiteID?: string | null
    userID: string
    type?: PostType | null
    images?: string | null
    createdAt?: string | null
    prompt?: string | null
    status?: PostStatus | null
    pageID?: string | null
    componentID?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    page?: {
      __typename: 'Page'
      id: string
      name: string
      path: string
      websiteID: string
      blocks: string
      createdAt: string
      userID: string
      updatedAt: string
    } | null
    owner?: string | null
  } | null
}

export type CreatePostLikeMutationVariables = {
  input: CreatePostLikeInput
  condition?: ModelPostLikeConditionInput | null
}

export type CreatePostLikeMutation = {
  createPostLike?: {
    __typename: 'PostLike'
    id: string
    userID: string
    postID: string
    like?: LikeStatus | null
    createdAt?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    post?: {
      __typename: 'Post'
      id: string
      title?: string | null
      description?: string | null
      websiteID?: string | null
      userID: string
      type?: PostType | null
      images?: string | null
      createdAt?: string | null
      prompt?: string | null
      status?: PostStatus | null
      pageID?: string | null
      componentID?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      page?: {
        __typename: 'Page'
        id: string
        name: string
        path: string
        websiteID: string
        blocks: string
        createdAt: string
        userID: string
        updatedAt: string
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type UpdatePostLikeMutationVariables = {
  input: UpdatePostLikeInput
  condition?: ModelPostLikeConditionInput | null
}

export type UpdatePostLikeMutation = {
  updatePostLike?: {
    __typename: 'PostLike'
    id: string
    userID: string
    postID: string
    like?: LikeStatus | null
    createdAt?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    post?: {
      __typename: 'Post'
      id: string
      title?: string | null
      description?: string | null
      websiteID?: string | null
      userID: string
      type?: PostType | null
      images?: string | null
      createdAt?: string | null
      prompt?: string | null
      status?: PostStatus | null
      pageID?: string | null
      componentID?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      page?: {
        __typename: 'Page'
        id: string
        name: string
        path: string
        websiteID: string
        blocks: string
        createdAt: string
        userID: string
        updatedAt: string
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type DeletePostLikeMutationVariables = {
  input: DeletePostLikeInput
  condition?: ModelPostLikeConditionInput | null
}

export type DeletePostLikeMutation = {
  deletePostLike?: {
    __typename: 'PostLike'
    id: string
    userID: string
    postID: string
    like?: LikeStatus | null
    createdAt?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    post?: {
      __typename: 'Post'
      id: string
      title?: string | null
      description?: string | null
      websiteID?: string | null
      userID: string
      type?: PostType | null
      images?: string | null
      createdAt?: string | null
      prompt?: string | null
      status?: PostStatus | null
      pageID?: string | null
      componentID?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      page?: {
        __typename: 'Page'
        id: string
        name: string
        path: string
        websiteID: string
        blocks: string
        createdAt: string
        userID: string
        updatedAt: string
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type CreatePostCommentMutationVariables = {
  input: CreatePostCommentInput
  condition?: ModelPostCommentConditionInput | null
}

export type CreatePostCommentMutation = {
  createPostComment?: {
    __typename: 'PostComment'
    id: string
    userID: string
    postID: string
    content: string
    createdAt?: string | null
    status?: CommentStatus | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    post?: {
      __typename: 'Post'
      id: string
      title?: string | null
      description?: string | null
      websiteID?: string | null
      userID: string
      type?: PostType | null
      images?: string | null
      createdAt?: string | null
      prompt?: string | null
      status?: PostStatus | null
      pageID?: string | null
      componentID?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      page?: {
        __typename: 'Page'
        id: string
        name: string
        path: string
        websiteID: string
        blocks: string
        createdAt: string
        userID: string
        updatedAt: string
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type UpdatePostCommentMutationVariables = {
  input: UpdatePostCommentInput
  condition?: ModelPostCommentConditionInput | null
}

export type UpdatePostCommentMutation = {
  updatePostComment?: {
    __typename: 'PostComment'
    id: string
    userID: string
    postID: string
    content: string
    createdAt?: string | null
    status?: CommentStatus | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    post?: {
      __typename: 'Post'
      id: string
      title?: string | null
      description?: string | null
      websiteID?: string | null
      userID: string
      type?: PostType | null
      images?: string | null
      createdAt?: string | null
      prompt?: string | null
      status?: PostStatus | null
      pageID?: string | null
      componentID?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      page?: {
        __typename: 'Page'
        id: string
        name: string
        path: string
        websiteID: string
        blocks: string
        createdAt: string
        userID: string
        updatedAt: string
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type DeletePostCommentMutationVariables = {
  input: DeletePostCommentInput
  condition?: ModelPostCommentConditionInput | null
}

export type DeletePostCommentMutation = {
  deletePostComment?: {
    __typename: 'PostComment'
    id: string
    userID: string
    postID: string
    content: string
    createdAt?: string | null
    status?: CommentStatus | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    post?: {
      __typename: 'Post'
      id: string
      title?: string | null
      description?: string | null
      websiteID?: string | null
      userID: string
      type?: PostType | null
      images?: string | null
      createdAt?: string | null
      prompt?: string | null
      status?: PostStatus | null
      pageID?: string | null
      componentID?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      page?: {
        __typename: 'Page'
        id: string
        name: string
        path: string
        websiteID: string
        blocks: string
        createdAt: string
        userID: string
        updatedAt: string
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type CreateCommentLikeMutationVariables = {
  input: CreateCommentLikeInput
  condition?: ModelCommentLikeConditionInput | null
}

export type CreateCommentLikeMutation = {
  createCommentLike?: {
    __typename: 'CommentLike'
    id: string
    userID: string
    commentID: string
    like?: LikeStatus | null
    createdAt?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    comment?: {
      __typename: 'PostComment'
      id: string
      userID: string
      postID: string
      content: string
      createdAt?: string | null
      status?: CommentStatus | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      post?: {
        __typename: 'Post'
        id: string
        title?: string | null
        description?: string | null
        websiteID?: string | null
        userID: string
        type?: PostType | null
        images?: string | null
        createdAt?: string | null
        prompt?: string | null
        status?: PostStatus | null
        pageID?: string | null
        componentID?: string | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type UpdateCommentLikeMutationVariables = {
  input: UpdateCommentLikeInput
  condition?: ModelCommentLikeConditionInput | null
}

export type UpdateCommentLikeMutation = {
  updateCommentLike?: {
    __typename: 'CommentLike'
    id: string
    userID: string
    commentID: string
    like?: LikeStatus | null
    createdAt?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    comment?: {
      __typename: 'PostComment'
      id: string
      userID: string
      postID: string
      content: string
      createdAt?: string | null
      status?: CommentStatus | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      post?: {
        __typename: 'Post'
        id: string
        title?: string | null
        description?: string | null
        websiteID?: string | null
        userID: string
        type?: PostType | null
        images?: string | null
        createdAt?: string | null
        prompt?: string | null
        status?: PostStatus | null
        pageID?: string | null
        componentID?: string | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type DeleteCommentLikeMutationVariables = {
  input: DeleteCommentLikeInput
  condition?: ModelCommentLikeConditionInput | null
}

export type DeleteCommentLikeMutation = {
  deleteCommentLike?: {
    __typename: 'CommentLike'
    id: string
    userID: string
    commentID: string
    like?: LikeStatus | null
    createdAt?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    comment?: {
      __typename: 'PostComment'
      id: string
      userID: string
      postID: string
      content: string
      createdAt?: string | null
      status?: CommentStatus | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      post?: {
        __typename: 'Post'
        id: string
        title?: string | null
        description?: string | null
        websiteID?: string | null
        userID: string
        type?: PostType | null
        images?: string | null
        createdAt?: string | null
        prompt?: string | null
        status?: PostStatus | null
        pageID?: string | null
        componentID?: string | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type CreateCommentReplyMutationVariables = {
  input: CreateCommentReplyInput
  condition?: ModelCommentReplyConditionInput | null
}

export type CreateCommentReplyMutation = {
  createCommentReply?: {
    __typename: 'CommentReply'
    id: string
    userID: string
    commentID: string
    content: string
    createdAt?: string | null
    status?: CommentStatus | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    comment?: {
      __typename: 'PostComment'
      id: string
      userID: string
      postID: string
      content: string
      createdAt?: string | null
      status?: CommentStatus | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      post?: {
        __typename: 'Post'
        id: string
        title?: string | null
        description?: string | null
        websiteID?: string | null
        userID: string
        type?: PostType | null
        images?: string | null
        createdAt?: string | null
        prompt?: string | null
        status?: PostStatus | null
        pageID?: string | null
        componentID?: string | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type UpdateCommentReplyMutationVariables = {
  input: UpdateCommentReplyInput
  condition?: ModelCommentReplyConditionInput | null
}

export type UpdateCommentReplyMutation = {
  updateCommentReply?: {
    __typename: 'CommentReply'
    id: string
    userID: string
    commentID: string
    content: string
    createdAt?: string | null
    status?: CommentStatus | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    comment?: {
      __typename: 'PostComment'
      id: string
      userID: string
      postID: string
      content: string
      createdAt?: string | null
      status?: CommentStatus | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      post?: {
        __typename: 'Post'
        id: string
        title?: string | null
        description?: string | null
        websiteID?: string | null
        userID: string
        type?: PostType | null
        images?: string | null
        createdAt?: string | null
        prompt?: string | null
        status?: PostStatus | null
        pageID?: string | null
        componentID?: string | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type DeleteCommentReplyMutationVariables = {
  input: DeleteCommentReplyInput
  condition?: ModelCommentReplyConditionInput | null
}

export type DeleteCommentReplyMutation = {
  deleteCommentReply?: {
    __typename: 'CommentReply'
    id: string
    userID: string
    commentID: string
    content: string
    createdAt?: string | null
    status?: CommentStatus | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    comment?: {
      __typename: 'PostComment'
      id: string
      userID: string
      postID: string
      content: string
      createdAt?: string | null
      status?: CommentStatus | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      post?: {
        __typename: 'Post'
        id: string
        title?: string | null
        description?: string | null
        websiteID?: string | null
        userID: string
        type?: PostType | null
        images?: string | null
        createdAt?: string | null
        prompt?: string | null
        status?: PostStatus | null
        pageID?: string | null
        componentID?: string | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type CreateConversationMutationVariables = {
  input: CreateConversationInput
  condition?: ModelConversationConditionInput | null
}

export type CreateConversationMutation = {
  createConversation?: {
    __typename: 'Conversation'
    id: string
    convID: string
    userID: string
    status?: ConvoStatus | null
    lastActiveTime: string
    lastMessageID?: string | null
    createdAt: string
    chatmateID?: string | null
    chatmate?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    lastMessage?: {
      __typename: 'Message'
      id: string
      senderID: string
      conversationID: string
      content: string
      type?: MessageType | null
      createdAt: string
      read?: boolean | null
      deleted?: boolean | null
      updatedAt: string
    } | null
  } | null
}

export type UpdateConversationMutationVariables = {
  input: UpdateConversationInput
  condition?: ModelConversationConditionInput | null
}

export type UpdateConversationMutation = {
  updateConversation?: {
    __typename: 'Conversation'
    id: string
    convID: string
    userID: string
    status?: ConvoStatus | null
    lastActiveTime: string
    lastMessageID?: string | null
    createdAt: string
    chatmateID?: string | null
    chatmate?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    lastMessage?: {
      __typename: 'Message'
      id: string
      senderID: string
      conversationID: string
      content: string
      type?: MessageType | null
      createdAt: string
      read?: boolean | null
      deleted?: boolean | null
      updatedAt: string
    } | null
  } | null
}

export type DeleteConversationMutationVariables = {
  input: DeleteConversationInput
  condition?: ModelConversationConditionInput | null
}

export type DeleteConversationMutation = {
  deleteConversation?: {
    __typename: 'Conversation'
    id: string
    convID: string
    userID: string
    status?: ConvoStatus | null
    lastActiveTime: string
    lastMessageID?: string | null
    createdAt: string
    chatmateID?: string | null
    chatmate?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    lastMessage?: {
      __typename: 'Message'
      id: string
      senderID: string
      conversationID: string
      content: string
      type?: MessageType | null
      createdAt: string
      read?: boolean | null
      deleted?: boolean | null
      updatedAt: string
    } | null
  } | null
}

export type CreateMessageMutationVariables = {
  input: CreateMessageInput
  condition?: ModelMessageConditionInput | null
}

export type CreateMessageMutation = {
  createMessage?: {
    __typename: 'Message'
    id: string
    senderID: string
    conversationID: string
    content: string
    type?: MessageType | null
    createdAt: string
    read?: boolean | null
    deleted?: boolean | null
    updatedAt: string
  } | null
}

export type UpdateMessageMutationVariables = {
  input: UpdateMessageInput
  condition?: ModelMessageConditionInput | null
}

export type UpdateMessageMutation = {
  updateMessage?: {
    __typename: 'Message'
    id: string
    senderID: string
    conversationID: string
    content: string
    type?: MessageType | null
    createdAt: string
    read?: boolean | null
    deleted?: boolean | null
    updatedAt: string
  } | null
}

export type DeleteMessageMutationVariables = {
  input: DeleteMessageInput
  condition?: ModelMessageConditionInput | null
}

export type DeleteMessageMutation = {
  deleteMessage?: {
    __typename: 'Message'
    id: string
    senderID: string
    conversationID: string
    content: string
    type?: MessageType | null
    createdAt: string
    read?: boolean | null
    deleted?: boolean | null
    updatedAt: string
  } | null
}

export type CreateNotificationMutationVariables = {
  input: CreateNotificationInput
  condition?: ModelNotificationConditionInput | null
}

export type CreateNotificationMutation = {
  createNotification?: {
    __typename: 'Notification'
    id: string
    owner?: string | null
    toNotifyID?: string | null
    topic: Topics
    topicUrl?: string | null
    topicDescription?: string | null
    topicID?: string | null
    status?: NotificationStatus | null
    createdAt?: string | null
    updatedAt: string
    creator?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    toNotify?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
  } | null
}

export type UpdateNotificationMutationVariables = {
  input: UpdateNotificationInput
  condition?: ModelNotificationConditionInput | null
}

export type UpdateNotificationMutation = {
  updateNotification?: {
    __typename: 'Notification'
    id: string
    owner?: string | null
    toNotifyID?: string | null
    topic: Topics
    topicUrl?: string | null
    topicDescription?: string | null
    topicID?: string | null
    status?: NotificationStatus | null
    createdAt?: string | null
    updatedAt: string
    creator?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    toNotify?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
  } | null
}

export type DeleteNotificationMutationVariables = {
  input: DeleteNotificationInput
  condition?: ModelNotificationConditionInput | null
}

export type DeleteNotificationMutation = {
  deleteNotification?: {
    __typename: 'Notification'
    id: string
    owner?: string | null
    toNotifyID?: string | null
    topic: Topics
    topicUrl?: string | null
    topicDescription?: string | null
    topicID?: string | null
    status?: NotificationStatus | null
    createdAt?: string | null
    updatedAt: string
    creator?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    toNotify?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
  } | null
}

export type CreateImageMutationVariables = {
  input: CreateImageInput
  condition?: ModelImageConditionInput | null
}

export type CreateImageMutation = {
  createImage?: {
    __typename: 'Image'
    id: string
    userID: string
    title?: string | null
    prompt?: string | null
    type: ImageType
    url: string
    status?: ImageStatus | null
    createdAt?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    owner?: string | null
  } | null
}

export type UpdateImageMutationVariables = {
  input: UpdateImageInput
  condition?: ModelImageConditionInput | null
}

export type UpdateImageMutation = {
  updateImage?: {
    __typename: 'Image'
    id: string
    userID: string
    title?: string | null
    prompt?: string | null
    type: ImageType
    url: string
    status?: ImageStatus | null
    createdAt?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    owner?: string | null
  } | null
}

export type DeleteImageMutationVariables = {
  input: DeleteImageInput
  condition?: ModelImageConditionInput | null
}

export type DeleteImageMutation = {
  deleteImage?: {
    __typename: 'Image'
    id: string
    userID: string
    title?: string | null
    prompt?: string | null
    type: ImageType
    url: string
    status?: ImageStatus | null
    createdAt?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    owner?: string | null
  } | null
}

export type GetUserProfileQueryVariables = {
  id: string
}

export type GetUserProfileQuery = {
  getUserProfile?: {
    __typename: 'UserProfile'
    id: string
    firstName: string
    lastName: string
    email: string
    profilePics?: {
      __typename: 'S3Object'
      bucket: string
      region: string
      key: string
    } | null
    username?: string | null
    profilePicture?: string | null
    country?: string | null
    state?: string | null
    city?: string | null
    bio?: string | null
    config?: string | null
    createdAt: string
    updatedAt: string
  } | null
}

export type ListUserProfilesQueryVariables = {
  filter?: ModelUserProfileFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListUserProfilesQuery = {
  listUserProfiles?: {
    __typename: 'ModelUserProfileConnection'
    items: Array<{
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type UserByUsernameQueryVariables = {
  username?: string | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelUserProfileFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type UserByUsernameQuery = {
  userByUsername?: {
    __typename: 'ModelUserProfileConnection'
    items: Array<{
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetWebsiteQueryVariables = {
  id: string
}

export type GetWebsiteQuery = {
  getWebsite?: {
    __typename: 'Website'
    id: string
    name: string
    config: string
    userID: string
    createdAt: string
    menu?: string | null
    footer?: string | null
    pages?: string | null
    links?: string | null
    newMenu?: string | null
    newMenuConfig?: string | null
    newFooter?: string | null
    isPostedOnSocial?: boolean | null
    cloudfrontDistributionID?: string | null
    cloudfrontDomainName?: string | null
    cloudfrontAlias?: string | null
    gaTrackingID?: string | null
    updatedAt: string
  } | null
}

export type ListWebsitesQueryVariables = {
  filter?: ModelWebsiteFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListWebsitesQuery = {
  listWebsites?: {
    __typename: 'ModelWebsiteConnection'
    items: Array<{
      __typename: 'Website'
      id: string
      name: string
      config: string
      userID: string
      createdAt: string
      menu?: string | null
      footer?: string | null
      pages?: string | null
      links?: string | null
      newMenu?: string | null
      newMenuConfig?: string | null
      newFooter?: string | null
      isPostedOnSocial?: boolean | null
      cloudfrontDistributionID?: string | null
      cloudfrontDomainName?: string | null
      cloudfrontAlias?: string | null
      gaTrackingID?: string | null
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetWebsiteByUserIDQueryVariables = {
  userID?: string | null
  createdAt?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelWebsiteFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetWebsiteByUserIDQuery = {
  getWebsiteByUserID?: {
    __typename: 'ModelWebsiteConnection'
    items: Array<{
      __typename: 'Website'
      id: string
      name: string
      config: string
      userID: string
      createdAt: string
      menu?: string | null
      footer?: string | null
      pages?: string | null
      links?: string | null
      newMenu?: string | null
      newMenuConfig?: string | null
      newFooter?: string | null
      isPostedOnSocial?: boolean | null
      cloudfrontDistributionID?: string | null
      cloudfrontDomainName?: string | null
      cloudfrontAlias?: string | null
      gaTrackingID?: string | null
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetWebsiteByCloudfrontAliasQueryVariables = {
  cloudfrontAlias?: string | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelWebsiteFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetWebsiteByCloudfrontAliasQuery = {
  getWebsiteByCloudfrontAlias?: {
    __typename: 'ModelWebsiteConnection'
    items: Array<{
      __typename: 'Website'
      id: string
      name: string
      config: string
      userID: string
      createdAt: string
      menu?: string | null
      footer?: string | null
      pages?: string | null
      links?: string | null
      newMenu?: string | null
      newMenuConfig?: string | null
      newFooter?: string | null
      isPostedOnSocial?: boolean | null
      cloudfrontDistributionID?: string | null
      cloudfrontDomainName?: string | null
      cloudfrontAlias?: string | null
      gaTrackingID?: string | null
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetBuildRequestQueryVariables = {
  id: string
}

export type GetBuildRequestQuery = {
  getBuildRequest?: {
    __typename: 'BuildRequest'
    id: string
    status: BuildStatus
    websiteID: string
    createdAt: string
    userID: string
    cloudfrontInvalidationID?: string | null
    updatedAt: string
  } | null
}

export type ListBuildRequestsQueryVariables = {
  filter?: ModelBuildRequestFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListBuildRequestsQuery = {
  listBuildRequests?: {
    __typename: 'ModelBuildRequestConnection'
    items: Array<{
      __typename: 'BuildRequest'
      id: string
      status: BuildStatus
      websiteID: string
      createdAt: string
      userID: string
      cloudfrontInvalidationID?: string | null
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetBuildRequestByWebsiteIDQueryVariables = {
  websiteID?: string | null
  createdAt?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelBuildRequestFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetBuildRequestByWebsiteIDQuery = {
  getBuildRequestByWebsiteID?: {
    __typename: 'ModelBuildRequestConnection'
    items: Array<{
      __typename: 'BuildRequest'
      id: string
      status: BuildStatus
      websiteID: string
      createdAt: string
      userID: string
      cloudfrontInvalidationID?: string | null
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetPageQueryVariables = {
  id: string
}

export type GetPageQuery = {
  getPage?: {
    __typename: 'Page'
    id: string
    name: string
    path: string
    websiteID: string
    blocks: string
    createdAt: string
    userID: string
    updatedAt: string
  } | null
}

export type ListPagesQueryVariables = {
  filter?: ModelPageFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListPagesQuery = {
  listPages?: {
    __typename: 'ModelPageConnection'
    items: Array<{
      __typename: 'Page'
      id: string
      name: string
      path: string
      websiteID: string
      blocks: string
      createdAt: string
      userID: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetPageByWebsiteIDQueryVariables = {
  websiteID?: string | null
  createdAt?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelPageFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetPageByWebsiteIDQuery = {
  getPageByWebsiteID?: {
    __typename: 'ModelPageConnection'
    items: Array<{
      __typename: 'Page'
      id: string
      name: string
      path: string
      websiteID: string
      blocks: string
      createdAt: string
      userID: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetBlockQueryVariables = {
  id: string
}

export type GetBlockQuery = {
  getBlock?: {
    __typename: 'Block'
    id: string
    type: string
    props: string
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type ListBlocksQueryVariables = {
  filter?: ModelBlockFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListBlocksQuery = {
  listBlocks?: {
    __typename: 'ModelBlockConnection'
    items: Array<{
      __typename: 'Block'
      id: string
      type: string
      props: string
      createdAt: string
      updatedAt: string
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetMintTransactionQueryVariables = {
  id: string
}

export type GetMintTransactionQuery = {
  getMintTransaction?: {
    __typename: 'MintTransaction'
    id: string
    transactionHash: string
    from: string
    userID: string
    blockID: string
    createdAt: string
    updatedAt: string
    block?: {
      __typename: 'Block'
      id: string
      type: string
      props: string
      createdAt: string
      updatedAt: string
      owner?: string | null
    } | null
  } | null
}

export type ListMintTransactionsQueryVariables = {
  filter?: ModelMintTransactionFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListMintTransactionsQuery = {
  listMintTransactions?: {
    __typename: 'ModelMintTransactionConnection'
    items: Array<{
      __typename: 'MintTransaction'
      id: string
      transactionHash: string
      from: string
      userID: string
      blockID: string
      createdAt: string
      updatedAt: string
      block?: {
        __typename: 'Block'
        id: string
        type: string
        props: string
        createdAt: string
        updatedAt: string
        owner?: string | null
      } | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetMintTransactionByUserQueryVariables = {
  userID?: string | null
  createdAt?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelMintTransactionFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetMintTransactionByUserQuery = {
  getMintTransactionByUser?: {
    __typename: 'ModelMintTransactionConnection'
    items: Array<{
      __typename: 'MintTransaction'
      id: string
      transactionHash: string
      from: string
      userID: string
      blockID: string
      createdAt: string
      updatedAt: string
      block?: {
        __typename: 'Block'
        id: string
        type: string
        props: string
        createdAt: string
        updatedAt: string
        owner?: string | null
      } | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetPostQueryVariables = {
  id: string
}

export type GetPostQuery = {
  getPost?: {
    __typename: 'Post'
    id: string
    title?: string | null
    description?: string | null
    websiteID?: string | null
    userID: string
    type?: PostType | null
    images?: string | null
    createdAt?: string | null
    prompt?: string | null
    status?: PostStatus | null
    pageID?: string | null
    componentID?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    page?: {
      __typename: 'Page'
      id: string
      name: string
      path: string
      websiteID: string
      blocks: string
      createdAt: string
      userID: string
      updatedAt: string
    } | null
    owner?: string | null
  } | null
}

export type ListPostsQueryVariables = {
  filter?: ModelPostFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListPostsQuery = {
  listPosts?: {
    __typename: 'ModelPostConnection'
    items: Array<{
      __typename: 'Post'
      id: string
      title?: string | null
      description?: string | null
      websiteID?: string | null
      userID: string
      type?: PostType | null
      images?: string | null
      createdAt?: string | null
      prompt?: string | null
      status?: PostStatus | null
      pageID?: string | null
      componentID?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      page?: {
        __typename: 'Page'
        id: string
        name: string
        path: string
        websiteID: string
        blocks: string
        createdAt: string
        userID: string
        updatedAt: string
      } | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type PostsByUserIdQueryVariables = {
  userID?: string | null
  createdAt?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelPostFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type PostsByUserIdQuery = {
  postsByUserId?: {
    __typename: 'ModelPostConnection'
    items: Array<{
      __typename: 'Post'
      id: string
      title?: string | null
      description?: string | null
      websiteID?: string | null
      userID: string
      type?: PostType | null
      images?: string | null
      createdAt?: string | null
      prompt?: string | null
      status?: PostStatus | null
      pageID?: string | null
      componentID?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      page?: {
        __typename: 'Page'
        id: string
        name: string
        path: string
        websiteID: string
        blocks: string
        createdAt: string
        userID: string
        updatedAt: string
      } | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type ListByPostTypeQueryVariables = {
  type?: PostType | null
  createdAt?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelPostFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListByPostTypeQuery = {
  listByPostType?: {
    __typename: 'ModelPostConnection'
    items: Array<{
      __typename: 'Post'
      id: string
      title?: string | null
      description?: string | null
      websiteID?: string | null
      userID: string
      type?: PostType | null
      images?: string | null
      createdAt?: string | null
      prompt?: string | null
      status?: PostStatus | null
      pageID?: string | null
      componentID?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      page?: {
        __typename: 'Page'
        id: string
        name: string
        path: string
        websiteID: string
        blocks: string
        createdAt: string
        userID: string
        updatedAt: string
      } | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type ListByPostStatusQueryVariables = {
  status?: PostStatus | null
  createdAt?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelPostFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListByPostStatusQuery = {
  listByPostStatus?: {
    __typename: 'ModelPostConnection'
    items: Array<{
      __typename: 'Post'
      id: string
      title?: string | null
      description?: string | null
      websiteID?: string | null
      userID: string
      type?: PostType | null
      images?: string | null
      createdAt?: string | null
      prompt?: string | null
      status?: PostStatus | null
      pageID?: string | null
      componentID?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      page?: {
        __typename: 'Page'
        id: string
        name: string
        path: string
        websiteID: string
        blocks: string
        createdAt: string
        userID: string
        updatedAt: string
      } | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type SearchPostsQueryVariables = {
  filter?: SearchablePostFilterInput | null
  sort?: SearchablePostSortInput | null
  limit?: number | null
  nextToken?: string | null
  from?: number | null
}

export type SearchPostsQuery = {
  searchPosts?: {
    __typename: 'SearchablePostConnection'
    items: Array<{
      __typename: 'Post'
      id: string
      title?: string | null
      description?: string | null
      websiteID?: string | null
      userID: string
      type?: PostType | null
      images?: string | null
      createdAt?: string | null
      prompt?: string | null
      status?: PostStatus | null
      pageID?: string | null
      componentID?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      page?: {
        __typename: 'Page'
        id: string
        name: string
        path: string
        websiteID: string
        blocks: string
        createdAt: string
        userID: string
        updatedAt: string
      } | null
      owner?: string | null
    } | null>
    nextToken?: string | null
    total?: number | null
  } | null
}

export type GetPostLikeQueryVariables = {
  id: string
}

export type GetPostLikeQuery = {
  getPostLike?: {
    __typename: 'PostLike'
    id: string
    userID: string
    postID: string
    like?: LikeStatus | null
    createdAt?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    post?: {
      __typename: 'Post'
      id: string
      title?: string | null
      description?: string | null
      websiteID?: string | null
      userID: string
      type?: PostType | null
      images?: string | null
      createdAt?: string | null
      prompt?: string | null
      status?: PostStatus | null
      pageID?: string | null
      componentID?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      page?: {
        __typename: 'Page'
        id: string
        name: string
        path: string
        websiteID: string
        blocks: string
        createdAt: string
        userID: string
        updatedAt: string
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type ListPostLikesQueryVariables = {
  filter?: ModelPostLikeFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListPostLikesQuery = {
  listPostLikes?: {
    __typename: 'ModelPostLikeConnection'
    items: Array<{
      __typename: 'PostLike'
      id: string
      userID: string
      postID: string
      like?: LikeStatus | null
      createdAt?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      post?: {
        __typename: 'Post'
        id: string
        title?: string | null
        description?: string | null
        websiteID?: string | null
        userID: string
        type?: PostType | null
        images?: string | null
        createdAt?: string | null
        prompt?: string | null
        status?: PostStatus | null
        pageID?: string | null
        componentID?: string | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type LikesByUserIdQueryVariables = {
  userID?: string | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelPostLikeFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type LikesByUserIdQuery = {
  likesByUserId?: {
    __typename: 'ModelPostLikeConnection'
    items: Array<{
      __typename: 'PostLike'
      id: string
      userID: string
      postID: string
      like?: LikeStatus | null
      createdAt?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      post?: {
        __typename: 'Post'
        id: string
        title?: string | null
        description?: string | null
        websiteID?: string | null
        userID: string
        type?: PostType | null
        images?: string | null
        createdAt?: string | null
        prompt?: string | null
        status?: PostStatus | null
        pageID?: string | null
        componentID?: string | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type LikesByPostIdQueryVariables = {
  postID?: string | null
  createdAt?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelPostLikeFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type LikesByPostIdQuery = {
  likesByPostId?: {
    __typename: 'ModelPostLikeConnection'
    items: Array<{
      __typename: 'PostLike'
      id: string
      userID: string
      postID: string
      like?: LikeStatus | null
      createdAt?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      post?: {
        __typename: 'Post'
        id: string
        title?: string | null
        description?: string | null
        websiteID?: string | null
        userID: string
        type?: PostType | null
        images?: string | null
        createdAt?: string | null
        prompt?: string | null
        status?: PostStatus | null
        pageID?: string | null
        componentID?: string | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetPostCommentQueryVariables = {
  id: string
}

export type GetPostCommentQuery = {
  getPostComment?: {
    __typename: 'PostComment'
    id: string
    userID: string
    postID: string
    content: string
    createdAt?: string | null
    status?: CommentStatus | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    post?: {
      __typename: 'Post'
      id: string
      title?: string | null
      description?: string | null
      websiteID?: string | null
      userID: string
      type?: PostType | null
      images?: string | null
      createdAt?: string | null
      prompt?: string | null
      status?: PostStatus | null
      pageID?: string | null
      componentID?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      page?: {
        __typename: 'Page'
        id: string
        name: string
        path: string
        websiteID: string
        blocks: string
        createdAt: string
        userID: string
        updatedAt: string
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type ListPostCommentsQueryVariables = {
  filter?: ModelPostCommentFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListPostCommentsQuery = {
  listPostComments?: {
    __typename: 'ModelPostCommentConnection'
    items: Array<{
      __typename: 'PostComment'
      id: string
      userID: string
      postID: string
      content: string
      createdAt?: string | null
      status?: CommentStatus | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      post?: {
        __typename: 'Post'
        id: string
        title?: string | null
        description?: string | null
        websiteID?: string | null
        userID: string
        type?: PostType | null
        images?: string | null
        createdAt?: string | null
        prompt?: string | null
        status?: PostStatus | null
        pageID?: string | null
        componentID?: string | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type CommentsByUserIdQueryVariables = {
  userID?: string | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelPostCommentFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type CommentsByUserIdQuery = {
  commentsByUserId?: {
    __typename: 'ModelPostCommentConnection'
    items: Array<{
      __typename: 'PostComment'
      id: string
      userID: string
      postID: string
      content: string
      createdAt?: string | null
      status?: CommentStatus | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      post?: {
        __typename: 'Post'
        id: string
        title?: string | null
        description?: string | null
        websiteID?: string | null
        userID: string
        type?: PostType | null
        images?: string | null
        createdAt?: string | null
        prompt?: string | null
        status?: PostStatus | null
        pageID?: string | null
        componentID?: string | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type CommentsByPostIdQueryVariables = {
  postID?: string | null
  createdAt?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelPostCommentFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type CommentsByPostIdQuery = {
  commentsByPostId?: {
    __typename: 'ModelPostCommentConnection'
    items: Array<{
      __typename: 'PostComment'
      id: string
      userID: string
      postID: string
      content: string
      createdAt?: string | null
      status?: CommentStatus | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      post?: {
        __typename: 'Post'
        id: string
        title?: string | null
        description?: string | null
        websiteID?: string | null
        userID: string
        type?: PostType | null
        images?: string | null
        createdAt?: string | null
        prompt?: string | null
        status?: PostStatus | null
        pageID?: string | null
        componentID?: string | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetCommentLikeQueryVariables = {
  id: string
}

export type GetCommentLikeQuery = {
  getCommentLike?: {
    __typename: 'CommentLike'
    id: string
    userID: string
    commentID: string
    like?: LikeStatus | null
    createdAt?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    comment?: {
      __typename: 'PostComment'
      id: string
      userID: string
      postID: string
      content: string
      createdAt?: string | null
      status?: CommentStatus | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      post?: {
        __typename: 'Post'
        id: string
        title?: string | null
        description?: string | null
        websiteID?: string | null
        userID: string
        type?: PostType | null
        images?: string | null
        createdAt?: string | null
        prompt?: string | null
        status?: PostStatus | null
        pageID?: string | null
        componentID?: string | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type ListCommentLikesQueryVariables = {
  filter?: ModelCommentLikeFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListCommentLikesQuery = {
  listCommentLikes?: {
    __typename: 'ModelCommentLikeConnection'
    items: Array<{
      __typename: 'CommentLike'
      id: string
      userID: string
      commentID: string
      like?: LikeStatus | null
      createdAt?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      comment?: {
        __typename: 'PostComment'
        id: string
        userID: string
        postID: string
        content: string
        createdAt?: string | null
        status?: CommentStatus | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type LikedCommentsByUserIdQueryVariables = {
  userID?: string | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelCommentLikeFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type LikedCommentsByUserIdQuery = {
  likedCommentsByUserId?: {
    __typename: 'ModelCommentLikeConnection'
    items: Array<{
      __typename: 'CommentLike'
      id: string
      userID: string
      commentID: string
      like?: LikeStatus | null
      createdAt?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      comment?: {
        __typename: 'PostComment'
        id: string
        userID: string
        postID: string
        content: string
        createdAt?: string | null
        status?: CommentStatus | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type LikesByPostCommentsIdQueryVariables = {
  commentID?: string | null
  createdAt?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelCommentLikeFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type LikesByPostCommentsIdQuery = {
  likesByPostCommentsId?: {
    __typename: 'ModelCommentLikeConnection'
    items: Array<{
      __typename: 'CommentLike'
      id: string
      userID: string
      commentID: string
      like?: LikeStatus | null
      createdAt?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      comment?: {
        __typename: 'PostComment'
        id: string
        userID: string
        postID: string
        content: string
        createdAt?: string | null
        status?: CommentStatus | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetCommentReplyQueryVariables = {
  id: string
}

export type GetCommentReplyQuery = {
  getCommentReply?: {
    __typename: 'CommentReply'
    id: string
    userID: string
    commentID: string
    content: string
    createdAt?: string | null
    status?: CommentStatus | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    comment?: {
      __typename: 'PostComment'
      id: string
      userID: string
      postID: string
      content: string
      createdAt?: string | null
      status?: CommentStatus | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      post?: {
        __typename: 'Post'
        id: string
        title?: string | null
        description?: string | null
        websiteID?: string | null
        userID: string
        type?: PostType | null
        images?: string | null
        createdAt?: string | null
        prompt?: string | null
        status?: PostStatus | null
        pageID?: string | null
        componentID?: string | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type ListCommentRepliesQueryVariables = {
  filter?: ModelCommentReplyFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListCommentRepliesQuery = {
  listCommentReplies?: {
    __typename: 'ModelCommentReplyConnection'
    items: Array<{
      __typename: 'CommentReply'
      id: string
      userID: string
      commentID: string
      content: string
      createdAt?: string | null
      status?: CommentStatus | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      comment?: {
        __typename: 'PostComment'
        id: string
        userID: string
        postID: string
        content: string
        createdAt?: string | null
        status?: CommentStatus | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type ReplyByuserIdQueryVariables = {
  userID?: string | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelCommentReplyFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ReplyByuserIdQuery = {
  replyByuserId?: {
    __typename: 'ModelCommentReplyConnection'
    items: Array<{
      __typename: 'CommentReply'
      id: string
      userID: string
      commentID: string
      content: string
      createdAt?: string | null
      status?: CommentStatus | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      comment?: {
        __typename: 'PostComment'
        id: string
        userID: string
        postID: string
        content: string
        createdAt?: string | null
        status?: CommentStatus | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type RepliesByCommentsIdQueryVariables = {
  commentID?: string | null
  createdAt?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelCommentReplyFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type RepliesByCommentsIdQuery = {
  repliesByCommentsId?: {
    __typename: 'ModelCommentReplyConnection'
    items: Array<{
      __typename: 'CommentReply'
      id: string
      userID: string
      commentID: string
      content: string
      createdAt?: string | null
      status?: CommentStatus | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      comment?: {
        __typename: 'PostComment'
        id: string
        userID: string
        postID: string
        content: string
        createdAt?: string | null
        status?: CommentStatus | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetConversationQueryVariables = {
  id: string
}

export type GetConversationQuery = {
  getConversation?: {
    __typename: 'Conversation'
    id: string
    convID: string
    userID: string
    status?: ConvoStatus | null
    lastActiveTime: string
    lastMessageID?: string | null
    createdAt: string
    chatmateID?: string | null
    chatmate?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    lastMessage?: {
      __typename: 'Message'
      id: string
      senderID: string
      conversationID: string
      content: string
      type?: MessageType | null
      createdAt: string
      read?: boolean | null
      deleted?: boolean | null
      updatedAt: string
    } | null
  } | null
}

export type ListConversationsQueryVariables = {
  filter?: ModelConversationFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListConversationsQuery = {
  listConversations?: {
    __typename: 'ModelConversationConnection'
    items: Array<{
      __typename: 'Conversation'
      id: string
      convID: string
      userID: string
      status?: ConvoStatus | null
      lastActiveTime: string
      lastMessageID?: string | null
      createdAt: string
      chatmateID?: string | null
      chatmate?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      lastMessage?: {
        __typename: 'Message'
        id: string
        senderID: string
        conversationID: string
        content: string
        type?: MessageType | null
        createdAt: string
        read?: boolean | null
        deleted?: boolean | null
        updatedAt: string
      } | null
    } | null>
    nextToken?: string | null
  } | null
}

export type ListConversationByStatusQueryVariables = {
  status?: ConvoStatus | null
  userIDLastActiveTime?: ModelConversationByStatusCompositeKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelConversationFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListConversationByStatusQuery = {
  listConversationByStatus?: {
    __typename: 'ModelConversationConnection'
    items: Array<{
      __typename: 'Conversation'
      id: string
      convID: string
      userID: string
      status?: ConvoStatus | null
      lastActiveTime: string
      lastMessageID?: string | null
      createdAt: string
      chatmateID?: string | null
      chatmate?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      lastMessage?: {
        __typename: 'Message'
        id: string
        senderID: string
        conversationID: string
        content: string
        type?: MessageType | null
        createdAt: string
        read?: boolean | null
        deleted?: boolean | null
        updatedAt: string
      } | null
    } | null>
    nextToken?: string | null
  } | null
}

export type ListConversationParticipantsQueryVariables = {
  convID?: string | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelConversationFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListConversationParticipantsQuery = {
  listConversationParticipants?: {
    __typename: 'ModelConversationConnection'
    items: Array<{
      __typename: 'Conversation'
      id: string
      convID: string
      userID: string
      status?: ConvoStatus | null
      lastActiveTime: string
      lastMessageID?: string | null
      createdAt: string
      chatmateID?: string | null
      chatmate?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      lastMessage?: {
        __typename: 'Message'
        id: string
        senderID: string
        conversationID: string
        content: string
        type?: MessageType | null
        createdAt: string
        read?: boolean | null
        deleted?: boolean | null
        updatedAt: string
      } | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetDirectConversationQueryVariables = {
  chatmateID?: string | null
  userID?: ModelIDKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelConversationFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetDirectConversationQuery = {
  getDirectConversation?: {
    __typename: 'ModelConversationConnection'
    items: Array<{
      __typename: 'Conversation'
      id: string
      convID: string
      userID: string
      status?: ConvoStatus | null
      lastActiveTime: string
      lastMessageID?: string | null
      createdAt: string
      chatmateID?: string | null
      chatmate?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      lastMessage?: {
        __typename: 'Message'
        id: string
        senderID: string
        conversationID: string
        content: string
        type?: MessageType | null
        createdAt: string
        read?: boolean | null
        deleted?: boolean | null
        updatedAt: string
      } | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetMessageQueryVariables = {
  id: string
}

export type GetMessageQuery = {
  getMessage?: {
    __typename: 'Message'
    id: string
    senderID: string
    conversationID: string
    content: string
    type?: MessageType | null
    createdAt: string
    read?: boolean | null
    deleted?: boolean | null
    updatedAt: string
  } | null
}

export type ListMessagesQueryVariables = {
  filter?: ModelMessageFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListMessagesQuery = {
  listMessages?: {
    __typename: 'ModelMessageConnection'
    items: Array<{
      __typename: 'Message'
      id: string
      senderID: string
      conversationID: string
      content: string
      type?: MessageType | null
      createdAt: string
      read?: boolean | null
      deleted?: boolean | null
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type ListMessagesByThreadQueryVariables = {
  conversationID?: string | null
  createdAt?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelMessageFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListMessagesByThreadQuery = {
  listMessagesByThread?: {
    __typename: 'ModelMessageConnection'
    items: Array<{
      __typename: 'Message'
      id: string
      senderID: string
      conversationID: string
      content: string
      type?: MessageType | null
      createdAt: string
      read?: boolean | null
      deleted?: boolean | null
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetNotificationQueryVariables = {
  id: string
}

export type GetNotificationQuery = {
  getNotification?: {
    __typename: 'Notification'
    id: string
    owner?: string | null
    toNotifyID?: string | null
    topic: Topics
    topicUrl?: string | null
    topicDescription?: string | null
    topicID?: string | null
    status?: NotificationStatus | null
    createdAt?: string | null
    updatedAt: string
    creator?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    toNotify?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
  } | null
}

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListNotificationsQuery = {
  listNotifications?: {
    __typename: 'ModelNotificationConnection'
    items: Array<{
      __typename: 'Notification'
      id: string
      owner?: string | null
      toNotifyID?: string | null
      topic: Topics
      topicUrl?: string | null
      topicDescription?: string | null
      topicID?: string | null
      status?: NotificationStatus | null
      createdAt?: string | null
      updatedAt: string
      creator?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      toNotify?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
    } | null>
    nextToken?: string | null
  } | null
}

export type ListByToNotifyIDQueryVariables = {
  toNotifyID?: string | null
  createdAt?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelNotificationFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListByToNotifyIDQuery = {
  listByToNotifyID?: {
    __typename: 'ModelNotificationConnection'
    items: Array<{
      __typename: 'Notification'
      id: string
      owner?: string | null
      toNotifyID?: string | null
      topic: Topics
      topicUrl?: string | null
      topicDescription?: string | null
      topicID?: string | null
      status?: NotificationStatus | null
      createdAt?: string | null
      updatedAt: string
      creator?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      toNotify?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetImageQueryVariables = {
  id: string
}

export type GetImageQuery = {
  getImage?: {
    __typename: 'Image'
    id: string
    userID: string
    title?: string | null
    prompt?: string | null
    type: ImageType
    url: string
    status?: ImageStatus | null
    createdAt?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    owner?: string | null
  } | null
}

export type ListImagesQueryVariables = {
  filter?: ModelImageFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListImagesQuery = {
  listImages?: {
    __typename: 'ModelImageConnection'
    items: Array<{
      __typename: 'Image'
      id: string
      userID: string
      title?: string | null
      prompt?: string | null
      type: ImageType
      url: string
      status?: ImageStatus | null
      createdAt?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type ImagesByUserIDQueryVariables = {
  userID?: string | null
  createdAt?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelImageFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ImagesByUserIDQuery = {
  imagesByUserID?: {
    __typename: 'ModelImageConnection'
    items: Array<{
      __typename: 'Image'
      id: string
      userID: string
      title?: string | null
      prompt?: string | null
      type: ImageType
      url: string
      status?: ImageStatus | null
      createdAt?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type ListByImageTypeQueryVariables = {
  type?: ImageType | null
  createdAt?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelImageFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListByImageTypeQuery = {
  listByImageType?: {
    __typename: 'ModelImageConnection'
    items: Array<{
      __typename: 'Image'
      id: string
      userID: string
      title?: string | null
      prompt?: string | null
      type: ImageType
      url: string
      status?: ImageStatus | null
      createdAt?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type ListByImageStatusQueryVariables = {
  status?: ImageStatus | null
  createdAt?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelImageFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListByImageStatusQuery = {
  listByImageStatus?: {
    __typename: 'ModelImageConnection'
    items: Array<{
      __typename: 'Image'
      id: string
      userID: string
      title?: string | null
      prompt?: string | null
      type: ImageType
      url: string
      status?: ImageStatus | null
      createdAt?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type SearchImagesQueryVariables = {
  filter?: SearchableImageFilterInput | null
  sort?: SearchableImageSortInput | null
  limit?: number | null
  nextToken?: string | null
  from?: number | null
}

export type SearchImagesQuery = {
  searchImages?: {
    __typename: 'SearchableImageConnection'
    items: Array<{
      __typename: 'Image'
      id: string
      userID: string
      title?: string | null
      prompt?: string | null
      type: ImageType
      url: string
      status?: ImageStatus | null
      createdAt?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      owner?: string | null
    } | null>
    nextToken?: string | null
    total?: number | null
  } | null
}

export type OnCreateUserProfileSubscription = {
  onCreateUserProfile?: {
    __typename: 'UserProfile'
    id: string
    firstName: string
    lastName: string
    email: string
    profilePics?: {
      __typename: 'S3Object'
      bucket: string
      region: string
      key: string
    } | null
    username?: string | null
    profilePicture?: string | null
    country?: string | null
    state?: string | null
    city?: string | null
    bio?: string | null
    config?: string | null
    createdAt: string
    updatedAt: string
  } | null
}

export type OnUpdateUserProfileSubscription = {
  onUpdateUserProfile?: {
    __typename: 'UserProfile'
    id: string
    firstName: string
    lastName: string
    email: string
    profilePics?: {
      __typename: 'S3Object'
      bucket: string
      region: string
      key: string
    } | null
    username?: string | null
    profilePicture?: string | null
    country?: string | null
    state?: string | null
    city?: string | null
    bio?: string | null
    config?: string | null
    createdAt: string
    updatedAt: string
  } | null
}

export type OnDeleteUserProfileSubscription = {
  onDeleteUserProfile?: {
    __typename: 'UserProfile'
    id: string
    firstName: string
    lastName: string
    email: string
    profilePics?: {
      __typename: 'S3Object'
      bucket: string
      region: string
      key: string
    } | null
    username?: string | null
    profilePicture?: string | null
    country?: string | null
    state?: string | null
    city?: string | null
    bio?: string | null
    config?: string | null
    createdAt: string
    updatedAt: string
  } | null
}

export type OnCreateWebsiteSubscription = {
  onCreateWebsite?: {
    __typename: 'Website'
    id: string
    name: string
    config: string
    userID: string
    createdAt: string
    menu?: string | null
    footer?: string | null
    pages?: string | null
    links?: string | null
    newMenu?: string | null
    newMenuConfig?: string | null
    newFooter?: string | null
    isPostedOnSocial?: boolean | null
    cloudfrontDistributionID?: string | null
    cloudfrontDomainName?: string | null
    cloudfrontAlias?: string | null
    gaTrackingID?: string | null
    updatedAt: string
  } | null
}

export type OnUpdateWebsiteSubscription = {
  onUpdateWebsite?: {
    __typename: 'Website'
    id: string
    name: string
    config: string
    userID: string
    createdAt: string
    menu?: string | null
    footer?: string | null
    pages?: string | null
    links?: string | null
    newMenu?: string | null
    newMenuConfig?: string | null
    newFooter?: string | null
    isPostedOnSocial?: boolean | null
    cloudfrontDistributionID?: string | null
    cloudfrontDomainName?: string | null
    cloudfrontAlias?: string | null
    gaTrackingID?: string | null
    updatedAt: string
  } | null
}

export type OnDeleteWebsiteSubscription = {
  onDeleteWebsite?: {
    __typename: 'Website'
    id: string
    name: string
    config: string
    userID: string
    createdAt: string
    menu?: string | null
    footer?: string | null
    pages?: string | null
    links?: string | null
    newMenu?: string | null
    newMenuConfig?: string | null
    newFooter?: string | null
    isPostedOnSocial?: boolean | null
    cloudfrontDistributionID?: string | null
    cloudfrontDomainName?: string | null
    cloudfrontAlias?: string | null
    gaTrackingID?: string | null
    updatedAt: string
  } | null
}

export type OnCreateBuildRequestSubscriptionVariables = {
  userID?: string | null
}

export type OnCreateBuildRequestSubscription = {
  onCreateBuildRequest?: {
    __typename: 'BuildRequest'
    id: string
    status: BuildStatus
    websiteID: string
    createdAt: string
    userID: string
    cloudfrontInvalidationID?: string | null
    updatedAt: string
  } | null
}

export type OnUpdateBuildRequestSubscriptionVariables = {
  userID?: string | null
}

export type OnUpdateBuildRequestSubscription = {
  onUpdateBuildRequest?: {
    __typename: 'BuildRequest'
    id: string
    status: BuildStatus
    websiteID: string
    createdAt: string
    userID: string
    cloudfrontInvalidationID?: string | null
    updatedAt: string
  } | null
}

export type OnDeleteBuildRequestSubscriptionVariables = {
  userID?: string | null
}

export type OnDeleteBuildRequestSubscription = {
  onDeleteBuildRequest?: {
    __typename: 'BuildRequest'
    id: string
    status: BuildStatus
    websiteID: string
    createdAt: string
    userID: string
    cloudfrontInvalidationID?: string | null
    updatedAt: string
  } | null
}

export type OnCreatePageSubscription = {
  onCreatePage?: {
    __typename: 'Page'
    id: string
    name: string
    path: string
    websiteID: string
    blocks: string
    createdAt: string
    userID: string
    updatedAt: string
  } | null
}

export type OnUpdatePageSubscription = {
  onUpdatePage?: {
    __typename: 'Page'
    id: string
    name: string
    path: string
    websiteID: string
    blocks: string
    createdAt: string
    userID: string
    updatedAt: string
  } | null
}

export type OnDeletePageSubscription = {
  onDeletePage?: {
    __typename: 'Page'
    id: string
    name: string
    path: string
    websiteID: string
    blocks: string
    createdAt: string
    userID: string
    updatedAt: string
  } | null
}

export type OnCreateBlockSubscription = {
  onCreateBlock?: {
    __typename: 'Block'
    id: string
    type: string
    props: string
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type OnUpdateBlockSubscription = {
  onUpdateBlock?: {
    __typename: 'Block'
    id: string
    type: string
    props: string
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type OnDeleteBlockSubscription = {
  onDeleteBlock?: {
    __typename: 'Block'
    id: string
    type: string
    props: string
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type OnCreateMintTransactionSubscriptionVariables = {
  userID?: string | null
}

export type OnCreateMintTransactionSubscription = {
  onCreateMintTransaction?: {
    __typename: 'MintTransaction'
    id: string
    transactionHash: string
    from: string
    userID: string
    blockID: string
    createdAt: string
    updatedAt: string
    block?: {
      __typename: 'Block'
      id: string
      type: string
      props: string
      createdAt: string
      updatedAt: string
      owner?: string | null
    } | null
  } | null
}

export type OnUpdateMintTransactionSubscriptionVariables = {
  userID?: string | null
}

export type OnUpdateMintTransactionSubscription = {
  onUpdateMintTransaction?: {
    __typename: 'MintTransaction'
    id: string
    transactionHash: string
    from: string
    userID: string
    blockID: string
    createdAt: string
    updatedAt: string
    block?: {
      __typename: 'Block'
      id: string
      type: string
      props: string
      createdAt: string
      updatedAt: string
      owner?: string | null
    } | null
  } | null
}

export type OnDeleteMintTransactionSubscriptionVariables = {
  userID?: string | null
}

export type OnDeleteMintTransactionSubscription = {
  onDeleteMintTransaction?: {
    __typename: 'MintTransaction'
    id: string
    transactionHash: string
    from: string
    userID: string
    blockID: string
    createdAt: string
    updatedAt: string
    block?: {
      __typename: 'Block'
      id: string
      type: string
      props: string
      createdAt: string
      updatedAt: string
      owner?: string | null
    } | null
  } | null
}

export type OnCreatePostSubscription = {
  onCreatePost?: {
    __typename: 'Post'
    id: string
    title?: string | null
    description?: string | null
    websiteID?: string | null
    userID: string
    type?: PostType | null
    images?: string | null
    createdAt?: string | null
    prompt?: string | null
    status?: PostStatus | null
    pageID?: string | null
    componentID?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    page?: {
      __typename: 'Page'
      id: string
      name: string
      path: string
      websiteID: string
      blocks: string
      createdAt: string
      userID: string
      updatedAt: string
    } | null
    owner?: string | null
  } | null
}

export type OnUpdatePostSubscription = {
  onUpdatePost?: {
    __typename: 'Post'
    id: string
    title?: string | null
    description?: string | null
    websiteID?: string | null
    userID: string
    type?: PostType | null
    images?: string | null
    createdAt?: string | null
    prompt?: string | null
    status?: PostStatus | null
    pageID?: string | null
    componentID?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    page?: {
      __typename: 'Page'
      id: string
      name: string
      path: string
      websiteID: string
      blocks: string
      createdAt: string
      userID: string
      updatedAt: string
    } | null
    owner?: string | null
  } | null
}

export type OnDeletePostSubscription = {
  onDeletePost?: {
    __typename: 'Post'
    id: string
    title?: string | null
    description?: string | null
    websiteID?: string | null
    userID: string
    type?: PostType | null
    images?: string | null
    createdAt?: string | null
    prompt?: string | null
    status?: PostStatus | null
    pageID?: string | null
    componentID?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    page?: {
      __typename: 'Page'
      id: string
      name: string
      path: string
      websiteID: string
      blocks: string
      createdAt: string
      userID: string
      updatedAt: string
    } | null
    owner?: string | null
  } | null
}

export type OnCreatePostLikeSubscription = {
  onCreatePostLike?: {
    __typename: 'PostLike'
    id: string
    userID: string
    postID: string
    like?: LikeStatus | null
    createdAt?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    post?: {
      __typename: 'Post'
      id: string
      title?: string | null
      description?: string | null
      websiteID?: string | null
      userID: string
      type?: PostType | null
      images?: string | null
      createdAt?: string | null
      prompt?: string | null
      status?: PostStatus | null
      pageID?: string | null
      componentID?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      page?: {
        __typename: 'Page'
        id: string
        name: string
        path: string
        websiteID: string
        blocks: string
        createdAt: string
        userID: string
        updatedAt: string
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnUpdatePostLikeSubscription = {
  onUpdatePostLike?: {
    __typename: 'PostLike'
    id: string
    userID: string
    postID: string
    like?: LikeStatus | null
    createdAt?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    post?: {
      __typename: 'Post'
      id: string
      title?: string | null
      description?: string | null
      websiteID?: string | null
      userID: string
      type?: PostType | null
      images?: string | null
      createdAt?: string | null
      prompt?: string | null
      status?: PostStatus | null
      pageID?: string | null
      componentID?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      page?: {
        __typename: 'Page'
        id: string
        name: string
        path: string
        websiteID: string
        blocks: string
        createdAt: string
        userID: string
        updatedAt: string
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnDeletePostLikeSubscription = {
  onDeletePostLike?: {
    __typename: 'PostLike'
    id: string
    userID: string
    postID: string
    like?: LikeStatus | null
    createdAt?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    post?: {
      __typename: 'Post'
      id: string
      title?: string | null
      description?: string | null
      websiteID?: string | null
      userID: string
      type?: PostType | null
      images?: string | null
      createdAt?: string | null
      prompt?: string | null
      status?: PostStatus | null
      pageID?: string | null
      componentID?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      page?: {
        __typename: 'Page'
        id: string
        name: string
        path: string
        websiteID: string
        blocks: string
        createdAt: string
        userID: string
        updatedAt: string
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnCreatePostCommentSubscription = {
  onCreatePostComment?: {
    __typename: 'PostComment'
    id: string
    userID: string
    postID: string
    content: string
    createdAt?: string | null
    status?: CommentStatus | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    post?: {
      __typename: 'Post'
      id: string
      title?: string | null
      description?: string | null
      websiteID?: string | null
      userID: string
      type?: PostType | null
      images?: string | null
      createdAt?: string | null
      prompt?: string | null
      status?: PostStatus | null
      pageID?: string | null
      componentID?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      page?: {
        __typename: 'Page'
        id: string
        name: string
        path: string
        websiteID: string
        blocks: string
        createdAt: string
        userID: string
        updatedAt: string
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnUpdatePostCommentSubscription = {
  onUpdatePostComment?: {
    __typename: 'PostComment'
    id: string
    userID: string
    postID: string
    content: string
    createdAt?: string | null
    status?: CommentStatus | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    post?: {
      __typename: 'Post'
      id: string
      title?: string | null
      description?: string | null
      websiteID?: string | null
      userID: string
      type?: PostType | null
      images?: string | null
      createdAt?: string | null
      prompt?: string | null
      status?: PostStatus | null
      pageID?: string | null
      componentID?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      page?: {
        __typename: 'Page'
        id: string
        name: string
        path: string
        websiteID: string
        blocks: string
        createdAt: string
        userID: string
        updatedAt: string
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnDeletePostCommentSubscription = {
  onDeletePostComment?: {
    __typename: 'PostComment'
    id: string
    userID: string
    postID: string
    content: string
    createdAt?: string | null
    status?: CommentStatus | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    post?: {
      __typename: 'Post'
      id: string
      title?: string | null
      description?: string | null
      websiteID?: string | null
      userID: string
      type?: PostType | null
      images?: string | null
      createdAt?: string | null
      prompt?: string | null
      status?: PostStatus | null
      pageID?: string | null
      componentID?: string | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      page?: {
        __typename: 'Page'
        id: string
        name: string
        path: string
        websiteID: string
        blocks: string
        createdAt: string
        userID: string
        updatedAt: string
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnCreateCommentLikeSubscription = {
  onCreateCommentLike?: {
    __typename: 'CommentLike'
    id: string
    userID: string
    commentID: string
    like?: LikeStatus | null
    createdAt?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    comment?: {
      __typename: 'PostComment'
      id: string
      userID: string
      postID: string
      content: string
      createdAt?: string | null
      status?: CommentStatus | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      post?: {
        __typename: 'Post'
        id: string
        title?: string | null
        description?: string | null
        websiteID?: string | null
        userID: string
        type?: PostType | null
        images?: string | null
        createdAt?: string | null
        prompt?: string | null
        status?: PostStatus | null
        pageID?: string | null
        componentID?: string | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnUpdateCommentLikeSubscription = {
  onUpdateCommentLike?: {
    __typename: 'CommentLike'
    id: string
    userID: string
    commentID: string
    like?: LikeStatus | null
    createdAt?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    comment?: {
      __typename: 'PostComment'
      id: string
      userID: string
      postID: string
      content: string
      createdAt?: string | null
      status?: CommentStatus | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      post?: {
        __typename: 'Post'
        id: string
        title?: string | null
        description?: string | null
        websiteID?: string | null
        userID: string
        type?: PostType | null
        images?: string | null
        createdAt?: string | null
        prompt?: string | null
        status?: PostStatus | null
        pageID?: string | null
        componentID?: string | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnDeleteCommentLikeSubscription = {
  onDeleteCommentLike?: {
    __typename: 'CommentLike'
    id: string
    userID: string
    commentID: string
    like?: LikeStatus | null
    createdAt?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    comment?: {
      __typename: 'PostComment'
      id: string
      userID: string
      postID: string
      content: string
      createdAt?: string | null
      status?: CommentStatus | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      post?: {
        __typename: 'Post'
        id: string
        title?: string | null
        description?: string | null
        websiteID?: string | null
        userID: string
        type?: PostType | null
        images?: string | null
        createdAt?: string | null
        prompt?: string | null
        status?: PostStatus | null
        pageID?: string | null
        componentID?: string | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnCreateCommentReplySubscription = {
  onCreateCommentReply?: {
    __typename: 'CommentReply'
    id: string
    userID: string
    commentID: string
    content: string
    createdAt?: string | null
    status?: CommentStatus | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    comment?: {
      __typename: 'PostComment'
      id: string
      userID: string
      postID: string
      content: string
      createdAt?: string | null
      status?: CommentStatus | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      post?: {
        __typename: 'Post'
        id: string
        title?: string | null
        description?: string | null
        websiteID?: string | null
        userID: string
        type?: PostType | null
        images?: string | null
        createdAt?: string | null
        prompt?: string | null
        status?: PostStatus | null
        pageID?: string | null
        componentID?: string | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnUpdateCommentReplySubscription = {
  onUpdateCommentReply?: {
    __typename: 'CommentReply'
    id: string
    userID: string
    commentID: string
    content: string
    createdAt?: string | null
    status?: CommentStatus | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    comment?: {
      __typename: 'PostComment'
      id: string
      userID: string
      postID: string
      content: string
      createdAt?: string | null
      status?: CommentStatus | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      post?: {
        __typename: 'Post'
        id: string
        title?: string | null
        description?: string | null
        websiteID?: string | null
        userID: string
        type?: PostType | null
        images?: string | null
        createdAt?: string | null
        prompt?: string | null
        status?: PostStatus | null
        pageID?: string | null
        componentID?: string | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnDeleteCommentReplySubscription = {
  onDeleteCommentReply?: {
    __typename: 'CommentReply'
    id: string
    userID: string
    commentID: string
    content: string
    createdAt?: string | null
    status?: CommentStatus | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    comment?: {
      __typename: 'PostComment'
      id: string
      userID: string
      postID: string
      content: string
      createdAt?: string | null
      status?: CommentStatus | null
      updatedAt: string
      user?: {
        __typename: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        email: string
        username?: string | null
        profilePicture?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        bio?: string | null
        config?: string | null
        createdAt: string
        updatedAt: string
      } | null
      post?: {
        __typename: 'Post'
        id: string
        title?: string | null
        description?: string | null
        websiteID?: string | null
        userID: string
        type?: PostType | null
        images?: string | null
        createdAt?: string | null
        prompt?: string | null
        status?: PostStatus | null
        pageID?: string | null
        componentID?: string | null
        updatedAt: string
        owner?: string | null
      } | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnCreateConversationSubscription = {
  onCreateConversation?: {
    __typename: 'Conversation'
    id: string
    convID: string
    userID: string
    status?: ConvoStatus | null
    lastActiveTime: string
    lastMessageID?: string | null
    createdAt: string
    chatmateID?: string | null
    chatmate?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    lastMessage?: {
      __typename: 'Message'
      id: string
      senderID: string
      conversationID: string
      content: string
      type?: MessageType | null
      createdAt: string
      read?: boolean | null
      deleted?: boolean | null
      updatedAt: string
    } | null
  } | null
}

export type OnUpdateConversationSubscription = {
  onUpdateConversation?: {
    __typename: 'Conversation'
    id: string
    convID: string
    userID: string
    status?: ConvoStatus | null
    lastActiveTime: string
    lastMessageID?: string | null
    createdAt: string
    chatmateID?: string | null
    chatmate?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    lastMessage?: {
      __typename: 'Message'
      id: string
      senderID: string
      conversationID: string
      content: string
      type?: MessageType | null
      createdAt: string
      read?: boolean | null
      deleted?: boolean | null
      updatedAt: string
    } | null
  } | null
}

export type OnDeleteConversationSubscription = {
  onDeleteConversation?: {
    __typename: 'Conversation'
    id: string
    convID: string
    userID: string
    status?: ConvoStatus | null
    lastActiveTime: string
    lastMessageID?: string | null
    createdAt: string
    chatmateID?: string | null
    chatmate?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    lastMessage?: {
      __typename: 'Message'
      id: string
      senderID: string
      conversationID: string
      content: string
      type?: MessageType | null
      createdAt: string
      read?: boolean | null
      deleted?: boolean | null
      updatedAt: string
    } | null
  } | null
}

export type OnCreateMessageSubscription = {
  onCreateMessage?: {
    __typename: 'Message'
    id: string
    senderID: string
    conversationID: string
    content: string
    type?: MessageType | null
    createdAt: string
    read?: boolean | null
    deleted?: boolean | null
    updatedAt: string
  } | null
}

export type OnUpdateMessageSubscription = {
  onUpdateMessage?: {
    __typename: 'Message'
    id: string
    senderID: string
    conversationID: string
    content: string
    type?: MessageType | null
    createdAt: string
    read?: boolean | null
    deleted?: boolean | null
    updatedAt: string
  } | null
}

export type OnDeleteMessageSubscription = {
  onDeleteMessage?: {
    __typename: 'Message'
    id: string
    senderID: string
    conversationID: string
    content: string
    type?: MessageType | null
    createdAt: string
    read?: boolean | null
    deleted?: boolean | null
    updatedAt: string
  } | null
}

export type OnCreateNotificationSubscription = {
  onCreateNotification?: {
    __typename: 'Notification'
    id: string
    owner?: string | null
    toNotifyID?: string | null
    topic: Topics
    topicUrl?: string | null
    topicDescription?: string | null
    topicID?: string | null
    status?: NotificationStatus | null
    createdAt?: string | null
    updatedAt: string
    creator?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    toNotify?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
  } | null
}

export type OnUpdateNotificationSubscription = {
  onUpdateNotification?: {
    __typename: 'Notification'
    id: string
    owner?: string | null
    toNotifyID?: string | null
    topic: Topics
    topicUrl?: string | null
    topicDescription?: string | null
    topicID?: string | null
    status?: NotificationStatus | null
    createdAt?: string | null
    updatedAt: string
    creator?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    toNotify?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
  } | null
}

export type OnDeleteNotificationSubscription = {
  onDeleteNotification?: {
    __typename: 'Notification'
    id: string
    owner?: string | null
    toNotifyID?: string | null
    topic: Topics
    topicUrl?: string | null
    topicDescription?: string | null
    topicID?: string | null
    status?: NotificationStatus | null
    createdAt?: string | null
    updatedAt: string
    creator?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    toNotify?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
  } | null
}

export type OnCreateImageSubscription = {
  onCreateImage?: {
    __typename: 'Image'
    id: string
    userID: string
    title?: string | null
    prompt?: string | null
    type: ImageType
    url: string
    status?: ImageStatus | null
    createdAt?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    owner?: string | null
  } | null
}

export type OnUpdateImageSubscription = {
  onUpdateImage?: {
    __typename: 'Image'
    id: string
    userID: string
    title?: string | null
    prompt?: string | null
    type: ImageType
    url: string
    status?: ImageStatus | null
    createdAt?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    owner?: string | null
  } | null
}

export type OnDeleteImageSubscription = {
  onDeleteImage?: {
    __typename: 'Image'
    id: string
    userID: string
    title?: string | null
    prompt?: string | null
    type: ImageType
    url: string
    status?: ImageStatus | null
    createdAt?: string | null
    updatedAt: string
    user?: {
      __typename: 'UserProfile'
      id: string
      firstName: string
      lastName: string
      email: string
      profilePics?: {
        __typename: 'S3Object'
        bucket: string
        region: string
        key: string
      } | null
      username?: string | null
      profilePicture?: string | null
      country?: string | null
      state?: string | null
      city?: string | null
      bio?: string | null
      config?: string | null
      createdAt: string
      updatedAt: string
    } | null
    owner?: string | null
  } | null
}
