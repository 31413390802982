// import { data } from "../../contstants";

import { motion } from 'framer-motion'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { FAQSection, FAQSectionItem } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantThree({ section }: { section: FAQSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const faqs = section.variables.find(variable => variable.name === 'ITEMS')?.data as FAQSectionItem[]

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
      }}
    >
      <div
        className="lg:py-[100px]"
        style={{
          ...generateSectionSpacing(section?.settings?.spacing),
        }}
      >
        <div className="md:container md:mx-auto ">
          <div className="flex items-center justify-center ">
            <h1 className="racking-wider md:text-4xl text-[26px]">
              <SectionSlatePreview initialValue={title} previewMode />
            </h1>
          </div>
          <div className="flex  justify-center items-center">
            <div className="grid grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 gap-3 items-start xl:gap-10 text-center">
              {faqs.map(items => {
                return (
                  <motion.div
                    initial={{ x: -40, opacity: 0.6 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    exit={{ x: 0 }}
                    transition={{ duration: 3, type: 'spring' }}
                    key={items.id}
                    className="md:w-[250px]  flex flex-col justify-between items-center mt-10 "
                  >
                    <div className="flex shadow-main items-center justify-center">
                      <img src={items?.image} className="w-[80px] h-[80px] rounded-[50%]" />
                      {/* <FcPicture className="text-[40px] text-[#333]"/> */}
                    </div>
                    <h2 className="font-semibold md:text-lg py-1">
                      <SectionSlatePreview initialValue={items?.title} previewMode />
                    </h2>
                    <p className="text-sm text-[gray] ">
                      <SectionSlatePreview initialValue={items?.description} previewMode />
                    </p>
                  </motion.div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
