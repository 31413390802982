import { useEffect } from 'react'
import SectionSlate from '../../../common/SectionSlatePreview'
import { TextSection as TextSectionType } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

function VariantEight({ section }: { section: TextSectionType }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const subText = subTitle[0]?.children[0]?.text
  const text = subText?.split(' ')

  useEffect(() => {
    console.log('variant 8', section)
    console.log('variant 8 title', title)
  }, [section, title])

  return (
    <>
      <div
        id="hood py-16"
        style={{
          ...generateSectionBackground({
            defaultColor: '#E4E1DF',
            backgroundSettings: section?.settings?.color?.background,
            image: null,
          }),
          ...generateSectionSpacing(section?.settings?.spacing),
        }}
      >
        <div className="hero px-5 lg:px-10">
          <div className="hero-content flex-col w-full justify-start">
            <div className="flex flex-row mb-6 w-full gap-1 lg:gap-8 text-lg text-[#2E4239] font-medium">
              {text?.map((data: string, i: number) => (
                <div className="flex gap-1 lg:gap-8 uppercase" key={i}>
                  {i > 0 && <p>•</p>}
                  <p>{data}</p>
                </div>
              ))}
            </div>
            <h1 className="lg:text-6xl w-full text-2xl text-[#2E4239] font-semibold flex items-center uppercase pb-6">
              <span>
                <SectionSlate initialValue={title} previewMode readOnly />
              </span>
            </h1>
            <div className="w-full">
              <p className="">
                <SectionSlate initialValue={paragraph} previewMode readOnly />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VariantEight
