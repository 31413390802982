import Icon from './icons'
import { IconPropsT } from './types'

function WriteMessage(props: IconPropsT) {
  const { title = 'WriteMessage', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 32 27" size={size} title={title} {...otherProps}>
      <g clipPath="url(#clip0_1105_2807)" fill="none">
        <path
          d="M22.1583 18.3712H15.0503C14.9246 18.3712 14.804 18.3212 14.7151 18.2323C14.6262 18.1434 14.5763 18.0229 14.5763 17.8972C14.5763 17.7714 14.6262 17.6508 14.7151 17.562C14.804 17.4731 14.9246 17.4232 15.0503 17.4232H22.1583C22.284 17.4232 22.4045 17.4731 22.4934 17.562C22.5823 17.6508 22.6323 17.7714 22.6323 17.8972C22.6323 18.0229 22.5823 18.1434 22.4934 18.2323C22.4045 18.3212 22.284 18.3712 22.1583 18.3712ZM22.0033 8.77112L12.5423 18.2321C12.4534 18.321 12.333 18.3711 12.2073 18.3712H9.83728C9.57528 18.3712 9.36426 17.8981 9.36426 17.8981V15.5281C9.36435 15.4025 9.41435 15.2819 9.50327 15.1931L18.9643 5.73212C19.3674 5.32899 19.9141 5.10254 20.4843 5.10254C21.0544 5.10254 21.6011 5.32899 22.0043 5.73212C22.4074 6.13525 22.6339 6.68203 22.6339 7.25214C22.6339 7.82225 22.4074 8.36903 22.0043 8.77216L22.0033 8.77112Z"
          fill="#494949"
          // stroke="black"
        />
        <path
          d="M28.5232 1.45007H3.47217C2.95794 1.44015 2.46076 1.63464 2.08975 1.99084C1.71874 2.34704 1.50419 2.83583 1.49316 3.35003V19.84C1.50419 20.3542 1.71874 20.8431 2.08975 21.1993C2.46076 21.5555 2.95794 21.75 3.47217 21.74H17.3162V24.9111C17.3177 24.996 17.336 25.0797 17.37 25.1576C17.404 25.2354 17.453 25.3059 17.5142 25.3647C17.5754 25.4236 17.6476 25.4698 17.7267 25.5008C17.8058 25.5317 17.8902 25.5468 17.9752 25.545C18.1048 25.5455 18.2318 25.5087 18.3412 25.4391L24.1062 21.7391H28.5232C29.0373 21.749 29.5344 21.5545 29.9052 21.1982C30.2761 20.842 30.4904 20.3532 30.5012 19.839V3.34906C30.4901 2.83512 30.2757 2.34655 29.9048 1.99054C29.534 1.63452 29.0371 1.44015 28.5232 1.45007Z"
          // stroke="black"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_1105_2807">
          <rect width="32" height="27" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  )
}

export default WriteMessage
