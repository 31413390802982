import Icon from './icons'
import { IconPropsT } from './types'

function ArrowRight(props: IconPropsT) {
  const { title = 'ArrowRight', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 8 14" size={size} title={title} {...otherProps}>
      <path
        d="M1 13L7 6.99506L0.999999 1"
        stroke="#D9D9D9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}

export default ArrowRight
