import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Close from 'src/icons/Close'
import Modal from '../common/Modal'

export interface ModalProps {
  open: any
  onBack?: () => void
  children: any
}

const ModalDialogBotNav = ({ open, onBack, children }: ModalProps) => {
  const currentLocation = useLocation()
  const [location] = useState(currentLocation)
  useEffect(() => {
    if (currentLocation !== location) onBack?.()
  }, [onBack, currentLocation, location])

  return (
    <Modal open={open}>
      <div className="bg-white dark:bg-black fixed lg:max-w-[500px] inset-0  overflow-y-auto ">
        <div className="flex items-center justify-end p-4">
          {onBack && (
            <button type="button" className="flex-shrink-0" onClick={onBack}>
              <Close
                size={25}
                fill="#D9D9D9"
                className="fill-white dark:fill-black stroke-black ml-auto dark:stroke-white cursor-pointer"
              />
            </button>
          )}
        </div>
        {children}
      </div>
    </Modal>
  )
}

export default ModalDialogBotNav
