import create from 'zustand'

type FeedStore = {
  posts: any[]
  setPosts: (posts: any[]) => void
  addPost: (post: any) => void
}

const useFeedStore = create<FeedStore>(
  (set): FeedStore => ({
    posts: [],
    setPosts: (posts: any[]) => set(state => ({ ...state, posts })),
    addPost: (post: any) => set(state => ({ ...state, posts: [post, ...state.posts] })),
  }),
)

export default useFeedStore
