import { useContext, useEffect, useState } from 'react'
import { WebsiteContext } from 'src/components/context/WebsiteContext'
import { Loader } from 'src/components/loader'
import Component from 'src/components/new/Component'
import Save from 'src/icons/Save'
import { getComponents } from 'src/services/website.service'
import { SectionPreviewRenderer } from 'src/_polly/components/src/sections'

type InPageComponentSelectorProps = {
  defaultValue?: string
  onSelect?: (path: string | undefined) => void
}

export default function InPageComponentSecletor({ defaultValue, onSelect }: InPageComponentSelectorProps) {
  const websiteContext = useContext(WebsiteContext)

  const [loading, setLoading] = useState(false)
  const [components, setComponents] = useState<any[]>([])

  const fetchComponents = async () => {
    setLoading(true)
    const comps = await getComponents(websiteContext.websiteID)
    setComponents(comps)
    setLoading(false)
  }

  useEffect(() => {
    fetchComponents()
  }, [])

  return (
    <div className="px-[18px]">
      <Loader show={loading} />

      <div className="mt-[16px] px-[8px]">
        <p className="text-[19px] dark:text-white border-black dark:border-[#D9D9D9] border-b pb-[14px]">
          In page Component
        </p>
        <p className="text-[19px] dark:text-white border-black dark:border-[#D9D9D9] border-b pt-[15px] pb-[20px] mb-[16px]">
          Select the component you would like to link to.
        </p>
      </div>

      {components
        .filter(comp => comp.type !== 'FOOTER' && comp.type !== 'MENU_BAR')
        .map(comp => (
          <div key={comp.id} className="mb-6 w-full max-h-full">
            {comp?.isSection && <SectionPreviewRenderer section={comp} disableHover noShadow />}
            {!comp?.isSection && <Component data={comp} />}

            <div className="flex items-center justify-end border-t border-b border-black dark:border-[#D9D9D9] mt-[2px]">
              <button type="button" className="py-[13px]" onClick={() => onSelect?.(`#${comp.id}`)}>
                <Save height={23} fill="none" />
                {defaultValue === comp.id && 'ed'}
              </button>
            </div>
          </div>
        ))}
    </div>
  )
}
