import React from 'react'
import { Link } from 'react-router-dom'
import Filter from 'src/icons/Filter'
import FollowersBox from 'src/icons/FollowersBox'

export default function SearchModal() {
  return (
    <div>
      <div className="w-[400px] md:w-[450px] px-4 py-10 bg-white gap-y-5 dark:bg-[#000000] flex flex-col items-center border-4 border-[#4E4E4E] dark:text-white rounded-lg text-center">
        <div className=" flex items-center space-x-5">
          <Filter size={33} className="text-brand-accent dark:text-white -ml-3" />
          <h1 className="capitalize tracking-wider text-2xl">search content </h1>
        </div>
        <div className="flex gap-x-8 ">
          <div className="flex flex-col items-center gap-3">
            <Link to="/">
              <FollowersBox size={64} className="hover:shadow-lg transition-all duration-300" />
            </Link>
            <h1 className="text-[14px]"> All Users</h1>
          </div>
          <div className=" flex flex-col items-center gap-3">
            <Link to="/">
              <FollowersBox size={64} className="hover:shadow-lg transition-all duration-300" />
            </Link>
            <h1 className="text-[14px]"> Followers</h1>
          </div>
        </div>
      </div>
    </div>
  )
}
