import { Link } from 'react-router-dom'
import UnauthenticatedPage from 'src/components/UnauthenticatedPage'

export default function LandingPage() {
  return (
    <UnauthenticatedPage>
      <div className="bg-black h-screen">
        <section className="flex justify-between items-center px-6 md:px-12 py-5 bg-white">
          <Link to="/">
            <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="22.5" cy="22.5" r="22.2" stroke="black" strokeWidth="0.6" />
              <circle cx="22.5004" cy="22.4999" r="14.1643" stroke="black" strokeWidth="0.6" />
              <circle cx="22.4996" cy="22.5001" r="7.73571" stroke="#0B0B0B" strokeWidth="0.6" />
            </svg>
          </Link>

          <div>
            <Link
              to="/login"
              className="w-[126px] block text-center py-1.5 border border-[#000000] rounded"
              type="button"
            >
              Login
            </Link>
          </div>
        </section>
        <section className="md:flex items-center justify-center bg-[#000000] text-[#FFFFFF] md:text-center md:h-[375px] px-6">
          <div className="flex justify-between items-center md:block md:space-y-16 h-[270px] md:h-auto pr-2">
            <div className="w-[150px] sm:w-[250px] md:w-full">
              <h1 className="text-[22px] md:text-[24px] uppercase">
                <span className="block md:inline">THE</span> DESIGN TOOL <span className="block md:inline">FROM</span>
                THE Future
              </h1>
            </div>
            <div className="text-end">
              <Link
                to="register"
                className="bg-brand-accent inline-block w-[130px] md:w-[200px] text-[14px] md:text-[20px] text-center py-1.5 rounded"
              >
                Sign Up
              </Link>
            </div>
          </div>
        </section>
        <div>
          <div className="h-[86px] bg-white" />
        </div>
        {/* button section  */}
        {/* <section className="flex justify-center items-center h-[300px] md:h-[375px]">
        <div className="md:flex md:space-x-8 lg:space-x-12 space-y-8 md:space-y-0 md:pb-[110px]">
          <button className="w-[200px] text-center py-1.5 rounded border border-black block" type="submit">
          Site builder
          </button>
          <button className="w-[200px] text-center py-1.5 rounded border border-black block" type="submit">
            Ai image creator
            </button>
            <button className="w-[200px] text-center py-1.5 rounded border border-black block" type="submit">
            Mint NFTs
            </button>
            </div>
          </section> */}
        {/* footer section  */}
        <div className="flex flex-col">
          <section className=" hidden md:flex bg-black text-[#FFFFFF] items-end justify-end md:h-[375px]">
            <p className="p-8">Created by Techboom Labs 2022</p>
          </section>
          <section className="flex md:hidden absolute bottom-0 right-0 bg-black text-[#FFFFFF] items-end justify-end md:h-[375px]">
            <p className="p-8">Created by Techboom Labs 2022</p>
          </section>
        </div>
      </div>
    </UnauthenticatedPage>
  )
}
