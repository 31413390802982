import clsx from 'clsx'
import { useContext, useEffect, useState } from 'react'
import { Descendant } from 'slate'

import { SubmitHandler, useForm } from 'react-hook-form'
import { ReactComponent as ImageIcon } from 'src/assets/icons/new/image.svg'
import BottomFloatingButton from 'src/components/BottomFloatingButton'
import { WebsiteContext } from 'src/components/context/WebsiteContext'
import ModalDialogV2 from 'src/components/new/ModalDialogV2'
import Vibing from 'src/icons/ComponentsIcons/Vibing'
import { upsertBlockByPageID } from 'src/services/website.service'
import {
  SectionVariableCategories,
  TESTIMONIALSection,
  TESTIMONIALSectionItem,
  TESTIMONIALVariables,
} from 'src/_polly/components/src/sections'
import { v4 as uuid } from 'uuid'
import { ReactComponent as DeleteIcon } from '../../assets/icons/menuBar/delete.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/menuBar/edit.svg'
import { AdvancedFeature } from '../modal/advanced'
import { MenuSaveButton } from '../modal/section/NewMenu/NewMenu'
import { EditSection } from './EditSectionModal'
import ImageUploader from './ImageUploader'
import SectionMediaField from './SectionField/SectionMediaField'
import SectionTextField from './SectionField/SectionTextField'
import ItemField from './SectionField/SectionSingleItemField'

interface EditSectionTESTIMONIALModalProps extends EditSection {
  section: TESTIMONIALSection
}

type EditTESTIMONIALProps = {
  data?: TESTIMONIALSectionItem
  onSave: (data: Partial<TESTIMONIALSectionItem>) => void
}

function Item({
  data,
  onDeleteClick,
  onEditClick,
}: {
  data: TESTIMONIALSectionItem
  onEditClick: () => void
  onDeleteClick: () => void
}) {
  return (
    <div className="border border-black dark:border-white h-[65px] flex justify-between">
      <div className="flex items-center">
        <div className="grid grid-cols-2 gap-1 ml-2 mr-4">
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
        </div>
        <div className="flex items-center gap-4">
          <div className="aspect-1 h-10">
            <img className="object-cover object-center bg-black w-full h-full" src={data.image} alt="" />
          </div>
          <p>
            <ItemField initialValue={data?.title1} />
          </p>
        </div>
      </div>

      <div className="flex">
        <button
          type="button"
          className="flex justify-center items-center w-16 border-l dark:border-white border-black"
          onClick={onEditClick}
        >
          <EditIcon />
        </button>
        <button
          type="button"
          className="flex justify-center items-center w-16 border-l dark:border-white border-black"
          onClick={onDeleteClick}
        >
          <DeleteIcon />
        </button>
      </div>
    </div>
  )
}

type FormInput = {
  title: any
  subtitle: any
  description: any
}

function EditTESTIMONIAL({ data, onSave }: EditTESTIMONIALProps) {
  const [showImageModal, setShowImageModal] = useState(false)
  const [url, setUrl] = useState(data?.image || '')

  const initialDataName: Descendant[] = [
    {
      type: 'paragraph',
      children: [
        {
          text: 'item name',
        },
      ],
    },
  ]
  const initialDataDescription: Descendant[] = [
    {
      type: 'paragraph',
      children: [
        {
          text: 'item description',
        },
      ],
    },
  ]
  const [titles, setTitle] = useState(data?.title1 || initialDataName)
  const [subtitles, setSubTitle] = useState(data?.subtitle1 || initialDataName)
  const [descriptions, setDescription] = useState(data?.description1 || initialDataDescription)
  const { handleSubmit } = useForm<FormInput>()

  const onSubmit: SubmitHandler<FormInput> = value => {
    onSave({
      ...value,
      id: data?.id,
      title1: titles,
      description1: descriptions,
      image: url,
      subtitle1: subtitles,
    })
    setSubTitle(value?.subtitle)
    setTitle(value?.title)
    setDescription(value?.description)
    setUrl(url)
  }

  return (
    <div className="px-4 dark:text-white">
      <form className="pb-24" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-8">
          <div>
            <SectionTextField
              title="Title"
              initialValue={titles}
              onChange={value => setTitle(value)}
              onUpdate={() => handleSubmit(onSubmit)}
            />
          </div>
          <div>
            <SectionTextField
              title="SubTitle"
              initialValue={subtitles}
              onChange={value => setSubTitle(value)}
              onUpdate={() => handleSubmit(onSubmit)}
            />
          </div>
          <div>
            <SectionTextField
              title="Description"
              initialValue={descriptions}
              onChange={value => setDescription(value)}
              onUpdate={() => handleSubmit(onSubmit)}
            />
          </div>
          <div className="space-y-4">
            <h2 className="text-[19px] font-semibold">Image</h2>
            <button type="button" className="w-full" onClick={() => setShowImageModal(true)}>
              <div
                className={clsx(
                  'w-full bg-white dark:bg-black',
                  !url && 'aspect-1 flex justify-center items-center border border-black dark:border-white',
                  url && 'aspect-1',
                )}
              >
                {url && <img src={url} className="object-cover object-center bg-black w-full h-full" alt="" />}
                {!url && <ImageIcon />}
              </div>
            </button>
          </div>
        </div>
      </form>

      <BottomFloatingButton label={data?.id ? 'Update' : 'Add'} onClick={handleSubmit(onSubmit)} disabled={!url} />

      <ModalDialogV2 open={showImageModal} onBack={() => setShowImageModal(false)}>
        <ImageUploader onSubmit={setUrl} buttonLabel="Select Image" onClose={() => setShowImageModal(false)} />
      </ModalDialogV2>
    </div>
  )
}

export default function EditSectionTestimonialModal({
  section: defaultValue,
  open,
  onClose: onBack,
  onUpdate,
}: EditSectionTESTIMONIALModalProps) {
  const websiteContext = useContext(WebsiteContext)

  const [section, setSection] = useState(defaultValue)

  const [showAddTESTIMONIALModal, setShowAddTESTIMONIALModal] = useState(false)
  const [editTESTIMONIAL, setEditTESTIMONIAL] = useState<TESTIMONIALSectionItem>()
  const [updating, setUpdating] = useState(false)
  const [fireUpdate, setFireUpdate] = useState({
    fire: false,
    goBack: false,
  })

  const title = section.variables.find(variable => variable.name === 'TITLE')
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')
  const menu = section.variables.find(variable => variable.name === 'ITEMS')
  const media = section.variables.find(variable => variable.name === 'MEDIA')
  const video = section.variables.find(variable => variable.name === 'VIDEO')

  const updateVariableData = (name: TESTIMONIALVariables, category: SectionVariableCategories, data: any) => {
    const current = section.variables.find(variable => variable.name === name)
    if (!current) {
      setSection({
        ...section,
        variables: [...section.variables, { name, data, category, id: uuid() }],
      })
      return
    }
    const otherVariables = section.variables.filter(variable => variable.name !== name)
    const newVariables = [...otherVariables, { ...current, data }]
    setSection({ ...section, variables: newVariables })
  }

  const handleDelete = (id: string) => {
    const newItems = menu?.data.filter((item: TESTIMONIALSectionItem) => item.id !== id)
    setSection({
      ...section,
      variables: section.variables.map(variable => {
        if (variable.name === 'ITEMS') {
          return { ...variable, data: newItems }
        }
        return variable
      }),
    })
    handleSave(false)
  }

  const handleEditAdd = (data: Partial<TESTIMONIALSectionItem>) => {
    if (data.id) {
      const newItems = menu?.data.map((item: TESTIMONIALSectionItem) => {
        if (item.id === data.id) {
          return { ...item, ...data }
        }
        return item
      })
      setSection({
        ...section,
        variables: section.variables.map(variable => {
          if (variable.name === 'ITEMS') {
            return { ...variable, data: newItems }
          }
          return variable
        }),
      })
    } else {
      setSection({
        ...section,
        variables: section.variables.map(variable => {
          if (variable.name === 'ITEMS') {
            return { ...variable, data: [...variable.data, { ...data, id: uuid() }] }
          }
          return variable
        }),
      })
    }
    setEditTESTIMONIAL(undefined)
    setShowAddTESTIMONIALModal(false)
    handleSave(false)
  }

  const handleSave = async (goBack = true) => {
    setFireUpdate({ fire: true, goBack })
  }

  useEffect(() => {
    const update = async (goBack = true) => {
      setUpdating(true)
      await upsertBlockByPageID(websiteContext.pageID, section as any)
      setUpdating(false)
      setFireUpdate({ fire: false, goBack: false })
      if (goBack) onBack()
    }
    if (fireUpdate.fire) update(fireUpdate.goBack)
  }, [fireUpdate, section])

  useEffect(() => {
    onUpdate?.(section)
  }, [section])

  return (
    <div>
      <ModalDialogV2 {...{ open, onBack }}>
        <div className="px-5  dark:text-white  mt-[25px]">
          <div className="border-t-[1px] border-black dark:border-white">
            {section.variant !== 'VARIANT_1' && (
              <div>
                <div>
                  <SectionTextField
                    title="Title"
                    initialValue={title?.data ?? []}
                    onChange={value => updateVariableData('TITLE', 'TEXT', value)}
                    onUpdate={() => handleSave(false)}
                  />
                </div>
                <div>
                  <SectionTextField
                    title="Subtitle"
                    initialValue={subTitle?.data ?? []}
                    onChange={value => updateVariableData('SUBTITLE', 'TEXT', value)}
                    onUpdate={() => handleSave(false)}
                  />
                </div>
              </div>
            )}

            {section.variant !== 'VARIANT_1' && section.variant !== 'VARIANT_2' ? (
              <div>
                <SectionMediaField
                  defaultValue={media?.data}
                  onChange={url => {
                    if (!url) {
                      setSection({
                        ...section,
                        variables: section.variables.filter(variable => variable.name !== 'MEDIA'),
                      })
                      return
                    }
                    updateVariableData('MEDIA', 'IMAGE', url)
                    handleSave(false)
                  }}
                />
              </div>
            ) : null}
            {section.variant === 'VARIANT_2' && (
              <div>
                <SectionMediaField
                  isVideo
                  defaultValue={video?.data}
                  onChange={url => {
                    if (!url) {
                      setSection({
                        ...section,
                        variables: section.variables.filter(variable => variable.name !== 'MEDIA'),
                      })
                      return
                    }
                    updateVariableData('VIDEO', 'VIDEO', url)
                    handleSave(false)
                  }}
                />
              </div>
            )}
            {section.variant === 'VARIANT_1' && (
              <div>
                {menu?.data && (
                  <div className="mt-12 space-y-4 pb-24 dark:text-white">
                    <h2 className="capitalize text-[19px] font-semibold ">Testimonials</h2>
                    <div className="space-y-5">
                      {menu?.data.map((item: TESTIMONIALSectionItem) => (
                        <Item
                          key={item.id}
                          data={item}
                          onDeleteClick={() => handleDelete(item.id)}
                          onEditClick={() => setEditTESTIMONIAL(item)}
                        />
                      ))}
                    </div>
                    <button
                      className="border border-black bg-black dark:border-white h-[65px] flex justify-between items-center text-lg px-6 w-full text-white"
                      type="button"
                      onClick={() => setShowAddTESTIMONIALModal(true)}
                    >
                      <span>Add TESTIMONIAL</span> <span className="text-4xl">+</span>
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="absolute top-[12px] left-[50%] translate-x-[-50%]">
          <Vibing width="auto" height={36} />
        </div>
        <div className="absolute top-[21px] right-[50px]">
          <button type="button" onClick={() => handleSave()} disabled={updating}>
            <MenuSaveButton save="save" />
          </button>
        </div>

        {updating && (
          <div className="fixed z-30 inset-0 lg:max-w-[500px] dark:bg-black/80 bg-white/80">
            <p className="flex items-center h-full justify-center w-full text-[19px] dark:text-white">Updating...</p>
          </div>
        )}

        <AdvancedFeature
          onBack={() => handleSave(false)}
          section={section}
          onChange={settings => setSection(s => ({ ...s, settings }))}
        />
      </ModalDialogV2>

      <ModalDialogV2
        open={editTESTIMONIAL !== undefined || showAddTESTIMONIALModal}
        onBack={() => (editTESTIMONIAL ? setEditTESTIMONIAL(undefined) : setShowAddTESTIMONIALModal(false))}
      >
        <EditTESTIMONIAL data={editTESTIMONIAL} onSave={handleEditAdd} />
      </ModalDialogV2>
    </div>
  )
}
