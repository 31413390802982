import { Template } from '.'
import { aboutUs, blog, contactUs, gallery, image, text } from './variants'

export const Template13: Template = {
  id: '13',
  pages: [
    {
      slug: '/',
      name: 'BLOG',
      blocks: [
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_8')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
              name: 'MEDIA',
              id: '69b1e83d-2dda-4198-b372-a61fa03d4890',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ color: '#000000', text: 'Isaiah Smith' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '2ded9eb0-c196-448c-b843-2598ee068a0b',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '520ac345-cdfa-40d6-b557-63acdff0c58f',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: '' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'f87cd37a-71f7-452a-919f-77695d22d14a',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_8',
          type: 'TEXT',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_15')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 30, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#E4E3E3', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Sub Title' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '27d1b690-707a-44d7-90c8-00bf2ca06930',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'MARGIN',
              id: 'a9e58e2d-dede-4541-972e-a20d41ebb3da',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'PADDING',
              id: '6bc22866-7c46-4f3e-9c9c-45bc3bd3bf11',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
              name: 'MEDIA',
              id: '388d5d2f-6d38-470e-8768-a140856be619',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: '96383269-79c5-4116-99e1-2ded7d10dae8',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: "Cheugy Site's Appeal" }], type: 'paragraph' }],
              name: 'TITLE',
              id: '6f9f5ef3-4bdb-447c-8830-a633d4b1a01e',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: '' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: '40c335dc-2433-48ec-888f-37b7b4b4df8b',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_15',
          type: 'TEXT',
        },
        {
          settings: {
            ...blog.find(i => i.variant === 'VARIANT_8')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '38506c78-9605-465e-978d-4eacc5377065',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Sub Title' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '298218f8-8cc1-46e5-b78c-d94a74f6c21d',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: 'fe79acc8-d19b-4b29-9c5f-ca259d653bba',
              category: 'TEXT',
            },
            {
              data: [
                {
                  date: '1 MAY 2016',
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-fdecce90-c259-469b-969a-f746ce3d27ff',
                  texts: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  name: [{ children: [{ text: 'Gucci Acquisition Success', bold: true }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#929292',
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: 'e6488ca8-0f5a-4bd4-92fd-296a3a7c351d',
                  title: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
                },
                {
                  date: '1 MAY 2016',
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-f82ff89e-188f-4c68-8416-38e9379436a2',
                  texts: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  name: [{ children: [{ text: 'Yeet Your Design', bold: true }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#908f8f',
                          text: 'We offer website performance, website speed, website accessibility, website security, website maintenance, website optimization, website analytics, website testing, website updates, website improvements, and website enhancements to ensure your site is top-notch.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '00272608-57d3-46af-a3a2-30a2ecf27154',
                  title: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
                },
              ],
              name: 'ITEMS',
              id: 'd231ca8b-e466-4f99-9c6d-7c3a0578dbe3',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  date: '1 MAY 2016',
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-fdecce90-c259-469b-969a-f746ce3d27ff',
                  texts: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  name: [{ children: [{ text: 'Gucci Acquisition Success', bold: true }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#929292',
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: 'e6488ca8-0f5a-4bd4-92fd-296a3a7c351d',
                  title: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
                },
                {
                  date: '1 MAY 2016',
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-f82ff89e-188f-4c68-8416-38e9379436a2',
                  texts: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  name: [{ children: [{ text: 'Yeet Your Design', bold: true }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#908f8f',
                          text: 'We offer website performance, website speed, website accessibility, website security, website maintenance, website optimization, website analytics, website testing, website updates, website improvements, and website enhancements to ensure your site is top-notch.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '00272608-57d3-46af-a3a2-30a2ecf27154',
                  title: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
                },
              ],
              name: 'ITEMS',
              id: '8f534591-f9ea-42f4-843c-e4ba7a662ee3',
              category: 'IMAGE',
            },
          ],
          variant: 'VARIANT_8',
          type: 'BLOG',
        },
        {
          settings: {
            ...blog.find(i => i.variant === 'VARIANT_8')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'adf2bfb5-dd48-4088-8c71-fbffd85c54a4',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Sub Title' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '34f6a230-d87d-4736-bf96-07c7b6d5c67a',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: '7a496b2b-582c-459d-bf8e-c920e987e9b1',
              category: 'TEXT',
            },
            {
              data: [
                {
                  date: '1 MAY 2016',
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-87cb29d6-4356-4d2f-b110-551a00758608',
                  texts: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  name: [{ children: [{ text: 'Fire Cottage Industry', bold: true }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#929292',
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '8a6bcb72-2c6d-48d6-be07-89a780822b65',
                  title: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
                },
                {
                  date: '1 MAY 2016',
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-516fa206-2533-44ba-b795-7b2f88f07a55',
                  texts: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  name: [{ children: [{ text: 'Fire Cottage Industry', bold: true }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#929292',
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: 'bf660046-0018-45b4-93eb-3e23c2aea648',
                  title: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
                },
              ],
              name: 'ITEMS',
              id: 'baafeece-c42c-4aea-bf87-6b3169173e14',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  date: '1 MAY 2016',
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-87cb29d6-4356-4d2f-b110-551a00758608',
                  texts: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  name: [{ children: [{ text: 'Fire Cottage Industry', bold: true }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#929292',
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '8a6bcb72-2c6d-48d6-be07-89a780822b65',
                  title: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
                },
                {
                  date: '1 MAY 2016',
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-516fa206-2533-44ba-b795-7b2f88f07a55',
                  texts: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  name: [{ children: [{ text: 'Fire Cottage Industry', bold: true }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#929292',
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: 'bf660046-0018-45b4-93eb-3e23c2aea648',
                  title: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
                },
              ],
              name: 'ITEMS',
              id: '34b39e4f-f200-421a-914c-72aa40272c71',
              category: 'IMAGE',
            },
          ],
          variant: 'VARIANT_8',
          type: 'BLOG',
        },
        {
          settings: {
            ...image.find(i => i.variant === 'VARIANT_7')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  subtitle: 'Static Site Generator',
                  description: '',
                  id: 'e5fe1135-4691-4f12-8ca6-5c4121f64259',
                  title: 'React',
                },
                {
                  subtitle: 'CSS Framework',
                  description: '',
                  id: '1c104a7a-5a9f-46ce-a4a9-a5a69a1b3c2f',
                  title: 'Tailwind',
                },
                {
                  subtitle: 'Animation',
                  description: '',
                  id: '4d9de2d7-4348-48bf-8379-fcfa57e79977',
                  title: 'Framer Motion',
                },
                {
                  subtitle: 'Headless CMS',
                  description: '',
                  id: '60387176-c584-4232-9168-9bb78b1feeb2',
                  title: 'Sanity',
                },
                { subtitle: 'Hosting', description: '', id: '0c223bac-8194-4db0-882f-c382491bc702', title: 'Vercel' },
              ],
              name: 'ITEMS',
              id: 'd66a744c-a8e6-47f8-8360-03f60c23ee40',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Snatched Crowdfunding UX' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '52bb76b8-e940-4f63-a0c8-1f01714d4ed3',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'UX Design for Crowdfunding Success' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'b21fb9bc-2d77-4315-82fe-de1c8070e9f1',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-bf6c1df6-f4d6-47c7-9cd9-4709e0ae9bd2',
              name: 'MEDIA',
              id: 'f0554388-b11d-4c4b-806c-16cd24f86084',
              category: 'IMAGE',
            },
            { data: [], name: 'PARAGRAPH', id: '827dfe0a-0fcc-4462-8127-d60fd16c8afa', category: 'TEXT' },
          ],
          variant: 'VARIANT_7',
          type: 'IMAGE_OMG',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_15')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#E4E3E3', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Sub Title' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '7c862a9f-14cc-44e2-aff1-76f785e7b253',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'MARGIN',
              id: '9a55623b-de11-4246-9290-e31c43f0feb4',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'PADDING',
              id: '4d8d26ac-e7a6-492e-9ea1-6020d8e2f2d8',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
              name: 'MEDIA',
              id: '2d74eb60-d686-4d61-a78e-ce21d751b55c',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: '229e936c-0344-409a-a92e-57e69678db8f',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'UX Design for Crowdfunding Success' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '2c44bb7a-b79a-4c6c-add8-de30dd370665',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ text: '' }], type: 'paragraph' },
                { children: [{ text: '' }], type: 'paragraph' },
              ],
              name: 'PARAGRAPH',
              id: '3d2f3803-1fc3-4d98-b4cb-e3deafe56424',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_15',
          type: 'TEXT',
        },
        {
          settings: {
            ...blog.find(i => i.variant === 'VARIANT_8')?.settings,
          },
          variables: [
            {
              data: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '6f7df887-bf85-43ff-9dcb-933964c4e91d',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Sub Title' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '405c3582-ac37-43fd-8d6b-1b098c0ca99f',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: '010d9a76-10d3-49aa-a7b0-4cddf062331f',
              category: 'TEXT',
            },
            {
              data: [
                {
                  date: '1 MAY 2016',
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-8dca73f5-d998-48e2-8a04-378b870fce69',
                  texts: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  name: [{ children: [{ bold: true, text: "Cheugy Site's Appeal" }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#6d6d6d',
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: 'b56d2972-52ba-40a0-b365-9fe9c2ef6064',
                  title: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
                },
                {
                  date: '1 MAY 2016',
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-28428d94-2b80-4376-946e-62286bc8ff4b',
                  texts: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  name: [{ children: [{ bold: true, text: "Cheugy Site's Appeal" }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#6d6d6d',
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: 'b83fb6aa-f0eb-401f-896e-9f3c6385ecd5',
                  title: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
                },
              ],
              name: 'ITEMS',
              id: '5285e4e2-b2fe-4a4c-ab35-54837be99d32',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  date: '1 MAY 2016',
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-8dca73f5-d998-48e2-8a04-378b870fce69',
                  texts: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  name: [{ children: [{ bold: true, text: "Cheugy Site's Appeal" }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#6d6d6d',
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: 'b56d2972-52ba-40a0-b365-9fe9c2ef6064',
                  title: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
                },
                {
                  date: '1 MAY 2016',
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-28428d94-2b80-4376-946e-62286bc8ff4b',
                  texts: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  name: [{ children: [{ bold: true, text: "Cheugy Site's Appeal" }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#6d6d6d',
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: 'b83fb6aa-f0eb-401f-896e-9f3c6385ecd5',
                  title: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
                },
              ],
              name: 'ITEMS',
              id: '3cbcc365-34a0-45d9-8acb-264aa4fcb037',
              category: 'IMAGE',
            },
          ],
          variant: 'VARIANT_8',
          type: 'BLOG',
        },
        {
          settings: {
            ...image.find(i => i.variant === 'VARIANT_4')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  subtitle: 'Static Site Generator',
                  description: '',
                  id: '75e88643-2083-4c25-8e47-1a4c453dbc19',
                  title: 'React',
                },
                {
                  subtitle: 'CSS Framework',
                  description: '',
                  id: '0295eb35-d2ec-46ed-b146-89863048e6ed',
                  title: 'Tailwind',
                },
                {
                  subtitle: 'Animation',
                  description: '',
                  id: 'eefc27b8-a245-4f68-b456-351188fd072a',
                  title: 'Framer Motion',
                },
                {
                  subtitle: 'Headless CMS',
                  description: '',
                  id: '9c7a61b6-d0e2-423d-9084-64e8d447b046',
                  title: 'Sanity',
                },
                { subtitle: 'Hosting', description: '', id: 'c1109531-45ca-448b-a75a-6e13fd92b724', title: 'Vercel' },
              ],
              name: 'ITEMS',
              id: '8a558f9b-4991-40ca-bd62-654563b3c53a',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-a10c0a8c-0165-43fe-a2c2-e1c74b9cf74b',
              name: 'MEDIA',
              id: '0e682096-0841-49b2-9ae6-8683e302b621',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ color: '#fffafa', text: 'Slay Your Startup' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '556145cc-3d9c-4828-b520-7c565332c0d4',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Accelerate Your Startup Growth' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '302896c7-b626-433d-bf11-8b6d605de7ce',
              category: 'TEXT',
            },
            { data: [], name: 'PARAGRAPH', id: '5f43013a-6b85-4e26-8afd-afa76d222adc', category: 'TEXT' },
          ],
          variant: 'VARIANT_4',
          type: 'IMAGE_OMG',
        },
      ],
    },
    {
      slug: '/about-us',
      name: 'ABOUT',
      blocks: [
        {
          settings: {
            ...aboutUs.find(i => i.variant === 'VARIANT_3')?.settings,
          },
          variables: [
            {
              data: [{ children: [{ text: 'About Us', bold: true }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'cea5927e-839a-4658-9b71-0ffc67afc28b',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'ISAIAH SMITH' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '8857210c-0fd6-43ec-b904-f765e0ac44b0',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'At our agency, we prioritize user satisfaction, user retention, and user conversion. We offer website performance, website speed, website accessibility, website security, website maintenance, website optimization, website analytics, website testing, website updates, website improvements, and website enhancements to ensure your site is top-notch. With our high-octane mobile-friendly design and invigorating user-friendly interface, we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'e312a2ca-9874-488f-ad79-3f816c85df6c',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-76166c9f-04ea-461c-96d3-f5013667920b',
              name: 'MEDIA',
              id: 'b08cf490-98da-4d44-8f18-c046e84374cd',
              category: 'IMAGE',
            },
          ],
          variant: 'VARIANT_3',
          type: 'ABOUT_US',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_1')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#f2f2f2', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ color: '#000000', text: 'Accelerate Your Startup Growth' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '8ece9b85-519e-4929-88af-c0ca0b67f250',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#000000', text: 'Bootstrap Your UX Design' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'ce84dbd8-bec1-41b2-93c3-701bc311cec2',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#000000',
                      text: 'Dope Investor Design: Dope Investor Design is a full-service investment firm with a focus on innovative design. We invest in companies that prioritize exceptional user experience and visual appeal. Our team of experienced designers and investors work hand-in-hand to support the growth and success of our portfolio companies.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'e2a19bd9-89c1-43ac-86d3-fbac89615498',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          type: 'TEXT',
        },
        {
          settings: {
            ...gallery.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 0, left: 30, bottom: 0, right: 30 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#f2f2f2', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-a6377fcb-cfb1-44be-af8d-e4a3a06926ac',
                  description: [
                    {
                      children: [
                        {
                          text: 'Our team of experienced designers and investors work hand-in-hand to support the growth and success of our portfolio companies.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                  title: [{ children: [{ text: 'Yeet ' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-79aa3e4f-93d8-4a84-90f7-cd2957aa0e23',
                  description: [
                    {
                      children: [
                        {
                          text: 'Our team of experienced designers and investors work hand-in-hand to support the growth and success of our portfolio companies.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                  title: [{ children: [{ text: 'Yeet Your Design' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-cfd3270a-edd0-44a9-8b8f-852d4d482d8e',
                  description: [
                    {
                      children: [
                        {
                          text: 'Our team of experienced designers and investors work hand-in-hand to support the growth and success of our portfolio companies.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                  title: [{ children: [{ text: 'Yeet Your Design' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-137d1f94-2ca9-4b8c-9b88-b048ae9f20b4',
                  description: [
                    {
                      children: [
                        {
                          text: 'Our team of experienced designers and investors work hand-in-hand to support the growth and success of our portfolio companies.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '4',
                  title: [{ children: [{ text: 'Yeet Your Design' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-6968d920-d1bc-495c-ac55-f16fc5eb4600',
                  description: [
                    {
                      children: [
                        {
                          text: 'Our team of experienced designers and investors work hand-in-hand to support the growth and success of our portfolio companies.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '5',
                  title: [{ children: [{ text: 'Yeet Your Design' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-bea2d356-c3f1-46dc-8e4c-a49773cc34e8',
                  description: [
                    {
                      children: [
                        {
                          text: 'Our team of experienced designers and investors work hand-in-hand to support the growth and success of our portfolio companies.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '6',
                  title: [{ children: [{ text: 'Yeet Your Design' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-8b7b74f5-1871-4539-adb8-fdb5986b85bd',
                  description: [
                    {
                      children: [
                        {
                          text: 'Our team of experienced designers and investors work hand-in-hand to support the growth and success of our portfolio companies.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '7',
                  title: [{ children: [{ text: 'Yeet Your Design' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-786d24b4-2b0d-4546-9a77-d97ff40b7d28',
                  description: [
                    {
                      children: [
                        {
                          text: 'Our team of experienced designers and investors work hand-in-hand to support the growth and success of our portfolio companies.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '8',
                  title: [{ children: [{ text: 'Yeet Your Design' }], type: 'paragraph' }],
                },
              ],
              name: 'MEDIA',
              id: '95f8980f-7b2f-4306-a9d2-f83ee2937025',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'GALLERY',
        },
      ],
    },
    {
      slug: '/contact-us',
      name: 'CONTACT',
      blocks: [
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_15')?.settings,
          },
          variables: [
            {
              data: [{ children: [{ text: 'We will be happy if u send us a message :)' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '33960fe9-efb0-49c4-9c32-72d4d321ac0a',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Visit US' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '496bf38a-b9a3-4af9-8fa0-b7163c387854',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Contact us today to learn more about how we can help you create a captivating, high-converting website.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '9dac0921-f015-441d-adc6-21d5d91b3a68',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-57eb12ec-0f27-4a3a-b83f-810a97949ef0',
              name: 'MEDIA',
              id: '16064b47-1313-4f3b-970e-d037f77b6a79',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  name: 'Email',
                  id: 'f475a637-12c7-4d46-8db5-5deaa8774a6c',
                  placeholder: 'example@gmail.com',
                  type: 'email',
                  error: 'email is required',
                },
              ],
              name: 'INPUTS',
              id: 'fe511e4a-2e4c-4da3-b0ea-c61227de4184',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_15',
          type: 'CONTACT_US',
        },
      ],
    },
  ],
  newMenu: [
    { name: 'Blog', type: 'internal', url: '/' },
    { name: 'About', type: 'internal', url: '/about-us' },
    { name: 'Contact', type: 'internal', url: '/contact-us' },
  ],
  newMenuConfig: {
    settings: { backgroundColor: { color: '#f2f2f2', enabled: true }, textColor: { color: '#000000', enabled: true } },
    links: [
      { name: 'Home', id: '511c4e19-5af8-4fb2-86ec-e8d5f2722295', type: 'internal', url: '/' },
      { name: 'Contact Us', id: 'a214fc3d-78e1-41f5-ade9-a3db2a42ec34', type: 'email', url: 'hello@example.com' },
    ],
    styleName: 'VARIANT 22',
    id: 'VARIANT_22',
    tags: [],
  },
  newFooter: {
    settings: {
      backgroundColor: { color: '#F2F2F2', enabled: true },
      spacing: {
        padding: { top: 0, left: 0, bottom: 0, right: 0 },
        margin: { top: 0, left: 0, bottom: 0, right: 0 },
        enabled: true,
      },
      textColor: { color: '#000000', enabled: true },
    },
    designedBy: 'Plly Staff',
    id: 'VARIANT_6',
    categories: [
      {
        name: 'Explore',
        links: [{ name: 'Home', id: '80a60ffe-2907-45e8-8e8e-0e040b12e1c2', type: 'internal', url: '/' }],
      },
    ],
    tags: [],
  },
  links: [],
}
