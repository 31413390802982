import { useState } from 'react'
import aiImageIcon from '../../../assets/icons/social/Ai-Image-Creator - Icon.png'
import mintIcon from '../../../assets/icons/social/MintNFT-Icon.png'
import siteBuilderIcon from '../../../assets/icons/social/SiteBuilder-Icon.png'
import Banner from '../common/aboutAndSignin/Banner'
import Footer from '../common/aboutAndSignin/Footer'
import Header from '../common/aboutAndSignin/Header'

// fake data
const datas = [
  {
    name: 'Site Builder',
    image: siteBuilderIcon,
    title: 'Welcome to the fastest site builder in the world.',
    subTitle: 'Stop wasting time on the compter. ',
    description: 'Build a website in less than 10 minutes. ',
  },
  {
    name: 'Ai image creator',
    image: aiImageIcon,
    title: 'Write text and allow the AI Engine to design for you.',
    subTitle: 'AI Image creation is currently the most powerful tool for Graphic Design.',
    description: ' ',
  },
  {
    name: 'Mint NFTs',
    image: mintIcon,
    title: 'Take ownership.',
    subTitle: 'Stop helping Facebook, Tiktok, and Insta make money. You created the content.  You own it.',
    description: 'Mint your work and store it in your digital wallet.  ',
  },
]

function About() {
  const [showData, setShowData] = useState<string>(datas[0]?.name)
  const filterData = datas?.find(data => data?.name === showData)
  const { name, image, title, subTitle, description } = filterData || {}
  return (
    <>
      <Header />
      <Banner />
      <div className="bg-[#000000]">
        <section className="py-12 px-6">
          <div className="max-w-[740px] mx-auto">
            <div className="hidden md:flex space-x-6">
              {datas?.map((data, idx) => (
                <button
                  onClick={() => setShowData(data?.name)}
                  key={idx}
                  className={`w-[200px] ${
                    data?.name === showData ? 'bg-[#D9D9D9]' : 'bg-[#FFFFFF]'
                  }  text-center py-1.5 rounded border border-black block`}
                  type="submit"
                >
                  {data?.name}
                </button>
              ))}
            </div>
            <div>
              <div className="flex space-x-8 text-white pb-12 md:py-12 ">
                <div className="text-center md:px-5 space-y-2">
                  <div className="w-[120px] h-[120px]">
                    <img className="w-full h-full rounded" src={image} alt="" />
                  </div>
                  <div>
                    <p className="text-[14px]">{name}</p>
                  </div>
                </div>
                <div className="space-y-4 text-[15px] md:text-[18px] lg:text-[20px]">
                  <p>{title}</p>
                  <p>{subTitle}</p>
                  <p>{description}</p>
                </div>
              </div>
            </div>
            <div className="flex justify-end pr-[12%]">
              <button
                className="w-[160px] md:w-[200px] bg-[#E90570] text-[20px] text-white text-center py-2 rounded border border-black block"
                type="submit"
              >
                Start
              </button>
            </div>
          </div>
        </section>
        <section>
          <div className="flex md:hidden justify-center space-x-6 sm:space-x-8 py-12">
            {datas
              ?.filter(data => data?.name !== showData)
              ?.map((data, idx) => (
                <button
                  onClick={() => setShowData(data?.name)}
                  key={idx}
                  className={`w-[160px] ${
                    data?.name === showData ? 'bg-[#D9D9D9]' : 'bg-[#FFFFFF]'
                  }  text-center py-1.5 rounded border border-black block`}
                  type="submit"
                >
                  {data?.name}
                </button>
              ))}
          </div>
        </section>
        {/* footer section  */}
        <Footer />
      </div>
    </>
  )
}

export default About
