import FontLoader from '../../menu/FontLoader'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../../menu/utils'
import { FooterVariantProps, getSocialIcon, getSocialLabel, NewFooter, Social } from '../footer'

const SocialItem = ({ type, link, footer }: { type: Social; link: string; footer: NewFooter }) => {
  const Icon = getSocialIcon(type)
  const label = getSocialLabel(type)

  return (
    <a href={link} target="_blank" className="flex items-center hover:underline">
      <Icon size={20} color={getTextColor({ settings: footer?.settings?.textColor, defaultValue: '#000' }).color} />
      <p
        className="ml-2"
        style={{
          ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
          ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: '#000' }),
        }}
      >
        {label}
      </p>
    </a>
  )
}

export default function VariantOne({ footer, website }: FooterVariantProps) {
  return (
    <div
      style={{
        ...getBackgroundColor({ settings: footer?.settings?.backgroundColor, defaultValue: '#fff' }),
      }}
    >
      <FontLoader settings={footer?.settings?.fontFamily} />
      <div
        className="container mx-auto"
        style={{
          ...getSpacing(footer?.settings?.spacing),
        }}
      >
        <div className="px-4 md:px-0 py-[120px]">
          <div className="flex justify-between flex-wrap gap-y-12">
            <div>
              <a href="/">
                {website?.config?.logo ? (
                  <img
                    style={{ height: '30px', width: 'auto' }}
                    className="cursor-pointer"
                    src={website?.config?.logo}
                    alt={website?.name}
                  />
                ) : (
                  <p
                    className="uppercase font-medium"
                    style={{
                      ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
                      ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: '#000' }),
                    }}
                  >
                    {website?.name}
                  </p>
                )}
              </a>
              <div className="space-y-4 mt-6">
                {website?.config?.social &&
                  Object.keys(website.config.social).map(item => (
                    <SocialItem type={item as Social} link={website.config.social[item]} footer={footer} />
                  ))}
              </div>
            </div>

            {footer.categories
              .filter(i => i.links.length > 0)
              .map(categorty => (
                <div className="w-full md:w-auto">
                  <p
                    className="text-sm font-bold mb-3"
                    style={{
                      ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
                      ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: '#000' }),
                    }}
                  >
                    {categorty.name}
                  </p>
                  <ul className="space-y-3 m-0 p-0 list-none">
                    {categorty.links.map(link => (
                      <li>
                        <a
                          className="hover:underline"
                          href={link.url}
                          style={{
                            ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
                            ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: '#000' }),
                          }}
                        >
                          {link.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}
