import Icon from './icons'
import { IconPropsT } from './types'

function Link(props: IconPropsT) {
  const { title = 'Link', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 22 10" size={size} title={title} {...otherProps}>
      <path d="M9.28598 8.78572H9.28572H4.64286C3.5441 8.78571 2.49035 8.34924 1.71341 7.5723C0.936478 6.79536 0.5 5.74161 0.5 4.64286C0.5 3.5441 0.936478 2.49035 1.71341 1.71342C2.49035 0.936478 3.5441 0.5 4.64286 0.5H7.12742C6.98863 0.675725 6.85854 0.858497 6.73767 1.04762H4.64286C3.68934 1.04762 2.77488 1.4264 2.10064 2.10064C1.4264 2.77488 1.04762 3.68934 1.04762 4.64286C1.04762 5.59637 1.4264 6.51084 2.10064 7.18507C2.77488 7.85931 3.68934 8.2381 4.64286 8.2381H9.28561C9.28565 8.2381 9.28568 8.2381 9.28572 8.2381C9.91141 8.23821 10.5263 8.07502 11.0696 7.76468C11.613 7.45431 12.0659 7.00751 12.3836 6.46845C12.7013 5.92939 12.8729 5.31674 12.8813 4.69106C12.8863 4.31842 12.8333 3.94885 12.7256 3.59524H13.2964C13.434 4.12172 13.4668 4.67132 13.3916 5.21256C13.3005 5.86923 13.0531 6.49442 12.6702 7.03562C12.2872 7.57683 11.7801 8.01824 11.1912 8.32277C10.6023 8.6273 9.94894 8.78606 9.28598 8.78572Z" />
      <path d="M12.3807 0.500001H12.3809H17.0238C17.5679 0.500001 18.1066 0.607159 18.6092 0.815357C19.1118 1.02356 19.5686 1.32872 19.9532 1.71342L20.3068 1.35986L19.9533 1.71342C20.338 2.09812 20.6431 2.55482 20.8513 3.05745C21.0595 3.56009 21.1667 4.09881 21.1667 4.64286C21.1667 5.18691 21.0595 5.72563 20.8513 6.22826C20.6431 6.7309 20.338 7.1876 19.9532 7.5723C19.5686 7.957 19.1118 8.26216 18.6092 8.47036C18.1066 8.67856 17.5679 8.78572 17.0238 8.78572H14.5391C14.678 8.60994 14.8082 8.42719 14.929 8.2381H17.0238C17.9773 8.2381 18.8918 7.85931 19.566 7.18508C20.2403 6.51084 20.619 5.59637 20.619 4.64286C20.619 3.68934 20.2403 2.77488 19.566 2.10064C18.8918 1.4264 17.9773 1.04762 17.0238 1.04762H12.3809H12.3809C11.7498 1.04764 11.1299 1.21377 10.5834 1.52932C10.0368 1.84488 9.58298 2.29874 9.26744 2.84528C8.9519 3.39182 8.78579 4.01179 8.78579 4.64288C8.78579 4.99937 8.8388 5.35231 8.94181 5.69048H8.3703C8.2327 5.164 8.19987 4.6144 8.27502 4.07315C8.36618 3.41649 8.61361 2.7913 8.99651 2.25009C9.37942 1.70889 9.88661 1.26748 10.4755 0.962946C11.0644 0.658414 11.7177 0.499653 12.3807 0.500001Z" />
    </Icon>
  )
}

export default Link
