import { Descendant } from 'slate'
import {
  // MENUImageItem,
  MENUSectionItem,
  MENUVariables,
  MENUVariants,
  Section,
  SectionTags,
} from 'src/_polly/components/src/sections'
import { v4 as uuid } from 'uuid'
import { DEFAULT_VARIANT_SETTINGS, getSectionVariants } from './get-variants'
const tags: Record<MENUVariants, SectionTags[]> = {
  VARIANT_1: ['IMAGE', 'ANIMATION'],
  VARIANT_2: ['IMAGE'],
  VARIANT_3: ['IMAGE'],
}
export function getMENUVariants() {
  const variants = getSectionVariants('MENU')
  const sections: Section<MENUVariants, MENUVariables>[] = []
  const name: Descendant[] = [{ type: 'paragraph', children: [{ text: 'OMG User Experience' }] }]
  const item: Descendant[] = [{ type: 'paragraph', children: [{ text: '83' }] }]

  const item2: Descendant[] = [{ type: 'paragraph', children: [{ text: '240' }] }]
  const item3: Descendant[] = [{ type: 'paragraph', children: [{ text: '15' }] }]
  const item4: Descendant[] = [{ type: 'paragraph', children: [{ text: '7' }] }]
  const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'meals' }] }]
  const subtitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'main' }] }]
  const description: Descendant[] = [
    {
      type: 'paragraph',
      children: [
        {
          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
        },
      ],
    },
  ]
  const paragraph: Descendant[] = [
    {
      type: 'paragraph',
      children: [
        {
          text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience? Look no further than our custom site and portfolio design services. Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. Whether you are a digital nomad or a disruptive technology startup, we have the expertise to optimize your website for exceptional user experience on any device.',
        },
      ],
    },
  ]

  variants.forEach(variant => {
    const data: Section<MENUVariants, MENUVariables> = {
      id: variant.id,
      createdAt: '',
      isSection: true,
      type: 'MENU',
      updatedAt: '',
      tags: tags[variant.id as MENUVariants],
      variables: [
        { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
        { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
        {
          id: uuid(),
          category: 'IMAGE',
          data: 'http://www.pixel-industry.com/html/royal-plate/img/pics/bkg-img1.jpg',
          name: 'MEDIA',
        },

        {
          id: uuid(),
          category: 'TEXT',
          data: paragraph,
          name: 'PARAGRAPH',
        },
        {
          id: uuid(),
          name: 'ITEMS',
          category: 'TEXT',
          data: [
            {
              id: uuid(),
              title1: name,
              subtitle1: item,
              image:
                'https://images.unsplash.com/photo-1535473895227-bdecb20fb157?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxkaW5uZXJ8ZW58MHx8fHwxNjc4MTAxODAx&ixlib=rb-4.0.3&q=80&w=1080',
              description1: description,
            },
            {
              id: uuid(),
              title1: name,
              subtitle1: item2,
              image:
                'https://images.unsplash.com/photo-1540189549336-e6e99c3679fe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
              description1: description,
            },
            {
              id: uuid(),
              title1: name,
              subtitle1: item3,
              image:
                'https://images.unsplash.com/photo-1594834749740-74b3f6764be4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxsdW5jaHxlbnwwfHx8fDE2NzgxMDE3NzA&ixlib=rb-4.0.3&q=80&w=1080',
              description1: description,
            },
            {
              id: uuid(),
              title1: name,
              subtitle1: item4,
              image:
                'https://images.unsplash.com/photo-1505253758473-96b7015fcd40?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
              description1: description,
            },
          ] as MENUSectionItem[],
        },
        // {
        //   id: uuid(),
        //   name: 'IMAGE',
        //   category: 'TEXT',
        //   data: [
        //     {
        //       id: uuid(),
        //       title1: item,
        //       image:
        //         'https://images.unsplash.com/photo-1607923722386-1c7b1d86f70a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=879&q=80',
        //     },
        //     {
        //       id: uuid(),
        //       title1:item2,
        //       image:
        //         '2https://images.unsplash.com/photo-1607923722386-1c7b1d86f70a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=879&q=80',
        //     },
        //     {
        //       id: uuid(),
        //       title1: item3,
        //       image:
        //         'https://images.unsplash.com/photo-1607923722386-1c7b1d86f70a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=879&q=80',
        //     },
        //     {
        //       id: uuid(),
        //       title1:item4,
        //       image:
        //         'https://images.unsplash.com/photo-1607923722386-1c7b1d86f70a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=879&q=80',
        //     },
        //   ] as MENUImageItem[],
        // },
      ],
      variant: variant.id as MENUVariants,
      styleName: variant.name,
      ...DEFAULT_VARIANT_SETTINGS,
    }
    sections.push(data)
  })

  return sections
}
