import { Dialog, Transition } from '@headlessui/react'
import EmojiPicker, { IEmojiData } from 'emoji-picker-react'
import { Fragment, useRef, useState } from 'react'
import ImageUploader from 'src/components/new/ImageUploader'
import Close from 'src/icons/Close'
import Emoji from 'src/icons/Emoji'
import ProfileUpload from 'src/icons/ProfileUpload'
import Send from 'src/icons/Send'
import UpIcon from 'src/icons/UpIcon'

export default function ConvoChatBox(props: any) {
  const { onSend } = props

  const [message, setMessage] = useState<any>({ content: '' })
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [showSendIcon, setShowSendIcon] = useState(false)
  const [showImageUploader, setShowImageUploader] = useState(false)
  const ref = useRef<HTMLTextAreaElement>(null)

  const send = () => {
    if (message.content.trim()) {
      onSend({ ...message, content: message.content.trim(), contentType: 'TEXT' })
      setMessage((state: any) => ({ ...state, content: '' }))
    }
  }

  const handleEmojiSelect = (_event: React.MouseEvent<Element, MouseEvent>, data: IEmojiData) => {
    const input = ref.current
    if (!input) return
    const cursorPosition = input.selectionStart
    if (cursorPosition === null) return
    const textBeforeCursorPosition = input.value.substring(0, cursorPosition)
    const textAfterCursorPosition = input.value.substring(cursorPosition, input.value.length)
    input.value = textBeforeCursorPosition + data.emoji + textAfterCursorPosition
    setMessage((state: any) => ({ ...state, content: input.value }))
    input.focus()
  }

  return (
    <div className="lg:min-h-[115px] min-h-[86px] border-t border-black bg-white flex items-center p-4">
      <Transition appear show={showImageUploader} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => setShowImageUploader(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 dark:bg-[#8080802e] bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto bg-white md:bg-transparent">
            <div className="flex min-h-full items-center justify-center md:p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full transform md:overflow-hidden md:rounded-2xl bg-white pb-12 md:pb-6 h-screen flex flex-col  md:h-full dark:bg-black text-left align-middle md:shadow-xl transition-all md:border-4 border-[#4E4E4E] md:p-10 p-4 py-6 md:max-w-[730px] max-w-full relative">
                  <ImageUploader
                    onSubmit={url => {
                      onSend({ content: url, contentType: 'IMAGE' })
                      setShowImageUploader(false)
                    }}
                    onClose={() => setShowImageUploader(false)}
                    buttonLabel="Select Image"
                    modalMode
                    options={['upload', 'unsplash', 'ttm', 'library']}
                  />
                  <button
                    className="absolute top-3 right-4 fill-white text-white block md:hidden"
                    type="button"
                    onClick={() => setShowImageUploader(false)}
                  >
                    <Close
                      size={25}
                      fill="#D9D9D9"
                      className="fill-white dark:fill-black stroke-black ml-auto dark:stroke-white cursor-pointer"
                    />
                  </button>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {showImageUploader && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div onClick={() => setShowImageUploader(false)} className="fixed w-full h-full top-0 left-0" />
      )}
      <div className="w-full">
        {showEmojiPicker && (
          <div className="mb-3">
            <EmojiPicker onEmojiClick={handleEmojiSelect} pickerStyle={{ width: '100%' }} />
          </div>
        )}
        <div className="flex lg:gap-x-6 gap-x-[11px] w-full">
          <div className={`flex items-center ${showSendIcon && 'lg:flex hidden'}`}>
            <button type="button" onClick={() => setShowImageUploader(true)}>
              <ProfileUpload className="dark:text-black hidden xl:block" size={38} />
              <ProfileUpload className="dark:text-black block xl:hidden" size={30} />
            </button>
            <div className="h-full w-[1px] mx-3 bg-black" />
            <button type="button" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
              <Emoji className="dark:text-black hidden xl:block" size={38} />
              <Emoji className="dark:text-black block xl:hidden" size={30} />
            </button>
          </div>
          <button type="button" className={`text-xl lg:hidden ${!showSendIcon && 'hidden'}`}>
            <UpIcon size={20} className="rotate-90 dark:text-black" />
          </button>
          <form
            onSubmit={e => {
              e.preventDefault()
            }}
            className="flex-1 flex gap-3 items-center"
          >
            <textarea
              ref={ref}
              className="lg:text-2xl text-[19px] py-[10px] px-[10px] lg:py-4 lg:px-5 rounded-md bg-[#EFEFEF] resize-none outline-none w-full "
              placeholder="Type a message..."
              value={message.content}
              onFocus={() => setShowSendIcon(true)}
              onBlur={() => setShowSendIcon(false)}
              onChange={e => {
                if (e.target) {
                  const val = e.target.value as string
                  setMessage((state: any) => ({ ...state, content: val }))
                }
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  send()
                }
              }}
              rows={1}
            />
            <button
              type="submit"
              onClick={e => {
                e.preventDefault()
                send()
              }}
              className={`rotate-45 lg:hidden mx-auto ${message?.content?.length === 0 && 'hidden'}`}
            >
              <Send size={24} className="dark:text-black" />
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
