import SectionSlate from '../../../common/SectionSlatePreview'
import { TextSection as TextSectionType } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

interface VariantOneProps {
  section: TextSectionType
  hideImageBg?: boolean
}

export default function VariantOne({ section, hideImageBg }: VariantOneProps) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: '#fff',
          backgroundSettings: section?.settings?.color?.background,
          image: media && !hideImageBg ? media : null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className={`md:container md:mx-auto px-6 md:px-4`}>
        <div className="md:w-[750px] max-w-full mx-auto">
          <p className="text-xl md:text-4xl font-bold  text-center ">
            {<SectionSlate initialValue={title} previewMode />}
          </p>
        </div>

        {subTitle ? (
          <div className="w-[750px] max-w-full mx-auto ">
            <p className="md:text-[20px] text-center">{<SectionSlate initialValue={subTitle} previewMode />}</p>
          </div>
        ) : null}

        {paragraph && (
          <div className="w-[750px] max-w-full mx-auto md:mt-2 ">
            <p className="text-center leading-7">{<SectionSlate initialValue={paragraph} previewMode />}</p>
          </div>
        )}
      </div>
    </div>
  )
}
