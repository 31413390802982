import Icon from './icons'
import { IconPropsT } from './types'

function Plus(props: IconPropsT) {
  const { title = 'Plus', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 43 49" size={size} title={title} {...otherProps}>
      <path
        d="M27.8896 22.68V24.92H22.8896V30.32H20.4096V24.92H15.4096V22.68H20.4096V17.28H22.8896V22.68H27.8896Z"
        fill="white"
      />
    </Icon>
  )
}

export default Plus
