import SectionSlate from '../../../common/SectionSlatePreview'
import { AttentionGrabberSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantFour({ section }: { section: AttentionGrabberSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data
  return (
    <>
      <div
        className="md:min-h-[90vh] min-h-[50vh] bg-center bg-no-repeat md:bg-cover bg-gradient-to-tr relative from-black flex flex-col items-center justify-center  to-slate-800"
        style={{
          ...generateSectionBackground({
            defaultColor: 'white',
            backgroundSettings: section?.settings?.color?.background,
            image: media ?? null,
          }),
          ...generateSectionSpacing(section?.settings?.spacing),
          backgroundRepeat: 'no-repeat',
          // backgroundSize: '100%',
          backgroundPosition: 'center',
          backgroundOrigin: 'border-box',
        }}
      >
        <div className="h-full w-full bg-[#0000009b]  z-0 absolute" />
        <div className="gap-10 text-center text-neutral-content flex flex-col items-center justify-center  ">
          <div className="md:min-w-[900px] md:max-w-[90%] py-6 flex flex-col">
            <div className="font-semibold text-[white] mt-2 lg:mt-6 mb-4">
              <SectionSlate initialValue={subTitle} previewMode />
            </div>
            <div className="text-white text-[32px] lg:text-8xl font-bold">
              <SectionSlate initialValue={title} previewMode />
            </div>
            <div className="mt-4 md:text-xl text-white md:w-[80%] px-4 md:mx-auto">
              <SectionSlate initialValue={paragraph} previewMode />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
