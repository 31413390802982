import { Template } from '.'
import { image, love, text, blog, aboutUs } from './variants'

export const Template10: Template = {
  id: '10',

  pages: [
    {
      slug: '/',
      name: 'Home',
      blocks: [
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'MARGIN',
              id: 'b07070c5-d187-4889-b6f0-a6fcb865a8c9',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'PADDING',
              id: '1d8ccc55-7846-487a-8b04-e94f8afe8e31',
              category: 'TEXT',
            },
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: '77a17ff4-55a9-440d-9e60-0d3c4aa9e6af',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Quicksand', color: '#000000', text: 'Bianca Witherspoon', bold: true }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '69f2246c-6d91-4f95-8691-c0003960ccda',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-1f8bf0a6-96f0-445b-8180-bb1a2f803812',
              name: 'MEDIA',
              id: 'd2abb53b-9f6e-4202-bc47-1e13021b6c2f',
              category: 'IMAGE',
            },
            { data: [], name: 'SUBTITLE', id: 'bf4c28fe-ea92-4cf0-938b-a398305d2ac8', category: 'TEXT' },
            {
              data: [
                {
                  children: [
                    {
                      highlightColor: '#b9b9b9',
                      color: '#012b10',
                      underline: true,
                      text: '                                  ',
                    },
                  ],
                  type: 'paragraph',
                },
                {
                  children: [{ color: '#000000', text: '                                                  ' }],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '790f2a16-1b38-4670-b835-5f46ff95a888',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'TEXT',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_7')?.settings,
          },
          variables: [
            {
              data: [
                {
                  children: [
                    {
                      text: 'Liquorice croissant topping lemon drops fruitcake tootsie roll. Pudding muffin dessert jelly beans sugar plum wafer wafer. Souffle cake sweet jelly beans tiramisu caramels cake sesame snaps. Marshmallow jujubes brownie gummies topping tiramisu. Sweet shortbread croissant caramels lemon drops jelly. Sweet candy powder dessert tart marshmallow powder chocolate bar cake souffle.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'a2bb8066-c6ce-4213-8da2-473987464267',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-5c7a86e6-c209-4eb9-ac9d-c71a152c45b7',
              name: 'MEDIA',
              id: 'b55fb2e2-f835-4b02-89bc-0bc62bafa48c',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Known as one of the safest North American hubs, Downtown Montreal is vibrant with shoppers, office workers and students from McGill and Concordia Universities.',
                    },
                  ],
                  type: 'paragraph',
                  align: 'center',
                },
              ],
              name: 'PARAGRAPH',
              id: '71dfb62a-1f31-49e4-8400-1e1909614f62',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ text: 'Hits Different Investors', bold: true }], type: 'paragraph', align: 'center' },
              ],
              name: 'TITLE',
              id: '69f8d170-af19-4212-a548-f78330e64e3a',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_7',
          type: 'TEXT',
        },
        {
          settings: {
            ...blog.find(i => i.variant === 'VARIANT_8')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'd7e649f9-5d96-4abe-9a48-a7f147a0f037',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Sub Title' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '2b6b8e58-c81f-4e94-a05c-517b165e6057',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: '515c9723-2527-409e-a5ee-dbf78ad82faa',
              category: 'TEXT',
            },
            {
              data: [
                {
                  date: '1 MAY 2016',
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-53f8e195-d76e-46af-ab2e-724e41610789',
                  texts: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  name: [{ children: [{ text: 'ONG Investor-Friendly', bold: true }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#5f5f5f',
                          text: 'We offer website performance, website speed, website accessibility, website security, website maintenance, website optimization, website analytics, website testing, website updates, website improvements, and website enhancements to ensure your site is top-notch',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: 'b603a646-32cb-451e-babe-594659a1fd6d',
                  title: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
                },
                {
                  date: '1 MAY 2016',
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-6d0069da-681b-4611-8edb-73e7826907ad',
                  texts: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  name: [{ children: [{ text: 'YOLO Startup Design', bold: true }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#666666',
                          text: 'We offer website performance, website speed, website accessibility, website security, website maintenance, website optimization, website analytics, website testing, website updates, website improvements, and website enhancements to ensure your site is top-notch',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: 'ee858adb-3e98-4d63-8b8b-b22800c61121',
                  title: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
                },
                {
                  date: '1 MAY 2016',
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-429bb0c2-d20a-4bef-9860-b1c5baf0799b',
                  texts: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  name: [{ children: [{ text: 'Tea for Investors', bold: true }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#575757',
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [{ children: [{ text: 'item name' }], type: 'paragraph' }],
                },
                {
                  date: '1 MAY 2016',
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-5c00563f-0ec0-42be-94d3-28100576b9cb',
                  texts: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  name: [{ children: [{ text: 'Sleep On Disruptive Design', bold: true }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#606060',
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [{ children: [{ text: 'item name' }], type: 'paragraph' }],
                },
              ],
              name: 'ITEMS',
              id: 'ce653cae-2cfe-4f6c-a142-437d7c5222e5',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  date: '1 MAY 2016',
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-53f8e195-d76e-46af-ab2e-724e41610789',
                  texts: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  name: [{ children: [{ text: 'ONG Investor-Friendly', bold: true }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#5f5f5f',
                          text: 'We offer website performance, website speed, website accessibility, website security, website maintenance, website optimization, website analytics, website testing, website updates, website improvements, and website enhancements to ensure your site is top-notch',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: 'b603a646-32cb-451e-babe-594659a1fd6d',
                  title: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
                },
                {
                  date: '1 MAY 2016',
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-6d0069da-681b-4611-8edb-73e7826907ad',
                  texts: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  name: [{ children: [{ text: 'YOLO Startup Design', bold: true }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#666666',
                          text: 'We offer website performance, website speed, website accessibility, website security, website maintenance, website optimization, website analytics, website testing, website updates, website improvements, and website enhancements to ensure your site is top-notch',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: 'ee858adb-3e98-4d63-8b8b-b22800c61121',
                  title: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
                },
                {
                  date: '1 MAY 2016',
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-429bb0c2-d20a-4bef-9860-b1c5baf0799b',
                  texts: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  name: [{ children: [{ text: 'Tea for Investors', bold: true }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#575757',
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [{ children: [{ text: 'item name' }], type: 'paragraph' }],
                },
                {
                  date: '1 MAY 2016',
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-5c00563f-0ec0-42be-94d3-28100576b9cb',
                  texts: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  name: [{ children: [{ text: 'Sleep On Disruptive Design', bold: true }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#606060',
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [{ children: [{ text: 'item name' }], type: 'paragraph' }],
                },
              ],
              name: 'ITEMS',
              id: '256af812-6914-4fd4-94f9-f144be1a30b5',
              category: 'IMAGE',
            },
          ],
          variant: 'VARIANT_8',
          type: 'BLOG',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_7')?.settings,
          },
          variables: [
            {
              data: [
                {
                  children: [
                    {
                      text: 'Liquorice croissant topping lemon drops fruitcake tootsie roll. Pudding muffin dessert jelly beans sugar plum wafer wafer. Souffle cake sweet jelly beans tiramisu caramels cake sesame snaps. Marshmallow jujubes brownie gummies topping tiramisu. Sweet shortbread croissant caramels lemon drops jelly. Sweet candy powder dessert tart marshmallow powder chocolate bar cake souffle.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'a81f9e8b-d5ff-4707-891c-9e1742d694a2',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-5c7a86e6-c209-4eb9-ac9d-c71a152c45b7',
              name: 'MEDIA',
              id: 'c7bf4dee-0e99-45c8-9c64-aff26d432dac',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'Hits Different Investors' }], type: 'paragraph', align: 'center' }],
              name: 'TITLE',
              id: '205d81fe-0bb3-4472-b718-61a71e10883e',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Known as one of the safest North American hubs, Downtown Montreal is vibrant with shoppers, office workers and students from McGill and Concordia Universities.',
                    },
                  ],
                  type: 'paragraph',
                  align: 'center',
                },
              ],
              name: 'PARAGRAPH',
              id: '1b143171-5df4-434a-ac9b-95b616d38df8',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_7',
          type: 'TEXT',
        },
        {
          settings: {
            ...aboutUs.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#F0F0F0', enabled: true } },
          },
          variables: [
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-8522a2ad-55fc-4e54-93ae-54ada9f5bf75',
              name: 'MEDIA',
              id: '5c0ba87e-ee75-4be0-9fc7-65855e774765',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'Hits Different Investors' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'a692239b-07e5-4da6-ae13-0599c8d5c192',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#797979', text: 'Accelerator-Approved UX Design' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'c3196550-983f-4a4f-a529-4c6ae49c2498',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '155e85cc-653d-46a3-885a-e52bc784aa14',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'ABOUT_US',
        },
        {
          settings: {
            ...aboutUs.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 60, right: 0 },
              margin: { top: 0, left: 0, bottom: 60, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#F0F0F0', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Yeet Your Design' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'b8b26be6-0b45-4804-a79f-040c8c908f23',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#717171', text: 'Tea for Investors' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '7cbf6002-238b-4cce-8ebe-15d852c91437',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-5f314773-823c-45f5-bd00-c8241bda310b',
              name: 'MEDIA',
              id: '0a67e857-03e7-43f8-b227-23ea83944c25',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '1121e3f2-dcad-4ed4-80f6-56c23169f4d9',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'ABOUT_US',
        },
        {
          settings: {
            ...image.find(i => i.variant === 'VARIANT_8')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  subtitle: 'Static Site Generator',
                  description: '',
                  id: '7c973ad6-0491-421e-bb88-9fc7d003e7ca',
                  title: 'React',
                },
                {
                  subtitle: 'CSS Framework',
                  description: '',
                  id: '023c6b47-4d2c-4f00-a016-bd7e13ee8e2f',
                  title: 'Tailwind',
                },
                {
                  subtitle: 'Animation',
                  description: '',
                  id: 'ff5e153d-865e-4400-9c36-608146983f3e',
                  title: 'Framer Motion',
                },
                {
                  subtitle: 'Headless CMS',
                  description: '',
                  id: '9b1734b3-806e-47a3-9b86-191c184dfafe',
                  title: 'Sanity',
                },
                { subtitle: 'Hosting', description: '', id: '87f6105c-4dae-4ae5-b850-ae685ac2eb9d', title: 'Vercel' },
              ],
              name: 'ITEMS',
              id: 'f0ff9cd9-c55e-4b95-acf2-3e05905059bd',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#000000', text: 'Snatched Crowdfunding UX' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'a0730508-76d9-4e7a-8dfc-51405aac1452',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-df2c7a16-dccd-44e4-bb42-bfe9e620d5a8',
              name: 'MEDIA',
              id: '73af7dde-396b-4641-ab52-0d9cdb02eadb',
              category: 'IMAGE',
            },
            {
              data: [
                { children: [{ color: '#000000', text: 'Cottage Industry, Captivating Design' }], type: 'paragraph' },
              ],
              name: 'SUBTITLE',
              id: 'b4ebd9da-73a9-4283-a24f-8225867c5b80',
              category: 'TEXT',
            },
            { data: [], name: 'PARAGRAPH', id: '09b73f58-284d-47b2-8b73-abb18a70f6ec', category: 'TEXT' },
          ],
          variant: 'VARIANT_8',
          type: 'IMAGE_OMG',
        },
      ],
    },
    {
      slug: '/about',
      name: 'About ',
      blocks: [
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_15')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 60, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Sub Title' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'ebcd13c6-acb0-43ca-ba60-27f180528079',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'MARGIN',
              id: '23779d3c-f26f-4d42-8900-1d478a14e918',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'PADDING',
              id: '2a4a1d38-2956-4811-9965-517c49603067',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
              name: 'MEDIA',
              id: 'c4be88bd-cbc5-4fc3-90d2-5208e7d64876',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: '0cf32fe2-bec4-4aac-b923-97e4c1580526',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      highlightColor: '#e8e8e8',
                      text: ' About Me ',
                    },
                  ],
                  type: 'paragraph',
                  align: 'center',
                },
              ],
              name: 'TITLE',
              id: '369db19e-8739-47ea-b1df-864272ba9ac6',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: '' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: 'c653941d-76a3-4931-abdf-4ad7f2c6e1e6',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_15',
          type: 'TEXT',
        },
        {
          settings: {
            ...aboutUs.find(i => i.variant === 'VARIANT_13')?.settings,
          },
          variables: [
            {
              data: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '172154b3-8dde-4a20-bbc2-d886290811d2',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-286307fc-ddf8-428e-bc42-40cd8e749507',
              name: 'MEDIA',
              id: 'aa23084b-7492-4f5c-b180-2cd05daf4adc',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Cheugy-Approved Acquisition: We are a team of experienced entrepreneurs and investors dedicated to acquiring and growing innovative businesses. Our approach is rooted in the latest industry trends and our commitment to long-term success. With our expertise and network, we aim to bring Cheugy-approved businesses to the forefront of the market.',
                    },
                  ],
                  type: 'paragraph',
                  align: 'center',
                },
              ],
              name: 'PARAGRAPH',
              id: '586743d8-5cdc-40ea-a582-ec6b2bd78480',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ highlightColor: '#ffffff', color: '#000000', text: 'Bianca Witherspoon', bold: true }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '9c58c341-c94a-4a0f-9583-1c6d44139ff6',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_13',
          type: 'ABOUT_US',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_15')?.settings,
            spacing: {
              padding: { top: 60, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Sub Title' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '231b400d-3208-457c-9b9f-44701637f948',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'MARGIN',
              id: 'e3fb7a28-9c40-469d-8016-30a2346838de',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'PADDING',
              id: '19f8ca36-4fd5-4a32-9e89-b66fb44ee202',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
              name: 'MEDIA',
              id: '2484a9a8-5c6a-4271-94e5-44bf7132c7d2',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: '2641ea65-342e-4099-b691-475db5015ab2',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      highlightColor: '#e8e8e8',
                      text: ' What I Do? ',
                    },
                  ],
                  type: 'paragraph',
                  align: 'center',
                },
              ],
              name: 'TITLE',
              id: '391a1809-4502-4434-84b0-95dfb3f1be29',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: '' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: '0f0e931f-a64a-4392-a870-ae257a0c274a',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_15',
          type: 'TEXT',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_14')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '9f1aa3ec-e03f-4ede-9a5d-944d6e6a7181',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Sub Title' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'f3027d81-f16e-4a9a-b547-d39f868d5e29',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: '341f025e-ef5b-409d-a668-b4da26c40279',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'MARGIN',
              id: '00041ec4-a7cc-4d13-a07d-eadab9ab65a6',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'PADDING',
              id: '9820198e-c86e-4592-8497-ea97de373174',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
              name: 'MEDIA',
              id: '822f4eef-094d-4e2f-8c69-f2b15ea8a1a2',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-75611eb2-3d03-4367-8c0b-8f62f7738fd1',
                  name: [
                    {
                      children: [{ color: '#5f5e5e', text: 'Hits Different Investors' }],
                      type: 'paragraph',
                      align: 'center',
                    },
                  ],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '1',
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-cec0466a-86d1-4ccb-8c20-b13c0d1b1841',
                  name: [
                    {
                      children: [{ color: '#626262', text: 'Hits Different Investors' }],
                      type: 'paragraph',
                      align: 'center',
                    },
                  ],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '2',
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-df757a15-7088-4d14-be88-31a0ce6a610b',
                  name: [
                    {
                      children: [{ color: '#666666', text: 'Hits Different Investors' }],
                      type: 'paragraph',
                      align: 'center',
                    },
                  ],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: 'd6792b89-4405-459c-89b2-508acd684f9c',
              category: 'IMAGE',
            },
          ],
          variant: 'VARIANT_14',
          type: 'TEXT',
        },
      ],
    },
    {
      slug: '/life',
      name: 'Life ',
      blocks: [
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_12')?.settings,
          },
          variables: [
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-d3699431-4813-4a73-815c-5647ded6559d',
              name: 'MEDIA',
              id: '491fdc8d-8d59-4d49-8f98-727499df0003',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    { highlightColor: '#e91e1e', color: '#ffffff', className: '', text: '  Life of Bloger  ' },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '8dfef5a5-60d1-468a-ab27-23a0fc8d4cac',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_12',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...aboutUs.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#f0f0f0', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Slay Your Startup' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'ed7bfab7-a445-4f7d-ad2d-4b70eb652215',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Yeet Your Design' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '781cbebd-c919-4ecc-8bc4-45fe2cb9ee31',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-d9dc62d0-be53-4910-96d0-520a531043d0',
              name: 'MEDIA',
              id: '1c7ad77e-31d1-409b-9992-c4202db1a212',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'At our agency, we prioritize user satisfaction, user retention, and user conversion. We offer website performance, website speed, website accessibility, website security, website maintenance, website optimization, website analytics, website testing, website updates, website improvements, and website enhancements to ensure your site is top-notch. With our high-octane mobile-friendly design and invigorating user-friendly interface, we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '922a53b5-7d51-412a-89b9-bfb8c10e095e',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'ABOUT_US',
        },
        {
          settings: {
            ...aboutUs.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#f0f0f0', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Accelerate Your Startup Growth' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '58a9dd18-1661-4368-8263-1e190e2aabeb',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Snatched Crowdfunding UX' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '3d03daab-ad61-466d-a5a2-03641ec496fd',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-ffa7d55d-d5d9-48fd-b348-88264e293efb',
              name: 'MEDIA',
              id: 'b347a076-43e3-4d02-952b-81a34abbc07f',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'At our agency, we prioritize user satisfaction, user retention, and user conversion. We offer website performance, website speed, website accessibility, website security, website maintenance, website optimization, website analytics, website testing, website updates, website improvements, and website enhancements to ensure your site is top-notch. With our high-octane mobile-friendly design and invigorating user-friendly interface, we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'd28aab5f-8510-4ffd-a2d5-0dd31f389190',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'ABOUT_US',
        },
        {
          settings: {
            ...aboutUs.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#f0f0f0', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Snatched Crowdfunding UX' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '0cfc9e62-4087-45d0-bb7c-8ebc97dc4d60',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Accelerator-Approved UX Design' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '1394ba1d-b5ec-4dad-9bcf-fa4783d0337a',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-528014c2-ef36-4dfa-8b97-ec1c2cb6b53b',
              name: 'MEDIA',
              id: 'aad7019a-3ad6-4642-8f93-96661e09d66b',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'At our agency, we prioritize user satisfaction, user retention, and user conversion. We offer website performance, website speed, website accessibility, website security, website maintenance, website optimization, website analytics, website testing, website updates, website improvements, and website enhancements to ensure your site is top-notch. With our high-octane mobile-friendly design and invigorating user-friendly interface, we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'f1750a81-5c7d-46bc-819b-6c2b6d32adb4',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'ABOUT_US',
        },
      ],
    },
    { slug: '/shop', name: 'Shop ', blocks: [] },
  ],
  newMenu: [
    { name: 'Home', id: 'e484f8f9-b270-4a34-b525-4d7fcdc75dd0', type: 'internal', url: '/' },
    { name: 'About', id: 'f12277a6-625b-4b10-bedf-da6317295990', type: 'internal', url: '/about' },
    { name: 'Life', id: 'b75570bd-48b9-484e-a664-299faec31a6b', type: 'internal', url: '/life' },
    {
      name: 'Shop',
      id: '7546888e-56f2-4d77-a962-c96f560bf6f4',
      type: 'external',
      url: 'https://staging.d30zyboiwgk246.amplifyapp.com/home',
    },
  ],
  newMenuConfig: {
    settings: { backgroundColor: { color: '#406F2E', enabled: true }, textColor: { color: '#ffffff', enabled: true } },
    links: [
      { name: 'Home', id: '9ef72bbd-7ddf-4e8e-b970-1cc8fefd6c2f', type: 'internal', url: '/' },
      { name: 'Contact Us', id: 'f9a05a72-af3a-4948-a3f0-1fe9af4d138e', type: 'email', url: 'hello@example.com' },
    ],
    styleName: 'VARIANT 5',
    id: 'VARIANT_5',
    tags: [],
  },
  newFooter: {
    settings: {
      textColor: { color: '#ffffff', enabled: true },
      backgroundColor: { color: '#406F2E', enabled: true },
      spacing: {
        padding: { top: 30, left: 0, bottom: 30, right: 0 },
        margin: { top: 0, left: 0, bottom: 0, right: 0 },
        enabled: true,
      },
    },
    designedBy: 'Plly Staff',
    id: 'VARIANT_4',
    categories: [
      {
        name: 'Explore',
        links: [{ name: 'Home', id: 'e484f8f9-b270-4a34-b525-4d7fcdc75dd0', type: 'internal', url: '/' }],
        id: '8af61ec4-446f-4f48-acf6-8ce916553589',
      },
    ],
    tags: [],
  },

  links: [],
}
