import { motion } from 'framer-motion'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { PortfolioItemsGroup, PortfolioSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
export default function VariantOne({ section }: { section: PortfolioSection }) {
  const data = section.variables.find(variable => variable.name === 'ITEMS')?.data as PortfolioItemsGroup[]

  return (
    <div
      className=""
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 h-full px-2 lg:gap-x-5 lg:gap-y-4 md:px-12">
        {data.map(item => {
          return (
            <motion.div initial={{ y: 40 }} whileInView={{ y: 0, transition: { duration: 1 } }} key={item.id}>
              <img
                src={item.image}
                alt=""
                className="md:h-[580px] h-[270px] w-full object-cover opacity-90 hover:opacity-100 transition-all duration-500 cursor-pointer "
              />
              <h1 className="my-4 lg:my-6 mx-4 md:text-2xl font-semibold text-center md:text-left">
                <SectionSlatePreview initialValue={item.name} previewMode />
              </h1>
            </motion.div>
          )
        })}
      </div>
    </div>
  )
}
