import Icon from './icons'
import { IconPropsT } from './types'

function Logo(props: IconPropsT) {
  const { title = 'Logo', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 29 29" size={size} title={title} {...otherProps}>
      <circle cx="14" cy="14" r="13.4" strokeWidth="1.2" />
      <circle cx="14" cy="14" r="8.6" strokeWidth="0.8" />
      <circle cx="14" cy="14" r="4.7" strokeWidth="0.6" />
    </Icon>
  )
}

export default Logo
