import SectionSlate from '../../../common/SectionSlatePreview'
import { AttentionGrabberSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantEight({ section }: { section: AttentionGrabberSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: '#000',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="text-white text-center px-6 md:min-h-[80vh] min-h-[40vh] flex flex-col items-center justify-center py-6 md:py-12">
        <div
          style={{
            display: 'grid',
            placeContent: 'center',
            fontWeight: 700,
          }}
          className="text-[30px] md:text-[70px] lg:text-[110px]"
        >
          <div
            style={{
              gridArea: '1/1/-1/-1',
              clipPath: 'polygon(0% 0%, 100% 0%, 100% 48%, 0% 58%)',
            }}
          >
            <SectionSlate initialValue={title} previewMode />
          </div>
          <div
            style={{
              gridArea: '1/1/-1/-1',
              clipPath: 'polygon(0% 60%, 100% 50%, 100% 100%, 0% 100%)',
              transform: 'translateX(-0.02em)',
            }}
            aria-hidden="true"
          >
            <SectionSlate initialValue={title} previewMode />
          </div>
        </div>
        <div className="md:text-[30px] lg:text-[40px]">
          <SectionSlate initialValue={subTitle} previewMode />
        </div>
      </div>
    </div>
  )
}
