import React from 'react'

export default function Contacts() {
  return (
    <div className="xl:border-b lg:border-b md:border-b h-[90px] xl:lg:md:pl-8 pl-[18px] w-full flex items-center relative border-[#C4C4C4] xl:lg:md:border-black">
      <img
        src="https://images.unsplash.com/photo-1503919545889-aef636e10ad4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
        alt=""
        className="rounded-full relative w-[60px] h-[60px] object-cover border border-[#D9D9D9]"
      />
      <img
        src="https://images.unsplash.com/photo-1503919545889-aef636e10ad4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
        alt=""
        className="rounded-full absolute xl:lg:md:left-[80px] left-[65px] w-[60px] h-[60px] object-cover border border-[#D9D9D9]"
      />
      <div className="flex items-center gap-x-1 xl:lg:md:pl-20 pl-[3.5rem] font-bold text-lg">
        <span> Irma Cooper</span>,<span> John Charles</span>
      </div>
    </div>
  )
}
