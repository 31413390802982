import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { TextSection, TextSectionMediaGroup } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantFourteen({ section }: { section: TextSection }) {
  const data = section.variables.find(variable => variable.name === 'MEDIAGROUP')?.data as TextSectionMediaGroup[]
  return (
    <div
      className="py-6 lg:py-12"
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-2 lg:gap-6 lg:px-12 px-4">
        {data.map(item => {
          return (
            <div key={item.id} className="transition-all duration-500 flex flex-col p-4 py-5 border">
              <div>
                <img
                  src={item.image}
                  className="h-[250px] lg:h-[500px] transition-all duration-300 object-cover object-center m-auto w-full"
                />
              </div>
              <div className="flex flex-col">
                <p className="lg:text-3xl tracking-wide md:font-semibold lg:mt-4 mt-2">
                  <SectionSlatePreview initialValue={item.name} previewMode />
                </p>
                <p className="lg:text-2xl lg:py-4">
                  <SectionSlatePreview initialValue={item.description} previewMode />
                </p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
