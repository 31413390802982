import Icon from './icons'
import { IconPropsT } from './types'

function Site(props: IconPropsT) {
  const { title = 'Site', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 24 34" size={size} title={title} {...otherProps}>
      <rect x="0.2" y="0.2" width="23.6" height="33.6" rx="5.8" fill="#E90570" stroke="black" strokeWidth="0.4" />
      <rect
        x="2.3333"
        y="2.2"
        width="20.4"
        height="29.6"
        rx="5.8"
        stroke="black"
        fill="black"
        strokeWidth="0.4"
        className="hidden dark:block"
      />
      <rect
        x="2.3333"
        y="2.2"
        width="20.4"
        height="29.6"
        rx="5.8"
        fill="white"
        stroke="white"
        strokeWidth="0.4"
        className="block dark:hidden"
      />
    </Icon>
  )
}

export default Site
