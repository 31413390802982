import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { Loader } from 'src/components/loader'
import ModalDialogV2 from 'src/components/new/ModalDialogV2'
import BackIcon from 'src/icons/BackIcon'
import Copy from 'src/icons/Copy'
import Delete from 'src/icons/Delete'
import EditMinus from 'src/icons/EditMinus'
import LinkIcon from 'src/icons/Link'
import Logo from 'src/icons/Logo'
import Wrench from 'src/icons/Wrench'
import { NewMenuLink, NewMenuLinkType } from 'src/_polly/components/src/menu/menu'
import LinkPointer from './LinkPointer'
import { MenuSaveButton } from './NewMenu'

type NewMenuAddNewLinkProps = {
  onAdd?: (link: Partial<NewMenuLink>) => void
  defaultValue?: NewMenuLink | null
  editBack?: () => void
  onDuplicateClick?: () => void
  onDeleteClick?: () => void
  handleLinkAdd?: (link: NewMenuLink) => void
  newLink?: any
  index?: any
}

type Inputs = { name: string; url: string }

function getEditLocationText(url?: string | null, type?: NewMenuLinkType) {
  if (type === NewMenuLinkType.DOCUMENT) return 'Document'
  if (type === NewMenuLinkType.IN_PAGE) return 'In-Page Component'
  if (!url || url === '#') return 'Edit location'
  if (type === NewMenuLinkType.EXTERNAL) {
    return (
      (url.search('https://') === 0 && url.split('https://')) || (url.search('http://') === 0 && url.split('http://'))
    )
  }
  return url
}

export default function NewMenuAddNewLink({
  onAdd,
  index,
  defaultValue,
  editBack,
  onDuplicateClick,
  onDeleteClick,
  handleLinkAdd,
  newLink,
}: NewMenuAddNewLinkProps) {
  const [linkConfig, setLinkConfig] = useState<Omit<NewMenuLink, 'id' | 'name'> | null>(null)
  const [showLinkPointer, setShowLinkPointer] = useState(false)
  const [error, setError] = useState(false)
  const [saveConfig, setSaveConfig] = useState(false)

  const {
    register,
    formState: { isSubmitting, errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<Inputs>()

  const name = watch('name')

  const onSubmit: SubmitHandler<Inputs> = data => {
    if (newLink && !linkConfig) {
      onAdd?.({
        ...defaultValue,
        name: data.name,
        url: `${
          (data.url.search('https://') !== 0 ? `http://${data.url}` : data.url) ||
          (data.url.search('http://') !== 0 ? `http://${data.url}` : data.url)
        }`,
        type: NewMenuLinkType.EXTERNAL,
      })
    } else {
      onAdd?.({
        ...defaultValue,
        ...linkConfig,
        name: data.name,
      })
    }
  }

  useEffect(() => {
    if ((newLink && !defaultValue && linkConfig) || (saveConfig && defaultValue && linkConfig)) {
      handleSubmit(onSubmit)()
      setSaveConfig(false)
    }
  }, [linkConfig, defaultValue])

  useEffect(() => {
    if (defaultValue) {
      setValue('name', defaultValue.name)
      setLinkConfig({
        type: defaultValue.type,
        url: defaultValue.url,
      })
    }
  }, [defaultValue])

  return (
    <div className="pb-[8px] bg-white dark:bg-black">
      <Loader show={isSubmitting} />
      <div className="fixed top-0 left-0 px-5 pt-[21px]">
        <div className="flex items-center">
          {editBack && (
            <button type="button" className="flex-shrink-0" onClick={editBack}>
              <BackIcon
                size={28}
                fill="none"
                className="fill-white dark:fill-black stroke-black dark:stroke-white cursor-pointer mr-[16px]"
              />
            </button>
          )}
          <Link to="/" className="">
            <Logo
              size={28}
              fill="none"
              className="fill-white dark:fill-black stroke-black dark:stroke-white cursor-pointer"
            />
          </Link>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          <div className="flex items-center pt-[32px] border-t-[1px] border-black dark:border-white">
            <div className="w-[58px]">
              <LinkIcon
                height={24}
                className="stroke-[#8D8D8D] fill-[#8D8D8D] dark:fill-[#D9D9D9] dark:stroke-[#D9D9D9] mx-auto"
              />
            </div>
            <input
              type="text"
              placeholder={
                (errors.name && errors.name.type === 'required') || error ? 'Link Name Required' : 'Type Link Name'
              }
              className={`text-[19px] w-1/2 border-b dark:text-white dark:bg-black pb-[7px] outline-none text-left ${
                (errors.name && errors.name.type === 'required') || error
                  ? 'border-red-500 placeholder:text-red-300'
                  : 'dark:border-white border-black'
              }`}
              {...register('name', { required: true })}
            />
            {editBack && (
              <div className="w-[127px]">
                <button type="button" className="hidden" onClick={editBack}>
                  <EditMinus
                    size={50}
                    fill="none"
                    className="stroke-[#8D8D8D] fill-white dark:fill-black dark:stroke-white mx-auto"
                  />
                </button>
              </div>
            )}
          </div>
          <div className="flex items-center pt-[32px]">
            {newLink || defaultValue?.type === 'external' ? (
              <div className="w-[65px]">
                <h2 className="text-[19px] dark:text-white">
                  {(defaultValue?.url.search('https://') === 0 ? 'https://' : 'http://') ||
                    (defaultValue?.url.search('http://') === 0 ? 'http://' : 'http://')}
                </h2>
              </div>
            ) : (
              <div className="w-[65px]">
                <LinkIcon
                  height={24}
                  className="stroke-[#8D8D8D] fill-[#8D8D8D] dark:fill-[#D9D9D9] dark:stroke-[#D9D9D9] mx-auto"
                />
              </div>
            )}
            {!newLink ? (
              <p
                className={`text-[19px] w-1/2 dark:border-white dark:bg-black dark:text-white border-b border-black pb-[7px] outline-none text-left ${
                  newLink && 'text-black/50 dark:text-white/50'
                }`}
              >
                {getEditLocationText(linkConfig?.url ?? defaultValue?.url, linkConfig?.type ?? defaultValue?.type)}
              </p>
            ) : (
              <input
                type="text"
                placeholder="google.com"
                className="text-[19px] w-1/2 border-b dark:text-white dark:bg-black dark:border-white border-black pb-[7px] outline-none text-left"
                {...register('url')}
              />
            )}
            <div className="w-[127px]">
              <MenuSaveButton save="save" type="submit" />
            </div>
          </div>
          <div className="dark:border-white border-black border-t border-b py-5 mt-[37px]">
            <div className="flex items-center justify-end px-[38px] gap-[35px]">
              {onDuplicateClick && (
                <button type="button" onClick={onDuplicateClick}>
                  <Copy size={18} fill="#E90570" className="stroke-[#E90570]" />
                </button>
              )}
              {onDeleteClick && (
                <button
                  type="button"
                  className={`${index === 0 && defaultValue?.url === '/' && 'hidden'}`}
                  onClick={onDeleteClick}
                >
                  <Delete height={20} fill="none" className="stroke-[#E90570]" />
                </button>
              )}
              <button
                type="button"
                onClick={() => {
                  if (name) {
                    setShowLinkPointer(true)
                  } else {
                    setError(true)
                  }
                }}
              >
                <Wrench title="Edit Location" height={23} fill="#E90570" />
              </button>
            </div>
          </div>
        </div>
      </form>

      <ModalDialogV2 open={showLinkPointer} onBack={() => setShowLinkPointer(false)}>
        <LinkPointer
          handleLinkAdd={handleLinkAdd}
          onSelect={config => {
            setLinkConfig(config)
            setShowLinkPointer(false)
            handleSubmit(onSubmit)
            setSaveConfig(true)
          }}
          linkName={name}
        />
      </ModalDialogV2>
    </div>
  )
}
