import { useEffect, useState } from 'react'

type TVideosProps = {
  searchQuery?: string
}

function useYoutubeVideos({ searchQuery = 'react' }: TVideosProps) {
  //   const API_KEY = 'AIzaSyC1ga8jQedewZ1QPf11BecvHHjBP4vI_dQ'
  const API_KEY = 'AIzaSyCsbPUgrPHWAInLb6ccdGjqcnF8516yxGQ'
  const SEARCH_URL = `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&type=video&part=snippet&maxResults=10`
  const [videos, setVideos] = useState([])
  useEffect(() => {
    search(`${searchQuery}`)
  }, [searchQuery])
  const search = (query: any) => {
    fetch(`${SEARCH_URL}&q=${query}`)
      .then(response => response.json())
      .then(data => setVideos(data.items))
  }

  return { videos }
}
export default useYoutubeVideos
