import { motion } from 'framer-motion'
import { GoPrimitiveDot } from 'react-icons/go'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { MENUSection, MENUSectionItem } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import MENUItem from './MenuItem'

export default function VariantOne({ section }: { section: MENUSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subtitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const menus = section.variables.find(variable => variable.name === 'ITEMS')?.data as MENUSectionItem[]

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
      }}
    >
      <div
        className=" py-12"
        style={{
          ...generateSectionSpacing(section?.settings?.spacing),
        }}
      >
        <div className="flex items-center flex-col container mx-auto">
          <div className="lg:flex gap-8 lg:mx-10 px-4 md:px-0">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{
                type: 'spring',
                stiffness: 100,
                delay: 0.1,
                duration: 200,
              }}
              viewport={{ once: true }}
            >
              <h1 className="text-3xl font-main">
                <SectionSlatePreview initialValue={title} previewMode />
              </h1>
              <div className="text-[#B48B7E] my-2 w-16 flex items-center">
                <span className="mr-2">
                  <GoPrimitiveDot></GoPrimitiveDot>
                </span>
                <div className="w-full">
                  <div className="bg-[#B48B7E] h-[1px]"></div>
                </div>
              </div>
              <div className="flex flex-col gap-6 mt-8">
                {menus.slice(0, 4).map(MENU => (
                  <MENUItem
                    key={MENU.id}
                    image={MENU.image}
                    title={MENU.title1}
                    subtitle={MENU.subtitle1}
                    description={MENU.description1}
                  />
                ))}
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{
                type: 'spring',
                stiffness: 100,
                delay: 0.1,
                duration: 200,
              }}
              viewport={{ once: true }}
            >
              <h1 className="md:text-3xl font-main">
                <SectionSlatePreview initialValue={subtitle} previewMode />
              </h1>
              <div className="text-[#B48B7E] my-2 w-16 flex items-center">
                <span className="mr-2">
                  <GoPrimitiveDot></GoPrimitiveDot>
                </span>
                <div className="w-full">
                  <div className="bg-[#B48B7E] h-[1px]"></div>
                </div>
              </div>
              <div className="flex flex-col gap-6 ">
                {menus.slice(4, 8).map(MENU => (
                  <MENUItem
                    key={MENU.id}
                    image={MENU.image}
                    title={MENU.title1}
                    subtitle={MENU.subtitle1}
                    description={MENU.description1}
                  />
                ))}
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  )
}
