import { ImageOmgSection as OmgSectionType } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import VariantThirteen from './VariantThirteen'

export default function VariantFourteen({ section }: { section: OmgSectionType }) {
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: 'black',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      {media && (
        <div>
          <div className="container mx-auto">
            <div className="h-[560px] w-full mx-auto">
              <img className="h-full w-full object-cover object-center bg-black" src={media} alt="" />
            </div>
          </div>
        </div>
      )}
      <VariantThirteen {...{ section }} hideImageBg />
    </div>
  )
}
