import { useEffect, useState } from 'react'
import { ReactComponent as EditPlus } from 'src/components/icons/EditPlus.svg'
import ModalDialogV2 from 'src/components/new/ModalDialogV2'
import AdvancedIcon from 'src/icons/Advanced'
import { Section, SectionSettings } from 'src/_polly/components/src/sections'
import Effects from './Effects'
import Spacing from './Spacing'
import ThemeColors from './ThemeColors'
import TitleBar from './TitleBar'

type AdvancedProps = {
  section: Section<any, any>
  onChange?: (settings: SectionSettings) => void
  onBack?: () => void
}

export default function Advanced({ onChange, section, onBack }: AdvancedProps) {
  const [settings, setSettings] = useState(section.settings)
  const [showAdvanced, setShowAdvanced] = useState(false)
  const [showThemeColors, setShowThemeColors] = useState(false)
  const [showSpacing, setShowSpacing] = useState(false)
  const [showEffects, setShowEffects] = useState(false)

  useEffect(() => {
    onChange?.(settings)
  }, [settings])

  return (
    <div>
      <div className="absolute right-6 bottom-11">
        <div className="dark:bg-black bg-white bottom-10 md:bottom-12 border rounded dark:border-white border-black w-[59px] h-[57px] flex flex-col items-center justify-center  py-6 mx-auto ">
          <button type="button" onClick={() => setShowAdvanced(!showAdvanced)} className="outline-none">
            {!showAdvanced && <AdvancedIcon size={28} color="#E90570" />}
          </button>
        </div>
      </div>

      <ModalDialogV2
        open={showAdvanced}
        onBack={() => {
          setShowAdvanced(false)
          onBack?.()
        }}
      >
        <div className="px-4">
          <TitleBar title="Advanced" />
          <Item title="Theme Colors" onClick={() => setShowThemeColors(true)} />
          <Item title="Spacing" onClick={() => setShowSpacing(true)} />
          <Item title="Effects" onClick={() => setShowEffects(true)} />
        </div>
      </ModalDialogV2>

      <ModalDialogV2 open={showThemeColors} onBack={() => setShowThemeColors(false)}>
        <ThemeColors defaultValue={settings?.color} onChange={color => setSettings(s => ({ ...s, color }))} />
      </ModalDialogV2>

      <ModalDialogV2 open={showSpacing} onBack={() => setShowSpacing(false)}>
        <Spacing
          defaultValue={settings?.spacing}
          onChange={spacing => setSettings(s => ({ ...s, spacing }))}
          onBack={() => setShowSpacing(false)}
        />
      </ModalDialogV2>

      <ModalDialogV2 open={showEffects} onBack={() => setShowEffects(false)}>
        <Effects
          defaultValue={settings?.transitionSpeed}
          onChange={speed => setSettings(s => ({ ...s, transitionSpeed: speed }))}
          onBack={() => setShowEffects(false)}
        />
      </ModalDialogV2>
    </div>
  )
}

function Item({ title, onClick }: { title: string; onClick?: () => void }) {
  return (
    <div className="flex justify-between items-center border-b border-gray-200 py-4 px-2">
      <p className="text-xl font-light dark:text-white">{title}</p>
      <button type="button" onClick={onClick}>
        <EditPlus />
      </button>
    </div>
  )
}
