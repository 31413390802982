import Color from 'color'
import { useEffect, useState } from 'react'
import { Descendant } from 'slate'
import { SectionSlate } from 'src/components/editor'
import SectionBaseField from './BaseField'

interface SectionTextFieldProps {
  initialValue: Descendant[]
  isParagraph?: boolean
  title?: string
  onChange: (value: Descendant[]) => void
  onUpdate?: (value: Descendant[]) => void | any
}

export default function SectionTextField({
  initialValue,
  onChange,
  isParagraph = true,
  title = 'Title',
  onUpdate,
}: SectionTextFieldProps) {
  const [isLightColor, setIsLightColor] = useState<boolean>(false)
  const [isDarkColor, setIsDarkColor] = useState<boolean>(false)
  const [isDefaultColor, setIsDefaultColor] = useState<boolean>(false)

  useEffect(() => {
    const defaultColor: any = initialValue[0]
    const initColor = defaultColor?.children[0]?.color
    const color = Color(initColor)
    const light = color.isLight()
    const dark = color.isDark()
    setIsLightColor(light)
    setIsDarkColor(dark)
    if (initColor) {
      setIsDefaultColor(true)
    } else {
      setIsDefaultColor(false)
    }
  }, [initialValue])

  return (
    <SectionBaseField
      title={title}
      initialValue={initialValue}
      isLightColor={isLightColor}
      isDarkColor={isDarkColor}
      isDefaultColor={isDefaultColor}
      preview={
        <SectionSlate initialValue={initialValue} editView previewMode onChange={onChange} onUpdate={onUpdate} />
      }
      onUpdate={onUpdate}
    >
      <div
        style={{
          background: `${
            (isLightColor && !isDarkColor && isDefaultColor && 'black') ||
            (!isLightColor && isDarkColor && isDefaultColor && 'white') ||
            (!isDefaultColor && '')
          }`,
        }}
      >
        <SectionSlate
          initialValue={initialValue}
          onChange={value => {
            console.log('calling on change from text field', value)
            onChange(value)
          }}
          showListSelectors={isParagraph}
          onUpdate={onUpdate}
        />
      </div>
    </SectionBaseField>
  )
}
