import { TextSection as TextSectionType } from '../../types'
import { motion } from 'framer-motion'
import SectionSlate from '../../../common/SectionSlatePreview'
import ButtonRenderer from '../ButtonRenderer'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantThree({ section }: { section: TextSectionType }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data
  const button = section.variables.find(variant => variant.name === 'BUTTON_1')?.data
  return (
    <div
      className="lg:py-[100px] mx-auto"
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div>
        <div className="rounded-[20px] flex flex-col justify-center items-center m-auto ">
          <motion.div
            initial={{ x: -5, opacity: 0.6 }}
            whileInView={{ x: 0, opacity: 1 }}
            exit={{ x: 0 }}
            transition={{ duration: 3, type: 'spring' }}
            className="grid md:grid-cols-2 shadow rounded-[20px] justify-between  xl:w-[60%]"
          >
            <div>
              <img src={media} className=" rounded-[20px] h-[200px] md:h-[400px] w-full object-cover " />
            </div>
            <div className="flex flex-col justify-center items-center gap-y-1">
              <h1 className="text-[20px] md:text-2xl font-bold  capitalize tracking-wider ">
                <SectionSlate initialValue={title} previewMode />
              </h1>
              <h1 className="tracking-wider capitalize text-lg lg:text-xl font-bold  font-title">
                <SectionSlate initialValue={subTitle} previewMode />
              </h1>
              <h3 className="capitalize">
                <SectionSlate initialValue={paragraph} previewMode />
              </h3>
              {button ? (
                <ButtonRenderer button={button} />
              ) : (
                <button className="font-semibold rounded-[20px] shadow-secondary bg-gray-900 p-2 xl:p-3 xl:px-10 mt-3 text-white">
                  for more
                </button>
              )}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  )
}
