import { GallerySection, GallerySectionItem } from '../../types'

import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Pagination } from 'swiper'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantEight({ section }: { section: GallerySection }) {
  const data = section.variables.find(variable => variable.name === 'MEDIA')?.data as GallerySectionItem[]

  return (
    <div
      className="md:py-[20px] py-4"
      style={{
        ...generateSectionBackground({
          defaultColor: '#fff',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <Swiper
        pagination={{
          clickable: true,
        }}
        loop={true}
        autoplay={{
          delay: 1500,
        }}
        modules={[Pagination]}
        effect="slide"
        className="mySwiper md:h-full "
        grabCursor={true}
      >
        <div className="md:container md:w-[1200px] w-full max-w-full gap-2">
          {data.map(item => (
            <SwiperSlide>
              <div key={item.id} style={{ aspectRatio: '4 / 3' }}>
                <img className="object-cover object-center bg-black w-full h-full" src={item.image} alt="" />
              </div>
            </SwiperSlide>
          ))}
        </div>
      </Swiper>
    </div>
  )
}
SwiperCore.use([Autoplay])
