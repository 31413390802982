import { Dialog, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { Fragment, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import usePostLike from 'src/hooks/usePostLike'
import Chat from 'src/icons/Chat'
import Close from 'src/icons/Close'
import Heart from 'src/icons/Heart'
import RightArrow from 'src/icons/RightArrow'
import Users from 'src/icons/Users'
import UsersLight from 'src/icons/UsersLight'
import { getPageByID, getWebsiteByID } from 'src/lib/services/website.service'
import { allCommentByPostID } from 'src/services/comment.service'
import { SectionPreviewRenderer } from 'src/_polly/components/src/sections'
import Comments from './Comments'
import ShareModal from './ShareModal'

type TCommentsProps = {
  post: any
  showComments: boolean
  setShowComments: any
}

function CommentsModal({ post, showComments, setShowComments }: TCommentsProps) {
  const closeModal = () => {
    setShowComments(false)
  }
  const [hideCommentInput, setHideCommentInput] = useState<boolean>(false)
  const { liked, toggleLike, count } = usePostLike(post.id)
  const [newLimit, setNewLimit] = useState(1)
  const [loadMoreCount, setLoadMoreCount] = useState(7)
  const [showEmojiIcons, setShowEmojiIcons] = useState(false)
  const [showNoSite, setShowNoSite] = useState(false)
  const [commentsCount, setCommentsCount] = useState(0)
  const [component, setComponent] = useState<any>(null)
  const [website, setWebsite] = useState<any>()
  const commentModal = true

  const commentInputRef = useRef<HTMLInputElement>(null)

  const handleComment = () => {
    setHideCommentInput(!hideCommentInput)
    commentInputRef?.current?.focus()
    setNewLimit(1)
    setLoadMoreCount(1)
    setShowEmojiIcons(false)
    setShowComments(true)
  }

  useEffect(() => {
    if (!post) return
    allCommentByPostID(post.id).then(res => setCommentsCount(res?.items.length || 0))
    if (post.pageID) {
      getPageByID(post.pageID).then(data => {
        if (!data) return
        setComponent((JSON.parse(data.blocks) as any[]).find(block => block.id === post.componentID))
      })
    }
  }, [post])
  useEffect(() => {
    Promise.all([getWebsiteByID(post?.websiteID)]).then(([_website]) => {
      setWebsite(_website)
    })
  }, [post?.websiteID])

  return (
    <Transition appear show={showComments} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-white dark:bg-black" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full w-full lg:w-[950px] mx-auto items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full transform overflow-hidden rounded-2xl bg-white dark:bg-black px-6 text-left align-middle transition-all">
                <div className="flex md:block justify-between items-center py-4">
                  <div className="md:hidden">
                    <Link to="/home">
                      <svg className="stroke-black dark:stroke-white stroke-2 text-black dark:text-white" height="30" width="30" viewBox="0 0 64 64" style={{ fill: 'none' }}><title>Logo</title><g clipPath="url(#clip0_102_3)"><path d="M32 62C48.5685 62 62 48.5685 62 32C62 15.4315 48.5685 2 32 2C15.4315 2 2 15.4315 2 32C2 48.5685 15.4315 62 32 62Z" /><path d="M32.0006 52.1447C43.1262 52.1447 52.1453 43.1255 52.1453 31.9999C52.1453 20.8742 43.1262 11.8551 32.0006 11.8551C20.8749 11.8551 11.8558 20.8742 11.8558 31.9999C11.8558 43.1255 20.8749 52.1447 32.0006 52.1447Z" /><path d="M31.9994 43.0021C38.0756 43.0021 43.0013 38.0764 43.0013 32.0002C43.0013 25.924 38.0756 20.9983 31.9994 20.9983C25.9232 20.9983 20.9975 25.924 20.9975 32.0002C20.9975 38.0764 25.9232 43.0021 31.9994 43.0021Z" /></g></svg>
                    </Link>
                  </div>
                  <div className="flex justify-end gap-x-6">
                    <button type="button" onClick={closeModal}>
                      <Close size={24} className="text-[#fff]" />
                    </button>
                    <button type="button">
                      <RightArrow size={24} className="text-[#EFEFEF]" />
                    </button>
                  </div>
                </div>
                {/* <div className="px-6 sm:px-0 sm:w-[370px] md:w-[720px] pt-6 mx-auto"> */}
                <div className="w-full md:pt-6">
                  {/* <Post filterGrid="one" post={post} /> */}
                  <div className="mb-12 lg:mx-[80px] pb-2">
                    <div
                      className={clsx(
                        'fixed z-50 top-0 right-0 bottom-0 left-0 justify-center items-center',
                        !showNoSite && 'hidden',
                      )}
                    >
                      <div className="relative w-full h-full bg-white/90 dark:bg-black/90 flex justify-center items-center">
                        <div className="w-[362px] bg-white dark:bg-black border-[1px] rounded-[6px] border-[#E90570] border-solid  relative">
                          <p className="text-[25px] text-black py-[50px] px-[65px] dark:text-white text-center">
                            The site connected to this component has been deleted.
                          </p>
                          <button
                            onClick={() => setShowNoSite(false)}
                            type="button"
                            className="absolute top-[-4px] right-[8px]"
                          >
                            <svg
                              width="29"
                              height="42"
                              className="fill-white stroke-[#E90570] dark:fill-black"
                              viewBox="0 0 29 42"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect x="0.5" y="13.5" width="28" height="28" rx="4.5" />
                              <path d="M10.705 22.1254L14.425 26.9554L18.13 22.1254H19.015L14.845 27.5254L18.895 32.7754H18.01L14.425 28.0954L10.825 32.7754H9.92495L13.975 27.5254L9.80495 22.1254H10.705Z" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    {website ? (
                      <Link onClick={setWebsite} to={`/website/${post.websiteID}/preview`}>
                        <div className=" w-full border-l border-t border-r rounded-t-lg overflow-hidden">
                          {component ? (
                            <SectionPreviewRenderer section={component} noShadow disableHover />
                          ) : (
                            <div className="px-10 py-20">
                              <p className="text-black dark:text-white">
                                This post was shared when we didn&apos;t have the component render feature. Posts from
                                now will have preview.
                              </p>
                            </div>
                          )}
                        </div>
                      </Link>
                    ) : (
                      <button
                        type="button"
                        onClick={() => setShowNoSite(true)}
                        className="max-w-full lg:max-w-[720px] w-full border-l border-t border-r rounded-t-lg overflow-hidden"
                      >
                        {component ? (
                          <SectionPreviewRenderer section={component} noShadow disableHover />
                        ) : (
                          <div className="px-10 py-20">
                            <p className="text-black dark:text-white">
                              This post was shared when we didn&apos;t have the component render feature. Posts from now
                              will have preview.
                            </p>
                          </div>
                        )}
                      </button>
                    )}
                    <div className="border dark:border-y-x dark:border-x-0 py-3 px-4">
                      <div className="flex justify-between items-center">
                        <Link to={`/profile/${post.user?.id}`} className="flex items-center space-x-3">
                          <div className="w-[20px] md:w-[30px] h-[20px] md:h-[30px]">
                            <img
                              className="w-full h-full object-cover object-center bg-gray-200 rounded-full"
                              src={post.user?.profilePicture ?? ''}
                              alt={post.user?.firstName}
                            />
                          </div>
                          <h4 className="text-[15px] dark:text-white md:text-[17px]">{post.title}</h4>
                        </Link>
                        <div className="flex items-center space-x-6 md:space-x-12">
                          <div className="relative flex items-end pt-1">
                            <button type="submit" onClick={toggleLike}>
                              {liked ? (
                                <Heart size={24} className="text-brand-accent dark:text-brand-accent" />
                              ) : (
                                <Heart size={24} className="text-black dark:text-white" />
                              )}
                              {count > 0 && (
                                <div
                                  style={{ top: '-2px', left: '12px' }}
                                  className="w-[14px] absolute h-[14px] rounded-full flex justify-center items-center bg-brand-accent text-white text-[11px]"
                                >
                                  {count}
                                </div>
                              )}
                            </button>
                          </div>
                          <div className="relative flex items-end">
                            <button onClick={handleComment} type="submit">
                              {commentsCount > 0 ? (
                                <Chat size={24} className="text-brand-accent dark:text-brand-accent" />
                              ) : (
                                <Chat size={24} />
                              )}
                              {commentsCount > 0 && (
                                <div
                                  style={{ top: '-2px', left: '12px' }}
                                  className="w-[14px] absolute h-[14px] rounded-full flex justify-center items-center bg-brand-accent text-white text-[11px]"
                                >
                                  {commentsCount}
                                </div>
                              )}
                            </button>
                          </div>

                          <button type="submit">
                            <div className="hidden dark:block">
                              <Link to={`/messages/${post.user?.id}`}>
                                <Users size={24} className="dark:text-[#ddd]" />
                              </Link>
                            </div>
                            <div className=" dark:hidden">
                              <Link to={`/messages/${post.user?.id}`}>
                                <UsersLight size={24} className="text-black" />
                              </Link>
                            </div>
                          </button>
                          <ShareModal postId={post?.id} />
                        </div>
                      </div>
                      {post.description && (
                        <div className="dark:text-white pt-3 md:text-[20px]">
                          <p>{post.description}</p>
                        </div>
                      )}
                    </div>
                    <Comments
                      ref={commentInputRef}
                      {...{
                        hideCommentInput,
                        post,
                        newLimit,
                        setNewLimit,
                        loadMoreCount,
                        showEmojiIcons,
                        setShowEmojiIcons,
                        commentModal
                      }}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
export default CommentsModal
