import clsx from 'clsx'
import { useContext, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Descendant } from 'slate'
import BottomFloatingButton from 'src/components/BottomFloatingButton'
import { WebsiteContext } from 'src/components/context/WebsiteContext'
import ModalDialogV2 from 'src/components/new/ModalDialogV2'
import Gallery from 'src/icons/ComponentsIcons/Gallery'
import { upsertBlockByPageID } from 'src/services/website.service'
import {
  GallerySection,
  GallerySectionItem,
  GallerySectionVariables,
  SectionVariableCategories,
} from 'src/_polly/components/src/sections'
import { v4 as uuid } from 'uuid'
import { ReactComponent as DeleteIcon } from '../../assets/icons/menuBar/delete.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/menuBar/edit.svg'
import { ImageIcon } from '../editor/icons'
import { AdvancedFeature } from '../modal/advanced'
import { MenuSaveButton } from '../modal/section/NewMenu/NewMenu'
import { EditSection } from './EditSectionModal'
import ImageUploader from './ImageUploader'
import SectionTextField from './SectionField/SectionTextField'
import ItemField from './SectionField/SectionSingleItemField'

interface EditSectionGalleryModalProps extends EditSection {
  section: GallerySection
}

type EditGalleyProps = {
  data?: GallerySectionItem
  onSave: (data: Partial<GallerySectionItem>) => void
  variantName: any
}

function Item({
  data,
  onDeleteClick,
  onEditClick,
}: {
  data: GallerySectionItem
  onEditClick: () => void
  onDeleteClick: () => void
}) {
  // const [titles, setTitle] = useState(data?.title)

  return (
    <div className="border border-black dark:border-white h-[65px] flex justify-between">
      <div className="flex items-center">
        <div className="grid grid-cols-2 gap-1 ml-2 mr-4">
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
        </div>
        <div className="flex items-center gap-4">
          <p>
            <ItemField initialValue={data?.title} />
          </p>
        </div>
      </div>

      <div className="flex">
        <button
          type="button"
          className="flex justify-center items-center w-16 border-l border-black dark:border-white"
          onClick={onEditClick}
        >
          <EditIcon />
        </button>
        <button
          type="button"
          className="flex justify-center items-center w-16 border-l border-black dark:border-white"
          onClick={onDeleteClick}
        >
          <DeleteIcon />
        </button>
      </div>
    </div>
  )
}

type FormInput = {
  titles: any
  descriptions: any
  items?: any
  years?: any
}

function EditGallery({ data, onSave, variantName }: EditGalleyProps) {
  const [showImageModal, setShowImageModal] = useState(false)
  const [url, setUrl] = useState(data?.image || '')

  const initialDataName: Descendant[] = [
    {
      type: 'paragraph',
      children: [
        {
          text: 'item name',
        },
      ],
    },
  ]
  const initialDataDescription: Descendant[] = [
    {
      type: 'paragraph',
      children: [
        {
          text: 'item description',
        },
      ],
    },
  ]
  const [titles, setTitle] = useState(data?.title || initialDataName)
  const [descriptions, setDescription] = useState(data?.description || initialDataDescription)
  const { handleSubmit } = useForm<FormInput>()

  const onSubmit: SubmitHandler<FormInput> = value => {
    onSave({
      ...value,
      id: data?.id,
      title: titles,
      description: descriptions,
      image: url,
    })
    setTitle(value?.titles)
    setDescription(value?.descriptions)
    setUrl(url)
  }

  // const title = watch('title')
  // const description = watch('description')
  return (
    <div className="px-4 dark:text-white">
      <form className="pb-24" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-8">
          {variantName !== 'VARIANT_8' && variantName !== 'VARIANT_1' && (
            <div>
              <SectionTextField
                title="Title"
                initialValue={titles ?? initialDataName}
                onChange={value => setTitle(value)}
                onUpdate={() => handleSubmit(onSubmit)}
              />
            </div>
          )}
          {variantName !== 'VARIANT_8' && variantName !== 'VARIANT_1' && (
            <div>
              <SectionTextField
                title="Description"
                initialValue={descriptions ?? initialDataDescription}
                onChange={value => setDescription(value)}
                onUpdate={() => handleSubmit(onSubmit)}
              />
            </div>
          )}

          {/* {(variantName === 'VARIANT_1' ||
            variantName === 'VARIANT_3' ||
            variantName === 'VARIANT_8' ||
            variantName === 'VARIANT_14') && ( */}
          <div className="space-y-4">
            <h2 className="text-[19px] font-semibold">Image</h2>
            <button type="button" className="w-full" onClick={() => setShowImageModal(true)}>
              <div
                className={clsx(
                  'w-full bg-white dark:bg-black',
                  !url && 'aspect-1 flex justify-center items-center border border-black dark:border-white',
                  url && 'aspect-1',
                )}
              >
                {url && <img src={url} className="object-cover object-center bg-black w-full h-full" alt="" />}
                {!url && <ImageIcon />}
              </div>
            </button>
          </div>
          {/* )} */}
        </div>
      </form>

      <BottomFloatingButton
        label={data?.id ? 'Save' : 'Add'}
        onClick={handleSubmit(onSubmit)}
        // disabled={!title || !description}
      />
      <ModalDialogV2 open={showImageModal} onBack={() => setShowImageModal(false)}>
        <ImageUploader
          onSubmit={_url => {
            setUrl(_url)
            setShowImageModal(false)
          }}
          buttonLabel="Select Image"
          onClose={() => setShowImageModal(false)}
        />
      </ModalDialogV2>
    </div>
  )
}

export default function EditSectionGalleryModal({
  section: defaultValue,
  open,
  onClose: onBack,
  onUpdate,
}: EditSectionGalleryModalProps) {
  const websiteContext = useContext(WebsiteContext)

  const [section, setSection] = useState(defaultValue)
  const [showGallerModal, setShowGalleryModal] = useState(false)
  const [editGallery, setEditGallery] = useState<GallerySectionItem>()
  const [updating, setUpdating] = useState(false)
  const [fireUpdate, setFireUpdate] = useState({
    fire: false,
    goBack: false,
  })

  const title = section.variables.find(variable => variable.name === 'TITLE')
  const gallery = section.variables.find(variable => variable.name === 'MEDIA')
  const variantName = section?.variant

  const updateVariableData = (name: GallerySectionVariables, category: SectionVariableCategories, data: any) => {
    const current = section.variables.find(variable => variable.name === name)
    if (!current) {
      setSection({
        ...section,
        variables: [...section.variables, { name, data, category, id: uuid() }],
      })
      return
    }
    const otherVariables = section.variables.filter(variable => variable.name !== name)
    const newVariables = [...otherVariables, { ...current, data }]
    setSection({ ...section, variables: newVariables })
  }

  const handleDelete = (id: string) => {
    const newItems = gallery?.data.filter((item: GallerySectionItem) => item.id !== id)
    setSection({
      ...section,
      variables: section.variables.map(variable => {
        if (variable.name === 'MEDIA') {
          return { ...variable, data: newItems }
        }
        return variable
      }),
    })
    handleSave(false)
  }

  const handleEditAdd = (data: Partial<GallerySectionItem>) => {
    if (data.id) {
      const newItems = gallery?.data.map((item: GallerySectionItem) => {
        if (item.id === data.id) {
          return { ...item, ...data }
        }
        return item
      })
      setSection({
        ...section,
        variables: section.variables.map(variable => {
          if (variable.name === 'MEDIA') {
            return { ...variable, data: newItems }
          }
          return variable
        }),
      })
    } else {
      setSection({
        ...section,
        variables: section.variables.map(variable => {
          if (variable.name === 'MEDIA') {
            return {
              ...variable,
              data: [
                ...variable.data,
                {
                  ...data,
                  id: uuid(),
                },
              ],
            }
          }
          return variable
        }),
      })
    }
    setEditGallery(undefined)
    setShowGalleryModal(false)
    handleSave(false)
  }

  const handleSave = async (goBack = true) => {
    setFireUpdate({ fire: true, goBack })
  }

  useEffect(() => {
    const update = async (goBack = true) => {
      setUpdating(true)
      await upsertBlockByPageID(websiteContext.pageID, section as any)
      setUpdating(false)
      setFireUpdate({ fire: false, goBack: false })
      if (goBack) onBack()
    }
    if (fireUpdate.fire) update(fireUpdate.goBack)
  }, [fireUpdate, section])

  useEffect(() => {
    onUpdate?.(section)
  }, [section])

  return (
    <div>
      <ModalDialogV2 open={open} onBack={onBack}>
        <div className="px-5 dark:text-white mt-[25px]">
          <div className="border-t-[1px] border-black dark:border-white">
            {section.variant === 'VARIANT_2' ||
            section.variant === 'VARIANT_3' ||
            section.variant === 'VARIANT_9' ||
            section.variant === 'VARIANT_10' ? (
              <div>
                <SectionTextField
                  title="Title"
                  initialValue={title?.data ?? []}
                  onChange={value => updateVariableData('TITLE', 'TEXT', value)}
                  onUpdate={() => handleSave(false)}
                />
              </div>
            ) : null}
            <div className="mt-12 dark:text-white space-y-4">
              <h2 className="uppercase text-[19px] font-semibold ">Gallery</h2>
              <div className="space-y-5">
                {gallery?.data.map((item: GallerySectionItem) => (
                  <Item
                    key={item.id}
                    data={item}
                    onDeleteClick={() => handleDelete(item.id)}
                    onEditClick={() => setEditGallery(item)}
                  />
                ))}
              </div>
              <button
                className="border border-black my-4 bg-black dark:border-white h-[65px] flex justify-between items-center text-lg px-6 w-full text-white"
                type="button"
                onClick={() => setShowGalleryModal(true)}
              >
                <span>Add Gallery</span> <span className="text-4xl">+</span>
              </button>
            </div>
          </div>
        </div>
        <div className="absolute top-4 left-[50%] translate-x-[-50%]">
          <Gallery width="auto" height={36} />
        </div>
        <div className="absolute top-[21px] right-[50px]">
          <button type="button" onClick={() => handleSave()} disabled={updating}>
            <MenuSaveButton save="save" />
          </button>
        </div>
        {updating && (
          <div className="fixed z-30 inset-0 lg:max-w-[500px] dark:bg-black/80 bg-white/80">
            <p className="flex items-center h-full justify-center w-full text-[19px] dark:text-white">Updating...</p>
          </div>
        )}

        <AdvancedFeature
          section={section}
          onBack={() => handleSave(false)}
          onChange={settings => setSection(s => ({ ...s, settings }))}
        />
      </ModalDialogV2>

      <ModalDialogV2
        open={editGallery !== undefined || showGallerModal}
        onBack={() => (editGallery ? setEditGallery(undefined) : setShowGalleryModal(false))}
      >
        <EditGallery data={editGallery} onSave={handleEditAdd} variantName={variantName} />
      </ModalDialogV2>
    </div>
  )
}
