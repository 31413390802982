import { motion } from 'framer-motion'
import FontLoader from '../../menu/FontLoader'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../../menu/utils'
import { FooterVariantProps, getSocialLabel, NewFooter, Social } from '../footer'

const SocialItem = ({ type, link, footer }: { type: Social; link: string; footer: NewFooter }) => {
  const label = getSocialLabel(type)

  return (
    <a href={link} target="_blank" rel="noreferrer" className="hover:line-through decoration-1">
      <p
        className="ml-2"
        style={{
          ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
          ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: '#bebebe' }),
        }}
      >
        {label}
      </p>
    </a>
  )
}

function VariantSix({ website, footer }: FooterVariantProps) {
  const handleTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  const handleEmail = () => {
    window.open(`mailto:${website?.config?.email ? website?.config?.email : footer?.email}?subject=&body=`)
  }
  return (
    <div
      style={{
        ...getBackgroundColor({ settings: footer?.settings?.backgroundColor, defaultValue: '#262525' }),
        ...getSpacing(footer?.settings?.spacing),
      }}
    >
      <FontLoader settings={footer?.settings?.fontFamily} />
      <div className={`opacity-70 pb-4 md:pb-0 px-4 md:px-12 xl:px-20`}>
        <div className={`border-t flex justify-between py-6`}>
          <nav
            style={{
              ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
              ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: '#bebebe' }),
              borderColor: getTextColor({ settings: footer?.settings?.textColor, defaultValue: '#bebebe' }).color,
            }}
            className="text-[16px] md:text-[28px] font-neuton flex space-x-2 sm:space-x-4 md:space-x-6"
          >
            {website?.config?.social
              ? Object.keys(website?.config?.social).map(item => (
                  <SocialItem footer={footer} type={item as Social} link={website?.config?.social[item]} />
                ))
              : footer?.social?.map(socialType => <SocialItem type={socialType} link="#" footer={footer} />)}
            <button
              style={{
                ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
                ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: '#bebebe' }),
              }}
              onClick={handleEmail}
              className="text-[16px] md:text-[28px] hover:line-through decoration-1"
            >
              Email
            </button>
          </nav>
          <div
            style={{
              ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
              ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: '#bebebe' }),
            }}
            className="xl:pr-24"
          >
            <motion.button
              onTap={handleTop}
              className="text-[16px] md:text-[28px] font-neuton hover:line-through decoration-1"
            >
              <span className="hidden md:block">Back to Top</span> <span className="md:hidden">Top</span>
            </motion.button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VariantSix
