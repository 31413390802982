import { Link } from 'react-router-dom'
import { UserProfile } from 'src/API'
// import AllUsers from 'src/icons/AllUsers'
import Chat from 'src/icons/Chat'
import Heart from 'src/icons/Heart'
import Send from 'src/icons/Send'
import Share from 'src/icons/Share'
import Users from 'src/icons/Users'
// import UsersLight from 'src/icons/UsersLight'
import img2 from '../../../../assets/images/UsersDark.png'
import img1 from '../../../../assets/images/Vector.png'
import ShareModal from '../../posts-page/components/ShareModal'

type ContactProps = {
  user: UserProfile | null | undefined
}

export default function Contact({ user }: ContactProps) {
  return (
    <div>
      <div className="hidden md:flex gap-y-3 flex-col items-center">
        <button type="button">
          <div className="h-[44px] w-[44px]">
            <img
              className="w-full h-full object-cover object-center rounded-full bg-gray-200"
              src={user?.profilePicture ?? ''}
              alt={user?.firstName}
            />
          </div>
        </button>
        <div className="flex flex-col gap-y-5 ">
          <button type="button" className="mt-[7px]">
            <Heart size={18} className="dark:stroke-white" />
          </button>
          <Link to={`/messages/${user?.id}`}>
            <Send size={18} className="dark:stroke-white" />
          </Link>
          <button type="button">
            <Users size={18} className="stroke-black dark:stroke-white" fill="white" />
          </button>
          <ShareModal contact={user} size={18} />
        </div>
      </div>

      <div className="md:hidden gap-x-3 flex items-start">
        <button type="button">
          <Heart size={16} className="dark:stroke-white" />
        </button>
        <button type="button">
          <Chat size={16} className="dark:stroke-white" />
        </button>
        <button type="button">
          <div className="h-[13px] w-[17px] block dark:hidden">
            <img src={img1} alt="" className="h-full w-full" />
          </div>
          <div className="h-[12px] w-[17px] hidden dark:block">
            <img src={img2} alt="" className="h-full w-full " />
          </div>
        </button>
        <button type="button">
          <Share className="stroke-black dark:stroke-white" size={15} />
        </button>
      </div>
    </div>
  )
}
