import { useNavigate } from 'react-router-dom'
import ChatBox from 'src/icons/ChatBox'
import DubleGrid from 'src/icons/DubleGrid'
import Filter from 'src/icons/Filter'
import FollowersBox from 'src/icons/FollowersBox'
import Grid from 'src/icons/Grid'
import SideBuilder from 'src/icons/SideBuilder'
import SingleGrid from 'src/icons/SingleGrid'
import TripleGrid from 'src/icons/TripleGrid'

function FilterModal({ setFilterGrid }: any) {
  console.log(setFilterGrid)
  const navigate = useNavigate()
  return (
    <div className="w-full md:w-[400px] px-4 md:px-10 py-10 bg-[#FFFFFF] dark:bg-[#000000] md:border-4 border-[#4E4E4E] dark:text-white rounded-lg text-center">
      <div>
        <div className="flex px-6 items-center space-x-4">
          <Grid size={38} className="text-[#E90570] dark:text-[#E90570]" />
          <h1 className="text-[30px] md:text-[25px]">Grid Style</h1>
        </div>
        <div className="grid justify-center grid-cols-2 md:grid-cols-3 py-6 gap-y-3">
          <div>
            <button
              onClick={() => {
                setFilterGrid('one')
              }}
              type="button"
              className="hover:shadow-md transition-all duration-500 rounded-xl"
            >
              <SingleGrid size={62} className="hidden md:block text-[#D9D9D9]" />
              <SingleGrid size={100} className="md:hidden text-[#D9D9D9] " />
            </button>
            <p className="text-[14px] text-center">Single</p>
          </div>
          <div>
            <button type="button" className="hover:shadow-md transition-all duration-500 rounded-xl">
              <DubleGrid onClick={() => setFilterGrid('two')} size={62} className="hidden md:block text-[#D9D9D9]" />
              <DubleGrid onClick={() => setFilterGrid('two')} size={100} className="md:hidden text-[#D9D9D9]" />
            </button>
            <p className="text-[14px] text-center">Double</p>
          </div>
          <div>
            <button
              onClick={() => setFilterGrid('three')}
              type="button"
              className="hover:shadow-md transition-all duration-500 rounded-xl"
            >
              <TripleGrid size={62} className="hidden md:block text-[#D9D9D9]" />
              <TripleGrid size={100} className="md:hidden text-[#D9D9D9]" />
            </button>
            <p className="text-[14px] text-center">Triple</p>
          </div>
        </div>
      </div>
      <div>
        <div className="flex items-center space-x-4 pl-5">
          <Filter size={32} className="text-[#E90570] dark:text-[#E90570]" />
          <h1 className="text-[30px] md:text-[25px]">Feed Content</h1>
        </div>
        <div className="grid justify-center grid-cols-2 md:grid-cols-3 py-6 gap-y-3">
          <div>
            <button
              type="button"
              onClick={() => navigate('/builder?newSite=true')}
              className="hover:shadow-md transition-all duration-500 rounded-xl"
            >
              <SideBuilder stroke="black" size={62} className="hidden md:block text-[#D9D9D9]" />
              <SideBuilder stroke="black" size={100} className="md:hidden text-[#D9D9D9]" />
            </button>
            <p className="text-[14px] text-center">Site Builder</p>
          </div>
          <div>
            <button type="button" className="hover:shadow-md transition-all duration-500 rounded-xl">
              <ChatBox size={64} className="hidden md:block text-[#D9D9D9]" />
              <ChatBox size={100} className="md:hidden text-[#D9D9D9]" />
            </button>
            <p className="text-[14px] text-center">Post</p>
          </div>
          <div className="flex flex-col items-center gap-y-2">
            <button
              type="button"
              className="hover:shadow-md transition-all duration-500 rounded flex items-center justify-center"
            >
              <FollowersBox size={62} className="hidden md:block " />
              <FollowersBox size={100} className="md:hidden " />
            </button>
            <p className="text-[14px] text-center">Followers</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FilterModal
