import { ImageOmgSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import VariantNine from './VariantNine'

export default function VariantTwelve({ section }: { section: ImageOmgSection }) {
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: 'black',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      {media && (
        <div>
          <div className="container mx-auto">
            <div className="h-[300px] md:h-[440px] w-full mx-auto">
              <img className="h-full w-full object-cover object-center" src={media} alt="" />
            </div>
          </div>
        </div>
      )}
      <VariantNine {...{ section }} hideImageBg />
    </div>
  )
}
