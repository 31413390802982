import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { TeamMembersSection, TeamMembersSectionMember } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import Member from './TeamMember'

export default function VariantTwo({ section }: { section: TeamMembersSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const members = section.variables.find(variable => variable.name === 'TEAM_MEMBERS')
    ?.data as TeamMembersSectionMember[]

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: '#fff',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
      className="md:py-[50px]"
    >
      <div className="md:container md:mx-auto  px-4 md:px-0 py-4 md:py-12">
        <div className="w-[750px] max-w-full mx-auto space-y-1">
          <div className="md:text-[40px] text-2xl">
            <p className="text-center">
              <SectionSlatePreview initialValue={title} previewMode />
            </p>
          </div>
          {subTitle && (
            <div>
              <p className="md:text-[18px] text-center">
                <SectionSlatePreview initialValue={subTitle} previewMode />
              </p>
            </div>
          )}
          {paragraph && (
            <div>
              <p className="text-center text-[14px] md:text-[16px]">
                <SectionSlatePreview initialValue={paragraph} previewMode />
              </p>
            </div>
          )}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-center md:justify-between lg:justify-between gap-4 mt-6 md:gap-y-14 md:mt-14">
          {members.map(member => (
            <Member key={member.id} member={member} />
          ))}
        </div>
      </div>
    </div>
  )
}
