import { CreatePostInput, CreatePostMutation, ListByPostStatusQuery, ModelSortDirection, PostStatus } from 'src/API'
import { createPost } from 'src/graphql/mutations'
import { listByPostStatus } from 'src/graphql/queries'
import { graphqlQuery } from 'src/lib/queries'

export const postOnSocial = async (post: CreatePostInput) => {
  try {
    const { data } = await graphqlQuery<CreatePostMutation>({
      query: createPost,
      variables: {
        input: {
          ...post,
          status: PostStatus.ACTIVE,
        },
      },
    })

    return data?.createPost
  } catch (err) {
    console.error('@feed.service::postOnSocial(post)::error', err)
    throw err
  }
}

export const getFeedPosts = async (nextToken?: string | null) => {
  try {
    const { data } = await graphqlQuery<ListByPostStatusQuery>({
      query: listByPostStatus,
      variables: {
        sortDirection: ModelSortDirection.DESC,
        limit: 15,
        nextToken,
        status: PostStatus.ACTIVE,
      },
    })

    return {
      nextToken: data?.listByPostStatus?.nextToken,
      items: data?.listByPostStatus?.items,
    }
  } catch (error) {
    console.error('@feed.service::getFeedPosts::error', error)
    throw error
  }
}
