import { motion } from 'framer-motion'
import { GoPrimitiveDot } from 'react-icons/go'
import SectionSlate from '../../../common/SectionSlatePreview'
import { AboutUsSection, AboutUsSectionServices } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import SectionSlatePreview from '../../../common/SectionSlatePreview'

export default function VariantSix({ section }: { section: AboutUsSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subtitle = section.variables.find(varaible => varaible.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data
  const services = section.variables.find(variable => variable.name === 'SERVICE')?.data as AboutUsSectionServices[]
  const sectionTitle = section.variables.find(variable => variable.name === 'SECTION_1')?.data

  return (
    <div
      className="py-12"
      style={{
        ...generateSectionBackground({
          defaultColor: '#eee',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="grid md:grid-cols-2 md:gap-x-12 md:px-12 px-4 overflow-x-hidden ">
        <motion.div
          initial={{ opacity: 0, scale: 0.6 }}
          animate={{
            opacity: 1,
            scale: 1,
            transition: {
              duration: 1,
            },
          }}
        >
          <img src={media} className="md:w-[800px] h-[300px] md:h-full w-full object-cover" />
        </motion.div>

        <div>
          <motion.div
            initial={{ x: 600, opacity: 0 }}
            animate={{ x: 0, opacity: 1, transition: { duration: 1.3 } }}
            className="md:space-y-3 flex flex-col items-start justify-center md:py-12 py-4"
          >
            <div>
              <p className="tracking-wider text-[22px] md:text-4xl font-bold ">
                <SectionSlate initialValue={title} previewMode />
              </p>
            </div>
            <div>
              <p className="tracking-wider">
                <SectionSlate initialValue={subtitle} previewMode />
              </p>
            </div>
            <div>
              <p className="text-sm lg:text-xl">
                <SectionSlate initialValue={paragraph} previewMode />
              </p>
            </div>
            <div>
              <p className="lg:text-xl text-md  uppercase tracking-wider pt-3">
                <SectionSlate initialValue={sectionTitle} previewMode />
              </p>
            </div>
            <div className="flex flex-wrap gap-3 mt-2">
              {services?.map((service, idx) => (
                <p key={idx} className="flex items-center gap-x-2">
                  <GoPrimitiveDot className="text-lg" />
                  <span>
                    <SectionSlatePreview initialValue={service?.name} previewMode />
                  </span>
                </p>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  )
}
