import Icon from './icons'
import { IconPropsT } from './types'

function SingleGrid(props: IconPropsT) {
  const { title = 'SingleGrid', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 120 120" size={size} title={title} {...otherProps}>
      <mask
        id="mask0_1280_12987"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="120"
        height="120"
      >
        <path
          d="M9 0.5H111C115.694 0.5 119.5 4.30558 119.5 9V111C119.5 115.694 115.694 119.5 111 119.5H9C4.30558 119.5 0.5 115.694 0.5 111V9C0.5 4.30558 4.30558 0.5 9 0.5Z"
          fill="#D9D9D9"
          stroke="black"
        />
      </mask>
      <g mask="url(#mask0_1280_12987)">
        <path
          d="M9 0.5H111C115.694 0.5 119.5 4.30558 119.5 9V111C119.5 115.694 115.694 119.5 111 119.5H9C4.30558 119.5 0.5 115.694 0.5 111V9C0.5 4.30558 4.30558 0.5 9 0.5Z"
          fill="#D9D9D9"
          stroke="black"
        />
        <rect x="25.5" y="8.5" width="70" height="54" rx="5.5" fill="black" stroke="black" />
        <rect x="27.5" y="10.5" width="67" height="51" rx="5.5" fill="white" stroke="black" />
        <rect x="25.5" y="70.5" width="70" height="54" rx="5.5" fill="black" stroke="black" />
        <rect x="27.5" y="72.5" width="67" height="51" rx="5.5" fill="white" stroke="black" />
      </g>
    </Icon>
  )
}

export default SingleGrid
