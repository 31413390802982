import moment from 'moment'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Topics } from 'src/API'
import { NotificationsContext } from 'src/components/context/NotificationContext'
import Chat from 'src/icons/Chat'
import Heart from 'src/icons/Heart'
import Notification from 'src/icons/Notification'
import NotificationTWo from 'src/icons/NotificationTwo'
import { markNotificationAsRead } from 'src/services/notification.service'

function NotificationModal() {
  const { notify } = useContext(NotificationsContext)

  return (
    <div className="md:w-[450px] md:max-h-[600px] md:overflow-auto px-6 py-3 md:py-6 bg-[#FFFFFF] dark:bg-[#000000] md:border-4 border-[#4E4E4E] dark:text-white rounded-lg">
      <div className="border-b border-[#DCE9D9]">
        <div className="flex items-center justify-between">
          <div className="flex md:justify-start justify-between w-full items-center space-x-4 py-3 md:py-0">
            <button type="button" className="order-last md:order-1">
              <Notification size={30} className="hidden md:block text-[#E90570] dark:text-[#E90570]" />
              <NotificationTWo
                size={30}
                className="block md:hidden mr-4 stroke-black dark:stroke-white"
                strokeWidth="1"
              />
            </button>
            <h1 className="text-[25px] font-semibold md:font-normal md:text-[20px] order-1 md:order-last ">
              Notifications
            </h1>
          </div>
        </div>
        <div className="py-3 justify-end hidden md:flex">
          <button type="button" className="text-[#767676] dark:text-[#D9D9D9] font-bold text-[14px]">
            Clear All
          </button>
        </div>
      </div>
      {notify.map(item => (
        <Link
          to={item.topicUrl ?? ''}
          onClick={() => markNotificationAsRead(item)}
          key={item.id}
          className="flex justify-between  cursor-pointer py-3 border-b border-[#DCE9D9]"
        >
          <div className="w-[52px] h-[52px] rounded-full bg-[#D9D9D9]">
            {item?.creator?.profilePicture && (
              <img className="w-full h-full rounded-full" src={item?.creator?.profilePicture} alt="" />
            )}
          </div>
          <div className="max-w-[250px] w-full">
            <h2 className="text-[16px] font-bold">
              {item.creator.firstName} {item.creator.lastName}
            </h2>
            <p className="text-[12px] font-bold">{item.topicDescription}</p>
            <p className="text-[12px] text-[#707070] text-right">{moment(item.createdAt).fromNow()}</p>
          </div>
          <div className="pt-2">
            {item.topic === Topics.NEW_POST_LIKE && <Heart size={20} className="text-[#E90570]" />}
            {item.topic === Topics.NEW_POST_COMMENT && <Chat size={24} />}
          </div>
        </Link>
      ))}
    </div>
  )
}

export default NotificationModal
