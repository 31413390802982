import { useState } from 'react'
import Banner from '../common/aboutAndSignin/Banner'
import Footer from '../common/aboutAndSignin/Footer'
import Header from '../common/aboutAndSignin/Header'
import FindAccount from './FindAccount'
import Invitation from './Invitation'
import Reset from './Reset'

interface ISignInProps {
  setShowPage: any
}

function Login({ setShowPage }: ISignInProps) {
  const handlePage = (value: string) => {
    setShowPage(value)
  }
  return (
    <form className="space-y-8">
      <div className="w-[265px]">
        <input
          className="w-full px-5 py-2.5 bg-[#FFFFFF] text-[15px] rounded focus:outline-none outline-0"
          type="email"
          placeholder="Username or Email"
        />
      </div>
      <div className="w-[265px]">
        <input
          className="w-full px-5 py-2.5 bg-[#FFFFFF] text-[15px] rounded focus:outline-none outline-0"
          type="password"
          placeholder="Password"
        />
        <p className="text-[10px] text-[#FFFFFF] opacity-70 pt-2">
          Passwords should atleast 1 character and 1 capital letter{' '}
        </p>
      </div>
      <div className="w-[265px]">
        <button className="w-full py-2 bg-[#E90570] text-white text-[20px] rounded" type="button">
          Login
        </button>
        <p className="text-[10px] text-[#FFFFFF] opacity-70 pt-2">
          Forgot your password?
          <button onClick={() => handlePage('reset')} className="pl-2" type="submit">
            Click Here
          </button>
        </p>
      </div>
      <div className="w-[265px]">
        <button
          onClick={() => handlePage('invite')}
          className="w-full py-2 bg-[#D9D9D9] text-[#000000] text-[20px] border-3 border-white rounded"
          type="button"
        >
          Request Invite
        </button>
      </div>
    </form>
  )
}

function SignIn() {
  const [showPage, setShowPage] = useState<string>('login')
  // decide what to render
  let render = null
  if (showPage === 'login') {
    render = <Login setShowPage={setShowPage} />
  } else if (showPage === 'invite') {
    render = <Invitation setShowPage={setShowPage} />
  } else if (showPage === 'reset') {
    render = <Reset setShowPage={setShowPage} />
  } else if (showPage === 'find') {
    render = <FindAccount setShowPage={setShowPage} />
  }

  return (
    <>
      <Header />
      <Banner />
      <div className="bg-[#000000]">
        <section className="flex justify-center  md:pt-24 pt-12 md:pb-12 pb-12">{render}</section>
        <section className="md:hidden bg-white h-[50px]" />
        <Footer />
      </div>
    </>
  )
}

export default SignIn
