import { useSearchParams } from 'react-router-dom'
import MenuRenderer from 'src/_polly/components/src/menu/Renderer'

export default function MenuPreview() {
  const [searchParams] = useSearchParams()
  const menu = searchParams.get('menu')
  const website = searchParams.get('website')

  if (!menu || !website) return <p>none</p>

  return <MenuRenderer menu={JSON.parse(window.atob(menu))} website={JSON.parse(window.atob(website))} />
}
