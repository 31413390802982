import { Auth } from 'aws-amplify'
import { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { UserContext } from 'src/components/context/UserContext'
import { Loader } from 'src/components/loader'
import Account from 'src/icons/Account'
import HelpBox from 'src/icons/HelpBox'
import LogOutBox from 'src/icons/LogoutBox'
import ProfileBox from 'src/icons/ProfileBox'
import SendBox from 'src/icons/SendBox'
import SettingsBox from 'src/icons/SettingsBox'
import { ReactComponent as YourImage } from 'src/assets/icons/uploader/YourImage.svg'
import { TECHBOOM_SUPPORT_ID } from 'src/util/constants'

function AccountItem({ onManageModal }: any) {
  const navigate = useNavigate()
  const { user } = useContext(UserContext)

  const [loading, setLoading] = useState(false)

  const signOut = async () => {
    try {
      setLoading(true)
      await Auth.signOut()
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <Loader show={loading} />
      <div className="flex items-center md:justify-start md:space-x-2 space-x-[23px] mt-2">
        <button type="button">
          <Account size={20} className="text-[#ffff] dark:text-[#000] hidden md:block" />
          <Account size={35} className="text-[#ffff] dark:text-[#000] md:hidden" />
        </button>
        <h1 className="text-[40px] md:text-[20px]">Account</h1>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 -ml-5 md:-ml-0  md:w-[90%] md:gap-y-2 gap-y-4 justify-center pt-3 pb-4 items-center ">
        <div>
          <Link to={`/profile/${user.id}`} className=" rounded-xl  justify-center flex items-center">
            <ProfileBox
              stroke="black"
              size={42}
              className="text-[#D9D9D9] hidden md:block hover:shadow-md transition-all duration-500"
            />
            <ProfileBox size={115} className=" text-[#D9D9D9] md:hidden" />
          </Link>
          <p className="text-[14px] md:text-[10px] text-center mt-1.5">Profile</p>
        </div>

        <div className=" hidden md:block">
          <button
            type="button"
            onClick={() => navigate('/messages')}
            className="hover:shadow-md transition-all duration-500"
          >
            <SendBox stroke="black" size={42} className="text-[#D9D9D9]" />
          </button>
          <p className="text-[14px] md:text-[10px] text-center">Message</p>
        </div>

        <div>
          <button type="button" className="hover:shadow-md transition-all duration-500 rounded-xl">
            <YourImage width={42} height={42} stroke="black" fill="black" className="hidden md:block" />
            <YourImage width={115} height={115} fill="#eee" className="md:hidden" />
          </button>
          <p className="text-[14px] md:text-[10px] text-center">Wallet</p>
        </div>
        <div>
          <div>
            <button
              onClick={onManageModal}
              type="button"
              className="hover:shadow-md transition-all duration-500 rounded-xl"
            >
              <SettingsBox stroke="gray" size={42} className="text-[#D9D9D9] dark:text-[#D9D9D9] hidden md:block" />
              <SettingsBox size={115} className="text-[#D9D9D9] dark:text-[#D9D9D9] md:hidden" />
            </button>
            <p className="text-[14px] md:text-[10px] text-center">Settings</p>
          </div>
          {/* <SettingsModal /> */}
        </div>

        <div>
          <button
            onClick={() => navigate(`/messages/${TECHBOOM_SUPPORT_ID}`)}
            type="button"
            className="hover:shadow-md transition-all duration-500 rounded-xl"
          >
            <HelpBox stroke="black" size={42} className="text-[#D9D9D9] hidden md:block" />
            <HelpBox size={115} className="text-[#D9D9D9] md:hidden" />
          </button>
          <p className="text-[14px] md:text-[10px] text-center">Help</p>
        </div>
        <div>
          <button type="button" onClick={signOut} className="hover:shadow-md transition-all duration-500 rounded-xl">
            <LogOutBox stroke="black" size={42} className="text-[#D9D9D9] hidden md:block" />
            <LogOutBox size={115} className="text-[#D9D9D9] md:hidden" />
          </button>
          <p className="text-[14px] md:text-[10px] text-center">Logout</p>
        </div>
      </div>
    </div>
  )
}

export default AccountItem
