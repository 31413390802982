import { Template } from '.'
import { contactUs, gallery, image, love, text, quote, team, aboutUs } from './variants'

export const Template07: Template = {
  id: '07',
  pages: [
    {
      slug: '/',
      name: 'Home',
      blocks: [
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_12')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  children: [{ fontFamily: 'Playfair Display', color: '#eee', text: '  pet care' }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: 'd2877c5b-049e-4639-826c-d4166dbe7147',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Playfair Display', color: '#ff4d82', text: 'pet care', bold: true }],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '0e6e6d5a-2290-4d8c-807e-02c2556ea5c9',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: '' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: '69aa886d-861d-4143-8acb-df5a56a01bfe',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_12',
          type: 'TEXT',
        },
        {
          settings: {
            ...quote.find(i => i.variant === 'VARIANT_4')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#f3f3f3', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'DESCRIPTION',
              id: '655a1e5b-9fd5-48ff-9724-cca6a271fdba',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1548767797-d8c844163c4c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxwZXRzfGVufDB8fHx8MTY3ODA3MjY5OA&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '88b774e4-89ef-4310-ae72-7f0ddc2e060d',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    { fontFamily: 'Playfair Display', color: '#ff4d82', text: 'We Care In Your Pet ', bold: true },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '76a8e65b-6011-498f-802c-2edb808f629e',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Dope Investor Design is a full-service investment firm with a focus on innovative design. We invest in companies that prioritize exceptional user experience and visual appeal. Our team of experienced designers and investors work hand-in-hand to support the growth and success of our portfolio companies.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '006125c2-d277-4770-b96f-b5991c298263',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_4',
          type: 'QUOTE_SECTION',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1625321150203-cea4bee44b54?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxjYXJlJTIwcGV0c3xlbnwwfHx8fDE2NzgwNzMyOTY&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ text: 'Gucci Acquisition Success' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '1',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1455557412176-951a705225c5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHx0cmVhdG1lbnQlMjBhbmltYWxzfGVufDB8fHx8MTY3ODA4NDE3OQ&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ text: 'Gucci Acquisition Success' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '2',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1625833017043-21a7642b9152?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw2fHxWYWNjaW5hdGlvbnN8ZW58MHx8fHwxNjc4MDg0MjM1&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ text: 'Gucci Acquisition Success' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: '902a579b-be3c-4d88-bec0-c9ca7a0672f1',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Playfair Display', color: '#ff4d82', text: 'Our Services', bold: true }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: 'd3d755c6-f0b6-49ab-a5ab-8e6fed520e12',
              category: 'TEXT',
            },
          ],

          variant: 'VARIANT_6',
          type: 'TEXT',
        },
        {
          settings: {
            ...image.find(i => i.variant === 'VARIANT_15')?.settings,

            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ text: 'CARE PETS' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '3cdad36c-da89-42ea-bbb6-accf96ced42b',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'GUCCI SUCCESS' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'f47b0a20-eeff-42ab-999c-0fd24bc308eb',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'You don’t have to wear an ironic t-shirt and grow a patchy beard to make barista-level coffee. Just subscribe to get our free emails - filled with tips on finding and brewing crazy delicious coffee.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '0d090fc6-44e8-4fb7-aae3-02ffd1b16ad5',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1516750105099-4b8a83e217ee?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw4fHxwaW5rJTIwcGV0c3xlbnwwfHx8fDE2NzgwODQ3MjQ&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '1fa955e8-c05b-40e2-8609-dbffcd48387f',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [{ color: '#bf005d', text: 'we ensure your site is cash flow good' }],
                  type: 'paragraph',
                },
              ],
              name: 'CIRCLE_TEXT',
              id: 'f2ff95f4-5131-4e8c-b60a-c74681ce9cb1',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_15',
          type: 'IMAGE_OMG',
        },
        {
          settings: {
            ...quote.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'DESCRIPTION',
              id: '9daf3445-dfec-4266-9c13-b3803f3694a7',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
              name: 'MEDIA',
              id: 'f1f65b3e-3556-44ca-84c9-6f6a6c507f80',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [{ highlightColor: '#ffffff', color: '#ff4d82', text: 'Burn Rate Buster UX Design' }],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '3fb517fd-7c7a-494b-884d-8132c2755f75',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Snatched Crowdfunding UX' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '79605e57-a6ed-452d-9d57-c55dc9204ad3',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'QUOTE_SECTION',
        },
        {
          settings: {
            ...gallery.find(i => i.variant === 'VARIANT_4')?.settings,
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1450778869180-41d0601e046e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxwZXRzfGVufDB8fHx8MTY3ODA3MjY5OA&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1517275916764-9b5685e1561f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxwaW5rJTIwcGV0c3xlbnwwfHx8fDE2NzgwODQ3MjQ&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1559059699-085698eba48c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw4fHxwaW5rJTIwY2F0c3xlbnwwfHx8fDE2NzgwODUwNTU&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1583337130417-3346a1be7dee?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw1fHxhbmltYWxzfGVufDB8fHx8MTY3ODA3MTk5Ng&ixlib=rb-4.0.3&q=80&w=1080',

                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '4',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1570824104453-508955ab713e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxjYXRzfGVufDB8fHx8MTY3ODA3MTk3Ng&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '5',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1589883661923-6476cb0ae9f2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw2fHxjYXRzfGVufDB8fHx8MTY3ODA3MTk3Ng&ixlib=rb-4.0.3&q=80&w=1080',
                  description: [
                    {
                      children: [
                        {
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '6',
                  title: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                },
              ],
              name: 'MEDIA',
              id: '123',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_4',
          type: 'GALLERY',
        },
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_11')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#eaeaea', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  name: 'First Name',
                  id: '2b0dda3e-0eb2-4616-9752-4829467bddfb',
                  placeholder: 'First Name',
                  type: 'text',
                  error: 'First Name is required',
                },
                {
                  name: 'Last Name',
                  id: '975e420a-70fe-4047-8f44-d8066860c94a',
                  placeholder: 'Last Name',
                  type: 'text',
                  error: 'Last Name is required',
                },
                {
                  name: 'Email',
                  id: '297fa155-3b3f-482c-adcf-5c784162f885',
                  placeholder: 'Email',
                  type: 'email',
                  error: 'email is required',
                },
                {
                  name: 'Number',
                  id: 'fc958af3-df17-44c5-8a7a-e955d0ba23ce',
                  placeholder: 'Number',
                  type: 'number',
                  error: 'number is required',
                },
              ],
              name: 'INPUTS',
              id: '4ac49391-49ef-4f44-a373-e51e69ad5abc',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#000000',
                      text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '36fe5078-ab25-4a47-93c3-de0092e44f0f',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Playfair Display', color: '#ff4d82', text: 'BOOK A CALL', bold: true }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '57ffe25b-8202-428f-8970-8dca7acde277',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1496284427489-f59461d8a8e6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw3fHxjYXQlMjB3aXRoJTIwbWFufGVufDB8fHx8MTY3ODI1NTMzOA&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '4c25af43-ec3d-4417-9f39-ba6f1253dd78',
              category: 'IMAGE',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#cecece',
                  backgroundColor: '#ff4d82',
                  borderRadius: '25px',
                  color: '#ffffff',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  bold: true,
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'send',
              },
              name: 'BUTTON_1',
              id: '31b119e3-df68-4b21-87e7-0654977fc0c2',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_11',
          type: 'CONTACT_US',
        },
        {
          settings: {
            ...team.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1591954692515-d1d30376fa64?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxMHx8YW5pbWFsJTIwZG9jdG9yfGVufDB8fHx8MTY3ODA4NjQ2NA&ixlib=rb-4.0.3&q=80&w=1080',
                  subtitle1: [{ children: [{ text: 'Position/Role' }], type: 'paragraph' }],
                  subtitle: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  name: [
                    { children: [{ color: '#ff4d82', text: 'Bianca Witherspoon', bold: true }], type: 'paragraph' },
                  ],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '1',
                  name1: [{ children: [{ text: 'Taylor Quill' }], type: 'paragraph' }],
                  description1: [{ children: [{ text: '#developer #expert #designer' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1547955326-921fec152788?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw2fHxhbmltYWwlMjBkb2N0b3J8ZW58MHx8fHwxNjc4MDg2NDY0&ixlib=rb-4.0.3&q=80&w=1080',
                  subtitle1: [{ children: [{ text: 'Position/Role' }], type: 'paragraph' }],
                  subtitle: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  name: [
                    { children: [{ color: '#ff317e', text: 'Bianca Witherspoon', bold: true }], type: 'paragraph' },
                  ],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '2',
                  name1: [{ children: [{ text: 'Taylor Quill' }], type: 'paragraph' }],
                  description1: [{ children: [{ text: '#developer #expert #designer' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1644675443401-ea4c14bad0e6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxMHx8YW5pbWFscyUyMGRvY3RvcnN8ZW58MHx8fHwxNjc4MDg2NTQz&ixlib=rb-4.0.3&q=80&w=1080',
                  subtitle1: [{ children: [{ text: 'Position/Role' }], type: 'paragraph' }],
                  subtitle: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  name: [
                    { children: [{ color: '#ff4d82', text: 'Bianca Witherspoon', bold: true }], type: 'paragraph' },
                  ],
                  description: [{ children: [{ color: '#000000', text: '' }], type: 'paragraph' }],
                  id: '3',
                  name1: [{ children: [{ text: 'Taylor Quill' }], type: 'paragraph' }],
                  description1: [{ children: [{ text: '#developer #expert #designer' }], type: 'paragraph' }],
                },
              ],
              name: 'TEAM_MEMBERS',
              id: '5fce1111-c88f-46a1-9441-b5966685668d',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#000000', text: 'Meet Our Team', bold: true }], type: 'paragraph' }],
              name: 'TITLE',
              id: '98e6e7b9-586c-490c-a12f-ab817ba13581',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ color: '#ff4d82', text: 'Thrilling Design for Investors', bold: true }],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '56a5743c-99c8-4621-b0b5-de362d0ca3e7',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#000000',
                      text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '88968d4e-f628-4889-91ac-d03c30afd402',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'TEAM_MEMBERS',
        },
        {
          settings: {
            ...quote.find(i => i.variant === 'VARIANT_1')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#f0f0f0', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ color: '#ff4d82', text: 'Caleb McConaughey' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '01abdc65-8916-4525-aa95-8272cf4fd25c',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'At our agency, we prioritize user satisfaction, user retention, and user conversion. We offer website performance, website speed, website accessibility, website security, website maintenance, ',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '3886a6f7-22e8-41ac-9d2c-ff08df130b3b',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1545529468-42764ef8c85f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxkb2N0b3IlMjB3aXRoJTIwY2F0fGVufDB8fHx8MTY3ODA4NzEwNg&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '866ce604-0c83-459b-8eed-a86c14b68d94',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'DESCRIPTION',
              id: '38ce7f13-f2cb-4177-a27f-03db002e07a3',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          type: 'QUOTE_SECTION',
        },
      ],
    },
    {
      slug: '/about-us',
      name: 'About Us',
      blocks: [
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_7')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 30, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#f2f2f2', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  children: [{ color: '#ff4d82', text: 'Cottage Industry, Captivating Design', bold: true }],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'a434dba2-b500-404e-9b0c-bc60853f1797',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Playfair Display', color: '#343434', text: 'About Us' }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '7af8ae74-f31c-4ef7-8dce-6cc82d4ac325',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#000000',
                      text: "Our experience showcasing unicorn investors and influencers means that we know exactly what it takes to optimize your website for maximum ROI. Whether you're a cottage industry or a disruptive technology startup, our accelerator services will help you achieve your business goals faster.",
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '9eaf1f1e-5c60-4ecd-a900-a7e6997e7b36',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_7',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...quote.find(i => i.variant === 'VARIANT_6')?.settings,
          },
          variables: [
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'DESCRIPTION',
              id: 'ccd53c43-1b00-44de-8138-be5195f9c83e',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
              name: 'MEDIA',
              id: '396ef7a9-1f88-4dfc-bc23-e7489379316f',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'Gucci Acquisition Success' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '961afdde-8639-4923-b435-35ab4a8ad1f1',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ color: '#ff4d82', text: 'Burn Rate-Busting UX Design' }], type: 'paragraph' },
                { children: [{ color: '#fd3166', text: '' }], type: 'paragraph' },
              ],
              name: 'SUBTITLE',
              id: 'b5ae00c5-85cd-4aa6-956e-99f0f76f8284',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'QUOTE_SECTION',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_4')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: 'eddc6e7d-5f6d-4dda-9fca-82ba90287fa9',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'MARGIN',
              id: '031e6613-fd9a-4e84-b1dc-c9c7f859c06d',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'PADDING',
              id: '63a513f6-824e-4895-9f75-9c747231b69b',
              category: 'TEXT',
            },
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: '79d03968-9942-475b-86f6-f59125f19b22',
              category: 'IMAGE',
            },
            {
              data: 'https://images.unsplash.com/photo-1611173622933-91942d394b04?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw1fHxwaW5rJTIwYW5kJTIwZG9nc3xlbnwwfHx8fDE2NzgwODkwNjU&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '9bb5e480-a4cd-4c92-8868-dade84ded034',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'Sleep On Disruptive Design' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '7661bf02-e85e-4597-ade9-51de6daa139b',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#ffffff',
                  backgroundColor: '#ff4d82',
                  borderRadius: '25px',
                  color: '#ffffff',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'more',
              },
              name: 'BUTTON_1',
              id: 'e7bf9ff6-3d03-4178-906e-78f6558d14aa',
              category: 'BUTTON',
            },
            {
              data: [{ children: [{ color: '#ff409a', text: 'Burn Rate-Busting UX Design' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '01490680-0b7e-4ff8-b52a-c4b631817095',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_4',
          type: 'TEXT',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_4')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 30, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: 'f9d10f45-f220-4730-b6ec-eb93014d10d1',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'MARGIN',
              id: '60d67445-9059-4ec0-bd8e-a1b85a1420b5',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'PADDING',
              id: '41c944b3-9bc5-4d52-b225-5a3a315983f0',
              category: 'TEXT',
            },
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: 'd42b6a18-a1c8-446d-af5f-2877c3831cb6',
              category: 'IMAGE',
            },
            {
              data: 'https://images.unsplash.com/photo-1516750105099-4b8a83e217ee?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxwaW5rJTIwY2F0c3xlbnwwfHx8fDE2NzgwODUwNTU&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '55028ecd-ba7e-49c8-bdf3-1900393229d5',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'Captivating UX for Investors' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '73d6fc77-a0d3-4bd9-a067-7369bdf42ad8',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#ffffff',
                  backgroundColor: '#ff4d82',
                  borderRadius: '25px',
                  color: '#ffffff',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'more',
              },
              name: 'BUTTON_1',
              id: '110e8fb5-67d9-4953-9217-7e793a0bf97e',
              category: 'BUTTON',
            },
            {
              data: [{ children: [{ color: '#ff3c9b', text: 'Sleep On Disruptive Design' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '3bb2ec0b-0850-4f40-8c61-0765743a97f0',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_4',
          type: 'TEXT',
        },
      ],
    },
    {
      slug: '/services',
      name: 'Services',
      blocks: [
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_7')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ebebeb', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ color: '#000000', text: 'What We Can Do ?' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'b7281092-23cb-4208-96fd-7dcaef46e954',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ color: '#ff4d82', text: 'Cottage Industry, Captivating Design', bold: true }],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '2392f485-0335-4d8b-831e-bf0f2474ea48',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#000000',
                      text: 'With our crowdfunding expertise, we can help you raise the funds you need to take your business to the next level. So why wait? Contact us today to learn more about how we can help you create a captivating,high-converting website.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'fe81e20d-224c-4707-b4df-9caddbbff956',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_7',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1625321150203-cea4bee44b54?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxjYXJlJTIwZG9nc3xlbnwwfHx8fDE2NzgwOTAwNTE&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [
                    {
                      children: [{ color: '#ff4d82', text: 'Captivating UX for Startups', bold: true }],
                      type: 'paragraph',
                    },
                  ],
                  description: [
                    {
                      children: [
                        {
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1570824104453-508955ab713e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxjYXRzfGVufDB8fHx8MTY3ODA3MTk3Ng&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [
                    { children: [{ color: '#ff4d82', text: "Cheugy Site's Appeal", bold: true }], type: 'paragraph' },
                  ],
                  description: [
                    {
                      children: [
                        {
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1478695605156-f64b0ee877f4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwyfHxiaXJkcyUyMGNhcmV8ZW58MHx8fHwxNjc4MDkwMTYw&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [
                    { children: [{ color: '#ff4d82', text: "Cheugy Site's Appeal", bold: true }], type: 'paragraph' },
                  ],
                  description: [
                    {
                      children: [
                        {
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: '5a7b8ed2-6bbb-4c6f-93b7-a185bcf20848',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'Snatched Crowdfunding UX', bold: true }], type: 'paragraph' }],
              name: 'TITLE',
              id: '49542dc4-8064-4234-990d-67f53bc66264',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'TEXT',
        },
      ],
    },
    {
      slug: '/blog',
      name: 'Blog',
      blocks: [
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_14')?.settings,
            color: { background: { value: '#cfcfcf', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: 'eb3cc8e0-1430-4eb1-8549-484f62b9298d',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1483728642387-6c3bdd6c93e5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxtb3VudGFpbnxlbnwwfHx8fDE2NzIzMjQ3NDc&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: 'a444afae-67f9-4e9e-9321-d98add4e97cb',
              category: 'IMAGE',
            },
            { data: 0, name: 'TRANSITION', id: 'becb8ae5-4cd2-44cd-9912-5d692f95a52e', category: 'IMAGE' },
            {
              data: [
                {
                  children: [{ fontFamily: 'Playfair Display', color: '#090506', text: 'Care A Pet with Us' }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '29bd7944-6624-4d7d-9a68-240a94cda9eb',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ color: '#a5a5a5', text: 'Disruptive Design for Acquisition', bold: true }],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '6f4ed410-4716-4280-9386-c56fae431dd6',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_14',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...quote.find(i => i.variant === 'VARIANT_6')?.settings,
          },
          variables: [
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'DESCRIPTION',
              id: '97e2e9c1-2a23-477a-a854-0f3aaa9a2802',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
              name: 'MEDIA',
              id: '1a0102de-a61f-4ce2-872f-79628220ae1d',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: "Cheugy Site's Appeal" }], type: 'paragraph' }],
              name: 'TITLE',
              id: '15b423a5-b57b-46cc-83b2-eeb381efef8d',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'GOAT Acquisition UX' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '6d2762b1-5f9c-433f-af73-27520a304cbb',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'QUOTE_SECTION',
        },
        {
          settings: {
            ...aboutUs.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 30, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#f3f3f3', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'GOAT Acquisition UX' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '9dc2ba19-42e9-41eb-8688-26ecb876b3e7',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1570824104453-508955ab713e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxjYXRzfGVufDB8fHx8MTY3ODA3MTk3Ng&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '8078d4d8-1b15-4eb4-88dc-7642a68b0cab',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Our streamlined design process ensures that your site is not only visually appealing but also easy to navigate, with modern layouts that include an interactive user interface and engaging typography. Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'e6274845-2684-4bcf-8cc7-9bd8e0ea1428',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#ff4d82', text: 'Digital Nomad, Thrilling UX' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '04a3503c-b76b-4af4-ad05-4c244fe8a42e',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'ABOUT_US',
        },
        {
          settings: {
            ...aboutUs.find(i => i.variant === 'VARIANT_2')?.settings,
            color: { background: { value: '#f3f3f3', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'UX Design for Crowdfunding Success' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '670392f9-9153-487f-8394-4d498560bdcf',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#ff4d82', text: 'Sleep On Disruptive Design' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'e69a5827-c471-4399-a153-dd37687c4cdc',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: "Our experience showcasing unicorn investors and influencers means that we know exactly what it takes to optimize your website for maximum ROI. Whether you're a cottage industry or a disruptive technology startup, our accelerator services will help you achieve your business goals faster. And with our crowdfunding expertise, we can help you raise the funds you need to take your business to the next level. So why wait? Contact us today to learn more about how we can help you create a captivating, high-converting website.",
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '7f4830ed-d8b8-41c8-8f33-e054d36bed75',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1583337130417-3346a1be7dee?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw1fHxhbmltYWxzfGVufDB8fHx8MTY3ODA3MTk5Ng&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: 'c442baf6-d995-4b60-bfa6-2b12df220668',
              category: 'IMAGE',
            },
          ],
          variant: 'VARIANT_2',
          type: 'ABOUT_US',
        },
        {
          settings: {
            ...aboutUs.find(i => i.variant === 'VARIANT_2')?.settings,
            color: { background: { value: '#f3f3f3', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  children: [
                    {
                      text: 'Our streamlined design process ensures that your site is not only visually appealing but also easy to navigate, with modern layouts that include an interactive user interface and engaging typography. Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '53cd0025-8a5e-4ffe-85d5-408060a03369',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'YOLO Startup Design' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '335f2dd4-62a4-4488-8955-9e44a89d6143',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ color: '#ff4d82', text: 'UX Design for Crowdfunding Success' }], type: 'paragraph' },
              ],
              name: 'SUBTITLE',
              id: '615f1be7-7233-461c-b1e9-e9e79de79ade',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1589883661923-6476cb0ae9f2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw2fHxjYXRzfGVufDB8fHx8MTY3ODA3MTk3Ng&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '2b051d9d-592d-470a-b342-9d94090c798e',
              category: 'IMAGE',
            },
          ],
          variant: 'VARIANT_2',
          type: 'ABOUT_US',
        },
      ],
    },
    {
      slug: '/contact-us',
      name: 'Contact',
      blocks: [
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_3')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#f3f3f3', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  name: 'First Name',
                  id: 'e1ceccef-b68f-4239-bf51-9cbf35694666',
                  placeholder: 'First Name',
                  type: 'text',
                  error: 'First Name is required',
                },
                {
                  name: 'Last Name',
                  id: 'd77e20fa-4a1a-4ffc-9198-4fb23bdcdc64',
                  placeholder: 'Last Name',
                  type: 'text',
                  error: 'Last Name is required',
                },
                {
                  name: 'Email',
                  id: 'dcc250dc-ba45-4768-9370-2f9cd4eef2bc',
                  placeholder: 'Email',
                  type: 'email',
                  error: 'email is required',
                },
                {
                  name: 'subject',
                  id: '3282a3b3-89d7-4be9-b628-6b7a4b1bc636',
                  placeholder: 'subject',
                  type: 'text',
                  error: 'subject is required',
                },
                {
                  name: 'Message',
                  id: '0592dae7-ddc7-4171-88b1-8d09eff5d4f0',
                  placeholder: 'message',
                  type: 'textarea',
                  error: '',
                },
              ],
              name: 'INPUTS',
              id: '1f4a32ef-3ec3-4853-a3b3-4705bdccafad',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1591856391054-7ffd7a2c44ef?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxwZXQlMjB3aXRoJTIwcGlua3xlbnwwfHx8fDE2NzgwOTcwMzM&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '497ddf8f-54bc-4896-a75b-b98edf0298c7',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ color: '#ff4d82', text: 'Visit Us' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'decab204-9dfb-4909-8397-4afab5046040',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#ffffff',
                  backgroundColor: '#ff4d82',
                  borderRadius: '25px',
                  color: '#ffffff',
                  borderWidth: '1px',
                  display: 'block',
                  width: '249px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'messsage us',
              },
              name: 'BUTTON_1',
              id: 'a18e1bf8-6e3e-4032-9825-3da726bc92bb',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_3',
          type: 'CONTACT_US',
        },
      ],
    },
  ],
  newMenu: [
    { name: 'Home', type: 'internal', url: '/' },
    { name: 'About Us', type: 'internal', url: '/about-us' },
    { name: 'Services', type: 'internal', url: '/services' },
    { name: 'Blog', type: 'internal', url: '/blog' },
    { name: 'Contact', type: 'internal', url: '/contact-us' },
  ],
  newMenuConfig: {
    links: [
      { name: 'Home', type: 'internal', url: '/' },
      { name: 'Contact Us', type: 'email', url: 'hello@example.com' },
    ],
    id: 'VARIANT_18',
    tags: [],
  },

  newFooter: {
    settings: {
      backgroundColor: { color: '#4A4860', enabled: true },
      spacing: {
        padding: { top: 0, left: 0, bottom: 0, right: 0 },
        margin: { top: 0, left: 0, bottom: 0, right: 0 },
        enabled: true,
      },
      textColor: { color: '#fff', enabled: true },
    },
    designedBy: 'Plly Staff',
    id: 'VARIANT_7',
    categories: [
      {
        name: 'Explore',
        links: [{ name: 'Home', id: '041e17b5-bc69-4263-8cff-6df4ce2c2560', type: 'internal', url: '/' }],
      },
    ],
    tags: [],
  },
  links: [],
}
