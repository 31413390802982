import { BsDot } from 'react-icons/bs'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../../menu/utils'
import { FooterVariantProps, getSocialLabel, NewFooter, Social } from '../footer'

const SocialItem = ({ type, link, footer }: { type: Social; link: string; footer: NewFooter }) => {
  const label = getSocialLabel(type)
  return (
    <div>
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className="cursor-pointer lg:text-2xl transition-all duration-300"
        style={{
          ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
          ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
        }}
      >
        {label}
      </a>
    </div>
  )
}

export default function VariantFive({ website, footer }: FooterVariantProps) {
  return (
    <>
      <MobileFooter website={website} footer={footer} />
      <DesktopFooter website={website} footer={footer} />
    </>
  )
}

const DesktopFooter = ({ website, footer }: FooterVariantProps) => {
  return (
    <div
      style={{
        ...getBackgroundColor({ settings: footer?.settings?.backgroundColor, defaultValue: 'black' }),
        ...getSpacing(footer?.settings?.spacing),
      }}
      className="text-white hidden md:flex flex-col gap-y-6 items-center justify-center"
    >
      <div
        style={{
          ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
          ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
        }}
        className="grid grid-cols-1 pt-4 items-start justify-start gap-3"
      >
        {footer.categories
          .filter(i => i.links.length > 0)
          .map(category => (
            <div>
              <ul className="list-none flex items-center gap-5 flex-wrap">
                <li className="uppercase mb-1 text-2xl font-semibold ">{category.name}</li>
                {category.links.map(link => (
                  <li className="hover:text-gray-600">
                    <a
                      href={link.url}
                      className="cursor-pointer transition-all duration-300 text-xl text-white"
                      style={{
                        ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
                        ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
                      }}
                    >
                      {link.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
      </div>
      <div
        className="flex flex-col my-4 items-center "
        style={{
          ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
          ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
        }}
      >
        <p className="text-xl mt-5 font-semibold text-center uppercase"> stay in touch</p>
        <div className="mt-3 flex">
          <div className="flex gap-x-12">
            {website?.config?.social
              ? Object.keys(website?.config?.social).map(item => (
                  <SocialItem type={item as Social} link={website?.config?.social[item]} footer={footer} />
                ))
              : footer?.social?.map(socialType => <SocialItem type={socialType} link="#" footer={footer} />)}
          </div>
        </div>
      </div>
      <div
        style={{
          ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
          ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
        }}
        className="flex font-bold font-serif text-5xl items-center justify-center pb-6"
      >
        {website?.config?.logo ? (
          <p className="flex items-end">
            {website?.name}
            <BsDot color={getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }).color} />
          </p>
        ) : (
          <p className="flex items-end">
            {website?.name}
            <BsDot color={getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }).color} />
          </p>
        )}
      </div>
    </div>
  )
}

const MobileFooter = ({ website, footer }: FooterVariantProps) => {
  return (
    <div
      className="bg-[black]  text-white block md:hidden"
      style={{
        ...getBackgroundColor({ settings: footer?.settings?.backgroundColor, defaultValue: 'black' }),
        ...getSpacing(footer?.settings?.spacing),
      }}
    >
      <div className="flex flex-col justify-center text-center pt-6">
        <div
          style={{
            ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
            ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
          }}
        >
          {footer.categories
            .filter(i => i.links.length > 0)
            .map(category => (
              <div className="flex flex-col">
                <div
                  style={{
                    ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
                    ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
                  }}
                >
                  <p className="my-2 font-bold">{category.name}</p>
                </div>
                <ul
                  className="space-y-3 m-0 p-0 list-none flex flex-col"
                  style={{
                    ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
                    ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
                  }}
                >
                  {category.links.map(link => (
                    <li>
                      <a
                        href={link.url}
                        className="cursor-pointer my-2 text-[12px] transition-all duration-300 hover:text-[gray]"
                      >
                        {link.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
        </div>
      </div>
      <div
        className="flex flex-col py-8 justify-center items-center"
        style={{
          ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
          ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
        }}
      >
        <p className="text-center text-[10px] uppercase"> stay in touch</p>
        <div>
          <div className="flex gap-2 px-4 items-center  flex-wrap mt-2 justify-center text-center">
            {website?.config?.social
              ? Object.keys(website?.config?.social).map(item => (
                  <SocialItem type={item as Social} link={website?.config?.social[item]} footer={footer} />
                ))
              : footer?.social?.map(socialType => <SocialItem type={socialType} link="#" footer={footer} />)}
          </div>
        </div>

        <div
          className="flex items-center justify-center text-center font-bold py-3"
          style={{
            ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
            ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
          }}
        >
          {website?.config?.logo ? (
            <p className="flex items-end text-xl">
              <span>{website?.name}</span>
              <span>
                <BsDot color={getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }).color} />
              </span>
            </p>
          ) : (
            <p className="flex items-end text-xl">
              <span>{website?.name}</span>
              <span>
                <BsDot color={getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }).color} />
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
