import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { TextSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantFifteen({ section }: { section: TextSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  return (
    <div
      className="py-6 md:py-12"
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="px-6 md:px-12">
        <div>
          <h4 className="text-[20px] md:text-5xl font-semibold ">
            <SectionSlatePreview initialValue={title} previewMode />
          </h4>
        </div>
        <div>
          <div>
            <p className="text-[16px] md:text-2xl  mt-2">
              <SectionSlatePreview initialValue={paragraph} previewMode />
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
