import { Descendant } from 'slate'
import {
  Section,
  SectionTags,
  TeamMembersSectionMember,
  TeamMembersVariables,
  TeamMembersVariants,
} from 'src/_polly/components/src/sections'
import { v4 as uuid } from 'uuid'
import { DEFAULT_VARIANT_SETTINGS, getSectionVariants } from './get-variants'
const tags: Record<TeamMembersVariants, SectionTags[]> = {
  VARIANT_1: ['IMAGE'],
  VARIANT_2: ['IMAGE'],
  VARIANT_3: ['IMAGE', 'SLIDESHOW'],
  VARIANT_4: ['IMAGE'],
  VARIANT_5: ['IMAGE'],
  VARIANT_6: ['IMAGE'],
  VARIANT_7: ['IMAGE', 'ANIMATION'],
}
export function getTeamMembersVariants() {
  const variants = getSectionVariants('TEAM_MEMBERS')
  const sections: Section<TeamMembersVariants, TeamMembersVariables>[] = []

  variants.forEach(variant => {
    const titleItem: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Bella Lawrence' }] }]
    const itemsubtitle: Descendant[] = [{ type: 'paragraph', children: [{ text: '---' }] }]
    const titleItem2: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Akira Hathaway' }] }]
    const titleItem3: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Carmen Lopez' }] }]
    const description: Descendant[] = [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio.',
          },
        ],
      },
    ]
    if (variant.id === 'VARIANT_1') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Meet the Team' }] }]
      const subtitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Sleep On Disruptive Design' }] }]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio.',
            },
          ],
        },
      ]
      const data: Section<TeamMembersVariants, TeamMembersVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TEAM_MEMBERS',
        updatedAt: '',
        tags: tags[variant.id as TeamMembersVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: '123',
            name: 'TEAM_MEMBERS',
            category: 'TEXT',
            data: [
              {
                id: '1',
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-00a18318-9bb9-47ad-b34f-63c1986b3d95',
                name: titleItem,
                subtitle: itemsubtitle,
                description: description,
              },
              {
                id: '2',
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-8ace8dd2-01ae-4558-a6f2-08ef459b754a',
                name: titleItem2,
                subtitle: itemsubtitle,
                description: description,
              },
              {
                id: '3',
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-95733307-3b4a-4ce4-87e3-39ff8199d3e4',
                name: titleItem3,
                subtitle: itemsubtitle,
                description: description,
              },
            ] as TeamMembersSectionMember[],
          },
        ],
        variant: variant.id as TeamMembersVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_2') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Meet the Team' }] }]
      const subtitle: Descendant[] = [
        { type: 'paragraph', children: [{ text: 'Digital Nomad, Thrilling UX', className: 'text-[#ff4d82]' }] },
      ]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
            },
          ],
        },
      ]
      const titleItem: Descendant[] = [
        { type: 'paragraph', children: [{ text: 'Bianca Witherspoon', className: 'text-[#ff4d82]' }] },
      ]
      const titleItem2: Descendant[] = [
        { type: 'paragraph', children: [{ text: 'Aliyah Jolie', className: 'text-[#ff4d82]' }] },
      ]
      const titleItem3: Descendant[] = [
        { type: 'paragraph', children: [{ text: 'Charles McConaughey', className: 'text-[#ff4d82]' }] },
      ]

      const data: Section<TeamMembersVariants, TeamMembersVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TEAM_MEMBERS',
        updatedAt: '',
        tags: tags[variant.id as TeamMembersVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: '123',
            name: 'TEAM_MEMBERS',
            category: 'TEXT',
            data: [
              {
                id: '1',
                image:
                  'https://images.unsplash.com/photo-1591954692515-d1d30376fa64?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxMHx8YW5pbWFsJTIwZG9jdG9yfGVufDB8fHx8MTY3ODA4NjQ2NA&ixlib=rb-4.0.3&q=80&w=1080',
                name: titleItem,
                description: description,
              },
              {
                id: '2',
                image:
                  'https://images.unsplash.com/photo-1547955326-921fec152788?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw2fHxhbmltYWwlMjBkb2N0b3J8ZW58MHx8fHwxNjc4MDg2NDY0&ixlib=rb-4.0.3&q=80&w=1080',
                name: titleItem2,
                description: description,
              },
              {
                id: '3',
                image:
                  'https://images.unsplash.com/photo-1644675443401-ea4c14bad0e6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxMHx8YW5pbWFscyUyMGRvY3RvcnN8ZW58MHx8fHwxNjc4MDg2NTQz&ixlib=rb-4.0.3&q=80&w=1080',
                name: titleItem3,
                description: description,
              },
            ] as TeamMembersSectionMember[],
          },
        ],
        variant: variant.id as TeamMembersVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_3') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Meet the Team' }] }]

      const titleItem: Descendant[] = [
        { type: 'paragraph', children: [{ text: 'Bianca Witherspoon', className: 'text-[brown]' }] },
      ]
      const titleItem2: Descendant[] = [
        { type: 'paragraph', children: [{ text: 'David Beckham', className: 'text-[brown]' }] },
      ]
      const titleItem3: Descendant[] = [
        { type: 'paragraph', children: [{ text: 'Elena Winslet', className: 'text-[brown]' }] },
      ]

      const titleItem4: Descendant[] = [
        { type: 'paragraph', children: [{ text: 'Delilah Holmes', className: 'text-[brown]' }] },
      ]

      const data: Section<TeamMembersVariants, TeamMembersVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TEAM_MEMBERS',
        updatedAt: '',
        tags: tags[variant.id as TeamMembersVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          {
            id: '123',
            name: 'TEAM_MEMBERS',
            category: 'TEXT',
            data: [
              {
                id: '1',
                image: 'https://image.lexica.art/full_jpg/74dad9e3-2ad3-411b-b399-fd8bd3b5108a',
                name: titleItem,
                description: description,
              },
              {
                id: '2',
                image: 'https://image.lexica.art/full_jpg/0c86af4b-2a59-4e51-a190-8d1c47810e4f',
                name: titleItem2,
                description: description,
              },
              {
                id: '3',
                image: 'https://image.lexica.art/full_jpg/96a9770f-caa9-47ae-ae7e-091717bb1581',
                name: titleItem3,
                description: description,
              },
              {
                id: '4',
                image: 'https://image.lexica.art/full_jpg/6f0fbe1b-3cad-425e-837d-4800aa2fc3c1',
                name: titleItem4,
                description: description,
              },
            ] as TeamMembersSectionMember[],
          },
        ],
        variant: variant.id as TeamMembersVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_4') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Meet the Team' }] }]
      const subtitle: Descendant[] = [
        { type: 'paragraph', children: [{ text: 'Digital Nomad, Thrilling UX', className: 'text-[#ae8967]' }] },
      ]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
            },
          ],
        },
      ]

      const description: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'UX Design for Crowdfunding Success', className: 'text-[#ae8967]' }],
        },
      ]

      const titleItem: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Amara Henson' }] }]
      const titleItem2: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Diana Lopez' }] }]
      const titleItem3: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Isaiah Smith' }] }]
      const data: Section<TeamMembersVariants, TeamMembersVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TEAM_MEMBERS',
        updatedAt: '',
        tags: tags[variant.id as TeamMembersVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: '123',
            name: 'TEAM_MEMBERS',
            category: 'TEXT',
            data: [
              {
                id: '1',
                image:
                  'https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw4fHxtYW58ZW58MHx8fHwxNjc3Njk4Mjkw&ixlib=rb-4.0.3&q=80&w=1080',
                name: titleItem,
                // subtitle: itemsubtitle,
                description: description,
              },
              {
                id: '2',
                image:
                  'https://images.unsplash.com/photo-1557862921-37829c790f19?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxtYW58ZW58MHx8fHwxNjc3Njk4Mjkw&ixlib=rb-4.0.3&q=80&w=1080',
                name: titleItem2,
                // subtitle: itemsubtitle,
                description: description,
              },
              {
                id: '3',
                image:
                  'https://images.unsplash.com/photo-1581382575275-97901c2635b7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxtYW58ZW58MHx8fHwxNjc3Njk4Mjkw&ixlib=rb-4.0.3&q=80&w=1080',
                name: titleItem3,
                // subtitle: itemsubtitle,
                description: description,
              },
            ] as TeamMembersSectionMember[],
          },
        ],
        variant: variant.id as TeamMembersVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_5') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Meet the Team' }] }]
      const subtitle: Descendant[] = [
        { type: 'paragraph', children: [{ text: 'Digital Nomad, Thrilling UX', className: 'text-[#ae8967]' }] },
      ]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
            },
          ],
        },
      ]

      const description: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'UX Design for Crowdfunding Success', className: 'text-[#ae8967]' }],
        },
      ]
      const titleItem: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Amara Henson' }] }]
      const titleItem2: Descendant[] = [{ type: 'paragraph', children: [{ text: 'James Johnson' }] }]
      const titleItem3: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Justin Hart' }] }]

      const data: Section<TeamMembersVariants, TeamMembersVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TEAM_MEMBERS',
        updatedAt: '',
        tags: tags[variant.id as TeamMembersVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: '123',
            name: 'TEAM_MEMBERS',
            category: 'TEXT',
            data: [
              {
                id: '1',
                image: 'https://image.lexica.art/full_jpg/c729ef0b-cc44-48c4-9698-bdcc4cd8b00f',
                name: titleItem,
                description: description,
                subtitle: subtitle,
              },
              {
                id: '2',
                image: 'https://image.lexica.art/full_jpg/5034d3d2-834e-4729-b179-c1769bd1520c',
                name: titleItem2,
                description: description,
                subtitle: subtitle,
              },
              {
                id: '3',
                image: 'https://image.lexica.art/full_jpg/b7029cea-0748-4fbb-8266-a8d27364bac9',
                name: titleItem3,
                description: description,
                subtitle: subtitle,
              },
            ] as TeamMembersSectionMember[],
          },
        ],
        variant: variant.id as TeamMembersVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_6') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Meet the Team' }] }]
      const subtitle: Descendant[] = [
        { type: 'paragraph', children: [{ text: 'Digital Nomad, Thrilling UX', className: 'text-[#ae8967]' }] },
      ]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
            },
          ],
        },
      ]

      const description: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'UX Design for Crowdfunding Success', className: 'text-[#ae8967]' }],
        },
      ]
      const titleItem: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Amara Henson' }] }]
      const titleItem2: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Jules Downey' }] }]
      const titleItem3: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Javon Foxx' }] }]

      const data: Section<TeamMembersVariants, TeamMembersVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TEAM_MEMBERS',
        updatedAt: '',
        tags: tags[variant.id as TeamMembersVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: '123',
            name: 'TEAM_MEMBERS',
            category: 'TEXT',
            data: [
              {
                id: '1',
                image: 'https://image.lexica.art/full_jpg/2b75a970-db61-4ef8-a45c-7d39dbd39e1d',
                name: titleItem,
                description: description,
                subtitle: subtitle,
              },
              {
                id: '2',
                image: 'https://image.lexica.art/full_jpg/b4608966-fade-4c03-a211-ff79e8e1f9fe',
                name: titleItem2,
                description: description,
                subtitle: subtitle,
              },
              {
                id: '3',
                image: 'https://image.lexica.art/full_jpg/6fdda9e6-a851-464f-ba9a-09ebe18d52bd',
                name: titleItem3,
                description: description,
                subtitle: subtitle,
              },
            ] as TeamMembersSectionMember[],
          },
        ],
        variant: variant.id as TeamMembersVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_7') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Meet the Trainers' }] }]

      const description: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'UX Design for Crowdfunding Success', className: 'text-[#ae8967]' }],
        },
      ]
      const titleItem: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Akira Hathaway' }] }]
      const titleItem2: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Jada Pinkett' }] }]
      const titleItem3: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Griffin Pitt' }] }]

      const data: Section<TeamMembersVariants, TeamMembersVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TEAM_MEMBERS',
        updatedAt: '',
        tags: tags[variant.id as TeamMembersVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          {
            id: '123',
            name: 'TEAM_MEMBERS',
            category: 'TEXT',
            data: [
              {
                id: '1',
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-2eb3cdba-c525-42d8-8a05-782b8ac2229b',
                name: titleItem,
                // subtitle: itemsubtitle,
                description: description,
              },
              {
                id: '2',
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-16bd1024-1b92-430a-a852-e1eb2024f73c',
                name: titleItem2,
                // subtitle: itemsubtitle,
                description: description,
              },
              {
                id: '3',
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-333f8a7b-5030-451a-9b7c-b438295f3400',
                name: titleItem3,
                // subtitle: itemsubtitle,
                description: description,
              },
            ] as TeamMembersSectionMember[],
          },
        ],
        variant: variant.id as TeamMembersVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Meet the Team' }] }]
      const subtitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'subtitle' }] }]
      const paragraph: Descendant[] = [{ type: 'paragraph', children: [{ text: 'paragraph' }] }]
      const titleItem: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Taylor Quill' }] }]
      const itemsubtitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Position/Role' }] }]
      const titleItem2: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Kris Ward' }] }]
      const titleItem3: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Alex Smith' }] }]
      const description: Descendant[] = [{ type: 'paragraph', children: [{ text: '#developer #expert #designer' }] }]
      const data: Section<TeamMembersVariants, TeamMembersVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TEAM_MEMBERS',
        updatedAt: '',
        tags: tags[variant.id as TeamMembersVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: '123',
            name: 'TEAM_MEMBERS',
            category: 'TEXT',
            data: [
              {
                id: '1',
                image:
                  'https://static.wixstatic.com/media/bc177aff88e04820a84c0f5dde2f6027.jpg/v1/fill/w_920,h_920,al_c,q_85,usm_0.66_1.00_0.01/Young%20Businessman.webp',
                name: titleItem,
                subtitle: itemsubtitle,
                description: description,
                name1: titleItem,
                subtitle1: itemsubtitle,
                description1: description,
              },
              {
                id: '2',
                image:
                  'https://static.wixstatic.com/media/2eca80590fba43cbbebc8202eb91a12d.jpg/v1/fill/w_920,h_920,al_c,q_85,usm_0.66_1.00_0.01/Male%20Portrait.webp',
                name: titleItem2,
                subtitle: itemsubtitle,
                description: description,
                name1: titleItem,
                subtitle1: itemsubtitle,
                description1: description,
              },
              {
                id: '3',
                image:
                  'https://static.wixstatic.com/media/11062b_bee520ef2f564ccba95330ccdbd6f250~mv2_d_2000_2000_s_2.jpg/v1/fill/w_920,h_920,al_c,q_85,usm_0.66_1.00_0.01/Happy%20Young%20Man.webp',
                name: titleItem3,
                subtitle: itemsubtitle,
                description: description,
                name1: titleItem,
                subtitle1: itemsubtitle,
                description1: description,
              },
            ] as TeamMembersSectionMember[],
          },
        ],
        variant: variant.id as TeamMembersVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    }
  })

  return sections
}
