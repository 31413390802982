import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { PageCatergories, getPageWithBlocks } from 'src/services/page.service'
import LinkIcon from 'src/icons/Link'
import { PageTemplate } from 'src/services/page-templates'
import { createPageRecord } from 'src/lib/services/website.service'
import ChoosePageTemplate from './ChoosePageTemplate'
import { ReactComponent as AddIcon } from '../../../assets/icons/menuBar/add.svg'
import ModalDialogV2 from '../ModalDialogV2'

interface CreatePageProps {
  defaultOpen?: boolean
  onPageCreate: (options: { websiteID: string; name: string; path: string; blocks: string }) => void
  createButton?: React.ReactNode
  pageName?: string
}

const CreatePage = ({ onPageCreate, defaultOpen, createButton, pageName }: CreatePageProps) => {
  const params = useParams()

  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(defaultOpen)
  const [name, setName] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState<{ category: PageCatergories; id: string } | null>(null)

  const handleCreatePage = async () => {
    if (!selectedTemplate) return
    setLoading(true)
    const random = Math.random().toString(36).substring(2, 5)
    const path = `/${random}-${name.toLowerCase().replace(/ /g, '-')}`
    const template = (await getPageWithBlocks(selectedTemplate)).default as PageTemplate
    const blocks = JSON.stringify(
      template.blocks.map(block => ({
        ...block,
        variables: block.variables.map((i: any) => ({ ...i, id: uuid() })), // generate id for variables
        isSection: true,
        id: uuid(),
      })),
    )
    const options = {
      websiteID: params.id as string,
      name,
      path,
      blocks,
    }
    await createPageRecord(options)
    setOpen(false)
    onPageCreate(options)
    setLoading(false)
  }

  useEffect(() => {
    if (pageName) {
      setName(pageName)
    }
  }, [pageName])

  useEffect(() => {
    if (selectedTemplate) handleCreatePage()
  }, [selectedTemplate])

  return (
    <div>
      {createButton ? (
        <button type="button" className="w-full" onClick={() => setOpen(true)}>
          {createButton}
        </button>
      ) : (
        <button type="button" onClick={() => setOpen(true)} className="mt-10 flex items-center cursor-pointer">
          <div className="h-14 w-16 border-[1px] border-black flex justify-center items-center">
            <AddIcon />
          </div>
          <span className="text-2xl pl-8">Create Page</span>
        </button>
      )}

      <ModalDialogV2 open={open} bgFill="transparent" onBack={() => setOpen(false)}>
        <div className="px-[18px] pt-[80px]">
          <h1 className="text-[19px] dark:text-white pb-[14px] border-b-[1px] border-[#8D8D8D]">New Page</h1>
          <p className="text-[19px] dark:text-white pt-[19px]">Provide a Name for your the new page you are creating</p>
          <div className="flex flex-col gap-[60px] mt-[38px]">
            <div className="flex items-center">
              <div className="w-[58px]">
                <LinkIcon
                  height={24}
                  className="stroke-[#8D8D8D] fill-[#8D8D8D] dark:fill-[#D9D9D9] dark:stroke-[#D9D9D9] mx-auto"
                />
              </div>
              <input
                value={name}
                onChange={e => setName(e.target.value)}
                type="text"
                placeholder="Name of Page"
                className="text-[19px] w-1/2 border-b dark:text-white dark:bg-black dark:border-white border-black pb-[7px] outline-none text-left"
              />
            </div>
            <ChoosePageTemplate setSelectedTemplate={setSelectedTemplate} loading={loading} />
          </div>
        </div>
      </ModalDialogV2>
    </div>
  )
}

export default CreatePage
