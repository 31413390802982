import { TextSection, TextSectionMediaGroup } from '../../types'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import SectionSlatePreview from '../../../common/SectionSlatePreview'

export default function VariantFive({ section }: { section: TextSection }) {
  const data = section.variables.find(variable => variable.name === 'MEDIAGROUP')?.data as TextSectionMediaGroup[]
  return (
    <div
      className="text-[silver] md:min-h-[600px] min-h-[350px] w-[100%]"
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),

        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <Swiper
        pagination={{
          el: '.my-custom-pagination-div',
          clickable: true,
        }}
        loop={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        modules={[Pagination]}
        className="mySwiper md:min-h-[600px] min-h-[350px] "
      >
        <div>
          {data.map((item, idx) => (
            <SwiperSlide
              className="flex flex-col bg-cover items-center justify-center min-h-[350px] md:min-h-[600px] bg-no-repeat w-full "
              style={{
                backgroundImage: `url(${item.image})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundOrigin: 'border-box',
              }}
            >
              <div
                key={idx}
                className="md:mr-auto min-h-[350px] md:min-h-[300px] h-full text-white col-span-1 xl:min-w-[700px] md:min-w-[230px] md:max-w-[600px] px-4 w-full flex flex-col items-center text-center md:text-left md:items-start justify-center md:p-12  bg-[#000000b4]"
              >
                <h1 className="md:text-3xl text-[16px]">
                  <SectionSlatePreview initialValue={item.name} previewMode />
                </h1>
                <p className="md:mt-3 mt-1 md:text-lg text-[12px]">
                  <SectionSlatePreview initialValue={item.description} previewMode />
                </p>
              </div>
            </SwiperSlide>
          ))}
        </div>
      </Swiper>
    </div>
  )
}
