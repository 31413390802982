import { useEffect } from 'react'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import useBatchFetch from 'src/hooks/useBatchFetch'
import WriteMessage from 'src/icons/WriteMessage'
import { listConversationByUser } from 'src/services/messenger.service'
import { getUserById } from 'src/services/user.service'
import useMessengerStore from 'src/store/messenger'
import Header from '../posts-page/components/Header'
import ChatMateList from './ChatMateList'
import ConvoSection from './ConvoSection'
import HeaderMobile from './HeaderMobile'

export default function MessengerPage() {
  const messengerStore = useMessengerStore(state => state)

  const params = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const showThreadList = searchParams.get('showThreadList')
  const isList = !params.chatmateId
  const isNewMessage = location.pathname.includes('/messages/new')

  const {
    items: threads,
    hasMore,
    nextBatch,
  } = useBatchFetch(
    listConversationByUser as any,
    // TODO: may need to remove duplicates from next batch
  )

  const handleMessageSend = (chatmateId: string) => {
    messengerStore.setThreads(
      messengerStore.threads.map(thread =>
        thread.chatmateID === chatmateId
          ? { ...thread, lastMessage: { ...thread?.lastMessage, createdAt: new Date().toISOString() } }
          : thread,
      ),
    )
  }

  useEffect(() => {
    if (isList && !isNewMessage && threads.length && !showThreadList) {
      navigate(`/messages/${threads[0].chatmateID}`, { replace: true })
    }
  }, [isNewMessage, isList, threads, showThreadList])

  useEffect(() => {
    messengerStore.setThreads(threads)
  }, [threads])

  useEffect(() => {
    if (!params.chatmateId) return
    if (!threads?.find(thread => thread.chatmateID === params.chatmateId)) {
      getUserById(params.chatmateId).then(data => {
        messengerStore.addThread({ chatmate: data, chatmateID: data.id })
      })
    }
  }, [threads, params.chatmateId])

  return (
    <div>
      <div>
        <div className="hidden md:block">
          <Header />
        </div>
        <div className="md:hidden">
          <div className={`flex-1 ${!isList || isNewMessage ? 'hidden ' : 'block'}`}>
            <HeaderMobile />
          </div>
          <div className={`flex-1 ${isNewMessage ? 'block' : 'hidden '}`}>
            <HeaderMobile status="new" />
          </div>
          <div className={`flex-1 ${!isList ? 'block' : 'hidden md:block'}`}>
            <HeaderMobile status="chat" />
          </div>
        </div>
      </div>

      <div className="flex" style={{ height: 'calc(100vh - 86px)' }}>
        <div className={`w-full md:w-[400px] flex-col ${isList && !isNewMessage ? 'flex' : 'hidden md:flex'}`}>
          <div className="flex justify-between items-center px-5 h-24 border-b border-black dark:bg-white flex-shrink-0">
            <h1 className="text-3xl font-bold">Messages</h1>
            <Link to="/messages/new">
              <WriteMessage size={36} className="mt-2 stroke-black" />
            </Link>
          </div>
          <div className="h-full bg-black overflow-hidden">
            <ChatMateList
              chatmateId={params.chatmateId}
              postId={params.postId}
              chatThreads={messengerStore.threads}
              hasMore={hasMore}
              nextBatch={nextBatch}
            />
          </div>
        </div>

        <div className={`flex-1 ${!isList || isNewMessage ? 'block' : 'hidden md:block'}`}>
          <ConvoSection chatmateId={params.chatmateId} onMessageSend={handleMessageSend} />
        </div>
      </div>
    </div>
  )
}
