import { Auth } from 'aws-amplify'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import InputText from 'src/_polly/components/src/common/InputText'
import AuthLayout from 'src/components/layout/AuthLayout'
import { Loader } from '../components/loader'

interface IUser {
  email: string
  password: string
}

const ForgotPassword = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()
  const navigate = useNavigate()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubmit = async (user: IUser) => {
    const { email } = user
    try {
      setLoading(true)
      await Auth.forgotPassword(email)
      localStorage.setItem('email', email)
      navigate('/verify-reset-password')
    } catch (err: any) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <AuthLayout>
      <Loader show={loading} />
      <form className="space-y-8" onSubmit={handleSubmit(onSubmit)}>
        <div className="text-[16px] md:text-[20px] text-white text-center opacity-80">
          <p>Enter your email to reset your password.</p>
        </div>
        <div className="w-[265px] mx-auto">
          <InputText
            authInput
            placeholder="Email"
            name="email"
            register={register}
            errors={errors}
            required={{ value: true, message: 'Email is required' }}
            pattern={{
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address',
            }}
          />
        </div>
        {error && <span className="text-secondary.main text-center mb-4">{error}</span>}
        <div className="w-[265px] mx-auto">
          <button className="w-full py-2 bg-[#E90570] text-white text-[20px] rounded" type="submit" disabled={loading}>
            Find Account
          </button>
        </div>
        <div className="w-[265px] mx-auto">
          <Link
            to="/login"
            className="w-full py-2 bg-[#D9D9D9] text-[#000000] text-[20px] border-3 border-white rounded block text-center"
          >
            Cancel
          </Link>
        </div>
      </form>
    </AuthLayout>
  )
}

export default ForgotPassword
