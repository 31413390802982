import { SectionSettings } from './types'

export function generateSectionBackground({
  defaultColor,
  backgroundSettings,
  image,
  defaultBackground,
}: {
  image: string | null
  backgroundSettings: SectionSettings['color']['background']
  defaultColor: string
  defaultBackground?: string
}) {
  const styles: Record<string, string> = {
    backgroundColor: defaultColor,
  }

  if (backgroundSettings?.enabled && backgroundSettings?.value) {
    styles.backgroundColor = backgroundSettings.value
    styles.background = backgroundSettings.value
  }

  if (!backgroundSettings?.enabled && defaultBackground) {
    styles.background = defaultBackground
  }

  if (image) styles.backgroundImage = `url('${image}')`

  return styles
}

export function generateSectionSpacing(spacing: SectionSettings['spacing']) {
  if (!spacing?.enabled) return {}

  const innerWidth = window.innerWidth
  const { padding, margin } = spacing
  const desktop = {
    padding: `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`,
    margin: `${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px`,
  }

  let mobile: { margin: string; padding: string } = { margin: '', padding: '' }
  if (spacing?.paddingMobile) {
    const { paddingMobile } = spacing
    mobile.padding = `${paddingMobile.top}px ${paddingMobile.right}px ${paddingMobile.bottom}px ${paddingMobile.left}px`
  }
  if (spacing?.marginMobile) {
    const { marginMobile } = spacing
    mobile.margin = `${marginMobile.top}px ${marginMobile.right}px ${marginMobile.bottom}px ${marginMobile.left}px`
  }

  if (innerWidth < 640) return mobile
  return desktop
}

export function getTransitionDuration({
  settings,
  defaultValue,
}: {
  settings: SectionSettings['transitionSpeed']
  defaultValue: number
}) {
  return settings?.enabled ? settings.value : defaultValue
}
