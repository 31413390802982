import { motion } from 'framer-motion'
import { Fragment, useState } from 'react'
import { BsFacebook, BsTwitter } from 'react-icons/bs'
import { IoClose, IoMenuOutline } from 'react-icons/io5'
import FontLoader from '../FontLoader'

import { getSocialIcon, MenuVariantProps, NewMenu, NewMenuLinkType, Social } from '../menu'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../utils'
import { Popover, Transition } from '@headlessui/react'

const underLine = {
  default: {
    x: -400,
    transition: {
      duration: 0.5,
    },
  },

  hover: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
}
export default function MenuVariantFourteen({ menu, website }: MenuVariantProps) {
  return (
    <div>
      <FontLoader settings={menu?.settings?.fontFamily} />
      <DesktopNavbar menu={menu} website={website} />
      <MobileNavbar menu={menu} website={website} />
    </div>
  )
}
const SocialItems = ({ type, link, menu }: { type: Social; link: string; menu: NewMenu }) => {
  let Icon = getSocialIcon(type)
  return (
    <a
      href={link}
      className="text-black hover:bg-[#f1ecdf] transition-all duration-500 shadow-2xl border p-3"
      style={{
        borderColor: getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }).color,
      }}
    >
      <Icon
        className="lg:text-xl"
        color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }).color}
      />
    </a>
  )
}

const DesktopNavbar = ({ menu, website }: MenuVariantProps) => {
  return (
    <div
      className="hidden lg:block "
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '#bdbdbdd5' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <motion.div className="px-12 flex py-4 justify-between items-center">
        <motion.div
          initial={{ y: -100 }}
          animate={{
            y: 0,
            transition: {
              duration: 1,
            },
          }}
        >
          <a href="/">
            {website?.config?.logo ? (
              <img
                src={website?.config?.logo}
                className="w-[80px] h-[80px] rounded-full origin-center border-black border-2  object-cover shadow-3xl cursor-pointer"
                style={{
                  borderColor: getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#eee' }).color,
                }}
              />
            ) : (
              <p
                className="cursor-pointer font-normal border px-4 py-2 uppercase hover:text-gray-700 transition-all duration-500 text-[black] text-xl"
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  border: '1px solid black',
                  borderColor: getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }).color,
                }}
              >
                {website?.name}
              </p>
            )}
          </a>
        </motion.div>
        <motion.div
          initial={{ y: -100 }}
          animate={{
            y: 0,
            transition: {
              duration: 1,
            },
          }}
          className="gap-x-5 flex"
        >
          {website?.config?.social ? (
            <>
              {website?.config?.social && (
                <SocialItems type={'facebook'} link={website?.config?.social['facebook']} menu={menu} />
              )}
              {website?.config?.social && (
                <SocialItems type={'twitter'} link={website?.config?.social['twitter']} menu={menu} />
              )}
            </>
          ) : (
            <ul className="flex gap-x-4 list-none ">
              <li
                style={{
                  border: '1px solid black',
                  borderColor: getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }).color,
                }}
                className="text-black hover:bg-[#f1ecdf] transition-all duration-500 shadow-2xl border cursor-pointer p-3"
              >
                <BsFacebook
                  className="lg:text-xl"
                  color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color}
                />
              </li>
              <li
                style={{
                  border: '1px solid black',
                  borderColor: getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }).color,
                }}
                className="text-black hover:bg-[#f1ecdf] transition-all duration-500 shadow-2xl border cursor-pointer p-3"
              >
                <BsTwitter
                  className="lg:text-xl"
                  color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color}
                />
              </li>
            </ul>
          )}
        </motion.div>
      </motion.div>

      <div
        className="py-4 px-12 border-t border-white"
        style={{
          ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '#f1ecdf' }),
          ...getSpacing(menu?.settings?.spacing),
          borderColor: getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }).color,
        }}
      >
        <ul className="flex  list-none text-xl gap-x-12  items-center justify-center ">
          {menu.links.map(({ id, name, url, type }) => {
            return (
              <motion.a
                href={url}
                target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                className="grid-rows-1 grid-cols-1"
                key={id}
                initial="default"
                whileHover="hover"
                animate="default"
              >
                <li
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                  className="cursor-pointer text-black hover:text-gray-800 font-normal  transition-all duration-300  relative m-[0 auto] grid  items-center overflow-hidden py-4"
                >
                  {name}
                  <motion.div
                    variants={underLine}
                    className="top-[17px] h-[5px] relative left-0 w-[100%] z-1 mt-[-23px] bg-black rounded-full "
                  />
                </li>
              </motion.a>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

const MobileNavbar = ({ menu, website }: MenuVariantProps) => {
  const [show, setShow] = useState(false)
  return (
    <div
      className="block lg:hidden"
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '#f1ecdf' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className="relative items-center px-4 py-4 ">
        <Popover>
          <div className="flex justify-between items-center">
            <a href="/">
              {website?.config?.logo ? (
                <img
                  className="cursor-pointer h-[30px] w-[30px] rounded-full"
                  src={website?.config?.logo}
                  alt={website?.name}
                />
              ) : (
                <p
                  className="font-bold text-xl"
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  {website?.name}
                </p>
              )}
            </a>

            <Popover.Button onClick={() => setShow(!show)} className="focus:outline-none">
              {show ? (
                <IoClose
                  size={24}
                  color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }).color}
                />
              ) : (
                <IoMenuOutline
                  size={24}
                  color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }).color}
                />
              )}
            </Popover.Button>
          </div>

          <Transition
            enter="transition ease-out duration-500"
            enterFrom="opacity-0 -translate-x-5"
            enterTo="opacity-100 translate-x-0"
            leave="transition ease-in duration-300"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 -translate-x-5"
            as={Fragment}
          >
            <Popover.Panel
              className={`flex flex-col items-center h-screen w-full gap-y-4 py-12 absolute right-0 z-50 mt-3`}
              style={{
                ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '#bdbdbdf9' }),
                ...getSpacing(menu?.settings?.spacing),
              }}
            >
              {menu.links.map(({ id, name, url, type }) => {
                return (
                  <motion.a
                    key={id}
                    href={url}
                    target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                    className="grid-rows-1 hover:bg-[#bdbdbdf9] border bg-[#f1ecdf] px-12 transition-all duration-300 grid-cols-1"
                    initial="default"
                    whileHover="hover"
                    animate="default"
                  >
                    <li
                      style={{
                        ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                        ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                      }}
                      className="cursor-pointer text-black hover:text-gray-800 font-normal  transition-all duration-300  relative m-[0 auto] grid  items-center overflow-hidden py-1"
                    >
                      {name}
                      <motion.div
                        variants={underLine}
                        style={{
                          ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                          ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                        }}
                        className="top-[12px] h-[3px] relative left-0 w-[100%] z-1 mt-[-23px] bg-black rounded-full "
                      />
                    </li>
                  </motion.a>
                )
              })}
            </Popover.Panel>
          </Transition>
        </Popover>
      </div>
    </div>
  )
}
