import { useCallback, useEffect, useState } from 'react'

interface GraphqlQueryResult<T = any> {
  items: T[]
  nextToken: string | null
}

/**
 * Batch query with `nextToken`
 * Deduplicate calls, query function invalidation
 * */
export default function useBatchFetch(
  queryFn: (nextToken?: string | null) => Promise<GraphqlQueryResult>,
  nextBatchMapFn?: (items: any[]) => any[] | Promise<any[]>,
) {
  const [items, setItems] = useState([] as any[])

  // use for query invalidation
  const [query, setQuery] = useState({
    nextBatch: queryFn,
    nextBatchMapping: nextBatchMapFn,
  })

  // undefined: initial, null: no more
  const [token, setToken] = useState<string | null | undefined>(null)
  const [nextToken, setNextToken] = useState<string | null | undefined>(null)
  const [fetching, setFetching] = useState(false)

  const nextBatch = useCallback(() => {
    // prevent call again too fast
    if (!fetching) {
      setFetching(true)
      setToken(nextToken)
    }
  }, [fetching, nextToken])

  /** Reset when query change */
  useEffect(() => {
    setItems([])
    setToken(undefined)
    setNextToken(undefined)
    setFetching(true)
    setQuery({
      nextBatch: queryFn,
      nextBatchMapping: nextBatchMapFn,
    })
  }, [queryFn, nextBatchMapFn])

  useEffect(() => {
    let cancelToken = false

    // there no more result
    if (token === null) return

    query
      .nextBatch(token)
      .then(async result => {
        if (!cancelToken) {
          const batch = query.nextBatchMapping ? await query.nextBatchMapping(result.items) : result.items

          setNextToken(result.nextToken)
          setItems(items => [...items, ...batch])
        }
      })
      // TODO: retry?
      .catch(error => console.error('@useBatchFetch::error', error))
      .finally(() => !cancelToken && setFetching(false))

    return () => {
      cancelToken = true
    }
  }, [token, query])

  return {
    items,
    updateItems: setItems,
    nextBatch,
    fetching,
    hasMore: nextToken !== null,
  }
}
