import { Descendant } from 'slate'
import {
  Section,
  SectionTags,
  TextSectionMediaGroup,
  TextSectionVariables,
  TextSectionVariants,
} from 'src/_polly/components/src/sections'
import { v4 as uuid } from 'uuid'
import { DEFAULT_VARIANT_SETTINGS, getSectionVariants } from './get-variants'
const tags: Record<TextSectionVariants, SectionTags[]> = {
  VARIANT_1: ['IMAGE'],
  VARIANT_2: ['IMAGE'],
  VARIANT_3: ['IMAGE'],
  VARIANT_4: ['IMAGE'],
  VARIANT_5: ['IMAGE'],
  VARIANT_6: ['IMAGE'],
  VARIANT_7: ['ANIMATION'],
  VARIANT_8: ['IMAGE'],
  VARIANT_9: ['IMAGE'],
  VARIANT_10: ['IMAGE', 'ANIMATION'],
  VARIANT_11: ['IMAGE', 'SLIDESHOW'],
  VARIANT_12: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_13: [],
  VARIANT_14: [],
  VARIANT_15: [],
}

export function getTextVariants() {
  const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Title' }] }]
  const paragraph: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Paragraph' }] }]
  const subTitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Sub Title' }] }]
  const variants = getSectionVariants('TEXT')
  const sections: Section<TextSectionVariants, TextSectionVariables>[] = []

  variants.forEach(variant => {
    if (variant.id === 'VARIANT_1') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Snatched Crowdfunding UX' }] }]
      const subtitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Investor-Focused UX Design' }] }]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience? Look no further than our custom site and portfolio design services. Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. Whether you are a digital nomad or a disruptive technology startup, we have the expertise to optimize your website for exceptional user experience on any device.',
            },
          ],
        },
      ]
      const data: Section<TextSectionVariants, TextSectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TEXT',
        updatedAt: '',
        tags: tags[variant.id as TextSectionVariants],

        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
        ],

        variant: variant.id as TextSectionVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_2') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Hits Different Investors' }] }]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience? Look no further than our custom site and portfolio design services. Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. Whether you are a digital nomad or a disruptive technology startup, we have the expertise to optimize your website for exceptional user experience on any device.',
            },
          ],
        },
      ]

      const data: Section<TextSectionVariants, TextSectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TEXT',
        updatedAt: '',
        tags: tags[variant.id as TextSectionVariants],

        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-5c7a86e6-c209-4eb9-ac9d-c71a152c45b7',
            name: 'MEDIA',
          },
        ],

        variant: variant.id as TextSectionVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_3') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Accelerator-Approved UX Design' }] }]
      const subtitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Burn Rate Buster UX Design' }] }]

      const data: Section<TextSectionVariants, TextSectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TEXT',
        updatedAt: '',
        tags: tags[variant.id as TextSectionVariants],

        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
            name: 'MEDIA',
          },
        ],

        variant: variant.id as TextSectionVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_4') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Accelerator-Approved UX Design' }] }]
      const subtitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Burn Rate Buster UX Design' }] }]

      const data: Section<TextSectionVariants, TextSectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TEXT',
        updatedAt: '',
        tags: tags[variant.id as TextSectionVariants],

        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-00301506-5c5f-49ef-ba58-38c2ddc0ff88',
            name: 'MEDIA',
          },
        ],

        variant: variant.id as TextSectionVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_5') {
      const name: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Conscious Startup Success' }] }]
      const description: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'We ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
            },
          ],
        },
      ]
      const data: Section<TextSectionVariants, TextSectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TEXT',
        updatedAt: '',
        tags: tags[variant.id as TextSectionVariants],

        variables: [
          {
            id: uuid(),
            category: 'IMAGE',
            name: 'MEDIAGROUP',
            data: [
              {
                id: '1',
                image:
                  'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                name: name,
                description: description,
              },
              {
                id: '2',
                image:
                  'https://images.unsplash.com/photo-1522199755839-a2bacb67c546?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxibG9nfGVufDB8fHx8MTY3Nzg2MDQyMg&ixlib=rb-4.0.3&q=80&w=1080',
                name: name,
                description: description,
              },
              {
                id: '3',
                image:
                  'https://images.unsplash.com/photo-1546074177-ffdda98d214f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw3fHxibG9nfGVufDB8fHx8MTY3Nzg2MDQyMg&ixlib=rb-4.0.3&q=80&w=1080',
                name: name,
                description: description,
              },
            ] as TextSectionMediaGroup[],
          },
        ],

        variant: variant.id as TextSectionVariants,
        styleName: variant.name,
        designedBy: 'Abrar Muthana',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_6') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'What We Do ?' }] }]
      const titleItem: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Burn Rate Buster UX Design' }] }]
      const descriptionItem: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
            },
          ],
        },
      ]
      const data: Section<TextSectionVariants, TextSectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TEXT',
        updatedAt: '',
        tags: tags[variant.id as TextSectionVariants],

        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          {
            id: uuid(),
            category: 'IMAGE',
            name: 'MEDIAGROUP',
            data: [
              {
                id: '1',
                image:
                  'https://images.unsplash.com/photo-1674707619293-d71bd8d7afa3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2Nzc1OTY4MDg&ixlib=rb-4.0.3&q=80&w=1080',
                name: titleItem,
                description: descriptionItem,
              },
              {
                id: '2',
                image:
                  'https://images.unsplash.com/photo-1675248954741-6d26c8fd7fd6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2Nzc1OTY4NzI&ixlib=rb-4.0.3&q=80&w=1080',
                name: titleItem,
                description: descriptionItem,
              },
              {
                id: '3',
                image:
                  'https://images.unsplash.com/photo-1606246481699-f16245882373?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxkZWNvcmV8ZW58MHx8fHwxNjc3NTk2NjQ0&ixlib=rb-4.0.3&q=80&w=1080',
                name: titleItem,
                description: descriptionItem,
              },
            ] as TextSectionMediaGroup[],
          },
        ],

        variant: variant.id as TextSectionVariants,
        styleName: variant.name,
        designedBy: 'Abrar Muthana',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_7') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Hits Different Investors',
            },
          ],
        },
      ]
      const subTitle: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Liquorice croissant topping lemon drops fruitcake tootsie roll. Pudding muffin dessert jelly beans sugar plum wafer wafer. Souffle cake sweet jelly beans tiramisu caramels cake sesame snaps. Marshmallow jujubes brownie gummies topping tiramisu. Sweet shortbread croissant caramels lemon drops jelly. Sweet candy powder dessert tart marshmallow powder chocolate bar cake souffle.',
            },
          ],
        },
      ]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Known as one of the safest North American hubs, Downtown Montreal is vibrant with shoppers, office workers and students from McGill and Concordia Universities.',
            },
          ],
        },
      ]
      const data: Section<TextSectionVariants, TextSectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TEXT',
        updatedAt: '',
        tags: tags[variant.id as TextSectionVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subTitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-5c7a86e6-c209-4eb9-ac9d-c71a152c45b7',
            name: 'MEDIA',
          },
        ],
        variant: variant.id as TextSectionVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_8') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'YOLO Startup Design _' }] }]
      const subTitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Buster UX Design' }] }]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience? Look no further than our custom site and portfolio design services. Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. Whether you are a digital nomad or a disruptive technology startup, we have the expertise to optimize your website for exceptional user experience on any device.',
            },
          ],
        },
      ]
      const data: Section<TextSectionVariants, TextSectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TEXT',
        updatedAt: '',
        tags: tags[variant.id as TextSectionVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subTitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-5c7a86e6-c209-4eb9-ac9d-c71a152c45b7',
            name: 'MEDIA',
          },
        ],
        variant: variant.id as TextSectionVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_9') {
      const subTitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Ceramics By Abrar & Saliha' }] }]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Cat ipsum dolor sit amet, fugiat iste. Totam aliqua, yet ab, for natus for suscipit. Minim eos molestiae. Enim irure officia and minima. Adipisicing ratione. Velitesse elit for magni aliquam. Qui corporis tempora quam, so est, magni. Nemo. Irure. Eos illum labore. Accusantium eu. Odit sit and cillum sed eaque, but quia, duis. Aliqua minima do adipisicing, doloremque.',
            },
          ],
        },
      ]
      const data: Section<TextSectionVariants, TextSectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TEXT',
        updatedAt: '',
        tags: tags[variant.id as TextSectionVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: subTitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
        ],
        variant: variant.id as TextSectionVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_10') {
      const media = 'https://www.datocms-assets.com/38619/1607792250-reform.jpg?auto=format&dpr=1&fit=crop&h=730&w=900'
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: ' Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience? Look no further than our custom site and portfolio design services. Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. Whether you are a digital nomad or a disruptive technology startup, we have the expertise to optimize your website for exceptional user experience on any device.',
            },
          ],
        },
      ]
      const data: Section<TextSectionVariants, TextSectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TEXT',
        updatedAt: '',
        tags: tags[variant.id as TextSectionVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          { id: uuid(), category: 'IMAGE', data: media, name: 'MEDIA' },
        ],
        variant: variant.id as TextSectionVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_11') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'skills and Experience',
            },
          ],
        },
      ]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience? Look no further than our custom site and portfolio design services. Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. Whether you are a digital nomad or a disruptive technology startup, we have the expertise to optimize your website for exceptional user experience on any device.',
            },
          ],
        },
      ]
      const data: Section<TextSectionVariants, TextSectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TEXT',
        updatedAt: '',
        tags: tags[variant.id as TextSectionVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
        ],
        variant: variant.id as TextSectionVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_12') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Pet Care',
            },
          ],
        },
      ]
      const subTitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Pet Care' }] }]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'wedding to saradina',
            },
          ],
        },
      ]
      const data: Section<TextSectionVariants, TextSectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TEXT',
        updatedAt: '',
        tags: tags[variant.id as TextSectionVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subTitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
        ],
        variant: variant.id as TextSectionVariants,
        styleName: variant.name,
        designedBy: 'Muhammad Shafi',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_13') {
      const title: Descendant[] = [
        { type: 'paragraph', children: [{ text: 'Join To our classes', className: 'experiment-title' }] },
      ]
      const data: Section<TextSectionVariants, TextSectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TEXT',
        updatedAt: '',
        tags: tags[variant.id as TextSectionVariants],

        variables: [{ id: uuid(), category: 'TEXT', data: title, name: 'TITLE' }],

        variant: variant.id as TextSectionVariants,
        styleName: variant.name,
        designedBy: 'Muhammad Shafi',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_14') {
      const titleItem: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Hits Different Investors' }] }]
      const descriptionItem: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
            },
          ],
        },
      ]
      const data: Section<TextSectionVariants, TextSectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TEXT',
        updatedAt: '',
        tags: tags[variant.id as TextSectionVariants],

        variables: [
          {
            id: uuid(),
            category: 'IMAGE',
            name: 'MEDIAGROUP',
            data: [
              {
                id: '1',
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-a416a289-9477-4496-bfcc-284fe3a760eb',
                name: titleItem,
                description: descriptionItem,
              },
              {
                id: '2',
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-b9512603-5dc8-43f4-ba14-5f79bb636572',
                name: titleItem,
                description: descriptionItem,
              },
              {
                id: '3',
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-6f7f7bcc-d1e5-4b73-b963-157ab2e454e8',
                name: titleItem,
                description: descriptionItem,
              },
            ] as TextSectionMediaGroup[],
          },
        ],

        variant: variant.id as TextSectionVariants,
        styleName: variant.name,
        designedBy: 'Abrar Muthana',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_15') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Hits Different Investors' }] }]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
            },
          ],
        },
      ]
      const data: Section<TextSectionVariants, TextSectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TEXT',
        updatedAt: '',
        tags: tags[variant.id as TextSectionVariants],

        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
        ],

        variant: variant.id as TextSectionVariants,
        styleName: variant.name,
        designedBy: 'Abrar Muthana',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else {
      const name: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Cheugy Sites Appeal' }] }]
      const description: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. ',
            },
          ],
        },
      ]
      const data: Section<TextSectionVariants, TextSectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TEXT',
        updatedAt: '',
        tags: tags[variant.id as TextSectionVariants],

        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subTitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          { id: uuid(), category: 'TEXT', data: { top: 20, bottom: 10, left: 15, right: 25 }, name: 'MARGIN' },
          { id: uuid(), category: 'TEXT', data: { top: 20, bottom: 10, left: 15, right: 25 }, name: 'PADDING' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-5c7a86e6-c209-4eb9-ac9d-c71a152c45b7',
            name: 'MEDIA',
          },
          {
            id: uuid(),
            category: 'IMAGE',
            name: 'MEDIAGROUP',
            data: [
              {
                id: '1',
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-5c7a86e6-c209-4eb9-ac9d-c71a152c45b7',
                name: name,
                description: description,
              },
              {
                id: '2',
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-5c7a86e6-c209-4eb9-ac9d-c71a152c45b7',
                name: name,
                description: description,
              },
              {
                id: '3',
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-5c7a86e6-c209-4eb9-ac9d-c71a152c45b7',
                name: name,
                description: description,
              },
            ] as TextSectionMediaGroup[],
          },
        ],
        variant: variant.id as TextSectionVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    }
  })

  return sections
}
