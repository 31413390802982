import SectionSlate from '../../../common/SectionSlatePreview'
import { AttentionGrabberSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantEleven({ section }: { section: AttentionGrabberSection }) {
  const title = section.variables.find(variable => variable?.name === 'TITLE')?.data

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: '#6868AC',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="flex justify-center py-4 md:py-12 items-center md:min-h-[100vh] text-center text-white">
        <h2 className="text-[6rem] md:text-[13rem] font-bold text-[#F9f1cc] text-shadow">
          <SectionSlate initialValue={title} previewMode />
        </h2>
      </div>
    </div>
  )
}
