export default function BGColorIcon({ color = 'black' }: { color?: string }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0377 19.1303C12.9641 19.3421 12.7308 19.452 12.5206 19.3739L5.76785 16.864C5.43573 16.7406 5.41623 16.2782 5.73677 16.1272L14.8552 11.8325C15.1757 11.6815 15.5198 11.9911 15.4035 12.3257L13.0377 19.1303Z"
        fill={color}
      />
      <mask id="path-2-inside-1_1111_201" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0928 7.29714C18.863 7.13423 18.5413 7.25354 18.4733 7.5269L18.2054 8.60399C17.9923 8.98498 17.9426 9.45961 18.109 9.90833C18.4055 10.7076 19.2755 11.1221 20.0522 10.834C20.8289 10.5459 21.2183 9.66444 20.9218 8.86513C20.7554 8.41642 20.4082 8.089 19.9983 7.93906L19.0928 7.29714Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0928 7.29714C18.863 7.13423 18.5413 7.25354 18.4733 7.5269L18.2054 8.60399C17.9923 8.98498 17.9426 9.45961 18.109 9.90833C18.4055 10.7076 19.2755 11.1221 20.0522 10.834C20.8289 10.5459 21.2183 9.66444 20.9218 8.86513C20.7554 8.41642 20.4082 8.089 19.9983 7.93906L19.0928 7.29714Z"
        fill={color}
      />
      <path
        d="M18.4733 7.5269L17.9881 7.4062L17.9881 7.4062L18.4733 7.5269ZM19.0928 7.29714L19.382 6.88924L19.382 6.88924L19.0928 7.29714ZM18.2054 8.60399L18.6418 8.84802L18.6744 8.78962L18.6906 8.72468L18.2054 8.60399ZM19.9983 7.93906L19.7091 8.34696L19.7637 8.38565L19.8265 8.40863L19.9983 7.93906ZM18.9585 7.64759C18.9415 7.71593 18.8611 7.74576 18.8036 7.70503L19.382 6.88924C18.8649 6.52269 18.1411 6.79115 17.9881 7.4062L18.9585 7.64759ZM18.6906 8.72468L18.9585 7.64759L17.9881 7.4062L17.7202 8.48329L18.6906 8.72468ZM18.5778 9.73447C18.4635 9.42618 18.4986 9.10412 18.6418 8.84802L17.769 8.35996C17.4861 8.86585 17.4217 9.49303 17.6402 10.0822L18.5778 9.73447ZM19.8783 10.3652C19.3731 10.5526 18.783 10.2876 18.5778 9.73447L17.6402 10.0822C18.028 11.1277 19.1778 11.6916 20.2261 11.3028L19.8783 10.3652ZM20.453 9.039C20.6582 9.59215 20.3836 10.1778 19.8783 10.3652L20.2261 11.3028C21.2743 10.914 21.7784 9.73673 21.3906 8.69126L20.453 9.039ZM19.8265 8.40863C20.1021 8.50942 20.3387 8.73072 20.453 9.039L21.3906 8.69126C21.1721 8.10211 20.7144 7.66857 20.17 7.46948L19.8265 8.40863ZM18.8036 7.70503L19.7091 8.34696L20.2875 7.53116L19.382 6.88924L18.8036 7.70503Z"
        fill="black"
        mask="url(#path-2-inside-1_1111_201)"
      />
      <rect
        x="4.96021"
        y="17.006"
        width="14"
        height="8.74608"
        rx="1.5"
        transform="rotate(-71.0588 4.96021 17.006)"
        stroke="black"
      />
      <line x1="3.96281" y1="6.79247" x2="11.327" y2="9.39403" stroke="black" />
      <rect x="4.86053" y="15.9448" width="12" height="1" transform="rotate(-25.2004 4.86053 15.9448)" fill="#060606" />
    </svg>
  )
}
