import { Template } from '.'
import { contactUs, faq, gallery, image, love, text, quote, portfolio } from './variants'

export const Template06: Template = {
  id: '06',
  pages: [
    {
      slug: '/',
      name: 'Home',
      blocks: [
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_5')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Discover More' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '3a8d6a50-0a91-40b2-954c-b38411f62986',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: 'd3b7c80c-1999-4f73-81df-79bf1ce5c775',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1583454110551-21f2fa2afe61?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw4fHxmaXRuZXNzfGVufDB8fHx8MTY3ODAxOTE5OQ&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '88a259da-b233-47bb-9aac-d5130d61c85f',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      highlightColor: '#160606',
                      fontFamily: 'Oswald',
                      color: '#ffffff',
                      className: 'uppercase',
                      text: '  Fittness and Healthy life  ',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: 'd4cd3d76-7bf8-4b1d-94ef-e079bea4ebaf',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#ffffff',
                  backgroundColor: '#B37733',
                  borderRadius: '0px',
                  color: '#ffffff',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                  font: 'Oswald',
                },
                visible: true,
                link: null,
                value: 'more',
              },
              name: 'BUTTON_1',
              id: '8857472d-0f38-4c9f-9283-79c8d7fb4a6e',
              category: 'BUTTON',
            },
          ],

          variant: 'VARIANT_5',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...quote.find(i => i.variant === 'VARIANT_9')?.settings,
            spacing: {
              padding: { top: 60, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ color: '#B37733', text: 'Yeet Your Design' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'ad2672a4-5e71-451d-8ae3-aedf00903c6d',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1517836357463-d25dfeac3438?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw2fHxmaXRuZXNzfGVufDB8fHx8MTY3ODAxOTE5OQ&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: 'b1902cd5-ca25-4dcb-b1ca-db018546708f',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ fontFamily: 'Oswald', text: 'About Us' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '38e39f6c-3399-440d-952d-4f54fa9cca5a',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      fontFamily: 'Montserrat',
                      text: 'We are a team of experienced entrepreneurs and investors dedicated to acquiring and growing innovative businesses. Our approach is rooted in the latest industry trends and our commitment to long-term success. With our expertise and network, we aim to bring Cheugy-approved businesses to the forefront of the market.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'DESCRIPTION',
              id: '28cf2099-0bb8-4b75-bc03-884bbe1fd9f5',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_9',
          type: 'QUOTE_SECTION',
        },
        {
          settings: {
            ...faq.find(i => i.variant === 'VARIANT_1')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1599058917212-d750089bc07e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxzcG9ydHxlbnwwfHx8fDE2NzgwMjA0OTA&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'We believe that health and wellness are essential components of a happy life',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                  title: [
                    {
                      children: [{ fontFamily: 'Oswald', color: '#000000', text: 'Gucci  Success' }],
                      type: 'paragraph',
                    },
                  ],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1530549387789-4c1017266635?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxMHx8c3BvcnRzfGVufDB8fHx8MTY3ODAyMDUxMA&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'We believe that health and wellness are essential components of a happy life',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                  title: [
                    {
                      children: [{ fontFamily: 'Oswald', color: '#000000', text: 'Gucci Success' }],
                      type: 'paragraph',
                    },
                  ],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1534438327276-14e5300c3a48?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwyfHxneW18ZW58MHx8fHwxNjc4MDE3OTQ1&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'We believe that health and wellness are essential components of a happy life',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                  title: [
                    {
                      children: [{ fontFamily: 'Oswald', color: '#000000', text: 'Gucci Success' }],
                      type: 'paragraph',
                    },
                  ],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1593079831268-3381b0db4a77?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw4fHxneW18ZW58MHx8fHwxNjc4MDE3OTQ1&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'We believe that health and wellness are essential components of a happy life',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '4',
                  title: [
                    {
                      children: [{ fontFamily: 'Oswald', color: '#000000', text: 'Gucci Success' }],
                      type: 'paragraph',
                    },
                  ],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
              ],
              name: 'ITEMS',
              id: 'a70ac0d9-68a1-4954-b5c8-3033f73737fa',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Oswald', color: '#000000', text: 'Hits Different Investors', bold: true }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '92bb21f0-97bf-431f-806f-b0b865b73c8b',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#000000',
                      text: ' we know exactly what it takes to optimize graphic design and deliver an exceptional user experience on any device.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '27cc84ea-cafb-4447-8ae3-01bae00d0669',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ color: '#B37733', text: 'Captivating UX for Investors', bold: true, italic: true }],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'e12f1b60-1261-4755-bc53-c103c531edca',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          type: 'FAQ',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 30, left: 0, bottom: 60, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-dfa6966f-6abb-43e6-9956-681fd92862a9',
                  name: [{ children: [{ fontFamily: 'Oswald', text: 'OMG User Experience' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '1',
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-0394a977-6c2f-4eb5-b344-4897e43830af',
                  name: [{ children: [{ fontFamily: 'Oswald', text: 'OMG User Experience' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '2',
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-413c88c2-d8ca-4093-a084-e8fbaedcc85b',
                  name: [{ children: [{ fontFamily: 'Oswald', text: 'OMG User Experience' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: 'c531b479-f1e0-46dc-b6ce-808b2f7c4b76',
              category: 'IMAGE',
            },
            {
              data: [
                { children: [{ fontFamily: 'Oswald', text: 'Snatched Crowdfunding Classes' }], type: 'paragraph' },
              ],
              name: 'TITLE',
              id: 'b5a4f792-5a3d-4d77-950b-6253a26b2455',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'TEXT',
        },
        {
          settings: {
            ...quote.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              padding: { top: 60, left: 0, bottom: 60, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#f0e8e8', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'DESCRIPTION',
              id: 'a65839f1-2fb0-4d77-9410-2d9913ca80b9',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Dancing Script', color: '#B37733', text: 'Cameron Damon' }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: 'f976a638-4935-4e21-8423-312551900d1e',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-f315a2ba-50d6-4ab3-a4e8-2ba712e06325',
              name: 'MEDIA',
              id: '5b0baaea-9033-4747-84d7-6740ad2732f4',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: ' Our team of experts assesses the issues and implements tailored solutions to bring businesses back to life. With our unique approach, we turn "dead" startups into thriving enterprises with renewed appeal.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'f2d2902d-a9ae-4cd3-8beb-619233044dc2',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'QUOTE_SECTION',
        },
        {
          settings: {
            ...quote.find(i => i.variant === 'VARIANT_9')?.settings,
            spacing: {
              padding: { top: 30, left: 30, bottom: 0, right: 30 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ fontFamily: 'Oswald', text: 'Hits Different Investors' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'e9e82762-ab9f-4eea-8e9c-78891b38486f',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ color: '#B37733', text: 'Cottage Industry, Captivating Design' }], type: 'paragraph' },
              ],
              name: 'SUBTITLE',
              id: 'f2bc873d-d549-4ff2-84b1-eb2baf889f69',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-abc6c308-7b7c-4ea4-818e-c1d447b75ea1',
              name: 'MEDIA',
              id: '7156e0de-48f8-4ffa-9955-e668da234679',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      fontFamily: 'Montserrat',
                      text: 'Looking for pixel-perfect, responsive design that ensures flawless functionality and a ',
                    },
                  ],
                  type: 'paragraph',
                },
                {
                  children: [
                    {
                      fontFamily: 'Montserrat',
                      text: 'seamless user experience? Our team specializes in building custom sites and designing',
                    },
                  ],
                  type: 'paragraph',
                },
                {
                  children: [
                    {
                      fontFamily: 'Montserrat',
                      text: ' portfolios that showcase your creative abilities and professional-looking portfolio, ',
                    },
                  ],
                  type: 'paragraph',
                },
                {
                  children: [
                    {
                      fontFamily: 'Montserrat',
                      text: 'while highlighting your design skills to impress even the most discerning users. ',
                    },
                  ],
                  type: 'paragraph',
                },
                {
                  children: [
                    {
                      fontFamily: 'Montserrat',
                      text: 'With experience showcasing unicorn bloggers and influencers, we know exactly',
                    },
                  ],
                  type: 'paragraph',
                },
                {
                  children: [
                    {
                      fontFamily: 'Montserrat',
                      text: ' what it takes to optimize graphic design and deliver an exceptional user experience ',
                    },
                  ],
                  type: 'paragraph',
                },
                { children: [{ fontFamily: 'Montserrat', text: 'on any device.' }], type: 'paragraph' },
              ],
              name: 'DESCRIPTION',
              id: '774959df-54b1-436b-bf97-cf353f6c95af',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_9',
          type: 'QUOTE_SECTION',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_1')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 30, left: 0, bottom: 30, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  children: [{ fontFamily: 'Oswald', color: '#000000', text: 'Gucci Acquisition Success' }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: 'ae873fc5-2464-4d50-8b58-98677a4ebfb0',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#000000',
                      text: 'Looking for pixel-perfect, responsive design that ensures flawless functionality and a seamless user experience? Our team specializes in building custom sites and designing portfolios that showcase your creative abilities and professional-looking portfolio, while highlighting your design skills to impress even the most discerning users. With experience showcasing unicorn bloggers and influencers, we know exactly what it takes to optimize graphic design and deliver an exceptional user experience on any device.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'aa91c23d-be0a-4f74-b5db-a226d484a796',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ color: '#B37733', text: 'Burn Rate-Busting UX Design', bold: true }],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '82a50d1a-0f87-4c9e-98ec-853076fe3832',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          type: 'TEXT',
        },
        {
          settings: {
            ...portfolio.find(i => i.variant === 'VARIANT_1')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            { data: ' transparent', name: 'BACKGROUND', id: '413d369b-863a-4812-8817-8f721e2e716f', category: 'TEXT' },
            {
              data: { top: 0, left: 0, bottom: 0, right: 0 },
              name: 'MARGIN',
              id: '573e57ca-b706-4d2a-b546-308bef887538',
              category: 'TEXT',
            },
            {
              data: { top: 0, left: 1, bottom: 0, right: 0 },
              name: 'PADDING',
              id: 'f30d2991-ab47-4f44-bf4d-69004d70ba6e',
              category: 'TEXT',
            },
            { data: 0, name: 'TRANSITION', id: '86d2bf9e-19ec-4cdb-934c-6cfce3f654c5', category: 'IMAGE' },
            {
              data: [
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-b33ad34f-2c07-4d51-9b2e-b71d40828925',
                  name: [{ children: [{ fontFamily: 'Anton', text: 'Caleb McConaughey' }], type: 'paragraph' }],
                  id: '850528bf-a9ed-4c29-959c-566ce4ce1bee',
                  title: [{ children: [{ text: 'item name' }], type: 'paragraph' }],
                  descriptions: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-9ccbe469-a843-4f2d-bf35-08e8d78fe194',
                  name: [{ children: [{ fontFamily: 'Anton', text: 'Caleb McConaughey' }], type: 'paragraph' }],
                  id: 'bd82b3a7-ed29-41ec-84fe-26319c087d4e',
                  title: [{ children: [{ text: 'item name' }], type: 'paragraph' }],
                  descriptions: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-5c993fba-c830-4eed-9049-fe0c4b2c428f',
                  name: [{ children: [{ fontFamily: 'Anton', text: 'Caleb McConaughey' }], type: 'paragraph' }],
                  id: 'cae8ed31-d7f3-4a67-88c9-5ae29c987aa1',
                  title: [{ children: [{ text: 'item name' }], type: 'paragraph' }],
                  descriptions: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-bbad7106-2163-41bd-98e2-9944d832abcd',
                  name: [{ children: [{ fontFamily: 'Anton', text: 'Caleb McConaughey' }], type: 'paragraph' }],
                  id: '30130a6b-7df5-4c85-9988-79e9f49b2c34',
                  title: [{ children: [{ text: 'item name' }], type: 'paragraph' }],
                  descriptions: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
              ],
              name: 'ITEMS',
              id: 'bc89c931-5029-4738-9676-29badacdccb7',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          type: 'PORTFOLIO',
        },
      ],
    },
    {
      slug: '/about-us',
      name: 'About',
      blocks: [
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_7')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#f2e7e7', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ color: '#B37733', text: 'YOLO Startup Design', bold: true }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '1942f5c5-aad1-4370-bb53-85578a5a521a',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ fontFamily: 'Oswald', color: '#000000', text: 'About Us' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '69d84dad-2826-43c1-a094-2068af07627b',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#000000',
                      text: 'Looking for pixel-perfect, responsive design that ensures flawless functionality and a seamless user experience? Our team specializes in building custom sites and  designing portfolios that showcase your creative abilities and professional-looking portfolio, while highlighting your design skills to impress even the most discerning users.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'a2ba372a-8142-495d-89f3-0c5597a92746',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_7',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...portfolio.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            { data: ' black', name: 'BACKGROUND', id: '3a614f98-5188-4f53-8545-8ed8e2142493', category: 'TEXT' },
            {
              data: { top: 0, left: 0, bottom: 0, right: 0 },
              name: 'MARGIN',
              id: '1310727d-9796-4392-bb76-40e468403f25',
              category: 'TEXT',
            },
            {
              data: { top: 0, left: 0, bottom: 0, right: 0 },
              name: 'PADDING',
              id: '8ffd143b-e3ab-457c-afbd-ee178d5be0fa',
              category: 'TEXT',
            },
            { data: 0, name: 'TRANSITION', id: 'dae398df-355a-4020-a73d-cde0ec7a1fea', category: 'IMAGE' },
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1580086319619-3ed498161c77?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw3fHxneW1zfGVufDB8fHx8MTY3ODAxNzk1OQ&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ text: 'item name' }], type: 'paragraph' }],
                  id: 'cc05057f-e497-4922-9d3b-0b6ff9f629b0',
                  title: [
                    { children: [{ color: '#000000', text: 'YOLO Startup Design', bold: true }], type: 'paragraph' },
                  ],
                  descriptions: [
                    {
                      children: [{ text: 'The 13 Best Drip Coffee Makers For Home In 2022 Ranked & Reviewed' }],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-4b76341e-3c1f-4137-9369-075855a9b5c4',
                  name: [{ children: [{ text: 'item name' }], type: 'paragraph' }],
                  id: '01859c60-9905-4dbd-8ae6-5eb834eae678',
                  title: [
                    { children: [{ color: '#000000', text: 'YOLO Startup Design', bold: true }], type: 'paragraph' },
                  ],
                  descriptions: [
                    {
                      children: [{ text: 'The 13 Best Drip Coffee Makers For Home In 2022 Ranked & Reviewed' }],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-023af45c-9d02-4ed6-905c-14169a68b88b',
                  name: [{ children: [{ text: 'item name' }], type: 'paragraph' }],
                  id: 'be86ef72-7a6a-4b26-a82c-d6a352ff268f',
                  title: [
                    { children: [{ color: '#000000', text: 'YOLO Startup Design', bold: true }], type: 'paragraph' },
                  ],
                  descriptions: [
                    {
                      children: [{ text: 'The 13 Best Drip Coffee Makers For Home In 2022 Ranked & Reviewed' }],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-66d6162e-1520-4f99-b7c8-2880c3bc3077',
                  name: [{ children: [{ text: 'item name' }], type: 'paragraph' }],
                  id: '4d02f488-9e50-4940-b87b-9585b554b13a',
                  title: [
                    { children: [{ color: '#000000', text: 'YOLO Startup Design', bold: true }], type: 'paragraph' },
                  ],
                  descriptions: [
                    {
                      children: [{ text: 'The 13 Best Drip Coffee Makers For Home In 2022 Ranked & Reviewed' }],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1576678927484-cc907957088c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxneW18ZW58MHx8fHwxNjc4MDE3OTQ1&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ text: 'item name' }], type: 'paragraph' }],
                  id: '71fb4a01-ff18-4770-b6cf-a7798595ab60',
                  title: [
                    { children: [{ color: '#000000', text: 'YOLO Startup Design', bold: true }], type: 'paragraph' },
                  ],
                  descriptions: [
                    {
                      children: [{ text: 'The 13 Best Drip Coffee Makers For Home In 2022 Ranked & Reviewed' }],
                      type: 'paragraph',
                    },
                  ],
                },
              ],
              name: 'ITEMS',
              id: 'a280dd24-bc26-42d6-8f21-97e1e7047b55',
              category: 'IMAGE',
            },
            {
              data: [
                { children: [{ fontFamily: 'Oswald', color: '#B37733', text: 'Dope Burn Rate' }], type: 'paragraph' },
              ],
              name: 'TITLE',
              id: 'a5cf27bb-54b0-4e14-a0b2-ebb5fa309922',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'PORTFOLIO',
        },
      ],
    },
    {
      slug: '/classes',
      name: 'Classes',
      blocks: [
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_13')?.settings,
            color: { background: { value: '#f2ecec', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  children: [
                    { fontFamily: 'Oswald', className: 'experiment-title', text: 'Join To ' },
                    { fontFamily: 'Oswald', color: '#B37733', className: 'experiment-title', text: 'Our' },
                    { fontFamily: 'Oswald', className: 'experiment-title', text: ' Classes' },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: 'c494ff77-616c-4723-ba37-bc5841b22c61',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_13',
          type: 'TEXT',
        },
        {
          settings: {
            ...image.find(i => i.variant === 'VARIANT_13')?.settings,
            spacing: {
              padding: { top: 90, left: 0, bottom: 120, right: 0 },
              margin: { top: 0, left: 0, bottom: 30, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  subtitle: 'Static Site Generator',
                  description: '',
                  id: '2f16c6f5-42cc-422a-97c0-5338572b2389',
                  title: 'React',
                },
                {
                  subtitle: 'CSS Framework',
                  description: '',
                  id: '1f6fcb2a-ddc3-4058-951e-134bd0b1682a',
                  title: 'Tailwind',
                },
                {
                  subtitle: 'Animation',
                  description: '',
                  id: 'b07fa0b0-4fa3-414f-a0ec-3e8b78a34fc2',
                  title: 'Framer Motion',
                },
                {
                  subtitle: 'Headless CMS',
                  description: '',
                  id: 'b613a7aa-9467-4f10-9844-0f402c76de49',
                  title: 'Sanity',
                },
                { subtitle: 'Hosting', description: '', id: 'cf7afb4d-6756-40c3-b96b-9d972ab1994c', title: 'Vercel' },
              ],
              name: 'ITEMS',
              id: 'd3e157bc-dc3c-4992-a25e-125c34d199fe',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: '' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'bb755061-1bc4-4407-8503-8c1f4130f0f3',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1517836357463-d25dfeac3438?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxneW1zfGVufDB8fHx8MTY3ODAxNzk1OQ&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: 'ae004740-8b2f-4a34-84aa-6e93baeeaa93',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: '' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '6b73d038-cc96-4b96-865d-51462b474d51',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_13',
          type: 'IMAGE_OMG',
        },
        {
          settings: {
            ...quote.find(i => i.variant === 'VARIANT_6')?.settings,
          },
          variables: [
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'DESCRIPTION',
              id: 'd1039260-8e53-42ae-9f40-0ee3560de014',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
              name: 'MEDIA',
              id: '343990ac-f0de-4f2d-b904-21cf2e5c40ce',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    { fontFamily: 'Oswald', text: 'OMG ' },
                    { fontFamily: 'Oswald', color: '#B37733', text: 'User' },
                    { fontFamily: 'Oswald', text: ' Experience' },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '204067c5-6815-402c-9db8-27a60088416d',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Looking for pixel-perfect, responsive design that ensures flawless functionality and a seamless user experience? Our team specializes in building custom sites and designing portfolios that showcase your creative abilities and professional-looking portfolio, while highlighting your design skills to impress even the most discerning users. With experience showcasing unicorn bloggers and influencers, we know exactly what it takes to optimize graphic design and deliver an exceptional user experience on any device.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'e64f86dc-03f6-4643-a7c7-2e188be17c81',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'QUOTE_SECTION',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_4')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 30, left: 0, bottom: 60, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: '4173bc1d-149f-4693-a332-2dce684e6807',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'MARGIN',
              id: '011eb15b-2bd5-4937-947d-eaf493ff3dea',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'PADDING',
              id: '72d2a7dc-4612-4cb0-811a-d37f708208d9',
              category: 'TEXT',
            },
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: 'bc64ccf1-322c-42de-9b19-c0ddc44cb1ac',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'Burn Rate-Busting UX Design' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'cf3a498f-4f01-4e1d-b81b-684ef4cca9dc',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-28acfa41-482b-47ed-9682-fdfb64e753df',
              name: 'MEDIA',
              id: '0405ce85-0826-4c66-adb8-61e322179947',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Oswald', color: '#B37733', text: 'Accelerator-Approved UX Design' }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '973524a7-a485-4eb4-a5ad-31bda9908bdd',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#ffffff',
                  backgroundColor: '#B37733',
                  borderRadius: '25px',
                  color: '#ffffff',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'more',
              },
              name: 'BUTTON_1',
              id: '111c3545-e9d8-47ba-b5a2-06d517cf289a',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_4',
          type: 'TEXT',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_4')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: '02496a54-6d8d-4606-8d4c-73831f8baba4',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'MARGIN',
              id: 'a11a0397-11dd-4a56-9507-4f9840f4973d',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'PADDING',
              id: '0f448ca6-6e8f-4023-b8a4-edfe86db1dc5',
              category: 'TEXT',
            },
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: '147ab67d-6a0b-45c4-a03e-2f02bd6a7c32',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'Thrilling Design for Investors' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'dd293713-2160-4c97-aa74-8475b765e061',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-00301506-5c5f-49ef-ba58-38c2ddc0ff88',
              name: 'MEDIA',
              id: '1c509294-d6d8-4ec0-a841-a3a0034f917e',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Oswald', color: '#B37733', text: 'Accelerator-Approved UX Design' }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '6713d15a-1602-402f-a2f5-08dd7050c7a3',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#ffffff',
                  backgroundColor: '#B37733',
                  borderRadius: '25px',
                  color: '#ffffff',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'more',
              },
              name: 'BUTTON_1',
              id: 'c481ed26-6fd3-4cd9-9ef1-77f30aacb57f',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_4',
          type: 'TEXT',
        },
        {
          settings: {
            ...quote.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#fef8f8', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'DESCRIPTION',
              id: 'd5bed19e-b4c8-4b67-9c52-b74f412f1725',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Dancing Script', color: '#B37733', text: 'Carmen Lopez' }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '9b2aa0d9-c793-4359-8ae7-f0bca2d7babc',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-66fd5994-ff28-4072-8f5d-ffdbb109a6ea',
              name: 'MEDIA',
              id: '67a7d412-9238-4046-9c80-4401015b9649',
              category: 'IMAGE',
            },
            {
              data: [
                { children: [{ text: '' }], type: 'paragraph' },
                {
                  children: [
                    {
                      text: ' our web design and UX design services provide the expertise you need to create an intuitive, user-centered experience. We pride ourselves on streamlined user experience, clean design, and modern layouts that include an interactive user interface and easy-to-use interface. ',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '84d0fdab-8979-4a0f-ba72-6523a212ecfe',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'QUOTE_SECTION',
        },
      ],
    },
    {
      slug: '/gallery',
      name: 'Gallery',
      blocks: [
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_12')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 30, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-3679e116-b471-4bf4-9c4c-521f5192e575',
              name: 'MEDIA',
              id: '04219047-0547-4856-9c45-c626a00ef754',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Oswald', color: '#ffffff', className: '', text: 'GALLERY' }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '18f6dba8-e02d-4ba5-91d1-ec3f2a5176f0',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_12',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...quote.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'DESCRIPTION',
              id: '9f7d4ae2-a1ed-4a20-bbb1-41d5a641bf82',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
              name: 'MEDIA',
              id: '5653bda1-5b76-4fe2-96cc-294e0a4e79f1',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    { fontFamily: 'Oswald', text: 'OMG ' },
                    { fontFamily: 'Oswald', color: '#B37733', text: 'User' },
                    { fontFamily: 'Oswald', text: ' Experience' },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '178de434-59af-4e8d-a78e-a423d6677778',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Accelerate Your Startup Growth' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'a2eec542-3b01-48fc-82b4-bd910ea87d9a',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'QUOTE_SECTION',
        },
        {
          settings: {
            ...gallery.find(i => i.variant === 'VARIANT_4')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-6ea017fb-4e3a-41e9-9291-59d01bb8d2ec',
                  description: [
                    {
                      children: [
                        { text: 'we can help you raise the funds you need to take your business to the next level' },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                  title: [{ children: [{ text: 'Crypto Investment Chic' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-e6438669-31b8-4f90-8fff-0f2eb31f7ba2',
                  description: [
                    {
                      children: [
                        { text: 'we can help you raise the funds you need to take your business to the next level' },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                  title: [{ children: [{ text: 'Crypto Investment Chic' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-7fbb7220-f125-46cf-b9f0-4fc87585476d',
                  description: [
                    {
                      children: [
                        { text: 'we can help you raise the funds you need to take your business to the next level' },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                  title: [{ children: [{ text: 'Crypto Investment Chic' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-3c27e48d-e981-4cc2-a764-014adbcf1977',
                  description: [
                    {
                      children: [
                        { text: 'we can help you raise the funds you need to take your business to the next level' },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '4',
                  title: [{ children: [{ text: 'Crypto Investment Chic' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-95175861-71aa-4073-a618-d29483463199',
                  description: [
                    {
                      children: [
                        { text: 'we can help you raise the funds you need to take your business to the next level' },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '5',
                  title: [{ children: [{ text: 'Crypto Investment Chic' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-51c83b38-3511-4c3d-88ee-2500691c5567',
                  description: [
                    {
                      children: [
                        { text: 'we can help you raise the funds you need to take your business to the next level' },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '6',
                  title: [{ children: [{ text: 'Crypto Investment Chic' }], type: 'paragraph' }],
                },
              ],
              name: 'MEDIA',
              id: '130f300e-7921-4909-80c5-dadcf0f0c374',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_4',
          type: 'GALLERY',
        },
      ],
    },
    {
      slug: '/contact-us',
      name: 'Contact Us',
      blocks: [
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_11')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  name: 'First Name',
                  id: 'ffe26ae4-031a-48c1-a4a1-df0e07d5f081',
                  placeholder: 'First Name',
                  type: 'text',
                  error: 'First Name is required',
                },
                {
                  name: 'Last Name',
                  id: 'd448b46e-927a-405f-ba49-853510efb4c8',
                  placeholder: 'Last Name',
                  type: 'text',
                  error: 'Last Name is required',
                },
                {
                  name: 'Email',
                  id: '7d21f614-353f-492e-b5c4-97f69dd31b44',
                  placeholder: 'Email',
                  type: 'email',
                  error: 'email is required',
                },
                {
                  name: 'Number',
                  id: '480609f7-6bd9-428d-a75d-ba62f2b0d496',
                  placeholder: 'Number',
                  type: 'number',
                  error: 'number is required',
                },
              ],
              name: 'INPUTS',
              id: 'f04ba4f8-ac04-4d21-b4bf-36ab1aae1b06',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#B37733',
                      text: 'Our experience showcasing unicorn investors and influencers means that we know exactly what it takes to optimize your website for maximum ROI.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'eb1f5760-26df-4843-aad3-cc44e2a56e2d',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1580086319619-3ed498161c77?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw3fHxneW1zfGVufDB8fHx8MTY3ODAxNzk1OQ&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: 'bf61ec25-8567-4331-a5b8-11460db3ea29',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Oswald', color: '#000000', bold: true, text: 'BOOK A CALL' }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '61c61388-cbed-4a21-942f-76f8c8c93e22',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#ffffff',
                  backgroundColor: '#B37733',
                  borderRadius: '25px',
                  color: '#f9f9f9',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'send',
              },
              name: 'BUTTON_1',
              id: 'ca0e31e0-3733-4184-8d26-bbd8a559fe17',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_11',
          type: 'CONTACT_US',
        },
      ],
    },
  ],
  newMenu: [
    { name: 'Home', type: 'internal', url: '/' },
    { name: 'About', type: 'internal', url: '/about-us' },
    { name: 'Classes', type: 'internal', url: '/classes' },
    { name: 'Gallery', type: 'internal', url: '/gallery' },
    { name: 'Contacts', type: 'internal', url: '/contact-us' },
  ],
  newMenuConfig: {
    settings: { backgroundColor: { color: '#000000', enabled: true }, textColor: { color: '#fff', enabled: true } },
    links: [
      { name: 'Home', type: 'internal', url: '/' },
      { name: 'Contact Us', type: 'email', url: 'hello@example.com' },
    ],
    styleName: 'VARIANT 20',
    id: 'VARIANT_20',
    tags: [],
  },

  newFooter: {
    settings: {
      backgroundColor: { color: '#B37733', enabled: true },
      spacing: {
        padding: { top: 90, left: 0, bottom: 90, right: 0 },
        margin: { top: 0, left: 0, bottom: 0, right: 0 },
        enabled: true,
      },
      textColor: { color: '#ffffff', enabled: true },
    },
    designedBy: 'Plly Staff',
    id: 'VARIANT_3',
    categories: [
      {
        name: 'Explore',
        links: [{ name: 'Home', id: '85f6b065-cd9c-4233-be6f-021f61ea9f4e', type: 'internal', url: '/' }],
      },
    ],
    tags: [],
  },
  links: [],
}
