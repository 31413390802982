import Icon from './icons'
import { IconPropsT } from './types'

function UpIcon(props: IconPropsT) {
  const { title = 'UpIcon', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 22 18" size={size} title={title} {...otherProps}>
      <path d="M1.47372 17.5L11 1L20.5263 17.5H1.47372Z" />
    </Icon>
  )
}

export default UpIcon
