import EmojiPicker, { IEmojiData } from 'emoji-picker-react'
import { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { ModelSortDirection } from 'src/API'
import { UserContext } from 'src/components/context/UserContext'
import ArrowRight from 'src/icons/ArrowRight'
import ImageCreator from 'src/icons/ImageCreator'
import Send from 'src/icons/Send'
import SmileEmoji from 'src/icons/SmileEmoji'
import { allRepliesByCommentID, createReply } from 'src/services/comment.service'
import { renderClickableLinks } from 'src/util'

type CommentReplyProps = {
  hideReplyInput: boolean
  commentID: string
  onReply: () => void
}

const loadMoreCount = 3

export default function CommentReply({ commentID, hideReplyInput, onReply }: CommentReplyProps) {
  const { user } = useContext(UserContext)

  const [replies, setReplies] = useState<any[]>([])
  const [firstToken, setFirstToken] = useState<string | null>()
  const [nextToken, setNextToken] = useState<string | null>()
  const [newLimit, setNewLimit] = useState(loadMoreCount)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [showEmojiIcons, setShowEmojiIcons] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const replyInputRef = useRef<HTMLInputElement>(null)

  const handleAddReply = (e: any) => {
    e.preventDefault()
    const replyInput = replyInputRef.current?.value
    if (replyInputRef.current) replyInputRef.current.value = ''
    if (replyInput) {
      const reply = {
        userID: user.id,
        commentID,
        content: replyInput,
      }
      createReply(reply)
        .then(() =>
          allRepliesByCommentID(commentID, {
            limit: newLimit,
            sortDirection: ModelSortDirection.DESC,
          }),
        )
        .then(res => {
          setReplies(res?.items || [])
          setNextToken(res?.nextToken)
        })
        .catch(() => {
          if (replyInputRef.current) replyInputRef.current.value = replyInput
        })
    }
    onReply()
  }

  const handleLoadMore = async () => {
    const newReplies = await allRepliesByCommentID(commentID, {
      limit: loadMoreCount,
      sortDirection: ModelSortDirection.DESC,
      nextToken,
    })

    setReplies([...replies, ...(newReplies?.items || [])])
    setNextToken(newReplies?.nextToken)
    setNewLimit(newLimit + loadMoreCount)
  }

  const resetRepliesArea = () => {
    if (!replies) return
    if (replies.length <= 0) return
    setNewLimit(loadMoreCount)
    setReplies(replies.slice(0, loadMoreCount))
    setNextToken(firstToken)
  }

  const handleEmojiSelect = (_event: React.MouseEvent<Element, MouseEvent>, data: IEmojiData) => {
    const input = replyInputRef.current
    if (!input) return
    const cursorPosition = input.selectionStart
    if (cursorPosition === null) return
    const textBeforeCursorPosition = input.value.substring(0, cursorPosition)
    const textAfterCursorPosition = input.value.substring(cursorPosition, input.value.length)
    input.value = textBeforeCursorPosition + data.emoji + textAfterCursorPosition
    input.focus()
  }

  useEffect(() => {
    if (hideReplyInput) resetRepliesArea()
  }, [hideReplyInput])

  useEffect(() => {
    if (!commentID) return

    allRepliesByCommentID(commentID, {
      limit: newLimit,
      sortDirection: ModelSortDirection.DESC,
    }).then(res => {
      setReplies(res?.items || [])
      setFirstToken(res?.nextToken)
      setNextToken(res?.nextToken)
    })
  }, [commentID])

  return (
    <div className="ml-10 px-4">
      {!hideReplyInput && (
        <div className="pt-3 pb-5 flex items-center gap-x-5">
          <div>
            <div className="w-[20px] rounded-full bg-[#D9D9D9] md:w-[30px] h-[20px] md:h-[30px]">
              {user?.profilePicture && (
                <Link to={`/profile/${user?.id}`}>
                  <img className="w-full h-full rounded-full" src={user?.profilePicture} alt="" />
                </Link>
              )}
            </div>
          </div>
          <form className="w-full" onSubmit={handleAddReply}>
            <div className="flex border-[#181818] dark:border-white border py-1 rounded">
              <div className="flex items-center pl-3">
                <div className="flex items-center space-x-3">
                  {showEmojiIcons ? (
                    <>
                      <button type="button">
                        <ImageCreator className="text-[#8D8D8D]" />
                      </button>
                      <button type="button" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                        <SmileEmoji size={17} className="text-[#8D8D8D]" />
                      </button>
                    </>
                  ) : (
                    <button onClick={() => setShowEmojiIcons(true)} type="button">
                      <ArrowRight size={18} className="text-[#fff] dark:text-[#000]" />
                    </button>
                  )}
                </div>
              </div>
              <input
                onChange={e => setInputValue(e.target.value)}
                type="text"
                className="block w-full px-4 py-3 text-[15px] text-black dark:text-white bg-white dark:bg-black rounded focus:outline-0"
                placeholder="Add a reply... "
                required
                ref={replyInputRef}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleAddReply(e)
                  }
                }}
              />
              <div className="flex md:hidden items-center pl-3">
                <div className="flex items-center space-x-3 pr-4">
                  <button className="rotate-45" type="button">
                    <Send size={17} className={` ${inputValue === '' && 'text-[#8D8D8D] dark:text-[#9ca3af]'} `} />
                  </button>
                </div>
              </div>
            </div>
            {showEmojiPicker && (
              <div className="mt-3">
                <EmojiPicker onEmojiClick={handleEmojiSelect} pickerStyle={{ width: '100%' }} />
              </div>
            )}
          </form>
        </div>
      )}

      {replies.length > 0 && (
        <div className={`${hideReplyInput ? 'pt-3' : 'pt-0'} pb-5 space-y-5`}>
          {replies.map((reply: any) => (
            <Reply key={reply.id} reply={reply} />
          ))}
        </div>
      )}

      {replies.length > 0 && nextToken && (
        <div className="px-4 mb-3">
          <button onClick={handleLoadMore} type="button" className="dark:text-white font-semibold text-sm px-2 py-1">
            View more comments
          </button>
        </div>
      )}
    </div>
  )
}

function Reply({ reply }: { reply: any }) {
  // const [likesCount, setLikesCount] = useState(0)
  // const [liked, setLiked] = useState(false)

  // const handleLikeClick = async (commentID: string) => {
  //   const { like } = (liked ? await unlikeComment(commentID) : await likeComment(commentID)) || {}
  //   const newLiked = like === LikeStatus.LIKE
  //   setLikesCount(likesCount + (newLiked ? +1 : -1))
  //   setLiked(newLiked)
  // }

  // useEffect(() => {
  //   getCommentLikes(comment.id, { filter: { like: { eq: LikeStatus.LIKE } } }).then(res =>
  //     setLikesCount(res?.items?.length || 0),
  //   )

  //   getUserCommentLike(comment.id).then(res => setLiked(res?.like === LikeStatus.LIKE))
  // }, [reply])

  return (
    <div>
      <div className=" flex items-end gap-x-5">
        <div>
          <div className="w-[20px] rounded-full bg-[#D9D9D9] md:w-[30px] h-[20px] md:h-[30px]">
            {reply?.user?.profilePicture && (
              <Link to={`/profile/${reply?.user?.id}`}>
                <img className="w-full h-full rounded-full" src={reply?.user?.profilePicture} alt="" />
              </Link>
            )}
          </div>
        </div>
        <div className="w-full dark:bg-[#D9D9D9] text-black border border-[#181818] rounded-r-lg rounded-t-lg px-4 py-2">
          <a href={`/profile/${reply?.user?.id}`}>
            <h2 className="text-[15px]">
              {reply?.user?.firstName} {reply?.user?.lastName}
            </h2>
          </a>
          <p className="text-[11px] font-bold">{renderClickableLinks(reply.content)}</p>
        </div>
      </div>
      {/* <div className="flex items-center py-2 ml-12 px-8 gap-x-3">
        <div className="relative flex items-center">
          <button type="button" onClick={() => handleLikeClick(comment.id)}>
            {liked ? (
              <Heart size={17} className="text-brand-accent dark:text-brand-accent" />
            ) : (
              <Heart size={17} className="text-black dark:text-white" />
            )}
            <div
              style={{ top: '-5px', left: '12px' }}
              className="w-[14px] absolute h-[14px] rounded-full flex justify-center items-center bg-brand-accent text-white text-[11px]"
            >
              {likesCount}
            </div>
          </button>
        </div>
        <span>|</span>
        <div className="relative flex items-center">
          <button type="button" onClick={() => setHideReplyInput(!hideReplyInput)}>
            <Chat size={17} />
            <div
              style={{ top: '-5px', left: '12px' }}
              className="w-[14px] absolute h-[14px] rounded-full flex justify-center items-center bg-brand-accent text-white text-[11px]"
            >
              2
            </div>
          </button>
        </div>
      </div> */}
    </div>
  )
}
