import InfiniteScroll from 'react-infinite-scroller'
import { ImageType } from 'src/API'
import useBatchFetch from 'src/hooks/useBatchFetch'
import { getLibraryImages } from 'src/services/images.service'
import { Loader } from '../loader'

type ImageLibraryProps = {
  onImageSelect?: (url: string) => void
}

export default function ImageLibrary({ onImageSelect }: ImageLibraryProps) {
  const { items: images, fetching, nextBatch, hasMore } = useBatchFetch(getLibraryImages as any)

  return (
    <div>
      <Loader show={fetching} />
      {!images.length && !fetching && <p>You have no images in your library.</p>}
      <InfiniteScroll loadMore={nextBatch} hasMore={hasMore} className="grid grid-cols-2 gap-4">
        {images.map(image => (
          <button key={image.id} type="button" onClick={() => onImageSelect?.(image.url)} className="relative">
            <img src={image.url} alt="" className="w-full h-full object-cover object-center rounded-md" />
            {(image.type === ImageType.TEXT_TO_IMAGE || image.type === ImageType.IMAGE_TO_IMAGE) && (
              <div className="text-xs font-medium bg-brand-accent py-1 px-1.5 text-white rounded-full absolute top-2 right-2">
                AI Generated
              </div>
            )}
          </button>
        ))}
      </InfiniteScroll>
    </div>
  )
}
