import Icon from './icons'
import { IconPropsT } from './types'

function Wrench(props: IconPropsT) {
  const { title = 'Wrench', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 23 23" size={size} title={title} {...otherProps}>
      <g clipPath="url(#clip0_951_2821)">
        <path d="M17.6217 18.378L12.7957 10.0191C13.8941 8.52761 14.056 6.47456 13.0484 4.72929C11.9877 2.89218 9.89203 2.02006 7.93984 2.35107L10.2203 6.30087L7.46458 7.89186L5.11172 3.92265C3.77651 5.42838 3.55639 7.69877 4.61705 9.53589C5.62468 11.2811 7.48362 12.1675 9.32455 11.9619L14.1505 20.3208C14.3627 20.6882 14.7973 20.8047 15.1648 20.5926L17.2775 19.3728C17.7173 19.1801 17.8532 18.673 17.6217 18.378Z" />
      </g>
      <defs>
        <clipPath id="clip0_951_2821">
          <rect width="18" height="18" transform="translate(4.86499 0.207031) rotate(15)" />
        </clipPath>
      </defs>
    </Icon>
  )
}

export default Wrench
