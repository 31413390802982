import { Combobox, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { Bars } from 'react-loader-spinner'
import { useLocation, useNavigate } from 'react-router-dom'
import { getAllUsers } from 'src/services/user.service'

export default function ConvoHeaderAvatar(props: any) {
  const { chatmates } = props
  const location = useLocation()

  return (
    <div className="border-b border-black h-24 px-5 md:px-10 flex items-center">
      {chatmates && <ChatMateHeader {...{ chatmates }} />}
      {location.pathname.includes('/messages/new') && <ConvoContactsHeader />}
    </div>
  )
}

function ChatMateHeader(props: any) {
  const { chatmates } = props

  return (
    <div className="flex items-center gap-4">
      {chatmates && (
        <div className="flex items-center space-x-4 md:space-x-6">
          <div className="flex">
            {chatmates?.map((item: any, idx: any) => (
              <span
                key={`avatar-item-${idx}`}
                style={{
                  marginLeft: `${idx === chatmates.length - 1 && '-10px'}`,
                }}
              >
                <img
                  className="w-[45px] h-[45px] md:w-[50px] md:h-[50px] border border-[#D9D9D9] rounded-full"
                  src={item.profilePicture}
                  alt=""
                />
              </span>
            ))}
          </div>
          <div>
            {chatmates?.map((item: any, idx: any) => (
              <span key={`avatar-item-${idx}`} className="font-bold text-xl">
                <a key={item.id} href={`/profile/${item.id}`}>
                  {item.firstName ? `${item.firstName} ${item.lastName}` : item.username}
                </a>
                {idx !== chatmates.length - 1 ? <span>,&nbsp;</span> : ' '}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

function ConvoContactsHeader() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [contacts, setContacts] = useState([] as any[])
  const [query, setQuery] = useState('')

  const filteredContact =
    query === ''
      ? contacts
      : contacts.filter(contact => {
          const name = `${contact.firstName} ${contact.lastName}`.toLowerCase()
          return name.includes(query.toLowerCase())
        })

  useEffect(() => {
    setLoading(true)
    getAllUsers()
      .then(users => {
        setContacts(users.filter(user => user?.firstName))
      })
      .catch(err => console.error('@convo.section::useEffect[contactIds]::error', err))
      .finally(() => setLoading(false))
  }, [])

  return (
    <div className="flex items-center gap-6">
      <svg width="40" height="37" viewBox="0 0 40 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.825 6.7002C13.1501 6.7002 14.4455 7.09314 15.5473 7.82935C16.6491 8.56555 17.5079 9.61195 18.015 10.8362C18.5221 12.0605 18.6548 13.4076 18.3963 14.7073C18.1377 16.007 17.4996 17.2008 16.5626 18.1378C15.6256 19.0748 14.4318 19.7129 13.1321 19.9715C11.8324 20.23 10.4853 20.0973 9.26102 19.5902C8.03676 19.0831 6.99036 18.2243 6.25416 17.1225C5.51795 16.0207 5.125 14.7253 5.125 13.4002C5.125 12.5203 5.2983 11.6491 5.63501 10.8362C5.97172 10.0233 6.46523 9.28473 7.08739 8.66257C7.70954 8.04042 8.44814 7.5469 9.26102 7.2102C10.0739 6.87349 10.9451 6.7002 11.825 6.7002Z"
          fill="black"
        />
        <path
          d="M11.825 21.6851C8.68968 21.6851 5.6827 22.9302 3.46523 25.1468C1.24776 27.3633 0.00132572 30.3697 0 33.5051C0 34.3424 0.332612 35.1453 0.924664 35.7374C1.51672 36.3294 2.31971 36.6621 3.157 36.6621H20.5C21.3373 36.6621 22.1403 36.3294 22.7323 35.7374C23.3244 35.1453 23.657 34.3424 23.657 33.5051C23.6563 31.9522 23.3498 30.4146 22.755 28.9802C22.1601 27.5458 21.2885 26.2426 20.19 25.145C19.0915 24.0474 17.7875 23.1769 16.3526 22.5833C14.9177 21.9896 13.3799 21.6844 11.827 21.6851"
          fill="black"
        />
        <path
          d="M27.7635 13.4C29.0886 13.4 30.384 13.0071 31.4858 12.2708C32.5876 11.5346 33.4464 10.4882 33.9535 9.26398C34.4606 8.03972 34.5933 6.69257 34.3347 5.3929C34.0762 4.09323 33.4381 2.8994 32.5011 1.96239C31.5641 1.02538 30.3703 0.387263 29.0706 0.128742C27.7709 -0.129779 26.4238 0.00290304 25.1995 0.51001C23.9752 1.01712 22.9288 1.87587 22.1926 2.97768C21.4564 4.07949 21.0635 5.37487 21.0635 6.7C21.0635 8.47695 21.7694 10.1811 23.0259 11.4376C24.2824 12.6941 25.9865 13.4 27.7635 13.4Z"
          fill="black"
        />
        <path
          d="M27.7623 14.9782C25.7306 14.9774 23.733 15.5003 21.9623 16.4964C20.1916 17.4926 18.7075 18.9284 17.6533 20.6652C19.6483 21.3845 21.4136 22.6264 22.7646 24.2611C24.1155 25.8958 25.0027 27.8634 25.3333 29.9582H36.4333C37.2706 29.9582 38.0736 29.6256 38.6656 29.0335C39.2577 28.4415 39.5903 27.6385 39.5903 26.8012C39.5909 25.248 39.2854 23.7098 38.6913 22.2747C38.0973 20.8396 37.2264 19.5355 36.1283 18.437C35.0302 17.3386 33.7264 16.4672 32.2915 15.8727C30.8565 15.2782 29.3185 14.9722 27.7653 14.9722"
          fill="black"
        />
      </svg>
      <Combobox onChange={(contact: any) => navigate(`/messages/${contact.id}`)}>
        <div className="relative mt-1">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              className="w-full border-none md:py-4 py-2 pl-4 pr-10 text-sm focus:ring-0 min-w-[250px] md:min-w-[300px]"
              onChange={event => setQuery(event.target.value)}
              placeholder="Search contact"
            />
            <Combobox.Button className="absolute inset-y-0 right-2 flex items-center pr-2">
              <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.12891 1.13379L8.25195 8.25073L15.3633 1.13379"
                  stroke="#313131"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Combobox.Button>
            {loading && (
              <div className="absolute top-0 left-0 w-full h-full bg-transparent flex justify-center items-center">
                <Bars height="25" width="25" color="#000" ariaLabel="bars-loading" wrapperStyle={{}} wrapperClass="" />
              </div>
            )}
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute mt-1 list-none max-h-60 w-full overflow-auto rounded-md bg-white py-1 px-0 text-base shadow-lg focus:outline-none sm:text-sm">
              {filteredContact.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">Nothing found.</div>
              ) : (
                filteredContact.map(contact => (
                  <Combobox.Option
                    key={contact.id}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 px-4 ${
                        active ? 'bg-brand-accent text-white' : 'text-gray-900'
                      }`
                    }
                    value={contact}
                  >
                    {({ selected }) => (
                      <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                        {contact.firstName} {contact.lastName}
                      </span>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}
