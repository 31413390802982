/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUserProfile = /* GraphQL */ `
  subscription OnCreateUserProfile {
    onCreateUserProfile {
      id
      firstName
      lastName
      email
      profilePics {
        bucket
        region
        key
      }
      username
      profilePicture
      country
      state
      city
      bio
      config
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserProfile = /* GraphQL */ `
  subscription OnUpdateUserProfile {
    onUpdateUserProfile {
      id
      firstName
      lastName
      email
      profilePics {
        bucket
        region
        key
      }
      username
      profilePicture
      country
      state
      city
      bio
      config
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserProfile = /* GraphQL */ `
  subscription OnDeleteUserProfile {
    onDeleteUserProfile {
      id
      firstName
      lastName
      email
      profilePics {
        bucket
        region
        key
      }
      username
      profilePicture
      country
      state
      city
      bio
      config
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWebsite = /* GraphQL */ `
  subscription OnCreateWebsite {
    onCreateWebsite {
      id
      name
      config
      userID
      createdAt
      menu
      footer
      pages
      links
      newMenu
      newMenuConfig
      newFooter
      isPostedOnSocial
      cloudfrontDistributionID
      cloudfrontDomainName
      cloudfrontAlias
      gaTrackingID
      updatedAt
    }
  }
`;
export const onUpdateWebsite = /* GraphQL */ `
  subscription OnUpdateWebsite {
    onUpdateWebsite {
      id
      name
      config
      userID
      createdAt
      menu
      footer
      pages
      links
      newMenu
      newMenuConfig
      newFooter
      isPostedOnSocial
      cloudfrontDistributionID
      cloudfrontDomainName
      cloudfrontAlias
      gaTrackingID
      updatedAt
    }
  }
`;
export const onDeleteWebsite = /* GraphQL */ `
  subscription OnDeleteWebsite {
    onDeleteWebsite {
      id
      name
      config
      userID
      createdAt
      menu
      footer
      pages
      links
      newMenu
      newMenuConfig
      newFooter
      isPostedOnSocial
      cloudfrontDistributionID
      cloudfrontDomainName
      cloudfrontAlias
      gaTrackingID
      updatedAt
    }
  }
`;
export const onCreateBuildRequest = /* GraphQL */ `
  subscription OnCreateBuildRequest($userID: String) {
    onCreateBuildRequest(userID: $userID) {
      id
      status
      websiteID
      createdAt
      userID
      cloudfrontInvalidationID
      updatedAt
    }
  }
`;
export const onUpdateBuildRequest = /* GraphQL */ `
  subscription OnUpdateBuildRequest($userID: String) {
    onUpdateBuildRequest(userID: $userID) {
      id
      status
      websiteID
      createdAt
      userID
      cloudfrontInvalidationID
      updatedAt
    }
  }
`;
export const onDeleteBuildRequest = /* GraphQL */ `
  subscription OnDeleteBuildRequest($userID: String) {
    onDeleteBuildRequest(userID: $userID) {
      id
      status
      websiteID
      createdAt
      userID
      cloudfrontInvalidationID
      updatedAt
    }
  }
`;
export const onCreatePage = /* GraphQL */ `
  subscription OnCreatePage {
    onCreatePage {
      id
      name
      path
      websiteID
      blocks
      createdAt
      userID
      updatedAt
    }
  }
`;
export const onUpdatePage = /* GraphQL */ `
  subscription OnUpdatePage {
    onUpdatePage {
      id
      name
      path
      websiteID
      blocks
      createdAt
      userID
      updatedAt
    }
  }
`;
export const onDeletePage = /* GraphQL */ `
  subscription OnDeletePage {
    onDeletePage {
      id
      name
      path
      websiteID
      blocks
      createdAt
      userID
      updatedAt
    }
  }
`;
export const onCreateBlock = /* GraphQL */ `
  subscription OnCreateBlock {
    onCreateBlock {
      id
      type
      props
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateBlock = /* GraphQL */ `
  subscription OnUpdateBlock {
    onUpdateBlock {
      id
      type
      props
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteBlock = /* GraphQL */ `
  subscription OnDeleteBlock {
    onDeleteBlock {
      id
      type
      props
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateMintTransaction = /* GraphQL */ `
  subscription OnCreateMintTransaction($userID: String) {
    onCreateMintTransaction(userID: $userID) {
      id
      transactionHash
      from
      userID
      blockID
      createdAt
      updatedAt
      block {
        id
        type
        props
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const onUpdateMintTransaction = /* GraphQL */ `
  subscription OnUpdateMintTransaction($userID: String) {
    onUpdateMintTransaction(userID: $userID) {
      id
      transactionHash
      from
      userID
      blockID
      createdAt
      updatedAt
      block {
        id
        type
        props
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const onDeleteMintTransaction = /* GraphQL */ `
  subscription OnDeleteMintTransaction($userID: String) {
    onDeleteMintTransaction(userID: $userID) {
      id
      transactionHash
      from
      userID
      blockID
      createdAt
      updatedAt
      block {
        id
        type
        props
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const onCreatePost = /* GraphQL */ `
  subscription OnCreatePost {
    onCreatePost {
      id
      title
      description
      websiteID
      userID
      type
      images
      createdAt
      prompt
      status
      pageID
      componentID
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      page {
        id
        name
        path
        websiteID
        blocks
        createdAt
        userID
        updatedAt
      }
      owner
    }
  }
`;
export const onUpdatePost = /* GraphQL */ `
  subscription OnUpdatePost {
    onUpdatePost {
      id
      title
      description
      websiteID
      userID
      type
      images
      createdAt
      prompt
      status
      pageID
      componentID
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      page {
        id
        name
        path
        websiteID
        blocks
        createdAt
        userID
        updatedAt
      }
      owner
    }
  }
`;
export const onDeletePost = /* GraphQL */ `
  subscription OnDeletePost {
    onDeletePost {
      id
      title
      description
      websiteID
      userID
      type
      images
      createdAt
      prompt
      status
      pageID
      componentID
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      page {
        id
        name
        path
        websiteID
        blocks
        createdAt
        userID
        updatedAt
      }
      owner
    }
  }
`;
export const onCreatePostLike = /* GraphQL */ `
  subscription OnCreatePostLike {
    onCreatePostLike {
      id
      userID
      postID
      like
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      post {
        id
        title
        description
        websiteID
        userID
        type
        images
        createdAt
        prompt
        status
        pageID
        componentID
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        page {
          id
          name
          path
          websiteID
          blocks
          createdAt
          userID
          updatedAt
        }
        owner
      }
      owner
    }
  }
`;
export const onUpdatePostLike = /* GraphQL */ `
  subscription OnUpdatePostLike {
    onUpdatePostLike {
      id
      userID
      postID
      like
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      post {
        id
        title
        description
        websiteID
        userID
        type
        images
        createdAt
        prompt
        status
        pageID
        componentID
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        page {
          id
          name
          path
          websiteID
          blocks
          createdAt
          userID
          updatedAt
        }
        owner
      }
      owner
    }
  }
`;
export const onDeletePostLike = /* GraphQL */ `
  subscription OnDeletePostLike {
    onDeletePostLike {
      id
      userID
      postID
      like
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      post {
        id
        title
        description
        websiteID
        userID
        type
        images
        createdAt
        prompt
        status
        pageID
        componentID
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        page {
          id
          name
          path
          websiteID
          blocks
          createdAt
          userID
          updatedAt
        }
        owner
      }
      owner
    }
  }
`;
export const onCreatePostComment = /* GraphQL */ `
  subscription OnCreatePostComment {
    onCreatePostComment {
      id
      userID
      postID
      content
      createdAt
      status
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      post {
        id
        title
        description
        websiteID
        userID
        type
        images
        createdAt
        prompt
        status
        pageID
        componentID
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        page {
          id
          name
          path
          websiteID
          blocks
          createdAt
          userID
          updatedAt
        }
        owner
      }
      owner
    }
  }
`;
export const onUpdatePostComment = /* GraphQL */ `
  subscription OnUpdatePostComment {
    onUpdatePostComment {
      id
      userID
      postID
      content
      createdAt
      status
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      post {
        id
        title
        description
        websiteID
        userID
        type
        images
        createdAt
        prompt
        status
        pageID
        componentID
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        page {
          id
          name
          path
          websiteID
          blocks
          createdAt
          userID
          updatedAt
        }
        owner
      }
      owner
    }
  }
`;
export const onDeletePostComment = /* GraphQL */ `
  subscription OnDeletePostComment {
    onDeletePostComment {
      id
      userID
      postID
      content
      createdAt
      status
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      post {
        id
        title
        description
        websiteID
        userID
        type
        images
        createdAt
        prompt
        status
        pageID
        componentID
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        page {
          id
          name
          path
          websiteID
          blocks
          createdAt
          userID
          updatedAt
        }
        owner
      }
      owner
    }
  }
`;
export const onCreateCommentLike = /* GraphQL */ `
  subscription OnCreateCommentLike {
    onCreateCommentLike {
      id
      userID
      commentID
      like
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      comment {
        id
        userID
        postID
        content
        createdAt
        status
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        post {
          id
          title
          description
          websiteID
          userID
          type
          images
          createdAt
          prompt
          status
          pageID
          componentID
          updatedAt
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const onUpdateCommentLike = /* GraphQL */ `
  subscription OnUpdateCommentLike {
    onUpdateCommentLike {
      id
      userID
      commentID
      like
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      comment {
        id
        userID
        postID
        content
        createdAt
        status
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        post {
          id
          title
          description
          websiteID
          userID
          type
          images
          createdAt
          prompt
          status
          pageID
          componentID
          updatedAt
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const onDeleteCommentLike = /* GraphQL */ `
  subscription OnDeleteCommentLike {
    onDeleteCommentLike {
      id
      userID
      commentID
      like
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      comment {
        id
        userID
        postID
        content
        createdAt
        status
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        post {
          id
          title
          description
          websiteID
          userID
          type
          images
          createdAt
          prompt
          status
          pageID
          componentID
          updatedAt
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const onCreateCommentReply = /* GraphQL */ `
  subscription OnCreateCommentReply {
    onCreateCommentReply {
      id
      userID
      commentID
      content
      createdAt
      status
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      comment {
        id
        userID
        postID
        content
        createdAt
        status
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        post {
          id
          title
          description
          websiteID
          userID
          type
          images
          createdAt
          prompt
          status
          pageID
          componentID
          updatedAt
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const onUpdateCommentReply = /* GraphQL */ `
  subscription OnUpdateCommentReply {
    onUpdateCommentReply {
      id
      userID
      commentID
      content
      createdAt
      status
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      comment {
        id
        userID
        postID
        content
        createdAt
        status
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        post {
          id
          title
          description
          websiteID
          userID
          type
          images
          createdAt
          prompt
          status
          pageID
          componentID
          updatedAt
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const onDeleteCommentReply = /* GraphQL */ `
  subscription OnDeleteCommentReply {
    onDeleteCommentReply {
      id
      userID
      commentID
      content
      createdAt
      status
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      comment {
        id
        userID
        postID
        content
        createdAt
        status
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        post {
          id
          title
          description
          websiteID
          userID
          type
          images
          createdAt
          prompt
          status
          pageID
          componentID
          updatedAt
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const onCreateConversation = /* GraphQL */ `
  subscription OnCreateConversation {
    onCreateConversation {
      id
      convID
      userID
      status
      lastActiveTime
      lastMessageID
      createdAt
      chatmateID
      chatmate {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      lastMessage {
        id
        senderID
        conversationID
        content
        type
        createdAt
        read
        deleted
        updatedAt
      }
    }
  }
`;
export const onUpdateConversation = /* GraphQL */ `
  subscription OnUpdateConversation {
    onUpdateConversation {
      id
      convID
      userID
      status
      lastActiveTime
      lastMessageID
      createdAt
      chatmateID
      chatmate {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      lastMessage {
        id
        senderID
        conversationID
        content
        type
        createdAt
        read
        deleted
        updatedAt
      }
    }
  }
`;
export const onDeleteConversation = /* GraphQL */ `
  subscription OnDeleteConversation {
    onDeleteConversation {
      id
      convID
      userID
      status
      lastActiveTime
      lastMessageID
      createdAt
      chatmateID
      chatmate {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      lastMessage {
        id
        senderID
        conversationID
        content
        type
        createdAt
        read
        deleted
        updatedAt
      }
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      senderID
      conversationID
      content
      type
      createdAt
      read
      deleted
      updatedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      senderID
      conversationID
      content
      type
      createdAt
      read
      deleted
      updatedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      senderID
      conversationID
      content
      type
      createdAt
      read
      deleted
      updatedAt
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      owner
      toNotifyID
      topic
      topicUrl
      topicDescription
      topicID
      status
      createdAt
      updatedAt
      creator {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      toNotify {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      owner
      toNotifyID
      topic
      topicUrl
      topicDescription
      topicID
      status
      createdAt
      updatedAt
      creator {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      toNotify {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      owner
      toNotifyID
      topic
      topicUrl
      topicDescription
      topicID
      status
      createdAt
      updatedAt
      creator {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      toNotify {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateImage = /* GraphQL */ `
  subscription OnCreateImage {
    onCreateImage {
      id
      userID
      title
      prompt
      type
      url
      status
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
export const onUpdateImage = /* GraphQL */ `
  subscription OnUpdateImage {
    onUpdateImage {
      id
      userID
      title
      prompt
      type
      url
      status
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
export const onDeleteImage = /* GraphQL */ `
  subscription OnDeleteImage {
    onDeleteImage {
      id
      userID
      title
      prompt
      type
      url
      status
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
