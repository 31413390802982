import { Template } from '.'
import { contactUs, faq, image, love, text, quote, team, video, menu } from './variants'

export const Template09: Template = {
  id: '09',
  pages: [
    {
      slug: '/',
      name: 'Home',
      blocks: [
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_4')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: 'https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxyZXN0YXVyYW50fGVufDB8fHx8MTY3ODg5MjM4OQ&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '600ea472-0570-4e40-ba12-445f20f5c4cb',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ color: '#d02b2b', text: 'THRestaurant' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '5b07f13e-498f-44ed-bef0-1a222fb953ab',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Josefin Sans', bold: true, text: 'Snatched Crowdfunding UX' }],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'cd8daf48-7129-4958-8407-5b374f2874e5',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: '' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: '36c78345-bfa8-49a4-b6ad-9ff23e404a21',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_4',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...faq.find(i => i.variant === 'VARIANT_1')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1493770348161-369560ae357d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwyfHxicmVha2Zhc3R8ZW58MHx8fHwxNjc4MTAxNzMy&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          fontFamily: 'Josefin Sans',
                          color: '#ffffff',
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                  title: [
                    {
                      children: [{ fontFamily: 'Josefin Sans', color: '#ffffff', text: 'Breakfast' }],
                      type: 'paragraph',
                    },
                  ],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1594834749740-74b3f6764be4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxsdW5jaHxlbnwwfHx8fDE2NzgxMDE3NzA&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          fontFamily: 'Josefin Sans',
                          color: '#ffffff',
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                  title: [
                    { children: [{ fontFamily: 'Josefin Sans', color: '#ffffff', text: 'Lunch' }], type: 'paragraph' },
                  ],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1535473895227-bdecb20fb157?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxkaW5uZXJ8ZW58MHx8fHwxNjc4MTAxODAx&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          fontFamily: 'Josefin Sans',
                          color: '#ffffff',
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                  title: [
                    { children: [{ fontFamily: 'Josefin Sans', color: '#ffffff', text: 'Dinner' }], type: 'paragraph' },
                  ],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1551024709-8f23befc6f87?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxkcmlua3xlbnwwfHx8fDE2NzgxMDE4MjQ&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          fontFamily: 'Josefin Sans',
                          color: '#ffffff',
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '4',
                  title: [
                    { children: [{ fontFamily: 'Josefin Sans', color: '#ffffff', text: 'Juices' }], type: 'paragraph' },
                  ],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
              ],
              name: 'ITEMS',
              id: 'c7299e27-15da-453c-a2b1-d929723b4d97',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    { fontFamily: 'Josefin Sans', color: '#ffffff', text: 'Snatched Crowdfunding UX', bold: true },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '5a74f168-1926-4330-92ab-19393061f949',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ color: '#ffffff', text: 'Cottage Industry, Captivating Design' }], type: 'paragraph' },
              ],
              name: 'SUBTITLE',
              id: '09f17c60-ce88-4f98-8353-85b7050911da',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      fontFamily: 'Josefin Sans',
                      color: '#ffffff',
                      text: 'For B2B startups and those building their first site, our alluring web and UX design services provide the invigorating expertise you need to create an intuitive, user-centered experience. ',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '5e0ccf1a-2d7d-4c4f-9bbd-fea1721711c1',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          type: 'FAQ',
        },
        {
          settings: {
            ...image.find(i => i.variant === 'VARIANT_5')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  subtitle: 'Static Site Generator',
                  description: '',
                  id: 'e5cbd7dd-4496-4711-8201-96cc24db8bbb',
                  title: 'React',
                },
                {
                  subtitle: 'CSS Framework',
                  description: '',
                  id: 'c50a056f-aa22-4c97-8eef-fe0813529fb7',
                  title: 'Tailwind',
                },
                {
                  subtitle: 'Animation',
                  description: '',
                  id: '9a0ec235-0562-4427-be9d-b4436a45ac74',
                  title: 'Framer Motion',
                },
                {
                  subtitle: 'Headless CMS',
                  description: '',
                  id: 'e39992a4-d3ef-4392-bb30-ead6876360f8',
                  title: 'Sanity',
                },
                { subtitle: 'Hosting', description: '', id: 'ac334aec-a1cc-4014-943f-fd6bf824987e', title: 'Vercel' },
              ],
              name: 'ITEMS',
              id: '5f2036d4-8bac-4403-a016-4760fe8999cb',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1482049016688-2d3e1b311543?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxmb29kfGVufDB8fHx8MTY3ODA5Nzk3MQ&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: 'abec1453-0816-42c5-b72d-bb17738ebaca',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Josefin Sans', text: 'Hits Different Investors' }],
                  type: 'paragraph',
                  align: 'center',
                },
              ],
              name: 'TITLE',
              id: '9ddeb7c5-10c5-4b33-a20d-b550ce449ac3',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      fontFamily: 'Josefin Sans',
                      text: 'At our agency, we prioritize user satisfaction, user retention, and user conversion. We offer website performance, website speed, website accessibility, website security, website maintenance, website optimization, website analytics, website testing, website updates, website improvements, and website enhancements to ensure your site is top-notch. With our high-octane mobile-friendly design and invigorating user-friendly interface, we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                    },
                  ],
                  type: 'paragraph',
                  align: 'center',
                },
              ],
              name: 'SUBTITLE',
              id: '1f8606db-b3b4-4c62-b11c-53fa35564799',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_5',
          type: 'IMAGE_OMG',
        },
        {
          settings: {
            ...faq.find(i => i.variant === 'VARIANT_1')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1540189549336-e6e99c3679fe?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxmb29kfGVufDB8fHx8MTY3ODA5Nzk3MQ&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '1',
                  title: [
                    {
                      children: [{ fontFamily: 'Josefin Sans', color: '#ffffff', text: 'Accelerate ', bold: true }],
                      type: 'paragraph',
                    },
                  ],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1555939594-58d7cb561ad1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwyfHxmb29kfGVufDB8fHx8MTY3ODA5Nzk3MQ&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '2',
                  title: [
                    {
                      children: [{ fontFamily: 'Josefin Sans', color: '#ffffff', text: 'Accelerate', bold: true }],
                      type: 'paragraph',
                    },
                  ],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1512621776951-a57141f2eefd?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxMHx8Zm9vZHxlbnwwfHx8fDE2NzgwOTc5NzE&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '3',
                  title: [
                    {
                      children: [{ fontFamily: 'Josefin Sans', color: '#ffffff', text: 'Accelerate ', bold: true }],
                      type: 'paragraph',
                    },
                  ],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1546069901-ba9599a7e63c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxmb29kfGVufDB8fHx8MTY3ODA5Nzk3MQ&ixlib=rb-4.0.3&q=80&w=1080',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '4',
                  title: [
                    {
                      children: [{ fontFamily: 'Josefin Sans', color: '#ffffff', text: 'Accelerate ', bold: true }],
                      type: 'paragraph',
                    },
                  ],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
              ],
              name: 'ITEMS',
              id: '181f0492-11c5-4cca-a24d-9eb877676135',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Josefin Sans', color: '#ffffff', text: 'YOLO Startup Design' }],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'd91dc6d5-1770-4f66-a9df-17f6f5287ad1',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Josefin Sans', color: '#ffffff', text: 'OMG User Experience', bold: true }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '11227a3b-c450-4eec-9d19-4ba4025f54d6',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      fontFamily: 'Josefin Sans',
                      color: '#ffffff',
                      text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'c3912165-1d0f-411d-bdc5-ead70e07e542',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          type: 'FAQ',
        },
        {
          settings: {
            ...quote.find(i => i.variant === 'VARIANT_3')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'DESCRIPTION',
              id: '76aea5c0-3048-4f77-90b8-6321c41d7865',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1554050857-c84a8abdb5e2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxibGFja3xlbnwwfHx8fDE2NzgxMzY4ODY&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: 'ebdeb6c2-5075-4cad-891a-8ede3fc5348f',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Josefin Sans', color: '#fffbfb', text: 'Snatched Crowdfunding UX' }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: 'c1e0b869-4b67-47fb-b462-9b4a743e1bfc',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      fontFamily: 'Josefin Sans',
                      color: '#ffffff',
                      text: "Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience? Look no further than our custom site and portfolio design services. Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. Whether you're a digital nomad or a disruptive technology startup, we have the expertise to optimize your website for exceptional user experience on any device.",
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '5e5eecca-de1f-455c-9dc8-d82fd0fa92e2',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_3',
          type: 'QUOTE_SECTION',
        },
        {
          settings: {
            ...video.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
              name: 'MEDIA',
              id: '3b7a6e77-abbf-47c7-85d4-657533fccd9a',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1615921511258-0aa98c84d400?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTY1fHxsb2dvfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
                  subtitle1: [{ children: [{ text: 'Tech wid, Bd' }], type: 'paragraph' }],
                  title1: [{ children: [{ text: 'Testimonial' }], type: 'paragraph' }],
                  id: '91cc9556-357c-4d1d-a791-2352523569b2',
                  description1: [
                    {
                      children: [
                        {
                          text: 'Pastry shortbread muffin sesame snaps icing marzipan. Brownie sesame snaps candy canes chocolate cake donut bonbon powder cotton candy. Jelly sesame snaps gummies pudding cotton candy. Sesame snaps danish muffin sweet roll cheesecake cake wafer pastry. Icing fruitcake cookie cupcake cake muffin cheesecake bonbon pie. Wafer marshmallow gummi bears wafer halvah. Croissant jelly-o croissant gummi bears cake. Gingerbread icing dessert toffee lemon drops sweet roll jelly beans.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1554730501-8dd4db2b18cc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nzd8fGxvZ298ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
                  subtitle1: [{ children: [{ text: 'Tech wid, Bd' }], type: 'paragraph' }],
                  title1: [{ children: [{ text: 'Testimonial' }], type: 'paragraph' }],
                  id: '5dd056e9-3439-4bd9-a5d6-af21e329cbde',
                  description1: [
                    {
                      children: [
                        {
                          text: 'Pastry shortbread muffin sesame snaps icing marzipan. Brownie sesame snaps candy canes chocolate cake donut bonbon powder cotton candy. Jelly sesame snaps gummies pudding cotton candy. Sesame snaps danish muffin sweet roll cheesecake cake wafer pastry. Icing fruitcake cookie cupcake cake muffin cheesecake bonbon pie. Wafer marshmallow gummi bears wafer halvah. Croissant jelly-o croissant gummi bears cake. Gingerbread icing dessert toffee lemon drops sweet roll jelly beans.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1579509330413-8a7e4addc442?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OTZ8fGxvZ298ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
                  subtitle1: [{ children: [{ text: 'Tech wid, Bd' }], type: 'paragraph' }],
                  title1: [{ children: [{ text: 'Testimonial' }], type: 'paragraph' }],
                  id: '88942946-80dd-493a-836b-5346ed2a7da1',
                  description1: [
                    {
                      children: [
                        {
                          text: 'Pastry shortbread muffin sesame snaps icing marzipan. Brownie sesame snaps candy canes chocolate cake donut bonbon powder cotton candy. Jelly sesame snaps gummies pudding cotton candy. Sesame snaps danish muffin sweet roll cheesecake cake wafer pastry. Icing fruitcake cookie cupcake cake muffin cheesecake bonbon pie. Wafer marshmallow gummi bears wafer halvah. Croissant jelly-o croissant gummi bears cake. Gingerbread icing dessert toffee lemon drops sweet roll jelly beans.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
              ],
              name: 'ITEMS',
              id: '95e13f68-00d4-41bb-955d-ee4438334cf8',
              category: 'TEXT',
            },
            { data: 'wLQFtcqSL0k', name: 'VIDEO', id: '1ecdbf21-11ec-4545-bad1-358ce7740bd5', category: 'VIDEO' },
            {
              data: [
                {
                  children: [{ fontFamily: 'Josefin Sans', text: 'Accelerate Appeal' }],
                  type: 'paragraph',
                  align: 'center',
                },
              ],
              name: 'TITLE',
              id: 'e54022fd-9c83-423a-aa49-ec26038af534',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      fontFamily: 'Josefin Sans',
                      text: 'Our team specializes in building custom sites and designing portfolios that showcase your creative abilities and professional-looking portfolio, while highlighting your design skills to impress even the most discerning users. ',
                    },
                  ],
                  type: 'paragraph',
                  align: 'center',
                },
              ],
              name: 'SUBTITLE',
              id: '1e204183-8d55-4c0a-81ea-be5300cffbbc',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'TESTIMONIAL',
        },
        {
          settings: {
            ...quote.find(i => i.variant === 'VARIANT_3')?.settings,
          },
          variables: [
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'DESCRIPTION',
              id: '42a221a1-c80c-41d4-95fa-ebccb8c2d678',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1554050857-c84a8abdb5e2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxibGFja3xlbnwwfHx8fDE2NzgxMzY4ODY&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: 'fc874ec6-428f-4102-8515-15c79ce27a8f',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      fontFamily: 'Josefin Sans',
                      color: '#ffffff',
                      text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'b55120e1-1281-4888-9a80-0b416ae3acc6',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Dancing Script', color: '#ec0606', text: 'Cassidy Cruise' }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: 'a6c0eb07-8524-43de-ad20-aabd6320ef58',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_3',
          type: 'QUOTE_SECTION',
        },
        {
          settings: {
            ...team.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1654922207993-2952fec328ae?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxjaGVmfGVufDB8fHx8MTY3ODEzOTkwNg&ixlib=rb-4.0.3&q=80&w=1080',
                  subtitle1: [{ children: [{ text: 'Position/Role' }], type: 'paragraph' }],
                  subtitle: [{ children: [{ text: '' }], type: 'paragraph' }],
                  name: [{ children: [{ color: '#ffffff', text: 'Charles McConaughey' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '1',
                  name1: [{ children: [{ text: 'Taylor Quill' }], type: 'paragraph' }],
                  description1: [{ children: [{ text: '#developer #expert #designer' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1577219491135-ce391730fb2c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxjaGVmfGVufDB8fHx8MTY3ODEzOTkwNg&ixlib=rb-4.0.3&q=80&w=1080',
                  subtitle1: [{ children: [{ text: 'Position/Role' }], type: 'paragraph' }],
                  subtitle: [{ children: [{ text: '' }], type: 'paragraph' }],
                  name: [{ children: [{ color: '#ffffff', text: 'Charles McConaughey' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '2',
                  name1: [{ children: [{ text: 'Taylor Quill' }], type: 'paragraph' }],
                  description1: [{ children: [{ text: '#developer #expert #designer' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1581299894007-aaa50297cf16?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxjaGVmfGVufDB8fHx8MTY3ODEzOTkwNg&ixlib=rb-4.0.3&q=80&w=1080',
                  subtitle1: [{ children: [{ text: 'Position/Role' }], type: 'paragraph' }],
                  subtitle: [{ children: [{ text: '' }], type: 'paragraph' }],
                  name: [{ children: [{ color: '#ffffff', text: 'Charles McConaughey' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '3',
                  name1: [{ children: [{ text: 'Taylor Quill' }], type: 'paragraph' }],
                  description1: [{ children: [{ text: '#developer #expert #designer' }], type: 'paragraph' }],
                },
              ],
              name: 'TEAM_MEMBERS',
              id: 'b70daffd-d831-4ddb-88d7-958a41865986',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#ffffff', text: 'Meet our chefs', bold: true }], type: 'paragraph' }],
              name: 'TITLE',
              id: '64cf2e41-067c-45a4-8a10-12bf2318fb7a',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ color: '#ffffff', text: 'UX Design for Crowdfunding Success' }], type: 'paragraph' },
              ],
              name: 'SUBTITLE',
              id: '8aa41359-b7eb-44af-aefa-debac99010d0',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#ffffff',
                      text: 'we have the expertise to optimize your website for exceptional user experience on any device.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '1cbac552-1007-4186-acd2-ed71e31ce423',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'TEAM_MEMBERS',
        },
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  name: 'Email',
                  id: '87a604bf-7e40-45e1-b046-af34e6357b5f',
                  placeholder: 'Enter Your Email',
                  error: 'email is required',
                },
              ],
              name: 'INPUTS',
              id: '34b744bb-d865-47d6-9e04-430aff3bb789',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      fontFamily: 'Josefin Sans',
                      color: '#ffffff',
                      text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '2dd2380f-8571-4e4b-a715-2126ebbea4e6',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ color: '#ffffff', text: '', bold: true }], type: 'paragraph' },
                { children: [{ color: '#ffffff', bold: true, text: 'Book A Table ' }], type: 'paragraph' },
              ],
              name: 'SUBTITLE',
              id: '1fd2128a-e5a6-4fbc-bc50-a4671003ca81',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#000',
                  backgroundColor: '#c43434',
                  borderRadius: '0px',
                  color: '#ffffff',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'send',
              },
              name: 'BUTTON_1',
              id: 'c21d897d-3085-4364-a18f-630a81bdec79',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_6',
          type: 'CONTACT_US',
        },
      ],
    },
    {
      slug: '/menu',
      name: 'Menu',
      blocks: [
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_1')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ color: '#ffffff', text: 'Our Main Menu' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '2485f035-8563-4238-83ac-fd24f7b05aa2',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ color: '#ffffff', text: 'UX Design for Acquisition Success' }], type: 'paragraph' },
              ],
              name: 'SUBTITLE',
              id: '3b21a5aa-7156-490d-9ce5-19cc68fced0b',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#ffffff', text: '' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: 'e4eb0aa5-0a54-43e4-b93e-0b472cf75e75',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          type: 'TEXT',
        },
        {
          settings: {
            ...menu.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: 'http://www.pixel-industry.com/html/royal-plate/img/pics/bkg-img1.jpg',
              name: 'MEDIA',
              id: '1a0ebc73-5c17-4672-9896-07eb43cc71f7',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Lx minus fuga earum aut animi enim delectus provident reiciendis in, totam, voluptatum doloremquefacilis eos velit beatae molestias eum laborum, vero a dicta aliquid veritatis labore libero itaque!Temporibus vero incidunt animi veritatis sapiente deserunt, voluptas, aspernatur reiciendis iusto quaerat cumque distinctio! Odit nostrum delectus iste, ad voluptatibus repellat tempora adipisc voluptatum distinctio. Tenetur recusandae eligendi, dolore officiis sint odit adipisci deserunt earum',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'ee6bc4e9-a401-4a00-a5c5-630ad93ab451',
              category: 'TEXT',
            },
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1535473895227-bdecb20fb157?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxkaW5uZXJ8ZW58MHx8fHwxNjc4MTAxODAx&ixlib=rb-4.0.3&q=80&w=1080',
                  subtitle1: [{ children: [{ color: '#ffffff', text: '$50' }], type: 'paragraph' }],
                  title1: [
                    {
                      children: [{ fontFamily: 'Josefin Sans', color: '#ffffff', text: 'OMG User Experience' }],
                      type: 'paragraph',
                    },
                  ],
                  id: '6680c8f9-b5fd-4550-95fc-c8fe6273ef0c',
                  description1: [
                    {
                      children: [
                        {
                          fontFamily: 'Josefin Sans',
                          color: '#ffffff',
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1540189549336-e6e99c3679fe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
                  subtitle1: [{ children: [{ color: '#ffffff', text: '$40' }], type: 'paragraph' }],
                  title1: [
                    {
                      children: [{ fontFamily: 'Josefin Sans', color: '#ffffff', text: 'OMG User Experience' }],
                      type: 'paragraph',
                    },
                  ],
                  id: 'dd41a135-137d-47d8-8cc9-32c63f315723',
                  description1: [
                    {
                      children: [
                        {
                          color: '#ffffff',
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1594834749740-74b3f6764be4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxsdW5jaHxlbnwwfHx8fDE2NzgxMDE3NzA&ixlib=rb-4.0.3&q=80&w=1080',
                  subtitle1: [{ children: [{ color: '#ffffff', text: '$25' }], type: 'paragraph' }],
                  title1: [
                    {
                      children: [{ fontFamily: 'Josefin Sans', color: '#ffffff', text: 'OMG User Experience' }],
                      type: 'paragraph',
                    },
                  ],
                  id: '6fd234d7-be18-49b2-84fc-08a767228431',
                  description1: [
                    {
                      children: [
                        {
                          fontFamily: 'Josefin Sans',
                          color: '#ffffff',
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1473093295043-cdd812d0e601?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxtZWFsc3xlbnwwfHx8fDE2NzgxNzI0OTg&ixlib=rb-4.0.3&q=80&w=1080',
                  subtitle1: [{ children: [{ color: '#ffffff', text: '$7' }], type: 'paragraph' }],
                  title1: [
                    {
                      children: [{ fontFamily: 'Josefin Sans', color: '#ffffff', text: 'OMG User Experience' }],
                      type: 'paragraph',
                    },
                  ],
                  id: '49d98445-3551-45ad-bd07-927e6094ddb9',
                  description1: [
                    {
                      children: [
                        {
                          fontFamily: 'Josefin Sans',
                          color: '#ffffff',
                          text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
              ],
              name: 'ITEMS',
              id: 'b4883807-b5d6-44d9-b00e-1b88db26112c',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ fontFamily: 'Josefin Sans', color: '#ffffff', text: 'meals' }], type: 'paragraph' },
              ],
              name: 'TITLE',
              id: '178ca83f-4546-4fcf-8be0-3a52d45b7a68',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ fontFamily: 'Josefin Sans', color: '#ffffff', text: 'main' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'bc4b51de-f142-4927-a84f-ce8866794d0d',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'MENU',
        },
      ],
    },
    {
      slug: '/services',
      name: 'Services',
      blocks: [
        {
          settings: { ...love.find(i => i.variant === 'VARIANT_4')?.settings },
          variables: [
            {
              data: 'https://images.unsplash.com/photo-1581349485608-9469926a8e5e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxMHx8Y2hlZnN8ZW58MHx8fHwxNjc4ODgyNjA1&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '218cb0c7-3747-4427-a6f1-e2958da7976c',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ color: '#ffffff', text: '' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '2466d45c-ba30-4c30-b19d-c791cd4b49be',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ fontFamily: 'Josefin Sans', color: '#bcbcbc', text: '' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: '009b3778-5db6-47d2-885b-e61f8e90ed1d',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#ffffff', text: 'What We Do ? ' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'd7e8be8c-524d-4de0-9d12-bd6ddc83841e',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_4',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1512485800893-b08ec1ea59b1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHx3YWl0ZXJzfGVufDB8fHx8MTY3ODE3Mjg2Nw&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [
                    {
                      children: [{ fontFamily: 'Josefin Sans', color: '#ffffff', text: 'Tea for Investors' }],
                      type: 'paragraph',
                    },
                  ],
                  description: [{ children: [{ color: '#ffffff', text: '' }], type: 'paragraph' }],
                  id: '1',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1511421616335-5a9846f1afcb?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw2fHx3YWl0ZXJzfGVufDB8fHx8MTY3ODE3Mjg2Nw&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [
                    {
                      children: [{ fontFamily: 'Josefin Sans', color: '#ffffff', text: 'Tea for Investors' }],
                      type: 'paragraph',
                    },
                  ],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '2',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1516554646385-7642248096d1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxMHx8d2FpdGVyc3xlbnwwfHx8fDE2NzgxNzI4Njc&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ color: '#ffffff', text: 'Tea for Investors' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: '65a8ba9f-dddd-4610-aaf2-1847d04cf83c',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ color: '#ffffff', text: 'OMG User Experience', bold: true }], type: 'paragraph' }],
              name: 'TITLE',
              id: '9b39bd3c-af81-4f91-a07f-77222a492e1f',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'TEXT',
        },
        {
          settings: {
            ...quote.find(i => i.variant === 'VARIANT_3')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'DESCRIPTION',
              id: '856bb9e2-ca5b-4cd5-8695-0505cc91e47c',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1554050857-c84a8abdb5e2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxibGFja3xlbnwwfHx8fDE2NzgxMzY4ODY&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '2a3006cc-7f0f-46cd-8b75-f4c8e6e02635',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Dancing Script', color: '#ba2323', text: 'Anthony Wahlberg' }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '0c65a49a-4c7f-44d2-8477-c4062827ca65',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      fontFamily: 'Josefin Sans',
                      color: '#ffffff',
                      text: "Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience? Look no further than our custom site and portfolio design services. Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. Whether you're a digital nomad or a disruptive technology startup, we have the expertise to optimize your website for exceptional user experience on any device.",
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'f474e1d4-3e68-4f48-a6d6-9499bf3063b0',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_3',
          type: 'QUOTE_SECTION',
        },
      ],
    },
    {
      slug: '/books-table',
      name: 'Books A Table',
      blocks: [
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_11')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  name: 'First Name',
                  id: 'b2ac78cb-d49c-4311-959d-9b8808a40448',
                  placeholder: 'First Name',
                  type: 'text',
                  error: 'First Name is required',
                },
                {
                  name: 'Last Name',
                  id: '080337de-39d1-4048-9d1b-cf8f84c188a9',
                  placeholder: 'Last Name',
                  type: 'text',
                  error: 'Last Name is required',
                },
                {
                  name: 'Email',
                  id: '8b0e8c2c-db2c-4341-b46d-2a0b960d5886',
                  placeholder: 'Email',
                  type: 'email',
                  error: 'email is required',
                },
                {
                  name: 'Number',
                  id: 'e11f3380-bf9c-44c4-a1f5-296d0205f671',
                  placeholder: 'Number',
                  type: 'number',
                  error: 'number is required',
                },
              ],
              name: 'INPUTS',
              id: '2dfc0c45-9cf1-40f9-96a4-f598426358fc',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1547573854-74d2a71d0826?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw3fHx0YWJsZSUyMGluJTIwcmVzdHVyYW50fGVufDB8fHx8MTY3ODE3MzQyNQ&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '1314074d-c1ac-4c3f-b294-9e74639929ec',
              category: 'IMAGE',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#ffffff',
                  backgroundColor: '#c43434',
                  borderRadius: '0px',
                  color: '#ffffff',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  bold: true,
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'send',
              },
              name: 'BUTTON_1',
              id: 'f09a1295-2517-40f7-9e7d-78c7000987b7',
              category: 'BUTTON',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#ffffff',
                      text: 'Dope Investor Design is a full-service investment firm with a focus on innovative design. We invest in companies that prioritize exceptional user experience and visual appeal. Our team of experienced designers and investors work hand-in-hand to support the growth and success of our portfolio companies.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'be20fb06-471a-42d8-80c8-9e82150684eb',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Josefin Sans', color: '#ffffff', text: 'BOOK A TABLE' }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: 'f857188e-1b88-4b72-9866-5b9fd447837f',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_11',
          type: 'CONTACT_US',
        },
      ],
    },
    {
      slug: '/contact-us',
      name: 'Contact Us',
      blocks: [
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  name: 'Email',
                  id: '898da3c2-e2f9-4afd-8985-59302fbaf33d',
                  type: 'email',
                  placeholder: 'Enter Your Email',
                  error: 'email is required',
                },
              ],
              name: 'INPUTS',
              id: '5c896a7e-ad1b-4729-9e8b-6b46ec0421df',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Josefin Sans', color: '#ffffff', text: 'Send us a message' }],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '5495b3ab-b97e-4b07-9f20-2d35a2785bab',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#ffffff',
                      text: "Look no further than our custom site and portfolio design services. Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. Whether you're a digital nomad or a disruptive technology startup, we have the expertise to optimize your website for exceptional user experience on any device.",
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '775b940a-3d9b-4f9c-9197-84c56f91f806',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#ffffff',
                  backgroundColor: '#c43434',
                  borderRadius: '0px',
                  color: '#ffffff',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'send',
              },
              name: 'BUTTON_1',
              id: '884899bd-e5e8-43b9-a60a-f20aeb9c7444',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_6',
          type: 'CONTACT_US',
        },
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_1')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: {
                address: '1600 Amphitheatre Parkway, Mountain View, CA',
                position: { lng: -122.0842499, lat: 37.4224764 },
              },
              name: 'MAP',
              id: '2703f4eb-8ff2-4ddf-bef8-42be39a44fbf',
              category: 'TEXT',
            },
            {
              data: [
                {
                  name: [{ children: [{ color: '#ffffff', text: 'Hours' }], type: 'paragraph' }],
                  description: [{ children: [{ color: '#ffffff', text: 'monday-friday' }], type: 'paragraph' }],
                  id: '554cc326-b752-4d20-94ff-b57df691dd89',
                },
                {
                  name: [{ children: [{ color: '#ffffff', text: 'Phone' }], type: 'paragraph' }],
                  description: [{ children: [{ color: '#ffffff', text: '(555) 555-5555' }], type: 'paragraph' }],
                  id: '28d7957c-640a-4bb7-a201-a6f3f9aa0ba5',
                },
                {
                  name: [{ children: [{ color: '#ffffff', text: 'Email' }], type: 'paragraph' }],
                  description: [{ children: [{ color: '#ffffff', text: 'example@gmail.com' }], type: 'paragraph' }],
                  id: '11a12a12-05d8-4829-8f2e-3e89b8ad460e',
                },
              ],
              name: 'CONTRACT',
              id: '7110f030-608b-4472-b928-988756f4d424',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ color: '#ffffff', text: '1600 Amphitheatre Parkway, Mountain View, CA' }],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '48895f29-da70-4bd2-b9e5-409d7c2d805c',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ fontFamily: 'Josefin Sans', color: '#ffffff', text: ' Visit Us' }], type: 'paragraph' },
              ],
              name: 'TITLE',
              id: 'c428f412-14e0-42e1-99d7-930fb26a6293',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#ffffff',
                      text: "Whether you're a digital nomad or a disruptive technology startup, we have the expertise to ",
                    },
                  ],
                  type: 'paragraph',
                },
                {
                  children: [
                    { color: '#ffffff', text: 'optimize your website for exceptional user experience on any device.' },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'ecf88239-2426-4249-8322-e3115f94deb2',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          type: 'CONTACT_US',
        },
      ],
    },
  ],
  newMenu: [
    { name: 'Home', type: 'internal', url: '/' },
    { name: 'Menu', type: 'internal', url: '/menu' },
    { name: 'Services', type: 'internal', url: '/services' },
    { name: 'Book A Table', type: 'internal', url: '/books-table' },
    { name: 'Contact us', type: 'internal', url: '/contact-us' },
  ],
  newMenuConfig: {
    links: [
      { name: 'Home', id: '3fe8e15d-1aac-4878-b6f7-909fbcd45ce3', type: 'internal', url: '/' },
      { name: 'Contact Us', id: '8aee6254-7e5c-4f15-87a4-ff90108d6a93', type: 'email', url: 'hello@example.com' },
    ],
    styleName: 'VARIANT 19',
    id: 'VARIANT_19',
    tags: [],
  },
  newFooter: {
    settings: {
      backgroundColor: { color: '#000000', enabled: true },
      spacing: {
        padding: { top: 30, left: 0, bottom: 30, right: 0 },
        margin: { top: 0, left: 0, bottom: 0, right: 0 },
        enabled: true,
      },
      textColor: { color: '#c43434', enabled: true },
    },
    designedBy: 'Plly Staff',
    id: 'VARIANT_2',
    categories: [
      {
        name: 'Explore',
        links: [{ name: 'Home', id: '0dd6b309-f3f0-431e-86e2-dd64fa230c97', type: 'internal', url: '/' }],
        id: '98a91700-53a1-4537-8ca0-8c758d15fb05',
      },
    ],
    tags: [],
  },
  links: [],
}
