import clsx from 'clsx'
import SectionSlatePreview from '../../../../common/SectionSlatePreview'
import { TeamMembersSectionMember } from '../../../types'

export default function Team52({ member, isRound }: { member: TeamMembersSectionMember; isRound?: boolean }) {
  return (
    <div className="mt-[120px] md:ml-[-70px] -ml-[10px]  lg:mt-[300px] md:mr-64 lg:mr-[300px]">
      <div className="w-[190px] lg:w-[500px] mx-auto">
        <img
          className={clsx('h-[180px] lg:h-[500px] object-cover object-top w-full', isRound && 'rounded-full')}
          src={member.image}
          alt=""
        />
      </div>
      <div className="md:space-y-2 mt-2 md:mt-5">
        <p className="text-black  md:text-2xl font-medium text-center">
          <SectionSlatePreview initialValue={member.name} previewMode />
        </p>
        {member.subtitle && (
          <p className="text-black text-center text-[14px] md:text-[18px]">
            <SectionSlatePreview initialValue={member.subtitle} previewMode />
          </p>
        )}
        {member.description && (
          <p className="text-black text-center text-[12px] md:text-[16px]">
            <SectionSlatePreview initialValue={member.description} previewMode />
          </p>
        )}
      </div>
    </div>
  )
}
