export const aiTemplateRequestPayload = {
  id: '1',
  prompt: 'a small restaurant selling italian food',
  pages: [
    {
      id: '1',
      name: 'Home',
      components: [
        {
          id: '1',
          name: 'ATTENTION_GRABBER',
          description: 'Hero area with a background image and a title and subtitle',
          elements: [
            {
              id: '1',
              type: 'title',
              description: 'hero section title',
            },
            {
              id: '2',
              type: 'subtitle',
              description: 'hero section subtitle',
            },
            {
              id: '3',
              type: 'image',
              description: 'hero section background image',
            },
            {
              id: '4',
              type: 'image',
              description: 'hero section icon type image',
            },
          ],
        },
        {
          id: '2',
          name: 'TEXT',
          description: 'section showing business tagline',
          elements: [
            {
              id: '1',
              type: 'title',
              description: 'business tagline',
              character_limit: 100,
            },
          ],
        },
        {
          id: '3',
          name: 'TEXT',
          description: "section showing business's about us",
          elements: [
            {
              id: '1',
              type: 'title',
            },
            {
              id: '2',
              type: 'subtitle',
            },
            {
              id: '3',
              type: 'paragraph',
              description: 'business about us',
            },
          ],
        },
        {
          id: '4',
          name: 'TEXT',
          description: "section showing business's services or products",
          elements: [
            {
              id: '1',
              type: 'title',
            },
            {
              id: '2',
              type: 'list',
              description: 'business services or products',
              count: 3,
              items: [
                {
                  id: '1',
                  type: 'image',
                  description: 'business service or product image',
                },
                {
                  id: '2',
                  type: 'title',
                  description: 'business service or product title',
                },
              ],
            },
          ],
        },
        {
          id: '5',
          name: 'FAQ',
          description: "section showing business's skills and expertise",
          elements: [
            {
              id: '1',
              type: 'title',
            },
            {
              id: '2',
              type: 'list',
              description: 'business skills and expertise',
              count: 6,
              items: [
                {
                  id: '1',
                  type: 'image',
                  description: 'business skill or expertise image',
                },
                {
                  id: '2',
                  type: 'title',
                  description: 'business skill or expertise title',
                },
                {
                  id: '3',
                  type: 'subtitle',
                  description: 'business skill or expertise subtitle',
                },
              ],
            },
          ],
        },
        {
          id: '6',
          name: 'TEXT',
          description: 'section showing customer testimonials',
          elements: [
            {
              id: '1',
              type: 'title',
            },
          ],
        },
        {
          id: '7',
          name: 'GALLERY',
          description: 'section showing customer testimonials',
          elements: [
            {
              id: '1',
              type: 'list',
              description: 'customer testimonials',
              count: 3,
              items: [
                {
                  id: '1',
                  type: 'image',
                  description: 'image of a person',
                },
                {
                  id: '2',
                  type: 'title',
                  description: 'name of person giving testimonial',
                },
                {
                  id: '4',
                  type: 'paragraph',
                  description: 'testimonial about the business',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: '2',
      name: 'Gallery',
      components: [
        {
          id: '1',
          name: 'ATTENTION_GRABBER',
          description: "section for showcasing business's work or products",
          elements: [
            {
              id: '1',
              type: 'title',
              description: 'title for the section',
            },
            {
              id: '2',
              type: 'subtitle',
              description: 'subtitle for the section',
            },
          ],
        },
        //   {
        //     id: '2',
        //     name: 'GALLERY',
        //     description: "section for showcasing business's work or products",
        //     elements: [
        //       {
        //         id: '1',
        //         type: 'list',
        //         description: "business's work or products",
        //         count: 8,
        //         items: [
        //           {
        //             id: '1',
        //             type: 'image',
        //             description: "image of business's work or product",
        //           },
        //           {
        //             id: '2',
        //             type: 'title',
        //             description: "title of business's work or product",
        //           },
        //           {
        //             id: '3',
        //             type: 'subtitle',
        //             character_limit: 100,
        //             description: "subtitle of business's work or product",
        //           },
        //         ],
        //       },
        //     ],
        //   },
      ],
    },
  ],
}
