import { Descendant } from 'slate'
import {
  AttentionGrabberItemsGroup,
  AttentionGrabberVariables,
  AttentionGrabberVariants,
  Section,
  SectionTags,
  SectionVariable,
} from 'src/_polly/components/src/sections'
import { v4 as uuid } from 'uuid'
import { DEFAULT_VARIANT_SETTINGS, getSectionVariants } from './get-variants'

const tags: Record<AttentionGrabberVariants, SectionTags[]> = {
  VARIANT_1: ['IMAGE', 'ANIMATION'],
  VARIANT_2: ['IMAGE', 'ANIMATION'],
  VARIANT_3: ['IMAGE', 'ANIMATION'],
  VARIANT_4: ['IMAGE', 'ANIMATION'],
  VARIANT_5: ['IMAGE', 'ANIMATION'],
  VARIANT_6: ['IMAGE'],
  VARIANT_7: ['IMAGE'],
  VARIANT_8: ['IMAGE'],
  VARIANT_9: ['IMAGE'],
  VARIANT_10: ['IMAGE'],
  VARIANT_11: ['IMAGE'],
  VARIANT_12: ['IMAGE', 'ANIMATION'],
  VARIANT_13: ['IMAGE'],
  VARIANT_14: ['IMAGE'],
}

export function getAttentionGrabberVariants() {
  const title: Descendant[] = [
    {
      type: 'paragraph',
      children: [{ text: 'Title' }],
    },
  ]
  const subTitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Sub Title' }] }]
  const paragraph: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Paragraph' }] }]
  const media =
    'https://images.unsplash.com/photo-1483728642387-6c3bdd6c93e5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxtb3VudGFpbnxlbnwwfHx8fDE2NzIzMjQ3NDc&ixlib=rb-4.0.3&q=80&w=1080'

  const transition = 0
  const variants = getSectionVariants('ATTENTION_GRABBER')
  const sections: Section<AttentionGrabberVariants, AttentionGrabberVariables>[] = []

  for (const variant of variants) {
    let variables: SectionVariable<AttentionGrabberVariables>[] = []

    if (variant.id === 'VARIANT_1') {
      const title2: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'Choose your design', className: 'jumbotron-heading1 ' }],
        },
      ]
      const subTitle2: Descendant[] = [{ type: 'paragraph', children: [{ text: 'UX Design for Digital Nomads' }] }]
      const transition2 = 9

      variables = [
        { id: uuid(), category: 'TEXT', data: title2, name: 'TITLE' },
        { id: uuid(), category: 'TEXT', data: subTitle2, name: 'SUBTITLE' },
        { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
        {
          id: uuid(),
          category: 'IMAGE',
          data: 'https://images.unsplash.com/photo-1618005182384-a83a8bd57fbe?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw4fHx2ZWN0b3J8ZW58MHx8fHwxNjc3NzU1ODk2&ixlib=rb-4.0.3&q=80&w=1080',
          name: 'MEDIA',
        },
        { id: uuid(), category: 'IMAGE', data: transition2, name: 'TRANSITION' },
      ]
    } else if (variant.id === 'VARIANT_2') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'Ayden Pit' }],
        },
      ]
      const subtitle: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'Photographer And Designer' }],
        },
      ]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. ',
            },
          ],
        },
      ]

      variables = [
        { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
        { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
        { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
        {
          id: uuid(),
          category: 'IMAGE',
          data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-9af3d084-b291-4fb3-8760-dce2dda43db0',
          name: 'MEDIA',
        },
        {
          id: uuid(),
          category: 'IMAGE',
          data: 'https://images.unsplash.com/photo-1591114286974-595c5ffb0c41?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80',
          name: 'MEDIA2',
        },
      ]
    } else if (variant.id === 'VARIANT_5') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'Fitness And healthy life ', className: 'uppercase text-white' }],
        },
      ]
      const subTitle: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'Discover More' }],
        },
      ]

      variables = [
        { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
        { id: uuid(), category: 'TEXT', data: subTitle, name: 'SUBTITLE' },
        { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
        {
          id: uuid(),
          category: 'IMAGE',
          data: 'https://images.unsplash.com/photo-1583454110551-21f2fa2afe61?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw4fHxmaXRuZXNzfGVufDB8fHx8MTY3ODAxOTE5OQ&ixlib=rb-4.0.3&q=80&w=1080',
          name: 'MEDIA',
        },
      ]
    } else if (variant.id === 'VARIANT_4') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'THRestaurant' }],
        },
      ]
      const subTitle: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'Snatched Crowdfunding UX' }],
        },
      ]
      variables = [
        { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
        { id: uuid(), category: 'TEXT', data: subTitle, name: 'SUBTITLE' },
        { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
        {
          id: uuid(),
          category: 'IMAGE',
          data: 'https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxyZXN0YXVyYW50fGVufDB8fHx8MTY3ODg5MjM4OQ&ixlib=rb-4.0.3&q=80&w=1080',
          name: 'MEDIA',
        },
      ]
    } else if (variant.id === 'VARIANT_6') {
      const titleItem1: Descendant[] = [{ type: 'paragraph', children: [{ text: 'YOLO Startup Design' }] }]
      const descriptionItem1: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Investor-Focused UX Design' }] }]
      const titleItem2: Descendant[] = [{ type: 'paragraph', children: [{ text: 'OMG User Experience' }] }]
      const descriptionItem2: Descendant[] = [
        { type: 'paragraph', children: [{ text: 'B2B UX Design for Investors' }] },
      ]
      const titleItem3: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Thirsty Cash Flow' }] }]
      const descriptionItem3: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Bootstrap Your UX Design' }] }]
      const titleItem4: Descendant[] = [{ type: 'paragraph', children: [{ text: 'GOAT Acquisition UX' }] }]
      const descriptionItem4: Descendant[] = [
        { type: 'paragraph', children: [{ text: 'Accelerator-Approved UX Design' }] },
      ]
      variables = [
        {
          id: uuid(),
          category: 'IMAGE',
          name: 'ITEMS',
          data: [
            {
              id: uuid(),
              title: titleItem1,
              description: descriptionItem1,
            },
            {
              id: uuid(),
              title: titleItem2,
              description: descriptionItem2,
            },
            {
              id: uuid(),
              title: titleItem3,
              description: descriptionItem3,
            },
            {
              id: uuid(),
              title: titleItem4,
              description: descriptionItem4,
            },
            {
              id: uuid(),
              title: titleItem2,
              description: descriptionItem2,
            },
            {
              id: uuid(),
              title: titleItem3,
              description: descriptionItem3,
            },
            {
              id: uuid(),
              title: titleItem4,
              description: descriptionItem4,
            },
          ] as AttentionGrabberItemsGroup[],
        },
      ]
    } else if (variant.id === 'VARIANT_7') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'Buzzworthy Investor Trends' }],
        },
      ]
      const subTitle: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'UX Design for Digital Nomads' }],
        },
      ]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'At our agency, we prioritize user satisfaction, user retention, and user conversion. We offer website performance, website speed, website accessibility, website security, website maintenance, website optimization, website analytics, website testing, website updates, website improvements, and website enhancements to ensure your site is top-notch. With our high-octane mobile-friendly design and invigorating user-friendly interface, we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
            },
          ],
        },
      ]
      variables = [
        { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
        { id: uuid(), category: 'TEXT', data: subTitle, name: 'SUBTITLE' },
        { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
      ]
    } else if (variant.id === 'VARIANT_8') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'TBH BRAND REPUTATION', className: 'uppercase' }],
        },
      ]
      const subTitle: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'Decore Company ' }],
        },
      ]

      variables = [
        { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
        { id: uuid(), category: 'TEXT', data: subTitle, name: 'SUBTITLE' },
      ]
    } else if (variant.id === 'VARIANT_9') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'dream', className: '' }],
        },
      ]
      const subTitle: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'Dangerously' }],
        },
      ]
      variables = [
        { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
        { id: uuid(), category: 'TEXT', data: subTitle, name: 'SUBTITLE' },
      ]
    } else if (variant.id === 'VARIANT_10') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'Yeet Your Design', className: '' }],
        },
      ]
      const subTitle: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'UX Design for Crowdfunding Success' }],
        },
      ]
      variables = [
        { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
        {
          id: uuid(),
          category: 'IMAGE',
          data: 'https://images.unsplash.com/photo-1610653216265-74079d187414?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80',
          name: 'MEDIA',
        },
        { id: uuid(), category: 'TEXT', data: subTitle, name: 'SUBTITLE' },
      ]
    } else if (variant.id === 'VARIANT_11') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'Butter', className: '' }],
        },
      ]
      variables = [{ id: uuid(), category: 'TEXT', data: title, name: 'TITLE' }]
    } else if (variant.id === 'VARIANT_12') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'GALLERY' }],
        },
      ]
      const media =
        'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-3679e116-b471-4bf4-9c4c-521f5192e575'
      variables = [
        { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
        { id: uuid(), category: 'IMAGE', data: media, name: 'MEDIA' },
      ]
    } else if (variant.id === 'VARIANT_13') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'lightness', className: 'shadow-lightness' }],
        },
      ]
      variables = [
        { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
        { id: uuid(), category: 'TEXT', data: subTitle, name: 'SUBTITLE' },
      ]
    } else if (variant.id === 'VARIANT_14') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'About Us' }],
        },
      ]
      const subTitle: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'Sustainable Design Revolution' }],
        },
      ]
      variables = [
        { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
        { id: uuid(), category: 'TEXT', data: subTitle, name: 'SUBTITLE' },
      ]
    } else if (!variables.length) {
      variables = [
        { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
        { id: uuid(), category: 'TEXT', data: subTitle, name: 'SUBTITLE' },
        { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
        { id: uuid(), category: 'IMAGE', data: media, name: 'MEDIA' },
        { id: uuid(), category: 'IMAGE', data: transition, name: 'TRANSITION' },
      ]
    }

    sections.push({
      id: variant.id,
      createdAt: '',
      isSection: true,
      type: 'ATTENTION_GRABBER',
      updatedAt: '',
      variables,
      variant: variant.id as AttentionGrabberVariants,
      tags: tags[variant.id as AttentionGrabberVariants],
      styleName: variant.name,
      designedBy: 'Plly Staff',
      ...DEFAULT_VARIANT_SETTINGS,
    })
  }

  return sections
}
