import clsx from 'clsx'
import React, { Suspense, useCallback, useContext, useEffect, useState } from 'react'
import ReactCardFlip from 'react-card-flip'
import UpIcon from 'src/icons/UpIcon'
import { getPagesByWebsite, getWebsiteByID } from 'src/lib/services/website.service'
import ShareModal from 'src/pages/social/posts-page/components/ShareModal'
import { getComponents, updateComponents, updateComponentsByPageID } from 'src/services/website.service'
import useApp from 'src/store/app'
import { swapObject } from 'src/util'
import MenuRenderer from 'src/_polly/components/src/menu/Renderer'
import { RenderSection, Section, SectionPreviewRenderer } from 'src/_polly/components/src/sections'
import useWebsite from '../../store/website'
import { getBlockWidth } from '../../util/grid'
import Footer1 from '../../_polly/components/src/Footer1'
import Footer2 from '../../_polly/components/src/Footer2'
import Footer3 from '../../_polly/components/src/Footer3'
import Footer4 from '../../_polly/components/src/Footer4'
import { WebsiteContext } from '../context/WebsiteContext'
import { ReactComponent as DuplicateIcon } from '../icons/Copy.svg'
import { ReactComponent as DeleteIcon } from '../icons/Delete.svg'
import { ReactComponent as EditIcon } from '../icons/Pencil.svg'
import { ReactComponent as PlusIcon } from '../icons/Plus.svg'
import { ReactComponent as Plus } from '../icons/PlusIcon.svg'
import { Loader } from '../loader'
import EditSection from '../modal/EditSection'
import MintModal from '../modal/MintModal'
import NewSection from '../modal/NewSection'
import { VariantIframe } from '../modal/NewSectionVariantSelection'
import PostWebsite from '../modal/PostWebsite'
import NewFooter from '../modal/section/NewMenu/NewFooter'
import NewMenu from '../modal/section/NewMenu/NewMenu'
import WebsiteSettings from '../modal/WebsiteSettings'
import Component from './Component'
import ConfirmModal from './ConfirmModal'
import EditSectionModal from './EditSectionModal'

const GoogleFonts = React.lazy(() => import('react-google-font-loader'))

export default function ComponentList({ flipped }: { flipped?: boolean }) {
  const { websiteID, addGoogleFont, googleFonts, pagePath, pageID, setPageID } = useContext(WebsiteContext)
  const websiteStore = useWebsite(state => state)
  const appStore = useApp(state => state)

  const [loading, setLoading] = useState(true)
  const [editItem, setEditItem] = useState<any>(null)
  const [website, setWebsite] = useState<any>(null)
  const [components, setComponents] = useState<any[]>(['none'])
  const [showNewSection, setShowNewSection] = useState(false)
  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const [confirmDeleteComponentId, setConfirmDeleteComponentId] = useState(null)
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
  const [mintComponent, setMintComponent] = useState<null | any>(null)
  const [editSection, setEditSection] = useState<Section<any, any> | null>(null)
  const [previewMode, setPreviewMode] = useState<'desktop' | 'mobile'>('desktop')
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [postComponentID, setPostComponentID] = useState<null | string>(null)

  const footer = website?.footer ? JSON.parse(website.footer) : null

  const invalidate = useCallback(async () => {
    const pages = await getPagesByWebsite(websiteID)
    // TODO: implement page not found
    if (!pages.length) return

    if (pagePath) {
      const page = pages.find(_page => _page?.path === `/${pagePath}`)
      if (!page) {
        // TODO: implement page not found
        return
      }
      setPageID(page.id)

      const { blocks } = page
      setComponents(JSON.parse(blocks ?? '[]'))
      console.log('components', blocks)
    } else {
      const page = pages.find(_page => _page?.path === '/')
      if (!page) {
        // TODO: implement page not found
        return
      }
      setPageID(page.id)
      getComponents(websiteID).then(_components => {
        console.log('components', JSON.stringify(_components))
        setComponents(_components)
      })
    }
    getWebsiteByID(websiteID).then(site => {
      setWebsite(site)
      console.log('website', JSON.stringify(site, null, 2))
      websiteStore.setWebsite({
        ...site,
        config: site?.config ? JSON.parse(site?.config) : null,
        footer: site?.footer ? JSON.parse(site?.footer) : null,
        menu: site?.menu ? JSON.parse(site?.menu) : null,
        links: site?.links ? JSON.parse(site?.links) : null,
        newMenu: site?.newMenu ? JSON.parse(site?.newMenu) : null,
        newMenuConfig: site?.newMenuConfig ? JSON.parse(site?.newMenuConfig) : null,
        newFooter: site?.newFooter ? JSON.parse(site?.newFooter) : null,
      })
      websiteStore.setMobilePreviewComponents({
        ...site,
        config: site?.config ? JSON.parse(site?.config) : null,
        footer: site?.footer ? JSON.parse(site?.footer) : null,
        menu: site?.menu ? JSON.parse(site?.menu) : null,
        links: site?.links ? JSON.parse(site?.links) : null,
        newMenu: site?.newMenu ? JSON.parse(site?.newMenu) : null,
        newMenuConfig: site?.newMenuConfig ? JSON.parse(site?.newMenuConfig) : null,
        newFooter: site?.newFooter ? JSON.parse(site?.newFooter) : null,
      })
    })
  }, [websiteID, pagePath])

  useEffect(() => {
    setLoading(true)
    invalidate().finally(() => setLoading(false))
  }, [invalidate])

  useEffect(() => {
    websiteStore.setComponents(components)
    components.forEach(component => {
      const { type } = component
      if (type !== 'QUOTE' && type !== 'TITLE') return

      const {
        title: {
          settings: { font },
        },
      } = component.props
      if (font) addGoogleFont(font)

      if (type === 'TITLE') {
        if (component.props.subHeadline?.settings?.font) {
          addGoogleFont(component.props.subHeadline.settings.font)
        }
      }

      if (type === 'BLOG') {
        const {
          content: {
            settings: { font: _font },
          },
        } = component.props
        if (_font) addGoogleFont(_font)
      }
    })
  }, [components])

  const goToEdit = (value: any) => {
    setEditItem(value)
  }

  const onMoveUp = (id: string) => {
    const items = swapObject(components, 'id', id, 'ahead')
    updateComponentsByPageID(pageID, items)
    setComponents(items)
  }

  const handleDuplicate = (comp: any) => {
    const currentCompIndex = components.findIndex(c => c.id === comp.id)
    const duplicateComp = { ...comp, id: `${comp.id}_${Date.now()}` }
    const items = [
      ...components.slice(0, currentCompIndex + 1),
      duplicateComp,
      ...components.slice(currentCompIndex + 1),
    ]
    updateComponents(websiteID, items)
    setComponents(items)
  }

  const handleDelete = (id: string) => {
    if (!pageID) return
    const index = components.findIndex(item => item.id === id)
    const newComponents = [...components]
    newComponents.splice(index, 1)
    updateComponentsByPageID(pageID, newComponents)
    setComponents(newComponents)
  }

  const handleConfirmDelete = (confirmed: boolean) => {
    if (confirmDeleteComponentId && confirmed) handleDelete(confirmDeleteComponentId)
    setShowConfirmDeleteModal(false)
    setConfirmDeleteComponentId(null)
  }

  const onMoveDown = (id: string) => {
    const items = swapObject(components, 'id', id, 'behind')
    updateComponentsByPageID(pageID, items)
    setComponents(items)
  }

  const handleEditSection = (section: Section<any, any>) => {
    setEditSection(section)
  }

  const handleModeChange = (preview: 'desktop' | 'mobile') => {
    if (preview === 'mobile') {
      if (localStorage.getItem('mobilePreviewWarning') !== 'true') {
        setShowWarningModal(true)
      }
    }
    setPreviewMode(preview)
    appStore.setPreviewMode(preview)
  }
  const handleMobileWarning = () => {
    localStorage.setItem('mobilePreviewWarning', 'true')
    setShowWarningModal(false)
  }

  const tutorialCount = JSON.parse(localStorage.getItem(`tutorial_editpage_${websiteID}`) || '1')

  const componentsLength = components.filter(comp => comp.type !== 'FOOTER' && comp.type !== 'MENU_BAR').length

  useEffect(() => {
    if (components.find(s => s) !== 'none') {
      if (componentsLength <= 0 && tutorialCount <= 1) {
        setShowNewSection(true)
      }
    }
  }, [components.find(s => s)])

  return (
    <>
      <Loader show={loading} />
      <div className="flex justify-between">
        <div
          className={clsx(
            'z-50 fixed top-0 right-0 bottom-0 left-0 justify-center items-center',
            !showWarningModal && 'hidden',
          )}
        >
          <div className="relative w-full h-full bg-white/90 flex justify-center items-center">
            <div className="w-[340px] bg-white border-[3px] border-black border-solid">
              <div className="p-6 flex justify-center items-center">
                <p className="w-56 text-base font-light leading-relaxed text-black">
                  Mobile preview does not support live editing.
                </p>
              </div>
              <div className="flex items-center pb-4 px-9 space-x-8">
                <button
                  onClick={handleMobileWarning}
                  type="button"
                  className="w-full h-9 text-base font-light text-black text-center border-[1px] border-black"
                >
                  OKAY
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute top-[21px] right-[30px]">
          <button
            type="button"
            className="border-[0.6px] border-[#0C0C0C] dark:border-white rounded-[5px] w-[24px] h-[32px]"
            onClick={() => handleModeChange('mobile')}
          >
            <div
              className={clsx(
                'border-[0.6px] border-black dark:border-[#D9D9D9] rounded-[5px] w-[18px] h-[25px] mx-auto',
                previewMode === 'mobile' && 'bg-[#D9D9D9]',
              )}
            />
          </button>

          <button
            type="button"
            className="border-[0.6px] border-[#0C0C0C] dark:border-white rounded-[5px] w-[40px] h-[32px] ml-[10px]"
            onClick={() => handleModeChange('desktop')}
          >
            <div
              className={clsx(
                'border-black dark:border-[#D9D9D9] border-[0.6px] rounded-[5px] w-[32px] h-[25px] mx-auto',
                previewMode === 'desktop' && 'bg-[#D9D9D9]',
              )}
            />
          </button>
        </div>
        <div className="absolute top-[21px] left-[112px]">
          <button type="button" onClick={() => setShowNewSection(true)}>
            <Plus />
          </button>
        </div>
      </div>
      <ReactCardFlip isFlipped={flipped} flipDirection="horizontal">
        <div className="px-6 pb-6 pt-[35px] dark:bg-black">
          <div>
            <NewMenu
              {...{ website }}
              firstSection={
                components.filter(comp => comp.type !== 'FOOTER' && comp.type !== 'MENU_BAR' && comp?.isSection)[0]
              }
              invalidate={invalidate}
            />
          </div>

          {/* components */}
          {components
            .filter(comp => comp.type !== 'FOOTER' && comp.type !== 'MENU_BAR')
            .map((comp, idx) => (
              <div key={comp.id} className="mb-6 w-full max-h-full">
                {comp?.isSection && previewMode === 'desktop' && (
                  <SectionPreviewRenderer index={idx} rounded section={comp} disableHover noShadow />
                )}
                {comp?.isSection && previewMode === 'mobile' && <VariantIframe rounded index={idx} section={comp} />}
                <div className="flex justify-between mt-[2px] border-t border-b border-black dark:border-[#D6D6D6] items-center px-[10px] h-[50px]">
                  <div className="flex items-center md:gap-x-[33px] gap-4">
                    <button
                      type="button"
                      className={clsx(
                        comp.type === 'MENU_BAR' || comp.type === 'FOOTER'
                          ? 'cursor-not-allowed disabled:opacity-50'
                          : 'cursor-pointer',
                        'w-full flex justify-center items-center',
                      )}
                      disabled={comp.type === 'MENU_BAR' || comp.type === 'FOOTER'}
                      onClick={() => {
                        onMoveUp(comp.id)
                      }}
                    >
                      <UpIcon size={15} className="text-black dark:text-white hidden md:block" />
                      <UpIcon size={13} className="text-black dark:text-white block md:hidden" />
                    </button>
                    <button
                      type="button"
                      className={clsx(
                        comp.type === 'MENU_BAR' || comp.type === 'FOOTER'
                          ? 'cursor-not-allowed disabled:opacity-50'
                          : 'cursor-pointer',
                        'w-full flex justify-center items-center',
                      )}
                      disabled={comp.type === 'MENU_BAR' || comp.type === 'FOOTER'}
                      onClick={() => onMoveDown(comp.id)}
                    >
                      <UpIcon size={15} className="text-black dark:text-white rotate-180 hidden md:block" />
                      <UpIcon size={13} className="text-black dark:text-white rotate-180 block md:hidden" />
                    </button>
                  </div>
                  <div className="flex items-center gap-5 md:gap-x-[45px]">
                    <button
                      type="button"
                      className="w-full flex justify-center items-center"
                      onClick={() => {
                        if (comp?.isSection) {
                          handleEditSection(comp)
                        } else {
                          goToEdit(comp)
                        }
                      }}
                    >
                      <EditIcon className="hidden md:block" />
                      <EditIcon className="md:hidden block" width={24} />
                    </button>
                    <button
                      type="button"
                      onClick={() => handleDuplicate(comp)}
                      className="w-full cursor-pointer flex justify-center items-center"
                    >
                      <DuplicateIcon className="hidden md:block" />
                      <DuplicateIcon className="md:hidden block" width={14} />
                    </button>
                    <button
                      type="button"
                      className="w-full cursor-pointer flex justify-center items-center"
                      onClick={() => {
                        setShowConfirmDeleteModal(true)
                        setConfirmDeleteComponentId(comp.id)
                      }}
                    >
                      <DeleteIcon className="hidden md:block" />
                      <DeleteIcon className="md:hidden block" width={12} height={20} />
                    </button>
                    <div className="hidden md:block">
                      <ShareModal post onPostShareClick={() => setPostComponentID(comp.id)} />
                    </div>
                    <div className="md:hidden block mt-1">
                      <ShareModal post onPostShareClick={() => setPostComponentID(comp.id)} size={16} />
                    </div>
                  </div>
                </div>
              </div>
            ))}

          <div className="mb-6">
            <NewFooter rounded={componentsLength > 0} {...{ website, invalidate }} />
          </div>

          {/* footer */}
          <div className="hidden">
            {footer ? (
              <div className="mb-6">
                {footer.style === 'FOOTER1' && <Footer1 {...{ ...footer }} />}
                {footer.style === 'FOOTER2' && <Footer2 {...{ ...footer }} />}
                {footer.style === 'FOOTER3' && <Footer3 {...{ ...footer }} />}
                {footer.style === 'FOOTER4' && <Footer4 {...{ ...footer }} />}

                <div className="z-10 bottom-12 h-12 border-black border-[1px] text-black w-full">
                  <button
                    type="button"
                    onClick={() => goToEdit({ type: 'FOOTER', ...footer })}
                    className="w-[50%] h-full border-black border-r-[1px] p-1 bg-white"
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    className="w-[50%] h-full border-black p-1 bg-white"
                    onClick={() =>
                      setMintComponent({
                        type: 'FOOTER',
                        props: footer,
                      })
                    }
                  >
                    Mint
                  </button>
                </div>
              </div>
            ) : (
              <div className="mb-6 w-full aspect-w-4 aspect-h-3 bg-black">
                <button
                  type="button"
                  onClick={() =>
                    goToEdit({
                      type: 'FOOTER',
                      props: {
                        info: {
                          name: website.name,
                        },
                      },
                    })
                  }
                  className="font-extralight flex text-center justify-center items-center text-white text-7xl"
                >
                  Footer
                </button>
              </div>
            )}
          </div>

          {/* settings */}
          <div className="mb-6 w-full aspect-w-4 hidden aspect-h-3 bg-black">
            <button
              type="button"
              className="font-extralight flex text-center justify-center items-center text-white text-7xl"
              onClick={() => setShowSettingsModal(true)}
            >
              Settings
            </button>
          </div>

          {/* add new component button */}
          <div className="mb-6 w-full aspect-w-4 aspect-h-3 bg-[#D9D9D9] rounded-[6px] border-[10px] border-black dark:border-white">
            <button
              type="button"
              className="z-1 p-5 font-extralight flex text-center justify-center items-center rounded-[6px]"
              onClick={() => {
                setShowNewSection(true)
                localStorage.setItem(
                  `tutorial_component_${website?.id}`,
                  JSON.stringify(JSON.parse(localStorage.getItem(`tutorial_component_${website?.id}`) || '0') + 1),
                )
              }}
            >
              <PlusIcon />
            </button>
          </div>
        </div>

        <div>
          {websiteStore.website && (
            <MenuRenderer
              website={websiteStore.website}
              menu={{
                ...websiteStore.website.newMenuConfig,
                links: websiteStore.website.newMenu,
              }}
            />
          )}
          {flipped &&
            components.map(comp => (
              <div key={comp.id}>
                {comp.isSection ? (
                  <RenderSection section={comp} />
                ) : (
                  <>
                    <div className="hidden md:block" style={{ width: getBlockWidth(comp.columns ?? 4) }}>
                      <Component data={comp} />
                    </div>
                    <div className="block md:hidden px-4">
                      <Component data={comp} />
                    </div>
                  </>
                )}
              </div>
            ))}
          {footer && (
            <>
              {footer.style === 'FOOTER1' && <Footer1 {...{ ...footer }} />}
              {footer.style === 'FOOTER2' && <Footer2 {...{ ...footer }} />}
              {footer.style === 'FOOTER3' && <Footer3 {...{ ...footer }} />}
              {footer.style === 'FOOTER4' && <Footer4 {...{ ...footer }} />}
            </>
          )}
        </div>
      </ReactCardFlip>

      <ConfirmModal open={showConfirmDeleteModal} onConfirm={handleConfirmDelete} />

      <Suspense>
        {googleFonts.length ? (
          <GoogleFonts fonts={[...googleFonts.map(font => ({ font, weights: [400, 500, 600, 700] }))]} />
        ) : null}
      </Suspense>

      <NewSection
        componenstLength={componentsLength}
        open={showNewSection}
        websiteId={websiteID}
        onClose={() => {
          setShowNewSection(false)
          invalidate()
        }}
      />

      <EditSection
        value={editItem}
        open={!!editItem}
        onClose={() => {
          setEditItem(null)
          invalidate()
        }}
        onUpdate={({ id, ...rest }) => {
          websiteStore.updateComponent(id, rest)
        }}
      />
      <WebsiteSettings
        websiteLogo={JSON.parse(website?.config ?? '{}')?.logo}
        open={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
        id={websiteID}
      />

      <MintModal open={mintComponent} onClose={() => setMintComponent(null)} component={mintComponent} />

      <EditSectionModal
        open={!!editSection}
        section={editSection}
        onClose={() => {
          setEditSection(null)
          invalidate()
        }}
        onUpdate={({ id, ...rest }) => {
          console.log('store comp on update', { id, rest })
          websiteStore.updateComponent(id, rest)
        }}
      />

      {postComponentID && (
        <PostWebsite
          open={!!postComponentID}
          onBack={() => setPostComponentID(null)}
          website={website}
          pageID={pageID}
          componentID={postComponentID}
        />
      )}
    </>
  )
}
