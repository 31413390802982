import { motion } from 'framer-motion'
import { useState } from 'react'
import { BsFacebook, BsInstagram, BsTwitter } from 'react-icons/bs'
import { HiMenuAlt4 } from 'react-icons/hi'
import { IoMdClose } from 'react-icons/io'
import { getSocialIcon } from '../../footer/footer'
import FontLoader from '../FontLoader'
import { MenuVariantProps, NewMenu, NewMenuLinkType, Social } from '../menu'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../utils'

const SocialItems = ({ link, type, menu }: { type: Social; link: string; menu: NewMenu }) => {
  let Icon = getSocialIcon(type)
  return (
    <a
      href={link}
      className="bg-[#1a4f90ce] rounded-full text-gray-50 p-3 shadow-xl transition-all duration-300"
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '#1a3250dc' }),
      }}
    >
      <Icon color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#e3e3e3' }).color} />
    </a>
  )
}
export default function MenuVariantThirteen({ menu, website }: MenuVariantProps) {
  return (
    <>
      <FontLoader settings={menu?.settings?.fontFamily} />
      <DesktopNavbar menu={menu} website={website} />
      <MobileNavbar menu={menu} website={website} />
    </>
  )
}

const DesktopNavbar = ({ menu, website }: MenuVariantProps) => {
  return (
    <div
      className="hidden lg:block"
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '#e1e1e15d' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className="flex px-12 py-6 items-center  justify-between">
        <div>
          <a href="/">
            {website?.config?.logo ? (
              <img
                src={website?.config?.logo}
                alt={website?.name}
                className="cursor-pointer w-[80px] h-[80px] rounded-full border origin-center"
                style={{
                  borderColor: getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }).color,
                }}
              />
            ) : (
              <p
                className="uppercase font-semibold text-xl"
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#1a4f90ce' }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  textShadow: '3px 4px 5px gray',
                }}
              >
                {website?.name}
              </p>
            )}
          </a>
        </div>
        <div className="text-[#1a4f90ce]">
          <ul className="flex items-center list-none gap-x-5">
            {menu.links.map(({ id, name, type, url }) => {
              return (
                <li
                  key={id}
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#1a4f90ce' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                    ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '#f2f5f6' }),
                  }}
                >
                  <a
                    href={url}
                    target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                    className="text-xl transition-all border tracking-wide duration-300 p-4  rounded-xl"
                    style={{
                      borderColor: getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#1a4f90ce' })
                        .color,
                    }}
                  >
                    {name}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="text-xl flex gap-x-5">
          {website?.config?.social ? (
            <>
              {website?.config?.social && (
                <SocialItems type={'facebook'} link={website.config.social['facebook']} menu={menu} />
              )}
              {website?.config?.social && (
                <SocialItems type={'instagram'} link={website.config.social['instagram']} menu={menu} />
              )}
              {website?.config?.social && (
                <SocialItems type={'twitter'} link={website.config.social['twitter']} menu={menu} />
              )}
            </>
          ) : (
            <ul className="list-none flex gap-x-5 items-center">
              <li
                style={{
                  ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '1a4f90ce' }),
                }}
                className="rounded-full text-gray-50 p-3 cursor-pointer hover:bg-[#1a3250dc] shadow-xl transition-all duration-300"
              >
                <BsFacebook
                  color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#e3e3e3' }).color}
                />
              </li>
              <li
                style={{
                  ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '1a4f90ce' }),
                }}
                className="bg-[#1a4f90ce] rounded-full text-gray-50 p-3 cursor-pointer hover:bg-[#1a3250dc] shadow-xl transition-all duration-300"
              >
                <BsInstagram
                  color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#e3e3e3' }).color}
                />
              </li>
              <li
                style={{
                  ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '1a4f90ce' }),
                }}
                className="bg-[#1a4f90ce] rounded-full text-gray-50 p-3 cursor-pointer hover:bg-[#1a3250dc] shadow-xl transition-all duration-300"
              >
                <BsTwitter
                  color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#e3e3e3' }).color}
                />
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

const MobileNavbar = ({ menu, website }: MenuVariantProps) => {
  const [show, setShow] = useState(false)
  return (
    <div
      className="block lg:hidden overflow-hidden"
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '#e1e1e15d' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className="flex justify-between  items-center px-4 py-4 bg-[#e1e1e15d] text-[#1a4f90ce]">
        <div>
          <a href="/">
            {website?.config?.logo ? (
              <img
                src={website.config.logo}
                alt={website?.name}
                className="cursor-pointer w-[40px] h-[40px] rounded-full border origin-center"
                style={{
                  borderColor: getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }).color,
                }}
              />
            ) : (
              <p
                className="font-bold"
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#1a4f90ce' }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                }}
              >
                {website?.name}
              </p>
            )}
          </a>
        </div>

        <button type="button" className="bg-white rounded-full p-2 text-xl shadow-xl" onClick={() => setShow(!show)}>
          {show ? (
            <IoMdClose color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#1a4f90ce' }).color} />
          ) : (
            <HiMenuAlt4
              color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#1a4f90ce' }).color}
            />
          )}
        </button>

        <ul
          className={`z-10 absolute w-full left-0 list-none flex flex-col gap-y-2 py-8 duration-700 ${
            show ? 'top-[4.3rem]' : 'top-[-200%]'
          }  flex flex-col items-center justify-center px-6`}
          style={{
            ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '#1a4f90ce' }),
            ...getSpacing(menu?.settings?.spacing),
          }}
        >
          <div className="py-6 flex flex-col gap-y-3">
            {menu.links.map(({ id, name, url, type }) => {
              return (
                <a href={url} target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}>
                  <motion.li
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                      transition: {
                        duration: 1,
                        delay: 0.4,
                      },
                    }}
                    key={id}
                    className="transition-all text-center duration-300 ease-in-out bg-[#dcddde] hover:bg-[#e2e2e2dc] w-full px-12  py-1 text-[#1a4f90ce] rounded-lg hover:text-[#0d1e33ce]"
                    style={{
                      ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#1a4f90ce' }),
                      ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                    }}
                  >
                    {name}
                  </motion.li>
                </a>
              )
            })}
          </div>
        </ul>
      </div>
    </div>
  )
}
