import { love, text, faq, gallery, contactUs } from '../templates/variants'

export function generateAITemplate(data: any) {
  console.log('received data', data)
  const homePage = data[0]
  const hero = homePage.components[0]
  const tagline = homePage.components[1].elements[0].data
  const aboutUs = homePage.components[2]
  const services = homePage.components[3]
  const skills = homePage.components[4]
  const testimonialTitle = homePage.components[5].elements[0].data
  const testimonials = homePage.components[6]

  //   const galleryPage = data[1]
  //   const galleryTitle = galleryPage.components[0].elements[0].data
  //   const gallerySubtitle = galleryPage.components[0].elements[1].data
  //   const galleryItems = galleryPage.components[1].elements[0].items

  return {
    id: '01',
    pages: [
      {
        slug: '/',
        name: 'Home',
        blocks: [
          {
            settings: { ...love.find(i => i.variant === 'VARIANT_2')?.settings },
            variables: [
              {
                data: [{ children: [{ text: hero.elements[0].data }], type: 'paragraph' }],
                name: 'TITLE',
                id: 'a316df00-7a2a-49ab-ad94-0cd2e33d4f33',
                category: 'TEXT',
              },
              {
                data: [{ children: [{ text: hero.elements[1].data }], type: 'paragraph' }],
                name: 'SUBTITLE',
                id: '1b9edabe-71ec-4c48-b612-4088cbd1c06e',
                category: 'TEXT',
              },
              {
                data: [
                  {
                    children: [
                      {
                        text: 'Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. ',
                      },
                    ],
                    type: 'paragraph',
                  },
                ],
                name: 'PARAGRAPH',
                id: '5c242d3c-f927-4c6a-bec4-522d42042f8a',
                category: 'TEXT',
              },
              {
                data: hero.elements[2].data,
                name: 'MEDIA',
                id: 'fc27fac9-961f-4112-939c-39f1811716f4',
                category: 'IMAGE',
              },
              {
                data: hero.elements[3].data,
                name: 'MEDIA2',
                id: '464faee8-4900-4651-842c-7a021e3f0cb4',
                category: 'IMAGE',
              },
            ],
            variant: 'VARIANT_2',
            type: 'ATTENTION_GRABBER',
          },
          {
            settings: {
              ...text.find(i => i.variant === 'VARIANT_8')?.settings,
              spacing: {
                padding: { top: 30, left: 0, bottom: 0, right: 0 },
                margin: { top: 0, left: 0, bottom: 0, right: 0 },
                enabled: true,
              },
            },
            variables: [
              {
                data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                name: 'MEDIA',
                id: 'd3b5c4ae-6c24-4450-94f6-11b813ea0e18',
                category: 'IMAGE',
              },
              {
                data: [{ children: [{ text: tagline }], type: 'paragraph' }],
                name: 'TITLE',
                id: '0190aa8d-dad2-4b6f-acf1-8ce115768f15',
                category: 'TEXT',
              },
              {
                data: [{ children: [{ text: '' }], type: 'paragraph' }],
                name: 'SUBTITLE',
                id: 'b81a7487-1f82-44a4-8a5c-acf355ea6515',
                category: 'TEXT',
              },
              {
                data: [{ children: [{ text: '' }], type: 'paragraph' }],
                name: 'PARAGRAPH',
                id: 'f6799ad5-92d8-466d-aed3-0ad31331d991',
                category: 'TEXT',
              },
            ],
            variant: 'VARIANT_8',
            type: 'TEXT',
          },
          {
            settings: {
              ...text.find(i => i.variant === 'VARIANT_1')?.settings,
              spacing: {
                padding: { top: 60, left: 0, bottom: 60, right: 0 },
                margin: { top: 0, left: 0, bottom: 0, right: 0 },
                enabled: true,
              },
              color: { background: { value: '#ffffff', enabled: true } },
            },
            variables: [
              {
                data: [{ children: [{ color: '#000000', text: aboutUs.elements[0].data }], type: 'paragraph' }],
                name: 'TITLE',
                id: '887fe231-cd21-462a-94a9-901f8c9e316a',
                category: 'TEXT',
              },
              {
                data: [
                  {
                    children: [{ color: '#0f0606', text: aboutUs.elements[1].data, bold: true, italic: true }],
                    type: 'paragraph',
                  },
                ],
                name: 'SUBTITLE',
                id: '877da3e2-bbb6-4cd7-bf45-f85a4a7c69eb',
                category: 'TEXT',
              },
              {
                data: [
                  {
                    children: [
                      {
                        color: '#000000',
                        text: aboutUs.elements[2].data,
                      },
                    ],
                    type: 'paragraph',
                    align: 'center',
                  },
                ],
                name: 'PARAGRAPH',
                id: 'fbbf6f1b-e989-43f2-9a36-587b2dd207f3',
                category: 'TEXT',
              },
            ],
            variant: 'VARIANT_1',
            type: 'TEXT',
          },
          {
            settings: {
              ...text.find(i => i.variant === 'VARIANT_6')?.settings,
              spacing: {
                padding: { top: 0, left: 0, bottom: 60, right: 0 },
                margin: { top: 0, left: 0, bottom: 0, right: 0 },
                enabled: true,
              },
            },
            variables: [
              {
                data: services.elements[1].items.map((i: any) => ({
                  image: i[0].data,
                  name: [{ children: [{ text: i[1].data }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: Math.random().toString(36).substring(7),
                })),
                name: 'MEDIAGROUP',
                id: 'a842f63c-ba63-4117-bd29-e1f9929f3e98',
                category: 'IMAGE',
              },
              {
                data: [
                  { children: [{ text: services.elements[0].data }], type: 'paragraph' },
                  { children: [{ text: '' }], type: 'paragraph' },
                ],
                name: 'TITLE',
                id: 'd0fbf91c-e461-43d7-9d90-5de8cf3e3620',
                category: 'TEXT',
              },
            ],
            variant: 'VARIANT_6',
            type: 'TEXT',
          },
          {
            settings: {
              ...faq.find(i => i.variant === 'VARIANT_3')?.settings,
              spacing: {
                padding: { top: 30, left: 0, bottom: 30, right: 0 },
                margin: { top: 0, left: 0, bottom: 0, right: 0 },
                enabled: true,
              },
              color: { background: { value: '#ffffff', enabled: true } },
            },
            variables: [
              {
                data: skills.elements[1].items.map((i: any) => ({
                  image: i[0].data,
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  title: [{ children: [{ text: i[1].data }], type: 'paragraph' }],
                  description: [{ children: [{ text: i[2].data }], type: 'paragraph' }],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  id: Math.random().toString(36).substring(7),
                })),
                name: 'ITEMS',
                id: '95d410ac-bde1-4f90-8f69-4e51d56420d1',
                category: 'TEXT',
              },
              {
                data: [{ children: [{ text: skills.elements[0].data, bold: true }], type: 'paragraph' }],
                name: 'TITLE',
                id: 'f88b72e0-962a-412a-9a23-f7852f13d06f',
                category: 'TEXT',
              },
            ],
            variant: 'VARIANT_3',
            type: 'FAQ',
          },
          {
            settings: {
              ...text.find(i => i.variant === 'VARIANT_8')?.settings,
              spacing: {
                padding: { top: 30, left: 0, bottom: 0, right: 0 },
                margin: { top: 30, left: 0, bottom: 0, right: 0 },
                enabled: true,
              },
            },
            variables: [
              {
                data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                name: 'MEDIA',
                id: 'ddbd6474-309e-4e96-82cd-15981b89ba79',
                category: 'IMAGE',
              },
              {
                data: [{ children: [{ text: testimonialTitle }], type: 'paragraph' }],
                name: 'TITLE',
                id: 'e007941e-acd6-44e5-b9d7-7983966595e2',
                category: 'TEXT',
              },
              {
                data: [{ children: [{ text: '' }], type: 'paragraph' }],
                name: 'SUBTITLE',
                id: '14703949-cf42-4a57-b333-b8969c3c1b7d',
                category: 'TEXT',
              },
              {
                data: [{ children: [{ text: '' }], type: 'paragraph' }],
                name: 'PARAGRAPH',
                id: 'a0349db8-ec59-4b2d-b8ba-32f14d678228',
                category: 'TEXT',
              },
            ],
            variant: 'VARIANT_8',
            type: 'TEXT',
          },
          {
            settings: {
              ...gallery.find(i => i.variant === 'VARIANT_14')?.settings,
              spacing: {
                padding: { top: 0, left: 0, bottom: 30, right: 0 },
                margin: { top: 0, left: 0, bottom: 0, right: 0 },
                enabled: true,
              },
            },
            variables: [
              {
                data: testimonials.elements[0].items.map((i: any) => ({
                  image: i[0].data,
                  title: [{ children: [{ text: i[1].data }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: i[2].data,
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: Math.random().toString(36).substring(7),
                })),
                name: 'MEDIA',
                id: '123',
                category: 'TEXT',
              },
            ],
            variant: 'VARIANT_14',
            type: 'GALLERY',
          },
        ],
      },
      {
        slug: '/gallery',
        name: 'Gallery',
        blocks: [
          {
            settings: {
              ...love.find(i => i.variant === 'VARIANT_8')?.settings,
              spacing: {
                padding: { top: 0, left: 0, bottom: 0, right: 0 },
                margin: { top: 0, left: 0, bottom: 0, right: 0 },
                enabled: true,
              },
              color: { background: { value: '#ffffff', enabled: true } },
            },
            variables: [
              {
                data: [
                  { children: [{ color: '#000000', className: 'uppercase', text: 'portfolio' }], type: 'paragraph' },
                ],
                name: 'TITLE',
                id: 'bb461b92-7661-4282-bd92-be5d730d0be2',
                category: 'TEXT',
              },
              {
                data: [
                  { children: [{ color: '#000000', text: ' Our Gallery ' }], type: 'paragraph' },
                  {
                    children: [{ highlightColor: '#e3dfd4', color: '#cac3af', text: '                         ' }],
                    type: 'paragraph',
                  },
                ],
                name: 'SUBTITLE',
                id: '0c52d1f8-7b5e-4dd6-a531-b6c973c1110a',
                category: 'TEXT',
              },
            ],
            variant: 'VARIANT_8',
            type: 'ATTENTION_GRABBER',
          },
          {
            settings: {
              ...gallery.find(i => i.variant === 'VARIANT_5')?.settings,
              spacing: {
                padding: { top: 0, left: 0, bottom: 120, right: 0 },
                margin: { top: 0, left: 0, bottom: 0, right: 0 },
                enabled: true,
              },
              color: { background: { value: '#ffffff', enabled: true } },
            },
            variables: [
              {
                data:
                  // galleryItems.map((i: any) => ({
                  //     image: i[0].data,
                  //     title: [{ children: [{ text: i[1].data, bold: true }], type: 'paragraph' }],
                  //     description: [
                  //         { children: [{ text: '' }], type: 'paragraph' },
                  //         { children: [{ text: i[2].data }], type: 'paragraph' },
                  //       ],
                  // }))
                  [
                    {
                      image:
                        'https://images.unsplash.com/photo-1675332690481-1183222e47e3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2Nzc1OTI3ODQ&ixlib=rb-4.0.3&q=80&w=1080',
                      description: [
                        { children: [{ text: '' }], type: 'paragraph' },
                        { children: [{ text: 'Snatched Crowdfunding ' }], type: 'paragraph' },
                        { children: [{ text: 'UX' }], type: 'paragraph' },
                      ],
                      id: '1',
                      title: [{ children: [{ text: 'Snatched Crowdfunding UX', bold: true }], type: 'paragraph' }],
                    },
                    {
                      image:
                        'https://images.unsplash.com/photo-1677253170860-b5f20dab49c3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2Nzc1OTI4MDg&ixlib=rb-4.0.3&q=80&w=1080',
                      description: [
                        { children: [{ text: '' }], type: 'paragraph' },
                        { children: [{ text: 'Snatched Crowdfunding ' }], type: 'paragraph' },
                        { children: [{ text: 'UX' }], type: 'paragraph' },
                      ],
                      id: '2',
                      title: [{ children: [{ text: 'Snatched Crowdfunding UX', bold: true }], type: 'paragraph' }],
                    },
                    {
                      image:
                        'https://images.unsplash.com/photo-1677142709742-fdaa4b7a495e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2Nzc1OTI4NzU&ixlib=rb-4.0.3&q=80&w=1080',
                      description: [
                        { children: [{ text: '' }], type: 'paragraph' },
                        { children: [{ text: 'Snatched Crowdfunding ' }], type: 'paragraph' },
                        { children: [{ text: 'UX' }], type: 'paragraph' },
                      ],
                      id: '3',
                      title: [{ children: [{ text: 'Snatched Crowdfunding UX', bold: true }], type: 'paragraph' }],
                    },
                    {
                      image:
                        'https://images.unsplash.com/photo-1675589052020-0489b8a84f09?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2Nzc1OTI5MDY&ixlib=rb-4.0.3&q=80&w=1080',
                      description: [
                        { children: [{ text: '' }], type: 'paragraph' },
                        { children: [{ text: 'Snatched Crowdfunding ' }], type: 'paragraph' },
                        { children: [{ text: 'UX' }], type: 'paragraph' },
                      ],
                      id: '4',
                      title: [{ children: [{ text: 'Snatched Crowdfunding UX', bold: true }], type: 'paragraph' }],
                    },
                    {
                      image:
                        'https://images.unsplash.com/photo-1676968987747-da6f4df4804d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2Nzc1OTI5MzA&ixlib=rb-4.0.3&q=80&w=1080',
                      description: [
                        { children: [{ text: '' }], type: 'paragraph' },
                        { children: [{ text: 'Snatched Crowdfunding ' }], type: 'paragraph' },
                        { children: [{ text: 'UX' }], type: 'paragraph' },
                      ],
                      id: '5',
                      title: [{ children: [{ text: 'Snatched Crowdfunding UX', bold: true }], type: 'paragraph' }],
                    },
                    {
                      image:
                        'https://images.unsplash.com/photo-1674855086643-b884fd82fabb?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHJhbmRvbXx8fHx8fHx8fDE2Nzc1OTI5ODA&ixlib=rb-4.0.3&q=80&w=1080',
                      description: [
                        { children: [{ text: '' }], type: 'paragraph' },
                        { children: [{ text: 'Snatched Crowdfunding ' }], type: 'paragraph' },
                        { children: [{ text: 'UX' }], type: 'paragraph' },
                      ],
                      id: '6',
                      title: [{ children: [{ text: 'Snatched Crowdfunding UX', bold: true }], type: 'paragraph' }],
                    },
                    {
                      image:
                        'https://images.unsplash.com/photo-1452570053594-1b985d6ea890?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxMHx8YmlyZHxlbnwwfHx8fDE2Nzc1OTMwMjQ&ixlib=rb-4.0.3&q=80&w=1080',
                      description: [
                        { children: [{ text: '' }], type: 'paragraph' },
                        { children: [{ text: 'Snatched Crowdfunding ' }], type: 'paragraph' },
                        { children: [{ text: 'UX' }], type: 'paragraph' },
                      ],
                      id: '7',
                      title: [{ children: [{ text: 'Snatched Crowdfunding UX', bold: true }], type: 'paragraph' }],
                    },
                    {
                      image:
                        'https://images.unsplash.com/photo-1440288736878-766bd5839edb?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxjaGlsZHxlbnwwfHx8fDE2Nzc1OTMwNTg&ixlib=rb-4.0.3&q=80&w=1080',
                      description: [
                        { children: [{ text: '' }], type: 'paragraph' },
                        { children: [{ text: 'Snatched Crowdfunding ' }], type: 'paragraph' },
                        { children: [{ text: 'UX' }], type: 'paragraph' },
                      ],
                      id: '8',
                      title: [{ children: [{ text: 'Snatched Crowdfunding UX', bold: true }], type: 'paragraph' }],
                    },
                  ],
                name: 'MEDIA',
                id: 'ecc17a49-29c2-47cf-b305-81c5f70925bb',
                category: 'TEXT',
              },
            ],
            variant: 'VARIANT_5',
            type: 'GALLERY',
          },
        ],
      },
      {
        slug: '/contact-us',
        name: 'Contact us',
        blocks: [
          {
            settings: {
              ...love.find(i => i.variant === 'VARIANT_8')?.settings,
              color: { background: { value: '#ffffff', enabled: true } },
            },
            variables: [
              {
                data: [
                  { children: [{ color: '#000000', className: 'uppercase', text: 'portfolio' }], type: 'paragraph' },
                ],
                name: 'TITLE',
                id: '21d8ddf8-3940-458e-b7fd-d5533a95961b',
                category: 'TEXT',
              },
              {
                data: [
                  { children: [{ color: '#000000', text: 'Contact Us' }], type: 'paragraph' },
                  {
                    children: [{ highlightColor: '#d0c6bc', color: '#b38761', text: '                       ' }],
                    type: 'paragraph',
                  },
                ],
                name: 'SUBTITLE',
                id: '4dac7bd4-c39a-4bb6-8522-662f3c80b4a9',
                category: 'TEXT',
              },
            ],
            variant: 'VARIANT_8',
            type: 'ATTENTION_GRABBER',
          },
          {
            settings: {
              ...contactUs.find(i => i.variant === 'VARIANT_7')?.settings,
              spacing: {
                padding: { top: 0, left: 0, bottom: 60, right: 0 },
                margin: { top: 0, left: 0, bottom: 0, right: 0 },
                enabled: true,
              },
            },
            variables: [
              {
                data: [
                  {
                    name: 'First Name',
                    id: 'eae2f55e-67c9-423b-b84d-65810ac6b571',
                    placeholder: 'First Name',
                    type: 'text',
                    error: 'First Name is required',
                  },
                  {
                    name: 'last Name',
                    id: 'fa88d4d9-fba0-4569-9a1e-edd34d4b9135',
                    placeholder: 'last Name',
                    type: 'text',
                    error: 'last Name is required',
                  },
                  {
                    name: 'Email',
                    id: '2b078cd0-c24a-498a-aeae-0c628b5bf5d2',
                    placeholder: 'Email',
                    type: 'email',
                    error: 'email is required',
                  },
                  {
                    name: 'Message',
                    id: '5b0e9f76-b9d4-449f-8ccf-b003b44914de',
                    placeholder: 'message',
                    type: 'textarea',
                    error: 'message is required',
                  },
                ],
                name: 'INPUTS',
                id: '04030965-3de1-422c-b549-53fa74bcf90e',
                category: 'TEXT',
              },
              {
                data: 'https://images.unsplash.com/photo-1525598912003-663126343e1f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwyfHxwaG9uZXxlbnwwfHx8fDE2Nzc1OTE1Mjc&ixlib=rb-4.0.3&q=80&w=1080',
                name: 'MEDIA',
                id: '6b58fb01-2494-412d-a7c6-753b68a61f79',
                category: 'IMAGE',
              },
              {
                data: [{ children: [{ text: 'Visit Us' }], type: 'paragraph' }],
                name: 'SUBTITLE',
                id: '3714771c-c3ba-4bab-8133-b87eb65ee916',
                category: 'TEXT',
              },
              {
                data: [
                  { children: [{ text: '' }], type: 'paragraph' },
                  { children: [{ text: 'Get In Touch' }], type: 'paragraph' },
                ],
                name: 'TITLE',
                id: '18d1a310-f121-4e5a-971d-823b225c0717',
                category: 'TEXT',
              },
              {
                data: {
                  settings: {
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    borderColor: '#ffffff',
                    backgroundColor: '#000000',
                    borderRadius: '0px',
                    color: '#ffffff',
                    borderWidth: '1px',
                    display: 'block',
                    width: '249px',
                    fontSize: '30px',
                    height: '60px',
                    maxWidth: '100%',
                  },
                  visible: true,
                  link: null,
                  value: 'Send ',
                },
                name: 'BUTTON_1',
                id: '5610ecf4-bd26-4b08-b872-615aa7df04f4',
                category: 'BUTTON',
              },
            ],
            variant: 'VARIANT_7',
            type: 'CONTACT_US',
          },
        ],
      },
    ],
    newMenu: [
      { name: 'Home', type: 'internal', url: '/' },
      { name: 'Gallery', type: 'internal', url: '/gallery' },
      { name: 'Contact us', type: 'internal', url: '/contact-us' },
    ],
    newMenuConfig: {
      settings: { backgroundColor: { color: '#FFFFFF', enabled: false } },
      links: [
        { name: 'Home', type: 'internal', url: '/' },
        { name: 'Contact Us', type: 'email', url: 'hello@example.com' },
      ],
      id: 'VARIANT_14',
      tags: [],
    },
    newFooter: {
      settings: {
        backgroundColor: { color: '#bdbdbdd5', enabled: true },
        textColor: { color: '#000000', enabled: true },
      },
      designedBy: 'Plly Staff',
      id: 'VARIANT_3',
      categories: [
        {
          name: 'Explore',
          links: [{ name: 'Home', type: 'internal', url: '/' }],
        },
      ],
      tags: [],
    },
    links: [],
  }
}
