import EmojiPicker, { IEmojiData } from 'emoji-picker-react'
import { forwardRef, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ModelSortDirection, Post } from 'src/API'
import { UserContext } from 'src/components/context/UserContext'
import ArrowRight from 'src/icons/ArrowRight'
import ImageCreator from 'src/icons/ImageCreator'
import Send from 'src/icons/Send'
import SmileEmoji from 'src/icons/SmileEmoji'
import { allCommentByPostID, createCommentPost } from 'src/services/comment.service'
import Comment from './Comment'

type CommentsProps = {
  hideCommentInput: boolean
  post: Post
  newLimit: number
  setNewLimit: any
  showEmojiIcons: boolean
  setShowEmojiIcons: any
  loadMoreCount: number
  commentModal?: boolean
}

const Comments = forwardRef<HTMLInputElement, CommentsProps>(
  (
    {
      hideCommentInput,
      post,
      newLimit,
      setNewLimit,
      loadMoreCount,
      showEmojiIcons,
      setShowEmojiIcons,
      commentModal = false,
    },
    ref,
  ) => {
    const commentInputRef = ref as React.RefObject<HTMLInputElement>
    const { user } = useContext(UserContext)
    const [comments, setComments] = useState<any[]>([])
    const [firstToken, setFirstToken] = useState<string | null>()
    const [nextToken, setNextToken] = useState<string | null>()
    const [showEmojiPicker, setShowEmojiPicker] = useState(false)
    const [inputValue, setInputValue] = useState('')
    // const [showEmojiIcons, setShowEmojiIcons] = useState(false)
    // const [newLimit, setNewLimit] = useState(limit)

    const handleAddComment = (e: any) => {
      e.preventDefault()
      const commentInput = commentInputRef?.current?.value
      if (commentInputRef?.current) commentInputRef.current.value = ''
      setShowEmojiPicker(false)
      if (commentInput) {
        const comment = {
          userID: user.id,
          postID: post.id,
          content: commentInput,
        }
        createCommentPost(comment)
          .then(() =>
            allCommentByPostID(post.id, {
              limit: newLimit,
              sortDirection: ModelSortDirection.DESC,
            }),
          )
          .then(res => {
            setComments(res?.items || [])
            setNextToken(res?.nextToken)
          })
          .catch(() => {
            if (commentInputRef?.current) commentInputRef.current.value = commentInput
          })
      }
    }

    const handleLoadMore = async () => {
      const newComments = await allCommentByPostID(post.id, {
        limit: loadMoreCount,
        sortDirection: ModelSortDirection.DESC,
        nextToken,
      })

      setComments([...comments, ...(newComments?.items || [])])
      setNextToken(newComments?.nextToken)
      setNewLimit(newLimit + loadMoreCount)
    }

    const resetCommentsArea = () => {
      if (!comments) return
      if (comments.length <= 0) return
      setNewLimit(loadMoreCount)
      setComments(comments.slice(0, loadMoreCount))
      setNextToken(firstToken)
    }

    const handleEmojiSelect = (_event: React.MouseEvent<Element, MouseEvent>, data: IEmojiData) => {
      const input = commentInputRef?.current
      if (!input) return
      const cursorPosition = input.selectionStart
      if (cursorPosition === null) return
      const textBeforeCursorPosition = input.value.substring(0, cursorPosition)
      const textAfterCursorPosition = input.value.substring(cursorPosition, input.value.length)
      input.value = textBeforeCursorPosition + data.emoji + textAfterCursorPosition
      input.focus()
    }

    useEffect(() => {
      if (!post.id) return
      allCommentByPostID(post.id, { limit: newLimit, sortDirection: ModelSortDirection.DESC }).then(res => {
        setComments(res?.items || [])
        setFirstToken(res?.nextToken)
        setNextToken(res?.nextToken)
      })
    }, [post, newLimit])

    useEffect(() => {
      if (hideCommentInput) resetCommentsArea()
    }, [hideCommentInput])

    return (
      <div className="border-b border-x dark:border-x-0 rounded-bl-lg rounded-br-lg dark:rounded-none ">
        <div className={` ${!hideCommentInput && comments?.length === 0 && !commentModal ? 'hidden' : 'block'} `}>
          <div className="pt-5 px-4 flex items-center gap-x-5">
            <div>
              <div className="w-[20px] h-[20px] rounded-full bg-[#D9D9D9] md:w-[30px] md:h-[30px]">
                {user?.profilePicture && (
                  <Link to={`/profile/${user?.id}`}>
                    <img className="w-full h-full rounded-full" src={user?.profilePicture} alt="" />
                  </Link>
                )}
              </div>
            </div>
            <form className="w-full" onSubmit={handleAddComment}>
              <div className="flex border-[#181818] dark:border-white border py-1 rounded">
                <div className="flex items-center pl-3">
                  <div className="flex items-center space-x-3">
                    {showEmojiIcons ? (
                      <>
                        <button type="button">
                          <ImageCreator className="text-[#8D8D8D]" />
                        </button>
                        <button type="button" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                          <SmileEmoji size={17} className="text-[#8D8D8D]" />
                        </button>
                      </>
                    ) : (
                      <button onClick={() => setShowEmojiIcons(true)} type="button">
                        <ArrowRight size={18} className="text-[#fff] dark:text-[#000]" />
                      </button>
                    )}
                  </div>
                </div>
                <input
                  onChange={e => setInputValue(e.target.value)}
                  type="text"
                  className="block w-full px-4 py-3 text-[15px] text-black dark:text-white bg-white dark:bg-black rounded focus:outline-0"
                  placeholder="Add a comment... "
                  required
                  ref={commentInputRef}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      handleAddComment(e)
                    }
                  }}
                />
                <div className="flex md:hidden items-center pl-3">
                  <div className="flex items-center space-x-3 pr-4">
                    <button className="rotate-45" type="button">
                      <Send size={17} className={` ${inputValue === '' && 'text-[#8D8D8D] dark:text-[#9ca3af]'} `} />
                    </button>
                  </div>
                </div>
              </div>
              {showEmojiPicker && (
                <div className="mt-3">
                  <EmojiPicker onEmojiClick={handleEmojiSelect} pickerStyle={{ width: '100%' }} />
                </div>
              )}
            </form>
          </div>
          <div className="pt-5">
            {comments.map((comment: any) => (
              <Comment key={comment.id} comment={comment} />
            ))}
          </div>
        </div>

        {comments.length > 0 && nextToken && (
          <div className="px-4 mb-3">
            <button onClick={handleLoadMore} type="button" className="dark:text-white font-semibold text-sm px-2 py-1">
              More comments
            </button>
          </div>
        )}
      </div>
    )
  },
)

export default Comments
