import clsx from 'clsx'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { WebsiteContext } from 'src/components/context/WebsiteContext'
import { Loader } from 'src/components/loader'
import EditPlus from 'src/icons/EditPlus'
import ExpandIcon from 'src/icons/Hamburger'
import LinkIcon from 'src/icons/Link'
import Settings from 'src/icons/Settings'
import { createPageRecord, getPagesByWebsite, getWebsiteByID, saveWebsite } from 'src/lib/services/website.service'
import { upsertMenuLinks } from 'src/services/website.service'
import useWebsite from 'src/store/website'
import { formatMenuLinks } from 'src/util/menu'
import { NewMenuLink, NewMenuLinkType } from 'src/_polly/components/src/menu/menu'
import { v4 as uuid } from 'uuid'
import { ReactComponent as PencilIcon } from '../../../icons/Pencil.svg'
import AdvancedMenuFooter from '../../advanced/AdvancedMenuFooter'
import WebsiteSettings from '../../WebsiteSettings'
import NewMenuAddNewLink from './AddNewLink'
import LinkItem from './LinkItem'

type MenuLinksProps = {
  onSave?: () => void
  onBack?: () => void
  onExpand?: () => void
  showPreview?: () => void
  menuExpanded?: boolean
  website?: any
}

export default function MenuLinks({ onSave, onExpand, menuExpanded, showPreview, website, onBack }: MenuLinksProps) {
  const websiteContext = useContext(WebsiteContext)
  const store = useWebsite(state => state)
  const params = useParams()

  const [menuLinks, setMenuLinks] = useState<NewMenuLink[]>([])
  const [loading, setLoading] = useState(false)
  const [linkEdit, setLinkEdit] = useState(false)
  const [deleteItem, setDeleteItem] = useState<NewMenuLink | null>(null)
  const [editItem, setEditItem] = useState<NewMenuLink | null>(null)
  const [linksUpdated, setLinksUpdated] = useState<'update' | 'add' | 'sort' | 'duplicate' | 'delete' | 'none'>('none')
  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const [pages, setPages] = useState<any>([])
  const [isWebSetting, setIsWebSetting] = useState<boolean>(false)

  // query received by website setting modal
  const url: any = document?.location
  const query: any = new URL(url)?.searchParams
  const webSetting = query?.get('webSetting')
  useEffect(() => {
    setShowSettingsModal(webSetting)
    setIsWebSetting(webSetting)
  }, [])

  const handleLinkAdd = (link: NewMenuLink) => {
    setMenuLinks(prev => [
      ...prev,
      {
        ...link,
        id: uuid(),
      },
    ])
    setLinksUpdated('add')
    setLinkEdit(false)
    setEditItem(null)
  }

  const fetchWebsite = () => {
    setLoading(true)
    getWebsiteByID(websiteContext.websiteID).then(_website => {
      setMenuLinks(JSON.parse(_website?.newMenu ?? '[]'))
      setLoading(false)
    })
  }

  const handleSave = async (callOnSave = true) => {
    setLoading(true)
    await saveWebsite({ id: websiteContext.websiteID, newMenu: JSON.stringify(menuLinks) })
    if (callOnSave) onSave?.()
    setLoading(false)
  }

  const handleMoveUp = (index: number) => {
    if (index === 0) return
    const newLinks = [...menuLinks]
    const temp = newLinks[index]
    newLinks[index] = newLinks[index - 1]
    newLinks[index - 1] = temp
    setMenuLinks(newLinks)
    setLinksUpdated('sort')
  }

  const handleMoveDown = (index: number) => {
    if (index === menuLinks.length - 1) return
    const newLinks = [...menuLinks]
    const temp = newLinks[index]
    newLinks[index] = newLinks[index + 1]
    newLinks[index + 1] = temp
    setMenuLinks(newLinks)
    setLinksUpdated('sort')
  }

  const handleDuplicate = (index: number) => {
    const newLinks = [...menuLinks]
    if (newLinks[index]?.type === NewMenuLinkType.INTERNAL) {
      const random = Math.random().toString(36).substring(2, 5)
      const path = `/${random}-${newLinks[index]?.name.toLowerCase().replace(/ /g, '-')}`
      const blocks = pages?.find((data: any) => data?.path === newLinks[index]?.url)
      newLinks.splice(index + 1, 0, { ...newLinks[index], id: uuid(), url: path })
      const options = {
        websiteID: params.id as string,
        name: newLinks[index]?.name,
        path,
        blocks: blocks?.blocks,
      }
      createPageRecord(options)
    } else {
      newLinks.splice(index + 1, 0, { ...newLinks[index], id: uuid() })
    }
    setMenuLinks(newLinks)
    setLinksUpdated('duplicate')
    setEditItem(null)
  }

  const handleDelete = async (type: 'permanent' | 'move-to-all-links') => {
    setMenuLinks(prev => prev.filter(link => link.id !== deleteItem?.id))

    if (type === 'move-to-all-links' && deleteItem) {
      setLoading(true)
      await upsertMenuLinks(websiteContext.websiteID, {
        name: deleteItem.name,
        url: deleteItem.url,
        type: deleteItem.type,
      })
      await handleSave(false)
    }

    setDeleteItem(null)
    setLinksUpdated('delete')
    setEditItem(null)
  }

  const handleEdit = async (link: Partial<NewMenuLink>) => {
    setMenuLinks(prev => prev.map(item => (item.id === editItem?.id ? { ...item, ...link } : item)))
    setLinksUpdated('update')
    setEditItem(null)
  }

  const handleAddNewLink = async (link: Omit<NewMenuLink, 'id'>) => {
    setMenuLinks(prev => [
      ...prev,
      {
        ...link,
        id: uuid(),
      },
    ])
    setLinksUpdated('add')
    setLinkEdit(false)
  }

  useEffect(() => {
    fetchWebsite()
  }, [])

  useEffect(() => {
    if (linksUpdated !== 'none') handleSave()
  }, [linksUpdated])

  useEffect(() => {
    store.updateNewMenu(menuLinks)
    getPagesByWebsite(websiteContext.websiteID).then(data => setPages(data))
  }, [menuLinks])

  return (
    <div className="px-4">
      <Loader show={loading} />

      <div
        className={clsx(
          'fixed z-50 top-0 right-0 bottom-0 left-0 justify-center items-center',
          !deleteItem && 'hidden',
        )}
      >
        <div className="relative w-full h-full bg-white/90 dark:bg-black/90 flex justify-center items-center">
          <div className="w-[362px] bg-white dark:bg-black border-[1px] rounded-[6px] border-[#E90570] border-solid  relative">
            <div className="pt-[13px] pb-[20px]">
              <p className="text-[25px] text-black dark:text-white text-center">Remove link</p>
              <p className="text-[25px] text-black dark:text-white text-center">{deleteItem?.name}</p>
            </div>
            <div className="grid border-t dark:border-white mx-[10px] border-black grid-cols-2">
              <button
                onClick={() => handleDelete('move-to-all-links')}
                type="button"
                className="border-r text-[19px] dark:border-white dark:text-white border-black text-center text-black my-[16px] "
              >
                Save outside <br /> Menu Bar
              </button>
              <button
                onClick={() => handleDelete('permanent')}
                type="button"
                className="dark:text-white text-[19px] text-black my-[16px]"
              >
                Delete forever
              </button>
            </div>
            <button onClick={() => setDeleteItem(null)} type="button" className="absolute top-[-4px] right-[8px]">
              <svg
                width="29"
                height="42"
                className="fill-white stroke-black dark:fill-black dark:stroke-white"
                viewBox="0 0 29 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="0.5" y="13.5" width="28" height="28" rx="4.5" />
                <path d="M10.705 22.1254L14.425 26.9554L18.13 22.1254H19.015L14.845 27.5254L18.895 32.7754H18.01L14.425 28.0954L10.825 32.7754H9.92495L13.975 27.5254L9.80495 22.1254H10.705Z" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className="px-2 pt-[25px]">
        <div className="flex items-center h-[46px] px-[5px] mt-[2px] border-t border-b border-black dark:border-[#D6D6D6]">
          <h2 className="w-full font-bold text-[15px] dark:text-white">Main Navigation</h2>
          <div className="w-full flex items-center gap-[28px]">
            <button type="button" onClick={showPreview}>
              <PencilIcon />
            </button>
            <button type="button" onClick={() => handleSave(true)}>
              <LinkIcon
                size={24}
                title="Reload"
                className="stroke-black fill-black dark:fill-white dark:stroke-white"
              />
            </button>
            {store.website && (
              <AdvancedMenuFooter
                mode="icon"
                menu={{
                  ...store.website.newMenuConfig,
                  links: store.website.newMenu,
                }}
                component="menu"
              />
            )}
          </div>
          <button type="button" onClick={onExpand}>
            <ExpandIcon size={24} className="stroke-black fill-black dark:fill-white dark:stroke-white" />
          </button>
        </div>

        {menuExpanded && (
          <div className="pt-[20px] border-b border-black dark:border-[#D6D6D6]">
            <ul className="list-none p-0">
              {formatMenuLinks({ links: menuLinks, website }).map(link => (
                <li key={link.id}>
                  <a href={link.url} className="w-full font-bold pb-[20px] text-[15px] block dark:text-white">
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="h-[76px] w-full border-b-[1px] border-[#D9D9D9] flex justify-between px-[20px] items-center">
          <p className="md:text-[19px] text-left dark:text-[#F3F3F3]">Site Name + Logo</p>
          <button type="button" onClick={() => setShowSettingsModal(true)} className="pr-2">
            <Settings size={27} className="text-[#8D8D8D] dark:text-white hidden md:block" />
            <Settings size={22} className="text-[#8D8D8D] dark:text-white md:hidden" />
          </button>
        </div>

        <div>
          {menuLinks.map((link: NewMenuLink, idx) => (
            <LinkItem
              key={link.id}
              index={idx}
              item={link}
              onEditClick={() => setEditItem(link)}
              onEditBack={() => setEditItem(null)}
              defaultValue={editItem}
              onUpClick={() => handleMoveUp(idx)}
              onDownClick={() => handleMoveDown(idx)}
              onDuplicateClick={() => handleDuplicate(idx)}
              onDeleteClick={() => setDeleteItem(link)}
              onAdd={handleEdit}
              handleLinkAdd={handleLinkAdd}
            />
          ))}
        </div>
      </div>

      <div className="px-2 w-full">
        <div className="h-[76px] w-full border-b-[1px] border-[#D9D9D9] flex justify-between px-[20px] items-center">
          <div className="flex items-center gap-[18px]">
            <LinkIcon
              height={10}
              className="stroke-[#8D8D8D] fill-[#8D8D8D] dark:fill-[#D9D9D9] dark:stroke-[#D9D9D9]"
            />
            <p className="md:text-[19px] text-left dark:text-white">Create New Link</p>
          </div>
          <button type="button" onClick={() => setLinkEdit(true)}>
            <EditPlus
              size={50}
              fill="none"
              className="fill-white dark:fill-black stroke-[#E90570] dark:stroke-[#E90570]"
            />
          </button>
        </div>

        {linkEdit && (
          <div className="">
            <div className="fixed z-30 inset-0 lg:max-w-[500px] dark:bg-black bg-white" />
            <div className="fixed top-[20vh] px-[22px] z-40 inset-0 lg:max-w-[500px]">
              <NewMenuAddNewLink
                newLink
                handleLinkAdd={handleLinkAdd}
                editBack={() => setLinkEdit(false)}
                onAdd={link => {
                  handleAddNewLink(link as any)
                }}
              />
            </div>
          </div>
        )}
      </div>

      <WebsiteSettings
        websiteLogo={JSON.parse(website?.config ?? '{}')?.logo}
        open={showSettingsModal}
        fetchWebsite={fetchWebsite}
        onClose={() => {
          setShowSettingsModal(false)
          fetchWebsite()
        }}
        id={website?.id}
        onMenuBack={onBack}
        isWebSetting={isWebSetting}
      />

      {store.website && (
        <AdvancedMenuFooter
          mode="button"
          menu={{
            ...store.website.newMenuConfig,
            links: store.website.newMenu,
          }}
          component="menu"
        />
      )}
    </div>
  )
}
