import { Dialog, Transition } from '@headlessui/react'
import axios from 'axios'
import { Fragment, useContext, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FaCamera } from 'react-icons/fa'
import AuthenticatedPage from 'src/components/AuthenticatedPage'
import { UserContext } from 'src/components/context/UserContext'
import { ReactComponent as PhotoUpload } from 'src/components/icons/PhotoUp.svg'
import AuthLayout from 'src/components/layout/AuthLayout'
import { Loader } from 'src/components/loader'
import ImageUploader from 'src/components/new/ImageUploader'
import ProfileUpload from 'src/icons/ProfileUpload'
import { getUserByUsername, saveUser } from 'src/services/user.service'
import useAddress from './useAddress'

type Inputs = {
  username: string
  firstName: string
  lastName: string
  country: string
  state: string
  city: string
  description: string
}

function ProfileSetup() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [imagePreview, setImagePreview] = useState<any>(undefined)
  const [uploadIcon, setUploadIcon] = useState(false)
  const { user } = useContext(UserContext)
  const [countryName, setCountryName] = useState('')
  const [stateName, setStateName] = useState('')
  const [cityName, setCityName] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const { token, countries } = useAddress()
  const [states, setStates] = useState<any>([])
  const [cities, setCities] = useState<any>([])

  const fetchStates = () => {
    axios
      .get(`https://www.universal-tutorial.com/api/states/${countryName || user?.country}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      })
      .then((data: any) => setStates(data))
  }
  const fetchCities = () => {
    axios
      .get(`https://www.universal-tutorial.com/api/cities/${stateName || user?.state}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      })
      .then((data: any) => setCities(data))
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = async data => {
    setLoading(true)
    if (user) {
      const userExists = !user?.username && (await getUserByUsername(data?.username))
      if (userExists) {
        // eslint-disable-next-line no-alert
        alert('Username already exists')
        setLoading(false)
        return
      }
      await saveUser({
        id: user?.id,
        username: data?.username || user?.username,
        firstName: data?.firstName || user?.firstName,
        lastName: data?.lastName || user?.lastName,
        country: countryName || user?.country,
        state: stateName || user?.state,
        city: cityName || user?.city,
        bio: data.description || user?.bio,
        profilePicture: imagePreview || user?.profilePicture,
      })
      if (!user?.username) {
        window.location.href = '/home'
      } else {
        window.location.href = `/profile/${user?.id}`
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    if (countryName || user?.country) {
      fetchStates()
    }
    if (stateName || user?.state) {
      fetchCities()
    }
  }, [countryName, stateName, user?.country, user?.state])

  useEffect(() => {
    if (user) {
      setValue('username', user?.username)
      setValue('firstName', user?.firstName)
      setValue('lastName', user?.lastName)
      setValue('description', user?.bio)
      setValue('country', user?.country)
      setValue('state', user?.state)
      setValue('city', user?.city)
      setImagePreview(user?.profilePicture)
    }
  }, [user])

  return (
    <AuthenticatedPage>
      <AuthLayout>
        <Loader show={loading} />
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={() => setIsOpen(false)}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 dark:bg-[#8080802e] bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto bg-white md:bg-transparent">
              <div className="flex min-h-full items-center justify-center md:p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full transform md:overflow-hidden md:rounded-2xl bg-white pb-12 md:pb-6 h-screen flex flex-col  md:h-full dark:bg-black text-left align-middle md:shadow-xl transition-all md:border-4 border-[#4E4E4E] md:p-10 p-4 py-6 md:max-w-[730px] max-w-full relative">
                    <ImageUploader
                      onSubmit={url => {
                        setImagePreview(url)
                        setIsOpen(false)
                      }}
                      onClose={() => setIsOpen(false)}
                      buttonLabel="Select Image"
                      modalMode
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
        <form className="space-y-8  px-[24px] " onSubmit={handleSubmit(onSubmit)}>
          {/* {error && <p className="text-secondary.main mb-4 text-center">{error}</p>} */}
          <div className="flex lg:gap-[40px] gap-[15px] w-full items-end justify-center">
            <div className="flex flex-col lg:gap-[32px] gap-[20px] lg:w-[265px] w-full">
              {/* {!user?.username ? ( */}
              <input
                className={`w-full px-5 py-2.5 bg-white text-[15px] rounded focus:outline-none outline-0 ${
                  (errors.username && errors.username.type === 'required') || (error && 'placeholder:text-red-500')
                }`}
                type="text"
                placeholder={
                  (errors.username && errors.username.type === 'required') || error ? 'Username Required' : 'Username'
                }
                defaultValue={user?.username}
                {...register('username', {
                  required: { value: true, message: 'Username is required' },
                  pattern: {
                    value: /^[a-zA-Z0-9]+$/,
                    message: 'Username should contain only letters and numbers',
                  },
                })}
              />
              <input
                className={`w-full px-5 py-2.5 bg-white text-[15px] rounded focus:outline-none outline-0 ${
                  (errors.firstName && errors.firstName.type === 'required') || (error && 'placeholder:text-red-500')
                }`}
                type="text"
                placeholder={
                  (errors.firstName && errors.firstName.type === 'required') || error
                    ? 'First Name Required'
                    : 'First Name'
                }
                defaultValue={user?.firstName}
                {...register('firstName', {
                  required: { value: true, message: 'First Name is required' },
                })}
              />
              <input
                className={`w-full px-5 py-2.5 bg-white text-[15px] rounded focus:outline-none outline-0 ${
                  (errors.lastName && errors.lastName.type === 'required') || (error && 'placeholder:text-red-500')
                }`}
                type="text"
                placeholder={
                  (errors.lastName && errors.lastName.type === 'required') || error ? 'Last Name Required' : 'Last Name'
                }
                defaultValue={user?.lastName}
                {...register('lastName', {
                  required: { value: true, message: 'Last Name is required' },
                })}
              />
              <select
                name="country"
                className="w-full px-5 py-3 bg-white text-[15px] rounded focus:outline-none outline-0 appearance-none"
                id=""
                onChange={(e: any) => {
                  setCountryName(e.target.value)
                  setStateName('')
                  setCityName('')
                  setError('')
                }}
              >
                <option value="">{user?.country || 'Select Country'}</option>
                {user?.country !== 'United States' && <option value="United States">United States</option>}
                {countries?.data?.map((country: any, idx: number) => (
                  <option key={idx} value={country?.country_name}>
                    {country?.country_name}
                  </option>
                ))}
              </select>
              <select
                name="states"
                className="w-full px-5 py-3 bg-white text-[15px] rounded focus:outline-none outline-0 appearance-none"
                id=""
                onChange={(e: any) => {
                  setStateName(e.target.value)
                  setCityName('')
                  setError('')
                }}
              >
                <option value="">{user?.state || 'Select State'}</option>
                {states?.data?.map((state: any, idx: number) => (
                  <option key={idx} value={state?.state_name}>
                    {state?.state_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="lg:w-[265px] w-full flex flex-col lg:gap-[32px] gap-[20px] justify-between">
              <label
                onClick={() => setIsOpen(!isOpen)}
                htmlFor="upload-image"
                className="bg-white cursor-pointer lg:w-[144px] lg:h-[144px] w-[112px] h-[112px] rounded-full flex justify-center items-center mx-auto"
              >
                {imagePreview || user?.profilePicture ? (
                  <div
                    onMouseEnter={() => setUploadIcon(true)}
                    onMouseLeave={() => setUploadIcon(false)}
                    className="relative"
                  >
                    <img
                      src={imagePreview || user?.profilePicture}
                      className="lg:w-[140px] lg:h-[140px] w-[108px] h-[108px] rounded-full object-cover object-center"
                      alt=""
                    />
                    <div
                      className={`absolute top-0 bg-black/50 duration-700 lg:w-[140px] lg:h-[140px] w-[108px] h-[108px] rounded-full flex flex-col justify-center items-center ${
                        uploadIcon ? 'opacity-100' : 'opacity-0'
                      }`}
                    >
                      <FaCamera fill="#fff" size={40} title="Upload Profile Image" />
                    </div>
                  </div>
                ) : (
                  <button
                    type="button"
                    onClick={() => setError('')}
                    className={`lg:w-[140px] lg:h-[140px] w-[108px] h-[108px] rounded-full border ${
                      error === 'image' ? 'border-red-500 text-red-500' : 'border-[#909090] text-black'
                    } border-dashed flex flex-col justify-center items-center`}
                  >
                    {error === 'image' ? (
                      <>
                        <ProfileUpload size={59} height={43} fill="#ef4444" className="text-red-500 hidden lg:block" />
                        <ProfileUpload size={39} height={33} fill="#ef4444" className="text-red-500 block lg:hidden" />
                        <p className="text-[12px] text-center md:pt-[11px]">
                          Please Upload <br /> Profile Image
                        </p>
                      </>
                    ) : (
                      <>
                        <PhotoUpload width={59} className="hidden lg:block" />
                        <PhotoUpload width={39} className="lg:hidden block" />
                        <p className="text-[12px] text-center md:pt-[11px]">
                          Upload Profile <br /> Image
                        </p>
                      </>
                    )}
                  </button>
                )}
              </label>
              <select
                name="cities"
                className="w-full px-5 py-3 bg-white text-[15px] rounded focus:outline-none outline-0 appearance-none"
                id=""
                onChange={(e: any) => {
                  setCityName(e.target.value)
                }}
              >
                <option value="">{user?.city || 'Select City'}</option>
                {cities?.data?.map((city: any, idx: number) => (
                  <option key={idx} value={city?.city_name}>
                    {city?.city_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="lg:w-[573px] w-full h-[129px] mx-auto">
            <textarea
              className={`w-full h-full px-5 py-2.5 bg-white text-[15px] rounded focus:outline-none outline-0 ${
                (errors.description && errors.description.type === 'required') || (error && 'placeholder:text-red-500')
              }`}
              defaultValue={user?.bio}
              placeholder={
                (errors.description && errors.description.type === 'required') || error
                  ? 'Description Required'
                  : 'Description'
              }
              {...register('description', {
                required: { value: true, message: 'Description is required' },
              })}
            />
          </div>
          <div className="w-[265px] mx-auto">
            <button className="w-full py-2 bg-brand-accent text-white text-[20px] rounded" type="submit">
              {user?.username ? 'Update' : 'Save'}
            </button>
          </div>
        </form>
      </AuthLayout>
    </AuthenticatedPage>
  )
}

export default ProfileSetup
