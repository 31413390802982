import FontLoader from '../../menu/FontLoader'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../../menu/utils'
import { FooterVariantProps, getSocialIcon, NewFooter, Social } from '../footer'

const SocialItem = ({ type, link, footer }: { type: Social; link: string; footer: NewFooter }) => {
  const Icon = getSocialIcon(type)

  return (
    <a href={link} target="_blank" className="flex items-center hover:underline">
      <Icon size={20} color={getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }).color} />
    </a>
  )
}

export default function VariantSeven({ footer, website }: FooterVariantProps) {
  return (
    <div
      style={{
        ...getBackgroundColor({ settings: footer?.settings?.backgroundColor, defaultValue: '#303c3c' }),
        ...getSpacing(footer?.settings?.spacing),
      }}
    >
      <FontLoader settings={footer?.settings?.fontFamily} />
      <div
        className="px-6 md:px-8 text-center"
        style={{
          ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
          ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: '#f8f2ea' }),
        }}
      >
        <div
          style={{
            ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
            ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: '#f8f2ea' }),
          }}
        >
          <div className="py-12 flex justify-around">
            {footer.categories
              .filter(i => i.links.length > 0)
              .map(categorty => (
                <ul className="space-y-3 m-0 p-0 list-none text-[14px] md:text-[20px] uppercase font-bold">
                  {categorty.links.map(link => (
                    <li>
                      <a className="hover:opacity-80" href={link.url}>
                        {link.name}
                      </a>
                    </li>
                  ))}
                </ul>
              ))}
          </div>
          {/* <div className="border-l border-r px-6 py-12 space-y-6">
            <h2 className="uppercase text-[42px] font-bold">NEWSLETTER</h2>
            <p>Sign up for a free newsletter to help you brew amazing coffee at home.</p>
            <div className="relative">
              <div className="absolute inset-y-0 right-0 flex items-center pr-6 pointer-events-none">
                <BsArrowRight size={30} />
              </div>
              <input
                type="email"
                className="block w-full px-4 py-2 text-[20px] border border-gray-300 focus:outline-0 bg-transparent"
                placeholder="Enter Email..."
                required
              />
            </div>
          </div> */}
        </div>
        <div
          className="px-4 border-t border-b"
          style={{
            ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
            ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: '#f8f2ea' }),
            borderColor: getTextColor({ settings: footer?.settings?.textColor, defaultValue: '#f8f2ea' }).color,
          }}
        >
          <div className="flex justify-around py-4">
            {website?.config?.social
              ? Object.keys(website.config.social).map(item => (
                  <SocialItem footer={footer} type={item as Social} link={website.config.social[item]} />
                ))
              : footer?.social?.map(socialType => <SocialItem type={socialType} link="#" footer={footer} />)}
          </div>
        </div>
        {/* <div className="py-6 max-w-[700px] mx-auto px-6">
          <p>
            RoastyCoffee.com is a participant in the Amazon Services LLC Associates Program. As an Amazon Associate, I
            earn from qualifying purchases by linking to Amazon.com and affiliated sites.
          </p>
        </div> */}
      </div>
      <div
        className="flex items-center justify-center text-center px-2 py-2 lg:py-4 w-full text-gray-400 "
        style={{
          ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
          ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
        }}
      >
        {website?.config?.copyright ? website?.config?.copyright : footer?.copyright}
      </div>
    </div>
  )
}
