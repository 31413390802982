import FontLoader from '../../menu/FontLoader'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../../menu/utils'
import { FooterVariantProps, getSocialIcon, NewFooter, Social } from '../footer'

const SocialItem = ({ type, link, footer }: { type: Social; link: string; footer: NewFooter }) => {
  const Icon = getSocialIcon(type)

  return (
    <a
      href={link}
      target="_blank"
      className="flex items-center justify-center w-[40px] h-[40px] hover:bg-[#e5efff] rounded-full"
    >
      <Icon size={20} color={getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'black' }).color} />
    </a>
  )
}

export default function VariantEight({ footer, website }: FooterVariantProps) {
  return (
    <div
      style={{
        ...getBackgroundColor({ settings: footer?.settings?.backgroundColor, defaultValue: 'white' }),
        ...getSpacing(footer?.settings?.spacing),
      }}
    >
      <FontLoader settings={footer?.settings?.fontFamily} />
      <div
        className="px-6 md:px-12 py-8"
        style={{
          ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
          ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'black' }),
        }}
      >
        <div
          className="pb-6 space-y-4 md:hidden"
          style={{
            ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
            ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'black' }),
          }}
        >
          <a href="/">
            {website?.config?.logo ? (
              <img
                style={{ height: '30px', width: 'auto' }}
                className="cursor-pointer"
                src={website?.config?.logo}
                alt={website?.name}
              />
            ) : (
              <p className="uppercase font-medium">{website?.name}</p>
            )}
          </a>
          {/* TODO: Implement leater  */}
          {/* <p>
            The Wix website builder offers a complete solution from enterprise-grade infrastructure and business
            features to advanced SEO and marketing tools–enabling anyone to create and grow online.
          </p> */}
        </div>
        <div
          style={{
            ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
            ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'black' }),
          }}
          className="grid grid-cols-2 md:flex md:justify-between md:flex-wrap gap-y-4"
        >
          {footer.categories
            .filter(i => i.links.length > 0)
            .map(categorty => (
              <div className="w-full md:w-auto">
                <p className="text-sm font-bold mb-3">{categorty.name}</p>
                <ul className="space-y-1 m-0 p-0 list-none">
                  {categorty.links.map(link => (
                    <li>
                      <a className="hover:underline" href={link.url}>
                        {link.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          <div
            className="hidden md:block max-w-[300px] space-y-4"
            style={{
              ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
              ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'black' }),
            }}
          >
            <a href="/">
              {website?.config?.logo ? (
                <img
                  style={{ height: '30px', width: 'auto' }}
                  className="cursor-pointer"
                  src={website?.config?.logo}
                  alt={website?.name}
                />
              ) : (
                <p
                  className="uppercase font-medium"
                  style={{
                    ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
                    ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'black' }),
                  }}
                >
                  {website?.name}
                </p>
              )}
            </a>
            {/* TODO: Implement leater  */}
            {/* <p>
              The Wix website builder offers a complete solution from enterprise-grade infrastructure and business
              features to advanced SEO and marketing tools–enabling anyone to create and grow online.
            </p> */}
          </div>
        </div>
      </div>
      <div
        style={{
          ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
          ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'black' }),
        }}
        className="flex lg:flex-row flex-col justify-between lg:items-center py-3 px-6 md:px-12 border-t"
      >
        <div className="flex gap-x-3">
          {website?.config?.social
            ? Object.keys(website.config.social).map(item => (
                <SocialItem type={item as Social} link={website.config.social[item]} footer={footer} />
              ))
            : footer?.social?.map(socialType => <SocialItem type={socialType} link="#" footer={footer} />)}
        </div>
        <div
          style={{
            ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
            ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'black' }),
          }}
          className="flex lg:items-center justify-center lg:text-center"
        >
          <p>{website?.config?.copyright ? website?.config?.copyright : footer?.copyright}</p>
        </div>
      </div>
    </div>
  )
}
