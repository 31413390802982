import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { TeamMembersSection, TeamMembersSectionMember } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import Team4 from './VariantDesign/Team4'
import Team41 from './VariantDesign/Team41'
import Team43 from './VariantDesign/Team43'

export default function VariantFour({ section }: { section: TeamMembersSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const members = section.variables.find(variable => variable.name === 'TEAM_MEMBERS')
    ?.data as TeamMembersSectionMember[]

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: '#f8f8f8',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
      className="py-[50px]"
    >
      <div className="container mx-auto">
        <div className="w-[750px] max-w-full mx-auto space-y-1 ">
          <div className="md:text-[40px] text-2xl">
            <h1 className="text-black text-center">
              <SectionSlatePreview initialValue={title} previewMode />
            </h1>
          </div>
          {subTitle && (
            <div>
              <p className="md:text-[18px] text-center">
                <SectionSlatePreview initialValue={subTitle} previewMode />
              </p>
            </div>
          )}
          {paragraph && (
            <div>
              <p className="text-[14px] md:text-[16px] text-center">
                <SectionSlatePreview initialValue={paragraph} previewMode />
              </p>
            </div>
          )}
        </div>

        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:justify-between lg:justify-between gap-y-14 mt-6 md:mt-14">
          {members.map((member, index) => {
            console.log('member.id', index % 3)
            switch (index % 3) {
              case 0:
                return <Team4 key={member.id} member={member} />
              case 1:
                return <Team41 key={member.id} member={member} />
              case 2:
                return <Team43 key={member.id} member={member} />
            }
          })}
        </div>
      </div>
    </div>
  )
}
