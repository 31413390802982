import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { FAQSection, FAQSectionItem } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantOne({ section }: { section: FAQSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const faqs = section.variables.find(variable => variable.name === 'ITEMS')?.data as FAQSectionItem[]

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: '#f3f3f3',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
      className="py-4 md:py-[100px]"
    >
      <div className="font-light md:py-12 py-4 px-12 md:px-4">
        <div className="max-w-full md:space-y-1">
          <div className="md:text-[40px] text-[26px]">
            <p className="text-center">
              <SectionSlatePreview initialValue={title} previewMode />
            </p>
          </div>
          {subTitle && (
            <div>
              <p className="md:text-[18px] text-[12px] text-center">
                <SectionSlatePreview initialValue={subTitle} previewMode />
              </p>
            </div>
          )}
          {paragraph && (
            <div>
              <p className="text-center text-[12px] md:text-[18px] px-2">
                <SectionSlatePreview initialValue={paragraph} previewMode />
              </p>
            </div>
          )}
        </div>

        <div className="grid grid-cols-1 justify-between items-start gap-x-16 gap-y-8 md:mt-12 md:grid-cols-4 mt-6">
          {faqs.map((faq, idx) => (
            <div key={idx} className="flex flex-col items-center justify-center md:gap-3 text-center ">
              <img className="h-[60px] w-[60px] md:w-[100px] md:h-[100px] rounded-full" src={faq?.image} alt="" />
              <p className={`text-[20px] md:text-[25px]`}>
                <SectionSlatePreview initialValue={faq?.title} previewMode />
              </p>
              <p className="text-[12px] md:text-lg">
                <SectionSlatePreview initialValue={faq?.description} previewMode />
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
