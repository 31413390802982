import { AnimateSharedLayout, motion } from 'framer-motion'
import { useState } from 'react'
import { IoMenuSharp } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import FontLoader from '../FontLoader'
import { MenuVariantProps, NewMenuLinkType } from '../menu'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../utils'

export default function MenuVariantTwentyOne({ menu, website }: MenuVariantProps) {
  return (
    <div>
      <FontLoader settings={menu?.settings?.fontFamily} />
      <DesktopNavbar menu={menu} website={website} />
      <MobileNavbar menu={menu} website={website} />
    </div>
  )
}

const DesktopNavbar = ({ menu, website }: MenuVariantProps) => {
  const navigate = useNavigate()
  return (
    <AnimateSharedLayout>
      <div
        style={{
          ...getBackgroundColor({
            settings: menu?.settings?.backgroundColor,
            defaultValue: 'white',
          }),
          ...getSpacing(menu?.settings?.spacing),
        }}
      >
        <div className="py-8 px-6 md:px-12 hidden md:block">
          <div className="flex justify-between items-center">
            <a href="/">
              {website?.config.logo ? (
                <img
                  src={website?.config.logo}
                  className="w-11 cursor-pointer h-11 rounded-full border-2 border-white "
                />
              ) : (
                <p
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                  className="text-xl"
                >
                  {website.name}
                </p>
              )}
            </a>
            <ul className="flex flex-row justify-center items-center gap-x-10 list-none">
              {menu.links.map((item, i: any) => (
                <li key={i} className={`relative text-xl cursor-pointer select-none`}>
                  <a
                    href={item.url}
                    target={item.type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                    style={{
                      ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                      ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                    }}
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
            <div className="flex gap-x-6">
              <button
                onClick={() => navigate('/login')}
                className="px-6 py-2 border rounded-full border-black"
                type="button"
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                }}
              >
                Log In
              </button>
              <button
                onClick={() => navigate('/messages')}
                className="px-6 py-2 border rounded-full border-black"
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#2bffff' }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                }}
                type="button"
              >
                Let's chat
              </button>
            </div>
          </div>
        </div>
      </div>
    </AnimateSharedLayout>
  )
}

const MobileNavbar = ({ menu, website }: MenuVariantProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
  return (
    <AnimateSharedLayout>
      <div
        style={{
          ...getBackgroundColor({
            settings: menu?.settings?.backgroundColor,
            defaultValue: 'white',
          }),
          ...getSpacing(menu?.settings?.spacing),
        }}
      >
        <div className="flex justify-between items-center px-4 py-4 md:hidden">
          <a href="/">
            {website?.config.logo ? (
              <img src={website?.config.logo} className="w-11 cursor-pointer h-11 rounded-full " />
            ) : (
              <p
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                }}
              >
                {website.name}
              </p>
            )}
          </a>
          <div>
            <button onClick={() => setIsOpen(!isOpen)}>
              <IoMenuSharp
                size={30}
                className="text-black"
                color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }).color}
              />
            </button>
          </div>
        </div>
        {isOpen && (
          <motion.div
            initial={{ x: -100 }}
            animate={{ x: 0, transition: { duration: 0.6, type: 'spring' }, originX: 30 }}
            className={`text-center`}
            style={{
              ...getBackgroundColor({
                settings: menu?.settings?.backgroundColor,
                defaultValue: 'white',
              }),
              ...getSpacing(menu?.settings?.spacing),
            }}
          >
            <div className="grid gap-y-3 list-none py-6 ">
              {menu.links.map((item, i: any) => (
                <li key={i} className={`cursor-pointer `}>
                  <a
                    href={item.url}
                    target={item.type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                    style={{
                      ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                      ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                    }}
                    className="md:text-xl"
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </div>
            <div className="gap-y-3 grid justify-center pb-3">
              <button
                onClick={() => navigate('/login')}
                className="px-6 py-2 block border rounded-full border-black"
                type="button"
              >
                Log In
              </button>
              <button
                onClick={() => navigate('/messages')}
                className="px-6 py-2 block bg-[#2bffff] border rounded-full border-black"
                type="button"
              >
                Let's chat
              </button>
            </div>
          </motion.div>
        )}
      </div>
    </AnimateSharedLayout>
  )
}
