import Icon from './icons'
import { IconPropsT } from './types'

function Close(props: IconPropsT) {
  const { title = 'Close', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 17 10" size={size} title={title} {...otherProps}>
      <path d="M1.12891 1.13391L8.25195 8.25085L15.3633 1.13391" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
  )
}

export default Close
