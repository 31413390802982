import WriteMessage from 'src/icons/WriteMessage'

type ShowPanel = {
  setSearch?: () => void
}

export default function NewMessage({ setSearch }: ShowPanel) {
  return (
    <div className="py-6 md:pl-[30px] pl-[30px] bg-white border-b border-black w-full  flex justify-between h-[90px] items-center">
      <h1 className="font-bold text-3xl">Messages </h1>
      <button type="button" className="relative md:right-[41.5px] right-[40px] outline-none " onClick={setSearch}>
        <WriteMessage size={36} className="mt-2 stroke-black" />
      </button>
    </div>
  )
}
