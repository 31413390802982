import clsx from 'clsx'
import { useState } from 'react'
import Chat from 'src/icons/Chat'
import Love from 'src/icons/Love'
import Network from 'src/icons/network'
import Share from 'src/icons/Share'
import { getFooterVariants, NewFooter } from 'src/_polly/components/src/footer/footer'
import FooterPreviewRenderer from 'src/_polly/components/src/footer/FooterPreviewRenderer'
import { Section, SectionPreviewRenderer } from 'src/_polly/components/src/sections'
import { EffectCoverflow } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { VariantIframe } from '../../NewSectionVariantSelection'
import FooterIframe from './FooterIframe'

type NewFooterPreviewProps = {
  website: any
  lastSection?: Section<any, any>
  onSelect: (footer: NewFooter) => void
}

export default function NewFooterPreview({ website, lastSection, onSelect }: NewFooterPreviewProps) {
  const [mode, setMode] = useState<'mobile' | 'desktop'>('desktop')
  const [previewItem] = useState<NewFooter | null>(null)
  const variants = getFooterVariants()

  return (
    <div className="px-4">
      <div className="flex w-full justify-between items-center">
        <div className="absolute top-[21px] right-[30px]">
          <button
            type="button"
            className="border-[0.6px] border-[#0C0C0C] dark:border-white rounded-[5px] w-[24px] h-[32px]"
            onClick={() => setMode('mobile')}
          >
            <div
              className={clsx(
                'border-[0.6px] border-black dark:border-[#D9D9D9] rounded-[5px] w-[18px] h-[25px] mx-auto',
                mode === 'mobile' && 'bg-[#D9D9D9]',
              )}
            />
          </button>

          <button
            type="button"
            className="border-[0.6px] border-[#0C0C0C] dark:border-white rounded-[5px] w-[40px] h-[32px] ml-[10px]"
            onClick={() => setMode('desktop')}
          >
            <div
              className={clsx(
                'border-black dark:border-[#D9D9D9] border-[0.6px] rounded-[5px] w-[32px] h-[25px] mx-auto',
                mode === 'desktop' && 'bg-[#D9D9D9]',
              )}
            />
          </button>
        </div>
      </div>

      {previewItem ? (
        <>
          <div className="border border-black mt-[100px]">
            {mode === 'desktop' && (
              <>
                <FooterPreviewRenderer
                  footer={previewItem}
                  noShadow
                  disableHover
                  website={{
                    ...website,
                    config: JSON.parse(website?.config ?? '{}'),
                  }}
                />
                {lastSection && <SectionPreviewRenderer section={lastSection} noShadow disableHover />}
              </>
            )}

            {mode === 'mobile' && (
              <>
                <FooterIframe
                  footer={previewItem}
                  website={{
                    ...website,
                    config: JSON.parse(website?.config ?? '{}'),
                  }}
                />
                {lastSection && <VariantIframe section={lastSection} />}
              </>
            )}
          </div>
          <div className="flex justify-center">
            <button
              type="button"
              onClick={() => {
                onSelect(previewItem)
              }}
              className="mt-20 h-[4.5rem] w-96  bg-gray-100 text-4xl font-extralight border border-black"
            >
              select
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="mt-[40px] mb-12">
            <h1 className="capitalize text-center font-extralight text-4xl dark:text-white">Footer Menu</h1>
            <p className="text-center font-extralight text-xl mt-2 dark:text-white">Click on your desired section</p>
          </div>

          <div className="space-y-8">
            <Swiper
              effect="coverflow"
              modules={[EffectCoverflow]}
              loop
              slidesPerView={1}
              slidesPerGroup={1}
              shortSwipes={false}
              className="mySwiper"
            >
              {variants.map(footer => (
                <SwiperSlide key={footer.id}>
                  <div className="w-full h-full px-2 bg-white dark:bg-black">
                    <div>
                      {mode === 'desktop' && (
                        <>
                          <FooterPreviewRenderer
                            footer={footer}
                            noShadow
                            disableHover
                            rounded
                            stroke
                            website={{
                              ...website,
                              config: JSON.parse(website?.config ?? '{}'),
                            }}
                          />
                          {lastSection && <SectionPreviewRenderer section={lastSection} noShadow disableHover />}
                        </>
                      )}

                      {mode === 'mobile' && (
                        <>
                          <FooterIframe
                            footer={footer}
                            website={{
                              ...website,
                              config: JSON.parse(website?.config ?? '{}'),
                            }}
                          />
                          {lastSection && <VariantIframe section={lastSection} />}
                        </>
                      )}
                    </div>
                    <div className="w-full h-[50px] px-4 mt-[2px] flex justify-between border-b border-t border-black dark:border-[#D6D6D6]">
                      <div className="flex items-center gap-x-[12px]">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="8.36636" cy="8.41629" r="7.53152" className="fill-black dark:fill-white" />
                        </svg>
                        <p className="text-[15px] font-bold dark:text-white">{footer?.id}</p>
                      </div>
                      <div className="flex gap-x-[18px] items-center">
                        <button type="button">
                          <Love size={20} className="text-black dark:text-white" />
                        </button>
                        <button type="button">
                          <Chat size={20} className="text-black dark:text-white" />
                        </button>
                        <button type="button">
                          <Network size={20} fill="none" className="stroke-black dark:stroke-white" />
                        </button>
                        <button type="button">
                          <Share size={20} className="stroke-black fill-black dark:fill-white dark:stroke-white" />
                        </button>
                      </div>
                    </div>
                    <div className="mt-[44px] flex bg-black h-[53px] w-[216px] justify-center text-white mx-auto border-[4px] border-white dark:border-[#E90570] rounded-[9px]">
                      <button
                        type="button"
                        className="text-[20px] h-full w-full text-center"
                        onClick={() => onSelect(footer)}
                      >
                        select
                      </button>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </>
      )}

      <div className="pb-10" />
    </div>
  )
}
