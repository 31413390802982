import { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { AboutUsSection, AboutUsSectionServices } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import SectionSlate from '../../../common/SectionSlatePreview'
import ButtonRenderer from '../ButtonRenderer'
import SectionSlatePreview from '../../../common/SectionSlatePreview'

function VariantFourteen({ section }: { section: AboutUsSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const items = section.variables.find(variable => variable.name === 'SERVICE')?.data as AboutUsSectionServices[]
  const button = section.variables.find(variable => variable.name === 'BUTTON_1')?.data

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: '#eee',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
      className="md:py-12 py-4 "
    >
      <h2 className="md:text-[26px] uppercase text-center py-6">
        <SectionSlate initialValue={title} previewMode />
      </h2>
      <Swiper
        pagination={{
          clickable: true,
        }}
        navigation={true}
        loop={true}
        modules={[Pagination, Navigation]}
        grabCursor={true}
        className="mySwiper px-4 lg:pl-8 pb-7 "
      >
        {items?.map((item, idx) => (
          <SwiperSlide key={idx}>
            <div className={`flex md:flex-row flex-col gap-3 md:gap-1 items-center md:text-left text-center px-4 `}>
              <div className="w-[150px] h-[150px] md:w-[450px] md:h-[450px]">
                <img
                  className="lg:w-[350px] lg:h-[350px] h-full w-full rounded-full object-cover lg:mt-3"
                  src={item?.image}
                  alt=""
                />
              </div>
              <div className="space-y-3 md:space-y-6 flex flex-col  md:items-start">
                <h6 className="md:text-[16px]">
                  <SectionSlatePreview initialValue={item?.name} previewMode />
                </h6>
                <h2 className="text-[22px] md:text-[42px] lg:text-[75px] font-bold leading-none">
                  <SectionSlatePreview initialValue={item?.title} previewMode />
                </h2>
                <p className="text-[16px]">
                  <SectionSlatePreview initialValue={item?.description} previewMode />
                </p>
                {button ? (
                  <ButtonRenderer button={button} />
                ) : (
                  <button className="border border-black px-4 py-2" type="button">
                    Read More
                  </button>
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default VariantFourteen
