import { Template } from '.'
import { aboutUs, contactUs, faq, love, portfolio, quote, text } from './variants'

export const Template03: Template = {
  id: '03',
  pages: [
    {
      slug: '/',
      name: 'Home',
      blocks: [
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_1')?.settings,
          },
          variables: [
            { data: 9, name: 'TRANSITION', id: 'b770c209-5a97-4d2f-bc2d-59a45fba14ad', category: 'IMAGE' },
            {
              data: 'https://images.unsplash.com/photo-1618005182384-a83a8bd57fbe?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw4fHx2ZWN0b3J8ZW58MHx8fHwxNjc3NzU1ODk2&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '1d3819bc-dcf9-4917-9830-ffe198cbee06',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Pacifico', color: '#000000', text: 'UX Design for Crowdfunding Success' }],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'b3776b33-b704-4a9f-b498-59aab679a0e7',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#000000', text: '' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: '84f1771a-5eb9-4d3b-96c3-039f66cd4ed6',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    { fontFamily: 'Quicksand', color: '#7f3c86', className: 'jumbotron-heading1', text: 'Choose ' },
                    { fontFamily: 'Quicksand', color: '#ffffff', className: 'jumbotron-heading1', text: 'your' },
                    { fontFamily: 'Quicksand', color: '#7f3c86', className: 'jumbotron-heading1', text: ' ' },
                  ],
                  type: 'paragraph',
                },
                {
                  children: [
                    { fontFamily: 'Quicksand', color: '#b6bfff', className: 'jumbotron-heading1', text: 'Design' },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '9e8d9a5f-a01f-477e-92b7-93a271d0f68d',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_1')?.settings,
            spacing: {
              padding: { top: 60, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  children: [
                    {
                      fontFamily: 'Libre Baskerville',
                      color: '#b146ab',
                      text: 'Investor-Focused UX Design',
                      bold: true,
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'a8a09ff6-28de-448f-9ac9-2fe64e412ef4',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Libre Baskerville', color: '#000000', text: 'Snatched Crowdfunding UX' }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '6cb8796d-6908-4cb9-96d3-189b32f00afd',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ fontFamily: 'Libre Baskerville', color: '#000000', text: '' }], type: 'paragraph' },
              ],
              name: 'PARAGRAPH',
              id: '0ab1afb8-5f14-417a-afa3-4d1c9c459083',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          type: 'TEXT',
        },
        {
          settings: {
            ...faq.find(i => i.variant === 'VARIANT_5')?.settings,

            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 60, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'List Title' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '6281d311-66fc-46c6-9bf9-9df6a5e2ce12',
              category: 'TEXT',
            },
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'We offer website performance, website speed, website accessibility, ',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                  title: [
                    { children: [{ color: '#000000', text: 'GOAT Acquisition UX', bold: true }], type: 'paragraph' },
                  ],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'We offer website performance, website speed, website accessibility, ',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                  title: [
                    { children: [{ color: '#000000', text: 'GOAT Acquisition UX', bold: true }], type: 'paragraph' },
                  ],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'We offer website performance, website speed, website accessibility, ',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                  title: [
                    { children: [{ color: '#000000', text: 'GOAT Acquisition UX', bold: true }], type: 'paragraph' },
                  ],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'We offer website performance, website speed, website accessibility, ',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '4',
                  title: [
                    { children: [{ color: '#000000', text: 'GOAT Acquisition UX', bold: true }], type: 'paragraph' },
                  ],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
              ],
              name: 'ITEMS',
              id: '5b363474-cc12-4cfc-9a90-8693e4729e49',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_5',
          type: 'FAQ',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_1')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 30, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  children: [
                    { fontFamily: 'Libre Baskerville', color: '#000000', text: 'Customer-Centric User Experience' },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '3749852f-723c-4669-9421-4aa94c2c67b0',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      fontFamily: 'Libre Baskerville',
                      color: '#b33597',
                      text: 'UX Design for Crowdfunding Success',
                      bold: true,
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'd4c9e0cc-7f18-4f50-a352-ce7c96aa893c',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: '' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: 'f392c82d-fef0-4ddd-b8f9-05ac3c746700',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          type: 'TEXT',
        },
        {
          settings: {
            ...faq.find(i => i.variant === 'VARIANT_5')?.settings,

            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'List Title' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'c6de282c-d484-45d2-af24-e75757fb03ae',
              category: 'TEXT',
            },
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'We ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                  title: [
                    {
                      children: [{ color: '#000000', text: 'Conscious Startup Success', bold: true }],
                      type: 'paragraph',
                    },
                  ],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'We ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                  title: [
                    {
                      children: [{ color: '#000000', text: 'Conscious Startup Success', bold: true }],
                      type: 'paragraph',
                    },
                  ],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'We ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                  title: [
                    {
                      children: [{ color: '#000000', text: 'Conscious Startup Success', bold: true }],
                      type: 'paragraph',
                    },
                  ],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          color: '#000000',
                          text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '4',
                  title: [
                    {
                      children: [{ color: '#000000', text: 'Conscious Startup Success', bold: true }],
                      type: 'paragraph',
                    },
                  ],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
              ],
              name: 'ITEMS',
              id: 'f6b717b3-6abe-4043-9eaf-b492232acd79',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_5',
          type: 'FAQ',
        },
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_7')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 60, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#9e3ba2', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Buzzworthy Investor Trends' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'cc0bb77c-41b9-48d2-8477-b656c0a5707c',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ text: '' }], type: 'paragraph' },
                {
                  children: [
                    {
                      text: 'At our agency, we prioritize user satisfaction, user retention, and user conversion. We offer website performance, website speed, website accessibility, website security, website maintenance,website optimization, website analytics, website testing, website updates, website improvements, and website enhancements to ensure your site is top-notch.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'f4bdb6c4-3c4d-4af3-9011-27e89009a4db',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'UX Design for Digital Nomads' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '233e4517-4881-4ff8-a37e-451a61a929ad',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_7',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...quote.find(i => i.variant === 'VARIANT_4')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 60, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'DESCRIPTION',
              id: '591db6c9-75bf-41d3-97f9-c013ed86d747',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1633410189542-36d96e3762b8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHx0ZWFtc3xlbnwwfHx8fDE2Nzc3NTk4MDQ&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: 'd26f260a-36e4-4e31-96e6-8433a02bf3d6',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ color: '#a947a5', text: 'Mindful  UX Mastery' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'cf468ad0-53e2-4bb1-819b-801d7103c12e',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'At our agency, we prioritize user satisfaction, user retention, and user conversion. We offer website performance, website speed, website accessibility, website security, website maintenance, website optimization, website analytics, website testing, website updates, website improvements, and website enhancements to ensure your site is top-notch. With our high-octane mobile-friendly design and invigorating user-friendly interface, we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'bbad9435-7b04-4963-8fbf-2fe366e746c0',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_4',
          type: 'QUOTE_SECTION',
        },
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 30, left: 0, bottom: 30, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#9F46B7', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  name: 'Email',
                  id: '557b0d5a-1fb1-438b-85e7-83870af134d6',
                  placeholder: 'example@gmail.com',
                  type: 'email',
                  error: 'email is required',
                },
              ],
              name: 'INPUTS',
              id: '97704a0f-1120-44da-8510-59f397982030',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#ffffff',
                      text: 'At our agency, we prioritize user satisfaction, user retention, and user conversion. We offer website performance, website speed, website accessibility,',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'ba2b534e-2fc6-40a8-aca3-d261e609691a',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    { fontFamily: 'Libre Baskerville', color: '#ffffff', text: 'Send us a message now', bold: true },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '722210ac-0a46-444a-ad57-4feae3d6fbfe',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#ffffff',
                  backgroundColor: '#fcbfff',
                  borderRadius: '0px',
                  color: '#000',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'Send',
              },
              name: 'BUTTON_1',
              id: '317cb1d0-506e-464a-9d95-2c37002b46a4',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_6',
          type: 'CONTACT_US',
        },
        {
          settings: {
            ...quote.find(i => i.variant === 'VARIANT_6')?.settings,
          },
          variables: [
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'DESCRIPTION',
              id: '5a080a89-0e62-47ed-b949-a73715bee113',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
              name: 'MEDIA',
              id: '37836533-2023-47f3-9273-b8e3bba67269',
              category: 'IMAGE',
            },
            {
              data: [
                { children: [{ fontFamily: 'Libre Baskerville', text: 'Bohemian Crowdfunding ' }], type: 'paragraph' },
              ],
              name: 'TITLE',
              id: 'a4a389ec-a48f-4477-bd62-fcebc2342af5',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ color: '#bababa', text: '' }], type: 'paragraph' },
                {
                  children: [
                    {
                      fontFamily: 'Libre Baskerville',
                      color: '#6a6969',
                      text: 'At our agency, we prioritize user satisfaction, user retention, and user conversion. We offer website performance, website speed, website accessibility, website security, website maintenance, website optimization, website analytics, website testing, website updates, website improvements',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'd125e6f8-b6a6-44be-8850-1d389e8456b4',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'QUOTE_SECTION',
        },
        {
          settings: {
            ...portfolio.find(i => i.variant === 'VARIANT_1')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 30, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            { data: ' transparent', name: 'BACKGROUND', id: '2c4920ee-6f94-435c-9ca5-6ba22cb42d91', category: 'TEXT' },
            {
              data: { top: 0, left: 0, bottom: 0, right: 0 },
              name: 'MARGIN',
              id: 'c92837fa-b4d9-4a11-b53f-6fff4316d1d7',
              category: 'TEXT',
            },
            {
              data: { top: 0, left: 1, bottom: 0, right: 0 },
              name: 'PADDING',
              id: 'b7d7c32c-88c3-4b6a-85dd-51d225f16274',
              category: 'TEXT',
            },
            { data: 0, name: 'TRANSITION', id: '1093eef4-4f08-469c-b25c-f1166ced4a58', category: 'IMAGE' },
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1633410189542-36d96e3762b8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHx0ZWFtc3xlbnwwfHx8fDE2Nzc3NTk4MDQ&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [
                    {
                      children: [
                        { fontFamily: 'Libre Baskerville', color: '#9136b3', text: 'Mindful Customer Acquisition' },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [{ children: [{ text: 'item name' }], type: 'paragraph' }],
                  descriptions: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1543857778-c4a1a3e0b2eb?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwyfHxjb2xvciUyMHJvc2V8ZW58MHx8fHwxNjc3NzYyNzk2&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [
                    {
                      children: [
                        { fontFamily: 'Libre Baskerville', color: '#9d2eac', text: 'Mindful Customer Acquisition' },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  title: [{ children: [{ text: 'item name' }], type: 'paragraph' }],
                  descriptions: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
              ],
              name: 'ITEMS',
              id: '3702bf5f-dbe3-42d3-9dc6-e1d8aa039cae',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          type: 'PORTFOLIO',
        },
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_7')?.settings,

            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 60, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#9e3ba2', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ fontFamily: 'Lobster', text: 'Our customer say :-' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '2083571c-ba01-4892-b84e-b47aea2ea796',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ fontFamily: 'Dancing Script', text: 'Alexandra DeGeneres' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '29848f23-a588-4040-8a5a-90b7f83dc104',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ text: '' }], type: 'paragraph' },
                {
                  children: [
                    {
                      text: 'At our agency, we prioritize user satisfaction, user retention, and user conversion. We offer website performance, website speed, website accessibility, website security, website maintenance,website optimization, website analytics,',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '25b671f4-f8cf-41dc-b820-fe7c695fe688',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_7',
          type: 'ATTENTION_GRABBER',
        },
      ],
    },
    {
      slug: '/about-us',
      name: 'About us',
      blocks: [
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_14')?.settings,
            color: { background: { value: '#773288', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: '9f032f13-ca77-4b8a-b9c6-467063a67dc2',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1483728642387-6c3bdd6c93e5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxtb3VudGFpbnxlbnwwfHx8fDE2NzIzMjQ3NDc&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: 'b6e9ec27-f826-47d3-a985-a7d31227815b',
              category: 'IMAGE',
            },
            { data: 0, name: 'TRANSITION', id: '5278f4c0-8cf6-41c4-84d0-d4d925fb752e', category: 'IMAGE' },
            {
              data: [
                { children: [{ fontFamily: 'Libre Baskerville', text: '' }], type: 'paragraph' },
                { children: [{ fontFamily: 'Libre Baskerville', text: 'About Us' }], type: 'paragraph' },
              ],
              name: 'TITLE',
              id: 'c36f9cf5-2ed4-4999-8490-ebc020a252e1',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Disruptive Design for Acquisition' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'd8475f19-9968-4809-9770-122b63b6496c',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_14',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...aboutUs.find(i => i.variant === 'VARIANT_8')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 30, left: 0, bottom: 30, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Our Bio' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'd19005f9-47b0-4c6c-a7ac-abb39f596b99',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'At our agency, we prioritize user satisfaction, user retention, and user conversion. We offer website performance, website speed, website accessibility, website security, website maintenance, website optimization, website analytics, website testing, website updates, website improvements, and website enhancements to ensure your site is top-notch. With our high-octane mobile-friendly design and invigorating user-friendly interface, we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'eb105f10-47c9-48bf-8c0b-e636cee47920',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1607004468138-e7e23ea26947?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw3fHxhYm91dHxlbnwwfHx8fDE2Nzc3ODkxODY&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '58c5342e-535b-4ab7-b909-bdf3e935200d',
              category: 'IMAGE',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#000',
                  backgroundColor: '#ffffff',
                  borderRadius: '25px',
                  color: '#000',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'More',
              },
              name: 'BUTTON_1',
              id: '39625ecd-c44f-4526-bd23-c012dbf3608f',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_8',
          type: 'ABOUT_US',
        },
      ],
    },
    {
      slug: '/services',
      name: 'Services',
      blocks: [
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_1')?.settings,
            spacing: {
              padding: { top: 60, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  children: [{ fontFamily: 'Libre Baskerville', color: '#000000', text: 'Snatched Crowdfunding UX' }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '8045590b-266f-48a1-a16d-726e54486e9c',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      fontFamily: 'Libre Baskerville',
                      color: '#000000',
                      text: "Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience? Look no further than our custom site and portfolio design services. Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. Whether you're a digital nomad or a disruptive technology startup, we have the expertise to optimize your website for exceptional user experience on any device.",
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '00653e2d-4dc4-4e3c-a0f2-fd54ce85b00c',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      fontFamily: 'Libre Baskerville',
                      color: '#823c8c',
                      text: 'Investor-Focused UX Design',
                      bold: true,
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '3b2d0862-f48b-4484-b377-4144f09ba76b',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          type: 'TEXT',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 60, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHx0ZW1wbGF0ZXxlbnwwfHx8fDE2Nzc4NTQ3MzY&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ text: 'TBH Digital Nomads' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1453928582365-b6ad33cbcf64?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw2fHxXb3JrfGVufDB8fHx8MTY3Nzg1NDc5NA&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ text: 'TBH Digital Nomads' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw3fHxzZXJ2aWNlc3xlbnwwfHx8fDE2Nzc4NTQ4MzU&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ text: 'TBH Digital Nomads' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                },
              ],
              name: 'MEDIAGROUP',
              id: 'f791e224-b945-4de7-adf5-8011eb5379b9',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: '' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'e0dc2efe-547a-44f2-8112-24dcdd1f5e51',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'TEXT',
        },
      ],
    },
    {
      slug: '/blog',
      name: 'Blog',
      blocks: [
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_5')?.settings,
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '210d4781-ae65-4c5e-af9a-4fb350ea1cc7',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Sub Title' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '2829d845-304f-4f65-871e-38618de5f2a0',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: '288dcb26-611a-41ed-ae09-b0c7ebcd1d99',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'MARGIN',
              id: '6b0f65d3-caa7-4579-ae1d-56348170c9c0',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'PADDING',
              id: '0bb5f672-5999-4792-94d7-2a6e7e141551',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
              name: 'MEDIA',
              id: 'c727ad80-97b6-4b69-ba24-8127c58fbb77',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1522199755839-a2bacb67c546?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxibG9nfGVufDB8fHx8MTY3Nzg2MDQyMg&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ text: 'Snatched Crowdfunding UX' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1460925895917-afdab827c52f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxMHx8YmxvZ3xlbnwwfHx8fDE2Nzc4NjA0MjI&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ text: 'Snatched Crowdfunding UX' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1546074177-ffdda98d214f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw3fHxibG9nfGVufDB8fHx8MTY3Nzg2MDQyMg&ixlib=rb-4.0.3&q=80&w=1080',
                  name: [{ children: [{ text: 'Snatched Crowdfunding UX' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: 'da4cd9be-5920-42d3-b939-918bb1b72734',
              category: 'IMAGE',
            },
          ],
          variant: 'VARIANT_5',
          type: 'TEXT',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_1')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  children: [{ fontFamily: 'Libre Baskerville', color: '#000000', text: 'YOLO Startup Design' }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '63d30150-f134-4e17-9a9a-30243a64396e',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#000000',
                      text: "Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience? Look no further than our custom site and portfolio design services. Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. Whether you're a digital nomad or a disruptive technology startup, we have the expertise to optimize your website for exceptional user experience on any device.",
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'c17deab3-99f0-4e63-9f22-b60f762420e8',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      fontFamily: 'Libre Baskerville',
                      color: '#b04baf',
                      bold: true,
                      text: 'Sleep On Disruptive Design',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'c83a53af-988a-459d-8622-027c244ac9f9',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          type: 'TEXT',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_4')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: '9f07b24c-af96-416e-b9bf-670903711fdc',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'MARGIN',
              id: '4986a6e9-6d31-4b45-b1e5-a65105d2bd70',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'PADDING',
              id: 'aaf7f54e-6e92-46af-84cc-9a6397907ed2',
              category: 'TEXT',
            },
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: '80e3f23a-5608-462b-a02c-76c262aa7802',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Libre Baskerville', color: '#b555ca', text: 'Sleep On Disruptive Design' }],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: 'd5528c5a-667d-4c10-ba1d-c28637768ac2',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'UX Design for Crowdfunding Success' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '09b49b69-ea91-4152-ae02-c0d67418d6f4',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1522199755839-a2bacb67c546?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxibG9nfGVufDB8fHx8MTY3Nzg2MDQyMg&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: 'd97ac374-61e8-4be8-baaf-019cb550be29',
              category: 'IMAGE',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#ffffff',
                  backgroundColor: '#bd3ab2',
                  borderRadius: '25px',
                  color: '#ffffff',
                  borderWidth: '1px',
                  display: 'block',
                  width: '249px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                  font: 'Libre Baskerville',
                },
                visible: true,
                link: null,
                value: 'for more',
              },
              name: 'BUTTON_1',
              id: 'cdd90c05-6514-4b2f-9e59-334057c74df6',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_4',
          type: 'TEXT',
        },
        {
          settings: {
            ...faq.find(i => i.variant === 'VARIANT_8')?.settings,

            spacing: {
              padding: { top: 60, left: 0, bottom: 30, right: 0 },
              margin: { top: 30, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#b654c5', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image: 'https://www.roastycoffee.com/wp-content/uploads/business-insider-logo.svg',
                  id: '172a4dc6-9339-43f1-9501-a9ae49ea2f1b',
                },
                {
                  image: 'https://www.roastycoffee.com/wp-content/uploads/buzzfeed-logo.svg',
                  id: '128e59c6-ceea-4bb3-943e-5834422c091d',
                },
                {
                  image: 'https://www.roastycoffee.com/wp-content/uploads/fastcompany-logo.svg',
                  id: '00c47dda-e420-408d-a7b3-4eb077c0063e',
                },
                {
                  image: 'https://www.roastycoffee.com/wp-content/uploads/today-logo.svg',
                  id: 'f8819dd3-bf10-49e6-8c7f-4c2b329e259d',
                },
                {
                  image: 'https://www.roastycoffee.com/wp-content/uploads/wired-logo.svg',
                  id: 'b3f763b8-7483-4264-ae43-0357ceb48ecd',
                },
                {
                  image: 'https://www.roastycoffee.com/wp-content/uploads/yahoo-logo.svg',
                  id: 'ea986320-d430-4621-b1c1-6be734151f35',
                },
              ],
              name: 'ITEMS',
              id: 'dc1fbcb7-765e-438b-bc24-095dedc6ef4c',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#fafafa', text: 'Proudly Featured' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '6fa0bc26-bccd-40f3-b83c-ac4250283be0',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_8',
          type: 'FAQ',
        },
      ],
    },
    {
      slug: '/contact-us',
      name: 'Contact us',
      blocks: [
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_9')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#f9f9f9', enabled: true } },
          },
          variables: [
            {
              data: [
                { name: 'First Name', placeholder: 'John Doaa', type: 'text', error: 'First Name is required' },
                { name: 'Email', placeholder: 'boss@gmail.com', type: 'email', error: 'email is required' },
                { name: 'Message', placeholder: 'Type text', type: 'textarea', error: 'message is required' },
              ],
              name: 'INPUTS',
              id: '8cd074e1-7316-4fd9-9ed3-9eb357982007',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality',
                    },
                  ],
                  type: 'paragraph',
                },
                { children: [{ text: ' and a captivating seamless user experience.' }], type: 'paragraph' },
              ],
              name: 'PARAGRAPH',
              id: 'abd1d86a-ce8c-46b2-ab0f-8de24298d33f',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1499159058454-75067059248a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHx0ZWxlcGhvbmV8ZW58MHx8fHwxNjc3ODYwMDc0&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '47698fce-81d3-45c4-97d6-1fa8efaec16e',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ color: '#a041c2', text: 'Visit Us', bold: true }], type: 'paragraph' }],
              name: 'TITLE',
              id: '664ea7e0-d8c0-4f9a-a80b-01b687c9bfc0',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#ffffff',
                  backgroundColor: '#a73faf',
                  borderRadius: '25px',
                  color: '#ffffff',
                  borderWidth: '1px',
                  display: 'block',
                  width: '249px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'send',
              },
              name: 'BUTTON_1',
              id: '8f904eb0-417b-4415-b15c-71d34f418d7d',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_9',
          type: 'CONTACT_US',
        },
      ],
    },
  ],

  newMenu: [
    { name: 'Home', type: 'internal', url: '/' },
    { name: 'About us', type: 'internal', url: '/about-us' },
    { name: 'Services', type: 'internal', url: '/services' },
    { name: 'Blog', type: 'internal', url: '/blog' },
    { name: 'Contact us', type: 'internal', url: '/contact-us' },
  ],
  newMenuConfig: {
    settings: { backgroundColor: { color: '#E5E5E5', enabled: true } },
    links: [
      { name: 'Home', type: 'internal', url: '/' },
      { name: 'Contact us', type: 'email', url: 'Email' },
    ],
    id: 'VARIANT_6',
    tags: [],
  },
  newFooter: {
    settings: { backgroundColor: { color: '#E5E5E5', enabled: true }, textColor: { color: '#94328c', enabled: true } },
    designedBy: 'Plly Staff',
    id: 'VARIANT_7',
    categories: [{ name: 'Explore', links: [{ name: 'Home', type: 'internal', url: '/' }] }],
    tags: [],
  },
  links: [],
}
