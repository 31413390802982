import { Template } from '.'
import { contactUs, faq, gallery, image, love, text, quote, aboutUs, blog, team } from './variants'

export const Template08: Template = {
  id: '08',
  pages: [
    {
      slug: '/',
      name: 'Home',
      blocks: [
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_1')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#000000', enabled: true } },
          },
          variables: [
            { data: 9, name: 'TRANSITION', id: '91343795-c0de-4dc2-a9d3-dc29bf01280e', category: 'IMAGE' },
            {
              data: [{ children: [{ color: '#000000', text: '' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'c594a98c-e704-4e9b-9a22-6b7025739ace',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#ffffff', bold: true, text: '' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: '3c931287-163b-4acd-acef-05dc01473578',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-c709b5da-ba6d-4c04-b29d-5549347b7956',
              name: 'MEDIA',
              id: '1586074a-fe35-43fc-8b86-cce093362d50',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [{ color: '#000000', className: 'jumbotron-heading1', text: 'Discover the ' }],
                  type: 'paragraph',
                  align: 'center',
                },
                {
                  children: [{ color: '#fff4e8', className: 'jumbotron-heading1', text: 'world' }],
                  type: 'paragraph',
                  align: 'center',
                },
              ],
              name: 'TITLE',
              id: '19d2b275-8331-4481-b084-c29a608b578d',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          type: 'ATTENTION_GRABBER',
        },
        // {
        //   settings: {
        //     ...quote.find(i => i.variant === 'VARIANT_6')?.settings,
        //   },
        //   variables: [
        //     {
        //       data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
        //       name: 'DESCRIPTION',
        //       id: 'e8544528-5558-4534-bb98-ef1724eb6bba',
        //       category: 'TEXT',
        //     },
        //     {
        //       data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
        //       name: 'MEDIA',
        //       id: 'dc4dc092-8f36-4b2b-b6bd-2402fed6c1b4',
        //       category: 'IMAGE',
        //     },
        //     {
        //       data: [
        //         { children: [{ fontFamily: 'Abril Fatface', text: 'Sleep On Disruptive Design' }], type: 'paragraph' },
        //       ],
        //       name: 'TITLE',
        //       id: '35d5566d-d0ea-417d-99a8-b47a5dfe4290',
        //       category: 'TEXT',
        //     },
        //     {
        //       data: [
        //         {
        //           children: [
        //             {
        //               text: "Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience? Look no further than our custom site and portfolio design services. Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. Whether you're a digital nomad or a disruptive technology startup, we have the expertise to optimize your website for exceptional user experience on any device.",
        //             },
        //           ],
        //           type: 'paragraph',
        //         },
        //       ],
        //       name: 'SUBTITLE',
        //       id: 'ef1306e8-da6a-4610-97e4-d76951178a84',
        //       category: 'TEXT',
        //     },
        //   ],
        //   variant: 'VARIANT_6',
        //   type: 'QUOTE_SECTION',
        // },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 90, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-96efd818-ea2d-4405-b228-be3876f904d9',
                  name: [
                    { children: [{ fontFamily: 'Abril Fatface', text: 'ONG Investor-Friendly' }], type: 'paragraph' },
                  ],
                  description: [
                    {
                      children: [
                        {
                          text: "Whether you're a digital nomad or a disruptive technology startup, we have the expertise to optimize your website for exceptional user experience on any device.",
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-c6d1c212-e155-4799-b479-504e943395e2',
                  name: [
                    { children: [{ fontFamily: 'Abril Fatface', text: 'ONG Investor-Friendly' }], type: 'paragraph' },
                  ],
                  description: [
                    {
                      children: [
                        {
                          text: "Whether you're a digital nomad or a disruptive technology startup, we have the expertise to optimize your website for exceptional user experience on any device.",
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-54d8366a-c565-4f8e-aca0-9e438c72eae8',
                  name: [
                    { children: [{ fontFamily: 'Abril Fatface', text: 'ONG Investor-Friendly' }], type: 'paragraph' },
                  ],
                  description: [
                    {
                      children: [
                        {
                          text: "Whether you're a digital nomad or a disruptive technology startup, we have the expertise to optimize your website for exceptional user experience on any device.",
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: '547a632f-cfe4-488c-aedd-0ec07b1cd31a',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'ONG Investor-Friendly', bold: true }], type: 'paragraph' }],
              name: 'TITLE',
              id: '81f81058-caa2-4d96-b753-1f5de6670bcd',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'TEXT',
        },
        {
          settings: {
            ...aboutUs.find(i => i.variant === 'VARIANT_3')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-d5a35388-e16e-483c-84a2-edf8de371f8d',
              name: 'MEDIA',
              id: '1a66b5e4-55af-4554-a95e-522230800875',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'Thrilling Design for Investors' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '5af3cb3c-2b47-44ef-a3e8-19a313b7f6ab',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ fontFamily: 'Abril Fatface', text: 'ONG Investor-Friendly' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'ec495eb2-69d1-4e31-9d57-fb4bea17098d',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'For B2B startups and those building their first site, our alluring web and UX design services provide the invigorating expertise you need to create an intuitive, user-centered experience. Our streamlined design process ensures that your site is not only visually appealing but also easy to navigate, with modern layouts that include an interactive user interface and engaging typography. Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'f9c1211b-5239-4b31-bc7e-efcdf47a6c76',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_3',
          type: 'ABOUT_US',
        },
        {
          settings: {
            ...faq.find(i => i.variant === 'VARIANT_1')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-d99ecbd9-2be6-40ed-a772-ee2afe6ecc57',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        { color: '#000000', text: 'With experience showcasing unicorn bloggers and influencers' },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                  title: [
                    {
                      children: [{ fontFamily: 'Abril Fatface', color: '#000000', text: 'Yeet  Design', bold: true }],
                      type: 'paragraph',
                    },
                  ],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-f52540c1-50ac-496f-8793-99159f35073a',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        { color: '#000000', text: 'With experience showcasing unicorn bloggers and influencers.' },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                  title: [
                    {
                      children: [{ fontFamily: 'Abril Fatface', color: '#000000', text: 'Yeet Design', bold: true }],
                      type: 'paragraph',
                    },
                  ],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-836702ea-00b9-4019-94d7-06b9296bde0c',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        { color: '#000000', text: 'With experience showcasing unicorn bloggers and influencers.' },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                  title: [
                    {
                      children: [{ fontFamily: 'Abril Fatface', color: '#000000', text: 'Yeet Design', bold: true }],
                      type: 'paragraph',
                    },
                  ],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-1df11270-6da9-466e-8a0a-92121a6829bc',
                  year: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        { color: '#000000', text: 'With experience showcasing unicorn bloggers and influencers.' },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '4',
                  title: [
                    {
                      children: [{ fontFamily: 'Abril Fatface', color: '#000000', text: 'Yeet Design', bold: true }],
                      type: 'paragraph',
                    },
                  ],
                  items: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
              ],
              name: 'ITEMS',
              id: '41e3a5da-152b-457a-a989-897b8398aca9',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      fontFamily: 'Abril Fatface',
                      color: '#000000',
                      text: 'Accelerator-Approved UX Design',
                      bold: true,
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'TITLE',
              id: '93510120-542c-4d04-b6da-ade1e407cc73',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#eee',
                      text: 'Yeet Design',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '21bc1a1b-dcbd-43e7-9cc3-977c8362c9e3',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#000000',
                      text: 'With our high-octane mobile-friendly design and invigorating user-friendly interface, we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'db1de06e-4394-4c48-8f6a-411357067290',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          type: 'FAQ',
        },
        {
          settings: {
            ...quote.find(i => i.variant === 'VARIANT_5')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ededed', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'DESCRIPTION',
              id: 'b617b638-cead-45ef-81ec-bcb46ae74271',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-5247c2b1-7157-4c8e-8fae-fe9fafa29f19',
              name: 'MEDIA',
              id: '5a523a82-385d-4e8b-9c99-9f101b758d5c',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      fontFamily: 'Dancing Script',
                      color: '#737373',
                      text: 'Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. ',
                    },
                  ],
                  type: 'paragraph',
                  align: 'left',
                },
              ],
              name: 'TITLE',
              id: '2bd72cc0-a203-4ebd-a3f6-d2d0fe94824a',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [{ fontFamily: 'Quicksand', color: '#bf741a', text: 'Benjamin Cruise', bold: true }],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '659ca9f6-0f6b-4859-8fab-1dc14cb33b39',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_5',
          type: 'QUOTE_SECTION',
        },
        {
          settings: {
            ...gallery.find(i => i.variant === 'VARIANT_10')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-fea746c9-62b3-4eab-a7a6-71ef02a6342a',
                  description: [
                    {
                      children: [{ text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia' }],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                  title: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-e98aaba2-83ad-4f14-a53c-70ede9654dea',
                  description: [
                    {
                      children: [{ text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia' }],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                  title: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-85a7066f-b4eb-4836-b866-6c52a13ad8c8',
                  description: [
                    {
                      children: [{ text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia' }],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                  title: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-3694a581-7c24-49a5-81a8-dac9429fd314',
                  description: [
                    {
                      children: [{ text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia' }],
                      type: 'paragraph',
                    },
                  ],
                  id: '4',
                  title: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-59710965-9bd1-4371-be18-76ad65d22ee3',
                  description: [
                    {
                      children: [{ text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia' }],
                      type: 'paragraph',
                    },
                  ],
                  id: '5',
                  title: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-758c9195-7435-453b-aa49-df981ab6895e',
                  description: [
                    {
                      children: [{ text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia' }],
                      type: 'paragraph',
                    },
                  ],
                  id: '6',
                  title: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
                },
              ],
              name: 'MEDIA',
              id: '904c48cf-980e-495f-87d3-8b0c1cf11b6f',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ fontFamily: 'Abril Fatface', text: 'Trending', bold: true }], type: 'paragraph' }],
              name: 'TITLE',
              id: '1b654ec2-9354-40f6-aace-873eb5ec2473',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_10',
          type: 'GALLERY',
        },
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_6')?.settings,
          },
          variables: [
            {
              data: [
                {
                  name: 'Email',
                  placeholder: 'example@gmail.com',
                  type: 'email',
                  error: 'email is required',
                },
              ],
              name: 'INPUTS',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. ',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '8f184b0f-bd65-42d4-a7e2-90fd9a641f4a',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#b7b5b5',
                  backgroundColor: '#bf6625',
                  borderRadius: '0px',
                  color: '#e5e5e5',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'send ..',
              },
              name: 'BUTTON_1',
              id: 'b01dfa26-8ce0-4e9f-8195-0e3c89aed67a',
              category: 'BUTTON',
            },
            {
              data: [
                { children: [{ fontFamily: 'Abril Fatface', text: 'Subscribe ', bold: true }], type: 'paragraph' },
              ],
              name: 'SUBTITLE',
              id: '774e382a-9d0c-43a2-b702-7a424de5f728',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'CONTACT_US',
        },
      ],
    },
    {
      slug: '/about-us',
      name: 'About us',
      blocks: [
        {
          settings: {
            ...image.find(i => i.variant === 'VARIANT_4')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  subtitle: 'Static Site Generator',
                  description: '',
                  id: '416b2b90-831b-4606-875b-88efd1850b2c',
                  title: 'React',
                },
                {
                  subtitle: 'CSS Framework',
                  description: '',
                  id: '019c7f7c-bc9f-4225-b079-de79ab398402',
                  title: 'Tailwind',
                },
                {
                  subtitle: 'Animation',
                  description: '',
                  id: '4eca94a8-f1bc-4700-92cd-f59d719a6587',
                  title: 'Framer Motion',
                },
                {
                  subtitle: 'Headless CMS',
                  description: '',
                  id: '9cb50815-d227-49d8-be8f-ca70bb3d87a3',
                  title: 'Sanity',
                },
                { subtitle: 'Hosting', description: '', id: '5840d7fb-769c-40ed-98a0-669932a5fd05', title: 'Vercel' },
              ],
              name: 'ITEMS',
              id: '3b49ff8f-df91-4c3e-be40-09aa408f50b5',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-05b48778-830b-4202-8155-191b342da983',
              name: 'MEDIA',
              id: '8429fff7-37f1-434c-b144-d0d2b2220cb0',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'About Us' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'e487ffda-84c2-4466-bcb7-7e218fb1363e',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: ' We offer website performance, website speed, website accessibility, website security, website maintenance, website optimization, website analytics, website testing, website updates, website improvements, and website enhancements to ensure your site is top-notch. With our high-octane mobile-friendly design and invigorating user-friendly interface, we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '84002a70-56be-4ac7-9c3a-d30d0335861f',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_4',
          type: 'IMAGE_OMG',
        },
        {
          settings: {
            ...team.find(i => i.variant === 'VARIANT_1')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-00a18318-9bb9-47ad-b34f-63c1986b3d95',
                  subtitle1: [{ children: [{ text: 'item name' }], type: 'paragraph' }],
                  subtitle: [{ children: [{ text: '----' }], type: 'paragraph' }],
                  name: [
                    { children: [{ text: '' }], type: 'paragraph' },
                    { children: [{ text: 'Bella Lawrence' }], type: 'paragraph' },
                  ],
                  description: [
                    {
                      children: [
                        {
                          text: 'Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                  name1: [{ children: [{ text: 'item name' }], type: 'paragraph' }],
                  description1: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-8ace8dd2-01ae-4558-a6f2-08ef459b754a',
                  subtitle1: [{ children: [{ text: 'item name' }], type: 'paragraph' }],
                  subtitle: [{ children: [{ text: '----' }], type: 'paragraph' }],
                  name: [
                    { children: [{ text: '' }], type: 'paragraph' },
                    { children: [{ text: 'Kris Ward' }], type: 'paragraph' },
                  ],
                  description: [
                    {
                      children: [
                        {
                          text: 'Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                  name1: [{ children: [{ text: 'item name' }], type: 'paragraph' }],
                  description1: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-95733307-3b4a-4ce4-87e3-39ff8199d3e4',
                  subtitle1: [{ children: [{ text: 'item name' }], type: 'paragraph' }],
                  subtitle: [{ children: [{ text: '---' }], type: 'paragraph' }],
                  name: [{ children: [{ text: 'Alex Smith' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                  name1: [{ children: [{ text: 'item name' }], type: 'paragraph' }],
                  description1: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                },
              ],
              name: 'TEAM_MEMBERS',
              id: '2562d158-b249-4e91-bfdc-74f8e4d6bfb3',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#000000', text: 'Meet the Team', bold: true }], type: 'paragraph' }],
              name: 'TITLE',
              id: '0914318e-3b8b-4c5b-8f9b-5aca2db1ca28',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#000000', text: 'Sleep On Disruptive Design' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'a492fe4d-182a-4a27-b24a-c46808c4d36f',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#000000',
                      text: 'Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'f755f1e0-5fcd-4dab-8664-9280c4ee92e4',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          type: 'TEAM_MEMBERS',
        },
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_6')?.settings,
          },
          variables: [
            {
              data: [{ name: 'Email', placeholder: 'example@gmail.com', type: 'email', error: 'email is required' }],
              name: 'INPUTS',
              id: '6479c546-7369-4ce2-ace9-095fdb2f4bb6',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'At Dead Startup Appeal, we specialize in reviving companies that have hit a roadblock. Our team of experts assesses the issues and implements tailored solutions to bring businesses back to life. With our unique approach, we turn dead startups into thriving enterprises with renewed appeal.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'dfbea4d4-bb43-4838-a7cf-bd0ce4c00d3f',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Get In Touch', bold: true }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '43d0b159-789c-4edc-b813-c9fcc40ebb4e',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#ffffff',
                  backgroundColor: '#b6642a',
                  borderRadius: '0px',
                  color: '#ffffff',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'send ..',
              },
              name: 'BUTTON_1',
              id: '15c842cf-ff23-49e4-b33d-301889246dab',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_6',
          type: 'CONTACT_US',
        },
      ],
    },
    {
      slug: '/offers',
      name: 'Offers',
      blocks: [
        {
          settings: {
            ...image.find(i => i.variant === 'VARIANT_7')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  subtitle: 'Static Site Generator',
                  description: '',
                  id: 'dad18c52-edd2-46c3-b337-a0b374632a5f',
                  title: 'React',
                },
                {
                  subtitle: 'CSS Framework',
                  description: '',
                  id: '325d7014-76db-4c24-864d-926617a99116',
                  title: 'Tailwind',
                },
                {
                  subtitle: 'Animation',
                  description: '',
                  id: 'db2cd18e-79ff-44d1-9ee4-83638e879d99',
                  title: 'Framer Motion',
                },
                {
                  subtitle: 'Headless CMS',
                  description: '',
                  id: 'bf989eaf-752f-4077-9814-7389b186b0db',
                  title: 'Sanity',
                },
                { subtitle: 'Hosting', description: '', id: '5921acec-2156-4a6c-8734-1ccf992e8bf4', title: 'Vercel' },
              ],
              name: 'ITEMS',
              id: '849f86e4-8e59-4af7-9fd3-3276926630c0',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-3e6d1036-dd75-40bf-a9f8-985af9ebf68a',
              name: 'MEDIA',
              id: 'd85c5b3e-538c-4258-9579-253d24bdc804',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'Our Offers' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '84f01094-5237-4be8-a7b3-82f68827d242',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'At our agency, we prioritize user satisfaction, user retention, and user conversion. We offer website performance, website speed, website accessibility, website security, website maintenance, website optimization, website analytics, website testing, website updates, website improvements, and website enhancements to ensure your site is top-notch. With our high-octane mobile-friendly design and invigorating user-friendly interface, we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: 'bd84fd1d-5f28-4272-9c83-0c9df3a17217',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_7',
          type: 'IMAGE_OMG',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_15')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 30, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Hits Different Investors' }], type: 'paragraph', align: 'center' }],
              name: 'TITLE',
              id: '86a69d42-c774-4a5f-b4b0-e775e668f09a',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
                    },
                  ],
                  type: 'paragraph',
                  align: 'center',
                },
              ],
              name: 'PARAGRAPH',
              id: '67092131-777d-433e-acf3-60b0785b3476',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_15',
          type: 'TEXT',
        },
        {
          settings: {
            ...aboutUs.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 30, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#EBEBEB', enabled: true } },
          },
          variables: [
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-000cb3a0-3df3-467e-96ee-a554a032bb42',
              name: 'MEDIA',
              id: '376ea077-723f-457d-995f-c7eef6ebbd3d',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'Crypto Investment Chic' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '7970c577-f372-4ed0-8312-f61366e1b1ca',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ highlightColor: '#e37d2c', color: '#ffffff', text: '  140$  ' }], type: 'paragraph' },
              ],
              name: 'SUBTITLE',
              id: '09b8ae28-20df-4b29-acac-6f947baca78f',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: 'd0b65b9c-9c9e-4af7-97bd-3d37cfa0d29b',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'ABOUT_US',
        },
        {
          settings: {
            ...aboutUs.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#EBEBEB', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Low-Key Accelerator Ready' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '9124044f-809d-4da0-86ca-9593a54747b8',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ highlightColor: '#c87021', color: '#ffffff', text: '  140$  ' }], type: 'paragraph' },
              ],
              name: 'SUBTITLE',
              id: '433254e6-5bf9-4906-8d05-74c65a39dc7e',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-c7c11b6c-8cd5-483f-8d3d-c874263ee75a',
              name: 'MEDIA',
              id: '90144099-7ff2-4063-82a4-8dec452a2eb3',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '4083a14f-bdae-4a24-a565-c6f4c4a6f1e5',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'ABOUT_US',
        },
        {
          settings: {
            ...aboutUs.find(i => i.variant === 'VARIANT_2')?.settings,
            color: { background: { value: '#EBEBEB', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Crypto Investment Chic' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '8158979e-bbc1-44a6-9815-eaa6465de2fa',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ highlightColor: '#c76621', color: '#ebeae9', text: '  140$  ' }], type: 'paragraph' },
              ],
              name: 'SUBTITLE',
              id: '66173e44-15f9-4338-ac77-1fc6ecb0b9aa',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'we have the expertise to optimize your website for exceptional user experience on any device.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '46c297c8-5204-44b3-9b2d-b15cd0631f3c',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-9d407108-a91e-4033-b763-1034329f075a',
              name: 'MEDIA',
              id: '05b6445d-24cf-400b-b2d9-b7bcbad83eb3',
              category: 'IMAGE',
            },
          ],
          variant: 'VARIANT_2',
          type: 'ABOUT_US',
        },
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_6')?.settings,
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  name: 'Email',
                  placeholder: 'example@gmail.com',
                  type: 'email',
                  error: 'email is required',
                },
              ],
              name: 'INPUTS',
              id: 'd2a98ed3-a873-4f93-b55f-501707918334',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Get In Touch', bold: true }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'f35bd907-5097-46f5-ba70-811ced083808',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'we have the expertise to optimize your website for exceptional user experience on any device.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '5e024598-f9e6-4c91-8a3b-dd042e568756',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#e9e9e9',
                  backgroundColor: '#c06632',
                  borderRadius: '0px',
                  color: '#e8e8e8',
                  borderWidth: '1px',
                  display: 'block',
                  width: '128px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'send ..',
              },
              name: 'BUTTON_1',
              id: 'f9b2abeb-13e8-4644-9f64-13b36775f744',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_6',
          type: 'CONTACT_US',
        },
      ],
    },
    {
      slug: '/news',
      name: 'News',
      blocks: [
        {
          settings: {
            ...image.find(i => i.variant === 'VARIANT_4')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  subtitle: 'Static Site Generator',
                  description: '',
                  id: '41939b80-8e59-4b8b-8e3f-e4c915bd2308',
                  title: 'React',
                },
                {
                  subtitle: 'CSS Framework',
                  description: '',
                  id: 'bfca3ad4-b9fc-4807-8397-7bc1c2ace007',
                  title: 'Tailwind',
                },
                {
                  subtitle: 'Animation',
                  description: '',
                  id: 'e6bec465-2094-45a6-b043-983ace2fbb63',
                  title: 'Framer Motion',
                },
                {
                  subtitle: 'Headless CMS',
                  description: '',
                  id: 'e4aa6861-1031-4d23-898b-7328550d0c96',
                  title: 'Sanity',
                },
                { subtitle: 'Hosting', description: '', id: '360f9408-02a5-4e3f-a048-f68b2ef84c67', title: 'Vercel' },
              ],
              name: 'ITEMS',
              id: '29e41ab0-54fc-4d49-a2f9-dc7e0f21344b',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-6811666b-92c0-4e25-b337-ecfd4b9f67a1',
              name: 'MEDIA',
              id: 'ff21084c-46a6-4117-8997-8264a5497695',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'Blog And Articles' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'c3696d5f-d3e4-4eae-a71a-98f0c36d39dd',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'At our agency, we prioritize user satisfaction, user retention, and user conversion. We offer website performance, website speed, website accessibility, website security, website maintenance, website optimization, website analytics, website testing, website updates, website improvements, and website enhancements to ensure your site is top-notch. With our high-octane mobile-friendly design and invigorating user-friendly interface, we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '73a53214-e01f-4345-a847-e75b0c0a27c9',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_4',
          type: 'IMAGE_OMG',
        },
        {
          settings: {
            ...blog.find(i => i.variant === 'VARIANT_3')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 60, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ededed', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Lorem ipsum dolor sit.' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '4f1b3ce0-a206-4f1b-a36e-42bb613b2890',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repudiandae obcaecati expedita nobis nesciunt voluptatibus quasi fuga quod vitae nulla esse..',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '37a916bb-5ef7-4f2c-a8eb-ba45f1598c5b',
              category: 'TEXT',
            },
            {
              data: [
                {
                  date: '1 MAY 2016',
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-cb603365-7b8e-4e1a-b1a9-97e7550436a8',
                  texts: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  name: [{ children: [{ text: 'Investor-Focused UX Design' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'For B2B startups and those building their first site, our alluring web and UX design services provide the invigorating expertise you need to create an intuitive, user-centered experience. Our streamlined design process ensures that your site is not only visually appealing but also easy to navigate, with modern layouts that include an interactive user interface and engaging typography. Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '588f2ad2-09d8-47b4-a46e-24d03f34741e',
                  title: [{ children: [{ text: 'item name' }], type: 'paragraph' }],
                },
                {
                  date: '1 MAY 2016',
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-50594cad-3a77-468d-92cd-fff32c729bcc',
                  texts: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  name: [{ children: [{ text: 'GOAT Acquisition UX' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'For B2B startups and those building their first site, our alluring web and UX design services provide the invigorating expertise you need to create an intuitive, user-centered experience. Our streamlined design process ensures that your site is not only visually appealing but also easy to navigate, with modern layouts that include an interactive user interface and engaging typography. Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '6c892b82-9206-4fed-8623-e94080281561',
                  title: [{ children: [{ text: 'item name' }], type: 'paragraph' }],
                },
                {
                  date: '1 MAY 2016',
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-d42eddb5-11d6-4344-9b0f-90121ecb9bf5',
                  texts: [{ children: [{ text: 'item description' }], type: 'paragraph' }],
                  name: [{ children: [{ text: 'Accelerate Appeal' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'At our agency, we prioritize user satisfaction, user retention, and user conversion. We offer website performance, website speed, website accessibility, website security, website maintenance, website optimization, website analytics, website testing, website updates, website improvements, and website enhancements to ensure your site is top-notch. With our high-octane mobile-friendly design and invigorating user-friendly interface, we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: 'c2f19c5d-19d9-48aa-a662-e6bfd7b99fa4',
                  title: [{ children: [{ text: 'item name' }], type: 'paragraph' }],
                },
              ],
              name: 'ITEMS',
              id: '8717ff2e-802b-4ed2-ae59-304af03f16c9',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ highlightColor: '#e3e3e3', text: '' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '48b94e48-b4d5-4b3d-9a0b-1c9469a4a487',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_3',
          type: 'BLOG',
        },
      ],
    },
    {
      slug: '/contact-us',
      name: 'Contact us',
      blocks: [
        {
          settings: {
            ...image.find(i => i.variant === 'VARIANT_9')?.settings,
          },
          variables: [
            {
              data: [
                {
                  subtitle: 'Static Site Generator',
                  description: '',
                  id: 'ac0a2989-4ee4-4638-9042-f0e102e46c4d',
                  title: 'React',
                },
                {
                  subtitle: 'CSS Framework',
                  description: '',
                  id: '2d51a94b-ecb7-44a6-a529-873f9930c6cb',
                  title: 'Tailwind',
                },
                {
                  subtitle: 'Animation',
                  description: '',
                  id: '2df45f97-82ea-4bed-a698-bdf2cc141562',
                  title: 'Framer Motion',
                },
                {
                  subtitle: 'Headless CMS',
                  description: '',
                  id: '6aabce98-2f11-436a-8ff5-57290ffcd0d2',
                  title: 'Sanity',
                },
                { subtitle: 'Hosting', description: '', id: 'a5ad5590-7236-4ec6-9913-09672c4cdebc', title: 'Vercel' },
              ],
              name: 'ITEMS',
              id: 'f58425e5-8356-49c0-bf35-0af476282f59',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-cf775b40-bcc5-499f-9772-c6bb0c3e4b84',
              name: 'MEDIA',
              id: 'fb7e9c21-dd9e-4a2b-8dbb-00f1bf7a99b6',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: '' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'f4c69161-57e6-4943-8755-eb950d816ab0',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ text: '' }], type: 'paragraph' },
                { children: [{ text: 'Start your journey with Us' }], type: 'paragraph' },
              ],
              name: 'TITLE',
              id: 'b55ad2d4-a20f-493e-8a0b-f31043528951',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_9',
          type: 'IMAGE_OMG',
        },
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_4')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 60, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  name: 'First Name',
                  id: 'c61c2eca-7e0e-45b7-9d49-dac5e05f1c9a',
                  type: 'text',
                  placeholder: 'First Name',
                  error: 'First Name is required',
                },
                {
                  name: 'last Name',
                  id: '0716550f-7729-435f-ab18-e5145940f026',
                  placeholder: 'Last Name',
                  type: 'text',
                  error: 'last Name is required',
                },
                {
                  name: 'Email',
                  id: '35af96e2-f85d-42e8-a03c-ed06eb0c2bfa',
                  placeholder: 'example@gmail.com',
                  type: 'email',
                  error: 'email is required',
                },
                {
                  name: 'Message',
                  id: '91a57867-e490-42a2-b0d0-34511fc51e4c',
                  placeholder: 'write a message',
                  type: 'textarea',
                  error: 'message is required',
                },
              ],
              name: 'INPUTS',
              id: '8e838454-ac3b-466b-9451-1cb34fff225b',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#010101', text: 'Contact us', bold: true }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'ac37c7b9-e7eb-4986-aaf3-579c6baf24cc',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ color: '#e88618', text: 'Bootstrap Your UX Design', bold: true }], type: 'paragraph' },
              ],
              name: 'SUBTITLE',
              id: '0d08a385-34a9-4fbb-8f76-7521b0b109ca',
              category: 'TEXT',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#ffffff',
                  backgroundColor: '#d26a10',
                  borderRadius: '0px',
                  color: '#ffffff',
                  borderWidth: '1px',
                  display: 'block',
                  width: '249px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'send a message',
              },
              name: 'BUTTON_1',
              id: 'bc9c1b2d-cf30-4cbf-a729-17b4d48baa51',
              category: 'BUTTON',
            },
          ],
          variant: 'VARIANT_4',
          type: 'CONTACT_US',
        },
      ],
    },
  ],
  newMenu: [
    { name: 'Home', type: 'internal', url: '/' },
    { name: 'About us', type: 'internal', url: '/about-us' },
    { name: 'Offers', type: 'internal', url: '/offers' },
    { name: 'News', type: 'internal', url: '/news' },
    { name: 'Contact us', type: 'internal', url: '/contact-us' },
  ],
  newMenuConfig: {
    settings: { backgroundColor: { color: '#eaeaea', enabled: true }, textColor: { color: '#000000', enabled: true } },
    links: [
      { name: 'Home', id: '21b67c62-0633-49de-beed-dbdcb4aa47fb', type: 'internal', url: '/' },
      { name: 'Contact Us', id: '5730131f-0ab9-49bf-903f-deea36b67ac9', type: 'email', url: 'hello@example.com' },
    ],
    styleName: 'VARIANT 10',
    id: 'VARIANT_10',
    tags: [],
  },
  newFooter: {
    settings: {
      backgroundColor: { color: '#392115', enabled: true },
      spacing: {
        padding: { top: 30, left: 0, bottom: 30, right: 0 },
        margin: { top: 0, left: 0, bottom: 0, right: 0 },
        enabled: true,
      },
    },
    designedBy: 'Plly Staff',
    id: 'VARIANT_5',
    categories: [
      {
        name: 'Explore',
        links: [{ name: 'Home', id: 'f6767087-c88b-428b-b28e-e1bc386f0e75', type: 'internal', url: '/' }],
        id: '5da3f21c-85f4-4f5a-9e75-c90ad96578c0',
      },
    ],
    tags: [],
  },
  links: [],
}
