import { Descendant } from 'slate'
import {
  ImageOmgItemsGroup,
  ImageOmgVariables,
  ImageOmgVariants,
  Section,
  SectionTags,
} from 'src/_polly/components/src/sections'
import { v4 as uuid } from 'uuid'
import { DEFAULT_VARIANT_SETTINGS, getSectionVariants } from './get-variants'

const tags: Record<ImageOmgVariants, SectionTags[]> = {
  VARIANT_1: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_2: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_3: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_4: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_5: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_6: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_7: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_8: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_9: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_10: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_11: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_12: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_13: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_14: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
  VARIANT_15: ['IMAGE', 'ANIMATION', 'SLIDESHOW'],
}

export function getImageOmgVariants() {
  const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Title' }] }]
  const SubTitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Subtitle' }] }]
  const media =
    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'
  const variants = getSectionVariants('IMAGE_OMG')
  const sections: Section<ImageOmgVariants, ImageOmgVariables>[] = []

  variants.forEach(variant => {
    if (variant.id === 'VARIANT_2') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Title' }] }]
      const data: Section<ImageOmgVariants, ImageOmgVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'IMAGE_OMG',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: SubTitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'IMAGE', data: media, name: 'MEDIA' },
          {
            id: uuid(),
            category: 'TEXT',
            name: 'ITEMS',
            data: [
              {
                id: uuid(),
                title: 'React',
                subtitle: 'Static Site Generator',
                description: '',
              },
              {
                id: uuid(),
                title: 'Tailwind',
                subtitle: 'CSS Framework',
                description: '',
              },
              {
                id: uuid(),
                title: 'Framer Motion',
                subtitle: 'Animation',
                description: '',
              },
              {
                id: uuid(),
                title: 'Sanity',
                subtitle: 'Headless CMS',
                description: '',
              },
              {
                id: uuid(),
                title: 'Vercel',
                subtitle: 'Hosting',
                description: '',
              },
            ] as ImageOmgItemsGroup[],
          },
        ],
        variant: variant.id as ImageOmgVariants,
        tags: tags[variant.id as ImageOmgVariants],
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_3') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'DGucci Acquisition Success' }] }]
      const SubTitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'UX Design for Crowdfunding Success' }] }]

      const data: Section<ImageOmgVariants, ImageOmgVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'IMAGE_OMG',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: SubTitle, name: 'SUBTITLE' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://images.unsplash.com/photo-1498758536662-35b82cd15e29?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxMHx8Y3VzdG9tZXIlMjB3aXRoJTIwcGxhbnR8ZW58MHx8fHwxNjc4MDA2NDI5&ixlib=rb-4.0.3&q=80&w=1080',
            name: 'MEDIA',
          },
        ],
        variant: variant.id as ImageOmgVariants,
        tags: tags[variant.id as ImageOmgVariants],
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_4') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Dope Burn Rate' }] }]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: ' Dope Investor Design is a full-service investment firm with a focus on innovative design. We invest in companies that prioritize exceptional user experience and visual appeal. Our team of experienced designers and investors work hand-in-hand to support the growth and success of our portfolio companies.',
            },
          ],
        },
      ]
      const data: Section<ImageOmgVariants, ImageOmgVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'IMAGE_OMG',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: SubTitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://images.unsplash.com/photo-1448697138198-9aa6d0d84bf4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxkZWNvcmV8ZW58MHx8fHwxNjc3NTk2NjQ0&ixlib=rb-4.0.3&q=80&w=1080',
            name: 'MEDIA',
          },
        ],
        variant: variant.id as ImageOmgVariants,
        tags: tags[variant.id as ImageOmgVariants],
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_7') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Dope Burn Rate' }] }]
      const SubTitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'UX Design for Crowdfunding Success' }] }]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: ' Dope Investor Design is a full-service investment firm with a focus on innovative design. We invest in companies that prioritize exceptional user experience and visual appeal. Our team of experienced designers and investors work hand-in-hand to support the growth and success of our portfolio companies.',
            },
          ],
        },
      ]
      const data: Section<ImageOmgVariants, ImageOmgVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'IMAGE_OMG',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: SubTitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://images.unsplash.com/photo-1448697138198-9aa6d0d84bf4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxkZWNvcmV8ZW58MHx8fHwxNjc3NTk2NjQ0&ixlib=rb-4.0.3&q=80&w=1080',
            name: 'MEDIA',
          },
        ],
        variant: variant.id as ImageOmgVariants,
        tags: tags[variant.id as ImageOmgVariants],
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_8') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Dope Burn Rate' }] }]
      const SubTitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'UX Design for Crowdfunding Success' }] }]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: ' Dope Investor Design is a full-service investment firm with a focus on innovative design. We invest in companies that prioritize exceptional user experience and visual appeal. Our team of experienced designers and investors work hand-in-hand to support the growth and success of our portfolio companies.',
            },
          ],
        },
      ]
      const data: Section<ImageOmgVariants, ImageOmgVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'IMAGE_OMG',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: SubTitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://images.unsplash.com/photo-1448697138198-9aa6d0d84bf4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxkZWNvcmV8ZW58MHx8fHwxNjc3NTk2NjQ0&ixlib=rb-4.0.3&q=80&w=1080',
            name: 'MEDIA',
          },
        ],
        variant: variant.id as ImageOmgVariants,
        tags: tags[variant.id as ImageOmgVariants],
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_9') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Dope Burn Rate' }] }]
      const SubTitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'UX Design for Crowdfunding Success' }] }]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: ' Dope Investor Design is a full-service investment firm with a focus on innovative design. We invest in companies that prioritize exceptional user experience and visual appeal. Our team of experienced designers and investors work hand-in-hand to support the growth and success of our portfolio companies.',
            },
          ],
        },
      ]
      const data: Section<ImageOmgVariants, ImageOmgVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'IMAGE_OMG',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: SubTitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://images.unsplash.com/photo-1517836357463-d25dfeac3438?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxneW1zfGVufDB8fHx8MTY3ODAxNzk1OQ&ixlib=rb-4.0.3&q=80&w=1080',
            name: 'MEDIA',
          },
        ],
        variant: variant.id as ImageOmgVariants,
        tags: tags[variant.id as ImageOmgVariants],
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_15') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'CARE PETS' }] }]
      const subTitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'GUCCI SUCCESS' }] }]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'You don’t have to wear an ironic t-shirt and grow a patchy beard to make barista-level coffee. Just subscribe to get our free emails - filled with tips on finding and brewing crazy delicious coffee.',
            },
          ],
        },
      ]
      const data: Section<ImageOmgVariants, ImageOmgVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'IMAGE_OMG',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subTitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          // { id: uuid(), category: 'TEXT', data: text, name: 'CIRCLE_TEXT' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://images.unsplash.com/photo-1516750105099-4b8a83e217ee?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw4fHxwaW5rJTIwcGV0c3xlbnwwfHx8fDE2NzgwODQ3MjQ&ixlib=rb-4.0.3&q=80&w=1080',
            name: 'MEDIA',
          },
        ],
        variant: variant.id as ImageOmgVariants,
        tags: tags[variant.id as ImageOmgVariants],
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else {
      const data: Section<ImageOmgVariants, ImageOmgVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'IMAGE_OMG',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: SubTitle, name: 'SUBTITLE' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
            name: 'MEDIA',
          },
          {
            id: uuid(),
            category: 'TEXT',
            name: 'ITEMS',
            data: [
              {
                id: uuid(),
                title: 'React',
                subtitle: 'Static Site Generator',
                description: '',
              },
              {
                id: uuid(),
                title: 'Tailwind',
                subtitle: 'CSS Framework',
                description: '',
              },
              {
                id: uuid(),
                title: 'Framer Motion',
                subtitle: 'Animation',
                description: '',
              },
              {
                id: uuid(),
                title: 'Sanity',
                subtitle: 'Headless CMS',
                description: '',
              },
              {
                id: uuid(),
                title: 'Vercel',
                subtitle: 'Hosting',
                description: '',
              },
            ] as ImageOmgItemsGroup[],
          },
        ],
        variant: variant.id as ImageOmgVariants,
        tags: tags[variant.id as ImageOmgVariants],
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    }
  })
  return sections
}
