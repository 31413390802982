import { motion } from 'framer-motion'
import SectionSlate from '../../../common/SectionSlatePreview'
import { TextSection as TextSectionType } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

function VariantTen({ section }: { section: TextSectionType }) {
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data
  let str = paragraph[0]?.children[0]?.text?.split(' ')
  let strArr = str?.length
  return (
    <>
      <div
        className="py-12 md:py-20 px-[20px] md:px-[42px]"
        style={{
          ...generateSectionBackground({
            defaultColor: 'white',
            backgroundSettings: section?.settings?.color?.background,
            image: null,
          }),
          ...generateSectionSpacing(section?.settings?.spacing),
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, type: 'spring' }}
        >
          <h2 className="md:text-3xl max-w-6xl">
            <SectionSlate initialValue={paragraph} previewMode />
          </h2>
        </motion.div>
        <motion.div initial={{ scale: 1.03 }} animate={{ scale: 1 }} transition={{ duration: 1 }} exit={{ opacity: 0 }}>
          <div
            className={`
            ${strArr > 20 ? 'mt-8 sm:mt-[-20%] md:mt-[-15%]' : 'mt-8 md:mt-10'}
            z-0
            `}
          >
            <img
              className="w-[95%] sm:w-[70%] md:w-[60%] lg:w-[50%] sm:h-[300px] md:h-[400px] lg:h-[500px] ml-auto mr-[3%] sm:mr-[5%] z-0 opacity-40 rounded"
              src={media}
              alt=""
            />
          </div>
        </motion.div>
      </div>
    </>
  )
}

export default VariantTen
