import Icon from './icons'
import { IconPropsT } from './types'

function Search(props: IconPropsT) {
  const { title = 'Search', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 47 48" size={size} title={title} {...otherProps}>
      <rect x="0.5" y="0.5" width="46" height="44" rx="5.5" stroke="black" />
      <path
        d="M28.6424 27.9572C30.0922 26.3277 30.85 24.1973 30.7549 22.0181C30.6598 19.8389 29.7194 17.7826 28.1332 16.2857C26.547 14.7888 24.4401 13.9692 22.2596 14.0009C20.0791 14.0326 17.9969 14.9131 16.4549 16.4555C14.9129 17.9979 14.0326 20.0807 14.0009 22.2617C13.9692 24.4428 14.7886 26.5503 16.2851 28.1369C17.7816 29.7235 19.8374 30.6642 22.016 30.7593C24.1946 30.8544 26.3245 30.0964 27.9535 28.6463L32.3643 33.0582C32.4096 33.1038 32.4636 33.1399 32.5231 33.1643C32.5827 33.1886 32.6465 33.2008 32.7108 33.2C32.8403 33.1993 32.9645 33.1485 33.0574 33.0582C33.1487 32.9668 33.2 32.8428 33.2 32.7136C33.2 32.5844 33.1487 32.4605 33.0574 32.3691L28.6424 27.9572ZM14.9975 22.3961C14.9975 20.9337 15.4311 19.5041 16.2433 18.2882C17.0556 17.0722 18.2101 16.1245 19.5609 15.5648C20.9116 15.0052 22.3979 14.8588 23.8319 15.1441C25.2658 15.4294 26.583 16.1336 27.6168 17.1677C28.6506 18.2018 29.3546 19.5193 29.6399 20.9536C29.9251 22.3879 29.7787 23.8746 29.2192 25.2257C28.6597 26.5768 27.7122 27.7316 26.4966 28.5441C25.2809 29.3566 23.8517 29.7902 22.3897 29.7902C20.43 29.7877 18.5512 29.0079 17.1654 27.6218C15.7797 26.2356 15 24.3564 14.9975 22.3961Z"
        stroke="black"
        strokeWidth="1"
        fill="white"
      />
    </Icon>
  )
}

export default Search
