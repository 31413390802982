import { useContext, useEffect, useState } from 'react'
import { WebsiteContext } from 'src/components/context/WebsiteContext'
import { Loader } from 'src/components/loader'
import ModalDialogV2 from 'src/components/new/ModalDialogV2'
import LinkIcon from 'src/icons/Link'
import { getWebsiteByID, saveWebsite } from 'src/lib/services/website.service'
import useWebsite from 'src/store/website'
import { NewFooter as NewFooterType } from 'src/_polly/components/src/footer/footer'
import FooterPreviewRenderer from 'src/_polly/components/src/footer/FooterPreviewRenderer'
import { Section } from 'src/_polly/components/src/sections'
import { ReactComponent as PencilIcon } from '../../../icons/Pencil.svg'
import AdvancedMenuFooter from '../../advanced/AdvancedMenuFooter'
import { getWebsiteFooter } from './footer'
import FooterEditor from './FooterEditor'
import NewFooterPreview from './NewFooterPreview'

type NewFooterProps = {
  website: any
  rounded?: any
  lastSection?: Section<any, any>
  invalidate?: () => void
}

export default function NewFooter({ website, rounded, invalidate, lastSection }: NewFooterProps) {
  const websiteContext = useContext(WebsiteContext)
  const store = useWebsite(state => state)

  const [footer, setFooter] = useState<NewFooterType | null>(website?.newFooter ?? null)
  const [loading, setLoading] = useState(false)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [showFooterEditor, setShowFooterEditor] = useState(false)

  const fetchWebsite = () => {
    getWebsiteByID(websiteContext.websiteID).then(_website => {
      setFooter(_website?.newFooter ? JSON.parse(_website.newFooter) : null)
    })
  }

  const handleSelect = async (selectedFooter: NewFooterType) => {
    setLoading(true)
    const siteFooter = await getWebsiteFooter(websiteContext.websiteID)
    await saveWebsite({
      id: websiteContext.websiteID,
      newFooter: JSON.stringify({
        ...siteFooter,
        id: selectedFooter.id,
        categories: siteFooter?.categories,
      }),
    })
    setLoading(false)
    setShowPreviewModal(false)
    setShowFooterEditor(true)
  }

  useEffect(() => {
    fetchWebsite()
  }, [website])

  return (
    <div>
      <Loader show={loading} />
      <div className="">
        <div>
          {footer && (
            <FooterPreviewRenderer
              footer={footer}
              noShadow
              stroke
              rounded={rounded}
              disableHover
              website={{
                ...website,
                config: JSON.parse(website?.config ?? '{}'),
              }}
            />
          )}
        </div>

        <div className="flex items-center h-[46px] px-[5px] mt-[2px] border-t border-b border-black dark:border-[#D6D6D6]">
          <h2 className="w-full font-bold text-[13px] md:text-[15px] dark:text-white">Footer</h2>
          <div className="w-full flex items-center gap-[28px]">
            <button type="button" onClick={() => setShowPreviewModal(true)}>
              <PencilIcon className="hidden md:block" />
              <PencilIcon width={22} className="md:hidden block" />{' '}
            </button>
            <button type="button" onClick={() => setShowFooterEditor(true)}>
              <LinkIcon
                size={24}
                className="hidden md:block stroke-black fill-black dark:fill-white dark:stroke-white"
              />
              <LinkIcon
                size={20}
                className="block md:hidden stroke-black fill-black dark:fill-white dark:stroke-white"
              />
            </button>
            {store.website && (
              <AdvancedMenuFooter mode="icon" footer={store.website.newFooter} component="footer" onBack={invalidate} />
            )}
          </div>
        </div>
      </div>

      <ModalDialogV2
        open={showPreviewModal}
        onBack={() => {
          setShowPreviewModal(false)
          invalidate?.()
          fetchWebsite()
        }}
      >
        <NewFooterPreview onSelect={handleSelect} website={website} lastSection={lastSection} />
      </ModalDialogV2>

      <ModalDialogV2
        open={showFooterEditor}
        onBack={() => {
          setShowFooterEditor(false)
          invalidate?.()
          fetchWebsite()
        }}
      >
        {footer && (
          <FooterEditor
            onBack={() => {
              setShowFooterEditor(false)
              invalidate?.()
              fetchWebsite()
            }}
          />
        )}
      </ModalDialogV2>
    </div>
  )
}
