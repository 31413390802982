/* eslint-disable @typescript-eslint/no-shadow */
import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { NewFooter } from 'src/_polly/components/src/footer/footer'
import { NewMenuLinkType } from 'src/_polly/components/src/menu/menu'
import Delete from 'src/icons/Delete'
import EditPlus from 'src/icons/EditPlus'
import Settings from 'src/icons/Settings'
import {
  createPageRecord,
  getWebsiteByCloudfrontAlias,
  getWebsiteByID,
  saveWebsite,
} from 'src/lib/services/website.service'
import ColorModeSwitcher from 'src/pages/social/components/ColorModeSwitcher'
import { aiTemplateRequestPayload } from 'src/services/ai-template/ai-template-request'
import { generateAITemplate } from 'src/services/ai-template/generate-ai-template'
import { getSectionTemplates } from 'src/services/section.service'
import Animation from 'src/components/animation'
import { templates } from 'src/services/templates'
import { createNewWebsite, deleteWebsite } from 'src/services/website.service'
import useWebsite from 'src/store/website'
import { v4 as uuid } from 'uuid'
import InputText from '../../_polly/components/src/common/InputText'
import { Loader } from '../loader'
import ImageUploader from '../new/ImageUploader'
import ModalDialog from '../new/ModalDialog'
import ModalDialogV2 from '../new/ModalDialogV2'
import NewMenuAllLinks from './section/NewMenu/AllLinks'

const OLD_MENU_FOOTER = {
  menu: JSON.stringify({
    pages: [
      {
        title: 'Home',
        type: 'internal',
        url: '/',
      },
    ],
    style: 'MENU_BAR1',
  }),
  footer: JSON.stringify({
    style: 'FOOTER2',
    info: {
      twitter: '#',
      phoneNumber: '123-456-7890',
      address: '123 Mockingbird Lane, San Fancis',
      copyRight: '© 2023 Ayden Pitt',
      facebook: '#',
      name: 'Ayden Pitt',
      instagram: '#',
      fax: '123-456-7890',
      email: 'hello@example.com',
    },
  }),
}

interface WebsiteSettingsProps {
  open: boolean
  onClose: () => void
  onMenuBack?: any
  id?: string
  websiteLogo?: string
  fetchWebsite?: any
  isWebSetting?: boolean
}

export default function WebsiteSettings({
  open,
  onClose,
  onMenuBack,
  id,
  websiteLogo,
  fetchWebsite,
  isWebSetting,
}: WebsiteSettingsProps) {
  const navigate = useNavigate()
  const websiteStore = useWebsite(state => state)

  const {
    register,
    setError,
    formState: { errors },
    setValue,
    handleSubmit,
    watch,
  } = useForm()

  const [loading, setLoading] = useState(false)
  const [logo, setLogo] = useState<string | null>(null)
  const [showModalUploadLogo, setShowModalUploadLogo] = useState(false)
  const [showModalChooseTemplate, setShowModalChooseTemplate] = useState(false)
  const [showDomainModal, setShowDomainModal] = useState(false)
  const [sectionTemplates, setSectionTemplates] = useState<Awaited<ReturnType<typeof getSectionTemplates>>>()
  const [selectedSectionTemplate, setSelectedSectionTemplate] = useState<
    Awaited<ReturnType<typeof getSectionTemplates>>[number] | null
  >(null)
  const [showName, setShowName] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showLogo, setShowLogo] = useState(false)
  const [showAllLinks, setShowAllLinks] = useState(false)
  const [showAnalytics, setShowAnalytics] = useState(false)
  const [aiTemplateSelected, setAiTemplateSelected] = useState(false)
  const [aiTemplateData, setAiTemplateData] = useState<ReturnType<typeof generateAITemplate> | null>(null)
  const [generatingAiTemplate, setGeneratingAiTemplate] = useState(false)

  const promptRef = useRef<HTMLTextAreaElement>(null)

  const onSubmit = async (form: any) => {
    setLoading(true)

    // if editing existing website
    if (id) {
      const { cloudfrontAlias } = form

      if (!/^[\w-]+$/.test(cloudfrontAlias)) {
        setError('cloudfrontAlias', {
          message: 'Domain can only contain letters, numbers, and dashes',
        })
        setLoading(false)
        return
      }

      if (cloudfrontAlias.length > 15) {
        setError('cloudfrontAlias', {
          message: 'Domain must be less than 15 characters',
        })
        setLoading(false)
        return
      }

      const domain = `${cloudfrontAlias}.techboom.network`

      const exists = await getWebsiteByCloudfrontAlias(domain)

      if (exists?.items.length && exists.items[0]?.id !== id) {
        setError('cloudfrontAlias', {
          message: 'Domain is already taken',
        })
        setLoading(false)
        return
      }

      await saveWebsite({
        id,
        ...form,
        config: JSON.stringify(form.config),
        cloudfrontAlias: domain,
      })
      // closes the settings modal and
      // !exiting the fn
      setLoading(false)
      onMenuBack()
      return
    }

    const links = [
      {
        id: uuid(),
        name: 'Home',
        url: '/',
        type: NewMenuLinkType.INTERNAL,
      },
    ]

    const menu = {
      newMenu: [...links],
      newMenuConfig: {
        tags: ['IMAGE'],
        id: 'VARIANT_1',
      },
    }

    const footer: NewFooter = {
      id: 'VARIANT_1',
      categories: [
        {
          id: uuid(),
          name: 'Explore',
          links: [...links],
        },
      ],
      designedBy: 'Plly Staff',
      tags: [],
    }

    // else create a new website
    try {
      const alias = form.name
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '')
      let domain = `${alias}.techboom.network`
      const exists = await getWebsiteByCloudfrontAlias(domain)

      if (exists?.items.length) {
        const random = Math.random().toString(36).substring(2, 7)
        domain = `${alias}-${random}.techboom.network`
      }

      const template = aiTemplateData || (selectedSectionTemplate ? templates[selectedSectionTemplate.id] : null)
      let homePageBlocks: string | null = null

      if (template) {
        const homePage = template.pages.find(p => p.slug === '/')
        if (homePage) {
          homePageBlocks = JSON.stringify(
            homePage.blocks.map(block => ({
              ...block,
              variables: block.variables.map((i: any) => ({ ...i, id: uuid() })), // generate id for variables
              isSection: true,
              id: uuid(),
            })),
          )
        }
      }

      const website = await createNewWebsite({
        data: {
          ...form,
          config: JSON.stringify(form.config),
          cloudfrontAlias: domain,
          newMenu: JSON.stringify(menu.newMenu),
          links: JSON.stringify(links),
          newMenuConfig: JSON.stringify(menu.newMenuConfig),
          newFooter: JSON.stringify(footer),
          ...OLD_MENU_FOOTER,
        },
        blocks: homePageBlocks,
      })

      if (template) {
        await saveWebsite({
          id: website?.id ?? '',
          newMenu: JSON.stringify(template.newMenu.map(i => ({ ...i, id: uuid() }))),
          links: JSON.stringify(template.links),
          newMenuConfig: JSON.stringify(template.newMenuConfig),
          newFooter: JSON.stringify(template.newFooter),
        })

        const promises: Promise<any>[] = []

        template.pages
          .filter(p => p.slug !== '/')
          .forEach(page => {
            const options = {
              websiteID: website?.id ?? '',
              name: page.name,
              path: page.slug,
              blocks: JSON.stringify(
                page.blocks.map(block => ({
                  ...block,
                  variables: block.variables.map((i: any) => ({ ...i, id: uuid() })), // generate id for variables
                  isSection: true,
                  id: uuid(),
                })),
              ),
            }
            createPageRecord(options)
          })

        await Promise.all(promises)
      }

      navigate(`/websites/${website?.id}`)
      localStorage.setItem(`tutorial_editpage_${website?.id}`, '1')
    } finally {
      setLoading(false)
    }
  }
  const name = watch('name')
  const title = watch('config.title')

  const fetchTemplates = () => {
    getSectionTemplates().then(res => setSectionTemplates(res))
  }

  const handleDestroy = async () => {
    if (!id) return
    await deleteWebsite(id)
    navigate('/')
  }

  const generateAITemplateData = async () => {
    const prompt = promptRef.current?.value
    if (!prompt) return
    try {
      setGeneratingAiTemplate(true)
      const res = await axios.post('https://template-ai.techboom.ai/generate', {
        req: {
          ...aiTemplateRequestPayload,
          prompt,
        },
      })
      const templateData = generateAITemplate(res.data)
      console.log(templateData)
      setAiTemplateData(templateData)
    } catch (error) {
      console.log(error)
      setGeneratingAiTemplate(false)
      alert('Error from API')
    }
  }

  useEffect(() => {
    if (!id && open && !isWebSetting) {
      setShowName(true)
    }
  }, [id, open])

  useEffect(() => {
    if (!id) return
    getWebsiteByID(id).then(data => {
      const config = JSON.parse(data?.config || '{}')
      setValue('name', data?.name || '')
      setValue('config.title', config?.title || '')
      setValue('config.logo', config?.logo || undefined)
      setValue('cloudfrontAlias', data?.cloudfrontAlias?.replace('.techboom.network', '') || '')
      setValue('gaTrackingID', data?.gaTrackingID || '')
    })
  }, [id, setValue])

  useEffect(() => {
    websiteStore.setWebsite(undefined)
    fetchTemplates()
  }, [])

  useEffect(() => {
    if (!id && (selectedSectionTemplate || aiTemplateData)) {
      handleSubmit(onSubmit)()
    }
  }, [id, selectedSectionTemplate, aiTemplateData])

  return (
    <>
      <ModalDialogV2 open={open} onBack={onClose}>
        <div className="px-[18px] mt-[38px]">
          <div className="flex px-[14px] gap-[22px] pb-[18px] border-b border-black dark:border-white">
            <Settings size={27} className="text-[#8D8D8D] dark:text-white" />
            <h2 className="text-[19px] dark:text-white">Settings</h2>
          </div>
          <SettingsItem title="Site Name" onClick={() => setShowName(true)} />
          <SettingsItem title="Logo" onClick={() => setShowLogo(true)} />
          <SettingsItem title="Domain" onClick={() => setShowDomainModal(true)} />
          <SettingsItem title="All Links" onClick={() => setShowAllLinks(true)} />
          <SettingsItem title="Analytics" onClick={() => setShowAnalytics(true)} />
          <SettingsItem title="Social Media Accounts" onClick={() => null} />
          <SettingsItem title="Delete Site" onClick={() => setShowDelete(true)} />
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="px-6 pb-20">
          <ModalDialogV2
            open={showName}
            onBack={() => {
              if (!id) {
                navigate('/')
              } else {
                setShowName(false)
              }
            }}
          >
            <Loader show={loading} />
            <div className="px-[18px] mt-[38px]">
              <div className="flex px-[14px] gap-[22px] pb-[18px] border-b border-black dark:border-white">
                <Settings size={27} className="text-[#8D8D8D] dark:text-white" />
                <h2 className="text-[19px] dark:text-white">Site Name</h2>
              </div>
              <p className="text-[19px] dark:text-white pt-[9px] pb-[44px]">
                Enter a name you would like for your site. Don&apos;t worrry, you can update your site name in the
                future.
              </p>
              <div className="flex flex-col justify-center">
                <InputText
                  settings
                  label="Your Site Name"
                  type="text"
                  placeholder="Enter your site name"
                  name="name"
                  register={register}
                  required={{ value: true, message: 'Site name is required' }}
                  errors={errors}
                />
                <p className="text-[19px] dark:text-white pt-[42px] pb-[44px]">
                  Site title shows at the top of your browser tab. Add a short name or phrase for your title.
                </p>
                <InputText
                  settings
                  label="Title Tag"
                  type="text"
                  placeholder="Enter your site title"
                  name="config.title"
                  register={register}
                  required={{ value: true, message: 'Site title is required' }}
                  errors={errors}
                />
              </div>
              <div className="w-full flex justify-center pt-[68px] pb-[50px] border-b dark:border-white">
                <button
                  type="submit"
                  onClick={() => {
                    if (!id) {
                      if (!name) {
                        setError('name', { message: 'Site name is required' })
                      }
                      if (!title) {
                        setError('config.title', { message: 'Site title is required' })
                      } else {
                        setShowLogo(true)
                      }
                    } else {
                      handleSubmit(onSubmit)()
                    }
                  }}
                  className="px-[53px] py-[13px] bg-white dark:bg-black dark:text-white border-[4px] border-[#E90570] rounded-[9px] mx-auto text-[19px]"
                >
                  save name
                </button>
              </div>
            </div>
          </ModalDialogV2>

          <ModalDialogV2
            open={showDelete}
            onBack={() => {
              if (!id) {
                navigate('/')
              } else {
                setShowDelete(false)
              }
            }}
          >
            <div className="px-[18px] mt-[38px]">
              <div className="flex px-[14px] gap-[22px] pb-[18px] border-b border-black dark:border-white">
                <Delete size={24} className="text-[#8D8D8D] dark:text-white" />
                <h2 className="text-[19px] dark:text-white">Delete Site </h2>
              </div>
              <p className="text-[19px] dark:text-white pt-[9px] pb-[44px]">
                This will delete your site forever. Are you sure you would like to lose your precious efforts?
              </p>
              <div className="w-full pt-[50px] pb-[100px] flex justify-center border-b dark:border-white">
                <button
                  onClick={handleDestroy}
                  type="submit"
                  className="px-[53px] py-[13px] bg-white dark:bg-black dark:text-white border-[4px] border-[#E90570] rounded-[9px] mx-auto text-[19px]"
                >
                  Destroy Site
                </button>
              </div>
            </div>
          </ModalDialogV2>

          <ModalDialogV2 open={showLogo} onBack={() => setShowLogo(false)}>
            <Loader show={loading} />
            <div className="px-[18px] mt-[38px]">
              <div className="flex px-[14px] gap-[22px] pb-[18px] border-b border-black dark:border-white">
                <Settings size={27} className="text-[#8D8D8D] dark:text-white" />
                <h2 className="text-[19px] dark:text-white">Logo</h2>
              </div>
              <p className="text-[19px] dark:text-white pt-[9px] pb-[65px]">
                We provide you with a default logo to get started. You can edit the logo now, or any time in the future.
              </p>
              {logo || websiteLogo ? (
                <div className="w-full">
                  <p className="text-[19px] dark:text-white">Your Current Logo </p>
                  <div className="flex justify-center">
                    <button
                      type="button"
                      onClick={() => setShowModalUploadLogo(true)}
                      className="relative w-[288px] h-[158px] mt-[17px] flex items-center justify-center mx-auto border"
                    >
                      <img
                        src={logo ?? websiteLogo}
                        alt="Brand logo"
                        className="w-full h-full object-cover object-center"
                      />
                      <div className="absolute top-[9px] right-[12px]">
                        <svg width="29" height="11" viewBox="0 0 29 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M6.69341 9.13815L0.900544 4.69907L6.7255 1.08917L6.69341 9.13815Z"
                            fill="white"
                            stroke="#E90570"
                          />
                          <rect x="8.06738" y="0.59375" width="16.0652" height="9" fill="white" stroke="#E90570" />
                          <rect x="25.2998" y="0.5" width="2.8" height="9" fill="white" stroke="#E90570" />
                          <line x1="9.53613" y1="3.78906" x2="22.6632" y2="3.78906" stroke="#E90570" />
                          <line x1="9.53613" y1="6.30469" x2="22.6632" y2="6.30469" stroke="#E90570" />
                        </svg>
                      </div>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="w-full">
                  <p className="text-[19px] dark:text-white">New Logo ?</p>
                  <div className="flex justify-center">
                    <button
                      type="button"
                      onClick={() => setShowModalUploadLogo(true)}
                      className="relative w-[288px] h-[158px] mt-[17px] flex items-center justify-center mx-auto border border-black dark:border-white"
                    >
                      <h1 className="text-[19px] dark:text-white">{name}</h1>
                      <div className="absolute top-[9px] right-[12px]">
                        <svg width="29" height="11" viewBox="0 0 29 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M6.69341 9.13815L0.900544 4.69907L6.7255 1.08917L6.69341 9.13815Z"
                            fill="white"
                            stroke="#E90570"
                          />
                          <rect x="8.06738" y="0.59375" width="16.0652" height="9" fill="white" stroke="#E90570" />
                          <rect x="25.2998" y="0.5" width="2.8" height="9" fill="white" stroke="#E90570" />
                          <line x1="9.53613" y1="3.78906" x2="22.6632" y2="3.78906" stroke="#E90570" />
                          <line x1="9.53613" y1="6.30469" x2="22.6632" y2="6.30469" stroke="#E90570" />
                        </svg>
                      </div>
                    </button>
                  </div>
                </div>
              )}
              <button
                type="submit"
                onClick={() => {
                  if (!id) {
                    setShowModalChooseTemplate(true)
                  } else {
                    handleSubmit(onSubmit)()
                  }
                }}
                className="w-full flex justify-center pt-[68px] pb-[50px] border-b dark:border-white"
                disabled={loading}
              >
                <p className="px-[53px] py-[13px] bg-white dark:bg-black dark:text-white border-[4px] border-[#E90570] rounded-[9px] mx-auto text-[19px]">
                  save logo
                </p>
              </button>
            </div>
          </ModalDialogV2>
        </form>

        <div className="absolute flex items-center gap-[11px] top-[21px] right-[30px]">
          <h2 className="text-[19px] dark:text-white">Site Color</h2>
          <ColorModeSwitcher hideTitle />
        </div>
      </ModalDialogV2>

      <ModalDialog open={showModalUploadLogo} onClose={() => setShowModalUploadLogo(false)}>
        <ImageUploader
          hideNext
          onSubmit={image => {
            setValue('config.logo', image)
            setLogo(image)
            setShowModalUploadLogo(false)
          }}
          state="logo"
        />
      </ModalDialog>

      <ModalDialogV2
        open={showAllLinks}
        onBack={() => {
          setShowAllLinks(false)
        }}
      >
        <NewMenuAllLinks myAllLinks fetchWebsiteLinks={fetchWebsite} />
      </ModalDialogV2>

      <ModalDialog
        title="Choose template"
        open={showModalChooseTemplate}
        onClose={() => setShowModalChooseTemplate(false)}
      >
        <div className="px-6">
          <div className="hidden mb-6 md:mb-8 w-full relative aspect-w-4 aspect-h-2 dark:bg-white rounded-lg border border-gray-300 dark:border-white">
            <button
              type="button"
              onClick={() => {
                setAiTemplateSelected(true)
                setShowModalChooseTemplate(false)
              }}
            >
              <p className="text-black dark:text-white text-5xl font-medium">AI Template</p>
            </button>
          </div>
          {sectionTemplates?.map(template => (
            <div
              key={template.id}
              className="mb-6 md:mb-8 w-full relative aspect-w-4 aspect-h-2 dark:bg-white rounded-lg border border-gray-300 dark:border-white"
            >
              <button type="button" className="h-full" onClick={() => setSelectedSectionTemplate(template)}>
                {template.image ? (
                  <div className="flex flex-col items-center justify-center">
                    <div
                      style={{
                        backgroundImage: `url(${template.image})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '100%',
                        backgroundOrigin: 'padding-box',
                      }}
                      className="w-full h-full rounded-lg absolute z-0 inset-0 hover:shadow-lg transition-all duration-300 "
                    >
                      <div className="absolute rounded-lg rounded-t-none bg-[#e0dfdf] md:font-roboto transition-all duration-500 text-sm lg:text-xl w-full bottom-0 py-1 md:p-0 md:h-[50px] z-30 flex items-center justify-center">
                        {template.name}
                      </div>
                    </div>
                  </div>
                ) : (
                  <p className="text-white dark:text-black text-3xl md:text-5xl font-medium">{template.name}</p>
                )}
              </button>
            </div>
          ))}
          <div className="mb-6 w-full aspect-w-4 aspect-h-3 rounded-lg border border-black dark:border-white">
            <button type="button" onClick={handleSubmit(onSubmit)}>
              <p className="text-black dark:text-white text-5xl font-medium">
                Start from
                <br />
                Scratch
              </p>
            </button>
          </div>
        </div>
      </ModalDialog>

      <ModalDialog
        title="Write business details"
        open={aiTemplateSelected}
        onClose={() => {
          setAiTemplateSelected(false)
          setShowModalChooseTemplate(true)
        }}
      >
        <div className="px-6">
          {generatingAiTemplate ? (
            <>
              <Animation name="website-building" size={400} />
              <p className="dark:text-white text-lg mt-2 text-center">Generating your website...</p>
            </>
          ) : (
            <>
              <textarea rows={5} className="w-full border p-2 rounded-md" ref={promptRef} />
              <div className="flex justify-center mt-3">
                <button
                  type="button"
                  onClick={generateAITemplateData}
                  className="px-[53px] py-[13px] bg-white dark:bg-black dark:text-white border-[4px] border-[#E90570] rounded-[9px] mx-auto text-[19px]"
                >
                  generate
                </button>
              </div>
            </>
          )}
        </div>
      </ModalDialog>

      <ModalDialogV2 open={showAnalytics} onBack={() => setShowAnalytics(false)}>
        <Loader show={loading} />
        <div className="px-[18px] mt-[38px]">
          <div className="flex px-[14px] gap-[22px] pb-[18px] border-b border-black dark:border-white">
            <Settings size={27} className="text-[#8D8D8D] dark:text-white" />
            <h2 className="text-[19px] dark:text-white">Analytics</h2>
          </div>

          <div className="mt-10">
            <InputText
              settings
              label="GA Tracking ID"
              type="text"
              placeholder="Enter your GA tracking ID"
              name="gaTrackingID"
              register={register}
              required={{ value: false }}
              errors={errors}
            />
            <div className="w-full flex justify-center pt-[68px] pb-[50px] border-b dark:border-white">
              <button
                type="submit"
                onClick={() => {
                  handleSubmit(onSubmit)()
                }}
                className="px-[53px] py-[13px] bg-white dark:bg-black dark:text-white border-[4px] border-[#E90570] rounded-[9px] mx-auto text-[19px]"
              >
                save
              </button>
            </div>
          </div>
        </div>
      </ModalDialogV2>

      <ModalDialogV2 open={showDomainModal} onBack={() => setShowDomainModal(false)}>
        <Loader show={loading} />
        <div className="px-[18px] mt-[38px]">
          <div className="flex px-[14px] gap-[22px] pb-[18px] border-b border-black dark:border-white">
            <Settings size={27} className="text-[#8D8D8D] dark:text-white" />
            <h2 className="text-[19px] dark:text-white">Domain</h2>
          </div>
          <div className="flex mt-10">
            <div className="flex flex-col flex-1">
              <InputText
                settings
                label="Domain name"
                type="text"
                placeholder="Enter your domain name"
                name="cloudfrontAlias"
                register={register}
                required={{ value: true, message: 'Domain name is required' }}
                errors={errors}
              />
            </div>
            <p>.techboom.network</p>
          </div>
          <div className="w-full flex justify-center pt-[68px] pb-[50px] border-b dark:border-white">
            <button
              type="submit"
              onClick={() => {
                handleSubmit(onSubmit)()
              }}
              className="px-[53px] py-[13px] bg-white dark:bg-black dark:text-white border-[4px] border-[#E90570] rounded-[9px] mx-auto text-[19px]"
            >
              save domain
            </button>
          </div>
        </div>
      </ModalDialogV2>
    </>
  )
}

function SettingsItem({ title, onClick }: { title: string; onClick: () => void }) {
  return (
    <div className="flex justify-between items-center py-[22px] px-[14px] border-b">
      <p className="text-[19px] text-left dark:text-white">{title}</p>
      <button type="button" onClick={onClick}>
        <EditPlus size={50} fill="none" className="stroke-[#8D8D8D] fill-white dark:fill-black dark:stroke-white" />
      </button>
    </div>
  )
}
