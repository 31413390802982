import SectionSlatePreview from '../../../common/SectionSlatePreview'
import SectionSlate from '../../../common/SectionSlatePreview'
import { GallerySection, GallerySectionItem } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
const Fade = require('react-reveal/Fade')

const VariantSeven = ({ section }: { section: GallerySection }) => {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const gallery = section.variables.find(variable => variable.name === 'MEDIA')?.data as GallerySectionItem[]
  return (
    <div
      className="text-[40px] mb-[30px] mt-[20px]"
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="container mx-auto px-8 text-center">
        <h3 className="col px-3 lg:mr-[80px] uppercase px-sm-2 px-md-0 text-[30px] lg:text-[2.5em] text-right">
          <SectionSlate initialValue={title} previewMode />
        </h3>
        <div className=" container w-[1300px]  max-w-full grid-cols-6 space-y-2 lg:space-y-0 md:grid-col-3 lg:grid lg:gap-3 lg:grid-rows-3 mx-auto">
          {gallery.map((data, idx) => {
            if (idx % 9 === 0) {
              return (
                <Fade top>
                  <div className={`w-full col-span-3 bg-violet-800 bg-center bg-cover bg-white relative tmp-inner`}>
                    <Fade top delay={500}>
                      <img
                        className="tmp-highlight-inner object-cover object-center bg-black w-full h-full"
                        src={data.image}
                        alt=""
                      />
                    </Fade>
                    <div className="hover-box flex justify-center items-center mx-auto py-[20px] px-6">
                      <span>
                        <h1 className="text-[20px] text-center py-1 lg:py-6 mx-auto text-white">
                          <SectionSlatePreview initialValue={data.title} previewMode />{' '}
                        </h1>
                        <p className="text-[16px] font-semibold text-white text-center mx-auto">
                          <SectionSlatePreview initialValue={data.description} previewMode />
                        </p>
                      </span>
                    </div>
                  </div>
                </Fade>
              )
            } else if (idx % 9 === 1) {
              return (
                <Fade top>
                  <div className={`w-full col-span-3 bg-violet-800 bg-center bg-cover bg-white relative tmp-inner`}>
                    <Fade top delay={500}>
                      <img
                        className="tmp-highlight-inner object-cover object-center bg-black w-full h-full"
                        src={data.image}
                        alt=""
                      />
                    </Fade>
                    <div className="hover-box flex justify-center items-center mx-auto py-[20px] px-6">
                      <span>
                        <h1 className="text-[20px] text-center py-1 lg:py-6 mx-auto text-white">
                          <SectionSlatePreview initialValue={data.title} previewMode />
                        </h1>
                        <p className="text-[16px] font-semibold text-white text-center mx-auto">
                          <SectionSlatePreview previewMode initialValue={data.description} />
                        </p>
                      </span>
                    </div>
                  </div>
                </Fade>
              )
            } else if (idx % 9 === 2) {
              return (
                <Fade top>
                  <div className={`w-full col-span-2 bg-violet-800 bg-center bg-cover bg-white relative tmp-inner`}>
                    <Fade top delay={500}>
                      <img
                        className="tmp-highlight-inner object-cover object-center bg-black w-full h-full"
                        src={data.image}
                        alt=""
                      />
                    </Fade>
                    <div className="hover-box flex justify-center items-center mx-auto py-[20px] px-6">
                      <span>
                        <h1 className="text-[20px] text-center py-1 lg:py-6 mx-auto text-white">
                          <SectionSlatePreview initialValue={data.title} previewMode />
                        </h1>
                        <p className="text-[16px] font-semibold text-white text-center mx-auto">
                          <SectionSlatePreview initialValue={data.description} previewMode />
                        </p>
                      </span>
                    </div>
                  </div>
                </Fade>
              )
            } else if (idx % 9 === 3) {
              return (
                <Fade top>
                  <div className={`w-full col-span-4 bg-violet-800 bg-center bg-cover bg-white relative tmp-inner`}>
                    <Fade top delay={500}>
                      <img
                        className="tmp-highlight-inner object-cover object-center bg-black w-full h-full"
                        src={data.image}
                        alt=""
                      />
                    </Fade>
                    <div className="hover-box flex justify-center items-center mx-auto py-[20px] px-6">
                      <span>
                        <h1 className="text-[20px] text-center py-1 lg:py-6 mx-auto text-white">
                          {' '}
                          <SectionSlatePreview initialValue={data.title} previewMode />
                        </h1>
                        <p className="text-[16px] font-semibold text-white text-center mx-auto">
                          <SectionSlatePreview initialValue={data.description} previewMode />
                        </p>
                      </span>
                    </div>
                  </div>
                </Fade>
              )
            } else if (idx % 9 === 4) {
              return (
                <Fade top>
                  <div className={`w-full col-span-4 bg-violet-800 bg-center bg-cover bg-white relative tmp-inner`}>
                    <Fade top delay={500}>
                      <img
                        className="tmp-highlight-inner object-cover object-center bg-black w-full h-full"
                        src={data.image}
                        alt=""
                      />
                    </Fade>
                    <div className="hover-box flex justify-center items-center mx-auto py-[20px] px-6">
                      <span>
                        <h1 className="text-[20px] text-center py-1 lg:py-6 mx-auto text-white">
                          {' '}
                          <SectionSlatePreview initialValue={data.title} previewMode />
                        </h1>
                        <p className="text-[16px] font-semibold text-white text-center mx-auto">
                          <SectionSlatePreview initialValue={data.description} previewMode />
                        </p>
                      </span>
                    </div>
                  </div>
                </Fade>
              )
            } else if (idx % 9 === 5) {
              return (
                <Fade top>
                  <div className={`w-full col-span-2 bg-violet-800 bg-center bg-cover bg-white relative tmp-inner`}>
                    <Fade top delay={500}>
                      <img
                        className="tmp-highlight-inner object-cover object-center bg-black w-full h-full"
                        src={data.image}
                        alt=""
                      />
                    </Fade>
                    <div className="hover-box flex justify-center items-center mx-auto py-[20px] px-6">
                      <span>
                        <h1 className="text-[20px] text-center py-1 lg:py-6 mx-auto text-white">
                          {' '}
                          <SectionSlatePreview initialValue={data.title} previewMode />
                        </h1>
                        <p className="text-[16px] font-semibold text-white text-center mx-auto">
                          <SectionSlatePreview initialValue={data.description} previewMode />
                        </p>
                      </span>
                    </div>
                  </div>
                </Fade>
              )
            } else {
              return (
                <Fade top>
                  <div className={`w-full col-span-3 bg-violet-800 bg-center bg-cover bg-white relative tmp-inner`}>
                    <Fade top delay={500}>
                      <img
                        className="tmp-highlight-inner object-cover object-center bg-black w-full h-full"
                        src={data.image}
                        alt=""
                      />
                    </Fade>
                    <div className="hover-box flex justify-center items-center mx-auto py-[20px] px-6">
                      <span>
                        <h1 className="text-[20px] text-center py-1 lg:py-6 mx-auto text-white">
                          {' '}
                          <SectionSlatePreview initialValue={data.title} previewMode />
                        </h1>
                        <p className="text-[16px] font-semibold text-white text-center mx-auto">
                          <SectionSlatePreview initialValue={data.description} previewMode />
                        </p>
                      </span>
                    </div>
                  </div>
                </Fade>
              )
            }
          })}
        </div>
      </div>
    </div>
  )
}

export default VariantSeven
