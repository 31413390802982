import clsx from 'clsx'
import React, { ChangeEvent, useCallback, useRef, useState } from 'react'
import Cropper from 'react-easy-crop'
import { getCroppedImg } from 'src/util'
import { ReactComponent as CropAdd } from '../../assets/icons/editor/crop-add.svg'
import { ReactComponent as CropOriginal } from '../../assets/icons/editor/crop-original.svg'
import { ReactComponent as CropSelect } from '../../assets/icons/editor/crop-select.svg'
import { ReactComponent as ZoomIn } from '../../assets/icons/editor/zoom-in.svg'
import { Loader } from '../loader'
import { ModalProps } from './ModalDialog'
import ModalDialogV2 from './ModalDialogV2'

interface IProps extends ModalProps {
  image: any
  onSubmit?: (image: any) => void
  hideNext?: boolean
  saving?: boolean
  modalMode?: boolean
}

const CropImage: React.FC<IProps> = ({ image, onSubmit, hideNext, saving, modalMode, ...rest }) => {
  const [showOptionAspect, setShowOptionAspect] = useState(false)
  const [showZoom, setShowZoom] = useState(false)
  const [zoom, setZoom] = useState(1)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [aspect, setAspect] = useState({ h: 1, w: 1 })
  const area = useRef({})

  const onCropComplete = useCallback((_: any, croppedAreaPixels: any) => {
    area.current = croppedAreaPixels
  }, [])

  const handleSubmit = useCallback(async () => {
    const url = await getCroppedImg(image, area.current)
    onSubmit?.(url)
  }, [])

  const openOptionAspect = () => {
    setShowOptionAspect(preState => !preState)
    setShowZoom(false)
  }

  const openZoom = () => {
    setShowZoom(preState => !preState)
    setShowOptionAspect(false)
  }

  const onChangeZoom = (e: ChangeEvent<HTMLInputElement>) => {
    setZoom(+e.target.value)
  }

  const Parent: any = modalMode ? 'div' : ModalDialogV2

  return (
    <Parent {...rest} hideNext={hideNext}>
      <div className="px-[18px]">
        <h1 className="text-[19px] dark:text-white py-6">Crop</h1>
        <div className="relative aspect-w-1 aspect-h-1 border">
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={aspect.w / aspect.h}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            classes={{ containerClassName: 'z-50' }}
          />
          {showOptionAspect && (
            <div
              className="absolute bottom-[60px] top-auto w-auto h-auto left-[10px] z-50 border-[1px] border-white bg-black rounded-md"
              style={{ width: 140 }}
              onBlur={() => setShowOptionAspect(false)}
            >
              <div className="flex gap-8 items-center justify-between p-[8px] pl-[20px] pr-[20px] text-sm">
                <span className="peer-checked:p-2 text-white font-medium peer-checked:bg-white">Original</span>
                <CropOriginal />
              </div>
              <div className="bg-white h-[1px]" />
              <button
                type="button"
                className="cursor-pointer w-full border-white text-sm"
                onClick={() => setAspect({ w: 1, h: 1 })}
              >
                <input type="radio" className="hidden peer" />
                <div
                  className={clsx(
                    aspect.w === 1 && 'bg-[#D0867E]',
                    'flex gap-8 items-center justify-between p-[8px] pl-[20px] pr-[20px]',
                  )}
                >
                  <span className="peer-checked:p-2 text-white font-medium peer-checked:bg-white">1:1</span>
                  <span className="border-[1px] border-white w-4 h-4 rounded-sm" />
                </div>
              </button>
              <div className="bg-white h-[1px]" />
              <button
                type="button"
                className="cursor-pointer w-full border-white text-sm"
                onClick={() => setAspect({ w: 4, h: 5 })}
              >
                <input type="radio" className="hidden peer" />
                <div
                  className={clsx(
                    aspect.w === 4 && 'bg-[#D0867E]',
                    'flex gap-8 items-center justify-between p-[8px] pl-[20px] pr-[23px]',
                  )}
                >
                  <span className="peer-checked:p-2 text-white font-medium peer-checked:bg-white">4:5</span>
                  <span className="border-[1px] border-white w-3 h-4 rounded-sm" />
                </div>
              </button>
              <div className="bg-white h-[1px]" />
              <button
                type="button"
                className="cursor-pointer w-full border-white text-sm"
                onClick={() => setAspect({ w: 16, h: 9 })}
              >
                <input type="radio" className="hidden peer" />
                <div
                  className={clsx(
                    aspect.w === 16 && 'bg-[#D0867E]',
                    'flex gap-8 items-center justify-between p-[8px] pl-[20px] pr-[18px]',
                  )}
                >
                  <span className="peer-checked:p-2 text-white font-medium peer-checked:bg-white">16:9</span>
                  <span className="border-[1px] border-white w-5 h-3 rounded-sm" />
                </div>
              </button>
            </div>
          )}
          {showZoom && (
            <div className="mx-3.5 z-50 bottom-[60px] top-auto w-auto h-auto" style={{ width: 130 }}>
              <label className="range-input bg-white">
                <input type="range" max="3" min="1" step="0.1" value={zoom} onChange={onChangeZoom} />
              </label>
            </div>
          )}
          <div className="absolute p-[10px] flex items-end justify-between">
            <div className="flex z-50">
              <CropSelect className="cursor-pointer mr-2" onClick={openOptionAspect} />
              <ZoomIn className="cursor-pointer" onClick={openZoom} />
            </div>
            <CropAdd className="cursor-pointer z-50" />
          </div>
        </div>
        {hideNext && (
          <>
            <Loader show={saving || false} />
            <button
              type="submit"
              onClick={handleSubmit}
              className="w-full flex justify-center pt-[68px]"
              disabled={saving}
            >
              <p className="px-[53px] py-[13px] bg-white dark:bg-black dark:text-white border-[4px] border-[#E90570] rounded-[9px] mx-auto text-[19px]">
                save logo
              </p>
            </button>
          </>
        )}
      </div>
      {!hideNext && (
        <div className="flex items-center justify-center mt-12">
          <button
            type="button"
            onClick={handleSubmit}
            className="w-[160px] h-[40px] bg-[#E90570] text-white rounded text-center disabled:opacity-50"
            disabled={saving}
          >
            {saving ? 'Saving...' : 'Save Upload'}
          </button>
        </div>
      )}
    </Parent>
  )
}

export default CropImage
