import { motion } from 'framer-motion'
import SectionSlate from '../../../common/SectionSlatePreview'
import { TextSection as TextSectionType } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

function VariantNine({ section }: { section: TextSectionType }) {
  const subtitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            duration: 1,
          },
        }}
        className="py-[50px] px-6 md:px-8"
        style={{
          ...generateSectionBackground({
            defaultColor: 'white',
            backgroundSettings: section?.settings?.color?.background,
            image: null,
          }),
          ...generateSectionSpacing(section?.settings?.spacing),
        }}
      >
        <motion.p
          initial={{ opacity: 0, x: -30 }}
          whileInView={{ x: 0, opacity: 1, transition: { duration: 0.7 } }}
          className="md:text-[21px] text-[16px] w-[90%]"
        >
          <SectionSlate initialValue={paragraph} previewMode />
        </motion.p>
        <p className="mt-3 capitalize text-[19px] font-semibold">
          <i>
            <SectionSlate initialValue={subtitle} previewMode />
          </i>
        </p>
      </motion.div>
    </>
  )
}

export default VariantNine
