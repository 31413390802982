import Icon from './icons'
import { IconPropsT } from './types'

function TechboomLogo(props: IconPropsT) {
  const { title = 'Logo', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 64 64" size={size} title={title} {...otherProps}>
      <g clipPath="url(#clip0_102_3)">
        <path d="M32 62C48.5685 62 62 48.5685 62 32C62 15.4315 48.5685 2 32 2C15.4315 2 2 15.4315 2 32C2 48.5685 15.4315 62 32 62Z" />
        <path d="M32.0006 52.1447C43.1262 52.1447 52.1453 43.1255 52.1453 31.9999C52.1453 20.8742 43.1262 11.8551 32.0006 11.8551C20.8749 11.8551 11.8558 20.8742 11.8558 31.9999C11.8558 43.1255 20.8749 52.1447 32.0006 52.1447Z" />
        <path d="M31.9994 43.0021C38.0756 43.0021 43.0013 38.0764 43.0013 32.0002C43.0013 25.924 38.0756 20.9983 31.9994 20.9983C25.9232 20.9983 20.9975 25.924 20.9975 32.0002C20.9975 38.0764 25.9232 43.0021 31.9994 43.0021Z" />
      </g>
    </Icon>
  )
}

export default TechboomLogo
