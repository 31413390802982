import Lottie from 'react-lottie'
import * as animationData from './loader.json'

export default function Loader({ show }: { show: boolean }) {
  if (!show) return null
  return (
    <div className="z-[9999] fixed top-0 right-0 bottom-0 left-0 justify-center items-center">
      <div className="relative w-full h-full bg-white/90 flex justify-center items-center">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData,
          }}
        />
      </div>
    </div>
  )
}
