import Icon from './icons'
import { IconPropsT } from './types'

function Alarm(props: IconPropsT) {
  const { title = 'Notification', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 27 33" size={size} title={title} {...otherProps}>
      <path
        d="M20.8813 26.7582V26.7581H20.8719H5.96691V26.7581L5.95798 26.7582C2.60305 26.7981 1.39036 25.7224 0.975537 24.7887C0.751121 24.2836 0.709274 23.7226 0.783897 23.1955C0.859446 22.6619 1.04698 22.2163 1.21771 21.9648L1.23281 21.9426L1.24627 21.9193C2.31585 20.0717 2.81027 17.9474 2.66683 15.8179C2.73854 13.2845 3.56947 10.8307 5.05234 8.77468C6.54203 6.70922 8.62033 5.14137 11.0155 4.27614L11.5106 4.09726V3.57076C11.5106 1.9433 12.005 1.30757 12.3784 1.04027C12.7932 0.743386 13.2834 0.73562 13.5254 0.757399L13.594 0.763576L13.6626 0.757144C13.8872 0.736088 14.3364 0.743482 14.7178 1.03004C15.0666 1.29209 15.5432 1.92997 15.5432 3.57076V4.08368L16.0211 4.2697C18.3547 5.17782 20.3615 6.76607 21.7815 8.82849C23.1964 10.8834 23.9636 13.3146 23.9847 15.809C23.8441 17.9268 24.3103 20.041 25.329 21.9035L25.3423 21.9277L25.3572 21.9508C25.7106 22.4974 26.0257 23.7309 25.5975 24.7733C25.2154 25.7033 24.111 26.7591 21.0435 26.7602L20.8813 26.7582Z"
        fill="#DDDDDD"
        stroke="#EFEFEF"
        strokeWidth="1.5"
      />
      <path
        d="M13.1125 32.1383H12.6574C11.8465 32.1005 11.0605 31.836 10.3898 31.3724C9.95904 31.0746 9.58831 30.7033 9.2929 30.2783H16.7437C16.4702 30.6898 16.124 31.0518 15.7192 31.3452C15.0153 31.8555 14.1695 32.1328 13.3002 32.1383H13.1125Z"
        fill="#F8F8F8"
        stroke="#EFEFEF"
        strokeWidth="1.5"
      />
    </Icon>
  )
}

export default Alarm
