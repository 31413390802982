import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { LikeStatus } from 'src/API'
import Chat from 'src/icons/Chat'
import Heart from 'src/icons/Heart'
import {
  allRepliesByCommentID,
  getCommentLikes,
  getUserCommentLike,
  likeComment,
  unlikeComment,
} from 'src/services/comment.service'
import { renderClickableLinks } from 'src/util'
import CommentReply from './CommentReply'

type CommentProps = {
  comment: any
}

export default function Comment({ comment }: CommentProps) {
  const [likesCount, setLikesCount] = useState(0)
  const [commentsCount, setCommentsCount] = useState(0)
  const [liked, setLiked] = useState(false)
  const [hideReplyInput, setHideReplyInput] = useState<boolean>(true)

  const handleLikeClick = async (commentID: string) => {
    const { like } = (liked ? await unlikeComment(commentID) : await likeComment(commentID)) || {}
    const newLiked = like === LikeStatus.LIKE
    setLikesCount(likesCount + (newLiked ? +1 : -1))
    setLiked(newLiked)
  }

  const getCommentsCount = async () => {
    const res = await allRepliesByCommentID(comment.id)
    setCommentsCount(res?.items.length || 0)
  }

  useEffect(() => {
    getCommentLikes(comment.id, { filter: { like: { eq: LikeStatus.LIKE } } }).then(res =>
      setLikesCount(res?.items?.length || 0),
    )

    getCommentsCount()

    getUserCommentLike(comment.id).then(res => setLiked(res?.like === LikeStatus.LIKE))
  }, [comment])

  return (
    <div>
      <div key={comment.id}>
        <div className="px-4 flex items-end gap-x-5">
          <div>
            <div className="w-[20px] rounded-full bg-[#D9D9D9] md:w-[30px] h-[20px] md:h-[30px]">
              {comment?.user?.profilePicture && (
                <Link to={`/profile/${comment?.user?.id}`}>
                  <img className="w-full h-full rounded-full" src={comment?.user?.profilePicture} alt="" />
                </Link>
              )}
            </div>
          </div>
          <div className="w-full dark:bg-[#D9D9D9] text-black border border-[#181818] rounded-r-lg rounded-t-lg px-4 py-2">
            <Link to={`/profile/${comment?.user?.id}`}>
              <h2 className="text-[15px]">
                {comment?.user?.firstName} {comment?.user?.lastName}
              </h2>
            </Link>
            <p className="text-[11px] font-bold">{renderClickableLinks(comment.content)}</p>
          </div>
        </div>
        <div className="flex items-center py-2 ml-12 px-8 gap-x-3">
          <div className="relative flex items-center">
            <button type="button" onClick={() => handleLikeClick(comment.id)}>
              {liked ? (
                <Heart size={17} className="text-brand-accent dark:text-brand-accent" />
              ) : (
                <Heart size={17} className="text-black dark:text-white" />
              )}
              {likesCount > 0 && (
                <div
                  style={{ top: '-5px', left: '12px' }}
                  className="w-[14px] absolute h-[14px] rounded-full flex justify-center items-center bg-brand-accent text-white text-[11px]"
                >
                  {likesCount}
                </div>
              )}
            </button>
          </div>
          <span>|</span>
          <div className="relative flex items-center">
            <button type="button" onClick={() => setHideReplyInput(!hideReplyInput)}>
              {commentsCount > 0 ? (
                <Chat size={17} className="text-brand-accent dark:text-brand-accent" />
              ) : (
                <Chat size={17} />
              )}
              {commentsCount > 0 && (
                <div
                  style={{ top: '-5px', left: '12px' }}
                  className="w-[14px] absolute h-[14px] rounded-full flex justify-center items-center bg-brand-accent text-white text-[11px]"
                >
                  {commentsCount}
                </div>
              )}
            </button>
          </div>
        </div>
      </div>

      <CommentReply hideReplyInput={hideReplyInput} commentID={comment.id} onReply={getCommentsCount} />
    </div>
  )
}
