import { motion } from 'framer-motion'
import { FcNext, FcPrevious } from 'react-icons/fc'
import { EffectCoverflow, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import SectionSlate from '../../../common/SectionSlatePreview'
import { BlogItemsGroup, BlogSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantTwo({ section }: { section: BlogSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const items = section.variables.find(variable => variable.name === 'ITEMS')?.data as BlogItemsGroup[]

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: 'black',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
      className="lg:py-12 py-5"
    >
      <div className="px-4 md:px-12 md:min-h-[700px] md:max-h-[800px] overflow-x-hidden grid grid-cols-1 lg:grid-cols-2  text-[#eee] items-start">
        <motion.div className="lg:gap-y-12 col-span-1 md:w-[95%]  mt-5">
          <div className="overflow-hidden w-[95%] leading-8 tracking-wide">
            <motion.p
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1, transition: { duration: 1 } }}
              className="text-[32px] md:text-4xl font-bold  overflow-hidden"
            >
              <SectionSlate initialValue={title} previewMode />
            </motion.p>
            <div className="max-h-[520px] whitespace-nowrap overflow-hidden w-[100%] lg:mt-8 mt-6 lg:w-[98%] flex flex-col tracking-wide text-sm lg:text-2xl gap-y-6  lg:gap-y-12 ">
              <motion.p
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1, transition: { duration: 1, delay: 0.5 } }}
                className=" lg:tracking-wider lg:text-2xl tracking-wide  leading-6 lg:leading-10"
              >
                <SectionSlate initialValue={paragraph} previewMode />
              </motion.p>
            </div>
          </div>
        </motion.div>
        <div className="mt-12 lg:mt-0 md:w-[800px] relative items-center w-[500px]">
          <Swiper
            effect="coverflow"
            modules={[EffectCoverflow, Navigation]}
            loop
            slidesPerView={1}
            slidesPerGroup={1}
            shortSwipes={false}
            allowTouchMove={false}
            noSwiping
            navigation={{
              nextEl: '.custom-swiper-button-next',
              prevEl: '.custom-swiper-button-prev',
              hideOnClick: true,
            }}
            className="my-swiper"
          >
            {items.map(item => {
              return (
                <SwiperSlide key={item.id} className="flex  pr-24">
                  <div className="flex items-center justify-center ml-auto w-[120px]">
                    <p className="text-[10px] font-bold min-h-[50px]  items-center md:text-lg  -mt-32 -ml-4 md:ml-0 md:mt-[-400px] w-full md:h-[30px] text-ellipsis overflow-hidden whitespace-nowrap rotate-[90deg] lg:rotate-[270deg]">
                      <p>
                        <SectionSlatePreview initialValue={item.name} previewMode />
                      </p>
                    </p>
                  </div>
                  <div>
                    <img
                      src={item.image}
                      alt=""
                      className="md:w-[600px] w-[400px] duration-500 object-cover object-center md:h-[700px] h-[300px] "
                    />
                  </div>
                </SwiperSlide>
              )
            })}
            <div className="bottom-0 w-full gap-x-3 md:gap-x-0 md:justify-between flex  absolute z-50">
              <button className="border border-white rounded-full w-[25px] md:w-[60px] md:h-[60px] h-[25px] flex items-center justify-center  md:p-2 custom-swiper-button-prev">
                <FcPrevious size={12} strokeWidth={4} stroke="white" className="block md:hidden" />
                <FcPrevious size={30} strokeWidth={4} stroke="white" className="hidden md:block" />
              </button>
              <button className="border border-white rounded-full w-[25px] h-[25px] md:w-[60px] md:h-[60px] flex items-center justify-center  md:p-2 custom-swiper-button-next">
                <FcNext size={30} strokeWidth={4} stroke="white" className="hidden md:block" />
                <FcNext size={12} strokeWidth={4} stroke="white" className="block md:hidden" />
              </button>
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  )
}
