import useBatchFetch from 'src/hooks/useBatchFetch'
import { getFeedPosts } from 'src/services/feed.service'
import SocialSiteCard from './components/SocialSiteCard'

export default function Feed() {
  const { items: posts } = useBatchFetch(getFeedPosts as any)

  return (
    <div className="max-w-[720px] mx-auto px-4 lg:px-0 bg-white ">
      <div className="my-12 hidden md:block">
        <svg width="123" height="48" viewBox="0 0 123 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="76.5" y="0.5" width="46" height="47" rx="5.5" fill="white" stroke="black" />
          <path
            d="M104.642 27.9572C106.092 26.3277 106.85 24.1973 106.755 22.0181C106.66 19.8389 105.719 17.7826 104.133 16.2857C102.547 14.7888 100.44 13.9692 98.2596 14.0009C96.0791 14.0326 93.9969 14.9131 92.4549 16.4555C90.9129 17.9979 90.0326 20.0807 90.0009 22.2617C89.9692 24.4428 90.7886 26.5503 92.2851 28.1369C93.7816 29.7235 95.8374 30.6642 98.016 30.7593C100.195 30.8544 102.325 30.0964 103.954 28.6463L108.364 33.0582C108.41 33.1038 108.464 33.1399 108.523 33.1643C108.583 33.1886 108.647 33.2008 108.711 33.2C108.84 33.1993 108.965 33.1485 109.057 33.0582C109.149 32.9668 109.2 32.8428 109.2 32.7136C109.2 32.5844 109.149 32.4605 109.057 32.3691L104.642 27.9572ZM90.9975 22.3961C90.9975 20.9337 91.4311 19.5041 92.2433 18.2882C93.0556 17.0722 94.2101 16.1245 95.5609 15.5648C96.9116 15.0052 98.3979 14.8588 99.8319 15.1441C101.266 15.4294 102.583 16.1336 103.617 17.1677C104.651 18.2018 105.355 19.5193 105.64 20.9536C105.925 22.3879 105.779 23.8746 105.219 25.2257C104.66 26.5768 103.712 27.7316 102.497 28.5441C101.281 29.3566 99.8517 29.7902 98.3897 29.7902C96.43 29.7877 94.5512 29.0079 93.1654 27.6218C91.7797 26.2356 91 24.3564 90.9975 22.3961Z"
            fill="#060606"
            stroke="black"
            strokeWidth="0.5"
          />
          <line x1="2" y1="39" x2="41" y2="39" stroke="black" strokeWidth="4" stroke-Òinecap="round" />
          <line x1="8" y1="27" x2="35" y2="27" stroke="black" strokeWidth="4" stroke-Òinecap="round" />
          <line x1="13" y1="14" x2="31" y2="14" stroke="black" strokeWidth="4" stroke-Òinecap="round" />
        </svg>
      </div>
      <div className="space-y-16 pb-10 py-10 md:py-0">
        {posts.map(post => (
          <SocialSiteCard key={post.id} post={post} />
        ))}
      </div>
    </div>
  )
}
