import SectionSlatePreview from '../../../common/SectionSlatePreview'
import SectionSlate from '../../../common/SectionSlatePreview'
import { TextSection, TextSectionMediaGroup } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

function VariantSix({ section }: { section: TextSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const data = section.variables.find(variable => variable.name === 'MEDIAGROUP')?.data as TextSectionMediaGroup[]
  return (
    <div
      className="py-2 lg:py-6"
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="py-4 md:py-12">
        <div className="text-center px-4 xl:text-[40px] text-2xl ">
          <SectionSlate initialValue={title} previewMode />
        </div>
        <div className="grid md:mt-3 md:grid-cols-2 lg:grid-cols-3 gap-2 lg:gap-x-6 md:px-12 m-auto mx-4">
          {data.map(item => {
            return (
              <div
                key={item.id}
                className="shadow-lg transition-all duration-500 hover:shadow-2xl flex flex-col p-4 py-5"
              >
                <div>
                  <img
                    src={item.image}
                    className="h-[250px] lg:h-[500px] transition-all duration-300 object-cover object-center m-auto w-full"
                  />
                </div>
                <div className="flex flex-col">
                  <p className="lg:text-3xl tracking-wide font-semibold lg:mt-4  mt-2 capitalize">
                    <SectionSlatePreview initialValue={item.name} previewMode />
                  </p>
                  <p className="lg:text-2xl lg:py-4 ">
                    <SectionSlatePreview initialValue={item.description} previewMode />
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default VariantSix
