import Icon from './icons'
import { IconPropsT } from './types'

function NotificationBox(props: IconPropsT) {
  const { title = 'NotificationBox', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 120 120" size={size} title={title} {...otherProps}>
      <path d="M111 0H9C4.02944 0 0 4.02944 0 9V111C0 115.971 4.02944 120 9 120H111C115.971 120 120 115.971 120 111V9C120 4.02944 115.971 0 111 0Z" />
      <path
        d="M12 2.25H109C113.832 2.25 117.75 6.16751 117.75 11V108C117.75 112.832 113.832 116.75 109 116.75H12C7.16751 116.75 3.25 112.832 3.25 108V11C3.25 6.16751 7.16751 2.25 12 2.25Z"
        stroke="black"
        strokeWidth="0.5"
      />
      <path
        d="M76.4482 81.1774H48.7716C35.7588 81.3324 36.9988 72.7566 38.8008 70.1021C40.6589 66.8923 41.512 63.1992 41.2499 59.4996C41.3778 54.4922 43.0138 49.64 45.9439 45.5774C48.874 41.5147 52.9619 38.4309 57.6729 36.729C57.6729 30.0986 61.769 30.0133 62.9316 30.118C64.0476 30.0133 67.946 30.0986 67.946 36.729C72.5391 38.5165 76.4893 41.6427 79.2843 45.7022C82.0793 49.7617 83.5902 54.5672 83.6212 59.4958C83.3669 63.1808 84.1738 66.8615 85.9463 70.1021C87.6475 72.7334 88.8139 81.1813 76.7582 81.1813L76.4482 81.1774Z"
        fill="black"
      />
      <path
        d="M62.0406 91.1753H61.7112C59.7421 91.2043 57.813 90.618 56.193 89.4982C54.573 88.3784 53.3429 86.7809 52.6743 84.9285H71.0001C70.3964 86.7368 69.2419 88.3109 67.6983 89.4298C66.1548 90.5487 64.2997 91.1564 62.3933 91.1675H62.0406V91.1753Z"
        fill="black"
      />
      <path
        d="M75.4576 81.4275V81.4274H75.4482L47.7716 81.4274L47.7627 81.4275C41.4053 81.5032 38.8345 79.456 37.916 77.3888C37.4398 76.317 37.3598 75.1487 37.5112 74.0795C37.6635 73.0037 38.0427 72.0811 38.4213 71.5234L38.4364 71.5011L38.4499 71.4779C40.377 68.1489 41.2651 64.3202 41.0006 60.4833C41.1314 55.6394 42.7173 50.9467 45.5522 47.0161C48.394 43.0759 52.3587 40.085 56.9277 38.4344L57.4229 38.2555V37.729C57.4229 34.5718 58.3898 33.1613 59.3028 32.5077C60.2572 31.8246 61.3541 31.819 61.8643 31.865L61.933 31.8711L62.0016 31.8647C62.4837 31.8195 63.5113 31.8247 64.4068 32.4975C65.2697 33.1458 66.196 34.5584 66.196 37.729V38.242L66.674 38.428C71.1271 40.161 74.9568 43.1918 77.6665 47.1275C80.3711 51.0556 81.8355 55.7042 81.871 60.4727C81.613 64.2911 82.4516 68.104 84.2883 71.462L84.3015 71.4862L84.3165 71.5093C85.0624 72.663 85.7083 75.1747 84.8173 77.3434C83.9726 79.3997 81.6121 81.4302 75.763 81.4313L75.4576 81.4275Z"
        fill="white"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M60.2906 91.4175V91.4218C58.6208 91.3678 56.9985 90.8345 55.6195 89.8812C54.4297 89.0588 53.4683 87.9565 52.8156 86.6785H68.8876C68.2873 87.923 67.3858 89.0051 66.2581 89.8225C64.842 90.8491 63.1401 91.4068 61.391 91.4175H61.0406H60.2906Z"
        fill="#F8F8F8"
        stroke="black"
        strokeWidth="1.5"
      />
    </Icon>
  )
}

export default NotificationBox
