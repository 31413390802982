import { Auth } from 'aws-amplify'
import { useEffect, useState } from 'react'
import GoogleFontLoader from 'react-google-font-loader'
import { Link, useNavigate } from 'react-router-dom'
import { Loader } from 'src/components/loader'
import ModalDialogV2 from 'src/components/new/ModalDialogV2'
import HelpBox from 'src/icons/HelpBox'
import LogOutBox from 'src/icons/LogoutBox'
import NotificationBox from 'src/icons/NotificationBox'
import ProfileBox from 'src/icons/ProfileBox'
import Settings from 'src/icons/Settings'
import Site from 'src/icons/Site'
import { getWebsitesByUserID } from 'src/lib/services/website.service'
import { TECHBOOM_SUPPORT_ID } from 'src/util/constants'
import ColorModeSwitcher from '../../components/ColorModeSwitcher'

const SiteItem = () => {
  const [websites, setWebsites] = useState<any[]>([])
  const fetchWebsites = () => {
    getWebsitesByUserID().then(res => {
      setWebsites(res?.items || [])
    })
  }
  useEffect(() => {
    fetchWebsites()
  }, [])
  return (
    <>
      <GoogleFontLoader
        fonts={[
          {
            font: 'Monoton',
            weights: [400],
          },
        ]}
        subsets={['cyrillic-ext', 'greek']}
      />
      <div className="flex flex-col md:items-start space-y-4">
        <div className="flex items-center space-x-[18px] md:space-x-1 border-b pb-3 md:border-0 md:pb-0">
          <button type="button">
            <Site className="hidden md:block" size={20} />
            <Settings size={22} className="md:hidden text-brand-accent dark:text-brand-accent" />
          </button>
          <h1 className="text-[20px]">Sites </h1>
        </div>
        <div className="grid grid-cols-3 md:grid-cols-6 gap-x-4 gap-y-6 ">
          {websites.map((website, idx) => (
            <Link
              onClick={() =>
                localStorage.setItem(
                  `tutorial_editpage_${website?.id}`,
                  JSON.stringify(JSON.parse(localStorage.getItem(`tutorial_editpage_${website?.id}`) || '0') + 1),
                )
              }
              key={idx}
              to={`/websites/${website.id}?webSetting=true`}
            >
              <div
                key={idx}
                className="relative w-[68px] md:w-[42px] overflow-hidden h-[101px] md:h-[65px] border border-black dark:border-white rounded-[3px] flex items-center justify-center text-center bg-[#D9D9D9] hover:shadow-md transition-all duration-500"
              >
                {JSON.parse(website?.config)?.logo ? (
                  <img
                    src={JSON.parse(website?.config)?.logo}
                    alt=""
                    className="w-full h-full rounded-[3px] z-10 object-cover object-center absolute"
                  />
                ) : (
                  <div className="font-monoton w-[90%] text-2xl md:text-xl  dark:text-black  flex items-center justify-center  text-center">
                    {website.name.split('')[0]}
                  </div>
                )}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  )
}

function SettingsModal() {
  const [loading, setLoading] = useState(false)
  const [siteModal, setSiteModal] = useState(false)
  //   const { user } = useContext(UserContext)
  const navigate = useNavigate()
  const signOut = async () => {
    try {
      setLoading(true)
      await Auth.signOut()
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Loader show={loading} />
      <div>
        <div className="hidden md:flex justify-end pb-5">
          <span className="text-[18px] dark:text-white">Site Mode </span> <ColorModeSwitcher />
        </div>
        <div className="text-black dark:text-white space-y-6">
          <div className="space-y-4">
            <div className="flex justify-between pt-3 pb-3 border-b md:border-0">
              <div className="flex items-center md:space-x-2 space-x-[23px]">
                <button type="button">
                  <Settings size={22} className="text-brand-accent dark:text-brand-accent" />
                </button>
                <h1 className="text-[20px]">Settings</h1>
              </div>
            </div>
            <div className="flex md:hidden justify-between border-b pt-1 pb-3 px-2">
              <h3 className="text-[20px] darK:text-white">Profile Setting</h3>
              <button
                type="button"
                onClick={() => navigate('/profile-setup')}
                className="w-[25px] h-[25px] flex justify-center items-center border rounded text-black dark:text-white"
              >
                <span className="text-[32px]">+</span>
              </button>
            </div>
            <div className="flex md:hidden justify-between border-b pt-1 pb-3 px-2">
              <h3 className="text-[20px] darK:text-white">Sites </h3>
              <button
                onClick={() => setSiteModal(true)}
                type="button"
                className="w-[25px] h-[25px] flex justify-center items-center border rounded text-black dark:text-white"
              >
                <span className="text-[32px]">+</span>
              </button>
            </div>
            <div className="hidden md:grid grid-cols-4 gap-6">
              <div className="text-center">
                <a href="/profile-setup" className=" rounded-xl  justify-center flex items-center">
                  <ProfileBox
                    stroke="black"
                    size={70}
                    className="text-[#D9D9D9] hover:shadow-md transition-all duration-500"
                  />
                </a>
                <p className="text-[14px] md:text-[10px] text-center mt-1.5">Profile Setting</p>
              </div>
              <div className="text-center">
                <button
                  onClick={() => navigate(`/messages/${TECHBOOM_SUPPORT_ID}`)}
                  type="button"
                  className="hover:shadow-md transition-all duration-500 rounded-xl"
                >
                  <NotificationBox stroke="black" size={70} className="text-[#D9D9D9] dark:text-[#D9D9D9]" />
                </button>
                <p className="text-[14px] md:text-[10px]">Notification</p>
              </div>
              <div className="text-center">
                <button
                  onClick={() => navigate(`/messages/${TECHBOOM_SUPPORT_ID}`)}
                  type="button"
                  className="hover:shadow-md transition-all duration-500 rounded-xl"
                >
                  <HelpBox stroke="black" size={70} className="text-[#D9D9D9] " />
                </button>
                <p className="text-[14px] md:text-[10px]">Help</p>
              </div>
              <div>
                <button
                  type="button"
                  onClick={signOut}
                  className="hover:shadow-md transition-all duration-500 rounded-xl"
                >
                  <LogOutBox stroke="black" size={70} className="text-[#D9D9D9]" />
                </button>
                <p className="text-[14px] md:text-[10px] text-center">Logout</p>
              </div>
            </div>
          </div>
          <div className="hidden md:block">
            <SiteItem />
          </div>
        </div>
      </div>
      <ModalDialogV2 open={siteModal} onBack={() => setSiteModal(false)}>
        <div className="md:hidden px-6 text-black dark:text-white">
          <SiteItem />
        </div>
      </ModalDialogV2>
    </>
  )
}
export default SettingsModal
