import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AuthenticatedPage from '../components/AuthenticatedPage'
import HeaderNav from '../components/HeaderNav'
import PreviewLayout from '../components/layout/PreviewLayout'
import { Loader } from '../components/loader'
import WebsiteSettings from '../components/modal/WebsiteSettings'
import ConfirmModal from '../components/new/ConfirmModal'
import { getWebsitesByUserID } from '../lib/services/website.service'
import { deleteWebsite, getComponents } from '../services/website.service'

export default function New() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [websites, setWebsites] = useState([] as any[])
  const [showModalNewWebsite, setShowModalNewWebsite] = useState(false)
  const [confirmDeleteWebsiteId, setConfirmDeleteWebsiteId] = useState(null)
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)

  const url: any = document?.location
  const query: any = new URL(url)?.searchParams
  const newSite = query?.get('newSite')
  useEffect(() => {
    setShowModalNewWebsite(newSite)
  }, [])

  const handleDeleteWebsite = async (id: string) => {
    setWebsites(_websites => _websites.filter(w => w.id !== id))
    await deleteWebsite(id)
  }

  const handleConfirmDeleteWebsite = async (confirmed: boolean) => {
    if (confirmDeleteWebsiteId && confirmed) await handleDeleteWebsite(confirmDeleteWebsiteId)

    setShowConfirmDeleteModal(false)
    setConfirmDeleteWebsiteId(null)
  }

  const fetchWebsites = () => {
    getWebsitesByUserID()
      .then(async res => {
        const sites = await Promise.all(
          (res?.items || [])
            .filter(item => item !== null)
            .map(async item => ({
              ...item,
              components: await getComponents(item?.id as string),
            })),
        )
        setWebsites(sites)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchWebsites()
  }, [])

  return (
    <AuthenticatedPage>
      <PreviewLayout>
        <Loader show={loading} />

        <HeaderNav />

        <div className="w-full px-5 dark:bg-black pb-6">
          {websites.map(website => {
            const bgImage: string = website?.components?.[0]?.props?.src || null
            const bgColor: string = website?.components?.[0]?.props?.title?.settings?.backgroundColor || '#000000'
            return (
              <div key={website.id} className="mb-6 border border-black dark:border-white">
                <button
                  type="button"
                  className="aspect-h-3 overflow-hidden cursor-pointer relative bg-cover"
                  style={{
                    width: '100%',
                    aspectRatio: '4 / 3',
                    background: bgImage ? `url(${bgImage})` : bgColor,
                  }}
                  onClick={() => navigate(`/websites/${website.id}`)}
                >
                  <p className="font-extralight flex text-center justify-center items-center text-7xl text-white mix-blend-difference">
                    {website.name}
                  </p>
                </button>

                <div className="z-10 bottom-12 h-12 dark:border-white border-black border-t text-black w-full">
                  <button
                    type="button"
                    className="w-[50%] h-full border-black dark:border-white p-1 bg-white dark:bg-black border-r dark:text-white"
                    onClick={() => navigate(`/websites/${website.id}`)}
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    className="w-[50%] h-full border-black dark:border-white dark:bg-black p-1 bg-white dark:text-white"
                    onClick={() => {
                      setConfirmDeleteWebsiteId(website.id)
                      setShowConfirmDeleteModal(true)
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            )
          })}

          {/* wallet */}
          <div className="w-full aspect-w-4 aspect-h-3 bg-black dark:bg-white">
            <button
              type="button"
              className="font-extralight flex text-center justify-center items-center text-white text-7xl dark:text-black"
              onClick={() => navigate('/wallet')}
            >
              Digital Wallet
            </button>
          </div>
        </div>

        {/* modals */}
        <ConfirmModal
          open={showConfirmDeleteModal}
          onConfirm={handleConfirmDeleteWebsite}
          title="Are you sure you would like to delete this website?"
        />

        <WebsiteSettings open={showModalNewWebsite} onClose={() => setShowModalNewWebsite(false)} />
      </PreviewLayout>
    </AuthenticatedPage>
  )
}
