import SectionSlate from '../../../common/SectionSlatePreview'
import { AboutUsSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import ButtonRenderer from '../ButtonRenderer'

export default function VariantEight({ section }: { section: AboutUsSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data
  const button = section.variables.find(variable => variable.name === 'BUTTON_1')?.data
  return (
    <div
      className="grid md:grid-cols-3 grid-cols-1 gap-4 h-full relative"
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="hidden md:block">
        <img src={media} className="h-[300px] object-cover w-[450px] md:h-full absolute left-0 " />
      </div>
      <div className="text-center md:gap-y-4 flex flex-col items-center justify-center w-full px-4 md:px-0 py-2 md:py-12">
        <p className="text-[32px] md:text-4xl font-bold ">
          <SectionSlate initialValue={title} previewMode />
        </p>
        <h1 className="md:text-[18px] text-[14px]">
          <SectionSlate initialValue={paragraph} previewMode />
        </h1>
        {button ? (
          <ButtonRenderer button={button} />
        ) : (
          <button className="rounded-3xl lg:w-[50%] mt-2 md:mt-0 w-[40%] bg-black border-2  text-white lg:px-6  px-3 lg:text-xl  lg:py-3  py-1 hover:bg-white hover:border-2 border-black hover:text-black font-semibold transition-all duration-300">
            more
          </button>
        )}
      </div>
      <div className="hidden md:block">
        <img src={media} className="h-[300px] object-cover hidden md:block md:w-[450px] md:h-full absolute right-0" />
      </div>
    </div>
  )
}
