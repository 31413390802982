import SectionSlate from '../../../common/SectionSlatePreview'
import { TextSection as TextSectionType } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantTwo({ section }: { section: TextSectionType }) {
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: '#fff',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className={`md:container md:mx-auto px-6 md:px-4 pt-4 md:pt-12`}>
        <div className="md:w-[750px] max-w-full mx-auto">
          <p className="text-xl md:text-4xl font-bold text-center ">
            {<SectionSlate initialValue={title} previewMode />}
          </p>
        </div>
        {paragraph && (
          <div className="md:w-[750px] max-w-full mx-auto md:mt-2 ">
            <p className="text-center leading-7">{<SectionSlate initialValue={paragraph} previewMode />}</p>
          </div>
        )}
      </div>
      {media && (
        <div className="h-[300px] md:h-[565px] md:px-12 px-4 mt-2 md:mt-4 pb-4 md:pb-12">
          <img className="h-full w-full object-cover object-center bg-black" src={media} alt="" />
        </div>
      )}
    </div>
  )
}
