import { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Chat from 'src/icons/Chat'
import Close from 'src/icons/Close'
import Heart from 'src/icons/Heart'
import RightArrow from 'src/icons/RightArrow'
import Users from 'src/icons/Users'
import UsersLight from 'src/icons/UsersLight'
import { getWebsiteByID } from 'src/lib/services/website.service'
import { getUserById } from 'src/services/user.service'
import ShareModal from '../../posts-page/components/ShareModal'

function Preview() {
  const params = useParams()
  const navigate = useNavigate()

  const [website, setWebsite] = useState<any>(null)
  const [user, setUser] = useState<any>(null)

  const iframeRef = useRef<HTMLIFrameElement>(null)

  const getData = async () => {
    const websiteData = await getWebsiteByID(params.websiteId as string)
    setWebsite({
      ...websiteData,
      config: websiteData?.config ? JSON.parse(websiteData?.config) : null,
      footer: websiteData?.footer ? JSON.parse(websiteData?.footer) : null,
      menu: websiteData?.menu ? JSON.parse(websiteData?.menu) : null,
      links: websiteData?.links ? JSON.parse(websiteData?.links) : null,
      newMenu: websiteData?.newMenu ? JSON.parse(websiteData?.newMenu) : null,
      newMenuConfig: websiteData?.newMenuConfig ? JSON.parse(websiteData?.newMenuConfig) : null,
      newFooter: websiteData?.newFooter ? JSON.parse(websiteData?.newFooter) : null,
    })
  }
  const getUserData = async () => {
    const userProfile = await getUserById(website?.userID ?? '')
    setUser(userProfile)
  }

  useEffect(() => {
    if (params.websiteId) {
      getData()
    }
  }, [params])

  useEffect(() => {
    if (website) {
      getUserData()
    }
  }, [website])

  return (
    <>
      <div className="md:flex justify-between p-5">
        <div className="flex justify-between gap-x-8">
          <div className="flex items-center space-x-2 md:space-x-4">
            <div className="w-[20px] md:w-[30px] h-[20px] md:h-[30px] rounded-full bg-[#EFEFEF]">
              {user?.profilePicture && (
                <Link to={`/profile/${user?.id}`}>
                  <img className="w-full h-full rounded-full" src={user?.profilePicture} alt="" />
                </Link>
              )}
            </div>
            <h4 className="text-[15px] dark:text-white md:text-white md:text-[20px]">{website?.config?.title}</h4>
          </div>
          <div className="flex items-center space-x-4 ">
            <div className=" flex items-end pt-1">
              <button type="button">
                <Heart size={24} className="md:text-[#fff]" />
              </button>
            </div>
            <div className="flex items-end">
              <button type="button">
                <Chat className="md:text-[#fff]" size={24} />
              </button>
            </div>
            <button type="button">
              <div className="hidden dark:block md:block">
                <Users size={24} className="md:text-[#fff]" />
              </div>
              <div className="md:hidden dark:hidden">
                <UsersLight size={24} />
              </div>
            </button>
            <ShareModal page="preview" />
            {/* <button type="button">
              <Share size={24} className="md:text-[#fff]" />
            </button> */}
          </div>
        </div>
        <div className="hidden md:flex gap-x-6">
          <button type="button" onClick={() => navigate(-1)}>
            <Close size={24} className="text-[#fff]" />
          </button>
          <button type="button">
            <RightArrow size={24} className="text-[#EFEFEF]" />
          </button>
        </div>
      </div>

      <div className="rounded-lg bg-white">
        <iframe
          ref={iframeRef}
          src={`/preview/${params.websiteId}`}
          title="Plly Preview"
          className="h-full min-h-screen w-full"
        />
      </div>
    </>
  )
}

export default Preview
