import clsx from 'clsx'
import SectionSlatePreview from '../../../../common/SectionSlatePreview'
import { TeamMembersSectionMember } from './../../../types'

export default function CardDesign({ member, isRound }: { member: TeamMembersSectionMember; isRound?: boolean }) {
  return (
    <div>
      <div className=" w-[300px] lg:w-[400px] mx-auto lg:mx-0 lg:max-w-lg rounded overflow-hidden shadow-lg bg-neutral-200 py-[0px] ">
        <img
          className={clsx('h-[400px] w-[400px] object-cover object-top', isRound && 'rounded-full')}
          src={member.image}
          alt=""
        />
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2">
            <SectionSlatePreview initialValue={member.name} previewMode />
          </div>
          {member.subtitle && (
            <p className="text-gray-700 text-base">
              <SectionSlatePreview initialValue={member.subtitle} previewMode />
            </p>
          )}
        </div>
        <div className="px-6 pt-4 pb-2">
          <p className="bg-slate-300 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 text-center">
            <SectionSlatePreview initialValue={member.description} previewMode />
          </p>
        </div>
      </div>
    </div>
  )
}
