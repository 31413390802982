import SectionSlate from '../../../common/SectionSlatePreview'
import { AttentionGrabberSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

const VariantTwelve = ({ section }: { section: AttentionGrabberSection }) => {
  const title = section.variables.find(variable => variable?.name === 'TITLE')?.data
  const media = section.variables.find(variable => variable?.name === 'MEDIA')?.data

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: 'none',
          backgroundSettings: section?.settings?.color?.background,
          image: media,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
      className="bg-cover bg-center bg-no-repeat h-screen flex justify-center items-center text-center"
    >
      <div className="logo-dribble lg:text-[19vh] text-5xl">
        <b>
          <SectionSlate initialValue={title} previewMode />
        </b>
      </div>
    </div>
  )
}

export default VariantTwelve
