import { AnimateSharedLayout, motion } from 'framer-motion'
import { useState } from 'react'
import { BsArrowRight } from 'react-icons/bs'
import { IoMenuSharp } from 'react-icons/io5'
import { MdOutlineClose, MdOutlineSearch } from 'react-icons/md'
import FontLoader from '../FontLoader'
import { MenuVariantProps, NewMenuLinkType } from '../menu'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../utils'

export default function MenuVariantTwentyThree({ menu, website }: MenuVariantProps) {
  return (
    <>
      <FontLoader settings={menu?.settings?.fontFamily} />
      <MobileNavbar menu={menu} website={website} />
    </>
  )
}

const MobileNavbar = ({ menu, website }: MenuVariantProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isSearch, setIsSearch] = useState(false)

  return (
    <AnimateSharedLayout>
      <div
        style={{
          ...getBackgroundColor({
            settings: menu?.settings?.backgroundColor,
            defaultValue: '#f8f2ea',
          }),
          ...getSpacing(menu?.settings?.spacing),
        }}
      >
        <div className="pb-6">
          <div className="flex justify-between items-center px-6 md:px-12 py-4">
            <div>
              <button onClick={() => setIsSearch(!isSearch)} type="button">
                {isSearch ? (
                  <>
                    <MdOutlineClose
                      size={40}
                      className="text-black hover:text-red-700 md:block hidden"
                      color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }).color}
                    />
                    <MdOutlineClose
                      size={25}
                      className="text-black hover:text-red-700 md:hidden block"
                      color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }).color}
                    />
                  </>
                ) : (
                  <>
                    <MdOutlineSearch
                      size={25}
                      className="md:block hidden text-black hover:text-red-700"
                      color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }).color}
                    />
                    <MdOutlineSearch
                      size={25}
                      className="block md:hidden text-black hover:text-red-700"
                      color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }).color}
                    />
                  </>
                )}
              </button>
            </div>
            <div>
              <a href="/">
                {website?.config.logo ? (
                  <img src={website?.config.logo} className="cursor-pointer h-11 w-11 rounded-full" />
                ) : (
                  <p
                    style={{
                      ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                      ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                    }}
                    className="md:text-xl"
                  >
                    {website.name}
                  </p>
                )}
              </a>
            </div>
            <div>
              <button onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? (
                  <>
                    <MdOutlineClose
                      color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }).color}
                      size={40}
                      className="text-black hover:text-red-700 md:block hidden"
                    />
                    <MdOutlineClose
                      size={25}
                      color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }).color}
                      className="md:hidden block"
                    />
                  </>
                ) : (
                  <>
                    <IoMenuSharp
                      size={40}
                      className="text-black hover:text-red-700 md:block hidden"
                      color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }).color}
                    />
                    <IoMenuSharp
                      size={25}
                      color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }).color}
                      className="md:hidden block"
                    />
                  </>
                )}
              </button>
            </div>
          </div>
          {isOpen && (
            <motion.div
              initial={{ x: 900 }}
              animate={{ x: 0, transition: { duration: 0.6, type: 'spring' }, originX: 30 }}
              className={`text-white  space-y-8 md:w-[500px] border p-4`}
              style={{
                ...getBackgroundColor({
                  settings: menu?.settings?.backgroundColor,
                  defaultValue: '#303c3c',
                }),
                ...getSpacing(menu?.settings?.spacing),
                marginLeft: 'auto',
              }}
            >
              <motion.div className={`space-y-3 py-6`}>
                {menu.links.map((item, i: any) => (
                  <div key={i} className={`uppercase  cursor-pointer border-b`}>
                    <a
                      href={item.url}
                      target={item.type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                      style={{
                        ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }),
                        ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                      }}
                      className="md:text-xl"
                    >
                      {item.name}
                    </a>
                  </div>
                ))}
              </motion.div>
              <div className="space-y-3">
                <h2
                  className="uppercase text-[20px] md:text-[24px] font-bold"
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  NEWSLETTER
                </h2>
                <p
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  Sign up for a free newsletter to help you brew amazing coffee at home.
                </p>
                <div className="relative">
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 md:pr-6 pointer-events-none">
                    <BsArrowRight
                      size={30}
                      color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }).color}
                      className="md:block hidden"
                    />
                    <BsArrowRight
                      size={20}
                      color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }).color}
                      className="md:hidden block"
                    />
                  </div>
                  <input
                    type="email"
                    className="block w-full  px-2 md:px-4 py-2 md:text-[20px] border border-gray-300 focus:outline-0 bg-transparent"
                    placeholder="Enter Email..."
                    required
                  />
                </div>
              </div>
            </motion.div>
          )}
          {isSearch && (
            <motion.div
              initial={{ y: -100 }}
              animate={{ y: 0, transition: { duration: 0.6, type: 'spring' }, originX: 30 }}
              style={{
                ...getBackgroundColor({
                  settings: menu?.settings?.backgroundColor,
                  defaultValue: '#f8f2ea',
                }),
                ...getSpacing(menu?.settings?.spacing),
              }}
            >
              <div className="relative max-w-[700px] mx-auto py-6 md:px-6">
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 md:pr-6 pointer-events-none">
                  <>
                    <MdOutlineSearch
                      size={30}
                      color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }).color}
                      className="md:block hidden"
                    />
                    <MdOutlineSearch
                      size={20}
                      color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }).color}
                      className="md:hidden block"
                    />
                  </>
                </div>
                <input
                  type="email"
                  className="block w-full px-4 py-2 md:text-[20px] border border-black focus:outline-0 bg-transparent"
                  placeholder="Search..."
                  required
                />
              </div>
            </motion.div>
          )}
        </div>
      </div>
    </AnimateSharedLayout>
  )
}
