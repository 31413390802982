import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { AboutUsSection, AboutUsSectionServices } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import SectionSlate from '../../../common/SectionSlatePreview'

export default function VariantFifteen({ section }: { section: AboutUsSection }) {
  const media = section.variables.find(i => i.name === 'MEDIA')?.data
  const items = section.variables.find(variable => variable.name === 'SERVICE')?.data as AboutUsSectionServices[]
  const title = section.variables.find(i => i.name === 'TITLE')?.data
  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
      className="md:py-12 py-4"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 items-center text-center md:px-14 px-4">
        <div className="block md:hidden">
          <h1 className="text-xl">
            <SectionSlatePreview initialValue={title} previewMode />
          </h1>
        </div>
        <div className="md:h-[600px] h-[250px]">
          <img src={media} alt="" className="w-full h-full object-cover object-center " />
        </div>
        <div className="flex flex-col items-center w-[90%] m-auto">
          <h1 className="md:text-[36px] hidden md:block">
            <SectionSlatePreview initialValue={title} previewMode />
          </h1>
          <Swiper
            pagination={{
              clickable: true,
            }}
            navigation={{
              nextEl: '.custom-next',
              prevEl: '.custom-prev',
              hideOnClick: true,
            }}
            loop={true}
            modules={[Pagination, Navigation]}
            grabCursor={true}
            className="mySwiper w-full pb-4 md:pb-0"
          >
            {items.map(item => {
              return (
                <SwiperSlide key={item.id}>
                  <div className="flex text-center mt-4 gap-y-1 pb-8 flex-col items-center">
                    <img
                      src={item.image}
                      alt=""
                      className="md:w-[350px] md:h-[350px] rounded-full object-cover object-top w-[100px] h-[100px]"
                    />

                    <h1 className="md:text-2xl md:w-[80%]">
                      <SectionSlate initialValue={item.name} previewMode />
                    </h1>
                    <h6 className="md:text-xl text-[12px] md:w-[80%]">
                      <SectionSlate initialValue={item.description} previewMode />
                    </h6>
                  </div>
                </SwiperSlide>
              )
            })}
            <div className="md:flex justify-between items-center w-[60%] m-auto hidden">
              <button className="custom-prev font-bold  ">prev</button>
              <button className="custom-next font-bold">nex</button>
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  )
}
