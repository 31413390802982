import { motion } from 'framer-motion'
import { useState } from 'react'
import { GrFormClose } from 'react-icons/gr'
import { HiMenuAlt4 } from 'react-icons/hi'
import FontLoader from '../FontLoader'
import { MenuVariantProps, NewMenuLinkType } from '../menu'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../utils'

export default function MenuVariantFour({ menu, website }: MenuVariantProps) {
  return (
    <>
      <FontLoader settings={menu?.settings?.fontFamily} />
      <DesktopNavbar menu={menu} website={website} />
      <MobileNavbar menu={menu} website={website} />
    </>
  )
}

const DesktopNavbar = ({ menu, website }: MenuVariantProps) => {
  return (
    <motion.div
      initial={{ y: -40 }}
      animate={{
        y: 0,
        transition: {
          duration: 1,
        },
      }}
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'white' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
      className="hidden lg:block text-xl"
    >
      <div className="flex justify-between items-center px-12 py-6">
        <div className="text-xl">
          <a href="/">
            {website?.config?.logo ? (
              <img
                src={website?.config?.logo}
                alt={website?.name}
                className="cursor-pointer h-[80px] w-[80px] rounded-full"
              />
            ) : (
              <p className="tracking-wide font-bold">
                <span
                  className="hover:shadow-xl"
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  {website?.name}
                </span>
              </p>
            )}
          </a>
        </div>

        <div className="">
          <ul className="list-none flex font-semibold tracking-wide gap-x-12">
            {menu.links.map(({ id, name, type, url }) => {
              return (
                <li key={id}>
                  <a
                    style={{
                      ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                      ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                    }}
                    href={url}
                    target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                    className="p-2 font-bold px-4 transition-all duration-300 hover:shadow-2xl"
                  >
                    {name}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </motion.div>
  )
}

const MobileNavbar = ({ menu, website }: MenuVariantProps) => {
  const [show, setShow] = useState(false)
  return (
    <div
      className="block lg:hidden "
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'white' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className="flex justify-between items-center py-4 px-4">
        <div>
          {website?.config?.logo ? (
            <img
              className="cursor-pointer h-[30px] w-[30px] rounded-full"
              src={website?.config?.logo}
              alt={website?.name}
            />
          ) : (
            <p className="font-bold text-xl"> {website?.name}</p>
          )}
        </div>
        <div>
          {show ? (
            <GrFormClose className="cursor-pointer" onClick={() => setShow(false)} size={28} />
          ) : (
            <HiMenuAlt4 className="cursor-pointer" onClick={() => setShow(true)} size={25} />
          )}
        </div>
      </div>

      {show && (
        <motion.ul
          animate={{
            y: show ? [-80, 0] : -80,
          }}
          transition={{
            duration: 0.5,
            type: 'spring',
          }}
          className="shadow py-4 list-none flex flex-col  ml-[-35px] gap-y-2"
        >
          {menu.links.map(({ id, name, url, type }) => {
            return (
              <li key={id}>
                <motion.a
                  initial={{
                    x: 0,
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                    transition: {
                      duration: 1,
                      delay: 0.2,
                    },
                  }}
                  whileHover={{
                    x: 10,
                    transition: {
                      duration: 1,
                      type: 'spring',
                    },
                  }}
                  href={url}
                  target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                  className="flex items-center gap-x-1 text-gray-800 font-semibold"
                >
                  <span className="shadow-lg transition-all duration-300 px-2 hover:shadow-xl hover:text-gray-600">
                    {name}
                  </span>
                </motion.a>
              </li>
            )
          })}
        </motion.ul>
      )}
    </div>
  )
}
