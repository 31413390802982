import React from 'react'
import Header from '../common/posts/Header'
import BottomNavigation from '../feed/components/BottomNavigation'
import BubbleChat from './components/BubbleChat'
import ChatFooter from './components/ChatFooter'
import Contacts from './components/Contacts'
// import SearchUser from './components/SearchUser'

export default function MobileChat() {
  return (
    <div className="flex flex-col">
      <div className="z-10 w-full h-full overflow-hidden">
        <Header />
      </div>
      <div className="pl-2 w-full bg-[white] border-b-2 border-[#C4C4C4] dark:bg-[#EFEFEF] flex items-center ">
        {/* <div>{search ? <SearchUser /> : <Contacts />}</div> */}
        <div>
          <Contacts />
        </div>
      </div>
      <div className="bg-white dark:bg-black h-[450px] ">
        <div className="flex flex-col chat px-[18px] pt-6 overflow-y-auto overflow-x-hidden h-[550px] pb-24 ">
          <BubbleChat
            name="Irma Cooper"
            message="Amet quam id tellus et massa nulla sitconsequat.Amet quam id tellus et massa nulla sit consequat."
            date="Message sent 2:40 PM"
            image="https://images.unsplash.com/photo-1503919545889-aef636e10ad4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
            isUserMessage
          />

          <BubbleChat
            name="Irma Cooper"
            message="Amet quam id tellus et massa nulla sitconsequat.Amet quam id tellus et massa nulla sit consequat."
            date="Message sent 2:40 PM"
            image="https://images.unsplash.com/photo-1503919545889-aef636e10ad4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
          />
        </div>
        <div className="fixed w-[100%] bottom-0">
          <ChatFooter search="" />
        </div>
        <BottomNavigation variant="two" />
      </div>
    </div>
  )
}
