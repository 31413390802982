export type UserInfo = {
  name: string
  image: string
  message: string
  date: string
  time: string
  active: boolean
  onActiveClick?: () => void
}

export default function UserMessage({ name, message, date, time, image, active, onActiveClick }: UserInfo) {
  return (
    <button
      type="button"
      className={`lg:w-[412px] xl:w-[450px] md:w-[400px] ${
        active ? 'bg-[#FFFFFF] text-[black]' : 'bg-black text-[#D9D9D9]'
      }`}
      onClick={onActiveClick}
    >
      <div className="xl:pt-3 lg:pt-2 lg:h-[110px] h-[100px] md:h-[110px] md:py-2 xl:h-[120px] pl-[30px] pr-[28.5px] flex justify-between cursor-pointer">
        <div className="flex gap-x-12 lg:gap-x-8 md:gap-x-7 text-left ">
          <img
            src={image}
            alt={name}
            className="w-[50px] object-cover mt-3 h-[50px] border-2 border-[#D9D9D9] rounded-full"
          />
          <div className="flex flex-col w-[198px] gap-y-1">
            <h3 className="font-bold text-[19px]"> {name}</h3>
            <p className="font-bold text-sm xl:overflow-hidden whitespace-wrap">{message}</p>
            <p className="flex text-[11px] ml-auto gap-x-2">
              <span className="text-[#707070] uppercase"> {date} </span>
              <span className="text-[#707070] uppercase">{time} </span>
            </p>
          </div>
        </div>
        {!active && <div className="bg-[#E90570] w-[14.84px] h-[14.84px] mt-[0.4rem] rounded-full lg:-mr-2" />}
      </div>
      <div className="bg-[#DCE9D9] h-[1px] w-[349px] lg:w-[87%] xl:w-[400px] m-auto" />
    </button>
  )
}
