import Icon from './icons'
import { IconPropsT } from './types'

function Filter(props: IconPropsT) {
  const { title = 'Filter', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 24 24" size={size} title={title} {...otherProps}>
      <path fillRule="evenodd" clipRule="evenodd" d="M1 17.9656C1 17.3943 1.46315 16.9312 2.03448 16.9312L22.2069 16.9312C22.7782 16.9312 23.2414 17.3943 23.2414 17.9656C23.2414 18.537 22.7782 19.0001 22.2069 19.0001L2.03448 19.0001C1.46315 19.0001 1 18.537 1 17.9656Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.10339 11.7586C4.10339 11.1873 4.56655 10.7241 5.13788 10.7241L19.1034 10.7241C19.6747 10.7241 20.1379 11.1873 20.1379 11.7586C20.1379 12.3299 19.6747 12.7931 19.1034 12.7931L5.13788 12.7931C4.56655 12.7931 4.10339 12.3299 4.10339 11.7586Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.6897 5.03448C6.6897 4.46315 7.15285 4 7.72418 4L17.0345 4C17.6059 4 18.069 4.46315 18.069 5.03448C18.069 5.60581 17.6059 6.06896 17.0345 6.06896L7.72418 6.06897C7.15285 6.06897 6.6897 5.60581 6.6897 5.03448Z" />

    </Icon>
  )
}

export default Filter
