import { ImageOmgSection as OmgSectionType } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import VariantNine from './VariantNine'

export default function VariantEleven({ section }: { section: OmgSectionType }) {
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: 'black',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <VariantNine {...{ section }} hideImageBg />
      {media && (
        <div className="pb-14">
          <div className="h-[445px] w-[940px] max-w-full mx-auto">
            <img className="h-full w-full object-cover object-center bg-black" src={media} alt="" />
          </div>
        </div>
      )}
    </div>
  )
}
