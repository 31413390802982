import { Descendant } from 'slate'
import {
  ContactUsMap,
  ContactUsVariables,
  ContactUsVariants,
  ContractSectionContract,
  InputsContactUSSection,
  Section,
  SectionTags,
} from 'src/_polly/components/src/sections'
import { v4 as uuid } from 'uuid'
import { DEFAULT_VARIANT_SETTINGS, getSectionVariants } from './get-variants'

const tags: Record<ContactUsVariants, SectionTags[]> = {
  VARIANT_1: ['IMAGE', 'ANIMATION'],
  VARIANT_2: ['IMAGE', 'ANIMATION'],
  VARIANT_3: ['IMAGE'],
  VARIANT_4: [],
  VARIANT_5: ['IMAGE'],
  VARIANT_6: [],
  VARIANT_7: [],
  VARIANT_8: ['IMAGE', 'ANIMATION'],
  VARIANT_9: [],
  VARIANT_10: [],
  VARIANT_11: [],
  VARIANT_12: [],
  VARIANT_13: [],
  VARIANT_14: [],
  VARIANT_15: [],
  VARIANT_17: [],
  VARIANT_16: [],
}

export function getContactUsVariants() {
  const variants = getSectionVariants('CONTACT_US')
  const sections: Section<ContactUsVariants, ContactUsVariables>[] = []

  variants.forEach(variant => {
    if (variant.id === 'VARIANT_1') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Visit Us' }] }]
      const subtitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Subtitle' }] }]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'Our aesthetic design includes minimalist design, compelling visuals.' }],
        },
      ]
      const phoneLabel: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Phone' }] }]
      const phone: Descendant[] = [{ type: 'paragraph', children: [{ text: '(555) 555-5555' }] }]
      const emailLabel: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Email' }] }]
      const email: Descendant[] = [{ type: 'paragraph', children: [{ text: 'example@gmail.com' }] }]

      const hoursLabel: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Hours' }] }]
      const hours: Descendant[] = [{ type: 'paragraph', children: [{ text: 'monday-friday' }] }]

      const data: Section<ContactUsVariants, ContactUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'CONTACT_US',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },

          {
            id: uuid(),
            category: 'TEXT',
            data: {
              address: '1600 Amphitheatre Parkway, Mountain View, CA',
              position: { lat: 37.4224764, lng: -122.0842499 },
            } as ContactUsMap,
            name: 'MAP',
          },
          {
            id: uuid(),
            name: 'CONTRACT',
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: hoursLabel,
                description: hours,
              },
              {
                id: uuid(),
                name: phoneLabel,
                description: phone,
              },
              {
                id: uuid(),
                name: emailLabel,
                description: email,
              },
            ] as ContractSectionContract[],
          },
        ],
        tags: tags[variant.id as ContactUsVariants],
        variant: variant.id as ContactUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_2') {
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'Our aesthetic design includes minimalist design, compelling visuals.' }],
        },
      ]
      const subtitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'contact us' }] }]
      const phoneLabel: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Phone' }] }]
      const phone: Descendant[] = [{ type: 'paragraph', children: [{ text: '(555) 555-5555' }] }]

      const hoursLabel: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Hours' }] }]
      const hours: Descendant[] = [{ type: 'paragraph', children: [{ text: 'monday-friday' }] }]

      const data: Section<ContactUsVariants, ContactUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'CONTACT_US',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },

          {
            id: uuid(),
            category: 'TEXT',
            data: {
              address: '1600 Amphitheatre Parkway, Mountain View, CA',
              position: { lat: 37.4224764, lng: -122.0842499 },
            } as ContactUsMap,
            name: 'MAP',
          },
          {
            id: uuid(),
            name: 'CONTRACT',
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: hoursLabel,
                description: hours,
              },
              {
                id: uuid(),
                name: phoneLabel,
                description: phone,
              },
            ] as ContractSectionContract[],
          },
        ],
        tags: tags[variant.id as ContactUsVariants],
        variant: variant.id as ContactUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_3') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Visit Us' }] }]

      const data: Section<ContactUsVariants, ContactUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'CONTACT_US',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },

          {
            id: uuid(),
            name: 'INPUTS',
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: 'First Name',
                placeholder: 'First Name',
                type: 'text',
                error: 'First Name is required',
              },
              {
                id: uuid(),
                name: 'Last Name',
                placeholder: 'Last Name',
                type: 'text',
                error: 'Last Name is required',
              },
              {
                id: uuid(),
                name: 'Email',
                placeholder: 'example@gmail.com',
                type: 'email',
                error: 'email is required',
              },
              {
                id: uuid(),
                name: 'subject',
                placeholder: 'subject',
                type: 'text',
                error: 'subject is required',
              },
              {
                id: uuid(),
                name: 'Message',
                placeholder: 'message',
                type: 'textarea',
                error: '',
              },
            ] as InputsContactUSSection[],
          },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://images.unsplash.com/photo-1524758631624-e2822e304c36?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fG9mZmljZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
            name: 'MEDIA',
          },
        ],
        tags: tags[variant.id as ContactUsVariants],
        variant: variant.id as ContactUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_4') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Get In Touch' }] }]
      const subtitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Burn Rate Buster UX Design' }] }]
      const data: Section<ContactUsVariants, ContactUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'CONTACT_US',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          {
            id: uuid(),
            name: 'INPUTS',
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: 'First Name',
                placeholder: 'enter your first name',
                type: 'text',
                error: 'First Name is required',
              },
              {
                id: uuid(),
                name: 'last Name',
                type: 'text',
                placeholder: 'enter your last name',
                error: 'last Name is required',
              },
              {
                id: uuid(),
                name: 'Email',
                type: 'email',
                placeholder: 'enter your email',
                error: 'email is required',
              },
              {
                id: uuid(),
                name: 'Message',
                type: 'textarea',
                placeholder: 'enter your message',
                error: 'message is required',
              },
            ] as InputsContactUSSection[],
          },
        ],
        tags: tags[variant.id as ContactUsVariants],
        variant: variant.id as ContactUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_5') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Visit US' }] }]
      const subtitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'subtitle' }] }]
      const data: Section<ContactUsVariants, ContactUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'CONTACT_US',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },

          {
            id: uuid(),
            name: 'INPUTS',
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: 'Email',
                type: 'email',
                error: 'email is required',
                placeholder: 'example@gmail.com',
              },
            ] as InputsContactUSSection[],
          },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
            name: 'MEDIA',
          },
        ],
        tags: tags[variant.id as ContactUsVariants],
        variant: variant.id as ContactUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_6') {
      const subtitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Send us a message' }] }]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Look no further than our custom site and portfolio design services. Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. Whether you are a digital nomad or a disruptive technology startup, we have the expertise to optimize your website for exceptional user experience on any device.',
            },
          ],
        },
      ]

      const data: Section<ContactUsVariants, ContactUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'CONTACT_US',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },

          {
            id: uuid(),
            name: 'INPUTS',
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: 'Email',
                type: 'email',
                error: 'email is required',
                placeholder: 'example@gmail.com',
              },
            ] as InputsContactUSSection[],
          },
        ],
        tags: tags[variant.id as ContactUsVariants],
        variant: variant.id as ContactUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_7') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Get In Touch' }] }]
      const subtitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Visit Us' }] }]

      const data: Section<ContactUsVariants, ContactUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'CONTACT_US',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://images.unsplash.com/photo-1525598912003-663126343e1f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwyfHxwaG9uZXxlbnwwfHx8fDE2Nzc1OTE1Mjc&ixlib=rb-4.0.3&q=80&w=1080',
            name: 'MEDIA',
          },
          {
            id: uuid(),
            name: 'INPUTS',
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: 'First Name',
                placeholder: 'First Name',
                type: 'text',
                error: 'First Name is required',
              },
              {
                id: uuid(),
                name: 'last Name',
                placeholder: 'last Name',
                type: 'text',
                error: 'last Name is required',
              },
              {
                id: uuid(),
                name: 'Email',
                placeholder: 'example@gmail.com',
                type: 'email',
                error: 'email is required',
              },
              {
                id: uuid(),
                name: 'Message',
                placeholder: 'message',
                type: 'textarea',
                error: 'message is required',
              },
            ] as InputsContactUSSection[],
          },
        ],
        tags: tags[variant.id as ContactUsVariants],
        variant: variant.id as ContactUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_8') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Visit us' }] }]
      const subtitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'subtitle' }] }]
      const paragraph: Descendant[] = [{ type: 'paragraph', children: [{ text: 'What We Should Work Together ?' }] }]

      const phoneLabel: Descendant[] = [{ type: 'paragraph', children: [{ text: 'P:' }] }]
      const phone: Descendant[] = [{ type: 'paragraph', children: [{ text: '(555) 555-5555' }] }]
      const emailLabel: Descendant[] = [{ type: 'paragraph', children: [{ text: 'E:' }] }]
      const email: Descendant[] = [{ type: 'paragraph', children: [{ text: 'example2gmail.com' }] }]

      const data: Section<ContactUsVariants, ContactUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'CONTACT_US',
        updatedAt: '',
        variables: [
          {
            id: uuid(),
            category: 'TEXT',
            data: title,
            name: 'TITLE',
          },
          {
            id: uuid(),
            category: 'TEXT',
            data: paragraph,
            name: 'PARAGRAPH',
          },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },

          {
            id: uuid(),
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: phoneLabel,
                description: phone,
              },
              {
                id: uuid(),
                name: emailLabel,
                description: email,
              },
            ] as ContractSectionContract[],
            name: 'CONTRACT',
          },
          {
            id: uuid(),
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: 'First Name',
                placeholder: 'First Name',
                type: 'text',
                error: 'First Name is required',
              },
              {
                id: uuid(),
                name: 'Last Name',
                placeholder: 'Last Name',
                type: 'text',
                error: 'Last Name is required',
              },
              {
                id: uuid(),
                name: 'Email',
                placeholder: 'example@gmail.com',
                type: 'email',
                error: 'email is required',
              },
              {
                id: uuid(),
                name: 'company',
                placeholder: 'company',
                type: 'text',
                error: 'company is required',
              },
              {
                id: uuid(),
                name: 'Message',
                placeholder: 'message',
                type: 'textarea',
                error: 'message is required',
              },
            ] as InputsContactUSSection[],
            name: 'INPUTS',
          },
        ],
        tags: tags[variant.id as ContactUsVariants],
        variant: variant.id as ContactUsVariants,
        styleName: variant.name,
        designedBy: 'Abrar Muthana',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_9') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Visit US' }] }]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience.',
            },
          ],
        },
      ]
      const data: Section<ContactUsVariants, ContactUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'CONTACT_US',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://images.unsplash.com/photo-1499159058454-75067059248a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHx0ZWxlcGhvbmV8ZW58MHx8fHwxNjc3ODYwMDc0&ixlib=rb-4.0.3&q=80&w=1080',
            name: 'MEDIA',
          },
          {
            id: uuid(),
            name: 'INPUTS',
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: 'First Name',
                placeholder: 'John Doaa',
                type: 'text',
                error: 'First Name is required',
              },
              {
                id: uuid(),
                name: 'Email',
                placeholder: 'boss@gmail.com',
                type: 'email',
                error: 'email is required',
              },
              {
                id: uuid(),
                name: 'Message',
                placeholder: 'Type text',
                type: 'textarea',
                error: 'message is required',
              },
            ] as InputsContactUSSection[],
          },
        ],
        tags: tags[variant.id as ContactUsVariants],
        variant: variant.id as ContactUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_10') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Get In Touch' }] }]
      const subtitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'What We Should Work Together ?' }] }]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio.',
            },
          ],
        },
      ]
      const phoneLabel: Descendant[] = [{ type: 'paragraph', children: [{ text: 'P :' }] }]
      const phone: Descendant[] = [{ type: 'paragraph', children: [{ text: '(555) 555-5555' }] }]
      const emailLabel: Descendant[] = [{ type: 'paragraph', children: [{ text: 'E :' }] }]
      const email: Descendant[] = [{ type: 'paragraph', children: [{ text: 'example@gmail.com' }] }]
      const data: Section<ContactUsVariants, ContactUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'CONTACT_US',
        updatedAt: '',
        variables: [
          {
            id: uuid(),
            category: 'TEXT',
            data: title,
            name: 'TITLE',
          },
          {
            id: uuid(),
            category: 'TEXT',
            data: paragraph,

            name: 'PARAGRAPH',
          },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://images.unsplash.com/photo-1524758631624-e2822e304c36?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fG9mZmljZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
            name: 'MEDIA',
          },
          {
            id: uuid(),
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: phoneLabel,
                description: phone,
              },
              {
                id: uuid(),
                name: emailLabel,
                description: email,
              },
            ] as ContractSectionContract[],
            name: 'CONTRACT',
          },
          {
            id: uuid(),
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: 'First Name',
                placeholder: 'First Name',
                type: 'text',
                error: 'First Name is required',
              },
              {
                id: uuid(),
                name: 'Last Name',
                placeholder: 'Last Name',
                type: 'text',
                error: 'Last Name is required',
              },
              {
                id: uuid(),
                name: 'Email',
                placeholder: 'example@gmail.com',
                type: 'email',
                error: 'email is required',
              },
              {
                id: uuid(),
                name: 'Company',
                placeholder: 'Company',
                type: 'text',
                error: 'company is required',
              },
              {
                id: uuid(),
                name: 'Message',
                placeholder: 'Message',
                type: 'textarea',
                error: 'message is required',
              },
            ] as InputsContactUSSection[],
            name: 'INPUTS',
          },
        ],
        tags: tags[variant.id as ContactUsVariants],
        variant: variant.id as ContactUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_11') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'BOOK A CALL' }] }]
      const subtitle: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Our experience showcasing unicorn investors and influencers means that we know exactly what it takes to optimize your website for maximum ROI',
            },
          ],
        },
      ]

      const data: Section<ContactUsVariants, ContactUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'CONTACT_US',
        updatedAt: '',
        variables: [
          {
            id: uuid(),
            category: 'TEXT',
            data: title,
            name: 'TITLE',
          },
          {
            id: uuid(),
            category: 'TEXT',
            data: subtitle,
            name: 'SUBTITLE',
          },

          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://images.unsplash.com/photo-1580086319619-3ed498161c77?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw3fHxneW1zfGVufDB8fHx8MTY3ODAxNzk1OQ&ixlib=rb-4.0.3&q=80&w=1080',
            name: 'MEDIA',
          },
          {
            id: uuid(),
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: 'First Name',
                placeholder: 'First Name',
                type: 'text',
                error: 'First Name is required',
              },
              {
                id: uuid(),
                name: 'Last Name',
                placeholder: 'Last Name',
                type: 'text',
                error: 'Last Name is required',
              },
              {
                id: uuid(),
                name: 'Email',
                placeholder: 'example@gmail.com',
                type: 'email',
                error: 'Email is required',
              },
              {
                id: uuid(),
                name: 'Number',
                placeholder: '+20 123 456',
                type: 'number',
                error: 'number is required',
              },
            ] as InputsContactUSSection[],
            name: 'INPUTS',
          },
        ],
        tags: tags[variant.id as ContactUsVariants],
        variant: variant.id as ContactUsVariants,
        styleName: variant.name,
        designedBy: 'Abrar Muthana',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_12') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'Our leasing agents are available for virtual and in-person visits.' }],
        },
      ]

      const data: Section<ContactUsVariants, ContactUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'CONTACT_US',
        updatedAt: '',
        variables: [
          {
            id: uuid(),
            category: 'TEXT',
            data: title,
            name: 'TITLE',
          },
          {
            id: uuid(),
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: 'First Name',
                placeholder: 'First Name',
                type: 'text',
                error: 'First Name is required',
              },
              {
                id: uuid(),
                name: 'Last Name',
                placeholder: 'Last Name',
                type: 'text',
                error: 'Last Name is required',
              },
              {
                id: uuid(),
                name: 'Email',
                placeholder: 'example@gmial.com',
                type: 'email',
                error: 'email is required',
              },
              {
                id: uuid(),
                name: 'Message',
                placeholder: 'message',
                type: 'textarea',
                error: 'message is required',
              },
            ] as InputsContactUSSection[],
            name: 'INPUTS',
          },
        ],
        tags: tags[variant.id as ContactUsVariants],
        variant: variant.id as ContactUsVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_13') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'Visit US' }],
        },
      ]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'Cat Ipsum Dolor Sit Amet, Velit, And Eos. Do Pariatur Lorem. Magna Ad Yet.' }],
        },
      ]
      const phoneLabel: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Phone' }] }]
      const phone: Descendant[] = [{ type: 'paragraph', children: [{ text: '(555) 555-5555' }] }]
      const emailLabel: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Email' }] }]
      const email: Descendant[] = [{ type: 'paragraph', children: [{ text: 'example@gmail.com' }] }]

      const hoursLabel: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Hours' }] }]
      const hours: Descendant[] = [{ type: 'paragraph', children: [{ text: 'monday-friday' }] }]

      const data: Section<ContactUsVariants, ContactUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'CONTACT_US',
        updatedAt: '',
        variables: [
          {
            id: uuid(),
            category: 'TEXT',
            data: title,
            name: 'TITLE',
          },
          {
            id: uuid(),
            category: 'TEXT',
            data: paragraph,
            name: 'PARAGRAPH',
          },
          {
            id: uuid(),
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: hoursLabel,
                description: hours,
              },
              {
                id: uuid(),
                name: phoneLabel,
                description: phone,
              },
              {
                id: uuid(),
                name: emailLabel,
                description: email,
              },
            ] as ContractSectionContract[],
            name: 'CONTRACT',
          },
        ],
        tags: tags[variant.id as ContactUsVariants],
        variant: variant.id as ContactUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_14') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'LETS COLLABORATE' }] }]
      const subtitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Subtitle' }] }]

      const data: Section<ContactUsVariants, ContactUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'CONTACT_US',
        updatedAt: '',
        variables: [
          {
            id: uuid(),
            category: 'TEXT',
            data: title,
            name: 'TITLE',
          },

          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://images.unsplash.com/photo-1518655048521-f130df041f66?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
            name: 'MEDIA',
          },
        ],
        tags: tags[variant.id as ContactUsVariants],
        variant: variant.id as ContactUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_15') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'We will be happy if u send us a message :)' }],
        },
      ]
      const subtitle: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'Visit US' }],
        },
      ]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Contact us today to learn more about how we can help you create a captivating, high-converting website.',
            },
          ],
        },
      ]

      const data: Section<ContactUsVariants, ContactUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'CONTACT_US',
        updatedAt: '',
        variables: [
          {
            id: uuid(),
            category: 'TEXT',
            data: title,
            name: 'TITLE',
          },
          {
            id: uuid(),
            category: 'TEXT',
            data: subtitle,
            name: 'SUBTITLE',
          },
          {
            id: uuid(),
            category: 'TEXT',
            data: paragraph,
            name: 'PARAGRAPH',
          },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-57eb12ec-0f27-4a3a-b83f-810a97949ef0',
            name: 'MEDIA',
          },
          {
            id: uuid(),
            name: 'INPUTS',
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: 'Email',
                type: 'email',
                error: 'email is required',
                placeholder: 'example@gmail.com',
              },
            ] as InputsContactUSSection[],
          },
        ],
        tags: tags[variant.id as ContactUsVariants],
        variant: variant.id as ContactUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_17') {
      const title: Descendant[] = [
        { type: 'paragraph', children: [{ text: 'Want to learn more ? Get in touch with us at' }] },
      ]
      const subtitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'example@gmail.com' }] }]

      const data: Section<ContactUsVariants, ContactUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'CONTACT_US',
        updatedAt: '',
        variables: [
          {
            id: uuid(),
            category: 'TEXT',
            data: title,
            name: 'TITLE',
          },
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },

          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
        ],
        tags: tags[variant.id as ContactUsVariants],
        variant: variant.id as ContactUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_16') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Put website friction out of business' }] }]
      const subtitle: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Chatbots are just the beginning. Drift is on a mission to simplife buying online one conversation at a time',
            },
          ],
        },
      ]

      const data: Section<ContactUsVariants, ContactUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'CONTACT_US',
        updatedAt: '',
        variables: [
          {
            id: uuid(),
            category: 'TEXT',
            data: title,
            name: 'TITLE',
          },
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },

          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          {
            id: uuid(),
            name: 'INPUTS',
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: 'Email',
                type: 'email',
                error: 'email is required',
                placeholder: 'example@gmail.com',
              },
            ] as InputsContactUSSection[],
          },
        ],
        tags: tags[variant.id as ContactUsVariants],
        variant: variant.id as ContactUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else {
      const subtitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'SubTitle' }] }]
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Visit Us' }] }]

      const paragraph: Descendant[] = [{ type: 'paragraph', children: [{ text: 'paragraph' }] }]

      const phoneLabel: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Phone' }] }]
      const phone: Descendant[] = [{ type: 'paragraph', children: [{ text: '(555) 555-5555' }] }]
      const emailLabel: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Email' }] }]
      const email: Descendant[] = [{ type: 'paragraph', children: [{ text: 'example@gmail.com' }] }]
      const hoursLabel: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Hours' }] }]
      const hours: Descendant[] = [{ type: 'paragraph', children: [{ text: 'monday-friday' }] }]

      const data: Section<ContactUsVariants, ContactUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'CONTACT_US',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
            name: 'MEDIA',
          },
          {
            id: uuid(),
            category: 'TEXT',
            data: {
              address: '1600 Amphitheatre Parkway, Mountain View, CA',
              position: { lat: 37.4224764, lng: -122.0842499 },
            } as ContactUsMap,
            name: 'MAP',
          },
          {
            id: uuid(),
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: 'First Name',
                placeholder: 'First Name',
                type: 'text',
                error: 'First Name is required',
              },
              {
                id: uuid(),
                name: 'Last Name',
                placeholder: 'Last Name',
                type: 'text',
                error: 'Last Name is required',
              },
              {
                id: uuid(),
                name: 'Email',
                placeholder: 'example@gmail.com',
                type: 'email',
                error: 'email is required',
              },
            ] as InputsContactUSSection[],
            name: 'INPUTS',
          },
          {
            id: uuid(),
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: hoursLabel,
                description: hours,
              },
              {
                id: uuid(),
                name: phoneLabel,
                description: phone,
              },
              {
                id: uuid(),
                name: emailLabel,
                description: email,
              },
            ] as ContractSectionContract[],
            name: 'CONTRACT',
          },
        ],
        tags: tags[variant.id as ContactUsVariants],
        variant: variant.id as ContactUsVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    }
  })

  return sections
}
