import SectionSlate from '../../../common/SectionSlatePreview'
import { QuoteSection as QuoteSectionType } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

interface VariantOneProps {
  section: QuoteSectionType
}

export default function VariantNine({ section }: VariantOneProps) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'DESCRIPTION')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data
  const buttonOne = section.variables.find(variable => variable.name === 'BUTTON_1')?.data
  const buttonTwo = section.variables.find(variable => variable.name === 'BUTTON_2')?.data

  return (
    <div
      className="md:py-16 py-4 bg-base-100"
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="flex items-center md:justify-start md:gap-6 flex-col lg:flex-row md:px-12 px-4 ">
        <div className="w-full mb-2 md:mt-0">
          <img src={media} className="w-full lg:shadow-2xl object-cover md:max-h-[600px] max-h-[300px]" />
        </div>
        <div className="w-full text-center">
          <h1 className="md:text-2xl font-semibold font-head text-[#B48B7E]">
            <SectionSlate initialValue={subTitle} previewMode />
          </h1>
          <h1 className="md:text-4xl font-sub uppercase font-bold">
            <SectionSlate initialValue={title} previewMode />
          </h1>

          <p className="md:mt-3 text-sm">
            <SectionSlate initialValue={paragraph} previewMode />
          </p>
          {buttonOne && (
            <div className="flex justify-center gap-5 flex-wrap pt-4">
              <a className="uppercase font-sub border-b border-[#B48B7E] text-2xl" href={buttonOne.to}>
                {buttonOne.label}➧
              </a>
              {buttonTwo && (
                <a className="uppercase font-sub border-b border-[#B48B7E] text-2xl" href={buttonTwo.to}>
                  {buttonTwo.label}➧
                </a>
              )}
            </div>
          )}
          <div className="my-custom-pagination-div text-center mt-10" />
        </div>
      </div>
    </div>
  )
}
