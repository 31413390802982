import { useState } from 'react'
import { BsFillSuitHeartFill } from 'react-icons/bs'
import FontLoader from '../FontLoader'
import { MenuVariantProps, NewMenuLinkType } from '../menu'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../utils'

export default function MenuVariantEighteen({ menu, website }: MenuVariantProps) {
  return (
    <div>
      <FontLoader settings={menu?.settings?.fontFamily} />
      <DesktopNavbar menu={menu} website={website} />
      <MobileNavbar menu={menu} website={website} />
    </div>
  )
}

const DesktopNavbar = ({ menu }: MenuVariantProps) => {
  return (
    <div
      className="md:block hidden"
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '#4A4860' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className="border-b-4 border-[#323042] flex items-center justify-center gap-x-4 py-6">
        {menu.links.map((item, index) => {
          if (index === 2) {
            return (
              <div key={index} className="flex items-center py-2">
                <a
                  href={item.url}
                  target={item.type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                  className="text-xl  text-white hover:bg-[#FFC4EB] hover:text-[#303034] py-6 px-6 transition-all duration-500 ease-in-out tracking-widest uppercase"
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  {item.name}
                </a>
                <p className="hover:scale-125 transition-all duration-300 ml-4">
                  <BsFillSuitHeartFill
                    style={{
                      color: getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#FFC4EB' }).color,
                    }}
                    size={58}
                    className="hover:text-red-600  transition-all duration-300"
                  />
                </p>
              </div>
            )
          } else {
            return (
              <div key={index}>
                <a
                  href={item.url}
                  target={item.type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                  className="text-xl text-white hover:bg-[#FFC4EB] hover:text-[#303034] py-6 px-6 transition-all duration-500 ease-in-out tracking-widest uppercase"
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  {item.name}
                </a>
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}

const MobileNavbar = ({ menu }: MenuVariantProps) => {
  const [isOpen, setISOpen] = useState(false)
  return (
    <div
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '#4A4860' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className="border-b-4 border-[#323042] py-4 md:hidden">
        <div className="flex items-center justify-center">
          <button onClick={() => setISOpen(!isOpen)} className="hover:scale-125 transition-all duration-300">
            <BsFillSuitHeartFill
              size={28}
              className="hover:text-red-600 transition-all duration-300"
              color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#FFC4EB' }).color}
            />
          </button>
        </div>
        {isOpen && (
          <div
            className="list-none items-center justify-center py-4 flex flex-col gap-y-2"
            style={{
              ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '#4A4860' }),
              ...getSpacing(menu?.settings?.spacing),
            }}
          >
            {menu.links.map((item, index) => {
              return (
                <p
                  key={index}
                  className="hover:text-[#4A4860]"
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  <a
                    href={item.url}
                    target={item.type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                    className="tracking-widest uppercase hover:bg-[#FFC4EB] hover:text-[#4A4860] hover:border-b-4 text-sm border-[#bc88aa] w-full px-20 py-1 "
                  >
                    {item.name}
                  </a>
                </p>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
