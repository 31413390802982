import { useState } from 'react'
import { IoClose, IoMenu } from 'react-icons/io5'
import FontLoader from '../FontLoader'
import { MenuVariantProps, NewMenuLinkType } from '../menu'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../utils'

export default function MenuVariantOne({ menu, website }: MenuVariantProps) {
  return (
    <>
      <FontLoader settings={menu?.settings?.fontFamily} />
      <DesktopNavbar menu={menu} website={website} />
      <MobileNavbar menu={menu} website={website} />
    </>
  )
}

const MobileNavbar = ({ menu, website }: MenuVariantProps) => {
  const [show, setShow] = useState(false)

  return (
    <div className="block lg:hidden">
      <div
        className="flex py-4 items-center justify-between border-b border-[#F2ECE9] px-4"
        style={{
          ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'white' }),
          ...getSpacing(menu?.settings?.spacing),
        }}
      >
        <a href="/">
          {website?.config?.logo ? (
            <img
              className="cursor-pointer rounded-full w-[30px] h-[30px] "
              src={website?.config?.logo}
              alt={website?.name}
            />
          ) : (
            <p
              className="uppercase font-medium"
              style={{
                ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
              }}
            >
              {website?.name}
            </p>
          )}
        </a>

        <div className="flex items-center">
          {show ? (
            <IoClose
              className="cursor-pointer"
              color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color}
              onClick={() => setShow(false)}
              size={28}
            />
          ) : (
            <IoMenu
              className="cursor-pointer"
              color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color}
              onClick={() => setShow(true)}
              size={28}
            />
          )}
        </div>
      </div>

      {show && (
        <ul
          className="flex flex-col list-none"
          style={{
            ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'white' }),
            ...getSpacing(menu?.settings?.spacing),
          }}
        >
          {menu.links.map(({ name, url, id, type }) => (
            <li key={id} className="border-b border-[#F2ECE9] w-full">
              <a
                href={url}
                target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                className="text-base py-4 block"
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#585858' }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                }}
              >
                {name}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

const DesktopNavbar = ({ menu, website }: MenuVariantProps) => {
  return (
    <div
      className="hidden lg:block"
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'white' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className="px-12 mx-auto py-6">
        <div className="flex items-center justify-between">
          <div className="w-[25%]">
            <a href="/">
              {website?.config?.logo ? (
                <img
                  className="cursor-pointer rounded-full w-[80px] h-[80px]"
                  src={website?.config?.logo}
                  alt={website?.name}
                />
              ) : (
                <p
                  className="text-xl font-medium"
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  {website?.name}
                </p>
              )}
            </a>
          </div>

          <div className="flex-1" />

          <ul className="flex justify-end gap-x-16 list-none">
            {menu.links.map(({ id, name, type, url }) => (
              <li key={id} className="relative">
                <a
                  href={url}
                  target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                  className="text-xl"
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#585858' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  {name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
