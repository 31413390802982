import { AboutUsSection as AboutUsSectionType } from '../../types'
import SectionSlate from '../../../common/SectionSlatePreview'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

interface VariantThreeProps {
  section: AboutUsSectionType
}

export default function VariantThree({ section }: VariantThreeProps) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data

  return (
    <div
      className="flex bg-cover bg-no-repeat bg-center"
      style={{
        ...generateSectionBackground({
          defaultColor: '#000',
          backgroundSettings: section?.settings?.color?.background,
          image: media ?? null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="container mx-auto py-10 px-6 md:px-40 md:py-20 text-center font-light">
        <div className="bg-[#EFEFEF] py-8 px-6 md:px-14 md:py-14 md:space-y-4 space-y-2">
          {title && (
            <div>
              <p className="text-[26px] md:text-4xl font-bold ">{<SectionSlate initialValue={title} previewMode />}</p>
            </div>
          )}

          {subTitle && (
            <div>
              <p className="md:text-[24px] text-[17px] font-semibold">
                {<SectionSlate initialValue={subTitle} previewMode />}
              </p>
            </div>
          )}

          {paragraph && (
            <div>
              <p className="leading-7 md:text-[18px] text-[14px]">
                {<SectionSlate initialValue={paragraph} previewMode />}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
