import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { UserContext } from 'src/components/context/UserContext'
import {
  createConversation,
  createMessage,
  getConversation,
  getMessagesByConversation,
  markMessageAsRead,
  messageSubscriptions,
  threadSubscriptions,
} from 'src/services/messenger.service'
import { getUserById } from 'src/services/user.service'
import { v4 as uuidv4 } from 'uuid'
import ConvoChatBox from './ConvoChatBox'
import ConvoHeader from './ConvoHeader'
import ConvoMessages from './ConvoMessages'

interface ActiveConvo {
  chatmate: any
  convo: any
}

export interface IMessageItem {
  id: string
  senderID: string
  content: string
  type: 'IMAGE' | 'TEXT'
  createdAt: string
  updatedAt?: string
  // client: local sending status
  sendStatus?: 'sending' | 'error'
  // client: tricky - from updatedAt
  readAt?: string

  read?: boolean
  deleted?: boolean
}

function useActiveConvo(chatmateId?: string) {
  const [data, setData] = useState<ActiveConvo>({
    chatmate: null,
    convo: null,
  })

  const [messages, setMessages] = useState([] as IMessageItem[])
  const [loadingMessages, setLoadingMessages] = useState(false)
  const { user: currentUser } = useContext(UserContext)
  const userId = currentUser?.id
  const convId = data.convo?.convID

  useEffect(() => {
    if (!chatmateId) {
      setData({ chatmate: null, convo: null })
      return
    }

    async function query() {
      const [chatmate, convo] = await Promise.all([
        getUserById(chatmateId ?? ''),
        getConversation({
          to: chatmateId as string,
        }),
      ])

      setData({ chatmate, convo })
      setLoadingMessages(!!convo?.convID)
      setMessages([])

      if (convo?.convID) {
        getMessagesByConversation(convo.convID)
          .then(_data => setMessages((_data?.items?.filter(x => x !== null) as any) || []))
          .catch(err => console.error('@convo.section::useEffect[convId]::error', err))
          .finally(() => setLoadingMessages(false))
      }
    }

    query()
  }, [chatmateId, userId])

  const sendMessage = useCallback(
    async (message: any) => {
      if (!chatmateId) {
        alert('Select a message recipient first')
        return
      }

      const length = message?.content?.trim()?.length
      if (!length || !userId) return

      const messageItem: IMessageItem = {
        // Temporary id for updating sending below
        id: `sending-${uuidv4()}`,
        senderID: userId,
        content: message.content,
        type: message.contentType,
        createdAt: new Date().toISOString(),
        sendStatus: 'sending',
      }
      setMessages(_messages => [messageItem, ..._messages])

      const updateMessageState = (savedMessage: any) => {
        setMessages(_messages => {
          const item = _messages.find(x => x.id === messageItem.id)

          // `item` should not be null here
          if (item) {
            const newItem: IMessageItem = savedMessage || { ...item, sendStatus: 'error' }

            _messages.splice(_messages.indexOf(item), 1, newItem)
          }
          return [..._messages]
        })
      }

      try {
        let newConvo: any

        if (!convId) {
          newConvo = await createConversation(chatmateId)
          setData(_data => ({ ..._data, convo: newConvo }))
        }

        const threadID = newConvo?.convID || convId

        const saved = await createMessage({
          threadID,
          senderID: null,
          receiverID: chatmateId,
          message: message.content,
          messageType: message.contentType,
        })

        updateMessageState(saved)
      } catch (err) {
        updateMessageState(null)
        console.error('@convo.section::handleSend::error', err)
      }
    },
    [chatmateId, userId, convId],
  )

  // TODO: optimize subscriptions channel
  useEffect(() => {
    if (!convId) return

    const updateMessage = (msg: any) => {
      // if not relevant to this convo
      if (convId !== msg.conversationID) return

      // if new message is created and is sender
      if (userId === msg?.senderID && msg.updatedAt === msg.createdAt) return

      setMessages(_messages => {
        const index = _messages.findIndex((m: any) => m.id === msg.id)
        const newMsgs = [..._messages]

        if (index === -1) {
          newMsgs.unshift(msg)
        } else {
          newMsgs[index] = { ...msg }
        }
        return newMsgs
      })
    }

    const { onMessageCreate, onMessageUpdate } = messageSubscriptions()
    const subscriptions = [onMessageCreate.subscribe(updateMessage), onMessageUpdate.subscribe(updateMessage)]

    // eslint-disable-next-line consistent-return
    return () => subscriptions.forEach(x => x.unsubscribe())
  }, [userId, convId])

  useEffect(() => {
    const { onThreadCreate, onThreadUpdate } = threadSubscriptions()
    const subscription = [
      !convId &&
        onThreadCreate.subscribe(item => {
          if (!item.id) return

          if (item.userID === userId && item.chatmateID === chatmateId) {
            setData(_data => ({ ..._data, convo: item }))

            setLoadingMessages(true)
            getMessagesByConversation(item.convID as string)
              .then(_data => setMessages((_data?.items?.filter(x => x !== null) as any) || []))
              .catch(err => console.error('@convo.section::useEffect[convId]::error', err))
              .finally(() => setLoadingMessages(false))
          }
        }),
      convId &&
        onThreadUpdate.subscribe(item => {
          if (item?.convID === convId && item?.userID === userId) {
            setData(_data => ({ ..._data, convo: item }))
          }
        }),
    ].filter(x => !!x)

    return () => subscription.forEach(x => x.unsubscribe())
  }, [chatmateId, userId, convId])

  // TODO: more clear and optimized approach
  // eslint-disable-next-line no-underscore-dangle
  const _messages = useMemo(() => {
    let readAt: string | undefined
    return messages.map(item => {
      // Don't mark system generated message read date
      if (item.senderID !== chatmateId && item.senderID !== userId) {
        return { ...item }
      }

      if (item.senderID === chatmateId) {
        readAt = item.createdAt
        return { ...item }
      }

      if ((item.updatedAt || '') > item.createdAt) {
        readAt = item.updatedAt
      }

      return { ...item, readAt }
    })
  }, [userId, chatmateId, messages])

  // When data are stale - not up-to-date with params - return null
  // Instead of setState hooks, this return immediate result - NOT stale one
  if (!chatmateId || chatmateId !== data.chatmate?.id) {
    return {
      chatmate: null,
      post: null,
      convo: null,
      messages: [] as IMessageItem[],
      loadingMessages: false,
      sendMessage: null,
    }
  }

  return { ...data, messages: _messages, loadingMessages, sendMessage }
}

export default function ConvoSection(props: { chatmateId?: string; onMessageSend?: (chatmateId: string) => void }) {
  const { chatmateId } = props
  const { chatmate, messages, loadingMessages, sendMessage } = useActiveConvo(chatmateId)
  const { user } = useContext(UserContext)

  const latestMessage = messages[0]
  useEffect(() => {
    if (!latestMessage || user?.id === latestMessage.senderID || latestMessage.read) return
    markMessageAsRead(latestMessage.id).catch(err =>
      console.error('@convo.section::useEffect[latestMsg, userId]::error', err),
    )
  }, [user?.id, latestMessage])

  const chatmates = user && chatmate ? [user, chatmate] : undefined
  // TODO: hadnle convoDeleted
  // const convoDeleted = convo?.status === ConvoStatus.ARCHIVED;

  const handleOnSend = (data: any) => {
    const { onMessageSend } = props
    sendMessage?.(data)
    onMessageSend?.(chatmateId as string)
  }

  return (
    <div className="flex flex-col h-full bg-[#EFEFEF]">
      <ConvoHeader chatmates={chatmates} />

      <div className="flex-1 overflow-auto">
        {chatmate && (
          <ConvoMessages messages={messages} loading={loadingMessages} chatmate={chatmate} currentUser={user} />
        )}
      </div>

      {chatmate && <ConvoChatBox onSend={handleOnSend} chatmateId={chatmateId} />}
    </div>
  )
}
