import { useEffect, useState } from 'react'
import { Loader } from 'src/components/loader'
import { PageCatergories, getAllPages } from 'src/services/page.service'

import ModalDialogV2 from '../ModalDialogV2'

interface IChoosePageTemplateProps {
  setSelectedTemplate: React.Dispatch<React.SetStateAction<{ category: PageCatergories; id: string } | null>>
  loading?: boolean
}

const ChoosePageTemplate = ({ setSelectedTemplate, loading }: IChoosePageTemplateProps) => {
  const [pages, setPages] = useState<ReturnType<typeof getAllPages>>([])
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const allPages = getAllPages()
    setPages(allPages)
  }, [])

  return (
    <div>
      <Loader show={loading || false} />
      <div className="flex justify-center">
        <button
          onClick={() => setOpen(true)}
          type="button"
          className="border-[4px] border-[#E90570] rounded-[9px] text-[20px] py-[13px] px-[50px] cursor-pointer mt-6 bg-white dark:bg-black dark:text-white"
        >
          create page
        </button>
      </div>
      <ModalDialogV2 open={open} onBack={() => setOpen(false)}>
        <div className="px-[18px] pb-6">
          <h1 className="text-2xl">Choose a page template</h1>
          <div className="mt-3 space-y-4">
            {pages.map(page => (
              <button
                type="button"
                key={page.id}
                onClick={() => {
                  setSelectedTemplate?.({ category: page.category, id: page.id })
                }}
                className="h-[250px] w-full"
              >
                <div
                  style={{
                    backgroundImage: `url(${page.image})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100%',
                    backgroundOrigin: 'padding-box',
                  }}
                  className="w-full h-full border rounded-lg relative z-0 inset-0 hover:shadow-lg transition-all duration-300 "
                >
                  <div className="absolute rounded-lg rounded-t-none bg-[#e0dfdf] md:font-roboto transition-all duration-500 text-sm lg:text-xl w-full bottom-0 py-1 md:p-0 md:h-[50px] z-30 flex items-center justify-center">
                    {page.name}
                  </div>
                </div>
                {/* <img src={page.image} alt="" className="w-full h-auto" /> */}
              </button>
            ))}
          </div>
        </div>
      </ModalDialogV2>
    </div>
  )
}

export default ChoosePageTemplate
