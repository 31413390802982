import Icon from './icons'
import { IconPropsT } from './types'

function Grid(props: IconPropsT) {
  const { title = 'Grid', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 38 24" size={size} title={title} {...otherProps}>
      <rect width="18" height="11" rx="3" />
      <rect y="13" width="18" height="11" rx="3" />
      <rect x="20" width="18" height="11" rx="3" />
      <rect x="20" y="13" width="18" height="11" rx="3" />
    </Icon>
  )
}

export default Grid
