import SectionSlate from '../../../common/SectionSlatePreview'
import { AboutUsSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

function VariantEleven({ section }: { section: AboutUsSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  return (
    <>
      <div
        className="px-[20px] md:px-[42px] lg:px-[62px] py-12 lg:py-16 grid md:grid-cols-3 pb-20 space-y-3 md:space-y-0"
        style={{
          ...generateSectionBackground({
            defaultColor: 'white',
            backgroundSettings: section?.settings?.color?.background,
            image: null,
          }),
          ...generateSectionSpacing(section?.settings?.spacing),
        }}
      >
        <div>
          <h2 className="text-[18px] lg:px-6 text-[#262525] opacity-70 font-neuton">
            <SectionSlate initialValue={title} previewMode />
          </h2>
        </div>
        <div className="space-y-6">
          <p className="text-[20px] md:text-[33px] lg:text-[52px] text-[#262525] font-neuton leading-none first-letter:ml-12">
            <SectionSlate initialValue={paragraph} previewMode />
          </p>
        </div>
      </div>
    </>
  )
}

export default VariantEleven
