import { useContext, useEffect, useState } from 'react'
import { Website } from 'src/API'
import { WebsiteContext } from 'src/components/context/WebsiteContext'
import { ReactComponent as PencilIcon } from 'src/components/icons/Pencil.svg'
import { Loader } from 'src/components/loader'
import ModalDialogV2 from 'src/components/new/ModalDialogV2'
import EditPlus from 'src/icons/EditPlus'
import NewLink from 'src/icons/Link'
import Settings from 'src/icons/Settings'
import { getWebsiteByID, saveWebsite } from 'src/lib/services/website.service'
import useWebsite from 'src/store/website'
import { NewFooter, NewFooterCategory } from 'src/_polly/components/src/footer/footer'
import AdvancedMenuFooter from '../../advanced/AdvancedMenuFooter'
import WebsiteSettings from '../../WebsiteSettings'
import { getWebsiteFooter } from './footer'
import FooterSimpleField, { FooterSimpleFieldProps } from './FooterSimpleField'
import FooterSocial from './FooterSocial'
import FooterSubtitle from './FooterSubtitle'
import NewSubtitle from './NewSubtitle'

function ListItem({
  title,
  subtitle,
  onEditClick,
  hideLeftIcon = false,
}: {
  title: string
  subtitle?: string
  onEditClick?: () => void
  hideLeftIcon?: boolean
}) {
  return (
    <div className="h-[82px] border-b-[1px] border-[#D9D9D9] flex justify-between px-[22px] items-center">
      <div className="flex items-center gap-[22px]">
        {!hideLeftIcon && (
          <NewLink height={10} className="stroke-[#8D8D8D] fill-[#8D8D8D] dark:fill-[#D9D9D9] dark:stroke-[#D9D9D9]" />
        )}
        <div>
          <p className="text-[19px] text-left dark:text-white">{title}</p>
          {subtitle && <p className="text-[13px] dark:text-white">{subtitle}</p>}
        </div>
      </div>
      <button onClick={onEditClick} type="button">
        <EditPlus size={50} fill="none" className="stroke-[#8D8D8D] fill-white dark:fill-black dark:stroke-white" />
      </button>
    </div>
  )
}

export default function FooterEditor({ onBack }: { onBack?: () => void }) {
  const store = useWebsite(state => state)
  const websiteContext = useContext(WebsiteContext)

  const [editCategory, setEditCategory] = useState<NewFooterCategory | null>(null)
  const [footer, setFooter] = useState<NewFooter | null>(null)
  const [loading, setLoading] = useState(true)
  const [showNewSubtitle, setShowNewSubtitle] = useState(false)
  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const [simpleField, setSimpleField] = useState<Omit<FooterSimpleFieldProps, 'onSave'> | null>(null)
  const [website, setWebsite] = useState<Website | null | undefined>(null)
  const [showSocial, setShowSocial] = useState(false)

  const getFooter = async () => {
    setLoading(true)
    const siteFooter = await getWebsiteFooter(websiteContext.websiteID)
    if (siteFooter) store.updateNewFooter(siteFooter)
    setFooter(siteFooter)
    setLoading(false)
  }

  const getWebsite = () => {
    getWebsiteByID(websiteContext.websiteID).then(setWebsite)
  }

  const updateConfig = async (field: string, value: any) => {
    setWebsite(
      website ? { ...website, config: JSON.stringify({ ...JSON.parse(website.config), [field]: value }) } : website,
    )
  }

  const getDefaultConfigValue = (field: string) => (website ? JSON.parse(website.config)[field] ?? '' : '')

  const handleDeleteSimpleField = () => {
    updateConfig(simpleField?.type || '', '')
    setSimpleField(null)
  }
  useEffect(() => {
    saveWebsite({ id: websiteContext.websiteID, config: website?.config }).then(() => setLoading(false))
  }, [website])

  useEffect(() => {
    getFooter()
    getWebsite()
  }, [])

  return (
    <div className="px-4 border-t-[2px] border-black dark:border-white">
      <Loader show={loading} />
      <h1 className="absolute top-[21px] right-[30px] text-[20px]">Footer Navigation</h1>
      <div className="hidden items-center h-[46px] px-[5px] mt-[2px] border-t border-b border-black dark:border-[#D6D6D6]">
        <h2 className="w-full font-bold text-[15px] dark:text-white">Footer</h2>
        <div className="w-full flex items-center gap-[28px]">
          <button type="button">
            <PencilIcon />
          </button>
          <button type="button">
            <NewLink size={24} className="stroke-black fill-black dark:fill-white dark:stroke-white" />
          </button>
        </div>
      </div>
      <div className="h-[80px] w-full border-b-[1px] border-[#D9D9D9] flex justify-between px-[20px] items-center">
        <p className="text-[19px] text-left dark:text-[#F3F3F3]">Site Name + Logo</p>
        <button type="button" onClick={() => setShowSettingsModal(true)} className="pr-2">
          <Settings size={27} className="text-[#8D8D8D] dark:text-white" />
        </button>
      </div>
      <div>
        <ListItem
          title="Phone"
          hideLeftIcon
          onEditClick={() => setSimpleField({ type: 'phone', defaultValue: getDefaultConfigValue('phone') })}
        />
        <ListItem
          title="Address"
          hideLeftIcon
          onEditClick={() => setSimpleField({ type: 'address', defaultValue: getDefaultConfigValue('address') })}
        />
        <ListItem
          title="Copyright"
          hideLeftIcon
          onEditClick={() => setSimpleField({ type: 'copyright', defaultValue: getDefaultConfigValue('copyright') })}
        />
        <ListItem
          title="Email"
          onEditClick={() => setSimpleField({ type: 'email', defaultValue: getDefaultConfigValue('email') })}
        />
        <ListItem title="Social" onEditClick={() => setShowSocial(true)} />
      </div>

      <div>
        <div>
          {footer?.categories.map(category => (
            <ListItem
              key={category.id}
              title={category.name}
              subtitle={`${category.links.length} Connected Link`}
              onEditClick={() => setEditCategory(category)}
            />
          ))}
        </div>
        <div className="h-[76px] w-full border-b-[1px] border-[#D9D9D9] flex justify-between px-[20px] items-center">
          <div className="flex items-center gap-[18px]">
            <NewLink
              height={10}
              className="stroke-[#8D8D8D] fill-[#8D8D8D] dark:fill-[#D9D9D9] dark:stroke-[#D9D9D9]"
            />
            <p className="text-[19px] text-left dark:text-white">Create New Subtitle</p>
          </div>
          <button type="button" onClick={() => setShowNewSubtitle(true)}>
            <EditPlus
              size={50}
              fill="none"
              className="fill-white dark:fill-black stroke-[#E90570] dark:stroke-[#E90570]"
            />
          </button>
        </div>
      </div>

      <ModalDialogV2
        open={editCategory !== null}
        onBack={() => {
          setEditCategory(null)
          getFooter()
        }}
      >
        {editCategory && (
          <FooterSubtitle
            category={editCategory}
            onSaveChanges={() => {
              setEditCategory(null)
              getFooter()
            }}
          />
        )}
      </ModalDialogV2>

      <ModalDialogV2 open={showNewSubtitle} onBack={() => setShowNewSubtitle(false)}>
        <NewSubtitle
          onSave={() => {
            setShowNewSubtitle(false)
            getFooter()
          }}
        />
      </ModalDialogV2>

      <ModalDialogV2 open={simpleField !== null} onBack={() => setSimpleField(null)}>
        {simpleField && (
          <FooterSimpleField
            {...{ ...simpleField }}
            onSave={(type, value) => {
              updateConfig(type, value)
              setSimpleField(null)
            }}
            onDelete={handleDeleteSimpleField}
          />
        )}
      </ModalDialogV2>

      <ModalDialogV2 open={showSocial} onBack={() => setShowSocial(false)}>
        <FooterSocial
          defaultValue={website ? JSON.parse(website.config)?.social ?? null : null}
          onSave={value => {
            updateConfig('social', value)
            setShowSocial(false)
          }}
          onDelete={() => {
            updateConfig('social', '')
            setShowSocial(false)
          }}
        />
      </ModalDialogV2>

      <WebsiteSettings
        websiteLogo={JSON.parse(website?.config ?? '{}')?.logo}
        open={showSettingsModal}
        fetchWebsite={getFooter}
        onClose={() => {
          setShowSettingsModal(false)
          getFooter()
        }}
        id={website?.id}
        onMenuBack={onBack}
      />

      <AdvancedMenuFooter component="footer" mode="button" footer={store.website.footer} />
    </div>
  )
}
