import { motion, AnimateSharedLayout } from 'framer-motion'
import { useState } from 'react'
import { IoMenuSharp } from 'react-icons/io5'
import FontLoader from '../FontLoader'
import { MenuVariantProps, NewMenuLinkType } from '../menu'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../utils'

export default function MenuVariantNineteen({ menu, website }: MenuVariantProps) {
  return (
    <div>
      <FontLoader settings={menu?.settings?.fontFamily} />
      <DesktopNavbar menu={menu} website={website} />
      <MobileNavbar menu={menu} website={website} />
    </div>
  )
}

const DesktopNavbar = ({ menu }: MenuVariantProps) => {
  const [hovered, setHovered] = useState(undefined)
  const [selected, setSelected] = useState(undefined)
  const [preSelect, setPreSelect] = useState(undefined)
  return (
    <AnimateSharedLayout>
      <div
        style={{
          ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'black' }),
          ...getSpacing(menu?.settings?.spacing),
        }}
      >
        <div
          onMouseLeave={() => {
            setHovered(selected)
          }}
          className="text-white py-2 pt-8  flex-col items-center relative hidden md:flex"
        >
          <div className="flex flex-row justify-center items-center gap-x-10">
            {menu.links.map((item, i: any) => (
              <p
                key={i}
                className={`relative text-[26px] cursor-pointer select-none`}
                onMouseEnter={() => {
                  setHovered(i)
                  setPreSelect(i)
                }}
                onClick={() => {
                  setSelected(i)
                }}
              >
                {i === hovered && (
                  <motion.div
                    layoutId="hoverHere"
                    className="w-full h-1 rounded-[4px] absolute -bottom-[10px] bg-[white] opacity-100 "
                    style={{
                      background: getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'red' }).color,
                    }}
                  />
                )}
                {(i === selected || (selected === undefined && i === preSelect)) && (
                  <motion.div
                    key={i}
                    layoutId="selected"
                    className="w-full h-[2px] rounded-[4px] absolute bottom-0 md:-bottom-[10px] bg-[#474747] opacity-100"
                    style={{
                      background: getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'red' }).color,
                    }}
                  />
                )}
                <a
                  href={item.url}
                  target={item.type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  {item.name}
                </a>
              </p>
            ))}
          </div>
          <div
            className="w-full h-1 mt-2"
            style={{
              background: getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#c43434' }).color,
            }}
          />
        </div>
      </div>
    </AnimateSharedLayout>
  )
}

const MobileNavbar = ({ menu, website }: MenuVariantProps) => {
  const [hovered, setHovered] = useState(undefined)
  const [selected, setSelected] = useState(undefined)
  const [preSelect, setPreSelect] = useState(undefined)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <AnimateSharedLayout>
      <div
        className="bg-black  md:hidden"
        style={{
          ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'black' }),
          ...getSpacing(menu?.settings?.spacing),
        }}
      >
        <div className="px-4 py-4 flex justify-between items-center">
          <a href="/">
            {website?.config.logo ? (
              <img
                src={website?.config.logo}
                className="w-11 cursor-pointer h-11 rounded-full border-2 border-white "
              />
            ) : (
              <p
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'red' }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                }}
              >
                {website.name}
              </p>
            )}
          </a>
          <div>
            <button onClick={() => setIsOpen(!isOpen)}>
              <IoMenuSharp
                color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#981414' }).color}
                size={30}
                className="text-red-700 border-2 rounded-full border-white p-1"
              />
            </button>
          </div>
        </div>
      </div>
      {isOpen && (
        <motion.div
          onMouseLeave={() => {
            setHovered(selected)
          }}
          initial={{ x: -100 }}
          animate={{ x: 0, transition: { duration: 0.6, type: 'spring' }, originX: 30 }}
          className={`bg-black text-white py-6 flex flex-col items-center relative `}
          style={{
            ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'black' }),
            ...getSpacing(menu?.settings?.spacing),
          }}
        >
          <motion.div className={`flex flex-col justify-center items-center gap-y-3 py-6`}>
            {menu.links.map((item, i: any) => (
              <p
                key={i}
                className={`relative  ml-[20px] cursor-pointer select-none`}
                onMouseEnter={() => {
                  setHovered(i)
                  setPreSelect(i)
                }}
                onClick={() => {
                  setSelected(i)
                }}
              >
                {i === hovered && (
                  <motion.div
                    layoutId="hoverHere"
                    className="w-full h-1 rounded-[4px] absolute -bottom-[10px] bg-[red] opacity-100 hidden "
                  />
                )}
                {(i === selected || (selected === undefined && i === preSelect)) && (
                  <motion.div
                    key={i}
                    layoutId="selected"
                    className="w-full h-[2px] rounded-[4px] absolute bottom-0 bg-[white] opacity-100"
                  />
                )}
                <a
                  href={item.url}
                  target={item.type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  {item.name}
                </a>
              </p>
            ))}
          </motion.div>
        </motion.div>
      )}
    </AnimateSharedLayout>
  )
}
