import Color from 'color'
import { useEffect, useState } from 'react'
import { Descendant } from 'slate'
import { SectionSlate } from 'src/components/editor'

interface SectionTextFieldProps {
  initialValue: Descendant[]
}

const ItemField = ({ initialValue }: SectionTextFieldProps) => {
  const [isLightColor, setIsLightColor] = useState<boolean>(false)
  const [isDarkColor, setIsDarkColor] = useState<boolean>(false)
  const [isDefaultColor, setIsDefaultColor] = useState<boolean>(false)

  useEffect(() => {
    const defaultColor: any = initialValue[0]
    const initColor = defaultColor?.children[0]?.color
    const color = Color(initColor)
    const light = color.isLight()
    const dark = color.isDark()
    setIsLightColor(light)
    setIsDarkColor(dark)
    if (initColor) {
      setIsDefaultColor(true)
    } else {
      setIsDefaultColor(false)
    }
  }, [initialValue])

  return (
    <div
      style={{
        background: `${
          (isLightColor && !isDarkColor && isDefaultColor && 'black') ||
          (!isLightColor && isDarkColor && isDefaultColor && 'white') ||
          (!isDefaultColor && '')
        }`,
      }}
    >
      <SectionSlate initialValue={initialValue} previewMode />
    </div>
  )
}

export default ItemField
