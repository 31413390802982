import { FooterVariantProps } from './footer'
import VariantFive from './variants/VariantFive'
import VariantEight from './variants/VariantEight'
import VariantFour from './variants/VariantFour'
import VariantNine from './variants/VariantNine'
import VariantOne from './variants/VariantOne'
import VariantSeven from './variants/VariantSeven'
import VariantSix from './variants/VariantSix'
import VariantTen from './variants/VariantTen'
import VariantThree from './variants/VariantThree'
import VariantTwo from './variants/VariantTwo'

const variants: any = {
  VARIANT_1: VariantOne,
  VARIANT_2: VariantTwo,
  VARIANT_3: VariantThree,
  VARIANT_4: VariantFour,
  VARIANT_5: VariantFive,
  VARIANT_6: VariantSix,
  VARIANT_7: VariantSeven,
  VARIANT_8: VariantEight,
  VARIANT_9: VariantNine,
  VARIANT_10: VariantTen,
}

export default function FooterRenderer({ footer, website }: FooterVariantProps) {
  const Footer = variants[footer?.id]
  return Footer ? <Footer {...{ footer, website }} /> : null
}
