import useColorMode from 'src/hooks/useColorMode'

type ColorModeSwitcherProps = {
  hideTitle?: boolean
}

export default function ColorModeSwitcher({ hideTitle }: ColorModeSwitcherProps) {
  const { changeColorMode, mode } = useColorMode()

  return (
    <div className="flex items-center gap-3">
      {!hideTitle && <p className="font-light text-lg"> </p>}

      <div className="flex gap-3">
        <button type="button" onClick={() => changeColorMode('light')}>
          <div
            className={`h-6 w-6 border-2 ${
              mode === 'light' ? 'border-brand-accent' : 'border-black dark:border-white'
            } bg-white rounded-full`}
          />
        </button>
        <button type="button" onClick={() => changeColorMode('dark')}>
          <div
            className={`h-6 w-6 border-2 ${
              mode === 'dark' ? 'border-brand-accent' : 'border-black dark:border-white'
            } bg-black rounded-full`}
          />
        </button>
        <button type="button" onClick={() => changeColorMode('system')}>
          <div
            className={`h-6 w-6 border-2 ${
              mode === 'system' ? 'border-brand-accent' : 'border-black dark:border-white'
            } bg-black rounded-full`}
            style={{
              background:
                'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(0,0,0,1) 50%, rgba(0,0,0,1) 100%)',
            }}
          />
        </button>
      </div>
    </div>
  )
}
