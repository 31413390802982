import { useState } from 'react'
import { Link } from 'react-router-dom'
import ModalDialogBotNav from 'src/components/new/ModalDialogBotNav'
import ModalDialogV2 from 'src/components/new/ModalDialogV2'
import AllUsers from 'src/icons/AllUsers'
import HomeIcon from 'src/icons/Home'
import NewMessageMenu from 'src/icons/NewMessageMenu'
import NotificationMenu from 'src/icons/NotificationMenu'
import Plus from 'src/icons/Plus'
import Profile from 'src/icons/Profile'
import SearchMenu from 'src/icons/SearchMenu'
import Send from 'src/icons/Send'
// import CreateModal from '../../posts-page/components/CreateModal'
import CreatePostModal from '../../posts-page/components/CreatePostModal'
import FilterModal from '../../posts-page/components/FilterModal'
import ProfileModal from '../../posts-page/components/ProfileModal'
import SettingsModal from '../../posts-page/components/SettingsModal'

export type ChatProps = {
  variant: 'one' | 'two' | 'three' | 'four'
}

export default function BottomNavigation({ variant }: ChatProps) {
  const [profileModal, setProfileModal] = useState(false)
  const [createModal, setCreateModal] = useState(false)
  const [filterModal, setFilterModal] = useState(false)
  const [settingModal, setSettingModal] = useState(false)
  const [createImageModal, setCreateImageModal] = useState(false)
  const [createPostModal, setCreatePostModal] = useState(false)

  const handleSettingModal = () => {
    setSettingModal(true)
    setProfileModal(false)
  }
  const handleCreateImageModal = () => {
    setCreateImageModal(true)
    setProfileModal(false)
  }
  const handleCreatePostModal = () => {
    setCreatePostModal(true)
    setProfileModal(false)
  }

  return (
    <>
      <ModalDialogV2 open={settingModal} onBack={() => setSettingModal(false)}>
        <div className="px-6">
          <SettingsModal />
        </div>
      </ModalDialogV2>
      <CreatePostModal isOpen={createImageModal} setIsOpen={() => setCreateImageModal(false)} modal="image" />
      <CreatePostModal isOpen={createPostModal} setIsOpen={() => setCreatePostModal(false)} modal="post" />

      {/* click on circle */}
      <ModalDialogBotNav open={profileModal} onBack={() => setProfileModal(false)}>
        <ProfileModal
          onMobileSetting={handleSettingModal}
          onCreateImage={handleCreateImageModal}
          onCreatePost={handleCreatePostModal}
        />
      </ModalDialogBotNav>
      {/* click on plus */}
      <CreatePostModal isOpen={createModal} setIsOpen={() => setCreateModal(false)} />
      {/* filter */}
      <ModalDialogBotNav open={filterModal} onBack={() => setFilterModal(false)}>
        <FilterModal />
      </ModalDialogBotNav>
      <div className="md:hidden fixed bottom-0 w-full bg-white h-[50px] z-10 dark:bg-black border-t border-black dark:border-white flex justify-between items-center px-8">
        {variant === 'one' && (
          <>
            <Link to="/search">
              <SearchMenu
                strokeWidth="0.6"
                className="dark:fill-[#D9D9D9] dark:stroke-[#D9D9D9] fill-black stroke-black "
              />
            </Link>
            <Link to="/messages/?showThreadList=true">
              <Send size={22} />
            </Link>
            <button
              type="button"
              className="focus:outline-none"
              onClick={() => {
                setCreateModal(!createModal)
              }}
            >
              <Plus
                size={35}
                className="stroke-white bg-brand-accent -mt-11 rounded-lg w-[40px] flex items-center justify-center h-[40px]"
              />
            </button>
            <Link to="/notifications">
              <NotificationMenu strokeWidth={0.6} className="stroke-black dark:stroke-[#D9D9D9]" size={25} />
            </Link>
            <button type="button" onClick={() => setProfileModal(!profileModal)}>
              <Profile size={28} strokeWidth={1.6} fill="white" stroke="black" />
            </button>
          </>
        )}
        {variant === 'two' && (
          <>
            <Link to="/home">
              <HomeIcon size={27} className="dark:fill-[#D9D9D9]" />
            </Link>
            <Link to="/">
              <AllUsers size={29} className="dark:fill-[#D9D9D9]" />
            </Link>
            <button type="button" className="focus:outline-none" onClick={() => setCreateModal(!createModal)}>
              <Plus
                size={35}
                className="stroke-white bg-brand-accent -mt-11 rounded-lg w-[40px] flex items-center justify-center h-[40px]"
              />
            </button>
            <Link to="/messages/new">
              <NewMessageMenu className="stroke-[#000000] dark:stroke-white" fill="none" size={29} />
            </Link>
            <button type="button" onClick={() => setProfileModal(!profileModal)}>
              <Profile size={28} strokeWidth={1.6} fill="white" stroke="black" />
            </button>
          </>
        )}
        {variant === 'three' && (
          <>
            <Link to="/home">
              <HomeIcon size={27} className="dark:fill-[#D9D9D9]" />
            </Link>
            <Link to="/messages/?showThreadList=true">
              <Send size={22} />
            </Link>
            <button
              type="button"
              className="focus:outline-none"
              onClick={() => {
                setCreateModal(!createModal)
              }}
            >
              <Plus
                size={35}
                className="stroke-white bg-brand-accent -mt-11 rounded-lg w-[40px] flex items-center justify-center h-[40px]"
              />
            </button>
            <Link to="/notifications">
              <NotificationMenu strokeWidth={0.6} className="stroke-black dark:stroke-[#D9D9D9]" size={25} />
            </Link>
            <button type="button" onClick={() => setProfileModal(!profileModal)}>
              <Profile size={28} strokeWidth={1.6} fill="white" stroke="black" />
            </button>
          </>
        )}
        {variant === 'four' && (
          <>
            <Link to="/home">
              <HomeIcon size={27} className="dark:fill-[#D9D9D9]" />
            </Link>
            <Link to="/">
              <AllUsers size={29} className="dark:fill-[#D9D9D9]" />
            </Link>
            <button type="button" className="focus:outline-none" onClick={() => setCreateModal(!createModal)}>
              <Plus
                size={35}
                className="stroke-white bg-brand-accent -mt-11 rounded-lg w-[40px] flex items-center justify-center h-[40px]"
              />
            </button>
            <Link to="/messages/?showThreadList=true">
              <Send size={22} />
            </Link>
            <button type="button" onClick={() => setProfileModal(!profileModal)}>
              <Profile size={28} strokeWidth={1.6} fill="white" stroke="black" />
            </button>
          </>
        )}
      </div>
    </>
  )
}
