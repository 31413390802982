import Icon from './icons'
import { IconPropsT } from './types'

function Advanced(props: IconPropsT) {
  const { title = 'Advanced', size, color, ...otherProps } = props
  return (
    <Icon viewBox="0 0 22 21" size={size} title={title} {...otherProps}>
      <path
        d="M7.59016 6.85509L7.66471 6.73434V6.59243V0.5H13.8294V6.98935V7.13125L13.904 7.25201L20.0073 17.1381L20.0477 17.2036L20.106 17.2539C20.4994 17.5934 20.7471 18.094 20.7471 18.6529C20.7471 19.673 19.9201 20.5 18.9 20.5H2.34706C1.32696 20.5 0.5 19.673 0.5 18.6529C0.5 18.0924 0.749116 17.5905 1.14442 17.251L1.20332 17.2004L1.24411 17.1343L7.59016 6.85509Z"
        stroke={color}
        fill="none"
      />
      <circle cx="6.96471" cy="13.9647" r="2.96471" fill={color} />
      <circle cx="11.3661" cy="8.39931" r="2.22353" fill={color} />
      <circle cx="9.63666" cy="3.45893" r="1.23529" fill={color} />
      <circle cx="14.5" cy="16.5" r="2.5" fill={color} />
    </Icon>
  )
}

export default Advanced
