import React, { useState } from 'react'
import ArrowDown from 'src/icons/ArrowDown'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/solid'

const contacts = [
  { id: 2, name: 'Hayatul Islam' },
  { id: 1, name: 'Samuel Sohan' },
  { id: 6, name: 'Justin HM' },
  { id: 3, name: 'Muhammed Shafi' },
  { id: 4, name: 'Janice Mc' },
  { id: 5, name: 'Abrar Muthana' },
]

export default function SearchUser() {
  const [selected, setSelected] = useState(contacts[0])
  const [query, setQuery] = useState('')
  const filteredContacts =
    query === ''
      ? contacts
      : contacts.filter(person =>
          person.name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')),
        )

  return (
    <div className="xl:border-b lg:border-b md:border-b h-[90px] xl:lg:md:pl-8 pl-[18px] w-full flex items-center relative border-[#C4C4C4] md:border-black">
      <form>
        <div className="flex gap-x-4 items-center">
          <div>
            <svg width="36" height="32" viewBox="0 0 40 37" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.825 6.69995C13.1501 6.69995 14.4455 7.0929 15.5473 7.8291C16.6491 8.56531 17.5079 9.6117 18.015 10.836C18.5221 12.0602 18.6548 13.4074 18.3963 14.7071C18.1377 16.0067 17.4996 17.2006 16.5626 18.1376C15.6256 19.0746 14.4318 19.7127 13.1321 19.9712C11.8324 20.2297 10.4853 20.097 9.26102 19.5899C8.03676 19.0828 6.99036 18.2241 6.25416 17.1223C5.51795 16.0205 5.125 14.7251 5.125 13.3999C5.125 12.5201 5.2983 11.6488 5.63501 10.836C5.97172 10.0231 6.46523 9.28448 7.08739 8.66233C7.70954 8.04018 8.44814 7.54666 9.26102 7.20995C10.0739 6.87325 10.9451 6.69995 11.825 6.69995Z"
                fill="black"
              />
              <path
                d="M11.825 21.6849C8.68968 21.6849 5.6827 22.9301 3.46523 25.1466C1.24776 27.3632 0.00132572 30.3696 0 33.5049C0 34.3422 0.332612 35.1452 0.924664 35.7373C1.51672 36.3293 2.31971 36.662 3.157 36.662H20.5C21.3373 36.662 22.1403 36.3293 22.7323 35.7373C23.3244 35.1452 23.657 34.3422 23.657 33.5049C23.6563 31.9521 23.3498 30.4145 22.755 28.9801C22.1601 27.5457 21.2885 26.2425 20.19 25.1449C19.0915 24.0473 17.7875 23.1768 16.3526 22.5832C14.9177 21.9895 13.3799 21.6843 11.827 21.6849"
                fill="black"
              />
              <path
                d="M27.7635 13.4C29.0886 13.4 30.384 13.0071 31.4858 12.2708C32.5876 11.5346 33.4464 10.4882 33.9535 9.26398C34.4606 8.03972 34.5933 6.69257 34.3347 5.3929C34.0762 4.09323 33.4381 2.8994 32.5011 1.96239C31.5641 1.02538 30.3703 0.387263 29.0706 0.128742C27.7709 -0.129779 26.4238 0.00290304 25.1995 0.51001C23.9752 1.01712 22.9288 1.87587 22.1926 2.97768C21.4564 4.07949 21.0635 5.37487 21.0635 6.7C21.0635 8.47695 21.7694 10.1811 23.0259 11.4376C24.2824 12.6941 25.9865 13.4 27.7635 13.4Z"
                fill="black"
              />
              <path
                d="M27.7623 14.978C25.7306 14.9773 23.733 15.5002 21.9623 16.4963C20.1916 17.4925 18.7075 18.9282 17.6533 20.6651C19.6483 21.3844 21.4136 22.6262 22.7646 24.261C24.1155 25.8957 25.0027 27.8633 25.3333 29.9581H36.4333C37.2706 29.9581 38.0736 29.6254 38.6656 29.0334C39.2577 28.4413 39.5903 27.6383 39.5903 26.8011C39.5909 25.2478 39.2854 23.7097 38.6913 22.2746C38.0973 20.8394 37.2264 19.5354 36.1283 18.4369C35.0302 17.3384 33.7264 16.4671 32.2915 15.8726C30.8565 15.278 29.3185 14.972 27.7653 14.972"
                fill="black"
              />
            </svg>
          </div>
          <Combobox value={selected} onChange={setSelected}>
            <div className="relative mt-1 flex items-center">
              <div className="relative w-[90%] md:w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                <Combobox.Input
                  className="outline-none  placeholder:text-black font-semibold pr-40 w-full rounded-md px-4 md:pr-28 py-2 border-2 border-black relative"
                  displayValue={(person: any) => person.name}
                  placeholder="Select Contact"
                  onChange={event => setQuery(event.target.value)}
                />
                <Combobox.Button type="button" className="absolute outline-none right-4 top-[0.9rem] md:top-[0.8rem]">
                  <ArrowDown fill="white" size={16} className="stroke-black" />
                </Combobox.Button>
              </div>
              <Transition
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery('')}
              >
                <Combobox.Options className="absolute mt-1  md:max-h-60 md:w-full w-[90%] overflow-auto md:top-10 top-12 rounded-md bg-white py-1 left-0  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {filteredContacts.length === 0 && query !== '' ? (
                    <div className="relative -left-5 cursor-default select-none md:text-md text-sm py-2 text-gray-700">
                      Nothing found .
                    </div>
                  ) : (
                    filteredContacts.map(person => (
                      <Combobox.Option
                        key={person.id}
                        className={({ active }) =>
                          `relative md:text-md text-sm cursor-pointer -left-5 list-none py-2 flex gap-x-2 items-center pr-4 ${
                            active ? ' text-[#E90970]' : 'text-gray-900'
                          }`
                        }
                        value={person}
                      >
                        {({ active }) => (
                          <>
                            {selected ? (
                              <span className={`flex  ${active ? 'text-white' : 'text-[#E90970]'}`}>
                                <CheckIcon className="h-5 w-5 " aria-hidden="true" />
                              </span>
                            ) : null}
                            <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                              {person.name}
                            </span>
                          </>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
        </div>
      </form>
    </div>
  )
}
