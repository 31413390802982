import { GallerySection, GallerySectionItem } from '../../types'
import { motion } from 'framer-motion'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import SectionSlatePreview from '../../../common/SectionSlatePreview'

export default function VariantTen({ section }: { section: GallerySection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const gallery = section.variables.find(variable => variable.name === 'MEDIA')?.data as GallerySectionItem[]

  return (
    <div
      className="text-center md:leading-10 mx-auto py-6"
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-[25px] lg:text-[3rem] xl:text-h1 font-title  tracking-wider ">
          <SectionSlatePreview initialValue={title} previewMode />
        </h1>
        <div className="w-[60px] mt-2 ml-[10px] bg-lightpurple h-[1px]" />
      </div>

      <div className="flex justify-center items-center">
        <motion.div
          initial={{ x: -40, opacity: 0.6 }}
          whileInView={{ x: 0, opacity: 1 }}
          exit={{ x: 0 }}
          transition={{ duration: 2, type: 'tween' }}
          className="grid grid-rows-4 grid-cols-3  xl:w-[60%] p-4 gap-[25px] md:mt-10"
        >
          {gallery.map((data, idx) => (
            <div key={idx} className=" row-span-4">
              <div className="row-span-2 md:mt-7  bg-[#eee]  shadow- justify-center flex items-center rounded-[20px]  h-[80px]  xl:h-[100px] lg:h-[100px] md:h-[90px]">
                <img
                  src={data.image}
                  alt=""
                  className="w-[100px] h-[100x] lg:w-[120px] lg:h-[120px] md:w-[100px] md:h-[100px] xl:w-[150px] xl:h-[150px] object-cover flex justify-center items-center"
                />
              </div>
            </div>
          ))}
        </motion.div>
        <div></div>
      </div>
    </div>
  )
}
