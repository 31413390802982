import { ContactUsSection, InputsContactUSSection } from '../../types'
import { useForm } from 'react-hook-form'
import InputText from '../../../common/InputText'
import TextArea from '../../../common/TextArea'
import { useState } from 'react'
import clsx from 'clsx'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import ButtonRenderer from '../ButtonRenderer'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantFour({ section }: { section: ContactUsSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subtitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const inputs = section.variables.find(variant => variant.name === 'INPUTS')?.data as InputsContactUSSection[]
  const button = section.variables.find(variant => variant.name === 'BUTTON_1')?.data

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    setLoading(true)
    try {
    } catch (error) {
      setError('Something went wrong!')
    }
    setLoading(false)
  }

  const inputClasses = {
    classInput: 'font-extralight text-sm w-full border border-black md:px-3 px-1 py-2 resize-none outline-none',
    classLabel: 'font-extralight text-sm inline-block pb-0 mb-1',
  }
  let indexIdentifier = 2

  return (
    <div
      className="py-10 flex flex-col justify-center items-center "
      style={{
        ...generateSectionBackground({
          defaultColor: '#f0f0f0',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="text-center px-4 space-y-1">
        <p className="md:text-[45px] text-3xl font-medium">
          <SectionSlatePreview initialValue={title} previewMode />
        </p>
        <p className="text-sm">
          <SectionSlatePreview initialValue={subtitle} previewMode />
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col  justify-center px-4">
        {inputs && (
          <div
            className={`md:mb-6 w-12/12 md:mt-3 md:w-[550px] md:gap-y-4 gap-y-2 gap-x-2 items-center  text-sm grid-cols-1 md:grid-cols-2 space-y-2 lg:space-y-0 lg:grid  `}
          >
            {inputs.map(({ type, name, error, id, placeholder }, idx) => {
              if (idx === indexIdentifier) {
                if (indexIdentifier % 9 == 0) {
                  indexIdentifier += 2
                } else {
                  indexIdentifier += 1
                }

                return (
                  <div key={id} className="col-span-2 ">
                    {type !== 'button' && type !== 'textarea' && (
                      <InputText
                        {...inputClasses}
                        label={name}
                        name={name}
                        register={register}
                        type={type}
                        errors={errors}
                        required={{ value: true, message: `${error}` }}
                        placeholder={placeholder}
                      />
                    )}

                    {type === 'button' && (
                      <div className="w-full col-span-2 ">
                        <input
                          type={type}
                          value={name}
                          // placeholder={placeholder}
                          className="px-5 py-3 font-medium bg-black text-white cursor-pointer"
                        />
                      </div>
                    )}
                    {type === 'textarea' && (
                      <div className="w-full col-span-2 ">
                        <TextArea
                          {...inputClasses}
                          label={name}
                          name={name}
                          register={register}
                          type={type}
                          errors={errors}
                          required={{ value: true, message: `${error}` }}
                          placeholder={placeholder}
                        />
                      </div>
                    )}
                  </div>
                )
              } else {
                return (
                  <div key={id}>
                    {type !== 'button' && type !== 'textarea' && (
                      <div className="w-full">
                        <InputText
                          {...inputClasses}
                          label={name}
                          name={name}
                          register={register}
                          type={type}
                          errors={errors}
                          required={{ value: true, message: `${error}` }}
                          placeholder={placeholder}
                        />
                      </div>
                    )}

                    {type === 'button' && (
                      <div className="w-full">
                        <input
                          type={type}
                          value={name}
                          className="px-5 py-3 font-medium bg-black text-white cursor-pointer"
                        />
                      </div>
                    )}
                    {type === 'textarea' && (
                      <div className="w-full">
                        <TextArea
                          {...inputClasses}
                          label={name}
                          name={name}
                          register={register}
                          type={type}
                          errors={errors}
                          required={{ value: true, message: `${error}` }}
                          placeholder={placeholder}
                        />
                      </div>
                    )}
                  </div>
                )
              }
            })}
          </div>
        )}

        {error && <span className="text-secondary.main text-center mb-4">{error}</span>}
        <div className="btn-submit items-center flex justify-center">
          {button ? (
            <ButtonRenderer button={button} typeOfButton="submit" withOutHref />
          ) : (
            <button
              disabled={loading}
              type="submit"
              className={clsx(
                loading && 'cursor-not-allowed disabled:opacity-50',
                'px-5 py-3 font-medium bg-[#978246] text-white',
              )}
            >
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  )
}
