import React from 'react'
import BottomNavigation from '../feed/components/BottomNavigation'
import Header from '../posts-page/components/Header'
import NotificationModal from '../posts-page/components/NotificationModal'

export default function Notification() {
  return (
    <div className="block md:hidden dark:bg-black h-screen">
      <Header />
      <div>
        <NotificationModal />
      </div>

      <div>
        <BottomNavigation variant="two" />
      </div>
    </div>
  )
}
