import SectionSlate from '../../../common/SectionSlatePreview'
import { AttentionGrabberSection } from '../../types'
import { motion } from 'framer-motion'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantTwo({ section }: { section: AttentionGrabberSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data
  const media2 = section.variables.find(variable => variable.name === 'MEDIA2')?.data
  const transition = section.variables.find(variable => variable.name === 'TRANSITION')?.data

  return (
    <div
      className="relative mx-auto"
      style={{
        ...generateSectionBackground({
          defaultColor: '#000',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div
        className="overflow-hidden flex items-center bg-cover object-cover bg-no-repeat bg-black m-0 h-[250px] w-full lg:w-full lg:h-screen text-center"
        style={{
          backgroundImage: media ? `url(${media})` : '',
          backgroundPosition: 'center',
        }}
      >
        <div className="hero-overlay bg-black/40 h-[250px] md:h-[500px] w-full lg:w-full lg:h-screen">
          <motion.div
            initial={{ y: 200 }}
            whileInView={{ y: -40 }}
            transition={{ type: 'spring', stiffness: 100, duration: transition }}
            viewport={{ once: true }}
          >
            <div className="py-[50px] mt-8 lg:mt-[200px]">
              <div className="text-white pb-3 md:pb-6 text-center ">
                <h1 className="text-[32px] lg:text-5xl text-yellow-300 font-bold font-head">
                  {<SectionSlate initialValue={title} previewMode />}
                </h1>
                <h1 className="md:my-5 text-sm xl:text-2xl font-semibold uppercase text-white">
                  {<SectionSlate initialValue={subTitle} previewMode />}
                </h1>
              </div>
              <div className="text-primary items-center flex mx-auto w-8/12">
                <div className="w-full">
                  <div className="ml-1 bg-white h-[2px] mb-1"></div>
                  <div className="bg-white h-[2px]"></div>
                </div>
                <div className="flex items-center justify-center w-[90%] md:w-[50%]">
                  <img
                    src={media2}
                    className="md:w-32 h-12 w-12 md:h-32 md:border-4 rounded-full border-[white] object-cover"
                  />
                </div>
                <div className="w-full">
                  <div className="mr-1 bg-white h-[2px] mb-1"></div>
                  <div className="bg-white h-[2px]"></div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  )
}
