import Icon from './icons'
import { IconPropsT } from './types'

function Heart(props: IconPropsT) {
  const { title = 'Heart', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 24 24" size={size} title={title} {...otherProps}>
      <path d="M17.4936 0.000471457C16.4876 -0.0049262 15.4927 0.210965 14.5794 0.63285C13.6661 1.05474 12.8567 1.67229 12.2086 2.44178C11.5648 1.6673 10.7563 1.04626 9.84196 0.623918C8.92766 0.201577 7.93064 -0.0114144 6.92357 0.000471457C6.01427 0.000471447 5.11387 0.179589 4.2738 0.527596C3.43372 0.875603 2.67043 1.38568 2.0275 2.0287C1.38458 2.67173 0.874611 3.4351 0.52673 4.27522C0.178848 5.11535 -0.000135438 6.01577 7.68945e-08 6.92508C7.68945e-08 13.4772 11.4358 19.8756 11.8991 20.1222C11.9906 20.1835 12.0984 20.2163 12.2086 20.2163C12.3188 20.2163 12.4266 20.1835 12.5182 20.1222C12.9814 19.8745 24.4172 13.5701 24.4172 6.92508C24.4173 6.01577 24.2384 5.11535 23.8905 4.27522C23.5426 3.4351 23.0326 2.67173 22.3897 2.0287C21.7468 1.38568 20.9835 0.875603 20.1434 0.527596C19.3033 0.179589 18.4029 0.000471447 17.4936 0.000471457M12.2086 18.8829C10.4163 17.8356 1.2382 12.2411 1.2382 6.92508C1.23847 5.41721 1.83752 3.97116 2.90364 2.90484C3.96977 1.83852 5.41571 1.23921 6.92357 1.23867C7.86961 1.22624 8.80318 1.45528 9.63606 1.90412C10.4689 2.35297 11.1736 3.00679 11.6834 3.80379C11.7436 3.88671 11.8226 3.95419 11.9139 4.00072C12.0051 4.04725 12.1061 4.07151 12.2086 4.07151C12.3111 4.07151 12.4121 4.04725 12.5034 4.00072C12.5946 3.95419 12.6736 3.88671 12.7338 3.80379C13.4047 2.77804 14.3889 1.99645 15.54 1.57544C16.6911 1.15444 17.9474 1.11654 19.1217 1.4674C20.2961 1.81826 21.3257 2.5391 22.0572 3.52254C22.7886 4.50599 23.1829 5.69943 23.1811 6.92508C23.1811 12.303 13.9978 17.8625 12.2086 18.886" />
    </Icon>
  )
}

export default Heart
