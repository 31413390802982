import { NewFooter, NewFooterCategory } from 'src/_polly/components/src/footer/footer'
import { NewMenuLink } from 'src/_polly/components/src/menu/menu'
import create from 'zustand'

const updateComponent = (components: any[], id: string, data: any) =>
  components.map(component => (component.id === id ? { ...component, ...data } : component))

type Website = {
  website: any
  components: any[]
  mobilePreviewComponents: any[]
  setWebsite: (website: any) => void
  setComponents: (components: any) => void
  setMobilePreviewComponents: (components: any) => void
  updateComponent: (id: string, data: any) => void
  updateNewMenu: (newMenu: NewMenuLink[]) => void
  updateNewFooter: (newFooter: NewFooter) => void
  updateNewFooterCategory: (category: NewFooterCategory) => void
}

const useWebsite = create<Website>(
  (set): Website => ({
    website: undefined,
    components: [],
    mobilePreviewComponents: [],
    setWebsite: (website: any) => set(state => ({ ...state, website })),
    setComponents: (components: any) => set(state => ({ ...state, components })),
    setMobilePreviewComponents: (mobilePreviewComponents: any) => set(state => ({ ...state, mobilePreviewComponents })),
    updateComponent: (id: string, data: any) =>
      set(state => ({ ...state, components: updateComponent(state.components, id, data) })),
    updateNewMenu: (menu: NewMenuLink[]) => set(state => ({ ...state, website: { ...state.website, newMenu: menu } })),
    updateNewFooter: (footer: NewFooter) =>
      set(state => ({ ...state, website: { ...state.website, newFooter: footer } })),
    updateNewFooterCategory: (category: NewFooterCategory) =>
      set(state => ({
        ...state,
        website: {
          ...state.website,
          newFooter: {
            ...state.website.newFooter,
            categories: state.website.newFooter.categories.map((item: any) =>
              item.id === category.id ? category : item,
            ),
          },
        },
      })),
  }),
)

export default useWebsite
