import { ReactNode } from 'react'
import LivePhonePreview from '../new/LivePhonePreview'

export default function PreviewLayout({ children }: { children: ReactNode }) {
  const { innerWidth } = window

  return (
    <div
      className="lg:grid lg:h-screen lg:overflow-hidden"
      style={{
        gridTemplateColumns: '500px 1fr',
      }}
    >
      <div className="overflow-auto relative dark:bg-black">{children}</div>

      {innerWidth > 1024 && <LivePhonePreview />}
    </div>
  )
}
