import { PropsWithChildren, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from './AuthContext'
import { Loader } from './loader'

export default function UnauthenticatedPage(props: PropsWithChildren<Record<string, unknown>>) {
  const navigate = useNavigate()
  const { authState } = useContext(AuthContext)
  const { children } = props

  useEffect(() => {
    if (authState === 'authenticated') {
      navigate('/home', { replace: true })
    }
    console.log('authState', authState)
  }, [authState])

  if (authState === 'loading' || authState === 'authenticated') return <Loader show />

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
