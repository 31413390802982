import { Descendant } from 'slate'
import {
  Section,
  SectionTags,
  TABLESectionItem,
  TABLEVariables,
  TABLEVariants
} from 'src/_polly/components/src/sections'
import { v4 as uuid } from 'uuid'
import { DEFAULT_VARIANT_SETTINGS, getSectionVariants } from './get-variants'
const tags: Record<TABLEVariants, SectionTags[]> = {
  VARIANT_1: ['ANIMATION'],
  VARIANT_2: ['ANIMATION'],
  VARIANT_3: ['ANIMATION'],
}
export function getTABLEVariants() {
  const variants = getSectionVariants('TABLE')
  const sections: Section<TABLEVariants, TABLEVariables>[] = []
  const media =
    'https://images.unsplash.com/photo-1494122353634-c310f45a6d3c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fGRhcmt8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60'

  variants.forEach(variant => {
    if (variant.id === 'VARIANT_1') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Title' }] }]
      const subtitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'TECH STACK' }] }]
      const titleItem1: Descendant[] = [{ type: 'paragraph', children: [{ text: 'React' }] }]
      const titleItem2: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Tailwind' }] }]
      const titleItem3: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Framer Motion' }] }]
      const titleItem4: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Sanity' }] }]
      const titleItem5: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Vercel' }] }]
      const subtitleItem1: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Static Site Generator' }] }]
      const subtitleItem2: Descendant[] = [{ type: 'paragraph', children: [{ text: 'CSS Framework' }] }]
      const subtitleItem3: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Animation' }] }]
      const subtitleItem4: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Headless CMS' }] }]
      const subtitleItem5: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Hosting' }] }]

      const data: Section<TABLEVariants, TABLEVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TABLE',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'IMAGE', data: media, name: 'MEDIA' },
          {
            id: uuid(),
            category: 'TEXT',
            name: 'ITEMS',
            data: [
              {
                id: uuid(),
                title: titleItem1,
                subtitle: subtitleItem1,
              },
              {
                id: uuid(),
                title: titleItem2,
                subtitle: subtitleItem2,
              },
              {
                id: uuid(),
                title: titleItem3,
                subtitle: subtitleItem3,
              },
              {
                id: uuid(),
                title: titleItem4,
                subtitle: subtitleItem4,
              },
              {
                id: uuid(),
                title: titleItem5,
                subtitle: subtitleItem5,
              },
            ] as TABLESectionItem[],
          },
        ],

        variant: variant.id as TABLEVariants,
        tags: tags[variant.id as TABLEVariants],
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_3'){
      const titleItem1: Descendant[] = [{ type: 'paragraph', children: [{ text: 'The Best Home Coffee Roasters' }] }]
      const titleItem2: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Best Decaf Coffee: The Secrets Behind the Brew' }] }]
      const titleItem3: Descendant[] = [{ type: 'paragraph', children: [{ text: 'How To Make Your Coffee Taste Even Better' }] }]
      const titleItem4: Descendant[] = [{ type: 'paragraph', children: [{ text: 'The 7 Best Keurig Coffee Makers' }] }]
      const titleItem5: Descendant[] = [{ type: 'paragraph', children: [{ text: 'The Definitive Guide to Coffee Creamer' }] }]
      const titleItem6: Descendant[] = [{ type: 'paragraph', children: [{ text: 'How to Clean a Coffee Maker' }] }]
      const titleItem7: Descendant[] = [{ type: 'paragraph', children: [{ text: '11 of the Best Low-Acid Coffee for Sensitive Stomachs' }] }]
      const titleItem8: Descendant[] = [{ type: 'paragraph', children: [{ text: 'The Complete Guide To Choosing The Right Coffee Filter' }] }]
      const titleItem9: Descendant[] = [{ type: 'paragraph', children: [{ text: '50+ Different Types Of Coffee Drinks Explained [Ultimate Guide]' }] }]
      const titleItem10: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Best Espresso Machines For Your Kitchen' }] }]
      const titleItem11: Descendant[] = [{ type: 'paragraph', children: [{ text: 'How to Make Stovetop Percolator Coffee' }] }]
      const titleItem12: Descendant[] = [{ type: 'paragraph', children: [{ text: 'How to Make Coffee Without a Coffee Maker' }] }]

      const data: Section<TABLEVariants, TABLEVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TABLE',
        updatedAt: '',
        tags: tags[variant.id as TABLEVariants],
        variables: [
          {
            id: uuid(),
            name: 'ITEMS',
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                title: titleItem1,
              },
              {
                id: uuid(),
                title: titleItem2,
              },
              {
                id: uuid(),
                title: titleItem3,
              },
              {
                id: uuid(),
                title: titleItem4,
              },
              {
                id: uuid(),
                title: titleItem5,
              },
              {
                id: uuid(),
                title: titleItem6,
              },
              {
                id: uuid(),
                title: titleItem7,
              },
              {
                id: uuid(),
                title: titleItem8,
              },
              {
                id: uuid(),
                title: titleItem9,
              },
              {
                id: uuid(),
                title: titleItem10,
              },
              {
                id: uuid(),
                title: titleItem11,
              },
              {
                id: uuid(),
                title: titleItem12,
              },
            ] as TABLESectionItem[],
          },
        ],
        variant: variant.id as TABLEVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Title' }] }]
      const subtitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'subtitle' }] }]
      const paragraph: Descendant[] = [{ type: 'paragraph', children: [{ text: 'paragraph' }] }]
      const subtitleItem1: Descendant[] = [{ type: 'paragraph', children: [{ text: '' }] }]
      const titleItem1: Descendant[] = [{ type: 'paragraph', children: [{ text: '83' }] }]
      const titleItem2: Descendant[] = [{ type: 'paragraph', children: [{ text: '240' }] }]
      const titleItem3: Descendant[] = [{ type: 'paragraph', children: [{ text: '15' }] }]
      const titleItem4: Descendant[] = [{ type: 'paragraph', children: [{ text: '7' }] }]

      const descriptionItem: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'Describe the item and include any relevant details. Click to edit the text.' }],
        },
      ]
      const data: Section<TABLEVariants, TABLEVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'TABLE',
        updatedAt: '',
        tags: tags[variant.id as TABLEVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: uuid(),
            name: 'ITEMS',
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                title: titleItem1,
                subtitle: subtitleItem1,
                description: descriptionItem,
              },
              {
                id: uuid(),
                title: titleItem2,
                subtitle: subtitleItem1,
                description: descriptionItem,
              },
              {
                id: uuid(),
                title: titleItem3,
                subtitle: subtitleItem1,
                description: descriptionItem,
              },
              {
                id: uuid(),
                title: titleItem4,
                subtitle: subtitleItem1,
                description: descriptionItem,
              },
            ] as TABLESectionItem[],
          },
        ],
        variant: variant.id as TABLEVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    }
  })

  return sections
}
