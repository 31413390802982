import SectionSlate from '../../../common/SectionSlatePreview'
import { ImageOmgSection } from '../../types'
import { motion } from 'framer-motion'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantTwo({ section }: { section: ImageOmgSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data
  return (
    <div
      className="py-[10px] mx-auto w-full"
      style={{
        ...generateSectionBackground({
          defaultColor: '#fff',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <motion.div
        className="px-[1rem]  w-max-100  
      
      "
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        variants={{ exit: { opacity: 0 } }}
        transition={{ duration: 0.8, ease: 'easeOut' }}
        exit={{ opacity: 0 }}
      >
        <div className="logo text-[32px] md:text-4xl font-bold text-bold text-center tracking-wider uppercase md:visible ">
          {<SectionSlate initialValue={title} previewMode />}
        </div>
        <div className="app__home relative flex justify-between items-center z-10">
          <div className="m-auto mt-8">
            <img src={media} alt="" className="h-[700px] w-[900px] object-cover object-center" />
          </div>
        </div>

        {/* moving text */}
        <motion.div>
          <motion.h1
            initial={{ x: '50%' }}
            animate={{ x: '-100%' }}
            transition={{ ease: 'linear', duration: 6 }}
            className="uppercase leading-8  w-full tracking-wide absolute text-center top-[40%] lg:top-[55%] text-[28px] z-0 xl:text-7xl lg:text-5xl md:text-3xl
          "
          >
            {<SectionSlate initialValue={subTitle} previewMode />}
          </motion.h1>
          <div className="text-center">{<SectionSlate initialValue={paragraph} previewMode />}</div>
        </motion.div>
      </motion.div>
    </div>
  )
}

// https://strong-clafoutis-392a2e.netlify.app/static/media/girl.a19baa9162e2bc4b9e16.png
