import { ReactNode, useState } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { SectionSlate } from 'src/components/editor'
import { ReactComponent as EditPlus } from 'src/components/icons/EditPlus.svg'
import BackIcon from 'src/icons/BackIcon'
import Logo from 'src/icons/Logo'

interface SectionMediaFieldProps {
  children: ReactNode
  title: string
  preview?: ReactNode
  initialValue?: any[]
  isLightColor?: boolean
  isDarkColor?: boolean
  isDefaultColor?: boolean
  closeMediaModal?: any
  mediaModal?: boolean
  onUpdate?: any
  hideUpdateButton?: boolean
}

export default function SectionBaseField({
  children,
  title,
  preview,
  initialValue,
  isLightColor = false,
  isDarkColor = false,
  isDefaultColor = false,
  closeMediaModal,
  mediaModal,
  onUpdate,
  hideUpdateButton,
}: SectionMediaFieldProps) {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className="px-[1px]">
      {!mediaModal && !expanded && (
        <div className=" border-b-[1px] relative border-black dark:border-white">
          <div
            className={clsx(
              'min-h-[66px] my-2 flex justify-between items-center dark:text-white pl-[2px]',
              !initialValue && 'bg-[#F3F3F3] dark:bg-black pl-1 dark:pl-0',
            )}
          >
            <div className="flex-1">
              <h2
                className={clsx(
                  'capitalize font-semibold text-black dark:text-white',
                  initialValue ? 'text-lg font-bold pb-1' : 'text-lg',
                )}
              >
                {title}
              </h2>
              {initialValue && (
                <div className="preview-text hidden">
                  <SectionSlate initialValue={initialValue} previewMode />
                </div>
              )}
              {preview && (
                <div
                  className={clsx(
                    'mt-2 text-[15px] lg:max-w-[390px] max-w-[90%]',
                    isLightColor && !isDarkColor && isDefaultColor && 'bg-black',
                    !isLightColor && isDarkColor && isDefaultColor && 'bg-white',
                  )}
                >
                  {preview}
                </div>
              )}
            </div>

            <div className="ml-2 absolute right-0 top-0">
              <button
                type="button"
                onClick={() => {
                  if (closeMediaModal) {
                    closeMediaModal(true)
                  } else {
                    setExpanded(!expanded)
                  }
                }}
                className="pr-[8px]"
              >
                <EditPlus />
              </button>
            </div>
          </div>
        </div>
      )}

      {(expanded || mediaModal) && (
        <div className="fixed z-40 inset-0 max-w-[500px] dark:text-white bg-white dark:bg-black">
          <div className="px-5 pt-[21px]">
            <div className="flex items-center">
              <button
                type="button"
                className="flex-shrink-0"
                onClick={() => {
                  if (mediaModal) {
                    closeMediaModal(false)
                  } else {
                    setExpanded(!expanded)
                  }
                }}
              >
                <BackIcon
                  size={28}
                  fill="none"
                  className="fill-white dark:fill-black stroke-black dark:stroke-white cursor-pointer mr-[16px]"
                />
              </button>
              <Link to="/" className="">
                <Logo
                  size={28}
                  fill="none"
                  className="fill-white dark:fill-black stroke-black dark:stroke-white cursor-pointer"
                />
              </Link>
            </div>
          </div>
          <div className="pt-[23px] mt-10 mx-[22px] border-t-[1px] border-black dark:border-white">
            <div className="flex justify-between items-center">
              <h2 className="text-[19px] font-semibold ">{title}</h2>
            </div>
            {children}
          </div>
          {!hideUpdateButton && (
            <div className="fixed lg:max-w-[500px] h-[53px] border-t lg:h-[65px]  bg-black bottom-0 w-full left-0 flex justify-center items-center text-white">
              <button
                type="button"
                onClick={() => {
                  setExpanded(!expanded)
                  onUpdate()
                }}
                className="font-semibold outline-none"
              >
                Update
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
