import SectionSlate from '../../../common/SectionSlatePreview'
import { QuoteSection as QuoteSectionType } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

interface VariantOneProps {
  section: QuoteSectionType
}

export default function VariantSeven({ section }: VariantOneProps) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="md:container md:mx-auto px-4 md:px-0">
        <div className=" bg-white mx-auto lg:px-20 items-center justify-center ">
          <p className="text-gray-700 md:text-2xl text-xl text-center font-semibold">
            <SectionSlate initialValue={subTitle} previewMode />
          </p>
          <p className="text-zinc-800 md:text-2xl lg:w-5/6 w-full lg:mx-auto px-5 antialiased text-center ">
            <SectionSlate initialValue={title} previewMode />
          </p>
        </div>
      </div>
    </div>
  )
}
