function Reset({ setShowPage }: any) {
  return (
    <form className="space-y-8">
      <div className="text-[20px] text-white text-center opacity-80">
        <p>Reset Account Password</p>
      </div>
      <div className="w-[265px] flex justify-between">
        <input
          className="w-[39px] h-[39px] bg-[#FFFFFF] text-[28px] text-center rounded focus:outline-none outline-0"
          type="text"
          placeholder=""
        />
        <input
          className="w-[39px] h-[39px] bg-[#FFFFFF] text-[28px] text-center rounded focus:outline-none outline-0"
          type="text"
          placeholder=""
        />
        <input
          className="w-[39px] h-[39px] bg-[#FFFFFF] text-[28px] text-center rounded focus:outline-none outline-0"
          type="text"
          placeholder=""
        />
        <input
          className="w-[39px] h-[39px] bg-[#FFFFFF] text-[28px] text-center rounded focus:outline-none outline-0"
          type="text"
          placeholder=""
        />
        <input
          className="w-[39px] h-[39px] bg-[#FFFFFF] text-[28px] text-center rounded focus:outline-none outline-0"
          type="text"
          placeholder=""
        />
        <input
          className="w-[39px] h-[39px] bg-[#FFFFFF] text-[28px] text-center rounded focus:outline-none outline-0"
          type="text"
          placeholder=""
        />
      </div>
      <div className="w-[265px]">
        <input
          className="w-full px-5 py-2.5 bg-[#FFFFFF] text-[15px] rounded focus:outline-none outline-0"
          type="password"
          placeholder="New Password"
        />
        <p className="text-[10px] text-[#FFFFFF] opacity-70 pt-2">
          Passwords should atleast 1 character and 1 capital letter{' '}
        </p>
      </div>
      <div className="w-[265px]">
        <button className="w-full py-2 bg-[#E90570] text-white text-[20px] rounded" type="button">
          Reset
        </button>
      </div>
      <div className="w-[265px]">
        <button
          onClick={() => setShowPage('find')}
          className="w-full py-2 bg-[#D9D9D9] text-[#000000] text-[20px] border-3 border-white rounded"
          type="button"
        >
          Look up Your Account
        </button>
      </div>
    </form>
  )
}

export default Reset
