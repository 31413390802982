import Icon from './icons'
import { IconPropsT } from './types'

function SendBox(props: IconPropsT) {
  const { title = 'SendBox', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 120 121" size={size} title={title} {...otherProps}>
      <path
        d="M111 0H9C4.02944 0 0 4.02944 0 9V111C0 115.971 4.02944 120 9 120H111C115.971 120 120 115.971 120 111V9C120 4.02944 115.971 0 111 0Z"
        fill="#D9D9D9"
        stroke=""
      />
      <path
        d="M80.6362 44.1354C79.5218 43.7265 78.3021 43.7054 77.1743 44.0755L35.1893 57.8698C34.1787 58.2013 33.2924 58.8307 32.6462 59.6754C31.9999 60.52 31.6243 61.5405 31.5686 62.6026C31.5129 63.6647 31.7794 64.7188 32.3337 65.6265C32.8881 66.5342 33.7041 67.2528 34.6745 67.6883L51.662 75.3184L58.7006 92.562C59.1022 93.5474 59.7924 94.3883 60.6807 94.9742C61.569 95.56 62.6144 95.8635 63.6783 95.8447C64.7423 95.8259 65.7752 95.4856 66.6423 94.8687C67.5093 94.2518 68.1696 93.3871 68.5361 92.3881L83.7695 50.9007C84.008 50.2507 84.1161 49.5601 84.0876 48.8682C84.0591 48.1764 83.8951 47.4969 83.6039 46.8687C83.3128 46.2404 82.9003 45.6757 82.3909 45.2067C81.8815 44.7377 81.2851 44.3737 80.635 44.1354L80.6362 44.1354ZM65.2339 91.1735C65.1117 91.5064 64.8915 91.7946 64.6026 92.0002C64.3136 92.2057 63.9692 92.319 63.6146 92.3253C63.2601 92.3315 62.9122 92.2304 62.6162 92.0352C62.3202 91.8399 62.0898 91.5598 61.956 91.2315L55.0967 74.4421L80.3276 50.0683L65.2339 91.1735ZM52.6575 71.9123L36.1157 64.476C35.7931 64.33 35.5222 64.0898 35.3385 63.7871C35.1548 63.4843 35.0668 63.1332 35.0863 62.7796C35.1057 62.426 35.2316 62.0865 35.4474 61.8057C35.6632 61.525 35.959 61.316 36.2957 61.2062L77.8946 47.5336L52.6636 71.9074L52.6575 71.9123Z"
        fill="white"
        stroke="none"
      />
      <path
        d="M79.6362 42.1354C78.5218 41.7265 77.3021 41.7054 76.1743 42.0755L34.1893 55.8698C33.1787 56.2013 32.2924 56.8307 31.6462 57.6754C30.9999 58.52 30.6243 59.5405 30.5686 60.6026C30.5129 61.6647 30.7794 62.7188 31.3337 63.6265C31.8881 64.5342 32.7041 65.2528 33.6745 65.6883L50.662 73.3184L57.7006 90.562C58.1022 91.5474 58.7924 92.3883 59.6807 92.9742C60.569 93.56 61.6144 93.8635 62.6783 93.8447C63.7423 93.8259 64.7752 93.4856 65.6423 92.8687C66.5093 92.2518 67.1696 91.3871 67.5361 90.3881L82.7695 48.9007C83.008 48.2507 83.1161 47.5601 83.0876 46.8682C83.0591 46.1764 82.8951 45.4969 82.6039 44.8687C82.3128 44.2404 81.9003 43.6757 81.3909 43.2067C80.8815 42.7377 80.2851 42.3737 79.635 42.1354L79.6362 42.1354ZM64.2339 89.1735C64.1117 89.5064 63.8915 89.7946 63.6026 90.0002C63.3136 90.2057 62.9692 90.319 62.6146 90.3253C62.2601 90.3315 61.9122 90.2304 61.6162 90.0352C61.3202 89.8399 61.0898 89.5598 60.956 89.2315L54.0967 72.4421L79.3276 48.0683L64.2339 89.1735ZM51.6575 69.9123L35.1157 62.476C34.7931 62.33 34.5222 62.0898 34.3385 61.7871C34.1548 61.4843 34.0668 61.1332 34.0863 60.7796C34.1057 60.426 34.2316 60.0865 34.4474 59.8057C34.6632 59.525 34.959 59.316 35.2957 59.2062L76.8946 45.5336L51.6636 69.9074L51.6575 69.9123Z"
        fill="#060606"
        stroke="none"
      />
    </Icon>
  )
}

export default SendBox
