import { useState } from 'react'
import { IoClose, IoMenu, IoSearch } from 'react-icons/io5'
import FontLoader from '../FontLoader'
import { MenuVariantProps, NewMenuLinkType } from '../menu'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../utils'

export default function MenuVariantTwelve({ menu, website }: MenuVariantProps) {
  return (
    <>
      <FontLoader settings={menu?.settings?.fontFamily} />
      <DesktopNavbar menu={menu} website={website} />
      <MobileNavbar menu={menu} website={website} />
    </>
  )
}

const DesktopNavbar = ({ menu, website }: MenuVariantProps) => {
  return (
    <div
      className="hidden lg:block"
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '#d2d7d77e' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className="flex justify-between px-12 items-center py-6">
        <div>
          <a href="/">
            {website?.config?.logo ? (
              <img
                src={website?.config?.logo}
                alt={website?.name}
                className="cursor-pointer w-[80px] h-[80px] rounded-full origin-center"
              />
            ) : (
              <p
                className="uppercase p-4 font-bold text-xl"
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#935600' }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                }}
              >
                {website?.name}
              </p>
            )}
          </a>
        </div>
        <div>
          <ul className="flex items-center w-full list-none gap-x-5 ">
            {menu.links.map(({ id, name, type, url }) => {
              return (
                <li
                  key={id}
                  className="bg-[#fafafaf9] p-4 hover:text-[#eee] transition-all duration-300 border"
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#cd9343' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                    borderColor: getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#935600' }).color,
                  }}
                >
                  <a href={url} target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}>
                    {name}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>

        <div className="flex items-center gap-x-6 ">
          <input
            type="text"
            placeholder="search..."
            className="px-8 py-4 border-4 relative outline-none placeholder:text-xl w-[100%] placeholder:text-gray-400 text-xl bg-[#fafafaf9] rounded-xl"
            style={{
              borderColor: getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#935600' }).color,
            }}
          />
          <span className="absolute right-20 text-xl text-gray-400">
            <IoSearch color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#935600' }).color} />
          </span>
        </div>
      </div>
    </div>
  )
}

const MobileNavbar = ({ menu, website }: MenuVariantProps) => {
  const [show, setShow] = useState(false)
  return (
    <div
      className="block lg:hidden"
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'white' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className="flex justify-between px-4 py-4">
        <div>
          <a href="/">
            {website?.config?.logo ? (
              <img
                src={website?.config?.logo}
                alt={website?.name}
                className="cursor-pointer w-[30px] h-[30px] rounded-full origin-center"
              />
            ) : (
              <p
                className="font-bold"
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                }}
              >
                {website?.name}
              </p>
            )}
          </a>
        </div>

        <button type="button" className="outline-none text-xl" onClick={() => setShow(!show)}>
          {show ? (
            <IoClose color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color} />
          ) : (
            <IoMenu color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color} />
          )}
        </button>
      </div>
      <div
        style={{
          ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'white' }),
          ...getSpacing(menu?.settings?.spacing),
        }}
        className={`z-10 absolute w-full h-full bottom-0 duration-500 ${show ? 'left-0' : 'left-[-100%]'} top-12`}
      >
        <ul className={`py-6 list-none flex flex-col gap-y-3  justify-start absolute w-[90%]`}>
          {menu.links.map(({ id, name, url, type }) => {
            return (
              <a href={url} target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}>
                <li
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#935600' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                    borderColor: getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#eee' }).color,
                  }}
                  key={id}
                  className="transition-all px-4 text-center duration-300 ease-in-out hover:bg-[#fafafad7] w-full border bg-[#cd9343] py-1 text-[#eee]"
                >
                  {name}
                </li>
              </a>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
