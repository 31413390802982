import { Link } from 'react-router-dom'
import AuthLayout from 'src/components/layout/AuthLayout'
import UnauthenticatedPage from 'src/components/UnauthenticatedPage'

export default function RequestInvite() {
  return (
    <UnauthenticatedPage>
      <AuthLayout>
        <form className="space-y-8">
          <div className="w-[265px]">
            <input
              className="w-full px-5 py-2.5 bg-[#FFFFFF] text-[15px] rounded focus:outline-none outline-0"
              type="email"
              placeholder="Username or Email"
            />
          </div>
          <div className="w-[265px]">
            <input
              className="w-full px-5 py-2.5 bg-[#FFFFFF] text-[15px] rounded focus:outline-none outline-0"
              type="password"
              placeholder="Password"
            />
            <p className="text-[10px] text-[#FFFFFF] opacity-70 pt-2">
              Passwords should atleast 1 character and 1 capital letter{' '}
            </p>
          </div>
          <div className="w-[265px]">
            <input
              className="w-full px-5 py-2.5 bg-[#FFFFFF] text-[15px] rounded focus:outline-none outline-0"
              type="password"
              placeholder="Confirm Password"
            />
          </div>
          <div className="w-[265px]">
            <button className="w-full py-2 bg-[#E90570] text-white text-[20px] rounded" type="button">
              Request Invite
            </button>
            <p className="text-[12px] text-white text-center opacity-70 pt-3">
              <Link to="/login">Login to your account</Link>
            </p>
          </div>
        </form>
      </AuthLayout>
    </UnauthenticatedPage>
  )
}
