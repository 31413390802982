type BottomFloatingButtonProps = {
  label: string
  secondaryLabel?: string
  onSecondaryClick?: () => void
}

export default function BottomFloatingButton({
  onClick,
  label,
  onSecondaryClick,
  secondaryLabel,
  ...props
}: BottomFloatingButtonProps &
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) {
  return (
    <div className="fixed lg:max-w-[500px] h-[53px] border-t lg:h-[65px] items-center bg-black bottom-0 w-full left-0 flex justify-between">
      <button
        {...props}
        type="button"
        className="w-full font-bold my-[8px] text-center capitalize text-white text-[15px] lg:text-lg"
        onClick={onClick}
      >
        {label}
      </button>

      {onSecondaryClick && (
        <button
          {...props}
          type="button"
          className="w-full font-bold my-[8px] border-l-[2px] border-white text-center capitalize text-white text-[15px]"
          onClick={onSecondaryClick}
        >
          {secondaryLabel}
        </button>
      )}
    </div>
  )
}
