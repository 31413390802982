import { useEffect, useState } from 'react'

export default function useColorMode() {
  const [mode, setMode] = useState<'dark' | 'light' | 'system'>(localStorage.theme || 'system')

  const applyColorMode = () => {
    if (
      localStorage.theme === 'dark' ||
      (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }

  const changeColorMode = (mode: 'dark' | 'light' | 'system') => {
    setMode(mode)
    if (mode === 'system') {
      localStorage.removeItem('theme')
    } else {
      localStorage.theme = mode
    }
    applyColorMode()
  }

  useEffect(() => {
    applyColorMode()
  }, [])

  return { changeColorMode, mode }
}
