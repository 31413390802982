import { Dialog, Transition } from '@headlessui/react'
import { useState } from 'react'
import { AiFillFacebook } from 'react-icons/ai'
import { BsLinkedin, BsTwitter } from 'react-icons/bs'
import { RiWhatsappFill } from 'react-icons/ri'
import { useParams } from 'react-router-dom'
import { FacebookShareButton } from 'react-share'
import LinkedinShareButton from 'react-share/lib/LinkedinShareButton'
import TwitterShareButton from 'react-share/lib/TwitterShareButton'
import WhatsappShareButton from 'react-share/lib/WhatsappShareButton'
import ShareLink from 'src/icons/Link'
import SharePost from 'src/icons/Post'
import Share from 'src/icons/Share'

type TPostProps = {
  post?: boolean
  size?: number | string
  page?: string
  onPostShareClick?: () => void
  postId?: any
  contact?: any
}

function ShareModal({ contact, post = false, size = 22, page, onPostShareClick, postId }: TPostProps) {
  const params = useParams()
  const websiteID = params?.id as string
  const profileAddress = `https://staging.d30zyboiwgk246.amplifyapp.com/profile/${contact?.id}`

  const linkAddress = postId
    ? `https://staging.d30zyboiwgk246.amplifyapp.com/posts/${postId}`
    : `https://staging.d30zyboiwgk246.amplifyapp.com/website/${websiteID}/preview`
  const [copyTitle, setCopyTitle] = useState(false)

  const [isOpen, setIsOpen] = useState(false)
  const closeModal = () => {
    setIsOpen(false)
  }
  const openModal = () => {
    setIsOpen(true)
  }
  const copyLinkAddress = () => {
    navigator.clipboard.writeText(contact ? profileAddress : linkAddress)
    setCopyTitle(true)
    setTimeout(() => {
      setCopyTitle(false)
    }, 1000)
  }

  return (
    <>
      <button onClick={openModal} type="button">
        <Share size={size} className={`${page === 'preview' ? 'md:text-[#fff]' : 'stroke-black dark:stroke-white'} `} />
      </button>
      <Transition appear show={isOpen}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-white/90 dark:bg-black/90" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div
              className={`fixed z-50 bottom-[12%] left-1/2 translate-x-[-50%] border border- px-2 py-1 bg-[#D9D9D9] duration-500 rounded-md ${
                copyTitle ? 'opacity-100' : 'opacity-0 ease-in'
              }`}
            >
              Link Copied
            </div>
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full transform overflow-hidden rounded-2xl bg-white dark:bg-black border-4 border-[#4E4E4E] px-6 md:px-12 py-6 text-left align-middle shadow-xl transition-all">
                  <div className="py-6 space-y-8">
                    {post && (
                      <div className="space-y-6">
                        <div className="flex items-center gap-x-6">
                          <SharePost size={32} className="text-[#E90570] dark:text-[#E90570]" />
                          <p className="text-[30px] md:text-[40px] dark:text-white">Post</p>
                        </div>
                        <div className="text-center">
                          <button
                            className="w-[300px] md:w-[325px] bg-[#D9D9D9] rounded-md p-1 text-[24px] md:text-[30px]"
                            type="button"
                            onClick={() => {
                              onPostShareClick?.()
                              closeModal()
                            }}
                          >
                            <span className="border border-black py-2 block rounded-md">Create a Post </span>
                          </button>
                        </div>
                      </div>
                    )}

                    <div className="space-y-6">
                      <div className="flex items-center gap-x-6">
                        <Share size={32} className="text-[#E90570] dark:text-[#E90570]" />
                        <p className="text-[30px] md:text-[40px] dark:text-white">Share</p>
                      </div>
                      <div className="grid grid-cols-2 md:grid-cols-4 justify-items-center gap-8">
                        <button
                          type="button"
                          className="bg-[#D9D9D9] rounded-md flex justify-center items-center w-[120px] h-[120px]"
                        >
                          <span className="flex justify-center items-center border border-black rounded-md w-[114px] h-[114px]">
                            <FacebookShareButton
                              url={contact ? profileAddress : linkAddress}
                              hashtag="#created_by_techboom_labs_2022"
                            >
                              <AiFillFacebook size="80" />
                            </FacebookShareButton>
                          </span>
                        </button>
                        <button
                          type="button"
                          className="bg-[#D9D9D9] rounded-md flex justify-center items-center w-[120px] h-[120px]"
                        >
                          <span className="flex justify-center items-center border border-black rounded-md w-[114px] h-[114px]">
                            <TwitterShareButton url={contact ? profileAddress : linkAddress}>
                              <BsTwitter size="80" />
                            </TwitterShareButton>
                          </span>
                        </button>
                        <button
                          type="button"
                          className="bg-[#D9D9D9] rounded-md flex justify-center items-center w-[120px] h-[120px]"
                        >
                          <span className="flex justify-center items-center border border-black rounded-md w-[114px] h-[114px]">
                            <WhatsappShareButton url={contact ? profileAddress : linkAddress}>
                              <RiWhatsappFill size="80" />
                            </WhatsappShareButton>
                          </span>
                        </button>
                        <button
                          type="button"
                          className="bg-[#D9D9D9] rounded-md flex justify-center items-center w-[120px] h-[120px]"
                        >
                          <span className="flex justify-center items-center border border-black rounded-md w-[114px] h-[114px]">
                            <LinkedinShareButton url={contact ? profileAddress : linkAddress}>
                              <BsLinkedin size="70" />
                            </LinkedinShareButton>
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="space-y-6">
                      <div className="flex items-center gap-x-6">
                        <ShareLink size={32} className="text-[#E90570] dark:text-[#E90570]" />
                        <p className="text-[30px] md:text-[40px] dark:text-white">
                          {contact ? 'Profile Link' : 'Page Link'}
                        </p>
                      </div>
                      <div className="flex justify-between items-center gap-4 md:gap-6 px-4 md:px-6 py-3 bg-[#D9D9D9] rounded-md">
                        <input
                          type="text"
                          value={contact ? profileAddress : linkAddress}
                          className="w-full bg-[#D9D9D9] focus:outline-0 text-[20px]"
                          readOnly
                        />
                        <button type="button" onClick={() => copyLinkAddress()}>
                          <svg
                            className="w-[24px] h-[24px] md:w-[42px] md:h-[42px]"
                            viewBox="0 0 42 42"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect x="0.5" y="0.5" width="28.0769" height="28.0769" fill="#D9D9D9" stroke="#E90570" />
                            <rect x="12.9238" y="12.9231" width="29.0769" height="29.0769" fill="#E90570" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
export default ShareModal
