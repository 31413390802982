import Icon from './icons'
import { IconPropsT } from './types'

function Delete(props: IconPropsT) {
  const { title = 'Delete', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 14 21" size={size} title={title} {...otherProps}>
      <rect x="0.5" y="6.16602" width="13" height="14.1667" />
      <line x1="3.66211" y1="8.50586" x2="3.66211" y2="17.1612" />
      <line x1="6.82324" y1="8.50586" x2="6.82324" y2="16.4954" />
      <line x1="9.98535" y1="8.50586" x2="9.98535" y2="17.1612" />
      <rect x="0.5" y="3.83398" width="13" height="2.5" />
      <rect x="4" y="1" width="6" height="2.5" />
    </Icon>
  )
}

export default Delete
