import { motion } from 'framer-motion'
import { useState } from 'react'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { PortfolioItemsGroup, PortfolioSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
export default function VariantFour({ section }: { section: PortfolioSection }) {
  const data = section.variables.find(variable => variable.name === 'ITEMS')?.data as PortfolioItemsGroup[]
  const [isHover, setIsHover] = useState(false)
  const [isShow, setIsShow] = useState(false)

  const handleMouseEnter = () => {
    setIsHover(true)
    setIsShow(true)
  }
  const handleMouseLeave = () => {
    setIsHover(false)
    setIsShow(false)
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 200 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1, type: 'spring' }}
      className="py-12 pb-20 text-[silver]"
      style={{
        ...generateSectionBackground({
          defaultColor: '#000',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <motion.ol className="lg:marker:text-7xl lg:px-6 marker:text-2xl lg:pl-12  lg:w-[94%]">
        {data.map(item => {
          return (
            <motion.li
              initial={{ y: 20 }}
              animate={{ y: 0, transition: { duration: 1 } }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className={`relative py-8 lg:py-24 cursor-pointer  transition-all duration-300 z-50 ${
                isHover && 'hover:opacity-100 opacity-10'
              }`}
              key={item.id}
            >
              <div className="py-4 flex justify-between  items-center lg:pr-0 m-auto">
                <h1 className="uppercase font-semibold text-2xl lg:pt-[-60px] lg:text-8xl ">
                  <SectionSlatePreview initialValue={item.title} previewMode />
                </h1>
                <div className="uppercase text-lg lg:text-7xl lg:mb-[-38px] lg:pr-20 pr-8 mb-[-40px]">
                  <SectionSlatePreview initialValue={item.descriptions} previewMode />
                </div>
              </div>
              <hr
                className=" bg-white w-[95%] mt-2 "
                style={{
                  ...generateSectionBackground({
                    defaultColor: '#000',
                    backgroundSettings: section?.settings?.color?.background,
                    image: null,
                  }),
                }}
              />
              <div>
                {isShow && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, zIndex: 0 }}
                    transition={{ duration: 0.5 }}
                    className=" transition-all"
                  >
                    <div className="absolute w-[220px] h-[150px] lg:w-[760px] lg:h-[480px] lg:top-[-10px] top-[10px] transition-all right-[2%] lg:right-[5%]   opacity-50">
                      <img
                        className="lg:h-[480px] lg:w-[760px] object-cover object-center mr-auto"
                        src={item.image}
                        alt=""
                      />
                    </div>
                  </motion.div>
                )}
              </div>
            </motion.li>
          )
        })}
      </motion.ol>
    </motion.div>
  )
}
