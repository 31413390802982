import { motion } from 'framer-motion'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import SectionSlate from '../../../common/SectionSlatePreview'
import { BlogItemsGroup, BlogSection as BlogSectionType } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantThree({ section }: { section: BlogSectionType }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const items = section.variables.find(variable => variable.name === 'ITEMS')?.data as BlogItemsGroup[]
  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: '#E4E1DF',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
      className="py-3 lg:py-6 overflow-x-hidden"
    >
      <div className="lg:px-[100px] md:px-[50px] px-4">
        {title ? (
          <h1 className="lg:text-5xl text-3xl lg:text-left text-center font-sub font-bold py-2">
            <span>
              <SectionSlate initialValue={title} previewMode />
            </span>
          </h1>
        ) : (
          ''
        )}

        <div className="container mx-auto ">
          {items?.map((item, index) => {
            switch (index % 2) {
              case 0:
                return (
                  <div key={item.id} className="grid grid-cols-1 lg:grid-cols-3 items-end mt-5">
                    <motion.div
                      initial={{ x: 200 }}
                      whileInView={{ x: 0 }}
                      transition={{ type: 'spring', stiffness: 80 }}
                      viewport={{ once: true }}
                      className="mb-2 lg:mb-9 md:mb-5  lg:text-left lg:block hidden"
                    >
                      <h1 className="md:text-4xl font-sub font-bold uppercase">
                        <SectionSlatePreview initialValue={item?.name} previewMode />
                      </h1>
                      <p className="md:py-6 lg:pr-4">
                        <SectionSlatePreview initialValue={item?.description} previewMode />
                      </p>
                    </motion.div>

                    <motion.img
                      initial={{ x: 200 }}
                      whileInView={{ x: 0 }}
                      transition={{ type: 'spring', stiffness: 80 }}
                      viewport={{ once: true }}
                      src={item.image}
                      className="lg:h-[750px] h-[400px] w-full rounded-md pr-0  md:pr-10 justify-end col-span-2"
                    />

                    <motion.div
                      initial={{ x: 200 }}
                      whileInView={{ x: 0 }}
                      transition={{ type: 'spring', stiffness: 80 }}
                      viewport={{ once: true }}
                      className="mb-2 lg:mb-9 md:mb-5  lg:text-left mt-4 md:mt-0 lg:mt-0 lg:hidden"
                    >
                      <h1 className="md:text-4xl text-2xl font-sub font-bold uppercase">
                        <SectionSlatePreview initialValue={item?.name} previewMode />
                      </h1>
                      <p className="md:py-6 lg:pr-4">
                        <SectionSlatePreview initialValue={item?.description} previewMode />
                      </p>
                    </motion.div>
                  </div>
                )
              case 1:
                return (
                  <div key={item.id} className="hero-content grid grid-cols-1 lg:grid-cols-3 items-end mt-10">
                    <motion.img
                      initial={{ x: 200 }}
                      whileInView={{ x: 0 }}
                      transition={{ type: 'spring', stiffness: 80 }}
                      viewport={{ once: true }}
                      src={item.image}
                      className="lg:h-[750px] h-[400px] w-full rounded-md pr-0  md:pr-10 justify-start col-span-2"
                    />
                    <motion.div
                      initial={{ x: 200 }}
                      whileInView={{ x: 0 }}
                      transition={{ type: 'spring', stiffness: 80 }}
                      viewport={{ once: true }}
                      className="mb-2 lg:mb-9 md:mb-5  lg:text-left mt-4 md:mt-0 lg:mt-0 "
                    >
                      <h1 className="md:text-4xl text-2xl font-sub font-bold uppercase">
                        <SectionSlatePreview initialValue={item?.name} previewMode />
                      </h1>
                      <p className="md:py-6 lg:pr-4">
                        <SectionSlatePreview initialValue={item?.description} previewMode />
                      </p>
                    </motion.div>
                  </div>
                )
            }
          })}
        </div>
      </div>
    </div>
  )
}
