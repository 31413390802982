import Icon from './icons'
import { IconPropsT } from './types'

function TripleGrid(props: IconPropsT) {
  const { title = 'TripleGrid', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 120 120" size={size} title={title} {...otherProps}>
      <mask
        id="mask0_1280_13019"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="120"
        height="120"
      >
        <path
          d="M9 0.5H111C115.694 0.5 119.5 4.30558 119.5 9V111C119.5 115.694 115.694 119.5 111 119.5H9C4.30558 119.5 0.5 115.694 0.5 111V9C0.5 4.30558 4.30558 0.5 9 0.5Z"
          fill="#D9D9D9"
          stroke="black"
        />
      </mask>
      <g mask="url(#mask0_1280_13019)">
        <path
          d="M9 0.5H111C115.694 0.5 119.5 4.30558 119.5 9V111C119.5 115.694 115.694 119.5 111 119.5H9C4.30558 119.5 0.5 115.694 0.5 111V9C0.5 4.30558 4.30558 0.5 9 0.5Z"
          fill="#D9D9D9"
          stroke="black"
        />
        <rect x="7.5" y="8.5" width="31.2727" height="24" rx="5.5" fill="black" stroke="black" />
        <rect x="8.40894" y="9.40918" width="29.9091" height="22.6364" rx="5.5" fill="white" stroke="black" />
        <rect x="7.5" y="39.5" width="31.2727" height="24" rx="5.5" fill="black" stroke="black" />
        <rect x="8.40894" y="40.4092" width="29.9091" height="22.6364" rx="5.5" fill="white" stroke="black" />
        <rect x="7.5" y="70.5" width="31.2727" height="24" rx="5.5" fill="black" stroke="black" />
        <rect x="8.40894" y="71.4092" width="29.9091" height="22.6364" rx="5.5" fill="white" stroke="black" />
        <rect x="7.5" y="101.5" width="31.2727" height="24" rx="5.5" fill="black" stroke="black" />
        <rect x="8.40894" y="102.409" width="29.9091" height="22.6364" rx="5.5" fill="white" stroke="black" />
        <rect x="44.5" y="8.5" width="31.2727" height="24" rx="5.5" fill="black" stroke="black" />
        <rect x="45.4089" y="9.40918" width="29.9091" height="22.6364" rx="5.5" fill="white" stroke="black" />
        <rect x="44.5" y="39.5" width="31.2727" height="24" rx="5.5" fill="black" stroke="black" />
        <rect x="45.4089" y="40.4092" width="29.9091" height="22.6364" rx="5.5" fill="white" stroke="black" />
        <rect x="44.5" y="70.5" width="31.2727" height="24" rx="5.5" fill="black" stroke="black" />
        <rect x="45.4089" y="71.4092" width="29.9091" height="22.6364" rx="5.5" fill="white" stroke="black" />
        <rect x="44.5" y="101.5" width="31.2727" height="24" rx="5.5" fill="black" stroke="black" />
        <rect x="45.4089" y="102.409" width="29.9091" height="22.6364" rx="5.5" fill="white" stroke="black" />
        <rect x="81.5" y="8.5" width="31.2727" height="24" rx="5.5" fill="black" stroke="black" />
        <rect x="82.4089" y="9.40918" width="29.9091" height="22.6364" rx="5.5" fill="white" stroke="black" />
        <rect x="81.5" y="39.5" width="31.2727" height="24" rx="5.5" fill="black" stroke="black" />
        <rect x="82.4089" y="40.4092" width="29.9091" height="22.6364" rx="5.5" fill="white" stroke="black" />
        <rect x="81.5" y="70.5" width="31.2727" height="24" rx="5.5" fill="black" stroke="black" />
        <rect x="82.4089" y="71.4092" width="29.9091" height="22.6364" rx="5.5" fill="white" stroke="black" />
        <rect x="81.5" y="101.5" width="31.2727" height="24" rx="5.5" fill="black" stroke="black" />
        <rect x="82.4089" y="102.409" width="29.9091" height="22.6364" rx="5.5" fill="white" stroke="black" />
      </g>
    </Icon>
  )
}

export default TripleGrid
