import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { ISignUpUser } from 'src/lib/types/types'
import GoogleLogin from 'src/pages/GoogleLogin'
import InputText from 'src/_polly/components/src/common/InputText'
import { Loader } from '../loader'

interface IProps {
  onSubmit: (user: ISignUpUser) => void
  loading: boolean
  error?: string
}

const SignInForm: React.FC<IProps> = ({ onSubmit, loading, error }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm()

  const password = useRef({})
  password.current = watch('password', '')

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  return (
    <>
      <Loader show={loading} />
      <form className="space-y-8" onSubmit={handleSubmit(onSubmit)}>
        <div className="w-[265px]">
          <InputText
            authInput
            placeholder="First Name"
            name="firstName"
            register={register}
            errors={errors}
            required={{ value: true, message: 'First name is required' }}
          />
        </div>
        <div className="w-[265px]">
          <InputText
            authInput
            placeholder="Last Name"
            name="lastName"
            register={register}
            errors={errors}
            required={{ value: true, message: 'Last name is required' }}
          />
        </div>
        <div className="w-[265px]">
          <InputText
            authInput
            placeholder="Email"
            name="email"
            register={register}
            errors={errors}
            required={{ value: true, message: 'Email is required' }}
            pattern={{
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address',
            }}
          />
        </div>
        <div className="w-[265px] relative">
          <InputText
            authInput
            placeholder="Password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            register={register}
            errors={errors}
            required={{ value: true, message: 'Password is required' }}
            minLength={{ value: 8, message: 'Password must be 8-32 characters long' }}
            maxLength={{ value: 32, message: 'Password must be 8-32 characters long' }}
          />
          <div className="absolute right-4 top-2.5">
            <button type="button" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <IoEyeOutline size={20} /> : <IoEyeOffOutline size={20} />}
            </button>
          </div>
        </div>
        <div className="w-[265px] relative">
          <InputText
            authInput
            placeholder="Confirm Password"
            name="confirmPassword"
            type={showConfirmPassword ? 'text' : 'password'}
            register={register}
            errors={errors}
            required={{ value: true, message: 'Confirm password is required' }}
            minLength={{ value: 8, message: 'Confirm password must be 8-32 characters long' }}
            maxLength={{ value: 32, message: 'Confirm password must be 8-32 characters long' }}
            validate={(value: any) => value === password.current || 'The passwords do not match'}
          />
          <div className="absolute right-4 top-2.5">
            <button type="button" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
              {showConfirmPassword ? <IoEyeOutline size={20} /> : <IoEyeOffOutline size={20} />}
            </button>
          </div>
          <p className="text-[10px] text-white opacity-70 pt-2">
            Passwords should atleast 1 character and 1 capital letter{' '}
          </p>
        </div>
        {error && <span className="text-secondary.main text-center w-[265px]">{error}</span>}
        <div className="w-[265px]">
          <button
            disabled={loading}
            className="w-full py-2 bg-brand-accent text-white text-[20px] rounded"
            type="submit"
          >
            Register
          </button>
          <p className="text-[12px] text-white text-center opacity-70 py-3">
            <Link to="/login">Login to your account</Link>
          </p>
          <GoogleLogin isSignUp />
        </div>
      </form>
    </>
  )
}

export default SignInForm
