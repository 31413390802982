import { PortfolioItemsGroup, PortfolioSection } from '../../types'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'
import { FreeMode } from 'swiper'
import { motion } from 'framer-motion'
import SectionSlate from '../../../common/SectionSlatePreview'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantFive({ section }: { section: PortfolioSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const data = section.variables.find(variable => variable.name === 'ITEMS')?.data as PortfolioItemsGroup[]
  const backgroundHr = section.variables.find(variable => variable.name === 'HRTITLEBACKGROUND')?.data

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
      }}
    >
      <div
        className="flex flex-col lg:px-4 py-10"
        style={{
          ...generateSectionSpacing(section?.settings?.spacing),
        }}
      >
        <motion.div
          initial={{ y: -30 }}
          animate={{ y: 0, transition: { duration: 1 } }}
          className="flex flex-col gap-y-2  lg:gap-y-5 pb-6  text-center "
        >
          <h1 style={{ color: backgroundHr }} className="text-black text-[32px] md:text-4xl font-bold  ">
            <SectionSlate initialValue={title} previewMode />
          </h1>
          <p className="text-sm lg:text-2xl ">
            <SectionSlate initialValue={paragraph} previewMode />
          </p>
        </motion.div>
        <Swiper slidesPerView={3} spaceBetween={10} freeMode={true} modules={[FreeMode]} className="mySwiper w-[100%]">
          {data.map(item => {
            return (
              <SwiperSlide
                key={item.id}
                className="overflow-hidden cursor-grab shadow-lg border-2 border-gray-200 xl:p-4 p-1 w-[400px]"
              >
                <img
                  className="lg:h-[400px] h-[70px] object-center  transition-all opacity-90 hover:opacity-100 duration-700 object-cover w-[700px]"
                  src={item.image}
                  alt=""
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}
