import { useContext, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { getCurrentUser } from 'src/services/user.service'

import { AuthContext } from './AuthContext'
import { NotificationsContextProvider } from './context/NotificationContext'
import { UserContextProvider } from './context/UserContext'
import { Loader } from './loader'

export default function PrivatePage() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  const { authState } = useContext(AuthContext)

  const checkProfileSetup = async () => {
    const user = await getCurrentUser()
    if (!user?.username) navigate('/profile-setup', { replace: true })
    setLoading(false)
  }

  useEffect(() => {
    if (authState === 'unauthenticated') {
      navigate('/login', { replace: true })
    } else {
      checkProfileSetup()
    }
  }, [authState, navigate])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (
    <UserContextProvider>
      <NotificationsContextProvider>{loading ? <Loader show /> : <Outlet />}</NotificationsContextProvider>
    </UserContextProvider>
  )
}
