import { motion } from 'framer-motion'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { ContactUsSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantFourteen({ section }: { section: ContactUsSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subtitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data

  return (
    <>
      <div
        style={{
          ...generateSectionBackground({
            defaultColor: 'white',
            backgroundSettings: section?.settings?.color?.background,
            image: media ?? null,
          }),
          ...generateSectionSpacing(section?.settings?.spacing),
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
          backgroundPosition: 'center',
          backgroundOrigin: 'border-box',
        }}
        className="bg-cover bg-center bg-no-repeat min-h-[300px] md:min-h-[760px] w-full"
      >
        <div className="bg-[#03030366]  flex flex-col justify-center items-center md:min-h-[760px] min-h-[300px] w-[100%] p-6 md:px-4">
          <motion.div
            initial={{ y: -1000 }}
            animate={{
              y: 0,
              transition: {
                duration: 1.2,
              },
            }}
            className="border-2 gap-y-5 rounded-[10px] py-4 md:min-w-[800px] min-w-[250px] max-w-[95%] overflow-hidden text-ellipsis  md:min-h-[350px] max-h-[90%] bg-[#0000007c] justify-center items-center  border-[#eee] flex flex-col"
          >
            {subtitle && (
              <p className="md:text-2xl text-white w-[85%] text-center">
                <SectionSlatePreview initialValue={subtitle} previewMode />
              </p>
            )}
            {title && (
              <h1 className="md:text-4xl text-3xl text-white text-center font-semibold md:w-[80%] px-4">
                <SectionSlatePreview initialValue={title} previewMode />
              </h1>
            )}
          </motion.div>
        </div>
      </div>
    </>
  )
}
