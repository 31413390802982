import { debounce } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Descendant } from 'slate'
import DmUs from 'src/icons/ComponentsIcons/DmUs'
import Plus from 'src/icons/Plus'
import {
  ContactUsMap,
  ContactUsSection,
  ContactUsVariables,
  ContractSectionContract,
  InputsContactUSSection,
  SectionVariableCategories,
} from 'src/_polly/components/src/sections'
import { getGeocode, getLatLng } from 'use-places-autocomplete'
import { v4 as uuid } from 'uuid'
import { ReactComponent as DeleteIcon } from '../../assets/icons/menuBar/delete.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/menuBar/edit.svg'
import { ReactComponent as ContactUs } from '../../icons/ComponentsIcons/DmUs.svg'
import { upsertBlockByPageID } from '../../services/website.service'
import BottomFloatingButton from '../BottomFloatingButton'
import { WebsiteContext } from '../context/WebsiteContext'
import { AdvancedFeature } from '../modal/advanced'
import { MenuSaveButton } from '../modal/section/NewMenu/NewMenu'
import { ButtonForSection } from './ButtonEditor'
import { EditSection } from './EditSectionModal'
import ModalDialogV2 from './ModalDialogV2'
import SectionMediaField from './SectionField/SectionMediaField'
import SectionTextField from './SectionField/SectionTextField'
import ItemField from './SectionField/SectionSingleItemField'

interface EditSectionContactUsModalProps extends EditSection {
  section: ContactUsSection
}

type EdiContractProps = {
  data?: ContractSectionContract
  onSave: (data: Partial<ContractSectionContract>) => void
}

function ContractItem({
  data,
  onDeleteClick,
  onEditClick,
}: {
  data: ContractSectionContract
  onEditClick: () => void
  onDeleteClick: () => void
}) {
  return (
    <div className="border border-black dark:border-white h-[65px] flex justify-between">
      <div className="flex items-center">
        <div className="grid grid-cols-2 gap-1 ml-2 mr-4">
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
        </div>
        <div className="flex items-center gap-4">
          {/* <p>{data.name}</p> */}
          <ItemField initialValue={data.name} />
        </div>
      </div>

      <div className="flex">
        <button
          type="button"
          className="flex justify-center items-center w-16 border-l border-black dark:border-white"
          onClick={onEditClick}
        >
          <EditIcon />
        </button>
        <button
          type="button"
          className="flex justify-center items-center w-16 border-l border-black dark:border-white"
          onClick={onDeleteClick}
        >
          <DeleteIcon />
        </button>
      </div>
    </div>
  )
}

type FormInput = {
  name: any
  description: any
}

function EditItem({ data: contactData, onSave }: EdiContractProps) {
  const initialDataName: Descendant[] = [
    {
      type: 'paragraph',
      children: [
        {
          text: 'item name',
        },
      ],
    },
  ]
  const initialDataDescription: Descendant[] = [
    {
      type: 'paragraph',
      children: [
        {
          text: 'item description',
        },
      ],
    },
  ]
  const [nameContact, setNameContact] = useState(contactData?.name || initialDataName)
  const [descriptionContact, setDescriptionContact] = useState(contactData?.description || initialDataDescription)
  const { handleSubmit } = useForm<FormInput>()
  const onSubmit: SubmitHandler<FormInput> = value => {
    setNameContact(value?.name)
    setDescriptionContact(value?.description)
    onSave({
      ...value,
      id: contactData?.id,
      name: nameContact,
      description: descriptionContact,
    })
  }

  return (
    <div className="px-4 dark:text-white">
      <form className="pb-24" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-8">
          <div>
            <SectionTextField
              title="Title"
              initialValue={nameContact ?? initialDataName}
              onChange={value => setNameContact(value)}
              onUpdate={() => handleSubmit(onSubmit)}
            />
          </div>
          <div>
            <SectionTextField
              title="Description"
              initialValue={descriptionContact ?? initialDataDescription}
              onChange={value => setDescriptionContact(value)}
              onUpdate={() => handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </form>

      <BottomFloatingButton label={contactData?.id ? 'Save' : 'Add'} onClick={handleSubmit(onSubmit)} />
    </div>
  )
}

type EditInputsProps = {
  data?: InputsContactUSSection
  onSave: (data: Partial<InputsContactUSSection>) => void
  variantName: any
}

function InputItem({
  data,
  onDeleteClick,
  onEditClick,
}: {
  data: InputsContactUSSection
  onEditClick: () => void
  onDeleteClick: () => void
}) {
  return (
    <div className="border border-black dark:border-white h-[65px] flex justify-between">
      <div className="flex items-center">
        <div className="grid grid-cols-2 gap-1 ml-2 mr-4">
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
        </div>
        <div className="flex items-center gap-4">
          <p>{data.name ? data.name : data.placeholder}</p>
        </div>
      </div>

      <div className="flex">
        <button
          type="button"
          className="flex justify-center items-center w-16 border-l border-black dark:border-white"
          onClick={onEditClick}
        >
          <EditIcon />
        </button>
        <button
          type="button"
          className="flex justify-center items-center w-16 border-l border-black dark:border-white"
          onClick={onDeleteClick}
        >
          <DeleteIcon />
        </button>
      </div>
    </div>
  )
}

type FormInputModal = {
  name: string
  placeholder: string
  type: string
  error: string
}

function EditInput({ data, onSave }: EditInputsProps) {
  const { register, handleSubmit } = useForm<FormInputModal>()
  const onSubmit: SubmitHandler<FormInputModal> = value => {
    onSave({
      ...value,
      id: data?.id,
    })
  }

  return (
    <div className="px-4 dark:text-white">
      <form className="pb-24" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-8">
          <div>
            <h2 className="uppercase font-medium text-sm">Input Label</h2>
            <input
              className="border-b border-black dark:border-white dark:bg-black w-full py-2 outline-none mt-1"
              type="text"
              defaultValue={data?.name ? data?.name : 'input label'}
              {...register('name')}
            />
          </div>
          <div>
            <h2 className="uppercase font-medium text-sm">placeholder</h2>
            <input
              className="border-b border-black dark:border-white dark:bg-black w-full py-2 outline-none mt-1"
              defaultValue={data?.placeholder ? data?.placeholder : 'input placeholder'}
              {...register('placeholder')}
            />
          </div>
          <div>
            <h2 className="uppercase font-medium text-sm">Input Type</h2>
            <select
              className="border-b py-4 border-black dark:border-white dark:bg-black w-full  outline-none mt-1"
              defaultValue={data?.type}
              {...register('type')}
            >
              <option value="text">text</option>
              <option value="email">email</option>
              <option value="password">password</option>
              <option value="number">number</option>
              <option value="file">file</option>
              <option value="date">date</option>
              <option value="date">time</option>
              <option value="checkbox">checkbox</option>
              <option value="range">range</option>
              <option value="textarea">textarea</option>
              <option value="button">button</option>
            </select>
          </div>
          <div>
            <h2 className="uppercase font-medium text-sm">Error message</h2>
            <input
              className="border-b text-red-500 border-black dark:border-white dark:bg-black w-full py-2 outline-none mt-1"
              defaultValue={data?.error ? data?.error : 'required'}
              {...register('error')}
            />
          </div>
        </div>
      </form>

      <BottomFloatingButton label={data?.id ? 'Update' : 'Add'} onClick={handleSubmit(onSubmit)} />
    </div>
  )
}

export default function EditSectionContactUsModal({
  open,
  section: defaultValue,
  onClose: onBack,
  onUpdate,
}: EditSectionContactUsModalProps) {
  const websiteContext = useContext(WebsiteContext)

  if (!open || !defaultValue) return null
  const [section, setSection] = useState(defaultValue)
  const [updating, setUpdating] = useState(false)
  const [editItem, setEditItem] = useState<ContractSectionContract>()
  const [showAddItemModal, setShowAddItemModal] = useState(false)
  const [editInput, setEditInputs] = useState<InputsContactUSSection>()
  const [showAddInputModal, setShowAddInputModal] = useState(false)
  const [fireUpdate, setFireUpdate] = useState({
    fire: false,
    goBack: false,
  })

  const title = section.variables.find(variable => variable.name === 'TITLE')
  const subtitle = section.variables.find(variable => variable.name === 'SUBTITLE')
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')
  const map = section.variables.find(variable => variable.name === 'MAP')
  const ContractItems = section.variables.find(variable => variable.name === 'CONTRACT')
  const media = section.variables.find(variable => variable.name === 'MEDIA')
  const inputs = section.variables.find(variable => variable.name === 'INPUTS')
  const button = section.variables.find(variable => variable.name === 'BUTTON_1')
  const variantName = section?.variant

  const updateVariableData = (name: ContactUsVariables, category: SectionVariableCategories, data: any) => {
    const current = section.variables.find(variable => variable.name === name)
    if (!current) {
      setSection({
        ...section,
        variables: [...section.variables, { name, data, category, id: uuid() }],
      })
      return
    }
    const otherVariables = section.variables.filter(variable => variable.name !== name)
    const newVariables = [...otherVariables, { ...current, data }]
    setSection({ ...section, variables: newVariables })
  }

  const handleMapAddress = async (newAddress: string) => {
    const results = await getGeocode({ address: newAddress })
    const { lat, lng } = getLatLng(results[0])
    updateVariableData('MAP', 'MAP', { address: newAddress, position: { lat, lng } } as ContactUsMap)
  }

  const debouncedHandleMapAddress = debounce(handleMapAddress, 500)

  const handleEditAddContract = (data: Partial<ContractSectionContract>) => {
    if (data.id) {
      const newItems = ContractItems?.data.map((item: ContractSectionContract) => {
        if (item.id === data.id) {
          return { ...item, ...data }
        }
        return item
      })
      setSection({
        ...section,
        variables: section.variables.map(variable => {
          if (variable.name === 'CONTRACT') {
            return { ...variable, data: newItems }
          }
          return variable
        }),
      })
    } else {
      setSection({
        ...section,
        variables: section.variables.map(variable => {
          if (variable.name === 'CONTRACT') {
            return { ...variable, data: [...variable.data, { ...data, id: uuid() }] }
          }
          return variable
        }),
      })
    }
    setEditItem(undefined)
    setShowAddItemModal(false)
    handleSave(false)
  }

  const handleEditAddInput = (data: Partial<InputsContactUSSection>) => {
    if (data.id) {
      const newItems = inputs?.data.map((item: InputsContactUSSection) => {
        if (item.id === data.id) {
          return { ...item, ...data }
        }
        return item
      })
      setSection({
        ...section,
        variables: section.variables.map(variable => {
          if (variable.name === 'INPUTS') {
            return { ...variable, data: newItems }
          }
          return variable
        }),
      })
    } else {
      setSection({
        ...section,
        variables: section.variables.map(variable => {
          if (variable.name === 'INPUTS') {
            return { ...variable, data: [...variable.data, data] }
          }
          return variable
        }),
      })
    }
    setEditInputs(undefined)
    setShowAddInputModal(false)
    handleSave(false)
  }

  const handleDelete = (id: string) => {
    const newItems = ContractItems?.data.filter((item: ContractSectionContract) => item.id !== id)
    setSection({
      ...section,
      variables: section.variables.map(variable => {
        if (variable.name === 'CONTRACT') {
          return { ...variable, data: newItems }
        }
        return variable
      }),
    })
    handleSave(false)
  }

  const handleDeleteInput = (id: string) => {
    const newItems = inputs?.data.filter((item: InputsContactUSSection) => item.id !== id)
    setSection({
      ...section,
      variables: section.variables.map(variable => {
        if (variable.name === 'INPUTS') {
          return { ...variable, data: newItems }
        }
        return variable
      }),
    })
    handleSave(false)
  }

  const handleSave = async (goBack = true) => {
    setFireUpdate({ fire: true, goBack })
  }

  useEffect(() => {
    const update = async (goBack = true) => {
      setUpdating(true)
      await upsertBlockByPageID(websiteContext.pageID, section as any)
      setUpdating(false)
      setFireUpdate({ fire: false, goBack: false })
      if (goBack) onBack()
    }
    if (fireUpdate.fire) update(fireUpdate.goBack)
  }, [fireUpdate, section])

  useEffect(() => {
    onUpdate?.(section)
  }, [section])
  return (
    <>
      <ModalDialogV2 open={open} onBack={onBack}>
        <div className="px-5 dark:text-white mt-[25px]">
          <div className="border-t-[1px] border-black dark:border-white">
            {section.variant !== 'VARIANT_2' && section.variant !== 'VARIANT_6' && (
              <div>
                <SectionTextField
                  title="Title"
                  initialValue={title?.data ?? []}
                  onChange={value => updateVariableData('TITLE', 'TEXT', value)}
                  onUpdate={() => handleSave(false)}
                />
              </div>
            )}

            {section.variant !== 'VARIANT_3' &&
              section.variant !== 'VARIANT_12' &&
              section.variant !== 'VARIANT_9' &&
              section.variant !== 'VARIANT_13' &&
              section.variant !== 'VARIANT_15' && (
                <div>
                  <SectionTextField
                    title="Subtitle"
                    initialValue={subtitle?.data ?? []}
                    onChange={value => {
                      updateVariableData('SUBTITLE', 'TEXT', value)
                    }}
                    onUpdate={() => handleSave(false)}
                  />
                </div>
              )}

            {section.variant !== 'VARIANT_3' &&
              section.variant !== 'VARIANT_4' &&
              section.variant !== 'VARIANT_5' &&
              section.variant !== 'VARIANT_7' &&
              section.variant !== 'VARIANT_11' &&
              section.variant !== 'VARIANT_17' &&
              section.variant !== 'VARIANT_12' &&
              section.variant !== 'VARIANT_14' &&
              section.variant !== 'VARIANT_16' && (
                <div>
                  <SectionTextField
                    title="Paragraph"
                    initialValue={paragraph?.data ?? []}
                    onChange={value => updateVariableData('PARAGRAPH', 'TEXT', value)}
                    onUpdate={() => handleSave(false)}
                  />
                </div>
              )}

            {section.variant !== 'VARIANT_3' &&
              section.variant !== 'VARIANT_4' &&
              section.variant !== 'VARIANT_5' &&
              section.variant !== 'VARIANT_6' &&
              section.variant !== 'VARIANT_17' &&
              section.variant !== 'VARIANT_7' &&
              section.variant !== 'VARIANT_9' &&
              section.variant !== 'VARIANT_10' &&
              section.variant !== 'VARIANT_11' &&
              section.variant !== 'VARIANT_12' &&
              section.variant !== 'VARIANT_13' &&
              section.variant !== 'VARIANT_14' &&
              section.variant !== 'VARIANT_8' &&
              section.variant !== 'VARIANT_15' &&
              section.variant !== 'VARIANT_16' && (
                <div>
                  <h2 className="uppercase font-medium text-sm">Map</h2>
                  <input
                    className="border-b border-black dark:border-white dark:bg-black w-full py-2 outline-none mt-1"
                    type="text"
                    defaultValue={map?.data?.address}
                    onChange={e => debouncedHandleMapAddress(e.target.value)}
                  />
                </div>
              )}

            {section.variant !== 'VARIANT_1' &&
              section.variant !== 'VARIANT_2' &&
              section.variant !== 'VARIANT_4' &&
              section.variant !== 'VARIANT_6' &&
              section.variant !== 'VARIANT_8' &&
              section.variant !== 'VARIANT_12' &&
              section.variant !== 'VARIANT_17' &&
              section.variant !== 'VARIANT_10' &&
              section.variant !== 'VARIANT_16' &&
              section.variant !== 'VARIANT_13' && (
                <SectionMediaField
                  defaultValue={media?.data}
                  onChange={url => {
                    if (!url) {
                      setSection({
                        ...section,
                        variables: section.variables.filter(variable => variable.name !== 'MEDIA'),
                      })
                      return
                    }
                    updateVariableData('MEDIA', 'IMAGE', url)
                    handleSave(false)
                  }}
                />
              )}
            {section.variant === 'VARIANT_3' ||
            section.variant === 'VARIANT_4' ||
            section.variant === 'VARIANT_5' ||
            section.variant === 'VARIANT_6' ||
            section.variant === 'VARIANT_7' ||
            section.variant === 'VARIANT_17' ||
            section.variant === 'VARIANT_11' ||
            section.variant === 'VARIANT_12' ||
            section.variant === 'VARIANT_15' ||
            section.variant === 'VARIANT_16' ||
            section.variant === 'VARIANT_9' ? (
              <ButtonForSection
                defaultValue={button?.data}
                onButtonChange={update => updateVariableData('BUTTON_1', 'BUTTON', update)}
                onChange={update => updateVariableData('BUTTON_1', 'BUTTON', update)}
              />
            ) : null}
            {section.variant !== 'VARIANT_3' &&
              section.variant !== 'VARIANT_4' &&
              section.variant !== 'VARIANT_5' &&
              section.variant !== 'VARIANT_6' &&
              section.variant !== 'VARIANT_7' &&
              section.variant !== 'VARIANT_9' &&
              section.variant !== 'VARIANT_11' &&
              section.variant !== 'VARIANT_17' &&
              section.variant !== 'VARIANT_12' &&
              section.variant !== 'VARIANT_14' &&
              section.variant !== 'VARIANT_15' &&
              section.variant !== 'VARIANT_16' && (
                <div className="space-y-5 py-6">
                  <h2 className="capitalize text-[19px] font-semibold ">Contact Info</h2>
                  <div className="space-y-5">
                    {ContractItems?.data.map((item: ContractSectionContract) => (
                      <ContractItem
                        key={item.id}
                        data={item}
                        onDeleteClick={() => handleDelete(item.id)}
                        onEditClick={() => setEditItem(item)}
                      />
                    ))}
                  </div>
                  <button
                    className=" border border-black dark:border-white bg-black h-[65px] flex justify-between items-center text-lg px-2 w-full text-white"
                    type="button"
                    onClick={() => setShowAddItemModal(true)}
                  >
                    <h1>Add Item</h1>
                    <Plus size={40} />
                  </button>
                </div>
              )}
            {section.variant !== 'VARIANT_1' &&
              section.variant !== 'VARIANT_2' &&
              section.variant !== 'VARIANT_13' &&
              section.variant !== 'VARIANT_14' &&
              section.variant !== 'VARIANT_17' && (
                <div className="space-y-4 pb-12">
                  <h2 className="capitalize text-[19px] font-semibold ">Form Input Field</h2>
                  <div className="space-y-5">
                    {inputs?.data.map((item: InputsContactUSSection) => (
                      <InputItem
                        key={item.id}
                        data={item}
                        onDeleteClick={() => handleDeleteInput(item.id)}
                        onEditClick={() => setEditInputs(item)}
                      />
                    ))}
                  </div>
                  <button
                    className="flex justify-between border border-black dark:border-white bg-black h-[65px]  items-center text-lg px-2 w-full text-white"
                    type="button"
                    onClick={() => setShowAddInputModal(true)}
                  >
                    <h1>Add Field</h1>
                    <Plus size={40} />
                  </button>
                </div>
              )}
          </div>
        </div>
        <div className="absolute top-[16px] left-[50%] translate-x-[-50%] flex items-center justify-center">
          <ContactUs />
          <DmUs height={16} className="ml-1 mb-[-10px]" />
        </div>
        <div className="absolute top-[21px] right-[50px]">
          <button type="button" onClick={() => handleSave()} disabled={updating}>
            <MenuSaveButton save="save" />
          </button>
        </div>
        {updating && (
          <div className="fixed z-30 inset-0 lg:max-w-[500px] dark:bg-black/80 bg-white/80">
            <p className="flex items-center h-full justify-center w-full text-[19px] dark:text-white">Updating...</p>
          </div>
        )}
        <AdvancedFeature
          onBack={() => handleSave(false)}
          section={section}
          onChange={settings => setSection(s => ({ ...s, settings }))}
        />
      </ModalDialogV2>

      <ModalDialogV2
        open={editItem !== undefined || showAddItemModal}
        onBack={() => (editItem ? setEditItem(undefined) : setShowAddItemModal(false))}
      >
        <EditItem data={editItem} onSave={handleEditAddContract} />
      </ModalDialogV2>
      <ModalDialogV2
        open={editInput !== undefined || showAddInputModal}
        onBack={() => (editInput ? setEditInputs(undefined) : setShowAddInputModal(false))}
      >
        <EditInput data={editInput} onSave={handleEditAddInput} variantName={variantName} />
      </ModalDialogV2>
    </>
  )
}
