import SectionSlate from '../../../common/SectionSlatePreview'
import { ImageOmgSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantFour({ section }: { section: ImageOmgSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data

  return (
    <div
      className="py-[10px] lg:py-[50px]"
      style={{
        ...generateSectionBackground({
          defaultColor: '#000',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div
        className="flex bg-black bg-cover bg-no-repeat bg-center"
        style={{
          backgroundImage: media ? `url(${media})` : '',
        }}
      >
        <div className="flex-0 md:flex-1 hidden md:block" />
        <div className="flex-1 flex justify-center py-6 md:py-[150px] bg-black/40 h-full">
          <div className="flex justify-center gap-y-2 flex-col items-center px-4 md:px-12">
            <div className="text-[32px] md:text-4xl font-bold  text-white text-center">
              <SectionSlate initialValue={title} previewMode />
            </div>

            {paragraph && (
              <div className="text-white text-center">
                <SectionSlate initialValue={paragraph} previewMode />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
