import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { GallerySection, GallerySectionItem } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantNine({ section }: { section: GallerySection }) {
  const gallery = section.variables.find(variable => variable.name === 'MEDIA')?.data as GallerySectionItem[]
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data

  return (
    <div
      className="app__home mt-[50px] pb-[80px] pt-[20px] 
       border-t-2 border-black flex flex-col items-center justify-center"
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="app__home-header">
        <p className=" uppercase text-center  font-semibold lg:text-[40px]">
          <SectionSlatePreview initialValue={title} previewMode />
        </p>
      </div>

      <div
        className=" grid grid-rows-3 gap-7 xl:gap-4 lg:gap-4 md:gap:5
         grid-cols-2 xl:grid-cols-5  mt-10
         justify-center m-auto"
      >
        {gallery.map((data, idx) => {
          return (
            <div key={idx} className="flex items-center justify-center">
              <img src={data.image} className=" w-[80px]  xl:w-[40%] lg:w-[40%] md:w-[40%]" alt="" />
            </div>
          )
        })}
      </div>
    </div>
  )
}
