import Icon from './icons'
import { IconPropsT } from './types'

function Settings(props: IconPropsT) {
  const { title = 'Settings', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 20 20" size={size} title={title} {...otherProps}>
      <path d="M17.1401 10.9399C17.1801 10.6399 17.2001 10.3299 17.2001 9.9999C17.2001 9.6799 17.1801 9.3599 17.1301 9.0599L19.1601 7.4799C19.3401 7.3399 19.3901 7.0699 19.2801 6.8699L17.3601 3.5499C17.2401 3.3299 16.9901 3.2599 16.7701 3.3299L14.3801 4.2899C13.8801 3.9099 13.3501 3.5899 12.7601 3.3499L12.4001 0.809902C12.3601 0.569902 12.1601 0.399902 11.9201 0.399902H8.08011C7.84011 0.399902 7.65011 0.569902 7.61011 0.809902L7.25011 3.3499C6.66011 3.5899 6.12011 3.9199 5.63011 4.2899L3.24011 3.3299C3.02011 3.2499 2.77011 3.3299 2.65011 3.5499L0.74011 6.8699C0.62011 7.0799 0.66011 7.3399 0.86011 7.4799L2.89011 9.0599C2.84011 9.3599 2.80011 9.6899 2.80011 9.9999C2.80011 10.3099 2.82011 10.6399 2.87011 10.9399L0.84011 12.5199C0.66011 12.6599 0.61011 12.9299 0.72011 13.1299L2.64011 16.4499C2.76011 16.6699 3.01011 16.7399 3.23011 16.6699L5.62011 15.7099C6.12011 16.0899 6.65011 16.4099 7.24011 16.6499L7.60011 19.1899C7.65011 19.4299 7.84011 19.5999 8.08011 19.5999H11.9201C12.1601 19.5999 12.3601 19.4299 12.3901 19.1899L12.7501 16.6499C13.3401 16.4099 13.8801 16.0899 14.3701 15.7099L16.7601 16.6699C16.9801 16.7499 17.2301 16.6699 17.3501 16.4499L19.2701 13.1299C19.3901 12.9099 19.3401 12.6599 19.1501 12.5199L17.1401 10.9399ZM10.0001 13.5999C8.02011 13.5999 6.40011 11.9799 6.40011 9.9999C6.40011 8.0199 8.02011 6.3999 10.0001 6.3999C11.9801 6.3999 13.6001 8.0199 13.6001 9.9999C13.6001 11.9799 11.9801 13.5999 10.0001 13.5999Z" />
    </Icon>
  )
}

export default Settings
