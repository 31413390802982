import { IoHome, IoLogoFacebook, IoLogoInstagram, IoLogoTwitter, IoLogoYoutube } from 'react-icons/io5'
import { v4 as uuid } from 'uuid'
import { MenuFooterSettings, NewMenuLink, NewMenuLinkType, NewMenuTags } from '../menu/menu'

export type Social = 'facebook' | 'twitter' | 'instagram' | 'youtube'

export type FooterVariantProps = {
  footer: NewFooter
  website: any
}

export type NewFooterCategory = {
  id: string
  name: string
  links: NewMenuLink[]
}

export type NewFooter = {
  id: string
  categories: NewFooterCategory[]
  designedBy?: string
  styleName?: string
  tags?: NewMenuTags[]
  settings?: MenuFooterSettings
  phone?: string
  copyright?: string
  email?: string
  address?: string
  social?: Social[]
}

const variantCount = 10

export function getFooterVariants(): NewFooter[] {
  return Array(variantCount)
    .fill(null)
    .map((_, i) => {
      let categories: NewFooterCategory[] = [
        {
          id: uuid(),
          name: 'Use Cases',
          links: [
            {
              id: uuid(),
              name: 'UI design',
              url: '#',
              type: NewMenuLinkType.EXTERNAL,
            },
            {
              id: uuid(),
              name: 'UX design',
              url: '#',
              type: NewMenuLinkType.EXTERNAL,
            },
            {
              id: uuid(),
              name: 'Graphic design',
              url: '#',
              type: NewMenuLinkType.EXTERNAL,
            },
            {
              id: uuid(),
              name: 'Wireframing',
              url: '#',
              type: NewMenuLinkType.EXTERNAL,
            },
            {
              id: uuid(),
              name: 'Diagramming',
              url: '#',
              type: NewMenuLinkType.EXTERNAL,
            },
          ],
        },
        {
          id: uuid(),
          name: 'Resources',
          links: [
            {
              id: uuid(),
              name: 'Blog',
              url: '#',
              type: NewMenuLinkType.EXTERNAL,
            },
            {
              id: uuid(),
              name: 'Help Center',
              url: '#',
              type: NewMenuLinkType.EXTERNAL,
            },
            {
              id: uuid(),
              name: 'Guides',
              url: '#',
              type: NewMenuLinkType.EXTERNAL,
            },
            {
              id: uuid(),
              name: 'Events',
              url: '#',
              type: NewMenuLinkType.EXTERNAL,
            },
          ],
        },
        {
          id: uuid(),
          name: 'Company',
          links: [
            {
              id: uuid(),
              name: 'About',
              url: '#',
              type: NewMenuLinkType.EXTERNAL,
            },
            {
              id: uuid(),
              name: 'Team',
              url: '#',
              type: NewMenuLinkType.EXTERNAL,
            },
            {
              id: uuid(),
              name: 'Careers',
              url: '#',

              type: NewMenuLinkType.EXTERNAL,
            },
          ],
        },
      ]
      let tags: NewMenuTags[] = []
      const variantId = `VARIANT_${i + 1}`
      if (variantId === 'VARIANT_1') {
        tags = ['IMAGE']
      }

      let phone = '+9876543210'
      let copyright = 'copyright 2023.All rights reserved @ Techboom Team'
      let email = 'example@gmai.com'
      let address = '1600 Amphitheatre Parkway, Mountain View, CA '
      let social: Social[] = ['facebook', 'twitter', 'instagram', 'youtube']

      return {
        id: variantId,
        categories,
        tags,
        phone,
        copyright,
        email,
        address,
        social,
      }
    })
}

export function getSocialIcon(type: Social) {
  if (type === 'facebook') return IoLogoFacebook
  if (type === 'instagram') return IoLogoInstagram
  if (type === 'twitter') return IoLogoTwitter
  if (type === 'youtube') return IoLogoYoutube
  return IoHome
}

export function getSocialLabel(type: Social) {
  if (type === 'facebook') return 'Facebook'
  if (type === 'instagram') return 'Instagram'
  if (type === 'twitter') return 'Twitter'
  if (type === 'youtube') return 'Youtube'
  return type
}
