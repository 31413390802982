import { Descendant } from 'slate'
import { QuoteVariables, QuoteVariants, Section, SectionTags } from 'src/_polly/components/src/sections'
import { v4 as uuid } from 'uuid'
import { DEFAULT_VARIANT_SETTINGS, getSectionVariants } from './get-variants'
const tags: Record<QuoteVariants, SectionTags[]> = {
  VARIANT_1: ['IMAGE'],
  VARIANT_2: ['IMAGE'],
  VARIANT_3: ['IMAGE'],
  VARIANT_4: ['IMAGE'],
  VARIANT_5: ['IMAGE'],
  VARIANT_6: [],
  VARIANT_7: [],
  VARIANT_8: ['ANIMATION'],
  VARIANT_9: [],
  VARIANT_10: [],
}

export function getQuoteVariants() {
  const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Title' }] }]
  const subTitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Sub Title' }] }]
  const paragraph: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Paragraph' }] }]
  const variants = getSectionVariants('QUOTE_SECTION')
  const sections: Section<QuoteVariants, QuoteVariables>[] = []

  variants.forEach(variant => {
    if (variant.id === 'VARIANT_1') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Caleb McConaughey' }] }]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'At our agency, we prioritize user satisfaction, user retention, and user conversion. We offer website performance, website speed, website accessibility, website security, website maintenance, ',
            },
          ],
        },
      ]
      const media =
        'https://images.unsplash.com/photo-1545529468-42764ef8c85f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxkb2N0b3IlMjB3aXRoJTIwY2F0fGVufDB8fHx8MTY3ODA4NzEwNg&ixlib=rb-4.0.3&q=80&w=1080'

      const data: Section<QuoteVariants, QuoteVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'QUOTE_SECTION',
        updatedAt: '',
        tags: tags[variant.id as QuoteVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'DESCRIPTION' },
          { id: uuid(), category: 'IMAGE', data: media, name: 'MEDIA' },
        ],
        variant: variant.id as QuoteVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_3') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Snatched Crowdfunding UX' }] }]
      const subtitle: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'At our agency, we prioritize user satisfaction, user retention, and user conversion. We offer website performance, website speed, website accessibility, website security, website maintenance, ',
            },
          ],
        },
      ]
      const media =
        'https://images.unsplash.com/photo-1554050857-c84a8abdb5e2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxibGFja3xlbnwwfHx8fDE2NzgxMzY4ODY&ixlib=rb-4.0.3&q=80&w=1080'

      const data: Section<QuoteVariants, QuoteVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'QUOTE_SECTION',
        updatedAt: '',
        tags: tags[variant.id as QuoteVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'IMAGE', data: media, name: 'MEDIA' },
        ],
        variant: variant.id as QuoteVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_4') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'We Care In Your Pet' }] }]
      const subTitle: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Dope Investor Design is a full-service investment firm with a focus on innovative design. We invest in companies that prioritize exceptional user experience and visual appeal. Our team of experienced designers and investors work hand-in-hand to support the growth and success of our portfolio companies.',
            },
          ],
        },
      ]
      const media =
        'https://images.unsplash.com/photo-1548767797-d8c844163c4c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxwZXRzfGVufDB8fHx8MTY3ODA3MjY5OA&ixlib=rb-4.0.3&q=80&w=1080'

      const data: Section<QuoteVariants, QuoteVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'QUOTE_SECTION',
        updatedAt: '',
        tags: tags[variant.id as QuoteVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subTitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'IMAGE', data: media, name: 'MEDIA' },
        ],
        variant: variant.id as QuoteVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_5') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio. ',
            },
          ],
        },
      ]
      const subtitle: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Benjamin Cruise',
            },
          ],
        },
      ]
      const media =
        'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-5247c2b1-7157-4c8e-8fae-fe9fafa29f19'

      const data: Section<QuoteVariants, QuoteVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'QUOTE_SECTION',
        updatedAt: '',
        tags: tags[variant.id as QuoteVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'IMAGE', data: media, name: 'MEDIA' },
        ],
        variant: variant.id as QuoteVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_6') {
      const subtitle: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio.',
            },
          ],
        },
      ]
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Sleep On Disruptive Design',
            },
          ],
        },
      ]
      const data: Section<QuoteVariants, QuoteVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'QUOTE_SECTION',
        updatedAt: '',
        tags: tags[variant.id as QuoteVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
        ],
        variant: variant.id as QuoteVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_7') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Our team specializes in creating visually stunning designs that showcase your captivating creative abilities and enchanting professional-looking portfolio.',
            },
          ],
        },
      ]
      const subtitle: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Sleep On Disruptive Design',
            },
          ],
        },
      ]
      const data: Section<QuoteVariants, QuoteVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'QUOTE_SECTION',
        updatedAt: '',
        tags: tags[variant.id as QuoteVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
        ],
        variant: variant.id as QuoteVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_9') {
      const subtitle: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Hits Different Investors',
            },
          ],
        },
      ]
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Cottage Industry, Captivating Design',
            },
          ],
        },
      ]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Looking for pixel-perfect, responsive design that ensures flawless functionality .',
            },
          ],
        },
      ]
      const data: Section<QuoteVariants, QuoteVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'QUOTE_SECTION',
        updatedAt: '',
        tags: tags[variant.id as QuoteVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'DESCRIPTION' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-abc6c308-7b7c-4ea4-818e-c1d447b75ea1',
            name: 'MEDIA',
          },
        ],
        variant: variant.id as QuoteVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else {
      const media =
        'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'

      const data: Section<QuoteVariants, QuoteVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'QUOTE_SECTION',
        updatedAt: '',
        tags: tags[variant.id as QuoteVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subTitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'DESCRIPTION' },
          { id: uuid(), category: 'IMAGE', data: media, name: 'MEDIA' },
        ],
        variant: variant.id as QuoteVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    }
  })

  return sections
}
