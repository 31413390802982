import { useState } from 'react'
import useYoutubeVideos from 'src/hooks/useYoutubeVideos'

interface VideoUploaderProps {
  onSubmit?: (videoURL: string) => void
}

export default function VideosUploader(props: VideoUploaderProps) {
  const { onSubmit } = props
  const [inputValue, setInputValue] = useState<string>('reactjs')
  const [search, setSearch] = useState<string>(inputValue)
  const { videos } = useYoutubeVideos({ searchQuery: `${search}` })

  const handleVideoSearch = () => {
    setSearch(inputValue)
  }

  const handleSave = (value: string) => {
    if (onSubmit) {
      onSubmit(value)
    }
    console.log(value)
  }

  return (
    <div className="px-4">
      <input
        className="border-b border-black dark:border-white dark:bg-black dark:text-white w-full py-2 outline-none mt-1"
        type="text"
        placeholder="Search..."
        onChange={e => setInputValue(e.target.value)}
      />
      <button
        type="button"
        className="w-full mb-6 px-2 py-2 text-xl border mt-2 border-black text-center bg-black dark:border-white text-white"
        onClick={handleVideoSearch}
      >
        Search
      </button>
      <div>
        {videos?.map((video: any, idx) => (
          <div key={idx}>
            <iframe
              width="100%"
              height="350px"
              src={`https://www.youtube.com/embed/${video?.id?.videoId}`}
              title="Video"
            />
            <button
              onClick={() => handleSave(video?.id?.videoId)}
              className="w-full px-2 py-2 text-xl border my-2 border-black text-center bg-black dark:border-white text-white"
              type="button"
            >
              Select
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}
