import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Contacts from './components/Contacts'
import UserMessage from './components/UserMessage'
import BubbleChat from './components/BubbleChat'
import ChatFooter from './components/ChatFooter'
import MobileUsers from './components/MobileUsers'
import NewMessage from './components/NewMessage'
import DesktopHeader from '../posts-page/components/Header'
import './style.css'
import SearchUser from './components/SearchUser'
import BottomNavigation from '../feed/components/BottomNavigation'

export default function Messengers() {
  const [active, setActive] = useState(false)
  const [search, setSearch] = useState(false)
  const navigate = useNavigate()

  const handleContactSearch = () => {
    setSearch(!search)
  }
  const MobileHandleContactSearch = () => {
    navigate('message')
    setSearch(!search)
  }

  return (
    <>
      <div className=" hidden xl:block lg:block md:block overflow-hidden">
        <div className=" bg-black">
          <DesktopHeader />
        </div>
        <div className="flex messenger w-full bg-black">
          <div className="lg:w-[660px] md:w-[660px] xl:w-[606px] ">
            <div>
              <NewMessage setSearch={handleContactSearch} />
            </div>
            <div className="bg-black  h-[577px]">
              <div className="h-[570px]  flex flex-col overflow-y-auto overflow-x-hidden users md:pb-[6.7rem] xl:pb-12 lg:pb-28">
                <UserMessage
                  active={active}
                  onActiveClick={() => setActive(!active)}
                  name="Irma Cooper"
                  message="Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet."
                  date="Nov16"
                  time="19:00PM "
                  image="https://images.unsplash.com/photo-1503919545889-aef636e10ad4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
                />
              </div>
            </div>
          </div>

          <div className="bg-[#EFEFEF] grid grid-cols-1 w-[100%] relative ">
            <div>{search ? <SearchUser /> : <Contacts />}</div>
            <div className="flex flex-col px-[18px] chat lg:mt-0 lg:pb-12 md:mt-6  overflow-y-auto h-[550px] pb-32  overflow-x-hidden">
              <BubbleChat
                name="Irma Cooper"
                message="Amet quam id tellus et massa nulla sitconsequat.Amet quam id tellus et massa nulla sit consequat."
                date="Message sent 2:40 PM"
                image="https://images.unsplash.com/photo-1503919545889-aef636e10ad4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
                isUserMessage
              />
              <BubbleChat
                name="Irma Cooper"
                message="Amet quam id tellus et massa nulla sitconsequat.Amet quam id tellus et massa nulla sit consequat."
                date="Message sent 2:40 PM"
                image="https://images.unsplash.com/photo-1503919545889-aef636e10ad4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
              />
            </div>
            <div className="fixed w-full bottom-0">
              <ChatFooter search={search} />
            </div>
          </div>
        </div>
      </div>

      <div className="lg:hidden xl:hidden md:hidden flex flex-col h-[750px] dark:bg-black bg-white">
        <DesktopHeader />
        <div className="w-full ">
          <div className="bg-white border-[#C4C4C4] w-full flex justify-between items-center">
            {/* <NewMessage /> */}
            <NewMessage setSearch={MobileHandleContactSearch} />
          </div>
          <div className="chat bg-white dark:bg-black h-[500px] overflow-x-hidden flex flex-col overflow-y-auto">
            <MobileUsers
              active={active}
              onActiveClick={() => setActive(!active)}
              name="Irma Cooper"
              message="Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet."
              date="Nov16"
              time="19:00PM "
              image="https://images.unsplash.com/photo-1503919545889-aef636e10ad4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
            />
          </div>
        </div>
        <BottomNavigation variant="two" />
      </div>
    </>
  )
}