import { useEffect, useState } from 'react'
import ModalDialogV2 from 'src/components/new/ModalDialogV2'
import { SectionSettings } from 'src/_polly/components/src/sections'
import ColorSelector from './ColorSelector'
import TitleBar from './TitleBar'

type ThemeColorsProps = {
  defaultValue: SectionSettings['color']
  title?: string
  onChange?: (settings: SectionSettings['color']) => void
}

export default function ThemeColors({ defaultValue, onChange, title }: ThemeColorsProps) {
  const [colorSettings, setColorSettings] = useState(defaultValue)
  const [showModal, setShowModal] = useState<null | 'backgroundColor'>(null)

  const handleColorChange = (color: string) => {
    if (!showModal) return
    if (showModal === 'backgroundColor') {
      setColorSettings({
        ...colorSettings,
        background: {
          value: color,
          enabled: true,
        },
      })
    }
  }

  const handleSwitch = (enabled: boolean) => {
    if (!showModal) return
    if (showModal === 'backgroundColor') {
      setColorSettings({
        ...colorSettings,
        background: {
          ...colorSettings?.background,
          enabled,
        },
      })
    }
  }

  const getTitle = () => {
    if (showModal === 'backgroundColor') return 'Background Color'
    return 'Color'
  }

  const getDefaultColor = () => {
    if (showModal === 'backgroundColor') return colorSettings?.background?.value ?? '#fff'
    return '#fff'
  }

  const getEnabled = () => {
    if (showModal === 'backgroundColor') return colorSettings?.background?.enabled
    return false
  }

  useEffect(() => {
    onChange?.(colorSettings)
  }, [colorSettings])

  return (
    <div className="px-4 dark:text-white">
      <TitleBar title="Theme Colors" />
      <Item
        title={title || 'Background'}
        color={colorSettings?.background?.value ?? '#fff'}
        onClick={() => setShowModal('backgroundColor')}
      />

      <ModalDialogV2 open={showModal !== null} onBack={() => setShowModal(null)}>
        <ColorSelector
          title={getTitle()}
          color={getDefaultColor()}
          enabled={getEnabled()}
          onChange={handleColorChange}
          onEnabledChange={handleSwitch}
          onBack={() => setShowModal(null)}
        />
      </ModalDialogV2>
    </div>
  )
}

function Item({ title, onClick, color }: { title: string; onClick?: () => void; color: string | undefined }) {
  return (
    <div className="flex justify-between items-center border-b border-black dark:border-white py-5 px-2">
      <p className="text-xl font-light">{title}</p>
      <button type="button" onClick={onClick}>
        <div
          className="h-[30px] w-[30px] border border-black dark:border-white rounded"
          style={{
            backgroundColor: color,
          }}
        />
      </button>
    </div>
  )
}
