function FindAccount({ setShowPage }: any) {
  return (
    <form className="space-y-8">
      <div className="text-[16px] md:text-[20px] text-white text-center opacity-80">
        <p>Enter your email to reset your password.</p>
      </div>
      <div className="w-[265px] mx-auto">
        <input
          className="w-full px-5 py-2.5 bg-[#FFFFFF] text-[15px] rounded focus:outline-none outline-0"
          type="text"
          placeholder="Username or Email"
        />
      </div>
      <div className="w-[265px] mx-auto">
        <button className="w-full py-2 bg-[#E90570] text-white text-[20px] rounded" type="button">
          Find Account
        </button>
      </div>
      <div className="w-[265px] mx-auto">
        <button
          onClick={() => setShowPage('login')}
          className="w-full py-2 bg-[#D9D9D9] text-[#000000] text-[20px] border-3 border-white rounded"
          type="button"
        >
          Cancel
        </button>
      </div>
    </form>
  )
}

export default FindAccount
