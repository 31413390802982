import { useContext } from 'react'
import { Link } from 'react-router-dom'
import TechboomLogo from 'src/icons/TechboomLogo'
import { UserContext } from '../context/UserContext'

export default function AuthLayout({ children }: { children: React.ReactNode }) {
  return (
    <div className="bg-black">
      <Header />
      <section className="bg-white">
        <div className="md:py-12 py-6 px-6 md:text-center">
          <h1 className="text-[22px] md:text-[24px] uppercase">
            <span className="block md:inline">THE</span> DESIGN TOOL <span className="block md:inline">FROM</span> THE
            Future
          </h1>
        </div>
      </section>
      <div className="bg-black">
        <section className="flex justify-center  md:pt-24 pt-12 md:pb-12 pb-12">{children}</section>
        <section className="md:hidden bg-white h-[50px]" />
        <section className="flex overflow-hidden items-end justify-end bg-black text-[#FFFFFF] h-full md:h-[150px]">
          <p className="p-6 md:p-8">Created by Techboom Labs 2022</p>
        </section>
      </div>
    </div>
  )
}

function Header() {
  const { user, isLoading } = useContext(UserContext)

  return (
    <section className="flex justify-between items-center px-6 md:px-12 h-[86px] bg-black">
      <Link to="/">
        <TechboomLogo size={45} fill="none" className="stroke-white stroke-2" />
      </Link>

      <div>
        {!user && !isLoading && (
          <Link to="/login" className="w-[126px] block text-center py-1.5 border bg-white rounded" type="button">
            Login
          </Link>
        )}
      </div>
    </section>
  )
}
