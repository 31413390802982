import { Auth } from 'aws-amplify'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import InputText from 'src/_polly/components/src/common/InputText'
import { Loader } from '../loader'

interface IProps {
  email: string
  pass: string
}

type Input = {
  code: string
}

const ConfirmForm: React.FC<IProps> = ({ email, pass }) => {
  const navigate = useNavigate()

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<Input>()
  const code = watch('code', '')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [codeResent, setCodeResent] = useState(false)

  const handleResend = async () => {
    try {
      setLoading(true)
      await Auth.resendSignUp(email)
      setCodeResent(true)
    } catch (err: any) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  const onSubmit: SubmitHandler<Input> = async data => {
    try {
      setLoading(true)
      await Auth.confirmSignUp(email, data.code)
      await Auth.signIn(email, pass)
      navigate('/home')
    } catch (err: any) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Loader show={loading} />
      <form className="space-y-8 flex flex-col items-center" onSubmit={handleSubmit(onSubmit)}>
        <h1 className="text-white flex items-center justify-center text-[20px]">Confirm Account</h1>
        <div className="w-[265px] flex justify-center">
          <InputText
            authInput
            placeholder="Verification Code"
            name="code"
            type="text"
            register={register}
            errors={errors}
            required={{ value: true, message: 'Verification code is required' }}
          />
        </div>
        <div className="text-white flex items-center justify-center text-[12px]">
          {codeResent ? (
            <p>Code resent successfully</p>
          ) : (
            <p onClick={handleResend} className="cursor-pointer">
              Resend Confirmation Code
            </p>
          )}
        </div>
        {error && <span className="text-secondary.main mb-4 text-center">{error}</span>}
        <div className="w-[265px]">
          <button
            disabled={code.length !== 6 || loading}
            className={`w-full py-2 bg-brand-accent text-white text-[20px] rounded
             ${(loading || code.length !== 6) && 'cursor-not-allowed'}`}
            type="submit"
          >
            Enter
          </button>
        </div>
        <div className="flex items-center justify-center">
          <div className="text-[15px] flex items-center justify-center w-[189px] bg-[#D9D9D9] h-[45px] text-black text-center border-white border-2 rounded ">
            <a href="/login">Back to Login</a>
          </div>
        </div>
      </form>
    </>
  )
}

export default ConfirmForm
