import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { BlogItemsGroup, BlogSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantEight({ section }: { section: BlogSection }) {
  const items = section.variables.find(i => i.name === 'ITEMS')?.data as BlogItemsGroup[]

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
      className="py-4"
    >
      <div className="flex flex-col px-4 md:px-12 py-4">
        <div className="grid md:grid-cols-2 gap-5 rounded-lg">
          {items.map(item => {
            return (
              <div key={item.id} className="flex border lg:flex-row flex-col md:min-h-[350px] rounded-lg">
                <div className="lg:w-[35%]">
                  <img src={item.image} alt="" className="w-full md:h-full h-[250px] object-cover rounded-lg" />
                </div>
                <div className="lg:w-[65%] md:p-6 p-3 flex flex-col md:text-left text-center gap-y-1 md:px-8">
                  <h1 className="md:text-2xl  text-lg">
                    <SectionSlatePreview initialValue={item.name} previewMode />
                  </h1>
                  <h1 className="md:text-lg text-[15px] tracking-wider md:pr-2 mt-1">
                    <SectionSlatePreview initialValue={item.description} previewMode />
                  </h1>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
