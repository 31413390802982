import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { NewMenu } from './menu'
import MenuRenderer from './Renderer'

type PreviewProps = {
  menu: NewMenu
  website: any
  onHeight: (height: number) => void
  parentWidth?: number
  widthMultiplier?: number
  scale?: number
}

function Preview({ menu, onHeight, parentWidth, widthMultiplier = 4.13, scale = 1, website }: PreviewProps) {
  const ref = useRef<HTMLDivElement | null>(null)
  const width = parentWidth ? `${parentWidth * (widthMultiplier / scale)}px` : '1872px'
  const matrix = 0.25 * scale

  const heightSetCountRef = useRef(0)
  const intervalRef = useRef<NodeJS.Timer | null>(null)

  const checkHeight = (fromMutation = false) => {
    if (heightSetCountRef.current >= 5 && !fromMutation) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
      return
    }

    if (ref.current) {
      const height = ref.current.clientHeight * matrix
      onHeight(height)
      heightSetCountRef.current += 1
    }
  }

  const observer = new MutationObserver(function (mutations) {
    mutations.forEach(function () {
      checkHeight(true)
    })
  })

  useEffect(() => {
    checkHeight()

    const interval = setInterval(() => {
      checkHeight()
    }, 1000)

    intervalRef.current = interval

    if (ref.current) {
      observer.observe(ref.current, {
        attributes: true,
        childList: true,
        characterData: true,
        subtree: true,
      })
    }

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div
      ref={ref}
      style={{ transform: `matrix(${matrix}, 0, 0, ${matrix}, 0, 0)`, width }}
      className=" h-auto overflow-hidden block origin-top-left relative"
    >
      <div className="block">
        <div className="block z-[100]">
          <MenuRenderer {...{ menu, website }} />
        </div>
      </div>
    </div>
  )
}

type MenuPreviewRendererProps = {
  menu: NewMenu
  website: any
  disableHover?: boolean
  widthMultiplier?: number
  noShadow?: boolean
  scale?: number
  stroke?: any
  rounded?: any
}

export default function MenuPreviewRenderer({
  menu,
  website,
  disableHover,
  widthMultiplier,
  noShadow,
  scale,
  stroke,
  rounded,
}: MenuPreviewRendererProps) {
  const [height, setHeight] = useState(0)
  const [parentWidth, setParentWidth] = useState(0)

  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (ref.current) {
      setParentWidth(ref.current.clientWidth)
    }
  }, [])

  return (
    <div
      ref={ref}
      className={clsx('w-full relative mt-0 mx-auto', !disableHover && 'hover:scale-[1.03] transition-all')}
      style={{
        filter: noShadow ? 'none' : 'drop-shadow(0px 12px 12px rgba(0, 0, 0, 0.25))',
      }}
    >
      <div
        className={`h-full w-full overflow-hidden dark:bg-white ${stroke ? 'box-border' : 'box-content ml-[-1px]'} ${
          stroke && 'border border-b-0'
        } ${rounded && 'rounded-t-[6px]'}`}
      >
        <div className={`flex-grow relative ${rounded && 'rounded-t-[6px]'}`}>
          <div className="inline-block align-bottom">
            <div>
              <div className="flex">
                <div className="w-full my-0 mx-auto overflow-hidden" style={{ height: `${height}px` }}>
                  <Preview {...{ menu, parentWidth, widthMultiplier, scale, website }} onHeight={h => setHeight(h)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
