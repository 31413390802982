import Icon from '../icons'
import { IconPropsT } from '../types'

function Team(props: IconPropsT) {
  const { title = 'Team', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 77 59" size={size} title={title} {...otherProps}>
      <path
        d="M13.8 11.5H19.9L33.4 47H26.3L23 38.15H10.6L7.35 47H0.25L13.8 11.5ZM21.75 33.3L16.85 19.35L11.75 33.3H21.75Z"
        fill="#D9D9D9"
      />
      <path d="M32.78 29.72H27.58V43H26.56V29.72H21.36V28.8H32.78V29.72ZM44.4489 42.08V43H34.9889V28.8H44.2689V29.72H36.0089V35.3H43.2289V36.18H36.0089V42.08H44.4489ZM51.695 28.8H52.575L58.575 43H57.495L55.515 38.32H48.735L46.755 43H45.655L51.695 28.8ZM55.235 37.48L52.135 30.04L48.975 37.48H55.235ZM73.5911 43V30.78L68.0111 40.34H67.3311L61.7511 30.78V43H60.7311V28.8H61.7511L67.6711 38.96L73.5911 28.8H74.6111V43H73.5911Z" />
    </Icon>
  )
}

export default Team
