import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { ContactUsMap, ContactUsSection, ContractSectionContract } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import Map from './Map'

export default function VariantOne({ section }: { section: ContactUsSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const map = section.variables.find(variable => variable.name === 'MAP')?.data as ContactUsMap
  const contact = section.variables.find(variable => variable.name === 'CONTRACT')?.data as ContractSectionContract[]

  return (
    <div
      className="flex bg-[#F5EEE9] flex-col-reverse lg:flex-row"
      style={{
        ...generateSectionBackground({
          defaultColor: '#F5EEE9',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="flex-1 flex justify-center pt-0 pb-14 md:py-20">
        <div className="md:space-y-10 space-y-5">
          <div className="md:space-y-3 space-y-1">
            <div>
              <p className="text-[40px] text-center hidden md:block">
                <SectionSlatePreview initialValue={title} previewMode />
              </p>
            </div>
            <div>
              <p className="md:text-[18px] px-4 text-[14px] mt-2 text-center">
                <SectionSlatePreview initialValue={subTitle} previewMode />
              </p>
            </div>
          </div>
          <div>
            <p className="md:text-[18px] px-4 text-[14px] text-center w-8/12 mx-auto font-light">
              <SectionSlatePreview initialValue={paragraph} previewMode />
            </p>
          </div>

          {contact && (
            <div className="flex flex-col gap-y-8">
              {contact.map(({ id, name, description }) => {
                return (
                  <div key={id} className="flex flex-col md:gap-y-3 items-center justify-center">
                    {/* <p className="font-bold">{name} </p>
                    <p className="w-6/12 text-center tracking-wide ">{description}</p> */}
                    <p className="font-bold">
                      <SectionSlatePreview initialValue={name} previewMode />
                    </p>
                    <p className="w-6/12 text-center tracking-wide ">
                      <SectionSlatePreview initialValue={description} previewMode />
                    </p>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>

      <div className="flex-1 relative">
        <p className="text-3xl text-center md:hidden">
          <SectionSlatePreview initialValue={title} previewMode />
        </p>
        <div className="w-10/12 mx-auto md:w-full h-[250px] md:h-full top-0 left-0">
          <Map address={map.address} position={map.position} />
        </div>
      </div>
    </div>
  )
}
