import Icon from './icons'
import { IconPropsT } from './types'

function FollowersBox(props: IconPropsT) {
  const { title = 'FollowersBox', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 120 120" size={size} title={title} {...otherProps}>
      <path
        d="M7 0.5H113C116.59 0.5 119.5 3.41015 119.5 7V113C119.5 116.59 116.59 119.5 113 119.5H7C3.41015 119.5 0.5 116.59 0.5 113V7C0.5 3.41015 3.41015 0.5 7 0.5Z"
        fill="#D9D9D9"
        stroke="black"
      />
      <path
        d="M23.25 89.75V81.8669L23.25 81.8667L23.25 81.8653L23.2501 81.8581L23.2507 81.8265C23.2515 81.798 23.253 81.7546 23.256 81.6974C23.2622 81.583 23.2746 81.4134 23.2996 81.1964C23.3497 80.7622 23.4501 80.1391 23.6516 79.39C24.0545 77.8917 24.8611 75.8916 26.4758 73.8904C29.6978 69.8972 36.1794 65.8501 49.25 65.8501C62.3206 65.8501 68.8022 69.8972 72.0242 73.8904C73.6389 75.8916 74.4455 77.8917 74.8484 79.39C75.0499 80.1391 75.1503 80.7622 75.2004 81.1964C75.2254 81.4134 75.2378 81.583 75.244 81.6974C75.247 81.7546 75.2485 81.798 75.2493 81.8265L75.2499 81.8581L75.25 81.8653L75.25 81.8667V81.8667V81.8669V89.75H23.25ZM97.75 89.75H83.25V81.8667H83.2502L83.2498 81.8577C83.1351 78.6655 82.3766 75.5386 81.0271 72.6969C79.789 70.0899 78.0807 67.7804 76.0049 65.9044C86.8105 66.7777 92.231 70.7223 94.9577 74.4454C96.3555 76.3539 97.0537 78.2152 97.4025 79.5978C97.5769 80.289 97.6638 80.8601 97.7071 81.2566C97.7287 81.4548 97.7395 81.6093 97.7448 81.7133C97.7474 81.7653 97.7487 81.8046 97.7494 81.8305L97.7499 81.859L97.75 81.8655L97.75 81.8667V81.8668V81.8669V89.75ZM71.7485 29.2502H71.75C75.1578 29.2502 78.4306 30.7181 80.847 33.3385C83.264 35.9596 84.625 39.5187 84.625 43.2335C84.625 46.9483 83.264 50.5074 80.847 53.1285C78.4306 55.7489 75.1578 57.2168 71.75 57.2168L71.7485 57.2168C69.2993 57.2316 66.9005 56.4621 64.8518 55.0048C67.0735 51.5521 68.2652 47.4438 68.2652 43.2335C68.2652 39.0232 67.0735 34.9149 64.8518 31.4622C66.9005 30.0049 69.2993 29.2354 71.7485 29.2502ZM59.9502 35.4571C61.3675 37.7573 62.125 40.4636 62.125 43.2335C62.125 46.9483 60.764 50.5074 58.347 53.1285C55.9306 55.7489 52.6578 57.2168 49.25 57.2168C46.7083 57.2168 44.2218 56.3995 42.1048 54.8656C39.9876 53.3314 38.3345 51.1487 37.3576 48.5911C36.3807 46.0335 36.1249 43.2181 36.6231 40.5018C37.1213 37.7855 38.3507 35.293 40.153 33.3385C41.9551 31.3842 44.2486 30.056 46.7422 29.5181C49.2355 28.9802 51.8202 29.2561 54.1703 30.3117C56.5207 31.3675 58.533 33.157 59.9502 35.4571Z"
        fill="white"
        stroke="black"
        strokeWidth="1.5"
      />
    </Icon>
  )
}

export default FollowersBox
