import clsx from 'clsx'
import { useContext, useState } from 'react'
import { Triangle } from 'react-loader-spinner'

import { SectionPreviewRenderer, SectionTags, SectionTypes } from 'src/_polly/components/src/sections'

import { ReactComponent as AnimationTagIcon } from 'src/assets/icons/section/variant-animation-tag.svg'
import { ReactComponent as ImageTagIcon } from 'src/assets/icons/section/variant-image-tag.svg'
import { ReactComponent as SlideShowTagIcon } from 'src/assets/icons/section/variant-slide-tag.svg'
import { ReactComponent as VideoTagIcon } from 'src/assets/icons/section/variant-video-tag.svg'
import { Swiper, SwiperSlide } from 'swiper/react'

import { ReactComponent as BackgroundIcon } from 'src/assets/icons/section/variant-background-tag.svg'
import { ReactComponent as ButtonIcon } from 'src/assets/icons/section/variant-button-tag.svg'
import Chat from 'src/icons/Chat'
import AboutUs from 'src/icons/ComponentsIcons/About'
import Faq from 'src/icons/ComponentsIcons/Faq'
import DmUs from 'src/icons/ComponentsIcons/DmUs'
import GalleryBLit from 'src/icons/ComponentsIcons/GalleryBLit'
import Love1stSite from 'src/icons/ComponentsIcons/Love@1stSite'
import Menu from 'src/icons/ComponentsIcons/Menu'
import Portfolio from 'src/icons/ComponentsIcons/Portfolio'
import QuoteMe from 'src/icons/ComponentsIcons/QuoteMe'
import Team from 'src/icons/ComponentsIcons/Team'
import Vibing from 'src/icons/ComponentsIcons/Vibing'
import Yolo from 'src/icons/ComponentsIcons/Yolo'
import FlexTable from 'src/icons/FlexTable'
import ImageCreator from 'src/icons/ImageCreator'
import Love from 'src/icons/Love'
import Network from 'src/icons/network'
import Share from 'src/icons/Share'
import { EffectCoverflow } from 'swiper'
import { ReactComponent as ContactUs } from '../../icons/ComponentsIcons/DmUs.svg'
import 'swiper/css'
import { upsertBlockByPageID } from '../../services/website.service'
import { DEFAULT_VARIANT_SETTINGS, getSectionVariantsWithPreview } from '../../util/sections/get-variants'
import { WebsiteContext } from '../context/WebsiteContext'
import { Loader } from '../loader'
// icons
// import "swiper/css/effect-creative";

import blog from '../icons/ComponentsIcons/Blog.svg'

export const tagIcons: Record<SectionTags, typeof BackgroundIcon> = {
  IMAGE: ImageTagIcon,
  VIDEO: VideoTagIcon,
  ANIMATION: AnimationTagIcon,
  SLIDESHOW: SlideShowTagIcon,
  BACKGROUND: BackgroundIcon,
  BUTTON: ButtonIcon,
}

interface NewSectionVariantSelectionProps {
  onClose: () => void
  sectionType?: SectionTypes
}

export function VariantIframe({
  section,
  rounded,
  index,
}: {
  rounded?: any
  index?: number
  section: ReturnType<typeof getSectionVariantsWithPreview>[number]
}) {
  const [loaded, setLoaded] = useState(false)
  return (
    <div className="w-full max-h-[500px] h-[500px] relative">
      {!loaded && (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="dark:hidden block">
            <Triangle height="80" width="80" color="#000" ariaLabel="triangle-loading" wrapperStyle={{}} visible />
          </div>
          <div className="dark:block hidden">
            <Triangle height="80" width="80" color="#fff" ariaLabel="triangle-loading" wrapperStyle={{}} visible />
          </div>
        </div>
      )}
      <iframe
        key={section.id}
        src={`/variant-preview/?section=${window.btoa(JSON.stringify(section))}`}
        frameBorder="0"
        title="Plly Preview"
        className={`w-full max-h-[500px] h-[500px] ${rounded && index && index > 0 && 'rounded-t-[6px]'}`}
        onLoad={() => setLoaded(true)}
      />
    </div>
  )
}

export default function NewSectionVariantSelection({ onClose, sectionType }: NewSectionVariantSelectionProps) {
  const websiteContext = useContext(WebsiteContext)
  const variants = getSectionVariantsWithPreview(sectionType)

  const [adding, setAdding] = useState(false)
  const [mode, setMode] = useState<'desktop' | 'mobile'>('desktop')
  const [previewItem] = useState<ReturnType<typeof getSectionVariantsWithPreview>[number] | null>(null)

  const handleAddTextSection = async (variant: string) => {
    setAdding(true)
    const variables = (variants as any[]).find(v => v.id === variant)?.variables
    await upsertBlockByPageID(websiteContext.pageID, {
      type: sectionType,
      isSection: true,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      variables,
      variant,
      ...DEFAULT_VARIANT_SETTINGS,
    } as any)
    setAdding(false)
    onClose()
  }

  return (
    <div className="px-4 dark:bg-black">
      <Loader show={adding} />
      <div className="flex w-full  justify-between items-center">
        <div className="absolute top-[21px] right-[30px]">
          <button
            type="button"
            className="border-[0.6px] border-[#0C0C0C] dark:border-white rounded-[5px] w-[24px] h-[32px]"
            onClick={() => setMode('mobile')}
          >
            <div
              className={clsx(
                'border-[0.6px] border-black dark:border-[#D9D9D9] rounded-[5px] w-[18px] h-[25px] mx-auto',
                mode === 'mobile' && 'bg-[#D9D9D9]',
              )}
            />
          </button>

          <button
            type="button"
            className="border-[0.6px] border-[#0C0C0C] dark:border-white rounded-[5px] w-[40px] h-[32px] ml-[10px]"
            onClick={() => setMode('desktop')}
          >
            <div
              className={clsx(
                'border-black dark:border-[#D9D9D9] border-[0.6px] rounded-[5px] w-[32px] h-[25px] mx-auto',
                mode === 'desktop' && 'bg-[#D9D9D9]',
              )}
            />
          </button>
        </div>
      </div>

      {previewItem ? (
        <>
          <div className="border border-black mt-[100px]">
            {mode === 'desktop' ? (
              <SectionPreviewRenderer rounded section={previewItem} noShadow disableHover />
            ) : (
              <VariantIframe section={previewItem} />
            )}
          </div>
          <div className="flex justify-center">
            <button
              type="button"
              onClick={() => handleAddTextSection(previewItem.variant)}
              className="mt-20 h-[4.5rem] w-96  bg-gray-100 text-4xl font-extralight border border-black"
            >
              select
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="my-8">
            {(sectionType === 'ATTENTION_GRABBER' && <Love1stSite width={146} height={49} className="mx-auto" />) ||
              (sectionType === 'IMAGE_OMG' && (
                <div className="flex justify-center items-center">
                  <ImageCreator height={37} width={56} />
                  <span className="dark:text-white text-[30px]">OMG</span>
                </div>
              )) ||
              (sectionType === 'GALLERY' && <GalleryBLit width={124} height={37} className="mx-auto" />) ||
              (sectionType === 'TEXT' && <Yolo width={121} height={33} className="mx-auto" />) ||
              (sectionType === 'BLOG' && <img src={blog} alt={sectionType} className="mx-auto" />) ||
              (sectionType === 'TESTIMONIAL' && <Vibing width={128} height={33} className="mx-auto" />) ||
              (sectionType === 'PORTFOLIO' && <Portfolio width={152} height={35} className="mx-auto" />) ||
              (sectionType === 'QUOTE_SECTION' && <QuoteMe width={130} height={53} className="mx-auto" />) ||
              (sectionType === 'ABOUT_US' && <AboutUs width={131} height={21} className="mx-auto" />) ||
              (sectionType === 'CONTACT_US' && (
                <div className="flex items-center justify-center gap-0">
                  <ContactUs />

                  <DmUs height={16} className="ml-1 mb-[-10px]" />
                </div>
              )) ||
              (sectionType === 'MENU' && <Menu width={152} height={49} className="mx-auto" />) ||
              (sectionType === 'TABLE' && <FlexTable width={126} height={83} className="mx-auto" />) ||
              (sectionType === 'TEAM_MEMBERS' && <Team width={77} height={59} className="mx-auto" />) ||
              (sectionType === 'FAQ' && <Faq width={144} height={82} className="mx-auto" />)}
          </div>

          <div className="space-y-10">
            <Swiper
              effect="coverflow"
              modules={[EffectCoverflow]}
              loop
              slidesPerView={1}
              slidesPerGroup={1}
              shortSwipes={false}
              className="mySwiper"
            >
              {variants.map(section => (
                <SwiperSlide key={section.id}>
                  <div className="w-full h-full px-2 bg-white dark:bg-black">
                    <div>
                      {mode === 'desktop' ? (
                        <SectionPreviewRenderer rounded {...{ section }} noShadow disableHover />
                      ) : (
                        <VariantIframe section={section} />
                      )}
                    </div>

                    {section.styleName && (
                      <div className="w-full h-[50px] px-4 mt-[2px] flex justify-between border-b border-t border-black dark:border-[#D6D6D6]">
                        <div className="flex items-center gap-x-[12px]">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="8.36636" cy="8.41629" r="7.53152" className="fill-black dark:fill-white" />
                          </svg>
                          <p className="text-[15px] font-bold dark:text-white">{section.styleName}</p>
                        </div>
                        <div className="flex gap-x-[18px] items-center">
                          <button type="button">
                            <Love size={20} className="text-black dark:text-white" />
                          </button>
                          <button type="button">
                            <Chat size={20} className="text-black dark:text-white" />
                          </button>
                          <button type="button">
                            <Network size={20} fill="none" className="stroke-black dark:stroke-white" />
                          </button>
                          <button type="button">
                            <Share size={20} className="stroke-black fill-black dark:fill-white dark:stroke-white" />
                          </button>
                        </div>
                      </div>
                    )}

                    <div className="mt-[44px] flex bg-black h-[53px] w-[216px] justify-center text-white mx-auto border-[4px] border-white dark:border-[#E90570] rounded-[9px]">
                      <button
                        type="button"
                        className="text-[20px] h-full w-full text-center"
                        onClick={() => handleAddTextSection(section.variant)}
                      >
                        select
                      </button>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </>
      )}
      <div className="mb-5" />
    </div>
  )
}
