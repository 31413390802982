import create from 'zustand'

type MessengerStore = {
  threads: any[]
  setThreads: (threads: any[]) => void
  addThread: (thread: any) => void
  removeThread: (threadId: any) => void
}

const useMessengerStore = create<MessengerStore>(
  (set): MessengerStore => ({
    threads: [],
    setThreads: (threads: any[]) => set(state => ({ ...state, threads })),
    addThread: (thread: any) =>
      set(state => {
        console.log('addThread', state.threads, thread)
        const exists = state.threads.find(item => item.chatmateID === thread.chatmateID)
        if (exists) return state
        return { ...state, threads: [thread, ...state.threads] }
      }),
    removeThread: (threadId: any) =>
      set(state => {
        const threads = state.threads.filter(cm => cm.id !== threadId)
        return { ...state, threads }
      }),
  }),
)

export default useMessengerStore
