import Icon from './icons'
import { IconPropsT } from './types'

function BackIcon(props: IconPropsT) {
  const { title = 'Back', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 25 28" size={size} title={title} {...otherProps}>
      <path d="M23.8702 27.3075L0.81132 14.0195L23.8485 0.693851L23.8702 27.3075Z" strokeWidth="0.8" />
    </Icon>
  )
}

export default BackIcon
