/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUserProfile = /* GraphQL */ `
  mutation CreateUserProfile(
    $input: CreateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    createUserProfile(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      profilePics {
        bucket
        region
        key
      }
      username
      profilePicture
      country
      state
      city
      bio
      config
      createdAt
      updatedAt
    }
  }
`;
export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile(
    $input: UpdateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    updateUserProfile(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      profilePics {
        bucket
        region
        key
      }
      username
      profilePicture
      country
      state
      city
      bio
      config
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserProfile = /* GraphQL */ `
  mutation DeleteUserProfile(
    $input: DeleteUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    deleteUserProfile(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      profilePics {
        bucket
        region
        key
      }
      username
      profilePicture
      country
      state
      city
      bio
      config
      createdAt
      updatedAt
    }
  }
`;
export const createWebsite = /* GraphQL */ `
  mutation CreateWebsite(
    $input: CreateWebsiteInput!
    $condition: ModelWebsiteConditionInput
  ) {
    createWebsite(input: $input, condition: $condition) {
      id
      name
      config
      userID
      createdAt
      menu
      footer
      pages
      links
      newMenu
      newMenuConfig
      newFooter
      isPostedOnSocial
      cloudfrontDistributionID
      cloudfrontDomainName
      cloudfrontAlias
      gaTrackingID
      updatedAt
    }
  }
`;
export const updateWebsite = /* GraphQL */ `
  mutation UpdateWebsite(
    $input: UpdateWebsiteInput!
    $condition: ModelWebsiteConditionInput
  ) {
    updateWebsite(input: $input, condition: $condition) {
      id
      name
      config
      userID
      createdAt
      menu
      footer
      pages
      links
      newMenu
      newMenuConfig
      newFooter
      isPostedOnSocial
      cloudfrontDistributionID
      cloudfrontDomainName
      cloudfrontAlias
      gaTrackingID
      updatedAt
    }
  }
`;
export const deleteWebsite = /* GraphQL */ `
  mutation DeleteWebsite(
    $input: DeleteWebsiteInput!
    $condition: ModelWebsiteConditionInput
  ) {
    deleteWebsite(input: $input, condition: $condition) {
      id
      name
      config
      userID
      createdAt
      menu
      footer
      pages
      links
      newMenu
      newMenuConfig
      newFooter
      isPostedOnSocial
      cloudfrontDistributionID
      cloudfrontDomainName
      cloudfrontAlias
      gaTrackingID
      updatedAt
    }
  }
`;
export const createBuildRequest = /* GraphQL */ `
  mutation CreateBuildRequest(
    $input: CreateBuildRequestInput!
    $condition: ModelBuildRequestConditionInput
  ) {
    createBuildRequest(input: $input, condition: $condition) {
      id
      status
      websiteID
      createdAt
      userID
      cloudfrontInvalidationID
      updatedAt
    }
  }
`;
export const updateBuildRequest = /* GraphQL */ `
  mutation UpdateBuildRequest(
    $input: UpdateBuildRequestInput!
    $condition: ModelBuildRequestConditionInput
  ) {
    updateBuildRequest(input: $input, condition: $condition) {
      id
      status
      websiteID
      createdAt
      userID
      cloudfrontInvalidationID
      updatedAt
    }
  }
`;
export const deleteBuildRequest = /* GraphQL */ `
  mutation DeleteBuildRequest(
    $input: DeleteBuildRequestInput!
    $condition: ModelBuildRequestConditionInput
  ) {
    deleteBuildRequest(input: $input, condition: $condition) {
      id
      status
      websiteID
      createdAt
      userID
      cloudfrontInvalidationID
      updatedAt
    }
  }
`;
export const createPage = /* GraphQL */ `
  mutation CreatePage(
    $input: CreatePageInput!
    $condition: ModelPageConditionInput
  ) {
    createPage(input: $input, condition: $condition) {
      id
      name
      path
      websiteID
      blocks
      createdAt
      userID
      updatedAt
    }
  }
`;
export const updatePage = /* GraphQL */ `
  mutation UpdatePage(
    $input: UpdatePageInput!
    $condition: ModelPageConditionInput
  ) {
    updatePage(input: $input, condition: $condition) {
      id
      name
      path
      websiteID
      blocks
      createdAt
      userID
      updatedAt
    }
  }
`;
export const deletePage = /* GraphQL */ `
  mutation DeletePage(
    $input: DeletePageInput!
    $condition: ModelPageConditionInput
  ) {
    deletePage(input: $input, condition: $condition) {
      id
      name
      path
      websiteID
      blocks
      createdAt
      userID
      updatedAt
    }
  }
`;
export const createBlock = /* GraphQL */ `
  mutation CreateBlock(
    $input: CreateBlockInput!
    $condition: ModelBlockConditionInput
  ) {
    createBlock(input: $input, condition: $condition) {
      id
      type
      props
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateBlock = /* GraphQL */ `
  mutation UpdateBlock(
    $input: UpdateBlockInput!
    $condition: ModelBlockConditionInput
  ) {
    updateBlock(input: $input, condition: $condition) {
      id
      type
      props
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteBlock = /* GraphQL */ `
  mutation DeleteBlock(
    $input: DeleteBlockInput!
    $condition: ModelBlockConditionInput
  ) {
    deleteBlock(input: $input, condition: $condition) {
      id
      type
      props
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createMintTransaction = /* GraphQL */ `
  mutation CreateMintTransaction(
    $input: CreateMintTransactionInput!
    $condition: ModelMintTransactionConditionInput
  ) {
    createMintTransaction(input: $input, condition: $condition) {
      id
      transactionHash
      from
      userID
      blockID
      createdAt
      updatedAt
      block {
        id
        type
        props
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const updateMintTransaction = /* GraphQL */ `
  mutation UpdateMintTransaction(
    $input: UpdateMintTransactionInput!
    $condition: ModelMintTransactionConditionInput
  ) {
    updateMintTransaction(input: $input, condition: $condition) {
      id
      transactionHash
      from
      userID
      blockID
      createdAt
      updatedAt
      block {
        id
        type
        props
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const deleteMintTransaction = /* GraphQL */ `
  mutation DeleteMintTransaction(
    $input: DeleteMintTransactionInput!
    $condition: ModelMintTransactionConditionInput
  ) {
    deleteMintTransaction(input: $input, condition: $condition) {
      id
      transactionHash
      from
      userID
      blockID
      createdAt
      updatedAt
      block {
        id
        type
        props
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      title
      description
      websiteID
      userID
      type
      images
      createdAt
      prompt
      status
      pageID
      componentID
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      page {
        id
        name
        path
        websiteID
        blocks
        createdAt
        userID
        updatedAt
      }
      owner
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      title
      description
      websiteID
      userID
      type
      images
      createdAt
      prompt
      status
      pageID
      componentID
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      page {
        id
        name
        path
        websiteID
        blocks
        createdAt
        userID
        updatedAt
      }
      owner
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      title
      description
      websiteID
      userID
      type
      images
      createdAt
      prompt
      status
      pageID
      componentID
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      page {
        id
        name
        path
        websiteID
        blocks
        createdAt
        userID
        updatedAt
      }
      owner
    }
  }
`;
export const createPostLike = /* GraphQL */ `
  mutation CreatePostLike(
    $input: CreatePostLikeInput!
    $condition: ModelPostLikeConditionInput
  ) {
    createPostLike(input: $input, condition: $condition) {
      id
      userID
      postID
      like
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      post {
        id
        title
        description
        websiteID
        userID
        type
        images
        createdAt
        prompt
        status
        pageID
        componentID
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        page {
          id
          name
          path
          websiteID
          blocks
          createdAt
          userID
          updatedAt
        }
        owner
      }
      owner
    }
  }
`;
export const updatePostLike = /* GraphQL */ `
  mutation UpdatePostLike(
    $input: UpdatePostLikeInput!
    $condition: ModelPostLikeConditionInput
  ) {
    updatePostLike(input: $input, condition: $condition) {
      id
      userID
      postID
      like
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      post {
        id
        title
        description
        websiteID
        userID
        type
        images
        createdAt
        prompt
        status
        pageID
        componentID
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        page {
          id
          name
          path
          websiteID
          blocks
          createdAt
          userID
          updatedAt
        }
        owner
      }
      owner
    }
  }
`;
export const deletePostLike = /* GraphQL */ `
  mutation DeletePostLike(
    $input: DeletePostLikeInput!
    $condition: ModelPostLikeConditionInput
  ) {
    deletePostLike(input: $input, condition: $condition) {
      id
      userID
      postID
      like
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      post {
        id
        title
        description
        websiteID
        userID
        type
        images
        createdAt
        prompt
        status
        pageID
        componentID
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        page {
          id
          name
          path
          websiteID
          blocks
          createdAt
          userID
          updatedAt
        }
        owner
      }
      owner
    }
  }
`;
export const createPostComment = /* GraphQL */ `
  mutation CreatePostComment(
    $input: CreatePostCommentInput!
    $condition: ModelPostCommentConditionInput
  ) {
    createPostComment(input: $input, condition: $condition) {
      id
      userID
      postID
      content
      createdAt
      status
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      post {
        id
        title
        description
        websiteID
        userID
        type
        images
        createdAt
        prompt
        status
        pageID
        componentID
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        page {
          id
          name
          path
          websiteID
          blocks
          createdAt
          userID
          updatedAt
        }
        owner
      }
      owner
    }
  }
`;
export const updatePostComment = /* GraphQL */ `
  mutation UpdatePostComment(
    $input: UpdatePostCommentInput!
    $condition: ModelPostCommentConditionInput
  ) {
    updatePostComment(input: $input, condition: $condition) {
      id
      userID
      postID
      content
      createdAt
      status
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      post {
        id
        title
        description
        websiteID
        userID
        type
        images
        createdAt
        prompt
        status
        pageID
        componentID
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        page {
          id
          name
          path
          websiteID
          blocks
          createdAt
          userID
          updatedAt
        }
        owner
      }
      owner
    }
  }
`;
export const deletePostComment = /* GraphQL */ `
  mutation DeletePostComment(
    $input: DeletePostCommentInput!
    $condition: ModelPostCommentConditionInput
  ) {
    deletePostComment(input: $input, condition: $condition) {
      id
      userID
      postID
      content
      createdAt
      status
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      post {
        id
        title
        description
        websiteID
        userID
        type
        images
        createdAt
        prompt
        status
        pageID
        componentID
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        page {
          id
          name
          path
          websiteID
          blocks
          createdAt
          userID
          updatedAt
        }
        owner
      }
      owner
    }
  }
`;
export const createCommentLike = /* GraphQL */ `
  mutation CreateCommentLike(
    $input: CreateCommentLikeInput!
    $condition: ModelCommentLikeConditionInput
  ) {
    createCommentLike(input: $input, condition: $condition) {
      id
      userID
      commentID
      like
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      comment {
        id
        userID
        postID
        content
        createdAt
        status
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        post {
          id
          title
          description
          websiteID
          userID
          type
          images
          createdAt
          prompt
          status
          pageID
          componentID
          updatedAt
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const updateCommentLike = /* GraphQL */ `
  mutation UpdateCommentLike(
    $input: UpdateCommentLikeInput!
    $condition: ModelCommentLikeConditionInput
  ) {
    updateCommentLike(input: $input, condition: $condition) {
      id
      userID
      commentID
      like
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      comment {
        id
        userID
        postID
        content
        createdAt
        status
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        post {
          id
          title
          description
          websiteID
          userID
          type
          images
          createdAt
          prompt
          status
          pageID
          componentID
          updatedAt
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const deleteCommentLike = /* GraphQL */ `
  mutation DeleteCommentLike(
    $input: DeleteCommentLikeInput!
    $condition: ModelCommentLikeConditionInput
  ) {
    deleteCommentLike(input: $input, condition: $condition) {
      id
      userID
      commentID
      like
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      comment {
        id
        userID
        postID
        content
        createdAt
        status
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        post {
          id
          title
          description
          websiteID
          userID
          type
          images
          createdAt
          prompt
          status
          pageID
          componentID
          updatedAt
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const createCommentReply = /* GraphQL */ `
  mutation CreateCommentReply(
    $input: CreateCommentReplyInput!
    $condition: ModelCommentReplyConditionInput
  ) {
    createCommentReply(input: $input, condition: $condition) {
      id
      userID
      commentID
      content
      createdAt
      status
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      comment {
        id
        userID
        postID
        content
        createdAt
        status
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        post {
          id
          title
          description
          websiteID
          userID
          type
          images
          createdAt
          prompt
          status
          pageID
          componentID
          updatedAt
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const updateCommentReply = /* GraphQL */ `
  mutation UpdateCommentReply(
    $input: UpdateCommentReplyInput!
    $condition: ModelCommentReplyConditionInput
  ) {
    updateCommentReply(input: $input, condition: $condition) {
      id
      userID
      commentID
      content
      createdAt
      status
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      comment {
        id
        userID
        postID
        content
        createdAt
        status
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        post {
          id
          title
          description
          websiteID
          userID
          type
          images
          createdAt
          prompt
          status
          pageID
          componentID
          updatedAt
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const deleteCommentReply = /* GraphQL */ `
  mutation DeleteCommentReply(
    $input: DeleteCommentReplyInput!
    $condition: ModelCommentReplyConditionInput
  ) {
    deleteCommentReply(input: $input, condition: $condition) {
      id
      userID
      commentID
      content
      createdAt
      status
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      comment {
        id
        userID
        postID
        content
        createdAt
        status
        updatedAt
        user {
          id
          firstName
          lastName
          email
          username
          profilePicture
          country
          state
          city
          bio
          config
          createdAt
          updatedAt
        }
        post {
          id
          title
          description
          websiteID
          userID
          type
          images
          createdAt
          prompt
          status
          pageID
          componentID
          updatedAt
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation(
    $input: CreateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    createConversation(input: $input, condition: $condition) {
      id
      convID
      userID
      status
      lastActiveTime
      lastMessageID
      createdAt
      chatmateID
      chatmate {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      lastMessage {
        id
        senderID
        conversationID
        content
        type
        createdAt
        read
        deleted
        updatedAt
      }
    }
  }
`;
export const updateConversation = /* GraphQL */ `
  mutation UpdateConversation(
    $input: UpdateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    updateConversation(input: $input, condition: $condition) {
      id
      convID
      userID
      status
      lastActiveTime
      lastMessageID
      createdAt
      chatmateID
      chatmate {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      lastMessage {
        id
        senderID
        conversationID
        content
        type
        createdAt
        read
        deleted
        updatedAt
      }
    }
  }
`;
export const deleteConversation = /* GraphQL */ `
  mutation DeleteConversation(
    $input: DeleteConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    deleteConversation(input: $input, condition: $condition) {
      id
      convID
      userID
      status
      lastActiveTime
      lastMessageID
      createdAt
      chatmateID
      chatmate {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      lastMessage {
        id
        senderID
        conversationID
        content
        type
        createdAt
        read
        deleted
        updatedAt
      }
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      senderID
      conversationID
      content
      type
      createdAt
      read
      deleted
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      senderID
      conversationID
      content
      type
      createdAt
      read
      deleted
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      senderID
      conversationID
      content
      type
      createdAt
      read
      deleted
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      owner
      toNotifyID
      topic
      topicUrl
      topicDescription
      topicID
      status
      createdAt
      updatedAt
      creator {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      toNotify {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      owner
      toNotifyID
      topic
      topicUrl
      topicDescription
      topicID
      status
      createdAt
      updatedAt
      creator {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      toNotify {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      owner
      toNotifyID
      topic
      topicUrl
      topicDescription
      topicID
      status
      createdAt
      updatedAt
      creator {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      toNotify {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
    }
  }
`;
export const createImage = /* GraphQL */ `
  mutation CreateImage(
    $input: CreateImageInput!
    $condition: ModelImageConditionInput
  ) {
    createImage(input: $input, condition: $condition) {
      id
      userID
      title
      prompt
      type
      url
      status
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
export const updateImage = /* GraphQL */ `
  mutation UpdateImage(
    $input: UpdateImageInput!
    $condition: ModelImageConditionInput
  ) {
    updateImage(input: $input, condition: $condition) {
      id
      userID
      title
      prompt
      type
      url
      status
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
export const deleteImage = /* GraphQL */ `
  mutation DeleteImage(
    $input: DeleteImageInput!
    $condition: ModelImageConditionInput
  ) {
    deleteImage(input: $input, condition: $condition) {
      id
      userID
      title
      prompt
      type
      url
      status
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        profilePics {
          bucket
          region
          key
        }
        username
        profilePicture
        country
        state
        city
        bio
        config
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
