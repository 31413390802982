import { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import BackIcon from 'src/icons/BackIcon'
import Logo from 'src/icons/Logo'
import Menu from './layout/NavBar2'
import ModalDialog from './new/ModalDialog'

import { ReactComponent as MenuIcon } from '../assets/icons/navbar/ellipse.svg'
import { getWebsiteByID } from '../lib/services/website.service'
import { WebsiteContext } from './context/WebsiteContext'

interface HeaderNavProps {
  backButton?: boolean
  onBackClick?: () => void
  logo?: string | null
}

const HeaderNav = ({ backButton = false, onBackClick, logo }: HeaderNavProps) => {
  const navigate = useNavigate()
  const { websiteID } = useContext(WebsiteContext)

  const [showMenu, setShowMenu] = useState(false)
  const [websiteLogo, setWebsiteLogo] = useState()

  const handleBackClick = () => {
    if (onBackClick) {
      onBackClick()
    } else {
      navigate(-1)
    }
  }

  useEffect(() => {
    if (websiteID) {
      getWebsiteByID(websiteID).then(data => {
        const config = JSON.parse(data?.config || '{}')
        setWebsiteLogo(config?.logo || undefined)
      })
    }
  }, [websiteID])

  return (
    <>
      {/* header nav */}
      <div className="flex px-5 pb-5 pt-[21px] w-full justify-between items-center dark:bg-black">
        <div className="flex items-center">
          {(backButton || onBackClick) && (
            <BackIcon
              size={28}
              fill="none"
              className="fill-white dark:fill-black stroke-black dark:stroke-white cursor-pointer mr-[16px]"
              onClick={handleBackClick}
            />
          )}
          {/* <Link to="/" className=""> */}
          <Link to="/" className="">
            <Logo
              size={28}
              fill="none"
              className="fill-white dark:fill-black stroke-black dark:stroke-white cursor-pointer"
            />
          </Link>
          {/* </Link> */}
        </div>
        {logo || websiteLogo ? (
          // eslint-disable-next-line
          <button className="border-2 hidden border-black rounded-[4px] mt-1 h-[50px] p-[4px] mr-[12px]">
            <div className="rounded-sm w-[40px] h-full">
              <img
                src={logo || websiteLogo}
                alt=""
                className="border-2 border-black rounded-sm w-full h-full object-cover"
                onClick={() => setShowMenu(true)}
              />
            </div>
          </button>
        ) : (
          <MenuIcon className="cursor-pointer hidden" onClick={() => setShowMenu(true)} />
        )}
      </div>

      {/* menu modal */}
      <ModalDialog title="Menu" open={showMenu} onClose={() => setShowMenu(false)}>
        <Menu />
      </ModalDialog>
    </>
  )
}

export default HeaderNav
