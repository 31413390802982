import { motion } from 'framer-motion'
import SectionSlatePreview from '../../../../common/SectionSlatePreview'
import { TeamMembersSectionMember } from './../../../types'

export default function CardDesign({ member }: { member: TeamMembersSectionMember }) {
  return (
    <div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{
          duration: 1,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        className="overflow-hidden"
      >
        <img src={member.image} className="h-[300px] lg:h-[450px] w-full" alt="" />
        <div className="flex flex-col md:items-center items-center  mt-2">
          {member.subtitle && (
            <p className="text-left text-sm mt-1">
              <SectionSlatePreview initialValue={member.subtitle} previewMode />
            </p>
          )}
          <h1 className="text-left md:text-xl">
            <SectionSlatePreview initialValue={member.name} previewMode />
          </h1>
          <p className="text-justify text-sm ">
            <SectionSlatePreview initialValue={member.description} previewMode />
          </p>
        </div>
      </motion.div>
    </div>
  )
}
