import { useCallback, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Link, useParams } from 'react-router-dom'
import { ModelSortDirection } from 'src/API'
import { Loader } from 'src/components/loader'
import useBatchFetch from 'src/hooks/useBatchFetch'
import { getWebsitesByOtherUserID } from 'src/lib/services/website.service'
import { listPostsByUserID } from 'src/services/post.service'
import { getCurrentUser, getUserById, saveUser } from 'src/services/user.service'
import useProfileStore from 'src/store/profile'
import BottomNavigation from '../feed/components/BottomNavigation'
import Header from '../posts-page/components/Header'
import Post from '../posts-page/components/Post'
import Contact from './components/Contact'

function WebsitePreview({ userId }: { userId?: string }) {
  const [websites, setWebsites] = useState<any[]>([])
  const fetchWebsites = () => {
    if (userId) {
      getWebsitesByOtherUserID(userId).then(res => {
        setWebsites(res?.items || [])
      })
    }
  }

  useEffect(() => {
    fetchWebsites()
  }, [])

  return (
    <div className="flex gap-x-3 md:gap-x-4 py-[0.3rem] md:py-0">
      {websites.slice(0, 3).map((website, idx) => (
        <Link
          onClick={() =>
            localStorage.setItem(
              `tutorial_editpage_${website?.id}`,
              JSON.stringify(JSON.parse(localStorage.getItem(`tutorial_editpage_${website?.id}`) || '0') + 1),
            )
          }
          to={`/website/${website.id}/preview`}
          key={idx}
        >
          {JSON.parse(website?.config)?.logo ? (
            <img
              src={JSON.parse(website?.config)?.logo}
              alt=""
              className="w-[24px]  md:mt-0 md:w-[30px] h-[38px] md:h-[50px] object-cover  border rounded-[3px] border-black dark:border-white"
            />
          ) : (
            <div className="font-monoton w-[24px] md:w-[30px]  md:mt-0 h-[38px] md:h-[50px] text-[18px] md:text-[23px] dark:text-white border rounded-[3px] border-black dark:border-white flex items-center justify-center  text-center">
              {website.name.split('')[0]}
            </div>
          )}
        </Link>
      ))}
    </div>
  )
}

export default function ProfilePage() {
  const params = useParams()
  const userId = params?.userId

  const profileStore = useProfileStore(state => state)

  const query = useCallback(
    (nextToken?: string | null) =>
      listPostsByUserID({
        userID: userId,
        sortDirection: ModelSortDirection.DESC,
        nextToken,
        limit: 15,
      }),
    [userId],
  )

  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState<any>(undefined)
  const [currentUserId, setCurrentUserId] = useState<any>(undefined)
  const [filterGrid] = useState<string>('two')
  const { items, fetching, nextBatch, hasMore } = useBatchFetch(query as any)

  const getInitialData = useCallback(async () => {
    const userProfile = await getUserById(userId ?? '')
    setUser(userProfile)
  }, [])

  const getCurrentUserId = async () => {
    const currentUser = await getCurrentUser()
    setCurrentUserId(currentUser?.id)
  }

  useEffect(() => {
    if (user) {
      saveUser({
        id: user.id,
        config: JSON.stringify({
          ...user.config,
          profileGrid: filterGrid,
        }),
      })
    }
  }, [filterGrid])

  useEffect(() => {
    if (userId) {
      getInitialData().then(() => setLoading(false))
    }
  }, [userId])

  useEffect(() => {
    getCurrentUserId()
    profileStore.setPosts([])
  }, [])

  useEffect(() => {
    profileStore.setPosts(items)
  }, [items])

  return (
    <div className="dark:bg-black min-h-screen">
      <Loader show={loading || fetching} />
      <Header variant="profile" />
      <div className="px-3 md:px-0">
        <div className="fixed hidden md:block top-[175px] left-12 ">
          <Contact user={user} />
        </div>
        <div
          className={`gap-y-1 px-4 sm:px-0 py-8 md:py-0 md:mt-[69px] flex flex-col dark:text-white  mx-auto
          ${filterGrid === 'one' && ' xl:w-[690px] 2xl:w-[750px] lg:w-[700px] md:w-[600px] sm:w-[450px]'}
          ${filterGrid === 'two' && ' xl:w-[920px] 2xl:w-[920px] lg:w-[750px] md:w-[640px] px-4 sm:w-[450px]'}
          ${filterGrid === 'three' && ' xl:w-[1000px] 2xl:w-[1100px] lg:w-[780px] md:w-[640px] px-2 sm:w-[450px] '}
          `}
        >
          <div className="flex relative w-full">
            <span className="md:hidden block mr-3">
              <div className="w-[44px] h-[44px] rounded-full bg-[#D9D9D9]">
                <img
                  className="w-full h-full object-center rounded-full"
                  src={user?.profilePicture}
                  alt="profile_picture"
                />
              </div>
            </span>
            <div className="flex justify-around">
              <div>
                <p className="text-[15px] md:text-[20px] font-semibold">
                  {user?.firstName ? `${user?.firstName} ${user?.lastName}` : user?.username}
                </p>
                <p className="text-[12px] md:mt-1">
                  {user?.city} {user?.city && ','} {user?.state} {user?.state && ','} {user?.country}
                </p>
              </div>
              <div className="md:hidden absolute right-2">
                <WebsitePreview userId={userId} />
              </div>
            </div>
          </div>
          <p className="md:text-[14px] text-[15px] mt-2 md:mt-0 tracking-wide leading-7 lg:w-[650px] md:w-[600px]">
            {user?.bio}
          </p>
          <div>
            <div className="flex lg:gap-x-4 md:gap-x-6 items-center ">
              <div
                className={`h-[1px] bg-black dark:bg-white md:bg-[#D9D9D9] ${currentUserId !== userId && 'mb-3'} ${
                  filterGrid === 'one' && 'w-[730px]'
                } ${filterGrid === 'two' && 'md:w-[880px]'} ${filterGrid === 'three' && 'w-[1100px]'}`}
              />
            </div>
            <div>
              <div className="hidden md:block pt-3">
                <WebsitePreview userId={userId} />
              </div>
              <div className="md:hidden py-2">
                <Contact user={user} />
              </div>
            </div>
            <div className="md:mt-[40px] mt-4">
              <div>
                <InfiniteScroll loadMore={nextBatch} hasMore={hasMore}>
                  <div
                    className={`${filterGrid === 'three' && 'grid grid-cols-3 xl:gap-x-2 gap-x-4'} ${
                      filterGrid === 'two' && 'grid grid-cols-1 md:grid-cols-2 gap-x-4  '
                    }`}
                  >
                    {profileStore.posts.map((post, idx) => (
                      <div key={idx}>
                        <Post post={post} filterGrid={filterGrid} />
                      </div>
                    ))}
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <BottomNavigation variant="one" />
      </div>
    </div>
  )
}
