import { Auth } from 'aws-amplify'
import { ReactComponent as GoogleIcon } from 'src/components/icons/Google.svg'

const GoogleLogin = ({ isSignUp }: { isSignUp?: boolean }) => {
  const googleSignIn = async () => {
    Auth.federatedSignIn({ provider: 'Google' as any })
  }
  return (
    <button
      type="button"
      onClick={() => googleSignIn()}
      // className="flex justify-center items-center w-full py-[5px] bg-white text-black text-[15px] border border-white rounded "
      className="flex cursor-pointer justify-center items-center gap-[15px] w-full h-[45px] mt-4 bg-white text-black text-[15px] border border-white rounded text-center focus:outline-none"
    >
      <GoogleIcon /> Sign {isSignUp ? 'up' : 'in'} with Google
    </button>
  )
}

export default GoogleLogin
