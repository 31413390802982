import { NewMenuLink, NewMenuLinkType } from 'src/_polly/components/src/menu/menu'

export function formatMenuLinks({ links, website }: { links: NewMenuLink[]; website: any }) {
  return links.map(link => {
    if (link.type === NewMenuLinkType.INTERNAL && website) {
      return {
        ...link,
        url: `/websites/${website.id}${link.url}`,
      }
    }

    if (link.type === NewMenuLinkType.EMAIL) {
      return {
        ...link,
        url: `mailto:${link.url}`,
      }
    }
    return link
  })
}
