import Icon from './icons'
import { IconPropsT } from './types'

function RightArrow(props: IconPropsT) {
  const { title = 'RightArrow', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 25 25" size={size} title={title} {...otherProps}>
      {/* <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#D9D9D9" />
      <path
        d="M8.62525 17.1443L21.0931 17.1443L16.3176 21.878C16.1975 21.997 16.1277 22.1608 16.1236 22.3333C16.1196 22.5059 16.1816 22.673 16.2959 22.798C16.4103 22.923 16.5677 22.9956 16.7335 22.9998C16.8993 23.004 17.06 22.9395 17.1801 22.8205L22.6342 17.4145C22.7511 17.2928 22.8436 17.1482 22.9064 16.989C22.9692 16.8299 23.001 16.6593 23 16.4873C22.999 16.3152 22.9652 16.1451 22.9005 15.9868C22.8359 15.8284 22.7417 15.685 22.6234 15.5648L17.1801 10.17C17.0593 10.0554 16.9002 9.99447 16.7368 10.0004C16.5735 10.0063 16.4188 10.0786 16.306 10.2018C16.1932 10.3249 16.1313 10.4891 16.1334 10.6592C16.1356 10.8293 16.2017 10.9918 16.3176 11.1118L21.1132 15.8455L8.62525 15.8455C8.54314 15.8455 8.46183 15.8623 8.38597 15.895C8.31011 15.9277 8.24119 15.9756 8.18313 16.036C8.12507 16.0964 8.07901 16.1682 8.04759 16.2471C8.01617 16.326 8 16.4106 8 16.4961C8 16.5815 8.01617 16.6661 8.04759 16.7451C8.07901 16.824 8.12507 16.8957 8.18313 16.9561C8.24119 17.0166 8.31011 17.0645 8.38597 17.0972C8.46183 17.1299 8.54314 17.1467 8.62525 17.1467L8.62525 17.1443Z"
        // fill="#212222"
        // stroke="black"
        strokeLinecap="square"
      /> */}
      <rect x="0.5" y="0.5" width="24" height="24" rx="3.5" fill="white" stroke="#D9D9D9" />
      <path
        d="M6.7375 13.3957L16.478 13.3957L12.7471 17.0939C12.6533 17.1869 12.5988 17.3148 12.5956 17.4496C12.5924 17.5844 12.6409 17.715 12.7302 17.8126C12.8196 17.9103 12.9426 17.967 13.0721 17.9703C13.2016 17.9736 13.3271 17.9232 13.421 17.8302L17.682 13.6068C17.7733 13.5117 17.8456 13.3987 17.8946 13.2744C17.9437 13.1501 17.9685 13.0168 17.9678 12.8824C17.967 12.748 17.9406 12.6151 17.8901 12.4914C17.8396 12.3677 17.766 12.2556 17.6735 12.1617L13.421 7.94705C13.3266 7.85746 13.2023 7.80989 13.0747 7.81452C12.947 7.81915 12.8262 7.87563 12.7381 7.97183C12.65 8.06804 12.6016 8.19634 12.6033 8.32923C12.605 8.46212 12.6566 8.58904 12.7471 8.68279L16.4937 12.381L6.7375 12.381C6.67335 12.381 6.60983 12.3941 6.55056 12.4197C6.4913 12.4452 6.43745 12.4826 6.39209 12.5298C6.34673 12.577 6.31075 12.6331 6.2862 12.6948C6.26165 12.7564 6.24902 12.8225 6.24902 12.8893C6.24902 12.956 6.26165 13.0221 6.2862 13.0838C6.31075 13.1455 6.34673 13.2015 6.39209 13.2487C6.43745 13.2959 6.4913 13.3333 6.55056 13.3589C6.60983 13.3844 6.67335 13.3976 6.7375 13.3976L6.7375 13.3957Z"
        fill="#212222"
        stroke="black"
        strokeWidth={0.5}
        strokeLinecap="square"
      />
    </Icon>
  )
}

export default RightArrow
