import { useNavigate } from 'react-router-dom'
import MobileChatBox from 'src/icons/MobileChatBox'
import SideBuilder from 'src/icons/SideBuilder'
import ImageCreator from 'src/icons/ImageCreators'

function CreateItem({ onCreateImage, onCreatePost }: any) {
  const navigate = useNavigate()
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 -ml-5 md:-ml-0 md:w-[90%] pt-3 pb-4 gap-y-6 ">
      <div className="flex flex-col gap-1 items-center">
        <button
          type="button"
          className="hover:shadow-md rounded-xl transition-all duration-500 outline-none"
          onClick={onCreateImage}
        >
          <ImageCreator
            size={42}
            className="hidden md:block border border-[#a8a8a8] dark:border-hidden rounded-[3px]"
          />
          <ImageCreator size={115} className="md:hidden" fill="none" />
        </button>
        <p className="text-[14px] md:text-[10px] text-center w-full">Text to Image AI</p>
      </div>
      <div className="flex flex-col gap-1 items-center">
        <button
          type="button"
          onClick={() => navigate('/builder?newSite=true')}
          className="hover:shadow-md transition-all duration-500 outline-none rounded-xl"
        >
          <SideBuilder stroke="black" size={42} className="hidden md:block" />
          <SideBuilder size={115} className="md:hidden" />
        </button>
        <p className="text-[14px] md:text-[10px] text-center">Site Builder</p>
      </div>
      <div className="flex flex-col gap-1 items-center">
        <button
          type="button"
          className="hover:shadow-md rounded-xl transition-all duration-500 outline-none"
          onClick={onCreatePost}
        >
          <MobileChatBox fill="none" stroke="black" size={42} className="hidden md:block" />
          <MobileChatBox fill="none" size={115} className="md:hidden" />
        </button>
        <p className="text-[14px] md:text-[10px] text-center">Post</p>
      </div>
    </div>
  )
}

export default CreateItem
