import Icon from './icons'
import { IconPropsT } from './types'

function Close(props: IconPropsT) {
  const { title = 'Close', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 32 32" size={size} title={title} {...otherProps}>
      <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#D9D9D9" />
      <g clipPath="url(#clip0_951_2766)">
        <path d="M11.1763 11.1763L21.8233 21.8233" stroke="black" strokeWidth="2.5" strokeLinecap="round" />
        <path d="M21.8233 11.1763L11.1763 21.8233" stroke="black" strokeWidth="2.5" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_951_2766">
          <rect width="13" height="13" transform="translate(10 10)" />
        </clipPath>
      </defs>
    </Icon>
  )
}

export default Close
