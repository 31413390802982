import { motion } from 'framer-motion'
import SectionSlate from '../../../common/SectionSlatePreview'
import { AboutUsSection, AboutUsSectionServices } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

function VariantTwelve({ section }: { section: AboutUsSection }) {
  const subtitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const sectionOne = section.variables.find(variable => variable.name === 'SECTION_1')?.data
  const sectionTwo = section.variables.find(variable => variable.name === 'SECTION_2')?.data
  const services = section.variables.find(variable => variable.name === 'SERVICE')?.data as AboutUsSectionServices[]
  const clients = section.variables.find(variable => variable.name === 'CLIENT')?.data as AboutUsSectionServices[]
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1, transition: { duration: 1 } }}
      className="px-6 md:px-12 py-[50px]"
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div>
        <p
          className="text-[26px] lg:w-[40%] md:w-[80%] xl:w-[40%] capitalize  xl:text-[32px] lg:text-[26px] leading-10
        "
        >
          <SectionSlate initialValue={subtitle} previewMode />
        </p>
        <p className="mt-[40px] xl:w-[45%] lg:w-[45%] md:w-[90%] text-xl">
          <SectionSlate initialValue={paragraph} previewMode />
        </p>
      </div>
      {(services?.length !== 0 || clients?.length !== 0) && (
        <div className=" mt-[60px] xl:w-[40%] lg:w-[40%] grid grid-cols-2 xl:gap-[190px] lg:ga-[190px] ">
          {services?.length !== 0 && (
            <div className="grid-cols-1">
              <p className="text-xl font-semibold capitalize my-3 ">
                <SectionSlate initialValue={sectionOne} previewMode />
              </p>
              <ul className=" text-xl capitalize">
                {services?.map(service => (
                  <li key={service?.id}>
                    <SectionSlate initialValue={service?.name} previewMode />
                  </li>
                ))}
              </ul>
            </div>
          )}
          {clients?.length !== 0 && (
            <div className="grid-cols-1">
              <p className="text-xl font-semibold capitalize my-3 ">
                <SectionSlate initialValue={sectionTwo} previewMode />
              </p>
              <ul className=" text-xl capitalize">
                {clients?.map(service => (
                  <li key={service?.id}>
                    <SectionSlate initialValue={service?.name} previewMode />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </motion.div>
  )
}

export default VariantTwelve
