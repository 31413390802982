import { useState } from 'react'
import { LineWave } from 'react-loader-spinner'
import { NewMenu } from 'src/_polly/components/src/menu/menu'

export default function MenuIframe({
  menu,
  website,
  rounded,
  stroke,
}: {
  menu: NewMenu
  website: any
  rounded?: any
  stroke?: any
}) {
  const [loaded, setLoaded] = useState(false)
  return (
    <div className="w-full max-h-[60px] h-full relative">
      {!loaded && (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
          <LineWave height="auto" width="80" color="#000" ariaLabel="line-wave" wrapperStyle={{}} visible />
        </div>
      )}
      <iframe
        key={menu.id}
        src={`/menu-preview/?menu=${window.btoa(JSON.stringify(menu))}&website=${window.btoa(JSON.stringify(website))}`}
        frameBorder="0"
        title="Plly Preview"
        className={`w-full max-h-[500px] h-full ${stroke && 'border border-b-0'} ${rounded && 'rounded-t-[6px]'}`}
        onLoad={() => setLoaded(true)}
      />
    </div>
  )
}
