import { motion } from 'framer-motion'
import SectionSlate from '../../../common/SectionSlatePreview'
import { TextSection as TextSectionType } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

function VariantTwelve({ section }: { section: TextSectionType }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subtitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  return (
    <>
      <div
        className="py-4 md:py-12"
        style={{
          ...generateSectionBackground({
            defaultColor: 'white',
            backgroundSettings: section?.settings?.color?.background,
            image: null,
          }),
          ...generateSectionSpacing(section?.settings?.spacing),
        }}
      >
        <div className="container mx-auto relative">
          <div
            className="md:text-[1.5rem] mr-1  tracking-wide  mt-4  absolute right-0"
            style={{ textShadow: ' 1px 1px 2px black' }}
          >
            <SectionSlate initialValue={paragraph} previewMode />
          </div>
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: {
                duration: 1,
              },
            }}
          >
            <div className="flex flex-col justify-center md:px-12 px-2 py-4">
              <h1 className="relative z-10 md:text-[4.7rem] text-3xl text-[#696969] text-left ">
                <SectionSlate initialValue={title} previewMode />
              </h1>
              <h1 className="relative z-0 md:text-[14rem] text-5xl top-[-10px] ml-4 text-[#ff4d82] md:ml-0 md:top-[-50px]  md:left-[-150px] md:text-center">
                <SectionSlate initialValue={subtitle} previewMode />
              </h1>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  )
}
export default VariantTwelve
