import SectionSlate from '../../../common/SectionSlatePreview'
import { ImageOmgSection } from '../../types'
import { motion, useScroll } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantOne({ section }: { section: ImageOmgSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data

  const [offsetY, setOffsetY] = useState(0)

  const ref = useRef(null)

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['0% 30%', '50%'],
  })

  useEffect(() => {
    return scrollYProgress.onChange(latest => setOffsetY(latest))
  }, [])

  return (
    <div ref={ref}>
      <div
        className="py-[150px]"
        style={{
          ...generateSectionBackground({
            defaultColor: '#fff',
            backgroundSettings: section?.settings?.color?.background,
            image: null,
          }),
          ...generateSectionSpacing(section?.settings?.spacing),
        }}
      >
        <div className="container mx-auto px-0 md:px-0 relative pt-20 md:pt-36">
          <motion.div
            animate={{ y: 0, opacity: 1 }}
            initial={{ y: 100, opacity: 0, transitionDuration: '0.4s' }}
            className="w-full px-auto absolute top-0 z-10"
            // style={
            //   offsetY < window.innerHeight + 100
            //     ? {
            //         transform: `translateY(${window.innerWidth > 500 ? offsetY * -0.24 : offsetY * -0.2}px) scale(${
            //           1 - offsetY * 0.0009
            //         })`,
            //       }
            //     : {}
            // }
          >
            <div className="w-[750px] max-w-full px-2 mx-auto">
              <div
                className="text-[32px] md:text-4xl text-center font-bold uppercase"
                style={{ transform: `translateY(${offsetY * -120}px)` }}
              >
                <SectionSlate initialValue={title} previewMode />
              </div>
            </div>

            {subTitle && (
              <div className="w-[750px] max-w-full px-2 mx-auto mt-10">
                <div
                  style={{ transform: `translateY(${offsetY * -80}px)` }}
                  className=" md:text-4xl font-bold uppercase tracking-wide text-center"
                >
                  <SectionSlate initialValue={subTitle} previewMode />
                </div>
              </div>
            )}
          </motion.div>

          {media && (
            <div className="h-[430px] w-[300px] mx-auto overflow-hidden flex items-end">
              <img
                className="h-[550px] w-[300px] object-cover object-center bg-black"
                style={{ transform: `translateY(${offsetY * 70}px)` }}
                src={media}
                alt=""
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
