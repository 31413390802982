import Icon from './icons'
import { IconPropsT } from './types'

function SettingsBox(props: IconPropsT) {
  const { title = 'SettingsBox', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 120 120" size={size} title={title} {...otherProps}>
      <path d="M111 2H11C6.02944 2 2 6.02944 2 11V111C2 115.971 6.02944 120 11 120H111C115.971 120 120 115.971 120 111V11C120 6.02944 115.971 2 111 2Z" />
      <path
        d="M13 3.5H110C114.694 3.5 118.5 7.30558 118.5 12V109C118.5 113.694 114.694 117.5 110 117.5H13C8.30558 117.5 4.5 113.694 4.5 109V12C4.5 7.30558 8.30558 3.5 13 3.5Z"

      />
      <g clipPath="url(#clip0_1237_7308)">
        <path
          d="M86.0152 62.8982C86.1385 61.9732 86.2002 61.0174 86.2002 59.9999C86.2002 59.0132 86.1385 58.0266 85.9843 57.1016L92.2435 52.2299C92.7985 51.7982 92.9527 50.9657 92.6135 50.3491L86.6935 40.1124C86.3235 39.4341 85.5527 39.2182 84.8744 39.4341L77.5052 42.3941C75.9635 41.2224 74.3293 40.2357 72.5102 39.4957L71.4002 31.6641C71.2768 30.9241 70.6602 30.3999 69.9202 30.3999H58.0802C57.3402 30.3999 56.7543 30.9241 56.631 31.6641L55.521 39.4957C53.7018 40.2357 52.0368 41.2532 50.526 42.3941L43.1568 39.4341C42.4785 39.1874 41.7077 39.4341 41.3377 40.1124L35.4485 50.3491C35.0785 50.9966 35.2018 51.7982 35.8185 52.2299L42.0777 57.1016C41.9235 58.0266 41.8002 59.0441 41.8002 59.9999C41.8002 60.9557 41.8618 61.9732 42.016 62.8982L35.7568 67.7699C35.2018 68.2016 35.0477 69.0341 35.3868 69.6507L41.3068 79.8874C41.6768 80.5657 42.4477 80.7816 43.126 80.5657L50.4952 77.6057C52.0368 78.7774 53.671 79.7641 55.4902 80.5041L56.6002 88.3357C56.7543 89.0757 57.3402 89.5999 58.0802 89.5999H69.9202C70.6602 89.5999 71.2768 89.0757 71.3693 88.3357L72.4793 80.5041C74.2985 79.7641 75.9635 78.7774 77.4743 77.6057L84.8435 80.5657C85.5219 80.8124 86.2927 80.5657 86.6627 79.8874L92.5827 69.6507C92.9527 68.9724 92.7985 68.2016 92.2127 67.7699L86.0152 62.8982ZM64.0002 71.0999C57.8952 71.0999 52.9002 66.1049 52.9002 59.9999C52.9002 53.8949 57.8952 48.8999 64.0002 48.8999C70.1052 48.8999 75.1002 53.8949 75.1002 59.9999C75.1002 66.1049 70.1052 71.0999 64.0002 71.0999Z"
          fill="black"
        />
      </g>
      <g clipPath="url(#clip1_1237_7308)">
        <path
          d="M82.0152 61.8982C82.1385 60.9732 82.2002 60.0174 82.2002 58.9999C82.2002 58.0132 82.1385 57.0266 81.9843 56.1016L88.2435 51.2299C88.7985 50.7982 88.9527 49.9657 88.6135 49.3491L82.6935 39.1124C82.3235 38.4341 81.5527 38.2182 80.8744 38.4341L73.5052 41.3941C71.9635 40.2224 70.3293 39.2357 68.5102 38.4957L67.4002 30.6641C67.2768 29.9241 66.6602 29.3999 65.9202 29.3999H54.0802C53.3402 29.3999 52.7543 29.9241 52.631 30.6641L51.521 38.4957C49.7018 39.2357 48.0368 40.2532 46.526 41.3941L39.1568 38.4341C38.4785 38.1874 37.7077 38.4341 37.3377 39.1124L31.4485 49.3491C31.0785 49.9966 31.2018 50.7982 31.8185 51.2299L38.0777 56.1016C37.9235 57.0266 37.8002 58.0441 37.8002 58.9999C37.8002 59.9557 37.8618 60.9732 38.016 61.8982L31.7568 66.7699C31.2018 67.2016 31.0477 68.0341 31.3868 68.6507L37.3068 78.8874C37.6768 79.5657 38.4477 79.7816 39.126 79.5657L46.4952 76.6057C48.0368 77.7774 49.671 78.7641 51.4902 79.5041L52.6002 87.3357C52.7543 88.0757 53.3402 88.5999 54.0802 88.5999H65.9202C66.6602 88.5999 67.2768 88.0757 67.3693 87.3357L68.4793 79.5041C70.2985 78.7641 71.9635 77.7774 73.4743 76.6057L80.8435 79.5657C81.5219 79.8124 82.2927 79.5657 82.6627 78.8874L88.5827 68.6507C88.9527 67.9724 88.7985 67.2016 88.2127 66.7699L82.0152 61.8982ZM60.0002 70.0999C53.8952 70.0999 48.9002 65.1049 48.9002 58.9999C48.9002 52.8949 53.8952 47.8999 60.0002 47.8999C66.1052 47.8999 71.1002 52.8949 71.1002 58.9999C71.1002 65.1049 66.1052 70.0999 60.0002 70.0999Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1237_7308">
          <rect width="74" height="74" fill="white" transform="translate(27 23)" />
        </clipPath>
        <clipPath id="clip1_1237_7308">
          <rect width="74" height="74" fill="white" transform="translate(23 22)" />
        </clipPath>
      </defs>
    </Icon>
  )
}

export default SettingsBox
