import clsx from 'clsx'
import { useParams } from 'react-router-dom'
import { ButtonLinkTypes, ButtonState } from '../../constants'

type ButtonRendererProps = {
  button: ButtonState
  className?: string
  withOutHref?: boolean
  typeOfButton?: 'button' | 'submit'
}

const getLink = (link: ButtonState['link'], websiteID: string): string => {
  if (!link?.type || !link.to) return '#'

  const { to, type } = link

  if (type === ButtonLinkTypes.EXTERNAL || type === ButtonLinkTypes.DOCUMENT) return to
  if (type === ButtonLinkTypes.EMAIL) return `mailto:${to}`
  if (type === ButtonLinkTypes.INTERNAL) {
    if (websiteID) {
      return `/websites/${websiteID}${to}`
    }
    return to
  }

  return '#'
}

export default function ButtonRenderer({ button, className, withOutHref, typeOfButton }: ButtonRendererProps) {
  const params = useParams()
  const websiteID = params?.id as string

  return (
    <div className={`flex justify-evenly mt-6 ${className}`}>
      {withOutHref ? (
        <button
          type={typeOfButton}
          className={clsx(button.settings)}
          style={{ ...button.settings, fontFamily: button.settings.font, display: 'grid', placeItems: 'center' }}
        >
          {button.value}
        </button>
      ) : (
        <a
          href={getLink(button.link, websiteID)}
          target="_blank"
          className={clsx(button.settings)}
          style={{ ...button.settings, fontFamily: button.settings.font, display: 'grid', placeItems: 'center' }}
        >
          {button.value}
        </a>
      )}
    </div>
  )
}
