import GoogleFontLoader from 'react-google-font-loader'
import { AttentionGrabberSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

const VariantThirteen = ({ section }: { section: AttentionGrabberSection }) => {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const shadowTitle = new String(title[0]?.children[0]?.text ?? '')
  const bgColor = section?.settings?.color?.background?.value
  const color = title[0]?.children[0]?.color

  return (
    <div
      className="mx-auto md:min-h-[50vh] min-h-[300px] relative flex flex-col items-center justify-center text-eleven"
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '20vh',
          zIndex: 100,
          background: `linear-gradient( 0deg, ${bgColor || 'white'} 75%, ${
            bgColor ? bgColor + '90' : 'rgba(255, 255, 255, 0.9)'
          }  80%, ${bgColor ? bgColor + '25' : 'rgba(255, 255, 255, 0.25)'} 95%, ${
            bgColor ? bgColor + '00' : 'rgba(255, 255, 255, 0)'
          } 100%)`,
        }}
        className={`${bgColor ? `bg-[${bgColor}]` : 'bg-white'}] `}
      />
      <div className="shadow-text inset-y-0 text-3xl lg:text-8xl ">
        <GoogleFontLoader
          fonts={[
            {
              font: 'Yanone Kaffeesatz',
              weights: [300],
            },
          ]}
        />
        {[...shadowTitle]?.map(a => (
          <div className="shadow-wrapper uppercase">
            <div id={a} className="shadow-letter text-black" style={{ color: color ?? '#DEFAULT_COLOR' }}>
              {a}
            </div>
            <div className="shadow  opacity-60">{a}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default VariantThirteen
