import { API, Auth } from 'aws-amplify'
import {
  CreatePostLikeInput,
  CreatePostLikeMutation,
  GetPostQuery,
  LikesByPostIdQuery,
  LikeStatus,
  NotificationStatus,
  PostsByUserIdQuery,
  PostsByUserIdQueryVariables,
  SearchPostsQuery,
  SearchPostsQueryVariables,
  Topics,
  UpdatePostLikeMutation,
} from 'src/API'
import { createPostLike, updatePostLike } from 'src/graphql/mutations'
import { getPost, likesByPostId, postsByUserId, searchPosts } from 'src/graphql/queries'
import { graphqlQuery } from 'src/lib/queries'
import { createNewNotification } from './notification.service'
import { getUserById } from './user.service'

export const getPostLikesByPostId = async (postID: string, userID?: string) => {
  try {
    const { data } = (await API.graphql({
      query: likesByPostId,
      variables: {
        postID,
        filter: {
          like: { eq: LikeStatus.LIKE },
          userID: userID ? { eq: userID } : undefined,
        },
      },
    })) as { data: LikesByPostIdQuery }

    return data?.likesByPostId?.items || []
  } catch (error) {
    console.error('@post.service::getPostLikesByPostId::error', error)
    throw error
  }
}

/**
 * Each time User clicks the Like button, the app either creates new Like record or updates existing
 */
export const createPostLiked = async (postID: string) => {
  try {
    const CognitoUser = await Auth.currentAuthenticatedUser()
    const likeData = await getPostLikesByPostId(postID, CognitoUser?.username)

    // prevent multiple like
    if (likeData?.[0]) return

    // create like
    const input: CreatePostLikeInput = {
      postID,
      userID: CognitoUser?.username,
      like: LikeStatus.LIKE,
    }

    const { data } = await graphqlQuery<CreatePostLikeMutation>({
      query: createPostLike,
      variables: { input },
    })

    const post: any = await serviceGetPost(postID)

    if (post.userID !== CognitoUser?.username) {
      await createNewNotification({
        owner: CognitoUser?.username,
        toNotifyID: post.userID,
        topic: Topics.NEW_POST_LIKE,
        topicID: postID,
        topicDescription: 'liked your ' + (post.type || 'post').toLowerCase(),
        topicUrl: '/posts/' + postID,
        status: NotificationStatus.NOTIFIED,
      })
    }

    return data?.createPostLike
  } catch (error) {
    console.error('@post.service::createPostLiked::error', error)
    throw error
  }
}

/**
 * Unlike Post will just update the PostLike like field to UNLIKE
 * Use the LikeStatus
 **/

export const postUnLiked = async (postID: string) => {
  try {
    const CognitoUser = await Auth.currentAuthenticatedUser()
    const likeData = await getPostLikesByPostId(postID, CognitoUser?.username)
    const like = likeData?.[0]

    if (!like) return

    const { data } = await graphqlQuery<UpdatePostLikeMutation>({
      query: updatePostLike,
      variables: {
        input: {
          id: like.id,
          like: LikeStatus.UNLIKE,
        },
      },
    })

    return data?.updatePostLike
  } catch (error) {
    console.error('@post.service::postUnLiked::error', error)
    throw error
  }
}

export const serviceGetPost = async (id: string) => {
  try {
    const { data } = await graphqlQuery<GetPostQuery>({
      query: getPost,
      variables: { id },
    })

    const post: any = data?.getPost
    if (!post) return null

    const CognitoUser = await Auth.currentAuthenticatedUser()

    post.isOwner = CognitoUser?.username === post.userID

    return post
  } catch (err) {
    console.error('@post.service::serviceGetPost::error', err)
    throw err
  }
}

export const listPostsByUserID = async (params: PostsByUserIdQueryVariables) => {
  try {
    const { data } = await graphqlQuery<PostsByUserIdQuery>({
      query: postsByUserId,
      variables: params,
    })

    return {
      items: data?.postsByUserId?.items || [],
      nextToken: data?.postsByUserId?.nextToken || null,
    }
  } catch (err) {
    console.error('@post.service::listPostsByUserID::error', err)
    throw err
  }
}

export const serviceSearchPost = async (query: SearchPostsQueryVariables) => {
  try {
    const { data } = await graphqlQuery<SearchPostsQuery>({
      query: searchPosts,
      variables: query,
    })

    if (!data?.searchPosts?.items) return []
    const res = await Promise.all(
      data.searchPosts.items.map(async (post: any) => ({
        ...post,
        user: await getUserById(post.userID),
      })),
    )
    return res
  } catch (error) {
    console.error('@post.service::serviceSearchPost::error', error)
    throw error
  }
}
