import Icon from './icons'
import { IconPropsT } from './types'

export default function MobileChatBox(props: IconPropsT) {
  const { title = 'post', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 120 120" size={size} title={title} {...otherProps}>
      <path
        d="M111 0H9C4.02944 0 0 4.02944 0 9V111C0 115.971 4.02944 120 9 120H111C115.971 120 120 115.971 120 111V9C120 4.02944 115.971 0 111 0Z"
        fill="#D9D9D9"
        strokeWidth="1"
      />
      <path
        d="M108.5 2.5H11.5C6.52944 2.5 2.5 6.52944 2.5 11.5V108.5C2.5 113.471 6.52944 117.5 11.5 117.5H108.5C113.471 117.5 117.5 113.471 117.5 108.5V11.5C117.5 6.52944 113.471 2.5 108.5 2.5Z"
        fill="#D9D9D9"
        stroke="none"
      />
      <rect x="41.5" y="16.5" width="42" height="79" rx="3.5" fill="black" stroke="black" />
      <rect x="41.5" y="19.5" width="42" height="71" rx="3.5" fill="white" stroke="black" />
      <path d="M34.957 52.848L50.2799 46.0248L49.2658 64.3644L34.957 52.848Z" fill="#0B0B0B" />
      <path
        d="M47.8018 61.0772L48.4906 48.62L38.0824 53.2547L47.8018 61.0772ZM51.8803 68.5779C51.8802 68.5778 50.9882 67.8598 50.7747 67.6881C51.6027 68.3544 51.8803 68.5779 51.8803 68.5779ZM50.7747 67.6881C50.4133 67.3971 49.9469 67.0218 49.3529 66.5437C44.6283 62.7412 31.831 52.4414 31.831 52.4414L52.0687 43.4296L50.7293 67.6515C50.7293 67.6515 50.7457 67.6647 50.7747 67.6881Z"
        fill="#0B0B0B"
      />
      <path
        d="M53.3235 39H30.2843C28.4704 39 27 40.4704 27 42.2843V58.9782C27 60.7921 28.4704 62.2625 30.2843 62.2625H53.3235C55.1373 62.2625 56.6077 60.7921 56.6077 58.9782V42.2843C56.6077 40.4704 55.1373 39 53.3235 39Z"
        fill="black"
      />
      <path
        d="M53.3237 39.821H30.2845C28.9241 39.821 27.8213 40.9239 27.8213 42.2842V58.9782C27.8213 60.3386 28.9241 61.4414 30.2845 61.4414H53.3237C54.6841 61.4414 55.7869 60.3386 55.7869 58.9782V42.2842C55.7869 40.9239 54.6841 39.821 53.3237 39.821Z"
        fill="black"
        stroke="#040404"
        strokeWidth="0.5"
      />
      <path d="M34.8682 52.3006L50.0205 45.7471L49.0176 63.3617L34.8682 52.3006Z" fill="white" />
      <path
        d="M47.5703 60.2045L48.2514 48.2396L37.9592 52.6912L47.5703 60.2045ZM51.6033 67.4086C51.6033 67.4086 50.7211 66.719 50.5101 66.554C51.3288 67.194 51.6034 67.4087 51.6033 67.4086ZM50.5101 66.554C50.1526 66.2746 49.6915 65.9141 49.1041 65.4549C44.4322 61.8027 31.7774 51.91 31.7774 51.91L51.7896 43.2545L50.4651 66.5189C50.4651 66.5189 50.4814 66.5316 50.5101 66.554Z"
        fill="white"
      />
      <path
        d="M53.1411 40H31.1368C29.4044 40 28 41.3491 28 43.0133V58.3297C28 59.9939 29.4044 61.343 31.1368 61.343H53.1411C54.8735 61.343 56.2779 59.9939 56.2779 58.3297V43.0133C56.2779 41.3491 54.8735 40 53.1411 40Z"
        fill="white"
      />
      <path
        d="M53.1411 40.7533H31.1368C29.8375 40.7533 28.7842 41.7651 28.7842 43.0132V58.3297C28.7842 59.5778 29.8375 60.5896 31.1368 60.5896H53.1411C54.4404 60.5896 55.4937 59.5778 55.4937 58.3297V43.0132C55.4937 41.7651 54.4404 40.7533 53.1411 40.7533Z"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
      />
    </Icon>
  )
}
