import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { GallerySection, GallerySectionItem } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantFive({ section }: { section: GallerySection }) {
  const gallery = section.variables.find(variable => variable.name === 'MEDIA')?.data as GallerySectionItem[]

  return (
    <div
      className="py-[50px]"
      style={{
        ...generateSectionBackground({
          defaultColor: '#000',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className=" container w-[1300px]  max-w-full grid-cols-6 space-y-2 lg:space-y-0 md:grid-col-3 lg:grid lg:gap-3 lg:grid-rows-3 mx-auto">
        {gallery.map((data, idx) => {
          if (idx % 9 === 0) {
            return (
              <div className={`w-full col-span-3 bg-violet-800 bg-center bg-cover bg-white relative tmp-inner`}>
                <img
                  className="tmp-highlight-inner object-cover object-center bg-black w-full h-full"
                  src={data.image}
                  alt=""
                />
                <div className="hover-box flex justify-center items-center mx-auto py-[20px] px-6">
                  <span>
                    <h1 className="text-[20px] text-center py-1 lg:py-6 mx-auto text-white">
                      <SectionSlatePreview initialValue={data.title} previewMode />
                    </h1>
                    <p className="text-[16px] font-semibold text-white text-center mx-auto">
                      <SectionSlatePreview initialValue={data.description} previewMode />
                    </p>
                  </span>
                </div>
              </div>
            )
          } else if (idx % 9 === 1) {
            return (
              <div className={`w-full col-span-3 bg-violet-800 bg-center bg-cover bg-white relative tmp-inner`}>
                <img
                  className="tmp-highlight-inner object-cover object-center bg-black w-full h-full"
                  src={data.image}
                  alt=""
                />
                <div className="hover-box flex justify-center items-center mx-auto py-[20px] px-6">
                  <span>
                    <h1 className="text-[20px] text-center py-1 lg:py-6 mx-auto text-white">
                      {' '}
                      <SectionSlatePreview initialValue={data.title} previewMode />
                    </h1>
                    <p className="text-[16px] font-semibold text-white text-center mx-auto">
                      <SectionSlatePreview initialValue={data.description} previewMode />
                    </p>
                  </span>
                </div>
              </div>
            )
          } else if (idx % 9 === 2) {
            return (
              <div className={`w-full col-span-2 bg-violet-800 bg-center bg-cover bg-white relative tmp-inner`}>
                <img
                  className="tmp-highlight-inner object-cover object-center bg-black w-full h-full"
                  src={data.image}
                  alt=""
                />

                <div className="hover-box flex justify-center items-center mx-auto py-[20px] px-6">
                  <span>
                    <h1 className="text-[20px] text-center py-1 lg:py-6 mx-auto text-white">
                      {' '}
                      <SectionSlatePreview initialValue={data.title} previewMode />
                    </h1>
                    <p className="text-[16px] font-semibold text-white text-center mx-auto">
                      <SectionSlatePreview initialValue={data.description} previewMode />
                    </p>
                  </span>
                </div>
              </div>
            )
          } else if (idx % 9 === 3) {
            return (
              <div className={`w-full col-span-4 bg-violet-800 bg-center bg-cover bg-white relative tmp-inner`}>
                <img
                  className="tmp-highlight-inner object-cover object-center bg-black w-full h-full"
                  src={data.image}
                  alt=""
                />

                <div className="hover-box flex justify-center items-center mx-auto py-[20px] px-6">
                  <span>
                    <h1 className="text-[20px] text-center py-1 lg:py-6 mx-auto text-white">
                      {' '}
                      <SectionSlatePreview initialValue={data.title} previewMode />
                    </h1>
                    <p className="text-[16px] font-semibold text-white text-center mx-auto">
                      <SectionSlatePreview initialValue={data.description} previewMode />
                    </p>
                  </span>
                </div>
              </div>
            )
          } else if (idx % 9 === 4) {
            return (
              <div className={`w-full col-span-4 bg-violet-800 bg-center bg-cover bg-white relative tmp-inner`}>
                <img
                  className="tmp-highlight-inner object-cover object-center bg-black w-full h-full"
                  src={data.image}
                  alt=""
                />

                <div className="hover-box flex justify-center items-center mx-auto py-[20px] px-6">
                  <span>
                    <h1 className="text-[20px] text-center py-1 lg:py-6 mx-auto text-white">
                      {' '}
                      <SectionSlatePreview initialValue={data.title} previewMode />
                    </h1>
                    <p className="text-[16px] font-semibold text-white text-center mx-auto">
                      <SectionSlatePreview initialValue={data.description} previewMode />
                    </p>
                  </span>
                </div>
              </div>
            )
          } else if (idx % 9 === 5) {
            return (
              <div className={`w-full col-span-2 bg-violet-800 bg-center bg-cover bg-white relative tmp-inner`}>
                <img
                  className="tmp-highlight-inner object-cover object-center bg-black w-full h-full"
                  src={data.image}
                  alt=""
                />

                <div className="hover-box flex justify-center items-center mx-auto py-[20px] px-6">
                  <span>
                    <h1 className="text-[20px] text-center py-1 lg:py-6 mx-auto text-white">
                      {' '}
                      <SectionSlatePreview initialValue={data.title} previewMode />
                    </h1>
                    <p className="text-[16px] font-semibold text-white text-center mx-auto">
                      <SectionSlatePreview initialValue={data.description} previewMode />
                    </p>
                  </span>
                </div>
              </div>
            )
          } else {
            return (
              <div className={`w-full col-span-3 bg-violet-800 bg-center bg-cover bg-white relative tmp-inner`}>
                <img
                  className="tmp-highlight-inner object-cover object-center bg-black w-full h-full"
                  src={data.image}
                  alt=""
                />

                <div className="hover-box flex justify-center items-center mx-auto py-[20px] px-6">
                  <span>
                    <h1 className="text-[20px] text-center py-1 lg:py-6 mx-auto text-white">
                      {' '}
                      <SectionSlatePreview initialValue={data.title} previewMode />
                    </h1>
                    <p className="text-[16px] font-semibold text-white text-center mx-auto">
                      <SectionSlatePreview initialValue={data.description} previewMode />
                    </p>
                  </span>
                </div>
              </div>
            )
          }
          // if (idx % 9 === 0) {
          //   return <GalleryItem data={data} col={3} />
          // } else if (idx % 9 === 1) {
          //   return <GalleryItem data={data} col={3} />
          // } else if (idx % 9 === 2) {
          //   return <GalleryItem data={data} col={2} />
          // } else if (idx % 9 === 3) {
          //   return <GalleryItem data={data} col={4} />
          // } else if (idx % 9 === 4) {
          //   return <GalleryItem data={data} col={4} />
          // } else if (idx % 9 === 5) {
          //   return <GalleryItem data={data} col={2} />
          // } else {
          //   return <GalleryItem data={data} col={3} />
          // }
        })}
      </div>
    </div>
  )
}
