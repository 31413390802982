import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { GallerySection, GallerySectionItem } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantTwo({ section }: { section: GallerySection }) {
  const gallery = section.variables.find(variable => variable.name === 'MEDIA')?.data as GallerySectionItem[]

  return (
    <div
      className="md:py-[50px] py-4"
      style={{
        ...generateSectionBackground({
          defaultColor: '#000',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="container max-w-full h-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mx-auto gap-2 ">
        {gallery.map((data, idx) => (
          <div key={idx} style={{ aspectRatio: '4 / 3' }} className="bg-center bg-cover bg-white relative tmp-inner">
            <img className="object-cover object-center bg-black w-full h-full" src={data.image} alt="" />
            <div className="hover-box flex justify-center items-center mx-auto py-[20px] px-6">
              <span>
                <h1 className="text-[20px] text-center py-1 lg:py-6 mx-auto text-white">
                  <SectionSlatePreview initialValue={data.title} previewMode />
                </h1>
                <p className="text-[10px] lg:text-[14px] text-white text-center mx-auto">
                  <SectionSlatePreview initialValue={data.description} previewMode />
                </p>
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
