import clsx from 'clsx'
import moment from 'moment'
import { useContext, useState } from 'react'
import { IoTrash } from 'react-icons/io5'
import { Bars } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom'
import { UserContext } from 'src/components/context/UserContext'
import DeleteConfirmModal from 'src/components/modal/DeleteConfirmModal'
import { deleteThread } from 'src/services/messenger.service'
import useMessengerStore from 'src/store/messenger'

export default function ChatMateListItem(props: any) {
  const [isOpen, setIsOpen] = useState(false)
  const { paramEntry, active } = props
  const navigate = useNavigate()
  const messengerStore = useMessengerStore(state => state)

  const [deleting, setDeleting] = useState(false)

  const { chatmate, chatmateID, lastMessage, convID } = paramEntry

  const { user } = useContext(UserContext)
  const { createdAt, senderID, read } = lastMessage || {}

  const contentRead = senderID === user?.id || read || !lastMessage
  const preview = lastMessage?.content

  const momentCreatedAt = createdAt && moment(createdAt)
  const chatmateName = chatmate?.firstName ? `${chatmate?.firstName} ${chatmate?.lastName}` : chatmate?.username ?? ''

  const handleDelete = () => {
    setDeleting(true)
    deleteThread({
      to: chatmateID,
    })
      .then(conv => {
        messengerStore.removeThread(conv?.id)
        navigate('/messages/new', { replace: true })
      })
      .finally(() => {
        setDeleting(false)
        setIsOpen(false)
      })
  }
  const handleCancelDelete = (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsOpen(!isOpen)
  }

  return (
    <>
      <DeleteConfirmModal
        open={isOpen}
        text="Are you sure you would like to delete this thread ?"
        onConfirm={handleDelete}
        onClose={handleCancelDelete}
      />
      <button
        type="button"
        onClick={() => {
          navigate(`/messages/${chatmateID}`)
        }}
        className={`px-5 block cursor-pointer -mt-[1px] w-full ${active ? 'bg-white' : ''}`}
      >
        <div className="flex gap-8 py-4 items-center">
          <div className="h-[45px] w-[45px]">
            <img
              className="h-full w-full object-cover object-center rounded-full bg-gray-200"
              src={chatmate?.profilePicture ?? ''}
              alt=""
            />
          </div>

          <div className="flex flex-1">
            <div className="w-full">
              <p className={`text-xl text-left font-bold ${active ? 'text-black' : 'text-white'}`}>{chatmateName}</p>
              <div className="justify-between hidden">
                <p className={clsx(active ? 'text-black' : 'text-white')}>{preview}</p>
                <div className="flex justify-end">
                  <p className={clsx(active ? 'text-black' : 'text-white')}>
                    {momentCreatedAt &&
                      momentCreatedAt.format(moment().diff(momentCreatedAt, 'h') > 24 ? 'MMM DD' : 'h:mm A')}
                  </p>
                </div>
              </div>
            </div>

            <div>{!contentRead && <div className="h-2 w-2 bg-blue-500 rounded-full" />}</div>
          </div>

          {convID && (
            <div>
              <button
                type="button"
                className="h-6 w-6 flex justify-center items-center"
                disabled={deleting}
                // onClick={e => {
                //   e.stopPropagation()
                //   handleDelete()
                // }}
                onClick={() => setIsOpen(!isOpen)}
              >
                {deleting ? (
                  <Bars
                    height="25"
                    width="25"
                    color={active ? '#000' : '#fff'}
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                ) : (
                  <IoTrash color={active ? '#000' : '#fff'} size={20} />
                )}
              </button>
            </div>
          )}
        </div>
        <div className="px-4 border-b border-white h-[1px] w-full" />
      </button>
    </>
  )
}
