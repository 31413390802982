import axios from 'axios'
import { useEffect, useState } from 'react'

const useAddress = () => {
  const [token, setToken] = useState('')
  const [countries, setCountries] = useState<any>([])
  const url = 'https://www.universal-tutorial.com/api/getaccesstoken'
  const headers = {
    Accept: 'application/json',
    'api-token': 'ETi0wrHLgikKwyaqNRBPThJ7ShRp508gkxlqwXI-Fp1MljviPXaWr7IL8zbz_66YOZk',
    'user-email': 'dev.muhammadshafi@gmail.com',
  }
  const fetchToken = () => {
    axios.get(url, { headers }).then(data => setToken(data?.data?.auth_token))
  }
  const fetchCountries = () => {
    axios
      .get('https://www.universal-tutorial.com/api/countries', {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      })
      .then(data => setCountries(data))
  }
  useEffect(() => {
    if (token) {
      fetchCountries()
    } else {
      fetchToken()
    }
  }, [token])
  return { token, countries }
}

export default useAddress
