import { useContext, useEffect, useState } from 'react'
import { UserContext } from 'src/components/context/UserContext'
import { createPostLiked, getPostLikesByPostId, postUnLiked } from 'src/services/post.service'

export default function usePostLike(postID: string) {
  const { user: currentUser } = useContext(UserContext)
  const userId = currentUser?.id
  const [like, setLike] = useState({
    status: false,
    invalidated: false,
  })

  const [likedBy, setLikedBy] = useState<any>([])

  useEffect(() => {
    getPostLikesByPostId(postID, userId)
      .then(res => {
        setLike({
          status: res.some(like => like?.user?.id === userId),
          invalidated: false,
        })
        setLikedBy(res.filter(like => like?.user && like.user.id !== userId))
      })
      .catch(err => {
        console.log(err)
      })
  }, [postID, userId])

  useEffect(() => {
    if (!like.invalidated) return

    const timer = setTimeout(async () => {
      like.status ? createPostLiked(postID) : postUnLiked(postID)
    }, 1000)

    return () => clearTimeout(timer)
  }, [postID, like])

  return {
    liked: like.status,
    count: likedBy.length + like.status,
    toggleLike: () =>
      setLike(x => ({
        invalidated: true,
        status: !x.status,
      })),
  }
}
