import clsx from 'clsx'
import SectionSlatePreview from '../../../../common/SectionSlatePreview'
import { TeamMembersSectionMember } from './../../../types'

export default function Team1({ member, isRound }: { member: TeamMembersSectionMember; isRound?: boolean }) {
  return (
    <div>
      <div className="border-2 lg:border-4 lg:outline-8 rounded-full lg:rounded-full bg-slate-900 px-2">
        <div className="flex flex-row items-center gap-6 md:gap-8 py-2 md:py-0">
          <div className="basis-1/5 lg:w-[300px]  w-[80px] h-[80px] lg:h-[300px] mx-auto text-right mr-2 ml-2 md:ml-4 py-[10px] lg:py-[20px]">
            <img
              className={clsx('h-full w-full object-cover object-center', isRound && 'rounded-full')}
              src={member.image}
              alt=""
            />
          </div>
          <div className="basis-1/2  w-[40px] lg:w-[200px]">
            <div className="">
              <p className="text-white text-[10px] lg:text-4xl lg:font-medium ">
                <SectionSlatePreview initialValue={member.name} previewMode />
              </p>
              {member.subtitle && (
                <p className="text-white text-[8px]  lg:mt-2 lg:text-[20px] ">
                  <SectionSlatePreview initialValue={member.subtitle} previewMode />
                </p>
              )}
              {member.description && (
                <p className="text-white text-[6px] lg:text-sm mt-1 lg:mt-6 mb-2 ">
                  <SectionSlatePreview initialValue={member.description} previewMode />
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      {member.button && (
        <div className="flex justify-center mt-5">
          <a className="h-11 px-4 bg-black inline-flex justify-center items-center" href={member.button.url}>
            {member.button.label}
          </a>
        </div>
      )}
    </div>
  )
}
