import clsx from 'clsx'
import { Descendant } from 'slate'

import { useContext, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ReactComponent as ImageIcon } from 'src/assets/icons/new/image.svg'
import ImageUploader from 'src/components/new/ImageUploader'
import Yolo from 'src/icons/ComponentsIcons/Yolo'
import {
  SectionVariableCategories,
  TextSection,
  TextSectionMediaGroup,
  TextSectionVariables,
} from 'src/_polly/components/src/sections'
import { v4 as uuid } from 'uuid'
import { ReactComponent as DeleteIcon } from '../../assets/icons/menuBar/delete.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/menuBar/edit.svg'
import { upsertBlockByPageID } from '../../services/website.service'
import BottomFloatingButton from '../BottomFloatingButton'
import { WebsiteContext } from '../context/WebsiteContext'
import { AdvancedFeature } from '../modal/advanced'
import { MenuSaveButton } from '../modal/section/NewMenu/NewMenu'
import { ButtonForSection } from './ButtonEditor'
import ModalDialogV2 from './ModalDialogV2'
import SectionMediaField from './SectionField/SectionMediaField'
import SectionTextField from './SectionField/SectionTextField'
import ItemField from './SectionField/SectionSingleItemField'

type EdiItemProps = {
  data?: TextSectionMediaGroup
  onSave: (data: Partial<TextSectionMediaGroup>) => void
}

function Item({
  data,
  onDeleteClick,
  onEditClick,
}: {
  data: TextSectionMediaGroup
  onEditClick: () => void
  onDeleteClick: () => void
}) {
  return (
    <div className="border border-black dark:border-white h-[65px] flex justify-between">
      <div className="flex items-center">
        <div className="grid grid-cols-2 gap-1 ml-2 mr-4">
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
        </div>
        <div className="flex items-center gap-4">
          <div className="aspect-1 h-10">
            <img className="object-cover object-center bg-black w-full h-full" src={data.image} alt="" />
          </div>
          <p>
            <ItemField initialValue={data?.name} />
          </p>
        </div>
      </div>

      <div className="flex">
        <button
          type="button"
          className="flex justify-center items-center w-16 border-l dark:border-white border-black"
          onClick={onEditClick}
        >
          <EditIcon />
        </button>
        <button
          type="button"
          className="flex justify-center items-center w-16 border-l dark:border-white border-black"
          onClick={onDeleteClick}
        >
          <DeleteIcon />
        </button>
      </div>
    </div>
  )
}
type EditSection = {
  open: boolean
  section: TextSection | null
  onClose: () => void
  onUpdate?: (section: TextSection) => void
}
type FormInput = {
  name: any
  description: any
}

function EditItem({ data, onSave }: EdiItemProps) {
  const initialDataName: Descendant[] = [
    {
      type: 'paragraph',
      children: [
        {
          text: 'item name',
        },
      ],
    },
  ]
  const initialDataDescription: Descendant[] = [
    {
      type: 'paragraph',
      children: [
        {
          text: 'item description',
        },
      ],
    },
  ]
  const [showImageModal, setShowImageModal] = useState(false)
  const [url, setUrl] = useState(data?.image || '')
  const [names, setName] = useState(data?.name || initialDataName)
  const [descriptions, setDescription] = useState(data?.description || initialDataDescription)
  const { handleSubmit } = useForm<FormInput>()
  const onSubmit: SubmitHandler<FormInput> = value => {
    setName(value?.name)
    setDescription(value?.description)
    setUrl(url)
    onSave({
      ...value,
      id: data?.id,
      name: names,
      description: descriptions,
      image: url,
    })
  }

  return (
    <div className="px-4 dark:text-white">
      <form className="pb-24" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-8">
          <div>
            <SectionTextField
              initialValue={names ?? initialDataName}
              title="Name"
              onChange={value => setName(value)}
              onUpdate={() => handleSubmit(onSubmit)}
            />
          </div>
          <div>
            <div>
              <SectionTextField
                initialValue={descriptions ?? initialDataName}
                title="Description"
                onChange={value => setDescription(value)}
                onUpdate={() => handleSubmit(onSubmit)}
              />
            </div>
          </div>
          <div className="space-y-4">
            <h2 className="text-[19px] font-semibold">Image</h2>
            <button type="button" className="w-full" onClick={() => setShowImageModal(true)}>
              <div
                className={clsx(
                  'w-full bg-white dark:bg-black',
                  !url && 'aspect-1 flex justify-center items-center border border-black dark:border-white',
                  url && 'aspect-1',
                )}
              >
                {url && <img src={url} className="object-cover object-center bg-black w-full h-full" alt="" />}
                {!url && <ImageIcon />}
              </div>
            </button>
          </div>
        </div>
      </form>

      <BottomFloatingButton
        label={data?.id ? 'Update' : 'Add'}
        onClick={handleSubmit(onSubmit)}
        disabled={!url || !names}
      />

      <ModalDialogV2 open={showImageModal} onBack={() => setShowImageModal(false)}>
        <ImageUploader
          onSubmit={_url => {
            setUrl(_url)
            setShowImageModal(false)
          }}
          buttonLabel="Select Image"
          onClose={() => setShowImageModal(false)}
        />
      </ModalDialogV2>
    </div>
  )
}

export default function EditSectionTextModal({ open, section: defaultValue, onClose: onBack, onUpdate }: EditSection) {
  const websiteContext = useContext(WebsiteContext)

  if (!open || !defaultValue) return null
  const [section, setSection] = useState(defaultValue)
  const [updating, setUpdating] = useState(false)
  const [editItem, setEditItem] = useState<TextSectionMediaGroup>()
  const [showAddItemModal, setShowAddItemModal] = useState(false)
  const [fireUpdate, setFireUpdate] = useState({
    fire: false,
    goBack: false,
  })

  const title = section.variables.find(variable => variable.name === 'TITLE')
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')
  const media = section.variables.find(variable => variable.name === 'MEDIA')
  const items = section.variables.find(variable => variable.name === 'MEDIAGROUP')
  const button = section.variables.find(variable => variable.name === 'BUTTON_1')

  const updateVariableData = (name: TextSectionVariables, category: SectionVariableCategories, data: any) => {
    const current = section.variables.find(variable => variable.name === name)
    if (!current) {
      setSection({
        ...section,
        variables: [...section.variables, { name, data, category, id: uuid() }],
      })
      return
    }
    const otherVariables = section.variables.filter(variable => variable.name !== name)
    const newVariables = [...otherVariables, { ...current, data }]
    setSection({ ...section, variables: newVariables })
  }

  const handleDelete = (id: string) => {
    const newItems = items?.data.filter((item: TextSectionMediaGroup) => item.id !== id)
    setSection({
      ...section,
      variables: section.variables.map(variable => {
        if (variable.name === 'MEDIAGROUP') {
          return { ...variable, data: newItems }
        }
        return variable
      }),
    })
  }
  const handleEditAdd = (data: Partial<TextSectionMediaGroup>) => {
    if (data.id) {
      const newItems = items?.data.map((item: TextSectionMediaGroup) => {
        if (item.id === data.id) {
          return { ...item, ...data }
        }
        return item
      })
      setSection({
        ...section,
        variables: section.variables.map(variable => {
          if (variable.name === 'MEDIAGROUP') {
            return { ...variable, data: newItems }
          }
          return variable
        }),
      })
    } else {
      setSection({
        ...section,
        variables: section.variables.map(variable => {
          if (variable.name === 'MEDIAGROUP') {
            return { ...variable, data: [...variable.data, { ...data, id: uuid() }] }
          }
          return variable
        }),
      })
    }
    setEditItem(undefined)
    setShowAddItemModal(false)
    handleSave(false)
  }

  const handleSave = async (goBack = true) => {
    setFireUpdate({ fire: true, goBack })
  }

  useEffect(() => {
    const update = async (goBack = true) => {
      setUpdating(true)
      await upsertBlockByPageID(websiteContext.pageID, section as any)
      setUpdating(false)
      setFireUpdate({ fire: false, goBack: false })
      if (goBack) onBack()
    }
    if (fireUpdate.fire) update(fireUpdate.goBack)
  }, [fireUpdate, section])

  useEffect(() => {
    console.log('updating section', section)
    onUpdate?.(section)
  }, [section])
  return (
    <>
      <ModalDialogV2 {...{ open, onBack }}>
        <div className="px-5 dark:text-white mt-[25px]">
          <div className="border-t-[1px] border-black dark:border-white">
            {section.variant !== 'VARIANT_5' &&
              section.variant !== 'VARIANT_9' &&
              section.variant !== 'VARIANT_10' &&
              section.variant !== 'VARIANT_14' && (
                <SectionTextField
                  initialValue={title?.data ?? []}
                  onChange={value => {
                    console.log('calling update variable', value)
                    updateVariableData('TITLE', 'TEXT', value)
                  }}
                  onUpdate={() => handleSave(false)}
                />
              )}

            {section.variant !== 'VARIANT_5' &&
              section.variant !== 'VARIANT_6' &&
              section.variant !== 'VARIANT_7' &&
              section.variant !== 'VARIANT_10' &&
              section.variant !== 'VARIANT_2' &&
              section.variant !== 'VARIANT_11' &&
              section.variant !== 'VARIANT_13' &&
              section.variant !== 'VARIANT_14' &&
              section.variant !== 'VARIANT_15' && (
                <SectionTextField
                  title="Subtitle"
                  initialValue={subTitle?.data ?? []}
                  onChange={value => updateVariableData('SUBTITLE', 'TEXT', value)}
                  onUpdate={() => handleSave(false)}
                />
              )}

            {section.variant !== 'VARIANT_4' &&
              section.variant !== 'VARIANT_5' &&
              section.variant !== 'VARIANT_6' &&
              section.variant !== 'VARIANT_13' &&
              section.variant !== 'VARIANT_14' && (
                <SectionTextField
                  title="Paragraph"
                  initialValue={paragraph?.data ?? []}
                  isParagraph
                  onChange={value => updateVariableData('PARAGRAPH', 'TEXT', value)}
                  onUpdate={() => handleSave(false)}
                />
              )}

            {section.variant !== 'VARIANT_1' &&
              section.variant !== 'VARIANT_6' &&
              section.variant !== 'VARIANT_5' &&
              section.variant !== 'VARIANT_7' &&
              section.variant !== 'VARIANT_8' &&
              section.variant !== 'VARIANT_11' &&
              section.variant !== 'VARIANT_9' &&
              section.variant !== 'VARIANT_12' &&
              section.variant !== 'VARIANT_13' &&
              section.variant !== 'VARIANT_14' &&
              section.variant !== 'VARIANT_15' && (
                <SectionMediaField
                  defaultValue={media?.data}
                  onChange={url => {
                    if (!url) {
                      setSection({
                        ...section,
                        variables: section.variables.filter(variable => variable.name !== 'MEDIA'),
                      })
                      return
                    }
                    updateVariableData('MEDIA', 'IMAGE', url)
                    handleSave(false)
                  }}
                />
              )}

            {section.variant === 'VARIANT_5' || section.variant === 'VARIANT_6' || section.variant === 'VARIANT_14' ? (
              <div className="space-y-4 pb-24 mt-12">
                <h2 className="capitalize text-[19px] font-semibold ">Items</h2>
                <div className="space-y-5">
                  {items?.data.map((item: TextSectionMediaGroup) => (
                    <Item
                      key={item.id}
                      data={item}
                      onDeleteClick={() => handleDelete(item.id)}
                      onEditClick={() => setEditItem(item)}
                    />
                  ))}
                </div>
                <button
                  className="border border-black bg-black dark:border-white h-[65px] flex justify-between items-center text-lg px-6 w-full text-white"
                  type="button"
                  onClick={() => setShowAddItemModal(true)}
                >
                  <span>Add Item</span> <span className="text-4xl">+</span>
                </button>
              </div>
            ) : null}
            <div>
              {section.variant === 'VARIANT_3' || section.variant === 'VARIANT_4' ? (
                // (section.variant === 'VARIANT_4' && (
                <ButtonForSection
                  defaultValue={button?.data ?? null}
                  onButtonChange={update => updateVariableData('BUTTON_1', 'BUTTON', update)}
                  onChange={update => updateVariableData('BUTTON_1', 'BUTTON', update)}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className="absolute top-[21px] left-[50%] translate-x-[-50%]">
          <Yolo width="auto" height={25} />
        </div>
        <div className="absolute top-[21px] right-[50px]">
          <button type="button" onClick={() => handleSave()} disabled={updating}>
            <MenuSaveButton save="save" />
          </button>
        </div>
        {updating && (
          <div className="fixed z-30 inset-0 lg:max-w-[500px] dark:bg-black/80 bg-white/80">
            <p className="flex items-center h-full justify-center w-full text-[19px] dark:text-white">Updating...</p>
          </div>
        )}

        <AdvancedFeature
          section={section}
          onChange={settings => setSection(s => ({ ...s, settings }))}
          onBack={() => handleSave(false)}
        />
      </ModalDialogV2>

      <ModalDialogV2
        open={editItem !== undefined || showAddItemModal}
        onBack={() => (editItem ? setEditItem(undefined) : setShowAddItemModal(false))}
      >
        <EditItem data={editItem} onSave={handleEditAdd} />
      </ModalDialogV2>
    </>
  )
}
