import { useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Loader } from 'src/components/loader'
import useBatchFetch from 'src/hooks/useBatchFetch'
import { getFeedPosts } from 'src/services/feed.service'
import useFeedStore from 'src/store/feed'
import BottomNavigation from '../feed/components/BottomNavigation'
import Header from './components/Header'
import Post from './components/Post'
import SearchAndFilter from './components/SearchAndFilter'

function Posts() {
  const feedStore = useFeedStore(state => state)

  const { items, fetching, nextBatch, hasMore } = useBatchFetch(getFeedPosts as any)

  useEffect(() => {
    feedStore.setPosts(items)
  }, [items])

  return (
    <div className="bg-white dark:bg-black min-h-screen" onScroll={console.log}>
      <Header />
      <Loader show={fetching} />

      <div className="px-6 sm:px-0 sm:w-[370px] md:w-[720px] pt-6 md:pt-0 mx-auto">
        <div className=" hidden md:block">
          <SearchAndFilter />
        </div>
        <div>
          <InfiniteScroll loadMore={nextBatch} hasMore={hasMore}>
            {feedStore.posts.map((post, idx) => (
              <div key={idx}>
                <Post post={post} filterGrid="one" />
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
      <div>
        <BottomNavigation variant="one" />
      </div>
    </div>
  )
}

export default Posts
