import Alarm from 'src/icons/AlarmDark'
import Profile from 'src/icons/Profile'
import Send from 'src/icons/Send'
import logo from '../../../../assets/images/black_logo.png'

type HeaderFunction = {
  onSendClick?: () => void
  onNotificationClick?: () => void
  onProfileClick?: () => void
}

function Header({ onSendClick, onNotificationClick, onProfileClick }: HeaderFunction) {
  return (
    <section className="flex justify-between items-center bg-[#000000] px-6 md:px-12 py-6">
      <div className="w-[45px] h-[45px] ">
        <img className="w-full h-full" src={logo} alt="" />
      </div>
      <div className="space-x-10 hidden xl:flex lg:flex md:flex items-center">
        <button className="w-[200px] bg-[#E90570] text-[20px] text-white text-center py-2 rounded " type="submit">
          Create
        </button>
        <button type="button" onClick={onSendClick}>
          <Send size={35} fill="#D9D9D9" />
        </button>
        <button type="button">
          <Alarm size={26} onClick={onNotificationClick} />
        </button>
        <button type="button" onClick={onProfileClick}>
          <Profile size={40} fill="#EFEFEF" />
        </button>
      </div>
    </section>
  )
}

export default Header
