import Icon from './icons'
import { IconPropsT } from './types'

function Profile(props: IconPropsT) {
  const { title = 'Profile', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 33 32" size={size} title={title} {...otherProps}>
      <path d="M7.40485 12.8978L21.6761 6.54293L20.7316 23.6237L7.40485 12.8978Z" fill="#0B0B0B" />
      <path d="M19.3706 20.5621L20.0122 8.95984L10.3184 13.2765L19.3706 20.5621ZM23.1692 27.5479C23.1691 27.5479 22.3383 26.8792 22.1395 26.7192C22.9106 27.3398 23.1693 27.548 23.1692 27.5479ZM22.1395 26.7192C21.8029 26.4482 21.3685 26.0987 20.8153 25.6534C16.415 22.1119 4.49601 12.519 4.49601 12.519L23.3446 4.12575L22.0972 26.6851C22.0972 26.6851 22.1125 26.6975 22.1395 26.7192Z" />
      <path
        d="M24.5168 0H3.05886C1.3695 0 0 1.3695 0 3.05886V18.607C0 20.2964 1.3695 21.6659 3.05886 21.6659H24.5168C26.2061 21.6659 27.5756 20.2964 27.5756 18.607V3.05886C27.5756 1.3695 26.2061 0 24.5168 0Z"
        className="fill-white dark:fill-black"
      />
      <path
        d="M24.5138 0.764648H3.05586C1.78884 0.764648 0.761719 1.79177 0.761719 3.05879V18.607C0.761719 19.874 1.78884 20.9011 3.05586 20.9011H24.5138C25.7808 20.9011 26.8079 19.874 26.8079 18.607V3.05879C26.8079 1.79177 25.7808 0.764648 24.5138 0.764648Z"
        className="fill-white dark:fill-black"
        stroke="#E90570"
      />
    </Icon>
  )
}

export default Profile
