import SectionSlatePreview from '../../../common/SectionSlatePreview'
import SectionSlate from '../../../common/SectionSlatePreview'
import { GallerySection, GallerySectionItem } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantSix({ section }: { section: GallerySection }) {
  let check = true
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const gallery = section.variables.find(variable => variable.name === 'MEDIA')?.data as GallerySectionItem[]

  return (
    <div
      className="py-[1px] lg:py-[20px] mb-1"
      style={{
        ...generateSectionBackground({
          defaultColor: '#fff',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="container w-[350px] lg:w-[1300px] h-full mx-auto">
        <h3 className="col px-3 px-sm-2 px-md-0 text-[2em] lg:text-[5em] uppercase">
          <SectionSlate initialValue={title} previewMode />
        </h3>
        {Array.isArray(gallery) ? (
          <div className="grid grid-cols-2 lg:grid-cols-2 gap-[4px] lg:gap-[12px] mt-[3px]">
            {gallery.map((data, idx) => {
              if (check) {
                {
                  check = false
                }
                return (
                  <div
                    key={idx}
                    className=" grid row-span-5 bg-no-repeat bg-center bg-cover bg-white relative tmp-inner "
                  >
                    <img
                      className="object-cover object-center bg-black w-full max-h-[500px] h-full"
                      src={data?.image}
                      alt=""
                    />
                    <div className="hover-box flex justify-center items-center mx-auto py-[20px] px-6">
                      <span>
                        <h1 className="lg:text-[3.5em] text-center py-1 lg:py-6 mx-auto text-white">
                          <SectionSlatePreview initialValue={data?.title} previewMode />
                        </h1>
                        <p className="text-[10px] lg:text-[1.5em] font-semibold text-white text-center mx-auto">
                          <SectionSlatePreview initialValue={data?.description} previewMode />
                        </p>
                      </span>
                    </div>
                  </div>
                )
              } else {
                {
                  check = true
                }
                return (
                  <div key={idx} className="grid  bg-no-repeat bg-center bg-cover bg-black relative tmp-inner">
                    <img
                      className="object-cover object-center bg-black w-full max-h-[500px] h-full"
                      src={data?.image}
                      alt=""
                    />
                    <div className="hover-box flex justify-center items-center mx-auto py-[20px] px-6">
                      <span>
                        <h1 className="lg:text-[3.5em] text-center py-1 lg:py-6 mx-auto text-white">
                          <SectionSlatePreview initialValue={data?.title} previewMode />
                        </h1>
                        <p className=" text-[10px] lg:text-[1.5em] font-semibold text-white text-center mx-auto">
                          <SectionSlatePreview initialValue={data?.description} previewMode />
                        </p>
                      </span>
                    </div>
                  </div>
                )
              }
            })}
          </div>
        ) : null}
      </div>
    </div>
  )
}
