import { motion } from 'framer-motion'
import SectionSlate from '../../../common/SectionSlatePreview'
import { AboutUsSection as AboutUsSectionType } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

interface VariantFourProps {
  section: AboutUsSectionType
}

export default function VariantNine({ section }: VariantFourProps) {
  // const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const subTitle2 = section.variables.find(variable => variable.name === 'SUBTITLE2')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const paragraph2 = section.variables.find(variable => variable.name === 'PARAGRAPH_2')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data
  return (
    <header
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
      className="flex justify-center py-[20px] lg:py-[100px]"
    >
      <div className="md:py-12 py-4 flex gap-x-4">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          variants={{ 0: { opacity: 0 }, 1: { opacity: 1 } }}
          transition={{ duration: 0.8, ease: 'easeOut' }}
        >
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.8, ease: 'easeOut' }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ x: 100 }}
          >
            <div className="grid md:grid-cols-2 md:gap-6 items-start gap-4">
              <div className="flex flex-col xl:leading-7 uppercase tracking-wider items-start">
                <h1 className="font-semibold mb-2">
                  <SectionSlate initialValue={subTitle} previewMode />
                </h1>
                <p className="text-[12px] font-black">
                  <SectionSlate initialValue={paragraph} previewMode />
                </p>
              </div>
              <div className="flex flex-col xl:leading-7 uppercase  items-start">
                <h1 className="font-semibold mb-2">
                  <SectionSlate initialValue={subTitle2} previewMode />
                </h1>
                <p className="text-[12px] font-black">
                  <SectionSlate initialValue={paragraph2} previewMode />
                </p>
              </div>
            </div>
          </motion.div>
          <div className="md:relative mt-5 w-[300px] h-[300px] lg:w-[1100px] lg:h-[800px]">
            <img src={media} className="h-full  w-full object-cover  object-top bg-black" />
          </div>
        </motion.div>

        <motion.div className="h-full w-1 bg-black py-12 hidden md:block" />
      </div>
    </header>
  )
}
