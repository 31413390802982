import Icon from './icons'
import { IconPropsT } from './types'

function Create(props: IconPropsT) {
  const { title = 'Create', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 24 24" size={size} title={title} {...otherProps}>
      <path d="M0 2.4C0 1.07452 1.07452 0 2.4 0H21.6C22.9255 0 24 1.07452 24 2.4V21.6C24 22.9255 22.9255 24 21.6 24H2.4C1.07452 24 0 22.9255 0 21.6V2.4Z" />
      <path
        d="M15.96 11.808V13.152H12.96V16.392H11.472V13.152H8.472V11.808H11.472V8.568H12.96V11.808H15.96Z"
        fill="#D9D9D9"
      />
    </Icon>
  )
}

export default Create
