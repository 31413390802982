import { Auth, Hub } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { ThreeDots } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom'
import { GetUserProfileQuery } from 'src/API'
import { getUserProfile } from 'src/graphql/queries'
import { graphqlQuery } from 'src/lib/queries'
import { createUser } from 'src/lib/services/user.service'

export default function OAuth() {
  const navigate = useNavigate()

  const [status, setStatus] = useState('Verifying login...')

  async function getUser() {
    try {
      const userAuth = await Auth.currentAuthenticatedUser()
      const { data } = await graphqlQuery<GetUserProfileQuery>({
        query: getUserProfile,
        variables: { id: userAuth.username },
      })

      if (!data?.getUserProfile) {
        setStatus('Creating your profile...')
        const str = userAuth?.attributes?.name || userAuth?.signInUserSession?.idToken?.payload?.name || ''
        const res = str.split(' ')
        await createUser({
          id: userAuth?.username,
          firstName: res[0] ?? '',
          lastName: res[1] ?? '',
          email: userAuth?.signInUserSession?.idToken?.payload?.email,
        })
      }
      setStatus('Logging in...')
      navigate('/home')
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    localStorage.setItem('amplify-redirected-from-hosted-ui', 'false')
    localStorage.setItem('amplify-signin-with-hostedUI', 'false')

    Hub.listen('auth', ({ payload }) => {
      if (payload.event === 'signIn' || payload.event === 'cognitoHostedUI') {
        getUser()
      }
      console.log(payload)
    })

    getUser()

    const timeout = setTimeout(() => {
      Auth.federatedSignIn({ provider: 'Google' as any })
    }, 8000)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <div className="w-screen h-screen flex justify-center items-center flex-col">
      <ThreeDots height="80" width="80" radius="9" color="#E90570" ariaLabel="three-dots-loading" visible />
      <p className="dark:text-white text-sm">{status}</p>
    </div>
  )
}
