import { motion } from 'framer-motion'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { GallerySection, GallerySectionItem } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantEleven({ section }: { section: GallerySection }) {
  const gallery = section.variables.find(variable => variable.name === 'MEDIA')?.data as GallerySectionItem[]

  let indexIdentifier = 0
  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
      }}
    >
      <div
        className="container mt-5 max-w-[1300px] mx-auto h-full py-3
     space-y-2 lg:space-y-0 md:grid-col-3 lg:grid lg:gap-3 lg:grid-rows-3 grid-cols-3"
        style={{
          ...generateSectionSpacing(section?.settings?.spacing),
        }}
      >
        {gallery.map((data, idx) => {
          if (idx === indexIdentifier) {
            if (indexIdentifier % 1 == 5) {
              indexIdentifier += 1
            } else {
              indexIdentifier += 4
            }
            return (
              <>
                <div
                  key={idx}
                  className="w-full xl:col-span-1 xl:row-span-2 col-span-1 bg-cover bg-white relative tmp-inner"
                >
                  <img
                    className="object-cover object-center
                   bg-black xl:h-full lg:h-full h-[300px]  lg:w-[100%] xl:w-[100%]
                    transition duration-300 cursor-pointer hover:opacity-75 "
                    src={data.image}
                    alt=""
                  />
                  <div className="hover-box flex justify-center items-center mx-auto py-[20px] px-6">
                    <span>
                      <h1 className="lg:text-[3.5em] text-center py-1 lg:py-6 mx-auto text-white">
                        <SectionSlatePreview initialValue={data.title} previewMode />
                      </h1>
                      <p className="text-[10px] lg:text-[1.5em] font-semibold text-white text-center mx-auto">
                        <SectionSlatePreview initialValue={data.description} previewMode />
                      </p>
                    </span>
                  </div>
                </div>
              </>
            )
          } else {
            return (
              <div key={idx} className="xl:col-span-1 xl:row-span-1 bg-cover bg-white relative tmp-inner">
                <motion.img
                  className="object-cover object-center bg-black w-full max-h-[100%] 
                   lg:h-full xl:h-full h-[200px] transition duration-300 cursor-pointer  hover:opacity-75"
                  src={data.image}
                  alt=""
                />
                <div className="hover-box flex justify-center items-center mx-auto py-[20px] px-6">
                  <span>
                    <h1 className="lg:text-[3.5em] text-center py-1 lg:py-6 mx-auto text-white">
                      <SectionSlatePreview initialValue={data.title} previewMode />
                    </h1>
                    <p className=" text-[10px] lg:text-[1.5em] font-semibold text-white text-center mx-auto">
                      <SectionSlatePreview initialValue={data.description} previewMode />
                    </p>
                  </span>
                </div>
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}
