import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Loader } from 'src/components/loader'
import { NewFooter } from 'src/_polly/components/src/footer/footer'
import FooterRenderer from 'src/_polly/components/src/footer/Renderer'
import { NewMenu, NewMenuLink, NewMenuLinkType } from 'src/_polly/components/src/menu/menu'
import MenuRenderer from 'src/_polly/components/src/menu/Renderer'
import { RenderSection } from 'src/_polly/components/src/sections'
import Component from '../components/new/Component'
import { getPagesByWebsite, getWebsiteByID } from '../lib/services/website.service'
import { getComponentsByPageID } from '../services/website.service'
import { getBlockWidth } from '../util/grid'

export default function PreviewPage() {
  const params = useParams()
  const id = params.id as string
  const queryParams = new URLSearchParams(window.location.search)
  const path = queryParams.get('path') ?? '/'

  const [components, setComponents] = useState<Array<any>>([])
  const [menuLinks, setMenuLinks] = useState<NewMenuLink[]>([])
  const [newMenuConfig, setNewMenuConfig] = useState<Omit<NewMenu, 'links'> | null>(null)
  const [footer, setFooter] = useState<NewFooter | null>(null)
  const [website, setWebsite] = useState<any | null>(null)
  const [loading, setLoading] = useState(true)

  const getComponents = useCallback(async () => {
    if (!id) return
    const pages = await getPagesByWebsite(id)
    const site = await getWebsiteByID(id)
    setWebsite(site)
    setMenuLinks(JSON.parse(site?.newMenu ?? '[]'))
    setNewMenuConfig(JSON.parse(site?.newMenuConfig ?? '{}'))
    setFooter(JSON.parse(site?.newFooter ?? '{}'))
    const pathPage = pages.find(page => page?.path === path)
    if (!pathPage) return
    setComponents(await getComponentsByPageID(pathPage.id))
  }, [])

  useEffect(() => {
    getComponents().then(() => setLoading(false))
  }, [])

  return (
    <>
      <Loader show={loading} />
      {newMenuConfig && menuLinks && website && (
        <MenuRenderer
          menu={{
            ...newMenuConfig,
            links: menuLinks.map(link => {
              if (link.type !== NewMenuLinkType.INTERNAL) return link
              return {
                ...link,
                url: `/preview/${website.id}/?path=${link.url}`,
              }
            }),
          }}
          website={{
            ...website,
            config: JSON.parse(website?.config ?? '{}'),
          }}
        />
      )}
      {components.map(component => (
        <div key={component.id}>
          {component.isSection ? (
            <RenderSection section={component} />
          ) : (
            <>
              <div className="hidden md:block" style={{ width: getBlockWidth(component.columns ?? 4) }}>
                <Component data={component} />
              </div>
              <div className="block md:hidden px-4">
                <Component data={component} />
              </div>
            </>
          )}
        </div>
      ))}
      {footer && website && (
        <FooterRenderer
          footer={footer}
          website={{
            ...website,
            config: JSON.parse(website?.config ?? '{}'),
          }}
        />
      )}
    </>
  )
}
