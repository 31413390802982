import { useEffect, useState } from 'react'
import GoogleFontLoader from 'react-google-font-loader'

const FontButton = ({ label, font, onClick }: { label: string; font: string; onClick: (font: string) => void }) => (
  <button
    type="button"
    className="w-full mb-4 px-3 py-4 border border-black dark:border-white dark:text-white text-3xl"
    style={{
      fontFamily: font,
      textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    }}
    onClick={() => onClick(font)}
  >
    {label}
  </button>
)

export default function FontList({ onSelect }: { onSelect: (font: string) => void }) {
  const [result, setResult] = useState<{ items: any; family: any }>()
  const [isItems, setIsItems] = useState(20)
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    fetch('https://webfonts.googleapis.com/v1/webfonts?sort=POPULARITY&key=AIzaSyAxTv4tboo3bw5YB9TD5k0zTuNKvOIPMvg')
      .then(response => response.json())
      .then(data => setResult(data))
  }, [])

  const handleScroll = () => {
    const main = document.getElementById('font-list')?.offsetHeight as number
    if (main + document.documentElement.scrollTop !== document.documentElement.offsetHeight || isFetching) return
    setIsFetching(true)
  }

  const fetchMoreListItems = () => {
    setTimeout(() => {
      setIsItems(isItems + 10)
      setIsFetching(false)
    }, 1000)
  }

  useEffect(() => {
    if (!isFetching) return
    fetchMoreListItems()
  }, [isFetching])

  useEffect(() => {
    const main = document.getElementById('font-list') as HTMLCanvasElement
    main?.addEventListener('scroll', handleScroll)
    return () => main?.removeEventListener('scroll', handleScroll)
  }, [])

  if (!result) {
    return <p className="pl-3 text-lg dark:text-white">Loading...</p>
  }

  return (
    <div className="p-4">
      {result?.items.slice(0, isItems).map((fonts: any, index: number) => (
        <div key={index}>
          <GoogleFontLoader
            fonts={[
              {
                font: fonts.family,
                weights: fonts.variants,
              },
            ]}
            subsets={fonts.subsets}
          />
          <FontButton key={index} label={fonts.family} font={fonts.family} onClick={onSelect} />
        </div>
      ))}
      {isFetching && <p className="text-lg">Fetching...</p>}
    </div>
  )
}
