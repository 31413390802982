import { useState } from 'react'
import { IoClose, IoMenu } from 'react-icons/io5'
import FontLoader from '../FontLoader'
import { MenuVariantProps, NewMenuLinkType } from '../menu'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../utils'

export default function MenuVariantTwo({ menu, website }: MenuVariantProps) {
  return (
    <>
      <FontLoader settings={menu?.settings?.fontFamily} />
      <DesktopNavbar menu={menu} website={website} />
      <MobileNavbar menu={menu} website={website} />
    </>
  )
}

const MobileNavbar = ({ menu, website }: MenuVariantProps) => {
  const [show, setShow] = useState(false)

  return (
    <div
      className="block lg:hidden "
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '#c4c4f0' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className="flex py-4  justify-between items-center px-4">
        <a href="/">
          {website?.config?.logo ? (
            <img
              className="cursor-pointer rounded-full w-[30px] h-[30px] "
              src={website?.config?.logo}
              alt={website?.name}
            />
          ) : (
            <p
              className="font-medium"
              style={{
                ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
              }}
            >
              {website?.name}
            </p>
          )}
        </a>

        <div className="flex ">
          {show ? (
            <IoClose
              className="cursor-pointer"
              onClick={() => setShow(false)}
              size={25}
              color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color}
            />
          ) : (
            <IoMenu
              className="cursor-pointer"
              onClick={() => setShow(true)}
              size={25}
              color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#000' }).color}
            />
          )}
        </div>
      </div>

      {show && (
        <ul className="flex flex-col list-none">
          {menu.links.map(({ name, url, id, type }) => (
            <li key={id} className="border-b border-[#F2ECE9] w-full">
              <a
                href={url}
                target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                className="text-base text-[#585858]  py-4 block"
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#585858' }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                }}
              >
                {name}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

const DesktopNavbar = ({ menu, website }: MenuVariantProps) => {
  return (
    <div
      className="hidden lg:block bg-[#c4c4f0]"
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '#c4c4f0' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className="px-12 mx-auto">
        <div className="flex items-center justify-between  py-6">
          <div className="w-[25%]">
            <a href="/">
              {website?.config?.logo ? (
                <img
                  className="cursor-pointer w-[80px] h-[80px] rounded-full"
                  src={website?.config?.logo}
                  alt={website?.name}
                />
              ) : (
                <p
                  className="uppercase text-xl font-medium"
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#585858' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  {website?.name}
                </p>
              )}
            </a>
          </div>

          <div className="flex-1" />

          <ul className="flex justify-end gap-x-16 list-none">
            {menu.links.map(({ id, name, type, url }) => (
              <li key={id} className="relative">
                <a
                  href={url}
                  target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                  className="text-xl text-[#585858]"
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  {name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
