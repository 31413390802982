import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { ColorRing } from 'react-loader-spinner'
import { Link } from 'react-router-dom'
import { Post as PostType, PostType as TPostType } from 'src/API'
import usePostLike from 'src/hooks/usePostLike'
import { SectionSlate } from 'src/components/editor'
import AllUsers from 'src/icons/AllUsers'
import Chat from 'src/icons/Chat'
import Heart from 'src/icons/Heart'
import { getPageByID, getWebsiteByID } from 'src/lib/services/website.service'
import { allCommentByPostID } from 'src/services/comment.service'
import { SectionPreviewRenderer } from 'src/_polly/components/src/sections'
import UsersLightMobile from 'src/icons/UserLightMobile'
import UsersLight from '../../../../icons/UsersLight'
import Comments from './Comments'
import CommentsModal from './CommentsModal'
import ShareModal from './ShareModal'

interface PostProps {
  post: PostType
  filterGrid: string
}

const Post = ({ post, filterGrid }: PostProps) => {
  const postImages: string[] = post.images ? JSON.parse(post.images) : []
  const [hideCommentInput, setHideCommentInput] = useState<boolean>(false)
  const { liked, toggleLike, count } = usePostLike(post.id)
  const [newLimit, setNewLimit] = useState(1)
  const [loadMoreCount, setLoadMoreCount] = useState(7)
  const [showEmojiIcons, setShowEmojiIcons] = useState(false)
  const [showNoSite, setShowNoSite] = useState(false)
  const [commentsCount, setCommentsCount] = useState(0)
  const [component, setComponent] = useState<any>(null)
  const [website, setWebsite] = useState<any>()
  const [showComments, setShowComments] = useState<boolean>(false)
  const [websiteLoaded, setWebsiteLoaded] = useState<boolean>(false)
  const [componentLoaded, setComponentLoaded] = useState<boolean>(false)

  const commentInputRef = useRef<HTMLInputElement>(null)
  const [width, setWidth] = useState<number>(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleComment = () => {
    setHideCommentInput(!hideCommentInput)
    commentInputRef?.current?.focus()
    setNewLimit(1)
    setLoadMoreCount(1)
    setShowEmojiIcons(false)
    setShowComments(width >= 768 && filterGrid !== 'one')
  }

  useEffect(() => {
    if (!post) return
    allCommentByPostID(post.id).then(res => setCommentsCount(res?.items.length || 0))
    if (post.pageID) {
      getPageByID(post.pageID).then(data => {
        if (!data) {
          setComponentLoaded(true)
          return
        }
        setComponent((JSON.parse(data.blocks) as any[]).find(block => block.id === post.componentID))
        setComponentLoaded(true)
      })
    }
  }, [post])
  const parseSlateData = (data: any) => {
    try {
      const parsed = JSON.parse(data)
      if (!Array.isArray(parsed)) throw new Error('Not a valid slate data')
      return {
        slateComponent: true,
        data: parsed,
      }
    } catch {
      return {
        slateComponent: false,
        data,
      }
    }
  }
  useEffect(() => {
    getWebsiteByID(post?.websiteID ?? '').then(_website => {
      setWebsite(_website)
      setWebsiteLoaded(true)
    })
  }, [post?.websiteID])

  return (
    <div className="mb-12 pb-2">
      <div
        className={clsx(
          'fixed z-50 top-0 right-0 bottom-0 left-0 justify-center items-center',
          !showNoSite && 'hidden',
        )}
      >
        <div className="relative w-full h-full bg-white/90 dark:bg-black/90 flex justify-center items-center">
          <div className="w-[362px] bg-white dark:bg-black border-[1px] rounded-[6px] border-[#E90570] border-solid  relative">
            <p className="text-[25px] text-black py-[50px] px-[65px] dark:text-white text-center">
              The site connected to this component has been deleted.
            </p>
            <button onClick={() => setShowNoSite(false)} type="button" className="absolute top-[-4px] right-[8px]">
              <svg
                width="29"
                height="42"
                className="fill-white stroke-[#E90570] dark:fill-black"
                viewBox="0 0 29 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="0.5" y="13.5" width="28" height="28" rx="4.5" />
                <path d="M10.705 22.1254L14.425 26.9554L18.13 22.1254H19.015L14.845 27.5254L18.895 32.7754H18.01L14.425 28.0954L10.825 32.7754H9.92495L13.975 27.5254L9.80495 22.1254H10.705Z" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {post.type === TPostType.COMPONENT && (
        <div className="border-l border-t border-r rounded-t-lg overflow-hidden">
          {websiteLoaded && componentLoaded ? (
            <div>
              {website ? (
                <Link onClick={setWebsite} to={`/website/${post.websiteID}/preview`}>
                  <div className="max-w-full w-full overflow-hidden">
                    {component && <SectionPreviewRenderer section={component} noShadow disableHover />}
                  </div>
                </Link>
              ) : (
                <button type="button" onClick={() => setShowNoSite(true)} className="max-w-full w-full overflow-hidden">
                  <div className="px-10 py-20">
                    <p className="text-black dark:text-white">The website has been deleted.</p>
                  </div>
                </button>
              )}
            </div>
          ) : (
            <div className="flex justify-center py-20">
              <ColorRing
                visible
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
              />
            </div>
          )}
        </div>
      )}

      {post.type === TPostType.TTM && (
        <div className="border-l border-t border-r rounded-t-lg overflow-hidden">
          {postImages.length > 0 && <img className="w-full h-auto" src={postImages[0]} alt="" />}
        </div>
      )}

      {post.type === TPostType.POST && (
        <div className="border-l border-t border-r rounded-t-lg overflow-hidden p-6">
          <p className="text-lg dark:text-white">{post.description}</p>
          {postImages.length > 0 && (
            <div className="mt-4 grid grid-cols-3 gap-3">
              {postImages.map((image, idx) => (
                <button key={idx} type="button">
                  <img src={image} alt="" className="rounded-md object-cover object-center w-full h-full" />
                </button>
              ))}
            </div>
          )}
        </div>
      )}

      <div className="border dark:border-y-x dark:border-x-0 py-3 px-4">
        <div className="flex justify-between items-center">
          <Link to={`/profile/${post.user?.id}`} className="flex items-center space-x-3">
            <div
              className={`${filterGrid === 'one' && 'w-[20px] md:w-[30px] h-[20px] md:h-[30px]'} first-letter:
            ${filterGrid === 'two' && 'w-[20px] md:w-[20px] h-[20px] md:h-[20px]'}
            ${filterGrid === 'three' && 'w-[20px] md:w-[18px] h-[20px] md:h-[18px]'}
            `}
            >
              <img
                className="w-full h-full object-cover object-center bg-gray-200 rounded-full"
                src={post.user?.profilePicture ?? ''}
                alt={post.user?.firstName}
              />
            </div>
            {post.title && post.type === TPostType.COMPONENT && (
              <h4 className="text-[15px] dark:text-white md:text-[17px]">
                <h4>
                  {parseSlateData(post.title).slateComponent ? (
                    <SectionSlate initialValue={parseSlateData(post.title).data} previewMode readOnly />
                  ) : (
                    <p>{parseSlateData(post.title).data}</p>
                  )}
                </h4>
              </h4>
            )}
            {post.type === TPostType.TTM && (
              <h4 className="md:text-sm text-[12px] bg-brand-accent py-1 px-1.5 text-white rounded-full">
                AI Generated
              </h4>
            )}
          </Link>
          <div
            className={`flex items-center  ${filterGrid === 'one' && 'space-x-3 md:space-x-12'} ${
              filterGrid === 'two' && 'space-x-6 md:space-x-8'
            } ${filterGrid === 'three' && 'space-x-6 md:space-x-4'} `}
          >
            <div className="relative flex items-end pt-1">
              <button type="submit" onClick={toggleLike}>
                {liked ? (
                  <>
                    <Heart size={22} className="text-brand-accent dark:text-brand-accent hidden md:block" />
                    <Heart size={18} className="text-brand-accent dark:text-brand-accent block md:hidden" />
                  </>
                ) : (
                  <>
                    <Heart size={22} className="text-black dark:stroke-white dark:text-white hidden md:block" />
                    <Heart size={18} className="text-black dark:stroke-white dark:text-white md:hidden block" />
                  </>
                )}
                {count > 0 && (
                  <div
                    style={{ top: '-2px', left: '12px' }}
                    className="w-[14px] absolute h-[14px] rounded-full flex justify-center items-center bg-brand-accent text-white text-[11px]"
                  >
                    {count}
                  </div>
                )}
              </button>
            </div>
            <div className="relative flex items-end">
              <button onClick={handleComment} type="submit">
                {commentsCount > 0 ? (
                  <>
                    <Chat
                      size={22}
                      className="text-brand-accent dark:text-brand-accent dark:stroke-white hidden md:block"
                    />
                    <Chat
                      size={18}
                      className="text-brand-accent dark:text-brand-accent dark:stroke-white block md:hidden"
                    />
                  </>
                ) : (
                  <>
                    <Chat size={22} className="dark:stroke-white hidden md:block" />
                    <Chat size={18} className="dark:stroke-white block md:hidden" />
                  </>
                )}
                {commentsCount > 0 && (
                  <div
                    style={{ top: '-2px', left: '12px' }}
                    className="w-[14px] absolute h-[14px] rounded-full flex justify-center items-center bg-brand-accent text-white text-[11px]"
                  >
                    {commentsCount}
                  </div>
                )}
              </button>
            </div>

            <button type="submit">
              <div className="hidden dark:block">
                <Link to={`/messages/${post.user?.id}`}>
                  <AllUsers size={27} className="dark:text-[white] hidden md:block" strokeWidth={0.5} stroke="white" />
                  <AllUsers size={20} className="dark:text-[white] block md:hidden" />
                </Link>
              </div>
              <div className="dark:hidden w-full m-auto flex items-center justify-center">
                <Link to={`/messages/${post.user?.id}`}>
                  <UsersLight size={23} className="text-black hidden md:block" />
                  <UsersLightMobile size={21} className="md:hidden" />
                </Link>
              </div>
            </button>
            <div>
              <div className="hidden md:block">
                <ShareModal postId={post?.id} size={22} />
              </div>
              <div className="md:hidden block mt-2">
                <ShareModal postId={post?.id} size={18} />
              </div>
            </div>
          </div>
        </div>
        {post.description && post.type === TPostType.COMPONENT && (
          <div
            className={`dark:text-white pt-3 ${filterGrid === 'one' && 'md:text-[20px] '} 
          ${filterGrid === 'two' && 'md:text-[15px] text-[10px]'}
          ${filterGrid === 'three' && ' md:text-[12px]'}`}
          >
            {parseSlateData(post.description).slateComponent ? (
              <SectionSlate initialValue={parseSlateData(post.description).data} previewMode readOnly />
            ) : (
              <p>{post.description}</p>
            )}
          </div>
        )}
        {post.prompt && (
          <div
            className={`dark:text-white pt-3 ${filterGrid === 'one' && 'md:text-[20px] '} 
          ${filterGrid === 'two' && 'md:text-[15px] text-[10px]'}
          ${filterGrid === 'three' && ' md:text-[12px]'}`}
          >
            <p className="text-sm">Prompt: {post.prompt}</p>
          </div>
        )}
      </div>
      <div>
        {(filterGrid === 'one' || width < 768) && (
          <Comments
            ref={commentInputRef}
            {...{
              hideCommentInput,
              post,
              newLimit,
              setNewLimit,
              loadMoreCount,
              showEmojiIcons,
              setShowEmojiIcons,
            }}
          />
        )}
      </div>
      {showComments && <CommentsModal post={post} showComments={showComments} setShowComments={setShowComments} />}
    </div>
  )
}

export default Post
