import { getSectionVariantsWithPreview } from 'src/util/sections/get-variants'
import {
  AboutUsSection,
  AttentionGrabberSection,
  BlogSection,
  ContactUsSection,
  FAQSection,
  GallerySection,
  ImageOmgSection,
  MENUSection,
  PortfolioSection,
  QuoteSection,
  TABLESection,
  TeamMembersSection,
  TESTIMONIALSection,
  TextSection,
} from 'src/_polly/components/src/sections'

export const love = getSectionVariantsWithPreview('ATTENTION_GRABBER') as AttentionGrabberSection[]
export const image = getSectionVariantsWithPreview('IMAGE_OMG') as ImageOmgSection[]
export const gallery = getSectionVariantsWithPreview('GALLERY') as GallerySection[]
export const text = getSectionVariantsWithPreview('TEXT') as TextSection[]
export const blog = getSectionVariantsWithPreview('BLOG') as BlogSection[]
export const video = getSectionVariantsWithPreview('TESTIMONIAL') as TESTIMONIALSection[]
export const portfolio = getSectionVariantsWithPreview('PORTFOLIO') as PortfolioSection[]
export const quote = getSectionVariantsWithPreview('QUOTE_SECTION') as QuoteSection[]
export const aboutUs = getSectionVariantsWithPreview('ABOUT_US') as AboutUsSection[]
export const contactUs = getSectionVariantsWithPreview('CONTACT_US') as ContactUsSection[]
export const menu = getSectionVariantsWithPreview('MENU') as MENUSection[]
export const table = getSectionVariantsWithPreview('TABLE') as TABLESection[]
export const team = getSectionVariantsWithPreview('TEAM_MEMBERS') as TeamMembersSection[]
export const faq = getSectionVariantsWithPreview('FAQ') as FAQSection[]
