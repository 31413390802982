import { API, Auth } from 'aws-amplify'
import { CreateImageInput, CreateImageMutation, ImagesByUserIDQuery, ImageStatus, ModelSortDirection } from 'src/API'
import { createImage } from 'src/graphql/mutations'
import { imagesByUserID } from 'src/graphql/queries'
import { graphqlQuery } from 'src/lib/queries'

export const insertIntoImageLibrary = async (input: Omit<CreateImageInput, 'userID'>) => {
  try {
    const { attributes } = await Auth.currentAuthenticatedUser()
    const { data } = await graphqlQuery<CreateImageMutation>({
      query: createImage,
      variables: {
        input: {
          ...input,
          userID: attributes.sub,
          status: ImageStatus.ACTIVE,
        },
      },
    })

    return data?.createImage
  } catch (err) {
    console.error('@images.service::insertIntoImageLibrary::error', err)
    throw err
  }
}

export const getLibraryImages = async (nextToken?: string | null) => {
  try {
    const { attributes } = await Auth.currentAuthenticatedUser()
    const { data } = await graphqlQuery<ImagesByUserIDQuery>({
      query: imagesByUserID,
      variables: {
        sortDirection: ModelSortDirection.DESC,
        limit: 15,
        nextToken,
        status: ImageStatus.ACTIVE,
        userID: attributes.sub,
      },
    })

    return {
      nextToken: data?.imagesByUserID?.nextToken,
      items: data?.imagesByUserID?.items,
    }
  } catch (error) {
    console.error('@images.service::getLibraryImages::error', error)
    throw error
  }
}

export const generateTextToImage = async ({
  aspectRatio,
  guidanceScale,
  prompt,
}: {
  prompt: string
  aspectRatio: string
  guidanceScale: number
}) => {
  const result = await API.post('pollyTextToImage', '/text-to-image', {
    body: {
      prompt,
      aspectRatio,
      guidanceScale,
    },
  })
  return (result?.image as string) ?? null
}

export const boomThePrompt = async (prompt: string) => {
  const response = await API.post('pollyPromptBoom', '/boom-prompt', {
    body: {
      prompt,
    },
  })
  return (response?.result?.[0]?.[0]?.generated_text as string) ?? null
}
