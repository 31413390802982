import { Descendant } from 'slate'
import { AboutUsVariables, AboutUsVariants, Section, SectionTags } from 'src/_polly/components/src/sections'
import { v4 as uuid } from 'uuid'
import { AboutUsSectionServices } from './../../_polly/components/src/sections/types'
import { DEFAULT_VARIANT_SETTINGS, getSectionVariants } from './get-variants'
const tags: Record<AboutUsVariants, SectionTags[]> = {
  VARIANT_1: [],
  VARIANT_2: ['IMAGE'],
  VARIANT_3: ['IMAGE'],
  VARIANT_4: [],
  VARIANT_5: [],
  VARIANT_6: ['IMAGE'],
  VARIANT_7: ['IMAGE', 'ANIMATION'],
  VARIANT_8: ['IMAGE', 'ANIMATION'],
  VARIANT_9: ['IMAGE', 'ANIMATION'],
  VARIANT_10: ['IMAGE', 'ANIMATION'],
  VARIANT_11: [],
  VARIANT_12: [],
  VARIANT_13: [],
  VARIANT_14: [],
  VARIANT_15: [],
}
export function getAboutUsVariants() {
  const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Title' }] }]
  const subTitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Sub Title' }] }]
  const paragraph: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Paragraph' }] }]
  const media =
    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'

  const variants = getSectionVariants('ABOUT_US')
  const sections: Section<AboutUsVariants, AboutUsVariables>[] = []

  variants.forEach(variant => {
    if (variant.id === 'VARIANT_2') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'GOAT Acquisition UX',
            },
          ],
        },
      ]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Our streamlined design process ensures that your site is not only visually appealing but also easy to navigate, with modern layouts that include an interactive user interface and engaging typography. Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
            },
          ],
        },
      ]
      const data: Section<AboutUsVariants, AboutUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'ABOUT_US',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-000cb3a0-3df3-467e-96ee-a554a032bb42',
            name: 'MEDIA',
          },
        ],
        variant: variant.id as AboutUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_3') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Thrilling Design for Investors',
            },
          ],
        },
      ]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Our streamlined design process ensures that your site is not only visually appealing but also easy to navigate, with modern layouts that include an interactive user interface and engaging typography. Our captivating aesthetic design includes minimalist design, visually stunning custom graphics, and attention-grabbing visual hierarchy that keeps users engaged and coming back for more.',
            },
          ],
        },
      ]
      const data: Section<AboutUsVariants, AboutUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'ABOUT_US',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-d5a35388-e16e-483c-84a2-edf8de371f8d',
            name: 'MEDIA',
          },
        ],
        variant: variant.id as AboutUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_4') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Cottage Industry, Captivating Design',
            },
          ],
        },
      ]
      const subtitle: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Startup Appeal',
            },
          ],
        },
      ]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'At Dead Startup Appeal, we specialize in reviving companies that have hit a roadblock. Our team of experts assesses the issues and implements tailored solutions to bring Startup Appeal: At Dead Startup Appeal, we specialize in reviving companies that have hit a roadblock. Our team of experts assesses the issues and implements tailored solutions to bring businesses back to life. With our unique approach, we turn dead startups into thriving enterprises with renewed appeal .Startup Appeal: At Dead Startup Appeal, we specialize in reviving companies that have hit a roadblock. Our team of experts assesses the issues and implements tailored solutions to bring businesses back to life. With our unique approach, we turn dead startups into thriving enterprises with renewed appeal back to life. With our unique approach, we turn startups into thriving enterprises with renewed appeal.',
            },
          ],
        },
      ]
      const data: Section<AboutUsVariants, AboutUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'ABOUT_US',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          { id: uuid(), category: 'TEXT', data: subtitle, name: 'SUBTITLE' },
        ],
        variant: variant.id as AboutUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_6') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Calligraphy',
            },
          ],
        },
      ]
      const subTitle: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'UX Design',
            },
          ],
        },
      ]
      const sectionTitle: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'recent partners',
            },
          ],
        },
      ]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Fire Design: Fire Design is a full-service investment firm with a passion for innovative design. We invest in companies that prioritize exceptional user experience and visual appeal. Our team of experienced designers and investors work hand-in-hand to support the growth and success of our portfolio companies.Fire Design: Fire Design is a full-service investment firm with a passion for innovative design. We invest in companies that prioritize exceptional user experience and visual appeal. Our team of experienced designers and investors work hand-in-hand to support the growth and success of our portfolio companies.',
            },
          ],
        },
      ]
      const Item1: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Item 1',
            },
          ],
        },
      ]
      const Item2: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Item 2',
            },
          ],
        },
      ]
      const Item3: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Item 3',
            },
          ],
        },
      ]
      const data: Section<AboutUsVariants, AboutUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'ABOUT_US',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subTitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://images.unsplash.com/photo-1568602471122-7832951cc4c5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxtYW58ZW58MHx8fHwxNjgxNjI1MTg3&ixlib=rb-4.0.3&q=80&w=1080',
            name: 'MEDIA',
          },
          { id: uuid(), category: 'TEXT', data: sectionTitle, name: 'SECTION_1' },
          {
            id: uuid(),
            name: 'SERVICE',
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: Item1,
              },
              {
                id: uuid(),
                name: Item2,
              },
              {
                id: uuid(),
                name: Item3,
              },
            ] as AboutUsSectionServices[],
          },
        ],
        variant: variant.id as AboutUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_8') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Our Bio',
            },
          ],
        },
      ]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'At our agency, we prioritize user satisfaction, user retention, and user conversion. We offer website performance, website speed, website accessibility, website security, website maintenance, website optimization, website analytics, website testing, website updates, website improvements, and website enhancements to ensure your site is top-notch. With our high-octane mobile-friendly design and invigorating user-friendly interface, we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
            },
          ],
        },
      ]
      const data: Section<AboutUsVariants, AboutUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'ABOUT_US',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://images.unsplash.com/photo-1607004468138-e7e23ea26947?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw3fHxhYm91dHxlbnwwfHx8fDE2Nzc3ODkxODY&ixlib=rb-4.0.3&q=80&w=1080',
            name: 'MEDIA',
          },
        ],
        variant: variant.id as AboutUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_9') {
      const subTitle1: Descendant[] = [
        { type: 'h3', children: [{ text: 'Citation', className: 'text-[15px] lg:text-[30px]' }] },
      ]
      const subTitle2: Descendant[] = [
        { type: 'h3', children: [{ text: 'Publication', className: 'text-[15px] lg:text-[30px]' }] },
      ]
      const paragraph1: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'DILLER SCOFIDIO + RENFRO OMA "\n" pellt clarkee architects "\n" obermoser architects' }],
        },
      ]
      const paragraph2: Descendant[] = [
        {
          type: 'paragraph',
          children: [{ text: 'DILLER SCOFIDIO + RENFRO OMA "\n" pellt clarkee architects "\n" obermoser architects' }],
        },
      ]
      const data: Section<AboutUsVariants, AboutUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'ABOUT_US',
        updatedAt: '',
        tags: tags[variant.id as AboutUsVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: subTitle1, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: subTitle2, name: 'SUBTITLE2' },
          { id: uuid(), category: 'TEXT', data: paragraph1, name: 'PARAGRAPH' },
          { id: uuid(), category: 'TEXT', data: paragraph2, name: 'PARAGRAPH_2' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://image.lexica.art/full_jpg/e51fd8e7-feb5-4821-b6b3-97c1e4601345',
            name: 'MEDIA',
          },
        ],
        variant: variant.id as AboutUsVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_10') {
      const subTitle: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: "Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crow's nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.",
            },
          ],
        },
      ]

      const Item1: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Why korr',
            },
          ],
        },
      ]
      const Item2: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Product',
            },
          ],
        },
      ]
      const Description1: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Deadlights jack lad schooner scallywag dance the hempen jig carouser broadside cable strike colors. Bring a spring upon her cable holystone blow the man down spanker Shiver me timbers to go on account lookout wherry doubloon chase. Belay yo-ho-ho keelhaul squiffy black spot yardarm spyglass sheet transom heave to.',
            },
          ],
        },
      ]

      const data: Section<AboutUsVariants, AboutUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'ABOUT_US',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: subTitle, name: 'SUBTITLE' },
          {
            id: uuid(),
            name: 'SERVICE',
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: Item1,
                description: Description1,
              },
              {
                id: uuid(),
                name: Item2,
                description: Description1,
              },
            ] as AboutUsSectionServices[],
          },
        ],
        variant: variant.id as AboutUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_11') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'About me' }] }]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Gastropub gentrify ugh hashtag literally schlitz ramps viral bruh hammock typewriter pork belly. Cornhole 8-bit health goth literally. Four dollar toast YOLO portland paleo, kale chips cray swag before they sold out mixtape pitchfork actually cold-pressed DSA.',
            },
          ],
        },
      ]
      const data: Section<AboutUsVariants, AboutUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'ABOUT_US',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
        ],
        variant: variant.id as AboutUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_12') {
      const subTitle: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Ipsam Nihil Yet Error Commodi Quam Fugiat And Molestiae. Aliquip Nihil For Aliquam Eiusmod Ea So Aut, But Culpa. Eu Ullam Or Ab And Vel. Incididunt Consequat Yet Sed Aperiam , Accusantium Occaecat But Culpa.',
            },
          ],
        },
      ]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Anim doloremque but iste. Consequatur quo vel but iste commodi. Amet. Error magnam si. Eos. Occaecat qui accusantium yet dolores. Ut veritatis. Occaecat consequuntur voluptatem natus. Aliquip nihil for aliquam eiusmod ea so aut, but culpa . Occaecat qui accusantium yet dolores.',
            },
          ],
        },
      ]
      const sectionOne: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Services',
            },
          ],
        },
      ]
      const sectionTwo: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Clients',
            },
          ],
        },
      ]
      const Item1: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Why korr',
            },
          ],
        },
      ]
      const Item2: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Product',
            },
          ],
        },
      ]
      const Item31: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Art',
            },
          ],
        },
      ]

      const data: Section<AboutUsVariants, AboutUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'ABOUT_US',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: subTitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          { id: uuid(), category: 'TEXT', data: sectionOne, name: 'SECTION_1' },
          { id: uuid(), category: 'TEXT', data: sectionTwo, name: 'SECTION_2' },
          {
            id: uuid(),
            name: 'SERVICE',
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: Item1,
              },
              {
                id: uuid(),
                name: Item2,
              },
              {
                id: uuid(),
                name: Item31,
              },
            ] as AboutUsSectionServices[],
          },
          {
            id: uuid(),
            name: 'CLIENT',
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: Item1,
              },
              {
                id: uuid(),
                name: Item2,
              },
              {
                id: uuid(),
                name: Item31,
              },
              {
                id: uuid(),
                name: Item1,
              },
              {
                id: uuid(),
                name: Item2,
              },
            ] as AboutUsSectionServices[],
          },
        ],
        variant: variant.id as AboutUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_13') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Bianca Witherspoon',
            },
          ],
        },
      ]
      const paragraph: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'We are a team of experienced entrepreneurs and investors dedicated to acquiring and growing innovative businesses. Our approach is rooted in the latest industry trends and our commitment to long-term success. With our expertise and network, we aim to bring Cheugy-approved businesses to the forefront of the market.',
            },
          ],
        },
      ]
      const data: Section<AboutUsVariants, AboutUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'ABOUT_US',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: title, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          {
            id: uuid(),
            category: 'TEXT',
            data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-286307fc-ddf8-428e-bc42-40cd8e749507',
            name: 'MEDIA',
          },
        ],
        variant: variant.id as AboutUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_14') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Looking for an electrifyingly',
            },
          ],
        },
      ]

      const name1: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'For B2B startupss',
            },
          ],
        },
      ]

      const title1: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Looking for an electrifyingly',
            },
          ],
        },
      ]

      const Description: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Looking for an electrifyingly',
            },
          ],
        },
      ]

      const data: Section<AboutUsVariants, AboutUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'ABOUT_US',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          {
            id: uuid(),
            name: 'SERVICE',
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: name1,
                image: 'https://image.lexica.art/full_jpg/952b5228-5fde-48b2-81f7-106331441011',
                title: title1,
                description: Description,
              },
              {
                id: uuid(),
                name: name1,
                image: 'https://image.lexica.art/full_jpg/c72f2c06-1d28-4eba-a8b5-4d5aa2d722b4',
                title: title1,
                description: Description,
              },
              {
                id: uuid(),
                name: name1,
                image: 'https://image.lexica.art/full_jpg/42653426-19bc-4636-9950-4c3f5ca4a2e8',
                title: title1,
                description: Description,
              },
            ] as AboutUsSectionServices[],
          },
        ],
        variant: variant.id as AboutUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_15') {
      const title: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'About us',
            },
          ],
        },
      ]

      const name1: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'For B2B startupss',
            },
          ],
        },
      ]

      const title1: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Looking for an electrifyingly',
            },
          ],
        },
      ]

      const Description: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Looking for an electrifyingly',
            },
          ],
        },
      ]

      const data: Section<AboutUsVariants, AboutUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'ABOUT_US',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          {
            id: uuid(),
            category: 'IMAGE',
            data: 'https://image.lexica.art/full_jpg/95205f28-0b34-4d32-8b3a-6bcd079077db',
            name: 'MEDIA',
          },
          {
            id: uuid(),
            name: 'SERVICE',
            category: 'TEXT',
            data: [
              {
                id: uuid(),
                name: name1,
                image: 'https://image.lexica.art/full_jpg/4272dbee-cbfc-429f-824b-54318bb89ea0',
                title: title1,
                description: Description,
              },
              {
                id: uuid(),
                name: name1,
                image: 'https://image.lexica.art/full_jpg/7e4da853-5e97-4bec-aee3-4bf64e6b8855',
                title: title1,
                description: Description,
              },
              {
                id: uuid(),
                name: name1,
                image: 'https://image.lexica.art/full_jpg/71de64bc-1190-4874-abe9-f0c991448625',
                title: title1,
                description: Description,
              },
            ] as AboutUsSectionServices[],
          },
        ],
        variant: variant.id as AboutUsVariants,
        styleName: variant.name,
        designedBy: 'Hayatul Islam',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else {
      const data: Section<AboutUsVariants, AboutUsVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'ABOUT_US',
        updatedAt: '',
        tags: tags[variant.id as AboutUsVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: subTitle, name: 'SUBTITLE' },
          { id: uuid(), category: 'TEXT', data: paragraph, name: 'PARAGRAPH' },
          { id: uuid(), category: 'IMAGE', data: media, name: 'MEDIA' },
        ],
        variant: variant.id as AboutUsVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    }
  })

  return sections
}
