import Map from './Map'
import { ContactUsMap, ContactUsSection, ContractSectionContract } from '../../types'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantTwo({ section }: { section: ContactUsSection }) {
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const map = section.variables.find(variable => variable.name === 'MAP')?.data as ContactUsMap
  const contact = section.variables.find(variable => variable.name === 'CONTRACT')?.data as ContractSectionContract[]

  return (
    <div
      className="flex flex-col py-6 md:py-8"
      style={{
        ...generateSectionBackground({
          defaultColor: '#000',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="text-white">
        <div className="space-y-2 md:space-y-4 flex flex-col items-center justify-between ">
          <div>
            {subTitle && (
              <div>
                <p className="text-3xl md:text-5xl text-center">
                  <SectionSlatePreview initialValue={subTitle} previewMode />
                </p>
              </div>
            )}
          </div>
          <div className="md:p-12 items-center grid md:grid-cols-2 grid-cols-1 md:px-40 ">
            <p className="md:text-[25px] md:font-medium md:w-[80%] px-4 md:px-0 text-center md:text-left">
              <SectionSlatePreview initialValue={paragraph} previewMode />
            </p>

            <div className="flex gap-5 items-center justify-center  md:flex-col mt-4 md:mt-0">
              {contact.map(({ id, name, description }) => {
                return (
                  <div key={id} className="flex flex-col items-center justify-center md:gap-y-2">
                    {/* <p className="text-[18px] font-bold mb-2 ">{name} </p>
                    <p className="text-[18px] font-light md:w-6/12">{description}</p> */}
                    <p className="text-[18px] font-bold mb-2 ">
                      <SectionSlatePreview initialValue={name} previewMode />
                    </p>
                    <p className="text-[17px] font-light">
                      <SectionSlatePreview initialValue={description} previewMode />
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="flex-1 relative mt-6 md:mt-2">
        <div className="w-full h-[350px] top-0 left-0">
          <Map address={map.address} position={map.position} />
        </div>
      </div>
    </div>
  )
}
