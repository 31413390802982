import Icon from './icons'
import { IconPropsT } from './types'

function ChatBox(props: IconPropsT) {
  const { title = 'ChatBox', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 120 120" size={size} title={title} {...otherProps}>
      {/* <path
        d="M111 0H9C4.02944 0 0 4.02944 0 9V111C0 115.971 4.02944 120 9 120H111C115.971 120 120 115.971 120 111V9C120 4.02944 115.971 0 111 0Z"
        fill="#D9D9D9"
        strokeWidth="1"
        stroke="black"
      />

      <path d="M40.9765 57.0261L77.5201 40.7534L75.1015 84.4913L40.9765 57.0261Z" fill="#0B0B0B" />
      <path
        d="M71.6107 76.6518L73.2535 46.9425L48.431 57.9958L71.6107 76.6518ZM81.3375 94.54C81.3373 94.5399 79.2098 92.8276 78.7008 92.4179C80.6754 94.0071 81.3376 94.5401 81.3375 94.54ZM78.7008 92.4179C77.8387 91.7241 76.7266 90.829 75.3099 89.6888C64.0423 80.6201 33.522 56.0561 33.522 56.0561L81.7867 34.5641L78.5924 92.3306C78.5924 92.3306 78.6317 92.3623 78.7008 92.4179Z"
        fill="#0B0B0B"
      />
      <path
        d="M84.7787 24H29.8327C25.5068 24 22 27.5068 22 31.8327V71.646C22 75.9719 25.5068 79.4787 29.8327 79.4787H84.7787C89.1046 79.4787 92.6114 75.9719 92.6114 71.646V31.8327C92.6114 27.5068 89.1046 24 84.7787 24Z"
        fill="black"
      />
      <path
        d="M84.7787 25.9578H29.8327C26.5883 25.9578 23.9582 28.5879 23.9582 31.8323V71.6456C23.9582 74.89 26.5883 77.5201 29.8327 77.5201H84.7787C88.0231 77.5201 90.6532 74.89 90.6532 71.6456V31.8323C90.6532 28.5879 88.0231 25.9578 84.7787 25.9578Z"
        fill="black"
        stroke="#040404"
      />
      <path d="M40.7654 55.7205L76.9019 40.0911L74.5103 82.1001L40.7654 55.7205Z" fill="white" />
      <path
        d="M71.0579 74.5706L72.6824 46.0356L48.1364 56.652L71.0579 74.5706ZM80.6764 91.7517C80.6762 91.7516 78.5724 90.107 78.0691 89.7135C80.0216 91.2399 80.6765 91.7518 80.6764 91.7517ZM78.0691 89.7135C77.2166 89.0471 76.1168 88.1874 74.7159 87.0922C63.5738 78.382 33.3935 54.789 33.3935 54.789L81.1206 34.1465L77.9618 89.6297C77.9618 89.6297 78.0007 89.6601 78.0691 89.7135Z"
        fill="white"
      />
      <path
        d="M84.3439 26.385H31.8657C27.7342 26.385 24.3849 29.6024 24.3849 33.5713V70.0995C24.3849 74.0684 27.7342 77.2858 31.8657 77.2858H84.3439C88.4755 77.2858 91.8248 74.0684 91.8248 70.0995V33.5713C91.8248 29.6024 88.4755 26.385 84.3439 26.385Z"
        fill="white"
      />
      <path
        d="M84.3437 28.1816H31.8655C28.7669 28.1816 26.2549 30.5947 26.2549 33.5714V70.0995C26.2549 73.0762 28.7669 75.4893 31.8655 75.4893H84.3437C87.4424 75.4893 89.9543 73.0762 89.9543 70.0995V33.5714C89.9543 30.5947 87.4424 28.1816 84.3437 28.1816Z"
        fill="white"
        stroke="white"
      /> */}

      <path
        d="M108.5 2.5H11.5C6.52944 2.5 2.5 6.52944 2.5 11.5V108.5C2.5 113.471 6.52944 117.5 11.5 117.5H108.5C113.471 117.5 117.5 113.471 117.5 108.5V11.5C117.5 6.52944 113.471 2.5 108.5 2.5Z"
        fill="#D9D9D9"
        stroke="black"
        strokeWidth="1"
      />
      <path d="M40.9765 57.0261L77.5201 40.7534L75.1015 84.4913L40.9765 57.0261Z" fill="#0B0B0B" />
      <path
        d="M71.6107 76.6518L73.2535 46.9425L48.431 57.9958L71.6107 76.6518ZM81.3375 94.54C81.3373 94.5399 79.2098 92.8276 78.7008 92.4179C80.6754 94.0071 81.3376 94.5401 81.3375 94.54ZM78.7008 92.4179C77.8387 91.7241 76.7266 90.829 75.3099 89.6888C64.0423 80.6201 33.522 56.0561 33.522 56.0561L81.7867 34.5641L78.5924 92.3306C78.5924 92.3306 78.6317 92.3623 78.7008 92.4179Z"
        fill="#0B0B0B"
      />
      <path
        d="M84.7787 24H29.8327C25.5068 24 22 27.5068 22 31.8327V71.646C22 75.9719 25.5068 79.4787 29.8327 79.4787H84.7787C89.1046 79.4787 92.6114 75.9719 92.6114 71.646V31.8327C92.6114 27.5068 89.1046 24 84.7787 24Z"
        fill="black"
      />
      <path
        d="M84.7787 25.9578H29.8327C26.5883 25.9578 23.9582 28.5879 23.9582 31.8323V71.6456C23.9582 74.89 26.5883 77.5201 29.8327 77.5201H84.7787C88.0231 77.5201 90.6532 74.89 90.6532 71.6456V31.8323C90.6532 28.5879 88.0231 25.9578 84.7787 25.9578Z"
        fill="black"
        stroke="#040404"
      />
      <path d="M40.7654 55.7205L76.9019 40.0911L74.5103 82.1001L40.7654 55.7205Z" fill="white" />
      <path
        d="M71.0579 74.5706L72.6824 46.0356L48.1364 56.652L71.0579 74.5706ZM80.6764 91.7517C80.6762 91.7516 78.5724 90.107 78.0691 89.7135C80.0216 91.2399 80.6765 91.7518 80.6764 91.7517ZM78.0691 89.7135C77.2166 89.0471 76.1168 88.1874 74.7159 87.0922C63.5738 78.382 33.3935 54.789 33.3935 54.789L81.1206 34.1465L77.9618 89.6297C77.9618 89.6297 78.0007 89.6601 78.0691 89.7135Z"
        fill="white"
      />
      <path
        d="M84.3439 26.385H31.8657C27.7342 26.385 24.3849 29.6024 24.3849 33.5713V70.0995C24.3849 74.0684 27.7342 77.2858 31.8657 77.2858H84.3439C88.4755 77.2858 91.8248 74.0684 91.8248 70.0995V33.5713C91.8248 29.6024 88.4755 26.385 84.3439 26.385Z"
        fill="white"
      />
      <path
        d="M84.3437 28.1816H31.8655C28.7669 28.1816 26.2549 30.5947 26.2549 33.5714V70.0995C26.2549 73.0762 28.7669 75.4893 31.8655 75.4893H84.3437C87.4424 75.4893 89.9543 73.0762 89.9543 70.0995V33.5714C89.9543 30.5947 87.4424 28.1816 84.3437 28.1816Z"
        fill="white"
        stroke="white"
      />
    </Icon>
  )
}

export default ChatBox
