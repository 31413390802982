import FontLoader from '../../menu/FontLoader'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../../menu/utils'
import { FooterVariantProps, getSocialIcon, NewFooter, Social } from '../footer'

const SocialItem = ({ type, link, footer }: { type: Social; link: string; footer: NewFooter }) => {
  const Icon = getSocialIcon(type)

  return (
    <a
      href={link}
      target="_blank"
      className="flex items-center justify-center w-[40px] h-[40px] border-white border rounded-full"
    >
      <Icon size={20} color={getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }).color} />
    </a>
  )
}

export default function VariantTen({ footer, website }: FooterVariantProps) {
  // console.log(website, footer)
  return (
    <div
      style={{
        ...getBackgroundColor({ settings: footer?.settings?.backgroundColor, defaultValue: 'black' }),
        ...getSpacing(footer?.settings?.spacing),
      }}
    >
      <FontLoader settings={footer?.settings?.fontFamily} />

      <div className="text-white px-6 md:px-12 py-8 md:py-12">
        <div className="container mx-auto">
          <div className="flex justify-around flex-wrap pb-6 md:pb-12 gap-y-3">
            <div>
              <a href="/">
                {website?.config?.logo ? (
                  <img
                    style={{ height: '30px', width: 'auto' }}
                    className="cursor-pointer"
                    src={website?.config?.logo}
                    alt={website?.name}
                  />
                ) : (
                  <p
                    className="uppercase font-medium"
                    style={{
                      ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
                      ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
                    }}
                  >
                    {website?.name}
                  </p>
                )}
              </a>
            </div>
            <div
              className="w-full md:w-auto"
              style={{
                ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
                ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
              }}
            >
              <p className="text-sm font-bold mb-3 uppercase">Follow Us</p>
              <div className="flex gap-x-3">
                {website?.config?.social &&
                  Object.keys(website.config.social).map(item => (
                    <SocialItem type={item as Social} link={website.config.social[item]} footer={footer} />
                  ))}
              </div>
            </div>
            <div
              className="w-full md:w-auto"
              style={{
                ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
                ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
              }}
            >
              <h4 className="text-sm font-bold mb-3 uppercase">Call Us</h4>
              <p className="text-[20px] text-white">
                {website?.config?.phone ? website?.config?.phone : footer?.phone}
              </p>
            </div>
          </div>
          <div
            className="border-t pt-2 flex justify-center items-center"
            style={{
              ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
              ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
            }}
          >
            <p>{website?.config?.copyright ? website?.config?.copyright : footer?.copyright}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
