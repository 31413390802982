import Icon from './icons'
import { IconPropsT } from './types'

function AuthLogo(props: IconPropsT) {
  const { title = 'Logo', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 22 22" size={size} title={title} {...otherProps}>
      <ellipse cx="11.3548" cy="11.1775" rx="10.2903" ry="10.4677" fill="white" />
      <circle cx="11" cy="11" r="10.5" stroke="gray" />
      <circle cx="11" cy="11" r="10.5" stroke="gray" />
      <circle cx="11" cy="11" r="10.5" stroke="gray" />
      <circle cx="11" cy="11" r="10.5" stroke="gray" />
      <circle cx="11" cy="11" r="10.5" stroke="gray" />
      <circle cx="10.9999" cy="11.0001" r="8.37097" stroke="gray" />
      <circle cx="10.9999" cy="11.0001" r="8.37097" stroke="gray" />
      <circle cx="10.9999" cy="11.0001" r="8.37097" stroke="gray" />
      <circle cx="10.9999" cy="11.0001" r="8.37097" stroke="gray" />
      <circle cx="10.9999" cy="11.0001" r="8.37097" stroke="gray" />
      <circle cx="11.0002" cy="11" r="6.24194" stroke="gray" />
      <circle cx="11.0002" cy="11" r="6.24194" stroke="gray" />
      <circle cx="11.0002" cy="11" r="6.24194" stroke="gray" />
      <circle cx="11.0002" cy="11" r="6.24194" stroke="gray" />
      <circle cx="11.0002" cy="11" r="6.24194" stroke="gray" />
      <circle cx="11.0001" cy="11.0001" r="4.1129" fill="white" />
      <circle cx="11.0001" cy="11.0001" r="4.1129" stroke="gray" />
      <circle cx="11.0001" cy="11.0001" r="4.1129" stroke="gray" />
      <circle cx="11.0001" cy="11.0001" r="4.1129" stroke="gray" />
      <circle cx="11.0001" cy="11.0001" r="4.1129" stroke="gray" />
      <circle cx="11.0001" cy="11.0001" r="4.1129" stroke="gray" />{' '}
    </Icon>
  )
}

export default AuthLogo
