import { motion } from 'framer-motion'
import { useState } from 'react'
import FontLoader from '../FontLoader'
import { MenuVariantProps, NewMenuLinkType } from '../menu'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../utils'
export default function MenuVariantNine({ menu, website }: MenuVariantProps) {
  return (
    <div>
      <FontLoader settings={menu?.settings?.fontFamily} />
      <DesktopNavbar menu={menu} website={website} />
      <MobileNavbar menu={menu} website={website} />
    </div>
  )
}
const underLine = {
  default: {
    x: -400,
    transition: {
      duration: 0.5,
    },
  },

  hover: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
}
const DesktopNavbar = ({ menu, website }: MenuVariantProps) => {
  return (
    <div
      className="hidden lg:block"
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'black' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className="px-12  flex items-center py-6 justify-between">
        <div>
          <a href="/">
            {website?.config.logo ? (
              <img
                src={website?.config?.logo}
                alt={website?.name}
                className="cursor-pointer w-[80px] h-[80px] rounded-full"
              />
            ) : (
              <p
                className="font-bold text-gray-50 hover:text-gray-200 hover:scale-110 transition-all duration-300"
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                }}
              >
                {website?.name}
              </p>
            )}
          </a>
        </div>

        <div>
          <ul className="flex list-none text-gray-50 gap-x-16 text-xl">
            {menu.links.map(({ id, name, url, type }) => {
              return (
                <motion.li
                  key={id}
                  initial="default"
                  whileHover="hover"
                  animate="default"
                  className="cursor-pointer relative m-[0 auto] grid  items-center overflow-hidden pb-6"
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#e9e9e9' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  <a
                    href={url}
                    target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                    className="grid-rows-1 grid-cols-1"
                  >
                    {name}
                  </a>
                  <motion.div
                    variants={underLine}
                    className="top-[17px] h-2 relative left-0 w-[100%] z-1 mt-[-23px] bg-gradient-to-tr from-green-200 to-blue-400 rounded-full "
                    style={{
                      backgroundColor: getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'green' })
                        .color,
                    }}
                  />
                </motion.li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

const MobileNavbar = ({ menu, website }: MenuVariantProps) => {
  const [show, setShow] = useState(false)
  return (
    <div
      className="block  lg:hidden overlay-hidden"
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'black' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className="flex justify-between items-center px-4 py-4 overflow-hidden text-white">
        <div>
          <a href="/">
            {website?.config?.logo ? (
              <img
                className="cursor-pointer rounded-full h-[30px] w-[30px]"
                src={website?.config?.logo}
                alt={website?.name}
              />
            ) : (
              <p
                className="text-lg cursor-pointer"
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                }}
              >
                {website?.name}
              </p>
            )}
          </a>
        </div>
        <div>
          {show ? (
            <button
              className="flex flex-col cursor-pointer top-0 right-0 z-20 relative w-10 h-10 focus:outline-none"
              onClick={() => setShow(false)}
              style={{
                ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
              }}
            >
              <div className="absolute w-5 transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
                <span
                  className={`absolute h-0.5 w-5 bg-white transform transition duration-300 ease-in-out ${
                    show ? 'rotate-45 delay-200' : '-translate-y-1.5'
                  }`}
                ></span>
                <span
                  className={`absolute h-0.5 bg-white transform transition-all duration-200 ease-in-out ${
                    show ? 'w-0 opacity-50' : 'w-5 delay-200 opacity-100'
                  }`}
                ></span>
                <span
                  className={`absolute h-0.5 w-5 bg-white transform transition duration-300 ease-in-out ${
                    show ? '-rotate-45 delay-200' : 'translate-y-1.5'
                  }`}
                ></span>
              </div>
            </button>
          ) : (
            <button className="top-0 right-0 z-20 relative w-10 h-10 cursor-pointer" onClick={() => setShow(true)}>
              <div className="absolute w-5 transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
                <span
                  className={`absolute h-0.5 w-5 bg-white transform transition duration-300 ease-in-out ${
                    show ? 'rotate-45 delay-200' : '-translate-y-1.5'
                  }`}
                ></span>
                <span
                  className={`absolute h-0.5 bg-white transform transition-all duration-200 ease-in-out ${
                    show ? 'w-0 opacity-50' : 'w-5 delay-200 opacity-100'
                  }`}
                ></span>
                <span
                  className={`absolute h-0.5 w-5 bg-white transform transition duration-300 ease-in-out ${
                    show ? '-rotate-45 delay-200' : 'translate-y-1.5'
                  }`}
                ></span>
              </div>
            </button>
          )}
        </div>
      </div>
      {show && (
        <motion.ul
          animate={{ y: show ? [-150, 0] : -150 }}
          transition={{ duration: 1 }}
          className="flex flex-col items-center overflow-hidden text-white py-3"
          style={{
            ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'black' }),
            ...getSpacing(menu?.settings?.spacing),
          }}
        >
          {menu.links.map(({ id, name, url, type }) => {
            return (
              <motion.li
                key={id}
                initial="default"
                whileHover="hover"
                animate="default"
                className="cursor-pointer hover:text-gray-200 transition-all duration-300 relative m-[0 auto] grid  items-center overflow-hidden pb-6"
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                }}
              >
                <a
                  href={url}
                  target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                  className="grid-rows-1 grid-cols-1"
                >
                  {name}
                </a>
                <motion.div
                  variants={underLine}
                  className="top-[17px] h-[3px] relative left-0 w-[100%] z-1 mt-[-23px] bg-gradient-to-tr from-green-200 to-blue-400 rounded-full "
                />
              </motion.li>
            )
          })}
        </motion.ul>
      )}
    </div>
  )
}
