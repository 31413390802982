import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { AttentionGrabberSection } from '../../types'
import { generateSectionSpacing } from '../../utils'

const VariantFourteen = ({ section }: { section: AttentionGrabberSection }) => {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const bgColor = section?.settings?.color?.background?.value

  return (
    <div
      className="relative flex text-white items-end justify-center"
      style={{
        background: bgColor || '#773288',
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="w-full h-full">
        <div className="mb-[-10vh] text-center h-[50vh] lg:h-[60vh] flex items-center gap-3 flex-col justify-center">
          <h1 className="lg:text-8xl text-4xl">
            <SectionSlatePreview initialValue={title} previewMode />
          </h1>
          <h1 className="lg:text-3xl  text-xl">
            <SectionSlatePreview initialValue={subTitle} previewMode />
          </h1>
        </div>
        <div>
          <svg
            className="relative w-full lg:h-[30vh] h-[10vh] min-h-[10vh] lg:min-h-[100px] mb-[-7px]"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shape-rendering="auto"
          >
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" className="fill-white/70" />
              <use xlinkHref="#gentle-wave" x="48" y="3" className="fill-white/50" />
              <use xlinkHref="#gentle-wave" x="48" y="5" className="fill-white/30" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default VariantFourteen
