import create from 'zustand'

type App = {
  previewMode: 'desktop' | 'mobile'
  setPreviewMode: (previewMode: 'desktop' | 'mobile') => void
  togglePreviewMode: () => void
}

const useApp = create<App>(
  (set): App => ({
    previewMode: 'desktop',
    setPreviewMode: (previewMode: 'desktop' | 'mobile') => set(state => ({ ...state, previewMode })),
    togglePreviewMode: () =>
      set(state => ({ ...state, previewMode: state.previewMode === 'desktop' ? 'mobile' : 'desktop' })),
  }),
)

export default useApp
