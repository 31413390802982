function Banner() {
  return (
    <section>
      <div className="py-12 px-6 md:text-center">
        <h1 className="text-[22px] md:text-[24px] uppercase">
          <span className="block md:inline">THE</span> DESIGN TOOL <span className="block md:inline">FROM</span> THE
          Future
        </h1>
      </div>
    </section>
  )
}

export default Banner
