export type PageCatergories = 'landing' | 'aboutUs' | 'gallery' | 'services' | 'blog' | 'contact'

export function getAllPages() {
  const pages: Array<{ id: string; image: string; category: PageCatergories; name?: string }> = [
    {
      id: 'landing-01',
      image: '/images/pages/landing/landing-01.png',
      category: 'landing',
      name: 'landing',
    },
    {
      id: 'aboutUs-01',
      image: '/images/pages/aboutUS/aboutUs-01.png',
      category: 'aboutUs',
      name: 'about us',
    },
    {
      id: 'services-01',
      image: '/images/pages/services/services-01.png',
      category: 'services',
      name: 'services',
    },
    {
      id: 'gallery-01',
      image: '/images/pages/gallery/gallery-01.png',
      category: 'gallery',
      name: 'gallery',
    },
    {
      id: 'blog-01',
      image: '/images/pages/blog/blog-01.png',
      category: 'blog',
      name: 'blog',
    },
    {
      id: 'contact-01',
      image: '/images/pages/contact/contact-01.png',
      category: 'contact',
      name: 'contact',
    },
    {
      id: 'landing-02',
      image: '/images/pages/landing/landing-02.png',
      category: 'landing',
      name: 'landing',
    },
    {
      id: 'aboutUs-02',
      image: '/images/pages/aboutUs/aboutUs-02.png',
      category: 'aboutUs',
      name: 'about us',
    },
    {
      id: 'services-02',
      image: '/images/pages/services/services-02.png',
      category: 'services',
      name: 'services',
    },
    {
      id: 'gallery-02',
      image: '/images/pages/gallery/gallery-02.png',
      category: 'gallery',
      name: 'gallery',
    },
    {
      id: 'blog-02',
      image: '/images/pages/blog/blog-02.png',
      category: 'blog',
      name: 'blog',
    },
    {
      id: 'contact-02',
      image: '/images/pages/contact/contact-02.png',
      category: 'contact',
      name: 'contact',
    },
    {
      id: 'landing-03',
      image: '/images/pages/landing/landing-03.png',
      category: 'landing',
      name: 'landing',
    },
    {
      id: 'aboutUs-03',
      image: '/images/pages/aboutUS/aboutUs-03.png',
      category: 'aboutUs',
      name: 'about us',
    },
    {
      id: 'services-03',
      image: '/images/pages/services/services-03.png',
      category: 'services',
      name: 'services',
    },
    {
      id: 'gallery-03',
      image: '/images/pages/gallery/gallery-03.png',
      category: 'gallery',
      name: 'gallery',
    },
    {
      id: 'contact-03',
      image: '/images/pages/contact/contact-03.png',
      category: 'contact',
      name: 'contact',
    },
    {
      id: 'blog-03',
      image: '/images/pages/blog/blog-03.png',
      category: 'blog',
      name: 'blog',
    },
    {
      id: 'landing-04',
      image: '/images/pages/landing/landing-04.png',
      category: 'landing',
      name: 'landing',
    },

    {
      id: 'aboutUs-04',
      image: '/images/pages/aboutUS/aboutUs-04.png',
      category: 'aboutUs',
      name: 'about us',
    },
    {
      id: 'services-04',
      image: '/images/pages/services/services-04.png',
      category: 'services',
      name: 'services',
    },
    {
      id: 'gallery-04',
      image: '/images/pages/gallery/gallery-04.png',
      category: 'gallery',
      name: 'gallery',
    },
    {
      id: 'blog-04',
      image: '/images/pages/blog/blog-04.png',
      category: 'blog',
      name: 'blog',
    },
    {
      id: 'contact-04',
      image: '/images/pages/contact/contact-04.png',
      category: 'contact',
      name: 'contact',
    },
    {
      id: 'landing-05',
      image: '/images/pages/landing/landing-05.png',
      category: 'landing',
      name: 'landing',
    },
    {
      id: 'aboutUs-05',
      image: '/images/pages/aboutUS/aboutUs-05.png',
      category: 'aboutUs',
      name: 'about us',
    },
    {
      id: 'services-05',
      image: '/images/pages/services/services-05.png',
      category: 'services',
      name: 'services',
    },
    {
      id: 'gallery-05',
      image: '/images/pages/gallery/gallery-05.png',
      category: 'gallery',
      name: 'gallery',
    },
    {
      id: 'blog-05',
      image: '/images/pages/blog/blog-05.png',
      category: 'blog',
      name: 'blog',
    },
    {
      id: 'contact-05',
      image: '/images/pages/contact/contact-05.png',
      category: 'contact',
      name: 'contact',
    },

    {
      id: 'landing-06',
      image: '/images/pages/landing/landing-06.png',
      category: 'landing',
      name: 'landing',
    },
    {
      id: 'aboutUs-06',
      image: '/images/pages/aboutUS/aboutUs-06.png',
      category: 'aboutUs',
      name: 'about us',
    },
    {
      id: 'services-06',
      image: '/images/pages/services/services-06.png',
      category: 'services',
      name: 'services',
    },
    {
      id: 'gallery-06',
      image: '/images/pages/gallery/gallery-06.png',
      category: 'gallery',
      name: 'gallery',
    },
    {
      id: 'blog-06',
      image: '/images/pages/blog/blog-06.png',
      category: 'blog',
      name: 'blog',
    },
    {
      id: 'contact-06',
      image: '/images/pages/contact/contact-06.png',
      category: 'contact',
      name: 'contact',
    },
    {
      id: 'landing-07',
      image: '/images/pages/landing/landing-07.png',
      category: 'landing',
      name: 'landing',
    },
    {
      id: 'aboutUs-07',
      image: '/images/pages/aboutUS/aboutUs-07.png',
      category: 'aboutUs',
      name: 'about us',
    },
    {
      id: 'gallery-07',
      image: '/images/pages/gallery/gallery-07.png',
      category: 'gallery',
      name: 'gallery',
    },
    {
      id: 'contact-07',
      image: '/images/pages/contact/contact-07.png',
      category: 'contact',
      name: 'contact',
    },
    {
      id: 'landing-08',
      image: '/images/pages/landing/landing-08.png',
      category: 'landing',
      name: 'landing',
    },
    {
      id: 'aboutUs-08',
      image: '/images/pages/aboutUS/aboutUs-08.png',
      category: 'aboutUs',
      name: 'about us',
    },
    {
      id: 'gallery-08',
      image: '/images/pages/gallery/gallery-08.png',
      category: 'gallery',
      name: 'gallery',
    },
    {
      id: 'contact-08',
      image: '/images/pages/contact/contact-08.png',
      category: 'contact',
      name: 'contact',
    },
  ]
  return pages
}

export async function getPageWithBlocks({ category, id }: { category: PageCatergories; id: string }) {
  try {
    const page = await import(`./page-templates/${category}/${id}.ts`)
    return page
  } catch (error) {
    console.log(error)
  }
}
