import { useContext, useEffect, useState } from 'react'
import { WebsiteContext } from 'src/components/context/WebsiteContext'
import FontList from 'src/components/editor/FontList'
import { ReactComponent as EditPlus } from 'src/components/icons/EditPlus.svg'
import { Loader } from 'src/components/loader'
import ModalDialogV2 from 'src/components/new/ModalDialogV2'
import AdvancedIcon from 'src/icons/Advanced'
import { saveWebsite } from 'src/lib/services/website.service'
import useWebsite from 'src/store/website'
import { NewFooter } from 'src/_polly/components/src/footer/footer'
import { NewMenu } from 'src/_polly/components/src/menu/menu'
import ColorSelector from './ColorSelector'
import Spacing from './Spacing'
import TitleBar from './TitleBar'

type AdvancedMenuFooterProps = {
  mode: 'icon' | 'button'
  menu?: NewMenu
  footer?: NewFooter
  component: 'menu' | 'footer'
  onBack?: () => void
}

export default function AdvancedMenuFooter({ mode, menu, onBack, footer, component }: AdvancedMenuFooterProps) {
  const website = useContext(WebsiteContext)
  const store = useWebsite(state => state)

  const [settings, setSettings] = useState(menu?.settings ?? footer?.settings)
  const [loading, setLoading] = useState(false)
  const [showAdvanced, setShowAdvanced] = useState(false)
  const [showColorModal, setShowColorModal] = useState<null | 'background' | 'text'>(null)
  const [showSpacing, setShowSpacing] = useState(false)
  const [showFont, setShowFont] = useState(false)

  const getDefaultColor = () => {
    if (showColorModal === 'background') return settings?.backgroundColor?.color ?? '#FFFFFF'
    if (showColorModal === 'text') return settings?.textColor?.color ?? '#000000'
    return '#fff'
  }

  const getEnabled = () => {
    if (showColorModal === 'background') return settings?.backgroundColor?.enabled ?? false
    if (showColorModal === 'text') return settings?.textColor?.enabled ?? false
    return false
  }

  const handleColorChange = (color: string) => {
    if (showColorModal === 'background') {
      if (settings?.backgroundColor) {
        setSettings({
          ...settings,
          backgroundColor: {
            enabled: true,
            color,
          },
        })
      } else {
        setSettings({
          ...settings,
          backgroundColor: {
            enabled: true,
            color,
          },
        })
      }

      return
    }

    if (settings?.textColor) {
      setSettings({
        ...settings,
        textColor: {
          enabled: true,
          color,
        },
      })
    } else {
      setSettings({
        ...settings,
        textColor: {
          enabled: true,
          color,
        },
      })
    }
  }

  const handleColorSwitch = (enabled: boolean) => {
    if (showColorModal === 'background') {
      if (settings?.backgroundColor) {
        setSettings({
          ...settings,
          backgroundColor: {
            ...settings?.backgroundColor,
            enabled,
          },
        })
      } else {
        setSettings({
          ...settings,
          backgroundColor: {
            enabled,
            color: '#FFFFFF',
          },
        })
      }
      return
    }

    if (settings?.textColor) {
      setSettings({
        ...settings,
        textColor: {
          ...settings?.textColor,
          enabled,
        },
      })
    } else {
      setSettings({
        ...settings,
        textColor: {
          enabled,
          color: '#000000',
        },
      })
    }
  }

  const handleColorOnBack = async () => {
    setShowColorModal(null)
    save()
  }

  const handleSpacingOnBack = () => {
    setShowSpacing(false)
    save()
  }

  const handleFontOnBack = () => {
    setShowFont(false)
    save()
  }

  const save = async () => {
    setLoading(true)
    if (component === 'menu') {
      await saveWebsite({
        id: website.websiteID,
        newMenuConfig: JSON.stringify({
          ...store.website.newMenuConfig,
          settings,
        }),
      })
    } else {
      await saveWebsite({
        id: website.websiteID,
        newFooter: JSON.stringify({
          ...store.website.newFooter,
          settings,
        }),
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (component === 'menu') {
      store.setWebsite({
        ...store.website,
        newMenuConfig: {
          ...store.website.newMenuConfig,
          settings,
        },
      })
    } else {
      store.setWebsite({
        ...store.website,
        newFooter: {
          ...store.website.newFooter,
          settings,
        },
      })
    }
  }, [settings])

  return (
    <>
      {mode === 'button' ? (
        <div className="absolute right-6 bottom-11">
          <div className="dark:bg-black bg-white bottom-10 md:bottom-12 border rounded dark:border-white border-black w-[59px] h-[57px] flex flex-col items-center justify-center  py-6 mx-auto ">
            <button type="button" onClick={() => setShowAdvanced(!showAdvanced)} className="outline-none">
              {!showAdvanced && (
                <>
                  <AdvancedIcon size={28} color="#E90570" className="hidden md:block" />
                  <AdvancedIcon size={22} color="#E90570" className="block md:hidden" />
                </>
              )}
            </button>
          </div>
        </div>
      ) : (
        <button type="button" onClick={() => setShowAdvanced(!showAdvanced)}>
          <div className="hidden dark:block">
            <AdvancedIcon size={18} color="#fff" className="hidden md:block" />
            <AdvancedIcon size={16} color="#fff" className="block md:hidden" />
          </div>

          <div className="block dark:hidden">
            <AdvancedIcon size={18} color="#000" className="hidden md:block" />
            <AdvancedIcon size={16} color="#000" className="block md:hidden" />
          </div>
        </button>
      )}

      <ModalDialogV2
        open={showAdvanced}
        onBack={() => {
          setShowAdvanced(false)
          onBack?.()
        }}
      >
        <div className="px-4">
          <Loader show={loading} />

          <TitleBar title="Advanced" />
          <Item title="Background Color" onClick={() => setShowColorModal('background')} />
          <Item title="Text Color" onClick={() => setShowColorModal('text')} />
          <Item title="Font Family" onClick={() => setShowFont(true)} />
          <Item title="Spacing" onClick={() => setShowSpacing(true)} />
        </div>
      </ModalDialogV2>

      <ModalDialogV2 open={showColorModal} onBack={handleColorOnBack}>
        <ColorSelector
          title="Color"
          color={getDefaultColor()}
          enabled={getEnabled()}
          onChange={handleColorChange}
          onEnabledChange={handleColorSwitch}
          onBack={handleColorOnBack}
        />
      </ModalDialogV2>

      <ModalDialogV2 open={showSpacing} onBack={handleSpacingOnBack}>
        <Spacing
          defaultValue={
            settings?.spacing ?? {
              enabled: false,
              padding: { top: 0, bottom: 0, left: 0, right: 0 },
              margin: { top: 0, bottom: 0, left: 0, right: 0 },
              paddingMobile: { top: 0, bottom: 0, left: 0, right: 0 },
              marginMobile: { top: 0, bottom: 0, left: 0, right: 0 },
            }
          }
          onChange={spacing => setSettings(s => ({ ...s, spacing }))}
          onBack={handleSpacingOnBack}
        />
      </ModalDialogV2>

      <ModalDialogV2 open={showFont} onBack={handleFontOnBack} parentId="font-list">
        <FontList
          onSelect={font => {
            setSettings(s => ({ ...s, fontFamily: { enabled: true, font } }))
            handleFontOnBack()
          }}
        />
      </ModalDialogV2>
    </>
  )
}

function Item({ title, onClick }: { title: string; onClick?: () => void }) {
  return (
    <div className="flex justify-between items-center border-b border-gray-200 py-4 px-2">
      <p className="text-xl font-light dark:text-white">{title}</p>
      <button type="button" onClick={onClick}>
        <EditPlus />
      </button>
    </div>
  )
}
