import { motion } from 'framer-motion'
import { useState } from 'react'
import { HiOutlineMenuAlt4 } from 'react-icons/hi'
import { IoClose } from 'react-icons/io5'
import FontLoader from '../FontLoader'
import { MenuVariantProps, NewMenuLinkType } from '../menu'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../utils'
export default function MenuVariantSeven({ menu, website }: MenuVariantProps) {
  return (
    <>
      <FontLoader settings={menu?.settings?.fontFamily} />
      <DesktopNavbar menu={menu} website={website} />
      <MobileNavbar menu={menu} website={website} />
    </>
  )
}
const DesktopNavbar = ({ menu, website }: MenuVariantProps) => {
  return (
    <div className="hidden lg:block">
      <motion.div
        style={{
          ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'black' }),
          ...getSpacing(menu?.settings?.spacing),
        }}
      >
        <div className="px-12 py-6">
          <motion.div
            initial={{ y: -70, opacity: 0 }}
            animate={{
              y: 0,
              opacity: 1,
              transition: {
                duration: 1,
              },
            }}
            className="flex items-center"
          >
            <div className="w-[55%] text-black text-center">
              <a href="/">
                {website?.config?.logo ? (
                  <img
                    className="cursor-pointer w-[80px] h-[80px] rounded-full"
                    src={website?.config?.logo}
                    alt={website?.name}
                  />
                ) : (
                  <p
                    style={{
                      ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                    }}
                    className="text-xl py-6 text-black px-12 w-[350px] justify-center text-center items-center flex bg-white tracking-wide font-bold "
                  >
                    {website?.name}
                  </p>
                )}
              </a>
            </div>

            <ul className="flex gap-x-12 list-none w-[95%]">
              {menu.links.map(({ id, name, type, url }) => (
                <li
                  key={id}
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#d1cfcf' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  <a
                    href={url}
                    target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                    className="text-xl  ease-in-out transition-all duration-500 hover:text-[gold]"
                  >
                    {name}
                  </a>
                </li>
              ))}
            </ul>
          </motion.div>
        </div>
      </motion.div>
    </div>
  )
}

const MobileNavbar = ({ menu, website }: MenuVariantProps) => {
  const [show, setShow] = useState(false)
  return (
    <div
      className="block lg:hidden bg-black "
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'black' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <motion.div className="px-4 py-8 flex justify-between items-center">
        <div className=" text-white">
          <a href="/">
            {website?.config?.logo ? (
              <img
                className="cursor-pointer w-[30px] h-[30px] rounded-full"
                src={website?.config?.logo}
                alt={website?.name}
              />
            ) : (
              <p
                className="font-bold"
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                }}
              >
                {website?.name}
              </p>
            )}
          </a>
        </div>
        <div className=" text-white">
          {show ? (
            <IoClose
              onClick={() => setShow(false)}
              className="cursor-pointer"
              fontSize={30}
              color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#fff' }).color}
            />
          ) : (
            <HiOutlineMenuAlt4
              onClick={() => setShow(true)}
              className="cursor-pointer"
              fontSize={30}
              color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#fff' }).color}
            />
          )}
        </div>
      </motion.div>
      {show && (
        <div
          style={{
            ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '#77767630' }),
            ...getSpacing(menu?.settings?.spacing),
          }}
        >
          <motion.ul
            animate={{
              x: show ? [280, -20] : 280,
            }}
            transition={{
              duration: 0.7,
              type: 'spring',
            }}
            className="flex flex-col w-screen items-center gap-y-3 py-6 text-gray-50 list-none overflow-hidden mt-3"
          >
            {menu.links.map(({ id, name, type, url }) => {
              return (
                <motion.li
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                    transition: {
                      delay: 0.5,
                      duration: 1,
                    },
                  }}
                  key={id}
                  className=" -ml-1"
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'black' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  <a
                    href={url}
                    target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                    className="px-12 hover:text-[black] hover:bg-[gray] text-center transition-all duration-300  "
                  >
                    {name}
                  </a>
                </motion.li>
              )
            })}
          </motion.ul>
        </div>
      )}
    </div>
  )
}
