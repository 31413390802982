import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import useApp from 'src/store/app'
import PreviewSaveButton from 'src/components/PreviewSaveButton'
import { SectionSettings } from 'src/_polly/components/src/sections'
import Preview from './Preview'
import TitleBar from './TitleBar'

type SpacingProps = {
  defaultValue: SectionSettings['spacing']
  onChange?: (settings: SectionSettings['spacing']) => void
  onBack?: () => void
}

export default function Spacing({ defaultValue, onChange, onBack }: SpacingProps) {
  const [spacing, setSpacing] = useState(defaultValue)
  const [showPreview, setShowPreview] = useState(false)
  const appStore = useApp(state => state)

  useEffect(() => {
    onChange?.(spacing)
  }, [spacing])

  return (
    <div className="px-4 dark:text-white">
      <div className="flex absolute right-8 top-5">
        <button
          type="button"
          className="border-[0.6px] border-[#0C0C0C] dark:border-white rounded-[5px] w-[24px] h-[32px]"
          onClick={appStore.togglePreviewMode}
        >
          <div
            className={clsx(
              'border-[0.6px] border-black dark:border-[#D9D9D9] rounded-[5px] w-[18px] h-[25px] mx-auto',
              appStore.previewMode === 'mobile' && 'bg-[#D9D9D9]',
            )}
          />
        </button>
        <button
          type="button"
          className="border-[0.6px] border-[#0C0C0C] dark:border-white rounded-[5px] w-[40px] h-[32px] ml-[10px]"
          onClick={appStore.togglePreviewMode}
        >
          <div
            className={clsx(
              'border-black dark:border-[#D9D9D9] border-[0.6px] rounded-[5px] w-[32px] h-[25px] mx-auto',
              appStore.previewMode === 'desktop' && 'bg-[#D9D9D9]',
            )}
          />
        </button>
      </div>
      <TitleBar
        title="Spacing"
        description="Spacing is calculated by Top [T], Left [L], Bottom [B], and Right [R]."
        showSwitch
        checked={spacing.enabled}
        onChange={enabled => setSpacing(s => ({ ...s, enabled }))}
      />
      {appStore.previewMode === 'desktop' ? (
        <div>
          <Item
            title="Margin"
            description="Margin: is the distance from one component to another component."
            defaultValue={spacing.margin}
            onChange={update => setSpacing(s => ({ ...s, enabled: true, margin: update }))}
          />
          <Item
            title="Padding"
            description="Padding: is the spacing between the content area and the outside border of your component.."
            defaultValue={spacing.padding}
            onChange={update => setSpacing(s => ({ ...s, enabled: true, padding: update }))}
          />
        </div>
      ) : (
        <div>
          <Item
            title="Margin"
            description="Margin: is the distance from one component to another component."
            defaultValue={spacing.marginMobile}
            onChange={update => setSpacing(s => ({ ...s, enabled: true, marginMobile: update }))}
          />
          <Item
            title="Padding"
            description="Padding: is the spacing between the content area and the outside border of your component."
            defaultValue={spacing.paddingMobile}
            onChange={update => setSpacing(s => ({ ...s, enabled: true, paddingMobile: update }))}
          />
        </div>
      )}

      <Preview onBack={() => setShowPreview(false)} open={showPreview} />
      <PreviewSaveButton onPreviewClick={() => setShowPreview(true)} onSaveClick={onBack} />
    </div>
  )
}

type ItemProps = {
  title: string
  description: string
  onChange?: (data: Inputs) => void
  defaultValue?: SectionSettings['spacing']['margin']
}
type Inputs = {
  top: number
  right: number
  bottom: number
  left: number
}

function Item({ description, title, onChange, defaultValue }: ItemProps) {
  const { register, watch, setValue } = useForm<Inputs>({
    defaultValues: defaultValue,
  })

  const top = watch('top')
  const right = watch('right')
  const bottom = watch('bottom')
  const left = watch('left')

  useEffect(() => {
    onChange?.({
      top,
      bottom,
      left,
      right,
    })
  }, [top, right, bottom, left])

  useEffect(() => {
    if (defaultValue) {
      setValue('top', defaultValue.top)
      setValue('right', defaultValue.right)
      setValue('bottom', defaultValue.bottom)
      setValue('left', defaultValue.left)
    }
  }, [defaultValue])

  return (
    <div className="py-5 border-b border-black dark:border-white">
      <p className="font-light">{description}</p>
      <div className="mt-4 flex justify-between items-center">
        <p className="text-xl font-light">{title}</p>

        <div className="flex gap-6">
          <div className="flex flex-col items-center">
            <label htmlFor="top" className="text-sm font-medium mb-1">
              T
            </label>
            <select
              className="border border-black outline-none w-[30px] h-[30px] flex items-center justify-center text-center dark:text-black text-sm form-select form-select-lg mb-3 appearance-none font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat transition ease-in-out focus:text-gray-700 "
              {...register('top', { valueAsNumber: true })}
            >
              <option value="0">0</option>
              <option value="30">1</option>
              <option value="60">2</option>
              <option value="90">3</option>
              <option value="120">4</option>
            </select>
          </div>

          <div className="flex flex-col items-center">
            <label htmlFor="right" className="text-sm font-medium mb-1">
              R
            </label>
            <select
              className="border border-black outline-none w-[30px] h-[30px] flex items-center justify-center text-center dark:text-black text-sm form-select form-select-lg mb-3 appearance-none font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat transition ease-in-out focus:text-gray-700 "
              {...register('right', { valueAsNumber: true })}
            >
              <option value="0">0</option>
              <option value="30">1</option>
              <option value="60">2</option>
              <option value="90">3</option>
              <option value="120">4</option>
            </select>
          </div>

          <div className="flex flex-col items-center">
            <label htmlFor="bottom" className="text-sm font-medium mb-1">
              B
            </label>
            <select
              className="border border-black outline-none w-[30px] h-[30px] flex  items-center justify-center text-center dark:text-black text-sm form-select form-select-lg mb-3 appearance-none font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat transition ease-in-out focus:text-gray-700 "
              {...register('bottom', { valueAsNumber: true })}
            >
              <option value="0">0</option>
              <option value="30">1</option>
              <option value="60">2</option>
              <option value="90">3</option>
              <option value="120">4</option>
            </select>
          </div>

          <div className="flex flex-col items-center">
            <label htmlFor="left" className="text-sm font-medium mb-1">
              L
            </label>
            <select
              className="border border-black outline-none w-[30px] h-[30px] flex items-center justify-center text-center dark:text-black text-sm form-select form-select-lg mb-3 appearance-none font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat transition ease-in-out focus:text-gray-700 "
              {...register('left', { valueAsNumber: true })}
            >
              <option value="0">0</option>
              <option value="30">1</option>
              <option value="60">2</option>
              <option value="90">3</option>
              <option value="120">4</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}
