import { useEffect, useState } from 'react'
import { ReactComponent as EditPlus } from 'src/components/icons/EditPlus.svg'
import { ButtonState } from 'src/_polly/components/src/constants'
import ModalDialogV2 from '../ModalDialogV2'
import Button from './Button'
import ButtonEditor from './ButtonEditor'

type ButtonForSectionProps = {
  defaultValue: ButtonState | null
  onButtonChange: (button: ButtonState | null) => void
  onChange: (button: ButtonState | null) => void
}

export default function ButtonForSection({ defaultValue, onButtonChange, onChange }: ButtonForSectionProps) {
  const [showButtonModal, setShowButtonModal] = useState(false)
  const [showButtonSizeModal, setShowButtonSizeModal] = useState(false)
  const [button, setButton] = useState<ButtonState | null>(defaultValue)

  const handleButtonEdit = () => {
    if (defaultValue) {
      setShowButtonModal(true)
      return
    }
    setShowButtonSizeModal(true)
  }

  const handleButtonChanges = (styles: Record<string, string>) => {
    if (button) {
      // eslint-disable-next-line
      setButton((_button: any) => ({ ..._button, settings: { ..._button.settings, ...styles }, visible: true }))
      return
    }

    setButton({
      value: 'Button',
      settings: styles,
      link: null,
      visible: true,
    })
  }

  useEffect(() => {
    onButtonChange(button)
    onChange(button)
  }, [button])

  return (
    <div>
      <div className="min-h-[66px] border-b border-black dark:border-white my-2 flex justify-between items-center dark:text-white bg-[#F3F3F3] dark:bg-black pl-3 dark:pl-0">
        <h1 className="text-[19px] ">Button</h1>
        <button onClick={handleButtonEdit} type="button" className="pr-2">
          <EditPlus />
        </button>
      </div>

      <ModalDialogV2 title="Button" open={showButtonSizeModal} onBack={() => setShowButtonSizeModal(false)}>
        <Button
          onSelect={style => {
            handleButtonChanges(style)
            setShowButtonSizeModal(false)
            setShowButtonModal(true)
          }}
        />
      </ModalDialogV2>

      <ModalDialogV2 title="Button" open={showButtonModal} onBack={() => setShowButtonModal(false)}>
        <div className="px-4">
          {button && (
            <ButtonEditor
              button={button}
              setButton={setButton}
              onChange={() => {
                setShowButtonModal(false)
                onButtonChange(button)
              }}
            />
          )}
        </div>
      </ModalDialogV2>
    </div>
  )
}
