import { motion } from 'framer-motion'
import SectionSlate from '../../../common/SectionSlatePreview'
import { PortfolioItemsGroup, PortfolioSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantThree({ section }: { section: PortfolioSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const items = section.variables.find(variable => variable.name === 'ITEMS')?.data as PortfolioItemsGroup[]

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
      }}
    >
      <div className="container mx-auto">
        <motion.div
          transition={{ x: -50 }}
          animate={{
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          className="py-4"
          style={{
            ...generateSectionSpacing(section?.settings?.spacing),
          }}
        >
          <div className=" lg:w-[48%] flex flex-col lg:gap-y-4 gap-y-2 px-3 lg:px-6 ">
            <h1 className=" lg:text-5xl text-3xl font-bold tracking-wide">
              <SectionSlate initialValue={title} previewMode />
            </h1>
            <p className="lg:text-2xl text-sm tracking-wide lg:leading-9 ">
              <SectionSlate initialValue={paragraph} previewMode />
            </p>
          </div>
          <div className=" grid lg:grid-cols-2 lg:mt-12 mt-6 lg:px-6 px-3 lg:w-[97%] lg:gap-y-8 gap-y-4 gap-x-4">
            {items.map(item => {
              return (
                <div key={item.id}>
                  <img
                    src={item.image}
                    className="lg:w-[850px] w-[400px] lg:h-[580px] h-[270px] object-cover opacity-90 hover:opacity-100 transition-all duration-500 cursor-pointer "
                  />
                </div>
              )
            })}
          </div>
        </motion.div>
      </div>
    </div>
  )
}
