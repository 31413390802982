import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getWebsiteByID } from 'src/lib/services/website.service'
import { serviceGetPost } from 'src/services/post.service'
import Header from './components/Header'
import Post from './components/Post'

export default function SinglePost() {
  const param = useParams()

  const [post, setPost] = useState<any>(null)

  const [websiteLogo, setWebsiteLogo] = useState('')
  if (post?.websiteID) {
    getWebsiteByID(post?.websiteID).then(data => {
      const config = JSON.parse(data?.config || '{}')
      setWebsiteLogo(config?.logo || undefined)
    })
  }

  useEffect(() => {
    if (param.postId) {
      serviceGetPost(param.postId).then(setPost)
    }
  }, [param.postId])

  useEffect(() => {
    const title = post?.title
    const description = post?.description
    const image = websiteLogo || ''

    document.title = title
    document.querySelector('[property="og:title"]')?.setAttribute('content', title)
    document.querySelector('[name="twitter:title"]')?.setAttribute('content', title)

    document.querySelector('[name="description"]')?.setAttribute('content', description)
    document.querySelector('[property="og:description"]')?.setAttribute('content', description)
    document.querySelector('[name="twitter:description"]')?.setAttribute('content', description)

    document.querySelector('[property="og:image"]')?.setAttribute('content', image)
    document.querySelector('[name="twitter:image"]')?.setAttribute('content', image)
  }, [post?.title, post?.description, websiteLogo])

  return (
    <div className="bg-white dark:bg-black min-h-screen">
      <Header />

      <div className="w-full md:w-[720px] px-6 md:px-0 mx-auto mt-12">
        {post ? <Post post={post} filterGrid="one" /> : <p>Post not found.</p>}
      </div>
    </div>
  )
}
