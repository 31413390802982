import { ContactUsSection, InputsContactUSSection } from '../../types'
import { useForm } from 'react-hook-form'
import InputText from '../../../common/InputText'
import { useState } from 'react'
import clsx from 'clsx'
import TextArea from '../../../common/TextArea'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import ButtonRenderer from '../ButtonRenderer'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantSix({ section }: { section: ContactUsSection }) {
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const subtitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const inputs = section.variables.find(variant => variant.name === 'INPUTS')?.data as InputsContactUSSection[]
  const button = section.variables.find(variant => variant.name === 'BUTTON_1')?.data
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    setLoading(true)
    try {
    } catch (error) {
      setError('Something went wrong!')
    }
    setLoading(false)
  }

  const inputClasses = {
    classInput: 'w-full border border-black md:px-3 py-[1rem] outline-none resize-none px-1',
    classLabel: ' text-sm inline-block  mb-1 ',
  }

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: '#EFEFEF',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="flex justify-center py-4 md:py-12 font-light">
        <div className="w-10/12 max-w-[700px]">
          {subtitle && (
            <p className="md:text-[45px] text-[26px] text-center">
              <SectionSlatePreview initialValue={subtitle} previewMode />
            </p>
          )}
          <p className="text-sm text-center">
            <SectionSlatePreview initialValue={paragraph} previewMode />
          </p>

          {inputs && (
            <form className="py-3 flex flex-col md:w-10/12 mx-auto text-sm" onSubmit={handleSubmit(onSubmit)}>
              <div className="grid gap-5 md:gap-y-4 md:gap-x-0  md:grid-cols-[1fr_0.4fr] items-end">
                {inputs.map(item => {
                  return (
                    <div key={item.id}>
                      {item.type !== 'button' && item.type !== 'textarea' && (
                        <InputText
                          {...inputClasses}
                          label={item.name}
                          name={item.name}
                          register={register}
                          type={item.type}
                          errors={errors}
                          required={{ value: true, message: `${item.error}` }}
                          placeholder={item.placeholder}
                        />
                      )}

                      {item.type === 'button' && (
                        <div>
                          <input
                            type={item.type}
                            value={item.name}
                            // placeholder={item.placeholder}
                            className="w-full px-5 py-[13.5px] text-sm bg-black text-white cursor-pointer"
                          />
                        </div>
                      )}
                      {item.type === 'textarea' && (
                        <TextArea
                          {...inputClasses}
                          label={item.name}
                          name={item.name}
                          register={register}
                          type={item.type}
                          errors={errors}
                          placeholder={item.placeholder}
                          required={{ value: true, message: `${item.error}` }}
                        />
                      )}
                    </div>
                  )
                })}
                {error && <span className="text-secondary.main text-center pt-4 font-semibold">{error}</span>}
                <div className="btn-submit">
                  {button ? (
                    <ButtonRenderer button={button} typeOfButton="submit" withOutHref />
                  ) : (
                    <button
                      disabled={loading}
                      type="submit"
                      className={clsx(
                        loading && 'cursor-not-allowed disabled:opacity-50',
                        'w-full px-5  text-sm bg-black text-white py-[1.3rem]',
                      )}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}
