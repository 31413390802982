import { Amplify } from 'aws-amplify'
import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import ReactHeap from 'reactjs-heap'
import { AuthContextProvider } from 'src/components/AuthContext'
import { MintProvider } from 'src/components/context/MintContext'
import 'tailwindcss/tailwind.css'
import awsExports from './aws-exports'
import PrivatePage from './components/PrivatePage'
import useColorMode from './hooks/useColorMode'
import FooterPreview from './pages/footer-preview'
import ForgotPage from './pages/forgot-password'
import HomePage from './pages/index'
import LandingPage from './pages/landing-page'
import LiveEdit from './pages/live-edit'
import Login from './pages/login'
import MenuPreview from './pages/menu-preview'
import OAuth from './pages/oauth'
import PlaygroundPage from './pages/playground'
import PhonePreviewPage from './pages/preview'
import Register from './pages/register'
import RequestInvite from './pages/requestInvite'
import MessengerPage from './pages/social/messenger-page/messenger'
import Notification from './pages/social/notification/notification'
import { Posts } from './pages/social/posts-page'
import SinglePost from './pages/social/posts-page/single-post'
import SitePreviewPage from './pages/social/preview-page/preview-page'
import ProfilePage from './pages/social/profile/profile'
import ProfileSetup from './pages/social/profile/ProfileSetup'
import SearchPage from './pages/social/search-page/searchpage'
import Social from './pages/social/social'
import VariantPreview from './pages/variant-preview'
import VerifyPage from './pages/verify-reset-password'
import WalletComponentList from './pages/wallet/components'
import Wallet from './pages/wallet/index'
import WalletMintList from './pages/wallet/mint/index'
import WalletMintTransactionList from './pages/wallet/mint/transactions'
import WalletWebsiteList from './pages/wallet/websites'
import WalletWebsite from './pages/wallet/[websiteId]'
import WebsitePagePage from './pages/websites/[...id]'
import WebsitePage from './pages/websites/[id]'
import './styles/globals.css'
import { isLocalhost } from './util'

const [localRedirectSignIn, productionRedirectSignIn] = awsExports.oauth.redirectSignIn.split(',')

const [localRedirectSignOut, productionRedirectSignOut] = awsExports.oauth.redirectSignOut.split(',')

const updatedAwsConfig = {
  ...awsExports,
  oauth: {
    ...awsExports.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  },
  Storage: {
    AWSS3: {
      bucket: 'polly204636-prod',
      region: 'us-east-2',
    },
  },
}

Amplify.configure(updatedAwsConfig)

export default function App() {
  useColorMode()

  useEffect(() => {
    ReactHeap.initialize('1301932991')
  }, [])

  return (
    <AuthContextProvider>
      <MintProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/request-invite" element={<RequestInvite />} />
          <Route path="/verify-reset-password" element={<VerifyPage />} />
          <Route path="/forgot-password" element={<ForgotPage />} />
          <Route path="/oauth" element={<OAuth />} />

          <Route path="/" element={<LandingPage />} />
          <Route path="/builder" element={<HomePage />} />
          <Route path="/websites/:id" element={<WebsitePage />} />
          <Route path="/websites/:id/*" element={<WebsitePagePage />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/wallet/components" element={<WalletComponentList />} />
          <Route path="/wallet/websites" element={<WalletWebsiteList />} />
          <Route path="/wallet/:websiteId" element={<WalletWebsite />} />
          <Route path="/wallet/mint" element={<WalletMintList />} />
          <Route path="/wallet/mint/transactions" element={<WalletMintTransactionList />} />
          <Route path="/preview/:id" element={<PhonePreviewPage />} />
          <Route path="/playground" element={<PlaygroundPage />} />
          <Route path="/variant-preview" element={<VariantPreview />} />
          <Route path="/menu-preview" element={<MenuPreview />} />
          <Route path="/footer-preview" element={<FooterPreview />} />

          {/* Live edit */}
          <Route path="/live/:websiteId/:pageId" element={<LiveEdit />} />

          {/* Social */}
          <Route element={<PrivatePage />}>
            <Route path="social/*" element={<Social />} />
            <Route path="home" element={<Posts />} />
            <Route path="profile/:userId" element={<ProfilePage />} />
            <Route path="profile-setup" element={<ProfileSetup />} />
            <Route path="posts/:postId" element={<SinglePost />} />
            <Route path="search" element={<SearchPage />} />
            <Route path="messages" element={<MessengerPage />} />
            <Route path="messages/:chatmateId" element={<MessengerPage />} />
            <Route path="messages/new" element={<MessengerPage />} />
            <Route path="website/:websiteId/preview" element={<SitePreviewPage />} />
            <Route path="notifications" element={<Notification />} />
          </Route>
        </Routes>
        <div id="modal-wrapper" />
      </MintProvider>
    </AuthContextProvider>
  )
}
