import Icon from './icons'
import { IconPropsT } from './types'

function Hamburger(props: IconPropsT) {
  const { title = 'Minus', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 28 21" size={size} title={title} {...otherProps}>
      <line x1="1" y1="20" x2="27" y2="20" strokeWidth="2" strokeLinecap="round" />
      <line x1="1" y1="10" x2="27" y2="10" strokeWidth="2" strokeLinecap="round" />
      <line x1="1" y1="1" x2="27" y2="0.999998" strokeWidth="2" strokeLinecap="round" />
    </Icon>
  )
}

export default Hamburger
