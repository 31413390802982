import { Link } from 'react-router-dom'

export type UserInfo = {
  name: string
  image: string
  message: string
  date: string
  time: string
  active: boolean
  onActiveClick?: () => void
}

export default function MobileUsers({ name, image, message, date, time, active, onActiveClick }: UserInfo) {
  return (
    <Link to="message">
      <button
        type="button"
        className={`w-screen ${
          active
            ? 'bg-[#D9D9D9] text-[black] dark:bg-[#FFFFFF] dark:text-[black]'
            : 'dark:bg-black dark:text-[#D9D9D9] bg-white text-black'
        }`}
        onClick={onActiveClick}
      >
        <div className="h-[110px] pl-[30px] py-2 pr-[28.5px] flex justify-between cursor-pointer">
          <div className="flex gap-x-6 text-left ">
            <img
              src={image}
              alt={name}
              className="w-[50px] object-cover mt-3 h-[50px] border-2 border-[#D9D9D9] rounded-full"
            />
            <div className="flex flex-col w-[198px] gap-y-1">
              <h3 className="font-bold text-[19px]"> {name}</h3>
              <p className="font-bold text-sm">{message}</p>
              <p className="flex text-[11px] ml-auto gap-x-2">
                <span className="text-[#707070] uppercase"> {date} </span>
                <span className="text-[#707070] uppercase">{time} </span>
              </p>
            </div>
          </div>
          {!active && <div className="bg-[#E90570] w-[14.84px] h-[14.84px] mt-[0.4rem] rounded-full lg:-mr-2" />}
        </div>
        <div className="dark:bg-[#DCE9D9] bg-black h-[1px] w-[86%] m-auto" />
      </button>
    </Link>
  )
}
