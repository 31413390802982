import clsx from 'clsx'
import SectionSlate from '../../../common/SectionSlatePreview'
import { ImageOmgSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantSix({ section }: { section: ImageOmgSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data

  return (
    <div
      className="grid grid-cols-2 py-[100px]"
      id={section.id}
      style={{
        ...generateSectionBackground({
          defaultColor: '#000',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      {media && (
        <div className=" justify-center lg:justify-end flex">
          <div className="mt-[80px] lg:mt-0 w-[150px] lg:w-[400px]   h-[300px] lg:h-[780px] ">
            <img src={media} alt="" className="h-full w-full object-cover object-center bg-black" />
          </div>
        </div>
      )}

      <div className="pl-0 lg:pl-14 flex items-center">
        <div className={clsx('max-w-full', media && 'w-[460px]', !media && 'w-[930px] mx-auto')}>
          <div className="text-white text-[32px] md:text-4xl font-bold  mt-[80px] lg:mt-0">
            <SectionSlate initialValue={title} previewMode />
          </div>

          {subTitle && (
            <div className="text-[14px] lg:text-[20px] text-white mt-2 lg:mt-11">
              <SectionSlate initialValue={subTitle} previewMode />
            </div>
          )}

          {paragraph && (
            <div className="text-white lg:leading-7  mt-2 lg:mt-12">
              <SectionSlate initialValue={paragraph} previewMode />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
