import { motion } from 'framer-motion'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { TABLESection, TABLESectionItem } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantOne({ section }: { section: TABLESection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const tables = section.variables.find(variable => variable.name === 'ITEMS')?.data as TABLESectionItem[]
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data

  return (
    <>
      <div
        className="xl:px-[40px] lg:px-[40px] md:px[30px] px-[0px] md:py-12"
        style={{
          ...generateSectionBackground({
            defaultColor: 'rgb(0 0 0 / 0.8)',
            backgroundSettings: section?.settings?.color?.background,
            image: null,
          }),
          ...generateSectionSpacing(section?.settings?.spacing),
        }}
      >
        <div className="px-4 md:px-12">
          <div className="grid lg:grid-cols-[auto_400px] xl:grid-cols-[auto_450px] gap-12">
            <div>
              <motion.div
                initial={{ scale: 1.03 }}
                animate={{ scale: 1 }}
                transition={{ duration: 1 }}
                exit={{ opacity: 0 }}
              >
                <img className="w-full rounded " src={media} />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 200 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, type: 'spring' }}
              >
                <div className="text-[#bebebe] mt-[-60px] sm:mt-[-70px] md:mt-[-80px] lg:mt-[-110px] z-50 ">
                  <motion.div whileHover={{ opacity: 0.5 }}></motion.div>
                  <h3 className="text-[60px] sm:text-[88px] lg:text-[145px] font-neue leading-none">
                    <SectionSlatePreview initialValue={title} previewMode />
                  </h3>
                </div>
              </motion.div>
            </div>

            <div className="text-[#bebebe] space-y-16">
              <div>
                <div>
                  <h4 className="text-[16px] xl:text-[20px] font-neuton pb-2 opacity-60">
                    <SectionSlatePreview initialValue={subTitle} previewMode />
                  </h4>
                  <hr className="opacity-20" />
                </div>

                {tables.map(item => {
                  return (
                    <div key={item.id}>
                      <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1, type: 'spring' }}
                      >
                        <div className="flex justify-between items-center py-1 xl:py-4 lg:py-4 md:py-2">
                          <h4 className="text-[24px] xl:text-[34px] font-neuton ">
                            <SectionSlatePreview initialValue={item?.title} previewMode />
                          </h4>
                          <h4 className="text-[18px] xl:text-[20px] font-neuton ">
                            <SectionSlatePreview initialValue={item?.subtitle} previewMode />
                          </h4>
                        </div>
                      </motion.div>
                      <hr className="opacity-20" />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
