import React from 'react'
import { Link } from 'react-router-dom'
import WriteMessage from 'src/icons/WriteMessage'
import BackIcon from 'src/icons/BackIcon'
import lightLogo from '../../../assets/images/logo-techboom.png'

type headerProps = {
  status?: 'new' | 'chat'
}

export default function HeaderMobile({ status }: headerProps) {
  return (
    <header className="md:flex justify-between items-center bg-[#ffff] dark:bg-[#000000] px-6 h-[70px] border-b-[3px] border-[#000000] dark:border-[#FFFFFF] py-4 md:py-0">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          {status === 'chat' || status === 'new' ? (
            <Link to="/messages/?showThreadList=true">
              <BackIcon
                size={28}
                fill="none"
                className="fill-white dark:fill-black stroke-black dark:stroke-white cursor-pointer mr-[16px]"
              />
            </Link>
          ) : (
            <Link to="/">
              <BackIcon
                size={28}
                fill="none"
                className="fill-white dark:fill-black stroke-black dark:stroke-white cursor-pointer mr-[16px]"
              />
            </Link>
          )}
          <Link to="/home">
            <div className="w-[30px] h-[30px] ">
              <img className="w-full h-full dark:hidden" src={lightLogo} alt="" />
              <div className="hidden dark:block">
                <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.7 28.3995C22.2663 28.3995 28.4 22.2658 28.4 14.6995C28.4 7.13321 22.2663 0.999512 14.7 0.999512C7.1337 0.999512 1 7.13321 1 14.6995C1 22.2658 7.1337 28.3995 14.7 28.3995Z"
                    stroke="white"
                    strokeWidth="0.6"
                  />
                  <path
                    d="M14.7 23.3995C19.5049 23.3995 23.4 19.5044 23.4 14.6995C23.4 9.89463 19.5049 5.99951 14.7 5.99951C9.89512 5.99951 6 9.89463 6 14.6995C6 19.5044 9.89512 23.3995 14.7 23.3995Z"
                    stroke="white"
                    strokeWidth="0.6"
                  />
                  <path
                    d="M14.7 19.3995C17.2957 19.3995 19.4 17.2952 19.4 14.6995C19.4 12.1038 17.2957 9.99951 14.7 9.99951C12.1043 9.99951 10 12.1038 10 14.6995C10 17.2952 12.1043 19.3995 14.7 19.3995Z"
                    stroke="white"
                    strokeWidth="0.6"
                  />
                </svg>
              </div>
            </div>
          </Link>
        </div>
        {status === 'chat' && (
          <div>
            <Link to="/messages/new">
              <WriteMessage size={30} className="stroke-black dark:stroke-white" />
            </Link>
          </div>
        )}
      </div>
    </header>
  )
}
