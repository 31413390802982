import { IoHome, IoLogoFacebook, IoLogoInstagram, IoLogoTwitter } from 'react-icons/io5'
import { v4 as uuid } from 'uuid'

export type MenuVariantProps = { menu: NewMenu; website: any }

export type Social = 'facebook' | 'twitter' | 'instagram'

export enum NewMenuLinkType {
  EXTERNAL = 'external',
  INTERNAL = 'internal',
  IN_PAGE = 'in-page',
  DOCUMENT = 'document',
  EMAIL = 'email',
  NONE = 'none',
}

export type NewMenuLink = {
  id: string
  name: string
  url: string
  type: NewMenuLinkType
}

export type NewMenuTags = 'IMAGE' | 'ANIMATION'

export type MenuFooterSettings = {
  backgroundColor?: {
    enabled: boolean
    color: string
  }
  textColor?: {
    enabled: boolean
    color: string
  }
  fontFamily?: {
    enabled: boolean
    font: string
  }
  spacing?: {
    enabled: boolean
    padding: {
      top: number
      right: number
      bottom: number
      left: number
    }
    margin: { top: number; right: number; bottom: number; left: number }
    paddingMobile?: {
      top: number
      right: number
      bottom: number
      left: number
    }
    marginMobile?: { top: number; right: number; bottom: number; left: number }
  }
}

export type NewMenu = {
  id: string
  links: NewMenuLink[]
  designedBy?: string
  styleName?: string
  tags?: NewMenuTags[]
  settings?: MenuFooterSettings
}

const variantCount = 24

export function getSocialIcon(type: Social) {
  if (type === 'facebook') return IoLogoFacebook
  if (type === 'instagram') return IoLogoInstagram
  if (type === 'twitter') return IoLogoTwitter
  return IoHome
}

export function getSocialLabel(type: Social) {
  if (type === 'facebook') return 'Facebook'
  if (type === 'instagram') return 'Instagram'
  if (type === 'twitter') return 'Twitter'
  return type
}

export function getMenuVariants(): NewMenu[] {
  return Array(variantCount)
    .fill(null)
    .map((_, i) => {
      let links: NewMenuLink[] = []
      let tags: NewMenuTags[] = []
      const variantId = `VARIANT_${i + 1}`
      const variantNum = `VARIANT ${i + 1}`

      if (variantId === 'VARIANT_1') {
        tags = ['IMAGE']
      }

      if (!links.length) {
        links = [
          {
            id: uuid(),
            name: 'Home',
            type: NewMenuLinkType.INTERNAL,
            url: '/',
          },
          {
            id: uuid(),
            name: 'Contact Us',
            type: NewMenuLinkType.EMAIL,
            url: 'hello@example.com',
          },
        ]
      }
      return {
        styleName: variantNum,
        id: variantId,
        links,
        tags,
      }
    })
}
