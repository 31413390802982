import { BsArrowUpRightCircle } from 'react-icons/bs'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { ContactUsSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import ButtonRenderer from '../ButtonRenderer'

export default function VariantSeventeen({ section }: { section: ContactUsSection }) {
  const title = section.variables.find(variant => variant.name === 'TITLE')?.data
  const subtitle = section.variables.find(variant => variant.name === 'SUBTITLE')?.data
  const button = section.variables.find(variant => variant.name === 'BUTTON_1')?.data

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: '#FAF796',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
      className=" py-8 px-6  tracking-wider"
    >
      <div className="md:text-3xl flex w-[84%] md:w-[45%] flex-col gap-y-1">
        <p className="md:leading-[50px]">
          <SectionSlatePreview initialValue={title} previewMode />
        </p>
        <div className="flex items-center gap-x-2">
          <p>
            <SectionSlatePreview initialValue={subtitle} previewMode />
          </p>
          <div>
            {button ? (
              <ButtonRenderer button={button} typeOfButton="submit" withOutHref />
            ) : (
              <button>
                <BsArrowUpRightCircle size={72} className="hidden lg:block" />
                <BsArrowUpRightCircle size={30} className="block lg:hidden" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
