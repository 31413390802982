import SectionSlate from '../../../common/SectionSlatePreview'
import { QuoteSection as QuoteSectionType } from '../../types'
import clsx from 'clsx'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

interface VariantOneProps {
  section: QuoteSectionType
}

export default function VariantFive({ section }: VariantOneProps) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: '#fff',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="md:mx-auto px-4 md:px-0 ">
        <div className="flex flex-col sm:flex-row items-center justify-center gap-2 overflow-hidden">
          <div className="lg:w-1/4 lg:h-96 md:w-80 flex flex-col ">
            <p className="text-gray-700 pb-3  w-full text-sm lg:hidden block">
              <SectionSlate initialValue={subTitle} previewMode />
            </p>
            <img
              className={clsx('object-cover lg:w-full lg:h-full mb-5 bg-center overflow-hidden pr-16')}
              src={media}
              alt="image"
            />
            <p className="text-gray-700 md:text-2xl font-semibold lg:block hidden">
              <SectionSlate initialValue={subTitle} previewMode />
            </p>
          </div>
          <div className="lg:p-8 p-5 pr-10 -mt-28 -mr-32 rounded text-start lg:w-1/2 w-80 lg:-ml-48">
            <p className="text-zinc-800 pb-4 lg:text-4xl text-lg ">
              <SectionSlate initialValue={title} previewMode />
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
