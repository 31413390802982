import { useState } from 'react'
import { ImCross } from 'react-icons/im'
import { IoMenuSharp } from 'react-icons/io5'
import FontLoader from '../FontLoader'
import { MenuVariantProps, NewMenuLinkType } from '../menu'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../utils'

const VariantTwentyFour = ({ menu }: MenuVariantProps) => {
  const [nav, setNav] = useState(false)
  return (
    <>
      <FontLoader settings={menu?.settings?.fontFamily} />
      <div
        className={`${nav ? 'h-screen' : 'md:h-24 h-20'}`}
        style={{
          ...getBackgroundColor({
            settings: menu?.settings?.backgroundColor,
            defaultValue: 'white',
          }),
          ...getSpacing(menu?.settings?.spacing),
        }}
      >
        <div className="h-full w-full relative px-12 ">
          <button onClick={() => setNav(!nav)} className="absolute top-7 right-7  text-white z-10">
            {nav ? (
              <ImCross
                size={20}
                color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }).color}
              />
            ) : (
              <IoMenuSharp
                size={34}
                color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }).color}
              />
            )}
          </button>
          <div
            className={`wrapper-link w-screen h-screen bg-black absolute top-0 right-0`}
            style={{
              clipPath: nav ? 'circle(75%)' : 'circle(25px at calc(100% - 45px) 45px)',
              transition: 'all 0.3s ease-in-out',
            }}
          >
            <ul className="w-full h-full mt-24">
              {menu.links.map((link: any) => (
                <li key={link?.id} className="relative">
                  <a
                    href={link?.url}
                    target={link?.type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                    className="text-white font-bold"
                    style={{
                      ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }),
                      ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                    }}
                  >
                    {link?.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default VariantTwentyFour
