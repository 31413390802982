import clsx from 'clsx'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { GoPrimitiveDot } from 'react-icons/go'
import InputText from '../../../common/InputText'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import TextArea from '../../../common/TextArea'
import { ContactUsSection, InputsContactUSSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import ButtonRenderer from '../ButtonRenderer'

export default function VariantSeven({ section }: { section: ContactUsSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subtitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data
  const inputs = section.variables.find(variant => variant.name === 'INPUTS')?.data as InputsContactUSSection[]
  const button = section.variables.find(variant => variant.name === 'BUTTON_1')?.data

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    setLoading(true)
    try {
    } catch (error) {
      setError('Something went wrong!')
    }
    setLoading(false)
  }

  const inputClasses = {
    classInput:
      'input block focus:outline-none mx-auto py-3 md:p-2.5 border-2 lg:w-10/12 w-11/12 rounded-none resize-none px-1',
    classLabel: 'font-extralight text-sm inline-block flex items-start px-12 py-3 md:-ml-0 -ml-7',
  }

  return (
    <div
      className="my-8"
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="md:flex md:mx-auto md:container px-4 md:px-0">
        <div className="w-11/12 hidden lg:block min-h-[700px]">
          <img className="w-full h-full object-cover object-left  opacity-95" src={media} alt="" />
        </div>
        <div className="w-full flex items-center">
          <div className="text-center w-full flex flex-col">
            <p className="text-[#B48B7E] md:text-2xl">
              <SectionSlatePreview initialValue={subtitle} previewMode />
            </p>
            <p className="md:text-4xl -mt-6 text-lg">
              <SectionSlatePreview initialValue={title} previewMode />
            </p>
            <div className="text-[#B48B7E] md:mt-2 mb-2 w-28 mx-auto flex items-center">
              <div className="w-full">
                <div className="bg-[#B48B7E] h-[1px]"></div>
              </div>
              <span className="mx-2">
                <GoPrimitiveDot></GoPrimitiveDot>
              </span>
              <div className="w-full">
                <div className="bg-[#B48B7E] h-[1px]"></div>
              </div>
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ type: 'spring', stiffness: 100, duration: 300, delay: 0.1 }}
              viewport={{ once: true }}
            >
              {inputs ? (
                <form
                  className="grid lg:grid-cols-1 md:grid-cols-2 gap-y-3 items-center"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {inputs.map(item => {
                    return (
                      <div key={item.id}>
                        {item.type !== 'button' && item.type !== 'textarea' && (
                          <InputText
                            {...inputClasses}
                            label={item.name}
                            name={item.name}
                            register={register}
                            type={item.type}
                            errors={errors}
                            placeholder={item.placeholder}
                            required={{ value: true, message: `${item.error}` }}
                          />
                        )}

                        {item.type === 'button' && (
                          <div>
                            <input
                              type={item.type}
                              value={item.name}
                              className="px-5 py-3 font-medium bg-black text-white cursor-pointer"
                            />
                          </div>
                        )}
                        {item.type === 'textarea' && (
                          <TextArea
                            placeholder={item.placeholder}
                            {...inputClasses}
                            label={item.name}
                            name={item.name}
                            register={register}
                            type={item.type}
                            errors={errors}
                            required={{ value: true, message: `${item.error}` }}
                          />
                        )}
                      </div>
                    )
                  })}

                  <div className="btn-submit">
                    {button ? (
                      <div className="flex flex-col items-center w-full">
                        <ButtonRenderer button={button} typeOfButton="submit" withOutHref />
                      </div>
                    ) : (
                      <button
                        disabled={loading}
                        type="submit"
                        className={clsx(
                          loading && 'cursor-not-allowed disabled:opacity-50',
                          'btn mx-auto rounded-none mt-3 bg-[#161D27] hover:bg-[#B48B7E] hover:border-[#B48B7E] py-2 font-thin text-white lg:w-10/12 w-11/12',
                        )}
                      >
                        Find A Table
                      </button>
                    )}
                  </div>
                  {error && <span className="text-secondary.main text-center mb-4">{error}</span>}
                </form>
              ) : null}
            </motion.div>
          </div>
        </div>
        <div className="w-11/12 hidden lg:block min-h-[700px]">
          <img className="w-full h-full object-cover object-right opacity-95" src={media} alt="" />
        </div>
      </div>
    </div>
  )
}
