import { useState } from 'react'
import { RiCloseFill } from 'react-icons/ri'
import { TbEqual } from 'react-icons/tb'
import FontLoader from '../FontLoader'
import { MenuVariantProps, NewMenuLinkType } from '../menu'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../utils'

export default function MenuVariantSixteen({ menu, website }: MenuVariantProps) {
  return (
    <>
      <FontLoader settings={menu?.settings?.fontFamily} />

      <DesktopNavbar menu={menu} website={website} />
    </>
  )
}

const DesktopNavbar = ({ menu }: MenuVariantProps) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: '#dcdc39' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className="md:py-6 py-4 z-50 ">
        <div className={`w-${isOpen ? 'auto' : '[80px]'} mx-auto`}>
          <div className="flex items-center justify-center">
            <div className="bg-white flex items-center justify-center rounded-full md:p-6 p-2 shadow-lg ">
              <button onClick={() => setIsOpen(!isOpen)} className="flex items-center outline-none" type="button">
                {!isOpen ? (
                  <>
                    <TbEqual
                      color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#666666' }).color}
                      size={30}
                      className="hidden md:block"
                    />
                    <TbEqual
                      color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#666666' }).color}
                      size={20}
                      className="block md:hidden"
                    />
                  </>
                ) : (
                  <>
                    <RiCloseFill
                      size={30}
                      color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#f974a1' }).color}
                      className="md:mr-3 hidden md:block"
                    />
                    <RiCloseFill
                      size={25}
                      color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#f974a1' }).color}
                      className="block md:hidden"
                    />
                  </>
                )}
              </button>
              <div className="flex gap-3 md:gap-x-6 md:flex-nowrap flex-wrap items-center justify-center">
                {isOpen &&
                  menu.links.map(({ id, name, type, url }) => (
                    <a
                      style={{
                        ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#666666' }),
                        ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                      }}
                      className="uppercase md:text-[22px] text-[14px] font-semibold hover:text-gray-500"
                      key={id}
                      href={url}
                      target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                    >
                      {name}
                    </a>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
