import SectionSlate from '../../../common/SectionSlatePreview'
import { ImageOmgSection as omgSectionType } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

interface VariantFiveProps {
  section: omgSectionType
  hideImageBg?: boolean
}

export default function VariantThirteen({ section, hideImageBg }: VariantFiveProps) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data

  return (
    <div
      className="py-[100px] bg-cover bg-no-repeat bg-center"
      style={{
        ...generateSectionBackground({
          defaultColor: '#000',
          backgroundSettings: section?.settings?.color?.background,
          image: media && !hideImageBg ? media : null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="container mx-auto px-6 md:px-0">
        <div className="w-[900px] max-w-full mx-auto grid grid-cols-2 gap-x-5">
          <div>
            <p className="text-[32px] md:text-4xl font-bold  text-white leading-[90px]">
              {<SectionSlate initialValue={title} previewMode />}
            </p>

            {subTitle && (
              <div className="mt-10">
                <p className="text-[20px] text-white">{<SectionSlate initialValue={subTitle} previewMode />}</p>
              </div>
            )}
          </div>
          <div>
            <p className="text-white leading-7">{<SectionSlate initialValue={paragraph} previewMode />}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
