import { motion } from 'framer-motion'
import { FcNext, FcPrevious } from 'react-icons/fc'
import { EffectCoverflow, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import SectionSlate from '../../../common/SectionSlatePreview'
import { BlogItemsGroup, BlogSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantOne({ section }: { section: BlogSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const items = section.variables.find(variable => variable.name === 'ITEMS')?.data as BlogItemsGroup[]
  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: 'black',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="container mx-auto  md:min-h-[600px] md:max-h-[700px] overflow-x-hidden grid grid-cols-1 lg:grid-cols-2 py-5 px-4 md:py-12 md:px-12 text-[#eee] items-start">
        <div className="mt-12 lg:mt-0 md:w-[700px] overflow-x-hidden items-center">
          <Swiper
            effect="coverflow"
            modules={[EffectCoverflow, Navigation]}
            loop
            slidesPerView={1}
            slidesPerGroup={1}
            shortSwipes={false}
            navigation={{
              nextEl: '.custom-swiper-button-next',
              prevEl: '.custom-swiper-button-prev',
              hideOnClick: true,
            }}
            className="my-swiper"
          >
            {items.map(item => {
              return (
                <SwiperSlide key={item.id} className="flex md:gap-x-6  md:w-[600px]">
                  <img
                    src={item.image}
                    alt=""
                    className="lg:w-[480px] w-[290px] h-[200px] lg:h-[540px] object-cover object-center"
                  />
                  <div className="md:w-[120px] whitespace-nowrap lg:mt-32 -mr-5  text-ellipsis overflow-hidden text-left mt-12  md:-ml-12 h-[50px]  rotate-[270deg] lg:rotate-[90deg]">
                    <div className="flex lg:text-lg text-[10px] font-bold  items-center">
                      <SectionSlatePreview initialValue={item.name} previewMode />
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
          <div className="flex right-1 -mt-5  absolute z-50  md:hidden">
            <button className=" w-[25px] md:w-[60px] md:h-[60px] h-[25px] flex items-center justify-center  md:p-2 custom-swiper-button-prev">
              <FcPrevious size={12} strokeWidth={4} stroke="white" />
            </button>
            <button className=" w-[25px] h-[25px] md:w-[60px] md:h-[60px] flex items-center justify-center  md:p-2 custom-swiper-button-next">
              <FcNext size={12} strokeWidth={4} stroke="white" />
            </button>
          </div>
        </div>
        <div className="mt-5 flex md:-ml-20 ">
          <div className="overflow-hidden leading-8 tracking-wide ">
            <motion.p
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1, transition: { duration: 1 } }}
              className="lg:text-4xl text-2xl overflow-hidden w-[92%] max-h-[120px] md:max-h-[200px]"
            >
              <SectionSlate initialValue={title} previewMode />
            </motion.p>
            <div className="max-h-[400px] whitespace-nowrap overflow-hidden w-[100%] lg:mt-8 mt-6 lg:w-[95%] flex flex-col tracking-wide text-sm lg:text-2xl gap-y-6  lg:gap-y-12 ">
              <motion.p
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1, transition: { duration: 1, delay: 0.5 } }}
                className=" lg:tracking-wider lg:text-2xl tracking-wide  leading-6 lg:leading-10"
              >
                <SectionSlate initialValue={paragraph} previewMode />
              </motion.p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
