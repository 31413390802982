import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { TESTIMONIALSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantTwo({ section }: { section: TESTIMONIALSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data
  const video = section.variables.find(variable => variable.name === 'VIDEO')?.data
  // console.log(section)
  return (
    <div
      className="py-[50px] lg:py-[100px] bg-cover bg-no-repeat bg-center"
      style={{
        ...generateSectionBackground({
          defaultColor: '#000',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="md:py-12 py-4">
        {media && (
          <div>
            <div className="h-[445px] w-[940px] max-w-full mx-auto">
              {/* <img className="h-full w-full object-cover object-center bg-black" src={media} alt="" /> */}
              <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${video}`} title="Video" />
            </div>
          </div>
        )}
        <div></div>

        <div className="container mx-auto px-6 md:px-0">
          <div className="w-[940px] max-w-full mx-auto">
            <div className="md:text-3xl text-[32px] text-white  mt-4">
              <SectionSlatePreview initialValue={title} previewMode />
            </div>
            {subTitle && (
              <div className="md:text-[20px] text-[14px] text-white">
                <SectionSlatePreview initialValue={subTitle} previewMode />
              </div>
            )}
          </div>
        </div>
      </div>
      <div></div>
    </div>
  )
}
