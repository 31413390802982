import clsx from 'clsx'
import { useContext, useEffect, useState } from 'react'
import { WebsiteContext } from 'src/components/context/WebsiteContext'
import { Loader } from 'src/components/loader'
import EditMinus from 'src/icons/EditMinus'
import EditPlus from 'src/icons/EditPlus'
import LinkIcon from 'src/icons/Link'
import Delete from 'src/icons/Delete'
import { saveWebsite } from 'src/lib/services/website.service'
import useWebsite from 'src/store/website'
import { NewFooterCategory } from 'src/_polly/components/src/footer/footer'
import { NewMenuLink } from 'src/_polly/components/src/menu/menu'
import { v4 as uuid } from 'uuid'
import NewMenuAddNewLink from './AddNewLink'
import { getWebsiteFooter } from './footer'
import LinkItem from './LinkItem'
import { MenuSaveButton } from './NewMenu'

type FooterSubtitleProps = {
  category: NewFooterCategory
  onSaveChanges: () => void
}

export default function FooterSubtitle({ category, onSaveChanges }: FooterSubtitleProps) {
  const websiteContext = useContext(WebsiteContext)
  const store = useWebsite(state => state)

  const [loading, setLoading] = useState(false)
  const [deleteItem, setDeleteItem] = useState<NewMenuLink | null>(null)
  const [linkEdit, setLinkEdit] = useState(false)
  const [editItem, setEditItem] = useState<NewMenuLink | null>(null)
  const [links, setLinks] = useState<NewMenuLink[]>(category.links)
  const [name, setName] = useState(category.name)
  const [linksUpdated, setLinksUpdated] = useState<'update' | 'add' | 'sort' | 'duplicate' | 'delete' | 'none'>('none')
  const [showLink, setShowLink] = useState(false)

  const handleLinkAdd = (link: NewMenuLink) => {
    setLinkEdit(false)
    setLinks(prev => [
      ...prev,
      {
        ...link,
        id: uuid(),
      },
    ])
    setLinksUpdated('add')
    setLinkEdit(false)
    setEditItem(null)
  }
  const handleAddNewLink = async (link: Omit<NewMenuLink, 'id'>) => {
    setLinks(prev => [
      ...prev,
      {
        ...link,
        id: uuid(),
      },
    ])
    setLinksUpdated('add')
    setLinkEdit(false)
  }

  const handleEdit = async (link: Partial<NewMenuLink>) => {
    setLinks(prev => prev.map(item => (item.id === editItem?.id ? { ...item, ...link } : item)))
    setLinksUpdated('update')
    setEditItem(null)
  }

  const handleMoveUp = (index: number) => {
    if (index === 0) return
    const newLinks = [...links]
    const temp = newLinks[index]
    newLinks[index] = newLinks[index - 1]
    newLinks[index - 1] = temp
    setLinks(newLinks)
    setLinksUpdated('sort')
  }

  const handleMoveDown = (index: number) => {
    if (index === links.length - 1) return
    const newLinks = [...links]
    const temp = newLinks[index]
    newLinks[index] = newLinks[index + 1]
    newLinks[index + 1] = temp
    setLinks(newLinks)
    setLinksUpdated('sort')
  }

  const handleDuplicate = (index: number) => {
    const newLinks = [...links]
    newLinks.splice(index + 1, 0, { ...newLinks[index], id: uuid() })
    setLinks(newLinks)
    setLinksUpdated('duplicate')
    setEditItem(null)
  }

  const handleDelete = async () => {
    setLinks(prev => prev.filter(link => link.id !== deleteItem?.id))
    setDeleteItem(null)
    setLinksUpdated('delete')
    setEditItem(null)
  }

  const handleLinkUpdate = async () => {
    setLoading(true)
    const footer = await getWebsiteFooter(websiteContext.websiteID)
    if (!footer) return
    const newFooter = {
      ...footer,
      categories: footer.categories.map(c => {
        if (c.id === category.id) {
          return {
            ...c,
            links,
          }
        }
        return c
      }),
    }
    await saveWebsite({ id: websiteContext.websiteID, newFooter: JSON.stringify(newFooter) })
    setLoading(false)
  }

  const handleSave = async () => {
    setLoading(true)
    const footer = await getWebsiteFooter(websiteContext.websiteID)
    if (!footer) return
    const newFooter = {
      ...footer,
      categories: footer.categories.map(c => {
        if (c.id === category.id) {
          return {
            ...c,
            name,
            links,
          }
        }
        return c
      }),
    }
    await saveWebsite({ id: websiteContext.websiteID, newFooter: JSON.stringify(newFooter) })
    setLoading(false)
    onSaveChanges()
  }

  const fetchLinks = async () => {
    setLoading(true)
    const footer = await getWebsiteFooter(websiteContext.websiteID)
    if (!footer) return
    const categoryLinks = footer.categories.find(c => c.id === category.id)?.links ?? []
    setLinks(categoryLinks)
    setLoading(false)
  }
  const handleDeleteSubtitle = async () => {
    setLoading(true)
    const footer = await getWebsiteFooter(websiteContext.websiteID)
    if (!footer) return

    const newCategories = footer.categories.filter(c => c.id !== category.id)
    const newFooter = {
      ...footer,
      categories: newCategories,
    }

    await saveWebsite({ id: websiteContext.websiteID, newFooter: JSON.stringify(newFooter) })
    setName('')
    setLoading(false)
    onSaveChanges()
  }

  useEffect(() => {
    if (linksUpdated !== 'none') handleLinkUpdate()
  }, [linksUpdated])

  useEffect(() => {
    store.updateNewFooterCategory({
      id: category.id,
      name,
      links,
    })
  }, [name, links])

  useEffect(() => {
    fetchLinks()
  }, [])

  return (
    <div className="px-[18px]">
      <Loader show={loading} />
      <div
        className={clsx(
          'z-50 fixed top-0 right-0 bottom-0 left-0 justify-center items-center',
          !deleteItem && 'hidden',
        )}
      >
        <div className="relative w-full h-full bg-white/90 dark:bg-black/90 flex justify-center items-center">
          <div className="w-[362px] bg-white dark:bg-black border-[1px] rounded-[6px] border-[#E90570] border-solid">
            <div className="pt-[13px] pb-[20px]">
              <p className="text-[25px] text-black dark:text-white text-center">Remove link</p>
              <p className="text-[25px] text-black dark:text-white text-center">{deleteItem?.name}</p>
            </div>
            <div className="grid border-t dark:border-white mx-[10px] border-black grid-cols-2">
              <button
                onClick={() => handleDelete()}
                type="button"
                className="border-r text-[19px] dark:border-white dark:text-white border-black text-center text-black my-[16px] "
              >
                Delete
              </button>
              <button
                onClick={() => setDeleteItem(null)}
                type="button"
                className="dark:text-white text-[19px] text-black my-[16px]"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <h3 className="text-[19px] dark:text-white border-black px-[2px] border-b-[1px] dark:border-white pb-[16px] mb-[46px]">
        Subtitle
      </h3>
      <div className="flex items-center gap-4">
        <div className="w-[58px]">
          <LinkIcon
            height={24}
            className="stroke-[#8D8D8D] fill-[#8D8D8D] dark:fill-[#D9D9D9] dark:stroke-[#D9D9D9] mx-auto"
          />
        </div>
        <input
          className="text-[19px] w-1/2 border-b-[1px] dark:text-white dark:bg-black dark:border-white border-black pb-[7px] outline-none text-left"
          type="text"
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <div className="flex items-end gap-2">
          <MenuSaveButton save="save" onClick={handleSave} />
          <button type="button" onClick={handleDeleteSubtitle}>
            <Delete height={23} fill="none" className="mx-auto stroke-brand-accent" />
          </button>
        </div>
      </div>
      <div className="mt-[32px]">
        <div className="flex items-center justify-between pb-[22px] px-[22px]">
          <h2 className="text-[19px] dark:text-white">Connected Links ?</h2>
          {showLink ? (
            <button type="button" onClick={() => setShowLink(false)}>
              <EditMinus size={50} fill="none" className="stroke-[#8D8D8D] dark:stroke-white" />
            </button>
          ) : (
            <button type="button" onClick={() => setShowLink(true)}>
              <EditPlus size={50} fill="none" className="stroke-[#8D8D8D] dark:stroke-white" />
            </button>
          )}
        </div>
        {showLink && (
          <div className="border-t-[1px] dark:border-white">
            {links.map((link, idx) => (
              <LinkItem
                item={link}
                key={link.id}
                index={idx}
                defaultValue={editItem}
                onEditClick={() => setEditItem(link)}
                onEditBack={() => setEditItem(null)}
                onUpClick={() => handleMoveUp(idx)}
                onDownClick={() => handleMoveDown(idx)}
                onDuplicateClick={() => handleDuplicate(idx)}
                onDeleteClick={() => setDeleteItem(link)}
                onAdd={handleEdit}
                handleLinkAdd={handleLinkAdd}
              />
            ))}
            <div className="w-full relative">
              <div className="h-[76px] w-full border-b-[1px] border-[#D9D9D9] flex justify-between px-[20px] items-center">
                <div className="flex items-center gap-[18px]">
                  <LinkIcon
                    height={10}
                    className="stroke-[#8D8D8D] fill-[#8D8D8D] dark:fill-[#D9D9D9] dark:stroke-[#D9D9D9]"
                  />
                  <p className="text-[19px] text-left dark:text-white">Create New Link</p>
                </div>
                <button type="button" onClick={() => setLinkEdit(true)}>
                  <EditPlus
                    size={50}
                    fill="none"
                    className="fill-white dark:fill-black stroke-[#E90570] dark:stroke-[#E90570]"
                  />
                </button>
              </div>
              {linkEdit && (
                <div className="">
                  <div className="fixed z-30 inset-0 lg:max-w-[500px] dark:bg-black bg-white" />
                  <div className="fixed top-[20vh] px-[22px] z-40 inset-0 lg:max-w-[500px]">
                    <NewMenuAddNewLink
                      newLink
                      handleLinkAdd={handleLinkAdd}
                      editBack={() => setLinkEdit(false)}
                      onAdd={link => {
                        handleAddNewLink(link as any)
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
