import { motion, useInView } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { GallerySection, GallerySectionItem } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
const viewVariants = {
  offscreen: {
    y: 300,
  },
  onscreen: {
    y: 0,
    transition: {
      duration: 0.5,
      delay: 0.2,
      ease: [0, 0.71, 0.2, 1.01],
    },
  },
}
const viewImgVariants = {
  offscreen: {
    opacity: 0.1,
  },
  onscreen: {
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: 0.8,
      ease: [0, 0.71, 0.2, 1.01],
    },
  },
}
const viewport = { once: true, amount: 0.5 }

export default function VariantThirteen({ section }: { section: GallerySection }) {
  const [singleProduct, setSingleProduct] = useState<object | any>({})
  const [index, setIndex] = useState<number>()
  const [singleProductId, setSingleProductId] = useState<string>(section?.variables[0]?.id)
  // const tabs = section?.variables
  const gallery = section.variables.find(variant => variant.name === 'MEDIA')?.data as GallerySectionItem[]
  useEffect(() => {
    const findProduct = gallery?.find(product => product?.id === singleProductId)
    setSingleProduct(findProduct)
  }, [singleProductId])
  return (
    <>
      <div
        className="grid lg:flex lg:space-x-20 lg:px-12 px-4 py-6 lg:py-16"
        style={{
          ...generateSectionBackground({
            defaultColor: 'white',
            backgroundSettings: section?.settings?.color?.background,
            image: null,
          }),
          ...generateSectionSpacing(section?.settings?.spacing),
        }}
      >
        <div className={`lg:space-y-10 order-last lg:order-first ${section?.variables?.length > 0 && 'lg:py-6'} `}>
          {gallery?.map((product, i) => (
            <Product
              key={product?.id}
              product={product}
              index={i + 1}
              setIndex={setIndex}
              setSingleProductId={setSingleProductId}
              singleProductId={singleProductId}
            />
          ))}
        </div>
        <div>
          {gallery && gallery.length !== 0 && (
            <motion.div initial="offscreen" whileInView="onscreen" viewport={viewport}>
              <motion.div variants={viewImgVariants}>
                <div className="relative my-5 lg:my-0 lg:w-[350px] lg:h-[390px] border rounded-2xl overflow-hidden">
                  <img src={singleProduct?.image} alt="Avatar" className="object-cover w-[350px]  h-[390px]" />
                  <div className="absolute w-full py-2.5 top-0 inset-x-0">
                    <div className="flex justify-between px-3 text-white text-[16px]">
                      <h6 className="">
                        <SectionSlatePreview initialValue={singleProduct?.data?.title} previewMode />
                      </h6>
                      <h6 className="text-[#8f908e]">
                        {index}/{gallery?.length}
                      </h6>
                    </div>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          )}
        </div>
      </div>
    </>
  )
}
type TProduct = {
  product?: any
  singleProductId?: string
  setSingleProductId?: () => void
  setIndex?: () => void
  index?: number
}
type TData = {
  id?: string
  name?: string
  category?: string
  data?: any
}
export function Product({ product, setIndex, index, singleProductId, setSingleProductId }: TProduct | any) {
  const { id }: TData = product
  const ref = useRef(null)
  const isInView = useInView(ref)
  useEffect(() => {
    if (isInView) {
      setSingleProductId(id)
      setIndex(index)
    } else {
      setSingleProductId(id)
      setIndex(index)
    }
  }, [isInView, id, setSingleProductId])
  const handleClick = () => {
    setSingleProductId(id)
    setIndex(index)
  }
  return (
    <>
      <motion.div initial="offscreen" whileInView="onscreen" viewport={viewport}>
        <motion.div variants={viewVariants} className="flex">
          <div ref={ref} id={id} className="lg:flex lg:space-x-12  ">
            <div className=" mt-1 py-3 lg:py-0 w-[100px]">
              <button
                onClick={handleClick}
                className={`lg:text-lg border  whitespace-nowrap border-black px-[8px] py-px rounded-xl ${
                  singleProductId === id && 'bg-[#20231f] text-white'
                }`}
              >
                <SectionSlatePreview initialValue={product?.title} previewMode />
              </button>
            </div>
            <div>
              <p className="lg:max-w-[474px] lg:text-[18px]">
                <SectionSlatePreview initialValue={product?.description} previewMode />
              </p>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </>
  )
}
