/* eslint-disable react/jsx-no-useless-fragment */
import { useContext } from 'react'
import { UserContext } from 'src/components/context/UserContext'
import Icon from './icons'
import { IconPropsT } from './types'

function Profile(props: IconPropsT) {
  const { title = 'Profile', size, ...otherProps } = props

  const { user } = useContext(UserContext)

  return (
    <>
      {user?.profilePicture ? (
        <img
          src={user?.profilePicture}
          className="w-[30px] h-[30px] rounded-full border-2 border-black dark:border-white"
          alt=""
        />
      ) : (
        <Icon viewBox="0 0 40 40" size={size} title={title} {...otherProps}>
          <circle cx="20" cy="20" r="19" stroke="black" />
        </Icon>
      )}
    </>
  )
}

export default Profile
