import { BsDot } from 'react-icons/bs'
import FontLoader from '../../menu/FontLoader'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../../menu/utils'
import { FooterVariantProps, getSocialLabel, NewFooter, Social } from '../footer'

const SocialItem = ({ type, link, footer }: { type: Social; link: string; footer: NewFooter }) => {
  const label = getSocialLabel(type)

  return (
    <div>
      {label === 'Facebook' ? (
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="cursor-pointer border-b md:text-2xl  hover:text-gray-300 transition-all duration-300 mx-4"
          style={{
            ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
            ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
            borderColor: getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }).color,
          }}
        >
          {label}
        </a>
      ) : (
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="cursor-pointer flex items-center lg:gap-x-2  md:text-2xl  hover:text-gray-300 transition-all duration-300 mx-4"
          style={{
            ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
            ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
          }}
        >
          <BsDot
            className="text-white text-2xl"
            color={getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }).color}
          />
          <span
            className="border-b border-white"
            style={{
              borderColor: getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }).color,
            }}
          >
            {label}
          </span>
        </a>
      )}
    </div>
  )
}

export default function VariantThree({ website, footer }: FooterVariantProps) {
  return (
    <>
      <FontLoader settings={footer?.settings?.fontFamily} />
      <MobileFooter website={website} footer={footer} />
      <DesktopFooter website={website} footer={footer} />
    </>
  )
}
const DesktopFooter = ({ website, footer }: FooterVariantProps) => {
  return (
    <div
      className="text-white hidden lg:block"
      style={{
        ...getBackgroundColor({ settings: footer?.settings?.backgroundColor, defaultValue: '#172d3a' }),
        ...getSpacing(footer?.settings?.spacing),
      }}
    >
      <div className="flex justify-between items-center px-20 py-12">
        <div className="grid grid-cols-1 items-start justify-start gap-y-2">
          <div className="font-bold">
            {website?.config?.logo ? (
              <p
                className="font-serif capitalize text-4xl tracking-wider "
                style={{
                  ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
                  ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
                }}
              >
                {website?.name}
              </p>
            ) : (
              <p
                className="font-serif capitalize text-4xl tracking-wider "
                style={{
                  ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
                  ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
                }}
              >
                {website?.name}
              </p>
            )}
          </div>
          <div
            className="flex gap-x-12"
            style={{
              ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
              ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
            }}
          >
            <span>
              {website?.config?.email ? <a href={website?.config?.email}> {website?.config?.email}</a> : footer?.email}
            </span>
            <p>{website?.config?.phone ? <p>{website?.config?.phone} </p> : footer?.phone}</p>
          </div>
          <div
            className="flex gap-x-2 text-gray-400 tracking-wide"
            style={{
              ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
              ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
            }}
          >
            {website?.config?.copyright ? website?.config?.copyright : footer?.copyright}
          </div>
        </div>
        <div className="flex items-start justify-start">
          {website?.config?.social
            ? Object.keys(website?.config?.social).map(item => (
                <SocialItem footer={footer} type={item as Social} link={website?.config?.social[item]} />
              ))
            : footer?.social?.map(socialType => <SocialItem type={socialType} link="#" footer={footer} />)}
        </div>
      </div>
    </div>
  )
}

const MobileFooter = ({ website, footer }: FooterVariantProps) => {
  return (
    <div
      className="text-white block lg:hidden pt-3"
      style={{
        ...getBackgroundColor({ settings: footer?.settings?.backgroundColor, defaultValue: '#172d3a' }),
        ...getSpacing(footer?.settings?.spacing),
      }}
    >
      <div className="flex flex-col items-center justify-center pt-3 px-2">
        <div className="flex flex-col gap-y-2 items-center justify-center">
          <div className="font-bold">
            {website?.config?.logo ? (
              <p
                className="font-serif capitalize text-2xl tracking-wider  "
                style={{
                  ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
                  ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
                }}
              >
                {website?.name}
              </p>
            ) : (
              <p
                className="font-serif capitalize text-2xl tracking-wider "
                style={{
                  ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
                  ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
                }}
              >
                {website?.name}
              </p>
            )}
          </div>
          <div>
            <div
              className="flex flex-col items-center justify-center "
              style={{
                ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
                ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
              }}
            >
              {website?.config?.email ? <a href={website?.config?.email}> {website?.config?.email}</a> : footer?.email}
              <p>
                {website?.config?.phone ? (
                  <p className="flex items-center">{website?.config?.phone}</p>
                ) : (
                  <p className="flex items-center">{footer?.phone}</p>
                )}
              </p>
            </div>
          </div>
          <div
            className="flex flex-col items-center justify-center "
            style={{
              ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
              ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
            }}
          >
            {website?.config?.social
              ? Object.keys(website?.config?.social).map(item => (
                  <SocialItem footer={footer} type={item as Social} link={website?.config?.social[item]} />
                ))
              : footer?.social?.map(socialType => <SocialItem type={socialType} link="#" footer={footer} />)}
          </div>
          <div
            className="flex flex-col w-full text-center"
            style={{
              ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
              ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
            }}
          >
            <a href="/">{website?.config?.copyright ? website?.config?.copyright : footer?.copyright}</a>
          </div>
        </div>
      </div>
    </div>
  )
}
