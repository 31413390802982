import clsx from 'clsx'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import InputText from '../../../common/InputText'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import TextArea from '../../../common/TextArea'
import { ContactUsSection, InputsContactUSSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import ButtonRenderer from '../ButtonRenderer'

export default function VariantEleven({ section }: { section: ContactUsSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subtitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data
  const inputs = section.variables.find(variant => variant.name === 'INPUTS')?.data as InputsContactUSSection[]
  const button = section.variables.find(variable => variable.name === 'BUTTON_1')?.data

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    setLoading(true)
    try {
    } catch (error) {
      setError('Something went wrong!')
    }
    setLoading(false)
  }
  const inputClasses = {
    classInput:
      'placeholder:text-[12px] md:py-2.5  w-full text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 resize-none appearance-none dark:text-white dark:border-gray-600 placeholder:text-gray-300 focus:outline-none focus:ring-0  peer',
    classLabel: 'font-extralight my-3 md:my-0 text-sm inline-block',
  }

  return (
    <div
      id="contact"
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="hero">
        <div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:px-12 px-4">
            <div className="w-full flex flex-col my-6 py-6 lg:my-0 lg:mt-36 lg:mx-8 gap-4">
              <h1 className="md:text-5xl text-3xl md:font-medium">
                <SectionSlatePreview initialValue={title} previewMode />
              </h1>
              <p className="md:text-xl text-[14px] text-[#B37733]">
                <SectionSlatePreview initialValue={subtitle} previewMode />
              </p>

              {inputs && (
                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col items-start">
                  <div className="grid md:grid-cols-2 md:gap-8 grid-cols-1 w-full">
                    {inputs.map(item => {
                      return (
                        <div key={item.id}>
                          {item.type !== 'button' && item.type !== 'textarea' && (
                            <InputText
                              {...inputClasses}
                              label={item.name}
                              name={item.name}
                              register={register}
                              type={item.type}
                              errors={errors}
                              placeholder={item.placeholder}
                              required={{ value: true, message: `${item.error}` }}
                            />
                          )}

                          {item.type === 'button' && (
                            <div>
                              <input
                                type={item.type}
                                value={item.name}
                                className="px-5 py-3 font-medium bg-black text-white cursor-pointer"
                              />
                            </div>
                          )}
                          {item.type === 'textarea' && (
                            <TextArea
                              placeholder={item.placeholder}
                              {...inputClasses}
                              label={item.name}
                              name={item.name}
                              register={register}
                              type={item.type}
                              errors={errors}
                              required={{ value: true, message: `${item.error}` }}
                            />
                          )}
                        </div>
                      )
                    })}
                  </div>
                  {button ? (
                    <ButtonRenderer button={button} typeOfButton="submit" withOutHref />
                  ) : (
                    <button
                      disabled={loading}
                      className={clsx(
                        loading && 'cursor-not-allowed disabled:opacity-50',
                        'bg-[#B37733] md:w-[45%] mt-8 text-neutral p-3 text-white hover:bg-opacity-0 hover:text-black hover:border border border-[#B37733]',
                      )}
                      type="submit"
                    >
                      Submit
                    </button>
                  )}
                  {error && <span className="text-secondary.main text-center mb-4">{error}</span>}
                </form>
              )}
            </div>
            <div className="lg:p-10 p-4 h-[300px] md:h-[850px] hidden md:block">
              <img src={media} className="w-full h-full object-cover " />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
