import Create from 'src/icons/Create'
import ColorModeSwitcher from '../../components/ColorModeSwitcher'
import AccountItem from './AccountItem'
import CreateItem from './CreateItem'
import SiteItem from './SiteItem'

function ProfileModal({
  setSettingModalOpen,
  onMobileSetting,
  setCreateImageModalOpen,
  onCreateImage,
  setCreatePostModalOpen,
  onCreatePost,
}: any) {
  const onManageModal = () => {
    if (setSettingModalOpen) setSettingModalOpen(true)
    if (onMobileSetting) onMobileSetting()
  }
  const CreateImageModal = () => {
    if (setCreateImageModalOpen) setCreateImageModalOpen(true)
    if (onCreateImage) onCreateImage()
  }
  const CreatePostModal = () => {
    if (setCreatePostModalOpen) setCreatePostModalOpen(true)
    if (onCreatePost) onCreatePost()
  }
  return (
    <div className="md:w-[320px] h-full pb-12 md:pb-8 px-4  md:py-3  md:mt-7 md:px-[50px] bg-[#FFFFFF] dark:bg-[#000000] md:border-4 border-[#4E4E4E] dark:text-white rounded-lg text-center">
      <div className="flex justify-end my-4 md:mb-3 md:-mr-4 -ml-2 md:ml-0">
        <ColorModeSwitcher />
      </div>
      <SiteItem />
      <AccountItem onManageModal={onManageModal} />
      <div className="flex justify-start md:space-x-2 space-x-[23px] py-2">
        <button type="button">
          <Create size={22} className="text-brand-accent dark:text-brand-accent hidden md:block" />
          <Create size={40} className="text-brand-accent dark:text-brand-accent md:hidden" />
        </button>
        <h1 className="text-[40px] md:text-[20px]">Create</h1>
      </div>
      <div>
        <CreateItem onCreateImage={CreateImageModal} onCreatePost={CreatePostModal} />
      </div>
    </div>
  )
}

export default ProfileModal
