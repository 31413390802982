import create from 'zustand'

type ProfileStore = {
  posts: any[]
  setPosts: (posts: any[]) => void
  addPost: (post: any) => void
}

const useProfileStore = create<ProfileStore>(
  (set): ProfileStore => ({
    posts: [],
    setPosts: (posts: any[]) => set(state => ({ ...state, posts })),
    addPost: (post: any) => set(state => ({ ...state, posts: [post, ...state.posts] })),
  }),
)

export default useProfileStore
