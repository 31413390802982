import Icon from './icons'
import { IconPropsT } from './types'

function Send(props: IconPropsT) {
  const { title = 'Send', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 38 37" size={size} title={title} {...otherProps}>
      <g clipPath="url(#clip0_274_13985)">
        <path
          d="M35.153 0.781279C34.3863 0.499889 33.5469 0.485366 32.7709 0.740062L3.88129 10.2318C3.18593 10.4599 2.57609 10.893 2.1314 11.4742C1.68671 12.0554 1.42829 12.7576 1.38995 13.4884C1.35161 14.2192 1.53497 14.9446 1.91641 15.5691C2.29785 16.1937 2.85939 16.6882 3.52707 16.9879L15.2161 22.2381L20.0593 34.1033C20.3356 34.7813 20.8106 35.3599 21.4218 35.7631C22.033 36.1662 22.7523 36.375 23.4844 36.3621C24.2165 36.3491 24.9273 36.115 25.5239 35.6905C26.1205 35.2661 26.5748 34.6711 26.827 33.9837L37.3091 5.43643C37.4731 4.98914 37.5475 4.51394 37.5279 4.0379C37.5083 3.56185 37.3954 3.09431 37.1951 2.66201C36.9948 2.22972 36.711 1.8411 36.3604 1.5184C36.0099 1.1957 35.5995 0.945231 35.1522 0.781264L35.153 0.781279ZM24.5548 33.1479C24.4707 33.3769 24.3192 33.5752 24.1204 33.7167C23.9216 33.8581 23.6846 33.9361 23.4406 33.9404C23.1966 33.9447 22.9573 33.8751 22.7536 33.7408C22.5499 33.6064 22.3914 33.4137 22.2993 33.1877L17.5795 21.6351L34.9407 4.86364L24.5548 33.1479ZM15.9011 19.8944L4.51876 14.7775C4.2968 14.677 4.11036 14.5118 3.98397 14.3035C3.85758 14.0951 3.79703 13.8535 3.81041 13.6102C3.82379 13.3669 3.91045 13.1333 4.05892 12.9401C4.20739 12.7469 4.41095 12.6031 4.6426 12.5275L33.2665 3.11954L15.9053 19.891L15.9011 19.8944Z"
          // fill="#D9D9D9"
        />
      </g>
      <defs>
        <clipPath id="clip0_274_13985">
          <rect width="35.9791" height="35.9808" fill="white" transform="translate(1.62109) rotate(0.991262)" />
        </clipPath>
      </defs>
    </Icon>
  )
}

export default Send
