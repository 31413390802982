import { Descendant } from 'slate'
import SectionSlatePreview from '../../../common/SectionSlatePreview'

export default function FAQTextItem({
  icon,
  title,
  description,
  whiteFont,
}: {
  icon?: number
  title: Descendant[]
  description: Descendant[]
  whiteFont?: boolean
}) {
  const getIcon = (icon: number | undefined) => {
    const iconClasses = `w-20 h-20 fill-${whiteFont ? 'white' : 'dark'}`

    switch (icon) {
      case 0:
        return (
          <svg
            className={iconClasses}
            width="700pt"
            height="700pt"
            version="1.1"
            viewBox="0 0 700 700"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path d="m336.56 293.44c0.11328 8.8477-3.4727 16.129-7.0547 19.711-32.48 32.367-79.406 7.168-100.91-25.312-7.2812-14.449-14.449-32.48-18.145-50.398-3.5859-28.895 7.168-54.098 14.336-78.398 5.1523-12.098 6.832-19.043 9.8555-26.098 2.3516-6.3828 6.1602-12.879 11.535-19.375 0 0-69.777 32.367-63.281 72.688 0.67188 4.4805 3.0234 10.078 6.832 16.688-7.9531 66.863 38.863 153.44 114.57 142.57 10.754-3.5859 21.617-3.5859 30.129-15.68 8.7383-11.426 8.8516-24.191 2.1289-36.398z" />
              <path d="m347.43 299.71c25.312 31.359-14.336 63.84-43.121 67.426-14.449 0-28.895 3.6953-39.648 0-21.727-10.754-43.344-21.617-61.375-43.234-10.863-18.031-21.617-32.48-25.871-52.414-3.9219-13.887-7.0586-20.273-9.4102-27.777-2.2383-6.3828-3.5859-13.773-3.6953-22.176 0 0-32.48 69.664-1.457 96.32 3.3594 2.9102 8.8477 5.8242 17.809 14.449 40.656 56.449 141.57 88.816 184.8 16.801 3.5859-7.168 3.5859-14.449 4.8164-20.832-0.89844-14.113-9.4102-23.746-22.848-28.562z" />
              <path d="m359.97 297.36c2.0156 1.2305 9.1836 1.2305 12.77 4.8164 36.062 28.785 10.754 72.129-18.031 93.742-25.199 14.449-54.098 18.031-82.879 14.449-21.73-3.582-39.652-10.75-56.898-22.062-12.098-8.2891-19.715-12.543-26.32-16.914-5.9375-3.3594-11.648-8.1758-17.137-14.559 0 0 20.047 74.145 61.039 74.703 4.4805 0 10.414-1.2305 23.742 0 69.664 11.426 163.41-24.641 152.54-107.52-3.582-7.2812-10.859-14.559-12.43-20.16-10.305-9.9688-23.07-11.758-36.398-6.4961z" />
              <path d="m367.7 287.39c30.238-17.586 59.137 14.785 62.719 43.68 3.5859 36.066-14.445 68.547-43.23 93.746-10.863 10.863-25.312 14.449-39.648 21.617-10.754 3.5859-18.031 3.5859-29.121 5.2656-14.781 1.7891-24.191 3.1328-31.805 4.1406-6.7188 1.2305-14.113 1.2305-22.512-0.11328 0 0 63.055 43.793 94.754 17.809 3.3555-2.9102 7.0508-7.7266 18.027-15.457 64.398-33.152 107.63-123.43 46.367-181.11-7.2773-7.1641-14.445-10.75-22.957-8.0625-14.672-1.0078-25.875 6.0508-32.594 18.48z" />
              <path d="m367.14 274.96c23.633-37.52 74.145-23.184 88.48 9.2969 7.168 14.449 18.031 25.312 18.031 39.648 3.6953 25.199-3.5859 46.816-10.863 72.129-3.4727 18.027-21.617 21.613-26.211 37.965-9.8555 10.641-15.344 17.473-20.719 23.297-4.3672 5.2656-10.078 10.078-17.246 14.449 0 0 76.496-7.168 83.887-47.488 0.78516-4.4805 0.44922-10.531 3.0234-22.625 24.191-56.113 9.7461-142.69-55.105-164.19-18.031-3.6992-36.062-10.867-49.168 2.6875-12.316 8.5117-16.688 21.055-14.109 34.832z" />
              <path d="m358.85 266c-7.7266-39.426 35.504-61.039 67.984-53.871 21.617 7.168 43.23 18.031 57.68 36.062 10.863 14.449 18.031 28.785 21.617 43.23 7.168 18.031 14.449 36.062 9.6328 54.32-0.67188 14.223 0.22266 21.281-0.11328 29.23 0 6.7188-1.1211 14.113-3.9219 22.176 0 0 53.762-54.879 33.488-90.383-2.2383-3.9219-6.3828-8.1758-12.207-13.105-16.016-70.672-102.59-135.52-171.02-85.121-7.168 7.168-10.863 14.449-13.441 21.281-4.3672 14.453-0.22266 26.996 10.305 36.18z" />
              <path d="m346.86 264.32c-28.223-19.711-10.191-63.055 11.426-77.391 18.031-14.449 39.648-21.617 61.375-18.035 17.922 3.5859 35.953 7.168 50.398 14.336 18.035 14.562 39.648 21.73 50.625 43.344 8.0625 10.078 13.441 14.895 18.258 21.168 4.3672 5.1523 8.1758 11.539 11.312 19.488 0 0 5.8242-76.609-32.703-90.832-4.1445-1.5664-10.078-2.1289-17.695-2.0156-51.41-30.688-127.12-41.551-170.36 8.8477-10.863 18.035-18.031 36.066-12.992 59.473 5.4883 13.891 16.578 21.059 30.352 21.617z" />
              <path d="m336.45 270.59c-39.426-0.67188-53.871-47.602-35.84-76.496 10.863-14.336 18.031-32.367 32.48-43.23 14.449-10.863 32.48-18.031 50.512-21.617 21.617 0 43.344-3.5859 64.398 2.1289 11.871 2.2383 19.039 2.4648 26.77 4.1445 6.7188 1.1211 13.777 3.5859 21.168 7.6172 0 0-44.91-62.383-83.441-48.383-4.1445 1.4492-9.0703 4.9219-14.785 9.8516-68.207 6.6055-147.61 75.039-115.14 150.75 3.6953 7.168 10.863 14.449 17.246 17.922 12.883 7.168 25.766 5.5977 36.629-2.6875z" />
              <path d="m332.41 282.13c-2.9102 5.7109-10.191 5.7109-17.359 9.2969-36.062 3.582-57.68-25.203-61.262-57.684-3.6953-28.781 3.582-54.094 18.031-79.293 14.449-21.617 36.062-36.062 58.352-49.504 10.527-6.0469 16.238-10.414 23.184-14 5.8242-3.4727 12.879-6.1602 21.055-7.8398 0 0-74.48-18.703-94.977 16.688-2.2383 4.0312-3.8047 9.7422-4.9258 17.246-53.199 48.273-64.062 152.77 8.0625 188.83 7.2812 3.5859 18.031 3.5859 24.414 2.2383 14.117-2.9102 22.629-12.43 25.426-25.98z" />
            </g>
          </svg>
        )
      case 1:
        return (
          <svg
            className={iconClasses}
            width="700pt"
            height="700pt"
            version="1.1"
            viewBox="0 0 700 700"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path d="m193.54 307.21c-8.3984 1.9062-16.465 3.1367-24.414 3.8086 0.89453 5.1523 2.2383 10.078 3.4727 15.121 15.117 4.9258 34.047 4.7031 55.551-0.89844 36.734-9.5195 75.262-33.039 108.41-66.191 33.152-33.152 56.672-71.793 66.191-108.41 5.4883-21.504 5.7109-40.434 0.89453-55.551-4.9258-1.3477-9.9648-2.5781-15.117-3.4727-0.67188 7.8398-1.9023 16.016-3.8086 24.414-9.6289 41.777-34.719 84.672-70.559 120.51-35.84 35.84-78.734 61.043-120.62 70.672z" />
              <path d="m168.34 245.95c-1.457 9.4062-2.3516 18.816-2.4648 28.336 6.1602-0.44922 12.656-1.457 19.375-3.0234 35.168-8.1758 71.68-29.68 102.7-60.703 31.023-31.023 52.641-67.648 60.703-102.7 1.5664-6.7188 2.4648-13.215 3.0234-19.375-9.4062 0-18.93 0.89453-28.223 2.3516-9.9648 34.156-30.461 67.867-58.797 96.203-28.449 28.449-62.16 48.945-96.32 58.914z" />
              <path d="m337.01 471.41c51.07 2.4648 103.04-15.789 142.02-54.766 38.977-39.09 57.344-90.945 54.77-142.13-15.344 40.32-42.895 81.535-79.184 117.71-36.066 36.176-77.281 63.84-117.6 79.184z" />
              <path d="m281.46 102.7c-22.066 9.1836-42.672 22.738-60.594 40.656-17.922 17.922-31.359 38.527-40.543 60.594 20.945-9.5195 41.328-23.855 59.359-41.887 18.031-18.035 32.258-38.418 41.777-59.363z" />
              <path d="m278.21 421.12c-11.758 3.582-23.07 5.8242-33.824 7.0547 16.242 10.863 41.891 12.098 72.242 3.0234 39.086-11.648 80.75-38.305 117.38-74.816 36.512-36.512 63.055-78.176 74.703-117.38 9.0703-30.465 7.8398-56.223-3.1367-72.352-5.5977 52.191-37.965 114.35-92.512 168.89-41.328 41.328-89.262 71.793-134.85 85.57z" />
              <path d="m439.6 160.05c-11.09 43.234-38.195 87.922-76.051 125.89-37.969 37.855-82.656 64.961-125.89 76.16-17.023 4.3672-33.152 6.1602-48.047 5.375 3.0234 5.375 6.3828 10.641 9.8555 15.793 16.238 7.9531 39.762 7.9531 67.199-0.22266 39.426-11.871 81.426-38.754 118.16-75.602 36.961-36.848 63.727-78.848 75.602-118.27 8.2891-27.441 8.2891-50.961 0.33594-67.199-5.1523-3.5859-10.418-6.9453-15.793-9.9688 0.78516 14.895-1.0078 31.137-5.375 48.047z" />
            </g>
          </svg>
        )
      case 2:
        return (
          <svg
            className={iconClasses}
            width="700pt"
            height="700pt"
            version="1.1"
            viewBox="0 0 700 700"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path d="m487.31 207.76c-32.031 30.461-76.609 42.781-119.28 48.941-38.078 5.6016-77.84 7.9531-111.89 22.734-35.617 15.57-58.016 41.332-56.223 81.203 1.1211 23.969 14 47.375 32.145 65.406 5.0391 4.0312 10.191 7.8398 15.566 11.312-0.33594-0.67188-0.67187-1.457-1.2344-2.1289-21.613-29.68-38.414-114.69 74.707-133.17 22.734-3.6953 45.469-7.5039 68.094-12.656 19.938-4.5938 55.551-15.793 59.473-17.922 3.2461-1.9023-21.168 25.535-95.984 47.039-118.83 34.164-82.32 132.84-46.031 144.15 13.887 3.3594 28.449 5.0391 43.344 5.0391 103.71 0 187.82-84 187.82-187.71 0-37.297-10.863-72.016-29.566-101.14-5.043 10.977-12.098 20.383-20.945 28.898z" />
              <path d="m162.29 280c0 20.609 3.3594 40.207 9.4062 58.801 2.2383-20.383 9.1836-39.648 22.176-56 23.52-29.902 60.031-43.902 98-51.406 37.855-7.3945 78.176-8.2891 117.04-17.699 21.504-5.1523 43.344-13.551 60.703-27.328 14.449-11.648 21.172-29.344 6.1641-45.809-5.4883-4.9258-11.312-9.6289-17.363-13.887 10.977 22.512 2.4648 56-86.574 64.734-22.848 2.3555-45.582 4.4805-68.098 7.6172-6.6055 0.89844-14.781 2.1289-23.184 3.5859 0 0 7.3906-9.1836 59.586-21.055 107.3-24.191 87.246-70.895 55.441-83.777-14.559-3.5859-29.902-5.6016-45.586-5.6016-103.71 0.11328-187.71 84.113-187.71 187.82z" />
            </g>
          </svg>
        )
      case 3:
        return (
          <svg
            className={iconClasses}
            width="700pt"
            height="700pt"
            version="1.1"
            viewBox="0 0 700 700"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m436.8 190.29c11.871 66.305-49.84 91.613-108.98 53.086-83.777-54.543-152.54-42.223-163.75 10.863-1.1172 8.4023-1.7891 17.027-1.7891 25.762 0 103.71 84 187.82 187.71 187.82 48.16 0 91.953-18.145 125.21-47.938-10.078 6.9453-21.84 12.543-35.168 16.801-82.766 26.207-169.12-20.16-177.18-65.07-11.871-66.305 49.84-91.617 109.09-52.977 84.336 54.879 153.44 42 163.97-11.871 1.2305-8.8477 1.9023-17.699 1.9023-26.77 0-103.71-84.113-187.82-187.82-187.82-51.633 0-98.449 20.945-132.38 54.656 11.648-8.9609 25.648-16.465 42-21.727 82.766-26.098 169.12 20.27 177.18 65.184z" />
          </svg>
        )
      case 4:
        return (
          <svg
            className={iconClasses}
            width="700pt"
            height="700pt"
            version="1.1"
            viewBox="0 0 700 700"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path d="m336.56 293.44c0.11328 8.8477-3.4727 16.129-7.0547 19.711-32.48 32.367-79.406 7.168-100.91-25.312-7.2812-14.449-14.449-32.48-18.145-50.398-3.5859-28.895 7.168-54.098 14.336-78.398 5.1523-12.098 6.832-19.043 9.8555-26.098 2.3516-6.3828 6.1602-12.879 11.535-19.375 0 0-69.777 32.367-63.281 72.688 0.67188 4.4805 3.0234 10.078 6.832 16.688-7.9531 66.863 38.863 153.44 114.57 142.57 10.754-3.5859 21.617-3.5859 30.129-15.68 8.7383-11.426 8.8516-24.191 2.1289-36.398z" />
              <path d="m347.43 299.71c25.312 31.359-14.336 63.84-43.121 67.426-14.449 0-28.895 3.6953-39.648 0-21.727-10.754-43.344-21.617-61.375-43.234-10.863-18.031-21.617-32.48-25.871-52.414-3.9219-13.887-7.0586-20.273-9.4102-27.777-2.2383-6.3828-3.5859-13.773-3.6953-22.176 0 0-32.48 69.664-1.457 96.32 3.3594 2.9102 8.8477 5.8242 17.809 14.449 40.656 56.449 141.57 88.816 184.8 16.801 3.5859-7.168 3.5859-14.449 4.8164-20.832-0.89844-14.113-9.4102-23.746-22.848-28.562z" />
              <path d="m359.97 297.36c2.0156 1.2305 9.1836 1.2305 12.77 4.8164 36.062 28.785 10.754 72.129-18.031 93.742-25.199 14.449-54.098 18.031-82.879 14.449-21.73-3.582-39.652-10.75-56.898-22.062-12.098-8.2891-19.715-12.543-26.32-16.914-5.9375-3.3594-11.648-8.1758-17.137-14.559 0 0 20.047 74.145 61.039 74.703 4.4805 0 10.414-1.2305 23.742 0 69.664 11.426 163.41-24.641 152.54-107.52-3.582-7.2812-10.859-14.559-12.43-20.16-10.305-9.9688-23.07-11.758-36.398-6.4961z" />
              <path d="m367.7 287.39c30.238-17.586 59.137 14.785 62.719 43.68 3.5859 36.066-14.445 68.547-43.23 93.746-10.863 10.863-25.312 14.449-39.648 21.617-10.754 3.5859-18.031 3.5859-29.121 5.2656-14.781 1.7891-24.191 3.1328-31.805 4.1406-6.7188 1.2305-14.113 1.2305-22.512-0.11328 0 0 63.055 43.793 94.754 17.809 3.3555-2.9102 7.0508-7.7266 18.027-15.457 64.398-33.152 107.63-123.43 46.367-181.11-7.2773-7.1641-14.445-10.75-22.957-8.0625-14.672-1.0078-25.875 6.0508-32.594 18.48z" />
              <path d="m367.14 274.96c23.633-37.52 74.145-23.184 88.48 9.2969 7.168 14.449 18.031 25.312 18.031 39.648 3.6953 25.199-3.5859 46.816-10.863 72.129-3.4727 18.027-21.617 21.613-26.211 37.965-9.8555 10.641-15.344 17.473-20.719 23.297-4.3672 5.2656-10.078 10.078-17.246 14.449 0 0 76.496-7.168 83.887-47.488 0.78516-4.4805 0.44922-10.531 3.0234-22.625 24.191-56.113 9.7461-142.69-55.105-164.19-18.031-3.6992-36.062-10.867-49.168 2.6875-12.316 8.5117-16.688 21.055-14.109 34.832z" />
              <path d="m358.85 266c-7.7266-39.426 35.504-61.039 67.984-53.871 21.617 7.168 43.23 18.031 57.68 36.062 10.863 14.449 18.031 28.785 21.617 43.23 7.168 18.031 14.449 36.062 9.6328 54.32-0.67188 14.223 0.22266 21.281-0.11328 29.23 0 6.7188-1.1211 14.113-3.9219 22.176 0 0 53.762-54.879 33.488-90.383-2.2383-3.9219-6.3828-8.1758-12.207-13.105-16.016-70.672-102.59-135.52-171.02-85.121-7.168 7.168-10.863 14.449-13.441 21.281-4.3672 14.453-0.22266 26.996 10.305 36.18z" />
              <path d="m346.86 264.32c-28.223-19.711-10.191-63.055 11.426-77.391 18.031-14.449 39.648-21.617 61.375-18.035 17.922 3.5859 35.953 7.168 50.398 14.336 18.035 14.562 39.648 21.73 50.625 43.344 8.0625 10.078 13.441 14.895 18.258 21.168 4.3672 5.1523 8.1758 11.539 11.312 19.488 0 0 5.8242-76.609-32.703-90.832-4.1445-1.5664-10.078-2.1289-17.695-2.0156-51.41-30.688-127.12-41.551-170.36 8.8477-10.863 18.035-18.031 36.066-12.992 59.473 5.4883 13.891 16.578 21.059 30.352 21.617z" />
              <path d="m336.45 270.59c-39.426-0.67188-53.871-47.602-35.84-76.496 10.863-14.336 18.031-32.367 32.48-43.23 14.449-10.863 32.48-18.031 50.512-21.617 21.617 0 43.344-3.5859 64.398 2.1289 11.871 2.2383 19.039 2.4648 26.77 4.1445 6.7188 1.1211 13.777 3.5859 21.168 7.6172 0 0-44.91-62.383-83.441-48.383-4.1445 1.4492-9.0703 4.9219-14.785 9.8516-68.207 6.6055-147.61 75.039-115.14 150.75 3.6953 7.168 10.863 14.449 17.246 17.922 12.883 7.168 25.766 5.5977 36.629-2.6875z" />
              <path d="m332.41 282.13c-2.9102 5.7109-10.191 5.7109-17.359 9.2969-36.062 3.582-57.68-25.203-61.262-57.684-3.6953-28.781 3.582-54.094 18.031-79.293 14.449-21.617 36.062-36.062 58.352-49.504 10.527-6.0469 16.238-10.414 23.184-14 5.8242-3.4727 12.879-6.1602 21.055-7.8398 0 0-74.48-18.703-94.977 16.688-2.2383 4.0312-3.8047 9.7422-4.9258 17.246-53.199 48.273-64.062 152.77 8.0625 188.83 7.2812 3.5859 18.031 3.5859 24.414 2.2383 14.117-2.9102 22.629-12.43 25.426-25.98z" />
            </g>
          </svg>
        )

      default:
        return null
    }
  }

  return (
    <div className="flex flex-col items-center justify-center gap-5 text-center">
      {getIcon(icon)}
      {title && (
        <p className={`${whiteFont ? 'text-white' : ''} text-[45px]`}>
          <SectionSlatePreview initialValue={title} previewMode />
        </p>
      )}
      <p className={`${whiteFont ? 'text-white' : ''}`}>
        <SectionSlatePreview initialValue={description} previewMode />
      </p>
    </div>
  )
}
