import { BsArrowRight } from 'react-icons/bs'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { ContactUsSection, InputsContactUSSection } from '../../types'
import { generateSectionBackground } from '../../utils'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import InputText from '../../../common/InputText'
import TextArea from '../../../common/TextArea'
import ButtonRenderer from '../ButtonRenderer'

export default function VariantFifteen({ section }: { section: ContactUsSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data
  const inputs = section.variables.find(variant => variant.name === 'INPUTS')?.data as InputsContactUSSection[]
  const button = section.variables.find(variant => variant.name === 'BUTTON_1')?.data
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const [error, setError] = useState('')

  const onSubmit = async () => {
    try {
    } catch (error) {
      setError('Something went wrong!')
    }
  }

  const inputClasses = {
    classInput:
      'block w-full py-3 md:py-8 placeholder:text-[16px] px-3 text-[24px] text-gray-900 border border-gray-300  focus:outline-0 relative',
    classLabel: 'text-sm inline-block mb-1 ',
  }

  return (
    <div
      style={{
        ...generateSectionBackground({
          defaultColor: '#f2f2f2',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
      }}
      className="grid md:grid-cols-2 "
    >
      <div>
        <img className="w-full h-full" src={media} alt="" />
      </div>
      <div className="relative md:flex p-5 md:py-12 justify-center items-center">
        <div className="md:w-[730px]">
          <div className="md:p-12 md:py-0 md:space-y-10">
            <h2 className="text-[26px] md:text-[42px] font-bold uppercase text-[#638a49]">
              <SectionSlatePreview initialValue={title} previewMode />
            </h2>
            <p className="text-[16px] md:text-[30px]">
              <SectionSlatePreview initialValue={paragraph} previewMode />
            </p>
            <div className="relative">
              {/* <input
                type="email"
                className="block w-full px-6 py-8 text-[24px] text-gray-900 border border-gray-300  focus:outline-0"
                placeholder="Enter Email..."
                required
              /> */}

              {inputs && (
                <form className="flex flex-col gap-5" onSubmit={handleSubmit(onSubmit)}>
                  {inputs.map(item => {
                    return (
                      <div key={item.id}>
                        {item.type !== 'button' && item.type !== 'textarea' && (
                          <InputText
                            {...inputClasses}
                            label={item.name}
                            name={item.name}
                            register={register}
                            type={item.type}
                            errors={errors}
                            required={{ value: true, message: `${item.error}` }}
                            placeholder={item.placeholder}
                          />
                        )}

                        {item.type === 'button' && (
                          <div>
                            <input
                              type={item.type}
                              value={item.name}
                              // placeholder={item.placeholder}
                              className="w-full px-5 py-[13.5px] text-sm bg-black text-white cursor-pointer"
                            />
                          </div>
                        )}
                        {item.type === 'textarea' && (
                          <TextArea
                            {...inputClasses}
                            label={item.name}
                            name={item.name}
                            register={register}
                            type={item.type}
                            errors={errors}
                            placeholder={item.placeholder}
                            required={{ value: true, message: `${item.error}` }}
                          />
                        )}
                      </div>
                    )
                  })}
                  {error && <span className="text-center font-semibold">{error}</span>}
                  <div>
                    {button ? (
                      <ButtonRenderer button={button} typeOfButton="submit" withOutHref />
                    ) : (
                      <button
                        type="submit"
                        className="absolute cursor-pointer inset-y-0 right-0 flex items-center mt-1 md:mt-0 md:py-4 pr-6 z-50"
                      >
                        <BsArrowRight size={30} />
                      </button>
                    )}
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
