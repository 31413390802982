import { motion } from 'framer-motion'
import { useState } from 'react'
import { BsCircleFill } from 'react-icons/bs'
import FontLoader from '../FontLoader'
import { MenuVariantProps, NewMenuLinkType } from '../menu'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../utils'
export default function MenuVariantTen({ menu, website }: MenuVariantProps) {
  return (
    <>
      <FontLoader settings={menu?.settings?.fontFamily} />
      <DesktopNavbar menu={menu} website={website} />
      <MobileNavbar menu={menu} website={website} />
    </>
  )
}
const middleLine = {
  default: {
    x: -500,
    transition: {
      duration: 0.5,
    },
  },
  hover: {
    x: 0,

    transition: {
      duration: 0.5,
    },
  },
}
const DesktopNavbar = ({ menu, website }: MenuVariantProps) => {
  return (
    <div
      className="bg-black hidden lg:block"
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'black' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className="flex px-12 mx-auto justify-between items-center text-xl py-6 text-[silver] ">
        <div>
          <a href="/">
            {website?.config?.logo ? (
              <img
                src={website?.config?.logo}
                alt={website?.name}
                className="cursor-pointer w-[80px] h-[80px] rounded-full"
              />
            ) : (
              <p
                className="text-xl capitalize cursor-pointer font-semibold hover:text-gray-50 transition-all duration-300 "
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#c9c9c9' }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                }}
              >
                {website?.name}
              </p>
            )}
          </a>
        </div>
        <div className="flex gap-3 items-center">
          <span>
            <BsCircleFill
              className="text-sm text-gray-400 "
              color={getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#c9c9c9' }).color}
            />
          </span>
          <ul className="list-none flex  gap-x-8 items-center">
            {menu.links.map(({ id, name, url, type }) => {
              return (
                <motion.li
                  initial="default"
                  whileHover="hover"
                  animate="default"
                  className="cursor-pointer relative m-[0 auto] grid  items-center overflow-hidden text-xl"
                  key={id}
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#c9c9c9' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  <a
                    href={url}
                    target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                    className="tracking-wide"
                  >
                    {name}
                  </a>
                  <motion.div
                    variants={middleLine}
                    className="h-[2px] relative -top-[1px] left-0 w-[100%] z-1 mt-[-23px] bg-[white] rounded-lg"
                    style={{
                      background: getTextColor({ settings: menu?.settings?.textColor, defaultValue: '#c9c9c9' }).color,
                    }}
                  />
                </motion.li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

const MobileNavbar = ({ menu, website }: MenuVariantProps) => {
  const [show, setShow] = useState(false)
  return (
    <motion.div
      className="block lg:hidden bg-black"
      style={{
        ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'black' }),
        ...getSpacing(menu?.settings?.spacing),
      }}
    >
      <div className="py-4  text-[silver] hover:text-gray-50 transition-all duration-300 flex justify-between px-4 items-center ">
        <div>
          <a href="/">
            {website?.config?.logo ? (
              <img
                className="cursor-pointer rounded-full h-[30px] w-[30px]"
                src={website?.config?.logo}
                alt={website?.name}
              />
            ) : (
              <p
                className="capitalize font-semibold"
                style={{
                  ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'silver' }),
                  ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                }}
              >
                {website?.name}
              </p>
            )}
          </a>
        </div>

        {show ? (
          <button
            className="flex flex-col cursor-pointer top-0 right-0 z-20 relative w-10 h-10 focus:outline-none"
            onClick={() => setShow(false)}
            style={{
              ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }),
              ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
            }}
          >
            <div className="absolute w-5 transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
              <span
                className={`absolute h-0.5 w-5 transform transition duration-300 ease-in-out ${
                  show ? 'rotate-45 delay-200' : '-translate-y-1.5'
                }`}
                style={{
                  background: getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }).color,
                }}
              ></span>

              <span
                className={`absolute h-0.5 w-5 transform transition duration-300 ease-in-out ${
                  show ? '-rotate-45 delay-200' : 'translate-y-1.5'
                }`}
                style={{
                  background: getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }).color,
                }}
              ></span>
            </div>
          </button>
        ) : (
          <button
            className="top-0 right-0 z-20 relative w-10 h-10 cursor-pointer"
            onClick={() => setShow(true)}
            style={{
              ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }),
              ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
            }}
          >
            <div className="absolute w-5 transform -translate-x-1/2 -translate-y-1/2 left-1/2 ">
              <span
                className={`absolute h-0.5 rounded-full w-5  transform transition duration-300 ease-in-out ${
                  show ? 'rotate-45 delay-200' : '-translate-y-1.5'
                }`}
                style={{
                  background: getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }).color,
                }}
              ></span>

              <span
                className={`absolute h-0.5 -top-1 rounded-full w-5 transform transition duration-300 ease-in-out ${
                  show ? '-rotate-45 delay-200' : 'translate-y-1.5'
                }`}
                style={{
                  background: getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'white' }).color,
                }}
              ></span>
            </div>
          </button>
        )}
      </div>

      {show && (
        <div className="px-4 pb-6 flex flex-col items-center justify-center">
          <motion.ul
            animate={{ y: show ? [-140, 0] : -140 }}
            transition={{ duration: 1, type: 'spring' }}
            className="text-[silver] flex flex-col gap-y-2 bg-black"
            style={{
              ...getBackgroundColor({ settings: menu?.settings?.backgroundColor, defaultValue: 'black' }),
              ...getSpacing(menu?.settings?.spacing),
            }}
          >
            {menu.links.map(({ id, name, url, type }) => {
              return (
                <li
                  className="cursor-pointer text-center relative grid items-center overflow-hidden "
                  key={id}
                  style={{
                    ...getTextColor({ settings: menu?.settings?.textColor, defaultValue: 'silver' }),
                    ...getFontFamily({ settings: menu?.settings?.fontFamily, defaultValue: 'sans-serif' }),
                  }}
                >
                  <a
                    href={url}
                    target={type === NewMenuLinkType.EXTERNAL ? '_blank' : '_self'}
                    className="tracking-wide "
                  >
                    {name}
                  </a>
                </li>
              )
            })}
          </motion.ul>
        </div>
      )}
    </motion.div>
  )
}
