import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import AuthenticatedPage from 'src/components/AuthenticatedPage'
import { WebsiteContextProvider } from 'src/components/context/WebsiteContext'
import HeaderNav from 'src/components/HeaderNav'
import BuildModal from 'src/components/modal/BuildModal'
import ComponentList from 'src/components/new/ComponentList'
import { ReactComponent as RocketIco } from 'src/assets/icons/builder/rocket.svg'
import { ReactComponent as SuccessPhoneIco } from 'src/assets/icons/builder/success-phone.svg'
import { ReactComponent as SuccessPCIco } from 'src/assets/icons/builder/success-pc.svg'
import { getWebsiteByID } from 'src/lib/services/website.service'
import BottomFloatingButton from '../../components/BottomFloatingButton'
import PreviewLayout from '../../components/layout/PreviewLayout'

export default function Websites() {
  const params = useParams()
  const websiteID = params.id as string
  const pagePath = params['*'] as string

  const [showBuildModal, setShowBuildModal] = useState(false)
  const [logo, setLogo] = useState()
  const [flipped, setFlipped] = useState(false)
  const [showBuildStart, setShowBuildStart] = useState(false)
  const [showBuildSuccess, setShowBuildSuccess] = useState(false)
  const [siteUrl, setSiteUrl] = useState('')

  useEffect(() => {
    getWebsiteByID(websiteID).then(data => {
      const config = JSON.parse(data?.config || '{}')
      setLogo(config?.logo || undefined)
    })
  }, [websiteID])

  return (
    <AuthenticatedPage>
      <WebsiteContextProvider websiteID={websiteID} pagePath={pagePath}>
        <PreviewLayout>
          <HeaderNav backButton logo={logo} />
          <div>
            <ComponentList flipped={flipped} />
          </div>
          <div className="flex ">
            <div className="md:hidden">
              <BottomFloatingButton
                label={flipped ? 'Go Back' : 'Preview'}
                onClick={() => setFlipped(!flipped)}
                onSecondaryClick={() => setShowBuildModal(true)}
                secondaryLabel="Build Site"
              />
            </div>
            <div className="md:block hidden">
              <BottomFloatingButton label="Build Site" onClick={() => setShowBuildModal(true)} />
            </div>
          </div>
          <BuildModal
            open={showBuildModal}
            onClose={() => setShowBuildModal(false)}
            onStart={() => {
              setShowBuildModal(false)
              setShowBuildStart(true)
            }}
            onSuccess={(url: string) => {
              setSiteUrl(url)
              setShowBuildModal(false)
              setShowBuildStart(false)
              setShowBuildSuccess(true)
            }}
          />

          {showBuildStart && (
            <div>
              <div className="z-[9999] fixed top-0 right-0 bottom-0 left-0 justify-center items-center">
                <div className="relative w-full h-full bg-black/70 flex justify-center items-center p-20 lg:p-0">
                  <div className="bg-[#D9D9D9] h-screen md:max-h-[600px]  gap-20 md:border-4 border-[#4E4E4E] md:rounded-[20px] items-center absolute p-4 lg:p-20 max-w-2xl flex">
                    <div className="flex-shrink-0">
                      <RocketIco />
                    </div>
                    <p className="text-2xl">
                      Your site is getting ready for lift off.
                      <br />
                      <br />
                      We will send you a notification when it launches.
                    </p>
                    <CloseButton onClick={() => setShowBuildStart(false)} />
                  </div>
                </div>
              </div>
            </div>
          )}
          {showBuildSuccess && (
            <div>
              <div className="z-[9999] fixed top-0 right-0 bottom-0 left-0  justify-center items-center">
                <div className="relative w-full h-full bg-black/70 flex justify-center items-center p-20 lg:p-0">
                  <div className="bg-[#D9D9D9] w-full h-screen md:max-h-[600px] gap-4 md:gap-15 md:border-4 border-[#4E4E4E] md:rounded-[20px] items-center absolute p-4 justify-center lg:p-20 max-w-2xl flex flex-col">
                    <div className="flex-shrink-0">
                      <div className="block lg:hidden">
                        <SuccessPhoneIco className="px-12" />
                      </div>
                      <div className="hidden lg:block">
                        <SuccessPCIco />
                      </div>
                    </div>
                    <p className="text-2xl">Your site has launched!!</p>
                    <a
                      href={`https://${siteUrl}`}
                      target="_blank"
                      rel="noreferrer"
                      className="bg-brand-accent h-[45px] w-[219px] rounded-md text-white text-[20px] font-light justify-center items-center flex"
                    >
                      Visit your site
                    </a>
                    <CloseButton onClick={() => setShowBuildSuccess(false)} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </PreviewLayout>
      </WebsiteContextProvider>
    </AuthenticatedPage>
  )
}

function CloseButton({ onClick }: { onClick: () => void }) {
  return (
    <button
      type="button"
      {...{ onClick }}
      className="h-8 w-8 border border-black rounded-md flex justify-center items-center bg-white absolute top-8 right-4 md:right-8"
    >
      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4618_5779)">
          <path d="M1.17578 1.17651L11.8228 11.8235" stroke="black" strokeWidth="2.5" strokeLinecap="round" />
          <path d="M11.8228 1.17651L1.17578 11.8235" stroke="black" strokeWidth="2.5" strokeLinecap="round" />
        </g>
        <defs>
          <clipPath id="clip0_4618_5779">
            <rect width="13" height="13" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </button>
  )
}
