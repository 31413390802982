import SectionSlate from '../../../common/SectionSlatePreview'
import { ImageOmgSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantEight({ section }: { section: ImageOmgSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data

  return (
    <div
      className="py-[70px] lg:py-[100px]"
      style={{
        ...generateSectionBackground({
          defaultColor: '#000',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="container md:py-12 py-4 mx-auto px-6 md:px-0 space-y-1">
        <div className="md:w-[750px] max-w-full mx-auto">
          <div className="text-[26px] md:text-4xl font-bold  text-white text-center">
            <SectionSlate initialValue={title} previewMode />
          </div>
        </div>

        {subTitle && (
          <div className="w-[750px] max-w-full mx-auto ">
            <div className="md:text-[20px] text-white text-center">
              <SectionSlate initialValue={subTitle} previewMode />
            </div>
          </div>
        )}
      </div>
      {media && (
        <div className="h-[300px] md:h-[565px] mt-4">
          <img className="h-full w-full object-cover object-center bg-black" src={media} alt="" />
        </div>
      )}
    </div>
  )
}
