import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { TextSection as TextSectionType } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

const VariantThirteen = ({ section }: { section: TextSectionType }) => {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data

  return (
    <div
      className="md:max-h-[700px] max-h-[300px] md:min-h-[400px] min-h-[200px] lg:text-8xl text-3xl flex justify-center items-center"
      style={{
        ...generateSectionBackground({
          defaultColor: '#f2ecec',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <section className="portfolio-experiment">
        <div className="experiment-title text-center">
          <SectionSlatePreview initialValue={title} previewMode />
        </div>
      </section>
    </div>
  )
}

export default VariantThirteen
