import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { NotificationStatus, Topics } from 'src/API'
import {
  fetchNotification,
  getNotificationById,
  INotification,
  notificationSubscriptions,
} from 'src/services/notification.service'
import { UserContext } from './UserContext'

interface NotificationContextState {
  notify: INotification[]
  notifyCount: number
  notifyMsg: INotification[]
  msgNotifyCount: number
}

const defaultState: NotificationContextState = {
  notify: [],
  notifyCount: 0,
  notifyMsg: [],
  msgNotifyCount: 0,
}

export const NotificationsContext = createContext(defaultState)

export const NotificationsContextProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useContext(UserContext)
  const [notifications, setNotifications] = useState(defaultState)

  // Load notifications on start-up
  useEffect(() => {
    if (typeof user?.id === 'string') {
      fetchNotification(user.id).then(
        nData => {
          const notification = nData.filter(
            _notification =>
              _notification.topic !== Topics.NEW_MESSAGE && _notification.topicDescription !== 'sent a new message',
          )
          const unReadNotification = nData.filter(
            _notification =>
              _notification.status === NotificationStatus.NOTIFIED &&
              (_notification.topic !== Topics.NEW_MESSAGE || _notification.topicDescription !== 'sent a new message'),
          )

          const unReadMsgNotification = nData.filter(
            _notification =>
              _notification.status === NotificationStatus.NOTIFIED &&
              (_notification.topic === Topics.NEW_MESSAGE || _notification.topicDescription === 'sent a new message'),
          )

          const msgNotification = nData.filter(
            _notification =>
              _notification.topic === Topics.NEW_MESSAGE || _notification.topicDescription === 'sent a new message',
          )
          setNotifications({
            notify: notification,
            notifyCount: unReadNotification.length,
            notifyMsg: msgNotification,
            msgNotifyCount: unReadMsgNotification.length,
          })
        },
        error => console.error('@notification.service::fetch::error', error),
      )
    }
  }, [user?.id])

  const updateNotifications = useCallback(async (notifyId: string) => {
    const item = (await getNotificationById(notifyId)) as INotification
    if (!item) return

    if (item.topic === Topics.NEW_MESSAGE) {
      setNotifications(_notifications => {
        const { notifyCount, notifyMsg, msgNotifyCount } = _notifications

        const msgIndex = notifyMsg.findIndex(x => x.id === item.id)
        const msgExisting = notifyMsg[msgIndex]

        const msgCount =
          msgNotifyCount +
          Number(msgIndex === -1) -
          Number(msgExisting?.status === NotificationStatus.NOTIFIED && item.status === NotificationStatus.READ)

        if (msgExisting) {
          notifyMsg.splice(msgIndex, 1, item)
        } else {
          notifyMsg.unshift(item)
        }

        return {
          notifyCount,
          notify: [..._notifications.notify],
          notifyMsg: [..._notifications.notifyMsg],
          msgNotifyCount: msgCount,
        }
      })
    }

    if (item.topic !== Topics.NEW_MESSAGE) {
      setNotifications(_notifications => {
        const { notify, notifyCount, msgNotifyCount } = _notifications

        const index = notify.findIndex(x => x.id === item.id)
        const existing = notify[index]

        const count =
          notifyCount +
          Number(index === -1) -
          Number(existing?.status === NotificationStatus.NOTIFIED && item.status === NotificationStatus.READ)

        if (existing) {
          notify.splice(index, 1, item)
        } else {
          notify.unshift(item)
        }

        return {
          notifyCount: count,
          notify: [..._notifications.notify],
          notifyMsg: [..._notifications.notifyMsg],
          msgNotifyCount,
        }
      })
    }
  }, [])

  // Fetch and Subscribe to user Notifications
  useEffect(() => {
    if (typeof user?.id !== 'string') return

    const { onCreate, onUpdate } = notificationSubscriptions()

    const subscriptions = [
      onCreate
        .map(x => x?.value?.data?.onCreateNotification)
        .filter(x => x?.toNotifyID === user.id || x?.toNotify?.id === user.id)
        .subscribe(x => updateNotifications(x?.id as string)),
      onUpdate
        .map(x => x?.value?.data?.onUpdateNotification)
        .filter(x => x?.toNotifyID === user.id || x?.toNotify?.id === user.id)
        .subscribe(x => updateNotifications(x?.id as string)),
    ]

    // eslint-disable-next-line consistent-return
    return () => subscriptions.forEach(x => x.unsubscribe())
  }, [updateNotifications, user?.id])

  return <NotificationsContext.Provider value={notifications}>{children}</NotificationsContext.Provider>
}
