import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'

type modalProps = {
  text: string
  open: boolean
  onConfirm: () => void
  onClose: (status: any) => void
}

export default function DeleteConfirmModal({ text, open, onConfirm, onClose }: modalProps) {
  return (
    <Transition show={open} appear as={Fragment}>
      <Dialog open={open} onClose={onClose}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute z-50 inset-0 w-full h-full bg-white/90 dark:bg-black/90 flex justify-center items-center" />
        </Transition.Child>

        <div className="flex absolute inset-0 items-center justify-center p-2 lg:p-4 text-center">
          <div className="lg:p-4 p-2 w-[362px] z-50 bg-white dark:bg-black border-[1px] rounded-[6px] border-[#E90570] border-solid  relative">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95 ease-in-out"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100 ease-in-out"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel>
                <Dialog.Description className="lg:pb-4 pb-2 text-[19px] text-black dark:text-white text-center">
                  {text}
                </Dialog.Description>
              </Dialog.Panel>
              <div className="lg:pt-4 pt-2 grid border-t dark:border-white mx-[10px] border-black grid-cols-2">
                <button
                  onClick={onConfirm}
                  type="button"
                  className="border-r text-[19px] uppercase dark:border-white dark:text-white border-black text-center text-black "
                >
                  yes
                </button>
                <button onClick={onClose} type="button" className="dark:text-white text-[19px] text-black uppercase">
                  No
                </button>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
