import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Loader } from 'src/components/loader'
import CreatePage from 'src/components/new/addPage/CreatePage'
import ModalDialogV2 from 'src/components/new/ModalDialogV2'
import EditMinus from 'src/icons/EditMinus'
import EditPlus from 'src/icons/EditPlus'
import LinkIcon from 'src/icons/Link'
import Save from 'src/icons/Save'
import Wrench from 'src/icons/Wrench'
import { uploadAsset } from 'src/lib/services/storage.service'
import { NewMenuLink, NewMenuLinkType } from 'src/_polly/components/src/menu/menu'
import NewMenuAllLinks from './AllLinks'
import InPageComponentSecletor from './InPageComponentSelector'
import InternalPageSelector from './InternalPageSelector'
import { MenuIconButton, MenuSaveButton } from './NewMenu'

const MAX_FILE_SIZE = 52428800

type LinkPointerProps = {
  onSelect?: (link: Omit<NewMenuLink, 'id' | 'name'>) => void
  linkName?: string
  handleLinkAdd?: (link: NewMenuLink) => void
}

export default function LinkPointer({ onSelect, linkName, handleLinkAdd }: LinkPointerProps) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png, image/jpg, application/pdf',
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE,
  })

  const [config, setConfig] = useState({
    url: '',
    type: NewMenuLinkType.NONE,
  })

  const [showInternalPageSelector, setShowInternalPageSelector] = useState(false)
  const [showInPageComponentSelector, setShowInPageComponentSelector] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showAllLinks, setShowAllLinks] = useState(false)
  const [showEmail, setShowEmail] = useState(false)
  const [name, setName] = useState('')

  const handleSelect = async () => {
    if (config.type === NewMenuLinkType.DOCUMENT) {
      setLoading(true)
      const key = `doc-${Date.now()}`
      const url = await uploadAsset(key, acceptedFiles[0])
      onSelect?.({ url, type: NewMenuLinkType.DOCUMENT })
      return
    }
    if (config.type === NewMenuLinkType.EXTERNAL) {
      const url = `${
        (config.url.search('https://') !== 0 ? `http://${config.url}` : config.url) ||
        (config.url.search('http://') !== 0 ? `http://${config.url}` : config.url)
      }`
      onSelect?.({ url, type: NewMenuLinkType.EXTERNAL })
      return
    }
    onSelect?.(config)
    setLoading(false)
  }

  useEffect(() => {
    if (
      config.url &&
      config.type !== NewMenuLinkType.EMAIL &&
      config.type !== NewMenuLinkType.DOCUMENT &&
      config.type !== NewMenuLinkType.EXTERNAL
    ) {
      handleSelect()
    }
  }, [config])
  useEffect(() => {
    if (linkName) {
      setName(linkName)
    }
  }, [linkName])

  return (
    <div className="px-[18px]">
      <Loader show={loading} />
      <div className="mt-[40px]">
        <div className="flex items-center gap-[20px] pb-[20px] border-b border-black dark:border-white px-[20px]">
          <Wrench height={23} fill="#E90570" />
          <p className="text-[19px] dark:text-white">Link Points to:</p>
        </div>
        <div>
          <MenuIconButton label="My Links" onClick={() => setShowAllLinks(true)} />
          <MenuIconButton label="In-Page Component" onClick={() => setShowInPageComponentSelector(true)} />

          <MenuIconButton
            label="External Website"
            onClick={() => {
              setConfig({
                url: '',
                type: NewMenuLinkType.EXTERNAL,
              })
            }}
          />
          <ModalDialogV2
            open={config.type === NewMenuLinkType.EXTERNAL}
            onBack={() => {
              setConfig({
                url: '',
                type: NewMenuLinkType.NONE,
              })
            }}
            bgFill="transparent"
          >
            <div className="mt-[142px] px-[18px]">
              <h3 className="text-[19px] dark:text-white border-black px-[2px] border-b-[1px] dark:border-white pb-[25px] mb-[46px]">
                External Website
              </h3>
              <div className="flex items-center">
                <div className="w-[58px]">
                  <LinkIcon
                    height={24}
                    className="stroke-[#8D8D8D] fill-[#8D8D8D] dark:fill-[#D9D9D9] dark:stroke-[#D9D9D9] mx-auto"
                  />
                </div>
                <input
                  type="text"
                  placeholder="Name of Link"
                  className="text-[19px] w-1/2 border-b-[1px] dark:text-white dark:bg-black dark:border-white border-black pb-[7px] outline-none text-left"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
                <button type="button" onClick={handleSelect} className="w-[127px]">
                  <Save height={23} fill="none" className="mx-auto" />
                </button>
              </div>
              <div className="flex items-center pt-[32px] border-b-[1px] dark:border-white border-black pb-[46px]">
                <div className="w-[65px]">
                  <h2 className="text-[19px] dark:text-white">https://</h2>
                </div>
                <input
                  placeholder="google.com"
                  type="text"
                  defaultValue={config.url}
                  onChange={e => setConfig({ ...config, url: e.target.value })}
                  className="text-[19px] w-1/2 dark:border-white dark:bg-black dark:text-white border-b border-black pb-[7px] outline-none text-left"
                />
                <div className="w-[127px]" />
              </div>
            </div>
          </ModalDialogV2>

          <MenuIconButton
            label="Email"
            onClick={() => {
              setConfig({
                url: '',
                type: NewMenuLinkType.EMAIL,
              })
            }}
          />
          <ModalDialogV2
            open={config.type === NewMenuLinkType.EMAIL}
            onBack={() => {
              setConfig({
                url: '',
                type: NewMenuLinkType.NONE,
              })
            }}
            bgFill="transparent"
          >
            <div className="mt-[32px] px-[18px]">
              <h3 className="text-[19px] dark:text-white border-black px-[2px] border-b-[1px] dark:border-white pb-[15px] mb-[46px]">
                Email
              </h3>
              <p className="text-[19px] dark:text-white px-[2px] mb-[53px]">
                If visitors click this link they can email you directly within the application.
              </p>
              <div className="flex items-center">
                <div className="w-[58px]">
                  <LinkIcon
                    height={24}
                    className="stroke-[#8D8D8D] fill-[#8D8D8D] dark:fill-[#D9D9D9] dark:stroke-[#D9D9D9] mx-auto"
                  />
                </div>
                <input
                  type="text"
                  placeholder="Name of Link"
                  className="text-[19px] w-1/2 border-b-[1px] dark:text-white dark:bg-black dark:border-white border-black pb-[7px] outline-none text-left"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
                <button type="button" onClick={handleSelect} className="w-[127px]">
                  <Save height={23} fill="none" className="mx-auto" />
                </button>
              </div>
              <div className="pt-[32px] border-b-[1px] dark:border-white pb-[46px]">
                <div className="flex items-center justify-between pb-[22px]">
                  <h2 className="text-[19px] dark:text-white">External Email ?</h2>
                  {showEmail ? (
                    <button type="button" onClick={() => setShowEmail(false)}>
                      <EditMinus size={50} fill="none" className="stroke-[#8D8D8D] dark:stroke-white" />
                    </button>
                  ) : (
                    <button type="button" onClick={() => setShowEmail(true)}>
                      <EditPlus size={50} fill="none" className="stroke-[#8D8D8D] dark:stroke-white" />
                    </button>
                  )}
                </div>
                {showEmail && (
                  <div>
                    <p className="text-[19px] dark:text-white pb-[41px]">
                      Prefer to use an email account outside of Techboom? No problem. Type the email address below.
                    </p>
                    <div className="flex items-center gap-[26px] px-[1px]">
                      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M19.2469 0.440281C18.8148 0.281707 18.3418 0.273523 17.9045 0.417054L1.62405 5.76601C1.23219 5.89458 0.888521 6.13862 0.637919 6.46616C0.387317 6.7937 0.241687 7.18939 0.220083 7.60124C0.198479 8.01308 0.301807 8.42186 0.516764 8.77382C0.731721 9.12579 1.04817 9.40446 1.42443 9.57332L8.01164 12.532L10.741 19.2185C10.8967 19.6006 11.1644 19.9267 11.5088 20.1539C11.8533 20.3811 12.2586 20.4987 12.6712 20.4914C13.0838 20.4842 13.4843 20.3522 13.8205 20.113C14.1567 19.8738 14.4127 19.5385 14.5549 19.1511L20.4619 3.06364C20.5544 2.81157 20.5963 2.54378 20.5852 2.27551C20.5742 2.00724 20.5106 1.74376 20.3977 1.50015C20.2848 1.25653 20.1249 1.03753 19.9273 0.855678C19.7298 0.673822 19.4985 0.532674 19.2464 0.440273L19.2469 0.440281ZM13.2744 18.6801C13.227 18.8092 13.1416 18.921 13.0296 19.0007C12.9175 19.0804 12.784 19.1243 12.6465 19.1267C12.509 19.1292 12.3741 19.09 12.2593 19.0142C12.1445 18.9385 12.0552 18.8299 12.0033 18.7026L9.34351 12.1922L19.1273 2.74085L13.2744 18.6801ZM8.39768 11.2113L1.98329 8.32771C1.85821 8.27107 1.75314 8.17795 1.68192 8.06055C1.61069 7.94316 1.57657 7.80699 1.58411 7.66989C1.59165 7.53278 1.64048 7.40114 1.72415 7.29226C1.80782 7.18338 1.92254 7.10234 2.05308 7.05976L18.1838 1.75798L8.40004 11.2094L8.39768 11.2113Z"
                          className="fill-[#8D8D8D] dark:fill-white"
                        />
                      </svg>
                      <input
                        placeholder="hello@example.com"
                        type="text"
                        value={config.url}
                        onChange={e => setConfig({ ...config, url: e.target.value })}
                        className="text-[19px] w-1/2 dark:border-white dark:bg-black dark:text-white border-b border-black pb-[7px] outline-none text-left"
                      />
                    </div>
                  </div>
                )}
                <div className="w-[127px]" />
              </div>
            </div>
          </ModalDialogV2>

          <CreatePage
            pageName={linkName}
            onPageCreate={data => {
              setConfig({
                url: data.path,
                type: NewMenuLinkType.INTERNAL,
              })
            }}
            createButton={
              <MenuIconButton
                label="New Page"
                onClick={() => {
                  setConfig({
                    url: '',
                    type: NewMenuLinkType.INTERNAL,
                  })
                }}
              />
            }
          />

          <MenuIconButton
            label="Document"
            onClick={() => {
              setConfig({
                url: '',
                type: NewMenuLinkType.DOCUMENT,
              })
            }}
          />
          <ModalDialogV2
            open={config.type === NewMenuLinkType.DOCUMENT}
            onBack={() => {
              setConfig({
                url: '',
                type: NewMenuLinkType.NONE,
              })
            }}
            bgFill="transparent"
          >
            <div className="mt-[32px] px-[18px]">
              <h3 className="text-[19px] dark:text-white border-black px-[2px] border-b-[1px] dark:border-white pb-[15px] mb-[46px]">
                Document
              </h3>
              <div className="flex items-center">
                <div className="w-[58px]">
                  <LinkIcon
                    height={24}
                    className="stroke-[#8D8D8D] fill-[#8D8D8D] dark:fill-[#D9D9D9] dark:stroke-[#D9D9D9] mx-auto"
                  />
                </div>
                <input
                  type="text"
                  placeholder="Name of Link"
                  className="text-[19px] w-1/2 border-b-[1px] dark:text-white dark:bg-black dark:border-white border-black pb-[7px] outline-none text-left"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
                <button type="button" onClick={handleSelect} className="w-[127px]">
                  <Save height={23} fill="none" className="mx-auto" />
                </button>
              </div>
              <h2 className="text-[19px] dark:text-white pt-[60px] pb-[20px]">Upload</h2>
              <div {...getRootProps({ className: 'dropzone hover:bg-gray-100 dark:hover:bg-[#040404]' })}>
                <input {...getInputProps()} />
                <div className="flex justify-center flex-col items-center">
                  <p className="mt-2 dark:text-white">Click to select</p>
                  <p className="text-sm" style={{ color: 'gray' }}>
                    Maximum 1 file (50MB) can be uploaded
                  </p>
                </div>
              </div>
              {acceptedFiles.length > 0 && (
                <div className="mt-3 dark:text-white">
                  <p>Selected file:</p>
                  <div className="dropzone--selected-file border border-black dark:border-white">
                    <p style={{ textAlign: 'center' }}>{acceptedFiles[0].name}</p>
                  </div>
                </div>
              )}
            </div>
          </ModalDialogV2>
        </div>
      </div>

      <div className="py-10 hidden">
        <MenuSaveButton onClick={handleSelect} inverse />
      </div>

      <ModalDialogV2 open={showInternalPageSelector} onBack={() => setShowInternalPageSelector(false)}>
        <InternalPageSelector
          onSelect={path => {
            setShowInternalPageSelector(false)
            if (path) {
              setConfig({
                url: path,
                type: NewMenuLinkType.INTERNAL,
              })
            }
          }}
        />
      </ModalDialogV2>

      <ModalDialogV2 open={showInPageComponentSelector} onBack={() => setShowInPageComponentSelector(false)}>
        <InPageComponentSecletor
          onSelect={path => {
            setShowInPageComponentSelector(false)
            if (path) {
              setConfig({
                url: path,
                type: NewMenuLinkType.IN_PAGE,
              })
            }
          }}
        />
      </ModalDialogV2>
      <ModalDialogV2
        open={showAllLinks}
        onBack={() => {
          setShowAllLinks(false)
          // fetchWebsite()
        }}
      >
        <NewMenuAllLinks onSelect={handleLinkAdd} myLink />
      </ModalDialogV2>
    </div>
  )
}
