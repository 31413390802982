import { createContext, ReactNode, useEffect, useState } from 'react'
import { getCurrentUser } from 'src/services/user.service'

export type IUser = {
  id: string
  username: string
  firstName: string
  lastName: string
  country: string
  state: string
  city: string
  bio: string
  profilePicture: string
}

interface IUserContext {
  user: IUser
  isLoading: boolean
}

export const UserContext = createContext<IUserContext>({} as any)

export function UserContextProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<Awaited<ReturnType<typeof getCurrentUser>>>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getCurrentUser()
      .then(setUser)
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <UserContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ user: user as any, isLoading }}
    >
      {children}
    </UserContext.Provider>
  )
}
