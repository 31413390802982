export default function Invitation({ setShowPage }: any) {
  return (
    <form className="space-y-8">
      <div className="w-[265px]">
        <input
          className="w-full px-5 py-2.5 bg-[#FFFFFF] text-[15px] rounded focus:outline-none outline-0"
          type="email"
          placeholder="Username or Email"
        />
      </div>
      <div className="w-[265px]">
        <input
          className="w-full px-5 py-2.5 bg-[#FFFFFF] text-[15px] rounded focus:outline-none outline-0"
          type="password"
          placeholder="Password"
        />
        <p className="text-[10px] text-[#FFFFFF] opacity-70 pt-2">
          Passwords should atleast 1 character and 1 capital letter{' '}
        </p>
      </div>
      <div className="w-[265px]">
        <input
          className="w-full px-5 py-2.5 bg-[#FFFFFF] text-[15px] rounded focus:outline-none outline-0"
          type="password"
          placeholder="Confirm Password"
        />
      </div>
      <div className="w-[265px]">
        <button className="w-full py-2 bg-[#E90570] text-white text-[20px] rounded" type="button">
          Request Invite
        </button>
        <p className="text-[10px] text-[#FFFFFF] text-center opacity-70 pt-2">
          I already have an account,{' '}
          <button onClick={() => setShowPage('login')} className="pl-2" type="submit">
            Sign in.
          </button>
        </p>
      </div>
    </form>
  )
}
