import clsx from 'clsx'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { TeamMembersSectionMember } from '../../types'

export default function TeamMember({ member, isRound }: { member: TeamMembersSectionMember; isRound?: boolean }) {
  return (
    <div>
      <div className="md:h-[380px] md:w-[300px] mx-auto">
        <img
          className={clsx('h-full w-full object-cover object-center', isRound && 'rounded-full')}
          src={member.image}
          alt=""
        />
      </div>
      <div className="space-y-2 mt-5">
        <p className="text-lg font-medium text-center">
          <SectionSlatePreview initialValue={member.name} previewMode />
        </p>
        {member.subtitle && (
          <p className="text-center">
            <SectionSlatePreview initialValue={member.subtitle} previewMode />
          </p>
        )}
        {member.description && (
          <p className="text-center">
            <SectionSlatePreview initialValue={member.description} previewMode />
          </p>
        )}
      </div>
    </div>
  )
}
