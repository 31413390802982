import Icon from './icons'
import { IconPropsT } from './types'

function TextHighlight(props: IconPropsT) {
  const { title = 'TextHighlight', size, ...otherProps } = props
  return (
    <>
      <div className="dark:hidden">
        <Icon viewBox="0 0 16 15" size={size} title={title} {...otherProps}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5851 0.73936C10.9566 0.330745 11.5891 0.300699 11.9977 0.672252L14.0333 2.52325C14.4419 2.89481 14.472 3.52726 14.1004 3.93587L6.51553 12.2774L3.00016 9.08086L10.5851 0.73936ZM11.8631 0.820225L13.8988 2.67123C14.2257 2.96847 14.2497 3.47443 13.9525 3.80132L6.50211 11.9948L3.28268 9.06743L10.733 0.873912C11.0303 0.547019 11.5362 0.522983 11.8631 0.820225Z"
          />
          <path d="M1.96652 13.738L2.97241 9.22508L6.3778 12.3527L1.96652 13.738Z" />
          <path d="M0 14.5008C0 14.2247 0.223858 14.0008 0.5 14.0008H15.5C15.7761 14.0008 16 14.2247 16 14.5008C16 14.7769 15.7761 15.0008 15.5 15.0008H0.5C0.223858 15.0008 0 14.7769 0 14.5008Z" />
        </Icon>
      </div>
      <div className="hidden dark:block">
        <Icon viewBox="0 0 16 15" size={size} title={title} {...otherProps}>
          <path
            d="M11.9977 0.673229C11.5891 0.301676 10.9566 0.331721 10.5851 0.740337L3.00016 9.08183L6.51553 12.2783L14.1004 3.93685C14.472 3.52823 14.4419 2.89578 14.0333 2.52423L11.9977 0.673229Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.96652 13.7387L2.97241 9.22573L6.3778 12.3533L1.96652 13.7387ZM5.98738 12.2663L2.23709 13.4441L3.09226 9.60735L5.98738 12.2663Z"
            fill="white"
          />
          <path
            d="M0 14.501C0 14.2249 0.223858 14.001 0.5 14.001H15.5C15.7761 14.001 16 14.2249 16 14.501C16 14.7772 15.7761 15.001 15.5 15.001H0.5C0.223858 15.001 0 14.7772 0 14.501Z"
            fill="white"
          />
        </Icon>
      </div>
    </>
  )
}

export default TextHighlight
