import { PropsWithChildren, useEffect, useState } from 'react'
import PreviewSaveButton from 'src/components/PreviewSaveButton'
import { SectionSettings } from 'src/_polly/components/src/sections'
import Preview from './Preview'
import TitleBar from './TitleBar'

type EffectsProps = {
  defaultValue: SectionSettings['transitionSpeed']
  onChange?: (settings: SectionSettings['transitionSpeed']) => void
  onBack?: () => void
}

export default function Effects({ defaultValue, onChange, onBack }: EffectsProps) {
  const [transitionSettings, setTransitionSettings] = useState(defaultValue)
  const [showPreview, setShowPreview] = useState(false)

  useEffect(() => {
    onChange?.(transitionSettings)
  }, [transitionSettings])

  return (
    <div className="px-4 dark:text-white">
      <TitleBar
        title="Effects"
        showSwitch
        checked={transitionSettings?.enabled}
        onChange={enabled => setTransitionSettings(s => ({ ...s, enabled }))}
      />
      <Item title="Transition Speed:" desc="Speeds up or slows down the animations on your component.">
        <input
          type="range"
          min="1"
          max="100"
          defaultValue={transitionSettings?.value}
          onChange={e => setTransitionSettings(s => ({ ...s, value: Number(e.target.value) }))}
          className="w-full block  h-[1px] bg-black dark:bg-white appearance-none outline-none slider-thumb rotate-180"
        />
      </Item>

      <Preview onBack={() => setShowPreview(false)} open={showPreview} />
      <PreviewSaveButton onPreviewClick={() => setShowPreview(true)} onSaveClick={onBack} />
    </div>
  )
}

function Item({ title, desc, children }: PropsWithChildren<{ title: string; desc: string }>) {
  return (
    <div>
      <p className="mt-8">
        <span className="text-xl font-light">{title}</span> {desc}
      </p>
      <div className="mt-8">{children}</div>
    </div>
  )
}
