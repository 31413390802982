import { ContactUsSection, InputsContactUSSection } from '../../types'
import { useForm } from 'react-hook-form'
import InputText from '../../../common/InputText'
import { useState } from 'react'
import clsx from 'clsx'
import TextArea from '../../../common/TextArea'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import ButtonRenderer from '../ButtonRenderer'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

export default function VariantFive({ section }: { section: ContactUsSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subtitle =
    section.variables.find(variable => variable.name === 'SUBTITLE')?.data ??
    'Be the first to find out about sales, styles and everything between.'
  const media =
    section.variables.find(variable => variable.name === 'MEDIA')?.data ??
    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'
  const inputs = section.variables.find(variant => variant.name === 'INPUTS')?.data as InputsContactUSSection[]
  const button = section.variables.find(variant => variant.name === 'BUTTON_1')?.data

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    setLoading(true)
    try {
    } catch (error) {
      setError('Something went wrong!')
    }
    setLoading(false)
  }

  const inputClasses = {
    classInput:
      'bg-transparent font-extralight text-sm w-full border border-black md:px-3 px-1 py-2 resize-none outline-none',
    classLabel: 'font-extralight text-sm inline-block pb-0 mb-1',
  }

  return (
    <div
      className="md:min-h-[550px]"
      style={{
        ...generateSectionBackground({
          defaultColor: '#B7B6C4',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div className="flex justify-center md:py-14 font-light h-full">
        <div className="md:space-y-5 w-full md:max-w-[750px] md:w-10/12 relative">
          <div className="md:absolute w-full md:w-7/12 top-0 right-0 z-0">
            <div className="mx-auto w-full h-[220px] md:h-[350px] top-0 left-0">
              <img className="h-full w-full object-cover object-center bg-black" src={media} alt="" />
            </div>
          </div>

          <div className="top-10 md:absolute bg-[#efefef] w-full md:w-7/12 px-10 py-12 z-1">
            <p className="text-[40px]">
              <SectionSlatePreview initialValue={title} previewMode />
            </p>
            <p className="text-sm">
              <SectionSlatePreview initialValue={subtitle} previewMode />
            </p>

            <form className="py-7 flex  gap-y-2 flex-col mx-auto text-sm" onSubmit={handleSubmit(onSubmit)}>
              {inputs.map(item => {
                return (
                  <div key={item.id}>
                    {item.type !== 'button' && item.type !== 'textarea' && (
                      <InputText
                        {...inputClasses}
                        label={item.name}
                        name={item.name}
                        register={register}
                        type={item.type}
                        errors={errors}
                        placeholder={item.placeholder}
                        required={{ value: true, message: `${item.error}` }}
                      />
                    )}

                    {item.type === 'button' && (
                      <div>
                        <input
                          type={item.type}
                          value={item.name}
                          className="px-5 py-3 font-medium bg-black text-white cursor-pointer"
                        />
                      </div>
                    )}
                    {item.type === 'textarea' && (
                      <TextArea
                        {...inputClasses}
                        label={item.name}
                        name={item.name}
                        register={register}
                        type={item.type}
                        errors={errors}
                        required={{ value: true, message: `${item.error}` }}
                        placeholder={item.placeholder}
                      />
                    )}
                  </div>
                )
              })}
              {error && <span className="text-secondary.main text-center mb-4">{error}</span>}

              <div className="btn-submit">
                {button ? (
                  <ButtonRenderer button={button} typeOfButton="submit" withOutHref />
                ) : (
                  <button
                    disabled={loading}
                    type="submit"
                    className={clsx(
                      loading && 'cursor-not-allowed disabled:opacity-50',
                      'w-full px-5 py-[9px] text-sm bg-black text-white',
                    )}
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
