import { motion } from 'framer-motion'
import SectionSlate from '../../../common/SectionSlatePreview'
import { AttentionGrabberSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import ButtonRenderer from '../ButtonRenderer'

export default function VariantFive({ section }: { section: AttentionGrabberSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data
  const button = section.variables.find(variable => variable.name === 'BUTTON_1')?.data
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      variants={{ 0: { opacity: 0 }, 1: { opacity: 1 } }}
      transition={{ duration: 0.8, ease: 'easeOut' }}
      exit={{ opacity: 0 }}
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
      className="relative overflow-hidden"
    >
      <section className="relative lg:h-[950px] h-[250px]">
        <div className="h-[100%]">
          <img src={media} className="w-full h-[100%] object-cover object-center" />
        </div>
        <div className="absolute bottom-4 right-6 lg:top-[680px] z-50 flex items-center justify-center  md:right-[20%] md:left-[80%] ">
          {button ? (
            <ButtonRenderer button={button} />
          ) : (
            <button className="text-[12px] text-white border border-white text-center lg:font-bold lg:tracking-[0.6rem] tracking-wide lg:text-3xl  outline-none">
              Discover More
            </button>
          )}
        </div>

        <motion.h3
          initial={{ x: '100%' }}
          animate={{ x: '-110%' }}
          transition={{ ease: 'linear', duration: 5, repeat: Infinity }}
          className="leading-8 tracking-wide absolute top-[100px] lg:top-[500px] text-2xl lg:text-6xl text-center"
        >
          <SectionSlate initialValue={title} previewMode />
        </motion.h3>
      </section>
    </motion.div>
  )
}
