import { GallerySectionItem } from './../../_polly/components/src/sections/types'
import { Descendant } from 'slate'
import {
  GallerySectionVariables,
  GallerySectionVariants,
  Section,
  SectionTags,
} from 'src/_polly/components/src/sections'
import { v4 as uuid } from 'uuid'
import { DEFAULT_VARIANT_SETTINGS, getSectionVariants } from './get-variants'
const tags: Record<GallerySectionVariants, SectionTags[]> = {
  VARIANT_1: ['SLIDESHOW'],
  VARIANT_2: ['IMAGE', 'ANIMATION'],
  VARIANT_3: ['IMAGE', 'ANIMATION'],
  VARIANT_4: ['IMAGE'],
  VARIANT_5: ['IMAGE'],
  VARIANT_6: ['IMAGE'],
  VARIANT_7: ['ANIMATION'],
  VARIANT_8: ['IMAGE'],
  VARIANT_9: ['IMAGE'],
  VARIANT_10: ['IMAGE', 'ANIMATION'],
  VARIANT_11: ['IMAGE', 'ANIMATION'],
  VARIANT_12: ['SLIDESHOW'],
  VARIANT_13: [],
  VARIANT_14: ['ANIMATION', 'IMAGE'],
  VARIANT_15: ['ANIMATION', 'IMAGE', 'SLIDESHOW'],
}
export function getGalleryVariants() {
  const media =
    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'

  const variants = getSectionVariants('GALLERY')
  const sections: Section<GallerySectionVariants, GallerySectionVariables>[] = []
  const itemTitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Accelerate Appeal' }] }]
  const itemDescription: Descendant[] = [
    {
      type: 'paragraph',
      children: [
        { text: 'we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.' },
      ],
    },
  ]
  variants.forEach(variant => {
    if (variant.id === 'VARIANT_1') {
      const itemTitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Item' }] }]

      const data: Section<GallerySectionVariants, GallerySectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'GALLERY',
        updatedAt: '',
        tags: tags[variant.id as GallerySectionVariants],
        variables: [
          {
            id: '123',
            name: 'MEDIA',
            category: 'TEXT',
            data: [
              {
                id: '1',
                image:
                  'https://images.unsplash.com/photo-1574068468668-a05a11f871da?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw3fHxhbmltYWxzfGVufDB8fHx8MTY3OTQ3MDQ5MQ&ixlib=rb-4.0.3&q=80&w=1080',
                title: itemTitle,
                description: itemDescription,
              },
              {
                id: '2',
                image:
                  'https://images.unsplash.com/photo-1659539909696-2de5f1f75b10?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxhbmltYWwlMjBvZiUyMHJpdmVyfGVufDB8fHx8MTY3OTQ3MDM2Mg&ixlib=rb-4.0.3&q=80&w=1080',
                title: itemTitle,
                description: itemDescription,
              },
              {
                id: '3',
                image:
                  'https://images.unsplash.com/photo-1534447677768-be436bb09401?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxzdGFyJTIwb2YlMjB0aGUlMjBzZWF8ZW58MHx8fHwxNjc5NDcwMzE2&ixlib=rb-4.0.3&q=80&w=1080',
                title: itemTitle,
                description: itemDescription,
              },
            ] as GallerySectionItem[],
          },
        ],

        variant: variant.id as GallerySectionVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_2') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Our Services' }] }]
      const data: Section<GallerySectionVariants, GallerySectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'GALLERY',
        updatedAt: '',
        tags: tags[variant.id as GallerySectionVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          {
            id: '123',
            name: 'MEDIA',
            category: 'TEXT',
            data: [
              {
                id: '1',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '2',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '3',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '4',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '5',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '6',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '6',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '4',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
            ] as GallerySectionItem[],
          },
        ],

        variant: variant.id as GallerySectionVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_3') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'HIGHLIGHTS' }] }]
      const data: Section<GallerySectionVariants, GallerySectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'GALLERY',
        updatedAt: '',
        tags: tags[variant.id as GallerySectionVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          {
            id: '123',
            name: 'MEDIA',
            category: 'TEXT',
            data: [
              {
                id: '1',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '2',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '3',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '4',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '5',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '6',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '7',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '6',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
            ] as GallerySectionItem[],
          },
        ],

        variant: variant.id as GallerySectionVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_4') {
      const data: Section<GallerySectionVariants, GallerySectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'GALLERY',
        updatedAt: '',
        tags: tags[variant.id as GallerySectionVariants],
        variables: [
          {
            id: '123',
            name: 'MEDIA',
            category: 'TEXT',
            data: [
              {
                id: '1',
                title: itemTitle,
                description: itemDescription,
                image:
                  'https://images.unsplash.com/photo-1501183638710-841dd1904471?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxob21lfGVufDB8fHx8MTY3NzY2MTU4Nw&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '2',
                title: itemTitle,
                description: itemDescription,
                image:
                  'https://images.unsplash.com/photo-1556020685-ae41abfc9365?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxob21lfGVufDB8fHx8MTY3NzY2MTU4Nw&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '3',
                title: itemTitle,
                description: itemDescription,
                image:
                  'https://images.unsplash.com/photo-1567016376408-0226e4d0c1ea?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxMHx8aG9tZXxlbnwwfHx8fDE2Nzc2NjE1ODc&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '4',
                title: itemTitle,
                description: itemDescription,
                image:
                  'https://images.unsplash.com/photo-1484154218962-a197022b5858?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxob21lfGVufDB8fHx8MTY3NzY2MTU4Nw&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '5',
                title: itemTitle,
                description: itemDescription,
                image:
                  'https://images.unsplash.com/photo-1507494924047-60b8ee826ca9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxsaWdodHN8ZW58MHx8fHwxNjc3NjYxNzEy&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '6',
                title: itemTitle,
                description: itemDescription,
                image:
                  'https://images.unsplash.com/photo-1529704193007-e8c78f0f46f9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw2fHxsaWdodHxlbnwwfHx8fDE2Nzc2NjE3MzE&ixlib=rb-4.0.3&q=80&w=1080',
              },
            ] as GallerySectionItem[],
          },
        ],
        variant: variant.id as GallerySectionVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_5') {
      const data: Section<GallerySectionVariants, GallerySectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'GALLERY',
        updatedAt: '',
        tags: tags[variant.id as GallerySectionVariants],
        variables: [
          {
            id: '123',
            name: 'MEDIA',
            category: 'TEXT',
            data: [
              {
                id: '1',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '2',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '3',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '4',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '5',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '6',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '7',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '8',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
            ] as GallerySectionItem[],
          },
        ],
        variant: variant.id as GallerySectionVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_8') {
      const data: Section<GallerySectionVariants, GallerySectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'GALLERY',
        updatedAt: '',
        tags: tags[variant.id as GallerySectionVariants],
        variables: [
          {
            id: '123',
            name: 'MEDIA',
            category: 'TEXT',
            data: [
              {
                id: '1',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '2',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '3',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '4',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '5',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '6',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '7',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '8',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
            ] as GallerySectionItem[],
          },
        ],

        variant: variant.id as GallerySectionVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_14') {
      const itemTitle: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Ariel Bullock' }] }]
      const itemTitle2: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Carmen Lopez' }] }]
      const itemTitle3: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Ana Aniston' }] }]
      const itemDescription: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Looking for an electrifyingly thrilling, exuberant design that ensures a pulsating flawless functionality and a captivating seamless user experience',
            },
          ],
        },
      ]

      const data: Section<GallerySectionVariants, GallerySectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'GALLERY',
        updatedAt: '',
        variables: [
          {
            id: '123',
            name: 'MEDIA',
            category: 'TEXT',
            data: [
              {
                id: '3',
                title: itemTitle,
                description: itemDescription,
                image:
                  'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw1fHxtZW58ZW58MHx8fHwxNjc3NTgxMTQ0&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '1',
                title: itemTitle2,
                description: itemDescription,
                image:
                  'https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw4fHxtYW58ZW58MHx8fHwxNjc3NTgwODQ5&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '2',
                title: itemTitle3,
                description: itemDescription,
                image:
                  'https://images.unsplash.com/photo-1613005798967-632017e477c8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw0fHxnaXJsfGVufDB8fHx8MTY3NzU4Njc0Mw&ixlib=rb-4.0.3&q=80&w=1080',
              },
            ] as GallerySectionItem[],
          },
        ],
        variant: variant.id as GallerySectionVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_6') {
      const data: Section<GallerySectionVariants, GallerySectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'GALLERY',
        updatedAt: '',
        tags: tags[variant.id as GallerySectionVariants],
        variables: [
          {
            id: '123',
            name: 'MEDIA',
            category: 'TEXT',
            data: [
              {
                id: '1',
                title: itemTitle,
                description: itemDescription,
                image:
                  'https://images.unsplash.com/photo-1444464666168-49d633b86797?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxiaXJkfGVufDB8fHx8MTY3OTQ3NDkyMA&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '2',
                title: itemTitle,
                description: itemDescription,
                image:
                  'https://images.unsplash.com/photo-1480044965905-02098d419e96?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw2fHxiaXJkfGVufDB8fHx8MTY3OTQ3NDkyMA&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '3',
                title: itemTitle,
                description: itemDescription,
                image:
                  'https://images.unsplash.com/photo-1522926193341-e9ffd686c60f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw5fHxiaXJkfGVufDB8fHx8MTY3OTQ3NDkyMA&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '4',
                title: itemTitle,
                description: itemDescription,
                image:
                  'https://images.unsplash.com/photo-1606567595334-d39972c85dbe?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxiaXJkfGVufDB8fHx8MTY3OTQ3NDkyMA&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '5',
                title: itemTitle,
                description: itemDescription,
                image:
                  'https://images.unsplash.com/photo-1605720766560-0e9026556297?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw4fHxzbWFsbCUyMGJpcmR8ZW58MHx8fHwxNjc5NDc3ODAw&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '6',
                title: itemTitle,
                description: itemDescription,
                image:
                  'https://images.unsplash.com/photo-1551085254-e96b210db58a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw1fHxiaXJkfGVufDB8fHx8MTY3OTQ3NDkyMA&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '7',
                title: itemTitle,
                description: itemDescription,
                image:
                  'https://images.unsplash.com/photo-1539664030485-a936c7d29c6e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHw3fHxiaXJkfGVufDB8fHx8MTY3OTQ3NDkyMA&ixlib=rb-4.0.3&q=80&w=1080',
              },
              {
                id: '8',
                title: itemTitle,
                description: itemDescription,
                image:
                  'https://images.unsplash.com/photo-1518428618744-ecd97fbd445b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwzfHxiaXJkJTIwc21hbGx8ZW58MHx8fHwxNjc5NDc3Nzg3&ixlib=rb-4.0.3&q=80&w=1080',
              },
            ] as GallerySectionItem[],
          },
        ],

        variant: variant.id as GallerySectionVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_7') {
      const data: Section<GallerySectionVariants, GallerySectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'GALLERY',
        updatedAt: '',
        tags: tags[variant.id as GallerySectionVariants],
        variables: [
          {
            id: '123',
            name: 'MEDIA',
            category: 'TEXT',
            data: [
              {
                id: '1',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '2',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '3',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '4',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '5',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '6',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '5',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '6',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
            ] as GallerySectionItem[],
          },
        ],

        variant: variant.id as GallerySectionVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_9') {
      const title: Descendant[] = [
        { type: 'paragraph', children: [{ text: 'Our Clients. here are some on the vip list  ' }] },
      ]
      const data: Section<GallerySectionVariants, GallerySectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'GALLERY',
        updatedAt: '',
        tags: tags[variant.id as GallerySectionVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          {
            id: '123',
            name: 'MEDIA',
            category: 'TEXT',
            data: [
              {
                id: '1',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '2',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '3',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '4',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '5',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '6',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '7',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
            ] as GallerySectionItem[],
          },
        ],

        variant: variant.id as GallerySectionVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_10') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Trending' }] }]
      const data: Section<GallerySectionVariants, GallerySectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'GALLERY',
        updatedAt: '',
        tags: tags[variant.id as GallerySectionVariants],
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },
          {
            id: '123',
            name: 'MEDIA',
            category: 'TEXT',
            data: [
              {
                id: '1',
                title: itemTitle,
                description: itemDescription,
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-fea746c9-62b3-4eab-a7a6-71ef02a6342a',
              },
              {
                id: '2',
                title: itemTitle,
                description: itemDescription,
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-e98aaba2-83ad-4f14-a53c-70ede9654dea',
              },
              {
                id: '3',
                title: itemTitle,
                description: itemDescription,
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-85a7066f-b4eb-4836-b866-6c52a13ad8c8',
              },
              {
                id: '4',
                title: itemTitle,
                description: itemDescription,
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-3694a581-7c24-49a5-81a8-dac9429fd314',
              },
              {
                id: '5',
                title: itemTitle,
                description: itemDescription,
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-59710965-9bd1-4371-be18-76ad65d22ee3',
              },
              {
                id: '6',
                title: itemTitle,
                description: itemDescription,
                image:
                  'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-758c9195-7435-453b-aa49-df981ab6895e',
              },
            ] as GallerySectionItem[],
          },
        ],

        variant: variant.id as GallerySectionVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_11') {
      const data: Section<GallerySectionVariants, GallerySectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'GALLERY',
        updatedAt: '',
        tags: tags[variant.id as GallerySectionVariants],
        variables: [
          {
            id: '123',
            name: 'MEDIA',
            category: 'TEXT',
            data: [
              {
                id: '1',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '2',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '3',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '4',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '5',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '6',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '7',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
            ] as GallerySectionItem[],
          },
        ],

        variant: variant.id as GallerySectionVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    } else if (variant.id === 'VARIANT_12') {
      const title: Descendant[] = [{ type: 'paragraph', children: [{ text: 'Name' }] }]

      const data: Section<GallerySectionVariants, GallerySectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'GALLERY',
        updatedAt: '',
        variables: [
          { id: uuid(), category: 'TEXT', data: title, name: 'TITLE' },

          {
            id: '123',
            name: 'MEDIA',
            category: 'TEXT',
            data: [
              {
                id: '1',
                title: title,
                description: itemDescription,
                image: media,
              },
              {
                id: '2',
                title: title,
                description: itemDescription,
                image: media,
              },
              {
                id: '3',
                title: title,
                description: itemDescription,
                image: media,
              },
            ] as GallerySectionItem[],
          },
        ],
        variant: variant.id as GallerySectionVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      return sections.push(data)
    } else if (variant.id === 'VARIANT_13') {
      const description: Descendant[] = [
        {
          type: 'paragraph',
          children: [
            {
              text: 'Sweet carrot cake biscuit gummies lemon drops biscuit wafer apple pie jelly. Halvah gummi bears chupa chups candy canes sweet halvah tart sweet. Lemon drops sweet chocolate cake croissant topping cotton candy bonbon chocolate.    ',
            },
          ],
        },
      ]

      const data: Section<GallerySectionVariants, GallerySectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'GALLERY',
        updatedAt: '',
        variables: [
          {
            id: '123',
            name: 'MEDIA',
            category: 'TEXT',
            data: [
              {
                id: '1',
                title: itemTitle,
                description: description,
                image: media,
              },
              {
                id: '2',
                title: itemTitle,
                description: description,
                image: media,
              },
              {
                id: '3',
                title: itemTitle,
                description: description,
                image: media,
              },
              {
                id: '4',
                title: itemTitle,
                description: description,
                image: media,
              },
            ] as GallerySectionItem[],
          },
        ],
        variant: variant.id as GallerySectionVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      return sections.push(data)
    } else {
      const data: Section<GallerySectionVariants, GallerySectionVariables> = {
        id: variant.id,
        createdAt: '',
        isSection: true,
        type: 'GALLERY',
        updatedAt: '',
        tags: tags[variant.id as GallerySectionVariants],
        variables: [
          {
            id: '123',
            name: 'MEDIA',
            category: 'TEXT',
            data: [
              {
                id: '2',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '4',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
              {
                id: '5',
                title: itemTitle,
                description: itemDescription,
                image: media,
              },
            ] as GallerySectionItem[],
          },
        ],

        variant: variant.id as GallerySectionVariants,
        styleName: variant.name,
        designedBy: 'Plly Staff',
        ...DEFAULT_VARIANT_SETTINGS,
      }
      sections.push(data)
    }
  })

  return sections
}
