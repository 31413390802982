import { useEffect, useState } from 'react'
import useApp from 'src/store/app'
import { formatMenuLinks } from 'src/util/menu'
import FooterPreviewRenderer from 'src/_polly/components/src/footer/FooterPreviewRenderer'
import MenuPreviewRenderer from 'src/_polly/components/src/menu/MenuPreviewRenderer'
import { SectionPreviewRenderer } from 'src/_polly/components/src/sections'
import useWebsite from '../../store/website'
import Component from './Component'

function getWidth(columns: number, isSection: boolean) {
  const gap = '8px'

  if (columns === 4 || isSection) {
    return '100%'
  }

  if (columns === 3) {
    return `calc(75% - ${gap})`
  }

  if (columns === 2) {
    return `calc(50% - ${gap})`
  }

  return `calc(25% - ${gap})`
}

export default function LivePhonePreview() {
  const store = useWebsite(state => state)
  const appStore = useApp(state => state)
  const [iframeKey, setIframeKey] = useState(0)

  useEffect(() => {
    setIframeKey(iframeKey + 1)
  }, [store.mobilePreviewComponents])

  useEffect(() => {
    console.log('store.components', store.components)
  }, [store.components])

  return (
    <div className="relative hidden lg:block">
      {appStore.previewMode === 'mobile' ? (
        <div className="hidden h-full w-full lg:flex justify-center items-center bg-slate-200">
          <div className="no-scrollbar overflow-auto w-[400px] h-[750px] max-h-full border-8 border-gray-500 rounded-2xl bg-white">
            {store.components.length && store.website ? (
              <iframe
                src={`/preview/${store.website.id}`}
                frameBorder="0"
                width="384px"
                height="750px"
                title="Plly Preview"
                key={iframeKey}
              />
            ) : (
              <div className="flex justify-center items-center h-full">
                <p className="text-lg">
                  {store?.website ? 'Please add components to your website' : 'Select a website to preview'}
                </p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="h-screen container flex justify-center items-center bg-slate-200">
          <div
            className="overflow-auto w-[1000px] max-w-[90%] border-8 border-gray-500 rounded-2xl bg-white"
            style={{ aspectRatio: '16/9' }}
          >
            {store.components.length && store.website ? (
              <>
                <MenuPreviewRenderer
                  menu={{
                    ...store.website.newMenuConfig,
                    links: formatMenuLinks({ links: store.website.newMenu, website: store.website }),
                  }}
                  noShadow
                  disableHover
                  website={store.website}
                  scale={1.5}
                />

                <div className="flex flex-wrap">
                  {store.components.map((block: any) => (
                    <div
                      key={block.id}
                      style={{
                        width: getWidth(block.columns ?? 4, block?.isSection ?? false),
                        padding: block?.isSection ? '0' : '0 16px',
                      }}
                    >
                      {block?.isSection ? (
                        <SectionPreviewRenderer disableHover noShadow section={block} scale={2} />
                      ) : (
                        <Component data={block} />
                      )}
                    </div>
                  ))}
                </div>

                <FooterPreviewRenderer
                  footer={store.website.newFooter}
                  noShadow
                  disableHover
                  website={store.website}
                  scale={1.5}
                />
              </>
            ) : (
              <div className="flex justify-center items-center h-full">
                <p className="text-lg">
                  {store?.website ? 'Please add components to your website' : 'Select a website to preview'}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
