export default function PlaygroundPage() {
  return (
    <iframe
      src="http://localhost:3000/preview/fb428db1-7b56-4520-8be8-bd290e57770b"
      title="Plly"
      frameBorder="0"
      width="414px"
      height="896px"
    />
  )
}
