import SectionSlate from '../../../common/SectionSlatePreview'
import { QuoteSection as QuoteSectionType } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'

interface VariantOneProps {
  section: QuoteSectionType
}

export default function VariantThree({ section }: VariantOneProps) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data

  return (
    <div
      className="bg-gradient-to-t from-black via-transparent to-gray-900"
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div
        className={`bg-local flex flex-col  justify-center items-center bg-no-repeat bg-cover banner min-h-[300px] py-3 md:min-h-[80vh] brightness-90`}
        style={{
          backgroundImage: media ? `url(${media})` : '',
          backgroundPosition: 'center',
        }}
      >
        <div className="lg:w-3/5 w-80 md:px-12 px-4 space-y-1">
          <p className="text-white text-center text-lg md:text-4xl font-semibold md:font-bold tracking-tight">
            <SectionSlate initialValue={title} previewMode />
          </p>
          <p className="text-gray-50 md:text-2xl pb-4 text-center text-[14px] mt-2  md:mt-0">
            <SectionSlate initialValue={subTitle} previewMode />
          </p>
        </div>
      </div>
    </div>
  )
}
