import Icon from './icons'
import { IconPropsT } from './types'

function Love(props: IconPropsT) {
  const { title = 'Love', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 24 19" size={size} title={title} {...otherProps}>
      <path d="M17.1947 0.000443092C16.2059 -0.00462983 15.228 0.198272 14.3303 0.594776C13.4326 0.991279 12.637 1.57168 12 2.29487C11.3672 1.56699 10.5725 0.983312 9.67379 0.586381C8.77511 0.189449 7.79513 -0.0107277 6.80527 0.000443092C5.91151 0.000443083 5.02649 0.168784 4.20077 0.495854C3.37505 0.822923 2.6248 1.30231 1.99286 1.90665C1.36092 2.51099 0.859667 3.22843 0.51773 4.01801C0.175792 4.80759 -0.000133124 5.65384 7.55806e-08 6.50844C7.55806e-08 12.6664 11.2404 18.6798 11.6957 18.9116C11.7858 18.9692 11.8917 19 12 19C12.1083 19 12.2142 18.9692 12.3043 18.9116C12.7596 18.6788 24 12.7536 24 6.50844C24.0001 5.65384 23.8242 4.80759 23.4823 4.01801C23.1403 3.22843 22.6391 2.51099 22.0071 1.90665C21.3752 1.30231 20.6249 0.822923 19.7992 0.495854C18.9735 0.168784 18.0885 0.000443083 17.1947 0.000443092ZM12 17.7469C10.2383 16.7626 1.21704 11.5046 1.21704 6.50844C1.21731 5.09129 1.80612 3.73225 2.85403 2.73008C3.90194 1.72791 5.32317 1.16466 6.80527 1.16414C7.73514 1.15247 8.65277 1.36772 9.47141 1.78956C10.29 2.2114 10.9827 2.82589 11.4838 3.57494C11.5429 3.65287 11.6206 3.71629 11.7103 3.76002C11.8 3.80376 11.8993 3.82656 12 3.82656C12.1007 3.82656 12.2 3.80376 12.2897 3.76002C12.3794 3.71629 12.4571 3.65287 12.5162 3.57494C13.1756 2.61091 14.1431 1.87634 15.2745 1.48066C16.4059 1.08498 17.6407 1.04936 18.795 1.37911C19.9493 1.70886 20.9613 2.38634 21.6803 3.31062C22.3993 4.2349 22.7867 5.35653 22.785 6.50844C22.785 11.5628 13.7586 16.7878 12 17.7498" />
    </Icon>
  )
}

export default Love
