import { Auth } from 'aws-amplify'
import { useState } from 'react'
import AuthLayout from 'src/components/layout/AuthLayout'
import ConfirmCodeForm from 'src/components/signIn/ConfirmCodeForm'
import SignInForm from 'src/components/signIn/SigninForm'
import UnauthenticatedPage from 'src/components/UnauthenticatedPage'
import { createUser } from 'src/lib/services/user.service'
import { ISignUpUser } from '../lib/types/types'

type RegisterProps = {
  email?: string
  password?: string
  confirm?: boolean
}

export default function Register({ confirm: dConfirm, email: dEmail, password: dPassword }: RegisterProps) {
  const [confirm, setConfirm] = useState(dConfirm ?? false)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState(dEmail ?? '')
  const [pass, setPass] = useState(dPassword ?? '')
  const [error, setError] = useState('')

  const onSubmit = async (user: ISignUpUser) => {
    try {
      setLoading(true)
      const { userSub } = await Auth.signUp({
        username: user.email?.trim(),
        password: user.password?.trim(),
        attributes: {
          email: user.email?.trim(),
          name: `${user.firstName.trim()} ${user.lastName.trim()}`,
        },
      })
      await createUser({
        id: userSub,
        firstName: user.firstName.trim(),
        lastName: user.lastName.trim(),
        email: user.email.trim(),
      })
      setEmail(user.email)
      setPass(user.password)
      setConfirm(true)
    } catch (err: any) {
      setError(err?.message ?? 'Something went wrong')
      console.log(err?.message)
      setConfirm(false)
    } finally {
      setLoading(false)
    }
  }

  return (
    <UnauthenticatedPage>
      <AuthLayout>
        {!confirm ? (
          <SignInForm onSubmit={onSubmit} loading={loading} error={error} />
        ) : (
          <ConfirmCodeForm email={email} pass={pass} />
        )}
      </AuthLayout>
    </UnauthenticatedPage>
  )
}
