import { ReactNode, useState } from 'react'
import Delete from 'src/icons/Delete'
import { IoLogoFacebook, IoLogoInstagram, IoLogoTwitter, IoLogoYoutube } from 'react-icons/io5'
import { MenuSaveButton } from './NewMenu'

function Item({
  icon,
  onChange,
  placeholder,
  defaultValue,
}: {
  icon: ReactNode
  placeholder?: string
  defaultValue?: string
  onChange: (value: string) => void
}) {
  const [value, setValue] = useState(defaultValue ?? '')

  return (
    <div className="flex items-center">
      <div className="w-[58px]">{icon}</div>
      <input
        className="text-[19px] w-1/2 border-b-[1px] dark:text-white dark:bg-black dark:border-white border-black pb-[7px] outline-none text-left"
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={e => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
      />
    </div>
  )
}

type FooterSocialProps = {
  onSave: (value: any) => void
  defaultValue?: any
  onDelete?: (value: any) => void
}

export default function FooterSocial({ onSave, defaultValue, onDelete }: FooterSocialProps) {
  const [social, setSocial] = useState(defaultValue ?? {})

  return (
    <div className="mt-[142px] px-[18px]">
      <h3 className="text-[19px] dark:text-white border-black px-[2px] border-b-[1px] dark:border-white pb-[25px] mb-[46px]">
        Social Links
      </h3>
      <div className="space-y-6 border-b border-black dark:border-white pb-[41px]">
        <Item
          placeholder="Facebook"
          onChange={value => setSocial({ ...social, facebook: value })}
          defaultValue={social?.facebook}
          icon={<IoLogoFacebook size={28} className="fill-black dark:fill-white" />}
        />
        <Item
          placeholder="Twitter"
          onChange={value => {
            setSocial({ ...social, twitter: value })
          }}
          defaultValue={social?.twitter}
          icon={<IoLogoTwitter size={28} className="fill-black dark:fill-white" />}
        />
        <Item
          placeholder="Instagram"
          onChange={value => {
            setSocial({ ...social, instagram: value })
          }}
          defaultValue={social?.instagram}
          icon={<IoLogoInstagram size={28} className="fill-black dark:fill-white" />}
        />
        <Item
          placeholder="YouTube"
          onChange={value => {
            setSocial({ ...social, youtube: value })
          }}
          defaultValue={social?.youtube}
          icon={<IoLogoYoutube size={28} className="fill-black dark:fill-white" />}
        />
      </div>
      <div className="">
        <button type="button" onClick={() => onSave(social)} className="absolute top-[21px] right-[55px]">
          <MenuSaveButton save="save" />
        </button>
        <button type="button" className="absolute top-[21px] right-[20px]" onClick={onDelete}>
          <Delete height={23} fill="none" className="stroke-[#E90570]" />
        </button>
      </div>
    </div>
  )
}
