import clsx from 'clsx'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import InputText from '../../../common/InputText'
import SectionSlatePreview from '../../../common/SectionSlatePreview'
import TextArea from '../../../common/TextArea'
import { ContactUsSection, InputsContactUSSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import ButtonRenderer from '../ButtonRenderer'

function VariantTwelve({ section }: { section: ContactUsSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const inputs = section.variables.find(variant => variant.name === 'INPUTS')?.data as InputsContactUSSection[]
  const button = section.variables.find(variable => variable.name === 'BUTTON_1')?.data

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    setLoading(true)
    try {
    } catch (error) {
      setError('Something went wrong!')
    }
    setLoading(false)
  }
  const inputClasses = {
    classInput:
      'bg-[#f5f3f3] w-[100%] outline-none py-2 md:px-2 mt-3 px-1 placeholder:text-[12px] border-[1px] border-[#c1c0c0] resize-none',
    classLabel: ' inline-block ',
  }
  let indexIdentifier = 2

  return (
    <div
      className="grid md:grid-cols-2 grid-cols-1 pb-6"
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            duration: 1,
          },
        }}
        className="px-6 md:px-12 md:grid-cols-1"
      >
        <motion.p
          initial={{ opacity: 0, x: -20 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          className="xl:text-[2rem] lg:text-[1.5rem] text-3xl capitalize tracking-wider"
        >
          <SectionSlatePreview initialValue={title} previewMode />
        </motion.p>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full mt-12 ">
          {inputs && (
            <div
              className={`md:mb-8 gap-x-2 items-center gap-y-5 mx-auto text-sm grid-cols-2 space-y-2 lg:space-y-0 lg:grid md:mr-12 `}
            >
              {inputs.map(({ type, name, error, id, placeholder }, idx) => {
                if (idx === indexIdentifier) {
                  if (indexIdentifier % 9 == 0) {
                    indexIdentifier += 2
                  } else {
                    indexIdentifier += 1
                  }

                  return (
                    <div key={id} className="col-span-2 ">
                      {type !== 'button' && type !== 'textarea' && (
                        <InputText
                          {...inputClasses}
                          label={name}
                          name={name}
                          register={register}
                          type={type}
                          errors={errors}
                          required={{ value: true, message: `${error}` }}
                          placeholder={placeholder}
                        />
                      )}

                      {type === 'button' && (
                        <div className="w-full col-span-2 ">
                          <input
                            type={type}
                            value={name}
                            className="px-5 py-3 font-medium bg-black text-white cursor-pointer"
                          />
                        </div>
                      )}
                      {type === 'textarea' && (
                        <div className="w-full col-span-2 ">
                          <TextArea
                            {...inputClasses}
                            label={name}
                            name={name}
                            register={register}
                            type={type}
                            errors={errors}
                            required={{ value: true, message: `${error}` }}
                            placeholder={placeholder}
                          />
                        </div>
                      )}
                    </div>
                  )
                } else {
                  return (
                    <div key={id}>
                      {type !== 'button' && type !== 'textarea' && (
                        <div className="w-full">
                          <InputText
                            {...inputClasses}
                            label={name}
                            name={name}
                            register={register}
                            type={type}
                            errors={errors}
                            required={{ value: true, message: `${error}` }}
                            placeholder={placeholder}
                          />
                        </div>
                      )}

                      {type === 'button' && (
                        <div className="w-full">
                          <input
                            type={type}
                            value={name}
                            // placeholder={placeholder}
                            className="px-5 py-3 font-medium bg-black text-white cursor-pointer"
                          />
                        </div>
                      )}
                      {type === 'textarea' && (
                        <div className="w-full">
                          <TextArea
                            {...inputClasses}
                            label={name}
                            name={name}
                            register={register}
                            type={type}
                            errors={errors}
                            required={{ value: true, message: `${error}` }}
                            placeholder={placeholder}
                          />
                        </div>
                      )}
                    </div>
                  )
                }
              })}
            </div>
          )}
          <div className="btn-submit mt-6">
            {button ? (
              <ButtonRenderer button={button} typeOfButton="submit" withOutHref />
            ) : (
              <button
                disabled={loading}
                type="submit"
                className={clsx(
                  loading && 'cursor-not-allowed disabled:opacity-50',
                  ' outline-none border-2 border-black p-5  xl:w-[18%] lg:w-[22%] w-[40%] capitalize mt-[30px] text-xl',
                )}
              >
                Submit
              </button>
            )}
          </div>
          {error && <span className="text-secondary.main text-center mb-4">{error}</span>}
        </form>
      </motion.div>
    </div>
  )
}

export default VariantTwelve
