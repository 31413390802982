import SectionSlatePreview from '../../../common/SectionSlatePreview'
import { TeamMembersSection, TeamMembersSectionMember } from '../../types'
import { generateSectionBackground, generateSectionSpacing } from '../../utils'
import TeamSevenCard from './VariantDesign/TeamSevenCard'

export default function VariantSeven({ section }: { section: TeamMembersSection }) {
  const members = section.variables.find(variable => variable.name === 'TEAM_MEMBERS')
    ?.data as TeamMembersSectionMember[]
  const title = section.variables.find(i => i.name === 'TITLE')?.data

  return (
    <div
      className="flex flex-col items-center justify-center md:py-12 py-4"
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <div>
        <h1 className="text-2xl lg:text-[40px] text-center">
          <SectionSlatePreview initialValue={title} previewMode />
        </h1>
      </div>
      <div className="container px-12 ">
        <div className="flex flex-col items-center justify-center">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-7 md:gap-8 text-center md:justify-between lg:justify-between mt-3">
            {members.map(member => (
              <TeamSevenCard key={member.id} member={member} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
