import { Template } from '.'
import { love, text, aboutUs, contactUs } from './variants'

export const Template11: Template = {
  id: '11',
  pages: [
    {
      slug: '/',
      name: 'Work',
      blocks: [
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_1')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 60, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: [{ children: [{ color: '#5d67b1', text: 'Hits Different Investors' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'b3f9543d-4470-4339-811f-ce277e549bbe',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#000000',
                      text: 'At our agency, we prioritize user satisfaction, user retention, and user conversion. We offer website performance, website speed, website accessibility, website security, website maintenance, website optimization, website analytics, website testing, website updates, website improvements, and website enhancements to ensure your site is top-notch. With our high-octane mobile-friendly design and invigorating user-friendly interface, we ensure your site is cash flow positive, with a low burn rate and a high acquisition potential.',
                      bold: true,
                    },
                  ],
                  type: 'paragraph',
                },
                { children: [{ color: '#000000', bold: true, text: '' }], type: 'paragraph' },
              ],
              name: 'PARAGRAPH',
              id: '454c9ecf-ac2f-4f0d-b3fc-4af4c4a24163',
              category: 'TEXT',
            },
            {
              data: [
                { children: [{ color: '#a5a5a5', text: 'Disruptive Design for Acquisition' }], type: 'paragraph' },
              ],
              name: 'SUBTITLE',
              id: 'b92d44be-0786-4e0e-a81e-d2c70b516f27',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_1',
          type: 'TEXT',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_14')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'f5d4f8ca-fb52-4e0d-a7d9-5d03481f794d',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Sub Title' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '89109e8d-4a34-4bb9-b001-597b0661b44e',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: '32480deb-668c-40c2-b3b3-d20f057f0049',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'MARGIN',
              id: '5a522933-1e0a-453d-9965-4561691770e2',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'PADDING',
              id: 'feb87bb8-6217-45ce-b1c0-4ad1659cab85',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
              name: 'MEDIA',
              id: '9f3c9ec3-5b73-40f8-9c63-62f570b9346e',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-a416a289-9477-4496-bfcc-284fe3a760eb',
                  name: [{ children: [{ color: '#797979', text: ' Hits Different Investors' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '1',
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-b9512603-5dc8-43f4-ba14-5f79bb636572',
                  name: [{ children: [{ color: '#797979', text: ' Hits Different Investors' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '2',
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-6f7f7bcc-d1e5-4b73-b963-157ab2e454e8',
                  name: [{ children: [{ color: '#797979', text: ' Hits Different Investors' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: '5f4bd95b-449e-428b-a8fa-ce1b086c7001',
              category: 'IMAGE',
            },
          ],
          variant: 'VARIANT_14',
          type: 'TEXT',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_14')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Title' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '37a00456-e9c3-48c3-a145-5e3de24f3337',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Sub Title' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '38bd9d98-49b3-44e6-bd54-91f28415e34e',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Paragraph' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: '67f8294a-3f4f-4ef5-a2bb-4d95b6ea8ec1',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'MARGIN',
              id: '0f5db12b-32bc-4157-aa74-7b265a33b9e1',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'PADDING',
              id: 'd927a6e8-9de8-4f05-95bd-3939e00ad87d',
              category: 'TEXT',
            },
            {
              data: 'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
              name: 'MEDIA',
              id: 'beb64569-597e-4ca0-a915-d721ddc86bd6',
              category: 'IMAGE',
            },
            {
              data: [
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-1b25d51d-4117-4e88-a8ec-7e6fd9022345',
                  name: [{ children: [{ color: '#797979', text: ' Hits Different Investors' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '1',
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-31f448f3-c54c-4a41-96b0-7198429a6278',
                  name: [{ children: [{ color: '#797979', text: ' Hits Different Investors' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '2',
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-0bedbb55-75a3-4351-9d57-745387dd9be5',
                  name: [{ children: [{ color: '#797979', text: ' Hits Different Investors' }], type: 'paragraph' }],
                  description: [{ children: [{ text: '' }], type: 'paragraph' }],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: '5e7071dd-c85e-4e84-a834-08ffd2202534',
              category: 'IMAGE',
            },
          ],
          variant: 'VARIANT_14',
          type: 'TEXT',
        },
      ],
    },
    {
      slug: '/about-us',
      name: 'About us',
      blocks: [
        {
          settings: {
            ...aboutUs.find(i => i.variant === 'VARIANT_6')?.settings,
            spacing: {
              padding: { top: 30, left: 0, bottom: 30, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            { data: [], name: 'SERVICE', id: '37dce2ed-cde2-4415-906f-d83597ac3d47', category: 'TEXT' },
            {
              data: 'https://images.unsplash.com/photo-1568602471122-7832951cc4c5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjM2MzV8MHwxfHNlYXJjaHwxfHxtYW58ZW58MHx8fHwxNjgxNjI1MTg3&ixlib=rb-4.0.3&q=80&w=1080',
              name: 'MEDIA',
              id: '022a8192-8146-40ac-a887-eb29a2f8af96',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ color: '#4c92c7', text: 'Calligraphy' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '400fa3ab-6c88-406f-87c9-64fb7190cf36',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#1d38a5', text: 'Omar Kamal' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'f59df1f4-00e4-4bd9-8de1-282f4fd34c01',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#000000', text: '' }], type: 'paragraph' }],
              name: 'SECTION_1',
              id: '7339d90a-dedf-4ed3-9f16-0abda8eb4ea8',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#000000',
                      text: 'Fire Design: Fire Design is a full-service investment firm with a passion for innovative design. We invest in companies that prioritize exceptional user experience and visual appeal. Our team of experienced designers and investors work hand-in-hand to support the growth and success of our portfolio companies.Fire Design: Fire Design is a full-service investment firm with a passion for innovative design. We invest in companies that prioritize exceptional user experience and visual appeal. Our team of experienced designers and investors work hand-in-hand to support the growth and success of our portfolio companies.Fire Design',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'PARAGRAPH',
              id: '4180e88a-b512-4e08-87a9-c4d1a22b7de7',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_6',
          type: 'ABOUT_US',
        },
      ],
    },
    {
      slug: '/art',
      name: 'Art',
      blocks: [
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [{ children: [{ text: 'Hits Different Investors' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '1cf2123a-ba11-47ed-9b28-86b7d21c4395',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-5c7a86e6-c209-4eb9-ac9d-c71a152c45b7',
              name: 'MEDIA',
              id: 'adf625bf-e096-4489-8874-9824881738cb',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'Snatched Crowdfunding UX' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: '83d6c21d-3742-4a6f-9dc1-16d014522122',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'TEXT',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'MARGIN',
              id: '3f843bc5-37b9-447a-b7a9-ec184bb9e980',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'PADDING',
              id: '386edf6a-37ea-46b3-817a-d045b2531d70',
              category: 'TEXT',
            },
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: '8951f38c-11c6-40f5-8ef7-e674669ab958',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: '' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '75e33a6c-d819-4e77-a4fa-55b62aff62f8',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-5d78573b-362c-4afc-bcbf-b7c9e26895bb',
              name: 'MEDIA',
              id: '7579b60e-f93b-4838-9a6e-5da764f7c368',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'Disruptive Design for Acquisition' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'ff25fdf5-4f6f-4aa0-acd5-02b652cfa99a',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Snatched Crowdfunding UX' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: '5029419a-634e-41ba-976d-4bf9124d622e',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'TEXT',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_2')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#ffffff', enabled: true } },
          },
          variables: [
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'MARGIN',
              id: 'd15429d3-2e2f-429c-a90a-b3735342144d',
              category: 'TEXT',
            },
            {
              data: { top: 20, left: 15, bottom: 10, right: 25 },
              name: 'PADDING',
              id: '004375d8-8d04-4d7c-b9c2-ab908a82902a',
              category: 'TEXT',
            },
            {
              data: [
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '1',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '2',
                },
                {
                  image:
                    'https://images.unsplash.com/photo-1660236822651-4263beb35fa8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
                  name: [{ children: [{ text: 'loream iteam' }], type: 'paragraph' }],
                  description: [
                    {
                      children: [
                        {
                          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, eaque rerum! Provident similique accusantium nemo autem.Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: '7dfc6b3e-0af4-4f01-8b37-ecca00e0145c',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: '' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: '064eabaa-8cb7-4c57-b708-881019450020',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-1d29f4d9-271e-4446-8b0c-16aeb8a88274',
              name: 'MEDIA',
              id: '7ebcb0ab-ff49-4ed2-a2a7-ec4a01451f2b',
              category: 'IMAGE',
            },
            {
              data: [{ children: [{ text: 'TBH BRAND REPUTATION' }], type: 'paragraph' }],
              name: 'TITLE',
              id: 'e887e136-5653-47f1-96a1-9a025bbe69a5',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ text: 'Accelerate Your User Engagement' }], type: 'paragraph' }],
              name: 'PARAGRAPH',
              id: '785ca777-1040-45d3-8c03-5d77a8007887',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_2',
          type: 'TEXT',
        },
      ],
    },
    {
      slug: '/market',
      name: 'Market',
      blocks: [
        {
          settings: {
            ...love.find(i => i.variant === 'VARIANT_8')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 0, right: 0 },
              enabled: true,
            },
            color: { background: { value: '#E8E8E8', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  children: [{ color: '#115489', className: 'uppercase', text: 'Select your favorite ' }],
                  type: 'paragraph',
                },
                { children: [{ color: '#000000', className: 'uppercase', text: 'piece' }], type: 'paragraph' },
              ],
              name: 'TITLE',
              id: 'e168e0cc-8c4a-459c-b04f-ae314ec00004',
              category: 'TEXT',
            },
            {
              data: [{ children: [{ color: '#7f7f7f', text: 'Accelerate Your User Engagement' }], type: 'paragraph' }],
              name: 'SUBTITLE',
              id: 'f0b3adb9-d203-47fe-953b-f5dde41052e8',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_8',
          type: 'ATTENTION_GRABBER',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_14')?.settings,
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-a416a289-9477-4496-bfcc-284fe3a760eb',
                  name: [{ children: [{ text: 'Hits Different Investors' }], type: 'paragraph' }],
                  description: [{ children: [{ color: '#a84a4a', text: '500$' }], type: 'paragraph' }],
                  id: '1',
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-b9512603-5dc8-43f4-ba14-5f79bb636572',
                  name: [{ children: [{ text: 'Hits Different Investors' }], type: 'paragraph' }],
                  description: [{ children: [{ color: '#a84a4a', text: '500$' }], type: 'paragraph' }],
                  id: '2',
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-6f7f7bcc-d1e5-4b73-b963-157ab2e454e8',
                  name: [{ children: [{ text: 'Hits Different Investors' }], type: 'paragraph' }],
                  description: [{ children: [{ color: '#a84a4a', text: '500$' }], type: 'paragraph' }],
                  id: '3',
                },
              ],
              name: 'MEDIAGROUP',
              id: 'a5479fbd-db06-4452-b593-68c7e4bfe811',
              category: 'IMAGE',
            },
          ],
          variant: 'VARIANT_14',
          type: 'TEXT',
        },
        {
          settings: {
            ...text.find(i => i.variant === 'VARIANT_14')?.settings,
            spacing: {
              padding: { top: 0, left: 0, bottom: 0, right: 0 },
              margin: { top: 0, left: 0, bottom: 30, right: 0 },
              enabled: true,
            },
          },
          variables: [
            {
              data: [
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-55d84ca3-f1a7-4ff2-b965-0494466549e1',
                  name: [
                    { children: [{ color: '#4e72b1', text: 'Captivating UX, Cash Flow Positive' }], type: 'paragraph' },
                  ],
                  description: [{ children: [{ color: '#bf0101', text: '100$' }], type: 'paragraph' }],
                  id: '1',
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-df0d1a83-c18f-4aeb-93af-732a9a0194f6',
                  name: [
                    { children: [{ color: '#3d66a9', text: 'Captivating UX, Cash Flow Positive' }], type: 'paragraph' },
                  ],
                  description: [{ children: [{ color: '#cc0101', text: '50$' }], type: 'paragraph' }],
                  id: '2',
                },
                {
                  image:
                    'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-fa408e5a-df3d-4de8-99bf-2b49d2e8ed99',
                  name: [
                    { children: [{ color: '#31508c', text: 'Captivating UX, Cash Flow Positive' }], type: 'paragraph' },
                  ],
                  description: [{ children: [{ color: '#a80000', text: '80$' }], type: 'paragraph' }],
                  id: '3',
                },
              ],

              name: 'MEDIAGROUP',
              id: 'a367f86b-5617-492b-8198-0e6100e74a5c',
              category: 'IMAGE',
            },
          ],
          variant: 'VARIANT_14',
          type: 'TEXT',
        },
      ],
    },
    {
      slug: '/contact-us',
      name: 'Contact us',
      blocks: [
        {
          settings: {
            ...contactUs.find(i => i.variant === 'VARIANT_11')?.settings,
            color: { background: { value: '#fbfbfb', enabled: true } },
          },
          variables: [
            {
              data: [
                {
                  name: 'First Name',
                  id: '919e2e8a-71af-4419-aae5-8b8aebdfd4cf',
                  placeholder: 'First Name',
                  type: 'text',
                  error: 'First Name is required',
                },
                {
                  name: 'Last Name',
                  id: 'df4124d6-e83a-4414-88ea-9a13235bc1ae',
                  placeholder: 'Last Name',
                  type: 'text',
                  error: 'Last Name is required',
                },
                {
                  name: 'Email',
                  id: 'b608e0b2-b24b-42a1-8726-03eeee7eb440',
                  placeholder: 'Email',
                  type: 'email',
                  error: 'email is required',
                },
                {
                  name: 'Number',
                  id: '3b266ca3-094d-4fb7-9a8a-0beeaf9955ad',
                  placeholder: 'Number',
                  type: 'number',
                  error: 'number is required',
                },
              ],
              name: 'INPUTS',
              id: '5792df10-34b7-4ef3-8700-42965c808913',
              category: 'TEXT',
            },
            {
              data: [
                {
                  children: [
                    {
                      color: '#000000',
                      text: 'Our experience showcasing unicorn investors and influencers means that we know exactly what it takes to optimize your website for maximum ROI. ',
                    },
                  ],
                  type: 'paragraph',
                },
              ],
              name: 'SUBTITLE',
              id: '3c5065ab-2efe-46a8-a75e-673ddd039152',
              category: 'TEXT',
            },
            {
              data: 'https://polly02935-stage.s3.us-east-2.amazonaws.com/public/assets/image-1ee4fb1a-9454-40cb-8dbe-b25f37b3742d',
              name: 'MEDIA',
              id: '7fcffda6-ce12-4132-8b9d-b26b925c39f8',
              category: 'IMAGE',
            },
            {
              data: {
                settings: {
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderColor: '#ffffff',
                  backgroundColor: '#2d557f',
                  borderRadius: '0px',
                  color: '#ffffff',
                  borderWidth: '1px',
                  display: 'block',
                  width: '249px',
                  fontSize: '30px',
                  height: '60px',
                  maxWidth: '100%',
                },
                visible: true,
                link: null,
                value: 'send message',
              },
              name: 'BUTTON_1',
              id: 'b7f26935-0751-4341-b563-7dbdad855ac2',
              category: 'BUTTON',
            },
            {
              data: [{ children: [{ color: '#3877a6', text: 'Contact us' }], type: 'paragraph' }],
              name: 'TITLE',
              id: '05b12fc1-bd9b-4705-a4b8-18b13c57357f',
              category: 'TEXT',
            },
          ],
          variant: 'VARIANT_11',
          type: 'CONTACT_US',
        },
      ],
    },
  ],
  newMenu: [
    { name: 'Work', type: 'internal', url: '/' },
    { name: 'About Us', type: 'internal', url: '/about-us' },
    { name: 'Art', type: 'internal', url: '/art' },
    { name: 'Market', type: 'internal', url: '/market' },
    { name: 'Contact us', type: 'internal', url: '/contact-us' },
  ],
  newMenuConfig: {
    settings: {
      backgroundColor: { color: '#5a71a5', enabled: true },
      spacing: {
        padding: { top: 0, left: 0, bottom: 0, right: 0 },
        margin: { top: 0, left: 0, bottom: 0, right: 0 },
        enabled: true,
      },
      textColor: { color: '#ffffff', enabled: true },
    },
    links: [
      { name: 'Home', id: '0f9b4b94-db36-469d-92dc-6207b7720adb', type: 'internal', url: '/' },
      { name: 'Contact Us', id: 'ed6802a5-015e-4ff4-96b4-a10f3a6cd60e', type: 'email', url: 'hello@example.com' },
    ],
    styleName: 'VARIANT 19',
    id: 'VARIANT_19',
    tags: [],
  },
  newFooter: {
    settings: {
      backgroundColor: { color: '#5A71A5', enabled: true },
      spacing: {
        padding: { top: 30, left: 0, bottom: 30, right: 0 },
        margin: { top: 0, left: 0, bottom: 0, right: 0 },
        enabled: true,
      },
    },
    designedBy: 'Plly Staff',
    id: 'VARIANT_3',
    categories: [
      {
        name: 'Explore',
        links: [{ name: 'Home', id: 'c24e2035-ae5c-473d-9dda-6dc72208225e', type: 'internal', url: '/' }],
        id: 'a6a01d86-6145-4410-9aa8-09b4894832a8',
      },
    ],
    tags: [],
  },
  links: [],
}
