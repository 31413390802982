import { useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import PreviewSaveButton from 'src/components/PreviewSaveButton'
import Preview from './Preview'
import TitleBar from './TitleBar'

type ColorSelectorProps = {
  title: string
  color: string
  onChange: (color: string) => void
  enabled?: boolean
  onEnabledChange?: (enabled: boolean) => void
  onBack?: () => void
}

export default function ColorSelector({
  title,
  color,
  onChange,
  enabled,
  onEnabledChange,
  onBack,
}: ColorSelectorProps) {
  const [showPreview, setShowPreview] = useState(false)
  const [colorCode, setColorCode] = useState(color ?? '#000000')

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    let input = e.target.value

    if (!input.startsWith('#')) {
      input = `#${input}`
    }

    if (/^#[0-9A-F]{6}$/i.test(input)) {
      onChange(input)
      setColorCode(input)
      return
    }

    setColorCode(color)
  }

  return (
    <div className="px-4">
      <TitleBar {...{ title }} showSwitch checked={enabled} onChange={onEnabledChange} />
      <HexColorPicker
        color={color}
        onChange={newColor => {
          onChange(newColor)
          setColorCode(newColor)
        }}
        style={{ width: '100%', marginTop: '20px' }}
      />

      <div className="mt-4">
        <input
          type="text"
          className="border-2 border-black rounded px-2 py-1 text-lg w-28 uppercase bg-transparent dark:border-white dark:text-white"
          value={colorCode}
          onChange={e => {
            setColorCode(e.target.value)
          }}
          onBlur={handleBlur}
          onKeyUp={e => {
            if (e.key === 'Enter') {
              handleBlur(e as any)
            }
          }}
        />
      </div>

      <Preview onBack={() => setShowPreview(false)} open={showPreview} />
      <PreviewSaveButton onPreviewClick={() => setShowPreview(true)} onSaveClick={onBack} />
    </div>
  )
}
