import Icon from '../icons'
import { IconPropsT } from '../types'

function DmUs(props: IconPropsT) {
  const { title = 'DmUs', size, ...otherProps } = props
  return (
    <Icon viewBox="0 0 26 16" size={size} title={title} {...otherProps}>
      <path d="M0.0520001 8.952V0.959999H0.97V8.844C0.97 10.716 1.276 12.111 1.888 13.029C2.518 13.947 3.463 14.406 4.723 14.406C5.569 14.406 6.379 14.217 7.153 13.839C7.945 13.461 8.638 12.93 9.232 12.246C9.826 11.544 10.258 10.752 10.528 9.87V0.959999H11.446V13.596C11.446 13.776 11.491 13.92 11.581 14.028C11.671 14.136 11.788 14.19 11.932 14.19V15C11.806 15 11.698 15 11.608 15C11.536 15 11.473 14.991 11.419 14.973C11.185 14.919 10.987 14.793 10.825 14.595C10.681 14.379 10.609 14.136 10.609 13.866L10.582 11.544C9.97 12.696 9.124 13.605 8.044 14.271C6.964 14.937 5.794 15.27 4.534 15.27C3.058 15.27 1.942 14.739 1.186 13.677C0.43 12.615 0.0520001 11.04 0.0520001 8.952ZM20.1145 15.27C19.0345 15.27 18.0265 15.09 17.0905 14.73C16.1725 14.352 15.3625 13.776 14.6605 13.002L15.1465 12.354C15.9025 13.074 16.6765 13.605 17.4685 13.947C18.2605 14.289 19.1335 14.46 20.0875 14.46C21.4015 14.46 22.4635 14.181 23.2735 13.623C24.0835 13.065 24.4885 12.291 24.4885 11.301C24.4885 10.653 24.2995 10.149 23.9215 9.789C23.5615 9.429 23.0305 9.141 22.3285 8.925C21.6445 8.691 20.8075 8.457 19.8175 8.223C18.8455 7.971 18.0355 7.728 17.3875 7.494C16.7575 7.242 16.2805 6.909 15.9565 6.495C15.6325 6.081 15.4705 5.505 15.4705 4.767C15.4705 3.831 15.6955 3.075 16.1455 2.499C16.6135 1.905 17.2255 1.464 17.9815 1.176C18.7555 0.888 19.6015 0.744 20.5195 0.744C21.6355 0.744 22.5985 0.933 23.4085 1.311C24.2185 1.689 24.8035 2.166 25.1635 2.742L24.5695 3.255C24.1735 2.697 23.5975 2.274 22.8415 1.986C22.1035 1.698 21.2935 1.554 20.4115 1.554C19.6915 1.554 19.0255 1.662 18.4135 1.878C17.8015 2.094 17.3065 2.427 16.9285 2.877C16.5505 3.309 16.3615 3.894 16.3615 4.632C16.3615 5.226 16.4965 5.685 16.7665 6.009C17.0365 6.333 17.4415 6.594 17.9815 6.792C18.5215 6.972 19.2055 7.17 20.0335 7.386C21.1315 7.656 22.0765 7.935 22.8685 8.223C23.6785 8.493 24.2995 8.853 24.7315 9.303C25.1635 9.753 25.3795 10.383 25.3795 11.193C25.3795 12.453 24.8935 13.452 23.9215 14.19C22.9675 14.91 21.6985 15.27 20.1145 15.27Z" />
    </Icon>
  )
}

export default DmUs
