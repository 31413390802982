import clsx from 'clsx'
import React, { HTMLInputTypeAttribute } from 'react'
import { FieldErrors, FieldValues, Path, UseFormRegister } from 'react-hook-form'

interface InputProps {
  label?: string
  name: Path<FieldValues>
  placeholder?: string
  type?: HTMLInputTypeAttribute
  register: UseFormRegister<FieldValues>
  classInput?: string
  classLabel?: string
  errors?: FieldErrors<FieldValues>
  defaultValue?: any
  settings?: boolean
  authInput?: boolean
}

const InputText: React.FC<InputProps & any> = ({
  register,
  label,
  name,
  required,
  errors,
  settings,
  type = 'text',
  classInput = 'text-black font-extralight text-3xl w-full border border-black px-4 py-4',
  classLabel = 'text-black font-extralight text-3xl inline-block pb-3 mb-2',
  placeholder,
  authInput = false,
  ...props
}) => (
  <>
    {!authInput && !settings && (
      <label className={clsx(classLabel)}>
        {label}
        {required && <span className="text-secondary.main">*</span>}
      </label>
    )}
    <input
      {...register(name, { required, ...props })}
      type={type}
      className={clsx(
        !settings && !authInput && classInput,
        settings &&
          'text-[19px] w-full border-b dark:text-white dark:bg-black dark:border-white border-black pb-[18px] mx-auto outline-none text-left',
        authInput && 'w-full px-5 py-2.5 bg-white text-[15px] rounded focus:outline-none outline-0',
      )}
      placeholder={placeholder}
    />
    {errors?.[name] && (
      <span className={`text-secondary.main ${settings && 'w-full mx-auto'} ${authInput && 'text-[15px]'}`}>
        {errors?.[name]?.message}
      </span>
    )}
  </>
)

export default InputText
