import { BiChat } from 'react-icons/bi'
import FontLoader from '../../menu/FontLoader'
import { getBackgroundColor, getFontFamily, getSpacing, getTextColor } from '../../menu/utils'
import { FooterVariantProps, getSocialLabel, NewFooter, Social } from '../footer'

const SocialItem = ({ type, link, footer }: { type: Social; link: string; footer: NewFooter }) => {
  const label = getSocialLabel(type)

  return (
    <a
      style={{
        ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
        ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'black' }),
      }}
      href={link}
      target="_blank"
      className="flex items-center hover:underline"
    >
      <p>{label}</p>
    </a>
  )
}

export default function VariantNine({ footer, website }: FooterVariantProps) {
  const handleEmail = () => {
    window.open(`mailto:${website?.config?.email}?subject=&body=`)
  }
  return (
    <div
      style={{
        ...getBackgroundColor({ settings: footer?.settings?.backgroundColor, defaultValue: 'black' }),
        ...getSpacing(footer?.settings?.spacing),
      }}
    >
      <FontLoader settings={footer?.settings?.fontFamily} />

      <div className="text-white px-6 md:px-12 pt-8 md:pt-12">
        <div className="container mx-auto">
          <div
            className="flex justify-around flex-wrap gap-y-3"
            style={{
              ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
              ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
            }}
          >
            <div>
              <a href="/">
                {website?.config?.logo ? (
                  <img
                    style={{ height: '30px', width: 'auto' }}
                    className="cursor-pointer"
                    src={website?.config?.logo}
                    alt={website?.name}
                  />
                ) : (
                  <p
                    className="uppercase font-medium md:text-3xl text-lg"
                    style={{
                      ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
                      ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
                    }}
                  >
                    {website?.name}
                  </p>
                )}
              </a>
            </div>

            {footer.categories
              .filter(i => i.links.length > 0)
              .map(categorty => (
                <div
                  className="w-full md:w-auto "
                  style={{
                    ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
                    ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
                  }}
                >
                  <p className="text-sm font-bold mb-3">{categorty.name}</p>
                  <ul className="space-y-1 m-0 p-0 list-none">
                    {categorty.links.map(link => (
                      <li>
                        <a className="hover:underline" href={link.url}>
                          {link.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}

            <div
              className="w-full md:w-auto"
              style={{
                ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
                ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
              }}
            >
              <p className="text-sm font-bold mb-3">Follow Us</p>
              <ul className="space-y-1 m-0 p-0 list-none">
                {website?.config?.social &&
                  Object.keys(website.config.social).map(item => (
                    <SocialItem footer={footer} type={item as Social} link={website.config.social[item]} />
                  ))}
              </ul>
            </div>
          </div>
        </div>
        <div
          className="flex justify-end py-3"
          style={{
            ...getFontFamily({ settings: footer?.settings?.fontFamily, defaultValue: 'inherit' }),
            ...getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }),
          }}
        >
          <button
            onClick={handleEmail}
            className="w-[40px] h-[40px] flex justify-center items-center rounded-full bg-[#297ef6]"
            type="button"
          >
            <BiChat
              size={20}
              color={getTextColor({ settings: footer?.settings?.textColor, defaultValue: 'white' }).color}
            />
          </button>
        </div>
      </div>
    </div>
  )
}
