import { motion } from 'framer-motion'
import GoogleFontLoader from 'react-google-font-loader'
import SectionSlate from '../../../common/SectionSlatePreview'
import { AttentionGrabberSection } from '../../types'
import { generateSectionBackground, generateSectionSpacing, getTransitionDuration } from '../../utils'
import CloudSVG from './CloudSVG'

export default function VariantOne({ section }: { section: AttentionGrabberSection }) {
  const title = section.variables.find(variable => variable.name === 'TITLE')?.data
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')?.data
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')?.data
  const media = section.variables.find(variable => variable.name === 'MEDIA')?.data

  return (
    <div
      className="relative mx-auto overflow-x-hidden"
      style={{
        ...generateSectionBackground({
          defaultColor: 'white',
          backgroundSettings: section?.settings?.color?.background,
          image: null,
        }),
        ...generateSectionSpacing(section?.settings?.spacing),
      }}
    >
      <GoogleFontLoader
        fonts={[
          {
            font: 'Monoton',
            weights: [400],
          },
        ]}
      />
      <div
        className="jumbotron overflow-hidden flex items-center bg-cover bg-center bg-no-repeat bg-black m-0 min-h-[350px] md:min-h-[500px] w-full lg:w-full lg:h-screen text-center"
        style={{
          backgroundImage: media ? `url('${media}')` : '',
        }}
      >
        <div className="w-full pr-4 pl-4 mr-auto ml-auto text-color-black">
          <div className="relative flex flex-col items-center justify-center">
            <p className="font-bold md:text-9xl text-3xl md:tracking-wide text-gray-50">
              <SectionSlate initialValue={title} previewMode />
            </p>
            <div className="lg:mt-4 mt-2 dark:text-black text-sm lg:text-2xl font-semibold">
              <SectionSlate initialValue={subTitle} previewMode />
            </div>
            <div className="lg:mt-2 mt-1 dark:text-black">
              <SectionSlate initialValue={paragraph} previewMode />
            </div>
          </div>
          <div className="relative lg:-top-96 -top-40 text-black">
            <motion.div
              key={(section?.settings?.transitionSpeed?.value ?? 0) + Math.floor(Math.random() * 999)}
              initial={{ x: '-50%' }}
              animate={{
                x: '100%',
                transition: {
                  ease: 'linear',
                  duration: getTransitionDuration({ settings: section?.settings?.transitionSpeed, defaultValue: 9 }),
                  repeat: Infinity,
                },
              }}
              className="absolute w-full -top-40 md:top-[60px]"
            >
              {/* <img src="https://techboom.ai/images/cloud1%20(1).png" /> */}
              <CloudSVG />
            </motion.div>
            <motion.div
              key={(section?.settings?.transitionSpeed?.value ?? 0) + Math.floor(Math.random() * 999)}
              initial={{ x: '-90%' }}
              animate={{ x: '100%' }}
              transition={{
                ease: 'linear',
                duration: getTransitionDuration({ settings: section?.settings?.transitionSpeed, defaultValue: 9 }) + 6,
                repeat: Infinity,
              }}
              className="absolute w-full -top-40 md:top-[-2px]"
            >
              <CloudSVG />
            </motion.div>
            <motion.div
              key={(section?.settings?.transitionSpeed?.value ?? 0) + Math.floor(Math.random() * 999)}
              initial={{ x: '-90%' }}
              animate={{ x: '100%' }}
              transition={{
                ease: 'linear',
                duration: getTransitionDuration({ settings: section?.settings?.transitionSpeed, defaultValue: 9 }) + 1,
                repeat: Infinity,
              }}
              className="absolute w-full -top-40 md:top-[30px]"
            >
              <CloudSVG />
            </motion.div>
            <motion.div
              key={(section?.settings?.transitionSpeed?.value ?? 0) + Math.floor(Math.random() * 999)}
              initial={{ x: '-50%' }}
              animate={{ x: '100%' }}
              transition={{
                ease: 'linear',
                duration: getTransitionDuration({ settings: section?.settings?.transitionSpeed, defaultValue: 9 }) + 16,
                repeat: Infinity,
              }}
              className="absolute w-full -top-40 md:top-[35px]"
            >
              <CloudSVG />
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  )
}
