import clsx from 'clsx'
import { Descendant } from 'slate'
import { useContext, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import AboutUs from 'src/icons/ComponentsIcons/About'
import {
  AboutUsSection,
  AboutUsSectionServices,
  AboutUsVariables,
  SectionVariableCategories,
} from 'src/_polly/components/src/sections'
import { v4 as uuid } from 'uuid'
import { ReactComponent as DeleteIcon } from '../../assets/icons/menuBar/delete.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/menuBar/edit.svg'
import { upsertBlockByPageID } from '../../services/website.service'
import BottomFloatingButton from '../BottomFloatingButton'
import { WebsiteContext } from '../context/WebsiteContext'
import { SectionSlate } from '../editor'
import { ImageIcon } from '../editor/icons'
import { AdvancedFeature } from '../modal/advanced'
import { MenuSaveButton } from '../modal/section/NewMenu/NewMenu'
import { ButtonForSection } from './ButtonEditor'
import ImageUploader from './ImageUploader'
import ModalDialogV2 from './ModalDialogV2'
import SectionMediaField from './SectionField/SectionMediaField'
import SectionTextField from './SectionField/SectionTextField'
import ItemField from './SectionField/SectionSingleItemField'

type EditSection = {
  open: boolean
  section: AboutUsSection | null
  onClose: () => void
  onUpdate?: (section: AboutUsSection) => void
}

type EditServiceProps = {
  data?: AboutUsSectionServices
  onSave: (data: Partial<AboutUsSectionServices>) => void
  variantName: string
}

type FormInput = {
  name: any
  description?: any
  title?: any
}

function EditServices({ data, onSave, variantName }: EditServiceProps) {
  const { handleSubmit } = useForm<FormInput>()
  const [showImageModal, setShowImageModal] = useState(false)
  const [url, setUrl] = useState(data?.image || '')
  const initialDataName: Descendant[] = [
    {
      type: 'paragraph',
      children: [
        {
          text: 'item name',
        },
      ],
    },
  ]
  const initialDataDescription: Descendant[] = [
    {
      type: 'paragraph',
      children: [
        {
          text: 'item description',
        },
      ],
    },
  ]
  const [titles, setTitle] = useState(data?.title || initialDataName)
  const [nameItem, setNameItem] = useState(data?.title || initialDataName)
  const [descriptions, setDescription] = useState(data?.description || initialDataDescription)

  const onSubmit: SubmitHandler<FormInput> = value => {
    onSave({
      ...value,
      id: data?.id,
      title: titles,
      description: descriptions,
      image: url,
      name: nameItem,
    })
    setTitle(value?.title)
    setDescription(value?.description)
    setNameItem(value?.name)
    setUrl(url)
  }

  return (
    <div className="px-4 dark:text-white">
      <form className="pb-24" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-8">
          {variantName === 'VARIANT_14' ? (
            <div>
              <SectionTextField
                title="Subtitle"
                initialValue={nameItem ?? initialDataName}
                onChange={value => setNameItem(value)}
                onUpdate={() => handleSubmit(onSubmit)}
              />
            </div>
          ) : (
            <div>
              <SectionTextField
                title="Name"
                initialValue={nameItem ?? initialDataName}
                onChange={value => setNameItem(value)}
                onUpdate={() => handleSubmit(onSubmit)}
              />
            </div>
          )}
          {variantName === 'VARIANT_14' && (
            <div>
              <SectionTextField
                title="Title"
                initialValue={titles ?? initialDataName}
                onChange={value => setTitle(value)}
                onUpdate={() => handleSubmit(onSubmit)}
              />
            </div>
          )}

          {(variantName === 'VARIANT_11' || variantName === 'VARIANT_14' || variantName === 'VARIANT_15') && (
            <div>
              <SectionTextField
                title="Description"
                initialValue={descriptions ?? initialDataDescription}
                onChange={value => setDescription(value)}
                onUpdate={() => handleSubmit(onSubmit)}
              />
            </div>
          )}
          {(variantName === 'VARIANT_14' || variantName === 'VARIANT_15') && (
            <div className="space-y-4">
              <h2 className="text-[19px] font-semibold">Image</h2>
              <button type="button" className="w-full" onClick={() => setShowImageModal(true)}>
                <div
                  className={clsx(
                    'w-full bg-white dark:bg-black',
                    !url && 'aspect-1 flex justify-center items-center border border-black dark:border-white',
                    url && 'aspect-1',
                  )}
                >
                  {url && <img src={url} className="object-cover object-center bg-black w-full h-full" alt="" />}
                  {!url && <ImageIcon />}
                </div>
              </button>
            </div>
          )}
        </div>
      </form>

      <BottomFloatingButton
        label={data?.id ? 'Update' : 'Add'}
        onClick={handleSubmit(onSubmit)}
        //  disabled={!name}
      />
      <ModalDialogV2 open={showImageModal} onBack={() => setShowImageModal(false)}>
        <ImageUploader
          onSubmit={_url => {
            setUrl(_url)
            setShowImageModal(false)
          }}
          buttonLabel="Select Image"
          onClose={() => setShowImageModal(false)}
        />
      </ModalDialogV2>
    </div>
  )
}

function Item({
  data,
  onDeleteClick,
  onEditClick,
}: {
  data: AboutUsSectionServices
  onEditClick: () => void
  onDeleteClick: () => void
}) {
  return (
    <div className="border border-black dark:border-white h-[65px] flex justify-between">
      <div className="flex items-center">
        <div className="grid grid-cols-2 gap-1 ml-2 mr-4">
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
          <div className="w-2 h-2 bg-gray-300 rounded-full" />
        </div>
        <div className="flex items-center gap-4">
          <ItemField initialValue={data?.name} />
        </div>
      </div>

      <div className="flex">
        <button
          type="button"
          className="flex justify-center items-center w-16 border-l dark:border-white border-black"
          onClick={onEditClick}
        >
          <EditIcon />
        </button>
        <button
          type="button"
          className="flex justify-center items-center w-16 border-l dark:border-white border-black"
          onClick={onDeleteClick}
        >
          <DeleteIcon />
        </button>
      </div>
    </div>
  )
}

export default function EditSectionAboutUsModal({
  open,
  section: defaultValue,
  onClose: onBack,
  onUpdate,
}: EditSection) {
  const websiteContext = useContext(WebsiteContext)

  if (!open || !defaultValue) return null

  const [section, setSection] = useState(defaultValue)
  const [updating, setUpdating] = useState(false)
  const [editMember, setEditMember] = useState<AboutUsSectionServices>()
  const [showAddMemberModal, setShowAddMemberModal] = useState(false)
  const [isService, setIsService] = useState(false)
  const [fireUpdate, setFireUpdate] = useState({
    fire: false,
    goBack: false,
  })

  const title = section.variables.find(variable => variable.name === 'TITLE')
  const subTitle2 = section.variables.find(variable => variable.name === 'SUBTITLE2')
  const subTitle = section.variables.find(variable => variable.name === 'SUBTITLE')
  const paragraph = section.variables.find(variable => variable.name === 'PARAGRAPH')
  const paragraphTwo = section.variables.find(variable => variable.name === 'PARAGRAPH_2')
  const media = section.variables.find(variable => variable.name === 'MEDIA')
  const services = section.variables.find(variable => variable.name === 'SERVICE')
  const clients = section.variables.find(variable => variable.name === 'CLIENT')
  const sectionOne = section.variables.find(variable => variable.name === 'SECTION_1')
  const sectionTwo = section.variables.find(variable => variable.name === 'SECTION_2')
  const button = section.variables.find(variable => variable.name === 'BUTTON_1')
  const updateVariableData = (name: AboutUsVariables, category: SectionVariableCategories, data: any) => {
    const current = section.variables.find(variable => variable.name === name)
    if (!current) {
      setSection({
        ...section,
        variables: [...section.variables, { name, data, category, id: uuid() }],
      })
      return
    }
    const otherVariables = section.variables.filter(variable => variable.name !== name)
    const newVariables = [...otherVariables, { ...current, data }]
    setSection({ ...section, variables: newVariables })
  }

  const handleSave = async (goBack = true) => {
    setFireUpdate({ fire: true, goBack })
  }

  useEffect(() => {
    const update = async (goBack = true) => {
      setUpdating(true)
      await upsertBlockByPageID(websiteContext.pageID, section as any)
      setUpdating(false)
      setFireUpdate({ fire: false, goBack: false })
      if (goBack) onBack()
    }
    if (fireUpdate.fire) update(fireUpdate.goBack)
  }, [fireUpdate, section])

  useEffect(() => {
    onUpdate?.(section)
  }, [section])

  // service
  const handleDelete = (id: string) => {
    const newMembers = services?.data.filter((member: AboutUsSectionServices) => member.id !== id)
    const newClient = clients?.data.filter((member: AboutUsSectionServices) => member.id !== id)
    setSection({
      ...section,
      variables: section.variables.map(variable => {
        if (variable.name === 'SERVICE') {
          return { ...variable, data: newMembers }
        }
        if (variable.name === 'CLIENT') {
          return { ...variable, data: newClient }
        }
        return variable
      }),
    })
    handleSave(false)
  }

  const handleEditAdd = (data: Partial<AboutUsSectionServices>) => {
    if (data.id) {
      const newMembers = services?.data.map((member: AboutUsSectionServices) => {
        if (member.id === data.id) {
          return { ...member, ...data }
        }
        return member
      })
      const newClient = clients?.data.map((member: AboutUsSectionServices) => {
        if (member.id === data.id) {
          return { ...member, ...data }
        }
        return member
      })
      setSection({
        ...section,
        variables: section.variables.map(variable => {
          if (variable.name === 'SERVICE') {
            return { ...variable, data: newMembers }
          }
          if (variable.name === 'CLIENT') {
            return { ...variable, data: newClient }
          }
          return variable
        }),
      })
    } else {
      setSection({
        ...section,
        variables: section.variables.map(variable => {
          if (variable.name === 'SERVICE' && isService) {
            return { ...variable, data: [...variable.data, { ...data, id: uuid() }] }
          }
          if (variable.name === 'CLIENT' && !isService) {
            return { ...variable, data: [...variable.data, { ...data, id: uuid() }] }
          }
          return variable
        }),
      })
    }
    setEditMember(undefined)
    setShowAddMemberModal(false)
    handleSave(false)
  }

  return (
    <>
      <ModalDialogV2 open={open} onBack={onBack}>
        <div className="px-5 dark:text-white mt-[25px]">
          <div className="border-t-[1px] border-black dark:border-white">
            {section.variant !== 'VARIANT_9' && (
              <>
                {section.variant !== 'VARIANT_10' && section.variant !== 'VARIANT_12' && (
                  <SectionTextField
                    initialValue={title?.data ?? []}
                    onChange={value => updateVariableData('TITLE', 'TEXT', value)}
                    onUpdate={() => handleSave(false)}
                  />
                )}
                {section.variant !== 'VARIANT_14' &&
                  section.variant !== 'VARIANT_15' &&
                  section.variant !== 'VARIANT_2' &&
                  section.variant !== 'VARIANT_8' && (
                    <SectionTextField
                      title="Subtitle"
                      initialValue={subTitle?.data ?? []}
                      onChange={value => updateVariableData('SUBTITLE', 'TEXT', value)}
                      onUpdate={() => handleSave(false)}
                    />
                  )}

                {section.variant !== 'VARIANT_10' &&
                  section.variant !== 'VARIANT_14' &&
                  section.variant !== 'VARIANT_15' && (
                    <SectionTextField
                      title="Paragraph"
                      initialValue={paragraph?.data ?? []}
                      isParagraph
                      onChange={value => updateVariableData('PARAGRAPH', 'TEXT', value)}
                      onUpdate={() => handleSave(false)}
                    />
                  )}

                {section.variant === 'VARIANT_12' && (
                  <SectionTextField
                    title="Section Title"
                    initialValue={sectionOne?.data ?? []}
                    onChange={value => updateVariableData('SECTION_1', 'TEXT', value)}
                    onUpdate={() => handleSave(false)}
                  />
                )}
                {section.variant === 'VARIANT_12' && (
                  <SectionTextField
                    title="Section Title"
                    initialValue={sectionTwo?.data ?? []}
                    onChange={value => updateVariableData('SECTION_2', 'TEXT', value)}
                    onUpdate={() => handleSave(false)}
                  />
                )}
              </>
            )}
            {section.variant === 'VARIANT_9' && (
              <>
                <div className="p-4 border-2 border-t-0 border-black dark:border-white text-center text-2xl ">
                  <select value={websiteContext.dropValue} onChange={e => websiteContext.setDropValue(e.target.value)}>
                    <option value="info" key="info">
                      INFO
                    </option>
                    <option value="about-us" key="about-us">
                      ABOUT US
                    </option>
                  </select>
                </div>

                <SectionTextField
                  title="Subtitle One"
                  initialValue={subTitle?.data ?? []}
                  onChange={value => updateVariableData('SUBTITLE', 'TEXT', value)}
                  onUpdate={() => handleSave(false)}
                />

                <SectionTextField
                  title="Subtitle Two"
                  initialValue={subTitle2?.data ?? []}
                  onChange={value => updateVariableData('SUBTITLE2', 'TEXT', value)}
                  onUpdate={() => handleSave(false)}
                />

                <SectionTextField
                  title="Paragraph One"
                  initialValue={paragraph?.data ?? []}
                  isParagraph
                  onChange={value => updateVariableData('PARAGRAPH', 'TEXT', value)}
                  onUpdate={() => handleSave(false)}
                />

                <SectionTextField
                  title="Paragraph Two"
                  initialValue={paragraphTwo?.data ?? []}
                  isParagraph
                  onChange={value => updateVariableData('PARAGRAPH_2', 'TEXT', value)}
                  onUpdate={() => handleSave(false)}
                />
              </>
            )}
            {section.variant !== 'VARIANT_1' &&
              section.variant !== 'VARIANT_4' &&
              section.variant !== 'VARIANT_5' &&
              section.variant !== 'VARIANT_10' &&
              section.variant !== 'VARIANT_12' &&
              section.variant !== 'VARIANT_11' &&
              section.variant !== 'VARIANT_14' && (
                <SectionMediaField
                  defaultValue={media?.data}
                  onChange={url => {
                    if (!url) {
                      setSection({
                        ...section,
                        variables: section.variables.filter(variable => variable.name !== 'MEDIA'),
                      })
                      return
                    }
                    updateVariableData('MEDIA', 'IMAGE', url)
                    handleSave(false)
                  }}
                />
              )}

            {section.variant === 'VARIANT_6' && (
              <SectionTextField
                title="List Title"
                initialValue={sectionOne?.data ?? []}
                onChange={value => updateVariableData('SECTION_1', 'TEXT', value)}
                onUpdate={() => handleSave(false)}
              />
            )}
            {section.variant === 'VARIANT_7' || section.variant === 'VARIANT_8' ? (
              <ButtonForSection
                onButtonChange={update => updateVariableData('BUTTON_1', 'BUTTON', update)}
                onChange={update => updateVariableData('BUTTON_1', 'BUTTON', update)}
                defaultValue={button?.data}
              />
            ) : null}
            {(section.variant === 'VARIANT_6' ||
              section.variant === 'VARIANT_10' ||
              section.variant === 'VARIANT_14' ||
              section.variant === 'VARIANT_15') && (
              <div className="py-4">
                <h2 className="capitalize font-semibold text-[19px]  dark:text-white py-4">Items</h2>
                <div className="space-y-5">
                  {services?.data.map((service: AboutUsSectionServices) => (
                    <Item
                      key={service.id}
                      data={service}
                      onDeleteClick={() => handleDelete(service.id)}
                      onEditClick={() => setEditMember(service)}
                    />
                  ))}
                </div>
                <button
                  className="border border-black bg-black dark:border-white h-[65px] flex justify-between items-center text-lg px-6 w-full text-white mt-4"
                  type="button"
                  onClick={() => {
                    setShowAddMemberModal(true)
                    setIsService(true)
                  }}
                >
                  <span>Add Item</span> <span className="text-4xl">+</span>
                </button>
              </div>
            )}

            {section.variant === 'VARIANT_12' && (
              <>
                <div className="mt-4">
                  <h2 className="uppercase font-medium text-sm dark:text-white py-4">
                    <SectionSlate initialValue={sectionOne?.data} previewMode />
                  </h2>
                  <div className="space-y-5">
                    {services?.data.map((service: AboutUsSectionServices) => (
                      <Item
                        key={service.id}
                        data={service}
                        onDeleteClick={() => handleDelete(service.id)}
                        onEditClick={() => setEditMember(service)}
                      />
                    ))}
                  </div>
                  <button
                    className="border border-black bg-black dark:border-white h-[65px] flex justify-between items-center text-lg px-6 w-full text-white mt-4"
                    type="button"
                    onClick={() => {
                      setShowAddMemberModal(true)
                      setIsService(true)
                    }}
                  >
                    <span>Add Item</span> <span className="text-4xl">+</span>
                  </button>
                </div>
                <div className="mt-12 space-y-5 pb-24">
                  <h2 className="uppercase font-medium text-sm dark:text-white">
                    <SectionSlate initialValue={sectionTwo?.data} previewMode />
                  </h2>
                  <div className="space-y-5 ">
                    {clients?.data.map((service: AboutUsSectionServices) => (
                      <Item
                        key={service.id}
                        data={service}
                        onDeleteClick={() => handleDelete(service.id)}
                        onEditClick={() => setEditMember(service)}
                      />
                    ))}
                  </div>
                  <button
                    className="border border-black bg-black dark:border-white h-[65px] flex justify-between items-center text-lg px-6 w-full text-white mt-4"
                    type="button"
                    onClick={() => {
                      setShowAddMemberModal(true)
                      setIsService(false)
                    }}
                  >
                    <span>Add Item</span> <span className="text-4xl">+</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="absolute top-[21px] left-[50%] translate-x-[-50%]">
          <AboutUs width="auto" height={20} />
        </div>
        <div className="absolute top-[21px] right-[50px]">
          <button type="button" onClick={() => handleSave()} disabled={updating}>
            <MenuSaveButton save="save" />
          </button>
        </div>
        {updating && (
          <div className="fixed z-30 inset-0 lg:max-w-[500px] dark:bg-black/80 bg-white/80">
            <p className="flex items-center h-full justify-center w-full text-[19px] dark:text-white">Updating...</p>
          </div>
        )}

        <AdvancedFeature
          section={section}
          onChange={settings => setSection(s => ({ ...s, settings }))}
          onBack={() => handleSave(false)}
        />
      </ModalDialogV2>

      <ModalDialogV2
        open={editMember !== undefined || showAddMemberModal}
        onBack={() => (editMember ? setEditMember(undefined) : setShowAddMemberModal(false))}
      >
        <EditServices data={editMember} variantName={section?.variant} onSave={handleEditAdd} />
      </ModalDialogV2>
    </>
  )
}
