import { Fragment } from 'react'
import ModalDialogV2 from 'src/components/new/ModalDialogV2'
import useApp from 'src/store/app'
import useWebsite from 'src/store/website'
import { formatMenuLinks } from 'src/util/menu'
import FooterPreviewRenderer from 'src/_polly/components/src/footer/FooterPreviewRenderer'
import MenuPreviewRenderer from 'src/_polly/components/src/menu/MenuPreviewRenderer'
import { SectionPreviewRenderer } from 'src/_polly/components/src/sections'
import { VariantIframe } from '../NewSectionVariantSelection'

type PreviewProps = {
  open: boolean
  onBack: () => void
}

export default function Preview({ onBack, open }: PreviewProps) {
  const store = useWebsite(state => state)
  const appStore = useApp(state => state)

  return (
    <div>
      <ModalDialogV2 {...{ open, onBack }}>
        <div className="mx-2">
          <MenuPreviewRenderer
            menu={{
              ...store.website.newMenuConfig,
              links: formatMenuLinks({ links: store.website.newMenu, website: store.website }),
            }}
            noShadow
            disableHover
            website={store.website}
          />
          {store.components.map(component => (
            <Fragment key={component.key}>
              {appStore.previewMode === 'desktop' ? (
                <SectionPreviewRenderer rounded section={component} noShadow disableHover />
              ) : (
                <VariantIframe section={component} />
              )}
            </Fragment>
          ))}
          <FooterPreviewRenderer footer={store.website.newFooter} noShadow disableHover website={store.website} />
        </div>
      </ModalDialogV2>
    </div>
  )
}
